import { Grid, Stack, Typography, Button ,Modal ,Box ,Chip} from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { useNavigate } from "react-router-dom";
import AddBranch from "./addBranch";
import WarningImg from "../../../../images/warning.png"
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import RestoreImg from '../../../../common/images/restore-icn.svg'
import EditIcon from '@mui/icons-material/Edit';
import { EditPartnerBranch } from "./editPartnerBranch";



// styles

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };

export default function PartnerBranches() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const queryparams = decodeURIComponent(window.location.search);
    const [activateBranch, setActivateBranch] = useState(false);
    const [deactivateBranch, setDeactivateBranch] = useState(false);
    const [branchCode, setBranchCode] = useState();
    const [branchName , setbranchName] = useState();
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")
    const navigate = useNavigate();

    const handleActivateBranch = (params) => {
        setBranchCode(params?.row?.branchCode);
        setbranchName(params?.row?.branchName);
        setActivateBranch(true);
    }

    const handleAClsoeActivateBranch = (params) => {
        setActivateBranch(false);
    }

    const handleOpenDeactivateBranch = (params) => {
        setBranchCode(params?.row?.branchCode);
        setbranchName(params?.row?.branchName);;
        setDeactivateBranch(true);
    }

    const handleACloseDeactivateBranch = (params) => {
        setDeactivateBranch(false);
    }

    function handleActivateBranchSubmit() { 
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/activate-deactivate-branches`,
                body: {
                    branchCode: branchCode,
                    status: activateBranch ? "Active" : "InActive"
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    getAllBranchesList()
                    setTimeout(() => {
                        if (activateBranch) {
                            handleAClsoeActivateBranch()
                        } else {
                            handleACloseDeactivateBranch()
                        }
                    }, 1500)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }

    }


    function getAllBranchesList() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/allregionpartnerbranches?page=${pageState.page}&limit=${dataGridPageSize}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllBranchesList();
    }, [dataGridPageSize, pageState.page, queryParamValue])

    const getRowId = (row) => {
        return Math.floor(Math.random() * 2000000)
    }

    const AllBranchesColumns = [
        { headerName: "branchCode", field: 'branchCode', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Branch Code</strong>) } },
        { headerName: "branchName", field: 'branchName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Branch Name </strong>) } },
        { headerName: "regionName", field: 'regionName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Region Name</strong>) } },
        { headerName: "regionDescription", field: 'regionDescription', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Description </strong>) } },
        {
            headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Status </strong>) }, renderCell: (params) => {
                return (
                    <Chip label={params?.row?.status} color={params?.row?.status === 'Active' ? 'success' : 'error'} sx={{ fontSize: '12px', fontWeight: 600, height: '20px' }} />
                );
            }
        },
        {headerName: "Actions",field: "actions", headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Actions </strong>) }, renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        {params?.row?.status === 'Active' ? 
                            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <NotInterestedIcon onClick={() => handleOpenDeactivateBranch(params)} sx={{ color: '#cb444a' }} />
                                <EditIcon onClick={() => navigate(`/partner/branches?EditBranch?${params?.row?.regionBranchId}`)} sx={{ color: '#032541' }} />
                            </Box>
                         :  
                        <SettingsBackupRestoreIcon onClick={()=> handleActivateBranch(params)} sx={{color:"#17ae7b"}}/>}
                    </Stack>

                )
            }
        }
    ]


    return (
        <Grid container direction={'column'}>
            {queryParamValue === 'AddBranch' ? <AddBranch /> :queryParamValue === 'EditBranch' ? <EditPartnerBranch/>:  <>
                <SuccessAlert vertical="top" horizontal="right"
                    onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                    message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                    open={errorShow.state} message={errorShow.message} />
                <Grid item display={'flex'} alignItems={'center'} >
                    <Stack alignItems='center' direction='row' spacing={2}>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "#707070",
                                "&:hover": { cursor: "pointer" },
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        />
                        <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Branches</Typography>
                    </Stack>
                </Grid>
                <Grid item display={'flex'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                    <Breadcrumb name={'Branches'} />
                    <Button onClick={() => navigate(`/partner/branches?AddBranch`)} style={{ width: '146px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Add Branch</Button>
                </Grid>
                <Grid item mt={2}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[10, 20, 50, 70, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={AllBranchesColumns}
                        rows={pageState?.data}
                        getRowId={getRowId} />
                </Grid>
                <Modal
                    open={deactivateBranch}
                    onClose={handleACloseDeactivateBranch}
                    slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid container display={'flex'} spacing={4}>
                                <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid item>
                                        <img src={WarningImg} alt='Warning' />
                                    </Grid>
                                    <Grid item ml={2}>
                                        <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                            Deactivate branch ?
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Are you sure you want to deactivate -   <span style={{ fontWeight: 700, }}>{branchName}</span>
                                        </Typography>
                                        <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Button onClick={handleACloseDeactivateBranch} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                            <Button onClick={handleActivateBranchSubmit} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={activateBranch}
                    onClose={handleAClsoeActivateBranch}
                    slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid container display={'flex'} spacing={4}>
                                <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid item>
                                        <img src={RestoreImg} alt='Warning' />
                                    </Grid>
                                    <Grid item ml={2}>
                                        <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                            Restore  Branch ?
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Are you sure you want to restore   <span style={{ fontWeight: 700, }}>{branchName}</span>
                                        </Typography>
                                        <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Button onClick={handleAClsoeActivateBranch} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                            <Button onClick={handleActivateBranchSubmit} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#17ae7b" }}>Activate </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </>}
        </Grid>
    )
}