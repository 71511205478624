import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Modal, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import dissaprovetick from "../../../common/images/dissaprovetick.svg";
import { MoreVert } from "@mui/icons-material";
import { Menudropdown, SuspendCustomerModal } from "./modals";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { customerstyles } from "./styles";
import DateFormatter from "../../../utils/dateFormatter";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import HttpComponent from "../../School/MakeRequest";

const baseUrl = process.env.REACT_APP_BASE_URL;

// API END POINTS;
const getPendingCustomers = baseUrl + "/api/customers_list?status=Awaiting&page=1&limit=10&paymentType=&customerType=";

const approveCustomerUrl = baseUrl + "/api/activateCustomer";
const dissapproveCustomerUrl = baseUrl + "/api/deleteCustomer";

const CustomerAproval = (props) => {
  const { businessCat } = useSelector((store) => store.businessCategory);

  const customerSearchValue = props?.customerSearchValue;

  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  // APPROVE CUSTOMER STATES;
  // ACTIONS BUTTON STATE;

  const [customerApproval, setCustomerApproval] = useState(false)
  const [approvingProcess, setApprovingProcess] = useState(false)


  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((customer) => [customer?.customerName, customer?.customerType, customer?.paymentType, DateFormatter(customer?.createdOn), CurrencyFormatter(customer?.limit, "KES")]);
    const pdfHeaders = [["Customer Name", "Customer Type", "Payment Type", "Date Created", "Amount/Limit (KES)"]]
    setExportData({ headers: pdfHeaders, data: pdfData });

    const csvData = pageState?.data?.map((customer) => {
      return {
        "Customer Name": customer?.customerName,
        "Customer Type": customer?.customerType,
        "Payment Type": customer?.paymentType,
        "Date Created": customer?.createdOn,
        "Amount": customer?.limit
      }
    })

    const csvHeaders = [
      { label: "Customer Name", key: "Customer Name" },
      { label: "Customer Type", key: "Customer Type" },
      { label: "Payment Type", key: "Payment Type" },
      { label: "Date Created", key: "Date Created" },
      { label: "Amount", key: "Amount" },
    ]

    setCsvExport({ headers: csvHeaders, data: csvData })
  }, [pageState.data, pageState.pageSize, dataGridPageSize]);


  // CONST GET CUSTOMER PENDING APPROVAL DATA;
  const getCustomersPendingApproval = async () => {
    // if (getApprovedCustomersDb.length) return;
    try {
      const pendingApprovalresponse = await fetch(baseUrl + `/api/customers_list?status=Awaiting&page=${pageState.page}&limit=${dataGridPageSize}&paymentType=&customerType=`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (pendingApprovalresponse.status === 200) {
        const pendingApprovalResponseData = await pendingApprovalresponse.json();
        setPageState({ ...pageState, isLoading: false, data: pendingApprovalResponseData.data, count: pendingApprovalResponseData.count });
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getCustomersPendingApproval();
  }, [pageState.page, dataGridPageSize]);

  // APPROVE CUSTOMER STATES;
  const [sucess, setSucess] = useState({ state: false, message: "" })
  const [openSuspendModal, setSuspendModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  const [dissapproveId, setDissaprovedId] = useState("");
  const [dissaproveName, setDissaprovedName] = useState("");
  const [dissapprovingStarts, setDissapproving] = useState(false)
  const getUserDetailsAndDeleteAcc = (params) => {
    console.log(params);
    if (params) {
      setDissaprovedName(params.customerName);
      setDissaprovedId(params.id);
      setAnchorEl(null);
      setSuspendModal(true)
      setDissapproving(true)
    }
  }

  const dissApproveCustomer = async () => {
    try {
      const dissapproveCustomerResponse = await fetch(dissapproveCustomerUrl + `?customerId=${dissapproveId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (dissapproveCustomerResponse.status === 202) {
        setSucess({ state: true, message: "User has been deleted  sucessfully!" });
        getCustomersPendingApproval()
        setSuspendModal(false)
        setApprovingProcess(false)
      }

      setTimeout(() => {
        setSucess({ state: false });
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  };


  const [approveId, setApproveId] = useState("");
  const [approveName, setApprovedName] = useState("");
  // console.log(approveId);
  const getUserInfoAndApprove = (params) => {

    if (params) {
      setAnchorEl(null);
      setSuspendModal(true)
      setApprovingProcess(true)
      setApprovedName(params.row.customerName)
      setApproveId(params.row.id)
      setDissapproving(false)
    }
  }

  const approveCustomer = async (data) => {
    try {
      const approveCustomerResponse = await fetch(approveCustomerUrl + `?customerId=${approveId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (approveCustomerResponse.status === 202) {
        setSucess({ state: true, message: "User has been approved sucessfully!" });
        getCustomersPendingApproval()
        setSuspendModal(false)
        setApprovingProcess(false)
      }

      setTimeout(() => {
        setSucess({ state: false });
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const searchCustomer = () => {
    HttpComponent({
      method: "GET",
      url: `/api/searchCustomer?state=Awaiting&search=${customerSearchValue}`,
      body: null,
      token: localStorage.getItem('X-Authorization')
    }).then((data) => {
      setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
    }).catch((e) => {
      console.log(e.message);
    })
  }

  useEffect(() => {
    searchCustomer()
  }, [customerSearchValue])

  return (
    <>
      <DataGrid
        style={customerstyles.dataGridStyling}
        columns={[
          {
            field: "customerName",
            headerName: "Full Name",
            renderHeader: () => (
              <strong
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#032541",
                }}
              >
                {"Full Name"}
              </strong>
            ),
            flex: 1,
          },
          {
            field: "customerType",
            headerName: "Type",
            renderHeader: () => (
              <strong
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "#032541",
                }}
              >
                {"Type"}
              </strong>
            ),
            flex: 1,
          },
          { field: "limit", hide: businessCat === "Rental", headerName: "Amount/Limit", renderHeader: () => <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>{"Amount/Limit"}</strong>, flex: 1 },
          //{field: "extraServices", hide : businessCat !== 'Rental',  renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541",}}>{"Charges"}</strong>), flex: 1,},
          { field: "paymentType", hide: businessCat === "Rental", headerName: "Payment", renderHeader: () => <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>{"Payment"}</strong>, flex: 1 },
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {

              const [anchorEl, setAnchorEl] = React.useState(null);
              const MenuoptionOpen = Boolean(anchorEl);
              const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
                setCustomerApproval(true)
              };
              const handleActionClose = () => {
                setAnchorEl(null);
              };

              return (
                <div>

                  <MoreVert aria-controls={MenuoptionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={MenuoptionOpen ? "true" : undefined} variant="outlined" disableElevation color="primary" onClick={handleClick} id="demo-customized-button" />

                  <Menudropdown MenuoptionOpen={MenuoptionOpen} handleActionClose={handleActionClose} anchorEl={anchorEl} params={params.row.id} getUserInfoAndApprove={getUserInfoAndApprove} approveParams={params} customerApproval={customerApproval} getUserDetailsAndDeleteAcc={getUserDetailsAndDeleteAcc} />

                  <SuspendCustomerModal approvingProcess={approvingProcess} openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} approveCustomer={approveCustomer} setDissapproving={setDissapproving} dissapprovingStarts={dissapprovingStarts} dissApproveCustomer={dissApproveCustomer} />
                  {/* <Grid item xs="6" style={{ width: "100%" }}>
                      <img src={approveTick} alt="" />
                    </Grid>

                    <Grid item xs="6" style={{ width: "100%" }}>
                      <img src={dissaprovetick} alt="" />
                    </Grid> */}

                </div>
              );
            },
          },
        ]}
        rows={pageState?.data?.map((pendApp) => ({
          id: pendApp._id,
          customerName: pendApp.customerName,
          customerType: pendApp.customerType,
          paymentType: pendApp.paymentType,
          limit: pendApp.limit,
        }))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />

      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
    </>
  );
};

export default CustomerAproval;
