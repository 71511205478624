import {Breadcrumbs, Button, ButtonBase, Grid, Typography} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from "../../../School/CustomSearchInput";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CustomTable from "../../../School/CustomTable";
import Radio from "@mui/material/Radio";
import ServiceCharges from "./serviceCharges";
import HttpComponent from "../../../School/MakeRequest";
import { useSelector } from "react-redux";

//BreadCrumbs
const breadcrumbs = [
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Tenants</Typography>,
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">House Type</Typography>,
	<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Unoccupied Units</Typography>
];


export default function UnoccupiedUnits(props) {

		const snewelectedValue = props?.selectedValue;

		const navigate = useNavigate()
		const { X_Authorization } = useSelector((store) => store.user)
		const [availableHs ,setAvailableHs] = useState([])

		// Template Render
		const [flagState, setFlagState] = useState("listUnoccupiedUnits")
		function handleFlagState (state) {setFlagState(state)}
		const [uniCost ,setUnitCost] = useState('')
		const [unitName ,setUnitName] = useState('')

		const [selectedValue, setSelectedValue] = React.useState();

		const handleChange = (params) => {
			setSelectedValue(params.row._id);
			setUnitName(params.row.unitName)
			setUnitCost(params.row.unitCost)
		};

		const columns = [
			{field : 'unitName', headerAlign : 'left', align : 'left', flex : 1, renderHeader : () => { return (<strong style={{fontSize : '12px', color : '#032541', fontWeight : 600}} >House Number</strong>)}, renderCell : (params) => {return (
					<div>
						<Radio value={params.row._id} size={'small'} checked={selectedValue === params.row._id} onChange={()=>handleChange(params)} color={'error'}/>
						<span style={{marginLeft : '5px'}}>{params.value}</span>
					</div>
				)}},
		]

		// loader
		const [loader, setLoader] = useState(false)

		// search
		const [formData, setFormData] = useState({search:""})
		const handleInputChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value})}

		//fetch selected unit

		function fetchSelectedHouse(){
			try {
				HttpComponent({
					method:"GET" ,
					url:`/api/get_units_by_unit_type_id?businessUnitTypeId=${snewelectedValue}&page=1&limit=1000`,
					token:X_Authorization
				}).then((data)=>{
					console.log(data ,'available house')
					setAvailableHs(data.response.data)
				})
			} catch (error) {
				
			}

		}

		//effect to fetch  the selected units

		useEffect(()=>{
			fetchSelectedHouse()

		},[])


		return (
			<div>
				{flagState === 'listUnoccupiedUnits' ?
					<div>
						{/*Header and action buttons, breadCrumbs*/}
						<Grid container justifyContent={'flex-start'} direction={'column'} spacing={2}>

							{/*Header and Bread crumbs*/}
							<Grid item>
								<ButtonBase onClick={() =>{navigate("/dashboard")}}>
									<ArrowBackIosIcon style={{color : '#707070', fontSize : '25px', marginBottom : '5px'}} />
								</ButtonBase>
								<span style={{color : '#032541', fontWeight : '600', fontSize : '25px'}}>Unoccupied Units</span>
							</Grid>

							{/*BreadCrumbs and Action Buttons*/}
							<Grid item>
								<Grid container justifyContent={'space-between'} direction={'row'}>

									{/*BreadCrumb container*/}
									<Grid item>
										<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
											{breadcrumbs}
										</Breadcrumbs>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						{/*Search and Export Container*/}
						<Grid container justifyContent={'space-between'} mt={4}>

							{/*SearchBox*/}
							<Grid item>
								<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:"/>
							</Grid>

							{/*Export button*/}
							<Grid item>
								{/*	TODO : Add Export Button*/}
							</Grid>
						</Grid>

						{/*Table*/}
						<CustomTable fontSize={'10px'} data={availableHs} columns={columns} rowsPerPage={5} loading={loader}/>

						{/*Cancel and Next Buttons*/}
						<Grid container justifyContent={'flex-end'} spacing={3}>

							{/*Cancel*/}
							<Grid item>
								<Button onClick={() => {props.listUnoccupiedHouses()}} style={{borderRadius : "6px", border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}} >
									Cancel
								</Button>
							</Grid>

							{/*Add Unit*/}
							{!selectedValue ? null :
								<Grid item>
									<Button onClick={() => {handleFlagState('listServices')}} style={{padding : '5px', textTransform : 'none', color : '#fff', background : '#032541', height : '45px', fontSize : '16px', fontWeight : '600', width : '146px'}}>
										Next
									</Button>
								</Grid>
							}

						</Grid>
					</div> : flagState === 'listServices' ? <ServiceCharges unitType={props?.unitType} uniCost={uniCost} unitName={unitName} businessUniId={snewelectedValue} selectedValue={selectedValue} /> : null}
			</div>
		)
	} 