import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Breadcrumbs, Button, Checkbox, ButtonBase, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { handleAddCharge, handleAddChargeToList, handleChargeService, handleChargesData, handleDeleteCharge, handleRemoveChargeFromList } from "../../../../features/tenantsDataSlice";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import HttpComponent from "../../../School/MakeRequest";

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

export default function EditInvoice(props) {

	// navigation
	function handleCreateService() {
		props?.setFlagCreateService()
		console.log(`Props`, props)
	}

	//Redux Store
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const service = useSelector((store) => store.tenantData.tenantChargesData)
	const tenantHouseServices = useSelector((store) => store.tenantData.tenantHouseServices)
	//const products = useSelector((store) => store.tenantData.tenantChargesData.products)

	const { unitId, unitTypeId, amount, itemNumber, billingDate, unitType } = useSelector((store) => store.tenantData.tenantHouseData)

	//Auth
	const { userId, X_Authorization } = useSelector((store) => store.user);
	const [unitCharges, setUnitCharges] = useState([])


	//get the serviceType

	const handleCheckboxChange = (event, product) => {
		if (event.target.checked) {
			let data = {productId : product.productId, name: product.serviceName, amount: product.amount, serviceType : product.serviceType}
			console.log(`this is Data`, data)
			dispatch(handleAddCharge(data));}
		else {dispatch(handleDeleteCharge(product.productId))}
	};

	// check if service already exists in the list servicesdata

	const submitCellDataOnPressEnter = (params ,event) =>{
		if (event.key === 'Enter') {
			// Save the edited value
			const editedCellProps = params.api.getEditCellProps();
			const value = editedCellProps.value;
			const rowIndex = editedCellProps.rowIndex;
			const field = editedCellProps.field;
			const row = params.api.getRow(rowIndex);
			row.setDataValue(field, value);
			params.api.commitCellChange({ field, value, id: row.id }, 'api');
		}
		

	}
		


	const servicesColumns = [
		{
			field: 'serviceName', flex: 1, align: 'left', headerAlign: 'left', renderCell: (params) => (
				<div>
					<Checkbox
						style={{ color: '#dc3545' }}
						checked={service?.some(s => s.productId === params.row.productId)}
						onChange={(event) => handleCheckboxChange(event, params.row)} />
					<span style={{ color: '#707070', fontSize: '14px' }}>{params.value}</span>
				</div>
			), renderHeader: () => { return (<strong style={{ color: '#032541' }} >Service Name</strong>) }
		},
		{ field: 'serviceType', flex: 1, align: 'left', headerAlign: 'left', renderHeader: () => { return (<strong style={{ color: '#032541' }}>Service Type</strong>) } },
		{ field: 'amount', flex: 1, align: 'left', headerAlign: 'left',  editable: (row) => {
			 return row.serviceType.toString() === 'Variable'},
			 renderHeader: () => { return (<strong style={{ color: '#032541' }}>Service Amount</strong>) }, renderCell: (params) => 
			 {
				return (numberFormat(params.value)) } },
	]

	const breadcrumbs = [
		<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
		<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Create tenants</Typography>,
		<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Tenant Details</Typography>,
		<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">House Details</Typography>,
		<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Advance Charges</Typography>,
		<Link style={{ fontFamily: 'Poppins', fontSize: '14px', textDecoration: "none" }} to={-1} key="3" color="text.inactive">Preview Invoice</Link>,
		<Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Update Invoice</Typography>
	];

	//Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${unitId}`,
			token: X_Authorization
		}).then((data) => {
			console.log(data, 'hello serrvices per unit')
			if (data.status === 201) {
				setUnitCharges(data.response.data)
				dispatch(handleChargeService(data.response.data))
			} else {
				console.log(`Error getting data`)
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		fetchServicesPerUnit()
	}, [])

	console.log(`charges`, unitCharges)
	return (
		<div style={{ fontFamily: 'Poppins' }}>

			{/*BreadCrumb container*/}
			<Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
				{breadcrumbs}
			</Breadcrumbs>

			{/*Top Container*/}
			<Grid container justifyContent={'space-between'} mt={7}>

				{/*Header label*/}
				<Grid item>
					<span style={{ color: '#707070', fontFamily: 'Poppins', fontSize: '16px' }}>List of Service</span>
				</Grid>

				{/*Button Create Service*/}
				<Grid item>
					<Button onClick={handleCreateService} style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '35px' }} >Create Service</Button>
				</Grid>
			</Grid>

			{/*Table Container*/}
			<DataGrid
				components={{ NoRowsOverlay: NoRowsOverlay }}
				sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#707070', boxShadow: 0, border: 0 }}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				columns={servicesColumns}
				rows={unitCharges}
				getRowId={(row) => row.productId}
				isCellEditable={(params) => params.row.serviceType === 'Variable'}
			/>

			{/*Update Button*/}
			<Grid container justifyContent={'flex-end'}>
				<Grid item>
					<Button onClick={() => { navigate(-1) }} style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#17ae7b', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '35px' }} >Update Invoice</Button>
				</Grid>
			</Grid>
		</div>
	)
}


