import React, {useState, useEffect} from "react";
import {Modal, Box, Grid, Button} from "@mui/material";
import Success from '../../../common/images/success.svg'
const baseUrl = process.env.REACT_APP_BASE_URL;

const PaymentSent = (props) => {

    const [successOpen, setSuccessOpen] = useState(props.successOpen);

    const wholeModal = {width: '320px', height: '350px', backgroundColor: '#fff', borderColor:"transparent", borderRadius: "15px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',};


    const handleClick = () => {
        props.onClose()
    }

    useEffect(() => {
        setSuccessOpen(props.successOpen);
    }, [props.successOpen]);

    return (
        <div>
            <Modal open={successOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={wholeModal}>
                    <Grid container alignItems={'center'} justifyContent={'center'} style={{marginTop : '5%'}} >
                        <img src={Success} alt="Success" style={{
                            height:"100px",
                            width:"100px",
                            margin:"5px"
                        }}/>
                        <div style={{"color":"#032541", textAlign:"center", fontWeight:"bold", fontSize:"25px",margin:"5px"}}>Request Sent Successfully!</div>
                        <div style={{"color":"#032541", textAlign:"center",margin:"5px"}}>Kindly Check your Phone for PIN Input</div>
                        <Grid item style={{
                            margin:"5px"
                        }}>
                            <Button onClick={()=>handleClick()} style={{"width":"130px","height":"45px","borderRadius":"5px","backgroundColor":"#032541",color:"white"}}>Okay</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
};
export { PaymentSent };
