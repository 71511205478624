import { Box, Button, Checkbox, Typography } from "@mui/material"
import CustomInputLabel from "./CustomInputLabel"
import CustomTextField from "./CustomTextField"
import { useState } from "react"

const noStyle = {
    "borderRadius": "4px",
    "border": "solid 1px #dc3545",
    "color": "#dc3545",
    textTransform: "none",
    textDecoration: "none"
}

const buttonWidthHeight = {
    width: "9.063rem",
    height: "3.125rem"
}

const yesStyle = {
    "borderRadius": "4px",
    "backgroundColor": "#032541",
    textTransform: "none",
    textDecoration: "none",
    color: "white",
    '&:hover': {
        backgroundColor: '#032541',
        color: 'white'
    }
}

const AirtelMoney = ({ setSuccessShow, setErrorShow, setShowAirtelMoneySetup, airtelMoneyStatus }) => {
    const [checked, setChecked] = useState(false);
    const [formData, setFormData] = useState({
        merchantName: '',
        subscriptionName: '',
        clientId: "",
        clientSecret: "",
    })
    const handleInputChange = (e) => {
        setFormData(({ ...formData, [e.target.name]: e.target.value }))
    }
    const handleCheckBoxChange = (e) => {
        setChecked(!checked)
    }
    function handleAirtelMoney() {

    }
    return (
        <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "6.6%" }}>
            <Box component={'div'} pt={5} px={3} pb={2} display={'flex'} flexDirection={'column'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                    <Typography fontWeight={600} variant={'h6'} fontSize={'16px'} color={'#032541'}>Airtel money</Typography>
                </Box>
                <Box component="div" mt={2} width={'100%'}>
                    <CustomInputLabel required={true} label={"Merchant Name"} />
                    <CustomTextField large value={formData.merchantName} onChange={handleInputChange} name={"merchantName"} placeholder={"Merchant Name"} />
                </Box>
                <Box component="div" width={'100%'}>
                    <CustomInputLabel required={true} label={"Subscription Name"} />
                    <CustomTextField large value={formData.subscriptionName} onChange={handleInputChange} name={"subscriptionName"} placeholder={"collections"} />
                </Box>
                <Box component="div" width={'100%'}>
                    <CustomInputLabel required={true} label={"Client ID"} />
                    <CustomTextField large value={formData.clientId} onChange={handleInputChange} name={"clientId"} placeholder={"Client ID"} />
                </Box>
                <Box component="div" width={'100%'}>
                    <CustomInputLabel required={true} label={"Client Secret"} />
                    <CustomTextField large value={formData.clientSecret} onChange={handleInputChange} name={"clientSecret"} placeholder={"Client Secret"} />
                </Box>
                <Box component="div" sx={{}}>
                    <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                    <span>By ticking, you are agreeing to the terms and conditions.</span>
                </Box>
                {!airtelMoneyStatus ?
                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginBottom: 2 }}>
                        <Box component="div" sx={{ alignSelf: "center" }}>
                            <Button onClick={() => setShowAirtelMoneySetup(false)} sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                Cancel
                            </Button>

                            <Button hidden={!checked} onClick={handleAirtelMoney} sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }}>
                                Activate
                            </Button>
                        </Box>
                    </Box>
                    :
                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent:"space-between", marginBottom: 2 }}>
                        <Box component="div" sx={{ alignSelf: "center" }}>
                            <Button sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                Deactivate
                            </Button>

                            <Button sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }}>
                                Update
                            </Button>
                        </Box>
                    </Box>}
            </Box>
        </Box>
    )
}

export { AirtelMoney }