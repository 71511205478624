import { Grid, Typography, Box, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import CustomSearchInput from '../School/CustomSearchInput';
import { Exports } from '../customerAccounts/customerComponents/exports';
import CustomDataGrid from '../products/stockComponents/customDataGrid';
import { currencyconverter } from '../../common/currencyconverter';
import DateFormatter from '../../utils/dateFormatter';
import HttpComponent from '../School/MakeRequest';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>
]

export default function AllInsuranceTransactions() {
    const [searchValue, setSearchValue] = useState('');
    const [dataGridPageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setCsvExport] = useState({ headers: [], data: [] });


    function getRecentTransactions() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getinsurancetransactionsummary?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getRecentTransactions()
    },[dataGridPageSize , pageState.page])


    

    useEffect(() => {
        const filedata = pageState?.data?.map((data) => {
            return {
                "transactionId": data?.transactionID,
                "Business Name": data?.businessName,
                "Amount":currencyconverter(data?.transamount),
                "Date": DateFormatter(data?.transtime),
                "registrationNo": data?.regNo,               
            }
        })

        const fileHeaders = [["transactionId", "Business Name", "Amount", "Date", "registrationNo", ]]
        const csvColumns = [
            { label: "transactionId", key: "transactionId" },
            { label: "Sacco", key: "Sacco", },
            { label: "Amount", key: "Amount" },
            { label: "Date", key: "Date" },
            { label: "registrationNo", key: "registrationNo", },
        ];

        const fileDataPDF = pageState?.data?.map((data) => [data?.receiptNo ,(data?.transactionTime), data?.source, data?.transactionType, data?.businessId, data?.tellerId, data?.terminalSerialNumber]);

        setCsvExport({ headers: csvColumns, data: filedata })
        setExportData({ headers: fileHeaders, data: fileDataPDF })


    }, [pageState.data, startDate, searchValue, endDate])


    // vehicles columns

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        transactionId: config?.transactionID,
        businessNumber: config?.businessName,
        Amount: currencyconverter(config?.transamount),
        createdAt:DateFormatter(config?.transtime),
        registrationNo: config?.regNo,
    }))

    // recent transactions columns

    const recentTransactionsColumns = [
        { field: "transactionId", headerName: "transactionId", flex: 1 },
        { field: "businessNumber", headerName: "Sacco", flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
        { field: 'createdAt', headerName: 'Date', flex: 1 },
        { field: 'registrationNo', headerName: 'registrationNo', flex: 1 },
    ]



    return (
        <Grid container direction={'column'}>
            <Grid item mt={2}  mb={2}>
                <Grid container gap={4} display={'flex'} direction={'column'}>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                        <Typography variant='h4' sx={{fontWeight:600 , fontSize:'20px'}}>Transactions</Typography>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item width={'100%'} mt={2}  mb={2}>
                <Grid container width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <CustomSearchInput placeholder={'search'} name={'searchValue'} onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </Grid>
                    <Grid item>
                     <Exports exportData={exportData} activeTabs={'All Transactions'}  csvExport={csvExport} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'100%'} mt={2} mb={2}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={recentTransactionsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}