import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Checkbox, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import HttpComponent from "./MakeRequest";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';



const baseUrl = process.env.REACT_APP_BASE_URL;


const EcitizenSetUp = ({ setSuccessShow, setErrorShow, setShowEcitizenSetup }) => {
    const [formData, setFormData] = useState({
        ecitizenActivationStatus:true,
        ecitizenKey: '',
        ecitizenAccountId: '',
        ecitizenSecret: '',

    })

    const [showEcitizenSecret, setShowEcitizenSecret] = useState(false);
    const handleClickShowEcitizenSecret = () => setShowEcitizenSecret((show) => !show);
    const handleMouseDownEcitizenSecret = (event) => {
        event.preventDefault();
    }

    const handleFormData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [buttonState, setButtonState] = useState(false);
    const checkButtonState = () => {
        if (formData.ecitizenAccountId !== '' && formData.ecitizenKey !== '' && formData.ecitizenSecret !== '') {
            setButtonState(true)
        } else {
            setButtonState(false)
        }
    }

    useEffect(() => {
        checkButtonState()
    }, [formData])


    const activateEcitizen = async () => {
        HttpComponent({
            method: 'POST',
            url: `/api/v1/ecitizen/activate`,
            body: formData,
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            console.log("all payment status", data);
            if (data.status === 200) {
                setSuccessShow({ state: true, message: data.response.message })
                setShowEcitizenSetup(false)

            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }





    return (
        <>
            <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
                <div className="topHeader" style={{ display: "flex", marginTop: "15px", marginLeft: "16px" }}>
                    <Typography variant="h6" style={{ marginLeft: "10px", marginTop: "5px" }}>Ecitizen Setup</Typography>
                </div>
                <Box component="form" sx={{ '& .MuiTextField-root': { margin: "20px", paddingTop: "10px" }, }} noValidate autoComplete='off' >
                    <form>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}> Ecitizen Account ID</InputLabel>
                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                id="outlined-multiline-flexible"
                                placeholder="Ecitizen Account ID"
                                name="ecitizenAccountId"
                                value={formData.ecitizenAccountId}
                                onChange={handleFormData}
                                multiline
                                required
                            />
                        </FormControl>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}> Ecitizen Key </InputLabel>
                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                id="outlined-multiline-flexible"
                                placeholder="Ecitizen Key"
                                name="ecitizenKey"
                                value={formData.ecitizenKey}
                                onChange={handleFormData}
                                multiline
                                required
                            />
                        </FormControl>
                        <FormControl style={{ width: "100%", marginTop: '15px' }}>
                            {/* <InputLabel style={{ fontWeight: 'normal',marginTop: '15px', fontSize: '18px', marginLeft: '7px' }}> Ecitizen Secret </InputLabel> */}
                            <OutlinedInput
                                style={{ width: "95%", marginTop: "30px", marginLeft: '20px' }}
                                placeholder='Ecitizen Secret'
                                name="ecitizenSecret"
                                id="outlined-adornment-password"
                                type={showEcitizenSecret ? 'text' : 'password'}
                                value={formData.ecitizenSecret}
                                onChange={handleFormData}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowEcitizenSecret}
                                            onMouseDown={handleMouseDownEcitizenSecret}
                                            edge="end"
                                        >
                                            {showEcitizenSecret ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div style={{ display: "flex", justifyContent: "flex-end",  marginRight:"20px", marginTop:"10px", marginBottom:"10px"}}>
                            <Button variant="contained" style={{ width: "146px", height:"50px", marginRight: "20px", backgroundColor: "#fff", color: "#DC3545", border:"solid 1px #dc3545" }} onClick={() => setShowEcitizenSetup(false)}>Cancel</Button>
                            <Button disabled={!buttonState} variant="contained" style={{ width: "200px", height:"50px", backgroundColor: buttonState? "#032541" : "#9AA7B3", color: "#fff" }} onClick={activateEcitizen}>Activate</Button>
                        </div>


                    </form>



                </Box>
            </Box>
        </>


    )
}
export default EcitizenSetUp;
