import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { handleAdded } from "../../../../features/AlertSlice";
import axios from "axios";
import { handleTabChange } from "../../../../features/currentTabSlice";
import { makeStyles } from "@mui/styles";
import SearchableSelect from "../TerminalsAndPrinters/SearchableSelect";
import { handleBranches } from "../../../../features/selectedSlice";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function CreateOutlet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //value to set when user edits form
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  //updating the details object
  const [failMessage, setFailMessage] = useState(null);

  // let branchId = useSelector((state) => state.terminalSelect.branchId);
  const id = useSelector((state) => state.terminalSelect.businessId);
  const branches = useSelector((state) => state.terminalSelect.branches);

  let branchId = branches[0]?.branchId

  console.log("branchId .....", branchId)

  const fetchBranches = () => {
    try {
      axios
        .post(baseUrl + "/api/fetchBusinessBranches", {
          id: id,
        })
        .then((response) => {
          let data = JSON.stringify(response.data.data);
          let data2 = JSON.parse(data);
          dispatch(handleBranches(data2));
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) fetchBranches();
  }, [id]);

  const handleAdd = () => {
    try {
      axios
        .post(
          baseUrl + "/api/addStore",
          {
            storeName: name,
            description,
            businessNumber: id,
            branchId,
            storeType: "MainStore",
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.message;
          if (data.includes("Created Successfully")) {
            console.log("Outlet created successfully");
            dispatch(handleAdded(true));
            navigate("/Outlet");
          } else {
            setFailMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Create Outlet
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Outlets"}
        link1={"/Outlet"}
        point2={"Create Outlet"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Create Outlet
            </Typography>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              autoFocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDescription}
              className={classes.focusedTextField}
            />
            <SearchableSelect />
            {/* <SearchableSelect2 /> */}
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => {
                  dispatch(handleTabChange("Active"));
                  navigate("/Outlet");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default CreateOutlet;
