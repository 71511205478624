import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import TransferCategoryProducts from "./transferCategoryproducts";
import Category from "./category";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { stockStyles } from "../stockStyling";
import { useNavigate } from "react-router-dom";


const CategoryAndTableTransfer = ({ storeFromId,  storeToId }) => {

  const [categoryId, setCategoryId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [productValue, setProductValue] = useState("")

  const navigate = useNavigate();


  const moveToNext = () => {
    const stockTransferProducts = JSON.parse(localStorage.getItem("stockTransferProducts"));
    if(stockTransferProducts){
      navigate(`/transferStock/viewBatch?${storeFromId}?${storeToId}`)
    }
  }

  const clearStage = () => {
    localStorage.removeItem("stockTransferProducts");
    navigate(`/transferstock/transfer`);
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} margin={"0% 0% 1% 0%"}>
        <Grid item sx={{ width: "30%" }}>
          <SearchFilter placeholder={"Search categories"} setInputValue={setInputValue} />
        </Grid>
        <Grid item sx={{ width: "50%", display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        <SearchFilter  placeholder={"Search products"} setInputValue={setProductValue} />
          <Button
          onClick={clearStage}
            style={stockStyles?.cancelButton}
          >
            Cancel
          </Button>
          <Button style={stockStyles?.viewBatchButton} onClick={() => moveToNext()}>
            View Batch
          </Button>
        </Grid>
      </Grid>

      <Grid container width={"100%"} direction={"row"} spacing={2}>
        <Grid item xs={4}>
          <Category inputValue={inputValue} setCategoryId={setCategoryId} />
        </Grid>
        <Grid item xs={8}>
          <TransferCategoryProducts productValue={productValue} categoryId={categoryId} storeFromId={storeFromId}  />
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryAndTableTransfer;
