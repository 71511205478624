/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import { Breadcrumbs, Button, Grid, Typography, Box, Menu, MenuItem, Fade } from "@mui/material";
import React, { useEffect, useState } from "react";
import backImg from "../../../School/Images/back-icn.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from "../../../School/CustomSearchInput";
import { useSelector } from "react-redux";
import ListHouseTypes from "./components/listHouseTypes";
import ListHouseUnits from "./components/listHouseUnits";
import ListTenants from "./components/listTenants";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExportMenu from "../../../School/ExportMenu";
import HttpComponent from "../../../School/MakeRequest";

export default function HousingHistory() {
  try {
    // Template Render
    const [flagState, setFlagState] = useState("listHouses");
    const { X_Authorization } = useSelector((store) => store.user);

    const handleFlagState = (state) => {
      setFlagState(state);
    };
    const [details, setDetails] = useState({});

  
    const [unitIdP, setUnitIdP] = useState({});

    //handle details

    const handleDetails = (detail) => {
      setDetails(detail);
    };
  

    //update uinit id

    const handleUnitId = (unitid) => {
      setUnitIdP(unitid);
    };

    const [data, setData] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    // Search functionality
    const [dataToShow, setDataToShow] = useState([]);
    const [formData, setFormData] = useState({ search: "" });
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    

    const setSearchData = () => {
      let searchable = [...data];
      if (formData.search !== "") {
        let result = searchable.filter((invoice) => invoice.invoiceNumber?.toLowerCase()?.includes(formData.search) || invoice.invoiceNumber?.includes(formData.search) || invoice.sendToFirstName?.toLowerCase().includes(formData.search) || invoice.sendToFirstName.includes(formData.search));
        setDataToShow(result);
      } else {
        setDataToShow(data);
      }
    };

    //fetch unit types

    function fetchUnitTypes() {
      try {
        HttpComponent({
          method: "GET",
          url: `/api/get_unit_types_by_business_unit_config?limit=1000&page=1`,
          token: X_Authorization,
        })
          .then((data) => {
            console.log(data, "Avaibale housing units");
            if (data.status === 201) {
              setData(data.response.data);
            } else {
              console.log(`Error getting Units`);
            }
          })
          .catch((e) => {
            console.log(`Error getting transaction BreakDown`, e.message);
          });
      } catch (e) {
        console.log(`Error getting Units`, e.message);
      }
    }

    // useEffect to run on the
    useEffect(() => {
      fetchUnitTypes();
    }, []);

    console.log(data, "hey checking if data is on");
    // Search UseEffect
    useEffect(() => {
      setSearchData();
    }, [formData.search, data]);

    //generating pdfs and csvs

    let fileHeaders = [];
    let csvColumns = [];
    let fileDataPDF = {};
    let fileData = {};

    //generate pdfs and csvs

    const csvTenantsColumns = [
      { label: "Tenant Name", key: "Tenant Name" },
      { label: "Occupied On", key: "Occupied On" },
      { label: "Vacated On", key: "Vacated On" },
    ];
    const csvUnitsColumns = [
      { label: "House Number", key: "House Number" },
      { label: "Number of Tenants", key: "Number of Tenants" },
      { label: "Vacated On", key: "Vacated On" },
      { label: "Created On", key: "Created On" },
    ];
    const csvHouseHistoryColumns = [
      { label: "Unit Type", key: "Unit Type" },
      { label: "Units", key: "Units" },
      { label: "Amount", key: "Amount" },
    ];

    flagState === "listHouses" ? (csvColumns = csvHouseHistoryColumns) : flagState === "listHouseUnits" ? (csvColumns = csvUnitsColumns) : flagState === "listTenants" ? (csvColumns = csvTenantsColumns) : "";

    //filedatapdfs

    const fileDataHouseHistoryPDF = dataToShow.map((hsHistory) => [hsHistory?.unitType, hsHistory?.unitTypeCount, hsHistory?.unitCost]);
    const fileDataHouseUnitsPDF = dataToShow.map((unit) => [unit?.houseNumber, unit?.numberOfTenants, unit?.tenantsVacated, unit?.createdOn]);
    const fileDataHouseTenantsPDF = dataToShow.map((tenant) => [tenant?.tenantsName, tenant?.occupiedOn, tenant?.vacatedOn]);

    flagState === "listHouses" ? (fileDataPDF = fileDataHouseHistoryPDF) : flagState === "listHouseUnits" ? (fileDataPDF = fileDataHouseUnitsPDF) : flagState === "listTenants" ? (fileDataPDF = fileDataHouseTenantsPDF) : "";

    //filedata
    const fileDataHistory = dataToShow.map((hsHistory) => {
      return {
        "Unit Type": hsHistory?.unitType,
        "Units":hsHistory?.unitTypeCount,
        "Amount":hsHistory?.unitCost
      };
    });
    const fileDataUnits = dataToShow.map((unit) => {
    console.log(unit ,'heyy im units')
      return {
        "House Number":unit?.houseNumber,
        "Number of Tenants": unit?.numberOfTenants,
        "Vacated on": unit?.tenantsVacated,
        "Created on": unit?.createdOn,
      };
    });
    const fileDataTenants = dataToShow.map((tenant) => {
      return {
        "Tenant Name": tenant?.tenantsName,
        "Occupied On": tenant?.occupiedOn,
        "Vacated On": tenant?.vacatedOn,
      };
    });

    flagState === "listHouses" ? (fileData = fileDataHistory) : flagState === "listHouseUnits" ? (fileData = fileDataUnits) : flagState === "listTenants" ? (fileData = fileDataTenants) : "";

    //pdf headers
    const houseHistoryHeader = [["Unit Type", "Units", "Amount"]];
    const houseUnitsHeader = [["House Number", "NUmberofTenants", "Vacated", "Created On"]];
    const houseTenantHeader = [["Tenant Name", "Occupied On", "Vacated On"]];

    flagState === "listHouses" ? (fileHeaders = houseHistoryHeader) : flagState === "listHouseUnits" ? (fileHeaders = houseUnitsHeader) : flagState === "listTenants" ? (fileHeaders = houseTenantHeader) : "";
    // console.log(fileData, "heyy filedata");
    let renderComponent = (
      <ListHouseTypes
        setFlagState={setFlagState}
        listHouseUnits={() => {
          handleFlagState("listHouseUnits");
        }}
        data={data}
        setData={setData}
        dataToShow={dataToShow}
        setDataToShow={setDataToShow}
        handleDetails={handleDetails}
      />
    );

    flagState === "listHouseUnits" ? (renderComponent = <ListHouseUnits unitid={unitIdP} handleUnitId={handleUnitId} detail={details} setFlagState={setFlagState} listTenants={() => handleFlagState("listTenants")} dataToShow={dataToShow}  setDataToShow={setDataToShow}  data={data} setData={setData} />) : 
    flagState === "listTenants" ? (renderComponent = <ListTenants unitid={unitIdP} handleUnitId={handleUnitId} detail={details} setFlagState={setFlagState} listTenants={() => handleFlagState("listTenants")} dataToShow={dataToShow} setDataToShow={setDataToShow} data={data} />) : renderComponent;

    // Bread Crumbs
    let breadcrumbs = [
      <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
        Dashboard
      </Typography>,
      <Typography style={{ color: "#dc3545", fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.error">
        Housing History
      </Typography>,
    ];
    {
      flagState === "listHouseUnits"
        ? (breadcrumbs = [
            <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
              Dashboard
            </Typography>,
            <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
              House History
            </Typography>,
            <Typography style={{ color: "#dc3545", fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.error">
              Units
            </Typography>,
          ])
        : flagState === "listTenants"
        ? (breadcrumbs = [
            <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
              Dashboard
            </Typography>,
            <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
              House History
            </Typography>,
            <Typography style={{ color: "#dc3545", fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.error">
              Units
            </Typography>,
          ])
        : breadcrumbs;
    }

    return (
      <div>
        {/*Bread Crumbs*/}
        <Grid container justifyContent={"space-between"} mb={3}>
          {/*Header*/}
          <Grid item>
            {flagState === "listHouseUnits" ? (
              <>
                <img
                  alt=""
                  style={{ marginRight: "10px" }}
                  src={backImg}
                  onClick={() => {
                    handleFlagState("listHouses");
                  }}
                />
                <span style={{ color: "#032541", fontWeight: "bold", fontSize: "25px" }}>Units</span>
              </>
            ) : (
              <span style={{ color: "#032541", fontWeight: "bold", fontSize: "25px" }}>Housing History</span>
            )}
            <Grid item>
              <Breadcrumbs style={{ fontFamily: "Poppins", fontSize: "14px" }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>

        {/*Search and Export Container*/}
        <Grid container justifyContent={"space-between"} mt={4}>
          {/*SearchBox*/}
          <Grid item>
            <CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:" />
          </Grid>
          <Grid item>
            <Box>
              <Button id="fade-button" aria-controls={open ? "fade-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
                Filter <FilterListIcon />
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleClose}>Unit Type</MenuItem>
                <MenuItem onClick={handleClose}>Units</MenuItem>
                <MenuItem onClick={handleClose}>Amount</MenuItem>
              </Menu>
            </Box>
          </Grid>
          <Grid item>
            <ExportMenu csvColumns={csvColumns} fileData={fileData} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} title={flagState === 'listHouses' ? 'House types':flagState === 'listHouseUnits' ? 'House units' :flagState=== 'listTenants' ? 'Tenants':""} fileName={flagState === 'listHouses' ? 'House types':flagState === 'listHouseUnits' ? 'House units' :flagState=== 'listTenants' ? 'Tenants':""} />
          </Grid>
        </Grid>

        {renderComponent}
      </div>
    );
  } catch (e) {
    console.log(`Error on House History`, e.message);
  }
}
