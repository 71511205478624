import { Grid, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import CustomDataGrid from '../../../products/stockComponents/customDataGrid';
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import { DataGrid } from '@mui/x-data-grid';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Partners</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Partners Business</Typography>
]
export default function PartnerBusiness() {
    const partnerBusinesses = useSelector((state) => state.dropdownAction.allPartners);
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    useEffect(() => {
        setPageState({ ...pageState, data: partnerBusinesses, isLoading: false, count: partnerBusinesses?.length })
    }, [partnerBusinesses])

    console.log(pageState.data, 'partnerBusinesses >>>>')

    const column = [
        { headerName: "Business ID", field: 'businessId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Business ID</strong>) }, renderCell: (params) => <span>{params?.row?.businessId}</span> },
        { headerName: "Business Name ", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Business Name</strong>) }, renderCell: (params) => <span>{params?.row?.businessName}</span> },
    ]

    return (
        <Grid container direction={'column'}>
            <Grid item mt={2} display={'flex'} gap={2} flexDirection={'row'} alignItems={'center'}>
                <ArrowBackIosNewIcon sx={{ color: "#707070", "&:hover": { cursor: "pointer" }, }} onClick={() => { window.history.back() }} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Partners Business</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid mt={3} item width={'100%'}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={column}
                    rows={pageState?.data}
                    getRowId={row => row._id} />
            </Grid>
        </Grid>
    )
}