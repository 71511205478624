import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/configToUpdateSlice";
import { handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";


const baseUrl = process.env.REACT_APP_BASE_URL;

function EditGlobalConfig() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //pulling values from redux to set initial values of form inputs
  const reduxName = useSelector((state) => state.configDetails.name);
  const reduxDescription = useSelector(
    (state) => state.configDetails.description
  );
  const reduxValue = useSelector((state) => state.configDetails.value);
  const reduxCode = useSelector((state) => state.configDetails.code);

  //value to set when user edits form
  const [name, setName] = useState(reduxName);
  const [description, setDescription] = useState(reduxDescription);
  const [value, setValue] = useState(reduxValue);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleValue = (e) => {
    setValue(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleUpdate = () => {
    try {
      axios
        .post(baseUrl + "/api/editConfiguration", {
          code: reduxCode,
          name,
          description,
          value,
        })
        .then((response) => {
          let data = response.data.data
          if (data) {
            console.log("updated successfully");
            setLocalDetails({
              code: reduxCode,
              name,
              description,
              value,
            });
          } else {
            setFailMessage(response.data.message)
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/GlobalConfigurations");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
       <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Edit Configuration
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Terminals & Printers"}
        link1={"/TerminalsAndPrinters"}
        point2={"Edit Terminal"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Edit Configuration Details
            </Typography>
            <TextField
              label="Code"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxCode}
              InputLabelProps={{
                shrink: true,
              }}
              // onChange={handleSerialNo}
              autoFocus
              disabled
            />
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDescription}
            />
            <TextField
              label="Value"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxValue}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleValue}
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/GlobalConfigurations")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EditGlobalConfig;
