import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper
} from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import jwt_decode from "jwt-decode";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { handleBusinessId } from "../../../../features/selectedSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket
        Money</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Terminals</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create Terminal</Typography>
]

export default function CreateNewTerminal() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [outlets, setOutlets] = useState([])
    const [deviceModels, setDeviceModels] = useState([])
    const [searchInputValue, setSearchInputValue] = useState('')
    const [availableBusiness, setAvailablebusiness] = useState([])
    const [businessNumber, setBusinessNumber] = useState(null)
    const [businessName, setBusinessName] = useState(null)
    const userGroup = localStorage.getItem('group')

    const [formData, setFormData] = useState({ serialNo: "", simCardNo: "", deviceModel: "", outletName: "" })

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const token = localStorage.getItem('X-Authorization')
    const decoded = jwt_decode(token);
    const branchId = decoded?.branchId

    const getBusinesses = () => {
        HttpComponent({
            method: "GET",
            url: `/api/search_businesses?searchValue=${searchInputValue}`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        })
            .then((data) => {
                if (data.status === 200) {
                    setAvailablebusiness(data?.response?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getBusinesses();
    }, [searchInputValue]);

    //fetch outlets
    function getOutlets() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/list_all_stores_by_type_status?storeType=MainStore&status=ACTIVE&page&limit=`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setOutlets(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOutlets()
    }, [])

    // get buisnes for that partner'
    const [businessFound, setBusinessFound] = useState([])
    const [selectedBusinessnumber, setSelectedbnssNumber] = useState('')
    const [options, setOptions] = useState([])
    console.log(businessFound, 'businessFound')

    const fetchBusinessData = () => {
        try {
            axios
                .get(baseUrl + "/api/fetchBusinessNoAndName", {
                    headers: {
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                })
                .then((response) => {
                    setBusinessFound(response.data.data);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchBusinessData();
    }, []);


    const createArray = () => {
        if (businessFound) {
            const uniqueStrings = new Set(); // Use a Set to store unique strings
            businessFound?.forEach((obj) => {
                let string = `${obj.businessNumber} - ${obj.businessName}`;
                uniqueStrings.add(string); // Add each string to the Set
            });
            setOptions(Array.from(uniqueStrings)); // Convert Set back to an array and set options
        }
    };

    useEffect(() => {
        if (businessFound?.length > 0) createArray();
    }, [businessFound]);

    const [newBusinessNumber, setNewBusinessNumber] = useState('')

    useEffect(() => {
        if (selectedBusinessnumber) {
            const regex = /(\d+) -/;
            let match = selectedBusinessnumber.match(regex);
            if (match) {
                const extractedNumber = match[1];
                setNewBusinessNumber(extractedNumber)
                // props.handleId(extractedNumber);
                //dispatch(handleBusinessId(extractedNumber))
            }
        }
    }, [selectedBusinessnumber]);

    //fetch outlets
    function getDeviceModel() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getBusinessModels`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setDeviceModels(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDeviceModel()
    }, [])


    // formdata to submit

    const formDataAdmin = {
        terminalSerialNumber: formData.serialNo,
        till_paybill: "",
        deviceModel: formData.deviceModel,
        simCardNumber: formData.simCardNo,
        businessNumber: userGroup === 'Partner' ? newBusinessNumber : businessNumber,
        createdBy: localStorage.getItem('username'),
        terminalStatus: userGroup === 'Admin' ? 'New' : userGroup === 'Partner' ? 'Active' : '',
        payIt: true,
    }


    const submitData = {
        terminalSerialNumber: formData.serialNo,
        deviceModel: formData.deviceModel,
        simCardNumber: formData.simCardNo,
        storeId: formData.outletName,
        assignedTo: "outlet",
        branchId: branchId,
        till_paybill: "",
        businessNumber: businessNumber
    }

    //navigate user back after creating terminal

    const navigate = useNavigate()

    //create terminal

    function handleSubmit() {
        try {
            HttpComponent({
                method: "POST",
                body: formDataAdmin,
                url: `/api/createTerminal`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data.status === 201) {
                    setSuccessShow({ state: true, message: "Terminal created successfully" })
                    setTimeout(() => {
                        navigate(-1)
                    }, [2000])
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
                setTimeout(() => {
                    //navigate(-1)
                }, [2000])
            })
        } catch (error) {

        }

    }

    // console.log(selectedBusinessnumber.businessNumber ,'selectedBusinessnumber')

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Create Terminal</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb"
                    separator={<FiberManualRecordIcon
                        style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Terminal Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='serialNo' onChange={handleOnChange} style={{ width: "100%" }}
                            value={formData.serialNo} label="Serial No" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='simCardNo' onChange={handleOnChange}
                            style={{ width: "100%" }} value={formData.simCardNo} label="Sim Card No"
                            variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "100%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Device Model</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData?.deviceModel}
                                label="Device Model"
                                onChange={handleOnChange}
                                name="deviceModel"
                            >
                                {deviceModels?.map((item) => (
                                    <MenuItem name="outlet" key={item?._id}
                                        value={item?.deviceName}>{item?.deviceName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {userGroup === 'Partner' ?
                    <Grid item mt={3} style={{ width: '98%' }}>
                        <Autocomplete
                            label="Business Id"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            value={selectedBusinessnumber}
                            onChange={(event, newValue) => {
                                setSelectedbnssNumber(newValue);
                            }}
                            options={options}
                            renderInput={(params) => (
                                <TextField {...params} label="business id" variant="outlined" />
                            )}
                        />
                    </Grid> : <>

                        {businessNumber ? <Grid item mt={2} style={{ width: "98%" }}>
                            <TextField id="outlined-basic" style={{ width: "100%" }} value={businessName}
                                label="Business Name" variant="outlined" />
                        </Grid> : <Grid my={2} item width={'98%'}>
                            <SearchFilter placeholder={'search business here'} setInputValue={setSearchInputValue} />
                        </Grid>}
                        {!businessNumber &&
                            <Grid item width={'50%'}>
                                {availableBusiness?.map((business) => {
                                    return (
                                        <Grid item onClick={() => {
                                            setBusinessNumber(business?.businessNumber);
                                            setBusinessName(business?.businessName)
                                        }} key={business._id} width={"100%"} height={'50px'} style={{ cursor: "pointer" }}>
                                            <Paper sx={{ my: 4, px: 2, py: 1 }}>
                                                <Typography>{business?.businessName}</Typography>
                                                <Typography>{business?.country}</Typography>
                                            </Paper>
                                        </Grid>
                                    )
                                })}
                            </Grid>}</>}
            </Grid>
            <Grid mt={3} spacing={2} item style={{ width: "49%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{
                    background: "#fff",
                    color: "#DC3545",
                    border: "1px solid #DC3545",
                    width: "125px",
                    marginRight: "5px",
                    textTransform: "inherit"
                }}>Cancel</Button>
                <Button onClick={handleSubmit}
                    style={!formData.serialNo || !formData.deviceModel || !formData.simCardNo ? {
                        opacity: 0.4,
                        width: "125px",
                        background: "#032541",
                        textTransform: "inherit",
                    } : {
                        background: "#032541",
                        textTransform: "inherit",
                        width: "125px",
                        color: "#fff",
                        opacity: 1
                    }}>Submit</Button>
            </Grid>
        </Grid>
    )
}