import React, { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "./styles";
import { useNavigate } from "react-router-dom";
// import 
const Outgoingreceipts = () => {
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [customerId, setCustomerId] = useState("");
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  

  const getOutgoingReceipts = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_business_receipts?limit=${dataGridPageSize}&page=${pageState.page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 201) {

          console.log(data.response.data);
          setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
        }

      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getOutgoingReceipts();
  }, [pageState.pageSize, pageState.page, dataGridPageSize]);

  // navigate;
  // /invoice/INV_426
//   useEffect(() => {
//     if (customerId) navigate(`/paymentreceipt/${customerId}`);
//   }, [customerId]);

  return (
    <div>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data?.map((receipt) => ({
          id: receipt?.transactionId,
          receiptNo: receipt?.receiptNo,  
          issuedDate: new Date(receipt?.issuedDate).toDateString(),
          from: receipt?.from,
          amount: receipt?.amount,
          invoiceNumber:receipt?.invoiceNumber,
          transactionType:receipt?.transactionType
        }))}
        columns={[
          {
            field: "receiptNo",
            headerName: "Receipt No",
            flex: 1,
            renderCell: (params) => {
              return (
                <span style={{ color: "red", cursor: "pointer" }} onClick={() =>  navigate(`/paymentreceipt/${params.row.invoiceNumber}/coa`)}>
                  {params.row.receiptNo}
                </span>
              );
            },
          },
          { field: "from", headerName: "Paid By", flex: 1 },
          { field: "issuedDate", headerName: "Date & Time", flex: 1 },
          { field: "amount", headerName: "Invoice Amount", flex: 1 },
          { field: "transactionType", headerName: "Payment Type", flex: 1 },

        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState?.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />
    </div>
  );
};

export default Outgoingreceipts;
