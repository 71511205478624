import React, { useState, useEffect } from "react";
const baseUrl = process.env.REACT_APP_BASE_URL;

 const CreditExpiry = (url) => {

    const [returnData, setData] = useState()
  const getCreditExp = async () => {
    try {
      const expResp = await fetch(baseUrl + url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      const expData = await expResp.json();
      setData(expData)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCreditExp();
  }, [url]);


  return { returnData }
}

export default CreditExpiry