import { Button, Grid, InputLabel, Stack, TextField, Typography } from "@mui/material"
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";

const EditRegionPartner = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ branchName: '', branchCode: '' , regionId:'' })
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue , regionId] = state.split("?")
    const navigate = useNavigate()

    // handle formdata

    function handleFormdata(e) {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }

    // get the region to update the details

    function getRegionDetails(){
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/region-to-toupdate?regionId=${regionId}`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data)=>{
                if(data?.status === 200){
                    setFormdata({
                        ...formData,
                        regionName:data?.response?.data?.regionName,
                        regionDescription:data?.response?.data?.regionDescription
                    })
                }
            })
        }catch(error){
            setErrorShow({ state: true, message: error?.message })
        }
    }
    useEffect(()=>{
        getRegionDetails()
    },[regionId])

    function handleEditRegion(){
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/partnerUpdateRegion?regionId=${regionId}`,
                token: localStorage.getItem('X-Authorization'),
                body:{
                    regionName:formData.regionName,
                    regionDescription:formData.regionDescription
                }
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({ state: true, message: "Region Updated Successfully" })
                    setTimeout(() => {
                        navigate(-1)
                    },1500)
                }else{
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
            
        } catch (error) {
            setErrorShow({ state: true, message: error?.message }) 
        }

    }
    return (
        <Grid container direction={'column'}>
              <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'} >
                <Stack alignItems='center' direction='row' spacing={1}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Edit Region</Typography>
                </Stack>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumb name={'Edit Region'} />
            </Grid>
            <Grid item width={'50%'} gap={1} mt={2}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Region Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.regionName} name='regionName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item width={'50%'} mt={1} gap={1}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Region Description</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.regionDescription} name='regionDescription' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => handleEditRegion()} disabled={!formData.regionName || !formData.regionDescription} style={!formData.regionName || !formData.regionDescription ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}

export { EditRegionPartner }
