import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    schoolTypeDetail: {},

};

const schoolTypeSlice = createSlice({
    name: 'schoolTypeName',
    initialState,
    reducers: {
        handleSchoolTypeName: (state, action) => {
            //const { schoolTypeName } = action.payload;
            console.log("We are adding school type in redux", action.payload)

            state.schoolTypeDetail = action.payload
        },
        removeSchoolTypeName: (state, action) => {

            state.schoolTypeDetail = {}
        }
    }
});


export const { handleSchoolTypeName, removeSchoolTypeName } = schoolTypeSlice.actions;
export default schoolTypeSlice.reducer
