import {customerstyles} from "../customerComponents/styles";
import {Breadcrumbs, Grid, Typography, FormControl, OutlinedInput, InputLabel, Button} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, {useEffect, useState} from "react";
import SaveSupplierLoader from "./SaveSupplierLoader";
import HttpComponent from "../../School/MakeRequest";
import { useParams} from 'react-router-dom';
import {showToast} from "./Toast";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [
    <Typography style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Dashboard</Typography>,
    <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Suppliers</Typography>,
    <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Edit Supplier</Typography>
]

const EditSupplier = () => {

    const {id} = useParams();
    const[supplierName, setSupplierName] = useState('');
    const[phoneNumber, setPhoneNumber] = useState('');
    const[supplierEmail, setSupplierEmail] = useState('');
    const[supplierAddress, setSupplierAddress] = useState('');
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    const navigate = useNavigate();

    // FETCH ALL SUPPLIER BY ID
    const getSupplierById = async () =>{
        HttpComponent({
            method: "GET",
            url: `/api/get_store_details?storeType=suppliers&storeId=${id}`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
            if (data.status === 200) {
                const supplier = data.response.data[0];
                console.log(supplier);
                setSupplierName(supplier.suppliers.name)
                setPhoneNumber(supplier.suppliers.phone)
                setSupplierEmail(supplier.suppliers.email)
                setSupplierAddress(supplier.suppliers.location)
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    // get suppliers based on tab value
    useEffect(() =>{
        getSupplierById();
    },[id])


    // SAVE EDITED SUPPLIER
    const editSupplier = async (e) =>{
        e.preventDefault();
        setLoading(true);
        const body = { name:supplierName, phone:phoneNumber, email:supplierEmail, location:supplierAddress };
        HttpComponent({
            method: "POST",
            url: `/api/v1/supplier/edit/${id}`,
            body,
            token: localStorage.getItem("X-Authorization"),
        }).then((res) =>{
            console.log('edited response here',res);
            if(res.response.status === "SUCCESS"){
                showToast( `${res.response.message}`, 'success');
                setLoading(false);
                navigate('/supplierlist');
            }else{
                showToast( `${res.response.error}`, 'error');
                setLoading(false);
            }
        }).catch((error) =>{
            setError(error);
        })
    }

    return(
        <div style={customerstyles.universalStyle}>
            <Grid container direction={"column"}>
                <Grid item>
                    <Typography fontFamily={'Poppins'} style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Customer Accounts</Typography>
                </Grid>
                <Grid item mt={1}>
                    <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={"column"} style={{marginTop: '48px'}}>
                <Grid item>
                    <Typography fontFamily={'Poppins'}
                                style={{color: "#707070", fontSize: "15px", fontWeight: 300}}>Suppliers</Typography>
                </Grid>
                <form onSubmit={editSupplier}>
                    <Grid mt={2} item mb={3} xs={12} sm={12} lg={12} md={12} width={'50%'}>
                        <FormControl variant="outlined" style={{width: "100%"}}>
                            <InputLabel htmlFor="supplier-name">Supplier Name</InputLabel>
                            <OutlinedInput
                                required
                                id="supplier-name"
                                autoComplete="supplier-name"
                                label="Supplier Name"
                                value={supplierName}
                                onChange={(e) => setSupplierName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid mt={2} item mb={3} xs={12} sm={12} lg={12} md={12} width={'50%'}>
                        <FormControl variant="outlined" style={{width: "100%"}}>
                            <InputLabel htmlFor="phone-number">Phone No</InputLabel>
                            <OutlinedInput
                                required
                                id="phone-number"
                                autoComplete="phone-number"
                                label="Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid mt={2} item mb={3} xs={12} sm={12} lg={12} md={12} width={'50%'}>
                        <FormControl variant="outlined" style={{width: "100%"}}>
                            <InputLabel htmlFor="supplier-email">Email</InputLabel>
                            <OutlinedInput
                                required
                                id="supplier-email"
                                autoComplete="supplier-email"
                                label="Email"
                                value={supplierEmail}
                                onChange={(e) => setSupplierEmail(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid mt={2} item mb={3} xs={12} sm={12} lg={12} md={12} width={'50%'}>
                        <FormControl variant="outlined" style={{width: "100%"}}>
                            <InputLabel htmlFor="supplier-address">Address</InputLabel>
                            <OutlinedInput
                                required
                                id="supplier-address"
                                autoComplete="supplier-address"
                                label="Address"
                                value={supplierAddress}
                                onChange={(e) => setSupplierAddress(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button type={'submit'} style={{
                            border: "1px solid #032541",
                            background: '#032541',
                            height: "45px",
                            width: "145px",
                            textTransform: "inherit",
                            color: '#fff'
                        }}>
                            {loading ? <SaveSupplierLoader/> : 'Save'}
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </div>
    )
}

export default EditSupplier;