import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import HttpComponent from "../../../School/MakeRequest";
import AuthecateUser from "../../../paymentmodule/autheticatedComponent";

const SettlementPreview = () => {
  const navigate = useNavigate();

  const queryParams = decodeURIComponent(window.location.search)?.split("?");
  const [nillvalue, businessNo, totalAmount, txnFees, businessName] = queryParams;

  const settleMerchantTransaction = () => {
    if (!businessNo) {
      setOpenError({ ...openerror, state: true, message: "Businessnumber is required!" });
      return;
    }
    HttpComponent({
      method: `POST`,
      url: `/api/v1/manualsettlement`,
      token: localStorage.getItem("X-Authorization"),
      body: { businessId: businessNo },
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setOpenSuccess({ ...opensucess, state: true, message: "Configurations set succesfully!" });
        setTimeout(() => {
          closeSuccess();
          setShowAuthentication(false);
          navigate(-1);
        }, 3000);
      } else {
        setOpenError({ ...openerror, state: true, message: data.response?.message });
        setTimeout(() => {
          setShowAuthentication(false);
          closeError();
          navigate(-1);
        }, 3000);
      }
    });
  };

  //   handle modal;
  const [opensucess, setOpenSuccess] = useState({ message: "", state: false });
  const [openerror, setOpenError] = useState({ message: "", state: false });

  const closeSuccess = () => {
    setOpenSuccess(false);
  };

  const closeError = () => {
    setOpenError(false);
  };

  // authentication;
  const [isAuthecated, setIsAuthecated] = useState(false);
  const handleIsAuthecated = (childData) => {
    let state = childData.state;
    setIsAuthecated(state);
  };

  const [showAuthentication, setShowAuthentication] = useState(false);

  const manageAuthAndPayment = () => {
    setShowAuthentication(true);
  };

  useEffect(() => {
    if (isAuthecated) {
      settleMerchantTransaction();
    }
  }, [isAuthecated]);
  return (
    <div>
      <ErrorAlert vertical={"top"} horizontal={"right"} onClose={closeError} open={openerror.state} message={openerror.message} />
      <SuccessAlert vertical={"top"} horizontal={"right"} onClose={closeSuccess} open={opensucess.state} message={opensucess.message} />
      {!showAuthentication ? (
        <>
          {" "}
          <AuthecateUser handleIsAuthecated={handleIsAuthecated} />{" "}
        </>
      ) : (
        <>
          {" "}
          <Grid container direction={"column"} sx={{ padding: "20px 0px 0px 30px" }}>
            <Grid item mb={3}>
              <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }} mb={2}>
                <ArrowBackIos onClick={() => navigate(-1)} sx={{ cursor: "pointer" }} />
                <Typography sx={{ color: "#032541", fontSize: "25px", fontWeight: 600 }} variant="h5">
                  Preview Merchant Report
                </Typography>
              </Box>

              <Box mb={1}>
                <Breadcrumb name={"Preview Report"} />
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{ width: "30%" }}>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ color: "#000000", fontWeight: 600, fontSize: "20px" }}>Merchant Details</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={3}>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>Business ID: </Typography>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>{businessNo}</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={3}>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>Business Name: </Typography>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>{businessName}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{ width: "30%" }}>
                <Box mb={2}>
                  <Typography sx={{ color: "#000000", fontWeight: 600, fontSize: "18px" }}>Transaction Details</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={3}>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>TransAmount</Typography>
                  <Typography>{totalAmount}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={3}>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>Transaction Fee:</Typography>
                  <Typography>{txnFees}</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={4}>
                  <Typography sx={{ color: "#707070", fontSize: "18px" }}>Final Amount</Typography>
                  <Typography>{totalAmount}</Typography>
                </Box>

                <Box>
                  <Button onClick={() => (businessNo ? manageAuthAndPayment() : null)} style={{ backgroundColor: "#032541", color: "white", padding: "5px 25px 5px 25px" }}>
                    Settle
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default SettlementPreview;
