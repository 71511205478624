import React, { useEffect, useMemo, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import Breadcrumbcomponent from "../../components/products/stockComponents/breadcrumb";
import CustomSearchInput from "../../components/School/CustomSearchInput";
import CustomSelectField from "../../components/School/CustomSelectField";
import jsondecode from "jwt-decode";
import { CustomizedDate, CustomizedDateTimepicker } from "../../components/customerAccounts/customerComponents/customDate";
import { Exports } from "../../components/customerAccounts/customerComponents/exports";
import CustomDataGrid from "../../components/products/stockComponents/customDataGrid";
import HttpComponent from "../../components/School/MakeRequest";



const SchoolBioSalesReport = () => {
    const breadcrumbs = [
        <span style={{ color: "#707070", fontSize: "18px" }} key={1}>
            Dashboard
        </span>,
        <span style={{ color: "#dc3545", fontSize: "18px" }}>Bio Sales Report</span>,
    ];

    let localCurrency = localStorage.getItem('localCurrency')
    const localToken = localStorage.getItem("X-Authorization");
    const decoded = jsondecode(localToken)
    if (!localCurrency) {
        localCurrency = decoded?.localCurrency
    }
    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }

    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);
    const [salesSummary, setSalesSummary] = useState()
    const [soldQty, setSoldQty] = useState()
    const [totalGross, settotalGross] = useState()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [searchValue, setSearchValue] = useState('')


    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    const [handleDaysChange, setHandleDaysChange] = useState("Last 7 Days");

    useEffect(() => {
        const Last7Days = () => {
            var result = [];
            for (var i = 0; i < 7; i++) {
                var d = new Date();
                result.push(d.setDate(d.getDate() - i));
            }
            const lastSevenFirst = new Date(result[0]);
            const lastSevenLast = new Date(result[result?.length - 1]);
            setStartDate(lastSevenLast);
            setEndDate(lastSevenFirst);
        };
    
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1));
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0, 0));
                const lastDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth() + 1, 0, 23, 59, 59, 999));
                setStartDate(firstDay);
                setEndDate(lastDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth() - 1, 1, 0, 0, 0, 0));
                const lastDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), 0, 23, 59, 59, 999));
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Custom Range":
                setStartDate(new Date(dateFrom));
                setEndDate(new Date(dateTo));
                break;
            default:
                Last7Days();
        }
    }, [handleDaysChange, dateFrom, dateTo]);


    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setcsvExport] = useState({ headers: [], data: [] })


    //DataGrid Pagination
    const [dataGridPageSize, setPageSize] = React.useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


    // fetch bio sales

    function fetchBioSalesReport() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/sales/get_product_gross_margin_bio_sales?startDate=${startDate}&endDate=${endDate}&page=${pageState.page}&limit=${dataGridPageSize}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                    setSalesSummary(data?.response?.salesTotal)


                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchBioSalesReport()
    }, [pageState.page, dataGridPageSize, startDate, endDate,])

    const columns = [
        { field: "productName", headerName: "Product Name", flex: 1 },
        { field: "sellingPrice", headerName: "Selling Price", flex: 1 },
        { field: 'buyingPrice', headerName: 'Buying Price', flex: 1 },
        { field: 'qtySold', headerName: 'Qty Sold', flex: 1 },
        { field: 'totalSales', headerName: 'Total Sales', flex: 1 },
        // { field: 'totalCost', headerName: 'Total Cost', flex: 1 },
        // { field: 'grossMargin', headerName: 'Gross Margin', flex: 1 },
    ]

    const transRows = pageState?.data?.map((trans) => ({
        id: trans?._id,
        productName: trans?.productName,
        sellingPrice: trans?.sellingPrice,
        buyingPrice: trans?.buyingPrice,
        qtySold: trans?.quantitySold,
        totalSales: trans?.totalSales,
        // totalCost: trans?.totalCost,
        // grossMargin: trans?.grossMargin,

    }))

    useEffect(() => {
        // CSV Export Format
        const csvColumns = [
            { label: "Product Name", key: "productName" },
            { label: "Selling Price", key: "sellingPrice" },
            { label: "Buying Price", key: "buyingPrice" },
            { label: "Upload Time", key: "qtySold" },
            { label: "Total Sales", key: "totalSales" },
            { label: "Total Cost", key: "totalCost" },
            { label: "Gross Margint", key: "grossMargin" },
        ];

        const csvData = pageState?.data?.map((data) => {
            return {
                "productName": data?.productName,
                "sellingPrice": data?.sellingPrice,
                "buyingPrice": data?.buyingPrice,
                "qtySold": data?.quantitySold,
                "totalSales": data?.totalSales,
                "totalCost": data?.totalCost,
                "grossMargin": data?.grossMargin
            }
        })

        const fileDataPDF = pageState?.data?.map((data) => [data?.productName, numberFormat(data?.sellingPrice), numberFormat(data?.buyingPrice), data?.quantitySold, data?.totalSales, numberFormat(data?.totalCost), data?.grossMargin]);

        //PDF export Format
        const headers = [
            [
                "productName",
                "sellingPrice",
                'buyingPrice',
                'qtySold',
                'totalSales',
                'totalCost',
                'grossMargin',
            ],
        ];

        setExportData({ headers: headers, data: fileDataPDF });
        setcsvExport({ headers: csvColumns, data: csvData });

    }, [pageState?.data, pageState?.page, searchValue, startDate, endDate,])

    return (
        <div style={{ fontFamily: "Poppins" }}>
            <Grid container margin={"0px 0px 2% 0px"} width={'100%'}>
                <span style={{ color: "#032541", fontWeight: "bold", fontSize: "25px" }}> Bio Sales Report </span>
            </Grid>
            <Grid container margin={"0px 0px 2% 0px"} width={'100%'}>
                <Breadcrumbcomponent breadcrumbs={breadcrumbs} />
            </Grid>
            <Grid container gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Grid item>
                    <CustomSearchInput sm placeholder={'Search'} value={searchValue} name={'searchValue'} onChange={(e) => setSearchValue(e.target.value)} />
                </Grid>
                <Grid item>
                    {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}

                    {handleDaysChange === "Custom Range" && <CustomizedDateTimepicker setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", marginTop: '-5px', alignItems: "center" }} />}
                </Grid>
                <Grid item>
                    <Exports exportData={exportData} activeTabs={`Bio Sales Report`} csvExport={csvExport} />
                </Grid>
            </Grid>
            <Grid item mt={2} direction={'column'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={columns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={transRows} />
                <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Typography><strong>Totals</strong></Typography>
                    </Box>
                    <Grid container style={{
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'space-between',
                        marginTop: '30px'
                    }}>
                        <Grid item><strong>{soldQty}</strong></Grid>
                        <Grid item><strong>{salesSummary ? numberFormat(salesSummary) : 0}</strong></Grid>
                        {/* <Grid item><strong>{ soldQty? soldQty : 0}</strong></Grid>
                        <Grid item><strong>{totalGross ? numberFormat(totalGross) : 0}</strong></Grid> */}
                    </Grid>
                </Box>
            </Grid>
        </div>
    );
};

export default SchoolBioSalesReport;
