import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clickedItem: "",
  searchBy: "name",     // used in all transactions
  searchBy2: "username",  //used in terminal users
  searchBy3: "outletName", // used in outlets
  searchBy4: "serialNo", // used in terminals 'Active'
  searchBy5: "serialNo", // used in terminals 'Unassigned'
  searchBy6: "serialNo", // used in terminals 'Inactive'
  searchBy7: "businessName", // used in Businesses 'Active'
  model: "all models",
  module: "all modules",
  allPartners:'',
  toExport: ''
}


const actionSlice = createSlice({
  name:"dropdownAction",
  initialState,
  reducers:{
    handleItemClick:(state, action) => {
      state.clickedItem = action.payload
    },
    removeClicked: (state, action) => {
      state.clickedItem = ""
    },
    handleModel:(state, action) => {
      state.model = action.payload
    },
    removeModel: (state, action) => {
      state.model = "all models"
    },
    handleModule:(state, action) => {
      state.module = action.payload
    },
    removeModule: (state, action) => {
      state.module = "all modules"
    },
    handleSearchBy:(state, action) => {
      state.searchBy = action.payload
    },
    removeSearchBy: (state, action) => {
      state.searchBy = ""
    },
    handleSearchBy2:(state, action) => {
      state.searchBy2 = action.payload
    },
    removeSearchBy2: (state, action) => {
      state.searchBy2 = ""
    },
    handleSearchBy3:(state, action) => {
      state.searchBy3 = action.payload
    },
    removeSearchBy3: (state, action) => {
      state.searchBy3 = ""
    },
    handleSearchBy4:(state, action) => {
      state.searchBy4 = action.payload
    },
    removeSearchBy4: (state, action) => {
      state.searchBy4 = ""
    },
    handleSearchBy5:(state, action) => {
      state.searchBy5 = action.payload
    },
    removeSearchBy5: (state, action) => {
      state.searchBy5 = ""
    },
    handleSearchBy6:(state, action) => {
      state.searchBy6 = action.payload
    },
    removeSearchBy6: (state, action) => {
      state.searchBy6 = ""
    },
    handleSearchBy7:(state, action) => {
      state.searchBy7 = action.payload
    },
    removeSearchBy7: (state, action) => {
      state.searchBy7 = ""
    },
    handleToExport: (state, action) => {
      state.toExport = action.payload
    },
    removeToExport: (state, action) => {
      state.toExport = ''
    },
    handleGetPartnerBs:(state , action) =>{
      console.log(action.payload , 'firedddd')
      state.allPartners = action.payload
    }
  }
})

export const {  handleSearchBy7, removeSearchBy7, handleSearchBy6, removeSearchBy6, handleSearchBy5, removeSearchBy5, handleSearchBy4, removeSearchBy4, handleSearchBy3, removeSearchBy3, handleSearchBy2, removeSearchBy2, handleItemClick, removeClicked, handleGetPartnerBs , handleSearchBy,removeSearchBy, handleModel , removeModel, handleModule, removeModule, handleToExport, removeToExport,  } = actionSlice.actions;

export default actionSlice.reducer