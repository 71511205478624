import React, { useState, useEffect } from "react";
import { Edit, Delete } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Button } from "@mui/material";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import HttpComponent from "../../School/MakeRequest";
import { warehousestyles } from "../styles";
import { Suspendsuppliermodal } from "../modals";
import { useNavigate } from "react-router-dom";

const Activewarehouses = (props) => {

  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const getActiveWarehouses = () => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=Warehouse&status=ACTIVE&page=${pageState.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, total: 0 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getActiveWarehouses();
  }, [pageState.page, dataGridPageSize]);

  // suspendwarehouse;
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [openSuspendModal, setSuspendModal] = useState(false);
  const [suspendWareHouseName, setSuspendWarehouseName] = useState("");
  const [suspendWareHouseId, setSuspendWarehouseId] = useState("");
  const [warehousesToBeSuspended, setWarehousesToBeSuspended] = useState([]);
  const getWarehouseDetails = (warehousedetails) => {
    const { wareHouseName, warehouseId } = warehousedetails;
    if (wareHouseName) {
      setSuspendModal(true);
      setSuspendWarehouseName(wareHouseName);
      setSuspendWarehouseId(warehouseId);
    }
  };

  const suspendMultiplyWarehouses = () => {
    if (warehousesToBeSuspended.length > 1) {
      setSuspendModal(true);
    }
  };

  const suspendWareHouse = () => {
    HttpComponent({
      method: "POST",
      url: `/api/deactivate_or_activate_stores?action=inactive`,
      body: { storeIds: suspendWareHouseId ? [suspendWareHouseId] : warehousesToBeSuspended },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 202) {
          setSuspendModal(false);
          setSucess({ state: true, message: "Warehouse successfully suspended!" });
          getActiveWarehouses();
        }

        setTimeout(() => {
          setSucess({ state: false });
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchWarehouse = () => {
    if(props?.searchValue)
    HttpComponent({
      method:'GET',
      url:`/api/v1/searchByStoreType?page=${pageState?.page}&limit=${dataGridPageSize}&status=ACTIVE&storeType=Warehouse&searchValue=${props?.searchValue}`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      if (data.status === 200) {
  
        setPageState({ ...pageState, isLoading: false, data: data.response.data, total: 0 });
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(() => {

    if(!props?.searchValue)getActiveWarehouses()
    searchWarehouse();
  }, [props?.searchValue])

  return (
    <div>
      <DataGrid
        style={warehousestyles.dataGridStyling}
        rows={pageState?.data?.map((supplier) => ({
          id: supplier?._id,
          username: supplier?.name,
          phone: supplier?.phone,
          email: supplier?.email,
          location: supplier?.location,
          storesusersCount:supplier?.storesusersCount
        }))}
        columns={[
          { field: "username", headerName: "Name", flex: 1 },
          { field: "location", headerName: "Location", flex: 1 },
          {field:"storesusersCount", headerName: "Store Users", flex: 1},
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {
              return (
                <>
                  <div style={warehousestyles.activesupplierActions}>
                    {/* <img onClick={() => getsupplierdetails({ userId: params.row.id, supplierName: params.row.username })} src={suspendcustomer} alt="" /> */}
                    <Delete color="error" onClick={() => getWarehouseDetails({ warehouseId: params.row.id, wareHouseName: params.row.username })} />
                    <Edit onClick={() => navigate(`/addwarehouse/${params.row.id}`)} />
                  </div>
                </>
              );
            },
            flex: 1,
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableRowSelectionOnClick={true}
        onSelectionModelChange={(ids) => {
          setWarehousesToBeSuspended(ids);
        }}
      />

      <Grid container width={"100%"}>
        <Grid item width={"100%"}>
          <div style={warehousestyles.suspendBtnRightAligned}>
            {warehousesToBeSuspended?.length > 1 && (
              <Button style={warehousestyles.suspendSupplier} onClick={() => (warehousesToBeSuspended.length > 1 ? suspendMultiplyWarehouses() : null)}>
                Suspend {warehousesToBeSuspended.length}{" "}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      {openSuspendModal && <Suspendsuppliermodal openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} suspendWareHouseName={suspendWareHouseName} suspendWareHouse={suspendWareHouse} warehousesToBeSuspended={warehousesToBeSuspended} />}
      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
    </div>
  );
};

export default Activewarehouses;
