import {createSlice}  from "@reduxjs/toolkit";

let initialState = {
	businessData : null,
	businessUnitConfigId : null,
}
const tenantsBusinessSlice = createSlice({
	name : 'tenantsBusinessData',
	initialState,
	reducers : {
		handleSetBusinessData : (state, action) => {
			console.log(action.payload ,'tannt bs data')
			state.businessData = action.payload
		},
		handleBusinessUnitConfigId : (state, action) => {
			state.businessUnitConfigId = action.payload
		},
		
		handleUpdateBusinessData : (state, action) => {
			console.log(action.payload ,'updated tenant bs bs data')
				state.businessData = {...state.businessData, businessName : action.payload}
		}
	}
})

export const {handleSetBusinessData , handleBusinessUnitConfigId, handleUpdateBusinessData} = tenantsBusinessSlice.actions
export default tenantsBusinessSlice.reducer;