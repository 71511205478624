/* eslint-disable no-lone-blocks */
import {Button, Checkbox, Grid, TextField} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import lodash from 'lodash'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {handleAddTenantAllocated, handleClearTenantHouseData, handleTenantBioData, handleTenantHouseData} from "../../../../features/tenantsDataSlice";
import HttpComponent from "../../../School/MakeRequest";
import {ErrorAlert} from "../../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../../snackBar Alerts/successAlert";

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);

export default function AddCharge(props){

	console.log(props , 'check unit id at add charge')
	
	//Navigation
	const navigate = useNavigate()
	const dispatch = useDispatch()
	
	// Success Notification
	const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
	const handleSuccessNotificationClick = () => {setSuccessNotificationOpen(true);};
	const handleSuccessNotificationClose = (event, reason) => {if (reason === 'clickaway') {return} setSuccessNotificationOpen(false);};
	
	// Error Notification
	const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
	const handleErrorNotificationClick = () => {setErrorNotificationOpen(true);};
	const handleErrorNotificationClose = (event, reason) => {if (reason === 'clickaway') {return}setErrorNotificationOpen(false);};
	
	const [unitCharges, setUnitCharges] = useState([])
	
	//checkBox handler
	let [util, setUtil] = useState([])
	const [selectedItems, setSelectedItems] = useState([]);

	//Auth
	const { X_Authorization } = useSelector((store) => store.user);
	
	//Redux Store
	 const {unitId, amount, itemNumber, unitPurpose, billingDate,rentDueDate, unitType} = useSelector((store) => store.tenantData.tenantHouseData)

	 console.log(unitType ,'hhh')
	 console.log(itemNumber ,'gh')

	const {companyName, firstName, lastName, email, userPhone,tenantId, customerType} = useSelector((store) => store.tenantData.tenantBioData)
	
	// Select tenant
	const {tenantBioData, tenantHouseData } = useSelector((store) => store.tenantData)

	//Tenant creation Data
	let newTenantHouseData 
	let tenantData
	console.log(props)

	

	customerType?.toLowerCase()?.includes('company') ?
		tenantData = {companyName, firstName : companyName, lastName : '.', customerType, phone: `${userPhone}`, houses: [{unitType, itemNumber, amount, extraServices: util}], email}
		:
		tenantData = {firstName, lastName, customerType, phone: `+${userPhone}`, houses: [{unitType, itemNumber, amount, extraServices: util}], email}

		customerType?.toLowerCase()?.includes('company') ?
		newTenantHouseData = {tenantId:tenantId,houses: [{unitType, itemNumber,  amount, billingDate,rentDueDate , extraServices: util}]}
		:
		newTenantHouseData=  { tenantId:tenantId, houses:[{unitType,  itemNumber, amount,billingDate,rentDueDate, extraServices: util}]}

	const handleGoBackClick = () => {
		props.setFlagGoBack('')
	}



	const handleclearTenantHouseInfo = ()=>{
		handleClearTenantHouseData({unitType:"" ,unitId:"", amount:"", itemNumber:"", billingDate:"", })
	}
	
	const handlePreviewInvoice = () => {
		navigate('/tenantPreviewInvoice')
	}
	
	function handleAddTenantId (id) {
		dispatch(handleTenantBioData({...tenantBioData, tenantId : id}))
	}
	function handleUpdateTenantUnitId (id) {
		dispatch(handleTenantHouseData({...tenantHouseData, ssss : id}))
	}

	//if tenant is existing just update the house / billable item
	function handleUpdateTenantInfo () {
		dispatch(handleAddTenantAllocated({tenantData}))
		props.moreHouseUnits()
	}
	

	//Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method : 'GET',
			url : `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${props?.unitId}`,
			token : X_Authorization
		}).then((data)=>{
			console.log("here are unit charges for that house",data);
			if(data.status === 201){
				setUnitCharges(data.response.data)
			}else{
				console.log(`Error getting data`)
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}

	
	
	const handleCheckboxChange = (event, item) => {
		if (event.target.checked) {
			setSelectedItems([...selectedItems, item]);
			const object = {"amount" : item.amount, "name" : item.serviceName, "productId" : item.productId, "serviceType" : item.serviceType}
			setUtil([...util, object])
		} else {
			setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
			setUtil(util.filter((service) => service.productId !== item.productId))
		}
	};

	const [isProductSelected, setIsProductSelected] = useState(false)

	const getSelected = (productId) => {
		const newChecked = [...selectedItems];
		const currentIndex = newChecked.indexOf(productId)
		if(currentIndex === -1 ){
			newChecked.push(productId)
			setIsProductSelected(true)
		}else {
			newChecked.splice(currentIndex, 1)
			setIsProductSelected(false)
		}

		setSelectedItems(newChecked)
		setIsProductSelected(true)
	}
	
	//console.log(`utilss`, util)
	//console.log('selected' ,selectedItems)
	console.log(tenantData , 'tn')

	// Save tenant
	function saveTenant () {
		try {
			HttpComponent({
				method : 'POST',
				url : '/api/add_tenant',
				body :tenantData,
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201 || 200){
					handleAddTenantId(data.response.data._id)
					const billableItems = data?.response?.data?.billableItems
					if (billableItems) {
						for (let i = 0; i < billableItems.length; i++) {
						  const item = billableItems[i];
						 handleUpdateTenantUnitId(item._id);
						}
					  }
					handleSuccessNotificationClick()
					//handlePreviewInvoice();
					setTimeout(() => {
						props.moreHouseUnits()

					}, [1000])
				}else{
					handleErrorNotificationClick()
				}
			
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error saving tenant`, e.message)
		}
	}

	console.log(newTenantHouseData)

	//Add more units
	function saveTenantFromHouses(){
		try {
			HttpComponent({
				method : 'POST',
				url : '/api/add_tenant',
				body : newTenantHouseData,
				token : X_Authorization
			}).then((data)=>{
				if(data.status ===  200){
					handleclearTenantHouseInfo()
					handleSuccessNotificationClick()
					setTimeout(() => {
						props.moreHouseUnits()

					}, [1000])
				}else{
					handleErrorNotificationClick()
				}
			
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error saving tenant`, e.message)
		}

	}

	
	useEffect(() => {
		fetchServicesPerUnit()
	}, [])
	
	const selectedTotal = lodash.sumBy(selectedItems, item => {return item.amount})
	return (
		<div style={{fontFamily : 'Poppins'}}>
			{/*{componentToRender}*/}
			<Grid container direction={'row'} justifyContent={'space-between'} mt={3} spacing={3}>
				
				{/*Success Creation*/}
				<SuccessAlert message={'Success Creating Tenant'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
				
				{/*error sending mail*/}
				<ErrorAlert message={`Error Creating tenant`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />
				
				<Grid container justifyContent={'flex-start'}>
					{/*Header name*/}
					<Grid item mt={5}>
						<span style={{fontSize : '16px', fontWeight : '600', color : '#032541'}} >Advance Charges</span>
					</Grid>
				</Grid>
				
				{/*Rent Deposit, service Charge, electricity*/}
				<Grid item mt={0} width={'100%'}>
					<Grid container direction={'row'} justifyContent={'space-between'} spacing={4}>
						{
							unitCharges.map((data, index) => (
								<>
									{/*Rent Deposit*/}
									<Grid key={index} item>
										<Grid container direction={'row'} alignContent={'center'}  alignItems={'center'} justifyContent={'space-between'}>
											{/*Checkbox and Label*/}
											<Grid item direction={'row'}   justifyContent={'space-between'}>
												<FormControlLabel label={
													<Grid container direction={'row'} justifyContent={'space-between'}>
														
														{/*Label*/}
														<Grid item>
															<span style={{color : '#6e7074', fontSize : '14px', fontFamily : 'Poppins'}} >{data.serviceName}</span>
														</Grid>
													
													</Grid>
												} control={
													<Checkbox style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} edge="start" checked={selectedItems.includes(data)} onChange={event => handleCheckboxChange(event, data)} />}/>
											</Grid>
											
											{/*Input Box*/}
											<Grid item>
												<TextField disabled required readOnly size="medium" value={numberFormat(data.amount)} defaultValue={numberFormat(data.amount)} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '212px'}} id="outlined-basic" label="Amount" variant="outlined" />
											</Grid>
										</Grid>
									</Grid>
								</>
							))
						}
					</Grid>
				</Grid>
			</Grid>
			
			{/*Amount*/}
			<Grid container direction={'row'} justifyContent={'end'} alignContent={'flex-end'} mt={5}>
				<Grid item>
					<span style={{color : '#032541', frontWeight : 'bold', fontSize : '14px'}}>Total Amount :</span>
					<label style={{marginLeft : '5px', color : '#032541', fontFamily : 'Poppins', frontWeight : '600', fontSize : '16px'}}>{numberFormat(selectedTotal)}</label>
				</Grid>
			</Grid>
			
			{/*Buttons*/}
			<Grid container justifyContent={'flex-end'} alignContent={'center'} direction={'row'} mt={3} spacing={2}>
				<Grid item>
					<Button onClick={handleGoBackClick} style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
						Back
					</Button>
				</Grid>
				<Grid item>
					<Button disabled={selectedItems.length < 1 } onClick={props?.isFromCharges ? saveTenantFromHouses:saveTenant} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
						Save
					</Button>
				</Grid>
				{/* <Grid item>
					<Button disabled={selectedItems.length < 1 } onClick={saveTenant} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
						Preview Invoice
					</Button>
				</Grid> */}
			</Grid>
		</div>
	)
}
