import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Dash from "../../common/dash";
import user from "../../common/images/user-icon.png";
import bell from "../../common/images/bell-icon.png";
import logout from "../../common/images/logout-icon.png";
import logoutuser from "../logout/logout";
import {
  Grid,
  Button,
  Tab,
  styled,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";

const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "13px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.75",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      color: "#032541",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#dc3545",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "blue",
    },
  })
);

const Invoices = () => {
  // tabs controls;
    const {group} = useSelector((store) => store.user);
  const [tabvalue, setTabValue] = useState(group && group !== "Customer" ? "Generated":"Received");
  const [generatedRows, setGeneratedRows] = useState([]);
  const [receivedRows, setReceivedRows] = useState([]);
  const [generatedPageSize, setGeneratedPageSize] = useState(5);
  const [receivedPageSize, setReceivedPageSize] = useState(5);

  const baseUrl = process.env.REACT_APP_BASE_URL;
    let navigate = useNavigate();
  const fetchGenerated = async () => {
      try {
          const headers = {method : 'GET' , 'X-Authorization': localStorage.getItem('X-Authorization')};
          const response  = await fetch(baseUrl+"/api/get_business_invoices", {headers});
          if (response.status === 200) {
              await response.json()
                  .then((data)=>{
                      console.log("generated invoices", data)
                      const rowsWithIds = data.data.map(row => {
                          const createdAt = new Date(row.createdAt).toLocaleDateString("de-DE", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric"
                          });
                          return { ...row, id: row._id, createdAt }
                      });
                      setGeneratedRows(rowsWithIds);
                  })
          }
      } catch (error) {
          console.error("error fetching generated invoices",error.message);
      }
  };
  const fetchReceived = async () =>{
      try{
          const headers = {method : 'GET' , 'X-Authorization': localStorage.getItem('X-Authorization')};
          const response  = await fetch(baseUrl+"/api/get_user_invoices", {headers});
          if (response.status === 200) {
              await response.json()
                  .then((data)=>{
                      console.log("received invoices", data)
                      const rowsWithIds = data.data.map(row => {
                          const createdAt = new Date(row.createdAt).toLocaleDateString("de-DE", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric"
                          });
                          return { ...row, id: row._id, createdAt }
                      });
                      setReceivedRows(rowsWithIds);
                  })
          }
      }catch (e) {
          console.error("error fetching recived invoices",error.message);
      }
  }



      useEffect(() => {
      fetchGenerated()
          fetchReceived()
  }, []);

  const setNewValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <div>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginBottom="10px"
        >
          <Grid item>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "25px",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.32",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#dc3545",
              }}
            >
              Invoices
            </span>
          </Grid>

          <Grid item>
            {group && group != 'Admin' && group != "Customer" ?
              <Button
                style={{
                  width: "175px",
                  height: "37px",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor: "#032541",
                  // borderRadius:"5px",
                  color: "white",
                  margin: "0 12px",
                  padding: "9px 12px 8px",
                  // padding: "13px 36px 12px"
                }}
                startIcon={<Add />}
                  onClick={() => navigate("/customers")}
              >
                Create Invoice
              </Button>
              : <></>
            }
            <Button
              style={{
                width: "50px",
                height: "37px",
                margin: "0 12px 0 0",
                padding: "9px 10px 8px 11px",
                backgroundColor: "#05d8e1",
                color: "white",
              }}
            >
              CSV
            </Button>
            <Button
              style={{
                width: "50px",
                height: "37px",
                margin: "0 12px",
                padding: "9px 7px 8px",
                backgroundColor: "#032541",
                color: "white",
              }}
            >
              Excel
            </Button>
            <Button
              style={{
                width: "50px",
                height: "37px",
                margin: "0 0 0 12px",
                padding: "9px 12px 8px",
                backgroundColor: "#dc3545",
                color: "white",
              }}
            >
              PDF
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <p
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.65",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#b4bcc4",
              }}
            >
              Dashboard{" "}
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#b4bcc4",
                }}
              >
                \
              </span>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#032541",
                }}
              >
                Invoices
              </span>{" "}
            </p>
          </Grid>
        </Grid>

        <Grid container marginBottom="10px" >
          <Grid item style={{width:"100%"}}>
            <TabContext value={tabvalue}>
              <Box>
                <AntTabs onChange={setNewValue}>
                    <AntTab value="Received" label="Received" />
                    {group && group !== "Customer" &&(
                        <AntTab value="Generated" label="Generated" />
                    )}
                </AntTabs>
              </Box>


              <TabPanel value="Received">
                  <div style={{
                      height:"350px",
                      minWidth:"1000px"
                  }}>
                      <DataGrid
                          pagination
                          rows={receivedRows}
                          width="100%"
                          pageSize={receivedPageSize}
                          onPageSizeChange={(newPageSize) => setReceivedPageSize(newPageSize)}
                          rowsPerPageOptions={[5, 10, 20, 50, 100]}
                          columns={[
                              {field: 'invoiceNumber', headerName: 'Invoice Number', minWidth:200, renderCell : (params) => {return (<Link to={`/invoice/${params.value}`}>{params.value}</Link> )}},
                              {field: 'sentTo', headerName: 'Sent To',minWidth:200},
                              {field: 'createdAt', headerName: 'Created At',minWidth:150},
                              {field: 'invoiceAmount', headerName: 'Invoice Amount',minWidth:150},
                              {field: 'invoiceType', headerName: 'Invoice Type',minWidth:150},
                              {field: 'invoiceStatus', headerName: 'Invoice Status',minWidth:150},
                          ]}
                          getRowId={row => row._id}
                          // onRowClick={console.log}
                      />
                  </div>

              </TabPanel>
                {group && group !== "Customer" &&(
                <TabPanel value="Generated">
                    <div style={{
                        height:"350px",
                        minWidth:"1000px"
                    }}>
                        <DataGrid
                            pagination
                            rows={generatedRows}
                            width="100%"
                            pageSize={generatedPageSize}
                            onPageSizeChange={(newPageSize) => setGeneratedPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            columns={[
                                {field: 'invoiceNumber', headerName: 'Invoice Number', minWidth:200, renderCell : (params) => {return (<Link to={`/invoice/${params.value}`}>{params.value}</Link> )}},
                                {field: 'sentTo', headerName: 'Sent To',minWidth:200},
                                {field: 'createdAt', headerName: 'Created At',minWidth:150},
                                {field: 'invoiceAmount', headerName: 'Invoice Amount',minWidth:150},
                                {field: 'invoiceType', headerName: 'Invoice Type',minWidth:150},
                                {field: 'invoiceStatus', headerName: 'Invoice Status',minWidth:150},
                            ]}
                            getRowId={row => row._id}
                            // onRowClick={console.log}
                        />
                    </div>
                </TabPanel>
                )}
            </TabContext>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Invoices;
