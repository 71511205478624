import { Breadcrumbs, Grid, Stack, Tab, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState } from "react";
import SupplierVendorTabs from "./suppliervendortabs";
import { useNavigate } from "react-router-dom";
import VerificationPayable from "./VerificationPayable";
import OtherPaymentsPayableTabs from "./otherPayments";
import Breadcrumb from "../../../SuperAdmin/components/Breadcrumbs/Breadcrumbs";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";


//breadcrumbs

// const breadcrumbs = [
//     <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
//     <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>
// ]

// custom tab styling
const AntTabs = styled(TabList)({
  borderBottom: "0px solid #e8e8e8",
  "& .MuiTabs-indicator": { backgroundColor: "#dc3545" },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "18px",
    background: "#f5f6f7",
    borderRadius: "5px",
    marginLeft: "-10px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#cb444a",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
  })
);

export default function PayablesAdminTransactions() {
  const [tabValue, setTabValue] = useState("SuppliersVendors");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const queryparams = decodeURIComponent(window.location.search);
  // const state = queryparams.slice(1)

  // const navigate = useNavigate();

  // const handleNavigateToVerify = () => {
  //     navigate('/payables/transactions?verifyPayable')
  // }

  return (
    <Grid container direction={"column"}>
      <Grid item mt={1}>
        <Stack direction={"row"} spacing={2} alignItems={'center'}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}
          >
            Payable Transactions
          </Typography>
        </Stack>
      </Grid>
      <Grid item mt={1}>
        {/* <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs> */}
        <Breadcrumb name="Payable Transactions" />
      </Grid>
      <Grid item mt={2}>
        <TabContext value={tabValue}>
          <AntTabs onChange={handleChange} sx={{ display: "flex" }}>
            <AntTab label="Suppliers/Vendors" value="SuppliersVendors" />
            <AntTab label="OtherPayments" value="OtherPayments" style={{ marginLeft: "20px" }} />
          </AntTabs>
          <TabPanel value="SuppliersVendors" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <SupplierVendorTabs />
            </Grid>
          </TabPanel>
          <TabPanel value="OtherPayments" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <OtherPaymentsPayableTabs />
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}
