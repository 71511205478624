import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Breadcrumbs, Divider, IconButton } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../../../School/MakeRequest";
import { useParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Receipt Reprint Report</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transaction Details</Typography>
]

export default function ReprintReportDetails() {
    const [reprintData, setReprintData] = useState([]);
    const [reprintDataHistory, setReprintDataHistory] = useState([]);
    const { id } = useParams();  

    function getAllTransactionsDetails() {
        try {
        
            HttpComponent({
                method: "GET",
                url: `/api/reprintreceiptreportorder?orderId=${id}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {

                if (data.status === 200) {
                    setReprintData(data?.response?.data);
                    setReprintDataHistory(data?.response?.data?.reprintHistory)
                }
            })
            
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAllTransactionsDetails();
    }, []);
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    return (
       <>
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignItems={'center'}>
            <Grid item display={'flex'} alignItems={'center'}>
            <IconButton onClick={handleGoBack}>
            <ArrowBackIosNewIcon style={{ color: "#707070" }} />
        </IconButton>                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Transaction Details</Box>
                </Grid>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px',marginLeft:'19px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item display={'flex'} width={'100%'}>
                <Grid container mt={3} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box sx={{ p: 3 }}>
                    <Grid container spacing={2}>
    <Grid item xs={6}>
        <Typography variant="body2">Transaction ID</Typography>
        <Typography variant="subtitle2" sx={{fontWeight:''}}>{reprintData?.pushTransactionId}</Typography>
    </Grid>
    <Grid item xs={6} textAlign="right">
        <Typography variant="body2">No. of Reprints</Typography>
        <Typography variant="subtitle2">{reprintData?.numberOfReprints}</Typography>
    </Grid>

    <Grid item xs={12}>
        <Divider />
    </Grid>

    <Grid item xs={6}>
        <Typography variant="body2">Teller ID</Typography>
        <Typography variant="subtitle2">{reprintData?.tellerId ?? 'N/A'}</Typography>
    </Grid>
    <Grid item xs={6} textAlign="right">
        <Typography variant="body2">Outlet</Typography>
        <Typography variant="subtitle2">{reprintData?.outlet}</Typography>
    </Grid>
</Grid>
<Grid item xs={12}>
        <Divider />
    </Grid>


                        <Box mt={3}>
                            <Typography variant="h6" gutterBottom>
                                Reprint Details
                            </Typography>
                            <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none', padding: 0, margin: 0 }}>
            <Table sx={{ width: '100%', margin: 0 }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none', padding: '8px 0' }}>Time/Date</TableCell>
                        <TableCell sx={{ borderBottom: 'none', textAlign: 'right', padding: '8px 0' }}>Reprinted By</TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            {/* Full-Width Divider */}
            <Grid item xs={12}>
                <Divider sx={{ width: '100%', margin: '0' }} />
            </Grid>

            <Table sx={{ width: '100%', margin: 0 }}>       
                <TableBody>
                    {reprintDataHistory.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ borderLeft: 'none', borderRight: 'none', borderBottom: '', padding: '8px 0 19px' }}>
                                {new Date(row.createdAt).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ borderLeft: 'none', borderRight: 'none', borderBottom: '', textAlign: 'right', padding: '8px 0 19px' }}>
                                {row.cashier}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
       </>
    );
}
