import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { handleAdded } from "../../../../features/AlertSlice";
import axios from "axios";
import { handleTabChange } from "../../../../features/currentTabSlice";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function AddDeviceModel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  //   const reduxName = useSelector((state) => state.bankDetails.name);
  //   const reduxDescription = useSelector((state) => state.bankDetails.description);

  //value to set when user edits form
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [type, setType] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  const handleVersion = (e) => {
    setVersion(e.target.value);
  };

  //updating the details object
  //   const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleAdd = () => {
    try {
      axios
        .post(
          baseUrl + "/api/v1/addModels",
          {
            deviceName: name,
            deviceType: type,
            version,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let message = response.data.message;
          if (message === "Device created successfully!") {
            console.log("Device created successfully!");
            // setLocalDetails({
            //   name,
            //   cardNo,
            //   cardType,
            //   suspense,
            // });
            dispatch(handleAdded(true));
            navigate("/DeviceModels");
          } else {
            setFailMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  //   useEffect(() => {
  //     if (Object.getOwnPropertyNames(localDetails).length > 0) {
  //       // dispatch(setDetails(localDetails));
  //       navigate("/DeviceModels");
  //     }
  //   }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Add Device Model
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Devicel Models"}
        link1={"/DeviceModels"}
        point2={"Add Device Model"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Add Device Model Details
            </Typography>
            <TextField
              label="Device Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              autoFocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Device Type"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleType}
              className={classes.focusedTextField}
            />
            <TextField
              label="Device Version"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.focusedTextField}
              onChange={handleVersion}
            />

            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => {
                  dispatch(handleTabChange("Active"));
                  navigate("/DeviceModels");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddDeviceModel;
