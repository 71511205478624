import { Box, Breadcrumbs, Button, FormControl, FormControlLabel, Grid, InputLabel, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import GeoLocation from "../../../hooks/useLocation";
import HttpComponent from "../MakeRequest";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Sponsor</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Sponsor</Typography>,
]

export default function AddSponsor() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [selectedSponsorType, setSelectedSponsorType] = useState('')
    const [formData, setFormdata] = useState({ firstName: '', lastName: '', userName: '', email: '', organizationName: '' })
    const [phone, setPhone] = useState('')
    const [selectedValue, setSelectedValue] = useState(null)
    const navigate = useNavigate()
    const handleOnChange = (e) => {
        setSelectedSponsorType(e.target.value)
    }
    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }

    // add sponsor

    let disabled = selectedSponsorType === 'Organisation' ? !formData.organizationName || !selectedValue || !formData.userName || !formData.email || !phone : !formData.firstName || !formData.lastName || !formData.userName || !formData.email || !selectedValue


    function addNewSponsor() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/addsponsors`,
                body: {
                    firstName: selectedSponsorType === 'Organisation' ? formData.organizationName : formData.firstName,
                    lastName: formData.lastName,
                    userName: formData.userName,
                    email: formData.email,
                    customerAddress: selectedValue?.description,
                    paymentType: "prepaid",
                    customerClassification: "Sponsor",
                    phone: phone,
                    sponsorType: selectedSponsorType,
                    organizationName: formData.organizationName
                },
                token: localStorage.getItem('X-Authorization')
            }).then((response) => {
                if (response?.status === 200) {
                    setSuccessShow({ ...successShow, state: true, message: response?.data?.message })
                    navigate(-1)
                } else {
                    setErrorShow({ ...errorShow, state: true, message: response?.data?.message })
                }
            })
        } catch (error) {
            setErrorShow({ ...errorShow, state: true, message: error?.message })
        }
    }



    return (
        <Grid container display={'flex'} direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Sponsor Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography fontWeight={700}>Select Type of Sponsor</Typography>
            </Grid>
            <Grid item width={'50%'} mt={1}>
                <Grid container display={'flex'} alignItems={'center'} width={'100%'} gap={2}>
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup sx={{ width: '100%', gap: 1 }} onChange={handleOnChange} value={selectedSponsorType} row aria-labelledby="demo-row-radio-buttons-group-label" name="controlled-radio-buttons-group">
                            <Grid item width={'49%'}>
                                <Paper elevation={1} sx={{ width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center', }}>
                                    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" sx={{ fontSize: '12px', ml: 2 }}>Individual</Typography>
                                    </Box>
                                    <FormControlLabel value="Individual" control={<Radio />} />
                                </Paper>
                            </Grid>
                            <Grid item width={'49%'}>
                                <Paper elevation={1} sx={{ width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" sx={{ fontSize: '12px', ml: 2 }}>Organization</Typography>
                                    </Box>
                                    <FormControlLabel value="Organisation" control={<Radio />} />
                                </Paper>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {!selectedSponsorType ? null : <>
                {selectedSponsorType === 'Organisation' &&
                    <Grid item mb={2} width={'50%'} mt={1}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Organisation Name</InputLabel>
                        <TextField style={{ width: "100%" }} value={formData.organizationName} name='organizationName' onChange={handleFormdata} id="outlined" placeholder="Organisation Name" multiline required />
                    </Grid>
                }

                {selectedSponsorType === 'Individual' &&
                    <Grid item mb={2} width={'50%'} mt={2}>
                        <Grid container width={'100%'} gap={1}>
                            <Grid item width={'49%'}>
                                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>First Name</InputLabel>
                                <TextField style={{ width: "100%" }} value={formData.firstName} name='firstName' onChange={handleFormdata} id="outlined" placeholder="First Name" multiline required />
                            </Grid>
                            <Grid item width={'49%'}>
                                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Last Name</InputLabel>
                                <TextField style={{ width: "100%" }} value={formData.lastName} name='lastName' onChange={handleFormdata} id="outlined" placeholder="Last Name" multiline required />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item mb={2} width={'50%'} mt={1}>
                    <Grid container width={'100%'} gap={1}>
                        <Grid item width={'49%'}>
                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>userName</InputLabel>
                            <TextField style={{ width: "100%" }} value={formData.userName} name='userName' onChange={handleFormdata} id="outlined" placeholder="userName" multiline required />
                        </Grid>
                        <Grid item width={'49%'}>
                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Phone</InputLabel>
                            <PhoneInput required country={"ke"} enableSearch={true} label={"Mobile Number"} value={phone} onChange={(phone) => setPhone(phone)} multiline inputStyle={{ height: "55px", width: "100%" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item width={'50%'}>
                    <Grid container width={'100%'} gap={1}>
                        <Grid item width={'49%'}>
                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Email</InputLabel>
                            <TextField style={{ width: "100%" }} value={formData.email} name='email' onChange={handleFormdata} id="outlined" placeholder="Email" multiline required />
                        </Grid>
                        <Grid item width={'49%'}>
                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Address</InputLabel>
                            <GeoLocation required={true} sx={{ width: "100%" }} selectedValue={selectedValue} onValueChange={(address) => setSelectedValue(address)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                    <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                    <Button onClick={() => addNewSponsor()} disabled={disabled} style={disabled ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Add</Button>
                </Grid>
            </>}
        </Grid>
    )

}
