import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  amount: 0,
  amount2: 0
}


const transactionsSlice = createSlice({
  name:"transactionAmount",
  initialState,
  reducers:{
    handleAmount:(state, action) => {
      state.amount = action.payload
    },
    handleAmount2:(state, action) => {
      state.amount2 = action.payload
    },
  }
})

export const { handleAmount, handleAmount2 } = transactionsSlice.actions;

export default transactionsSlice.reducer