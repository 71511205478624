import { ArrowRight } from "@mui/icons-material";
// import { borderRadius } from "@mui/system";
import React, { useState, useEffect } from "react";
import Collection from "../../common/images/collection.svg";
import filterIcon from "../../common/images/redarrowdown.svg";
import StyledComponent from "@emotion/styled";
import { Link } from "react-router-dom";
// import { Button } from "react-bootstrap";
import { Box, FormControl, Grid, TextField, InputAdornment, MenuItem, Select, Typography } from "@mui/material";
import { useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import { AlertModal } from "../AlertModals/alertModals";
import { MessageAlertMOdal } from "./messageModal";
import { useSelector } from "react-redux";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";
import { QuestionModalSettle } from "./infomessage";
import { SuccessAlertModal } from "./successmodal";
import filterImage from "../../common/images/filtericon.png";

const collection = {
  margin: "0 58px 15px 0",
  fontamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: " normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  textAlign: "left",
  color: " #032541",
};
const colletion_sale = {
  margin: "0 18px 15px 0",
  fontfamily: " Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#032541",
};

const colletion_saleE = {
  margin: "0 18px 15px 0",
  fontfamily: " Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#032541",
};
const baseUrl = process.env.REACT_APP_BASE_URL;
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);
const businessId = localStorage.getItem("businessID");
const url = window.location.href;
const parts = url.split("/");
const id = parts[parts.length - 1];
const cashierId = id;
const businessNumber = localStorage.getItem("businessId");
const businessIdString = localStorage.getItem("businessID");
const branchId = localStorage.getItem("BranchId");

const SettleCashier = () => {
  const collection = {
    margin: "0 58px 15px 0",
    fontamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    textAlign: "left",
    color: " #032541",
  };
  const colletion_sale = {
    margin: "0 18px 15px 0",
    fontfamily: " Poppins",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#032541",
  };

  const colletion_saleE = {
    margin: "0 18px 15px 0",
    fontfamily: " Poppins",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#032541",
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);
  const businessId = localStorage.getItem("businessID");
  const url = window.location.href;
  const parts = url.split("/");
  const id = parts[parts.length - 1];
  const cashierId = id;
  const businessNumber = localStorage.getItem("businessId");
  const businessIdString = localStorage.getItem("businessID");
  const branchId = localStorage.getItem("BranchId");

  const { username } = useSelector((store) => store.user);
  const [expenditure, setExpenditure] = useState([]);
  const [sales, setSales] = useState([]);
  const [settledAmount, setSettledAmount] = useState("");

  const [cashierdetails, setSetCashierDetails] = useState({
    firstName: "",
    secondName: "",
  });
  const [settleCashier, setSettleCashier] = useState({
    Status: "",
    Message: "",
  });
  const [settlements, SetSettlements] = useState([]);
  const [pendingExpenses, setpendingExpenses] = useState("");

  console.log("first name", cashierdetails.firstName);
  console.log("lastname", cashierdetails.secondName);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);

  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
  const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

  const [deactivateModalOpen1, setDeactivateModalOpen1] = useState(false);
  const DeactivateModalHandleOpen1 = () => setDeactivateModalOpen1(true);
  const DeactivateModalHandleClose1 = () => setDeactivateModalOpen1(false);

  const [deactivateModalOpen2, setDeactivateModalOpen2] = useState(false);
  const DeactivateModalHandleOpen2 = () => setDeactivateModalOpen2(true);
  const DeactivateModalHandleClose2 = () => setDeactivateModalOpen2(false);

  useEffect(() => {
    handleSuccessClose;
    getAllExpenditureApprovedNotSettled();
    getSalesOfCashier();
    cashierDetails();
    getPreviousSetttlememt();
    checkPendingSettlement();
  }, []);

  const getSalesOfCashier = async () => {
    try {
      const res = await fetch(
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=${"Cash Payment"}`,
        {
          method: "GET",
          headers: {
            Content_Type: "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        console.log("-------------Total Sales--Not settled for cashier -->", data.data);
        setSales(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllExpenditureApprovedNotSettled = async () => {
    const transacations_type = "Cash Payment";

    console.log("Business String", businessIdString);
    console.log("Cashier Id", cashierId);
    console.log("Branch Id", branchId);

    try {
      const response = await fetch(
        //https://zed.swerri.io/api/get_all_expenditure_for_cashier?businessNumber=9000078&cashierId=6389b25d6020a23cd1b265a7&settled_Status=false
        `${baseUrl}/api/get_all_expenditure_for_cashier?businessNumber=${businessNumber}&cashierId=${cashierId}`,
        {
          method: "GET",
          headers: {
            Content_Type: "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        console.log("-----------get_all_expenditure_for_cashier------>", data.data);
        setExpenditure(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cashierDetails = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/get_cashier_by_id?cashierId=${cashierId}`, {
        method: "GET",
        headers: {
          Content_Type: "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        console.log("-------------CASHIER DETAILS -->", data.data);
        // setSetCashierDetails(data.data)
        setSetCashierDetails({ ...cashierdetails, firstName: data.data.firstName, secondName: data.data.secondName });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPreviousSetttlememt = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/get_settlements?cashierId=${cashierId}`, {
        method: "GET",
        headers: {
          Content_Type: "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        console.log("PREVIOUS SETTLEMENTS", data.data);
        // setSetCashierDetails(data.data)
        SetSettlements(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkPendingSettlement = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/get_all_expenditure_pending_not_settled?cashierId=${cashierId}`, {
        method: "GET",
        headers: {
          Content_Type: "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        console.log("-------------get_settlements-->", data.count);
        // setSetCashierDetails(data.data)SetSettlements
        setpendingExpenses(data.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [message, setMessage] = useState("Cashier settled successfully");
  const settleCashierCashE = async (e) => {
    e.preventDefault();
    console.log("cashierId", cashierId);
    console.log("transactionType", "Cash Payment");
    console.log("expectedAmount", expectedAmt);
    console.log("expenditureAmount", totalexpenditureCash);
    console.log("settledBy", username);
    console.log("settledAmount", settledAmount);

    try {
      const settleCashierResp = await fetch(`${baseUrl}/api/settle_cash`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          cashierId: cashierId,
          transactionType: "Cash Payment",
          expectedAmount: expectedAmt,
          expenditureAmount: totalexpenditureCash,
          settledBy: username,
          settledAmount: settledAmount,
        }),
      });

      await settleCashierResp
        .json()
        .then((data) => {
          // console.log(data.Status)
          if (data.Status === "SUCCESS") {
            setSettleCashier({
              ...settleCashier,
              Status: data.status,
              Message: data.message,
            });
            // alert(data.message)
            setMessage(data.message);
            setDeactivateModalOpen2(true);

            setDeactivateModalOpen(false);
          } else if (data.status === "error") {
            setDeactivateModalOpen(false);
            handleErrorOpen();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  let totalexpenditureCash = 0;

  for (let i = 0; i < expenditure.length; i++) {
    totalexpenditureCash += expenditure[i].amount;
  }
  console.log(totalexpenditureCash);
  let totalCashierCash = 0;

  for (let i = 0; i < sales.length; i++) {
    totalCashierCash += sales[i].transamount;
  }
  console.log(totalCashierCash);
  const expectedAmt = totalCashierCash - totalexpenditureCash;

  const valueAmt = parseInt(settledAmount - expectedAmt);

  const columns = [
    {
      field: "settledAmount",
      headerName: "settled Amount",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Settled Amount (KES)
        </strong>
      ),
      renderCell: (params) => {
        return (
            <div>
                <Typography variant="body2" color="textSecondary">
                 
                  {params.value}
                
                </Typography>
            </div>
        );
    },
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        // console.log("params", params);
        return numberFormat(params.value);
      },
    },
    {
      field: "expectedAmount",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Expected Amount (KES)
        </strong>
      ),
      renderCell: (params) => {
        return (
            <div>
                <Typography variant="body2" color="textSecondary">
                 
                  {params.value}
                
                </Typography>
            </div>
        );
    },
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        // console.log("params", params);
        return numberFormat(params.value);
      },
    },
    {
      field: "balance",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Deficit/Surplus (KES)
        </strong>
      ),
      renderCell: (params) => {
      const amt  = params.row.expectedAmount - params.row.settledAmount
      let color = amt > 0 ? "red" : amt < 0 ? "#FF870B" : "green"
        return (

            <div>
                <Typography variant="body2" color="textSecondary">
                  <span style={{color: color}}>
                  {
                    amt > 0 ? `${(amt)}` : amt < 0 ? `${(amt) * -1}` : `${amt}`
                  }
                  </span>
                </Typography>
            </div>
        );
    },
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        // console.log("params", params);
        return numberFormat(params.value);
      },
    },


    {
      field: "expenditureAmount",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Expenditure Amount (KES)
        </strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
            <div>
                <Typography variant="body2" color="textSecondary">
                 
                  {
                    // in three digits
                    params.value
                    
                  }
                
                </Typography>
            </div>
        );
    },
      valueFormatter: (params) => {
        // console.log("params", params);
        return numberFormat(params.value);
      },
    },
    
    {
      field: "settledBy",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Settled By
        </strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
            <div>
                <Typography variant="body2" color="textSecondary">
                 
                  {params.value}
                
                </Typography>
            </div>
        );
    },
    },
    {
      field: "createdAt",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Created At
        </strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
            <div>
                <Typography variant="body2" color="textSecondary">
                 
                  {moment(params.value).format("DD/MM/YYYY")}
                
                </Typography>
            </div>
        );
    },
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },
    {
      field: "status",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          Status
        </strong>
      ),
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
            <div>
                <Typography variant="body2" color="textSecondary">
                 
                  {params.value}
                
                </Typography>
            </div>
        );
    },
      renderCell: (params) => {
        const difference = params.row.settledAmount - params.row.expectedAmount;
        let color = "";
        let text = "";
        if (difference > 0) {
          color = "#FF870B";
          text = "Surplus";
        } else if (difference < 0) {
          color = "red";
          text = "Deficit";
        } else {
          color = "green";
          text = "Settled";
        }
        return <div style={{ color, textTransform: "capitalize", fontSize:"15px" }}>{text}</div>;
      },
    },
  ];

  const rows = settlements.map((item) => ({
    id: item._id,
    settledAmount: item.settledAmount,
    expectedAmount: item.expectedAmount,
    expenditureAmount: item.expenditureAmount,
    settledBy: item.settledBy,
    createdAt: item.createdAt,
  }));
  // const color =  expectedAmt == settledAmount ? "#17ae7b" :   (expectedAmt < settledAmount ? "#ff8503" : "#707070");
  const color =
    expectedAmt == parseInt(settledAmount)
      ? "#17ae7b"
      : expectedAmt < parseInt(settledAmount)
      ? "#ff8503"
      : parseInt(settledAmount) == ""
      ? "#707070"
      : expectedAmt > parseInt(settledAmount)
      ? "#dc3545"
      : "#707070";

  const color_btn = settledAmount === "" ? "#707070" : "#032541";

  return (
    <div>
      <div>
        <div>
          <div class="Settle__cashier">
            <div className="cashier-name">
              <h3
                style={{
                  width: "100%",
                  height: "35px",
                  margin: " 11px 0 11px 14px",
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.32",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#dc3545",
                }}
              >
                {
                  <div>
                    {cashierdetails.firstName} {cashierdetails.firstName}
                  </div>
                }
              </h3>
            </div>
            <div
              className="settle-cashier"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 100px 0px 0px",
              }}
            >
              <div
                className="cashier-collection "
                style={{
                  width: " 580px",
                  height: "155px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: " 155px",
                  margin: " 18px 464px 28px 0",
                  padding: " 20px 57px 19px 25px",
                  borderRadius: "18px",
                  boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16",
                  backgroundColor: "#fff",
                }}
              >
                <div className="cashier-collection-icon">
                  <img src={Collection} alt="" />
                </div>
                <div
                  className="cashier-collection-sale"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="collection">
                    <h4 style={collection}>Total sales</h4>
                    <h4 style={colletion_sale} color={`${color}`}>Expenditure</h4>
                    <h4 style={collection}>Expected Amount</h4>
                  </div>
                  <div className="collection-amount">
                    <h4 style={colletion_sale}> {numberFormat(totalCashierCash)}</h4>
                    <h4
                      style={{
                        margin: "0 18px 15px 0",
                        fontfamily: " Poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "1.29",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541 !important",
                      }}
                    >
                      {numberFormat(totalexpenditureCash)}
                    </h4>
                    <h4 style={colletion_sale}>{numberFormat(expectedAmt)}</h4>
                  </div>
                </div>
              </div>
              <div className="settle__cashier_form">
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {/*    onChange={(e) => setSettledAmount(e.target.value)} */}
                  <FormControl>
                    <TextField label="Enter Cash Amount" required onChange={(e) => setSettledAmount(e.target.value)} />
                  </FormControl>
                </Box>
                <h4
                  style={{
                    margin: "9px 173.1px 0 22px",
                    fontfamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.42",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: `${color}`,
                  }}
                >
                  Expected-Amount: <span>{numberFormat(expectedAmt)}</span>{" "}
                </h4>

                {pendingExpenses > 0 ? (
                  <h6
                    style={{
                      margin: "0 18px 15px 0",
                      fontfamily: " Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.29",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    The cashier has pending expenses, kindly approve or decline to proceed
                  </h6>
                ) : (
                  <div>
                    <button
                      style={{
                        width: "111px",
                        height: "34px",
                        margin: "28px 141.1px 0 141px",
                        padding: " 8px 9px 7px 10px",
                        borderRadius: "5px",
                        backgroundColor: `${color_btn}`,
                        fontfamily: "Poppins",
                        fontSize: "13px",
                        fontWeight: " 500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "0.92",
                        letterSpacing: " normal",
                        textAlign: "center",
                        outline: "none",
                        color: "#fff",
                      }}
                      onClick={() => {
                        console.log("i was clicked");

                        if (parseInt(settledAmount) == expectedAmt) {
                          setDeactivateModalOpen(true);
                        } else if (expectedAmt < settledAmount) {
                          setDeactivateModalOpen(true);
                        } else if (settledAmount == "") {
                          setDeactivateModalOpen1(true);
                        } else {
                          setDeactivateModalOpen(true);
                        }
                      }}
                    >
                      Receive Cash
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="previous-settlements">
              <div
                className="top-payments"
                style={{
                  display: "flex",
                 
                }}
              >
                <div
                  className="payments-title"
                  style={{
                    margin: "28px 124px 31px 14px",
                    fontfamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: " normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  <h4>Cash Payments</h4>
                </div>
                <div
                  className="payments-filter"
                  style={{
                    width: "44px",
                    height: "21px",
                    margin: "2px 16px 20px 0",
                    fontfamily: "Poppins",
                    fontSize: "15px",
                    fontweight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "2.2",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  <div
                    className="payments-title"
                    style={{
                      margin: "28px 124px 31px 14px",
                      fontfamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: " normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      display: "flex",
                      justifyContent: "space-between",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    <Grid item display='flex'>
                      <label
                        style={{
                          width: "24px",
                          height: "12px",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          marginTop:"-5px",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginRight:"10px",
                          
                        }}
                      >
                        {" "}
                        show{" "}
                      </label>
                      <FormControl sx={{ m: 1,marginLeft:"15px" }} size="small" display='block'>
                        <Select
                          sx={{
                            height: "23px",
                            borderRadius: "6px",
                            fontFamily: "Poppins",
                            border: "solid 1px #707070",
                            width: "70px",
                           

                          
                          }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>50</MenuItem>
                        </Select>
                      </FormControl>
                      <label
                        style={{
                          width: "24px",
                          height: "12px",
                          margin: "-5px 15px 4px 0",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                        }}
                      >
                      
                        entries
                      </label>
                    </Grid>
                    <h4 style={{
                      fontSize:"16px",
                      marginLeft:"40px",
                      marginTop:"8px",

                    }}>Filter</h4>
                    <img style={{
                      height:"20px",
                      marginLeft:"20px",
                      marginTop:"8px",
                      
                    }} src={filterImage} />
                  </div>
                </div>
              </div>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  style={{
                    height: "500px",
                    width: "auto",
                    overflow: "visible",
                  }}
                  rows={rows}
                  columns={columns}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <QuestionModalSettle
          deactivateModalOpen={deactivateModalOpen}
          onClose={DeactivateModalHandleClose}
          formAction={settleCashierCashE}
          setAmt={settledAmount}
          color={valueAmt < 0 ? "#dc3545" : "'#032541"}
          value={valueAmt < 0 ? valueAmt * -1 : valueAmt}
          message={valueAmt < 0 ? "deficit" : "Credited"}
        />
        <MessageAlertMOdal deactivateModalOpen={deactivateModalOpen1} onClose={DeactivateModalHandleClose1} />
        <SuccessAlertModal
          deactivateModalOpen={deactivateModalOpen2}
          onClose={DeactivateModalHandleClose2}
          message={message}
        />
      </div>
    </div>
  );
};

export default SettleCashier;
