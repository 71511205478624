import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CardImg from "./Images/cardIcon.svg";
import MpesaImg from "./Images/mpesaImage.svg";
import { GetDefaultCurrency } from "../../common/currencyconverter";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Load Student Wallet</Typography>
]

export default function SelectWalletPaymentMethod() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const navigate = useNavigate();
    const [defaultCurrency, setDefaultCurrency] = useState(GetDefaultCurrency())

    return (
        <Grid xs={12} sm={12} md={12} lg={12} direction={'column'} container>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Load Student Wallet</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontSize: "14px", color: "#707070" }}>Select a payment method</Typography>
            </Grid>
            <Grid item sx={{width:{xs:"100%", sm:"50%"}}}  mt={2}>
                {
                    (defaultCurrency === "KES" &&    <Box component="div" onClick={() => navigate('/dashboard?loadStudentWallet')} sx={{display:"flex", width: {xs:"95%", sm:"70%"}, cursor:'pointer', marginTop:'15px', borderRadius:'7px', border:'1px solid #edede9', padding:'5px'}} variant="outlined">
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <img src={MpesaImg} alt="mpesa"/>
                    </Box>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginLeft:1}}>
                        <Typography sx={{color:"#032541"}}>
                            Load Via Mpesa
                        </Typography>
                    </Box>
                </Box>)
                }
             
                <Box component="div" onClick={() => navigate('/dashboard?loadViaCard')} sx={{display:"flex", width: {xs:"95%", sm:"70%",} , cursor:'pointer', marginTop:'15px', borderRadius:'7px', border:'1px solid #edede9', padding:'5px'}} variant="outlined">
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <img src={CardImg} alt="card"/>
                    </Box>
                    <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginLeft:1}}>
                        <Typography sx={{color:"#032541"}}>
                            Load Via Card
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}