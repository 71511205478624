import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import HttpComponent from "./MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { useNavigate } from "react-router-dom";

let baseUrl = process.env.REACT_APP_BASE_URL;


const AddMoreServiceToStudent = () => {
  const [gradeOptions, setGradeOptions] = useState([])
  const [termOptions, setTermOptions] = useState([]);
  const [schoolTypeId, setSchoolTypeId] = useState('')
  const [schoolTypeName, setSchoolTypeName] = useState("");
  const navigate = useNavigate();
  
  const GetSchoolTypeIdForBusiness = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem('X-Authorization'),
      }
    });
    const data = await response.json();
    if (response.status === 201) {
      setSchoolTypeId(data.data?._id);
      setSchoolTypeName(data.data?.schoolType);

    }
  }
  useEffect(() => {
    GetSchoolTypeIdForBusiness();
  }, [])

  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();

    if (response.status === 201) {
      setGradeOptions(data.data);
    }
  };
 
  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization":localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return {value: terms.schoolGrades, label: terms.schoolGrades}
    }
    ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);


  const cancelButtonStyles = { width: "7.813rem", height: "2.813rem", padding: "12.5px 36.7px 12.5px 38.3px", borderRadius: "4px", border: "solid 1px #dc3545", color: "#dc3545" };

  const buttonStyles = { width: "7.813rem", height: "2.813rem", borderRadius: "4px" };

  const [formData, setFormData] = useState({
    serviceName: "",
    priceType: "Fixed",
    categoryId: "",
    amount: "",
    description: "",
    grade: "",
    term: "",
  });

  const [successShow, setSuccessShow] = useState({ state: false, message: "" });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const [options, setOptions] = useState([]);

  const fetchCategories = () => {
    HttpComponent({
      method: "GET",
      url: "/api/listCategories/Active",
      body: null,
      token: localStorage.getItem('X-Authorization'),
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          const mappedData = data.response.data.map(({ categoryName, _id }) => ({ label: categoryName, value: _id }));
          setOptions(mappedData);
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [coursesOptions, setCoursesOptions] = useState([]);

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization":localStorage.getItem('X-Authorization'),
            },
            method: "GET",
        });
        const data = await response.json();
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses.courseName, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
    }, []);


    //save service to db

    function addMoreServiceToDB(){
        try {
            HttpComponent({
                method:'POST',
                url:`/api/add_services`,
                body:{services:[
                    {
                        serviceName: formData.serviceName,
                        priceType: "Fixed",
                        categoryId: formData.categoryId,
                        amount:formData.amount,
                        description: formData.description,
                        grade: formData.grade,
                        term: formData.term,
                    }
                ]},
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 201){
                    setSuccessShow({state:true , message:'service added successfully'})
                    navigate(-1)
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })
        } catch (error) {
            setErrorShow({state:true , message:error?.message})
            
        }
    }



 

  return (
    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 , maxWidth: "600px", margin: "auto", marginTop: "40px" }}>
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <Box component="div" sx={{ display: "flex" }}>
        <Box>
          <CustomInputLabel required={true} label={"Service Name"} />
          <CustomTextField value={formData.serviceName} onChange={handleInputChange} name={"serviceName"} placeholder={"Service Name"} />

          <CustomInputLabel required={true} label={"Amount Type"} />
          {/*maybe be a disabled select or text field we will see*/}
          <CustomTextField value={formData.priceType} name={"priceType"} />
        </Box>

        <Box component="div" sx={{ marginLeft: 1 }}>
          <CustomInputLabel required={true} label={"Service Category"} />
          <CustomSelectField value={formData.categoryId} onChange={handleInputChange} name={"categoryId"} placeholder={"Select Service Category"} options={options} medium />

          <CustomInputLabel required={true} label={"Amount"} />
          <CustomTextField value={formData.amount} onChange={handleInputChange} name={"amount"} placeholder={"Amount"} />
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box component="div" sx={{}}>
          <CustomInputLabel required={true} label={ schoolTypeName?.includes("University") ? "Select Course" : "Select Grade"} />
       
          {schoolTypeName?.includes("University") ? (
            <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Course"} options={coursesOptions} />
          ) : (
          <Select labelId="Invoice Status" id="year-select" value={formData.grade === '' ? "Select Grade" : formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} style={{ width: "400px" }} label="Year" >
            <MenuItem value={"Select Grade"} disabled>
              { "Select Grade"}
            </MenuItem>
            
            {gradeOptions.map((item) => {
              return (
                <MenuItem value={item.schoolGrades}>{item.schoolGrades}</MenuItem>
              )
            })}

          </Select>
          )}

        </Box>
        <Box component="div" sx={{ marginLeft: 1 }}>
          <CustomInputLabel required={true} label={schoolTypeName?.includes("University") ? "Semesters" : "Term"} />
          <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={schoolTypeName?.includes("University") ? "Semesters" : "Term"} options={termOptions} />
        </Box>
      </Box>

      <Box>
        <CustomInputLabel required={false} label={"Service Description"} />
        <TextField
          name={"description"}
          value={formData.description}
          onChange={handleInputChange}
          placeholder={"Service Description"}
          variant="outlined"
          margin="dense"
          sx={{ marginBottom: 2 }}
          multiline
          minRows={3}
          InputProps={{
            sx: {
              width: "51rem",
              fontSize: "0.875rem",
              "& fieldset": {
                borderColor: "#bec5d1",
              },
            },
          }}
        />
      </Box>

      <Box component="div" sx={{ width: "100%", display: "flex", marginLeft: "55%" }}>
        <Box component="div">

          <Button
            onClick={()=>addMoreServiceToDB()}
            sx={{
              ...buttonStyles,
              border: " solid 1px #002543",
              color: "#002543",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#002543",
              },
            }}
            disabled={!formData.serviceName.trim() || !formData.categoryId.trim() || !formData.amount.trim()}
          >
            Save
          </Button>

          {/*<Button sx={{*/}
          {/*    ...buttonStyles,*/}
          {/*    marginLeft:1,*/}
          {/*    backgroundColor:" #032541",*/}
          {/*    color:"white",*/}
          {/*    '&:hover': {*/}
          {/*        backgroundColor: '#032541',*/}
          {/*        color: 'white'*/}
          {/*    }*/}
          {/*}}*/}
          {/*onClick={addToList}*/}
          {/*disabled={!formData.serviceName.trim() || !formData.categoryId.trim() || !formData.amount.trim()}*/}
          {/*>*/}
          {/*    Add Another*/}
          {/*</Button>*/}
        </Box>
      </Box>
    </Box>
  );
};

export default AddMoreServiceToStudent;
