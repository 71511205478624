import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, {useState, useEffect} from "react";
import {Modal, Grid, Box, Button, Accordion, AccordionSummary, AccordionDetails, Checkbox} from "@mui/material";
import {useNavigate, useParams} from 'react-router-dom';

const SelectedTenant = (props) => {
	
	console.log(props.billableItems, `Billable Items `)
	const [selectedOpen, setSelectedOpen] = useState(props.selectedOpen);
	const [billableItems, setBillableItems] = useState(props.billableItems)
	const [customerId , setCustomerId] = useState(props.customerId)
	const [extraService, setExtraService] = useState([])
	const [invoiceType, setInvoiceType] = useState("Invoice Type");
	const [repeatType, setRepeatType] = useState("Select Schedule")
	const baseUrl = process.env.REACT_APP_BASE_URL;
	const navigate = useNavigate();
	const { id } = useParams();
	const [tenantId, setTenantId] = useState('')
	const [tenant, setTenant] = useState(props.tenantName)
	
	// Accordion Setters and Getters
	const [expanded, setExpanded] = useState(null);
	const handleChange = (id) => (event, isExpanded) => {
		setExpanded(isExpanded ? id : false);
	};
	const handleTenantClick = (id) => {
		setTenantId(id)
	}
	
	const handleClick = () =>{
		fetchUtilitiesById()
		setSelectedOpen(false)
	}
	const saveAndRoute = (object) => {
		localStorage.setItem("invoice_data", JSON.stringify(object));
		navigate("/add_invoice_items");
		props.onClose()
	}
	
	const wholeModal = {
		width: '500px',
		height: '477px',
		backgroundColor: '#fff',
		borderColor:"transparent",
		borderRadius: "7px",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	};
	const headerSection = {
		backgroundColor: '#dc3545',
		width: '100%',
		borderTopLeftRadius: "7px",
		borderTopRightRadius: "7px",
		height: '109px',
		flexDirection: "row",
		alignContent: "center",
		justifyItems: "center",
		textAlign: "center",
	}
	const secondaryInvoice = {
		border: "solid 1px #dc3545",
		backgroundColor:"transparent",
		color:"#dc3545",
		width: "75px",
		height: "45px",
		margin:"5px"
	}
	const primaryInvoice = {
		backgroundColor:"#032541",
		color:"white",
		width: "225px",
		height: "45px",
		margin:"5px"
	}
	
	useEffect(() => {
		setSelectedOpen(props.selectedOpen);
	}, [props.selectedOpen]);
	
	useEffect(() => {
		setBillableItems(props.billableItems);
	}, [props.billableItems]);
	
	useEffect(() => {
		setExtraService(props.extraService);
	}, [props.extraService]);
	
	
	useEffect(() => {
		setTenant(props.tenantName);
	}, [props.tenantName]);
	
	useEffect(() => {
		setCustomerId(props.customerId);
	}, [props.customerId]);
	
	const fetchUtilitiesById = async() => {
		try {
			const tenantDetailsResponse = await fetch(baseUrl + `/api/get_extra_services_by_customer_id?page=1&limit=10`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization")},
					body : JSON.stringify({
						"customerId": customerId
					})
				}
			)
			await tenantDetailsResponse.json()
				.then((data) => {console.log(`Tenant Data`, data); setExtraService(data.data)})
				.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		} catch (error) {
			console.log(error.message);
		}
	};
	
	return (
		<div style={{fontFamily : 'Poppins'}} >
			<Modal open={selectedOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={wholeModal}>
					<div style={headerSection}>
						<div style={{marginLeft: "auto", marginRight: "auto", color: "white", fontWeight: "bold", fontSize: 25, paddingTop:"40px"}}>
							Select Tenant Units
						</div>
					</div>
					
					<Grid container direction={'column'} justifyContent={'center'} alignContent={'center'}>
						
						{/*Search Box*/}
						<Grid item>
							<TextField style={{marginTop : "5%", fontSize : '10px', fontFamily : 'Poppins', "width":"415px","height":"45px","borderRadius":"6px"}} size="small" variant="outlined" placeholder={'Search unit'} InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon style={{width :'15px', height : '15px'}} /></InputAdornment>),}}/>
						</Grid>
						
					</Grid>
					
					<Grid container alignContent={'center'} justifyContent={'center'}>
						{/*Accordion*/}
						<Grid item style={{width : "415px"}}>
							<Accordion expanded={expanded === 'x'} onChange={handleChange('x')} onClick={() => {handleTenantClick('x');}}>
								<AccordionSummary expandIcon={<ArrowForwardIosSharpIcon />}>
									<Typography sx={{"fontFamily":"Poppins","fontSize":"15px","fontWeight":"600","fontStretch":"normal","fontStyle":"normal","lineHeight":"2.2","letterSpacing":"normal","textAlign":"left","color":"#53365c"}} >{tenant}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid container direction={'column'} justifyContent={'flex-start'}>
										<Grid item>
											{
												billableItems?.map((item, index) => (
													<FormControlLabel key={index} control={<Checkbox />} label={item.itemNumber} />
												))
											}
										</Grid>
									</Grid>

								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
					
					<div style={{display:"flex", justifyContent:"center"}}>
						<div style={{marginTop:"20px"}}>
							
							{/*Buttons*/}
							<div style={{display:"flex", marginTop:"20%", justifyContent:"center"}}>
								<Button style={secondaryInvoice} onClick={props.onClose}>Close</Button>
								<Button style={primaryInvoice} onClick={()=>handleClick()}>Select</Button>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};
export { SelectedTenant };
