import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpComponent from "../../School/MakeRequest";
import { warehousestyles } from "../styles";
import { Reactivatesuppliermodal } from "../modals";
import restore from "../../../common/images/restore-icn.svg";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { Button, Grid } from "@mui/material";

const Suspendedwarehouse = (props) => {
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const getSuspendedWarehouses = () => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=Warehouse&status=INACTIVE&page=${pageState.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, total: data.response.count });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getSuspendedWarehouses();
  }, [pageState.page, dataGridPageSize]);

  //   reactivate warehouse;
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [reactivateSupplier, setReactivateSupplier] = useState(false);
  const [reactivateWarehouseName, setReactivateWarehouseName] = useState("");
  const [reactivateWarehouseId, setReactivatewarehouseId] = useState("");
  const [warehousestobesuspended, setWarehouseToBesuspended] = useState([]);


  const getWarehouseDetails = (warehouseData) => {
    const { warehouseName, warehouseId } = warehouseData;

    if (warehouseName) {
      setReactivateSupplier(true);
      setReactivateWarehouseName(warehouseName);
      setReactivatewarehouseId(warehouseId);
    }
  };

  const reactivateWarehouseChecker = () => {
    if(warehousestobesuspended.length > 1){
        setReactivateSupplier(true);
    }
  }

  const reactivateWarehouse = () => {
    HttpComponent({
      method: "POST",
      url: `/api/deactivate_or_activate_stores?action=active`,
      body: { storeIds: reactivateWarehouseId ? [reactivateWarehouseId] : warehousestobesuspended },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 202) {
          setReactivateSupplier(false);
          getSuspendedWarehouses();
          setSucess({ state: true, message: "warehouse successfully activated!" });
        }

        setTimeout(() => {
          setSucess({ state: false });
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const searchWarehouse = () => {
    if(props?.searchValue)
    HttpComponent({
      method:'GET',
      url:`/api/v1/searchByStoreType?page=${pageState?.page}&limit=${dataGridPageSize}&status=INACTIVE&storeType=Warehouse&searchValue=${props?.searchValue}`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      if (data.status === 200) {
  
        setPageState({ ...pageState, isLoading: false, data: data.response.data, total: 0 });
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(() => {
    if(!props?.searchValue)getSuspendedWarehouses()
    searchWarehouse();
  }, [props?.searchValue])

  return (
    <div>
      <DataGrid
        style={warehousestyles.dataGridStyling}
        rows={pageState?.data?.map((supplier) => ({
          id: supplier?._id,
          username: supplier?.name,
          updatedAt: new Date(supplier?.updatedAt).toDateString() + "" + new Date(supplier?.updatedAt).toLocaleTimeString(),
          location: supplier?.location,
        }))}
        columns={[
          { field: "username", headerName: "Name", flex: 1 },
          { field: "location", headerName: "Location" },
          { field: "updatedAt", headerName: "Disabled On", flex: 1 },
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {
              return (
                <>
                  <div>
                    <img onClick={() => getWarehouseDetails({ warehouseName: params.row.username, warehouseId: params.row.id })} style={{ width: "30px", height: "30px", cursor: "pointer" }} src={restore} alt="restore" />
                  </div>
                </>
              );
            },
            flex: 1,
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableRowSelectionOnClick={true}
        onSelectionModelChange={(ids) => {
            setWarehouseToBesuspended(ids);
      
        }}
      />

      <Grid container width={"100%"}>
        <Grid item width={"100%"}>
          <div style={warehousestyles.suspendBtnRightAligned}>
            {warehousestobesuspended?.length > 1 && (
              <Button style={warehousestyles.reactivateBtn} onClick={() => (warehousestobesuspended?.length > 1 ? reactivateWarehouseChecker() : null)}>
                Enable {warehousestobesuspended?.length}{" "}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      {reactivateSupplier && <Reactivatesuppliermodal reactivateSupplier={reactivateSupplier} setReactivateSupplier={setReactivateSupplier} reactivateWarehouseName={reactivateWarehouseName} reactivateWarehouse={reactivateWarehouse} />}
      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
    </div>
  );
};

export default Suspendedwarehouse;
