import {Box, Button, TextField, Typography} from "@mui/material";
import React from 'react';
import happy from "./Images/happy-icn.svg"

const WeCanHelp = () => {
    const buttonWidthHeight = {
        width:"9.063rem",
        height:"3.125rem",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }
    return (
       <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
           <img src={happy} style={{width:"5rem", height:"5rem", alignSelf:"center", marginY:1}} alt=""/>
           <Typography sx={{color:"#17ae7b", fontSize:"1.125rem",alignSelf:"center",marginY:1}}>
               We can help!
           </Typography>

           <Typography sx={{color:"#707070", fontSize:"0.875rem",alignSelf:"center",marginY:1}}>
               We will get in touch on setting up <br/> Mpesa Daraja/Portal for you.
           </Typography>

           <Button sx={{...buttonWidthHeight,alignSelf:"center",backgroundColor: "#032541", color:"white",marginY:1}}>
               Okay
           </Button>
       </Box>
    );
};

export default WeCanHelp;

