import React , {useState, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import session from "../../../common/images/session.svg";
import Button from "@mui/material/Button";

function SessionModal(props){

    // session Expired
    const style = {borderColor:"transparent", borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "350px", p: 4,};

    const handleLogin = () => {window.location.href = '/logout'}

    return (
        <div>
            {/*session expired modal*/}
            <Modal
                open={props.sessionOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div style={{width: "50px", height: "50px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
                        <img style={{width:"115px", height:"140px",margin: "-34px 0px 0px -66px"}} src={session} alt='img'/>
                    </div>
                    <div style={{height: "50px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
                        <span>
                            Your Session has expired and you have been logged out.
                        </span>
                    </div>
                    <div align='center' style={{height: "61.7px", margin: "41px 0px 0px 0px"}}>
                        <Button onClick={props.onClose} sx={{fontFamily: "Poppins", margin: "6px 0px 0px 0px", fontSize: "12px", fontWeight: "600", color: "#dc3545",width: "100.8px", height: "50.7px", padding: "19.1px 44.6px 18.5px 41.1px", border: "solid 1px #dc3545"}}>
                            Cancel
                        </Button>
                        <Button onClick={handleLogin} sx={{fontFamily: "Poppins", fontSize: "12px", fontWeight: "600", color: "#fff",width: "100.8px", height: "50.7px", margin: "0 0 0 62.2px", padding: "19px 32.1px 18.7px 33px", backgroundColor: "#032541"}} >
                            Login
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export {SessionModal};