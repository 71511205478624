import React, { useState } from "react";
import Breadcrumbcomponent from "../products/stockComponents/breadcrumb";
import { styled } from "@mui/material/styles";
import {  Grid, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PendingVoids from "./Reportsmodals/pendingVoids";
import ApprovedVoids from "./Reportsmodals/approvedVoids";
import DeclinedVoids from "./Reportsmodals/declinedVoids";
import Transactions from "./Reportsmodals/transactions";

// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid white", "& .MuiTabs-indicator": { backgroundColor: "#ffffff" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({ textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 }, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "18px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": { color: "#6e7074", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 }, "&.Mui-focusVisible": { backgroundColor: "blue" } }));

const VoidTransaction = () => {


  const breadcrumbs = [
    <span style={{ color: "#707070", fontSize: "18px" }} key={1}>
      Dashboard
    </span>,
    <span style={{ color: "#dc3545", fontSize: "18px" }}>Void Transactions Report</span>,
  ];

  const [tabvalue, setTabValue] = useState("all")
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
  const handleTabValueChange = (value, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Grid container margin={"0px 0px 2% 0px"} width={'100%'}>
        <span style={{ color: "#032541", fontWeight: "bold", fontSize: "25px" }}> Void Transactions Reports </span>
      </Grid>
      <Grid container margin={"0px 0px 2% 0px"} width={'100%'}>
        <Breadcrumbcomponent breadcrumbs={breadcrumbs} />
      </Grid>

      <Grid container margin={"0px 0px 2% 0px"} width={'100%'}>
        <Grid item></Grid>
        <Grid item width={'100%'}>
          <TabContext value={tabvalue}>
            <AntTabs onChange={handleTabValueChange}>g
              <AntTab value="all" label="All" />
              <AntTab value="pending" label="Pending Approval" />
              <AntTab value="approved" label="Approved" />
              <AntTab value="declined" label="Declined" />
            </AntTabs>
            <TabPanel value="all">
                <Transactions setCsvExport={setCsvExport} exportData={exportData} activeTabs={tabvalue} csvExport={csvExport} setExportData={setExportData} />
            </TabPanel>
            <TabPanel value="pending">
                <PendingVoids  setCsvExport={setCsvExport} exportData={exportData} tabvalue={tabvalue} csvExport={csvExport}  setExportData={setExportData}  />
            </TabPanel>
            <TabPanel value="approved">
                <ApprovedVoids  setCsvExport={setCsvExport} setExportData={setExportData} tabvalue={tabvalue} csvExport={csvExport} exportData={exportData}   />
            </TabPanel>
            <TabPanel value="declined">
              <DeclinedVoids  setCsvExport={setCsvExport} setExportData={setExportData}  tabvalue={tabvalue} csvExport={csvExport} exportData={exportData}   />   
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default VoidTransaction;
