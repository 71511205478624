import { Box, Button, Grid, List, ListItem, Modal, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import HttpComponent from "../School/MakeRequest";
import ZedaccImg from '../../images/zedacc.svg'
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ArrowBackIos } from "@mui/icons-material";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL


const zedAccountingmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 2,
}


const AccountingConfig = () => {
    const [openZedAccountingModal, setOpenZedAccountingModal] = useState(false);
    const [openXeroAccountingModal, setXeroAccountingModal] = useState(false);
    const [accountStatus, setAccountStatus] = useState()
    const [xeroStatus, setXeroStatus] = useState()
    const [zohoStatus,setZohoStatus] = useState()
    const [openZohoAccountingModal, setZohoAccountingModal] = useState(false);
    const [webHookStatus, setWebHookStatus] = useState(false)
    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [message, setMessage] = useState('')
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [webHook, setWebHook] =  useState('')
    const [showClientId, setShowClientId] = useState(false);
    const [showClientSecret, setShowClientSecret] = useState(false);
    const [showWebHook, setShowWebHook] = useState(false);
    const [originalClientSecret, setOriginalClientSecret] = useState('');
    const [originalClientId, setOriginalClientId] = useState('');
    const [originalWebHook, setOriginalWebHook] = useState('');
    const [zohoClientId, setZohoClientId] = useState('')
    const [zohoClientSecret, setZohoSecret] = useState('')
    const [zohoWebhookKey, setZohoWebhook] = useState('')
    const [originalZohoClientSecret, setOriginalZohoClientSecret] = useState('');
    const [originalZohoClientId, setOriginalZohoClientId] = useState('');
    const [originalZohoWebHook, setOriginalZohoWebHook] = useState('');
    const [showZohoClientId, setZohoShowClientId] = useState(false);
    const [showZohoClientSecret, setZohoShowClientSecret] = useState(false);
    const [showZohoWebHook, setZohoShowWebHook] = useState(false);


    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };

    const [zedAccountingStatus, setZedAccountingStatus] = useState(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        return savedStatus ? JSON.parse(savedStatus) : false;
    })

    const maskClientId = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setClientId(maskedValue);
        setOriginalClientId(value); 
        return maskedValue;
    }; 
    
    const maskClientSecret = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setClientSecret(maskedValue);
        setOriginalClientSecret(value); 
        return maskedValue;
    }; 

    const maskWebhook = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setWebHook(maskedValue);
        setOriginalWebHook(value); 
        return maskedValue;
    };

    const maskZohoClientId = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setZohoClientId(maskedValue);
        setOriginalZohoClientId(value); 
        return maskedValue;
    }; 
    
    const maskZohoClientSecret = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setZohoSecret(maskedValue);
        setOriginalZohoClientSecret(value); 
        return maskedValue;
    }; 

    const maskZohoWebhook = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
        setZohoWebhook(maskedValue);
        setOriginalZohoWebHook(value); 
        return maskedValue;
    };


    const toggleClientIdVisibility = () => {
        setShowClientId(!showClientId);
        if (!showClientId) {
            setClientId(originalClientId);
        } else {
            setClientId(maskClientId({ target: { value: originalClientId } }));
        }
    };
    
    const toggleClientSecretVisibility = () => {
        setShowClientSecret(!showClientSecret);
        if (!showClientSecret) {
            setClientSecret(originalClientSecret);
        } else {
            setClientSecret(maskClientSecret({ target: { value: originalClientSecret } }));
        }
    };
    
    const toggleWebhookVisibility = () => {
        setShowWebHook(!showWebHook);
        if (!showWebHook) {
            setWebHook(originalWebHook);
        } else {
            setWebHook(maskWebhook({ target: { value: originalWebHook } }));
        }
    };

    const toggleZohoClientIdVisibility = () => {
        setZohoShowClientId(!showZohoClientId);
        if (!showZohoClientId) {
            setZohoClientId(originalZohoClientId);
        } else {
            setZohoClientId(maskZohoClientId({ target: { value: originalZohoClientId } }));
        }
    };
    
    const toggleZohoClientSecretVisibility = () => {
        setZohoShowClientSecret(!showZohoClientSecret);
        if (!showZohoClientSecret) {
            setZohoSecret(originalZohoClientSecret);
        } else {
            setZohoSecret(maskZohoClientSecret({ target: { value: originalZohoClientSecret } }));
        }
    };
    
    const toggleZohoWebhookVisibility = () => {
        setZohoShowWebHook(!showZohoWebHook);
        if (!showZohoWebHook) {
            setZohoWebhook(originalZohoWebHook);
        } else {
            setZohoWebhook(maskZohoWebhook({ target: { value: originalZohoWebHook } }));
        }
    };

    const handleOpenZedAccountingModal = () => {
        setOpenZedAccountingModal(true)
    }
    const handleCloseZedAccountingModal = () => {
        if (zedAccountingStatus === true) {
            return;
        }
        setOpenZedAccountingModal(false);
        setTimeout(()=>{
            window.location.reload()
        },[1000])
    }

    const handleCancelClick = () => {
        setXeroAccountingModal(false);
    };

    const handleZohoClick = () =>{
        setZohoAccountingModal(false)
    }

    const swicthAccounting = () => {
        if (accountStatus) {
            handleCloseZedAccountingModal()
        } else if (!accountStatus) {
            handleOpenZedAccountingModal()
        }

    }

    const handleXeroSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await HttpComponent({
                method: 'post',
                url: '/api/v1/business/config/xero',
                body: {
                    clientId: originalClientId,
                    clientSecret: originalClientSecret,
                    webHookKey: originalWebHook
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                console.log(data);
                if (data.response.Status === "SUCCESS") {
                    // localStorage.setItem('clientId', originalClientId);
                    // localStorage.setItem('clientSecret', originalClientSecret);
                    // localStorage.setItem('webHook', originalWebHook);
    
                    setMessage('Xero Accounting enabled successfully, click xeroAccounting icon to proceed');
                    handleSuccessNotificationClick();
                    window.location.assign(data.response.redirect_url); 
                } else {
                    console.error('Error enabling Xero Accounting:', error);
                    setMessage('Failed to enable Xero Accounting');
                }
            });
        } catch (error) {
            // Handle the error
        }
    };

    const handleZohoSubmit = async(event) =>{
        event.preventDefault();
        try {
            const response = await HttpComponent({
                method: 'post',
                url: '/api/v1/zoho/auth/zoho_config',
                body: {
                    clientId: originalZohoClientId,
                    clientSecret: originalZohoClientSecret,
                    webHookKey: originalZohoWebHook
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                console.log(data);
                if (data.response.Status === "SUCCESS") {
                    setMessage('Zoho Accounting enabled successfully');
                    handleSuccessNotificationClick();
                    window.location.assign(data.response.redirect_url); 
                } else {
                    console.error('Error enabling zoho Accounting:', error);
                    setMessage('Failed to enable zoho Accounting');
                }
            });
        } catch (error) {
            // Handle the error
        }
        
    }

    useEffect(() => {
        const storedClientId = localStorage.getItem('clientId'); 
        const storedClientSecret = localStorage.getItem('clientSecret'); 
        const storedWebHook = localStorage.getItem('webHookKey'); 
        const zohoId = localStorage.getItem('zhClientId'); 
        const zohoSecret = localStorage.getItem('zhSecret'); 
        const zohoWebHook = localStorage.getItem('zhWebhookKey'); 
       

        const isValid = (value) => value && value !== 'undefined';
        const maskedClientId = isValid(storedClientId) ? maskClientId({ target: { value: storedClientId } }) : '';
        const maskedClientSecret = isValid(storedClientSecret) ? maskClientSecret({ target: { value: storedClientSecret } }) : '';
        const maskedWebhook = isValid(storedWebHook) ? maskWebhook({ target: { value: storedWebHook } }) : '';

        const maskedZohoClientId = isValid(zohoId) ? maskClientId({ target: { value: zohoId } }) : '';
        const maskedZohoClientSecret = isValid(zohoSecret) ? maskClientSecret({ target: { value: zohoSecret } }) : '';
        const maskedZohoWebhook = isValid(zohoWebHook) ? maskWebhook({ target: { value: zohoWebHook } }) : '';


        setClientId(maskedClientId);
        setOriginalClientId(storedClientId || '');
        setClientSecret(maskedClientSecret);
        setOriginalClientSecret(storedClientSecret || '');
        setWebHook(maskedWebhook);
        setOriginalWebHook(storedWebHook || '');
        setZohoClientId(maskedZohoClientId|| '')
        setOriginalZohoClientId(zohoId || '')
        setZohoSecret(maskedZohoClientSecret || '')
        setOriginalZohoClientSecret(zohoSecret || '')
        setZohoWebhook(maskedZohoWebhook || '')
        setOriginalZohoWebHook(zohoWebHook || '')
    }, []);
 

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data?.response?.data?.accountingEnabled)
                    setXeroStatus(data?.response?.data?.xeroAccountingEnabled)
                    setWebHookStatus(data?.response?.data?.xeroWebHookIntentStatus)
                    setZohoStatus(data?.response?.data?.zohoAccountingEnabled)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAccountingStatus()
    }, [])



    //enable accounting
    function handleEnableAccounting() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enable_accounting?status=true',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async(data) => {
                //console.log(data, 'acc status')
               
                if (data.status === 201) {
                    setMessage('Zed Accounting enabled successfully , click zedAccounting icon to proceed')
                    handleSuccessNotificationClick();
                    setZedAccountingStatus(data.response.data.accountingEnabled)
                    localStorage.setItem('zedAccountingStatus', true);
                }
            }).then(async () => {
                const newT = await HttpComponent({
                    method: "POST",
                    url: `/api/get_token_after_invite`,
                    token: localStorage.getItem('X-Authorization')
                })
                const result = newT?.response?.data?.token
                const decodedT = jwtDecode(result)
                localStorage.setItem('X-Authorization', result);
                localStorage.setItem('zedAccountingId', decodedT?.zedAccountingId);
                setTimeout(() => {
                    handleCloseZedAccountingModal()
                    window.location.reload()
                }, 1000);
            })
        } catch (error) {

        }
    }

    return (
        <div>
            { openXeroAccountingModal ? '' : (openZohoAccountingModal ? '' :
                <Grid container item className={"container-fluid"}>
                    {/*Page Title Container*/}
                    <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                        <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                    </Grid>

                    {/*Page Sub title and Search */}
                    <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                        <Grid item>
                            <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                                <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                                <span style={{ color: "#032541" }}>Accounting Config</span>
                            </div>
                        </Grid>

                        {/*Search input*/}
                        <Grid item>
                            <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
                            <TextField
                                size="small"
                                placeholder={"search"}
                                style={{ height: "1px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ width: "15px", height: "15px" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </Grid>
                    </Grid>

                    {/*Headers*/}
                    <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Configuration
                        </Grid>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Action
                        </Grid>
                    </Grid>
                    <Grid container style={{ width: "86%", marginTop: "30px" }}>
                        <Grid item style={{ width: "100%" }}>
                            <List>
                                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Accounting</Box>
                                        <Box>
                                            <Switch checked={accountStatus} onClick={swicthAccounting} />
                                        </Box>
                                    </Box>
                                </ListItem>
                                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Xero Accounting</Box>
                                        <Box>
                                            <Switch checked={xeroStatus} onClick= {() => setXeroAccountingModal(true)} />
                                        </Box>
                                    </Box>
                                </ListItem>
                                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Zoho Accounting</Box>
                                        <Box>
                                            <Switch checked={zohoStatus} onClick= {() => setZohoAccountingModal(true)} />
                                        </Box>
                                    </Box>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Modal
                open={openZedAccountingModal} onClose={handleCloseZedAccountingModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={zedAccountingmodal}>
                    <form>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={ZedaccImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Accounting </Box>
                                {/* <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Xero Accounting </Box> */}
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070" }}>
                                        Enabling accounting  will allow the business to:
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Manage financial transactions</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare budget forecasts.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements..</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish financial statements in time.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile accounts payable and receivable..</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleCloseZedAccountingModal

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={handleEnableAccounting}>Enable</Button>
                                    </Box>

                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Modal>
            {openXeroAccountingModal &&
                <>
                    <IconButton style={{ top: '40px', right: '1px', left:'5px' }} onClick={handleCancelClick}>
                        <ArrowBackIos />
                    </IconButton>
                    <Box style={{ display: "flex", justifyContent: "" }}>
                        <Box style={{ marginLeft: "35px" }}>
                            <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Enable Xero Accounting</Box>
                            <Box>
                                <p style={{ fontSize: '14px', color: "#707070", width: '475px', height: '41px', margin: '23px 599px 20px 3px' }}>
                                    Enabling Xero accounting will allow the business to manage financial transactions.
                                </p>
                            </Box>
                            <ul style={{ listStyleType: "none", padding: 0, marginLeft: 0 }}>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements.</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare Budget Forecasts</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish Financial statements in time</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", display: "inline-block", fontSize: '24px', width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile Accounts Payable and receivables</p>
                                </li>
                            </ul>
                            <form onSubmit={handleXeroSubmit}>
                                <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                    <label htmlFor="clientId" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client ID:<span style={{ color: 'red' }}>*</span></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" id="clientId" name="clientId" placeholder="Client ID" value={clientId} onChange={maskClientId} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                        <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleClientIdVisibility}>
                                            {showClientId ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                </Box>

                                <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                    <label htmlFor="clientSecret" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client Secret Key<span style={{ color: 'red' }}>*</span></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" id="clientSecret" name="clientSecret" placeholder="Client Secret" value={clientSecret} onChange={maskClientSecret} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px", paddingRight: "40px" }} />
                                        <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleClientSecretVisibility}>
                                            {showClientSecret ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                </Box>

                                <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                    <label htmlFor="webhook" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Webhook Key<span style={{ color: 'red' }}>*</span></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" id="webhook" name="webhook" placeholder="WebHook Key" value={webHook} onChange={maskWebhook} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                        <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleWebhookVisibility}>
                                            {showWebHook ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                </Box>

                                <Box style={{ display: "flex", marginTop: "15px" }}>
                                    <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelClick}>Cancel</Button>
                                    <Box marginLeft="20px">
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Enable</Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </>
            }
            {openZohoAccountingModal &&
               <>
               <IconButton style={{  top: '40px', right: '1px', left:'5px'  }} onClick={handleCancelClick}>
                   <ArrowBackIos />
               </IconButton>
               <Box style={{ display: "flex", justifyContent: "" }}>
                   <Box style={{ marginLeft: "35px" }}>
                       <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Enable Zoho Accounting</Box>
                       <Box>
                           <p style={{ fontSize: '14px', color: "#707070", width: '475px', height: '41px', margin: '23px 599px 20px 3px' }}>
                               Enabling Zoho accounting will allow the business to manage financial transactions.
                           </p>
                       </Box>
                       <ul style={{ listStyleType: "none", padding: 0, marginLeft: 0 }}>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements.</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare Budget Forecasts</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish Financial statements in time</p>
                           </li>
                           <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                               <span style={{ marginLeft: "10px", color: "#032541", display: "inline-block", fontSize: '24px', width: "10px" }}>•</span>
                               <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile Accounts Payable and receivables</p>
                           </li>
                       </ul>
                       <form onSubmit={handleZohoSubmit}>
                           <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                               <label htmlFor="clientId" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client ID:<span style={{ color: 'red' }}>*</span></label>
                               <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <input type="text" id="zohoClientId" name="zohoClientId" onChange={maskZohoClientId} placeholder="Client ID" value={zohoClientId} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                   <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleZohoClientIdVisibility}>
                                       {showZohoClientId ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </div>
                           </Box>

                           <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                               <label htmlFor="clientSecret" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client Secret Key<span style={{ color: 'red' }}>*</span></label>
                               <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <input type="text" id="zohoClientSecret" name="zohoClientSecret" placeholder="Client Secret" value={zohoClientSecret} onChange={maskZohoClientSecret} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px", paddingRight: "40px" }} />
                                   <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleZohoClientSecretVisibility}>
                                       {showZohoClientSecret ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </div>
                           </Box>

                           <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                               <label htmlFor="webhook" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Webhook Key<span style={{ color: 'red' }}>*</span></label>
                               <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <input type="text" id="zohoWebhookKey" name="zohoWebhookKey" placeholder="WebHook Key" value={zohoWebhookKey} onChange={maskZohoWebhook} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                   <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleZohoWebhookVisibility}>
                                       {showZohoWebHook ? <Visibility /> : <VisibilityOff />}
                                   </IconButton>
                               </div>
                           </Box>

                           <Box style={{ display: "flex", marginTop: "15px" }}>
                               <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelClick}>Cancel</Button>
                               <Box marginLeft="20px">
                                   <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Enable</Button>
                               </Box>
                           </Box>
                       </form>
                   </Box>
               </Box>
           </>
            }
            <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
        </div>
    );
};

export { AccountingConfig };
