import {Box, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Img404 from "../common/images/404.jpg"
const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div>
			<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
				<Container maxWidth="md">
					<Grid container spacing={2} justifyContent={'center'} alignContent={'center'}>
						<Grid item>
							<img src={Img404} alt="" width={950} height={700}/>
						</Grid>
						<Grid item>
							<Typography variant="h6" style={{fontFamily : 'Poppins'}}>
								The page you’re looking for doesn’t exist.
							</Typography>
							<div align={'center'} >
								<Button style={{background : '#032541FF'}} onClick={() => navigate('/Dashboard')} variant="contained">Back Home</Button>
							</div>
						</Grid>
					</Grid>
				</Container>
			</Box>

		</div>
	)
}
export {NotFound}