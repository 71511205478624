import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpComponent from "../../School/MakeRequest";
import { warehousestyles } from "../styles";
import { Reactivatesuppliermodal } from "../modals";
import restore from "../../../common/images/restore-icn.svg";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { Button, Grid } from "@mui/material";


const Suspendedsubstores = (props) => {
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const getSupendedSubstores = () => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=Substore&status=INACTIVE&page=${pageState.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, total: data.response.count });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getSupendedSubstores();
  }, [pageState.page, dataGridPageSize]);

  //   reactivate ssubstore;
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [reactivateSupplier, setReactivateSupplier] = useState(false);
  const [substoresToBeReactivated, setSubstoredToBeReactivated] = useState([]);
  const [reactivateSubstoreName, setReactSubstoreName] = useState("")
  const [reactivateSubstoreId, setReactSubstoreId] = useState("")
  const getsubstoredetails = (substoredetails) => {

    const {substoreName, substoreId } = substoredetails

    if(substoreName){
        setReactivateSupplier(true)
        setReactSubstoreName(substoreName)
        setReactSubstoreId(substoreId)
    }
  }

  const reactivateSubstorechecker = () => {

    if(substoresToBeReactivated.length > 1){
        setReactivateSupplier(true)
    }
  }

  const reactivateSubstores = () => {
    HttpComponent({
      method: "POST",
      url: `/api/deactivate_or_activate_stores?action=active`,
      body: { storeIds: reactivateSubstoreId ? [reactivateSubstoreId] : substoresToBeReactivated },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 202) {
          setReactivateSupplier(false);
          getSupendedSubstores();
          setSucess({ state: true, message: "warehouse successfully activated!" });
        }

        setTimeout(() => {
          setSucess({ state: false });
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const searchSubstore = () => {
    if(props?.searchValue)
    HttpComponent({
      method:'GET',
      url:`/api/v1/searchByStoreType?page=${pageState?.page}&limit=${dataGridPageSize}&status=INACTIVE&storeType=Substore&searchValue=${props?.searchValue}`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      if (data.status === 200) {
  
        setPageState({ ...pageState, isLoading: false, data: data.response.data, total: 0 });
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(() => {
    if(!props?.searchValue)getSupendedSubstores()
    searchSubstore();
  }, [props?.searchValue])
  
  return (
    <div>
      <DataGrid
        style={warehousestyles.dataGridStyling}
        rows={pageState?.data?.map((supplier) => ({
          id: supplier?._id,
          username: supplier?.name,
          branchName: supplier?.branchName,
          storeType: supplier?.storeType,
          location: supplier?.location,
        }))}
        columns={[
          { field: "username", headerName: "Name", flex: 1 },
          { field: "branchName", headerName: "Branch Assigned To", flex: 1 },
          { field: "storeType", headerName: "Store type", flex: 1 },
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {
              return (
                <>
                   <div>
                    <img onClick={() => getsubstoredetails({ substoreName: params.row.username, substoreId: params.row.id })} style={{ width: "30px", height: "30px", cursor: "pointer" }} src={restore} alt="restore" />
                  </div>
                </>
              );
            },
            flex: 1,
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableRowSelectionOnClick={true}
        onSelectionModelChange={(ids) => {
            setSubstoredToBeReactivated(ids);
        }}
      />

      <Grid container width={"100%"}>
        <Grid item width={"100%"}>
          <div style={warehousestyles.suspendBtnRightAligned}>
            {substoresToBeReactivated?.length > 1 && (
              <Button style={warehousestyles.reactivateBtn} onClick={() => (substoresToBeReactivated?.length > 1 ? reactivateSubstorechecker() : null)}>
                Enable {substoresToBeReactivated?.length}{" "}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      {reactivateSupplier && <Reactivatesuppliermodal reactivateSupplier={reactivateSupplier} setReactivateSupplier={setReactivateSupplier} reactivateSubstores={reactivateSubstores} reactivateSubstoreName={reactivateSubstoreName} substoresToBeReactivated={substoresToBeReactivated} />}
      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
    </div>
  );
};

export default Suspendedsubstores;
