import React from "react";
import { ReactSVG } from "react-svg";
import MySVG1 from "./zedPocket-iconRED.svg";
import MySVG2 from "./zedPocket-iconBLUE.svg";

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG =
  currentPath === "/Outlet" || currentPath === "/CardBins" || currentPath === "/DeviceModels" || currentPath === "/EditDeviceModel" || currentPath === "/terminal/mngt" || currentPath === "/TermUsers"
      ? MySVG1
      : MySVG2;

  return <ReactSVG src={MySVG} />;
}

export default MySVGComponent;
