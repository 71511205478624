import React, { useState, useEffect } from "react";
import Category from "./category";
import { Button, Grid } from "@mui/material";
import StockTakeProducts from "./stockTakeproducts";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { stockStyles } from "../stockStyling";
import { useNavigate } from "react-router-dom";

const CategoryAndProductsStockTake = ({ wareHouseId }) => {
  const navigate = useNavigate();

  const [categoryId, setCategoryId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [productvalue, setProductValue] = useState("")

  const updateStockTakeItems = () => {
    const stockItems = JSON.parse(localStorage.getItem("stockTakeItems"));
    if (stockItems) {
      navigate(`/stockTake/?${"viewBatch"}?${wareHouseId}`);
    }
  };

  const clearStage = () => {
    localStorage.removeItem("stockTakeItems")
    navigate(`/stockTake`)
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} margin={"0% 0% 1% 0%"}>
        <Grid item sx={{ width: "30%" }}>
          <SearchFilter placeholder={'Search Categories'} setInputValue={setInputValue} />
        </Grid>
        <Grid item sx={{ width: "50%", display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        <SearchFilter placeholder={'Search products'} setInputValue={setProductValue} />
          <Button style={stockStyles?.cancelButton} onClick={clearStage}>Cancel</Button>
          <Button style={stockStyles?.viewBatchButton} onClick={() => updateStockTakeItems()}>
            View Batch
          </Button>
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} spacing={2}>
        <Grid item xs={4}>
          <Category inputValue={inputValue} setCategoryId={setCategoryId} />
        </Grid>

        <Grid item xs={8}>
          <StockTakeProducts productvalue={productvalue} categoryId={categoryId} wareHouseId={wareHouseId}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryAndProductsStockTake;
