import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import PocketMoneyCard from "./PocketMoneyCard";
import KCBPocketMoney from "./KCBPocketmoney";

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 45,
  height: 23,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BankPocketmoney = ({cardPocketMoneyStatus, cardPocketMoneyBioStatus}) => {

  // console.log('cardPocketMoneyBioStatus ======<><>', cardPocketMoneyBioStatus);
  // state management for KCB BANK CARD
  const [showKcbCard, setShowKcbCard] = useState(false);
  const [pocketMoneyCardStatus, setPocketMoneyCardStatus] = useState(false);
  const [showPocketMoneyCard, setShowPocketMoneyCard] = useState(false);
  const [showPocketMoneyBio, setShowPocketMoneyBio] = useState(false)
  const [showEitherCard, setShowEitherCard] = useState(false);


  useEffect(() => {
    if (showPocketMoneyCard) {
      setShowEitherCard(true);
      setShowPocketMoneyBio(false)
    }

    if(showPocketMoneyBio){
      setShowEitherCard(true)
      setShowPocketMoneyCard(false)
    }
  }, [showPocketMoneyCard, showPocketMoneyBio]);
  return (
    <div style={{ width: "100%", fontFamily:'Poppins' }}>
      <Grid container direction={"column"} marginTop={"4.6%"} borderRadius={"6px"} border={"solid 1px #eaeaea"} width={"100%"}>
        <Grid style={{ cursor: "pointer" }} item padding={"50px"}>
          {/* starts of kcb */}
          <Grid container direction={"column"} width={"100%"}>
            <Grid item display={"flex"} margin={"0% 0% 2% 0%"} width={"100%"} justifyContent={"space-between"} onClick={() => setShowKcbCard(!showKcbCard)}>
              <span style={{fontSize: '14px', fontWeight: 500, color: '#032541'}}>KCB Bank</span>
              {!showKcbCard ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Grid>
            {showKcbCard && (
              <>
                {" "}
                <Grid item margin={"0% 0% 3% 0%"} display={"flex"} justifyContent={"space-between"} width={"100%"} onClick={() =>  setShowPocketMoneyCard(!showPocketMoneyCard) } >
                  <span style={{color: "#707070", fontSize: '14px',}}>Pocket Money(Card)</span>

                  <div style={{display: 'flex', gap: '10px'}}> 
                  {cardPocketMoneyStatus === false ? <Typography style={{ color: "#707070", marginRight:'5px' }}>Not Set</Typography> : <Typography style={{ color:'#33cf4d', marginRight:'5px' }}>Active</Typography>  }    
                  <CustomSwitch 
                  checked={cardPocketMoneyStatus} 
                  inputProps={{ "aria-label": "controlled" }} 
                  color="warning" 
                  />
                  </div>

                </Grid>
                <Grid item margin={"0% 0% 3% 0%"} display={"flex"} justifyContent={"space-between"} width={"100%"} onClick={() =>  setShowPocketMoneyBio(!showPocketMoneyBio)} >
                  <span style={{color: "#707070", fontSize: '14px',}} >Pocket Money(Bio)</span>

                  <div style={{display: 'flex', gap: '10px'}}>
                  {cardPocketMoneyBioStatus === false ? <Typography style={{ color: "#707070", marginRight:'5px' }}>Not Set</Typography> : <Typography style={{ color:'#33cf4d', marginRight:'5px' }}>Active</Typography>  }    
                  <CustomSwitch
                      checked={cardPocketMoneyBioStatus}
                    inputProps={{ "aria-label": "controlled" }}
                    color="warning"
                  />
                  </div>
                
                </Grid>{" "}
              </>
            )}
          </Grid>
        </Grid>

        {showEitherCard && <Grid item padding={"50px"} width={"100%"} >
          {showPocketMoneyCard && <PocketMoneyCard  />}
          {showPocketMoneyBio &&  <KCBPocketMoney />}
          </Grid>}

        
      </Grid>
    </div>
  );
};

export default BankPocketmoney;
