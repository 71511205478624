import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partners: []
}


const EditedPartnersSlice = createSlice({
  name:"editedPartners",
  initialState,
  reducers:{
    handlePartnerEdited:(state, action) => {
        state.partners.push(action.payload)
    },
    handleRemove: (state, action) => {
        state.partners = state.partners.filter((item) => item.name !== action.payload)
    }

  }
})

export const { handlePartnerEdited, handleRemove } = EditedPartnersSlice.actions;

export default EditedPartnersSlice.reducer