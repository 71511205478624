import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import BusinessesDropdown from "./BusinessesDropdown";
import { useDispatch, useSelector } from "react-redux";
// import { setDetails } from "../../../../features/businessToUpdateSlice";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { setDetails } from "../../../../features/businessUpdatedSlice";
import { setBusinesses } from "../../../../features/ExporterSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function InactiveTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch();

  // const itemDeactivated = useSelector(state => state.alertState.deactivated)
  const itemActivated = useSelector(state => state.alertState.activated)
  const itemDeleted = useSelector(state => state.alertState.deleted)
  const startDate = useSelector((state) => state.selectedDate.startDate2);
  const endDate = useSelector((state) => state.selectedDate.endDate2);

  let partnerName = localStorage.getItem("affiliatedPartner")

  //fetching the table data
  const getInactiveBusinesses = async () => {
    const response = await axios.post(baseUrl + "/api/getInactiveBusinesses",
    {
      partnerName,
      startDate,
      endDate
    },
    {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setData(response.data.data);
  };

  useEffect(
    () => {
      getInactiveBusinesses();
    },
    [
      itemActivated,
      itemDeleted,
      endDate
    ]
  );

  let searchQuery = useSelector((state) => state.search.searchValue);

  let filteredData;

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy7);

  useEffect(() => {
    if (reduxSearchBy === "businessName") {
      filteredData = data.filter((item) =>
        item.businessName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (reduxSearchBy === "dateCreated") {
      filteredData = data.filter((item) => {
        let dateString = item.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US");

        return formattedDate.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
  }, [searchQuery]);

  //creating rows from the fetched data
  const createRows = () => {
    let arr = [];
    if (searchQuery.length === 0) {
      data?.map((obj) => {
        let dateString = obj.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US");
  
        let val = createData(
          obj.businessName,
          obj.businessNumber,
          obj.businessOwnerName,
          obj.businessOwnerEmail,
          obj.businessOwnerPhone,
          obj.branchCount,
          obj.terminalCount,
          formattedDate,
          <BusinessesDropdown />
        );
        arr.push(val);
      });
    } else {
      filteredData?.map((obj) => {
        let dateString = obj.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US");
  
        let val = createData(
          obj.businessName,
          obj.businessNumber,
          obj.businessOwnerName,
          obj.businessOwnerEmail,
          obj.businessOwnerPhone,
          obj.branchCount,
          obj.terminalCount,
          formattedDate,
          <BusinessesDropdown />
        );
        arr.push(val);
      });
    }
   
    // arr.sort((a, b) => (a.businessID < b.businessID ? -1 : 1));
    setRows(arr);
  };

  useEffect(() => {
    createRows();
  }, [data, searchQuery])

  const [loading, setLoading] = useState(true);
  const [hasItems, setHasItems] = useState(true);


  useEffect(() => {
    if (rows.length > 0) {
      setLoading(false);
      const newArray = rows.map(({ Action, ...rest }) => rest);
      dispatch(setBusinesses(newArray))
    }
  }, [rows]);

  useEffect(() => {
    const delayedFunction = () => {
      // This function runs 5 seconds after initial load;
      if(rows.length === 0) {
        setHasItems(false)
        setLoading(false);
      }
    };

    const timer = setTimeout(delayedFunction, 10000); 

    return () => {
      clearTimeout(timer);
    };
  }, [rows]);

  return (
    <TableContainer component={Paper} width={"100%"}>
      {loading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null}
      <Table
        sx={{ minWidth: 500, fontFamily: "Poppins" }}
        aria-label="custom pagination table"
        size={"small"}
      >
        <TableHead sx={{ bgcolor: "#f0f0f0" }}>
          <TableRow>
            <TableCell>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business ID
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Owner
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Phone No,
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Branches
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Terminals
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Date Created
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!hasItems ? (
            <>
              <Typography sx={{
                fontFamily: "Poppins",
                fontSize: 14,
                fontWeight: "normal",
                fontStyle: "normal",
                textAlign: "center",
                color: "#707070"
              }}>No items to display</Typography>
            </>
          ) : null}
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow
              key={row.BusinessID}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => {
                let details = {
                  businessId: row.BusinessID,
                  businessName: row.BusinessName,
                  owner: row.Owner,
                  email: row.Email,
                  phoneNumber: row.PhoneNo,
              }
                dispatch(setDetails(details));
              }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.BusinessName}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.BusinessID}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.Owner}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                  sx={{ width: "100px", overflow: "scroll" }}
                >
                  {row.Email}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.PhoneNo}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.Branches}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.Terminals}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.DateCreated}
                </Typography>
              </TableCell>
              <TableCell align="left">{row.Action}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={9} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={9}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
function createData(
  BusinessName,
  BusinessID,
  Owner,
  Email,
  PhoneNo,
  Branches,
  Terminals,
  DateCreated,
  Action
) {
  return {
    BusinessName,
    BusinessID,
    Owner,
    Email,
    PhoneNo,
    Branches,
    Terminals,
    DateCreated,
    Action,
  };
}
