import HttpComponent from "../School/MakeRequest";

export default class ConfigurationFunctions {
  constructor() {}

  getAllBusiness(inputValue) {
    return HttpComponent({
      method: "GET",
      url: `/api/search_businesses?limit=1000&page=1&searchValue=${inputValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  setupTransactionMode(transMode, numberOfPrints,enableStartOrder, viewPrintSummary,  bsNumber, enableStudentPurchase, enableCashConfig){
    return HttpComponent({
        method: "POST",
        url: `/api/v1/enableZedPayIdentification`,
        body: {
            transactionMode: transMode,
            businessNumber:bsNumber,
            numberOfPrints:numberOfPrints,
            enableStartOrder:enableStartOrder,
            viewPrintSummary,
            isStudentPurchaseEnabled:enableStudentPurchase,
            enableCashConfig:enableCashConfig
        },
        token: localStorage.getItem("X-Authorization"),
      });
  }

  getTransactionMode(page, limit, search,){
    return HttpComponent({
      method: "GET",
      url: `/api/v1/getZedPayBusinessConfigs?page=${page}&limit=${limit}&searchValue=${search}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getaBusinessTransactionMode(businessNumber){
    console.log("incomingbusinessNUmer", businessNumber)
    return HttpComponent({
      method:'GET',
      url:`/api/v1/getZedPayItIdentificationConfigs?businessNumber=${businessNumber}`,
      token:localStorage.getItem("X-Authorization")
    })
  }
}
