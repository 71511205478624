import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../features/userSlice';
import { deleteBranchDetails } from '../../features/branchSlice';
import { deleteBusinessCategoryDetails } from "../../features/businessCategorySlice";
import { resetSideBarDefault } from '../../features/sideBarSlice';
import { deleteBusinessBranches } from '../../features/businessBranchesSlice.js';
import { reversePageLoadState } from '../../features/autorefreshonpageload';
import { resetPrintSettings } from '../../features/startorderSlice';
import { handleReset } from '../../features/tenantsDataSlice';

import { clearServicesToAdd, handleClearServicesData } from '../../features/servicesSlice';
import { clearAdditions } from '../../features/customerSlice';

import { removeSchoolTypeName } from '../../features/schoolType';
import { handleResetStaff } from '../../features/staffSlice.js';
import { handleResetDetail } from '../../features/businessAddPartner.js';

const baseUrl = process.env.REACT_APP_BASE_URL

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

    // variable declaration
    const userEmail = localStorage.getItem('email')
    const businessOwnerEmail = localStorage.getItem('email')
    const group = localStorage.getItem('group')
    

    fetch(baseUrl + "/logout" , {
        method : 'PUT' , headers : {
            'Content-Type' : 'application/json' ,
            'Accept' : 'application/json' ,
            'X-Authorization' : localStorage.getItem('X-Authorization')
        } , body : JSON.stringify({userEmail : userEmail})
    }).then(lout => {
        dispatch(deleteBranchDetails());
        dispatch(deleteBusinessCategoryDetails());
        dispatch(deleteBusinessBranches());
        dispatch(logoutUser());
        dispatch(resetSideBarDefault());
        dispatch(reversePageLoadState())
        dispatch(handleReset())
        dispatch(handleClearServicesData())
        dispatch(clearAdditions())
        dispatch(removeSchoolTypeName())
        dispatch(handleResetStaff())
        dispatch(handleResetDetail())
        localStorage.clear();
        navigate('/');
    })

}

export default Logout