import { Button, Grid, Stack, Typography } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import AddsalesPerson from "./addSalesPerson";
import EditSalesPerson from "./editSalesPerson";

export default function PartnerSalesPerson() {

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")
    const navigate = useNavigate()

    // get regions

    function getAllSalesPeopleList() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getpartneraddsalesperson?page=${pageState.page}&limit=${dataGridPageSize}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllSalesPeopleList();
    }, [dataGridPageSize, pageState.page, queryParamValue])

    const AllSalesPeople = [
        { headerName: "code", field: 'code', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Code </strong>) } },
        { headerName: "salesName", field: 'salesName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Name</strong>) } },
        { headerName: "salesPhone", field: 'salesPhone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Phone</strong>) } },
        {
            headerName: "Actions", field: "actions", headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Actions </strong>) }, renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <EditIcon sx={{ color: '#cb444a' , cursor:"pointer" }} onClick={() => navigate(`/partner/salesperson?EditSalesPerson?${params.row.code}?${params.row.firstName}?${params.row.lastName}?${params?.row?._id}?${params?.row?.salesPhone}`)} />
                    </Stack>
                )
            }
        }
    ]

    return (
        <Grid container direction={'column'}>
            {queryParamValue === 'AddSalesPerson' ? <AddsalesPerson /> : queryParamValue === 'EditSalesPerson' ? <EditSalesPerson/> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item display={'flex'} alignItems={'center'} >
                    <Stack alignItems='center' direction='row' spacing={1}>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "#707070",
                                "&:hover": { cursor: "pointer" },
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        />
                        <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Sales People</Typography>
                    </Stack>
                </Grid>
                <Grid item display={'flex'} justifyContent={'space-between'} direction={'row'} alignItems={'center'} mt={2}>
                    <Breadcrumb name={'Sales People'} />
                    <Button onClick={() => navigate(`/partner/salesperson?AddSalesPerson`)} style={{ width: '146px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Add Sales Person</Button>
                </Grid>
                <Grid item mt={2}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[10, 20, 50, 70, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={AllSalesPeople}
                        rows={pageState?.data}
                        getRowId={row => row?._id} />
                </Grid>
            </>}
        </Grid>
    )
}