import { ListItemButton, ListItemIcon, ListItem, ListItemText, List, Collapse } from '@mui/material'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ZedLogo from "../../images/zedLogo.png";
import DashLogo from "../../common/images/dashlogo.png";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        fontFamily: "Poppins",
    },
    drawer: {
        width: 291,
        color: "#fff",
        flexShrink: 0,
    },
    drawerPaper: {
        width: 291,
        color: "#fff",
        backgroundColor: "rgb(3,37,65)",

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        color: "#fff",
        paddingLeft: theme.spacing(4),
    },
}));

const drawerWidth = 245;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));
const MTNSidebar = ({ children }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const location = useLocation()
    const isActiveLink = (path) => location.pathname === path;
    const group = localStorage.getItem('group')
    const sidebarBg = group === 'Partner' ? 'rgb(3,37,65)' : "rgb(3,37,65)"
    const partnerLogo = group === 'Partner' ? DashLogo : DashLogo
    const activeColor = group === 'Partner' ? 'rgb(4, 242, 252)': '#dc3545'
    const normalColor = group === 'Partner' ? '#fff' : "#032541"
    return (
        <div className={classes.root}>
            <Drawer position={"fixed"} variant="permanent" open={open} PaperProps={{ sx: { backgroundColor: sidebarBg, width: "291px", color: normalColor } }}>
                <DrawerHeader style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                    {open ? (
                        <Link to={"/partnerdash"} style={{ marginLeft: 10, marginTop: 10 }}>
                            <img src={partnerLogo} alt="Zed Logo" width="140px" height="70px" />
                        </Link>
                    ) : (
                        <Link
                            to={"/partnerdash"}
                            style={{ alignSelf: "center", marginLeft: 5 }}
                        >
                            <img src={DashLogo} alt="Zed Logo" />
                        </Link>
                    )}
                </DrawerHeader>
                <List sx={{ marginTop: 2 }}>
                    <Link to="/partnerdash" style={{ textDecoration: 'none', color: isActiveLink("/partnerdash") ? activeColor : normalColor }}>
                        <ListItemButton style={{ textDecoration: 'none', color: isActiveLink("/partnerdash")  ? activeColor : normalColor }}>
                            <ListItemIcon sx={{ color: isActiveLink("/partnerdash") ? activeColor : normalColor }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </Link>
                    <Link to="/partner/agents" style={{ textDecoration: 'none', color: isActiveLink("/partner/agents") ? activeColor : normalColor }}>
                        <ListItemButton style={{ textDecoration: 'none', color: isActiveLink("/partner/agents") ? activeColor : normalColor }}>
                            <ListItemIcon sx={{ color: isActiveLink("/partner/agents") ? activeColor : normalColor }}>
                                <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText primary="Agents" />
                        </ListItemButton>
                    </Link>
                    <Link to="/partners/transactions" style={{ textDecoration: 'none', color: isActiveLink("/partners/transactions") ? activeColor : normalColor }}>
                        <ListItemButton style={{ textDecoration: 'none', color: isActiveLink("/partners/transactions") ? activeColor : normalColor }}>
                            <ListItemIcon sx={{ color: isActiveLink("/partners/transactions") ? activeColor : normalColor }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Transactions" />
                        </ListItemButton>
                    </Link>
                </List>
            </Drawer>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    )
}

export { MTNSidebar }