import { Box, Button, Grid, List, ListItem, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import PayablesIcon from "../../components/School/Images/payables_icn.png"
import PayablesIcon1 from "../../components/School/Images/payablesicon1.svg"
import PayablesIcon2 from "../../components/School/Images/payablesicon2.svg"
import PayablesIcon3 from "../../components/School/Images/payablesicon3.svg"
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL


const zedAccountingmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 2,
}


const PayableSetStatus = () => {
   
    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [errorNotificationOpen, setErrorNotificationOpen] = useState(false);
    const [payablesStatus, setPayablesStatus] = useState(false)
    const [openPayablesModal, setOpenPayablesModal] = useState(false)
    const [message, setMessage] = useState('')

    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };
    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };
    const handleErrorNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorNotificationOpen(false);
    };

    const handleOpenPayablesModal = () => {
        setOpenPayablesModal(true)
    }
    const handleClosePayablesModal = () => {
        
    }


    function getPayablesStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/business/payables/status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                // console.log(data, 'payables status')
                if (data.status === 200) {
                    console.log(data?.response?.data.payablesStatus, 'payables status')
                    let statusPayables = data?.response?.data?.payablesStatus
                    setPayablesStatus(JSON.parse(statusPayables));
                }
            })
        } catch (error) {
            console.log(error)

        }
    }

    useEffect(() => {
        getPayablesStatus()
    }, [])
    const switchPayables = () => {
        if (payablesStatus) {
            handleClosePayablesModal()
        } else if (!payablesStatus) {
            handleOpenPayablesModal()
        }
       
    }


    const updatePayablesStatus = async () => {
       
        try {
            // alert("payables status updated")

            HttpComponent({
                method: "POST",
                url: '/api/v1/business/payables/enable_disable',
                token: localStorage.getItem('X-Authorization'),
                body: { status : true}
            }).then((data) => {
                console.log(data, 'payables status')
                if (data.status === 200) {
                    setMessage('Payables Enabled Successfully')
                                handleSuccessNotificationClick();
                                getPayablesStatus()
                                setOpenPayablesModal(false)
                                window.location.reload()
                }else if(data.status === 400){
                    setMessage(data?.response?.message)
                    handleErrorNotificationClick()
                }
            })


          

        } catch (error) {

        }

    }



    //enable accounting
    // const reposonse = await fetch(`${baseUrl}/api/v1/business/payables/enable_disable`, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         "X-Authorization": localStorage.getItem("X-Authorization"),
    //     },
    //     body: JSON.stringify({status:true})

    // })
    // const data = reposonse.json()
    //     .then((response) => {
    //         console.log(response);
    //         if (response.status === "SUCCESS") {
    //             setMessage('Payables Enabled Successfully')
    //             handleSuccessNotificationClick();
    //             getPayablesStatus()
    //             setOpenPayablesModal(false)
    //             window.location.reload()
                    
                
    //         }else{
    //             setMessage(response?.message)
    //             handleErrorNotificationClick()
    //             setOpenPayablesModal(false)

               
    //         }
    //     })
    

    return (
        <div>
            <Grid container item className={"container-fluid"}>
                {/*Page Title Container*/}
                <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                    <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                </Grid>

                {/*Page Sub title and Search */}
                <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                    <Grid item>
                        <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                            <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                            <span style={{ color: "#032541" }}>Payables Config</span>
                        </div>
                    </Grid>

                    {/*Search input*/}
                    <Grid item>
                        <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
                        <TextField
                            size="small"
                            placeholder={"search"}
                            style={{ height: "1px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ width: "15px", height: "15px" }} />
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                    </Grid>
                </Grid>

                {/*Headers*/}
                <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                        Configuration
                    </Grid>
                    <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                        Action
                    </Grid>
                </Grid>
                <Grid container style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ width: "100%" }}>
                        <List>
                            <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Payables</Box>
                                    <Box>
                                    <Switch checked={payablesStatus} onClick={switchPayables} />
                                    </Box>
                                </Box>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={openPayablesModal} onClose={handleOpenPayablesModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={zedAccountingmodal}>
                    <form>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={PayablesIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Payables? </Box>
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070", marginTop:"10px" }}>
                                    Enabling payables will allow your business to pay suppliers, vendors and track internal payments through petty cash.
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "10px" }}>
                                    <Box style={{display:"flex"}}>
                                        <div className="image-container">
                                            <img src={PayablesIcon1} style={{ height: "25px", width: "25px" }} />
                                        </div>
                                        <div className="text-container" style={{marginLeft:"10px", color:"#707070"}}>
                                            <p>Assign one or more approvers to transactions <br/> made from the business.</p>
                                        </div>
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "" }}>
                                    <Box style={{display:"flex"}}>
                                        <div className="image-container">
                                            <img src={PayablesIcon2} style={{ height: "25px", width: "25px" }} />
                                        </div>
                                        <div className="text-container" style={{marginLeft:"10px", color:"#707070"}}>
                                            <p>Pay directly to suppliers and vendors from the app.</p>
                                        </div>
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "" }}>
                                    <Box style={{display:"flex"}}>
                                        <div className="image-container">
                                            <img src={PayablesIcon3} style={{ height: "25px", width: "25px" }} />
                                        </div>
                                        <div className="text-container" style={{marginLeft:"10px", color:"#707070"}}>
                                            <p>Make petty cash payments and track them.</p>
                                        </div>
                                    </Box>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleClosePayablesModal

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>

                                       
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={updatePayablesStatus}>Enable</Button>
                                        
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </form>
                </Box>
            </Modal>
            <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={message} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

        </div>
    );
};

export { PayableSetStatus };
