import { Box, Breadcrumbs, Button, Checkbox, Grid, IconButton, InputAdornment, OutlinedInput, Stack, TextField, Typography } from "@mui/material"
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import CustomInputLabel from "../../../School/CustomInputLabel";
import CustomTextFieldSmall from "../../../School/CustomTextFieldSmall";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HttpComponent from "../../../School/MakeRequest";
import { useNavigate } from "react-router-dom";
import AuthecateUser from "../../../paymentmodule/autheticatedComponent";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Zed Mpesa Daraja</Typography>
]


const noStyle = {
    "borderRadius":"4px",
    "border":"solid 1px #dc3545",
    "color":"#dc3545",
    textTransform : "none",
    textDecoration : "none"
}

const buttonWidthHeight = {
    width:"9.063rem",
    height:"3.125rem"
}

const yesStyle = {
    "borderRadius": "4px",
    "backgroundColor": "#032541",
    textTransform : "none",
    textDecoration : "none",
    color:"white",
    '&:hover': {
        backgroundColor: '#032541',
        color: 'white'
    }
}

const disabledStyle = {
    "borderRadius": "4px",
    backgroundColor: "rgba(3, 37, 65, 0.4)",
    textTransform : "none",
    textDecoration : "none",
    color:"#ffffff",
    '&:hover': {
        backgroundColor: "rgba(3, 37, 65, 0.4)",
        color: '#ffffff !important'
    }
}


const ZedAdminDarajaSetup = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate  = useNavigate()
    const [formData, setFormData] = useState({
        businessName:"",
        businessShortCode:"",
        consumerKey:"",
        consumerSecret:"",
        accountReference:"",
        passKey:"",
        thirdPartyCallback:""
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const [checked, setChecked] = useState(false);
    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };
    const [showPassKey , setShowPasskey]= useState(false)
    const toggleShowPaskey = () => {
        setShowPasskey(!showPassKey)
    }

    const handleMpesa = () =>{
        formData.thirdPartyCallback = "https://zed.swerri.io/api/stkCallback"
        HttpComponent({
            method: 'POST',
            url: `/api/activate_daraja_for_business`,
            body: formData,
            token: localStorage.getItem('X-Authorization')
        }).then((data)=>{
            console.log("here store is data",data);
            if(data?.status === 201){
                setSuccessShow({ state:true,message:"Mpesa Configurations Added Successfully"})
                setTimeout(()=>{
                    navigate('/adminDashboard')
                })
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            setErrorShow({state:true, message:error.message})
        })
    }

    const currentURL = window.location.href;
    console.log(currentURL, 'currentURL');
    const [isAuthecated, setIsAuthecated] = useState(false);
    const handleIsAuthecated = (childData) => {
        let state = childData.state;
        setIsAuthecated(state)
    }

    return (
        <Grid container direction={'column'}>
            {!isAuthecated && currentURL?.includes('/admin/zedmpesadarajasetup') ? 
                <AuthecateUser handleIsAuthecated={handleIsAuthecated} /> :<>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Zed Mpesa Daraja</Typography>
                </Stack>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Box component="div" sx={{display:"flex" , mt:3, width:"50%"}}>
                <Box component="div" sx={{marginX:0.5}}>
                    <CustomInputLabel required={true} label={"Business Name"}/>
                    <CustomTextFieldSmall value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
                </Box>

                <Box component="div" sx={{marginLeft:2}}>
                    <CustomInputLabel required={true} label={"Business Shortcode"}/>
                    <CustomTextFieldSmall value={formData.businessShortCode} onChange={handleInputChange} name={"businessShortCode"}  placeholder={"Short Code"} />
                </Box>
            </Box>
            <Box component="div" sx={{marginX:1 ,}}>
                <CustomInputLabel required={true} label={"Consumer Key"}/>
                <TextField
                    name={"consumerKey"}
                    value={formData.consumerKey}
                    onChange={handleInputChange}
                    placeholder={"Consumer Key"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{display:"flex"}}>
                <Box component="div" sx={{marginX:0.5}}>
                    <CustomInputLabel required={true} label={"Consumer Secret"}/>
                    <CustomTextFieldSmall value={formData.consumerSecret} onChange={handleInputChange} name={"consumerSecret"} placeholder={"Consumer Secret"} />
                </Box>
                <Box component="div" sx={{marginLeft:2}}>
                    <CustomInputLabel required={true} label={"Account Reference"}/>
                    <CustomTextFieldSmall value={formData.accountReference} onChange={handleInputChange} name={"accountReference"} placeholder={"Account Reference"} />
                </Box>
            </Box>
            <Box component="div" sx={{ marginX: 1, width: '50%' }}>
                <CustomInputLabel required={true} label={"Pass Key"} />
                <OutlinedInput
                    name={"passKey"}
                    value={formData.passKey}
                    onChange={handleInputChange}
                    placeholder={"Pass Key"}
                    type={showPassKey ? 'text' : 'password'}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 2, width: '31.063rem' }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPaskey}
                                edge="end"
                            >
                                {showPassKey ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>}
                />
            </Box>
            <Box component="div" sx={{}}>
                <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                <span>By ticking, you are agreeing to the terms and conditions.</span>
            </Box>
            <Box component="div" sx={{display:"flex", flexDirection:"column", width:'31.063rem', justifyContent:"flex-end", marginBottom:2}}>
                <Box component="div" sx={{alignItems:'flex-end' , display:'flex', width:'100%', justifyContent:'flex-end'}}>
                    <Button sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                        Cancel
                    </Button>

                    <Button onClick={() => handleMpesa()} disabled={checked === false}  sx={checked === false ? {...disabledStyle ,...buttonWidthHeight}:{...yesStyle,...buttonWidthHeight,marginX:1}}>
                        Activate
                    </Button>
                </Box>
            </Box>
            </>}
        </Grid>
    )
}

export { ZedAdminDarajaSetup }