import { Grid, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import DateFormatter, { DoMaskValue } from '../../../../utils/dateFormatter';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Audit Logs</Typography>
]

export default function AuditLogsAdmin() {
    const navigate = useNavigate();

    const AuditColumns = [

        { headerName: "user", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Users</strong>) } , renderCell:(params)=><Typography sx={{fontSize:'11px'}}>{DoMaskValue(params?.row?.email)}</Typography>},
        { headerName: "Name", field: 'Name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Name</strong>) }},
        { headerName: "Phone", field: 'Phone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Phone</strong>) }},
        { headerName: "businessName", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Business Name</strong>) }},
        { headerName: "oldvalue", field: 'oldValue', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Old Value</strong>) } ,renderCell:(params)=><Typography sx={{fontSize:'11px'}}>{params?.row?.oldValue}</Typography> },
        { headerName: "newValue", field: 'newValue', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >New Value</strong>) } ,renderCell:(params)=><Typography sx={{fontSize:'11px'}}>{params?.row?.newValue}</Typography>  },
        { headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Status</strong>) } ,renderCell:(params)=><Typography sx={{fontSize:'11px'}}>{params?.row?.status}</Typography>  },
        { headerName: "createdAt", field: 'createdAt', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date /Time</strong>) } , renderCell:(params)=><Typography sx={{fontSize:'11px'}}>{DateFormatter(params?.row?.createdAt)}</Typography> },
   

    ]

    const [dataGridPageSize, setPageSize] = useState(10);
    const [searchValue, setSearchValue] = useState('')
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    // : api/v1/getlbusinesslogs?docType=login&page=1&limit=1

    function getAllLoginLogs() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getlbusinesslogs?docType=login&page=${pageState.page}&limit=${dataGridPageSize === 100 ? pageState.total : dataGridPageSize}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllLoginLogs()
    }, [pageState.page , dataGridPageSize])


    return (
        <Grid container direction={'column'}>
            <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Audit Logs</Typography>
            </Grid>
            <Grid item mt={2} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={AuditColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}