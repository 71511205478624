import { LinearProgress, Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import axios from "axios";
import cardBG from '../../../common/images/TopPerfBG.png'
import { CircularProgress } from "@mui/material";



const baseUrl = process.env.REACT_APP_BASE_URL;

function TopPerforming() {
  const [topBusinesses, setTopBusinesses] = useState(null);

  let partnerName = localStorage.getItem("affiliatedPartner")

  const fetchBusinesses = async () => {
    await axios
      .post(baseUrl + "/api/topPerforming",
      {
        partnerName
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      })
      .then((response) => {
        setTopBusinesses(response.data.data)
      });
    };


  useEffect(() => {
    fetchBusinesses();  //fetch top performing businesses
  }, []);

  console.log("topBusinesses")
  console.log(topBusinesses)


  return (
    <Stack
      width={"100%"}
      height="166px"
      justifyContent={"space-between"}
      p={2}
      borderRadius={"12px"}
      boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
      bgcolor={"white"}
      style={{
        backgroundImage: `url(${cardBG})`,
        backgroundSize: "70% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={"16px"}
          fontWeight={500}
          fontStretch={"normal"}
          fontStyle={"normal"}
          lineHeight={1.31}
          letterSpacing={"normal"}
          textAlign={"left"}
          color={"#dc3545"}
        >
          Top Performing Businesses
        </Typography>
        <ListAltIcon sx={{color: '#dc3545'}} />
      </Stack>
      <Stack width="100%" direction={"row"} spacing={1}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#687c8d"
        >
         {!topBusinesses ? 
         (<Skeleton
          variant="text"
          fontSize={18}
          width={"100px"}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
          />) : (topBusinesses?.[0]?.businessName ? `${topBusinesses?.[0]?.businessName}:`: ""  )}
        </Typography>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
         {!topBusinesses ? (<Skeleton
          variant="text"
          fontSize={18}
          width={"100px"}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
          />) : (topBusinesses?.[0]?.total ? `KES ` + topBusinesses?.[0]?.total?.toLocaleString(): "")}             
        </Typography>
      </Stack>
      <Stack width="100%" direction={"row"} spacing={1}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#687c8d"
        >
         {!topBusinesses? (<Skeleton
          variant="text"
          fontSize={18}
          width={"100px"}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
          />) : (topBusinesses?.[1]?.businessName ? `${topBusinesses?.[1]?.businessName}:`: ""  )} 
        </Typography>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
         {!topBusinesses ? (<Skeleton
          variant="text"
          fontSize={18}
          width={"100px"}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
        />) : (topBusinesses?.[1]?.total ? `KES ` + topBusinesses?.[1]?.total?.toLocaleString(): "")}    
        </Typography>
      </Stack>
      <Stack width="100%" direction={"row"} spacing={1}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#687c8d"
        >
         {!topBusinesses? (<Skeleton
          variant="text"
          fontSize={18}
          width={"100px"}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
        />) : (topBusinesses?.[2]?.businessName ? `${topBusinesses?.[2]?.businessName}:`: ""  )}
        </Typography>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
         {!topBusinesses ? (<Skeleton
          variant="text"
          fontSize={18}
          width={"100px"}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
          />) : (topBusinesses?.[2]?.total ? `KES ` + topBusinesses?.[2]?.total?.toLocaleString(): "")}              
        </Typography>
      </Stack>
    </Stack>
  );
}

export default TopPerforming;
