import { Grid, Stack, Typography, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/bankToUpdateSlice";
import {
  handleAdded,
  handleEdited,
  handleEnabled,
} from "../../../../features/AlertSlice";
import axios from "axios";
import ModuleList from "./ModuleList";
import { setChange } from "../../../../features/onChangeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const baseUrl = process.env.REACT_APP_BASE_URL;

function ViewModules() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let moduleDetails = useSelector((state) => state.moduleDetails);
  const businessId = useSelector((state) => state.DetailsOfbusiness.businessId);
  const businessName = useSelector(
    (state) => state.DetailsOfbusiness.businessName
  );
  const valChanged = useSelector((state) => state.userChanged.valChanged);

  const handleApply = () => {
    try {
      axios
        .post(baseUrl + "/api/toggleBusinessModuleState", {
          businessId,
          moduleDetails,
        })
        .then((response) => {
          if (response) {
            console.log("updated successfully");
            dispatch(handleEnabled(true));
            navigate("/Businesses");
            dispatch(setChange(false));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <ArrowBackIosNewIcon
          sx={{
            color: "#707070",
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() => {
            window.history.back();
          }}
        />
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {`${businessName}'s Modules`}
        </Typography>
      </Stack>
      <Breadcrumbs2
        point1={"Businesses"}
        link1={"/Businesses"}
        point2={"View Modules"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Stack
              sx={{
                p: 1,
                borderRadius: 6,
                backgroundColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(112, 112, 112, 0.15)",
              }}
            >
              <Stack
                direction={"row"}
                width={"100%"}
                // spacing={2}
                justifyContent={"space-between"}
              >
                <Typography
                  fontFamily="Poppins"
                  fontSize={16}
                  fontWeight="500"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  {`${businessName}'s Modules`}
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize={16}
                  fontWeight="500"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  {`Business Code : ${businessId}`}
                </Typography>
              </Stack>
              <Stack sx={{ width: "100%" }} bgcolor="aqua">
                <ModuleList />
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/Businesses")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleApply}
                disabled={!valChanged}
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ViewModules;
