import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PinInput from 'react-pin-input';

import { useNavigate } from "react-router-dom";
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
export default function PaymentPinVerify(props) {
    console.log(props, 'props next');
    const [pin, setPin] = useState('')
    const [showPin, setShowPin] = useState(false)
    const [buttonState, setButtonState] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const navigate = useNavigate()

    // handle show pin
    const handleShowPin = () => {
        if (showPin) {
            setShowPin(false)
        }
        else {
            setShowPin(true)
        }
    }

    const checkIfPinIsValid = () => {
        HttpComponent({
            method: 'POST',
            url: `/api/v1/user/send/otp`,
            token: localStorage.getItem("X-Authorization"),
            body: {
                "pin": pin

            }

        }).then((data) => {
            if (data.status === 200) {
                console.log(data?.response?.message, 'data')
                let message = data?.response?.message
                
                setSuccessShow({ state: true, message: message });
                let dataGive = {
                    pin: pin,
                    email: data?.response?.email,
                    phone: data?.response?.phone

                }
                props.parentSubChildCallBack(dataGive)
                
            }

            else {
                console.log(data?.response?.message, 'data')

                let message = data?.response?.message
                
                setErrorShow({ state: true, message: message });
                setPin('')
            }
        }).catch((error) => {
            console.error(error);
        })


    }

    if (pin.length === 4) {
        // useEffect(() => {
            checkIfPinIsValid()
        // }, [pin])
    }

    return (

        <Grid container direction={'column'} mt={10}>
            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                <Typography style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>We need to confirm that it’s you</Typography>
                <Typography style={{ fontSize: '16px', color: '#707070' }}>Enter your login PIN below.</Typography>
            </Grid>
            <Grid item mt={1} display={'flex'} justifyContent={'center'}>
                <PinInput
                    length={4}
                    initialValue=""

                    secret={true}
                    secretDelay={100}
                    onChange={(pin) => setPin(pin)}
                    // type="numeric"
                    type={showPin ? 'text' : 'password'}
                    inputMode="numeric"
                    style={{ padding: '10px' }}
                    inputStyle={{ borderColor: '#bec5d1', width:"92px", height:"108px", fontSize:"50px" }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    //onComplete={(value, index) => { }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
            </Grid>
            <Grid item mt={2} display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                <Typography style={{ fontSize: '16px', cursor: 'pointer', fontWeight: 600, color: '#032541' }} onClick={handleShowPin}>{showPin ? 'Show Pin' : 'Hide Pin'}</Typography>

            </Grid>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />


        </Grid>
    )
}