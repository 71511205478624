import React , {useState, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import success from "../../../../../common/images/deactivate.svg"
import CurrencyFormatter from "../../../../../utils/currencyFormatter";
import {forfeitInvoice} from "../tenantPreviewInvoice";
import {useParams} from "react-router-dom";

function TenantInvoiceActionModal(props){

	// session Expired
	const style = {borderColor:"transparent", borderRadius: "15px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "300px", height: "320px",};

	const [open, setOpen] = useState(false)
	const modalType = props.popupType || 'ForFeit'
	const amount = props.amount || '56000'
	const customerName = props.customerName || 'Aineah'

	const {invoiceNumber} = useParams()

	function handlConfirm () {
		setOpen(false)
		props.onConfirm()
	}

	function handleClose () {
		props.onClose()
	}

	function handleOpen () {
		setOpen(true)
	}

	useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	return (
		<div>

			{/*successModal*/}
			<Modal
				open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<div style={{width: "50px", height: "50px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
						<img style={{width:"70px", height:"70px",margin: "12px 0px 0px -66px"}} src={success} alt='img'/>
					</div>
						<div>
							<div align={'center'}>
								<span style={{color : '#032541', fontWeight : 'bold', fontSize : '14px'}}>{modalType} Invoice {invoiceNumber}?</span>
							</div>
							<div align={'center'}>
								<span style={{fontSize : '11px'}}>Are you sure you want to {modalType} <span style={{color : '#032541', fontWeight : 'bold', fontSize : '11px'}}>{customerName}’s </span> Invoice of <span style={{color : '#032541', fontWeight : 'bold', fontSize : '11px'}}>{CurrencyFormatter(amount, 'KES')}</span>?</span>
							</div>
							<div style={{height: "50px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
							<span style={{marginTop:"-20px !important", fontSize:"11px", color : '#323d52'}}>This action cannot be undone.
                        </span>
							</div>
							<div align='center' className={'container'} style={{direction : "row", display : 'flex', justifyContent : 'space-between', height: "41.7px", margin: "5px 0px 10px 0px"}}>
								<div>
									<Button onClick={handleClose} style={{textDecoration : "none" ,textTransform : "none", fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "600" , color : "#032541" , border : '1px solid #032541', width : "124.5px" , height : "40.3px" , padding : "8px 44.5px 9.3px 35px" , backgroundColor : "#fff"}}>
										Cancel
									</Button>
								</div>
								<div>
									<Button onClick={handlConfirm} style={{textDecoration : "none" ,textTransform : "none", fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "600" , color : "#fff" , width : "124.5px" , height : "40.3px" , padding : "8px 44.5px 9.3px 35px" , backgroundColor : "#032541"}}>
										Confirm
									</Button>
								</div>

							</div>
						</div>
				</Box>
			</Modal>
		</div>
	)
}
export {TenantInvoiceActionModal};