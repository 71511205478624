import React from "react"

export const creditnotestyles = {
    universalFont: {
        fontFamily: "Poppins",
    },
    creditNoteText: {
       color:"#dc3545",
       fontSize:"25px",
       fontWeight:"600"
    },
    addcreditNoteButton:{
        backgroundColor:"#032541",
        borderRadius:"5px",
        color:"#fff",
        padding:"10px"
    },
    creditNoteTextMini:{
        color:"#dc3545",
       fontSize:"20px",
       fontWeight:"600"
    },
    dashboardText:{
        color:"#6e7074",
        fontSize:"20px",
        fontWeight:"600"
    },
    creditNoteSearchHolder:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"0px 16px 0px 16px",
        width:"100%"
    },
    filterText:{
        color:"#032541",
        fontSize:"14px",
        fontWeight:"500",
        margin:"0px 3px 0px 0px"
    },
    filterList:{
        color:"#dc3545"
    },
    filterItemsHolder:{
        display:"flex",
        alignItems:"center",
        // justifyContent:"space-between"
        // width:"100%"
        
    },
    inputstyling:{
        border:"1px solid #d8dee2",
        outline:"none",
        padding:"10px",
        borderRadius:"5px",
        width:"30%"
    },
    dataGridStyling:{
        height:"400px",
        width:"100%",
        margin:"0px 0px 10px 0px"
    },
    cancelButton:{
        border:"1px solid #dc3545",
        color:"#dc3545",
        margin:"0px 10px 0px 0px",
        width:"20%",
        padding:"10px"
    },
    cancelButtonLowOpacity:{
        border:"1px solid rgba(220,53,69, .4)",
        color:"rgba(220,53,69, .4)",
        margin:"0px 10px 0px 0px",
        width:"20%",
        padding:"10px",
        
    },
    approvalButton:{
        backgroundColor:"#032541",
        color:"#ffffff",
        width:"30%",
        padding:"10px",
        margin:"0px 0px 0px 10px",
    },
    approvalButtonLowOpacity:{
        backgroundColor:"rgba(3,37,65,.4)",
        color:"#ffffff",
        width:"30%",
        padding:"10px",
        margin:"0px 0px 0px 10px",
    },
    declineButton:{
        border:"1px solid #dc3545",
        color:"#dc3545",
        width:"10%",
        margin:"10px",
        padding:"10px"
    },
    approveButton:{
        backgroundColor:"#032541",
        color:"#ffffff",
        width:"10%",
        margin:"10px",
        padding:"10px"
    },
    shareModal:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        backgroundColor: "#fff",
        width: "25%",
        height: "fit-content",
    },
    shareModalIntro:{
        backgroundColor:"#dc3545",
        padding:"20px",
        color:"#ffffff",
        fontFamily:"20px",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        borderRadius:"15px 15px 0px 0px",
        fontWeight:500
    },
    creditNoteImgTextHolder:{
        
        
    },
    creditNotePosImg:{
        objectFit:"cover",
        margin:"0px 20px 0px 0px",
        width:"30px",
    },
    creditNoteModalText:{
        color: "#032541",
        fontSize:"21px"
    },
    printerPosModalStlying:{
        display:"flex",
        alignItems:"center",
        cursor:"pointer",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        padding:"20px"
    },shareCreditNoteText:{
        fontSize:"20px",
    },
    sendRequestModal:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        backgroundColor: "#fff",
        width: "20%",
        height: "200px",
      },
      sendingToPosImg:{
        height:"70px",
        width:"70px",
        objectFit:"cover"
      },
      sendingToPosImgText:{
        textAlign:"center"
      },
      cancelprintHolder:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
      },
      printButton:{
        backgroundColor: "#032541",
        color: "#ffffff",
        padding: "8px 30px 8px 30px",

      },
      cancelButton:{
        border: "solid 1px #dc3545",
        color: "#dc3545",
        padding: "8px 20px 8px 20px",
      },
      creditNotePreview:{
        height:"100vh",
        fontFamily:"Poppins"
      },
      creditNotePreviewCreditHolder:{
        backgroundColor:"rgba(220, 53, 69,)",
        padding:"0px 20px 0px 20px"
      },
      previewCreditNote:{
        color:"#dc3545",
        fontSize:"25px",
        fontWeight:"bold"
      },
      creditNoteBlack:{
        fontSize:"40px",
        fontWeight:"bold",
      },
      paraBold:{
        margin:"0px",
        fontWeight:"bold"
      },
      para:{
        margin:"0px"
      },
      creditNoteModal:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        backgroundColor: "#fff",
        width: "25%",
        height: "fit-content",
      },
      creditNoteConfigHolder:{
        padding:"20px"
      },
      searchCategoryInput:{
        outline:"none",
        border:"1px solid #032541",
        borderRadius:"6px",
        padding:"7px",
        width:"60%"
      },
      addCustomerNameInput:{
        width:"80%",
      },
      addCustomerNameInputError:{
        width:"80%",
        border:"1px solid red",
        outline:"1px solid red"
      }
}