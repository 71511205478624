import React, { useState, useEffect } from "react";
import StockFunctions from "../stockEndpoints";
import CustomDataGrid from "../customDataGrid";

const ReportsDrillDown = () => {
  const stockfunctions = new StockFunctions();
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const queryParams = decodeURIComponent(window.location.search);
  const queryParamsData = queryParams.slice(1).split("?")[0];
  const isARRAY = queryParams.slice(1).split("?")[1];
  const PATH = queryParams.slice(1).split("?")[2];

  useEffect(() => {
    const stockIds = isARRAY ? JSON.parse(JSON.stringify(queryParamsData)) : null;

    if (isARRAY === "ISARRAY")
      stockfunctions
        .getStockBatchReports(pageState?.page, dataGridPageSize, stockIds)
        .then((data) => {
          console.log(data);
          setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [dataGridPageSize, pageState?.page, isARRAY]);

  // get single add stock << >>
  useEffect(() => {
    const batchId = isARRAY === "NOTARRAY" ? queryParamsData : null;
    if (PATH === "ADDSTOCK" && isARRAY === "NOTARRAY")
      stockfunctions
        ?.getAddStockReport(pageState?.page, dataGridPageSize, batchId)
        .then((data) => {
          setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [dataGridPageSize, pageState?.page, isARRAY]);

  useEffect(() => {
    const batchId = isARRAY === "NOTARRAY" ? queryParamsData : null;
    if (PATH === "COSTOFGOODS" && isARRAY === "NOTARRAY")
      stockfunctions
        ?.getCostofGoodsReports(pageState?.page, dataGridPageSize, batchId)
        .then((data) => {
          setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [dataGridPageSize, pageState?.page, isARRAY, PATH]);

  // get single stock Take report
  useEffect(() => {
    const batchId = isARRAY === "NOTARRAY" ? queryParamsData : null;
    if (PATH === "STOCKTAKE" && isARRAY === "NOTARRAY")
      stockfunctions
        ?.getStockTakeReport(pageState?.page, dataGridPageSize, batchId)
        .then((data) => {
          setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [dataGridPageSize, pageState?.page, isARRAY, PATH]);

  // get single stock Transfer Report
  useEffect(() => {
    const batchId = isARRAY === "NOTARRAY" ? queryParamsData : null;
    if (PATH === "STOCKTRANSFER" && isARRAY === "NOTARRAY")
      stockfunctions
        ?.getStockTransferReport(pageState?.page, dataGridPageSize, batchId)
        .then((data) => {
          setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [dataGridPageSize, pageState?.page, isARRAY, PATH]);

  const stockColumn = [
    {
      field: "productName",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Product Name</span>;
      },
      flex: 1,
    },

    {
      field: "actualQuantity",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>In stock</span>;
      },
      flex: 1,
    },

    {
      field: "newStock",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>New Stock</span>;
      },
      flex: 1,
    },
    {
      field: "addedStock",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Added Stock</span>;
      },
      flex: 1,
    },

    {
      field: "buyingPrice",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Buying Price</span>;
      },
      flex: 1,
    },
    {
      field: "variance",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Variance</span>;
      },
      flex: 1,
    },
  ];

  const stockTransferColumn = [
    {
      field: "productName",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Product Name</span>;
      },
      flex: 1,
    },
    {
      field: "quantity",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Qty</span>;
      },
      flex: 1,
    },
  ];

  const costOfGoodsColumn = [
    {
      field: "productName",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Product Name</span>;
      },
      flex: 1,
    },
    {
      field: "buyingPrice",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Buying Price</span>;
      },
      flex: 1,
    },
    {
      field: "newQuantity",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Quantity</span>;
      },
      flex: 1,
    },
    {
      field: "totalPrice",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Total</span>;
      },
      flex: 1,
    },
  ]

  const stockTakeColumn = [
    {
      field: "productName",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Product Name</span>;
      },
      flex: 1,
    },
    {
      field: "expectedQuantity",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Expected</span>;
      },
      flex: 1,
    },
    {
      field: "actualQuantity",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Actual</span>;
      },
      flex: 1,
    },
    {
      field: "variance",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Variance</span>;
      },
      flex: 1,
    },
    {
      field: "soldQuantity",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Sold</span>;
      },
      flex: 1,
    },
  ];

  const stockData = pageState?.data?.map((stock, index) => ({
    id: index,
    productName: stock?.productName,
    actualQuantity: stock?.actualQuantity || stock?.inStockQuantity,
    newStock: stock?.newStock,
    buyingPrice: stock?.buyingPrice,
    newQuantity: stock?.newQuantity,
    totalPrice: stock?.totalPrice,
    variance: stock?.variance,
    quantity: stock?.quantity,
    addedStock: stock?.newQuantity,
    soldQuantity:stock?.soldQuantity,
    expectedQuantity:stock?.expectedQuantity
  }));
  return (
    <div>
      <CustomDataGrid customStyles={{ border: "none", height: "500px", width: "100%" }} column={PATH ===  'STOCKTRANSFER' ? stockTransferColumn : PATH ===  'COSTOFGOODS' ? costOfGoodsColumn : PATH ===  'STOCKTAKE' ? stockTakeColumn :  stockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={stockData} />
    </div>
  );
};

export default ReportsDrillDown;
