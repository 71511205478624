import { useState, useEffect } from "react";
import { Grid, Breadcrumbs, Tab, styled, Button, Typography } from "@mui/material";
import { customerstyles } from "./customerComponents/styles";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SearchFilter from "./customerComponents/searchFilter";
import { Exports } from "./customerComponents/exports";
import { CustomDate } from "./customerComponents/customDate";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ActiveSuppliers from "./supplierComponents/ActiveSuppliers";
import NewSuppliers from "./supplierComponents/NewSuppliers";
import EditedRequest from "./supplierComponents/EditedRequest";
import DeactivationRequest from "./supplierComponents/DeactivationRequest";
import InactiveSuppliers from "./supplierComponents/InactiveSuppliers";
import HttpComponent from "../School/MakeRequest";

//breadcrumbs

const breadcrumbs = [
    <Typography style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Dashboard</Typography>,
    <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Suppliers</Typography>
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const SupplierList = () => {
    const navigate = useNavigate();
    const [suppliersTab, setSuppliersTab] = useState("Active");
    // exported data;
    const [exportData, setExportData] = useState({ headers: [], data: [] });
    // csv data exports;
    const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
    // FILTER BY DATE;
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [activeSuppliers, setActiveSuppliers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // FETCH ALL SUPPLIERS
    const getActiveSuppliers = async () =>{
        setLoading(true)
        HttpComponent({
            method: "GET",
            url: `/api/v1/suppliers?page=1&limit=5000&status=ACTIVE`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
                if (data.status === 201) {
                   setActiveSuppliers(data.response.data);
                   setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false)
            });
        setLoading(false);
    }

    // get suppliers based on tab value
    useEffect(() =>{
        getActiveSuppliers();
    },[])


    // set tabChange;
    const suppliersTabChange = (event, newTabValue) => {
        setSuppliersTab(newTabValue);
    };

    useEffect(() => {
        // if(dateFrom && dateTo)
        // getFilteredData(dateFrom, dateTo);
    }, [dateFrom, dateTo]);


    // filter customer by name;
    const [supplierSearchValue, setInputValue] = useState("")

    return (
        <div style={customerstyles.universalStyle}>
            <Grid container direction={"column"}>
                <Grid item>
                    <Typography fontFamily={'Poppins'} style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Customer Accounts</Typography>
                </Grid>
                <Grid item mt={1}>
                    <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => navigate(`/addsupplier`)} startIcon={<Add />} style={customerstyles.addCustomer}>
                                Add Supplier
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={customerstyles.gridMargin}>
                <Grid item width={"100%"}>
                    <TabContext value={suppliersTab}>
                        <AntTabs onChange={suppliersTabChange}>
                            <AntTab label="Active" value="Active" />
                            <AntTab label="New" value="New" />
                            <AntTab label="Edited Request" value="Edited Request" />
                            <AntTab label="Deactivation Request" value="Deactivation Request" />
                            <AntTab label="Inactive" value="Inactive" />
                        </AntTabs>
                        <TabPanel value={"Active"}>
                            <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                                <Grid item style={customerstyles.searchDateFilter}  >
                                    <SearchFilter placeholder={'Search supplier'} setInputValue={setInputValue} />
                                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                </Grid>
                                <Grid item>
                                    <Exports  exportData={exportData} activeTabs={suppliersTab} csvExport={csvExport} />
                                </Grid>
                            </Grid>
                            <ActiveSuppliers activeSuppliers={activeSuppliers} loading={loading} error={error} />
                        </TabPanel>

                        {/* suspended! */}
                        <TabPanel value={"New"}>
                            <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                                <Grid item style={customerstyles.searchDateFilter}  >
                                    <SearchFilter placeholder={'Search supplier'} setInputValue={setInputValue} />
                                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                </Grid>
                                <Grid item>
                                    <Exports exportData={exportData} activeTabs={suppliersTab} csvExport={csvExport} />
                                </Grid>
                            </Grid>
                            <NewSuppliers loading={loading} error={error}/>
                        </TabPanel>

                        <TabPanel value={"Edited Request"}>
                            <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                                <Grid item style={customerstyles.searchDateFilter}  >
                                    <SearchFilter placeholder={'Search supplier'} setInputValue={setInputValue} />
                                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                </Grid>
                                <Grid item>
                                    <Exports exportData={exportData} activeTabs={suppliersTab} csvExport={csvExport} />
                                </Grid>
                            </Grid>
                            <EditedRequest />
                        </TabPanel>

                        <TabPanel value={"Deactivation Request"}>
                            <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                                <Grid item style={customerstyles.searchDateFilter}  >
                                    <SearchFilter placeholder={'Search supplier'} setInputValue={setInputValue} />
                                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                </Grid>
                                <Grid item>
                                    <Exports exportData={exportData} activeTabs={suppliersTab} csvExport={csvExport} />
                                </Grid>
                            </Grid>
                            <DeactivationRequest />
                        </TabPanel>

                        <TabPanel value={"Inactive"}>
                            <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                                <Grid item style={customerstyles.searchDateFilter}  >
                                    <SearchFilter placeholder={'Search supplier'} setInputValue={setInputValue} />
                                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                                </Grid>
                                <Grid item>
                                    <Exports exportData={exportData} activeTabs={suppliersTab} csvExport={csvExport} />
                                </Grid>
                            </Grid>
                            <InactiveSuppliers loading={loading} error={error} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </div>
    );
};

export default SupplierList;
