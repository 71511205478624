import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    description: "",
};

const bankToUpdateSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.name = action.payload.name;
      state.description = action.payload.description;
    },
  },
});

export const { setDetails } = bankToUpdateSlice.actions;

export default bankToUpdateSlice.reducer;
