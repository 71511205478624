import { CircularProgress, Stack, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { ResponsiveContainer } from "recharts";


const baseUrl = process.env.REACT_APP_BASE_URL;

const PaymentModeChart = () => {
  const [data, setData] = useState([
    { name: "Jan", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Feb", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Mar", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Apr", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "May", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Jun", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Jul", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Aug", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Sep", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Oct", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Nov", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
    { name: "Dec", Card: 0, Mpesa: 0, Wallet: 0, Cash: 0, BankPaybll: 0 },
  ]);

  const [loading, setLoading] = useState(true);

  let year = useSelector(state => state.salesDropdown.clickedItem2)

  let yearToPass 

  if(year === "2022") {
    yearToPass = 2022
  }
  else if(year === "2023") {
    yearToPass = 2023
  }
  else if(year === "2024") {
    yearToPass = 2024
  }

  const fetchPaymentModeTotalsByMonth = async () => {
    setLoading(true)
    const response = await axios.post(
      baseUrl + "/api/fetchPaymentModeTotalsByMonth2",
      {
        year: yearToPass
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    
    let result = response.data.result;

    setData([
      {
        name: "Jan",
        Card: result.January["Card Payment"] || 0,
        Mpesa: result.January["MPESA"] || 0,
        Wallet: result.January["PAYITCARD"] || 0,
        Cash: result.January["Cash Payment"] || 0,
        BankPaybll: result.January["Paybill Payment"] || 0,
      },
      {
        name: "Feb",
        Card: result.February["Card Payment"] || 0,
        Mpesa: result.February["MPESA"] || 0,
        Wallet: result.February["PAYITCARD"] || 0,
        Cash: result.February["Cash Payment"] || 0,
        BankPaybll: result.February["Paybill Payment"] || 0,
      },
      {
        name: "Mar",
        Card: result.March["Card Payment"] || 0,
        Mpesa: result.March["MPESA"] || 0,
        Wallet: result.March["PAYITCARD"] || 0,
        Cash: result.March["Cash Payment"] || 0,
        BankPaybll: result.March["Paybill Payment"] || 0,
      },
      {
        name: "Apr",
        Card: result.April["Card Payment"] || 0,
        Mpesa: result.April["MPESA"],
        Wallet: result.April["PAYITCARD"] || 0,
        Cash: result.April["Cash Payment"] || 0,
        BankPaybll: result.April["Paybill Payment"] || 0,
      },
      {
        name: "May",
        Card: result.May["Card Payment"] || 0,
        Mpesa: result.May["MPESA"] || 0,
        Wallet: result.May["PAYITCARD"] || 0,
        Cash: result.May["Cash Payment"] || 0,
        BankPaybll: result.May["Paybill Payment"] || 0,
      },
      {
        name: "Jun",
        Card: result.June["Card Payment"] || 0,
        Mpesa: result.June["MPESA"] || 0,
        Wallet: result.June["PAYITCARD"] || 0,
        Cash: result.June["Cash Payment"] || 0,
        BankPaybll: result.June["Paybill Payment"] || 0,
      },
      {
        name: "Jul",
        Card: result.July["Card Payment"] || 0,
        Mpesa: result.July["MPESA"] || 0,
        Wallet: result.July["PAYITCARD"] || 0,
        Cash: result.July["Cash Payment"] || 0,
        BankPaybll: result.July["Paybill Payment"] || 0,
      },
      {
        name: "Aug",
        Card: result.August["Card Payment"] || 0,
        Mpesa: result.August["MPESA"] || 0,
        Wallet: result.August["PAYITCARD"] || 0,
        Cash: result.August["Cash Payment"] || 0,
        BankPaybll: result.August["Paybill Payment"] || 0,
      },
      {
        name: "Sep",
        Card: result.September["Card Payment"] || 0,
        Mpesa: result.September["MPESA"] || 0,
        Wallet: result.September["PAYITCARD"] || 0,
        Cash: result.September["Cash Payment"] || 0,
        BankPaybll: result.September["Paybill Payment"] || 0,
      },
      {
        name: "Oct",
        Card: result.October["Card Payment"] || 0,
        Mpesa: result.October["MPESA"] || 0,
        Wallet: result.October["PAYITCARD"] || 0,
        Cash: result.October["Cash Payment"] || 0,
        BankPaybll: result.October["Paybill Payment"] || 0,
      },
      {
        name: "Nov",
        Card: result.November["Card Payment"] || 0,
        Mpesa: result.November["MPESA"] || 0,
        Wallet: result.November["PAYITCARD"] || 0,
        Cash: result.November["Cash Payment"] || 0,
        BankPaybll: result.November["Paybill Payment"] || 0,
      },
      {
        name: "Dec",
        Card: result.December["Card Payment"] || 0,
        Mpesa: result.December["MPESA"] || 0,
        Wallet: result.December["PAYITCARD"] || 0,
        Cash: result.December["Cash Payment"] || 0,
        BankPaybll: result.December["Paybill Payment"] || 0,
      },
    ]);

    setLoading(false);
  };

  useEffect(() => {
    fetchPaymentModeTotalsByMonth();
  }, [year]);

  const CustomYAxisTick = ({ x, y, payload }) => {
    let formattedValue = payload.value;
    formattedValue /= 1000000;
    formattedValue = formattedValue.toLocaleString(); // Format value as a comma-separated string
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
          {formattedValue} M
        </text>
      </g>
    );
  };

  //formatting tooltip values
  const formatValue = (value) => value.toLocaleString();

  return (
    <Stack width={"100%"}>
      {loading ? (
        // <CircularProgress
        //   sx={{
        //     alignSelf: "center",
        //     justifySelf: "center",
        //     mt: "150px",
        //   }}
        // />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={400}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
        />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart  data={data}>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            <YAxis tick={<CustomYAxisTick />} unit="M" />
            <Tooltip formatter={formatValue} />
            <Legend iconType="circle" />
            <Bar dataKey="Card" fill="#032541" />
            <Bar dataKey="Mpesa" fill="#f79009" />
            <Bar dataKey="Wallet" fill="#17ae7b" />
            <Bar dataKey="Cash" fill="#0381ed" />
            {/* <Bar dataKey="Cash" fill="" /> */}
            <Bar dataKey="BankPaybll" fill="#ff462e" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Stack>
  );
};

export default PaymentModeChart;
