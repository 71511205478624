import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partnerId: "",
  data: [],
  businessNo: "",
  data2: [] //used to store businessnames..that are selected when moving a termUser to new business
}


const partnerSelectSlice = createSlice({
  name:"partnerSelect",
  initialState,
  reducers:{
    handlePartnerId:(state, action) => {
      console.log("action.payload: ", action.payload)
      state.partnerId = action.payload
    },
    handleBusinessNo:(state, action) => {
      state.businessNo = action.payload
    },
    clearBusinessNo:(state, action) => {
      state.businessNo = ""
    },
    handleData:(state, action) => {
      state.data = action.payload
    },
    handleData2:(state, action) => {
      state.data2 = action.payload
    },
  }
})

export const { handlePartnerId, handleData, handleData2, handleBusinessNo, clearBusinessNo } = partnerSelectSlice.actions;

export default partnerSelectSlice.reducer