import { Grid, Typography, Checkbox, InputAdornment, TextField, Breadcrumbs, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux'
import { handleSaveStaffCoursesUnits } from '../../../features/staffSlice';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
let baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Staff</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>TeachingStaff</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Assign Courses & Units</Typography>
]

export default function AddUnitsStaff() {
    const navigate = useNavigate()
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue, setSearchValue] = useState('')
    const [openCollapse, setOpenCollapse] = useState(false)
    const [opencourse, setOpenCourse] = useState("")
    const [selectedCourses, setSelectedCourse] = useState([])
    const dispatch = useDispatch()

    // handle select courses

    const handleClick = (courseName, courseId) => {
        // check if the course has units

        const course = courses?.find((c) => c.courseName === courseName);
        if (course?.units?.length > 1) {
            setOpenCourse(courseName);
            setOpenCollapse(!openCollapse);

            // check whether the course already selected

            const isCourseSelected = selectedCourses?.some((course) => course?.courseId === courseId);

            if (!isCourseSelected) {
                setSelectedCourse((prev) => [
                    ...prev, {
                        courseId: courseId,
                        courseName: courseName,
                        units: []
                    }
                ])
            }
        } else {
            return setErrorShow({ state: true, message: `${courseName} has no units to assign to this staff` })
        }
    }

    // handle select units
    const handleSelectCourses = (unitId, courseId, unitName, unitCode) => {
        setSelectedCourse((prev) =>
            prev?.map((course) => {
                if (course?.courseId === courseId) {
                    const isUnitSelected = course?.units?.some((unit) => unit?.unitId === unitId);

                    if (isUnitSelected) {
                        return {
                            ...course,
                            units: course?.units?.filter((unit) => unit?.unitId !== unitId),
                        };
                    } else {
                        return {
                            ...course,
                            units: [
                                ...course.units,
                                {
                                    unitId: unitId,
                                    unitName: unitName,
                                    unitCode: unitCode,
                                },
                            ],
                        };
                    }
                }
                return course;
            })
        );
    };




    // get courses

    const [courses, setCourses] = useState([])

    const getCourses = async () => {
        const studentCourseDetails = await fetch(baseUrl + `/api/v1/getUnitsInCourse?searchValue=${searchValue}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        }
        );

        if (studentCourseDetails?.status === 200) {
            await studentCourseDetails.json().then((data) => {
                // console.log(data , 'hoes')
                setCourses(data?.data)
            })
        }
    }

    useEffect(() => {
        getCourses();
    }, [searchValue])

    console.log(courses ,'courses >>>>')

    // handle next

    const handleNext = () => {
        dispatch(handleSaveStaffCoursesUnits(selectedCourses))
        navigate(`/school/universityStaff?PreviewStaff`)
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Assign Courses & Units</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Grid container display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <Typography>Tap on course to view units</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>Add Unit</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <TextField name={'search'} value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={'search'}
                    variant="outlined"
                    margin="dense"
                    sx={{ width: '100%' }}
                    InputProps={{
                        sx: {
                            height: '3.438rem',
                            width: '100%',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        },
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <Grid container width={'100%'}>
                    {courses?.map((course) => (
                        <Grid item width={'100%'} py={2} sx={{ borderBottom: '0.1px solid #707070' }}>
                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography>{course?.courseName}</Typography>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    {openCollapse ? <ExpandLess onClick={() => handleClick(course?.courseName, course?._id)} /> : <ExpandMore onClick={() => handleClick(course?.courseName, course?._id)} />}
                                </Grid>
                            </Grid>
                            {opencourse === course?.courseName && course?.units?.length > 1 ?
                                <Grid container width={'100%'}>
                                    {course?.units?.length > 0 && course?.units?.filter(unit=>unit?.status === "ACTIVE").map((unit) => (
                                        <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography>{unit?.unitName}</Typography>
                                            <Checkbox onChange={() => handleSelectCourses(unit?._id, course?._id, unit?.unitName, unit?.unitCode)} checked={selectedCourses
                                                ?.find((selectedCourse) => selectedCourse.courseId === course?._id)
                                                ?.units?.some((selectedUnit) => selectedUnit.unitId === unit?._id)} />
                                        </Grid>
                                    ))}
                                </Grid> : null}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid mt={2} item width={'49%'} display={'flex'} justifyContent={'flex-end'}>
                <Button disabled={selectedCourses.length < 1} onClick={() => handleNext()} style={selectedCourses.length < 1 ? { display: 'flex', backgroundColor: 'rgba(3, 37, 65, 0.4)', color: '#fff', alignItems: 'center', textTransform: 'inherit' } : { display: 'flex', backgroundColor: '#032541', border: '1px #032541', color: '#fff', alignItems: 'center', textTransform: 'inherit' }}> Preview  Staff Details</Button>
            </Grid>
        </Grid>
    )
}