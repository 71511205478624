import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import HttpComponent from "../../../School/MakeRequest";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { currencyconverter } from "../../../../common/currencyconverter";
import DateFormatter from "../../../../utils/dateFormatter";
import ExportSearchBar from "../../../../common/exportsearchbar";

const MerchantReport = () => {
  // get fee configs;
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const [searchValue, setSearchValue] = useState("");
  const [startDate, newStartDate] = useState("");
  const [endDate, newEndDate] = useState("");

  const merchantCoulmn = [
    {
      field: "transactionID",
      headerName: "Txn Ref",
      flex: 1,
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Txn Ref</Typography>
          </Box>
        );
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>{params?.row?.transactionID}</Typography>
          </Box>
        );
      },
    },
    {
      field: "cardNo",
      headerName: "Txn Ref",
      flex: 1,
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Card Number</Typography>
          </Box>
        );
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>{params?.row?.cardNo}</Typography>
          </Box>
        );
      },
    },
    {
      field: "transamount",
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Amount</Typography>
          </Box>
        );
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>{currencyconverter(params?.row?.transamount)}</Typography>
          </Box>
        );
      },
      headerName: "Amount",
      flex: 1,
    },
    // { field: "TXN Ref", headerName: "Total Amount", flex: 1 },
    {
      field: "businessName",
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Business Name</Typography>
          </Box>
        );
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>{params?.row?.businessName}</Typography>
          </Box>
        );
      },
      headerName: "Business Name",
      flex: 1,
    },
    {
      field: "businessNo",
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Business ID</Typography>
          </Box>
        );
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>{params?.row?.businessNo}</Typography>
          </Box>
        );
      },
      headerName: "Business ID",
      flex: 1,
    },
    {
      field: "createdAt",
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Created At</Typography>
          </Box>
        );
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>{DateFormatter(params?.row?.createdAt)}</Typography>
          </Box>
        );
      },
      headerName: "Business ID",
      flex: 1,
    },
    { field: "accountCredited", headerName: "Account Credited", flex: 1 },
  ];

  const getMerchantTransactions = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/listmerchanttransactions?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data.response.data, isLoading: false, count: data?.response?.count });
      }
    });
  };

  useEffect(() => {
    getMerchantTransactions();
  }, [pageState.page, dataGridPageSize, searchValue, startDate ,endDate ]);

  const merchantData =
    pageState.data?.length > 0 &&
    pageState?.data?.map((trans) => ({
      id: trans?._id,
      transactionID: trans?.transactionID,
      businessName: trans?.businessName,
      transamount: trans?.transamount,
      businessNo: trans?.businessNo,
      cardNo: trans?.cardNumber,
      accountCredited: trans?.accountCredited,
      cardPresentCharge: trans?.cardPresentCharge,
      createdAt: trans?.createdAt,
    }));

  const [csvExport, setcsvExport] = useState({ headers: [], data: [] });
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [startTransactionTime, setStartTransactionTime] = useState("");
  const [endTransactionTime, setEndTransactionTime] = useState("");
  const [activeTab, setActiveTab] = useState(`Merchant Report for period ${startTransactionTime} and ${endTransactionTime} printed on ${new Date().toDateString()}`);
  useEffect(() => {
    const csvColumns = [
      { label: "Txn Ref", key: "transactionID" },
      { label: "Card Number", key: "cardNo" },
      { label: "Amount", key: "transamount" },
      { label: "BusinessName", key: "businessName" },
      { label: "Date", key: "createdAt" },
      { label: "Business ID", key: "businessNo" },
      { label: "Account Credited", key: "accountCredited" },
    ];

    const pdfData = pageState?.data?.map((transaction) => {
      return {
        transactionID: transaction.transactionID,
        cardNo: transaction.cardNumber,
        transamount: transaction.transamount,
        businessName: transaction.businessName,
        createdAt: transaction.createdAt,
        businessNo: transaction.businessNo,
        accountCredited: transaction.accountCredited,
      };
    });

    const headers = [["Txn Ref", "Card Number", "Amount", "BusinessName", "Date", "Business ID", "Account Credited"]];

    const data = pdfData?.map((data) => [data.transactionID, data.cardNo, data.transamount, data.businessName, data.createdAt, data.businessNo, data.accountCredited]);

    setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
    setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
    setExportData({ headers: headers, data: data });
    setcsvExport({ headers: csvColumns, data: pdfData });
  }, [pageState.page, dataGridPageSize, searchValue]);

  return (
    <div>
      <Grid container direction={"column"} style={{ padding: "30px 0px 0px 20px" }}>
        <Grid item mb={2}>
          <Typography sx={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Merchant Report</Typography>
        </Grid>

        <Grid item mb={2}>
          <Breadcrumb name={"Merchant Transaction Fee Configuration"} />
        </Grid>

        <Grid item>
          <ExportSearchBar activeTabs={activeTab} csvExport={csvExport} exportData={exportData} textfiledplaceholder={"Search..."} setSearchValue={setSearchValue} newStartDate={newStartDate} newEndDate={newEndDate} />
        </Grid>
        <Grid item>
          <CustomDataGrid customStyles={{ border: "none", height: "700px", width: "100%" }} column={merchantCoulmn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={merchantData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default MerchantReport;
