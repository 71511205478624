import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "Active"
}


const currentTabSlice = createSlice({
  name:"currentTab",
  initialState,
  reducers:{
    handleTabChange:(state, action) => {
      state.tab = action.payload
    },
  }
})

export const { handleTabChange } = currentTabSlice.actions;

export default currentTabSlice.reducer