/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    Box,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Breadcrumbs2 from "../../components/Breadcrumbs/Breadcrumbs2";
  import SearchIcon from "@mui/icons-material/Search";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  import GlobalConfigurationsTable from "../../components/GlobalConfigurations/GlobalConfigurationsTable";
  import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
  import { useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
//   import Alert from "@mui/material/Alert";
//   import { setSearch } from "../../../features/searchSlice";
  import Papa from "papaparse";
  import { makeStyles } from "@mui/styles";
import { setSearch } from "../../../../features/searchSlice";
import PartnerUsersTable from "./PartnerUsersTable";

  
  
  const useStyles = makeStyles((theme) => ({
    focusedTextField: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#032541", 
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "#032541",
      },
    },
  }));
  
  function exportArrayToCSV(data, filename) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
  
  function ViewPartnerUsers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);
  
    // const editedAlertState = useSelector((state) => state.alertState.edited);
    // const [showEditedAlert, setShowEditedAlert] = useState(false);
  
    // const handleShowAlert = () => {
    //   if (editedAlertState === true) {
    //     setShowEditedAlert(true);
  
    //     // Automatically hide the alert after 5 seconds
    //     setTimeout(() => {
    //       setShowEditedAlert(false);
    //       dispatch(handleEdited(false));
    //     }, 5000);
    //   }
    // };
  
    // useEffect(() => {
    //   handleShowAlert();
    // }, [editedAlertState]);
  
    const nextMove = () => {
    //   if (clickedItem === "Edit") {
    //     navigate("/EditGlobalConfig");
    //   }
    };
  
    useEffect(() => {
      nextMove();
    }, [clickedItem]);
  
    const [searchValue, setSearchValue] = useState("");
  
    const handleSearch = (e) => {
      setSearchValue(e.target.value);
    };
  
    //seeting search value in redux when user types in textfield
    useEffect(() => {
      if (searchValue) {
        dispatch(setSearch(searchValue));
      } else {
        dispatch(setSearch(""));
      }
    }, [searchValue]);
  
    const exportData = useSelector((state) => state.exportDetails.globalConfigs);
  
    const handleExport = () => {
      exportArrayToCSV(exportData, "Global Configs.csv");
    };
  
    return (
      <Stack bgcolor={"white"} p={2} spacing={1}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <ArrowBackIosNewIcon
              sx={{
                color: "#707070",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                window.history.back();
              }}
            />
            <Typography
              fontFamily="Poppins"
              fontSize={25}
              fontWeight="600"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Partner Users
            </Typography>
          </Stack>
          {/* {showEditedAlert && (
            <Alert variant="filled" severity="success">
              Config details Edited successfully...
            </Alert>
          )} */}
        </Stack>
        <Stack direction="row" justifyContent={"space-between"}>
      <Breadcrumbs2 point1={"Partners"} link1={"/Partners"} point2={"Partner Users"} />

          <Button
            variant="outlined"
            sx={{
              bgcolor: "#f5f6f7",
              borderColor: "#f5f6f7",
              color: "#032541",
              ":hover": {
                borderColor: "#032541",
              },
              textTransform: "none",
            }}
            onClick={handleExport}
          >
            Export
            <MoreVertIcon
              sx={{
                color: "#032541",
              }}
            />
          </Button>
        </Stack>
        <Box width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
            <TextField
              id="input-with-icon-textfield"
              label="Search by username"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
              className={classes.focusedTextField}
            />
          </Stack>
          <Stack>
            <PartnerUsersTable />
          </Stack>
        </Box>
      </Stack>
    );
  }
  
  export default ViewPartnerUsers;
  