import React, { useState, useEffect } from "react";
import ReceiveStockPreview from "./stockModals/receiveStockPreview";
import { Grid } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { stockStyles } from "./stockStyling";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbcomponent from "./breadcrumb";
import PreviewDeliveryNote from "./stockModals/previewDeliveryNote";

const ReceiveStock = () => {
  const navigate = useNavigate();
  const breadcrumb = [
    <span key={1}>Dashboard</span>,
    <span key={2}>Stock</span>,
    <span key={3} style={{ color: "red" }}>
      Stock Order
    </span>,
  ];

  const { state } = useParams();

  const queryParams = decodeURIComponent(window.location.search);
  const queryParamsData = queryParams.slice(1)
  const po_Id = queryParamsData.split("?")[0]
  const purchaseOrderNo = queryParamsData.split("?")[1]

  // businessName
  // supplierName
  // recieverStatus

  const componentToMount = () => {
    if (state === "newStock") {
      return <ReceiveStockPreview po_Id={po_Id} purchaseOrderNo={purchaseOrderNo} />;
    }else if(state === "preview"){
      return <PreviewDeliveryNote purchaseOrderNo={purchaseOrderNo} po_Id={po_Id} />
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"} margin={"0px 0px 2% 0px"}>
        <Grid item width={"100%"} margin={"0px 0px 1% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 0% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      <Grid container>{componentToMount()}</Grid>
    </div>
  );
};

export default ReceiveStock;
