import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, Grid, Menu, MenuItem, Slide, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "../../../School/CustomSearchInput";
import { forwardRef, useEffect, useState } from "react";
import ExportMenu from "../../../School/ExportMenu";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import CreateCard from "./createCard";
import HttpComponent from "../../../School/MakeRequest";
import DateFormatter from "../../../../utils/dateFormatter";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCardBin from "./editCard";
import WarningImg from "../../../../images/warning.png"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RestoreImg from "../../../../images/restoreImg.svg"
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));

//transition

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//breadcrumbs
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Card Bins</Typography>
]


export default function CardBins() {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [cardId, setCardId] = useState(null)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const RenderCell = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };
        const handleActionClose = () => { setAnchorEl(null); };

        const [openRestoreDialog, setOpenRestoreDialog] = useState(false)

        const handleOpenRestoreDialog = () => { setOpenRestoreDialog(true) }
        const handleCloseRestoreDialog = () => { setOpenRestoreDialog(false) }

        const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false)
        
        const handleOpenDeactivateDialog = () => { setOpenDeactivateDialog(true) }
        const handleCloseDeactivateDialog = () => { setOpenDeactivateDialog(false) }
        
        const [openDeleteCardBin ,setOpenDeleteCardBin] = useState(false)
        
        const handleOpenDeleteCardBin = () => { setOpenDeleteCardBin(true) }
        const handleCloseDeleteCardBin = () => { setOpenDeleteCardBin(false) }

        function activateCardBin(){
            try {
                HttpComponent({
                    method: "PUT",
                    url: `/api/v1/editCardBin?cardBinId=${cardId}`,
                    body: {
                        status:"ACTIVE"
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: "successfully restored card bin" })
                        setCardId(null)
                        setTimeout(()=>{
                            handleCloseRestoreDialog()
                        },[])
                    } else {
                        setErrorShow({ state: true, message: "Error restoring cardbin" })
                        setTimeout(()=>{
                            handleCloseRestoreDialog()
                        },[])
                    }
                    getCardBins()
                })
            } catch (error) {
                setErrorShow({ state: true, message: error })
            }

        }

        function deactivateCardBin() {
            try {
                HttpComponent({
                    method: "PUT",
                    url: `/api/v1/editCardBin?cardBinId=${cardId}`,
                    body: {
                        status:"INACTIVE"
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: "successfully Deactivated card bin" })
                        setCardId(null)
                        setTimeout(()=>{
                            handleCloseDeactivateDialog()
                        },[])
                    } else {
                        setErrorShow({ state: true, message: "Error deactivating cardbin" })
                        setTimeout(()=>{
                            handleCloseDeactivateDialog()
                        },[])
                    }
                    getCardBins()
                })
            } catch (error) {
                setErrorShow({ state: true, message: error })
            }

        }

        function deleteCardBin() {
            try {
                HttpComponent({
                    method: "DELETE",
                    url: `/api/v1/deleteCardBin?cardBinId=${cardId}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: "successfully deleted card bin" })
                        setCardId(null)
                        setTimeout(()=>{
                            handleCloseDeleteCardBin()
                        },[])
                    } else {
                        setErrorShow({ state: true, message: "Error delete cardbin" })
                        setTimeout(()=>{
                            handleCloseDeleteCardBin()
                        },[])
                    }
                    getCardBins()
                })
            } catch (error) {
                setErrorShow({ state: true, message: error })
            }

        }

        return (
            <div>
                <Dialog
                    open={openRestoreDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={RestoreImg} style={{ width: '100px' }} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Restore  CardBin</h6>
                            <span>Do you want to restore this cardbin ,  </span>
                            <span>This action cannot be undone</span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestoreDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={activateCardBin} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Restore</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeactivateDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={WarningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Deactivate  Cardbin</h6>
                            <span>Do you want to deactivate this cardbin  </span> <br></br>
                            {/* <span>This action cannot be undone</span> */}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeactivateDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={deactivateCardBin} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeleteCardBin}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={WarningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Delete CardBin ?</h6>
                            <span>Do you want to delete this cardbin  </span> <br></br>
                            {/* <span>This action cannot be undone</span> */}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteCardBin} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={deleteCardBin} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Delete</Button>
                    </DialogActions>
                </Dialog>
                <MoreVertIcon onClick={handleClick} />
                {params?.row?.status === 'ACTIVE' ?
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={() => { navigate(`/cards/cardbins?editCard`); setCardId(params?.row?._id) }}>
                            <EditIcon style={{ color: "#032541" }} /> Edit CardBin
                        </MenuItem>
                        <MenuItem disableRipple onClick={() => { handleOpenDeactivateDialog(); setCardId(params?.row?._id); setAnchorEl(null) }}>
                            <NotInterestedIcon style={{ color: "#DC3545FF" }} />  Deactivate CardBin
                        </MenuItem>
                    </StyledMenu> :
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={() => { handleOpenDeleteCardBin(); setCardId(params?.row?._id); setAnchorEl(null) }}>
                            <DeleteIcon style={{ color: "#DC3545FF" }} /> Delete CardBin
                        </MenuItem>
                        <MenuItem disableRipple onClick={() => { handleOpenRestoreDialog(); setCardId(params?.row?._id); setAnchorEl(null) }} >
                            <RestartAltIcon style={{ color: "#17ae7b" }} /> Restore CardBin
                        </MenuItem>
                    </StyledMenu>
                }
            </div>
        )
    }

    const AllCardBins = [
        { headerName: "Name", field: 'cardName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Name</strong>) } },
        { headerName: "CardBinNo", field: 'cardNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Card BIN No</strong>) } },
        { headerName: "Card Type", field: 'cardType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Card Type</strong>) } },
        { headerName: "Suspense Acc", field: 'suspenseAccount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Suspense Acc</strong>) } },
        {
            headerName: "modifiedat", field: 'modifiedAt', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Modified At</strong>) }, renderCell: (params) => {
                return (
                    <>
                        {DateFormatter(params?.row?.createdAt)}
                    </>
                )
            }
        },
        { headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) },renderCell:(params)=>{
            return(
                <>
                   <div><span style={params?.row?.status === 'INACTIVE' ? { color: "#dc3545" } : {color:"#17ae7b"}}>{params?.row?.status}</span></div>
                </>
            )
        } },
        { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: RenderCell },
    ]

    const fileData = []
    const fileHeaders = []
    const csvColumns = []
    const fileDataPDF = []


    // get card bins

    function getCardBins() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getCardBins`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'card bins')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getCardBins()
    }, [state])

    return (
        <Grid container direction={'column'}>
            {state === 'createnewcardbin' ? <CreateCard /> : state === 'editCard' ? <EditCardBin cardId={cardId} /> :
                <>
                 <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                 <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                    <Grid item>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Card Bins</Typography>
                    </Grid>
                    <Grid item display={'flex'} mt={2}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                            <Grid item>
                                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => navigate('/cards/cardbins?createnewcardbin')} style={{ background: "#032541", textTransform: "inherit", width: "150px", color: "#fff" }}>Create Card Bin</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mt={2} width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search  ...'} />
                        <ExportMenu fileData={fileData} fileDataPDF={fileDataPDF} csvColumns={csvColumns} fileHeaders={fileHeaders} fileName={'Card bins'} />
                    </Grid>
                    <Grid item mt={2}>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={AllCardBins}
                            rows={pageState?.data}
                            getRowId={row => row._id}
                        />
                    </Grid>
                </>
            }
        </Grid>
    )
} 