import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import DateFormatter from '../../../../utils/dateFormatter';
import HttpComponent from '../../../School/MakeRequest';
import AutocompleteComponent from '../../../../common/autoComplete';
import Breadcrumb from '../Breadcrumbs/Breadcrumbs';
import { Exports } from '../../../customerAccounts/customerComponents/exports';



// const breadcrumbs = [
//   <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
//   <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Pocket Money</Typography>,
//   <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
//   <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>All Transactions</Typography>
// ]

let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? "KES" : localCurrency, }).format(value);


function Zpmalltransaction() {
    const [searchValue, setSearchValue] = useState('')
    const [transactionType, setTransactionType] = useState('')

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("");
    const group = localStorage.getItem('group')



    useEffect(() => {
        const setLastSevenDays = () => {
            const result = [];
            for (let i = 0; i < 7; i++) {
                const d = new Date();
                d.setDate(d.getDate() - i);
                result.push(d.toLocaleDateString());
            }
            const lastSevenFirstStart = result[0];
            const lastSevenLastEnd = result[result.length - 1];
            setStartDate(lastSevenFirstStart);
            setEndDate(lastSevenLastEnd);
        };

        setLastSevenDays();
    }, []);



    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1));
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1);
                const currentDay = new Date();
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1);
                const lastDayLastMonth = new Date(date2.setDate(1));
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                function defaultLast7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                defaultLast7Days();
                break;
        }
    }, [handleDaysChange]);

    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    console.log(data, 'data >>>>>')
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness();
    }, [selectedValue])


    const [allRegions, setAllRegions] = useState([])
    const [selectedRegion, setSelectedRegion] = useState('')
    function getAllRegionsList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/partnerregions`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllRegions(data?.response?.data?.filter(item => item?.status === 'Active'))
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllRegionsList()
    }, [])

    const [allBranches, setAllBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState('')

    function getAllBranchesList() {
        try {
            HttpComponent({
                method: "GET",
               // url: `/api/v1/allregionpartnerbranches`,
                url:`/api/v1/allregionpartnerbranchesbyid?regionId=${selectedRegion}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const myfoundbranch = data?.response?.data?.filter(item => item?.status === 'Active')?.map((item) => ({
                        value: item?.branchCode,
                        label: item?.branchName
                    }))
                    setAllBranches(myfoundbranch)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllBranchesList()
    }, [selectedRegion])

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    //all  transactions columns

    const AllTransactionColumns = [
        { headerName: "Receipt No", field: 'receiptNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Receipt No</strong>) } },
        { headerName: "Created At", field: 'transTime', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <span>{DateFormatter(params?.row?.transactionTime)}</span> },
        { headerName: "Source", field: 'source', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Source</strong>) }, renderCell: (params) => <span>{params?.row?.source}</span> },
        { headerName: "Transaction Type", field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >TxN Type</strong>) }, renderCell: (params) => <Typography sx={{ color: params?.row?.transactionType === 'DEBIT' ? '#dc3545' : params?.row?.transactionType === 'CREDIT' ? '#17ae7b' : '#707070' }}>{params?.row?.transactionType}</Typography> },
        { headerName: "businessId", field: 'businessId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Institutions</strong>) } },
        { headerName: "Teller Id", field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) } },
        { headerName: "Terminal Serial", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) } },
        { headerName: "amount", field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Amount (KES)</strong>) }, renderCell: (params) => <Typography>{numberFormat(params?.row?.amount)}</Typography> },
    ]

    // fetch data

    function getAllTransactionsList() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getAllTransactionsByAdmin?page=${pageState.page}&limit=${dataGridPageSize === 100 ? pageState.total: dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}&businessNumber=${selectInputValue?.bsNo ? selectInputValue?.bsNo : ''}&branchCode=${selectedBranch}&regionId=${selectedRegion}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllTransactionsList()
    }, [dataGridPageSize, pageState.page, startDate, searchValue, selectInputValue?.bsNo, endDate ,selectedRegion ,selectedBranch])

    // exports

      // exported data;
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });

    useEffect(() => {

        const filedata = pageState?.data?.map((data) => {
            return {
                "Receipt No": data?.receiptNo,
                "Date & Time": DateFormatter(data?.transactionTime),
                "Source": data?.source,
                "TXN Type": data?.transactionType,
                "Institution": data?.businessId,
                "Teller Id": data?.tellerId,
                "Terminal Serial": data?.terminalSerialNumber,
                "Total Amount": numberFormat(data?.amount),
                "Business No": data?.bsNo
            }
        })

        const fileHeaders = [["Receipt No", "Date & Time", "Source", "TXN Type", "Institution", 'Teller Id', 'Terminal Serial', 'Total Amount' , 'Business No']]
        const csvColumns = [
            { label: "Receipt No", key: "Receipt No" },
            { label: "Date & Time", key: "Date & Time" },
            { label: "Source", key: "Source" },
            { label: "TXN Type", key: "TXN Type", },
            { label: "Institution", key: "Institution" },
            { label: "Teller Id", key: "Teller Id", },
            { label: "Terminal Serial", key: "Terminal Serial", },
            { label: "Total Amount", key: "Total Amount", },
            {label: "Business No", key: "Business No"}
        ];

        const fileDataPDF = pageState?.data?.map((data) => [data?.receiptNo, DateFormatter(data?.transactionTime), data?.source, data?.transactionType, data?.businessId, data?.tellerId, data?.terminalSerialNumber, numberFormat(data?.amount) , data?.bsNo]);

        setCsvExport({ headers: csvColumns, data: filedata })
        setExportData({ headers: fileHeaders, data: fileDataPDF })
    

    }, [pageState.data,startDate, searchValue, selectInputValue?.bsNo, endDate ])


    

    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignItems={'center'} >
                <Stack alignItems='center' direction='row' spacing={2}>
                    {/* <ArrowBackIosNewIcon style={{ color: "#707070" }} /> */}
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Zed Pocket Money Transactions</Typography>
                </Stack>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                {/* <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs> */}
                <Breadcrumb name={'Zed Pocket Money Transactions'} />
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} mt={2} width={'100%'}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                    {/* <Grid item mt={1}>
                        <CustomSearchInput sm={{ width: "200px" }} placeholder={'search'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                    </Grid> */}
                    <Grid item>
                        <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "10px", width: '300px' }} />
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item display={'flex'}>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value='All'>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {group === 'Partner' && (
                        <Grid item>
                            <FormControl sx={{ width: '150px' }}>
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Region"
                                    value={selectedRegion}
                                    //defaultValue={'Last7days'}
                                    onChange={(e) => setSelectedRegion(e.target.value)}
                                >
                                    {allRegions?.map((region) => {
                                        return (
                                            <MenuItem value={region?._id}>{region?.regionName}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {group === 'Partner' && (
                    <Grid item>
                        <FormControl sx={{ width: '150px' }}>
                            <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Branch"
                                value={selectedBranch}
                                onChange={(e) => setSelectedBranch(e.target.value)}
                            >
                                {allBranches?.map((branch) => {
                                    return (
                                        <MenuItem value={branch?.value}>{branch?.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>)}
                    <Grid item sx={{marginTop:group === 'Partner' ? '4px' :''}}>
                        <Exports exportData={exportData} activeTabs={'Zed-Pocket Money All Transactions'}  csvExport={csvExport} />
                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDFExport} fileData={filedataExport} fileName={'All Transactions'} title={'Zed-Pocket Money All Transactions'} /> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[10, 20, 50, 70, 100 ]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) =>  setPageSize(newPageSize)}
                    columns={AllTransactionColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}

export default Zpmalltransaction
