import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/bankToUpdateSlice";
import { handleAdded, handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { handleTabChange } from "../../../../features/currentTabSlice";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function AddCardbin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  //   const reduxName = useSelector((state) => state.bankDetails.name);
  //   const reduxDescription = useSelector((state) => state.bankDetails.description);

  //value to set when user edits form
  const [name, setName] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [cardType, setCardType] = useState("");
  const [suspense, setSuspense] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleNo = (e) => {
    setCardNo(e.target.value);
  };
  const handleType = (e) => {
    setCardType(e.target.value);
  };
  const handleSuspense = (e) => {
    setSuspense(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleAdd = () => {
    try {
      axios
        .post(baseUrl + "/api/addCardbin", {
          name,
          cardNo,
          cardType,
          suspense,
        })
        .then((response) => {
          let data = response.data.data
          if (data) {
            console.log("added successfully");
            setLocalDetails({
              name,
              cardNo,
              cardType,
              suspense,
            });
            dispatch(handleAdded(true));
          } else {
            setFailMessage(response.data.message)
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      // dispatch(setDetails(localDetails));
      navigate("/CardBins");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Add Cardbin
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Card Bins"}
        link1={"/CardBins"}
        point2={"Add Card Bin"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Add Cardbin Details
            </Typography>
            <TextField
              label="Card Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              autoFocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Card No"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleNo}
              className={classes.focusedTextField}
            />
            <TextField
              label="Card Type"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.focusedTextField}
              onChange={handleType}
            />
            <TextField
              label="Suspense Acc"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.focusedTextField}
              onChange={handleSuspense}
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => {
                  dispatch(handleTabChange("Active"));
                  navigate("/CardBins");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddCardbin;
