import React, { useEffect, useState } from 'react'
import CustomSearchInput from '../../School/CustomSearchInput';
import AutocompleteComponent from '../../../common/autoComplete';
import { styled } from "@mui/material/styles";
import { Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { PendingRows } from './CustomRowTable';
import HttpComponent from '../../School/MakeRequest';
import { makeStyles } from "@mui/styles";
import { Link } from 'react-router-dom';
import DateFormatter from '../../../utils/dateFormatter';
import { CustomizedDate } from '../../customerAccounts/customerComponents/customDate';
import ExportMenu from '../../School/ExportMenu';
import CustomSelectField from "../../School/CustomSelectField";

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "14px",
        fontWeight: "700",
        color: '#032541'
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);
const PendingFailedDisbursedReport = () => {
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const classes = useStyles()

    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);

                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Default":
                setStartDate("");
                setEndDate("");
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                return;
        }
    }, [handleDaysChange, dateFrom, dateTo]);


    const fileData = []
    const fileDataPDF = []
    const csvColumns = []
    const fileHeaders = [[]]


    function fetchtOtherPayableTransactions() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/funds_transfer/transaction_all_report?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa transaction_all_report')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchtOtherPayableTransactions()
    }, [pageState.page, dataGridPageSize, searchValue])
    return (
        <Grid>
            <Grid item>
                <Grid container display={'flex'} direction={'column'}>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <Grid container alignItems={'center'} gap={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Grid item width={'20%'}>
                                <CustomSearchInput  value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                            </Grid>
                            <Grid item marginLeft={2} display={"flex"} gap={"10px"} width={'40%'} alignItems={"center"}>
                                {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                            </Grid>
                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`All transactions Payees`} title={`All transactions`} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>ZED FT NO.</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Bank FT NO</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Date Requested</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Raised By</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Payment To</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Payment Type</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {pageState?.data?.map((transaction) => (
                                    <PendingRows key={transaction?.name} row={transaction} tabValue={transaction?.status} />
                                ))} */}
                                {pageState?.data?.map((row) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <TableCell component="th" scope="row">
                                            <Link to={`/payables/transcation/${row?._id}`} style={{ color: '#000000', fontSize: '14px', fontWeight: 500 }}>
                                                {row?.ftpNumber}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row">{row?.bankReference || '--'}</TableCell>
                                        <TableCell component="th" scope="row">{DateFormatter(row?.dateRequested)}</TableCell>
                                        <TableCell component="th" scope="row">{row?.RaisedBy}</TableCell>
                                        <TableCell component="th" scope="row">{row?.paymentTo}</TableCell>
                                        <TableCell component="th" scope="row">{row?.paymentType}</TableCell>
                                        <TableCell component={'th'} scope="row">{numberFormat(row?.amount)}</TableCell>
                                        <TableCell component={'th'} style={{ color: row?.status === "Pending" ? '#ff8503' : row?.status === "Failed" ? '#dc3545' : row?.status === "Cancelled" ? '#ff8503' : row?.status === "Declined" ? '#dc3545' : '#17ae7b', fontWeight: 'bold' }} scope="row">{row?.status}</TableCell>
                                        <TableCell component="th" scope="row">{DateFormatter(row?.statusDate)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            component={'div'}
                            count={pageState?.total}
                            rowsPerPage={dataGridPageSize}
                            page={pageState.page}
                            onPageChange={(newPage) => {
                                setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
                            }} onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PendingFailedDisbursedReport