import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/userToUpdate";
import { handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", // Change this to the desired color
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function ResetUserPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  const reduxEmail = useSelector((state) => state.userDetails.email);
  const reduxPhoneNo = useSelector((state) => state.userDetails.phoneNo);

  //value to set when user edits form
  const [pass, setPass] = useState(null);

  const handlePass = (e) => {
    setPass(e.target.value);
  };
  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  // const handlePhoneNo = (e) => {
  //   setPhoneNo(e.target.value);
  // };
  // const handleUserGroup = (e) => {
  //   setUserGroup(e.target.value);
  // };

  const handleUpdate = () => {
    try {
      axios
        .post(
          baseUrl + "/api/resetPasswordAdmin",
          {
            password: pass,
            email: reduxEmail,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.data;
          if (data) {
            console.log("updated successfully");
            dispatch(handleEdited(true));
            navigate("/TermUsers");
          } else {
            setFailedMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [failMessage, setFailedMessage] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailedMessage(null);
        // dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Reset User Password
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2 point1={"Users"} link1={"/Users"} point2={"Reset Password"} />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Reset User Password
            </Typography>
            <TextField
              label="Phone No"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxPhoneNo}
              InputLabelProps={{
                shrink: true,
              }}
              // onChange={handlePhoneNo}
              disabled
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxEmail}
              InputLabelProps={{
                shrink: true,
              }}
              // onChange={handleEmail}
              disabled
            />

            <TextField
              label="New Password"
              variant="outlined"
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handlePass}
              className={classes.focusedTextField}
            />
            {/* <TextField
                label="User Group"
                variant="outlined"
                fullWidth
                size="small"
                defaultValue={reduxUserGroup}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleUserGroup}
                className={classes.focusedTextField}
              /> */}
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/TermUsers")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ResetUserPassword;
