import { Typography, Grid, Breadcrumbs, TextField, FormControl, InputLabel, Select, MenuItem, Button, Checkbox } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";


//breadcrumbs

const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Products / Services
  </Typography>,
  <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Create Product / Service
  </Typography>

];

//POST https://dev.zed.business/api/createProduct

export default function PartnerCreateProduct() {
  const [bsoptions, setBsOptions] = useState([])
  const [businessFound, setBusinessFound] = useState([])
  const [selectedBusinessNo, setSelectedbnssNumber] = useState('')
  const [productName, setProductName] = useState('')
  const [productType, setProductType] = useState([])
  const [prodCat, setProdCat] = useState()
  const [productCategory, setProductCategory] = useState([])
  const [reorderLevel, setReorderLevel] = useState(0);
  const [buyingPrice, setBuyingPrice] = useState()
  const [amount, setAmount] = useState()
  const [productDescription, setProductDescription] = useState()
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [dataGridPageSize, setPageSize] = useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
  const [unitOfMeasure, setUnitOfMeasure] = useState([])
  const [unitofMeasureValue, setUnitOfMeasureValue] = useState("")
  const [consumable, setConsumables] = useState(false);
  const [isWeightedProduct, setIsWeightProduct] = useState(false);
  let partnerName = localStorage.getItem("affiliatedPartner");

  const [productTypes, setProductTypes] = useState([])


  //  create partner

  // get partner business

  function getActiveBusinesses() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/getActiveBusinesses`,
        token: localStorage.getItem('X-Authorization'),
        body: {
          module: 'all module',
          partnerName: partnerName
        }
      }).then((data) => {
        if (data?.status === 200) {
          setBusinessFound(data?.response?.data)
        }

      })
    } catch (error) {

    }

  };

  useEffect(() => {
    getActiveBusinesses();
  }, []);


  const createArray = () => {
    if (businessFound) {
      const uniqueStrings = new Set();
      businessFound?.forEach((obj) => {
        let string = `${obj.businessNumber} - ${obj.businessName}`;
        uniqueStrings.add(string);
      });
      setBsOptions(Array.from(uniqueStrings));
    }
  };

  useEffect(() => {
    if (businessFound?.length > 0) createArray();
  }, [businessFound]);


  const [newBusinessNumber, setNewBusinessNumber] = useState('')

  useEffect(() => {
    if (selectedBusinessNo) {
      const regex = /(\d+) -/;
      let match = selectedBusinessNo.match(regex);
      if (match) {
        const extractedNumber = match[1];
        setNewBusinessNumber(extractedNumber)

      }
    }
  }, [selectedBusinessNo]);



  // fetch service type from db

  function getTypeOPtions() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/getVariablePriceStatus`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setProductTypes(data?.response?.data)
        }

      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getTypeOPtions()
  }, [])

  //service type
  const typeOptions = [
    {
      value: "Fixed",
      label: "Fixed",
    },
    {
      value: "Variable Price Item",
      label: "Variable Price Item",
    }
  ]

  const mapOptions = productTypes?.map((option) => ({ value: option?.priceStatusName, label: option?.priceStatusName }))
  const productCa = productCategory?.map((option) => (option.categoryName))




  const formData = { businessID: newBusinessNumber, productState:'Active', priceStatus: productType, productCategory: prodCat, reorderLevel: reorderLevel, buyingPrice: buyingPrice, productName: productName, productPrice: amount, productDescription: productDescription, unitOfMeasure: unitofMeasureValue, consumable: consumable, isWeightedProduct: isWeightedProduct }


  function fetchProductCategories() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/listCategories/Active?productService=Product&businessNumber=${newBusinessNumber}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setProductCategory(data?.response?.data?.filter((item)=>item?.categoryName !== 'Zpm Activation Fee'))
        }
        console.log(data, 'product categoriess')
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchProductCategories()
  }, [newBusinessNumber])

  const getUnitOfMeasure = () => {
    HttpComponent({
      method: 'GET',
      url: `/api/v1/products/unitofmeasure/get`,
      token: localStorage.getItem("X-Authorization")
    }).then((data) => {
      if (data?.status == 200 || data?.status == 201) {
        setUnitOfMeasure(data.response.data)
      }
    })
  }

  useEffect(() => {
    getUnitOfMeasure()
  }, [])

  //fetch pending products
  const [newprod, setNewProd] = useState([])

  function fetchnewprod() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/listProducts/New?${pageState.page}&limit=${pageState.pageSize}&search=`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        setNewProd(data.response.data)
        console.log(data, 'New products')
      })

    } catch (error) {

    }
  }

  //effect to re run

  useEffect(() => {
    fetchnewprod()
  }, [])

  // submit product data
  const navigate = useNavigate()

  function submitCreateProductorService() {
    if (!formData) {
      return setErrorShow({ state: true, message: `${formData} is missing` })
    }
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/createProduct`,
        body: formData,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data?.status === 201) {
          setSuccessShow({ state: true, message: data.response.message })

          setTimeout(() => {
            navigate(-1);
          }, [1000])

        } else {
          setErrorShow({ state: true, message: data.response.message })
        }
        console.log(data, 'new product created')
      })
    } catch (error) {

    }
  }



  return (
    <>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Grid container direction={'column'}>
        <Grid item display={'flex'} alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
          <ArrowBackIosIcon />
          <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Product / Service </Typography>
        </Grid>
        <Grid item mb={'10px'} mt={'10px'}>
          <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item mb={'10px'} mt={'10px'}>
          <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Create a Product /Service</Typography>
        </Grid>
        <Grid item mt={3} style={{ width: '400px' }}>
          <Autocomplete
            label="Select Business"
            variant="outlined"
            fullWidth
            size="medium"
            value={selectedBusinessNo}
            onChange={(event, newValue) => {
              setSelectedbnssNumber(newValue);
            }}
            options={bsoptions}
            renderInput={(params) => (
              <TextField {...params} label="Select Business" variant="outlined" />
            )}
          />
        </Grid>
        {newBusinessNumber ? <>
          <Grid item mb={'10px'} mt={'10px'}>
            <FormControl sx={{ minWidth: 400 }}>
              <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
              <Select
                label="Select Category"
                value={prodCat}
                onChange={(e) => setProdCat(e.target.value)}
              >
                {productCa?.map((option) => {
                  return (
                    <MenuItem value={option}>{option}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField id="outlined-basic" value={productName} onChange={(e) => setProductName(e.target.value)} label="Product Name" style={{ fontWeight: 200, width: "400px" }} />
          </Grid>
          <Grid item mt={2} mb={2}>
            <TextField id="outlined-basic" value={productDescription} onChange={(e) => setProductDescription(e.target.value)} label="Description" style={{ fontWeight: 200, width: "400px" }} />
          </Grid>
          <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Amount Type </InputLabel>
              <Select
                label="Amount Type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}

              >
                {mapOptions.map((option) => {
                  return (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <TextField id="outlined-basic" value={amount} onChange={(e) => setAmount(e.target.value)} label="Selling Price" style={{ fontWeight: 200, marginLeft: '5px', width: "200px" }} />
          </Grid>

          <Grid item mt={2} mb={2}>
            <TextField id="outlined-basic" type="number" value={buyingPrice} onChange={(e) => setBuyingPrice(e.target.value)} label="Buying Price" style={{ fontWeight: 200, width: "400px" }} />
          </Grid>


          <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
            <FormControl sx={{ minWidth: 400 }}>
              <InputLabel id="demo-simple-select-label">Unit of Measure </InputLabel>
              <Select
                label="Unit of Measure"
                value={unitofMeasureValue}
                onChange={(e) => setUnitOfMeasureValue(e.target.value)}

              >
                {unitOfMeasure.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>


          </Grid>

          {productType === "Variable Price Item" && <Grid item width={400} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <Checkbox onChange={() => setIsWeightProduct(!isWeightedProduct)} /> <span style={{ fontFamily: 'Poppins', }}>Weighed Price Item</span>
          </Grid>}

          <Grid item width={400} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <Checkbox onChange={() => setConsumables(!consumable)} /> <span style={{ fontFamily: 'Poppins', margin: '0 50px 0 0' }}>Consumable</span>
          </Grid>

          <Grid item mt={2} mb={2}>
            <TextField id="outlined-basic" type="number" value={reorderLevel} onChange={(e) => setReorderLevel(e.target.value)} label="Reorder Level" style={{ fontWeight: 200, width: "400px" }} />
          </Grid>
          <Grid container>
            <Grid item style={{ width: "400px" }} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
              <Button onClick={() => navigate(-1)} style={{ border: "1px solid #dc3545", marginRight: '10px', color: "#dc3545", textTransform: 'inherit', width: "100px" }}>Cancel</Button>
              <Button disabled={!prodCat || !buyingPrice || !productName || !productType || !amount || !productDescription || !unitofMeasureValue} onClick={submitCreateProductorService} style={!prodCat || !productName || !productType || !productDescription || !amount || !buyingPrice || !unitofMeasureValue ? { background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", opacity: .2 } : { background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", }}>Create</Button>
            </Grid>
          </Grid>
        </> : null}
      </Grid>
    </>
  )
}