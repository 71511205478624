import React , {useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import loginbackgroundImage from "../../images/loginBackground.png";
import loginLogo from "../../images/zedLogo.png";
import {Button , IconButton , Input , InputAdornment , Paper ,} from "@mui/material";
import {Visibility , VisibilityOff} from "@mui/icons-material";
import Box from "@mui/material/Box";

const baseUrl = process.env.REACT_APP_BASE_URL;

const styles = {
    paperContainer : {
        backgroundImage : `url(${loginbackgroundImage})` ,
        backgroundSize : "contain" ,
        backgroundColor : "#1A354E" ,
        width : "100%" ,
        height : '100%' ,
        padding : "0px 0px 1px" ,
        ObjectFit : "contain" ,
        fontFamily : 'Poppins' ,
        overflow : 'auto'
    } ,
};

const ResetExpiredPassword = () => {

    // Toggle eye
    const [values , setValues] = React.useState({
        newPassword : "" , confirmPassword : "" , showNewPassword : false , showConfirmPassword : false
    });

    // check password Setters and Getters
    const [passwordInput , setPasswordInput] = useState("");
    const [poorPassword , setPoorPassword] = useState(false);
    const [weakPassword , setWeakPassword] = useState(false);
    const [strongPassword , setStrongPassword] = useState(false);
    const [passwordError , setPasswordErr] = useState("");

    // Checking password matching
    const [passwordMatch , setPasswordMatch] = useState(false);
    const [passwordNoMatch , setPasswordNoMatch] = useState(false);
    const [passwordCheckError , setPasswordCheckErr] = useState("");

    const handlePasswordChange = (prop) => (event) => {
        // setPasswordInput(event.target.value);
        setValues({...values , [prop] : event.target.value});
    }

    const handleConfirmChange = (prop) => (event) => {
        setValues({...values , [prop] : event.target.value});
    };

    // password strength checker validator fucntion
    const passwordStrength = (event) => {
        const passwordValue = event.target.value;
        const passwordLength = passwordValue.length;
        const poorRegExp = /[a-z]/;
        const weakRegExp = /(?=.*?[0-9])/;
        ;const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
        const whitespaceRegExp = /^$|\s+/;
        const poorPassword = poorRegExp.test(passwordValue);
        const weakPassword = weakRegExp.test(passwordValue);
        const strongPassword = strongRegExp.test(passwordValue);
        const whiteSpace = whitespaceRegExp.test(passwordValue);

        if (passwordValue === '') {
            setPasswordErr("Password is Empty");
        } else {

            // to check whitespace
            if (whiteSpace) {
                setPasswordErr("Whitespaces are not allowed");
            }
            // to check poor password
            if (passwordLength <= 3 && (poorPassword || weakPassword || strongPassword)) {
                setPoorPassword(true);
                setPasswordErr("Password is Poor");
            }
            // to check weak password
            if (passwordLength >= 4 && poorPassword && (weakPassword || strongPassword)) {
                setWeakPassword(true);
                setPasswordErr("Password is Weak");
            } else {
                setWeakPassword(false);
            }
            // to check strong Password
            if (passwordLength >= 6 && (poorPassword && weakPassword) && strongPassword) {
                setStrongPassword(true);
                setPasswordErr("Password is Strong");
            } else {
                setStrongPassword(false);
            }
        }
    }

    const checkPassMatch = (event) => {
        const NpasswordValue = values.newPassword
        const CpasswordValue = event.target.value


        if (CpasswordValue === NpasswordValue) {
            setPasswordCheckErr("Passwords Match");
            setPasswordMatch(true);
            setPasswordNoMatch(false)
        } else {

            // to check whitespace
            if (CpasswordValue !== NpasswordValue) {
                setPasswordCheckErr("Password Doesn't Match");
                setPasswordNoMatch(true)
                setPasswordMatch(false);
            } else {
                setPasswordNoMatch(true)
            }
        }
    }


    const handleChange = (prop) => (event) => {
        setValues({...values , [prop] : event.target.value});
    };

    const handleClickNewShowPassword = () => {
        setValues({
            ...values , showNewPin : !values.showNewPassword ,
        });
    };
    const handleClickConfirmShowPassword = () => {
        setValues({
            ...values , showConfirmPassword : !values.showConfirmPassword ,
        });
    };

    const handleMouseNewDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseConfirmDownPassword = (event) => {
        event.preventDefault();
    };

    // Submit Reset Request
    async function submitHandler(event) {
        event.preventDefault();
        if (values.newPassword !== values.confirmPassword) {
            alert("Passwords do not match");
            setValues({
                newPin : '' , confirmPin : ''
            })
            return;
        } else {
            if (values.newPassword.length >= 6) {
                //Password Not Strong Enough
                    const response = await fetch(baseUrl + '/api/resetExpPassword' , {
                        method : "PUT" , headers : {
                            "Content-Type" : "application/json" ,
                            "X-Authorization" : localStorage.getItem("X-Authorization") ,
                        } , body : JSON.stringify({
                            userEmail : localStorage.getItem("email") ,
                            userPassword : values.newPassword,
                            resetToken:localStorage.getItem('X-Authorization')
                        }) ,
                    });
                    const data = await response.json();
                    if (response.status === 202 && data.Status === "SUCCESS") {
                        return (window.location.href = '/resetSuccess');
                    }
            } else {
                alert("Password Not Strong Enough - Must be atleast 6 characters")
            }
        }
    }

    return (<Paper sx={styles.paperContainer}>
        <Stack alignItems={"center"}>
            <Card
                sx={{
                    width : "600px" ,
                    height : "700px" ,
                    marginTop : "150px" ,
                    marginLeft : "420px" ,
                    marginRight : "420px" ,
                    marginBottom : "150px" ,
                    backgroundColor : "#fff" ,
                    padding : "63px 49px 85px 50px" ,
                    borderRadius : "19px" ,
                    boxShadow : "0px 3px 6px 0 rgba(0, 0, 0, 0.16)" ,
                }}
            >
                <CardContent align={"center"}>
                    <div
                        style={{
                            marginTop : "0 158px 39px 196px" ,
                            width : "147px" ,
                            height : "95px" ,
                            padding : "0px 0 0" ,
                        }}
                    >
                        <img
                            src={loginLogo}
                            alt="logo"
                            style={{
                                width : "147px" , height : "67px" , margin : "0 0 17px" , objectFit : "contain" ,
                            }}
                        />
                        <label
                            style={{
                                fontSize : "8px" ,
                                height : "11px" ,
                                fontFamily : "Poppins" ,
                                fontWeight : "normal" ,
                                fontStyle : "normal" ,
                                fontStretch : "normal" ,
                                lineHeight : "3.38" ,
                                letterSpacing : "normal" ,
                                textAlign : "left" ,
                                color : "#032541" ,
                            }}
                        >
                            version 1.0.1
                        </label>
                    </div>
                </CardContent>
                <CardContent>
                    <div>
                        <Box>
                                <span style={{
                                    height : "21px" ,
                                    margin : "0 74px 23px 0" ,
                                    fontFamily : "Poppins" ,
                                    fontSize : "15px" ,
                                    fontWeight : "550" ,
                                    fontStretch : "normal" ,
                                    fontStyle : "normal" ,
                                    lineHeight : "3.2" ,
                                    letterSpacing : "normal" ,
                                    textAlign : "center" ,
                                    color : "#032541"
                                }}>
                                    Forgot Password?
                                </span>
                        </Box>
                        <form onSubmit={submitHandler}>
                            <Box sx={{margin : '15px 0px 0px'}}>
                                    <span style={{
                                        width : "109px" ,
                                        height : "21px" ,
                                        margin : "22px 73px 15px 0" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "15px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "2" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#032541"
                                    }} htmlFor="standard-adornment-password">
                                        Enter New Password
                                    </span>
                                <Input style={{
                                    width : "486px" ,
                                    height : "45px" ,
                                    margin : "15px 0 20px" ,
                                    padding : "12px 18px 12px" ,
                                    objectFit : "contain" ,
                                    borderRadius : "12px" ,
                                    border : "solid 1px #e4e4e4"
                                }}
                                       id="standard-adornment-password"
                                       required
                                       type={values.showNewPassword ? 'text' : 'password'}
                                       value={values.newPassword}
                                       onChange={handlePasswordChange('newPassword')}
                                       disableUnderline
                                       onInput={passwordStrength}
                                       endAdornment={<InputAdornment position="end">
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={handleClickNewShowPassword}
                                               onMouseDown={handleMouseNewDownPassword}
                                           >
                                               {values.showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                           </IconButton>
                                       </InputAdornment>}
                                />
                                <>
                                    <ul className="list-group list-group-horizontal">

                                        {poorPassword === true ? <li className="list-group-item bg-danger col-4"
                                                                     style={{padding : "1px 0px"}}></li> : ''}
                                        {weakPassword === true ? <li className="list-group-item bg-warning col-4"
                                                                     style={{padding : "1px 0px"}}></li> : ''}
                                        {strongPassword === true ? <li className="list-group-item bg-success col-4"
                                                                       style={{padding : "1px 0px"}}></li> : ''}

                                    </ul>
                                    <p> {passwordError}</p>
                                </>
                                <span>
                                        <br/>
                                    </span>
                                <span style={{
                                    height : "21px" ,
                                    margin : "22px 73px 15px 0" ,
                                    fontFamily : "Poppins" ,
                                    fontSize : "15px" ,
                                    fontWeight : "normal" ,
                                    fontStretch : "normal" ,
                                    fontStyle : "normal" ,
                                    lineHeight : "2" ,
                                    letterSpacing : "normal" ,
                                    textAlign : "left" ,
                                    color : "#032541"
                                }}>
                                        Confirm New Password
                                    </span>
                                <Input sx={{
                                    width : "486px" ,
                                    height : "45px" ,
                                    margin : "15px 0 20px" ,
                                    padding : "12px 18px 12px" ,
                                    objectFit : "contain" ,
                                    borderRadius : "12px" ,
                                    border : "solid 1px #e4e4e4"
                                }}
                                       id="standard-adornment-password"
                                       required
                                       type={values.showConfirmPassword ? 'text' : 'password'}
                                       value={values.confirmPassword}
                                       disableUnderline
                                       onChange={handleConfirmChange('confirmPassword')}
                                       onInput={checkPassMatch}
                                       endAdornment={<InputAdornment position="end">
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={handleClickConfirmShowPassword}
                                               onMouseDown={handleMouseConfirmDownPassword}
                                           >
                                               {values.showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                           </IconButton>
                                       </InputAdornment>}/>
                                <>
                                    <ul className="list-group list-group-horizontal">
                                        {passwordNoMatch === true ? <li className="list-group-item bg-danger"
                                                                        style={{
                                                                            width : "486px" ,
                                                                            padding : "1px 0px"
                                                                        }}></li> : ''}
                                        {passwordMatch === true ? <li className="list-group-item bg-success"
                                                                      style={{
                                                                          width : "486px" ,
                                                                          padding : "1px 0px"
                                                                      }}></li> : ''}
                                    </ul>
                                    <p> {passwordCheckError}</p>
                                </>
                            </Box>
                            <Stack align={"center"}>
                                <cardContent align={"center"}>
                                    <Button type="submit" style={{
                                        alignSelf : "center" ,
                                        width : "122px" ,
                                        height : "42.6px" ,
                                        padding : "9px 39.5px 8.6px 40.5px" ,
                                        backgroundColor : "#032541" ,
                                        borderRadius : "12px" ,
                                        margin : "9px 39.5px 8.6px 40.5px" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "15px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "normal" ,
                                        letterSpacing : "0.21px" ,
                                        textAlign : "center" ,
                                        color : "#ffffff" ,
                                        cursor : "pointer" ,
                                    }}>RESET</Button>
                                </cardContent>
                            </Stack>
                        </form>
                    </div>
                </CardContent>
            </Card>
        </Stack>
    </Paper>);
};
export default ResetExpiredPassword;
