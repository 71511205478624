import { Box, Breadcrumbs, Button, Grid, Modal, Tab, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { styled } from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import WarningImg from "../../../images/warning.png"
import SuccessPng from "../../../images/success.png"
import CheckIcon from '@mui/icons-material/Check';
import CustomSearchInput from "../CustomSearchInput"
import ExportMenu from "../ExportMenu"
import { DataGrid } from "@mui/x-data-grid"
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay"
import UploadBankStatement from "./uploadBankStatement"
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import HttpComponent from "../MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import PreviewBankStatements from "./previewBankStatement";

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "18px", marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    display:'flex',
    alignContent:'center',
    height:300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Bank Statements</Typography>
]

export default function MainBankStatementsSetup() {

    const navigate = useNavigate();
    //handle navigation
    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[0]

    //tabvalues

    const [tabValue, setTabValue] = useState('APPROVED')

    //handle tabvalue change
    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)

    //states
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [openApprove, setOpenApprove] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)

    //handle modals

    const handleOpenApprove = () => setOpenApprove(true)
    const handleCloseApprove = () => setOpenApprove(false)

    const handleOpenCancel = () => setOpenCancel(true)
    const handleCloseCancel = () => setOpenCancel(false)


    function getBankStatements() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/get_business_bankStatements?status=${tabValue}&searchValue=${searchValue}&page=${pageState.page}&limit=${dataGridPageSize}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data , 'pagestatttttttttttt')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getBankStatements()
    }, [searchValue, state, dataGridPageSize, tabValue])

    const [statementId , setStatementId]=useState(null)

    //handleApproveBs

    const handleApproveBs = ()=>{
        try {
            HttpComponent({
                method:'POST',
                url:`/api/v1/verifyBusinessbankstatemnets`,
                body:{
                    action:"APPROVED",
                    statementId:statementId
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setSuccessShow({state:true , message:'Successfully Approved bank statement'})
                    setStatementId(null)
                    setTimeout(()=>{
                        setTabValue('APPROVED')
                        setOpenApprove(false)
                       // window.location.reload()
                    },2000)
                   
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })
        } catch (error) {
            
        }

    }


    const handleCancelBs = ()=>{
        try {
            HttpComponent({
                method:'POST',
                url:`/api/v1/verifyBusinessbankstatemnets`,
                body:{
                    action:"DECLINED",
                    statementId:statementId
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setSuccessShow({state:true , message:'Successfully declined bank statement'})
                    setStatementId(null)
                    setTimeout(()=>{
                        setTabValue('DECLINED')
                        setOpenCancel(false)
                    },2000)
                    
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })
        } catch (error) {
            
        }
    }





    //approvedBanksStatement

    const approvedBanksStatementcolumns = [
        { headerName:"Statement ID" ,field: 'statementId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Statement  ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}><Link to={`/school/bankstatements?previewStatement?${params?.row?._id}`}>{params?.row?.statementNumber}</Link></span></>) } },
        { headerName:"Bank Name" ,field: 'bankName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.bankName}</span></>) } },
        { headerName:"Bank Account" ,field: 'bankAccount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Account</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.bankAccount}</span></>) } },
        { headerName:"Uploaded By" ,field: 'uploadedBy', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Uploaded By</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.uploadedBy}</span></>) } },
        { headerName:"Approved By" ,field: 'approvedBy', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Approved By</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.modifiedBy}</span></>) } },
        { headerName:"Approved On" ,field: 'approvedOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Approved  On</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.modifiedOn}</span></>) } },
        // { field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => { return (<> <CloseIcon /></>) } },
    ]

    // pending cloumns
    const pendingBanksStatementcolums = [
        { headerName: "statementNumber", field: 'statementNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Statement  ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px", cursor: 'pointer', }}><Link to={`/school/bankstatements?previewStatement?${params?.row?._id}`}>{params?.row?.statementNumber}</Link></span></>) } },
        { headerName: "bankName", field: 'bankName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.bankName}</span></>) } },
        { headerName: "bankAccount", field: 'bankAccount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Account</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.bankAccount}</span></>) } },
        { headerName: "uploadedBy", field: 'uploadedBy', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Uploaded By</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.uploadedBy}</span></>) } },
        { headerName: "uploadedOn", field: 'uploadedOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Uploaded On</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{DateFormatter(params?.row?.uploadedOn)}</span></>) } },
        { headerName: "action",
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <CheckIcon onClick={()=>{handleOpenApprove(); setStatementId(params?.row?._id)}} style={{ color: '#fff', borderRadius: '50%', cursor:'pointer', backgroundColor: '#17ae7b' }} />
                        <CloseIcon onClick={()=>{handleOpenCancel(); setStatementId(params?.row?._id)}} style={{ color: '#fff', marginLeft: '10px',cursor:'pointer', borderRadius: '50%', backgroundColor: '#dc3545' }} />
                    </Grid>
                )
            }
        },
    ]

    // declined cloumns
    const declinedBanksStatementcolumns = [
        { headerName: "statementNumber", field: 'statementNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Statement  ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}><Link to={`/school/bankstatements?previewStatement?${params?.row?._id}`}>{params?.row?.statementNumber}</Link></span></>) } },
        { headerName: "bankName", field: 'bankName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.bankName}</span></>) } },
        { headerName: "bankAccount", field: 'bankAccount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Account</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.bankAccount}</span></>) } },
        { headerName: "modifiedBy", field: 'modifiedBy', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Declined By</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.modifiedBy}</span></>) } },
        { headerName: "modifiedOn", field: 'modifiedOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Declined On</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{DateFormatter(params?.row?.modifiedOn)}</span></>) } },
        // { field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => { return (<> <DeleteIcon /></>) } },
    ]


    const fileDataApproved =  pageState?.data?.map((data) => {
        return {
            "Statement ID": data?.statementNumber,
            "Bank Name": data?.bankName,
            "Bank Account": data?.bankAccount,
            "Uploaded By": data?.uploadedBy,
            "Approved By":data?.modifiedBy,
            "Approved On":DateFormatter(data?.modifiedOn)
        }
    })

    const fileDataPending = pageState?.data?.map((data) => {
        return {
            "Statement ID": data?.statementNumber,
            "Bank Name": data?.bankName,
            "Bank Account": data?.bankAccount,
            "Uploaded By": data?.uploadedBy,
            "Uploaded On":DateFormatter(data?.uploadedOn)
        }
    })
    const fileDataDeclined =  pageState?.data?.map((data) => {
        return {
            "Statement ID": data?.statementNumber,
            "Bank Name": data?.bankName,
            "Bank Account": data?.bankAccount,
            "Declined By": data?.modifiedBy,
            "Declined On":DateFormatter(data?.modifiedOn)
        }
    })


    //headers
    const fileHeadersApproved =   [["Statement ID", "Bank Name", "Bank Account", "Uploaded By", "Approved By", 'Approved On']]
    const fileHeadersPending =    [["Statement ID", "Bank Name", "Bank Account", "Uploaded By", "Uploaded On"]]
    const fileHeadersDeclined =   [["Statement ID", "Bank Name", "Bank Account", "Declined By", 'Declined On']] 

    const csvColumnsApproved =  [
        { label: "Statement ID", key: "Statement ID" },
        { label: "Bank Name", key: "Bank Name" },
        { label: "Bank Account", key: "Bank Account" },
        { label: "Uploaded By", key: "Uploaded By", },
        { label: "Approved By", key: "Approved By", },
        {label:"Approved On" , key:"Approved On"},
    ]
    const csvColumnsPending= [
        { label: "Statement ID", key: "Statement ID" },
        { label: "Bank Name", key: "Bank Name" },
        { label: "Bank Account", key: "Bank Account" },
        { label: "Uploaded By", key: "Uploaded By", },
        {label:"Uploaded On" , key:"Uploaded On"},
    ]
    const csvColumnsDeclined =  [
        { label: "Statement ID", key: "Statement ID" },
        { label: "Bank Name", key: "Bank Name" },
        { label: "Bank Account", key: "Bank Account" },
        { label: "Declined By", key: "Declined By", },
        {label:"Declined On" , key:"Declined On"},
    ]

    const fileDataPDFApproved =  pageState?.data?.map((data) => [data?.statementNumber, data?.bankName, data?.bankAccount, data?.uploadedBy, data?.modifiedBy, DateFormatter(data?.modifiedOn)])
    const fileDataPDFPending =  pageState?.data?.map((data) => [data?.statementNumber, data?.bankName, data?.bankAccount, data?.uploadedBy,  DateFormatter(data?.uploadedOn) ])
    const fileDataPdfDeclined =  pageState?.data?.map((data) => [data?.statementNumber, data?.bankName, data?.bankAccount, data?.modifiedBy, DateFormatter(data?.modifiedOn) ])


    
    return (
        <Grid direction={'column'}>
            {state === 'uploadstatement' ? <UploadBankStatement /> : state === 'previewStatement' ? <PreviewBankStatements /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Typography style={{ fontconstWeight: 600, fontSize: "25px", color: "#032541" }}>Bank Statements</Typography>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => navigate('/school/bankstatements?uploadstatement')} style={{ background: "#032541", width: "215px", height: "45px", fontSize: "14px", textTransform: "inherit", color: "#fff" }}>Upload Bank Statement</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                            <AntTab label="Approved" value="APPROVED" />
                            <AntTab label="Pending Approval" value="PENDING" />
                            <AntTab label="Declined" value="DECLINED" />
                            {/* <AntTab label="Deactivated" value="Deactivated" /> */}
                        </AntTabs>
                        <TabPanel value="APPROVED">
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        <ExportMenu fileData={fileDataApproved} fileDataPDF={fileDataPDFApproved} csvColumns={csvColumnsApproved} fileHeaders={fileHeadersApproved} fileName={'Approved'}  title={'Bank Statements'} />
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.total}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState?.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={approvedBanksStatementcolumns}
                                            rows={pageState?.data}
                                            getRowId={row => row?._id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="PENDING">
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        <ExportMenu csvColumns={csvColumnsPending} fileHeaders={fileHeadersPending} fileDataPDF={fileDataPDFPending} fileData={fileDataPending} fileName={'Bank Statements'} title={'Bank Statements'} />
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.total}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState?.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={pendingBanksStatementcolums}
                                            rows={pageState?.data}
                                            getRowId={row => row?._id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="DECLINED">
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        <ExportMenu csvColumns={csvColumnsDeclined} fileHeaders={fileHeadersDeclined} fileDataPDF={fileDataPdfDeclined} fileData={fileDataDeclined} fileName={'Bank Statements'} title={'Bank Statements'} />
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.total}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState?.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={declinedBanksStatementcolumns}
                                            rows={pageState?.data}
                                            getRowId={row => row?._id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        {/* <TabPanel value="Deactivated">
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        <ExportMenu />
                                    </Grid>
                                    <Grid item>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.total}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState?.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={deactivatedBanksStatementcolumns}
                                            rows={pageState?.data}
                                            getRowId={row => row?._id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel> */}
                    </TabContext>
                </Grid>
            </>}
            <Modal
                open={openApprove}
                onClose={handleCloseApprove}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                        <Grid item>
                            <img src={SuccessPng} alt="Approve" />
                        </Grid>
                        <Grid item display={'flex'} height={'auto'} direction={'column'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Approve Bank Statement
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Are you sure you want to approve this Bank Statement
                            </Typography>
                            <Box mt={3}>
                                <Button  style={{width:'125px' ,height:'45px', color:'#dc3545', border: '1px solid #dc3545'}} onClick={()=>{setStatementId(null);handleCloseApprove()}}>Cancel</Button>
                                <Button style={{width:'125px', marginLeft:'10px' ,height:'45px', color:'#fff', backgroundColor:'#17ae7b'}} onClick={handleApproveBs}>Approve</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openCancel}
                onClose={handleCloseCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                        <Grid item>
                            <img src={WarningImg} alt="Approve" />
                        </Grid>
                        <Grid item display={'flex'} height={'auto'} direction={'column'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Decline Bank Statement
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Are you sure you want to decline this Bank Statement
                            </Typography>
                            <Box mt={3}>
                                <Button  style={{width:'125px' ,height:'45px', color:'#dc3545', border: '1px solid #dc3545'}} onClick={()=>{setStatementId(null);handleCloseCancel()}}>Cancel</Button>
                                <Button style={{width:'125px', marginLeft:'10px' ,height:'45px', color:'#fff', backgroundColor:'#17ae7b'}} onClick={handleCancelBs}>Decline</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )

}