import React, { useRef, useState } from 'react';
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import icon from "./Images/uploadFileIcon.svg"
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { useNavigate } from 'react-router-dom';
import SendIcon from "@mui/icons-material/Send";
import * as XLSX from "xlsx";


const base_url = process.env.REACT_APP_BASE_URL

const CustomDropZone = (props) => {
    // /school/invoices/batch
    // /school/parents
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    const successAction = props.successAction
    console.log('successAction', successAction);
    // const url = props.url;
    // const setErrorShow = props.setErrorShow;
    // const setSuccessToast = props.setSuccessToast;
    // console.log("url", url, 'setErrorShow', setErrorShow, 'setSuccessToast', setSuccessToast);
    // const [successToast, setSuccessToast] = useState({ state: false, message: "" });
    // const [errorToast, setErrorToast] = useState({ state: false, message: "" });

    // const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const navigate = useNavigate()
    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };

    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleErrorClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen(false); };

    const [Loading, setLoading] = useState(false);

    let { X_Authorization } = useSelector((store) => store.user);
    if (!X_Authorization) X_Authorization = localStorage.getItem("X-Authorization")
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState("")
    const [uploadClicked, setUploadClicked] = useState(false);
    const areaStyles = {
        borderRadius: "6px",
        border: "solid 1px #e1e1e1",
        backgroundColor: "rgba(3, 37, 65, 0.02)",
        minHeight: "20rem",
        width: "37.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }

    const buttonStyling = {
        "width": "10.625rem",
        "height": "2.813rem",
        "borderRadius": "5px",
        "backgroundColor": "#032541",
        color: "white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleFileUpload = (e) => {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        const selectedFile = e.dataTransfer ? e.dataTransfer.files[0].name : e.target.files[0].name;
        setFile(file);
        setSelectedFile(selectedFile);
    };

    const handleClearFile = () => {
        setFile(null);
        setSelectedFile(null);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };


    const columnCentered = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append("file", file);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${base_url}${props.url}`);
        xhr.setRequestHeader("X-Authorization", X_Authorization);

        // track upload progress
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = event.loaded / event.total * 100;
                setUploadProgress(percentComplete);
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                console.log(xhr.status, "status");
                if (xhr.status === 201) {
                    const response = JSON.parse(xhr.response);
                        handleSuccessNotificationClick();
                        setLoading(false)
                        if(successAction === "/school/invoices/batch"){
                            navigate("/school/invoices/batch")
                        }else{
                      setTimeout(() => {
                        if(props.url === "/api/upload_parents_students"){
                            successAction()
                        }else{
                            navigate("/school/parents")
                        }
                        
                      }, [2000])
                    }
                   
                } else {
                    console.error("add error");
                    const response = JSON.parse(xhr.response);
                    console.log(response, "response");
                    let dataDuplicates = response.duplicates;
                    let dataErrors = response.data;
                    if (dataDuplicates?.length > 0 ) {
                        // prepareCSV(dataDuplicates);
                        let data 
                        if (schoolTypeName?.includes("University")) {
                            data = dataDuplicates.map((item) => {
                                return {
                                    Admission_Number: item.admissionNo,
                                    FirstName: item.firstName,
                                    LastName: item.lastName,
                                    PhoneNumber: item.phone,
                                    Email: item.email,
                                    Course: item.courseName,
                                    Semester: item.term,
                                    Year: item.year,
                                    Address: item.customerAddress,
                                };
                            });
                        }else{
                             data = dataDuplicates.map((item) => {
                                return {
                                    Admission_Number: item.billableItems[0].itemNumber,
                                    FirstName: item.billableItems[0].firstName,
                                    LastName: item.billableItems[0].lastName,
                                    Grade: item.billableItems[0].grade,
                                    Term: item.billableItems[0].term,
                                    Stream: item.billableItems[0].stream,
                                    Boarding_Status: item.billableItems[0].boardingStatus,
                                    Parent_First_Name: item.lastName,
                                    Parent_Last_Name: item.lastName,
                                    PhoneNumber: item.phone,
                                    Email: item.email,
                                    Address: item.customerAddress,
                                };
                            });
                        }

                        
                        xlsxReport(data);
                    }
                    if (dataErrors?.length > 0 ) {
                        // prepareCSV(dataDuplicates);
                        let data 
                        if (schoolTypeName?.includes("University")) {
                            data = dataErrors.map((item) => {
                                const [
                                    admissionNo,
                                    firstName,
                                    lastName,
                                    phone,
                                    email,
                                    courseName,
                                    term,
                                    year,
                                    customerAddress
                                ] = item.split(',');
                            
                                return {
                                    Admission_Number: admissionNo,
                                    FirstName: firstName,
                                    LastName: lastName,
                                    PhoneNumber: phone,
                                    Email: email,
                                    Course: courseName,
                                    Semester: term,
                                    Year: year,
                                    Address: customerAddress
                                };
                            });
                        }else{
                             data = dataErrors.map((item) => {
                                const [
                                    itemNumber,
                                    firstName,
                                    lastName,
                                    grade,
                                    term,
                                    stream,
                                    boardingStatus,
                                    parentFirstName,
                                    parentLastName,
                                    phone,
                                    email,
                                    customerAddress
                                ] = item.split(',');
                            
                                return {
                                    Admission_Number: itemNumber,
                                    FirstName: firstName,
                                    LastName: lastName,
                                    Grade: grade,
                                    Term: term,
                                    Stream: stream,
                                    Boarding_Status: boardingStatus,
                                    Parent_First_Name: parentFirstName,
                                    Parent_Last_Name: parentLastName,
                                    PhoneNumber: phone,
                                    Email: email,
                                    Address: customerAddress
                                };
                            });
                        }

                        
                        xlsxReport(data);
                    }
                      
                    handleErrorNotificationClick();
                    setLoading(false)
                    setErrorMessage(response.message)

                   
                }
            }
        };
        xhr.send(formData);
    };
    // Admission_Number,FirstName,LastName,PhoneNumber,Email,Course,Semester,Year,Address

    

    const xlsxReport = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    
        /* generate XLSX file and send to client */
        XLSX.writeFile(
            wb,
            (schoolTypeName?.includes("University") ?
                `Duplicate Records Campus-Student-Template.csv` :
                `Duplicate Records Parent-Student-Template.csv`)
        );
    }

   
    


    

    return (
        <>
        {Loading  ? <><LoadingButton
                                sx={{
                                  width: "407px",
                                  backgroundColor: "#34A353",
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "#34A353",
                                    color: "white",
                                  },
                                }}
                                endIcon={<SendIcon />}
                                loading={true}
                                loadingPosition="end"
                                variant="contained"
                              >
                                <span>Awaiting </span>
                              </LoadingButton>
                </> :
            <>
            <Box sx={areaStyles}>
                <Box sx={{
                    display: "flex",
                    alignSelf: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "35.375rem",
                    height: "18.269rem",
                    padding: "20px 160.5px 46.3px 159.5px",
                    border: "dashed 2px #c2cdda"
                }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                        <img src={icon} alt="Upload" />
                    </Box>
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                        <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                            Drag and drop your CSV file here
                        </Typography>
                    </Box>
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                        <Typography sx={{ fontSize: "0.875rem", color: "#666666" }}>
                            or
                        </Typography>
                    </Box>
                    <input
                        type="file"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                    />
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                        <Button sx={buttonStyling} onClick={() => fileInputRef.current.click()}>
                            Browse Files
                        </Button>
                    </Box>
                </Box>
            </Box>
            {
                selectedFile ?
                    <Box component="div" id="file-identifier"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            border: "solid 1px #e1e1e1",
                            borderRadius: "0.25rem"
                        }}>
                        <Box component="div" sx={{ display: "flex", marginX: 1, padding: 1 }}>
                            <Box component="div" sx={{ ...columnCentered }}>
                                <CloseOutlinedIcon onClick={handleClearFile} sx={{ color: "#dc3545", cursor: "pointer" }} />
                            </Box>
                            <Box component="div" sx={{ ...columnCentered }}>
                                <InsertDriveFileOutlinedIcon sx={{ color: "#032541" }} />
                            </Box>
                            <Box component="div" sx={{ ...columnCentered, marginLeft: 1 }}>
                                <Typography sx={{ color: "#666666", fontSize: "0.875rem" }}>
                                    {selectedFile}
                                </Typography>
                            </Box>
                            <Box component="div" sx={{ marginLeft: 3.5, ...columnCentered }}>
                                <progress value={uploadProgress} max="100" />
                            </Box>
                            <Box component="div" sx={{ ...columnCentered, marginLeft: 'auto' }}>
                                <Button onClick={handleSubmit} sx={{ color: "#032541", fontSize: "0.875rem", fontWeight: 700 }}>
                                    Upload
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    :
                    null
            }
            <div>
                <div>
                    <ErrorAlert message={errorMessage} open={errorNotificationOpen} onClose={handleErrorClose} horizontal={'right'} vertical={'top'} />
                    <SuccessAlert message={'Uploaded successfully'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
                </div>
            </div>
            </>
}

        </>
    )
}

export default CustomDropZone;
