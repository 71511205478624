import React, { useState ,useEffect} from 'react';
import { Button, Container, TextField, Grid, Typography ,Box, Breadcrumbs} from '@mui/material';
import {useNavigate, useParams } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GeoLocation from '../../../hooks/useLocation';
import CustomInputLabel from "../../School/CustomInputLabel";
let baseUrl = process.env.REACT_APP_BASE_URL;



const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
      Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
      Students
  </Typography>,
    <Typography key={"student"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Student Profile
 </Typography>,
    <Typography key={"contacts"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Add Emergency Contacts
</Typography>
];

const CreateContacts = () => {
  const { customerId } = useParams();
  const [selectedValue, setSelectedValue]=useState('');
  const [address,setAddress]=useState('')
  const navigate = useNavigate()


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    relationship: '',
    customerId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setAddress(selectedValue);
    console.log("emergency address",address)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const requestData = {
        ...formData,
        address: selectedValue,
      };
  
      const response = await fetch(`${baseUrl}/api/add_emergency_contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Authorization': localStorage.getItem('X-Authorization'),
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.status === 200) {
        alert('Emergency contact added successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          relationship: '',
        });
        setSelectedValue(''); // Clear the selectedValue as well
      } else {
        alert('Error adding emergency contact');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while adding the emergency contact');
    }
  };

  return (
    <>
      <Box component="div" sx={{}}>
        <Breadcrumbs
            separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
            aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs>

        <br/>
       
    </Box>
      <Typography variant="h4" align="left" gutterBottom>
        Add Emergency Contact
      </Typography>
      <br/>
      <form  onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Relationship"
              variant="outlined"
              fullWidth
              name="relationship"
              value={formData.relationship}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={5}>
          <CustomInputLabel  />
							<GeoLocation  required={true} sx={{marginBottom:"10px" , marginTop:"10px"}}selectedValue={selectedValue} onValueChange={(address)=>setSelectedValue(address)}/>
          </Grid>
        </Grid>
        <Button 
          onClick={navigate(-1)}
          style={{
            "width":"7.813rem",
            "height":"2.813rem",
            "textTransform" : "none",
            "borderRadius":"4px",
            "marginRight" : '20px',
            "border":"solid 1px #dc3545",
            "color":"#dc3545"
          }}
          type="submit" >
          Cancel
        </Button>
        <Button 
         
         style={{
          "width": "7.813rem",
          "height": "2.813rem",
          "padding": "12.5px 43.7px 12.5px 45.3px",
          "borderRadius": "4px",
          "backgroundColor": "#032541",
          "color": "white",
          "marginLeft":"5px"
        }}
        variant="contained"  type="submit" >
          Next
        </Button>
      </form>
    </>
  );
}

export default CreateContacts;
