import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "eatery",
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    changeCategory: (state, action) => {
      state.category = action.payload;
      console.log("state cat", state.category);
    }
  },
});

export const { changeCategory,  clearCategory } = categorySlice.actions;
export default categorySlice.reducer;
