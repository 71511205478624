import {
    Grid,
    Stack,
    Typography,
    TextField,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Alert,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
  import { useDispatch, useSelector } from "react-redux";
  import { removeClicked } from "../../../../features/actionSlice";
  import { useNavigate } from "react-router-dom";
  import { setDetails } from "../../../../features/userToUpdate";
  import { handleEdited, handleMoved } from "../../../../features/AlertSlice";
  import axios from "axios";
  import { makeStyles } from "@mui/styles";
import { handleData2 } from "../../../../features/partnerSelectSlice";
import TermUsersSelect from "./TermUsersSelect";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  
  const useStyles = makeStyles((theme) => ({
    focusedTextField: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#032541", // Change this to the desired color
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "#032541",
      },
    },
  }));
  
  function MoveTermUser() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const classes = useStyles();
  
    //pulling values from redux to set initial values of form inputs
    const reduxEmail = useSelector((state) => state.userDetails.email);
    const userName = useSelector((state) => state.userDetails.userName);
    const businessName = useSelector((state) => state.userDetails.businessName);
    const businessID = useSelector((state)=> state.userDetails.businessNumber)
    const userId = useSelector((state)=> state.userDetails.userId)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    let businessNo = useSelector(state => state.partnerSelect.businessNo) //to be passed to backend as new business to place user in
    const [previousStore, setPreviousStore] = useState()
    const [branchID, setBranchId] = useState()
    const [outletsAdmin, setOutletsAdmin] = useState([])



    function getOutletsByAdminPartner() {
      try {
          HttpComponent({
              method: "GET",
              url: `/api/listBusinessOutlets?storeType=MainStore&businessNumber=${businessID}`,
              body: null,
              token: localStorage.getItem('X-Authorization')
          }).then((data) => {
              if (data?.status === 200) {
                  setOutletsAdmin(data?.response?.data)
              }
          })
      } catch (error) {
          console.log(error)
      }
  }
  
  useEffect(() => {
      getOutletsByAdminPartner()
  }, [businessID])


    function handlePreviousStore() {
      try {
          HttpComponent({
              method: "GET",
              url: `/api/v1/getOutletUserByUserId?userId=${userId}&businessNumber=${businessID}`,
              body: null,
              token: localStorage.getItem('X-Authorization')
          }).then((data) => {
              console.log(data, 'previiiii')
              if (data.status === 200) {
                  //branchId //outletName //storeId
                  setBranchId(data?.response?.data?.branchId)
                  setPreviousStore(data?.response?.data?.storeId)
                  // setOutletName(data?.response?.data?.outletName)
              }

          })
      } catch (error) {
          console.log(error)
      }
  }

  useEffect(() => {
      handlePreviousStore()
  }, [userId ,businessID])

  const [partnerOutlet, setPartnerOutlet] = useState('')

  const handdleOutletSelected = (e) => {
    const valueFound = e.target.value;
    const selectedValue = outletsAdmin?.find(item => item?._id === valueFound);
    if (selectedValue) {
        const { branchId, _id, storeName } = selectedValue
        setPartnerOutlet(_id)
       // setadminBranchId(branchId)
       // setoutlateNameAdmin(storeName)

    }
    
}

const formSubmit = {
  storeId: previousStore,
  assignedStoreId: partnerOutlet,
  branchId: branchID
}

function handleSubmit() {
  try {
      HttpComponent({
          method: "POST",
          url: `/api/v1/assignUserToOutlet?userId=${userId}&businessNumber=${businessID}`,
          body: formSubmit,
          token: localStorage.getItem('X-Authorization')
      }).then((data) => {
          if (data?.status === 200) {
              setSuccessShow({ state: true, message: data?.response?.message })
              dispatch(handleMoved(true));
              navigate("/TermUsers");
          } else {
              setErrorShow({ state: true, message:  data?.response?.message})
          }
      })
  } catch (error) {
      setErrorShow({ state: true, message: error })
  }
}

    
    const handleUpdate = () => {
      try {
        axios
          .post(
            baseUrl + "/api/moveUserToNewBusiness",
            {
              email: reduxEmail,
              businessName,
              businessNo
            },
            {
              headers: {
                "X-Authorization": localStorage.getItem("X-Authorization"),
              },
            }
          )
          .then((response) => {
            if (response) {
              dispatch(handleMoved(true));
              navigate("/TermUsers");
            } else {
              setFailedMessage(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    };
  
    // the clicked variable allows us to navigate to current page...removing it allows us to go back
    dispatch(removeClicked());
  
    const [failMessage, setFailedMessage] = useState(false);
    const [showFailedAlert, setShowFailedAlert] = useState(false);
  
    const handleShowFailedAlert = () => {
      if (failMessage) {
        setShowFailedAlert(true);
  
        // Automatically hide the alert after 3 seconds
        setTimeout(() => {
          setShowFailedAlert(false);
          setFailedMessage(null);
          // dispatch(handleAssigned(false));
        }, 5000);
      }
    };
  
    useEffect(() => {
      handleShowFailedAlert();
    }, [failMessage]);

    
  
    return (
      <Stack bgcolor={"white"} p={2} spacing={2} direction={'column'} height={"100%"}>
         <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Transfer User To Another Outlet 
          </Typography>
          {showFailedAlert && (
            <Alert variant="filled" severity="error">
              {failMessage}
            </Alert>
          )}
        </Stack>
        <Breadcrumbs2 point1={"Users"} link1={"/Users"} point2={"Move User"} />
        <Grid container>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
Transfer User To Another Outlet              </Typography>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                size="small"
                defaultValue={userName}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handlePhoneNo}
                disabled
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                size="small"
                defaultValue={reduxEmail}
                InputLabelProps={{
                  shrink: true,
                }}
                // onChange={handleEmail}
                disabled
              />
  
              <TextField
                label="Current Business"
                variant="outlined"
                fullWidth
                size="small"
                defaultValue={businessName}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              {/* <TermUsersSelect /> */}
              <Grid item mt={2} style={{ width: "100%" }}>
              <FormControl size="medium" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={partnerOutlet}
                  label="Outlet"
                  onChange={handdleOutletSelected}
                  name="outletName"
                >
                  {outletsAdmin?.map((item) => (
                    <MenuItem name="outlet" key={item._id} value={item._id}>{item?.storeName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Stack
                direction={"row"}
                width={"100%"}
                spacing={2}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    borderRadius: 4,
                    // backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#dc3545",
                    // color: "#dc3545"
                  }}
                  onClick={() => navigate("/TermUsers")}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 4,
                    backgroundColor: "#032541",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#032541",
                  }}
                  onClick={handleSubmit}
                >
                  Move
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    );
  }
  
  export default MoveTermUser;
  