import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { creditnotestyles } from "../creditNoteStyling/styless";
import HttpComponent from "../../School/MakeRequest";
import { QrcodeComponent } from "./qrgenerator";
import { useParams } from "react-router-dom";
import { CustomTable } from "../customHook/table";
import loadash from "lodash";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import printJS from "print-js";

const Previewcreditnote = () => {
  const [customerDetails, setCustomerDetails] = useState({ approvedByName: "", createdByName: "", dateCreated: "", approvedByName: "", dateApproved: "", creditNoteNumber: "", customerName: "", expiryDate: "" });
  const [creditNotePreviewItems, setCreditNotePreview] = useState([]);
  const totals = loadash.sumBy(creditNotePreviewItems, (creditNotePreviewItems) => {
    return creditNotePreviewItems.totalAmount;
  });

  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

  const { id } = useParams();
  const getCreditNoteDetails = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_credit_note_info?creditNoteId=${id}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          const { approvedByName, createdByName, creditNoteAmount, customerName, dateCreated, dateApproved, creditNoteNumber, expiryDate } = data.response.data;

          setCustomerDetails({
            ...customerDetails,
            approvedByName: approvedByName,
            createdByName: createdByName,
            creditNoteAmount,
            customerName: customerName,
            dateCreated: new Date(dateCreated).toLocaleDateString() + " " + new Date(dateCreated).toLocaleTimeString(),
            dateApproved: new Date(dateApproved).toLocaleDateString() + " " + new Date(dateApproved).toLocaleTimeString(),
            expiryDate: new Date(expiryDate).toLocaleDateString() + " " + new Date(expiryDate).toLocaleTimeString(),
            creditNoteNumber: creditNoteNumber,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCreditNoteDetails();
  }, [id]);

  const getItemsArray = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_credit_note_products?limit=10&page=1&creditNoteId=${id}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setCreditNotePreview(data.response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getItemsArray();
  }, [id]);

  // download pdf;

  const downloadCreditNote = () => {
    // const input = document.getElementById('previewcreditnote');
    // html2canvas(input)
    // .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png', 1.0);
    //     const inputWidthPx = input.clientWidth;
    //     const inputHeightPx = input.clientHeight;
    //     const dpi = window.devicePixelRatio * 96;
    //     let scaleFactor = 1;
    //     if( window.innerWidth > 1512){
    //         scaleFactor = 1.4;
    //     }
    //     const inputWidthMm = (inputWidthPx / dpi) * 25.4 / scaleFactor;
    //     const inputHeightMm = (inputHeightPx / dpi) * 25.4;
    //     const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
    //     pdf.addImage(imgData, 'JPEG', 5, 5, inputWidthMm - 10, inputHeightMm - 10);
    //     pdf.save(`credit note ${customerDetails.creditNoteNumber}`);
    // })

    var win = window.open("", "print", 'height=720,width="100%"');

    win.document.write(document.getElementById("previewcreditnote").innerHTML);

    win.document.close();
    win.focus();
    win.print();
    
//     var link = document.createElement('a');
// link.href = url;
// link.download = 'file.pdf';
// link.dispatchEvent(new MouseEvent('click'));

    // printJS("previewcreditnote", "html");
  };
  return (
    <>
      <div style={creditnotestyles.creditNotePreview}>
        <Grid container direction={"row"} style={{ padding: "0px 20px 0px 20px", margin: "0px 0px 10px 0px" }} justifyContent={"space-between"}>
          <Grid item>
            <span style={creditnotestyles.previewCreditNote}>Preview Credit Note</span>
          </Grid>

          <Grid item>
            <span>
              <FileDownload style={{ cursor: "pointer" }} onClick={() => downloadCreditNote()} />
            </span>
          </Grid>
        </Grid>

        <div id="previewcreditnote">
          <Grid container direction={"column"} style={{ padding: "0px 20px 0px 20px" }}>
            <Grid item style={{ backgroundColor: "rgba(220, 53, 69, .1)", padding: "40px" }}>
              <Grid item>
                <span style={creditnotestyles.creditNoteBlack}>CREDIT NOTE</span>
              </Grid>

              <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <p style={creditnotestyles.paraBold}>Date created:</p>
                  <p style={creditnotestyles.para}>{customerDetails.dateCreated}</p>
                </Box>

                <Box>
                  <p style={creditnotestyles.paraBold}>Credit Note No:</p>
                  <p style={creditnotestyles.para}>{customerDetails.creditNoteNumber}</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction={"column"} style={{ padding: "0px 20px 0px 20px" }}>
            <Grid item style={{ padding: "40px", borderBottom: "2px solid #bfbfbf", borderBottomStyle: "dotted" }}>
              <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <p style={creditnotestyles.paraBold}>From:</p>
                </Box>

                <Box>
                  <p style={creditnotestyles.paraBold}>{localStorage.getItem("businessName")}</p>
                </Box>
              </Grid>

              <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <p style={creditnotestyles.paraBold}>Issued to:</p>
                </Box>

                <Box>
                  <p style={creditnotestyles.paraBold}>{customerDetails.customerName}</p>
                </Box>
              </Grid>

              <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <p style={creditnotestyles.paraBold}>Approved By:</p>
                </Box>

                <Box>
                  <p style={creditnotestyles.paraBold}>{customerDetails.approvedByName}</p>
                </Box>
              </Grid>
              {/* 
            <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <p style={creditnotestyles.paraBold}>info@gmail.com</p>
              </Box>

              <Box>
                <p style={creditnotestyles.paraBold}>+254708512</p>
              </Box>
            </Grid> */}
            </Grid>
          </Grid>

          <Grid container direction={"column"} style={{ padding: "0px 20px 0px 20px", margin: "0px 0px 20px 0px" }}>
            <Grid item style={{ padding: "40px", borderBottom: "2px solid #bfbfbf", borderBottomStyle: "dotted" }}>
              <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <p style={creditnotestyles.paraBold}>Due Date:{customerDetails.expiryDate} </p>
                </Box>

                <Box>
                  <QrcodeComponent creditNoteNumber={customerDetails.creditNoteNumber}  size ={80}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction={"column"} style={{ padding: "0px 20px 0px 20px", margin: "0px 0px 20px 0px" }} width={"100%"}>
            <Grid item style={{ padding: "40px" }}>
              <CustomTable
                tableHeaders={[
                  { field: "productName", header: "Product Name" },
                  { field: "productPrice", header: "Price" },
                  { field: "quantity", header: "Qty(KES)" },
                  { field: "totalAmount", header: "Total(KES)" },
                ]}
                tableData={creditNotePreviewItems?.map((prod, index) => ({
                  productName:prod?.productName,
                  productPrice:prod?.productPrice,
                  quantity:prod?.quantity,
                  totalAmount:prod?.totalAmount
                }))}
              />
            </Grid>

            <Grid item style={{ padding: "40px", display: "flex", justifyContent: "right", borderBottom: "2px solid #bfbfbf", borderBottomStyle: "dotted" }}>
              <span style={{ backgroundColor: "#f7f7f7", padding: "10px", fontWeight: "bold" }}>Total {numberFormat(totals)} </span>
            </Grid>
          </Grid>

          <Grid container direction={"column"} style={{ padding: "0px 20px 0px 20px" }} width={"100%"}>
            <Grid item style={{ alignItems: "center", justifyContent: "center", display: "flex", backgroundColor: "#f7f7f7" }}>
              <p>ZED Powered by Riverbank Solutions info@riverbank.co.ke vl.0.2</p>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Previewcreditnote;
