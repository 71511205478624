import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  businessId: null,
  userId: null,
  branchName: null,
  branchCode: null,
  branchType: null,
  email: null,
  phone: null,
  status: null,
  isDefault: false,
  admins: null,
  stockOptionId: null,
  showStockInSale: false,
  allowNegativeStockSale: false,
  showBuyingPriceInSale: false,
  superVisorCanDoReturn: false,
  branchId: null,
  createdAt: null,
  updatedAt: null,
  stores: null,
  enableBarAndKitchen: false,
  totalSales: null,
};

const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    handleBranchDetails: (state, action) => {
      const {
        businessId,
        userId,
        branchName,
        branchCode,
        branchType,
        email,
        phone,
        status,
        isDefault,
        admins,
        stockOptionId,
        showStockInSale,
        allowNegativeStockSale,
        showBuyingPriceInSale,
        superVisorCanDoReturn,
        _id: branchId,
        createdAt,
        updatedAt,
        stores,
        enableBarAndKitchen,
        totalSales,
      } = action.payload;

      state.businessId = businessId;
      state.userId = userId;
      state.branchName = branchName;
      state.branchCode = branchCode;
      state.branchType = branchType;
      state.email = email;
      state.phone = phone;
      state.status = status;
      state.isDefault = isDefault;
      state.admins = admins;
      state.stockOptionId = stockOptionId;
      state.showStockInSale = showStockInSale;
      state.allowNegativeStockSale = allowNegativeStockSale;
      state.showBuyingPriceInSale = showBuyingPriceInSale;
      state.superVisorCanDoReturn = superVisorCanDoReturn;
      state.branchId = branchId;
      state.createdAt = createdAt;
      state.updatedAt = updatedAt;
      state.stores = stores;
      state.enableBarAndKitchen = enableBarAndKitchen;
      state.totalSales = totalSales;
    },
    deleteBranchDetails: (state) => {
      state.businessId= null;
      state.userId= null;
      state.branchName= null;
      state.branchCode= null;
      state.branchType= null;
      state.email= null;
      state.phone= null;
      state.status= null;
      state.isDefault= false;
      state.admins= null;
      state.stockOptionId= null;
      state.showStockInSale= false;
      state.allowNegativeStockSale= false;
      state.showBuyingPriceInSale= false;
      state.superVisorCanDoReturn= false;
      state.branchId= null;
      state.createdAt= null;
      state.updatedAt= null;
      state.stores= null;
      state.enableBarAndKitchen= false;
      state.totalSales= null;
    },
  },
});

export const { handleBranchDetails, deleteBranchDetails } = branchSlice.actions;

export default branchSlice.reducer;
