import { Select, MenuItem, FormControl, InputLabel, Box, Button } from "@mui/material";
import React from 'react';

const CustomSelectWithActions = ({
  value,
  onChange,
  name,
  label, 
  options,
  onAddAction,
  actionLabel = "Add Department",
  width = "100%",
  ...rest
}) => {
  // Function to find the label associated with the current value
  const getLabelForValue = (value) => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? selectedOption.label : "";
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          displayEmpty
          variant="outlined"
          margin="dense"
          style={{ width }}
          sx={{ marginBottom: 2, marginTop: 1 }}
          inputProps={{
            height: '3.438rem',
            fontSize: "0.875rem",
            "& ::placeholder": { color: '#bec5d1' },
            "& fieldset": { borderColor: "#bec5d1" }
          }}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" }
          }}
          renderValue={(value) => {
            // Display the label instead of the value (ID)
            if (value === "") {
              return <em>{label}</em>;
            }
            return getLabelForValue(value);
          }}
          {...rest}
        >
          {options.length === 0 ? (
            <MenuItem value="" disabled>
              No items available
            </MenuItem>
          ) : (
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))
          )}
          {/* Divider */}
          <MenuItem disabled>
            <hr style={{ width: '100%', borderTop: '1px solid #bec5d1' }} />
          </MenuItem>
          {/* Add Action Button */}
          <MenuItem>
            <Button style={{ border: 'none', width: '100%' }} variant="outlined" color="primary" onClick={onAddAction}>
              {actionLabel}
            </Button>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelectWithActions;
