import { Breadcrumbs, Grid } from "@mui/material";
import React from "react";
import { customerstyles } from "./styles";
import { NavigateNext } from "@mui/icons-material";
import Receipt from '../customerComponents/receipt'
import { useParams } from "react-router-dom";

const Paymentreceipt = () => {

    const { id, path } = useParams();

  return (
    <div>
      {/* <Grid container direction={"column"} style={{ margin: "0px 0px 10px 0px" }} justifyContent={"space-between"} width={"100%"}>
        <Grid item>
          <span style={customerstyles.invoicesText}>Preview Receipt</span>
        </Grid>

        <Grid item>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            <span style={{ color: "red" }}>Preview Receipt</span>
          </Breadcrumbs>
        </Grid>
      </Grid> */}


      <Grid container >
        <Receipt path={path} receiptNo={id} />
      </Grid>
    </div>
  );
};

export default Paymentreceipt;
