import { createSlice } from '@reduxjs/toolkit'

let initialState = {
	tenantInvoice: "",
	tenantExistBio:null,
	tenantBioData: null,
	tenantHouseData: {},
	tenantChargesData: [],
	tenantServices:null,
	tenantHouseServices:null ,
	tenantEvictionData: null,
	tenantWithExitNotice: null,
	tenantInvCharge:null,
	tenantsAllocatedHouses:[],
	tenantCurrentServices:null,
	tenantInvoiceServices:null,
	tenantExitNotice:null,
	tenantProfileHouses:[],
	tenantHouseArray:[],
	tenantTransferHouse:null
}

const tenantsDataSlice = createSlice({
	name: 'tenantsPostData',
	initialState,
	reducers: {
		handleTenantTransfer: (state , action) => {
			state.tenantTransferHouse = action.payload
		},
		handleAddTenantHouseArray:(state,action)=>{
		state.tenantHouseArray = action.payload
		},
		handleSaveSelectedHousesFromTenantProfile:(state,action)=>{
			const tenantProfileHouses = {...action.payload}
			state.tenantProfileHouses = [...state.tenantChargesData, tenantProfileHouses]
		},
		handleTenantInvoiceServices:(state ,action)=>{
			state.tenantInvoiceServices = action.payload
		},
		handleSaveTenantNotice:(state,action)=>{
			state.tenantExitNotice = action.payload
		},
		handleSetCurrentServices:(state,action)=>{
			console.log('check current house ====>', action.payload);
			state.tenantCurrentServices = action.payload
		},
		handleAddCurrentServcie:(state,action)=>{
			if (!state.tenantCurrentServices) { 
				state.tenantCurrentServices = [action.payload];
			 }
			else { state.tenantCurrentServices.push(action.payload); }
		},
		handledeleteCurrentServcie:(state,action)=>{
			state.tenantCurrentServices = state.tenantCurrentServices.filter(services => services.productId !== action.payload)
		},
		handleTenantInvoiceNumber: (state, action) => {
			state.tenantInvoice = action.payload
		},
		handleAddTenantAllocated: (state, action) => {
			state.tenantsAllocatedHouses =[...state.tenantsAllocatedHouses , action.payload]
		},
		handleTenantBioData: (state, action) => {
			state.tenantBioData = action.payload
		},
		handleTenantExistsBioData:(state,action)=>{
			state.tenantExistBio = action.payload
		},
		handleTenantHouseData: (state, action) => {
			console.log('check house info ====>', action.payload);
			console.log('initial state ====>', state.tenantHouseData);
			state.tenantHouseData = action.payload
		},
		handleUpdatedTenantHouseData:(state ,action) =>{
			if (state.tenantHouseData === null) {
				state.tenantHouseData = [action.payload];
			  } else {
				state.tenantHouseData.push(action.payload);
			  }
		},
		handleInvoiceCharge:(state ,action) =>{
			state.tenantInvCharge = action.payload
		} ,
		handleChargesData: (state, action) => {
			const newTenantCharge = {...action.payload}
			console.log('check house charges ====>', newTenantCharge);

			state.tenantChargesData = [...state.tenantChargesData, newTenantCharge]
		},
		handleChargeService: (state, action) => {
			state.tenantServices = action.payload
		},
		handleTenantHouseServices:(state,action)=>{
			state.tenantHouseServices = action.payload
		},
		//Update TenantBio Data
		handleUpdateTenantBioData: (state, action) => {
			state.tenantBioData = { ...state.tenantBioData, tenantId: action.payload }
		},

		//Update tenantHouse Data
		handleUpdateTenantHouseData: (state, action) => {
			console.log('check house info ====>', action.payload);
			state.tenantHouseData = action.payload
		},

		// Add Charge
		handleAddCharge : (state ,action) => {
			if (!state.tenantChargesData) {state.tenantChargesData = [action.payload];}
			else {state.tenantChargesData.push(action.payload);}
		},

		//Add to list
		handleAddChargeToList: (state, action) => {
			if (!state.tenantHouseServices) { 
				state.tenantHouseServices = [action.payload];
			 }
			else { state.tenantHouseServices.push(action.payload); }
		},


		// Remove Charge By Product ID
		handleDeleteCharge: (state, action) => {
			state.tenantChargesData = state.tenantChargesData.filter(services => services.productId !== action.payload)
		},

		//remove charge from listed services
		handleRemoveChargeFromList:(state,action)=>{
			state.tenantHouseServices = state.tenantHouseServices.filter(services => services.productId !== action.payload)

		},

		// handle delete product byb id  handleDeletebyId
		handleDeletebyId: (state, action) => {
			state.tenantHouseServices = state.tenantHouseServices.filter(services => services.productId !== action.payload)
		},

		// Get tenant Vacation Details
		handleTenantVacationData: (state, action) => {
			state.tenantEvictionData = action.payload
		},
		//get data for tenant with exit notice
		handleTenantExitNotice: (state, action) => {
			console.log(action.payload , 'set notice info')
			state.tenantWithExitNotice = action.payload
		},
		handleReset: (state) => {
			state.tenantInvoice = ""
			state.tenantExistBio =null
			state.tenantBioData = null
			state.tenantHouseData = {}
			state.tenantChargesData =[]
			state.tenantServices = null
			state.tenantHouseServices = null
			state.tenantEvictionData = null
			state.tenantWithExitNotice = null
			state.tenantInvCharge = null
			state.tenantsAllocatedHouses = []
			state.tenantProfileHouses = []
			state.tenantCurrentServices = null 
			state.tenantExitNotice = null
			state.tenantHouseArray = []
			state.tenantInvoiceServices = null
			state.tenantTransferHouse = null
		},
		handleClearTenantHouseData:(state) => {
			console.log(state ,'cleared bio')
			state.tenantHouseData = {}
		}

	}
})

export const { handleSetCurrentServices,handleTenantTransfer , handleTenantInvoiceServices ,handleAddTenantHouseArray, handleSaveSelectedHousesFromTenantProfile,handleSaveTenantNotice ,handledeleteCurrentServcie, handleAddCurrentServcie , handleTenantBioData,handleTenantExistsBioData,handleReset ,handleClearTenantHouseData,handleUpdatedTenantHouseData,handleAddTenantAllocated ,handleInvoiceCharge,handleChargeService,handleTenantHouseServices ,handleAddChargeToList ,handleRemoveChargeFromList , handleTenantVacationData,handleDeletebyId , handleTenantExitNotice, handleTenantHouseData, handleChargesData, handleTenantInvoiceNumber, handleDeleteCharge, handleAddCharge, handleUpdateTenantBioData, handleUpdateTenantHouseData } = tenantsDataSlice.actions
export default tenantsDataSlice.reducer