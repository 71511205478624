import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import powericon from "../../common/images/powericon.svg";
// src/common/images/powericon.svg

function QuestionModalSettle(props) {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);
  const color = props.color;

  // session Expired
  const style = {
    borderColor: "transparent",
    borderRadius: "36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "350px",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={props.deactivateModalOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={props.formAction}>
            <div
              style={{
                width: "50px",
                height: "50px",
                marginLeft: "150px",
                padding: "12px 30px 80px",
                objectFit: "contain",
              }}
            >
              <img style={{ width: "82px", height: "82px", margin: "-24px 0px 0px -66px" }} src={powericon} alt="img" />
            </div>
            <span
              style={{
                width: "244px",
                height: "25px",
                margin: "17px 28px 15px 31px",
                fontSize: "18px",
                fontWeight: " bold",
                fontStretch: "normal",
                fontStyle: " normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: " #032541",
              }}
            >
              Confirmation
            </span>
            <div>
              <h6
                style={{
                  margin: "15px 11px 6px 14px",
                  fontSize: "18px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  letterSpacing: "normal",
                  color: "#323d52",
                  display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    
                }}
              >
                Confirm you have received
              </h6>

              <h6
                style={{
                  fontWeight: "bold",
                  color: "#17ae7b",
                  textAlign: "center",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {numberFormat(props.setAmt)}
              </h6>

              
                
                <h6 style={{ color: color, display: "flex",   alignItems: "center",justifyContent: "center", }}>{numberFormat(props.value)} <span style={{color:"#323d52" , marginLeft:"5px"}}>   is the {props.message}</span> </h6>
             
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={props.onClose}
                variant="outlined"
                style={{
                  fontFamily: "Poppins",
                  width: "200px",
                  fontSize: "12px",
                  textAlign: "left",
                  color: "#dc3545",
                  height: "40px",
                  margin: "20px 30px 0 0",
                  border: "solid 1px #dc3545",
                }}
              >
                Cancel
              </Button>
              {/*  save  */}
              <Button
                type="submit"
                variant="standard"
                style={{
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  width: "240px",
                  height: "40px",
                  margin: "20px 10px 0 30px",
                  backgroundColor: "#032541",
                }}
              >
                Confirm
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
export { QuestionModalSettle };
