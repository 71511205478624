import { Breadcrumbs, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography, Stack } from '@mui/material';
import React from 'react'
import ExportMenu from '../../../School/ExportMenu';
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import BioIcon from "../../../../images/biotrans.svg"
import BioTotalIcon from "../../../../images/bioTotal.svg"
import BioBalanceIcon from "../../../../images/biobal.svg"
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFormatter from '../../../../utils/dateFormatter';
import HttpComponent from '../../../School/MakeRequest';
import AutocompleteComponent from '../../../../common/autoComplete';
import Breadcrumb from '../Breadcrumbs/Breadcrumbs';
import { Exports } from '../../../customerAccounts/customerComponents/exports';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import ExportDataFromApi from './ExportFromApi';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Pocket Money</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Bio Transactions</Typography>
]

let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? "KES" : localCurrency, }).format(value);


function ZPMbioreports() {

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [totalCredit, setTotalCredit] = useState(0)
    const [totalDebit, setTotalDebit] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("Last7days");
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const group = localStorage.getItem('group')


    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness()
    }, [selectedValue])


    const [allRegions, setAllRegions] = useState([])
    const [selectedRegion, setSelectedRegion] = useState('')
    function getAllRegionsList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/partnerregions`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllRegions(data?.response?.data?.filter(item => item?.status === 'Active'))
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllRegionsList()
    }, [])

    const [allBranches, setAllBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState('')

    function getAllBranchesList() {
        try {
            HttpComponent({
                method: "GET",
               // url: `/api/v1/allregionpartnerbranches`,
                url:`/api/v1/allregionpartnerbranchesbyid?regionId=${selectedRegion}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const myfoundbranch = data?.response?.data?.filter(item => item?.status === 'Active')?.map((item) => ({
                        value: item?.branchCode,
                        label: item?.branchName
                    }))
                    setAllBranches(myfoundbranch)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllBranchesList()
    }, [selectedRegion])


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last7days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                setStartDate('');
                setEndDate('');
                break;
        }

    }, [handleDaysChange])

    const inputStyle = {
        width: '100%',
        '& input': {
            border: '1px solid #bdbdbd',
            borderRadius: '4px',
        },
    };


    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    //all  transactions columns

    const BioTransactionColumns = [

        { headerName: "Receipt No", field: 'receiptNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >RRN/Receipt No</strong>) } },
        { headerName: "Created At", field: 'transTime', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <span>{DateFormatter(params?.row?.transactionTime)}</span> },
        { headerName: "Source", field: 'source', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Source</strong>) }, renderCell: (params) => <span>{params?.row?.source}</span> },
        { headerName: "Transaction Type", field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >TxN Type</strong>) }, renderCell: (params) => <Typography sx={{ color: params?.row?.transactionType === 'DEBIT' ? '#dc3545' : params?.row?.transactionType === 'CREDIT' ? '#17ae7b' : '#707070' }}>{params?.row?.transactionType}</Typography> },
        { headerName: "Instution", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Institution</strong>) } },
        { headerName: "Teller Id", field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) } },
        { headerName: "Terminal Serial", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) } },
        { headerName: "amount", field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Amount ({localCurrency})</strong>) }, renderCell: (params) => <span>{numberFormat(params?.row?.amount)}</span> },
    ]

    // fetch data

    function getBioTransactions() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/card/report/zpmbioreports?page=${pageState.page}&limit=${dataGridPageSize === 100 ? pageState.total: dataGridPageSize}&startDate=${startDate}&endDate=${endDate}&businessNumber=${selectInputValue?.bsNo ? selectInputValue?.bsNo : ''}&branchCode=${selectedBranch}&regionId=${selectedRegion}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    setTotalDebit(data?.response?.debitTotals)
                    setTotalCredit(data?.response?.creditTotals)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getBioTransactions()
    }, [dataGridPageSize, pageState.page, searchValue, startDate, endDate, handleDaysChange, selectInputValue?.bsNo , selectedRegion ,selectedBranch])


    //exports

 const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });

  useEffect(()=>{
    const filedata = pageState?.data?.map((data) => {
        return {
            "Receipt No": data?.receiptNo,
            "Date & Time": DateFormatter(data?.transactionTime),
            "Source": data?.source,
            "TXN Type": data?.transactionType,
            "Institution": data?.businessName,
            "Teller Id": data?.tellerId,
            "Terminal Serial": data?.terminalSerialNumber,
            "Total Amount": numberFormat(data?.amount)
        }
    })
    const fileHeaders = [["Receipt No", "Date & Time", "Source", "TXN Type", "Institution", 'Teller Id', 'Terminal Serial', 'Total Amount']]
    const csvColumns = [
        { label: "Receipt No", key: "Receipt No" },
        { label: "Date & Time", key: "Date & Time" },
        { label: "Source", key: "Source" },
        { label: "TXN Type", key: "TXN Type", },
        { label: "Institution", key: "Institution" },
        { label: "Teller Id", key: "Teller Id", },
        { label: "Terminal Serial", key: "Terminal Serial", },
        { label: "Total Amount", key: "Total Amount", }
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.receiptNo, DateFormatter(data?.transactionTime), data?.source, data?.transactionType, data?.businessName, data?.tellerId, data?.terminalSerialNumber, numberFormat(data?.amount)]);

    setCsvExport({ headers: csvColumns, data: filedata })
    setExportData({ headers: fileHeaders, data: fileDataPDF })
    
  },[pageState.data,startDate ,searchValue, selectInputValue?.bsNo, endDate ])


   // export csv

   function handleExportCSV() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/card/report/zpmbioreportsexport`,
        body: {
          startDate: startDate,
          endDate: endDate,
          searchValue:searchValue,
          businessNumber:selectInputValue?.bsNo
        },
        token: localStorage.getItem("X-Authorization"),
      }).then((data)=>{
        if(data?.status === 200){
           setSuccessShow({state:true , message:data?.response?.message})
        }else{
          setErrorShow({state:true , message:data?.response?.message})
        }
      })
    } catch (error) {
        setErrorShow({state:true , message:error?.message})
    }
  }

   

    return (
        <Grid container display={'flex'} direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'}>
                <Stack alignItems='center' direction='row' spacing={2}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Bio Transactions</Typography>
                </Stack>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        {/* <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs> */}
                        <Breadcrumb name={'Bio Transactions'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} display={'flex'}>
                <Grid container display={'flex'} justifyContent={'space-between'}>
                    <Grid item>
                        <Paper elevation={2} sx={{ width: '387px', borderRadius: '5px', height: '138px' }}>
                            <Grid item width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid container display={'flex'} px={1} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item display={'flex'} direction={'column'}>
                                        <Typography my={1} sx={{ color: '#212b36', lineHeight: 1.25, fontWeight: 'normal', fontSize: '16px' }}>Total Amount Deposited</Typography>
                                        <Typography my={0.5} sx={{ color: '#f79009', fontSize: '20px', fontWeight: 'bold' }}>{numberFormat(totalCredit)}</Typography>
                                        <Typography sx={{ color: '#909090', lineHeight: 1, fontWeight: 500, fontSize: '14px' }}>Credit</Typography>
                                    </Grid>
                                    <Grid item>
                                        <img alt="" src={BioIcon} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper elevation={2} sx={{ width: '387px', borderRadius: '5px', height: '138px' }}>
                            <Grid item width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid container display={'flex'} px={1} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item display={'flex'} direction={'column'}>
                                        <Typography my={1} sx={{ color: '#212b36', lineHeight: 1.25, fontWeight: 'normal', fontSize: '16px' }}>Total Amount Used</Typography>
                                        <Typography my={0.5} sx={{ color: '#0381ed', fontSize: '20px', fontWeight: 'bold' }}>{numberFormat(totalDebit)}</Typography>
                                        <Typography sx={{ color: '#909090', lineHeight: 1, fontWeight: 500, fontSize: '14px' }}>Debit</Typography>
                                    </Grid>
                                    <Grid item>
                                        <img alt="" src={BioTotalIcon} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper elevation={2} sx={{ width: '387px', borderRadius: '5px', height: '138px' }}>
                            <Grid item width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid container display={'flex'} px={1} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item display={'flex'} direction={'column'}>
                                        <Typography my={1} sx={{ color: '#032541', lineHeight: 1.25, fontWeight: 'normal', fontSize: '16px' }}>Total Balance</Typography>
                                        <Typography my={0.5} sx={{ color: '#17ae7b', fontSize: '20px', fontWeight: 'bold' }}>{numberFormat(totalCredit - totalDebit)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <img alt="" src={BioBalanceIcon} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={4} justifyContent={'space-between'} alignItems={'center'}>
                <Grid container display={'flex'} gap={2} width={'100%'} alignItems={'center'}>
                    <Grid item mt={-1}>
                        <TextField value={searchValue} onChange={(e) => setSearchValue(e.target.value)} name={'search'} placeholder={'search'} variant="outlined" margin="dense"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    {group === 'Partner' ? null :
                    <Grid item>
                        <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "10px", width: '250px' }} />
                    </Grid>}
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} placeholder={'startDate'} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    placeholder={'endDate'}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'endDate',
                                        style: { inputStyle }
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value=''>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last7days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {group === 'Partner' && (
                        <Grid item>
                            <FormControl sx={{ width: '150px' }}>
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Region"
                                    value={selectedRegion}
                                    //defaultValue={'Last7days'}
                                    onChange={(e) => setSelectedRegion(e.target.value)}
                                >
                                    {allRegions?.map((region) => {
                                        return (
                                            <MenuItem value={region?._id}>{region?.regionName}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {group === 'Partner' && (
                    <Grid item>
                        <FormControl sx={{ width: '150px' }}>
                            <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Branch"
                                value={selectedBranch}
                                onChange={(e) => setSelectedBranch(e.target.value)}
                            >
                                {allBranches?.map((branch) => {
                                    return (
                                        <MenuItem value={branch?.value}>{branch?.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>)}
                    <Grid item>
                    <ExportDataFromApi handleDownloadData={handleExportCSV} />
                        {/* <Exports  exportData={exportData} activeTabs={'Zed-Pocket Money Bio Transactions'}  csvExport={csvExport}/> */}
                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Bio Transactions'} title={'Zed-Pocket Money Bio Transactions'} /> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={BioTransactionColumns}
                    rows={pageState?.data}
                    getRowId={row => row._id} />
            </Grid>
        </Grid>
    )
}

export default ZPMbioreports
