import { Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Groups2Icon from "@mui/icons-material/Groups2";
import axios from "axios";
import cardBG from "../../../common/images/TotalUserBG.png";

const baseUrl = process.env.REACT_APP_BASE_URL;

function TotalUsers() {
  const [userTotals, setUserTotals] = useState({});

  let Group = localStorage.getItem("group")
  let partnerName = localStorage.getItem("affiliatedPartner")
  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  const fetchUsers = async () => {
    if(isAdmin) {
      const response = await axios.get(baseUrl + "/api/userCount", {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      setUserTotals(response.data.data);
    }
    //  else if (isPartner) {
    //   // fetch for partner
    //   const response = await axios.post(baseUrl + "/api/countPartnerUsers",
    //   {
    //     partnerName
    //   },
    //    {
    //     headers: {
    //       "X-Authorization": localStorage.getItem("X-Authorization"),
    //     },
    //   });
    //   setUserTotals(response.data);
    // }

    else if(isPartner) {
      const response = await axios.post(baseUrl + "/api/getPartnerUsers",
      {
        partnerName
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      let users = response.data.users
    
      let activeArr = []
      let inactiveArr = []
      let newArr = []
    
      users.map(obj => {
        if(obj.userState === 'Active' || obj.userState === 'ACTIVE') {
          activeArr.push(obj)
        } 
        else if(obj.userState === 'Inactive') {
          inactiveArr.push(obj)
        } 
        else if(obj.userState === 'New') {
          newArr.push(obj)
        } 
      })
    
      let obj = {
        "Active": activeArr.length,
        "New": newArr.length,
        "Inactive": inactiveArr.length,
        "total": activeArr.length + newArr.length + inactiveArr.length
       }
    
       setUserTotals(obj);
      // console.log('users count', obj)
    }


    console.log(`userTotals `, userTotals)
   
    // console.log(businessTotals)
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Stack
      width={"100%"}
      height="166px"
      justifyContent={"space-between"}
      p={2}
      borderRadius={"12px"}
      boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
      bgcolor={"white"}
      style={{
        backgroundImage: `url(${cardBG})`,
        backgroundSize: "70% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={"16px"}
          fontWeight={500}
          fontStretch={"normal"}
          fontStyle={"normal"}
          lineHeight={1.31}
          letterSpacing={"normal"}
          textAlign={"left"}
          color={"#2353ff"}
        >
          Total Users
        </Typography>
        <Groups2Icon sx={{ color: "#2353ff" }} />
      </Stack>
      <Stack width="100%">
        <Typography
          fontFamily="Poppins"
          fontSize={18}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {userTotals?.total >= 0 ? userTotals?.total : 
        <Skeleton variant="text" fontSize={18} width={'30%'} sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
          
          // <CircularProgress />
          }
        </Typography>
      </Stack>
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            {userTotals?.Active >= 0 ? (isPartner?userTotals?.Active : userTotals?.Active + userTotals?.ACTIVE) : 
            // <CircularProgress />
        <Skeleton variant="text" fontSize={14}sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
            
            }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Active
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            {userTotals?.Inactive >= 0 ? (
              userTotals?.Inactive
            ) :(
        <Skeleton variant="text" fontSize={14}sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
              // <CircularProgress />
            )}
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Inactive
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            {userTotals?.New >= 0 ? userTotals?.New : 
        <Skeleton variant="text" fontSize={14}sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
            
            // <CircularProgress />
            }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            New
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TotalUsers;
