import React, { useEffect, useState } from 'react'
import { Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment-timezone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from '../School/CustomSearchInput';
import HttpComponent from '../School/MakeRequest';
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';
import ExportMenu from '../School/ExportMenu';
import { currencyconverter } from '../../common/currencyconverter';
import CustomSelectField from '../School/CustomSelectField';
import { CustomizedDate } from '../customerAccounts/customerComponents/customDate';
import DateFormatter from '../../utils/dateFormatter';


//add breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Sales Report By Cashier</Typography>
]


const baseUrl = process.env.REACT_APP_BASE_URL




const columns = [
    { field: "transactionID", headerName: 'Receipt No', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Receipt No '}</strong>) },
    { field: "businessName", flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'BusinessName'}</strong>) },
    { field: "transactionType", headerName: 'Payment Type', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Payment Type '}</strong>) },
    { field: "paymentChanel", headerName: 'Payment Device', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Payment Device '}</strong>) },
    { field: "cashier", headerName: 'Terminal User', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Terminal User'}</strong>) },
    { field: "transtime", headerName: 'Date', flex: 1, align: 'left', valueGetter: (params) => { return DateFormatter(params.row.transtime) }, renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Date'}</strong>) },
    { field: "transamount", headerName: 'Amount', flex: 1, align: 'left', valueGetter: (params) => { return currencyconverter(params.row.transamount) }, renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Amount'}</strong>) },
];

const ReportsByCashier = () => {
    const [selectedCashier, setSelectedCashier] = useState('')

    const handlesetSelectedCashier = (event) => {
        const selectedValue = event.target.value.cashier
        if(!selectedValue){
            setSelectedCashier("")
        }else{
            setSelectedCashier(selectedValue)
        }
    }
    const [searchValue, setSearchValue] = useState('')
    const [selectedTransactionType, setSelectedTransactionType] = useState('')

  
    
    const [selectedCustomDate, setselectedCustomDate] = useState()
    const [showSelectDate, setShowSelectDate] = useState(true)
    const [transactionType, setTransactionType] = useState([])
    const [cashiers, setCashiers] = useState([])

    const handleTransactionType = (event) => {
        const selectedValue = event.target.value;
        console.log(selectedValue , 'selectedValue')
        const selectedTransaction = transactionType?.find(transaction => transaction?._id?.transactionType === selectedValue?.transactionType);
        if(selectedTransaction){
            const {transactionType} = selectedTransaction?._id
            setSelectedTransactionType(transactionType);
        }
    };
    
    console.log(selectedTransactionType , 'selectedTransactionType')
   

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    const hideShowSelectedDate = (selectedCustomDate) => {
        if (selectedCustomDate === 'custom_range') {
            setShowSelectDate(false)
        } else {
            setShowSelectDate(true)
        }
        setselectedCustomDate(selectedCustomDate);
    }


    // custom date format;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
  
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
      ]);

      useEffect(() => {
        switch (handleDaysChange) {
          case "Today":
            const today = new Date();
            setStartDate(today);
            setEndDate(today);
            break;
          case "Yesterday":
            let date = new Date();
            const yeseterday = new Date(date.setDate(date.getDate() - 1));
            // console.log(yeseterday);
            setStartDate(yeseterday);
            setEndDate(yeseterday);
            break;
          case "Last 7 Days":
            function Last7Days() {
              var result = [];
              for (var i = 0; i < 7; i++) {
                var d = new Date();
                result.push(d.setDate(d.getDate() - i));
              }
              const lastSevenFirst = new Date(result[0]);
              const lastSevenLast = new Date(result[result?.length - 1]);
              setStartDate(lastSevenLast); 
              setEndDate(lastSevenFirst);
            }
            Last7Days();
            break;
          case "This Month":
            const date1 = new Date();
            const firstDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0, 0));
            const lastDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth() + 1, 0, 23, 59, 59, 999));
            setStartDate(firstDay);
            setEndDate(lastDay);
            break;
          case "Last Month":
            const date2 = new Date();
            const firstDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth() - 1, 1, 0, 0, 0, 0));
            const lastDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), 0, 23, 59, 59, 999));
            setStartDate(firstDayLastMonth);
            setEndDate(lastDayLastMonth);
            break;
          case "Default":
            setStartDate("");
            setEndDate("");
            break;
          case "Custom Range":
            setStartDate(new Date(dateFrom));
            setEndDate(new Date(dateTo));
            break;
          default:
            return;
        }
      }, [handleDaysChange, dateFrom, dateTo]);


    //fetch all cashiers
    const get_all_cashiers = () => {
        HttpComponent({
            method: "GET",
            url: '/api/get_cashiers_by_business',
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            console.log(data, 'all cashiers')
            setCashiers(data?.response?.data)
        })
    }

    useEffect(() => {
        get_all_cashiers()
    }, [])

    //fetch  transaction types

    const getTransactionTypes = () => {
        HttpComponent({
            method: "GET",
            url: '/api/transactionType',
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            setTransactionType(data?.response?.data)
        })
    }

    useEffect(() => {
        getTransactionTypes()
    }, [])

    const mapOptions = transactionType?.map((transaction) => {
        return {
            value: transaction?._id?.transactionType,
            label: transaction?._id?.transactionType
        }
    })


    // get all transactions

    function getAllTransactions() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_transactions_by_cashier?startDate=${startDate}&endDate=${endDate}&page=${pageState.page}&limit=${dataGridPageSize}&cashier=${selectedCashier}&paymentType=${selectedTransactionType}&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log("data respose", data.response.data)
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllTransactions()
    }, [startDate, endDate, pageState?.page, dataGridPageSize, selectedCashier, selectedTransactionType, searchValue ])

    const rowData = pageState?.data?.map((transaction) => {
        return {
            id: transaction._id,
            businessNo: transaction.businessNo,
            businessName: transaction.businessName,
            businessShortCode: transaction.businessShortCode,
            transactionID: transaction.transactionID,
            transactionType: transaction.transactionType,
            transtime: transaction.transtime,
            uploadTime: transaction.uploadTime,
            customerFirstName: transaction.customerFirstName,
            customerMiddleName: transaction.customerMiddleName,
            customerPhone: transaction.customerPhone,
            transamount: transaction.transamount,
            serialNo: transaction.serialNo,
            cashier: transaction.cashier,
            productName: transaction.productName,
            productCategory: transaction.productCategory,
            paymentChanel: transaction.paymentChanel,
            items: transaction.items
        };
    })

     //export data

     const filedata = pageState?.data?.map((data) => {
        return {
            "Receipt No": data?.transactionID,
            "Payment Mode": data?.paymentChanel,
            "Transaction Type": data?.transactionType,
            "Cashier Name": data?.cashier,
            "Date": data?.transtime,
            "Amount": data?.transamount
        }
    })
    const fileHeaders =  [[ "Receipt No", "Payment Mode", "Transaction Type", "Cashier Name", "Date", "Amount"]];
    const csvColumns = [
        { label: "Receipt No", key: "Receipt No" },
        { label: "Payment Mode", key: "Payment Mode" },
        { label: "Transaction Type", key: "Transaction Type" },
        { label: "Cashier Name", key: "Cashier Name", },
        {label:"Date" , key:"Date"},
        { label: "Amount", key: "Amount", }
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.transactionID, data?.paymentChanel, data?.transactionType, data?.cashier, data?.transtime,  data?.transamount,]);

    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Sales Report By Cashier</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={`Sales Report By Cashier on ${DateFormatter(new Date())}`} title={`Sales Report By Cashier on ${DateFormatter(new Date())}`}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={4}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <CustomSearchInput placeholder={'Search receipt number'} name={searchValue} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                    </Grid>
                    <Grid item style={{ width: "200px" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="">Transaction Type</InputLabel>
                            <Select
                                labelId="This Month"
                                id="date"
                                label="Transaction Type"
                                value={selectedTransactionType}
                                onChange={(e)=>setSelectedTransactionType(e.target.value)}
                            >
                                {mapOptions?.map((transaction) => (
                                    <MenuItem value={transaction?.value}>{transaction?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ width: "300px" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="">Cashiers</InputLabel>
                            <Select
                                labelId="This Month"
                                id="date"
                                label={'Cashiers'}
                                value={selectedCashier}
                                onChange={(e)=>setSelectedCashier(e.target.value)}
                            >
                                <MenuItem value={"All Cashiers"} >All</MenuItem>
                                {cashiers?.map((cashier) => (
                                    <MenuItem value={cashier?._id?.cashier}>{cashier?._id?.cashier}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item display={'flex'} alignItems={'center'} style={{ width: "200px" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="">Date</InputLabel>
                            <Select
                                labelId="This Month"
                                id="date"
                                label="Date"
                                value={selectedCustomDate}
                                onChange={(e) => { hideShowSelectedDate(e.target.value) }}
                            >   
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"today"} >Today</MenuItem>
                                <MenuItem value={"yesterday"} >YesterDay</MenuItem>
                                <MenuItem value={"week"} >Last 7 days</MenuItem>
                                <MenuItem value={"this_month"} >This Month</MenuItem>
                                <MenuItem value={"last_month"} >Last Month </MenuItem>
                                <MenuItem value={"last_3_month"} >Last Three Months </MenuItem>
                                <MenuItem value={"custom_range"} >Custom Range </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid item style={{width:'500px'}}>
                        {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} /> } 
                        {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", }} />}
                    </Grid>

                </Grid>
            </Grid>
            <Grid item>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={columns}
                    rows={rowData}
                    getRowId={row => row.id} />
            </Grid>
        </Grid>
    )
}

export default ReportsByCashier