import { Grid, Breadcrumbs, Typography, Button, TextField, FormControlLabel, Checkbox} from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import { CheckBox } from "@mui/icons-material";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Terminal Users</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit User</Typography>
]

export default function EditTerminalUser(props) {

    const [formData, setFormData] = useState({ firstName: "", outlet: '', userName: "", lastName: "", phoneNumber: "", email: "", idNo: "", userType: "",allowedReceiptReprinting:"" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
   

    const navigate = useNavigate()

    //fetch details of single terminal user
    function terminalUserDetails() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/getZedPayItUserById?userId=${props?.userid}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'edit')
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        firstName: data?.response?.data?.firstName,
                        lastName: data?.response?.data?.secondName,
                        email: data?.response?.data?.userEmail,
                        userName: data?.response?.data?.userName,
                        phoneNumber: data?.response?.data?.userPhone,
                        idNo: data?.response?.data?.userIdNumber,
                        allowedReceiptReprinting: data?.response?.data?.allowedReceiptReprinting
                        //outlet: data?.response?.data?.storeId,
                    })
                    //setBranchId(data?.response?.data?.branchId)
                    //setPrevstoreId(data?.response?.data?.storeId)
                }
            })
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        terminalUserDetails()
    }, [])

    //console.log(formData?.outlet)
    //handle onchange

    const handleOnChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };


    //fetch outlets

    const formSubmit = {
        userEmail: formData.email,
        userPhone: formData.phoneNumber,
        userIdNumber: formData.idNo,
        firstName: formData.firstName,
        secondName:formData.lastName,
        userName: formData.userName,
        allowedReceiptReprinting:formData.allowedReceiptReprinting

       // storeId: prevStoreId,
       // branchId: branchId,
       // assignStoreId: formData.outlet
    }
    //update terminal user details

    function updateDetails() {
        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/v1/EditZedPayItUser?userId=${props?.userid}`,
                body: formSubmit,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setSuccessShow({state:true , message:'Updated terminal user details successfully'})
                }else{
                    setErrorShow({state:true , message:"Error updating terminal user"})
                }
                setTimeout(()=>{
                    navigate(-1)
                })
            })

        } catch (error) {
            setErrorShow({state:true , message:error})
        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit User</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Edit Terminal User Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='firstName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.firstName} label="FirstName" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='lastName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.lastName} label="LastName" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='idNo' onChange={handleOnChange} style={{ width: "100%" }} value={formData.idNo} label="ID NO" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='phoneNumber' onChange={handleOnChange} style={{ width: "100%" }} value={formData.phoneNumber} label="Phone Number" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='email' onChange={handleOnChange} style={{ width: "100%" }} value={formData.email} label="Email Address" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='userType' readOnly style={{ width: "100%" }} value="payItUser" label="User Type" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }} >
                <TextField id="outlined-basic" name='userName' onChange={handleOnChange} style={{ width: "98%" }} value={formData.userName} label="userName" variant="outlined" />
            </Grid>
           
            {/* <Grid item mt={2} style={{ width: "50%" }}>
                <FormControl style={{ width: "98%" }}>
                    <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.outlet}
                        label="Outlets"
                        onChange={handleOnChange}
                        name="outlet"
                    >
                        {outlets?.map((item) => (
                            <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid> */}
             <Grid item mt={2} style={{ width: "50%",marginLeft:'10px' }}>
             <FormControlLabel
                control={
                    <Checkbox
                        name="allowReceiptPrinting" 
                        checked={formData.allowedReceiptReprinting}
                        onChange={(e) => handleOnChange({ target: { name: 'allowedReceiptReprinting', value: e.target.checked } })}
                    />
                }
                label="Allow Receipt Printing"
            />
           </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={updateDetails} style={!formData.email || !formData.firstName || !formData.userName || !formData.lastName || !formData.idNo  || !formData.phoneNumber ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Update</Button>
            </Grid>
        </Grid>
    )
}