import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, FormControlLabel, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ParentGuardianForm from "./ParentGuardianForm";
import StudentSetup from "./StudentSetUp";
import { useDispatch, useSelector } from "react-redux";
import { clearAdditions, removeFromStudentsToAdd } from "../../features/customerSlice";
import CustomTable from "./CustomTable";
import HttpComponent from "./MakeRequest";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ProfileImage from "../../common/images/profile_picture.svg";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";

import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { saveStudentDeatails } from "../../features/saveStudentDetails";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { useHistory } from 'react-router-dom';
import CustomInputLabel from "./CustomInputLabel";
import CustomSelectField from "./CustomSelectField";
import { makeStyles } from "@mui/styles";
import CustomTextField from "./CustomTextField";
let baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
});
const inputStyles = {
  width: "266px",
  height: "54px",
  margin: "12px 0 0",
  padding: "17px 15px",
  borderRadius: "4px",
  border: "solid 1px #bec5d1",
  backgroundColor: "#fff",
};
function convertDate(dateString) {
  let date = new Date(dateString);
  let options = { month: "short" };
  let day = date.getDate();
  let suffix = "th";

  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  return day + suffix + " " + date.toLocaleString("default", options) + " " + date.getFullYear();
}
const commonTextSize = {
  fontSize: "15px",
};
const AddStudentExistingParent = () => {
  const { firstNameR, lastNameR, gradeR } = useSelector((store) => store.studentdetails);
  const { customerId } = useParams();
  console.log("cus id", customerId);

  let localCurrency = localStorage.getItem('localCurrency')

  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
  }

  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);


  const classes = useStyles();
  const theColumns = { display: "flex", flexDirection: "column", justifyContent: "center" };

  const [parentData, setParentData] = useState();
  const [students, setStudents] = useState([]);
  const [checkboxData, setCheckboxData] = useState([]);
  const [StudentDetails, setStudentDetails] = useState(true);
  // const [checkboxes, setCheckboxes] = useState(true);
  const [searchServices, setSearchServices] = useState("");
  const [fields, setFields] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  // const [relationship, setRelationship] = useState("Select Relationship");
  const [studentId, setStudentId] = useState("");
  const [grade, setGrade] = useState("Select Grade");
  const [term, setTerm] = useState("Select Term");
  const [stream, setStream] = useState("Select Stream");
  const [boardingStatus, setBoardingStatus] = useState("Select Boarding Status");
  const [admissionDate, SetadmissionDate] = useState(Date.now());
  const [services, setServices] = useState([]);

  const [gender, setGender] = useState("Select Gender");

  const editServices = () => {
    // setCheckboxes(!checkboxes)
  };
  const navigate = useNavigate()
  const editDetails = () => {
    setFields(!fields);
  };
  const handleAdmissionChange = (newValue) => {
    SetadmissionDate(newValue);
  };

  useEffect(() => {
    fetchCustomerInfo();
  }, [customerId]);

  const fetchCustomerInfo = async () => {
    const productResponse = await fetch(baseUrl + `/api/get_customer_by_number/?customerId=${customerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });

    if (productResponse.status === 200) {
      const customerData = await productResponse.json();
      console.log("customer data", customerData);
      setParentData(customerData.data[0]);
    }
  };

  const changeFormState = () => {
    if (StudentDetails) {
      setStudentDetails(false);
    } else if (!StudentDetails) {
      setStudentDetails(true);
    }
  };
  const handleCheckboxChange = (event, label, amount, id) => {
    if (event.target.checked) {
      setServices([
        ...services,
        {
          name: label,
          amount: amount,
          frequency: "Monthly",
          itemNumber: studentId,
          productId: id,
        },
      ]);
    } else {
      setServices(services.filter((service) => service.name !== label));
    }
  };

  //autogenerate students admission

  const [autogenerateStudentNumberStatus, setAutogenerateStudentNumberStatus] = useState()

  // get autoGenerateStudent config
  useEffect(() => {
    async function getAutoGenerateStudentAdmConfig() {
      try {
        await HttpComponent({
          method: 'GET',
          url: `/api/v1/students/setup/getAutogenerateStudentNumberStatus`,
          token: localStorage.getItem('X-Authorization')
        }).then((data) => {
          //console.log(data , 'dataaaaaaa')
          if (data?.status === 200) {
            setAutogenerateStudentNumberStatus(data?.response?.data?.autogenerateStudentNumber)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    getAutoGenerateStudentAdmConfig()
  }, [])

  // console.log(services, "Services here");

  const fetchProducts = async () => {
    const customerDetailsResponse = await fetch(baseUrl + `/api/listProducts/Active?search`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse.status === 200) {
      const customerDetailsData = await customerDetailsResponse.json();
      //console.log("init", customerDetailsData.data);
      setCheckboxData(customerDetailsData.data);
    }
  };

  const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
  const handleSuccessNotificationClick = () => {
    setSuccessNotificationOpen(true);
  };

  const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };



  useEffect(() => {
    fetchProducts();
  }, []);
  const dispatch = useDispatch();
  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const handleNextAutogenerateStudentNumber = async () => {
    try {
      // save the data to redux
      const newObj = {
        firstName: firstName,
        lastName: lastName,
        additionalInfo: additionalInfo,
        studentId: studentId,
        grade: grade,
        stream: stream,
        boardingStatus: boardingStatus,
        term: term,
        admissionDate: admissionDate,
        gender: gender,
        customerId: customerId,
      };
      dispatch(saveStudentDeatails(newObj));
      // changeFormState();
      newFunction();
    } catch (error) {
      setErrorShow({
        state: true,
        message: `Unexpected error happend ${error}`,
      });
    }

  }
  const checkIfStudentExist = async () => {
    if (studentId === "") {
      setErrorShow({
        state: true,
        message: "Student  Admission Number is required",
      });
    } else if (grade === "Select Grade") {
      setErrorShow({
        state: true,
        message: "Grade is required",
      });
    } else if (term === "Select Term") {
      setErrorShow({
        state: true,
        message: "Select the term kindly",
      });
    } else if (gender === "Select Gender") {
      setErrorShow({
        state: true,
        message: "Select Gender kindly",
      });
    } else if (firstName === "") {
      setErrorShow({
        state: true,
        message: "firstName is  required  kindly",
      });
    } else if (lastName === "") {
      setErrorShow({
        state: true,
        message: "lastName is  required  kindly",
      });
    } else {
      HttpComponent({
        method: "GET",
        url: `/api/student_id_exists?studentNumber=${studentId}`,
        body: null,
        token: X_Authorization,
      })
        .then((data) => {
          console.log("here store is data", data);
          if (data.status === 201) {
            if (data.response.data.studentExists) {
              setErrorShow({
                state: true,
                message: "Student with this Admission Number exists",
              });
            } else if (!data.response.data.studentExists) {
              try {
                // save the data to redux
                const newObj = {
                  firstName: firstName,
                  lastName: lastName,
                  additionalInfo: additionalInfo,
                  studentId: studentId,
                  grade: grade,
                  stream: stream,
                  boardingStatus: boardingStatus,
                  term: term,
                  admissionDate: admissionDate,
                  gender: gender,
                  customerId: customerId,
                };
                dispatch(saveStudentDeatails(newObj));
                // changeFormState();
                newFunction();
              } catch (error) {
                setErrorShow({
                  state: true,
                  message: `Unexpected error happend ${error}`,
                });
              }
            }
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const newFunction = async () => {
    try {


      const addResponse = await fetch(`${baseUrl}/api/add_billable_item`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          amountReceived: 0,
          billableItems: [
            {
              additionalInfo: additionalInfo,
              billingFrequency: "ThreeMonths",
              dateOfAdmission: admissionDate,
              dateOfBirth: admissionDate,
              firstName: firstName,
              grade: grade,
              stream: stream,
              boardingStatus: boardingStatus,
              itemNumber: studentId,
              lastName: lastName,
              relationShip: "Parent",
              role: "Parent",
              term: term,
            },
          ],
          businessId: "",
          customerAddress: "",
          customerId: customerId,
          customerType: "Individual",
          email: "",
          extraServices: [],
          paymentType: "Prepaid",
        }),
      });
      await addResponse.json().then((data) => {
        // console.log('data ====================>', data)
        if (data.Status === 'SUCCESS') {
          handleSuccessNotificationClick();
          setTimeout((data) => {
            navigate('/school/parents')
          }, 1000)

        }
      })


    } catch (err) {
      setErrorShow({
        state: true,
        message: `Unexpected error happend ${err}`,
      });

    }
  }

  const submitDataTODb = async () => {
    await checkIfStudentExist();
  };
  const [gradeOptions, setGradeOptions] = useState([])
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const [schoolTypeId, setSchoolTypeId] = useState('')
  const getSchoolTypeId = () => {
    HttpComponent({
      method: 'GET',
      url: "/api/get_school_type",
      body: null,
      token: X_Authorization
    }).then((data) => {
      console.log("get_school_type_id", data);
      if (data.status === 200) {
        setSchoolTypeId(data?.response?.data?._id)
      } else {
        console.error("Error setting info")
        setErrorShow({ state: true, message: data.response.message })
      }
    }
    ).catch((error) => {
      console.error(error.message);
    }
    )
  }
  useEffect(() => {
    getSchoolTypeId()
  }, []);
  const GetSchoolTypeIdForBusiness = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      }
    });
    const data = await response.json();
    // console.log(data, 'School Type Id');
    if (response.status === 201) {
      setSchoolTypeId(data.data?._id);

    }
  }
  useEffect(() => {
    GetSchoolTypeIdForBusiness();
  }, [])

  //console.log('School Type Id', schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    //console.log(data, "Grades");

    setGradeOptions(data.data.map((itemGrade) => {
      return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
    }
    ))
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    //console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    //console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      //console.log("here Boarding Status", data);
      setBoardingStatusOptions(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      //console.error("Error setting info")
      setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])
  return (
    <>
      <Grid container marginBottom="10px">
        <Grid item style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
            <div style={{ fontSize: "25px", fontWeight: "600", color: "#dc3545" }}>Parent/Guardian Profile</div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: "150px",
                width: "95%",
                boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)",
                borderRadius: "15px",
                marginTop: "3%",
                padding: "20px",
              }}
            >
              <div style={theColumns}>
                <img
                  src={ProfileImage}
                  alt="Profile"
                  style={{
                    height: "110px",
                    width: "110px",
                  }}
                />
              </div>
              <div style={theColumns}>
                <div style={{ color: "#032541", fontSize: "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem", }} >
                  {parentData?.firstName} {parentData?.lastName}
                </div>
                <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                  Added {convertDate(parentData?.createdAt)}
                </div>
              </div>
              <div style={theColumns}>
                <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                  <span style={{ fontWeight: "bold", color: "#032541" }}>Address: </span>
                  <span style={{ color: "#666f76" }}>{parentData?.country}</span>
                </div>
                <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                  <span style={{ fontWeight: "bold", color: "#032541" }}>No. Of Students: </span>
                  <span style={{ color: "#666f76" }}>{students?.length}</span>
                </div>
              </div>
              <div style={theColumns}>
                <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                  <span style={{ fontWeight: "bold", color: "#032541" }}>Email: </span>
                  <span style={{ color: "#666f76" }}>{parentData?.email}</span>
                </div>
                <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                  <span style={{ fontWeight: "bold", color: "#032541" }}>Mobile Number: </span>
                  <span style={{ color: "#666f76" }}>{parentData?.phone}</span>
                </div>
              </div>
              <div style={theColumns}>
                <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                  <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Invoices: </span>
                  <span style={{ color: "#666f76" }}>{parentData?.pendingInvoiceCount}</span>
                </div>
                <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                  <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Balance: </span>
                  <span style={{ color: "#666f76" }}>{localCurrency} {parentData?.pendingAmount}</span>
                </div>
              </div>
            </div>
          </div>
          <Grid item display={'flex'} width={'100%'} pl={5} pr={5}>
            <Grid container display={'flex'} width={'100%'}>
              {StudentDetails ? (
                <Grid item display={'flex'} width={'100%'}>
                  <Grid container display={'flex'} width={'100%'}>
                    <Grid item display={'flex'} width={'100%'}>
                      <form action="" method="post" style={{ width: '100%' }}>
                        <Grid container>
                          <Grid item mt={2}>
                            <Typography>Student Details</Typography>
                          </Grid>
                        </Grid>
                        <Grid mt={2} item display={'flex'} width={'100%'}>
                          <Grid container display={'flex'} alignItems={'center'} spacing={5} justifyContent={'space-between'}>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={'Admission Number'} />
                              <TextField value={studentId} onChange={(e) => setStudentId(e.target.value)} name={'studentId'} style={{ width: '100%' }} variant="outlined" />
                            </Grid>
                            <Grid item width={'50%'}>
                              <InputLabel required>Admission Date</InputLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  value={admissionDate}
                                  required
                                  onChange={handleAdmissionChange}
                                  renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item mt={2} display={'flex'} width={'100%'}>
                          <Grid container display={'flex'} alignItems={'center'} spacing={5} justifyContent={'space-between'}>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={'First Name'} />
                              <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} name={'firstName'} style={{ width: '100%' }} variant="outlined" />
                            </Grid>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={'Last Name'} />
                              <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} name={'lastName'} style={{ width: '100%' }} variant="outlined" />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item display={'flex'} mt={2} width={'100%'}>
                          <Grid container display={'flex'} alignItems={'center'} spacing={5} justifyContent={'space-between'}>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={'Grade'} />
                              <Select value={grade} onChange={(e) => setGrade(e.target.value)} name={"grades"} placeholder={"Select Grade"} style={{ width: "100%" }}>
                                <MenuItem value={"Select Grade"} disabled>Select Grade</MenuItem>
                                {gradeOptions.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={"Term"} />
                              <Select value={term} onChange={(e) => setTerm(e.target.value)} name={"terms"} placeholder={"Select Term"} style={{ width: "100%" }}>
                                <MenuItem value={"Select Term"} disabled>Select Term</MenuItem>
                                {termOptions.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item display={'flex'} mt={2} width={'100%'}>
                          <Grid container display={'flex'} alignItems={'center'} spacing={5} justifyContent={'space-between'}>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={"Stream"} />
                              <Select value={stream} onChange={(e) => setStream(e.target.value)} name={"streams"} placeholder={"Select Stream"} style={{ width: "100%", marginTop: "14px" }}>
                                <MenuItem value={"Select Stream"} disabled>Select Stream</MenuItem>
                                {streamsOptions.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={'Gender'} />
                              <Select
                                defaultValue="Select Gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                style={{ width: "100%", marginTop: "14px" }}
                              >
                                <MenuItem value={"Select Gender"} disabled>
                                  Select Gender
                                </MenuItem>
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item display={'flex'} mt={2} width={'100%'}>
                          <Grid container display={'flex'} alignItems={'center'} spacing={5} justifyContent={'space-between'}>
                            <Grid item width={'50%'}>
                              <CustomInputLabel required={true} label={"Boarding Status"} />
                              <Select defaultValue={"Select Boarding Status"} value={boardingStatus} onChange={(e) => setBoardingStatus(e.target.value)} style={{ height: "57px", border: "solid 1px #bec5d1", backgroundColor: "#fff", width: "100%" }} className="form-control">
                                <MenuItem value={"Select Boarding Status"} disabled>
                                  Select Boarding Status
                                </MenuItem>
                                {boardingStatusOptions.map((item) => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item width={'50%'}>
                              <CustomInputLabel label={'Additional Info'} required={true} />
                              <TextField value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)} name={'additionalInfo'}  style={{ width: '100%' }} variant="outlined" />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid mt={2} item display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
                          <Button
                            sx={{
                              backgroundColor: "#E43845",
                              width: "140px",
                              height:"48px",
                              color: "white",
                              "&:hover": { backgroundColor: "#17ae7b", color: "white" },
                            }}
                          >
                            Cancel
                          </Button>
                          {autogenerateStudentNumberStatus === true && !studentId ? <Button onClick={handleNextAutogenerateStudentNumber} sx={{
                            backgroundColor: "#032541",
                            width: "140px",
                            height:"48px",
                            color: "white",
                            "&:hover": { backgroundColor: "#17ae7b", color: "white" },
                          }}>Next</Button> :
                            <Button
                              sx={{
                                backgroundColor: "#032541",
                                width: "140px",
                                height:'48px',
                                color: "white",
                                "&:hover": { backgroundColor: "#17ae7b", color: "white" },
                              }}
                              onClick={() => { submitDataTODb() }}
                            >
                              Next
                            </Button>}
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item>
                    <Paper
                      component="form"
                      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "530px", height: "50px" }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="search services"
                        inputProps={{ "aria-label": "search services" }}
                        onChange={(e) => setSearchServices(e.target.value)}
                      />
                      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                    <Button
                      style={{
                        width: "200px",
                        height: "40px",
                        margin: "5px ",
                        padding: "10.4px 13px 9.6px",
                        borderRadius: "4px",
                        backgroundColor: "#f5f6f7",
                      }}
                    >
                      Create Service
                    </Button>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        maxHeight: "400px",
                        overflowY: "scroll",
                        marginTop: "10px",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#999 #f5f5f5",
                        "::-webkitScrollbar": { width: "8px", height: "8px" },
                        "::-WebkitScrollbarThumb": { backgroundColor: "#000", borderRadius: "5px" },
                        "::-webkitScrollbarTrack": { backgroundColor: "#0000" },
                        "::-webkitScrollbarThumb:hover": { backgroundColor: "#666" },
                      }}
                    >
                      {checkboxData
                        .filter((item) => {
                          return searchServices?.toLowerCase() === ""
                            ? item
                            : item?.productName?.toLowerCase().includes(searchServices?.toLowerCase());
                        })
                        .map((data) => (
                          <div
                            key={data._id}
                            style={{ display: "flex", justifyContent: "space-between", paddingTop: "20px" }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(event) =>
                                    handleCheckboxChange(event, data.productName, data.productPrice, data._id)
                                  }
                                />
                              }
                              label={data.productName}
                            />
                            <TextField
                              id="outlined-basic"
                              required
                              label="Amount"
                              defaultValue={numberFormat(data.productPrice)}
                              disabled={true}
                              variant="outlined"
                              style={{ width: "60%", height: "2.188rem" }}
                            />
                          </div>
                        ))}
                    </div>
                  </Grid>
                  <div className="d-flex justify-content-space-between">
                    <Button
                      sx={{
                        backgroundColor: "#032541",
                        width: "140px",
                        color: "white",
                        "&:hover": { backgroundColor: "#17ae7b", color: "white" },
                      }}
                      className="ml-3"
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              )}

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={() => setErrorShow({ ...errorShow, state: false })}
        open={errorShow.state}
        message={errorShow.message}
      />
      <SuccessAlert message={'Student successfull added to parent'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />

    </>
  );
};

export default AddStudentExistingParent;
