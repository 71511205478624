import { MenuItem, styled, alpha, Menu, Modal, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Delete, Edit } from "@mui/icons-material";
import suspendcustomer from "../../../common/images/suspendedcustomer.svg";
import creditAcc from "../../../common/images/creditaccount.svg";
import CreditImg from '../../../images/credit.svg'
import powericon from "../../../common/images/warning.svg";
import approveTick from "../../../common/images/approvetick.svg";
import { customerstyles } from "./styles";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const Menudropdown = (props) => {
  const MenuoptionOpen = props.MenuoptionOpen;
  const handleActionClose = props.handleActionClose;
  const anchorEl = props.anchorEl;
  const userParams = props.userParams;
  const getuserInfoForEdit = props.getuserInfoForEdit;
  const getCustomerDetailsAndSuspend = props.getCustomerDetailsAndSuspend;

  // approve user;
  const getUserInfoAndApprove = props.getUserInfoAndApprove;
  const customerApproval = props.customerApproval;
  const approveParams = props.approveParams;

  // delete user;
  const getUserDetailsAndDeleteAcc = props.getUserDetailsAndDeleteAcc;

  // credit customer;
  const creditcustomer = props?.creditcustomer;
  return (
    <div>
      {/* POP UP SUSPEND AND CREDIT */}
      <StyledMenu id="demo-customized-menu" aria-labelledby="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} open={MenuoptionOpen} anchorEl={anchorEl} onClose={handleActionClose}>
        {!customerApproval ? (
          <div>
            <MenuItem disableRipple onClick={() => getuserInfoForEdit(userParams.row.id)}>
              <span style={customerstyles.menuItemsHolder}>
                {" "}
                <Edit /> Edit{" "}
              </span>
            </MenuItem>
            <MenuItem disableRipple onClick={() => getCustomerDetailsAndSuspend(userParams.row.id)}>
              <span style={customerstyles.menuItemsHolder}>
                {" "}
                <img src={suspendcustomer} style={{ margin: "0px 8px 0px 0px" }} />
                suspend{" "}
              </span>
            </MenuItem>

            {userParams?.row?.paymentType !== "Normal" ? (
              <MenuItem disableRipple onClick={ () =>  creditcustomer(userParams)} >
                <span style={customerstyles.menuItemsHolder}>
                  {" "}
                  <img src={CreditImg} alt="" style={{ margin: "0px 8px 0px 0px" }} /> Credit Acc{" "}
                </span>
              </MenuItem>
            ) : null}
          </div>
        ) : null}

        {customerApproval ? (
          <div>
            <MenuItem disableRipple onClick={() => getUserDetailsAndDeleteAcc(approveParams)}>
              <span style={customerstyles.menuItemsHolder}>
                {" "}
                <Delete style={{ color: "red" }} /> Delete
              </span>
            </MenuItem>
            <MenuItem disableRipple onClick={() => getUserInfoAndApprove(approveParams)}>
              <span style={customerstyles.menuItemsHolder}>
                {" "}
                <img src={approveTick} alt="" style={{ margin: "0px 8px 0px 0px" }} /> Approve
              </span>
            </MenuItem>
          </div>
        ) : null}
      </StyledMenu>
    </div>
  );
};

export const SuspendCustomerModal = (props) => {
  const openSuspendModal = props?.openSuspendModal;
  const setSuspendModal = props?.setSuspendModal;
  const suspendCustomer = props?.suspendCustomer;
  const activateCustomer = props?.activateCustomer;
  const activateCustomers = props?.activateCustomers;

  // approve customer;
  const approvingProcess = props.approvingProcess;
  const approveCustomer = props.approveCustomer;

  //  dissaprove;
  const dissapprovingStarts = props.dissapprovingStarts;
  const dissApproveCustomer = props.dissApproveCustomer;

  return (
    <Modal open={openSuspendModal} style={customerstyles.universalFont}>
      <div style={customerstyles.deactivateSupplierModal}>
        <div style={customerstyles.customerIcon}>
          <div style={customerstyles.divwithImg}>
            <img style={customerstyles.customerImg} src={powericon} alt="customer" />
          </div>

          <div>
            <p style={customerstyles.customerText}> {activateCustomer ? "Activate Account?" : approvingProcess ? "Approve Account" : dissapprovingStarts ? "Delete Account?" : null} </p>
            <p style={customerstyles.customerPara}> {activateCustomer ? "Are you sure you want to approve" : approvingProcess ? "Are you sure you want to approve" : dissapprovingStarts ? "Are you sure you want to delete" : "Selected user will be suspended and no longer have access to the business"} </p>

            <p style={customerstyles.paraWithDisplayFlex}>
              <Button style={customerstyles.cancelButton} onClick={() => setSuspendModal(false)}>
                Cancel
              </Button>

              {(!approvingProcess && !activateCustomer) || dissapprovingStarts ? (
                <Button style={customerstyles.deactivateButton} onClick={() => (dissapprovingStarts ? dissApproveCustomer() : suspendCustomer())}>
                  {dissapprovingStarts ? "Delete" : "Suspend"}
                </Button>
              ) : null}

              {activateCustomer || approvingProcess ? (
                <Button style={customerstyles.activateButton} onClick={() => (activateCustomer ? activateCustomers() : approvingProcess ? approveCustomer() : null)}>
                  {activateCustomer ? "Activate" : "Approve"}
                </Button>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Activatecustomermodal = (props) => {};
export const SusPendCus = (props) => {

  const openSuspendModal = props?.openSuspendModal;
  const setSuspendModal = props?.setSuspendModal;
  const suspendCustomer = props?.suspendCustomer;
  const activateCustomer = props?.activateCustomer;
  const activateCustomers = props?.activateCustomers;

  // approve customer;
  const approvingProcess = props.approvingProcess;
  const approveCustomer = props.approveCustomer;

  //  dissaprove;
  const dissapprovingStarts = props.dissapprovingStarts;
  const dissApproveCustomer = props.dissApproveCustomer;

  return (
    <div>
      <Dialog
        open={openSuspendModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            height: "300px",
            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
          },
        }}

        BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.9px)", } }}

        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{display:"flex" , justifyContent:"center" , alignContent:"center"}}>
           <img style={customerstyles.customerImg} src={powericon} alt="customer" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           <p> {activateCustomer ? "Activate Account?" : approvingProcess ? "Approve Account" : dissapprovingStarts ? "Delete Account?" : null} </p>
            <p> {activateCustomer ? "Are you sure you want to approve" : approvingProcess ? "Are you sure you want to approve" : dissapprovingStarts ? "Are you sure you want to delete" : "Selected user will be suspended and no longer have access to the business"} </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{marginBottom:"10px"}}>
          <Button style={{...customerstyles.cancelButton , width:"150px"}} onClick={() => setSuspendModal(false)}>
            Cancel
          </Button>

          {(!approvingProcess && !activateCustomer) || dissapprovingStarts ? (
            <Button style={{...customerstyles.deactivateButton ,width:"150px"}} onClick={() => (dissapprovingStarts ? dissApproveCustomer() : suspendCustomer())}>
              {dissapprovingStarts ? "Delete" : "Suspend"}
            </Button>
          ) : null}

          {activateCustomer || approvingProcess ? (
            <Button style={{...customerstyles.activateButton , width:"150px"}} onClick={() => (activateCustomer ? activateCustomers() : approvingProcess ? approveCustomer() : null)}>
              {activateCustomer ? "Activate" : "Approve"}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

    </div>
  )
}

