import { ArrowBackIos, FiberManualRecord } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import EditPocketMoneyConfig from "./PocketMoneyFee/EditPocketMoneyFee";
import { useNavigate } from "react-router-dom";
import ZpmMasterAccountSetUp from "./zpm/zpmMasterAccountSet";
import ZPMEditCardAccount from "./zpm/zpmeditCardAccount";

const EditZpmConfigs = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    <Typography onClick={() => navigate("/adminDashboard")} key={1}>Dashboard</Typography>,
    <Typography onClick={() => navigate(-1)} key={2}>Fee Configs</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontFamily: "Poppins" }}>
      Edit Configs
    </Typography>,
  ];

  //   configurations;
  const configurations = ["ZPM Activation Fee", "ZPM Card Account Config", "Zed Master Account"];
  const [mountColor, setMountColor] = useState("ZPM Activation Fee");

      //get params from url

      const queryParams = decodeURIComponent(window.location.search);

      const newqueryParamValue = queryParams.slice(1);
  
      const [queryParamValue, businessNumber, businessName] = newqueryParamValue.split("?")
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Grid container direction={"column"}>
        <Grid item mb={2.5} display={"flex"} alignItems={"center"} gap={"20px"} sx={{ cursor: "pointer" }}>
          <ArrowBackIos onClick={() => navigate(-1)} />
          <Typography style={{color:"032541", fontSize:"25px", fontWeight: 600}} variant="h5">Edit Configs</Typography>
        </Grid>
        <Grid item mb={2.5}>
          <Breadcrumbs style={{ fontFamily: "Poppins", fontSize: "14px" }} aria-label="breadcrumb" separator={<FiberManualRecord style={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>

        <Grid item mb={2.5}>
          <Typography style={{color:"#032541", fontWeight: 600, fontSize:"25px"}} variant="h5"> {businessName} </Typography>
        </Grid>

        <Grid item mb={2.5}>
          <Typography style={{color:"#707070", fontSize:"14px"}} variant="h5"> {"Customize Configuration for this business"} </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} lg={4}>
          {configurations?.map((config) => {
            return (
              <Box mb={2.5} onClick={() => setMountColor(config)} sx={{ cursor: "pointer" }} style={mountColor === config ? { width: "265px", padding: "10px", backgroundColor: "black", color: "white", textAlign: "left" } : { width: "250px", padding: "10px", textAlign: "left", color:"#909090" }}>
                <Typography sx={{fontSize:"14px" }} > 
                    {config} 
                 </Typography>
              </Box>
            );
          })}
        </Grid>

        <Grid item xs={12} sm={6} lg={8}>
          {mountColor === "ZPM Activation Fee" && (
            <Paper sx={{ padding: "20px" }}>
              <EditPocketMoneyConfig />
            </Paper>
          )}
          {mountColor === "ZPM Card Account Config" && (
            <Paper sx={{ padding: "20px" }}>
              <ZPMEditCardAccount />
            </Paper>
          )}

          {mountColor === "Zed Master Account" && (
            <Paper sx={{ padding: "20px" }}>
              <ZpmMasterAccountSetUp />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default EditZpmConfigs;
