import { useState, useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HttpComponent from "../../components/School/MakeRequest";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function CustomerOnAccount(props) {
	const classes = useStyles();
	let businessCat = localStorage.getItem("businessCategory");
	const [open, setOpen] = React.useState(false);
	const location = useLocation()

	//set active link

	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"


	const handleClick = () => { setOpen(!open); };
	const userGroup = localStorage.getItem('group')
	const IsStoreManager = userGroup && userGroup?.toLocaleLowerCase()?.includes("storemanager")
	const isSupervisor = userGroup && userGroup === "Supervisor"
	const isZedPmAdmin = userGroup && userGroup === "ZPM School Admin"

	// const [schoolTypeName, setSchoolTypeName] = useState('')
	// const { userId, X_Authorization } = useSelector((store) => store.user);

	// const getSchoolTypeId = () => {
	// 	HttpComponent({
	// 		method: 'GET',
	// 		url: "/api/get_school_type_by_businesss",
	// 		body: null,
	// 		token: X_Authorization
	// 	}).then((data) => {
	// 		console.log("get_school_type_id", data?.response?.data?.schoolType);
	// 		if (data.status === 200) {
	// 			setSchoolTypeId(data?.response?.data?._id)
	// 		} else {
	// 			console.error("Error setting info")
	// 			setErrorShow({ state: true, message: data.response.message })
	// 		}
	// 	}
	// 	).catch((error) => {
	// 		console.error(error.message);
	// 	}
	// 	)
	// }
	// useEffect(() => {
	// 	getSchoolTypeId()
	// }, []);
	 const {schoolTypeName, schoolTypeId} = useSelector((store) => store.schoolType.schoolTypeDetail)




	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
				<div style={{ marginRight: '5%' }}>
					<AccountCircleIcon />
				</div>
				{businessCat === "School" ?
					<ListItemText primary= {schoolTypeName?.includes("University") ? "Students Accounts" : "Parents Accounts"} style={{ fontFamily: 'Poppins' }} />
					: businessCat === "Rental" ? <ListItemText primary="Tenants Accounts" style={{ fontFamily: 'Poppins' }} /> : <ListItemText primary="Customer Accounts" style={{ fontFamily: 'Poppins' }} />}
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
				<div style={{ marginLeft: '8%' }}>
					{(userGroup === 'Admin' || userGroup == 'Merchant' || userGroup === "Owner"  || IsStoreManager || isSupervisor) && businessCat !== 'Rental' && businessCat !== "School" ?
						<div>
							<Link style={{ textDecoration: 'none', color: isActiveLink("/customers") ? activeColor : '#fff' }} to="/customers">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Customers" />
								</ListItem>
							</Link>
							<Link style={{ textDecoration: 'none', color: isActiveLink("/allcustomerstransaction") ? activeColor : '#fff'}} to="/allcustomerstransaction">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Customer Activity" />
								</ListItem>
							</Link>

							<Link style={{ textDecoration: 'none', color: isActiveLink("/supplierlist") ? activeColor : '#fff'}} to="/supplierlist">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Suppliers" />
								</ListItem>
							</Link>

							<Link style={{ textDecoration: 'none', color: isActiveLink("/businessinvoice") ? activeColor : '#fff'}} to="/businessinvoice">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Invoices" />
								</ListItem>
							</Link>

							<Link style={{ textDecoration: 'none', color: isActiveLink("/customerreceipts") ? activeColor : '#fff' }} to="/customerreceipts">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Receipts" />
								</ListItem>
							</Link>
						</div> :
						(userGroup == 'Merchant' && businessCat == 'Rental' || userGroup === "Caretaker") || (userGroup == 'Owner' && businessCat == 'Rental') ?
							<div>
								<Link style={{ textDecoration: 'none',color: isActiveLink("/tenants") ? activeColor : '#fff'}} to="/tenants">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Tenants" />
									</ListItem>
								</Link>

								<Link style={{ textDecoration: 'none', color: isActiveLink("/tenantInvoices") ? activeColor : '#fff', }} to="/tenantInvoices">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Invoices" />
									</ListItem>
								</Link>

								<Link style={{ textDecoration: 'none',  color: isActiveLink("/tenantReceipts") ? activeColor : '#fff', }} to="/tenantReceipts">
									<ListItem button className={classes.nested}>
										<ListItemText primary="Receipts" />
									</ListItem>
								</Link>




							</div> :
							userGroup == 'Merchant' || userGroup ==='Director' || isZedPmAdmin || userGroup === 'Accountant'  && businessCat == 'School' ?
								<div>
									{schoolTypeName?.includes("University") ? null :
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/parents") ? activeColor : '#fff' }} to="/school/parents">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Parents/Guardians" />
										</ListItem>
									</Link>
									 }
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/students") ? activeColor : '#fff'  }} to="/school/students">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Students" />
										</ListItem>
									</Link>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/invoices/batch") ? activeColor : '#fff' }} to="/school/invoices/batch">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Invoices" />
										</ListItem>
									</Link>
									<Link style={{ color: isActiveLink("/receipts") ? activeColor : '#fff', textDecoration: 'none' }} to="/receipts">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Receipts" />
										</ListItem>
									</Link>
								</div>
								: null
					}
					</div>
				</List>
			</Collapse>
		</div>
	)
}
