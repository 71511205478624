import { Box, Button, Modal, Typography } from "@mui/material";
import warningIcon from "../../../../../src/common/images/deactivate.svg";
import restoreIcon from "../../../../../src/common/images/restore-icn.svg"

export const FeeInActivationModal = ({ open, handleClose, closeMoreVert, deactivateConfig }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div
        style={{
          width: "30%",
          height: "30%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "50px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "50px" }}>
          <Box>
            <img style={{ height: "100px", width: "100px", objectFit: "cover" }} src={warningIcon} alt="warningsvg" />
          </Box>

          <Box>
            <Box sx={{ margin: "0px 0px 20px 0px" }}>
              <Typography style={{ color: "#032541", fontSize: "16px", fontWeight: 600 }}>Deactivate Merchant Transactions Fee Config ?</Typography>
            </Box>

            <Box sx={{ margin: "0px 0px 30px 0px" }}>
              <Typography style={{ color: "#707070", fontSize: "14px", margin: "0px 0px 10px 0px" }}>Selected Merchant Transaction configuration will be deactivated This action can not be undone</Typography>
            </Box>

            <Box sx={{ display: "flex", gap: "20px" }}>
              <Button
                onClick={() => {
                  handleClose();
                  closeMoreVert();
                }}
                style={{ border: "1px solid #dc3545", color: " #dc3545", padding: "10px 50px 10px 50px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  closeMoreVert();
                  deactivateConfig();
                }}
                style={{ backgroundColor: "#032541", color: "white", padding: "10px 50px 10px 50px" }}
              >
                Deactivate
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export const FeeActivationModal = ({open , handleClose, closeMoreVert, deactivateConfig}) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div
        style={{
          width: "30%",
          height: "30%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "50px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "50px" }}>
          <Box>
            <img style={{ height: "100px", width: "100px", objectFit: "cover" }} src={restoreIcon} alt="restoresvg" />
          </Box>

          <Box>
            <Box sx={{ margin: "0px 0px 20px 0px" }}>
              <Typography style={{ color: "#032541", fontSize: "16px", fontWeight: 600 }}>Deactivate Merchant Transactions Fee Config ?</Typography>
            </Box>

            <Box sx={{ margin: "0px 0px 30px 0px" }}>
              <Typography style={{ color: "#707070", fontSize: "14px", margin: "0px 0px 10px 0px" }}>Selected Merchant Transaction configuration will be deactivated This action can not be undone</Typography>
            </Box>

            <Box sx={{ display: "flex", gap: "20px" }}>
              <Button
                onClick={() => {
                  handleClose();
                  closeMoreVert();
                }}
                style={{ border: "1px solid #dc3545", color: " #dc3545", padding: "10px 50px 10px 50px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                    closeMoreVert();
                  deactivateConfig();
                }}
                style={{ backgroundColor: "#17ae7b", color: "white", padding: "10px 50px 10px 50px" }}
              >
                Reactivate
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};
