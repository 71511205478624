import { Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import axios from "axios";
import cardBG from "../../../common/images/zedPayitBG.png";

const baseUrl = process.env.REACT_APP_BASE_URL;

function ZedPayit() {
  const [walletTotal, setWalletTotal] = useState(null);
  const [cardTotal, setCardTotal] = useState(null);

  let partnerName = localStorage.getItem("affiliatedPartner");

  const fetchWalletTransactions = async () => {
    const response = await axios.post(
      baseUrl + "/api/fetchWalletTransactions",
      {
        partnerName,
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    let result = response.data.walletTotal;
    if (result) {
      setWalletTotal(response.data.walletTotal);
    } else {
      setWalletTotal(0);
    }
  };

  const fetchCardTransactions = async () => {
    const response = await axios.post(
      baseUrl + "/api/fetchCardTransactions",
      {
        partnerName,
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    let result = response.data.cardTotal;
    if (result) {
      setCardTotal(response.data.cardTotal);
    } else {
      setCardTotal(0);
    }
  };

  useEffect(() => {
    fetchWalletTransactions();
    fetchCardTransactions();
  }, []);

  return (
    <Stack
      width={"100%"}
      height="166px"
      justifyContent={"space-between"}
      p={2}
      borderRadius={"12px"}
      boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
      bgcolor={"white"}
      style={{
        backgroundImage: `url(${cardBG})`,
        backgroundSize: "70% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={"16px"}
          fontWeight={500}
          fontStretch={"normal"}
          fontStyle={"normal"}
          lineHeight={1.31}
          letterSpacing={"normal"}
          textAlign={"left"}
          color={"#032541"}
        >
          Zed Pocket Money Transactions
        </Typography>
        <AutoGraphIcon sx={{ color: "#032541" }} />
      </Stack>
      <Stack width="100%">
        <Typography
          fontFamily="Poppins"
          fontSize={18}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {cardTotal || cardTotal === 0 ? (
            walletTotal + cardTotal
          ) : (
            //  <CircularProgress />
            <Skeleton
              variant="text"
              fontSize={18}
              width={"30%"}
              sx={{ bgcolor: "#f0f0f0" }}
              animation="wave"
            />
          ) }
        </Typography>
      </Stack>
      <Stack width={"100%"} direction={"row"} spacing={15}>
        {/* <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Cash
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="center"
            color="#707070"
          >
            1300
          </Typography>
        </Stack> */}
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Card
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            {cardTotal || cardTotal === 0 ? (
              cardTotal
            ) : (
              //  <CircularProgress />
              <Skeleton
                variant="text"
                fontSize={18}
                sx={{ bgcolor: "#f0f0f0" }}
                animation="wave"
              />
            )}
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Zed-Pocket Money
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            {/* 1300 */}
            {walletTotal || walletTotal === 0 ? (
              walletTotal
            ) : (
              <Skeleton
                variant="text"
                fontSize={18}
                sx={{ bgcolor: "#f0f0f0" }}
                animation="wave"
              />
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ZedPayit;
