import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ZedIcon from './zed.svg';
import iconEcommerce from "./icon.svg"
const baseUrl = process.env.REACT_APP_BASE_URL;

const ShopifyComponent = () => {
    const location = useLocation();
    console.log(location.pathname, 'location');
    // console all the params from the location object
    const searchParams = new URLSearchParams(location.search);


    // https://dev.zed.business/shopify_callback?code=07a3f2b946fb8030d057bd600aa816f0&hmac=255b870d6010696ae181b9e6f0296c278c5e87d9221a512f18f1e9954fe564d0&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvYWJ1emVkc2hvcA&shop=abuzedshop.myshopify.com&state=171145730950400&timestamp=17114573217
    let code = searchParams.get('code');
    let hmac = searchParams.get('hmac');
    let host = searchParams.get('host');
    let shop = searchParams.get('shop');
    let state = searchParams.get('state');
    let timestamp = searchParams.get('timestamp');
    const [shopUrl, setShopUrl] = useState('');
    console.log(code, hmac, host, shop, state, timestamp, 'searchParams');
    const postZedShopifyCallBack = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/v1/shopify_switch_callback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: code,
                    hmac: hmac,
                    host: host,
                    shop: shop,
                    state: state,
                    timestamp: timestamp,
                }),
            });
            const resp = await response.json();
            console.log(resp, 'data');
            const data = resp.data.shop;
            console.log(data, 'data shop');
            let url = data.domain;
            console.log(url, 'url');
            if (url) {
                let newUrl = `https://${url}/admin`;
                // window.location.href = newUrl;
                // open in new tab
                setShopUrl(newUrl);


            } else {
                console.log('No url found');

            }


        } catch (error) {
            console.error('Error:', error);
        }
    }


    useEffect(() => {
        postZedShopifyCallBack();
    }, []);


    const proceedAdmin = () => {
        window.open(shopUrl, '_self');
       
    }


    return (
        <div>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={iconEcommerce} alt="logo" />
                    </Box>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={ZedIcon} alt="logo" />
                        <h1 style={{color:"#032541" , fontSize:"24px" , fontWeight:"600", marginTop:"20px" }}>Redirecting you to your <br /> eCommerce Admin Portal</h1>

                        <h1  style={{color:"#707070" , fontSize:"16px" , fontWeight:"normal", marginTop:"10px" }}>Please wait as we redirect you to the admin portal…</h1>
                        {shopUrl ?
                            <a href={shopUrl} rel="noreferrer" style={{textDecoration: "none", marginTop: "10px"}}>
                                <Button variant="contained" sx={{width:"166px", height:"50px", padding:"16.2px 56px 14.8px",objectFit: "contain", borderRadius:"4px", backgroundColor:"#032541"}}  onClick={() => proceedAdmin}>Proceed</Button>
                            </a>
                        : null}

                    </Box>
                </Box>




            </Box>


        </div>

    );
};

export default ShopifyComponent;