import { useState } from 'react'
import { Box, Grid, MenuItem, Typography, Button} from '@mui/material'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import CustomInputLabel from '../CustomInputLabel'
// import CustomTextField from "../CustomTextField";
import HttpComponent from "../MakeRequest";
// import CustomSelectField from "../CustomSelectField";

const EquityBankDeposit = ({showEquityBankDepositSetup}) => {
  const [branch, setBranch] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [branches, setBranches] = useState([]);

  const buttonStyles =
  {"width":"7.813rem",
      "height":"2.813rem",
      "borderRadius":"4px",
  }

  return (
    <Box sx={{ display:'flex', width:'100%', padding:'26px' }}>
      <form style={{ display:'flex', flexDirection:'column', width:'100%' }}>
          <FormControl style={{ width:'100%' }}>
            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Branch</InputLabel>
            <TextField select style={{ height: "50px", width:'100%', marginTop:'40px', borderRadius: "1px", fontFamily: "Poppins", }}
                // defaultValue={branch}
                // onChange={changePayBills}
                placeholder='Select Branch'
                multiline
                required>

                {branches?.map((item, index) => (
                    <MenuItem key={index} value={item}>{item.bankName}</MenuItem>
                ))}
            </TextField>
          </FormControl>
          <FormControl style={{ width:'100%', marginTop:'-5px' }}>
            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}>Account Name</InputLabel>
            <TextField style={{ width: "100%", marginTop: "40px" }}
                id="outlined-multiline-flexible"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                multiline
                placeholder="Account Name"
                required
            />
          </FormControl>
          <FormControl style={{ width:'100%', marginTop:'-5px' }}>
            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}>Account Number</InputLabel>
            <TextField style={{ width: "100%", marginTop: "40px" }}
                id="outlined-multiline-flexible"
                placeholder='Account Number'
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
                multiline
                required
            />
          </FormControl>
          
          <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '0px', paddingBottom: '20px'}}>
              <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end", marginTop: '30px'}}>
                  <Box sx={{ display:'flex',  }}>
                    <Button sx={{
                    ...buttonStyles,
                    border:" solid 1px #002543",
                    color:"#002543",
                    '&:hover': {
                        backgroundColor:"transparent",
                        color: '#002543'
                    }
                    }}
                    onClick={() => showEquityBankDepositSetup(false)}
                    >
                    Cancel
                    </Button>

                    <Button sx={{
                    ...buttonStyles,
                    marginLeft:1,
                    backgroundColor:" #032541",
                    color:"white",
                    '&:hover': {
                        backgroundColor: '#032541',
                        color: 'white'
                    }
                    }}
                    // onClick={savePayBillDetails}
                    >
                    Save
                    </Button>
                  </Box>
              </Box>
          </div>
          
      </form>
    </Box>
  )
}

export default EquityBankDeposit;