import { Breadcrumbs, Button, FormControl, Grid, InputLabel, OutlinedInput, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import HttpComponent from "./MakeRequest";

const baseUrl = process.env.REACT_APP_BASE_URL


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Service Activation Payment</Typography>
]


export default function ServiceActivationFeeZpm(props) {

    console.log(props, 'props')

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [startTransaction, setStartTransaction] = useState(false)
    const [count, setCount] = useState(60)
    const amount = props?.amount
    const [socketInterval, setMyInterval] = useState(null)
    const [isNavigating, setIsNavigating] = useState(false);
    const invoiceNumber = props?.invoiceNumber


    const [userPhone, setPhone] = useState('')
    // const [amount, setAmount] = useState()

    const navigate = useNavigate()




    // initiate tranactions

    function handleInitiateKCBStkPush() {
        setStartTransaction(true)
        try {
            HttpComponent({
                method: 'POST',
                url: "/api/v1/payments/initiate_kcb_stk_push",
                body: {
                    accountType: 'zpmactivationFee',
                    amount: amount,
                    phone: userPhone,
                    orderID: invoiceNumber
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data , 'response')
                if (data?.status === 200) {
                    let socketInterValId = setInterval(() => {
                        listenWebsocket(invoiceNumber)
                    }, 6000);

                   setMyInterval(socketInterValId)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        let interval;

        if (startTransaction) {
            interval = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);

    }, [startTransaction]);

    //ws://api.dev.zed.business/getWalletNotification

    // check invoice statatus

    const [invoiceBalance , setInvoiceBalance] = useState()

    const getInvoicePayments = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    'X-Authorization': localStorage.getItem('X-Authorization')
                },
            }
        );
        if (invoicePaymentDetailsResponse?.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            let { invoiceBalance } = paymentDetailsData?.data
            setInvoiceBalance(invoiceBalance);
        }
    }




    const listenWebsocket = async (invoiceNumber) => {


        let ws = ""

        if(process.env.NODE_ENV === 'development'){
            ws = new WebSocket(`wss://api.dev.zed.business/notification/${invoiceNumber}`);
       }else if(process.env.NODE_ENV === 'production'){
            ws = new WebSocket(`wss://api.portal.zed.business/notification/${invoiceNumber}`);
       }else if(process.env.NODE_ENV === 'test'){
         //zed.swerri.io
          ws = new WebSocket(`wss://zed.api.swerri.io/notification/${invoiceNumber}`);
       }

        ws.onopen = () => {
        }
        ws.onmessage = function (event) {
            const data = JSON.parse(event.data);
            //console.log(data , 'ws response')
            if (data.status === "OK") {
                const message = data.message;
                setSuccessShow({ state: true, message: message })
                setStartTransaction(false)
                setIsNavigating(true)
                ws.close()

            } else if (data.status === "FAILED") {
                const message = data?.message;
                setErrorShow({ state: true, message: message });
                setStartTransaction(false)
                setIsNavigating(true)
                ws.close()

            }
        };
        ws.onclose = function (event) {
            console.log('WebSocket connection closed with code:', event.code);
        };

        ws.onerror = function (error) {
            console.error('WebSocket error:', error);
        };

    }
    useEffect(() => {
        if (isNavigating) {
            clearInterval(socketInterval);
            getInvoicePayments()

            if(parseInt(invoiceBalance) !== parseInt(amount)){
                setSuccessShow({state:true , message:'Payement received'})
            }
            setMyInterval(null);
            setTimeout(() => {
                navigate(-1);
            }, 1500)

        }
    }, [isNavigating , amount , invoiceBalance]);

    useEffect(() => {
        if (socketInterval === null) return;

        return () => {
            clearInterval(socketInterval);
            setMyInterval(null);
        };
    }, [socketInterval]);

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Service Activation Payment</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070" }}>Kindly enter your mobile number to receive the prompt and enter you M-Pesa Pin.</Typography>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <PhoneInput inputStyle={{ height: "55px" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />
            </Grid>
            <Grid mt={2} item mb={3} xs={12} sm={12} lg={12} md={12} width={'50%'}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="outlined-adornment-password">Amount</InputLabel>
                    <OutlinedInput
                        required
                        id="outlined-adornment-password"
                        value={amount}
                        disabled
                        autoComplete="new-password"
                        // onChange={(e) => setAmount(e.target.value)}
                        label="Amount"
                    />
                </FormControl>
            </Grid>
            <Grid item alignItems={'center'} display={'flex'} justifyContent={'flex-end'} width={'50%'}>
                <Button disabled={!amount || !userPhone} onClick={handleInitiateKCBStkPush} style={startTransaction === true ? { background: "rgba(3, 37, 65, 0.4)", textTransform: "inherit", width: '171px', color: "#fff" } : { background: "#032541", textTransform: "inherit", width: '171px', color: "#fff" }}> {startTransaction === true ? 'Awaiting Payment' : 'Send Prompt'}</Button>
            </Grid>
            <Grid mt={1} item alignItems={'center'} display={'flex'} justifyContent={'flex-end'} width={'50%'}>
                <Typography>{startTransaction === true ? `'Resend Prompt': ${count}` : null}</Typography>
            </Grid>
        </Grid>
    )
}


