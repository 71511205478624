import React, { useState, useEffect } from "react";
import { DataGrid, styled } from "@mui/x-data-grid";
import { customerstyles } from "./styles";
import HttpComponent from "../../School/MakeRequest";
import { MoreVert } from "@mui/icons-material";
import { Menudropdown, SusPendCus, SuspendCustomerModal } from "./modals";
import { useNavigate } from "react-router-dom";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import DateFormatter from "../../../utils/dateFormatter";
import CurrencyFormatter from "../../../utils/currencyFormatter";

const Allcustomers = (props) => {
  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  const customerSearchValue = props?.customerSearchValue
  const navigate = useNavigate();

  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((customer) => [customer?.customerName, customer?.customerType, customer?.paymentType, DateFormatter(customer?.createdOn), CurrencyFormatter(customer?.limit, "KES")]);
    const pdfHeaders = [["Customer Name", "Customer Type", "Payment Type", "Date Created", "Amount/Limit (KES)"]];
    setExportData({ headers: pdfHeaders, data: pdfData });

    // csv exports;
    const csvData = pageState?.data?.map((customer) => {
      return {
        "Customer Name": customer?.customerName,
        "Customer Type": customer?.customerType,
        "Date Created": DateFormatter(customer?.createdOn),
        Amount: CurrencyFormatter(customer?.limit, "KES"),
      };
    });

    // csv headers;
    const csvHeaders = [
      { label: "Customer Name", key: "Customer Name" },
      { label: "Customer Type", key: "Customer Type" },
      { label: "Date Created", key: "Date Created" },
      { label: "Amount", key: "Amount" },
    ];

    setCsvExport({ headers: csvHeaders, data: csvData });
  }, [pageState.data, pageState.pageSize, dataGridPageSize]);

  const getAllCustomers = () => {
    
    HttpComponent({
      method: "GET",
      url: `/api/customers_list?page=${pageState.page}&limit=${dataGridPageSize}&status=ACTIVE`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(customerSearchValue === "")
    getAllCustomers();
  }, [pageState.page,  dataGridPageSize, customerSearchValue]);


  // move to edit user;
  const getuserInfoForEdit = (id) => {
    navigate(`/createcustomer/${id}`);
    setAnchorEl(null);
  };

  // suspend customer
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [openSuspendModal, setSuspendModal] = useState(false);
  const [suspendId, setSuspendId] = useState("");
  const [ anchorEl,setAnchorEl] = useState(null)

 


  const getCustomerDetailsAndSuspend = (params) => {
    if (params) {
      setSuspendModal(true);
      setSuspendId(params);
      setAnchorEl(null);
    }
  };

  const suspendCustomer = () => {
    HttpComponent({
      method: "GET",
      url: `/api/suspendCustomer?customerId=${suspendId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 202) {
          setSucess({ state: false, message: "User has been suspended succsfully!" });
          setSuspendModal(false);
          getAllCustomers();
        }

        setTimeout(() => {
          setSucess({ state: false });
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const creditcustomer = (param) => {
    if (param) navigate(`/creditcustomer/${param.row.id}/${param.row.paymentType}`);
  };

  // console.log('customerSearchValue ======<><> ', customerSearchValue);

  // search values

  const searchCustomer = () => {
    HttpComponent({
      method:"GET",
      url:`/api/searchCustomer?state=Active&search=${customerSearchValue}`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      
      setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
    }).catch((e) => {
      console.log(e.message);
    })
  }

  useEffect(() => {
    if(customerSearchValue)
    searchCustomer()
  },[customerSearchValue, pageState.page, dataGridPageSize])

  return (
    <div>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data?.map((customer) => ({
          id: customer?._id,
          customerName: customer?.customerName,
          customerType: customer?.customerType,
          paymentType: customer?.paymentType,
          createdOn: DateFormatter(customer?.createdOn),
          // createdOn: new Date(customer.createdOn).toDateString() + new Date(customer.createdOn) ,
          limit: customer?.paymentType === "Normal" ? "N/A" : CurrencyFormatter(customer.limit, "KES"),
        }))}
        columns={[
          {
            field: "customerName",
            headerName: "Customer Name",
            renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Customer Name</strong>)},
            renderCell: (params) => {
              return (
                <span style={{textTransform: "underline", textDecoration:"underline", cursor: "pointer", fontSize:"14px", color:"#707070" }} onClick={() => navigate(`/customerDetails/${params.row.id}`)}>
                  {params.row.customerName}
                </span>
              );
            },
            flex: 1,
          },
          { field: "customerType", headerName: "Customer Type", flex: 1 ,renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Customer Type</strong>)},renderCell:(params)=>{return( <span style={{color:"#707070" }}>
          {params?.row?.customerType}
        </span>)}},
          { field: "paymentType", headerName: "Payment Type", flex: 1,renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Payment Type</strong>)} ,renderCell:(params)=>{return( <span style={{color:"#707070" }}>
          {params?.row?.paymentType}
        </span>)}},
          { field: "createdOn", headerName: "Date Created", flex: 1,renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Date Created</strong>)} ,renderCell:(params)=>{return( <span style={{color:"#707070" }}>
          {params?.row?.createdOn}
        </span>)}},
          { field: "limit", headerName: "Amount/Limit (KES)", flex: 1,renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Amount/Limit (KES)</strong>)} ,renderCell:(params)=>{return( <span style={{color:"#707070" }}>
          {params?.row?.limit}
        </span>)}},
          {
            field: "Actions",
            headerName: "Actions",
            renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Actions</strong>)},
            renderCell: (params) => {
              // ACTIONS BUTTON STATE;
              const [anchorEl, setAnchorEl] = React.useState(null);
              const MenuoptionOpen = Boolean(anchorEl);
              const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
              };
              const handleActionClose = () => {
                setAnchorEl(null);
              };
              return (
                <div>
                  <MoreVert aria-controls={MenuoptionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={MenuoptionOpen ? "true" : undefined} variant="outlined" disableElevation color="primary" onClick={handleClick} id="demo-customized-button" style={customerstyles.moreVertStyling} />

                  <Menudropdown MenuoptionOpen={MenuoptionOpen} handleActionClose={handleActionClose} anchorEl={anchorEl} userParams={params} getuserInfoForEdit={getuserInfoForEdit} setSuspendId={setSuspendId} getCustomerDetailsAndSuspend={getCustomerDetailsAndSuspend} creditcustomer={creditcustomer} />

                  {openSuspendModal && <SusPendCus openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} suspendCustomer={suspendCustomer} />}
                </div>
              );
            },
            flex: 1,
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />

      {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
    </div>
  );
};

export default Allcustomers;
