import React from 'react'
import Productscategory from './productsincategory'
const Createcreditnote = () => {
  return (
    <div>
        <Productscategory/>
    </div>
  )
}

export default Createcreditnote