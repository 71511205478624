import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import BankTabs from "../components/Banks/BanksTabs";
import TerminalsPrintersTabs from "../components/TerminalsAndPrinters/TerminalsPrintersTabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import {
  handleEdited,
  handleDeactivated,
  handleActivated,
  handleDeleted,
  handleAdded,
  handleAssigned,
  handleApproved,
  handleDeclined,
  handleReleased,
} from "../../../features/AlertSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { handleTabChange } from "../../../features/currentTabSlice";
import axios from "axios";
import { setDeviceModels } from "../../../features/DeviceModelsSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

function TerminalsAndPrinters() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editedAlertState = useSelector((state) => state.alertState.edited);
  const addedAlertState = useSelector((state) => state.alertState.added);
  const deletedAlertState = useSelector((state) => state.alertState.deleted);
  const assignedAlertState = useSelector((state) => state.alertState.assigned);
  const approvedAlertState = useSelector((state) => state.alertState.approved);
  const declinedAlertState = useSelector((state) => state.alertState.declined);
  const releasedAlertState = useSelector((state) => state.alertState.released);
  const deactivatedAlertState = useSelector(
    (state) => state.alertState.deactivated
  );
  const activateAlertState = useSelector((state) => state.alertState.activated);

  const [showEditedAlert, setShowEditedAlert] = useState(false);
  const [showDeactivatedAlert, setShowDeactivatedAlert] = useState(false);
  const [showDeletedAlert, setShowDeletedAlert] = useState(false);
  const [showActivatedAlert, setShowActivatedAlert] = useState(false);
  const [showAddedAlert, setShowAddedAlert] = useState(false);
  const [showAssignedAlert, setShowAssignedAlert] = useState(false);
  const [showApprovedAlert, setShowApprovedAlert] = useState(false);
  const [showDeclinedAlert, setShowDeclinedAlert] = useState(false);
  const [showReleasedAlert, setShowReleasedAlert] = useState(false);

  const handleShowReleasedAlert = () => {
    if (releasedAlertState === true) {
      setShowReleasedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowReleasedAlert(false);
        dispatch(handleReleased(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowReleasedAlert();
  }, [releasedAlertState]);

  const handleShowDeletedAlert = () => {
    if (deletedAlertState === true) {
      setShowDeletedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowDeletedAlert(false);
        dispatch(handleDeleted(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeletedAlert();
  }, [deletedAlertState]);

  const handleShowDeclinedAlert = () => {
    if (declinedAlertState === true) {
      setShowDeclinedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowDeclinedAlert(false);
        dispatch(handleDeclined(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeclinedAlert();
  }, [declinedAlertState]);

  const handleShowApprovedAlert = () => {
    if (approvedAlertState === true) {
      setShowApprovedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowApprovedAlert(false);
        dispatch(handleApproved(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowApprovedAlert();
  }, [approvedAlertState]);
  const handleShowAssignedAlert = () => {
    if (assignedAlertState === true) {
      setShowAssignedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAssignedAlert(false);
        dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAssignedAlert();
  }, [assignedAlertState]);

  const handleShowAddedAlert = () => {
    if (addedAlertState === true) {
      setShowAddedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAddedAlert(false);
        dispatch(handleAdded(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAddedAlert();
  }, [addedAlertState]);

  const handleShowAlert = () => {
    if (editedAlertState === true) {
      setShowEditedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowEditedAlert(false);
        dispatch(handleEdited(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [editedAlertState]);

  const handleShowDeactivatedAlert = () => {
    if (deactivatedAlertState === true) {
      setShowDeactivatedAlert(true);
      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowDeactivatedAlert(false);
        dispatch(handleDeactivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeactivatedAlert();
  }, [deactivatedAlertState]);

  const handleActivateAlert = () => {
    if (activateAlertState === true) {
      setShowActivatedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowActivatedAlert(false);
        dispatch(handleActivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleActivateAlert();
  }, [activateAlertState]);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const nextMove = () => {
    if (clickedItem == "Edit") {
      navigate("/EditTerminal");
    }
  };

  useEffect(() => {
    nextMove();
  }, [clickedItem]);

  const nextMove2 = () => {
    if (clickedItem == "Assign") {
      navigate("/AssignTerminal");
    }
  };

  useEffect(() => {
    nextMove2();
  }, [clickedItem]);

  const fetchDeviceModels = async () => {
    const response = await axios.get(baseUrl + "/api/fetchDeviceModels", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    dispatch(setDeviceModels(response.data.data));
  };

  useEffect(() => {
    fetchDeviceModels();
  }, []);

  // dispatch(handleTabChange("Active")); //setting the tab in redux

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Terminals & Printers
          </Typography>
        </Stack>
        {showReleasedAlert && (
          <Alert variant="filled" severity="success">
            Terminal has been released successfully...click on unassigned to view
          </Alert>
        )}
        {showEditedAlert && (
          <Alert variant="filled" severity="success">
            Terminal details Edited successfully...click on Edited to approve
          </Alert>
        )}
        {showDeactivatedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Deactivated successfully...Click on Inactive to view
          </Alert>
        )}

        {showDeletedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Deleted successfully
          </Alert>
        )}
        {showActivatedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Activated successfully...
          </Alert>
        )}
        {showAddedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Added successfully...
          </Alert>
        )}
        {showAssignedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Assigned successfully
          </Alert>
        )}
        {showApprovedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Edited successfully...
          </Alert>
        )}
        {showDeclinedAlert && (
          <Alert variant="filled" severity="success">
            Terminal Edits declined..
          </Alert>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Terminals & Printers" />
        <Button
          sx={{
            borderRadius: 2,
            backgroundColor: "#032541",
            color: "white",
            textTransform: "none",
            "&:hover": { color: "white", backgroundColor: "#032541" },
          }}
          onClick={() => {
            navigate("/AddTerminal");
          }}
        >
          Add Terminal
        </Button>
      </Stack>
      <TerminalsPrintersTabs />
    </Stack>
  );
}

export default TerminalsAndPrinters;
