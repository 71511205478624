import { Box, Breadcrumbs, Button, Grid, Typography, TextField, InputAdornment, Select, MenuItem, Tooltip } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from '../CustomSearchInput';
import ExportMenu from '../ExportMenu';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import CustomTable from '../CustomTable';
import HttpComponent from '../MakeRequest';
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../../No Rows/noRowsOverlay';
import { useSelector, useDispatch } from "react-redux";

const GradeCourseNotifications = (props) => {
    let notificationId = props.notificationId
    console.log(notificationId, "notificationId")
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [search, setSearch] = useState('')
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    const breadcrumbsrecipients = [
        <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
        <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Messaging Service</Typography>,
        <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}> {schoolTypeName === "University/College" ? "Course" : "Grade"} </Typography>
    
    ]


    const columns = [
        { field: 'courseName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > {schoolTypeName === "University/College" ? "Courses" : "Grades"} </strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.courseName}</span></>) } },
        { field: 'recipientCount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Recipients </strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.recipientCount}</span></>) } },
    ]

    const gradeCourseNotification = () => {
        // /api/v1/get_recipients_notifications
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpComponent({
            method: "GET",
            url: `/api/v1/get_recipient_nofications_grouped_by_course?notificationId=${notificationId}`,
            token: localStorage.getItem("X-Authorization")
        }).then((response) => {
            console.log(response?.response?.data, "response?.response?.data")

            if (response.status === 200) {
                // setNotification(response.response.data)
                setPageState({ ...pageState, isLoading: false, total: response?.response?.count, data: response?.response?.data })

            }
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        gradeCourseNotification()
    }, [])
    return (

        <Grid>
            <Grid>
                <Grid item mt={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Recipient</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbsrecipients}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                <Grid style={{ marginTop: "20px" }}>
                    <CustomSearchInput />

                </Grid>
                <Grid>
                    <ExportMenu />
                </Grid>
            </Box>
            <Box>
                <Grid>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '600px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={columns}
                        //pageState.data
                        rows={pageState.data}
                        getRowId={row => row?._id} />
                    {/* <CustomTable columns={columns} data={notification} rowsPerPage={20} /> */}
                </Grid>

            </Box>

        </Grid>
    )
}

export default GradeCourseNotifications