import { Grid, Breadcrumbs, Typography, TextField, Box, Button, Checkbox, InputAdornment } from '@mui/material'
import { SuccessAlert } from "../../snackBar Alerts/successAlert"
import { ErrorAlert } from "../../snackBar Alerts/errorAlert"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import CustomSelectField from "../CustomSelectField";
import CustomInputLabel from '../CustomInputLabel';
import { useSelector } from 'react-redux'
import HttpComponent from '../MakeRequest';

let baseUrl = process.env.REACT_APP_BASE_URL;

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Course & units</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Units</Typography>
]

export default function EditSchoolUnits(props) {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [unitId, setUnitId] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [formData, setFormData] = useState({ unitCode: '', unitName: '', unitYear: '', unitSemester: '', unitDescription: '' })
    const { schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [selectedCourses, setSelectedCourse] = useState([])
    const [courses, setCourses] = useState([])
    const [allcourses, setAllCourses] = useState([])


    // year & semester
    const [yearOptions, setYearOptions] = useState([])
    const [semesterOptions, setSemesterOptions] = useState([]);

    // check if a course already exist in the new array

    useEffect(() => {

        // check if course exists in the default course array
        const coursesFound = props?.unitDetails?.courses?.filter(course => allcourses?.some(courseResponse => courseResponse?.schoolCourses?._id === course?._id));
        setCourses(coursesFound);

        // handle the preselect course if its already found it exists in the above array

        const preselectedCourseIds = coursesFound?.map(course => course?._id);
        setSelectedCourse(preselectedCourseIds);

    }, [props?.unitDetails?.courses, allcourses]);


    // get school courses

    const getCourses = async () => {
        const studentCourseDetails = await fetch(baseUrl + `/api/v1/getCourses?&limit=${5}&searchValue=${searchValue}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        }
        );

        if (studentCourseDetails?.status === 200) {
            await studentCourseDetails.json().then((data) => {
                setAllCourses(data?.data)
            })
        }
    }

    // get course

    useEffect(() => {
        getCourses()
    }, [searchValue])

    // console.log(selectedCourses , 'hell')

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }

    const navigate = useNavigate();


    // handle select courses

    const handleSelectCourses = (courseId) => {
        setSelectedCourse((prevSelectedCourses) => {
            const isSelected = prevSelectedCourses?.includes(courseId);

            if (!isSelected) {
                handleUpdateCourseOnUnit(courseId, "ACTIVE");
                return [...prevSelectedCourses, courseId];
            } else {
                handleUpdateCourseOnUnit(courseId, "INACTIVE");
                return prevSelectedCourses?.filter((id) => id !== courseId);
            }
        });
    };

    useEffect(() => {
        const courses = props?.unitDetails
        setFormData({
            ...formData,
            unitCode: courses?.unitCode,
            unitSemester: courses?.semester,
            unitYear: courses?.year,
            unitName: courses?.unitName,
            unitDescription: courses?.description
        })
        setUnitId(courses?._id)
    }, [])

    //console.log(formData , 'formData')


    //update the course whether active or not


    function handleUpdateCourseOnUnit(courseId, status) {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/modifyCourseUnits`,
                body: {
                    unitId: unitId,
                    courseIds: [courseId],
                    status: status
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data , 'heyyy fcc')
                if (data?.status === 201 || data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                  
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                  
                }
            })
        } catch (error) {

        }
    }


    // update other details

    function handleUpdateOtherCourseOnUnit() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/updateUnitInfo`,
                body: {
                    unitId: unitId,
                    year: formData.unitYear,
                    semester: formData.unitSemester,
                    unitName: formData.unitName,
                    unitCode: formData.unitCode,
                    description: formData.unitDescription
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })

                    setTimeout(() => {
                        navigate(-1)
                    })
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })

                    setTimeout(() => {
                        navigate(-1)
                    })
                }
            })
        } catch (error) {

        }
    }






    const GetSemesters = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": localStorage.getItem('X-Authorization'),
            },
        });
        const data = await response.json();
        if (response?.status === 201) {
            setSemesterOptions(data?.data?.map((terms) => {
                return { value: terms?.schoolGrades, label: terms?.schoolGrades }
            }
            ))
        }
    };



    // get years

    const Getyears = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": localStorage.getItem('X-Authorization'),
            },
        });
        const data = await response.json();
        //console.log(data, "Grades");

        setYearOptions(data?.data?.filter((grade) => grade?.schoolGrades !== 'COMPLETED').map((itemGrade) => {
            return { value: itemGrade?.schoolGrades, label: itemGrade?.schoolGrades }
        }
        ))
    };

    useEffect(() => {
        GetSemesters()
        Getyears()
    }, [schoolTypeId])

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Units</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <TextField style={{ width: '100%' }} placeholder="Unit Code" value={formData.unitCode} onChange={handleOnChange} name="unitCode" />
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <TextField style={{ width: '100%' }} placeholder="Unit Name" value={formData.unitName} onChange={handleOnChange} name="unitName" />
            </Grid>
            <Grid item mt={1} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={1}>
                    <Grid item width={'50%'}>
                        <CustomInputLabel required={true} label={"Year"} />
                        <CustomSelectField style={{ width: '100%' }} value={formData.unitYear} onChange={handleOnChange} name={"unitYear"} placeholder={"Select Year"} options={yearOptions} />
                    </Grid>
                    <Grid item width={'50%'}>
                        <CustomInputLabel required={true} label={"Semester"} />
                        <CustomSelectField style={{ width: '100%' }} value={formData.unitSemester} onChange={handleOnChange} name={"unitSemester"} placeholder={"Select Semester"} options={semesterOptions} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <TextField style={{ width: '100%' }} placeholder="Description" value={formData.unitDescription} onChange={handleOnChange} name="unitDescription" />
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <Typography style={{ width: '100%' }}>Assign Courses</Typography>
            </Grid>
            <Grid item display={'flex'} mt={1} width={'49%'} mb={1}>
                <TextField name={'search'} value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={'search'}
                    variant="outlined"
                    margin="dense"
                    sx={{ width: '100%' }}
                    InputProps={{
                        sx: {
                            height: '3.438rem',
                            width: '100%',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        },
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <Grid container>
                    {allcourses?.map((course) => (
                        <Grid item width={'100%'} style={{ borderBottom: '0.1px solid #707070' }}>
                            <Box sx={{ display: "flex", paddingY: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography>{course?.schoolCourses?.courseName}</Typography>
                                <Checkbox onChange={() => handleSelectCourses(course?.schoolCourses?._id)} checked={selectedCourses?.includes(course?.schoolCourses?._id)} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid mt={2} item width={'49%'} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(-1)} style={{ border: '1px solid #dc3545', color: '#dc3545', textTransform: 'inherit', width: '124px' }}> Cancel</Button>
                <Button onClick={() => handleUpdateOtherCourseOnUnit()} style={!formData.unitCode || !formData.unitName || !formData.unitSemester || !formData.unitYear ? { backgroundColor: 'rgba(3, 37, 65, 0.4)', marginLeft: '10px', color: '#fff', textTransform: 'inherit', width: '124px' } : { backgroundColor: '#032541', marginLeft: '10px', color: '#fff', textTransform: 'inherit', width: '124px' }}> Update Unit</Button>
            </Grid>
        </Grid>
    )
}