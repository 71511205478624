import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Stack, Typography, LinearProgress } from "@mui/material";
import "./Calender.css";
import axios from "axios";
import { useSelector } from "react-redux";


const baseUrl = process.env.REACT_APP_BASE_URL;

function Calender() {
  const reduxEvents = useSelector((state) => state.calendarEvents.events);
  const [events, setEvents] = useState(reduxEvents);

  let Group = localStorage.getItem("group");
  let partnerName = localStorage.getItem("affiliatedPartner");
  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  const renderEventContent = (eventInfo) => {
    return (
      <Stack alignItems={"center"}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="500"
          fontStyle="normal"
          textAlign="left"
          color="#707070"
        >
          KES
        </Typography>
        {isAdmin && (
          <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="500"
          fontStyle="normal"
          textAlign="left"
          color="#f1c40f"
        >
          Rental: {eventInfo.event.extendedProps.rental?.toLocaleString()} 
        </Typography>
        )}
        {isAdmin && (
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="500"
          fontStyle="normal"
          textAlign="left"
          // color="#3498db"
          color="#03b2cb"
        >
          School: {eventInfo.event.extendedProps.school?.toLocaleString()}
        </Typography>
        )}
        {isAdmin && (
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="500"
          fontStyle="normal"
          textAlign="left"
          color="#07bc0c"
        >
          Other: {eventInfo.event.extendedProps.normal?.toLocaleString()}
        </Typography>
        )}
        {isPartner && (
          <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="500"
          fontStyle="normal"
          textAlign="left"
          color="#07bc0c"
        >
          {(eventInfo.event.extendedProps.normal + eventInfo.event.extendedProps.school + eventInfo.event.extendedProps.rental).toLocaleString()}
        </Typography>
        )}

      </Stack>
    );
  };

  const [period, setPeriod] = useState(null)

  const handleDatesSet = (arg) => {                       //executes when user clicks prev and next buttons to change month
    let date1 = arg.start
    let date = new Date(date1)
    console.log('Start Date formatted in func:', date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    console.log(`year: ${year}`)
    console.log(`month: ${month}`)
    setPeriod({
      month,
      year
    })
  };

  const [loading, setLoading] = useState(true);

  const fetchCategoryTotalsForMonth = async () => {
    setLoading(true)
    console.log(`fetcher called with year: ${period?.year}, month: ${period?.month}`)

    let monthValue = period?.month || "11"
    let yearValue = period?.year || "2023"
    try {
      let response = await axios.post(
        baseUrl + "/api/fetchCategoryTotalsForMonth",
        {
          year: yearValue,  
          month: monthValue
        },
        {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      let data = response.data.data;
      let daysInMonth = response.data.daysInMonth;
      if (data) {
        if (data.length === daysInMonth) {
          console.log("correct data");
          console.log(data);
          setEvents(data);
          setLoading(false);
        } else {
          console.log("incorrect data...fetch again");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCategoryTotalsForMonth();
  }, [period]);


 
  

  return (
    <Stack
      bgcolor={"white"}
      // id={'leStack'}
      borderRadius={5}
      shadowColor="rgba(196, 200, 208, 0.4)"
      shadowOffset={{
        width: 3,
        height: 3,
      }}
      shadowRadius={20}
      shadowOpacity={1}
      width={"100%"}
      height={"100%"}
      px={2}
      pb={2}
    >
      {/* <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        height={"auto"}
        headerToolbar={{
          start: "prev next",
          center: "title",
          right: false,
        }}
        selectable={true}
        expandRows={true}
        handleWindowResize={true}
        // updateSize={true}
        // contentHeight={'50px'}
        fixedWeekCount={false}
      /> */}
      {loading ? (
          <Stack width="100%" color={"#032541"}>
            <LinearProgress color="inherit" />
          </Stack>
      ) : null}
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventColor="#fff"
        handleWindowResize={true}
        height={"auto"}
        // updateSize={true}
        eventContent={renderEventContent}
        headerToolbar={{
          start: "prev next",
          center: "title",
          right: false,
        }}
        datesSet={handleDatesSet}
        selectable={true}
        fixedWeekCount={false}
        showNonCurrentDates={false}
      />
     
    </Stack>
  );
}

export default Calender;
