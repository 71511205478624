import { Grid, Typography, Breadcrumbs, Button, Box, Paper } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { useNavigate ,Link } from 'react-router-dom'
import CustomDataGrid from '../../products/stockComponents/customDataGrid';
import CustomSearchInput from '../../School/CustomSearchInput';
import HttpComponent from '../../School/MakeRequest';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DateFormatter from '../../../utils/dateFormatter';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle Owners</Typography>
]


export default function ViewOwnersVehicles() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, customerId, customerName, phone] = newqueryParamValue.split("?")
    const navigate = useNavigate()


    // get vehicle owners

    function getVehicles() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/listvehicles?customerId=${customerId}&page=${pageState?.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicles();
    }, [searchValue, pageState.page, dataGridPageSize ,customerId])

    //get vehicle owners

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        regNo: config?.regNo        ,
        vehicleCount: config?.vehicleOwnersCount,
        insuranceCompanyName: config?.insuranceCompanyName        ,
        color: config?.color,
        routeCount:config?.routeCount,
        vehicleType:config?.vehicleType,
        policyNo:config?.policyNo        ,
        policyExpiry:config?.policyExpiry


    }))

    // recent transactions columns

    const vehcileowners = [
        { field: "regNo", headerName: "Reg No", flex: 1 , renderCell:(params) => <Link to={`/sacco/vehicleowners?vehicledrilldown?${params?.row?.id}`}>{params?.row?.regNo}</Link> },
        { field: "vehicleType", headerName: "Vehicle Type", flex: 1 },
        { field: 'routeCount', headerName: 'Routes', flex: 1 },
        { field: 'color', headerName: 'Color', flex: 1 },
        { field: 'insuranceCompanyName', headerName: 'Insurance', flex: 1 },
        { field: 'policyNo', headerName: 'Insurance', flex: 1  },
        { field: 'policyExpiry', headerName: 'Expiry Date', flex: 1  , renderCell:(params) => <Typography>{DateFormatter(params?.row?.policyExpiry)}</Typography>},
        {
            field: 'action', headerName: 'Action', flex: 1, renderCell: (params) => {
                return (
                    <Box component={'div'} alignItems={'center'}>
                        <EditIcon sx={{ color: '#032541', cursor: 'pointer', marginLeft: '10px' }} />
                    </Box>
                )
            }
        },
    ]
    return (
        <Grid container direction={'column'} width={'100%'}>
            <Grid item mt={2} mb={2}>
                <Grid container gap={2} display={'flex'} direction={'column'}>
                    <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                            <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Vehicles</Typography>
                        </Grid>
                        <Button onClick={() => navigate(`/sacco/vehicleowners?addVehicles?${customerId}`)} style={{ background: "#032541", borderRadius: "5px", textTransform: "inherit", width: "180px", height: "45px", color: "#fff" }}>Add Vehicle</Button>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <Paper elevation={1} sx={{ width: '100%' , background:'#f4f4f4' , height:'80px' , display:'flex', alignItems:'center' }}>
                    <Grid item width={'100%'} display={'flex'} px={2}>
                        <Grid container display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item display={'flex'} direction={'column'}>
                                <Typography sx={{fontWeight:600 , color:'#000'}}>{customerName}</Typography>
                                <Typography >{phone}</Typography>
                            </Grid>
                            <PermIdentityIcon />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <CustomSearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder={'Search'} name={'searchValue'} />
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehcileowners} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}