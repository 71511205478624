import { Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import axios from "axios";
import cardBG from "../../../common/images/AllTransBG.png";
import { CircularProgress } from "@mui/material";

const baseUrl = process.env.REACT_APP_BASE_URL;

function AllTransactions() {
  const [transactionTotal, setTransactionTotal] = useState(null);
  const [todaysTransaction, setTodaysTransaction] = useState(null);
  const [weeksTransaction, setWeeksTransaction] = useState(null);

  let partnerName = localStorage.getItem("affiliatedPartner")

  const fetchTransactions = async () => {
    const response = await axios.post(baseUrl + "/api/transactionCount",
    {
      partnerName,
    },
    {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setTransactionTotal(response.data.data);
    // console.log('transaction totals below')
    // console.log(transactionTotal)
  };

  const fetchTodaysTransactions = async () => {
    const response = await axios.post(baseUrl + "/api/todaysTransactionCount",
    {
      partnerName,
    },
    {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setTodaysTransaction(response.data.daysTransaction);
    console.log("todays transaction totals below");
    console.log(todaysTransaction);
  };

  const fetchWeeksTransactions = async () => {
    const response = await axios.post(baseUrl + "/api/weeksTransactionCount",
    {
      partnerName,
    },
    {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    let weekCount = response.data.data
    if(weekCount) {
      setWeeksTransaction(response.data.data)
    }else {
      setWeeksTransaction(0)
    }
    // console.log('weeks transaction totals below')
    // console.log(transactionTotal)
  };

  useEffect(() => {
    fetchTransactions();
    fetchTodaysTransactions();
    fetchWeeksTransactions();
  }, []);

  return (
    <Stack
      width={"100%"}
      height="166px"
      justifyContent={"space-between"}
      p={2}
      borderRadius={"12px"}
      boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
      bgcolor={"white"}
      style={{
        backgroundImage: `url(${cardBG})`,
        backgroundSize: "70% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={"16px"}
          fontWeight={500}
          fontStretch={"normal"}
          fontStyle={"normal"}
          lineHeight={1.31}
          letterSpacing={"normal"}
          textAlign={"left"}
          color={"#0208a7"}
        >
          All Transactions
        </Typography>
        <SwapHorizIcon sx={{ color: "#0208a7" }} />
      </Stack>
      <Stack width="100%">
        <Typography
          fontFamily="Poppins"
          fontSize={18}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {
            transactionTotal || transactionTotal === 0 ? (
              transactionTotal
            ) : (
              <Skeleton
                variant="text"
                fontSize={18}
                width={"30%"}
                sx={{ bgcolor: "#f0f0f0" }}
                animation="wave"
              />
            )
            // <CircularProgress />
          }
        </Typography>
      </Stack>
      <Stack width={"100%"} direction={"row"} spacing={15}>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            {
              todaysTransaction || todaysTransaction === 0 ? (
                todaysTransaction
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={14}
                  sx={{ bgcolor: "#f0f0f0" }}
                  animation="wave"
                />
              )
              // <CircularProgress />
            }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Today's Transactions
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            {
              weeksTransaction || weeksTransaction === 0 ? (
                weeksTransaction
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={14}
                  sx={{ bgcolor: "#f0f0f0" }}
                  animation="wave"
                />
              )
              // <CircularProgress />
            }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Last 7 Days
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AllTransactions;
