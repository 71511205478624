import {Grid,Typography,Button,TextField,FormControl,InputLabel,Select,MenuItem} from "@mui/material";
import { useEffect, useState } from "react";
import HttpComponent from "../../../../School/MakeRequest";
import { SuccessAlert } from "../../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../../snackBar Alerts/errorAlert";
import {useSelector , useDispatch} from 'react-redux'
import { handleSaveTerminals } from "../../../../../features/businessAddPartner";
export default function AddTerminalStepper(props){
    const [formData, setFormData] = useState({ serialNo: "", simCardNo: "", deviceModel: "", outletName: "" })
    const {businessCategory ,businessNumber ,currency ,schoolType ,name  , branchId } = useSelector((store)=>store.BusinessPartnerDetails.businessDetails)
    const outlets = useSelector((store) => store.BusinessPartnerDetails.outlets)
    const [deviceModels ,setDeviceModels] = useState([])
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [selectedOutLet , setSelectedOutlet] = useState('')
    const dispatch = useDispatch()


    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

      //fetch outlets
      function getDeviceModel() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getBusinessModels`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setDeviceModels(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDeviceModel()
    }, [])


    const formDataAdmin = {
        terminalSerialNumber: formData.serialNo,
        till_paybill: "",
        deviceModel: formData.deviceModel,
        simCardNumber: formData.simCardNo,
        businessNumber:businessNumber,
        createdBy: localStorage.getItem('username'),
        terminalStatus: 'Active',
        payIt: true,
    }

    function handleSubmit() {
        try {
            HttpComponent({
                method: "POST",
                body: formDataAdmin,
                url: `/api/createTerminal`,
                token: localStorage.getItem("X-Authorization"),
            }).then(async(data) => {
                if (data?.status === 201) {
                    // console.log(data, 'terminal creation >>>')
                    // setSuccessShow({ state: true, message: "Terminal created successfully" })
                    dispatch(handleSaveTerminals({serialNo:data?.response?.terminal?.terminalSerialNumber ,simCardNo:data?.response?.terminal?.simCardNumber ,businessName:name,status:data?.response?.terminal?.terminalStatus}))

                    await HttpComponent({
                        method: "POST",
                        url: `/api/v1/assignTerminalsAdmin`,
                        body: {
                            storeId: selectedOutLet,
                            businessNumber: businessNumber,
                            branchId: branchId,
                            terminalId:data?.response?.terminal?._id
                        },
                        token: localStorage.getItem('X-Authorization')
                    }).then((data)=>{
                        if(data?.status === 200 || data?.status === 201){
                            setSuccessShow({ state: true, message: data?.response?.message })
                            setTimeout(()=>{
                                props.openAddOutlet();
                            },[1000])
                        }else{
                            setErrorShow({ state: true, message: data?.response?.message })
                        }
                    })
                } else{
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error?.message })
        }

    }

    return(
        <Grid direction={'column'} container>
             <SuccessAlert vertical="top" horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state} message={errorShow.message} />
             <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Terminal Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='serialNo' onChange={handleOnChange} style={{ width: "100%" }}
                            value={formData.serialNo} label="Serial No" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='simCardNo' onChange={handleOnChange}
                            style={{ width: "100%" }} value={formData.simCardNo} label="Sim Card No"
                            variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "100%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Device Model</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData?.deviceModel}
                                label="Device Model"
                                onChange={handleOnChange}
                                name="deviceModel"
                            >
                                {deviceModels?.map((item) => (
                                    <MenuItem name="outlet" key={item?._id}
                                        value={item?.deviceName}>{item?.deviceName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "100%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedOutLet}
                                label="Outlet"
                                onChange={(e)=>setSelectedOutlet(e.target.value)}
                                name="Outlet"
                            >
                                {outlets?.map((item) => (
                                    <MenuItem name="outlet" key={item?.outletNameId}
                                        value={item?.outletNameId}>{item?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "100%" }}>
                        <TextField id="outlined-basic" name='Institution Name' disabled  style={{ width: "100%" }} value={name} label="Institution Name" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={()=> props?.next()} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => handleSubmit()} disabled={!formData.serialNo || !formData.simCardNo} style={!formData.serialNo || !formData.simCardNo ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Next</Button>
            </Grid>
        </Grid>
    )
    }