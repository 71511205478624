import { Grid, Typography, Breadcrumbs, Button, Tab } from '@mui/material'
import { styled, alpha } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link, useNavigate } from 'react-router-dom'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import SearchFilter from '../../customerAccounts/customerComponents/searchFilter';
import CustomDataGrid from '../../products/stockComponents/customDataGrid';
import HttpComponent from '../../School/MakeRequest';
import AddVehicleOperator from './AddOperator';
import ViewOperatorDetails from './veiwOperatorDetails';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Operators</Typography>
]


// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: 'transparent' } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "17px", background: 'transparent', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#cb444a", backgroundColor: 'transparent', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: 'transparent' },
}));


export default function OperatorsMainView() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const [tabValue, setTabValue] = useState('driver')
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const navigate = useNavigate();

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")

    function getVehicleRoutes() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?status=Active&customerClassification=${tabValue}&page=${pageState.page}&limit=${dataGridPageSize} `,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getVehicleRoutes()
    }, [pageState.page, dataGridPageSize, tabValue ,queryParamValue])

    // data

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        name: config?.customerName,
        operatorType: config?.customerClassification,
        phone: config?.mobileNumber,
        vehicleAssigned: config?.vehicleAssigned

    }))


    const vehcileowners = [
        { field: "name", headerName: "Name", flex: 1, renderCell: (params) => <Link to={`/sacco/vehiclesoperators?viewOperatorDetails?${params?.row?.id}?${params?.row?.name}?${params?.row?.phone}`}>{params?.row?.name}</Link> },
        { field: "operatorType", headerName: "Operator Type", flex: 1 },
        { field: 'phone', headerName: 'Phone Number', flex: 1 },
        { field: 'vehicleAssigned', headerName: 'Vehicle Assigned', flex: 1 },
    ]



    return (
        <Grid container direction={'column'} width={'100%'}>
            {queryParamValue === 'AddOperator' ? <AddVehicleOperator /> : queryParamValue === 'viewOperatorDetails' ? <ViewOperatorDetails/>: <>
                <Grid item mt={2} mb={2}>
                    <Grid container gap={2} display={'flex'} direction={'column'}>
                        <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                                <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Operators</Typography>
                            </Grid>
                            <Button onClick={() => navigate(`/sacco/vehiclesoperators?AddOperator`)} style={{ background: "#032541", borderRadius: "5px", textTransform: "inherit", width: "180px", height: "45px", color: "#fff" }}>Add Operator</Button>
                        </Grid>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid item mt={2}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                            <AntTab label="Drivers" value="driver" />
                            <AntTab label="Conductors" value="conductor" style={{ marginLeft: '20px' }} />
                            <AntTab label="Sacco Operators" value="saccoOperator" style={{ marginLeft: '20px' }} />
                        </AntTabs>
                        <TabPanel value="driver" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehcileowners} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="conductor" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehcileowners} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="saccoOperator" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehcileowners} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>
        }
        </Grid>
    )
}