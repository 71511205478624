import { useMemo } from "react";
import { Grid, Select, ListSubheader, TextField, InputAdornment, Typography, SwipeableDrawer, Drawer } from "@mui/material";
import { redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import profileIcon from './images/profile.svg'
import notificationIcon from './images/notification.svg'
import logoutIcon from './images/logout.svg'
import React, { useEffect, useState } from "react";
import { Menu, SyncAlt } from "@mui/icons-material";
import { openSideBar, openMobileMenu } from "../features/sideBarSlice";
import HttpComponent from "../components/School/MakeRequest";
import AccountingImg from "../images/accounting.svg"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import personimg from "../images/person.svg"
import crosimg from "../images/cro.svg"
import notificationIon from "../images/notification.svg"
import DateFormatter from "../utils/dateFormatter";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import iconEcommerce from "../common/images/icon.svg";
import BookingIcon from '../common/images/switch-booking.svg'
import { makeStyles } from "@mui/styles";
import xeroIcon from "../common/images/xero.png";
import zohoIcon from "../common/images/zoho.jpeg"
import QuickBooks from "../common/images/quickbooks.svg"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SuccessAlertRightAligned, ErrorAlertRightAligned } from '../components/bills/startorderModals/modals';

const baseUrl = process.env.REACT_APP_BASE_URL;

//use styles

const useStyles = makeStyles((theme) => ({
  mobileNav: {
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
    // [theme.breakpoints.up("sm")]: {
    //   display: "none",
    // },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      right: 0
    },
    width: 291,
    color: "#fff",
    top: 0,
    right: 0,
    zIndex: 100,
  },
  drawerPaper: {
    [theme.breakpoints.up("sm")]: {
      right: 0
    },
    right: 0,
    width: 291,
    color: "#fff",
    top: 0,
    zIndex: 100,
    backgroundColor: "rgb(3,37,65)",
  },

}));

const NavBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { group } = useSelector((store) => store.user);
  const { isSideBarOpen } = useSelector((store) => store.sidebar);
  const { isMobile } = useSelector((store) => store.sidebar);
  const [bsDetails, setBsDetails] = useState({})
  console.log('bsDetails', bsDetails)
  const [accountStatus, setAccountStatus] = useState({})
  const [voucherBookingStatus, setVoucherBookingStatus] = useState(false);
  const [subDomainUrl, setSubDomainUrl] = useState('');
  const [quickBookStatus, setQuickBookStatus] = useState({})
  const [xeroStatus, setXeroStatus] = useState({})
  const [zohoStatus, setZohoStatus] = useState({})
  const [webHookStatus, setWebHookStatus] = useState({})
  const [shopifyStatus, setShopifyStatus] = useState(false)
  const [bsNotifications, setBusinessNotifications] = useState([])
  const [customerDetails, setCustomerDetails] = useState({})
  const { accountingEnabled } = accountStatus
  const { xeroAccountingEnabled } = xeroStatus
  const { xeroWebHookIntentStatus } = webHookStatus
  const { quickbooksEnabled } = quickBookStatus
  const { zohoAccountingEnabled } = zohoStatus
  const customerId = localStorage.getItem('customerId')
  const userIdd = localStorage.getItem('userId')
  const usergroup = localStorage.getItem('group')
  const currentlocation = useLocation()
  const pathname = currentlocation.pathname
  const [currentPath, tabValue] = pathname.split("/")
  const [showWebhookMessage, setShowWebhookMessage] = useState(false);
  const theme = useTheme();
  const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [successMessage, setSuccessMessage] = useState('');
  const [sucess, setSucess] = useState({ state: false, message: "" });

  //api/get_customer_by_customer_id

  function fetchCustomerDetaisl() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/get_customer_profile_by_userId?customerId=${userIdd}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        //console.log(data , 'customer data here')
        if (data?.status === 200) {
          setCustomerDetails(data?.response?.data)
        }
      })
    } catch (error) {
      console.log(error, 'customer data here')
    }
  }

  useEffect(() => {
    fetchCustomerDetaisl()
  }, [userIdd])

  //bs info

  function getBsInfo() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/get_business_info`,
        body: { businessId: localStorage.getItem('businessID') },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        setBsDetails(data?.response?.data)
      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getBsInfo()
  }, [])

  // const { businessOwnerName, businessOwnerUserName, businessOwnerEmail, businessOwnerGroup, businessOwnerPhone, businessOwnerPassword } = bsDetails
  const { firstName, secondName, userName, userPhone, userEmail } = customerDetails


  const fullName = firstName + secondName;
  //get accounting status

  function getAccountingStatus() {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/v1/check_accounting_status',
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        if (data?.status === 200) {
          setAccountStatus(data?.response?.data)
          setXeroStatus(data?.response?.data)
          setWebHookStatus(data?.response?.data)
          setQuickBookStatus(data?.response?.data)
          setZohoStatus(data?.response?.data)
        }
      })
    } catch (error) {
    }
  }

  const checkBookingStatus = async () => {
    try {
      HttpComponent({
        method: "GET",
        url: '/api/v1/booking/booking_status',
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        if (data.status === 200) {
          console.log('data response..>>>', data)
          console.log('data response..>>>123', data.response)
          setVoucherBookingStatus(data.response.bookingConfig);
          setSubDomainUrl(data.response.subDomainUrl);
        } else {
          console.log('Failed to fetch booking status');
        }
      });
    } catch (e) {
      console.log('error', e);
    }
  };
  const handleShopifyStatus = () => {
    try {
      HttpComponent({
        method: "GET",
        url: '/api/v1/check_shopify_status',
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        // console.log(data, 'payables status')
        if (data.status === 200) {
          console.log(data?.response?.data, 'shopify status')
          let shopifyStatus = data?.response?.data
          if (shopifyStatus === 'true' || shopifyStatus === true) {
            setShopifyStatus(true)
          } else {
            setShopifyStatus(false)
          }
        }
      })
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    console.log('voucherBookingStatus updated...>>', voucherBookingStatus);
  }, [voucherBookingStatus]);

  useEffect(() => {
    checkBookingStatus()
    getAccountingStatus()
    handleShopifyStatus()
  }, [])




  //get notifications

  function getAllNotifications() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/notification/all`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        setBusinessNotifications(data?.response?.data)
      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getAllNotifications()
  }, [])

  const accounturl = process.env.REACT_APP_ACCOUNTING_URL


  const userGroup = localStorage.getItem('group')
  const userId = localStorage.getItem('userId')
  const companyId = localStorage.getItem('zedAccountingId')
  const businessCat = localStorage.getItem('businessCategory');
  const businessNumber = localStorage.getItem('businessId');
  const businessId = localStorage.getItem('businessID');
  const branchId = localStorage.getItem('branchId');

  const isZedEcommerce = (userGroup === "Merchant" || userGroup === "Owner" || userGroup === 'Accountant' || userGroup === 'Supervisor') && businessCat && businessCat === 'ZED_ECOMMERCE'

  console.log('isZedEcommerce', isZedEcommerce)


  const openInNewTab2 = () => {
    //alert(`${accounturl}?id=${userId}&companyId=${companyId}`)
    window.open(`${accounturl}?id=${userId}&companyId=${companyId}`, '_self');

  };

  const openInBooking = () => {
    // const businessNumber = localStorage.getItem('businessId');
    setSucess({ state: true, message: "Switching to Booking" });
    setSuccessMessage("Success: Switching to Booking!");
    setTimeout(() => {
      window.location.assign(`${subDomainUrl}/booking/admin/login?businessId=${businessId}&businessNumber=${businessNumber}&userId=${userId}&branchId=${branchId}`);
    }, 2000);
  };

  const openInNewTab3 = () => {
    window.location.assign(`${process.env.REACT_APP_ECOMMERCE_URL}/zed_ecommerce/admin/login?businessId=${businessId}&businessNumber=${businessNumber}&businessCat=${businessCat}&userId=${userId}&branchId=${branchId}`)
  };

  const openQuickBooksTab = () => {
    const businessNumber = localStorage.getItem('businessId');
    window.location.assign(`https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&locale=en-ROW&state=%7B%22queryParams%22%3A%7B%22locale%22%3A%22en-ROW%22%7D%7D&app_environment=prod`);
  }

  const openXeroTab = () => {
    const businessNumber = localStorage.getItem('businessId');
    window.location.assign(`https://www.xero.com/au/login/`);
  }

  const openWebHookTab = () => {
    const businessNumber = localStorage.getItem('businessId');
    window.location.assign(`https://developer.xero.com/app/manage/`);
  }
  const openZohoTab = () => {
    window.location.assign(`https://accounts.zoho.com/signin?servicename=ZohoBooks&signupurl=https://www.zoho.com/ke/books/signup/index.html`)
  }

  // const openXeroTab = () => {
  //   const businessNumber = localStorage.getItem('businessId');
  //     window.location.assign(`https://api.dev.zed.business/api/v1/xero/get_token?token=${businessNumber}`);

  //   };

  const ZedEcommerceReirectUrl = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/shopify_switch`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem('X-Authorization')
        }
      })
      if (!response.ok) {
        console.log(response, 'response')
      } else {
        const url = await response.text();
        console.log("Url", url)
        if (url) {
          window.open(url, '_self');
        }


      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const [state, setState] = useState({ right: false });
  const [profileState, setProfileState] = useState({ right: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const toggleNotifications = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setProfileState({ ...profileState, [anchor]: open });
  };

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  const notificationList = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List onClick={handleContentClick}>
        <Grid mt={5} p py={3} item display={'flex'}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item display={'flex'} alignItems={'center'}>
              <img src={notificationIon} alt='person img' /> <Typography style={{ fontWeight: 600, color: "#032541", marginLeft: '10px', fontSize: "18px" }}>Notifications</Typography>
            </Grid>
            <Grid item>
              <img style={{ cursor: "pointer" }} onClick={toggleNotifications(anchor, false)} src={crosimg} alt="" />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item px={5} mt={2} display={'flex'} direction={'column'}>
          <Grid container display={'flex'} direction={'column'}>
            {bsNotifications?.map((notification) => {
              return (
                <Grid item my={2} display={'flex'} alignItems={'center'}>
                  <FiberManualRecordIcon style={{ color: "#dc3545", fontSize: "10px" }} />
                  <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                    <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>{notification?.title}</Typography>
                    <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{notification?.message}</Typography>
                    <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{DateFormatter(notification?.updatedAt)}</Typography>
                  </div>
                </Grid>)
            })}
          </Grid>
        </Grid>
      </List>
    </Box>
  )
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List onClick={handleContentClick}>
        <Grid mt={5} p py={3} item display={'flex'}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item display={'flex'} alignItems={'center'}>
              <img src={personimg} alt='person img' /> <Typography style={{ fontWeight: 600, color: "#032541", marginLeft: '10px', fontSize: "18px" }}>My profile</Typography>
            </Grid>
            <Grid item>
              <img style={{ cursor: "pointer" }} onClick={toggleDrawer(anchor, false)} src={crosimg} alt="" />
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item px={5} mt={2}>
          <Typography style={{ color: "#dc3545" }}>Personal Information</Typography>
        </Grid>
        <Grid item px={5} mt={2} display={'flex'} direction={'column'}>
          <Grid container display={'flex'} direction={'column'}>
            <Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Full Names</Typography>
              <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{fullName}</Typography>
            </Grid>
            <Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography style={{ color: "#032541", fontWeight: "500px", fontSize: "14px" }}> Username</Typography>
              <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userName}</Typography>
            </Grid>
            <Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Email Address</Typography>
              <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userEmail}</Typography>
            </Grid>
            <Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Phone Number</Typography>
              <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userPhone}</Typography>
            </Grid>
            <Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>User Group</Typography>
              <Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{usergroup}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </List>
    </Box>
  );

  const hideAcc = userGroup === 'Customer' || userGroup === 'Cashier' || userGroup === 'STOREMANAGER' || userGroup === 'StoreOperator'
  console.log(hideAcc, 'hide acc')
  console.log(shopifyStatus, 'shopifyStatus enabled')

  // add pos controls event listeners ==< >

  const switchToNormalBusiness = () => {
    dispatch(openSideBar({ openSideBar: true }))
    navigate("/dashboard")
  }
  const showtabValue = ["pos", "orders", "detailedbill"].includes(tabValue)
  console.log("showtabValue", showtabValue)
  return (
    <div style={{ fontFamily: "Poppins" }}>
      {/*businessName and Action Buttons Container*/}
      <Grid container width={'100%'} justifyContent="space-between" alignItems="center" direction={"row"} marginBottom="2%" px={isMuiMobile ? '' : '1%'}>
        {/* Business Name Label */}
        <Grid item style={{ display: "flex", alignItems: "center", width: "60%", justifyContent: "space-between" }}>
          {!isSideBarOpen && (
            <span onClick={() => dispatch(openSideBar({ openSideBar: true }))} style={{ cursor: "pointer" }}>
              {!showtabValue && <Menu className={classes.mobileNav} style={{ fontSize: "35px" }} />}
            </span>
          )}
          <span
            style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", textAlign: "left", width: '100%', color: "#032541", marginLeft: '-18px' }}
            onClick={() => {
              redirect("/dashboard");
            }}
          >
          </span>
        </Grid>

        {/*Action buttons*/}
        <Grid item display={'flex'} gap={'10px'} alignContent={'center'} alignItems={'center'} width={'100%'} justifyContent={'flex-end'}>
          {
            tabValue !== "dashboard" && (<Box onClick={() => switchToNormalBusiness()} sx={{ color: '#032541', backgroundColor: '#f5f5f5', display: 'flex', alignItems: 'center', gap: "10px", padding: '5px', borderRadius: '3px', cursor: 'pointer' }}>
              <Typography>Business View</Typography> <SyncAlt />
            </Box>)
          }

          {showWebhookMessage && xeroWebHookIntentStatus === false && xeroAccountingEnabled === true && !hideAcc ? (
            <div onClick={openWebHookTab} style={{
              display: "flex",
              cursor: "pointer",
              alignContent: "center",
              alignItems: "center",
              marginRight: "15px",
              color: "red",
              textDecoration: 'underline'
            }}>
              Webhook Intent Not Set!
            </div>
          ) : null}
          {zohoAccountingEnabled === true && !hideAcc ? (
            <div onClick={() => {
              openZohoTab();
            }} style={{
              display: "flex",
              cursor: "pointer",
              alignContent: "center",
              alignItems: "center",
              marginRight: "15px"
            }}>
              <img src={zohoIcon} style={{ width: "94px", height: "74px", objectFit: "contain" }}
                alt="zoho status" />
            </div>) : null
          }
          {xeroAccountingEnabled === true && !hideAcc ? (
            <div onClick={() => {
              if (xeroWebHookIntentStatus === false) {
                setShowWebhookMessage(true);
              } else {
                openXeroTab();
              }
              // setShowWebhookMessage(true);
            }} style={{
              display: "flex",
              cursor: "pointer",
              alignContent: "center",
              alignItems: "center",
              marginRight: "15px"
            }}>
              <img src={xeroIcon} style={{ width: "34px", height: "34px", objectFit: "contain" }}
                alt="Accounting status" />
            </div>) : null
          }
          {quickbooksEnabled === true && !hideAcc ? (
            <div onClick={openQuickBooksTab} style={{
              display: "flex",
              cursor: "pointer",
              alignContent: "center",
              alignItems: "center",
              marginRight: "15px"
            }}>
              <img src={QuickBooks}
                alt="QuickBooks status" />
            </div>) : null
          }
          {accountingEnabled === true && !hideAcc ? (
            <div onClick={openInNewTab2} style={{ display: "flex", cursor: "pointer", alignContent: "center", alignItems: "center", marginRight: "15px" }}>
              <img src={AccountingImg} style={{ width: "34px", height: "34px", objectFit: "contain" }} alt="Accounting status" />
            </div>) : null
          }
          <div>
            {voucherBookingStatus === true && !hideAcc ? (
              <div onClick={openInBooking} style={{ display: "flex", cursor: "pointer", alignContent: "center", alignItems: "center", marginRight: "15px" }}>
                <img src={BookingIcon} style={{ width: "34px", height: "34px", objectFit: "contain" }} alt="Accounting status" />
              </div>) : null
            }
            {successMessage && (
              <div style={{position: 'fixed',top: '20px',right: '20px',backgroundColor: 'green',color: 'white',padding: '10px 20px',borderRadius: '5px',boxShadow: '0 2px 10px rgba(0,0,0,0.2)',zIndex: 1000,transition: 'opacity 0.5s ease',}}>
                {successMessage}
              </div>
            )}
          </div>
          {isZedEcommerce && !hideAcc ?
            <div onClick={openInNewTab3} style={{ display: "flex", cursor: "pointer", alignContent: "center", alignItems: "center", marginRight: "15px" }}>
              <img src={iconEcommerce} style={{ width: "40px", height: "40px", objectFit: "contain" }} alt="Ecommerce status" />
            </div>
            : null
          }
          <div>
            {['right'].map((anchor) => (
              <div key={anchor}>
                <img onClick={toggleDrawer(anchor, true)} style={{ width: "34px", height: "34px", objectFit: "contain", backgroundColor: "#C4E0F8", borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "8px", cursor: "pointer" }} src={profileIcon} alt={"img"} />
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </div>
            ))}
          </div>

          <div>
            {['right'].map((anchor) => (
              <div key={anchor}>
                <img onClick={toggleNotifications(anchor, true)} style={{ width: "34px", height: "34px", objectFit: "contain", backgroundColor: "#C4E0F8", borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "8px", cursor: "pointer" }} src={notificationIcon} alt={"img"} />
                <Drawer
                  anchor={anchor}
                  open={profileState[anchor]}
                  onClose={toggleNotifications(anchor, false)}
                >
                  {notificationList(anchor)}
                </Drawer>
              </div>
            ))}
          </div>
          <img
            style={{ width: "34px", height: "34px", objectFit: "contain", backgroundColor: "#C4E0F8", borderRadius: "50%", border: "1px solid transparent", padding: "2px", marginRight: "8px", cursor: "pointer" }}
            src={logoutIcon}
            alt={"img"}
            onClick={() => {
              navigate("/logout");
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export { NavBar };
