import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edited: false,
  deactivated: false,
  approved: false,
  declined: false,
  activated: false,
  deleted: false,
  added: false,
  assigned: false,
  enabled: false,
  released: false,
  created: false,
  moved: false
}


const AlertSlice = createSlice({
  name:"alertState",
  initialState,
  reducers:{
    handleEdited:(state, action) => {
      state.edited = action.payload
    },
    handleDeactivated:(state, action) => {
      state.deactivated = action.payload
    },
    handleApproved:(state, action) => {
      state.approved = action.payload
    },
    handleDeclined:(state, action) => {
      state.declined = action.payload
    },
    handleActivated:(state, action) => {
      state.activated = action.payload
    },
    handleDeleted:(state, action) => {
      state.deleted = action.payload
    },
    handleAdded:(state, action) => {
      state.added = action.payload
    },
    handleAssigned:(state, action) => {
      state.assigned = action.payload
    },
    handleEnabled:(state, action) => {
      state.enabled = action.payload
    },
    handleReleased:(state, action) => {
      state.released = action.payload
    },
    handleCreated:(state, action) => {
      state.created = action.payload
    },
    handleMoved:(state, action) => {
      state.moved = action.payload
    },
  }
})

export const { handleMoved, handleCreated, handleReleased, handleEnabled, handleAssigned,handleEdited, handleDeactivated, handleApproved, handleActivated, handleDeclined, handleDeleted, handleAdded } = AlertSlice.actions;

export default AlertSlice.reducer