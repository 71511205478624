import React, { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { ArrowForwardIos, Delete } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "./styles";
import { escape, parseInt, sumBy } from "lodash";
import { PreviewBtn } from "./customDate";
import SearchFilter from "./searchFilter";
import HttpComponent from "../../School/MakeRequest";
import { addItemsToInvoice } from "../../../features/startorderSlice";
import { useSelector, useDispatch } from "react-redux";
import { ErrorAlertRightAligned } from "../../bills/startorderModals/modals";
import { currencyconverter } from "../../../common/currencyconverter";

const baseUrl = process.env.REACT_APP_BASE_URL;

const CategoryTable = (props) => {
  
  const dispatch =useDispatch();
  const { defaultInvoiceArray } = useSelector((store)  => store.startorder)
    // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = React.useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const setPreviewInvoiceItemsShow = props.setPreviewInvoiceItemsShow


  const [categories, setCategories] = useState({ data: [], count: 0 });
  console.log(categories , 'categories')
        // serch filter
  const [inputValue, setInputValue] = useState("");
  const [productsSearch, setProductSearch] = useState('')
  const fetchCategories = async () => {
    try {
      const response = await fetch(baseUrl + `/api/listByCategories?page=&limit=`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      await response.json().then((data) => {
        const categoryData = data?.categories?.map((category, index) => {
          console.log(category , 'category')
      
          return {
            id: index,
            categoryName: category?.category,
            categoryId:category?.categoryId
          };
        });
        setCategories({ ...categories, data: categoryData });
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchCategories();
    
  }, []);



  const [categoryName, setCategoryName] = useState("");
  console.log(pageState.page , 'page')
console.log(categoryName , 'categoryName')
  const fetchProductsPerCategory = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    if(categoryName)
    try {
      const response = await fetch(baseUrl + `/api/listByProducts?categoryId=${categoryName}&page=${pageState.page}&limit=${dataGridPageSize}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
     
      const data = await response.json();
      const productData = data?.products?.map((product) => ({
        id: product?._id,
        productName: product?.productName,
        InitialStock: product?.quantityInStock ?? product?.InitialStock,
        productPrice: product?.productPrice,
        outOfStock: product?.outOfStock,
        quantityInStock: product?.quantityInStock ?? product?.InitialStock,
        productCategory: product?.productCategory,
        priceStatus: product?.priceStatus,
        unitOfMeasure: product?.unitOfMeasure,
        variationKeyId: product?.variationKeyId,
        variationKey: product?.variationKey,
        variantCode: product?.variantCode,
      }));
       setPageState({ isLoading: false, data: productData, count: data?.count, page: pageState.page, pageSize: dataGridPageSize });

    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    let isFunctionRun = false;

    if (!isFunctionRun) {
      if (categoryName) {
        fetchProductsPerCategory();
      }
    }
    return () => {
      isFunctionRun = true;
    };
  }, [categoryName, pageState.page, dataGridPageSize]);



  // add product to array

  const [invoiceArray, setInvoiceArray] = useState(props?.itemsToAdd);
  useEffect(() => {
    props.setInvoiceArrayProducts(invoiceArray)
  },[invoiceArray])

  const addProductToInvoiceArray = (product) => {
    
    // priceStatus "Variable Price Item"
    // if ((product.outOfStock === true && product.priceStatus !== "Variable Price Item") || (product.quantityInStock === 0 && product.priceStatus !== "Variable Price Item") || (Math.sign(product.quantityInStock) === -1 && product.priceStatus !== "Variable Price Item") ) {
      // setNoItems(true);
    // } else {
      const newInvoiceArray = [...invoiceArray];
      // create object and add
      const invoiceProduct = {
        itemName: product.productName,
        itemAmount: product.productPrice,
        checkedProductId: product.id || product._id,
        itemCount: 1,
        totalAmount: product.productPrice * 1,
        customerNote: "",
        itemCategory: product.productCategory,
        unitOfMeasure: product.unitOfMeasure,
        reciptNumber: Date.now(),
        quantityInStock: product.quantityInStock,
        priceStatus: product.priceStatus,
        variationKeyId:product?.variationKeyId,
        variationKey:product?.variationKey,
        variantCode:product?.variantCode,
        writable: true
      };

      if (invoiceProduct.itemName) {
        const currentIndex = newInvoiceArray.findIndex((invoiceProduct) => invoiceProduct.checkedProductId === product.id || product._id);
        if (currentIndex === -1) {
          newInvoiceArray.push(invoiceProduct);
          dispatch(addItemsToInvoice(invoiceProduct))
        } else {
          newInvoiceArray?.map((item, index) => {
            if (index === currentIndex) {
              // console.log(item);
              item.itemCount = parseInt(item.itemCount) + 1;
              item.totalAmount = item.itemCount * item.itemAmount;
            }
            return item;
          });
        }
        setInvoiceArray(newInvoiceArray);
      }
    // }
  };

  const updateVariablePriceTotals = (e) => {
    let updateVariableTotals = [...invoiceArray];
    const value = e.target.value;
    updateVariableTotals?.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
        prod.itemAmount = e.target.value;
        prod.totalAmount = e.target.value * prod.itemCount;
      }
    });

    setInvoiceArray(updateVariableTotals);
  };

  const updateInputTotals = (e) => {

    let updateTotalsArray = [...invoiceArray];
    updateTotalsArray?.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
    
        prod.itemCount = parseInt(e.target.value);
        prod.totalAmount = parseInt(e.target.value)  * prod.itemAmount;
      }
    });

    updateTotalsArray.filter((prod, index) => {
      if (parseInt(e.target.name) === index) {
        if (prod.itemCount > prod.quantityInStock) {
          // setNoItems(true);
          // if(prod.priceStatus !== "Variable Price Item"){
          //   prod.itemCount = prod.quantityInStock - 1;
          // }
          
        }
      }
    });

    setInvoiceArray(updateTotalsArray);
    // updateTotalsArray.map((prod, index) => ({...prod, ...(parseInt(e.target.name) === index ? {itemCount: value, totalAmount: prod.itemAmount * value} :{} )}))
  };

  const removeproductFrominvoiceArray = (product) => {
    const newPosRemove = [...invoiceArray];

    const currentIndex = newPosRemove.indexOf(product);

    if (currentIndex >= 0) {
      newPosRemove?.map((item, index) => {
        if (currentIndex === index) {
          newPosRemove.splice(currentIndex, 1);
        }
        return item;
      });
    }
    setInvoiceArray(newPosRemove);
  };

  // INVOICE TOTALS;
  const invoiceTotals = sumBy(invoiceArray, (invoiceArray) => {
    return invoiceArray.totalAmount;
  });



  // search category;
  const searchCat = () => {
    if(inputValue)
    HttpComponent({
      method:"GET",
      url:`/api/searchByCategories?search=${inputValue}`,
      body:null,
      token:localStorage.getItem("X-Authorization")
    }).then((data) => {



      const categoryData = data?.response?.categories.map((category,index) => {
        return {
          id: index,
          categoryName: category?.category,
          categoryId:category?.categoryId
        };
      });
      setCategories({ ...categories, data:categoryData });
    }).catch((e) => {
      console.log(e.message);
    })
  }

  useEffect(() => {

    if(!inputValue)fetchCategories()
    searchCat()
  },[inputValue])


    // AACORDION DISPLAY;
    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    


    // get products per category;

    const getProducts = () => {
      if(productsSearch)
      HttpComponent({
        method:"GET",
        url:`/api/searchProducts?c_search=&p_search=&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${escape(productsSearch)}`,
        body:null,
        token:localStorage.getItem("X-Authorization")
      }).then((data) => {
        const productData = data?.response?.products?.map((product) => {
          return {
            id: product?._id,
            productName: product?.productName,
            inStock: product?.quantityInStock,
            productPrice: product?.productPrice,
            outOfStock: product?.outOfStock,
            quantityInStock: product?.quantityInStock,
            productCategory: product?.productCategory,
            priceStatus: product?.priceStatus,
            unitOfMeasure: product?.unitOfMeasure,
            variationKeyId:product?.variationKeyId,
            variationKey:product?.variationKey,
            variantCode:product?.variantCode
          };
        });

        setPageState({ isLoading: false, data: productData, count:data?.response?.count });
      }).catch((e) => {
        console.log(e.message);
      })
    }

    useEffect(() => {
     
      if(!productsSearch)fetchProductsPerCategory()
      getProducts()
      
    },[productsSearch])

    const [error, setError] = useState({state:false, message:''})

useEffect(() => {
  if(error.state){
    setTimeout(() => {
      setError({state:false})
    }, 2000);
    
  }
}, [error.state])

  return (
    <div style={customerstyles.universalFont}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div style={{margin:"0px 0px 10px 0px"}} >
          <SearchFilter placeholder={'Search Category'} setInputValue={setInputValue} />
          </div>
          
          {categories?.data?.map((category) => (
            
            <Accordion key={category?.id}  onChange={handleChange(category?.id)} expanded={expanded === category.id}  >
              <AccordionSummary expandIcon={<ArrowForwardIos />} onClick={() => {
                  setCategoryName(category?.categoryId)
              } }>
                <Typography>{category?.categoryName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                <div style={{display:'flex', justifyContent:'flex-end'}} >
                <input placeholder={'Search product'} style={{outline:"none", borderRadius:"5px" }} value={productsSearch} onChange={(e) => setProductSearch(e.target.value)} />
                </div>
                </Grid>
                
               
                <DataGrid
                  style={{ border: "none", height: "300px", width: "100%" }}
                  rows={pageState?.data}
                  columns={[
                    { field: "productName", headerName: "Product Name", flex: 1 },
                    { field: "quantityInStock", headerName: "In Stock", flex: 1 },
                    { field: "productPrice", headerName: "Price", flex: 1 },
                  ]}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  rowCount={pageState?.count}
                  loading={pageState?.isLoading}
                  pagination
                  page={pageState?.page - 1}
                  pageSize={dataGridPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    console.log(newPage,)
                    setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
                  }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onRowClick={(data) => addProductToInvoiceArray(data?.row)}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>

        <Grid item xs={8} style={{ backgroundColor: "rgba(178, 180, 187, 0.1)" }}>
          {invoiceArray?.length > 0 && (
            <TableContainer component={Paper} style={{ flexDirection: "column-reverse", height: "40vh", overflow: "auto", display: "flex" }}>
              <Table stickyHeader sx={{ width: "100%" }}>
                <TableHead style={{ fontWeight: "bold", fontSize: "10px" }}>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Product Name</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Item Amount(KES)</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="center">
                      Quantity
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoiceArray?.map((invoiceItem, index) => {
                    const { itemName, checkedProductId, itemAmount, itemCount, totalAmount, priceStatus } = invoiceItem;

                   
                    if (priceStatus === "Variable Price Item") {
                      return (
                        <TableRow style={{ cursor: "pointer" }} key={index}>
                          <TableCell>{itemName}</TableCell>
                          <TableCell>
                            <input
                              required
                              onChange={updateVariablePriceTotals}
                              value={itemAmount}
                              style={{
                                width: "50%",
                                outline: "none",
                                margin: "0px 10px 0px 0px",
                              }}
                              id={checkedProductId}
                              name={index}
                              type="number"
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <input
                              value={itemCount}
                              onChange={updateInputTotals}
                              required
                              id={checkedProductId}
                              name={index}
                              style={{
                                width: "50%",

                                margin: "0px 10px 0px 0px",
                              }}
                              type="number"
                            />{" "}
                          </TableCell>
                          <TableCell>{itemAmount * itemCount}</TableCell>
                          <TableCell>
                            {" "}
                            <Delete onClick={() => removeproductFrominvoiceArray(invoiceItem)} style={{ color: "#dc3545", cursor: "pointer" }} />
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow style={{ cursor: "pointer" }} key={index}>
                          <TableCell>{itemName}</TableCell>
                          <TableCell>{itemAmount}</TableCell>
                          <TableCell align="center">
                            {" "}
                            <input
                              type="number"
                              required
                              value={itemCount}
                              onChange={updateInputTotals}
                              id={checkedProductId}
                              name={index}
                              style={{
                                // padding: "7px",
                                width: "50%",
                                outline: "none",
                                margin: "0px 10px 0px 0px",
                              }}
                            />{" "}
                          </TableCell>
                          <TableCell>{itemAmount * itemCount}</TableCell>
                          <TableCell>
                            {" "}
                            <Delete onClick={() => removeproductFrominvoiceArray(invoiceItem)} style={{ color: "#dc3545", cursor: "pointer" }} />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {invoiceArray?.length === 0 && (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <p style={customerstyles.orderItemTotals}>No added Items</p>
            </div>
          )}

          {invoiceArray?.length > 0 && (
            <div style={customerstyles.orderItemHolder}>
              <p style={customerstyles.orderItemTotals}>
                Total Amount <span style={{ margin: "0px 0px 20px 0px" }}>{currencyconverter(invoiceTotals)}</span>{" "}
              </p>
            </div>
          )}

          {invoiceArray?.length > 0 && (
            <div style={customerstyles.orderItemHolder}>
              <PreviewBtn setInvoiceArray={setInvoiceArray} setPreviewInvoiceItemsShow={setPreviewInvoiceItemsShow} invoiceTotals={invoiceTotals} setError={setError} />
            </div>
          )}
        </Grid>

      {error.state && <ErrorAlertRightAligned message={error.message} error={error.state} /> }  
      </Grid>
    </div>
  );
};

export default CategoryTable;

