import { Grid, Typography, Breadcrumbs, FormControl, InputLabel, Select, MenuItem, TextField ,Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from 'react';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import { useNavigate } from 'react-router-dom'
import HttpComponent from '../../../School/MakeRequest';

// breadcrumbs


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>ZPM Account</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit ZPM Card Collection Account</Typography>,
]

export default function ZPMEditCardAccount() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [selectedBankCode, setSelectedBankCode] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [banksCodes, setBankCodes] = useState([])
    const [loading, setLoading] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);

    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, businessNumber, businessName , cardNumber] = newqueryParamValue.split("?")
    const navigate = useNavigate()

    //get bank codes

    function getBankCodes() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transfer_banks`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setBankCodes(data?.response?.data?.filter((bank)=>bank?.bankName === 'KCB'))
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getBankCodes()
    }, [])

    useEffect(() => {
        setAccountNumber(cardNumber);
      }, [cardNumber]);

      useEffect(() => {
        if (businessNumber) {
            fetchPaymentDetails()
        }
    }, [businessNumber])
    
    async function fetchPaymentDetails() {
        setLoading(true);
        try {
            const response = await HttpComponent({
                method: 'POST',
                url: `/api/v1/get_cards_details?businessNo=${businessNumber}`,
                token: localStorage.getItem('X-Authorization')
            });
    
            console.log('response------', response);
    
            if (response?.response?.success === true) {
                const { data } = response.response;
                const { pocketMoneyAccountNumber } = data.pocketMoneyAccount;
                
                if (pocketMoneyAccountNumber) {
                    setAccountNumber(pocketMoneyAccountNumber);
                } else {
                    console.error('Unexpected response structure:', data);
                    setErrorShow({ state: true, message: "Invalid payment details format" });
                }
            } else {
                console.error('Error fetching payment details:', response);
                // setErrorShow({ state: true, message: "Error fetching payment details" });
            }
        } catch (error) {
            console.error('Error fetching payment details:', error);
            setErrorShow({ state: true, message: "Error fetching payment details" });
        } finally {
            setLoading(false);
        }
    }

    console.log(accountNumber , 'accountNumber')

    //function set up pocket money card account

    function handleAdminSetPocketMoneyCardAccount(){
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/setUpPocketMoneyAccount`,
                body:{
                    accountNumber:accountNumber,
                    accountType: "KCB",
                    businessNo:businessNumber
                },
                token: localStorage.getItem('X-Authorization'),
            }).then((data)=>{
                if (data?.status === 200 || data?.status === 201  ) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
                setTimeout(() => {
                    navigate(-1)
                }, 1500)
            })
        }catch(error){

        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {/* <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit ZPM Card Collection Account</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid> */}
            <Grid item mt={2}>
                <Typography>Edit ZPM Card Collection Account</Typography>
            </Grid>
            <Grid item  mt={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom:'10px' }}>Business Name</InputLabel>
                <TextField  style={{ width: "100%" }} disabled value={businessName}  id="outlined"  multiline required />
            </Grid>
            <Grid item  mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom:'10px' }}>Bank Name</InputLabel>
                <TextField  style={{ width: "100%" }} disabled value={'KCB'} placeholder='KCB'  id="outlined"  multiline required />
            </Grid>
            {/* <Grid item width={'50%'} mb={2}>
                <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <InputLabel id="demo-simple-select-label">Select Bank</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedBankCode} label="Select Bank" onChange={(e) => setSelectedBankCode(e.target.value)}>
                        {banksCodes?.map((acc) => {
                            return <MenuItem defaultValue={acc?.bankName} disabled value={acc?.bankName}>{acc?.bankName}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid> */}
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom:'10px' }}>Bank Account</InputLabel>
                <TextField  style={{ width: "100%" }} value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} id="outlined" placeholder="1245378" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={handleAdminSetPocketMoneyCardAccount} disabled={!accountNumber} style={ !accountNumber  ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}