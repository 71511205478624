import {Box } from '@mui/material'
import TransportMerchantDashboard from './transportMerchantDashboard';
import TransportInsuranceDashboard from './transportInsurancedashboard';
export default function TransportPartnerDashboard() {
    const businessCat = localStorage.getItem("businessCategory");
    const group = localStorage.getItem("group");
    const isTransportPartner = group && group === "Merchant" && businessCat && businessCat === 'Transport'
    const isPartnerInsurance = group && group === 'TransportPartner';
    return (
       <Box component={'div'}>
         {isTransportPartner ? <TransportMerchantDashboard/>: null }
         {isPartnerInsurance ? <TransportInsuranceDashboard/>: null }
       </Box>
    )
}