import { Box, Breadcrumbs, Button, Grid, Typography, TextField, InputAdornment, Select, MenuItem, Checkbox, Menu } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomInputLabel from '../CustomInputLabel';
import CustomTextField from '../CustomTextField';
import CustomSelect from '../CustomSelectField';
import { useSelector } from "react-redux";
import HttpComponent from '../MakeRequest';
import { set } from 'lodash';
import CustomSearchInput from '../CustomSearchInput';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import { useNavigate } from 'react-router-dom';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Messaging Service</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create Message</Typography>
]
const baseUrl = process.env.REACT_APP_BASE_URL;
const SchoolCreateMessage = () => {
    const { schoolTypeName, schoolTypeId } = useSelector(
        (store) => store.schoolType.schoolTypeDetail
    );
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const [typeNotificationOptions, setTypeNotificationOptions] = useState([
        { value: "Email", label: "Email" },
        { value: "SMS", label: "SMS" },
        { value: "WhatsApp", label: "WhatsApp" },
        { value: "InApp", label: "InApp" },
    ]);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);


    };

    const closeMenu = () => {
        setAnchorEl(null);
    };




    const [formData, setFormData] = useState({
        typeNotification: "",
        message: "",
        title: "",
        studentGrade: [],
        fcmToken: "WEB",
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const [studentGradeChange, setStudentGradeChange] = useState([]);
    const [gradeOptions, setGradeOptions] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);
    const [checkIfOption, setCheckIfOption] = useState(false);
    const [selectedRecipient, setSelectedRecipient] = useState("");
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const handleStudentGrade = (value) => {
        let valueChange = value;
        const currentIndex = studentGradeChange.indexOf(valueChange);
        const newChecked = [...studentGradeChange];
        if (currentIndex === -1) {
            newChecked.push(valueChange);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }
        setStudentGradeChange(newChecked);
    }
    const checkIfChecked = (value) => {
        const currentIndex = studentGradeChange.findIndex((item) => item === value);
        if (currentIndex === -1) {
            return false;
        }
        else {

            return true;
        }
    }

    const selectRecipientValues = () => {
        if (studentGradeChange.length === 0) {
            setSelectedRecipient("Select Recipients")
        } else {
            setSelectedRecipient(studentGradeChange.join(", "))
        }
    }
    useEffect(() => {
        selectRecipientValues();
    }, [studentGradeChange])




    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": localStorage.getItem('X-Authorization'),
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const getCourses = async () => {
        HttpComponent({
            method: "GET",
            url: `/api/v1/get_school_courses`,
            token: localStorage.getItem("X-Authorization")
        }).then((response) => {
            if (response.status === 200) {
                let courses = response?.response?.data
                console.log(courses, "Courses ===>>>>>>")
                setCourseOptions(
                    courses?.map((itemCourse) => {
                        return {
                            value: itemCourse.courseName,
                            label: itemCourse.courseName,
                        };
                    })
                );
            }
        }).catch((error) => {
            console.log(error)
        })


    }
    useEffect(() => {
        if (schoolTypeName === "University/College") {
            getCourses();

        } else {
            GetGrades();

        }
    }, []);
    const [buttonState, setButtonState] = useState(false);
    const changeButtonState = () => {
        if (formData.typeNotification !== "" && formData.message !== "" && formData.title !== "" && studentGradeChange.length !== 0 && formData.message.length <= characterLimitState) {
            setButtonState(true);
        } else {
            setButtonState(false);
        }
    }
    useEffect(() => {
        changeButtonState();
    }, [formData])
    const handleSubmitMessage = async () => {
        let data = {
            typeNotification: formData.typeNotification,
            message: formData.message,
            studentGrade: studentGradeChange,
            title: formData.title,
            fcmToken: "WEB",
        }

        console.log(data, "data")
        HttpComponent({
            method: "POST",
            url: `/api/v1/students/sendStudentNotications`,
            token: localStorage.getItem("X-Authorization"),
            body: data
        }).then((response) => {
            if (response.status === 200) {
                setSuccessShow({ ...successShow, state: true, message: "Message sent successfully" })
                setTimeout(() => {
                    // setSuccessShow({ ...successShow, state: false, message: "" })
                    navigate('/messaging/service')

                }, 1000)
            } else {
                setErrorShow({ ...errorShow, state: true, message: response?.response?.data?.message })
                setTimeout(() => {
                    setErrorShow({ ...errorShow, state: false, message: "" })
                }, 1000)
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const [characterLimitState, setCharacterLimitState] = useState(0);

    const charachterLimit = () => {
        if (formData.typeNotification === "Email") {
            setCharacterLimitState(1000)


        } else if (formData.typeNotification === "SMS") {
            setCharacterLimitState(200)

        } else if (formData.typeNotification === "WhatsApp") {
            setCharacterLimitState(960)
        } else if (formData.typeNotification === "InApp") {
            setCharacterLimitState(200)
        }
    }
    useEffect(() => {
        charachterLimit();
    }, [formData.message, formData.typeNotification])
    //    console.log(formData, "formData")
    console.log(buttonState, "buttonState")

    return (
        <Grid>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <Grid>
                <Grid item mt={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Messaging Service</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>

            </Grid>
            <Grid sx={{ marginTop: "40px" }}>
                <Grid item mt={1}>
                    <Typography style={{ fontWeight: 400, fontSize: "16px", color: "#032541" }}>Messaging Channel</Typography>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "space-between", flexDirection: "row", width: "40rem", }} >
                    <CustomSelect name="typeNotification" options={typeNotificationOptions} sx={{ marginRight: "20px", height: "60px" }} onChange={handleChange} />
                    <Box component="div" sx={{ display: "flex", width: "30rem", }}>
                        <div>
                            <TextField
                                id="outlined-select-recipients"
                                label="Select Recipients"
                                defaultValue={""}
                                value={selectedRecipient}
                                required
                                onClick={openMenu}
                            />
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={closeMenu}
                            >

                                <CustomSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} name={"searchText"} placeholder={"search"} />
                                {/* <CustomTextField value={searchText} onChange={seaqrchPayee} name={"searchText"} placeholder={"search Payee"}  /> */}

                                {courseOptions?.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}

                                    >
                                        <Checkbox checked={checkIfChecked(option.value)} onChange={() => handleStudentGrade(option.value)} />
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </Box>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                    <CustomInputLabel required={false} label={"Subject"} />
                    <TextField
                        name={"title"}
                        value={formData.title}
                        onChange={handleChange}
                        placeholder={"Subject"}
                        variant="outlined"
                        margin="dense"
                        sx={{ marginBottom: 2 }}
                        multiline
                        minRows={1}
                        InputProps={{
                            sx: {
                                width: '40rem',
                                fontSize: "0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1"
                                }
                            }
                        }}
                    />
                </Box>
                <Box>
                    <CustomInputLabel required={false} label={"Message"} />
                    <TextField
                        id="message"
                        name="message"
                        value={formData.message}
                        onInput={handleChange}
                        placeholder="Message"
                        variant="outlined"
                        margin="dense"
                        multiline
                        minRows={3}
                        helperText={`${formData.message.length}/${characterLimitState}`}
                        InputProps={{
                            sx: {
                                width: '40rem',
                                fontSize: "0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1"
                                }
                            },
                            maxLenght: characterLimitState,
                            endAdornment: <InputAdornment position="end">
                                <Typography style={{ color: "#707070", fontSize: "12px", fontFamily: "Poppins", fontWeight: 400 }}>{formData.message.length}/{characterLimitState}</Typography>
                            </InputAdornment>
                        }}
                        // InputProps={{
                        //     sx: {
                        //         width: '40rem',
                        //         fontSize: '0.875rem',
                        //         '& fieldset': {
                        //             borderColor: '#bec5d1',
                        //         },
                        //     },
                                                 
                        // }}
                    />
                    
                </Box>

            </Grid>
            <Box sx={{ display: "flex", justifyContent: "", alignContent: "", flexDirection: "row", width: "40rem", marginTop: "40px" }} >
                <Button variant="contained" style={{ backgroundColor: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "140px", fontFamily: "Poppins", fontSize: "12px", fontWeight: 600, borderRadius: "5px", padding: "15px 20px", marginRight: "20px" }}>Cancel</Button>
                <Button disabled={!buttonState} variant="contained" style={{ backgroundColor: buttonState ? "#032541" : "#99A7B2", color: "#fff", width: "140px", fontFamily: "Poppins", fontSize: "12px", fontWeight: 600, borderRadius: "5px", padding: "13px 19px 12px" }} onClick={handleSubmitMessage}>Send Message</Button>
            </Box>
        </Grid>
    )
}

export default SchoolCreateMessage