import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutletsTable from "../components/Outlets/OutletsTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { setSearch } from "../../../features/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import DeviceModelsTable from "../components/DeviceModels/DeviceModelsTable";
import { useNavigate } from "react-router-dom";
import { handleAdded, handleEdited } from "../../../features/AlertSlice";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

function DeviceModels() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const editedAlertState = useSelector((state) => state.alertState.edited);
  const addedAlertState = useSelector((state) => state.alertState.added);
  const [showEditedAlert, setShowEditedAlert] = useState(false);
  const [showAddedAlert, setShowAddedAlert] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleShowAlert = () => {
    if (editedAlertState === true) {
      setShowEditedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowEditedAlert(false);
        dispatch(handleEdited(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [editedAlertState]);

  const handleShowAddedAlert = () => {
    if (addedAlertState === true) {
      setShowAddedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAddedAlert(false);
        dispatch(handleAdded(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAddedAlert();
  }, [addedAlertState]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.deviceModels);

  const handleExport = () => {
    exportArrayToCSV(exportData, "Device Models.csv");
  };

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const nextMove = () => {
    if (clickedItem == "Edit") {
      navigate("/EditDeviceModel");
    }
  };

  useEffect(() => {
    nextMove();
  }, [clickedItem]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Device Models
          </Typography>
        </Stack>
        {showEditedAlert && (
          <Alert variant="filled" severity="success">
            Device Model details Edited successfully...
          </Alert>
        )}
        {showAddedAlert && (
          <Alert variant="filled" severity="success">
            Device Model Added successfully...
          </Alert>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Device Models" />
        <Button
          sx={{
            borderRadius: 2,
            backgroundColor: "#032541",
            color: "white",
            textTransform: "none",
            "&:hover": { color: "white", backgroundColor: "#032541" },
          }}
          onClick={() => {
            navigate("/AddDeviceModel");
          }}
        >
          Add Device Model
        </Button>
        
      </Stack>
      <Box width={"100%"}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <TextField
            id="input-with-icon-textfield"
            label="Search by Model"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearch}
            className={classes.focusedTextField}
          />
          <Button
          variant="outlined"
          sx={{
            bgcolor: "#f5f6f7",
            borderColor: "#f5f6f7",
            color: "#032541",
            ":hover": {
              borderColor: "#032541",
            },
            textTransform: "none",
          }}
          onClick={handleExport}
        >
          Export
          <MoreVertIcon
            sx={{
              color: "#032541",
            }}
          />
        </Button>
        </Stack>
        <Stack>
          <DeviceModelsTable />
        </Stack>
      </Box>
    </Stack>
  );
}

export default DeviceModels;
