import {Button, Grid, TextField} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import CustomSelectField from "../../../School/CustomSelectField";
import HttpComponent from "../../../School/MakeRequest";
import {ErrorAlert} from "../../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../../snackBar Alerts/successAlert";

export default function AddService(props){

	// Form Data
	const [unitType ,setUnitType] = useState('')
	const [serviceType, setServiceType] = useState('')
	const [serviceName, setServiceName] = useState('')
	const [amount, setAmount] = useState('')
	const [newAmount ,setNewAmount] = useState('')

	// Data variable
	const [serviceData, setServiceData] = useState([])
	const [unitServiceName, setUnitServiceNames] = useState([])
	const [unitServiceType, setUnitServiceTypes] = useState([])
	
	//Select Field Options
	const unitOptions = serviceData.map((unit) => ({value : unit._id, label : unit.unitType}))
	const serviceOptions = unitServiceName.map((service) => ({value : service, label : service}))
	const serviceTypeOption = unitServiceType.map((type) => ({value : type, label : type}))
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"
	
	const formData = {serviceType : serviceType, amount : amount === 0 ? '0' :amount , serviceName : serviceName, businessUnitTypeId : unitType}

	// Notifications
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
	const [successShow, setSuccessShow] = useState({state: false, message: ""})

	const [unitRows, setUnitRows] = useState([])

	//Get units
	function getUnits() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unit_types_by_business_unit_config?limit=1000&page=1&businessUnitConfigId=${configId}`,
			token: X_Authorization
		}).then((data)=>{
			
			console.log("here store is data", data.response.data);
			if(data.status === 201){
				setServiceData(data.response.data)
			}
			else{
				console.error("Error setting info")
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}

	// Get services
	function getServices(){
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_unity_type_services?page=1&limit=1`,
				token : X_Authorization
			}).then((data)=>{
				console.log("here store is data",data);
				if(data.status === 201){
					setUnitRows(data.response.data)
				}
				else{
					setUnitRows([])
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error Getting Services`, e.message)
		}
	}

	//Get Service names
	function ServiceName() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_rental_service_names',
				token : X_Authorization
			}).then((data)=>{
				console.log("Service names",data);
				if(data.status === 201){
					setUnitServiceNames(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error getting Service names')
		}
	}
	
	//Get Service Types
	function ServiceType() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_service_types',
				token : X_Authorization
			}).then((data)=>{
				console.log("Service Types",data);
				if(data.status === 201){
					setUnitServiceTypes(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error getting Service names')
		}
	}
	
	//Create Service
	function submitService() {
		try {
			HttpComponent({
				method: 'POST',
				url: `/api/add_business_unit_service`,
				token: X_Authorization,
				body : formData,
			}).then((data)=>{
				console.log("here is post data",data);
				if(data.status === 201){
					setSuccessShow({state : true, message: data.response.message})
					handleListingClick()
				}
				else{
					setErrorShow({state : true, message: data.response.message})
					console.error("Error setting info")
				}
			}).catch((error)=>{

				console.error(error.message);
			})
		} catch (e) {
			console.log('Error Saving Service!')
		}
	}

	function handleClickNoList () {
		console.log('formdata', formData)
		try {
			HttpComponent({
				method: 'POST',
				url: `/api/add_business_unit_service`,
				token: X_Authorization,
				body : formData,
			}).then((data)=>{
				console.log("here is post data",data);
				if(data.status === 201){
					handleNoListingClick()
					setSuccessShow({state : true, message: data.response.message})
				}
				else{
					setErrorShow({state : true, message: data.response.message})
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error Saving Service!')
		}
	}

	function handleInputCheck () {
		return !unitType.trim() ||
		!serviceType.trim() ||
		!serviceName.trim() ||
		!amount.trim()
	}

	useEffect(() => {
		getUnits()
		ServiceName()
		ServiceType()
	}, [])
	
	
	const handleListingClick = () => {
		props.setFlagStateListService('listService')
	}

	const handleNoListingClick = () => {
		setServiceType('')
		setServiceName('')
		setUnitType('')
		setAmount('')
	}

	const handleListingFlagClick = () => {
		props.setFlagListServices('listService')
	}

	const weback = ()=>{
		props.back()
	}

	// services

	useEffect(() => {
		if(serviceType === 'Variable'){
			setAmount(0)
			setNewAmount(0)
		}
		if(serviceType === 'Fixed'){
			setAmount( Number(newAmount) )
		}

	},[serviceType, newAmount, amount])


	return (
		<div>
			<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

			<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>

		<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={2} mt={4}>

			{/*header*/}
			<Grid item display={'flex'} justifyContent={'center'} alignItems={'center'} justifyItems={'center'}>
				<span>Add Service</span>
			</Grid>
			
			<Grid item>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '14px',fontWeight : 'normal'}} value={unitType} onChange={(e) => {setUnitType(e.target.value)}} name={"Unit Type"} placeholder={"Select Unit Type"} options={unitOptions} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '14px',fontWeight : 'normal'}} value={serviceName} onChange={(e) => {setServiceName(e.target.value)}} name={"Service Name"} placeholder={"Select Service Name"} options={serviceOptions} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '14px',fontWeight : 'normal'}} value={serviceType} onChange={(e) => {setServiceType(e.target.value)}} name={"Service Type"} placeholder={"Select Service Type"} options={serviceTypeOption} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
				<Grid item>
						<TextField disabled={false} value={newAmount} onChange={(e) => {setNewAmount(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "14px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Amount" variant="outlined" required />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={1}>
					<Grid item direction={'row'} justifyContent={'space-between'}>
						<Button onClick={weback} style={{
								border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Cancel
						</Button>
						<Button disabled={!unitType.trim() || !serviceType.trim() || !serviceName.trim()  }
							onClick={submitService} style={{
								marginLeft:"40px",
								opacity : !unitType.trim() || !serviceType.trim() || !serviceName.trim()  ,
								border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Save
						</Button>
					</Grid>
					{ props.isFromRentalSetup ? null :
					<Grid item>
						<Button onClick={() => {(!unitType.trim() || !serviceType.trim() || !serviceName.trim() ) ? handleListingClick() :
								submitService()

							}} style={{
								opacity : (!unitType.trim() || !serviceType.trim() || !serviceName.trim() ) ? "0.5" : "1.0",
								textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							List Services
						</Button>
					</Grid>}
				</Grid>
			</Grid>
		</Grid>
		</div>
	)
}
