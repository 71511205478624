import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomTextFieldSmall from "./CustomTextFieldSmall";
import {useDispatch, useSelector} from "react-redux";
import HttpComponent from "./MakeRequest";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import { handleIsMpesaSetup, setMpesaStatus } from '../../features/paymentOptionsSlice';
import EditMpesa from './EditMpesa';

const DarajaSetUpForm = (props) =>{
    let {setShowDarajaSetup,setSuccessShow,setErrorShow} = props
    const [pageStatus, setPageStatus] = useState("initial")
    const ExPortalForm = localStorage.getItem('exPortalForm')
    // const [mpesaData, setMpesaData] = useState([])
    const [checked, setChecked] = React.useState(true);
    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

     // force component to rerender
     const useForceUpdate = () =>{
        const [value, setValue] = useState(0);
        return () => {setValue( value => value + 1); console.log(value)};
    }

    const forceUpdate = useForceUpdate();

    // re-render
    useEffect(() =>{
        // forceUpdate();
    },[forceUpdate])


    const noStyle = {
        "borderRadius":"4px",
        "border":"solid 1px #dc3545",
        "color":"#dc3545",
        textTransform : "none",
        textDecoration : "none"
    }

    const buttonWidthHeight = {
        width:"9.063rem",
        height:"3.125rem"
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        textTransform : "none",
        textDecoration : "none",
        color:"white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const [formData, setFormData] = useState({
        businessName:"",
        businessShortCode:"",
        consumerKey:"",
        consumerSecret:"",
        accountReference:"",
        passKey:"",
        thirdPartyCallback:""
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const { userId ,X_Authorization } = useSelector((store) => store.user);
    const { mpesaStatus } = useSelector((store) => store.payment);
    
    const dispatch = useDispatch()
    // {
    //     "businessName": "Test Limited",
    //     "businessShortCode": "0000110",
    //     "consumerKey": "sv6zTGpu3TKqpu0k5kXyYM8PtjshI16XBBB",
    //     "consumerSecret": "AL5grB46Bz7goC0aBBB",
    //     "accountReference": "Test On Limited",
    //     "passKey":"f463d18174cb2ad5a2d7870b5ae1376b06cbcc686db80a0e330f9bccde141797BBB",
    //     "thirdPartyCallback":""
    // }

    const handleMpesa = () =>{
        formData.thirdPartyCallback = "https://zed.swerri.io/api/stkCallback"
        HttpComponent({
            method: 'POST',
            url: `/api/activate_daraja_for_business`,
            body: formData,
            token: X_Authorization
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 201){
                setSuccessShow({
                    state:true,
                    message:"Mpesa Configurations Added Successfully"
                })
                dispatch(handleIsMpesaSetup(true));
                dispatch(setMpesaStatus(true));
                props.setMpesaStatus(true)
                setShowDarajaSetup(false)
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
            setErrorShow({state:true, message:error.message})
        })
    }


    return(
        <>
        <Box sx={{display:"flex", justifyContent:"center"}}>
        {
            mpesaStatus === false ? (
                <Box component="div" sx={{display:"flex", flexDirection:"column"}}>
            <Box component="div" sx={{display:"flex"}}>
                <Box component="div" sx={{marginX:0.5}}>
                    <CustomInputLabel required={true} label={"Business Name"}/>
                    <CustomTextFieldSmall value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
                </Box>

                <Box component="div" sx={{marginLeft:2}}>
                    <CustomInputLabel required={true} label={"Business Shortcode"}/>
                    <CustomTextFieldSmall value={formData.businessShortCode} onChange={handleInputChange} name={"businessShortCode"} placeholder={"Short Code"} />
                </Box>
            </Box>
            <Box component="div" sx={{marginX:1}}>
                <CustomInputLabel required={true} label={"Consumer Key"}/>
                <TextField
                    name={"consumerKey"}
                    value={formData.consumerKey}
                    onChange={handleInputChange}
                    placeholder={"Consumer Key"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{display:"flex"}}>
                <Box component="div" sx={{marginX:0.5}}>
                    <CustomInputLabel required={true} label={"Consumer Secret"}/>
                    <CustomTextFieldSmall value={formData.consumerSecret} onChange={handleInputChange} name={"consumerSecret"} placeholder={"Consumer Secret"} />
                </Box>
                <Box component="div" sx={{marginLeft:2}}>
                    <CustomInputLabel required={true} label={"Account Reference"}/>
                    <CustomTextFieldSmall value={formData.accountReference} onChange={handleInputChange} name={"accountReference"} placeholder={"Account Reference"} />
                </Box>
            </Box>
            <Box component="div" sx={{marginX:1}}>
                <CustomInputLabel required={true} label={"Pass Key"}/>
                <TextField
                    name={"passKey"}
                    value={formData.passKey}
                    onChange={handleInputChange}
                    placeholder={"Pass Key"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />
            </Box>
            <Box component="div" sx={{}}>
                <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                <span>By ticking, you are agreeing to the terms and conditions.</span>
            </Box>

            {!ExPortalForm?.includes('true') ?
            <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                <Box component="div" sx={{alignSelf:"center"}}>
                    <Button onClick={()=>setShowDarajaSetup(false)} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                        Cancel
                    </Button>

                    <Button hidden={!checked} onClick={handleMpesa} sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                        Activate
                    </Button>
                </Box>
            </Box>
            :
            <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                <Box component="div" sx={{alignSelf:"center"}}>
                    <Button sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                        Deactivate
                    </Button>

                    <Button sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                        Update
                    </Button>
                </Box>
            </Box> }
        </Box>):
        <EditMpesa setSuccessShow={setSuccessShow}  setErrorShow={setErrorShow} setShowDarajaSetup={setShowDarajaSetup} />
        }
        </Box>
        </>
    )
}

export default DarajaSetUpForm;
