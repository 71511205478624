import {
    TextField,
    Stack,
    Grid,
    InputAdornment,
    Button,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import SearchIcon from "@mui/icons-material/Search";
  import MenuItem from "@mui/material/MenuItem";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  import Dialog from "@mui/material/Dialog";
  import DialogActions from "@mui/material/DialogActions";
  import DialogContent from "@mui/material/DialogContent";
  import Slide from "@mui/material/Slide";
  import { useDispatch, useSelector } from "react-redux";
  import { removeClicked } from "../../../../features/actionSlice";
  import ErrorIcon from "@mui/icons-material/Error";
  import CircleIcon from "@mui/icons-material/Circle";
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import {handleApproved, handleDeclined} from "../../../../features/AlertSlice";
import EditedTable from "./EditedTable.js";
import {handleRemove} from '../../../../features/EditedTerminalsSlice'
import { setSearch } from "../../../../features/searchSlice";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  function Edited() {
    const classes = useStyles()
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const clickedItem = useSelector(
      (state) => state.dropdownAction.clickedItem
    );
    const serialNo = useSelector((state) => state.terminalDetails.serialNo);
    const posModel = useSelector((state) => state.terminalDetails.posModel);
    const alias = useSelector((state) => state.terminalDetails.alias);
    const businessId = useSelector((state) => state.terminalDetails.businessId);
    const handleOpenDialog = () => {
      setOpen(true);
    };
    const handleOpenDialog2 = () => {
      setOpen2(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClose2 = () => {
      setOpen2(false);
    };

    const handleDecline = () => {
      dispatch(handleRemove(serialNo))
      dispatch(handleDeclined(true))
    }

    const handleApprove = () => {
      try {
        axios
        .post(baseUrl + "/api/editTerminal", {
          serialNo,
          posModel,
          alias,
          businessId,
        })
        .then((response) => {
          let data = response.data.data
          if (data) {
            console.log("edited successfully");
            dispatch(handleApproved(true))
            dispatch(handleRemove(serialNo))
          } 
        });
      } catch (e) {
        console.log(e)
      }
      
    };
    useEffect(() => {
      if (clickedItem == "Approve") {
        handleOpenDialog();
      }
      dispatch(removeClicked());
    }, [clickedItem]);

    useEffect(() => {
      if (clickedItem == "Decline") {
        handleOpenDialog2();
      }
      dispatch(removeClicked());
    }, [clickedItem]);

    const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);
  

    return (
      <Stack >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"space-between"} >
              <TextField
                id="input-with-icon-textfield"
                label="Search by serialNo"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={handleSearch}
                className={classes.focusedTextField}
              />
            
            {/* <Button
              variant="outlined"
              sx={{
                bgcolor: "#f5f6f7",
                borderColor: "#f5f6f7",
                color: "#032541",
                ":hover": {
                  borderColor: "#032541",
                },
                textTransform:"none"
              }}
            >
              Export
              <MoreVertIcon
                sx={{
                  color: "#032541",
                }}
              />
            </Button> */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <EditedTable />
          </Grid>
        </Grid>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
          <DialogContent>
            <Stack direction="row">
              <Stack
                width={"30%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <ErrorIcon
                  sx={{
                    fontSize: "100px",
                    color: "#17ae7b",
                  }}
                />
              </Stack>
              <Stack width={"70%"}>
                <Typography
                  fontFamily="Poppins"
                  fontSize={16}
                  fontWeight="600"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  Approve Terminal Edits?
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                 Selected Terminal will be edited...
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {serialNo}
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                borderRadius: 4,
                backgroundColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#032541",
                color: "#032541",
                ":hover": {
                  backgroundColor: "#032541",
                  color: "white",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleApprove();
              }}
              variant="outlined"
              sx={{
                borderRadius: 4,
                backgroundColor: "#17ae7b",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#17ae7b",
                color: "white",
                ":hover": {
                  backgroundColor: "#032541",
                  color: "white",
                },
              }}
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
            open={open2}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose2}
            aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
          <DialogContent>
            <Stack direction="row">
              <Stack
                  width={"30%"}
                  alignItems={"center"}
                  justifyContent={"center"}
              >
                <ErrorIcon
                    sx={{
                      fontSize: "100px",
                      color: "#dc3545",
                    }}
                />
              </Stack>
              <Stack width={"70%"}>
                <Typography
                    fontFamily="Poppins"
                    fontSize={16}
                    fontWeight="600"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                >
                  Decline Terminal Edits?
                </Typography>
                <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="left"
                    color="#707070"
                >
                  Selected Terminal edits will be deleted...
                </Typography>
                <Typography
                    fontFamily="Poppins"
                    fontSize={14}
                    fontWeight="normal"
                    fontStyle="normal"
                    textAlign="left"
                    color="#032541"
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {serialNo}
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={handleClose2}
                variant="outlined"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                  color: "#032541",
                  ":hover": {
                    backgroundColor: "#032541",
                    color: "white",
                  },
                }}
            >
              Cancel
            </Button>
            <Button
                onClick={() => {
                  handleClose2();
                  handleDecline();
                }}
                variant="outlined"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#dc3545",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#032541",
                    color: "white",
                  },
                }}
            >
              Decline
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    );
  }
  
  export default Edited;
  