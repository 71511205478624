import {
  TextField,
  Stack,
  Grid,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InactiveTable from "./InactiveTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { handleActivated } from "../../../../features/AlertSlice";
import { setSearch, setSelect } from "../../../../features/searchSlice";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Inactive() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currencies = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "School",
      label: "School",
    },
    {
      value: "Rental",
      label: "Rental",
    },
    {
      value: "Normal",
      label: "Normal",
    },
  ];

  const [activateOpen, setActivateOpen] = React.useState(false);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const handleOpenDialog = () => {
    setActivateOpen(true);
  };

  const handleClose = () => {
    setActivateOpen(false);
  };

  const id = useSelector((state) => state.businessDetails.id);
  const fee = useSelector((state) => state.businessDetails.fee);

  // /api/activateConvenienceFee

  const handleActivate = () => {
    try {
      axios
        .post(
          baseUrl + "/api/v1/business/convenienceFee/enable",
          {
            businessNumber: id,
            convenientFeeAmount: fee,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data
          if (data) {
            console.log("Activated successfully");
            dispatch(handleActivated(true));
          } else {
            console.log(response.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (clickedItem == "Activate") {
      // handleOpenDialog();
      navigate("/EnableFee")
    }
    // dispatch(removeClicked());
  }, [clickedItem]);

  const businessName = useSelector((state) => state.businessDetails.name);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  // const [categories, setCategories] = useState([]);
  // const [selected, setSelected] = useState("All");

  // const handleSelect = (e) => {
  //   setSelected(e.target.value);
  // };

  // const fetchBusinessCategories = () => {
  //   try {
  //     axios
  //       .get(baseUrl + "/api/fetchBusinessCategories", {
  //         headers: {
  //           "X-Authorization": localStorage.getItem("X-Authorization"),
  //         },
  //       })
  //       .then((response) => {
  //         if (response) {
  //           setCategories(response.data.data);
  //           let obj = {
  //             categoryName: "All",
  //           };
  //           setCategories((prevCategories) => [obj, ...prevCategories]);
  //         }
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   fetchBusinessCategories();
  // }, []);

  // useEffect(() => {
  //   if (selected) {
  //     if (selected === "All") {
  //       dispatch(setSelect(""));
  //     } else {
  //       dispatch(setSelect(selected));
  //     }
  //   } else {
  //     dispatch(setSelect(""));
  //   }
  // }, [selected]);

  const exportData = useSelector((state) => state.exportDetails.convenience);

  const handleExport = () => {
    exportArrayToCSV(exportData, "Inactive Convenience Fee.csv");
  };

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <TextField
              id="input-with-icon-textfield"
              label="Search by businessName"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
            />
            {/* <TextField
              select
              label="Business Type"
              defaultValue="All"
              size="small"
              value={selected}
              onChange={handleSelect}
              sx={{
                width: "150px",
              }}
            >
              {categories?.map((option) => (
                <MenuItem key={option.categoryName} value={option.categoryName}>
                  {option.categoryName}
                </MenuItem>
              ))}
            </TextField> */}
            <Button
              variant="outlined"
              sx={{
                bgcolor: "#f5f6f7",
                borderColor: "#f5f6f7",
                color: "#032541",
                ":hover": {
                  borderColor: "#032541",
                },
                textTransform: "none",
              }}
              onClick={handleExport}
            >
              Export
              <MoreVertIcon
                sx={{
                  color: "#032541",
                }}
              />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <InactiveTable />
        </Grid>
      </Grid>
      <Dialog
        open={activateOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: "100px",
                  color: "#17ae7b",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Activate Convenience Fee
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Do You Want To Activate Convenience Fee For:{" "}
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {businessName}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              // handleActivate();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#17ae7b",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#17ae7b",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Activate
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Inactive;
