import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";
import List from "@mui/material/List";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import SponsorIcon from '../images/sponsorship.svg'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function StudentSponsorship(props) {
    const classes = useStyles();
    let businessCat = localStorage.getItem("businessCategory");
    const [open, setOpen] = React.useState(false);
    const location = useLocation()

    //set active link

    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc"


    const handleClick = () => { setOpen(!open); };
    const userGroup = localStorage.getItem('group')
    const IsStoreManager = userGroup && userGroup?.toLocaleLowerCase()?.includes("storemanager")
    const isSupervisor = userGroup && userGroup === "Supervisor"
    const isZedPmAdmin = userGroup && userGroup === "ZPM School Admin"

    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)




    return (
        <div>

            {/*Users*/}
            <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
                <div style={{ marginRight: '5%' }}>
                    <Box component={'img'} src={SponsorIcon}/>
                </div>
                {businessCat === "School" ?
                    <ListItemText primary={schoolTypeName?.includes("University") ? "Student Sponsorship" : "Student Sponsorship"} style={{ fontFamily: 'Poppins' }} />
                    : businessCat === "Rental" ? <ListItemText primary="Tenants Accounts" style={{ fontFamily: 'Poppins' }} /> : null}
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/*Collapsed Users*/}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <div style={{ marginLeft: '8%' }}>
                        {userGroup === 'Merchant' || userGroup === 'Director' || isZedPmAdmin || userGroup === 'Accountant' && businessCat === 'School' ?
                                <div>
                                     <Link style={{ textDecoration: 'none', color: isActiveLink("/school/sponsors") ? activeColor : '#fff' }} to="/school/sponsors">
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Sponsors" />
                                        </ListItem>
                                    </Link>
                                    <Link style={{ textDecoration: 'none', color: isActiveLink("/school/sponsors/students") ? activeColor : '#fff' }} to="/school/sponsors/students">
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Sponsored Students" />
                                        </ListItem>
                                    </Link>
                                    <Link style={{ textDecoration: 'none', color: isActiveLink("/school/sponsors/invoices") ? activeColor : '#fff' }} to="/school/sponsors/invoices">
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Sponsorship Invoices" />
                                        </ListItem>
                                    </Link>
                                    <Link style={{ color: isActiveLink("/school/sponsors/paymenthistory") ? activeColor : '#fff', textDecoration: 'none' }} to="/school/sponsors/paymentHistory">
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Payment History"/>
                                        </ListItem>
                                    </Link>
                                </div>
                                : null
                        }
                    </div>
                </List>
            </Collapse>
        </div>
    )
}
