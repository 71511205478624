import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultPosArray: [],
  defaultInvoiceArray:[],
};

const startOrderSlice = createSlice({
  name: "startorder",
  initialState,
  reducers: {
    addStartOrderArray: (state, action) => {
      const newObj = { ...action.payload };
      if (newObj) {
        state.defaultPosArray = [...state.defaultPosArray, newObj];
      }
    },
    removeStartOrderArray:(state, action) => {
      state.defaultPosArray = [];
    },
    addItemsToInvoice:(state, action)=> {
      const newObj = {...action.payload}
      state.defaultInvoiceArray = [...state.defaultInvoiceArray, newObj]
    },
    removeInvoiceItems:(state, action) => {
      state.defaultInvoiceArray = []
    },
    doPrintDocs:(state, action) => {
      // console.log(action.payload);
      const {dateFrom,  dateTo, printPdf, customerId} = action.payload
      state.printPdf = printPdf
      state.startDate = dateFrom
      state.endDate = dateTo
      state.customerId = customerId
    },
    deleteItemFromArray:(state, action) => {
      const newObj = {...action.payload}
      // declare id and get Index;
      const currentIndex = state.defaultPosArray.indexOf((obj) => obj.checkedProductId === newObj.checkedProductId);
      const newArray = [...state.defaultPosArray];
      newArray.splice(currentIndex)
      state.defaultPosArray = newArray
    },

  },
});

export const { addStartOrderArray, removeStartOrderArray, addItemsToInvoice, removeInvoiceItems, doPrintDocs, resetPrintSettings , deleteItemFromArray  } = startOrderSlice.actions;

export default startOrderSlice.reducer;
