import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import React, { useState } from "react";
import DraftsIcon from '@mui/icons-material/Drafts';
import { SuccessAlert } from "../../components/snackBar Alerts/successAlert";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function BankStatement () {
	const classes = useStyles();
	const [successShow, setSuccessShow] = useState({ state: false, message: "" })
	const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	//to="/school/bankstatements">

	return (
		<div>
			<SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
			<Link to="/school/bankstatements" style={{textDecoration: 'none', color: isActiveLink("/school/bankstatements") ? activeColor : '#fff'}}>
				<ListItem button className={classes.nested}>
					<div style={{marginRight : '5%'}}>
						<DraftsIcon />
					</div>
					<div>
						<ListItemText primary="Bank Statements" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}