import { Grid, Breadcrumbs, Typography, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, Chip, Button } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material/styles';
import HttpComponent from "../../../School/MakeRequest";
import ClearIcon from '@mui/icons-material/Clear';
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Outlets</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Assign Services Categories</Typography>
]

export default function AssignCategory(props) {
    const theme = useTheme();
    const [categoryId, setCategoryId] = useState([]);
    const [productCategory, setProductCategory] = useState([])
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const productCategoryIds = categoryId?.map((item)=>item?._id)

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setCategoryId(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    let businessNumber = useSelector(state => state.outletDetails.businessNumber)

  //console.log(props)
    function FetchActiveCategories() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listCategories/Active/?businessNumber=${businessNumber}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'data')
                if (data?.status === 200) {
                    setProductCategory(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useState(() => {
        FetchActiveCategories()
    }, [])

  //submit user data

  let storeId = props?.outLetId
  let branchId = props?.branchId

  let Group = localStorage.getItem("group");
  const isPartner = Group && Group === "Partner";
  const isAdmin = Group && Group === "Admin";

  let storeId2 = useSelector(state => state.outletDetails.storeId)
  let branchId2 = useSelector(state => state.outletDetails.branchId)

  if (isPartner || isAdmin) {
    storeId = storeId2
    branchId = branchId2
  }


  function AssignCategoryToOutlet(){
    try {
        HttpComponent({
            method: 'POST',
            url: `/api/assignCategoryToOutlet`,
            body: {
                storeId,
                branchId,
                categoryIds:productCategoryIds,
                businessNumber
            },
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
           // console.log(data , 'data')
            if (data.status === 201) {
                setSuccessShow({state:true , message:"Categories assigned successfully"})
            }else{
                setErrorShow({state:true , message:data?.response?.message})
            }
            setTimeout(()=>{
                navigate(-1)
            },[1000])
        })
        
    } catch (error) {
        
    }
  }


    const handleDelete = (item) => {
        const result = categoryId.filter(i => i !== item)
        setCategoryId(result)
    };

    const navigate = useNavigate()
    return (
        <Grid container direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Assign Service Categories</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Assign Service Categories</Typography>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <FormControl sx={{ m: 1, width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label">Service Categories</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={categoryId}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Service Categories" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value?._id} value={value?._id} label={value?.categoryName} onDelete={()=>handleDelete(value)} deleteIcon={<ClearIcon />} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {productCategory?.filter((item) => item?.productService === 'Product')?.map((newitem) => (
                            <MenuItem
                                key={newitem?._id}
                                value={newitem}
                                style={getStyles(newitem, categoryId, theme)}
                            >
                                {newitem?.categoryName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid mt={2} item style={{ width: "51%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Reset</Button>
                <Button onClick={AssignCategoryToOutlet} style={categoryId?.length < 0 ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}