import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fname: "",
    sname: "",
    userName: "",
    email: "",
    phoneNo: "",
    userGroup: "",
    status: "",
    businessName: "",
    userId:"" ,
    businessNumber:"",
    userEmail:''
};

const userToUpdate = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.fname = action.payload.fname;
      state.sname = action.payload.sname;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.userGroup = action.payload.userGroup;
      state.status = action.payload.status;
      state.businessName = action.payload.businessName;
      state.businessNumber = action.payload.businessNumber;
      state.userId = action.payload.userId
      state.userEmail = action.payload.userEmail
    },
  },
});

export const { setDetails } = userToUpdate.actions;

export default userToUpdate.reducer;
