import React, { useEffect, useState } from 'react';


async function hmacSHA256(key, data) {
    const encoder = new TextEncoder();
    const encodedKey = encoder.encode(key);
    const encodedData = encoder.encode(data);

    const keyBuffer = await crypto.subtle.importKey(
        'raw',
        encodedKey,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
    );

    const hashBuffer = await crypto.subtle.sign(
        'HMAC',
        keyBuffer,
        encodedData
    );

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
}
const Ecitizen = () => {
    // Payload
    let payload = {
        apiClientID: "331",
        serviceID: "195471",
        billDesc: "Fees",
        currency: "KES",
        billRefNumber: "INV_9",
        clientMSISDN: "254743770216",
        clientName: "ELIJAH KUNGU",
        clientIDNumber: "36995022",
        clientEmail: "elijah.kungu@zed.business",
        callBackURLONSuccess: "https://dev.zed.business",
        amountExpected: "20",
        notificationURL: "https://dev.zed.business",
        secureHash: "",
        format: "json",
        sendSTK: "true",
        PictureURL: "https://dev.zed.business"
    };

    let secret = "OU1Rf29hECDTzFlum6Pg+DMjU7OyrgTN";
    let data = payload.apiClientID + payload.amountExpected + payload.serviceID + payload.clientIDNumber + payload.currency + payload.billRefNumber + payload.billDesc + payload.clientName + secret;
    let secureHash
    const getSecureHash = async () => {
        try {
            secureHash = await hmacSHA256("sha256", data);
            return secureHash;
            console.log(secureHash);
        } catch (error) {
            console.log(error);
        }
    }

    const submit = async () => {
        let newHash = await getSecureHash()

        let newPayload = {
            ...payload,
            secureHash: newHash
        }
        console.log("submitting", newPayload);

        try {
            const response = await fetch('https://test.pesaflow.com/PaymentAPI/iframev2.1.phpThe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newPayload)
            }).then(res => res.json())
                .then(data => console.log(data))
                .catch(err => console.log(err))
            console.log(response);
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getSecureHash()
        submit()

    }, [payload]);

    let response = `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="A fully featured admin theme which can be used to build CRM, CMS, etc." />
        <!-- Theme CSS -->
        <link rel="stylesheet" href="https://test.pesaflow.com/iframev2/assets/css/theme.min.css">
        <!-- Title -->
          <title>Pesaflow</title>
        <link rel="stylesheet" href="https://test.pesaflow.com/iframe/card_assets/card.css">
      </head>
      <body>
        <!-- MAIN CONTENT
        ================================================== -->
        <div class="main-content">
    <div id="vue-root">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 mx-auto">
          <div class="d-flex justify-content-between mb-3 mt-6">
            <a class="navbar-brand mr-auto" href="#">
                <img src="https://test.pesaflow.com/images/khusapay.svg" alt="..." class="navbar-brand-img" width="200px" height="120px">
            </a>
            <p class="mb-0 align-self-end lead">ELIJAH KUNGU, 254743770216</p>
            </div>
            <div id="accordion">
              <div class="card">
                <div class="card-body d-flex justify-content-between">
                  <div>
                    <p class="mb-1 small text-uppercase">
                      PAYMENT REF
                    </p>
                    <h2 class="mb-0">DJYMNZ</h2>
                  </div>
                  <div class="media aos-init aos-animate">
                    <div class="media-body text-right">
                      <p class="mb-1 small text-uppercase">
                        Total Bill
                      </p>
                      <h2 class="mb-0">
    KES 20.00                  </h2>
                    </div>
                    <!-- setting here -->
                  </div>
                </div>
                <!-- setting here -->
    
                  <div id="collapseOne" class="collapse bg-light" aria-labelledby="headingOne" data-parent="#accordion-disable">
                  <div class="card-body py-0">
                    <div class="list-group list-group-flush">
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col">
                            <!-- Title -->
                            <p class="mb-0">Zed Test Service</p>
                          </div>
                          <div class="col-auto">
                            <!-- Time -->
                            <p class="mb-0">
    KES 20.00                        </p>
                          </div>
                        </div>
                        <!-- / .row -->
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col">
                            <!-- Title -->
                            <p class="mb-0">Convenience Fee</p>
                          </div>
                          <div class="col-auto">
                            <!-- Time -->
                            <p class="mb-0">
    KES 0.00                        </p>
                          </div>
                        </div>
                        <!-- / .row -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header">
              <div class="header-body pb-2">
                <!-- Title -->
                <p class="mb-0">
                  Select Payment Mode
                </p>
              </div>
            </div>
            <!-- Card -->
            <ul class="row feature feature-list checkout-btns">
                  <li class="col-12 col-md-6">
                    <!-- Mpesa trigger modal -->
                    <button type="button" class="btn btn-lg btn-block btn-white mb-3" data-toggle="modal"
                    data-target="#mpesaModal1">
                      <p class="lead mb-0">
                        Mpesa
                      </p>
                    </button>
                    <mpesa-v3
                      logo="https://test.pesaflow.com/images/khusapay.svg"
                      request_type="invoice"
                      currency="KES"
                      amount_net="20"
                      fee="0"
                      gid="1"
                      shortcode=""
                      gateway="1"
                      msisdn="254743770216"
                      instructions=""
                      agent_fee="0"
                      invoice_no="DJYMNZ"
                      base_url="https://test.pesaflow.com"
                      callback_url="https://dev.zed.business"
                      fetch_payment_url="/api/payments/fetch"
                      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus">
                    </mpesa-v3>
                  </li>
    
    
    
    
    
    
    
    
    
    
    
              <!-- End of Modals -->
            </ul>
          </div>
        </div>
        <div class="row">
        <div class="col-12 col-md-8 mx-auto">
          <center><p> </p></center>
        </div>
      </div>
      </div>
      <!---End row -->
      <div id="oo">
    <pesaflow-gateway  xcsrftoken="JmA9AAAiBmklETESbgZDLj4iKRY9M3g8N-ipXRcDiEXs_tnVjKLyKtAs" 
      logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      bill_ref="INV_9"
      service_id="538"
      agent_fee="0"
      agency_url="https://test.pesaflow.com/signin"
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      mpgs_api_url="http://localhost:4003/api/payment/mpg/mpg_processor"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></pesaflow-gateway>
    <!-- Mpesa -->
    
    <!-- Debit / Credit Card / Prepaid -->
    
    <!-- Eazzypay -->
    <eazzypay-v2 
      logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></eazzypay-v2>
    <!-- AirtelMoney -->
    <airtel-v2 
      logo="https://test.pesaflow.com/images/khusapay.svg"  
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></airtel-v2>
    <!-- KCB cash -->
    <kcbcash-v2 
      logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      service_name="Zed Test Service"
      bill_ref="INV_9"
      fee="0"
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></kcbcash-v2>
    <!-- Equity cash -->
    <equitycash-v2 
      logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      service_name="Zed Test Service"
      bill_ref="INV_9"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></equitycash-v2>
    <!-- Pesalink -->
    <pesalink-v2 logo="https://test.pesaflow.com/images/khusapay.svg"
    request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></pesalink-v2>
    <!--Unionpay-->
    <unionpay-v2 logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      trxtime = '20240110155822'
      loader='https://test.pesaflow.com/images/loaders/loader6.gif'
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      merchant_id="000000059138006"
      mpgs_url="http://localhost:8080/unionpay/api/PurchaseService/new"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></unionpay-v2>
    <capitalpay-v2 logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      fee="0"
      trxtime = '20240110155822'
      loader='https://test.pesaflow.com/images/loaders/loader6.gif'
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      agent_fee="0"
      merchant_id="000000059138006"
      mpgs_url="http://localhost:8080/unionpay/api/PurchaseService/new"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"></capitalpay-v2>
    <pesaflow-account-v2 logo="https://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      customer_allowed="true"
      fee="0"
      trxtime = '20240110155822'
      loader='https://test.pesaflow.com/images/loaders/loader6.gif'
      base_url="https://test.pesaflow.com"
      callback_url="https://dev.zed.business"
      sys_name=Pesaflow
      agent_fee="0"
      merchant_id="000000059138006"
      mpgs_url="http://localhost:8080/unionpay/api/PurchaseService/new"
      status_api_url="https://test.pesaflow.com/api/payment/co/getStatus"
      partial_payment_allowed="false"
      con_key="LyEaKqURHXHoM6L/"
      con_secret="SoDkcRFgO9lzHx2b1ZhuPhXdfSUQAgw9"></pesaflow-account-v2>
    <fdh-bank-gateway logo="http://test.pesaflow.com/images/khusapay.svg"
      logo="http://test.pesaflow.com/images/khusapay.svg"
      request_type="invoice"
      currency="KES"
      amount_net="20"
      invoice_no="DJYMNZ"
      service_name="Zed Test Service"
      bill_ref="INV_9"
      fee="0"
      gateway=""
      callback_url="https://dev.zed.business"
      agent_fee="0"
      status_api_url="/api/smartpay/co/getStatus"
      payment_url="/api/fdh/make/payment">
    </fdh-bank-gateway>
      </div>
      
    
    </div>    </div>
      </div>
      <!-- / .main-content -->
      <!-- JAVASCRIPT
        ================================================== -->
      <!-- Libs JS -->
      <script src="https://test.pesaflow.com/iframev2/assets/libs/jquery/dist/jquery.min.js"></script>
      <script src="https://test.pesaflow.com/iframev2/assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="https://test.pesaflow.com/iframev2/assets/libs/jquery-mask-plugin/dist/jquery.mask.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.4/jspdf.min.js"></script>
      <!-- Theme JS -->
      <script src="https://test.pesaflow.com/iframev2/assets/js/theme.min.js"></script>
      <script src='https://test.pesaflow.com/js/vendor.js'></script>
      <script src='https://test.pesaflow.com/js/app.js'></script>
      <script src='https://test.pesaflow.com/iframe/card_assets/jquery.card.js'></script>
      <script src='https://test.pesaflow.com/iframe/card_assets/card.js'></script>
      
      <script type="text/javascript">
        if (document.getElementById('vue-root')) {
            new Vue({
                el: '#vue-root',
            });
        };
      </script>
    </body>
    </html>
    `;

   




    return (
        <iframe
          title="Ecitizen HTML"
          srcDoc={response}
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      );
};

export default Ecitizen;
// const crypto = require('crypto'); // Corrected typo here
// let data = {
//     apiClientID: "331",
//     serviceID: "195471",
//     billDesc: "Fees",
//     currency: "KES",
//     billRefNumber: "INV_9",
//     clientMSISDN: "254743770216",
//     clientName: "ELIJAH KUNGU",
//     clientIDNumber: "36995022",
//     clientEmail: "elijah.kungu@zed.business",
//     callBackURLOnSuccess: "https://dev.zed.business",
//     amountExpected: "20",
//     notificationURL: "https://api.dev.zed.business/api/v1/ecitizen/notification",
//     secureHash: "",
//     format: "html",
//     sendSTK: "true",
//     PictureURL: "https://dev.zed.business"
// }
// let ECITIZEN_SECRET = "OU1Rf29hECDTzFlum6Pg+DMjU7OyrgTN"
// let dataPass = data.apiClientID + data.amountExpected + data.serviceID + data.clientIDNumber + data.currency + data.billRefNumber + data.billDesc + data.clientName + ECITIZEN_SECRET
// // let secureHash = crypto.createHmac('sha256', dataPass)
// // // Base64.encode (secure_hash)

// // secureHash = secureHash.digest('base64')


// // console.log(secureHash)
// // return secureHash\
// const key = "RjpYGpxss6sq0xHt"
// const encrypt = (dataPass) => {
//     const hmac = crypto.createHmac('sha256', key);
//     const sig_2 = hmac.update(dataPass);
//     const sigHex = sig_2.digest('hex');
//     const sigBase64 = Buffer.from(sigHex).toString('base64');
//     console.log("SIG - HMAC - Base64 (Crypto): ", sigBase64);

// }


// encrypt(dataPass)



