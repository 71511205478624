import { Breadcrumbs, Button, Grid, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { useState } from "react";
import HttpComponent from "../School/MakeRequest";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../products/stockComponents/customDataGrid";
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Agents</Typography>,
]


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));

const ListAgents = () => {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()

    function handleFetchAgents() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/agents?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((res) => {
                if (res?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, data: res?.data?.data, count: res?.data?.count })
                }
            }).catch((err) => {
                console.log(err)
            })

        } catch (err) {
            console.log(err)
        }
    }
    const RenderCell = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };
        const handleActionClose = () => { setAnchorEl(null); };
        const navigate = useNavigate()
        return (
            <Grid item>
                <MoreVertIcon onClick={handleClick} />
                <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                    <MenuItem disableRipple onClick={() => navigate(`/agents/edit/${params?.row?.id}`)}>
                        <ModeEditIcon style={{ color: '#cb444a' }} /> <span>Edit</span>
                    </MenuItem>
                </StyledMenu>
            </Grid>
        )
    }
    const AgentsColumns = [
        { field: "agentName", headerName: "Agent Name", flex: 1 },
        { field: 'phone', headerName: 'Phone Number', flex: 1 },
        { field: "merchantcode", headerName: "Agent Merchanant Code", flex: 1 },
        { field: "glAccount", headerName: "GL Account", flex: 1 },
        { field: "action", headerName: "Action", flex: 1, renderCell: RenderCell }
    ]

    const agentsData = pageState?.data?.map((config) => ({
        id: config._id,
        agentName: config?.agentName,
        phone: config?.phone,
        merchantcode: config?.merchantcode,
        glAccount: config?.glAccount,
    }))
    return (
        <Grid direction={'column'} container spacing={2} gap={1}>
            <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Agents</Typography>
                </Stack>
            </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
                <Button onClick={() => navigate(`/agents?AddNewAgent`)} style={{ width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Create Agent</Button>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
            </Grid>
            <Grid item width={'100%'} mt={2}>
                 <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={AgentsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={agentsData} />
            </Grid>
        </Grid>
    )

}

export { ListAgents }