import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userGroups: []
}


const EditedUserGroups = createSlice({
  name:"editedUserGroups",
  initialState,
  reducers:{
    handleUserGroupEdited:(state, action) => {
        state.userGroups.push(action.payload)
    },
    handleRemove: (state, action) => {
        state.userGroups = state.userGroups.filter((item) => item.name !== action.payload)
    }

  }
})

export const { handleUserGroupEdited, handleRemove } = EditedUserGroups.actions;

export default EditedUserGroups.reducer