import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Breadcrumbs, Grid, Tab, Typography, styled } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Customer Account</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Customer Transactions</Typography>,
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));


export default function ListOrders() {
    const [tabValue, setTabValue] = useState('unpaid')
    const navigate = useNavigate()
    const [customerDetails, setCustomerDetails] = useState({})

    const handleChangeTabValue = (e, newTabValue) => {
        setTabValue(newTabValue)
    }

    const queryparams = decodeURIComponent(window.location.search)
    const state = queryparams.slice(1)
    const d = state.split("?")
    const customerId = d[0]

    const { firstName, lastName } = customerDetails

    console.log(customerDetails)

    function getCustomerDetails() {
        HttpComponent({
            method: "GET",
            url: `/api/get_customer_by_customer_id?customerId=${customerId}`,
            token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
            console.log(data, 'cus')
            if (data.status === 200) {
                setCustomerDetails(data?.response?.data)
            }
        })
    }

    useEffect(() => {
        getCustomerDetails()
    }, [])

    const [dataGridPageSize, setPageSize] = useState(5);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });



    const ordersColumns = [
        {
            headerName: "No of Items", field: 'noOfItems', headerAlign: 'left', align: 'left', flex: 1, renderHeader: (params) => { return (<strong style={{ fontSize: '16px', color: '#032541', fontWeight: 600 }} >No of Items</strong>) }, renderCell: (params) => {
                return (
                    <>
                        <span>{params?.row?.noOfItems}</span>
                    </>
                )
            }
        },
        { headerName: "Order Amount", field: 'orderAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '16px', color: '#032541', fontWeight: 600 }} >Order Amount</strong>) } },
        {
            headerName: "Payment Status", field: 'paymentStatus', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Payment Status</strong>) }, renderCell: (params) => {
                const paymentStatus = params?.row?.paymentStatus
                return (
                    <>
                        <span style={paymentStatus === 'paid' ? { color: "#17ae7b" } : { color: "#dc3545" }}>{params?.row?.paymentStatus}</span>
                    </>
                )
            }
        },
        { headerName: "Served By", field: 'servedBy', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '16px', color: '#032541', fontWeight: 600 }} >Served By</strong>) } },
        {
            headerName: "Date Created", field: 'dateCreated', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '16px', color: '#032541', fontWeight: 600 }} >Date  Created</strong>) }, renderCell: (params) => {
                return (
                    <>{DateFormatter(params?.row?.dateCreated)}</>
                )
            }
        },
    ]

    const getAllCustomersOrders = () => {
        HttpComponent({
            method: "GET",
            url: `/api/v1/customers/orders/report/byCustomerId?page=${pageState.page}&limit=${dataGridPageSize}&customerId=${customerId}`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        })
            .then((data) => {
                console.log(data, 'fffff')
                if (data.status === 200) {
                    // setPageOrders(data?.response?.data)
                    setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getAllCustomersOrders();
    }, [pageState.page, dataGridPageSize, tabValue]);

    return (
        <Grid direction={'column'}>
            <Grid item mb={2} display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={() => navigate(-1)} style={{ marginRight: "10px", cursor: "pointer" }} />
                <Typography style={{ fontSize: "25px", fontFamily: "Poppins", color: "#032541", fontWeight: 600 }}>{firstName + lastName}</Typography>
            </Grid>
            <Grid item mb={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={ordersColumns}
                    rows={pageState?.data}
                    getRowId={row => row._id}
                />
            </Grid>
        </Grid>
    )
}