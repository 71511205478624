import { Grid, Typography, Breadcrumbs, FormControl, Button, Radio } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import HttpComponent from '../MakeRequest';
import BankPng from "../../../images/bank.svg"
import MpesaPng from "../../../images/mpes.svg"
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import { Link, useNavigate } from "react-router-dom";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Configurations</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Self Ordering Setup</Typography>
]


export default function StudentOrderConfigSave() {
    const [paymentSetStudentOrder, setPaymentSet] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [kcbpay ,setkcbpay] = useState(false)
    const [mpesapay ,setmpesapay] = useState(false)
    const [selectedValue , setSelectedValue] = useState('')
    const navigate = useNavigate()

    //console.log(paymentSetStudentOrder , 'paymentSetStudentOrder')

   //  const paymentMethods = ['KCB PayBill', 'MPESA'];


   //console.log(selectedValue , 'selectedValue')

    //get the configs
    useEffect(() => {
        function handlePaymentSetStudentOrder() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getPaymentSetUp`,
                    token: localStorage.getItem('X-Authorization'),
                }).then((data) => {
                    if (data?.status === 200) {
                        setPaymentSet(data?.response?.data)
                    }
                    console.log(data, 'config found')
                })

            } catch (error) {

            }
        }
        handlePaymentSetStudentOrder()

    }, [])

    // handle disable  studet order

    function handleDisableStudentOrder(){
        try {
            HttpComponent({
                method:'POST',
                url:`/api/v1/enableZedPayIdentification`,
                body:{
                    isStudentPurchaseEnabled:false
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({state:true , message:data?.response?.message})
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }

                setTimeout(()=>{
                    navigate(-1)
                },1000)
            })
            
        } catch (error) {
            
        }
    }

    function handleUpdateStudentOrder(){
        try {
            HttpComponent({
                method:'POST',
                url:`/api/v1/enableZedPayIdentification`,
                body:{
                    paymentPurchaseSetupType:selectedValue
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({state:true , message:data?.response?.message})
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }

                setTimeout(()=>{
                    navigate(`/school/configuration`)
                },1000)
            })
            
        } catch (error) {
            
        }
    }




    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Student Self Ordering Setup</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid mt={2} mb={1}>
                <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Select where to receive payments</Typography>
            </Grid>
            {paymentSetStudentOrder?.length > 0 ? <>
                {paymentSetStudentOrder?.map((paymeth) => {
                    // const resultFound = paymentSetStudentOrder?.find((item) => item === paymeth);
                    return (
                        <Grid item mt={2} width={'40%'} display={'flex'}>
                            <FormControl style={{ display: 'flex', height: '70px', border: '2px solid rgba(112, 112, 112, 0.15)', width: '100%' }}>
                                <Grid container style={{ display: 'flex', alignContent: 'center', marginTop: 'auto', marginBottom: 'auto', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <Grid item>
                                        <img src={paymeth === 'KCBACCOUNT' ? BankPng : MpesaPng} alt='' />
                                    </Grid>
                                    <Grid item>
                                        <Typography> {paymeth === 'KCBACCOUNT' ? 'KCB Bank Mobile Money' : 'MPESA'}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Radio  value={paymeth === 'KCBACCOUNT' ? 'KCBACCOUNT' :'MPESA' } onChange={(e)=>setSelectedValue(e.target.value)} />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    )
                })}
            </> :
                <Grid item>
                    <Typography>No Payment method Configured</Typography>
                </Grid>
            }

            <Grid mt={5} item display={'flex'} justifyContent={'flex-end'}  alignItems={'center'} width={'40%'}>
                {/* <Button style={{width:'141px'  , textTransform:'inherit', color:'#dc3545' , border:'2px solid #dc3545'}} onClick={handleDisableStudentOrder}>Disable</Button> */}
                <Button disabled={!selectedValue} onClick={handleUpdateStudentOrder} style={{width:'141px'  , marginLeft:'10px', textTransform:'inherit', color:'#fff' , backgroundColor:'#032541'}}>Save</Button>
            </Grid>
        </Grid>
    )
}