import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { handleBusinessId } from "../../../../features/selectedSlice";
import { setSelectedModel } from "../../../../features/DeviceModelsSlice";


// const options = ["Option 1", "Option 2", "Option 3", "Option 4"];

function DeviceModelSelect() {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  let data = useSelector((state) => state.deviceModels.deviceModels);

  const createArray = () => {
    if (data) {
      const uniqueStrings = new Set(); // Use a Set to store unique strings
      data?.forEach((obj) => {
        let string = `${obj.deviceName}`;
        uniqueStrings.add(string); // Add each string to the Set
      });
      setOptions(Array.from(uniqueStrings)); // Convert Set back to an array and set options
    }
  };

  useEffect(() => {
    if (data.length > 0) createArray();
  }, [data]);


  useEffect(() => {
    if (selectedOption) {
        console.log("Selected Option", selectedOption)
        dispatch(setSelectedModel(selectedOption))
    //   const regex = /(\d+) -/;
    //   let match = selectedOption.match(regex);
    //   if (match) {
    //     const extractedNumber = match[1];
        // console.log(extractedNumber);
        // props.handleId(extractedNumber);
        // dispatch(handleBusinessId(extractedNumber))
    //   }
    }
  }, [selectedOption]);

  return (
    <Autocomplete
      label="POS Model"
      variant="outlined"
      fullWidth
      size="small"
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label="POS Model" variant="outlined" />
      )}
    />
  );
}

export default DeviceModelSelect;
