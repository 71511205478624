import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setDetails } from "../../../../features/BusinessModuleSlice";
import { setChange } from "../../../../features/onChangeSlice";
import zedImg from "../../../../common/images/zedPayitModule.svg";
import warehouseImg from "../../../../common/images/warehouseModule.svg";
import inventoryImg from "../../../../common/images/inventoryModule.svg";
import accountingImg from "../../../../common/images/accountingModule.svg";
import quickbooksImg from "../../../../common/images/quickbooksModule.png";
import { Stack, Typography } from "@mui/material";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function ModuleList() {
  const dispatch = useDispatch();
  //   const [checked, setChecked] = React.useState([1]);
  const [obj, setObj] = useState({
    zedPayItStatus: false,
    accountingEnabled: false,
    inventoryEnabled: false,
    warehouseOn: false,
  });

  const [modules, setModules] = useState([
    {
      name: "Zed Pocket Money",
      isEnabled: false,
    },
    {
      name: "Accounting",
      isEnabled: false,
    },
    {
      name: "Inventory",
      isEnabled: false,
    },
    {
      name: "Warehouse And Stores",
      isEnabled: false,
    },
    {
      name: "Quickbooks",
      isEnabled: false,
    },
  ]);

  const businessId = useSelector((state) => state.DetailsOfbusiness.businessId);

  const fetchBusinessModules = () => {
    try {
      axios
        .post(baseUrl + "/api/fetchBusinessModules", {
          businessId,
        })
        .then((response) => {
          let result = response.data.data[0];
          let accountingVal = result.accountingEnabled;
          let accounting = accountingVal === "true" ? true : false;
          setObj({
            zedPayItStatus: result.zedPayItStatus || false,
            accountingEnabled: accounting || false,
            inventoryEnabled: result.inventoryEnabled || false,
            warehouseOn: result.warehouseOn || false,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessModules();
  }, []);

  useEffect(() => {
    setModules([
      {
        name: "Zed Pocket Money",
        isEnabled: obj.zedPayItStatus,
      },
      {
        name: "Accounting",
        isEnabled: obj.accountingEnabled,
      },
      {
        name: "Inventory",
        isEnabled: obj.inventoryEnabled,
      },
      {
        name: "Warehouse And Stores",
        isEnabled: obj.warehouseOn,
      },
      {
        name: "Quickbooks",
        isEnabled: false,
      },
    ]);
  }, [obj]);

  const [objectToPass, setObjToPass] = useState({
    zedPayItStatus: false,
    accountingEnabled: false,
    inventoryEnabled: false,
    warehouseOn: false,
  });

  const handleToggle = (valueName, valueState) => () => {
    dispatch(setChange(true));
    const newModules = [...modules];
    const accountingModule = newModules.find(
      (module) => module.name === valueName
    );
    accountingModule.isEnabled = !valueState;

    setModules(newModules);

    setObjToPass({
      zedPayItStatus: newModules[0].isEnabled,
      accountingEnabled: newModules[1].isEnabled,
      inventoryEnabled: newModules[2].isEnabled,
      warehouseOn: newModules[3].isEnabled,
    });
  };

  useEffect(() => {
    dispatch(setDetails(objectToPass));
  }, [objectToPass]);

  let x = "#032541"

  return (
    <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
      {modules.map((value) => {
        console.log(value.name);
        const labelId = `checkbox-list-secondary-label-${value.name}`;
        return (
          <ListItem
            key={value.name}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(value.name, value.isEnabled)}
                checked={value.isEnabled}
                style={{ color: "#032541" }}
              />
            }
            disablePadding
            sx={{mt: 1}}
          >
            <ListItemButton>
              <Stack direction="row" spacing={2}>
                {value.name === "Zed Pocket Money" ? (
                  <img alt="Remy Sharp" src={zedImg} />
                ) : null}
                {value.name === "Warehouse And Stores" ? (
                  <img alt="Remy Sharp" src={warehouseImg} />
                ) : null}
                {value.name === "Inventory" ? (
                  <img alt="Remy Sharp" src={inventoryImg} />
                ) : null}
                {value.name === "Accounting" ? (
                  <img alt="Remy Sharp" src={accountingImg} />
                ) : null}
                {value.name === "Quickbooks" ? (
                  <img alt="Remy Sharp" src={quickbooksImg} />
                ) : null}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "left",
                    color: "#707070",
                  }}
                >
                  {value.name}
                </Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
