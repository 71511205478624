import React, { useState } from "react";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { Box, Button, FormControl, InputLabel, TextField } from "@mui/material";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import HttpComponent from "../MakeRequest";

const CopMobileMoney = ({ setCopMobileChecked }) => {
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const buttonStyles = {
    width: "7.813rem",
    height: "2.813rem",
    borderRadius: "4px",
  };
  const [coopconfigdetails, setCoopConfigDetails] = useState({ clientSecret: "", clientId: "", OperatorCode: "", confirmOperatorCode: "" });

  const getconfigdetails = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setCoopConfigDetails({ ...coopconfigdetails, [name]: value });
  };

  const saveMobileMoneyDetails = () => {
    if (coopconfigdetails?.clientSecret === "") {
      setErrorShow({ state: true, message: "Missing clientSecret" });
      return;
    }
    if (coopconfigdetails?.OperatorCode === "") {
      setErrorShow({ state: true, message: "Missing OperatorCode" });
      return;
    }
    if (coopconfigdetails?.clientId === "") {
      setErrorShow({ state: true, message: "Missing clientId" });
      return;
    }

    HttpComponent({
      method: "POST",
      url: `/api/v1/setupcoopconfigs`,
      body: coopconfigdetails,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setSuccessShow({ state: true, message: "Config set successfully" }); 
          setCoopConfigDetails({ confirmOperatorCode: "", clientSecret: "", OperatorCode: "", clientId: "" });
          setTimeout(() => {
            setSuccessShow({ state: false, message: "" });
            setCopMobileChecked(false);
          }, 2000);
        } else {
          setErrorShow({ state: true, message: data?.response?.message });

          setTimeout(() => {
            setErrorShow({ state: false, message: "" });
          }, 2000);
        }
      })
      .catch((e) => {
        setErrorShow({ state: true, message: e?.message });

        setTimeout(() => {
          setErrorShow({ state: false, message: "" });
        }, 2000);
      });
  };

  return (
    <Box sx={{ display: "flex", width: "100%", padding: "26px" }}>
      <form style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Client Secret</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="clientSecret" value={coopconfigdetails?.clientSecret} multiline required />
        </FormControl>

        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Client Id</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="clientId" value={coopconfigdetails?.clientId} multiline required />
        </FormControl>

        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Operator Code</InputLabel>
          <TextField onChange={getconfigdetails} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="OperatorCode" value={coopconfigdetails?.OperatorCode} multiline required />
        </FormControl>

        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
          <InputLabel style={{ fontSize: "16px" }}>Verify Operator Code</InputLabel>
          <TextField onChange={getconfigdetails} color={coopconfigdetails?.OperatorCode !== coopconfigdetails?.confirmOperatorCode ? "error" : "primary"} style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="" type="text" name="confirmOperatorCode" value={coopconfigdetails?.confirmOperatorCode} multiline required />
        </FormControl>

        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: "0px", paddingBottom: "20px" }}>
          <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end", marginTop: "30px" }}>
            <Box sx={{ display: "flex" }}>
              {/* <Button sx={{
                            ...buttonStyles,
                            border: " solid 1px #002543",
                            color: "#002543",
                            '&:hover': {
                                backgroundColor: "transparent",
                                color: '#002543'
                            }
                        }}
                            onClick={() => showKCBMobileMoneySetup(false)}
                        >
                            Cancel
                        </Button> */}

              <Button
                sx={{
                  ...buttonStyles,
                  marginLeft: 1,
                  backgroundColor: " #032541",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "white",
                  },
                }}
                onClick={() => saveMobileMoneyDetails()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </div>
      </form>
    </Box>
  );
};

export default CopMobileMoney;
