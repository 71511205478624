import React , {useState, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import deactivateGroup from "../../../common/images/deactivate.svg";
import success from "../../../common/images/success.png";

function QuestionModal(props){

    // session Expired
    const style = {borderColor:"transparent", borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "350px", p: 4,};

    // Deactivate  Modal Config
    // const [deactivateModalOpen , setDeactivateModalOpen] = useState(props.deactivateModalOpen);
    // const DeactivateModalHandleClose = () => setDeactivateModalOpen(false)

    return (
        <div>
            <Modal
                open={props.deactivateModalOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <form onSubmit={props.formAction}>
                        <div style={{width: "50px", height: "50px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
                            <img style={{width:"115px", height:"115px",margin: "-24px 0px 0px -66px"}} src={props.actionImage} alt='img'/>
                        </div>
                        <div style={{height: "50px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
                            <span>
                                Do you want to {props.action} <b>{props.item}?</b>
                            </span>
                        </div>
                        <div align='right'>
                            <Button onClick={props.onClose} variant='outlined' style={{fontFamily : "Poppins" , fontSize : "12px" , textAlign : "left" , color : "#dc3545" , width : "90px" , height : "40px" , margin : "40px 30px 0 0" , padding : "9px 29px 8.1px 30px" , border : "solid 1px #dc3545"}}>
                                Cancel
                            </Button>
                            {/*  save  */}
                            <Button type='submit' variant='standard' style={{color : '#fff' , fontFamily : "Poppins" , fontSize : "12px" , width : "90px" , height : "40px" , margin : "40px 50px 0 30px" , padding : "9px 32px 8.1px 31px" , backgroundColor : "#032541"}}>
                                {props.processName}
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
            {/*/!*Deactivate Modal*!/*/}
            {/*<Modal centered align={'center'} className='container container-fluid' open={deactivateModalOpen}*/}
            {/*       onClose={props.onClose} aria-labelledby="modal-modal-title"*/}
            {/*       aria-describedby="modal-modal-description">*/}
            {/*    <div style={{width : "553px" , height : "306px" , margin : "10% 23px 8px 39px" , padding : "0 0 57.9px" , borderRadius : "15px" , backgroundColor : "#fff"}}>*/}
            {/*        <div style={{width : "553px" , height : "90px" , margin : "0 0 50px" , padding : "35px 36px" , borderRadius : "16px" , backgroundColor : "#dc3545"}}>*/}
            {/*            <label style={{height : "20px" , margin : "0 325px 0 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "600" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#fff"}}>*/}
            {/*                {props.action} {props.title}*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <form onSubmit={props.formAction}>*/}
            {/*                <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}*/}
            {/*                     src={deactivateGroup} alt={'activate Group'}/>*/}
            {/*                <label style={{height : "24px" , margin : "0 1.1px 0 14px" , fontFamily : "Poppins" , fontSize : "17px" , fontWeight : "500" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "1.47" , letterSpacing : "normal" , textAlign : "left" , color : "#032541"}}>*/}
            {/*                    Do you want to {props.action} <b>{props.item}?</b>*/}
            {/*                </label>*/}
            {/*                <div align='right'>*/}
            {/*                    <Button onClick={props.onClose} variant='outlined' style={{fontFamily : "Poppins" , fontSize : "11px" , textAlign : "left" , color : "#dc3545" , width : "90px" , height : "33.1px" , margin : "30px 30px 0 0" , padding : "9px 29px 8.1px 30px" , border : "solid 1px #dc3545"}}>*/}
            {/*                        Cancel*/}
            {/*                    </Button>*/}
            {/*                    /!*  save  *!/*/}
            {/*                    <Button type='submit' variant='standard' style={{color : '#fff' , fontFamily : "Poppins" , fontSize : "11px" , width : "90px" , height : "33.1px" , margin : "30px 70px 0 30px" , padding : "9px 32px 8.1px 31px" , backgroundColor : "#032541"}}>*/}
            {/*                        {props.processName}*/}
            {/*                    </Button>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </div>
    )
}
export {QuestionModal};