import HttpComponent from "../../School/MakeRequest";

export default class CustomeronAccountFunctions{
    constructor(){

    }

    getDiscountName(){
        return HttpComponent({
            method:'GET',
            url:`/api/v1/invoice/discount-names`,
            token:localStorage.getItem('X-Authorization')
        })
    }

    getDiscountType(){

        return HttpComponent({
            method:'GET',
            url:`/api/v1/invoice/discount-type`,
            token:localStorage.getItem('X-Authorization')
        })

    }

    postDiscount(invoiceNumber, discountName, discountType, discountAmount, discountPercent){
        return HttpComponent({
            method:'POST',
            url:`/api/v1/invoice/add-discount`,
            body:{
                invoiceNumber,
                discountName,
                discountType,
                discountAmount,
                discountPercent
            },
            token:localStorage.getItem('X-Authorization')
        })
        
    }

    deleteInvoiceDiscount(invoiceNumber){
        return HttpComponent({
          method:'POST',
          url: `/api/v1/invoice/delete-discount`,
          body:{invoiceNumber},
          token:localStorage.getItem('X-Authorization')
        })
      }
}