import { Box} from '@mui/material'
import { useEffect, useState } from 'react'
import AddBusinessSaccoDetails from './AddbusinessDetails'
export default function TransportBusinessSetUp() {

    return (
        <Box component={'div'} width={'100%'}>
           <AddBusinessSaccoDetails/>
        </Box>
    )
}