import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoutes = () => {
  const { isLoggedIn,group, businessCategory} = useSelector((store) => store.user);
  const workflow = localStorage.getItem('workflow')
return (
    (isLoggedIn && group === 'Merchant') &&
    (businessCategory?.toLowerCase()?.includes('rental')) &&
    (!workflow?.toLowerCase()?.includes('complete')) ? <Navigate to='/setup/rental/rentalSetup'/> :
        (workflow?.toLowerCase()?.includes('complete')) ? <Navigate to='/dashboard' /> :
  isLoggedIn && group !== "User" ? (group === "Admin" ? <Navigate to='/adminDashboard' /> :( (group === 'TransportPartner' ) ||  (group === 'Merchant' &&  businessCategory === 'Transport')) ? <Navigate to='/partnerstransport' />:<Navigate to='/dashboard' /> ): <Outlet />
  )
};

export default PublicRoutes;
