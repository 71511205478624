import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branchId: "",
  storeId: "",
  businessNumber: ""
}


const OutletsSlice = createSlice({
  name:"outletDetails",
  initialState,
  reducers:{
    setOutletDetails:(state, action) => {
      state.branchId = action.payload.branchId
      state.storeId = action.payload.storeId
      state.businessNumber = action.payload.businessNumber
    },
    
  }
})

export const {  setOutletDetails } = OutletsSlice.actions;

export default OutletsSlice.reducer