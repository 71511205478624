import React, { useState, useEffect } from "react";
import { Grid, Button, Breadcrumbs, Tab, styled } from "@mui/material";
import { ArrowBack, NavigateNext } from "@mui/icons-material";
import { warehousestyles } from "./styles";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useNavigate } from "react-router";
import Activesubstores from "./substorecomponents/activesubstore";
import Suspendedsubstores from "./substorecomponents/suspendedsubstores";
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";


const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const Substores = () => {
 
    const navigate = useNavigate();
    const [substoresTab, setSubstores] = useState("Active");
  
    const handleSubstorechange = (event, newTabValue) => {
        setSubstores(newTabValue)
    }


    const [searchValue, setInputValue] = useState('')

    return (
      <div style={warehousestyles.mainDivStyling}>
        {" "}
        <Grid container width={"100%"} justifyContent={"space-between"} direction={"row"} alignItems={"center"} style={warehousestyles.gridContainerMargin}>
          <Grid item>
            <span style={warehousestyles.supplierText}>Substores</span>
          </Grid>
  
          <Grid item>
            <Button onClick={() => navigate(`/addsubstore/addsubstore`)} style={warehousestyles.addSupplierBtn}>
              Add Substores
            </Button>
          </Grid>
        </Grid>
        <Grid container style={warehousestyles.gridContainerMargin}>
          <Grid item>
            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
              <span>Dashboard</span>
              <span style={{ color: "red" }}>Substores</span>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container style={warehousestyles.gridContainerMargin} width={"100%"}>
          <Grid item width={"100%"}>
            <TabContext value={substoresTab}>
              <AntTabs onChange={handleSubstorechange}>
                <AntTab label="Active" value="Active" />
                <AntTab label="Disabled" value="Suspended" />
  
                {/* <AntTab label="Expired" value="Expired" /> */}
              </AntTabs>
  
              {/* search , filter,  */}
              <Grid container >
                <SearchFilter placeholder={'Search substore'} setInputValue={setInputValue}  />
              </Grid>
              {/* active tab panel display */}
              <TabPanel value={"Active"}>
                <Activesubstores  searchValue={searchValue} />
             
              </TabPanel>
  
              {/* suspended! */}
              <TabPanel value={"Suspended"}>
                <Suspendedsubstores searchValue={searchValue} />
    
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </div>
    );
}

export default Substores