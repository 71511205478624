import { Grid ,Typography , Breadcrumbs, Checkbox, TextField, FormControlLabel, Button  } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const breadcrumbs = [
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Charges
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Add Charges
    </Typography>
]

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);


export default function AddMoreChargesToTenant(props) {
    const [unitCharges ,setUnitCharges] = useState([])
    const {X_Authorization} = useSelector((store)=>store.user)
    let [util, setUtil] = useState([])
    const {houseType ,billingPeriod ,unit_amount,houseNo,unitPurpose ,rentDueDate} = useSelector((store)=>store.tenantData.tenantTransferHouse)
	const [selectedItems, setSelectedItems] = useState([]);
    const {id} = useParams()

    const handleCheckboxChange = (event, item) => {
		if (event.target.checked) {
			setSelectedItems([...selectedItems, item]);
			const object = {"amount" : item.amount, "name" : item.serviceName, "productId" : item.productId, "serviceType" : item.serviceType}
			setUtil([...util, object])
		} else {
			setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
			setUtil(util.filter((service) => service.productId !== item.productId))
		}
	};

    	
	//Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method : 'GET',
			url : `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${props?.units}`,
			token : X_Authorization
		}).then((data)=>{
			if(data.status === 201){
				setUnitCharges(data.response.data)
			}else{
				console.log(`Error getting data`)
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}

    useEffect(()=>{
        fetchServicesPerUnit()
    },[])

    useEffect(()=>{
    },[selectedItems])

    let services = selectedItems?.map((service)=>{
        return{
            name:service?.serviceName,
            productId:service?.productId,
            amount:service?.amount
        }
    })

    let formData = {
        tenantId: id,
        houses: [{
            unitType: houseType,
            itemNumber: houseNo,
            amount: unit_amount,
            unitPurpose:unitPurpose,
            billingDate: billingPeriod,
            rentDueDate: rentDueDate,
            extraServices:services
        }]
    }
console.log(formData, 'post')
   

   function handleAssignTenantAnotherHouse(){
       try {
           HttpComponent({
               method : 'POST',
               url : '/api/add_tenant',
               body : formData,
               token : X_Authorization
           }).then((data)=>{
              if(data.status === 200 || 201){
                    setTimeout(()=>{
                        window.location.reload()
                    },[])
              }
           }).catch((error)=>{
               console.error(error.message);
           })
       } catch (e) {
           console.log(`Error saving tenant`, e.message)
       }

   }

    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={props?.prev} />
                <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Add Charges</Typography>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={4}>
					<Grid container direction={'row'} justifyContent={'space-between'} spacing={4}>
						{
							unitCharges.map((data, index) => (
								<>
									{/*Rent Deposit*/}
									<Grid key={index} item>
										<Grid container direction={'row'} spacing={4} justifyContent={'space-between'}>
											{/*Checkbox and Label*/}
											<Grid item>
												<FormControlLabel label={
													<Grid container direction={'row'} justifyContent={'space-between'}>
														
														{/*Label*/}
														<Grid item>
															<span style={{color : '#6e7074', fontSize : '14px', fontFamily : 'Poppins'}} >{data.serviceName}</span>
														</Grid>
													
													</Grid>
												} control={
													<Checkbox style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} edge="start" checked={selectedItems.includes(data)} onChange={event => handleCheckboxChange(event, data)} />}/>
											</Grid>
											
											{/*Input Box*/}
											<Grid item>
												<TextField disabled required readOnly size="small" value={numberFormat(data.amount)} defaultValue={numberFormat(data.amount)} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '212px'}} id="outlined-basic" label="Amount" variant="outlined" />
											</Grid>
										</Grid>
									</Grid>
								</>
							))
						}
					</Grid>
				</Grid>
                <Grid mt={3} display={'flex'} justifyContent={'flex-end'} item>
                    <Button onClick={handleAssignTenantAnotherHouse}  style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
                        Save
                    </Button>
                </Grid>
        </Grid>
    )
}