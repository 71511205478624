import { Box, Breadcrumbs, Button, Divider, Grid, Menu, MenuItem, Tab, TextField, Typography, TableBody, TableRow, TableCell, Table } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomInputLabel from "../../School/CustomInputLabel";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../School/CustomTextField";
import { useState, useEffect, useRef } from "react";
import CustomSelectField from "../../School/CustomSelectField";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { set } from "lodash";
import icon from "../../../components/School/Images/upload.svg"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Supplier Payments</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transaction  Details</Typography>,

]
const breadcrumbsUpload = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Supplier Payments</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Upload image</Typography>

]
const numberFormat = (value) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(value) }
const PreviewRaiseSupplierPayment = (props) => {
    const navigate = useNavigate()
    const transcationDetails = JSON.parse(localStorage.getItem('supplierPayment'))
    const [transcationTypeOptions, setTranscationTypeOptions] = useState([])
    const [debitAccountOptions, setDebitAccountOptions] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [showUpload, setShowUpload] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [payToOptions, setPayToOptions] = useState([{ label: `Phone  +${transcationDetails?.phoneNumber}`, value: "phone" },
    { label: `${transcationDetails?.bankName} - ${transcationDetails?.accountNumber}`, value: "accountNumber" },])



    let invoicesIds = []
    transcationDetails?.selectedInvoices?.map((item) => {
        invoicesIds.push(item?.invoiceId)
    })
    const [raisepaymentButtonState, setRaisepaymentButtonState] = useState(false)
    const [formData, setFormData] = useState({
        transactionType: "",
        debitBankName: "",
        debitBankCode: "",
        debitAccountNumber: "",
        supplierId: transcationDetails?.selectedSupplierId,
        description: "",
        invoiceIds: invoicesIds,
        payTo: "",
        fileName: ''
    })
    const [img, setImg] = useState(null);
    const logoImg = localStorage.getItem('logoImage');
    useEffect(() => {
        setImg(localStorage.getItem('logoImage'));
    }, []);

    const getTranscationDetails = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transcation_type`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'data getTranscationDetails');

                if (data.status === 200) {
                    let transcationType = data.response?.data
                    transcationType = transcationType.map((item) => {
                        return { label: item?.transactionName, value: item?.transactionType }
                    })
                    setTranscationTypeOptions(transcationType)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getTranscationDetails()
    }, [])
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const toSetBankNameAndCode = () => {
        const bankDetails = debitAccountOptions.find((item) => item.value === formData.debitAccountNumber)
        // console.log(bankDetails, 'bankNamebankName');
        setFormData({ ...formData, debitBankCode: bankDetails?.bankCode, debitBankName: bankDetails?.bankName })
    }
    useEffect(() => {
        toSetBankNameAndCode()
    }, [formData.debitAccountNumber])
    const getDebitAccountDetails = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getfunds_transfer_config`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data, 'data getTranscationDetails');

                if (data.status === 200) {
                    let debitAccountDetails = data.response?.data
                    // console.log(debitAccountDetails, 'debitAccountDetails');
                    debitAccountDetails = debitAccountDetails.map((item) => {
                        return { label: item.bankName + "-" + item.accountNumber, value: item.accountNumber, bankCode: item.bankCode, bankName: item.bankName }
                    })

                    setDebitAccountOptions(debitAccountDetails)

                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getDebitAccountDetails()
    }, [])
    const raisepaymentButtonStateMethod = () => {
        if (formData.transactionType !== "" && formData.debitAccountNumber !== "" && formData.description !== "") {
            setRaisepaymentButtonState(true)
        } else {
            setRaisepaymentButtonState(false)
        }
    }
    useEffect(() => {
        raisepaymentButtonStateMethod()
    }, [formData.transactionType, formData.debitAccountNumber, formData.description])

    const baseUrl  = process.env.REACT_APP_BASE_URL

    const handleFileUpload = async (file) => {
        if (!file) {
            console.log('No file selected, skipping upload.');
            await raisePayment();
            return;
        }
        const url = `${baseUrl}/api/v1/process/upload_receipt`;
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
      
        formData.append('file', file);
      
        return new Promise((resolve, reject) => {
            xhr.open('POST', url, true);
            xhr.setRequestHeader("X-Authorization", localStorage.getItem('X-Authorization'));
            
            xhr.onload = function() {
                if (xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    console.log('data-text', data);
                    setSuccessShow({ state: true, message: 'Receipt uploaded successfully' });
                    resolve(data.data);
                } else {
                    setErrorShow({ state: true, message: 'Failed to upload receipt' });
                    reject(new Error('Failed to upload receipt'));
                }
                setSelectedFile(null);
                setFile(null);
            };
          
            xhr.onerror = function() {
                console.error('An error occurred while uploading the file');
                reject(new Error('An error occurred while uploading the file'));
            };
          
            xhr.send(formData);
        });
    };
    
    const raisePayment = () => {
        console.log(formData, 'formDataformData');
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/process/funds_transfer`,
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'data getTranscationDetails');
    
                if (data.status === 200 || data.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: "Payment Successfully raised" })
                    // remove otherPayments from local storage
                    localStorage.removeItem('supplierPayment')
    
                    navigate('/payables/transcations')
                } else {
                    console.log(data?.response?.message, 'data getTranscationDetails')
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.message })
                }
            }).catch(error => {
                setErrorShow({ ...errorShow, state: true, message: error?.response?.error })
            });
        } catch (error) {
            setErrorShow({ ...errorShow, state: true, message: error?.message });
        }
    };    
    
    const [transcationTypeState, setTranscationTypeState] = useState(false)
    const handleDrop = (e) => {
        e.preventDefault();
        handleFileInputChange(e);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const uploadedImage = useRef(null);
    const imageUploader = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const [receipt, setReceipt] = useState('')
    const uploadFile = async () => {
        console.log("file", file);
        if (!file) {
            console.log('No file selected');
            return;
        }
        try {
            const newPath = await handleFileUpload(file);
            if (newPath) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    fileName: newPath,
                }));
                
                setReceipt(file); 
    
                setTimeout(() => {
                    setShowUpload(false);
                    const childData = { file: file };
                    // console.log('childData', childData)
                    // props?.getFile(childData);
                }, 1000);
            } else {
                setErrorShow({ state: true, message: "File upload failed. Payment not raised." });
            }
        } catch (error) {
            setErrorShow({ state: true, message: error.message });
        }
    };
    
    
    // const uploadFile = () => {
    //     console.log("file", file);
    //     setImg("");
    //     localStorage.setItem('logoImage', "");
    //     localStorage.setItem('receipt', selectedFile);

    //     console.log(uploadedImage.current.src);
    //     console.log(img);
    //     setReceipt(selectedFile);

    //     setTimeout(() => {
    //         setShowUpload(false);
    //         const childData = { file: selectedFile };
    //         // console.log('childData', childData)
    //         // props?.getFile(childData); 
    //     }, 1000);
    // }

    console.log(selectedFile, 'selectedFile')
    console.log(props, 'Props')

    const handleFileInputChange = (e) => {
        const [file] = e.target.files;
        console.log("file", e.target.files)
        console.log("uploadedImage", uploadedImage)
        console.log("imageUploader", imageUploader)
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            const selectedFile = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            setFile(file);
            console.log('file....', file)
            setSelectedFile(selectedFile);
            setFormData((prevFormData) => ({ ...prevFormData, fileName: file }));
        }
    };

    const handleChangePayOption = () => {
        if (formData.payTo === "phone") {
            setFormData({ ...formData, transactionType: "MO" })

        }
        // clear the transaction type
        else {
            setFormData({ ...formData, transactionType: "" })
        }
    }

    useEffect(() => {
        handleChangePayOption()
    }, [formData.payTo])

    return (
        <Grid container>
            {showUpload ? <>
                <Grid>
                    <Grid item mt={1}>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }} onClick={() => navigate(-1)}> <ArrowBackIos /> Upload Image</Typography>
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbsUpload}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Grid mt={3}>
                        <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#000", margin: " 30px 53px 20px 68px" }}>Upload your Receipt</Typography>

                    </Grid>
                    <Grid >
                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", margin: " 20px 24px 20px 39px" }}>Have a receipt? Upload it now!</Typography>
                    </Grid>

                    <Grid sx={{ width: "350px", height: "400px", margin: "20px 792px 20px 40px", padding: "8px 10px 10px", borderRadius: "12px", border: " solid 1px #e0e0e0", backgroundColor: "#fff" }}>
                        <Box sx={{ borderRadius: "6px", border: "solid 1px #e1e1e1", backgroundColor: "rgba(3, 37, 65, 0.02)", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                            {!logoImg ? (
                                <Box sx={{ display: "flex", alignSelf: "center", flexDirection: "column", justifyContent: "center", width: "15.375rem", height: "24.269rem", padding: "20px 16.5px 46.3px 15.5px" }}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <img style={{
                                            width: "15.375rem", height: "10.269rem", objectFit: "contain", display: "flex", flexDirection: "column", justifyContent: "center",



                                        }}
                                            onClick={() => imageUploader.current.click()}
                                            alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                                    </Box>
                                    {file === null ? (
                                        <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                            <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                                                Drag or drop your file here
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                            <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                                                {file?.name}
                                            </Typography>
                                        </Box>
                                    )}
                                    {/* {!logoImg==='' ? null : (
                        <Box sx={{ alignSelf: "center", marginY: 1 }}>
                        <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                            Drag or drop your file here
                        </Typography>
                        </Box>
                    )} */}

                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple="false"
                                        onChange={handleFileInputChange}
                                        style={{ display: "none" }}
                                        ref={imageUploader}
                                    />


                                </Box>
                            ) : (
                                <Box sx={{ display: "flex", alignSelf: "center", flexDirection: "column", justifyContent: "center", width: "15.375rem", height: "24.269rem", padding: "20px 16.5px 46.3px 15.5px" }}
                                >
                                    <img style={{ width: '56.5px', height: '56.5px', objectFit: 'contain' }} onClick={() => imageUploader.current.click()} alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                                </Box>


                            )}

                        </Box>

                    </Grid>
                    <Grid sx={{ margin: "20px 792px 20px 40px", }} >
                        <Grid sx={{ display: "flex", flexDirection: "row", }}>
                            <CheckCircleOutlineIcon sx={{ color: "#17AE7B", marginRight: "10px" }} />
                            <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", }}>  Accepted file formats: .png or .jpg</Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                            <CheckCircleOutlineIcon sx={{ color: "#17AE7B", marginRight: "10px" }} />
                            <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", }}>  Max. File size: 1MB</Typography>
                        </Grid>
                    </Grid>
                    <Grid sx={{ margin: "20px 792px 20px 40px" }}>
                        {file === null ? (
                            <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", borderRadius: "12px", width: "350px", height: "50px", textTransform: "none", fontSize: "16px", fontWeight: 600, }} onClick={() => imageUploader.current.click()}>
                                Browse Files {logoImg}
                            </Button>
                        ) : (
                            <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", borderRadius: "12px", width: "350px", height: "50px", textTransform: "none", fontSize: "16px", fontWeight: 600, }} onClick={() => { uploadFile(); setShowUpload(false); }}>
                                Upload File
                            </Button>
                        )}
                    </Grid>

                </Grid>
            </> :
                <>
                    <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                    <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                    <Grid item mt={1}>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Raise Payment</Typography>
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Grid item >
                        {/* {console.log(transcationDetails, 'transcationDetails <<<<>>>>')} */}

                        <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>Payee Details</p>
                    </Grid>
                    <Grid container >
                        <Table style={{ width: "600px" }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Payment To : </TableCell>
                                    <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.selectedName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Amount : </TableCell>
                                    <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}> {numberFormat(transcationDetails?.totalInvoicesAmount)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Payment Mode </TableCell>
                                    <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.bankName ? transcationDetails?.bankName + " " + "Bank" : "-"} </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid container direction={'column'} style={{ width: "600px" }}>
                        <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>Transaction Details</p>
                        <CustomSelectField medium value={formData.payTo} onChange={handleInputChange} name={"payTo"} placeholder={"Sent To"} options={payToOptions} />

                        <CustomInputLabel required={true} label={"Transcation Type"} />
                        {formData.payTo === "phone" ? <TextField
                            // name={"transactionType"}
                            value={"MOBILE MONEY"}
                            // onChange={handleInputChange}
                            // placeholder={"Description"}
                            disabled={true}
                            variant="outlined"
                            margin="dense"
                            sx={{ marginBottom: 1 }}
                            multiline
                            minRows={1}
                            InputProps={{
                                sx: {
                                    width: "400px",
                                    fontSize: "0.875rem",
                                    "& fieldset": {
                                        borderColor: "#bec5d1",
                                    },
                                },
                            }}
                        /> :
                            <CustomSelectField medium value={formData.transactionType} onChange={handleInputChange} name={"transactionType"} placeholder={"Select Transaction Type"} options={transcationTypeOptions} />
                        }

                        <CustomInputLabel required={true} label={"Account To Debit"} />
                        <CustomSelectField medium value={formData.debitAccountNumber} onChange={handleInputChange} name={"debitAccountNumber"} placeholder={"Select Account To Debit"} options={debitAccountOptions} />
                        <CustomInputLabel required={true} label={"Description"} />

                        <TextField
                            name={"description"}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={"Description"}
                            variant="outlined"
                            margin="dense"
                            sx={{ marginBottom: 1 }}
                            multiline
                            minRows={1}
                            InputProps={{
                                sx: {
                                    width: "400px",
                                    fontSize: "0.875rem",
                                    "& fieldset": {
                                        borderColor: "#bec5d1",
                                    },
                                },
                            }}
                        />
                        <Grid container direction={'column'} style={{ width: "600px" }}>
                            {receipt ? <>
                                <Box component="div" style={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between", height: "50px", padding: "10px 10px 10px 0px", border: "1.5px dotted #707070", marginTop: "10px" }}>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", paddingLeft: "10px" }}>Attached Receipt</Typography>
                                    </Box>

                                    <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", cursor: "pointer" }} >receipt.png</Typography>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", cursor: "pointer" }} ><CloseOutlinedIcon onClick={() => {
                                            setReceipt("")
                                            localStorage.setItem("receipt", "")
                                        }} /></Typography>

                                    </Box>

                                </Box>

                            </>
                                : <>
                                    <Box component="div" style={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between", height: "50px", padding: "10px 10px 10px 0px", border: "1.5px dotted #707070", marginTop: "10px" }}>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                                            <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", paddingLeft: "10px" }}>Attach an Image</Typography>
                                        </Box>

                                        <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                                            <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", cursor: "pointer" }} onClick={() => setShowUpload(true)} >Upload File</Typography>

                                        </Box>

                                    </Box>
                                </>
                            }
                            {
                                raisepaymentButtonState ? <Button variant="contained" style={{ backgroundColor: "#022541", color: "#fff", width: "200px", height: "40px", marginTop: "20px" }} onClick={raisePayment}>Raise Payment</Button>
                                    :
                                    <Button disabled variant="contained" style={{ backgroundColor: "#707070", color: "#fff", width: "200px", height: "40px", marginTop: "20px" }} onClick={raisePayment}>Raise Payment</Button>

                            }
                        </Grid >

                    </Grid>
                </>
            }
        </Grid >

    )
}

export default PreviewRaiseSupplierPayment