import { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { useParams } from "react-router";
import moment from "moment-timezone";
import Dash from "../../../common/dash";
const baseUrl = process.env.REACT_APP_BASE_URL;

const DetailedBills = () => {
  const { billId, businessNo } = useParams();
  const [detailedBill, setDetailedBill] = useState();
  const [sumAmounts, setSumAmounts] = useState();

  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);


  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${baseUrl}/api/order_payment_status`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              pushyTransactionId: billId,
              businessNo: businessNo
            }),
          }
        );
        if (response.status === 200) {
          const result = await response.json();
          setDetailedBill(result);
          let sum = {
            subtotal: 0,
            discount: 0,
            vat: 0,
          };
          sum.subtotal += Number(result.order.subTotal);
          sum.discount += Number(result.order.discountAmount);
          sum.vat += Number(result.order.vat);

          result.order.childOrders.map((childOrder) => {
            sum.subtotal += Number(childOrder.subTotal);
            sum.discount += Number(childOrder.discountAmount);
            sum.vat += Number(childOrder.vat);
          });
          setSumAmounts(sum);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  const styles = {
    firstTopDiv: {
      "width": "82%",
      "marginLeft": "250px",
      "backgroundColor": "#fef4f5",
      "marginTop": "25px",
      "marginBottom": "25px",
    },
    secondTopDiv: {
      "width": "82%",
      "marginLeft": "250px",
      "backgroundColor": "#ffffff"
    },
    thirdTopDiv: {
      "width": "82%",
      "marginLeft": "250px",
      "backgroundColor": "#ffffff",
      "borderTop": "dashed 0.12rem rgba(193,193,193, .3)"
    },
    thirdGridContainer: {
      "marginTop": "10px",
    },
    detailedBillTitle: {
      "fontFamily": "Poppins",
      "fontSize": "30px",
      "fontWeight": "600",
      "fontStretch": "normal",
      "fontStyle": "normal",
      "lineHeight": "normal",
      "letterSpacing": "normal",
      "textAlign": "left",
      "color": "#032541",
      "marginTop": "10px",
      "marginBottom": "25px",
    },
    transactionDateTextLabel: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "marginBottom": "0px",
      "color": "#032541",
    },
    amountTextLabel: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "marginBottom": "0px",
      "color": "#032541",
      "text-align": "right"
    },
    paidTitle: {
      "fontFamily": "Poppins",
      "fontSize": "30px",
      "fontWeight": "600",
      "fontStretch": "normal",
      "fontStyle": "normal",
      "lineHeight": "normal",
      "letterSpacing": "normal",
      "textAlign": "left",
      "color": "#dc3545",
      "marginTop": "10px",
      "marginBottom": "25px",
      "text-align": "right"
    },
    transactionDateTextValue: {
      "fontFamily": "Poppins",
    },
    amountTextValue: {
      "fontFamily": "Poppins",
      "text-align": "right"
    },
    customerNameLabel: {
      "fontFamily": "Poppins"
    },
    paymentModeLabel: {
      "fontFamily": "Poppins"
    },
    servedByLabel: {
      "fontFamily": "Poppins"
    },
    customerBalanceLabel: {
      "fontFamily": "Poppins"
    },
    customerNameValue: {
      "fontFamily": "Poppins",
      "text-align": "right"
    },
    paymentModeValue: {
      "fontFamily": "Poppins",
      "text-align": "right"
    },
    servedByValue: {
      "fontFamily": "Poppins",
      "text-align": "right"
    },
    customerBalanceValue: {
      "fontFamily": "Poppins",
      "text-align": "right"
    },
    thirdSectionTitles: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541"
    },
    totalAmountListTitle: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541",
      "text-align": "right"
    },
    productNameLabel: {
      "fontFamily": "Poppins"
    },
    productNameValues: {
      "fontFamily": "Poppins"
    },
    totalAmountListValues: {
      "fontFamily": "Poppins",
      "text-align": "right"
    },
    fourthTopDiv: {
      "width": "82%",
      "marginLeft": "250px",
      "backgroundColor": "#ffffff",
      "borderBottom": "dashed 0.12rem rgba(193,193,193, .3)"
    },
    fourthGridContainer: {
      "marginTop": "10px",
      "marginBottom": "80px"
    },
    subTotalLabel: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541"
    },
    discountLabel: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541"
    },
    vatLabel: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#dc3545"
    },
    totalAmountLabel: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541"
    },
    subTotalValue: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541",
      "text-align": "right"
    },
    discountValue: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541",
      "text-align": "right"
    },
    vatValue: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#dc3545",
      "text-align": "right"
    },
    totalAmountValue: {
      "fontFamily": "Poppins",
      "fontWeight": "600",
      "color": "#032541",
      "text-align": "right"
    },
    lastDivValues: {
      "backgroundColor": "#f7f7f7",
    },
    lastDivLabels: {
      "backgroundColor": "#f7f7f7",
    },
    footerDiv: {
      "width": "86%",
      "marginLeft": "250px",
      "backgroundColor": "#f7f7f7"
    },
    footerValue: {
      "marginTop": "20px",
      "paddingTop": "10px",
      "paddingBottom": "10px"
    },
    zedFootertext: {
      "color": "#032541",
      "fontWeight": "600",
    }
  };

  return (
    <div>
      <div className="container-fluid content" style={styles.firstTopDiv}>
        <Grid container spacing={2} direction={'row'} className={theme.palette.primary} justifyContent={'space-between'} alignContent={'space-between'}>
          <Grid item>
            <h3 style={styles.detailedBillTitle}>DETAILED BILL</h3>
            <p style={styles.transactionDateTextLabel}>Transaction Date:</p>
            <p style={styles.transactionDateTextValue}>{detailedBill ? moment(detailedBill.order.transTime).tz("Africa/Nairobi").format("llll") : ''}</p>
          </Grid>
          <Grid item>
            <h3 style={styles.paidTitle}>Paid</h3>
            <p style={styles.amountTextLabel}>Amount:</p>
            <p style={styles.amountTextValue}>{sumAmounts ? numberFormat(sumAmounts.subtotal + sumAmounts.vat - sumAmounts.discount) : numberFormat(0)}</p>
          </Grid>
        </Grid>
      </div>

      <div className="container-fluid content" style={styles.secondTopDiv}>
        <Grid container spacing={2} className={theme.palette.primary}>
          <Grid item xs={6}>
            {detailedBill && detailedBill.order.paymentMethod == 'Customer On Account' ? (
              <p style={styles.customerNameLabel}>Customer Name:</p>
            ) : <></>}
            <p style={styles.paymentModeLabel}>Payment Mode:</p>
            <p style={styles.servedByLabel}>Served By:</p>
            {detailedBill && detailedBill.order.paymentMethod == 'Customer On Account' ? (
              <p style={styles.customerBalanceLabel}>Customer Balance:</p>
            ) : <></>}
          </Grid>
          <Grid item xs={6}>
            {detailedBill && detailedBill.order.paymentMethod == 'Customer On Account' ? (
              <p style={styles.customerNameValue}>{detailedBill.order.customerName}</p>
            ) : <></>}
            {detailedBill ? <p style={styles.paymentModeValue}>{detailedBill.order.paymentMethod == 'unknown' ? 'N/A' : detailedBill.order.paymentMethod}</p> : <></>}
            <p style={styles.servedByValue}>{detailedBill ? detailedBill.order.cashier : ''}</p>
            {detailedBill && detailedBill.order.paymentMethod == 'Customer On Account' ? (
              <p style={styles.customerBalanceValue}>{numberFormat(detailedBill.order.customerBalance)}</p>
            ) : <></>}
          </Grid>
        </Grid>
      </div>

      <div className="container-fluid content" style={styles.thirdTopDiv}>
        <Grid container spacing={2} className={theme.palette.primary} style={styles.thirdGridContainer} justifyContent="flex-end">
          <Grid item xs={4}>
            <p style={styles.thirdSectionTitles}>Products</p>

            {detailedBill ? detailedBill.order.items.map((item) => (
              <p style={styles.productNameLabel} key={item._id}>{item.itemName}</p>
            )) : <></>}
            {detailedBill ? detailedBill.order.childOrders.map((childOrder) => (
              childOrder.items.map((item) => (
                <p style={styles.productNameValues}>{item.itemName}</p>
              ))
            )) : <></>}
          </Grid>
          <Grid item xs={3}>
            <p style={styles.thirdSectionTitles}>Price (KES)</p>

            {detailedBill ? detailedBill.order.items.map((item) => (
              <p style={styles.productNameValues} key={item._id}>{numberFormat(item.itemAmount)}</p>
            )) : <></>}
            {detailedBill ? detailedBill.order.childOrders.map((childOrder) => (
              childOrder.items.map((item) => (
                <p style={styles.productNameValues}>{numberFormat(item.itemAmount)}</p>
              ))
            )) : <></>}
          </Grid>
          <Grid item xs={3}>
            <p style={styles.thirdSectionTitles}>Qty</p>

            {detailedBill ? detailedBill.order.items.map((item) => (
              <p style={styles.productNameValues} key={item._id}>{item.itemCount}</p>
            )) : <></>}

            {detailedBill ? detailedBill.order.childOrders.map((childOrder) => (
              childOrder.items.map((item) => (
                <p style={styles.productNameValues}>{item.itemCount}</p>
              ))
            )) : <></>}
          </Grid>
          <Grid item xs={2}>
            <p style={styles.totalAmountListTitle}>Total (KES)</p>

            {detailedBill ? detailedBill.order.items.map((item) => (
              <p style={styles.totalAmountListValues} key={item._id}>{numberFormat(item.totalAmount)}</p>
            )) : <></>}
            {detailedBill ? detailedBill.order.childOrders.map((childOrder) => (
              childOrder.items.map((item) => (
                <p style={styles.totalAmountListValues}>{numberFormat(item.totalAmount)}</p>
              ))
            )) : <></>}
          </Grid>
        </Grid>
      </div>

      <div className="container-fluid content" style={styles.fourthTopDiv}>
        <Grid container spacing={2} direction="row-reverse" className={theme.palette.primary} style={styles.fourthGridContainer}>
          <Grid item xs={3} style={styles.lastDivValues}>
            <p style={styles.subTotalValue}>{sumAmounts ? numberFormat(sumAmounts.subtotal) : numberFormat(0)}</p>
            <p style={styles.discountValue}>{sumAmounts ? numberFormat(sumAmounts.discount) : numberFormat(0)}</p>
            <p style={styles.vatValue}>{sumAmounts ? numberFormat(sumAmounts.vat) : numberFormat(0)}</p>
            <p style={styles.totalAmountValue}>{sumAmounts ? numberFormat(sumAmounts.subtotal + sumAmounts.vat - sumAmounts.discount) : numberFormat(0)}</p>
          </Grid>
          <Grid item xs={3} style={styles.lastDivLabels}>
            <p style={styles.subTotalLabel}>Subtotal</p>
            <p style={styles.discountLabel}>Discount</p>
            <p style={styles.vatLabel}>VAT (16%)</p>
            <p style={styles.totalAmountLabel}>Total Amount</p>
          </Grid>
        </Grid>
      </div>

      <div className="container-fluid content" style={styles.footerDiv}>
        <Typography variant="subtitle1" align="center" style={styles.footerValue} paragraph><span style={styles.zedFootertext}>ZED. </span>Powered by Riverbank Solutions . info@riverbank.co.ke . v.1.0.2</Typography>
      </div>

    </div>
  )
}

export default DetailedBills