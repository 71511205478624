import React, { useEffect, useState } from "react";
import { warehousestyles } from "./styles";
import { Box, Breadcrumbs, Button, Grid, Tab, Typography, IconButton, Tabs } from "@mui/material";
import { NavigateNext, ArrowBackIos } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import CustomSearchInput from "../School/CustomSearchInput";
import { DataGrid } from "@mui/x-data-grid";
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useNavigate } from "react-router-dom";
import HttpComponent from "../School/MakeRequest";
import CustomInputLabel from "../School/CustomInputLabel";
import CustomTextField from "../School/CustomTextField";
import CustomSelectField from "../School/CustomSelectField";
import CustomSelectAction from "../School/customSelectAction"
// import CustomSelectAction from " ../School/CustomSelectWithActions";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ca } from "date-fns/locale";

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const Employee = () => {
  const queryparams = decodeURIComponent(window.location.search);
  const [value, setValue] = useState('employees');
  const [searchValue, setSearchValue] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [dataGridPageSize, setDataPageSize] = useState(10);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const navigate = useNavigate();
  const [subValue, setSubValue] = useState('Active');
  const stateUrl = queryparams.slice(1);
  const [managers, setManagers] = useState([]);
  const [editForm, setEditForm] = useState(false)
  const [employeeId, setEmployeeId] = useState('')
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    data: [],
    page: 1,
    pageSize: dataGridPageSize
  });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    employmentNumber: '',
    currency: 'KES',
    amount: '',
    department: '',
    departmentid: '',
    paymentFrequency: '',
    gender: '',
    hireDate: '',
    birthDay: '',
  });

  useEffect(() => {
    // console.log('subValue', subValue);
    if (value === 'employees') {
      getEmployees();
    } else if (value === 'departments') {
      getDepartments();
    }
  }, [value, subValue, pageState.page, dataGridPageSize, stateUrl, searchValue]);

  const [formDataTwo, setFormDataTwo] = useState({
    name: '',
    managerId: '',
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showEditEmployeeForm = (id) => {
    setEditForm(true)
    setEmployeeId(id)
  }

  const handleSubChange = (event, newValue) => {
    setSubValue(newValue);
  };

  const handleCancelModal = () => {
    setOpenEmployeeModal(false);
    if (value === 'employees') {
      getEmployees();
    } else if (value === 'departments') {
      getDepartments();
    }
  }

  const handleCancelEditModal = () => {
    setEditForm(false);
  }

  const handleCancelEmployeeModal = () => {
    setOpenDepartmentModal(false);
  }

  const handleInputChange = (e, name = null) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDepartmentChange = (event) => {
    const selectedValue = event.target.value;
    const selectedDepartment = departmentOptions.find(option => option.value === selectedValue);
  
    if (selectedDepartment) {
      setFormData({
        ...formData,
        department: selectedValue,
        departmentId: selectedDepartment.zedDepartmentAccountingId,
      });
    } else {
      console.error(`Department with value "${selectedValue}" not found.`);
      setFormData({
        ...formData,
        department: selectedValue,
        departmentId: "",  
      });
    }
  };

  const suspendActiveEmployee = (id) => {
    console.log('Suspend employee called with id:', id);
    let status = ''
    if (subValue === 'Active') {
      status = 'Suspended'
    } else if (subValue === 'Suspended') {
      status = 'Active'
    }
    try {
      HttpComponent({
        method: 'POST',
        url: '/api/v1/employee/update_status',
        token: localStorage.getItem('X-Authorization'),
        body: {
          employeeId: id,
          status: status
        }
      }).then((data) => {
        console.log(data, 'data.response.data')
        if (data?.status === 200 || data?.status === 201) {
          setSuccessShow({ ...successShow, state: true, message: data?.response?.message })
          getEmployees()
        } else {
          setErrorShow({ ...errorShow, state: true, message: data?.response?.error })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleInputChangeTwo = (e, name = null) => {
    setFormDataTwo({
      ...formDataTwo,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (e, name = null) => {
    const fieldName = name || e.target.name;
    setFormData({
      ...formData,
      [fieldName]: e.target ? e.target.value : e,
    });
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
  ]

  const frequencyPayments = [
    { value: 'bi-weekly', label: 'Bi Weekly' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ]

  const handleEmailValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getManager = async () => {
    try {
      const data = await HttpComponent({
        method: "GET",
        url: `/api/listUsers/Active?page=${1}&limit=${100}`,
        body: null,
        token: localStorage.getItem("X-Authorization"),
      });

      if (data.status === 200) {
        const managers = data.response.data;
        // console.log(managers, 'managers');
        const uniqueManagers = [...new Set(managers.map((manager) => manager._id))].map((id) => {
          const manager = managers.find((m) => m._id === id);
          return { label: manager.userName, value: manager.userId };
        });

        setManagers(uniqueManagers);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getManager();
  }, []);

  const getEmployeeDetails = () => {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/employee/employee_id?employeeId=${employeeId}`,
        body: null,
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        console.log('data', data);
        if (data.status === 200 || data.status === 201) {
          let employeeDetails = data?.response?.data[0];

          if (employeeDetails) {
            setFormData({
              employeeId: employeeDetails?._id,
              firstName: employeeDetails.firstName,
              lastName: employeeDetails.lastName,
              email: employeeDetails.email,
              phone: employeeDetails.phone,
              employmentNumber: employeeDetails.employmentNumber,
              amount: employeeDetails.amount,
              department: employeeDetails.department,
              gender: employeeDetails.gender,
              currency: employeeDetails.currency,
              paymentFrequency: employeeDetails.paymentFrequency,
            });
          }
        }
      });
    } catch (err) {
      console.log('error', err);
    }
  }
  useEffect(() => {
    getEmployeeDetails();
  }, [employeeId])


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!handleEmailValidation(formData.email)) {
      setValidEmail(false);
      return;
    }

    const phoneRegex = /^\+?\d{10,15}$/;
    if (!phoneRegex.test(formData.phone)) {
      console.error('Invalid phone number format');
      return;
    }

    console.log('Submitting formData:', formData);

    const url = '/api/v1/employee/addEmployee';

    try {
      const data = await HttpComponent({
        method: 'POST',
        url: url,
        body: formData,
        token: localStorage.getItem('X-Authorization'),
      });

      console.log('data', data);
      if (data.status === 200 || data.status === 201) {
        setSuccessShow({ state: true, message: 'Employee added Successfully' });
        setOpenEmployeeModal(false);
        window.location.reload();
      } else {
        setErrorShow({ state: true, message: data?.response?.error });
      }
    } catch (error) {
      console.error('Error adding employee:', error);
    }
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    if (!handleEmailValidation(formData.email)) {
      setValidEmail(false);
      return;
    }

    const phoneRegex = /^\+?\d{10,15}$/;
    console.log('phoneRegex', phoneRegex)
    if (!phoneRegex.test(formData.phone)) {
      console.error('Invalid phone number format');
      return;
    }

    console.log('Submitting formData:', formData);

    const url = '/api/v1/employee/updateEmployee';

    try {
      const data = await HttpComponent({
        method: 'POST',
        url: url,
        body: formData,
        token: localStorage.getItem('X-Authorization'),
      });

      console.log('data', data);
      if (data.status === 200 || data.status === 201) {
        setSuccessShow({ state: true, message: 'Employee updated Successfully' });
        setEditForm(false);
        window.location.reload();
      } else {
        setErrorShow({ state: true, message: data?.response?.error });
      }
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };

  const handleAddItem = () => {
    setOpenEmployeeModal(false); 
    setOpenDepartmentModal(true); 
  };

  const handleFormSubmitTwo = async (e) => {
    e.preventDefault();

    console.log('Submitting formData:', formDataTwo);

    const url = '/api/v1/employee/addDepartment';

    try {
      const data = await HttpComponent({
        method: 'POST',
        url: url,
        body: formDataTwo,
        token: localStorage.getItem('X-Authorization'),
      });

      console.log('data', data);
      if (data.status === 200 || data.status === 201) {
        setSuccessShow({ state: true, message: 'Department added Successfully' });
        setOpenDepartmentModal(false);
        window.location.reload();
      } else {
        setErrorShow({ state: true, message: data?.response?.error });
      }
    } catch (error) {
      console.error('Error adding department:', error);
    }
  };

  const getDashboardText = () => {
    if (value === 'employees') {
      return 'Employee';
    } else if (value === 'departments') {
      return 'Department';
    }
    return '';
  };

  useEffect(() => {
    if (formData.email !== '') {
      let result = handleEmailValidation(formData.email);
      setValidEmail(result);
    } else {
      setValidEmail(true);
    }
  }, [formData.email]);

  const getEmployees = async () => {
    setPageState({ ...pageState, isLoading: true });
    let url = `/api/v1/employee/getEmployee?page=${pageState.page}&limit=${dataGridPageSize}&status=${subValue}&search=${searchValue}`;

    try {
      const data = await HttpComponent({
        method: 'GET',
        url: url,
        token: localStorage.getItem('X-Authorization')
      });
      console.log(data, 'data.response.data');
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data });
      } else {
        setPageState({ ...pageState, isLoading: false });
        console.error('Failed to fetch employees:', data);
      }
    } catch (error) {
      setPageState({ ...pageState, isLoading: false });
      console.error('Error fetching employees:', error);
    }
  };

  const getDepartments = async () => {
    setPageState({ ...pageState, isLoading: true });
    let url = `/api/v1/employee/getDepartments?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`;
  
    try {
      const data = await HttpComponent({
        method: 'GET',
        url: url,
        token: localStorage.getItem('X-Authorization')
      });
      console.log(data, 'data.response.departments');
      if (data?.status === 200 || data?.status === 201) {
        const departments = data.response.departments;
        setPageState({ ...pageState, isLoading: false, data: departments });
  
        const options = departments.map(department => ({
          value: department._id, 
          label: department.name,
          zedDepartmentAccountingId: department.zedDepartmentAccountingId 
        }));
        setDepartmentOptions(options);
      } else {
        setPageState({ ...pageState, isLoading: false });
        console.error('Failed to fetch departments:', data);
      }
    } catch (error) {
      setPageState({ ...pageState, isLoading: false });
      console.error('Error fetching departments:', error);
    }
  };

  useEffect(() => {
    if (openEmployeeModal) {
      getDepartments();
    }
  }, [openEmployeeModal]);

  const columns = [
    {
      field: 'name', headerName: 'Name', flex: 1, renderHeader: (params) => {
        return (
          <span style={{ display: 'flex', fontWeight: '900', justifyContent: 'flex-start' }}>
            Name
          </span>
        );
      },
      renderCell: (params) => {
        return (
          <span style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {params.row.firstName + " " + params.row.lastName}
          </span>
        );
      }
    },
    {
      field: 'phone', headerName: 'Phone Number', flex: 1,
      renderHeader: (params) => {
        return (
          <span style={{ display: 'flex', fontWeight: '900', justifyContent: 'flex-start' }}>
            Phone Number
          </span>
        );
      }
    },
    {
      field: 'email', headerName: 'Email Address', flex: 1,
      renderHeader: (params) => {
        return (
          <span style={{ display: 'flex', fontWeight: '900', justifyContent: 'flex-start' }}>
            Email Address
          </span>
        );
      }
    },
    {
      field: 'departmentName', headerName: 'Department', flex: 1,
      renderHeader: (params) => {
        return (
          <span style={{ display: 'flex', fontWeight: '900', justifyContent: 'flex-start' }}>
            Department
          </span>
        );
      }
    },
    {
      field: "action", headerName: "Action", flex: 1, headerAlign: 'center', align: 'center',
      renderCell: (params) => {
        return (
          <>
            {subValue === 'Active' ?
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }} onClick={() => showEditEmployeeForm(params?.row?._id)}><EditIcon /></Typography>
                <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#dc3545", marginLeft: "20px", cursor: "pointer" }} onClick={() => suspendActiveEmployee(params?.row?._id)}><PersonOffIcon /></Typography>
              </Box>
              : subValue === 'Suspended' ? <>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                  <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#2E7D32", cursor: "pointer" }} onClick={() => suspendActiveEmployee(params?.row?._id)}><RestoreIcon /></Typography>

                </Box>
              </> : <>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                  <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}><EditIcon /></Typography>
                  <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#dc3545", cursor: "pointer" }}><PersonOffIcon /></Typography>

                </Box>
              </>}
          </>

        )

      },
    }
  ];

  const employeeColumns = [
    {
      field: 'name', headerName: 'Department Name', flex: 1,
      renderHeader: (params) => {
        return (
          <span style={{ display: 'flex', fontWeight: '900', justifyContent: 'flex-start' }}>
            Department name
          </span>
        );
      }
    },
    {
      field: 'managerName', headerName: 'Manager Name', flex: 1,
      renderHeader: (params) => {
        return (
          <span style={{ display: 'flex', fontWeight: '900', justifyContent: 'flex-start' }}>
            Manager Name
          </span>
        )
      }
    }
  ]

  const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Employees</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Employee</Typography>
  ];

  const employeebreadCrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Employees</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Department</Typography>
  ]

  return (
    <div style={warehousestyles.mainDivStyling}>
        {openEmployeeModal ? (
          <>
            <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancelModal}>
              <ArrowBackIos />
            </IconButton>
            <Box style={{ display: "flex", justifyContent: "" }}>
              <Box style={{ marginLeft: "35px" }}>
                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Employees</Box>
                <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" sx={{ marginTop: '10px' }}>
                  {breadcrumbs}
                </Breadcrumbs>
                <Box>
                  <p style={{ fontSize: '14px', color: "#707070", width: '475px', margin: '23px 599px 5px 3px' }}>
                    Create new Employees
                  </p>
                </Box>
                <Grid item mt={1}>
                  <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", marginTop: "20px" }}>  Add Employee Details</Typography>
                </Grid>
                <Box component="div" sx={{ display: 'flex', flexDirection: "column", marginX: 1, marginTop: '10px' }}>
                  <Box component='div' sx={{ display: 'flex' }}>
                    <Box>
                      <CustomInputLabel required={true} label={'First Name'} />
                      <CustomTextField value={formData.firstName} onChange={handleInputChange} name={'firstName'} placeholder={"First Name"} />
                      <CustomInputLabel label={'Phone Number'} required={true} />
                      <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                        <PhoneInput country={"ke"} value={formData.phone} onChange={(value) => setFormData({ ...formData, phone: value })} name={'phone'} placeholder={'Phone Number'} />
                      </Box>
                      <CustomInputLabel required={true} label={'Employment Number'} />
                      <CustomTextField value={formData.employmentNumber} onChange={handleInputChange} name={'employmentNumber'} placeholder={'Employment Number'} />
                      <CustomInputLabel required={true} label={'Department'} />
                      <CustomSelectAction value={formData.department} onChange={handleDepartmentChange} name={'department'} options={departmentOptions} onAddAction={handleAddItem}/>
                      <CustomInputLabel required={true} label={'Currency'} />
                      <CustomTextField value={formData.currency} onChange={handleInputChange} name={'currency'} placeholder={'Currency'} />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CustomInputLabel required={true} label={'Hire Date'} />
                        <DatePicker value={formData.hireDate} onChange={(date) => handleDateChange(date, 'hireDate')}
                          renderInput={(props) => (
                            <CustomTextField {...props} name={'hireDate'} placeholder={'Hire Date'} sx={{width: '100%'}} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ marginLeft: 1 }}>
                      <CustomInputLabel required={true} label={'Last Name'} />
                      <CustomTextField value={formData.lastName} onChange={handleInputChange} name={'lastName'} placeholder={'Last Name'} />
                      <CustomInputLabel required={true} label={"Email"} />
                      {!validEmail && (
                        <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                          Please enter a valid email
                        </Typography>
                      )}
                      <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                      <CustomInputLabel required={true} label={"Gender"} />
                      <CustomSelectField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                      <CustomInputLabel required={true} label={'Payment Frequency'} />
                      <CustomSelectField value={formData.paymentFrequency} onChange={handleInputChange} name={"paymentFrequency"} placeholder={"Select Payment Frequency"} options={frequencyPayments} />
                      <CustomInputLabel required={true} label={'Salary'} />
                      <CustomTextField value={formData.amount} onChange={handleInputChange} name={'amount'} placeholder={'Salary Amount'} />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CustomInputLabel required={true} label={'Birth Date'} />
                        <DatePicker value={formData.birthDay} onChange={(date) => handleDateChange(date, 'birthDay')}
                          renderInput={(props) => (
                            <CustomTextField {...props} name={'birthDay'} placeholder={'Birth Date'} sx={{width: '100%'}} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>

                <form onSubmit={handleFormSubmit}>
                  <Box style={{ display: "flex", marginTop: "15px" }}>
                    <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelModal}>Cancel</Button>
                    <Button style={{ height: "45px", width: "125px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Add</Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </>
        ) :
        // Department Modal
        openDepartmentModal ? (
          <>
            <Box style={{ marginLeft: "35px", marginTop: '30px' }}>
              <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancelEmployeeModal}>
                <ArrowBackIos />
              </IconButton>
              <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", marginLeft: '25px' }}>Departments</Box>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" sx={{ marginTop: '10px' }}>
                {employeebreadCrumbs}
              </Breadcrumbs>
              <p style={{ fontSize: '14px', color: "#707070", width: '475px', margin: '10px 0' }}>
                Create new Department
              </p>
              <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", marginTop: "10px" }}>
                Add Department Details
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                <Box sx={{ marginRight: '20px' }}>
                  <CustomInputLabel required={true} label={'Department Name'} />
                  <CustomTextField value={formDataTwo.name} onChange={handleInputChangeTwo} name={'name'} placeholder={"Department Name"} />
                  <CustomInputLabel required={true} label={'Manager Name'} />
                  <CustomSelectField value={formDataTwo.managerId} onChange={handleInputChangeTwo} name={'managerId'} placeholder={'Manager Name'} options={managers} />
                </Box>
              </Box>
              <form onSubmit={handleFormSubmitTwo}>
                <Box style={{ display: "flex", marginTop: "15px" }}>
                  <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelEmployeeModal}>Cancel</Button>
                  <Button style={{ height: "45px", width: "125px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Add</Button>
                </Box>
              </form>
            </Box>
          </>
        ) :
          editForm ? (
            <>
              <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancelEditModal}>
                <ArrowBackIos />
              </IconButton>
              <Box style={{ display: "flex", justifyContent: "" }}>
                <Box style={{ marginLeft: "35px" }}>
                  <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Employees</Box>
                  <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" sx={{ marginTop: '10px' }}>
                    {breadcrumbs}
                  </Breadcrumbs>
                  <Box>
                    <p style={{ fontSize: '14px', color: "#707070", width: '475px', margin: '23px 599px 5px 3px' }}>
                      Edit Employee
                    </p>
                  </Box>
                  <Grid item mt={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", marginTop: "20px" }}>  Edit Employee Details</Typography>
                  </Grid>
                  <Box component="div" sx={{ display: 'flex', flexDirection: "column", marginX: 1, marginTop: '10px' }}>
                    <Box component='div' sx={{ display: 'flex' }}>
                      <Box>
                        <CustomInputLabel required={true} label={'First Name'} />
                        <CustomTextField value={formData.firstName} onChange={handleInputChange} name={'firstName'} placeholder={"First Name"} />
                        <CustomInputLabel label={'Phone Number'} required={true} />
                        <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                          <PhoneInput country={"ke"} value={formData.phone} onChange={(value) => setFormData({ ...formData, phone: value })} name={'phone'} placeholder={'Phone Number'} />
                        </Box>
                        <CustomInputLabel required={true} label={'Employment Number'} />
                        <CustomTextField value={formData.employmentNumber} onChange={handleInputChange} name={'employmentNumber'} placeholder={'Employment Number'} />
                        <CustomInputLabel required={true} label={'Department'} />
                        <CustomTextField value={formData.department} onChange={handleInputChange} name={'department'} placeholder={'Department'} />
                        <CustomInputLabel required={true} label={'Currency'} />
                        <CustomTextField value={formData.currency} onChange={handleInputChange} name={'currency'} placeholder={'Currency'} />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <CustomInputLabel required={true} label={'Hire Date'} />
                          <DatePicker value={formData.hireDate} onChange={(date) => handleDateChange(date, 'hireDate')}
                            renderInput={(props) => (
                              <CustomTextField {...props} name={'hireDate'} placeholder={'Hire Date'} />
                            )}
                          />
                        </LocalizationProvider> */}
                      </Box>
                      <Box sx={{ marginLeft: 1 }}>
                        <CustomInputLabel required={true} label={'Last Name'} />
                        <CustomTextField value={formData.lastName} onChange={handleInputChange} name={'lastName'} placeholder={'Last Name'} />
                        <CustomInputLabel required={true} label={"Email"} />
                        {!validEmail && (
                          <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                            Please enter a valid email
                          </Typography>
                        )}
                        <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                        <CustomInputLabel required={true} label={"Gender"} />
                        <CustomSelectField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                        <CustomInputLabel required={true} label={'Payment Frequency'} />
                        <CustomSelectField value={formData.paymentFrequency} onChange={handleInputChange} name={"paymentFrequency"} placeholder={"Select Payment Frequency"} options={frequencyPayments} />
                        <CustomInputLabel required={true} label={'Salary'} />
                        <CustomTextField value={formData.amount} onChange={handleInputChange} name={'amount'} placeholder={'Salary Amount'} />
                      </Box>
                    </Box>
                  </Box>

                  <form onSubmit={handleEditFormSubmit}>
                    <Box style={{ display: "flex", marginTop: "15px" }}>
                      <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelEditModal}>Cancel</Button>
                      <Button style={{ height: "45px", width: "125px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Add</Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </>
          ) : (
            <Grid>
              <Grid container width={'100%'} justifyContent={"space-between"} direction={"row"} alignItems={"center"} style={warehousestyles.gridContainerMargin}>
                <Grid item>
                  <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                  <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                  <span style={warehousestyles.supplierText}>Employees</span>
                </Grid>
                <Grid item>
                  <Button variant="contained" style={warehousestyles.addSupplierBtn} onClick={() => setOpenEmployeeModal(true)}>Add Employee</Button>
                  <Button variant="contained" sx={{ marginLeft: '20px' }} style={warehousestyles.addSupplierBtn} onClick={() => setOpenDepartmentModal(true)}>Add Department</Button>
                </Grid>
              </Grid>

              <Grid container style={warehousestyles.gridContainerMarginEmployee}>
                <Grid item>
                  <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    <span>Dashboard</span>
                    <span style={{ color: value === 'departments' ? 'red' : 'red' }}>{getDashboardText()}</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>

              <TabContext value={value}>
                <Grid container width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                  <Grid item>
                    <TabContext value={value} sx={{ display: 'flex' }}>
                      <AntTabs onChange={handleChange}>
                        <AntTab label="Employees" value="employees" />
                        <AntTab label="Departments" value="departments" sx={{ marginLeft: '20px' }} />
                      </AntTabs>
                    </TabContext>
                  </Grid>
                </Grid>

                <TabPanel value="employees">
                  <TabContext value={subValue}>
                    <Grid container width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px 20px' }}>
                      <Grid item>
                        <Tabs value={subValue} onChange={handleSubChange} sx={{ display: 'flex' }}>
                          <Tab label="Active" value="Active" />
                          <Tab label="Suspended" value="Suspended" sx={{ marginLeft: '20px' }} />
                        </Tabs>
                      </Grid>
                    </Grid>

                    <TabPanel value="Active">
                      <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search Employees'} />
                      </Grid>
                      <DataGrid
                        sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: '10px', color: '#272d3b' }}
                        columns={columns}
                        rows={pageState.data}
                        pageSize={pageState.pageSize}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        paginationMode="server"
                        page={pageState.page - 1}
                        onPageChange={(newPage) => setPageState({ ...pageState, page: newPage + 1 })}
                        onPageSizeChange={(newPageSize) => setPageState({ ...pageState, pageSize: newPageSize })}
                        getRowId={(row) => row._id}
                      />
                    </TabPanel>
                    <TabPanel value="Suspended">
                      <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search Employees'} />
                      </Grid>
                      <DataGrid
                        sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: '10px', color: '#272d3b' }}
                        columns={columns}
                        rows={pageState.data}
                        pageSize={pageState.pageSize}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        paginationMode="server"
                        page={pageState.page - 1}
                        onPageChange={(newPage) => setPageState({ ...pageState, page: newPage + 1 })}
                        onPageSizeChange={(newPageSize) => setDataPageSize({ ...pageState, pageSize: newPageSize })}
                        getRowId={(row) => row._id}
                      />
                    </TabPanel>
                  </TabContext>
                </TabPanel>

                <TabPanel value="departments">
                  <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search Departments'} />
                  </Grid>
                  <DataGrid
                    sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: '10px', color: '#272d3b' }}
                    columns={employeeColumns}
                    rows={pageState.data}
                    pageSize={pageState.pageSize}
                    loading={pageState.isLoading}
                    paginationMode="server"
                    page={pageState.page - 1}
                    onPageChange={(newPage) => setPageState({ ...pageState, page: newPage + 1 })}
                    onPageSizeChange={(newPageSize) => setDataPageSize({ ...pageState, pageSize: newPageSize })}
                    getRowId={(row) => row._id}
                  />
                </TabPanel>
              </TabContext>
            </Grid>
          )}
    </div>
  );
};

export default Employee;
