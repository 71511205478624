import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {Link} from "react-router-dom";
import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function AdminConfiguration (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')

	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick}>
				<div style={{marginRight : '5%'}}>
					<SettingsIcon />
				</div>
				<ListItemText primary="Configurations" style={{fontFamily : 'Poppins'}}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					{userGroup === 'Admin' ?
						<div>
							<Link style={{textDecoration: 'none', color : '#fff'}} to="/configuration">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Global Configuration" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/reasons">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Reason Codes" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/businessCategories">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Business Categories" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color : '#fff'}} to="/payItMode">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Zed Pocket Money Modes" />
								</ListItem>
							</Link>
						</div> : null}

				</List>
			</Collapse>
		</div>
	)
}