import { Box, Drawer, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import profileIcon from './images/profile.svg'
import notificationIcon from './images/notification.svg'
import logout from "./images/logout-icon.png";
import React, { useEffect, useState } from "react";
import AccountingImg from "../images/accounting.svg"
import HttpComponent from "../components/School/MakeRequest";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import personimg from "../images/person.svg"
import crosimg from "../images/cro.svg"
import notificationIon from "../images/notification.svg"
import DateFormatter from "../utils/dateFormatter";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ecommerceIcon from "../common/images/icon.svg"
import xeroIcon from "../common/images/xero.png"
import QuickBooks from "../common/images/quickbooks.svg"
import zohoIcon from "../common/images/zoho.jpeg"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';




const UserNavbar = ({handleDrawerToggle}) => {
	const navigate = useNavigate();
	const { group } = useSelector((store) => store.user);
	const [accountStatus, setAccountStatus] = useState({})
	const [xeroStatus, setXeroStatus] = useState({})
	const [webHookStatus, setWebHookStatus] = useState({})
	const [quickBookStatus, setQuickBookStatus] = useState({})
	const [zohoStatus, setZohoStatus] = useState({})
	const { accountingEnabled } = accountStatus
	const { xeroAccountingEnabled } = xeroStatus
	const { xeroWebHookIntentStatus } = webHookStatus
	const { quickbooksEnabled } = quickBookStatus
	const { zohoAccountingEnabled } = zohoStatus
	const bsGroup = localStorage.getItem('group')
	const bsName = localStorage.getItem('businessName')
	const [bsNotifications, setBusinessNotifications] = useState([])
	const [customerDetails, setCustomerDetails] = useState({})
	const userIdd = localStorage.getItem('userId')
	const [showWebhookMessage, setShowWebhookMessage] = useState(false);

	function fetchCustomerDetaisl() {
		try {
			HttpComponent({
				method: 'GET',
				url: `/api/get_customer_profile_by_userId?customerId=${userIdd}`,
				token: localStorage.getItem('X-Authorization')
			}).then((data) => {
				console.log(data, 'customer data here')
				if (data?.status === 200) {
					setCustomerDetails(data?.response?.data)
				}
			})
		} catch (error) {
			console.log(error, 'customer data here')
		}
	}

	useEffect(() => {
		fetchCustomerDetaisl()
	}, [userIdd])

	const { firstName, secondName, userName, userPhone, userEmail } = customerDetails

	const fullName = `${firstName}   ${secondName}`;

	function getAllNotifications() {
		try {
			HttpComponent({
				method: 'GET',
				url: `/api/v1/notification/all`,
				token: localStorage.getItem('X-Authorization')
			}).then((data) => {
				setBusinessNotifications(data?.response?.data)
			})

		} catch (error) {

		}
	}

	useEffect(() => {
		getAllNotifications()
	}, [])


	//get accounting status

	function getAccountingStatus() {
		try {
			HttpComponent({
				method: "POST",
				url: '/api/v1/check_accounting_status',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				if (data?.status === 200) {
					setAccountStatus(data.response.data)
					setXeroStatus(data?.response?.data)
					setWebHookStatus(data?.response?.data)
					setQuickBookStatus(data?.response?.data)
					setZohoStatus(data?.response?.data)
				}
			})
		} catch (error) {
		}
	}

	useEffect(() => {
		getAccountingStatus()
	}, [])

	const accounturl = process.env.REACT_APP_ACCOUNTING_URL

	const affiliatedPartner = localStorage.getItem('affiliatedPartner')
	const businessCat = localStorage.getItem('businessCategory');
	const businessNumber = localStorage.getItem('businessId');
	const businessId = localStorage.getItem('businessID');
	const branchId = localStorage.getItem('branchId');


	const userGroup = localStorage.getItem('group')
	const hideAcc = userGroup === 'Customer' || userGroup === 'Cashier' || userGroup === 'STOREMANAGER' || userGroup === 'StoreOperator'
	const userId = localStorage.getItem('userId')
	let companyId = localStorage.getItem('zedAccountingId')
	const theme = useTheme();
	const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const openInNewTab3 = () => {
		window.location.assign( `${process.env.REACT_APP_ECOMMERCE_URL}/zed_ecommerce/admin/login?businessId=${businessId}&businessNumber=${businessNumber}&businessCat=${businessCat}&userId=${userId}&branchId=${branchId}`)
	  };

	const isZedEcommerce  = (userGroup  === "Merchant" ||  userGroup === "Owner" || userGroup === 'Accountant' || userGroup === 'Supervisor')  && businessCat && businessCat === 'ZED_ECOMMERCE'

	// const openXeroTab = () => {
	// const businessNumber = localStorage.getItem('businessId');
	// 	window.location.assign(`https://api.dev.zed.business/api/v1/xero/get_token?token=${businessNumber}`);

	// };

	const openXeroTab = () => {
		window.location.assign(`https://www.xero.com/au/login/`);
	  }

	  const openQuickBooksTab = () => {
		window.location.assign(`https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&locale=en-ROW&state=%7B%22queryParams%22%3A%7B%22locale%22%3A%22en-ROW%22%7D%7D&app_environment=prod`);
	  }

	  const openWebHookTab = () => {
		window.location.assign(`https://developer.xero.com/app/manage/`);
	  }
	  
	  const openZohoTab = () => {
		window.location.assign(`https://accounts.zoho.com/signin?servicename=ZohoBooks&signupurl=https://www.zoho.com/ke/books/signup/index.html`)
	  }

	const openInSameTab = () => {
		//alert(`${accounturl}?id=${userId}&companyId=${companyId}`)
		window.open(`${accounturl}?id=${userId}&companyId=${companyId}`, '_self');
	};

	console.log('isZedEcommerce' , isZedEcommerce)

	const [state, setState] = useState({ right: false });
	const [profileState, setProfileState] = useState({ right: false })

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};

	const toggleNotifications = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setProfileState({ ...profileState, [anchor]: open });
	};

	const handleContentClick = (event) => {
		event.stopPropagation();
	};
	const notificationList = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List onClick={handleContentClick}>
				<Grid mt={5} p py={3} item display={'flex'}>
					<Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={notificationIon} alt='person img' /> <Typography style={{ fontWeight: 600, color: "#032541", marginLeft: '10px', fontSize: "18px" }}>Notifications</Typography>
						</Grid>
						<Grid item>
							<img style={{ cursor: "pointer" }} onClick={toggleNotifications(anchor, false)} src={crosimg} alt="" />
						</Grid>
					</Grid>
				</Grid>
				<Divider />
				<Grid item px={5} mt={2} display={'flex'} direction={'column'}>
					<Grid container display={'flex'} direction={'column'}>
						{bsNotifications?.map((notification) => {
							return (
								<Grid item my={2} display={'flex'} alignItems={'center'}>
									<FiberManualRecordIcon style={{ color: "#dc3545", fontSize: "10px" }} />
									<div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
										<Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>{notification?.title}</Typography>
										<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{notification?.message}</Typography>
										<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{DateFormatter(notification?.updatedAt)}</Typography>
									</div>
								</Grid>)
						})}
					</Grid>
				</Grid>
			</List>
		</Box>
	)
	const list = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List onClick={handleContentClick}>
				<Grid mt={5} py={3} item display={'flex'}>
					<Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={personimg} alt='person img' /> <Typography style={{ fontWeight: 600, color: "#032541", marginLeft: '10px', fontSize: "18px" }}>My profile</Typography>
						</Grid>
						<Grid item>
							<img style={{ cursor: "pointer" }} onClick={toggleDrawer(anchor, false)} src={crosimg} alt="" />
						</Grid>
					</Grid>
				</Grid>
				<Divider />
				<Grid item px={5} mt={2}>
					<Typography style={{ color: "#dc3545" }}>Personal Information</Typography>
				</Grid>
				<Grid item px={5} mt={2} display={'flex'} direction={'column'}>
					<Grid container display={'flex'} direction={'column'}>
						<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
							<Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Full Names</Typography>
							<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{fullName}</Typography>
						</Grid>
						<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
							<Typography style={{ color: "#032541", fontWeight: "500px", fontSize: "14px" }}> Username</Typography>
							<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userName}</Typography>
						</Grid>
						<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
							<Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Email Address</Typography>
							<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userEmail}</Typography>
						</Grid>
						<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
							<Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>Phone Number</Typography>
							<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userPhone}</Typography>
						</Grid>
						<Grid item my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
							<Typography style={{ color: "#032541", fontWeight: 500, fontSize: "14px" }}>User Group</Typography>
							<Typography style={{ color: "#a1a7b2", fontWeight: 500, fontSize: "14px" }}>{userGroup}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</List>
		</Box>
	);



	return (
		<div style={{ fontFamily: 'Poppins' }} >
			{/*businessName and Action Buttons Container*/}
			<Grid container width={'100%'} justifyContent="space-between" alignItems="center" direction={'row'} marginBottom="2%" px={isMuiMobile ? '':'1%'}>
			{isMuiMobile && (
            <Grid item>
                <MenuIcon onClick={handleDrawerToggle}  sx={{fontWeight: 700, fontSize: '30px' }} />
            </Grid>
        )}
				{/* Business Name Label */}
				{isMuiMobile ? null :
				<Grid item>
					<span style={{ fontSize: "1.25rem", fontWeight: "700" }}>{(bsName === "All" && (affiliatedPartner === null || affiliatedPartner === 'undefined')) ? null : ((affiliatedPartner !== null || affiliatedPartner !== 'undefined') && bsName === 'All') ? affiliatedPartner : localStorage.getItem("businessName")}</span>
				</Grid>}
				{/*Action buttons*/}
				<Grid item display={'flex'}>
					{showWebhookMessage && xeroWebHookIntentStatus === false && xeroAccountingEnabled === true && !hideAcc ? (
						<div onClick={openWebHookTab} style={{
							display: "flex",
							cursor: "pointer",
							alignContent: "center",
							alignItems: "center",
							marginRight: "15px",
							color: "red",
							textDecoration: 'underline'
						}}>
							Webhook Intent Not Set!
						</div>
					) : null}
					{zohoAccountingEnabled === true && !hideAcc ?(
						<div onClick={() => {
							openZohoTab();
						}} style={{
							display: "flex",
							cursor: "pointer",
							alignContent: "center",
							alignItems: "center",
							marginRight: "15px"
						}}>
							<img src={zohoIcon} style={{width: "94px", height: "74px", objectFit: "contain"}}
								alt="zoho status"/>
						</div>):null
					}
					{xeroAccountingEnabled === true && !hideAcc ?(
						<div onClick={() => {
							if (xeroWebHookIntentStatus === false) {
								setShowWebhookMessage(true);
							}else{
								openXeroTab();
							}
							// setShowWebhookMessage(true);
						}} style={{
							display: "flex",
							cursor: "pointer",
							alignContent: "center",
							alignItems: "center",
							marginRight: "15px"
						}}>
							<img src={xeroIcon} style={{width: "34px", height: "34px", objectFit: "contain"}}
								alt="Accounting status"/>
						</div>):null
					}
					{quickbooksEnabled === true && !hideAcc ? (
						<div onClick={openQuickBooksTab} style={{
							display: "flex",
							cursor: "pointer",
							alignContent: "center",
							alignItems: "center",
							marginRight: "15px"
						}}>
							<img src={QuickBooks}
								 alt="QuickBooks status"/>
						</div>) : null
					}
					{accountingEnabled === true && !hideAcc ? (
						<div onClick={openInSameTab} style={{
							display: "flex",
							cursor: "pointer",
							alignContent: "center",
							alignItems: "center",
							marginRight: "15px"
						}}>
							<img src={AccountingImg} style={{width: "34px", height: "34px", objectFit: "contain"}}
								 alt="Accounting status"/>
						</div>) : null
					}

					{isZedEcommerce && !hideAcc ?
						<div onClick={openInNewTab3} style={{
							display: "flex",
							cursor: "pointer",
							alignContent: "center",
							alignItems: "center",
							marginRight: "15px"
						}}>
							<img src={ecommerceIcon} style={{width: "40px", height: "40px", objectFit: "contain"}}
								 alt="Ecommerce status"/>
						</div>
						: null
					}

					<div>
						{['right'].map((anchor) => (
							<div key={anchor}>
								<img onClick={toggleDrawer(anchor, true)} style={{
									width: "34px",
									height: "34px",
									objectFit: "contain",
									backgroundColor: "#C4E0F8",
									borderRadius: "50%",
									border: "1px solid transparent",
									padding: "2px",
									marginRight: "8px",
									cursor: "pointer"
								}} src={profileIcon} alt={"img"}/>
								<Drawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
								>
									{list(anchor)}
								</Drawer>
							</div>
						))}
					</div>

					<div>
						{['right'].map((anchor) => (
							<div key={anchor}>
								<img onClick={toggleNotifications(anchor, true)} style={{
									width: "34px",
									height: "34px",
									objectFit: "contain",
									backgroundColor: "#C4E0F8",
									borderRadius: "50%",
									border: "1px solid transparent",
									padding: "2px",
									marginRight: "8px",
									cursor: "pointer"
								}} src={notificationIcon} alt={"img"}/>
								<Drawer
									anchor={anchor}
									open={profileState[anchor]}
									onClose={toggleNotifications(anchor, false)}
								>
									{notificationList(anchor)}
								</Drawer>
							</div>
						))}
					</div>
					<div>
					<img style={{
						width: "34px",
						height: "34px",
						objectFit: "contain",
						backgroundColor: "#C4E0F8",
						borderRadius: "50%",
						border: "1px solid transparent",
						padding: "2px",
						marginRight: "8px",
						cursor: "pointer"
					}} src={logout} alt={'img'} onClick={() => {
						navigate("/logout");
					}}/>
						</div>
				</Grid>
			</Grid>
		</div>
	)
}
export {UserNavbar}
