import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import reactivate from "../../../common/images/reactivatecustomer.svg";
import activatecustomer from "../../../common/images/activatecustomer.svg";
import successIcon from "../../../common/images/customersuccessfullyadded.svg";
import { customerstyles } from "./styles";
import { Box, Button, Modal } from "@mui/material";
import { SuspendCustomerModal } from "./modals";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import HttpComponent from "../../School/MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL;
// suspended customers URL;
const suspendedCustomerUrl = baseUrl + "/api/customers_list?page=1&limit=10&status=Suspended";
const activateCutomerUrl = baseUrl + "/api/activateCustomer";

const SuspendedCustomer = (props) => {

  const customerSearchValue = props?.customerSearchValue
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  
  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((customer) => [customer?.customerName, customer?.customerType, customer?.paymentType, CurrencyFormatter(customer?.limit, "KES")]);
    const pdfHeaders  =  [["Customer Name", "Customer Type", "Payment Type", "Amount/Limit (KES)"]]
    setExportData({headers: pdfHeaders, data : pdfData});

    // csv exports
    const csvData = pageState?.data?.map((customer) => {
      return {
        "Customer Name":customer?.customerName,
        "Customer Type":customer?.customerType,
        "Payment Type":customer?.paymentType,
        "Amount":customer?.limit
      }
    })

    const csvHeaders = [
      {label:"Customer Name", key:"Customer Name"},
      {label:"Customer Type", key:"Customer Type"},
      {label:"Payment Type", key:"Payment Type"},
      {label:"Amount", key:"Amount"}
    ]

    setCsvExport({headers:csvHeaders, data:csvData})
  }, [pageState.data,  pageState.pageSize, dataGridPageSize]);


  // get susepdned customers from db;
  const getSuspendedCustomers = useCallback(async () => {
    const suspendedCustomersResp = await fetch( baseUrl +  `/api/customers_list?page=${pageState.page}&limit=${dataGridPageSize}&status=Suspended`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });

    if (suspendedCustomersResp.status === 200) {
      const suspendedData = await suspendedCustomersResp.json();

      setPageState({...pageState, isLoading: false, data: suspendedData.data, count: suspendedData.count });
    }
  });

  useEffect(() => {
    getSuspendedCustomers();
  }, [dataGridPageSize, pageState.page]);

  const [openSuspendModal, setSuspendModal] = useState(false);
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [activateCustomer, setActivateCustomer] = useState(false);
  const [approveId, setApproveId] = useState("");
  const [approveName, setApproveName] = useState("");

  const getCustomerDetailsAndActivate = (params) => {
    if (params) {
      setSuspendModal(true);
      setActivateCustomer(true);
      setApproveId(params.row.id);
      setApproveName(params.row.customerName);
    }
  };
  // SUSPEND CUSTOMER;
  const activateCustomers = useCallback(async () => {
    try {
      const activateCustomerResponse = await fetch(activateCutomerUrl + `?customerId=${approveId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      
      if (activateCustomerResponse.status === 202) {
        setSucess({ state: true, message: "User has been activated sucessfully!" });
        setSuspendModal(false);
        setActivateCustomer(false);
        getSuspendedCustomers()
      }

      setTimeout(() => {
        setSucess({ state: false });
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  });

  const searchCustomer = () => {
    HttpComponent({
      method:"GET",
      url:`/api/searchCustomer?state=Suspended&search=${customerSearchValue}`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
    }).catch((e) => {
      console.log(e.message);
    })
  }

  useEffect(() => {
    searchCustomer()
  },[customerSearchValue])

  return (
    <>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data?.map((customer) => ({
          id: customer._id,
          customerName: customer.customerName,
          customerType: customer.customerType,
          paymentType: customer.paymentType,
          amountRecieved: customer.limit,
        }))}
        columns={[
          { field: "customerName", headerName: "Full Name", flex: 1 },
          { field: "customerType", headerName: "Type", flex: 1 },
          { field: "paymentType", headerName: "Payment", flex: 1 },
          { field: "amountRecieved", headerName: "Amount", flex: 1 },
          {
            field: "Action",
            headerName: "Action",
            renderCell: (params) => {
              return (
                <div onClick={() => getCustomerDetailsAndActivate(params)} style={{cursor:"pointer"}}>
                  <img style={{ margin: "0 2px 0 0" }} src={activatecustomer} alt=""  />
                  <span>Activate</span>
                </div>
              );
            },
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />

      {openSuspendModal && <SuspendCustomerModal openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} activateCustomer={activateCustomer} activateCustomers={activateCustomers} />}

      {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
    </>
  );
};

export default SuspendedCustomer;
