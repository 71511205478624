export const stockStyles = {
  universalFont: {
    fontFamily: "Poppins",
  },
  mediumIntroText: {
    fontSize: "25px",
    fontWeight: "600",
    color: "#032541",
  },
  smallTextIntro: {
    fontSize: "14px",
    color: "#707070",
  },
  smallTextActive: {
    fontSize: "14px",
    color: "#dc3545",
  },
  smallUltraText: {
    fontSize: "17px",
    color: "#707070",
  },
  cardHolders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nextButtonInactive: {
    backgroundColor: "#032541",
    opacity: ".4",
    color: "white",
    width: "30%",
  },
  nextButtonActive: {
    backgroundColor: "#032541",
    opacity: "1",
    color: "white",
    width: "30%",
  },
  cancelButton: {
    color: "#dc3545",
    border: "1px solid #dc3545",
    fontWeight: "600",
    width: "20%",
  },
  viewBatchButton: {
    color: "white",
    backgroundColor: "#032541",
    width: "20%",
  },
  batchDetailsHolder: {
    backgroundColor: "rgba(112, 112, 112, 0.05)",
    padding: "10px",
  },
  submitBatchdisplay: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#032541",
  },
  cancelBatchButton: {
    color: "#dc3545",
    border: "1px solid #dc3545",
    fontWeight: "600",
  },
  approveButton: {
    backgroundColor: "#17ae7b",
    fontWeight: "600",
    color: "white",
  },
  declineButton:{
    backgroundColor: "#dc3545",
    fontWeight: "600",
    color: "white",
  },
  cancelStockButton:{
    border: "1px solid #032541",
    fontWeight: "600",
    color: "#032541",
  },
  approveStockModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "20%",
    height: "fit-content",
    fontFamily: "Poppins",
    padding: "30px",
  },
  purchaseOrderText:{
    color:"#032541",
    fontSize:"30px",
    fontWeight:"bold",

  },
  purchaseOrderDateHolder:{
    color:"#032541",
    fontSize:"14px",
    fontWeight:"bold",
    margin:'2px'
  },
  purchaseOrderDate:{
    color:"#032541",
    fontSize:"14px",
    margin:'2px'
  }
};
