import { useState, useEffect } from "react";
import { InputAdornment, TextField,  } from "@mui/material";
import { Search } from "@mui/icons-material";
import { FormControl, FormHelperText, InputLabel, OutlinedInput, Grid } from "@mui/material";
import React from "react";




const SearchFilter = (props) => {

  
  const placeholder = props?.placeholder

  return (
    <div>
      {/* <TextField
        //   label="With normal TextField"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">
                <Search/>
            </InputAdornment>,
          }}
        /> */}

      <FormControl sx={{  width: "100%" }} variant="outlined">
        {/* <InputLabel htmlFor="outlined-adornment-Search">Search</InputLabel> */}
        <OutlinedInput
        sx={{ "&.Mui-selected": {
          outline:"none"
      },}}
        placeholder={placeholder}
        onChange={(e) => props?.setInputValue(e.target.value) }
          id="outlined-adornment-Search"
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
        />
      </FormControl>      
    </div>
  );
};

export default SearchFilter;
