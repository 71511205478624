import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { creditnotestyles } from "../creditNoteStyling/styless";
import HttpComponent from "../../School/MakeRequest";
import { Share } from "@mui/icons-material";
import { Sharecreditnotemodal, ShareToPos } from "../creditnotemodals/modals";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { CustomTable } from "../customHook/table";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const Activecreditnote = () => {
  const LinkName = styled(Link)({
    textDecoration: "none",
    color: "#dc3545",
  });

  // share modal;
  const [share, setSharemodal] = useState(false);
  const [activeCreditNotes, setActiveCreditNotes] = useState([]);
  const [sendRequest, setSendingrequest] = useState(false);
	// DataGrid Pagination
	const [dataGridPageSize, setPageSize] = React.useState(5);
	const [pageState, setPageState] = useState({isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize,})

  
  const getActiveCreditNotes = () => {

    setPageState((old) => ({ ...old, isLoading: true }));
    HttpComponent({
      method: "GET",
      url: `/api/get_credit_note_by_status?limit=${dataGridPageSize}&page=${pageState.page}&status=approved`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {

         
          const { results, itemCount } = data.response;
          
          setPageState((old) => ({
						...old,
						isLoading: false,
						data: results,
						total: itemCount,
					}));
          // setPageCount(itemCount)
          // setActiveCreditNotes(results);
          // setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isTrue = false;

    !isTrue ? getActiveCreditNotes() : null;
    return () => {
      isTrue = true;
    };
  }, [ pageState.page, pageState.pageSize, dataGridPageSize]);

  //  creditNoteColumn =

  const [creditNoteId, setCreditNoteId] = useState("");

  const openPosModalAndSetId = (id) => {
    if (id) {
      setSharemodal(true);
      setCreditNoteId(id);
    }
  };
  // get active pos;
  const [activePos, setActivePos] = useState([]);

  const [posSerialNo, setPosSerialNo] = useState("");
  const getPos = async () => {
    HttpComponent({
      method: "GET",
      url: `/api/allTerminals/Active`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setActivePos(data.response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPos();
  }, []);

  // send to printer;
  const [sucess, setSucess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const printCreditNote = () => {
    HttpComponent({
      method: "POST",
      url: `/api/send_pos_print_credit_note`,
      body: { serialNumber: posSerialNo, creditNoteId: creditNoteId },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
    
        if (data.status === 200) {
          setSucess(true);

          setTimeout(() => {
            setSucess(false);
            setRefresh(true);
          }, 2000);
          // setActivePos(data.response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshPage = () => {
    getActiveCreditNotes();
  };

  useEffect(() => {
    refreshPage();
    setSharemodal(false);
  }, [refresh]);

  // const customTableData = activeCreditNotes.map((creditNote) => ({
  //   id: creditNote.creditNoteId,
  //   creditNoteNo: creditNote.creditNoteNo,
  //   approvedByName: creditNote.approvedByName,
  //   dateApproved: new Date(creditNote.dateApproved).toDateString() + " " + new Date(creditNote.dateApproved).toLocaleTimeString(),
  //   amount: creditNote.amount,
  // }))

  return (
    <>
      <DataGrid
        style={creditnotestyles.dataGridStyling}
        columns={[
          {
            field: "creditNoteNo",
            headerName: "Credit Note No",
            renderCell: (params) => {
              return <LinkName to={`/previewcreditnote/${params.row.id}`}>{params.row.creditNoteNo}</LinkName>;
            },
            flex: 1,
          },
          { field: "approvedByName", headerName: "Approved By", flex: 1 },
          { field: "dateApproved", headerName: "Approved On", flex: 1 },
          { field: "amount", headerName: "Total Amount", flex: 1 },
          {
            field: "Action",
            headerName: "Action",
            renderCell: (params) => {
              return (
                <>
                  <Share style={{ cursor: "pointer" }} onClick={() => openPosModalAndSetId(params.row.id)} />
                </>
              );
            },
            flex: 1,
          },
        ]}
        rows={pageState?.data?.map((creditNote) => ({
          id: creditNote?.creditNoteId,
          creditNoteNo: creditNote?.creditNoteNo,
          approvedByName: creditNote?.approvedByName,
          dateApproved: new Date(creditNote?.dateApproved).toDateString() + " " + new Date(creditNote.dateApproved).toLocaleTimeString(),
          amount: creditNote?.amount,
        }))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {setPageState((old) => ({...old, page: newPage + 1, pageSize: dataGridPageSize}));}}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />

      <Sharecreditnotemodal share={share} setSharemodal={setSharemodal} setPosSerialNo={setPosSerialNo} activePos={activePos} printCreditNote={printCreditNote} creditNoteId={creditNoteId} />
      <ShareToPos sendRequest={sendRequest} setSendingrequest={setSendingrequest} />
      {sucess && <SuccessAlertRightAligned message={"Credit note successfully printed!"} sucess={sucess} />}

      {/* <CustomTable tableHeaders={["creditNote", "approvedByName", "dateApproved", "amount"]} activeCreditNotes={customTableData}  /> */}
      {/* <CustomTable tableHeaders={[{header:"creditNote" , field:"creditNoteNo"} , {header: "approvedByName", field:"approvedByName"} , {header:"dateApproved", field:"dateApproved"} , {header: "amount", field:"amount"} ]} activeCreditNotes={customTableData}  /> */}
    </>
  );
};

export default Activecreditnote;
