import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { Grid, Stack, Typography, InputLabel, TextField ,Button } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import PhoneInput from "react-phone-input-2";

export default function EditSalesPerson() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue ,code, firstName , lastName  , id ,salesPhone] = state.split("?")
    const [formData, setFormdata] = useState({ code: code, firstName:firstName ,lastName:lastName , salesPhone:salesPhone})


    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }

    function updateSalesPerson(){
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/partnerupdate-sales-person?id=${id}`,
                body: formData,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200) {
                    setFormdata({ code: '', firstName: '' , lastName:"" });
                    setSuccessShow({ state: true, message: data?.response?.message});
                    setTimeout(() => {
                        navigate(-1);
                    }, 1500);
                }else{
                    setErrorShow({ state: true, message: data?.response?.message});
                }

            })
            
        } catch (error) {
            setErrorShow({ state: true, message: error?.message});

        }
    }
    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'} >
                <Stack alignItems='center' direction='row' spacing={1}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Add Sales Person</Typography>
                </Stack>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumb name={'Add Sales Person'} />
            </Grid>
            <Grid item width={'50%'} gap={1} mt={2}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>First Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.firstName} name='firstName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item width={'50%'} mt={1} gap={1}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Last Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.lastName} name='lastName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item width={'50%'} mt={1} gap={1}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Code</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.code} name='code' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item  width={'50%'} mt={1} gap={1}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Phone</InputLabel>
                 <PhoneInput required inputStyle={{ height: '55px', width: '100%' }} country={"ke"} enableSearch={true} name="phone" value={formData.salesPhone} onChange={(phone) => formData.salesPhone = phone} />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => updateSalesPerson()} disabled={!formData.firstName || !formData.code || !formData.lastName} style={!formData.lastName || !formData.code || !formData.firstName ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}