import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AllTransactionsTable from "../components/Reports/AllTransactionsTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearch,
  setSelect,
  setSelect2,
} from "../../../features/searchSlice";
import Papa from "papaparse";
import TransactionsDropdown from "../components/Reports/TransactionsDropdown";
// import DatePicker from "../components/Reports/DatePicker";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  clearPeriod,
  clearSelectedDate,
  handleSelectedDate,
} from "../../../features/dateSlice";
import AllTransactionsDropdown from "../components/Reports/AllTransactionsDropdown";
import { makeStyles } from "@mui/styles";
import OverallPerformanceTable from "../components/Reports/OverallPerformanceTable";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { removeToExport } from "../../../features/actionSlice";
import ExportDropdown from "../components/Reports/ExportDropdown";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const baseUrl = process.env.REACT_APP_BASE_URL;

function OverallPerformance() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.transactions);

  const xlsxReport = (data, filteredData) => {
    // console.log(data);
    // e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "Overall Performance" + `__report.xlsx`);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = "Overall Performance";

    const headers = [
      ["Business Name", "Business Id", "Business Type", "Total Amount"],
    ];

    const data = exportData.map((data) => [
      data?.businessName,
      data?.businessId,
      data?.businessType,
      data?.total,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Overall Performance" + ` report.pdf`);
  };

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy);
  const [searchBy, setSearchBy] = useState(reduxSearchBy);

  useEffect(() => {
    setSearchBy(reduxSearchBy);
  }, [reduxSearchBy]);

  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState("All");
  const [selected2, setSelected2] = useState("All");

  let categories2 = [
    {
      categoryName: "All",
    },
    {
      categoryName: "ZPM Card",
    },
    {
      categoryName: "ZPM Cash",
    },
  ];

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  const handleSelect2 = (e) => {
    setSelected2(e.target.value);
  };

  const fetchBusinessCategories = () => {
    try {
      axios
        .get(baseUrl + "/api/fetchBusinessCategories", {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        })
        .then((response) => {
          if (response) {
            setCategories(response.data.data);
            let obj = {
              categoryName: "All",
            };
            setCategories((prevCategories) => [obj, ...prevCategories]);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessCategories();
  }, []);

  useEffect(() => {
    if (selected) {
      if (selected === "All") {
        dispatch(setSelect(""));
      } else {
        dispatch(setSelect(selected));
      }
    } else {
      dispatch(setSelect(""));
    }
  }, [selected]);

  useEffect(() => {
    if (selected2) {
      if (selected2 === "All") {
        dispatch(setSelect2(""));
      } else {
        dispatch(setSelect2(selected2));
      }
    } else {
      dispatch(setSelect2(""));
    }
  }, [selected2]);

  const [dateValue, setDateValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [finalStartDate, setFinalStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [finalEndDate, setFinalEndDate] = useState(null);

  const dateChange = (e) => {
    setDateValue(e);
  };

  useEffect(() => {
    if (dateValue) {
      setStartDate(dateValue[0]);
      setEndDate(dateValue[1]);
    }
  }, [dateValue]);

  useEffect(() => {
    if (startDate) {
      const inputDate = new Date(startDate);

      // Get the components of the date
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      const day = inputDate.getDate().toString().padStart(2, "0");
      const hours = inputDate.getHours().toString().padStart(2, "0");
      const minutes = inputDate.getMinutes().toString().padStart(2, "0");
      const seconds = inputDate.getSeconds().toString().padStart(2, "0");
      const milliseconds = inputDate
        .getMilliseconds()
        .toString()
        .padStart(3, "0");
      const offset = "+00:00";

      // Construct the formatted date string
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offset}`;

      console.log("formattedDate");
      console.log(formattedDate);
      setFinalStartDate(formattedDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      const inputDate = new Date(endDate);

      // Get the components of the date
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      const day = inputDate.getDate().toString().padStart(2, "0");
      const hours = inputDate.getHours().toString().padStart(2, "0");
      const minutes = inputDate.getMinutes().toString().padStart(2, "0");
      const seconds = inputDate.getSeconds().toString().padStart(2, "0");
      const milliseconds = inputDate
        .getMilliseconds()
        .toString()
        .padStart(3, "0");
      const offset = "+00:00";

      // Construct the formatted date string
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offset}`;

      console.log("formattedDate2");
      console.log(formattedDate);
      setFinalEndDate(formattedDate);
    }
  }, [endDate]);

  useEffect(() => {
    if (finalStartDate && finalEndDate) {
      let obj = {
        start: finalStartDate,
        end: finalEndDate,
      };
      dispatch(handleSelectedDate(obj));
    }
  }, [finalStartDate, finalEndDate]);

  useEffect(() => {
    dispatch(clearSelectedDate());
    dispatch(clearPeriod());
  }, []);

  const amount = useSelector((state) => state.transactionAmount.amount2);

  const toExport = useSelector((state) => state.dropdownAction.toExport);

  useEffect(() => {
    if (toExport === "excel") {
      xlsxReport(exportData);
      dispatch(removeToExport());
    } else if (toExport === "csv") {
      exportArrayToCSV(exportData, "Overall Performance.csv");
      dispatch(removeToExport());
    } else if (toExport === "pdf") {
      exportPDF();
      dispatch(removeToExport());
    }
    removeToExport();
  }, [toExport]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Overall Performance
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Overall Performance" />

        <Stack direction={"row"} spacing={1}>
          <Typography
            fontFamily="Poppins"
            fontSize={15}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="grey"
          >
            Total Amount :
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={15}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            {amount.toLocaleString()}
          </Typography>
        </Stack>

        <ExportDropdown />
      </Stack>
      <Box width={"100%"}>
        <Stack direction={"row"} spacing={3} mb={2}>
          <TextField
            id="input-with-icon-textfield"
            label={`Search By ${searchBy}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearch}
            className={classes.focusedTextField}
          />
          <TextField
            select
            label="Business Type"
            defaultValue="All"
            size="small"
            value={selected}
            onChange={handleSelect}
            sx={{
              width: "150px",
            }}
            className={classes.focusedTextField}
          >
            {categories?.map((option) => (
              <MenuItem key={option.categoryName} value={option.categoryName}>
                {option.categoryName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Transaction Type"
            defaultValue="All"
            size="small"
            value={selected2}
            onChange={handleSelect2}
            sx={{
              width: "150px",
            }}
            className={classes.focusedTextField}
          >
            {categories2?.map((option) => (
              <MenuItem key={option.categoryName} value={option.categoryName}>
                {option.categoryName}
              </MenuItem>
            ))}
          </TextField>
          {/* <DatePicker /> */}
          <DateRangePicker
            onChange={dateChange}
            value={dateValue}
            clearIcon={null}
          />
          {/* <Button
          variant="outlined"
          sx={{
            bgcolor: "#f5f6f7",
            borderColor: "#f5f6f7",
            color: "#032541",
            ":hover": {
              borderColor: "#032541",
            },
            textTransform: "none",
          }}
          onClick={handleAllTime}
        >
          All Time
        </Button> */}
          <AllTransactionsDropdown />
        </Stack>
        <Stack>
          <OverallPerformanceTable />
        </Stack>
      </Box>
    </Stack>
  );
}

export default OverallPerformance;
