import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Modal, Grid, Box } from '@mui/material'
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Stack, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { useDispatch, useSelector } from "react-redux";
import { handleItemClick } from "../../../../features/actionSlice";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import WarningImg from '../../../../images/warning-icn.svg'

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };

export default function TerminalUsersDropdown2() {
  const dispatch = useDispatch();

  const handleClick = (clicked) => {
    dispatch(handleItemClick(clicked)); //setting the clicked item in redux
  };

  const [openResetTerminaluserPassword, setOpenResetTerminaluserPassword] = React.useState(false)
  const [successShow, setSuccessShow] = React.useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = React.useState({ state: false, message: "" })

  const handleOpenResetModal = () => {
    setOpenResetTerminaluserPassword(true)
  }
  const handleCloseResetModal = () => {
    setOpenResetTerminaluserPassword(false)
  }
  const userDetails = useSelector((state) => state.userDetails);

  const handleResetPassword = () => {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/resetZedPayItPassword?userId=${userDetails.userId}`,
        body: {
          businessNumber: userDetails.businessNumber
        },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data?.status === 200) {
          setSuccessShow({ state: true, message: data?.response?.message })

          setTimeout(() => {
            handleCloseResetModal()
          }, 1000)
        } else {
          setErrorShow({ state: true, message: data?.response?.message })
        }
      })
    } catch (error) {

    }
  }

  return (
    <>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            {/* <ThemeProvider theme={theme}> */}
            <Button variant="text" {...bindTrigger(popupState)}>
              <MoreVertIcon sx={{ color: "#032541" }} />
            </Button>
            {/* </ThemeProvider> */}
            <Menu {...bindMenu(popupState)}>
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Edit");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <EditIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Edit User
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
              <>
                <MenuItem
                  onClick={() => {
                    handleOpenResetModal();
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <ReplayIcon color={"success"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Reset Password
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("moveToOtherBusiness");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <MoveDownIcon color={"success"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Transfer User To Another Outlet
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Modal
        open={openResetTerminaluserPassword}
        onClose={handleCloseResetModal}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
        // slotProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        // BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid container display={'flex'} spacing={4}>
              <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item ml={2}>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Reset Pin
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to reset pin for {userDetails.email}
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={handleCloseResetModal} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleResetPassword} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Reset Pin </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
