import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businesses: []
}


const EditedConvenienceSlice = createSlice({
  name:"editedBusinesses",
  initialState,
  reducers:{
    handleFeeEdited:(state, action) => {
        state.businesses.push(action.payload)
    },
    handleRemove: (state, action) => {
        state.businesses = state.businesses.filter((item) => item.id !== action.payload)
    }

  }
})

export const { handleFeeEdited, handleRemove } = EditedConvenienceSlice.actions;

export default EditedConvenienceSlice.reducer