import { Box, Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import "./deleteAccountRequest.css"
import HttpComponent from '../School/MakeRequest';
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

const DeleteAccountRequest = () => {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        firstName: "",
        secondName: "",
        userEmail: "",
        userPhone: "",
        reason: "",
    });
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [validEmail, setValidEmail] = useState(true);
    useEffect(() => {
        if (formData.userEmail != "") {
            let result = validateEmail(formData.userEmail);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.userEmail]);

    const handleSubmit = async () => {
        if (formData.firstName === "") {
            setErrorShow({ ...errorShow, state: true, message: "First name is required" })
            return;
        } else if (formData.secondName === "") {
            setErrorShow({ ...errorShow, state: true, message: "Last name is required" })
            return;
        }
        else if (formData.userEmail === "") {
            setErrorShow({ ...errorShow, state: true, message: "Email is required" })
            return;
        }
        else if (formData.userPhone === "") {
            setErrorShow({ ...errorShow, state: true, message: "Phone number is required" })
            return;
        }
        else if (formData.reason === "") {
            setErrorShow({ ...errorShow, state: true, message: "Reason is required" })
            return;
        }

        try {
            HttpComponent({
                method: 'POST',
                url: `/delete/user/account/request`,
                body: formData,
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200 || data.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: "Succcessfull submitted the request" })
                    setTimeout(() => {
                        // setSuccessShow({ ...successShow, state: false, message: "" })
                        navigate('/dashboard')

                    }, 1000)
                } else {
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.error })
                }
            })
        } catch (error) {
            console.log(error, 'error')
            setErrorShow({ ...errorShow, state: true, message: error })

        }
    }
    console.log(formData, "formData")
    return (
        <Grid >
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <div className="formbold-main-wrapper">
                <div className="formbold-form-wrapper">
                    <img src="https://zed.business/assets/img/logo-01.svg" alt="zed logo" width="120px" />


                    <div className="formbold-form-title">
                        <h2>We are sorry to see you leave!</h2>
                        <p>
                            Deleting your account will remove all of your information from our database. This cannot be undone.
                        </p>
                    </div>

                    <div className="formbold-input-flex">
                        <div>
                            <label htmlFor="firstName" className="formbold-form-label">
                                First name
                            </label>
                            <input type="text" name="firstName" id="firstName" className="formbold-form-input" onChange={handleInputChange} />
                        </div>
                        <div>
                            <label htmlFor="secondName" className="formbold-form-label">
                                Last name
                            </label>
                            <input type="text" name="secondName" id="secondName" className="formbold-form-input" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="formbold-mb-3">
                        {!validEmail ?
                            <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                Please enter a valid email
                            </Typography>
                            :
                            null
                        }
                        <label htmlFor="email" className="formbold-form-label">
                            Email
                        </label>
                        <input type="email" name="userEmail" id="userEmail" className="formbold-form-input" onChange={handleInputChange} />
                    </div>

                    <div className="formbold-mb-3">
                        <label htmlFor="phone" className="formbold-form-label">
                            Phone number
                        </label>
                        <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={formData.userPhone} name={"userPhone"} onChange={(phone) => formData.userPhone = phone} />
                            </Box>

                        {/* <input type="text" name="userPhone" id="userPhone" className="formbold-form-input" onChange={handleInputChange} /> */}
                    </div>

                    <div className="formbold-mb-3">
                        <label htmlFor="reason" className="formbold-form-label">
                            Reason For Requesting Deletion
                        </label>
                        <textarea rows="6" cols="50" name="reason" id="reason" className="formbold-form-input" onChange={handleInputChange}></textarea>
                    </div>

                    <button className="formbold-btn" onClick={() => handleSubmit()}>Delete Account</button>

                </div>
            </div>

        </Grid>
    )
}

export default DeleteAccountRequest