import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HttpComponent from './MakeRequest';
import { Box, Breadcrumbs, styled, Button, FormControl, Tab, Tabs, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { ArrowBackIos, EditOutlined } from '@mui/icons-material';
import DeactivateUser from '../../common/images/deactivate-user.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from "@mui/icons-material/Search";

import ExportMenu from './ExportMenu';
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';
import CustomSearchInput from './CustomSearchInput';
import CustomSelectField from "./CustomSelectField";


let baseUrl = process.env.REACT_APP_BASE_URL;
const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"students"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    All Students
  </Typography>
];
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const AllStudents = () => {
  const [tabvalue, setTabValue] = useState("active");
  const [allStudentData, setAllStudentData] = useState([])
  const [searcheData, setSearchData] = useState('')
  const [pageSize, setPageSize] = React.useState(10);
  //console.log(pageSize);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: 100, });
  const navigate = useNavigate()
  const [gridLoading, setGridLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [coursesOptions, setCoursesOptions] = useState([]);
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
  // get grades

  const [grade, setGrade] = useState('')
  const [stream, setStream] = useState('')
  const [courseName, setCourseName] = useState('')


  const setNewTabValue = (prev, newValue) => {
    setTabValue(newValue);
  };
  const { userId, X_Authorization } = useSelector((store) => store.user);


  const fetchAllStudents = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));


    try {
      HttpComponent({
        method: "GET",
        url: `/api/get_students_in_school?page=${pageState.page}&limit=${pageSize}`,
        token: X_Authorization,
      }).then((data) => {
        console.log('data', data);
        setSearchData(data.response.data)
        setAllStudentData(data.response.data)
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: data.response.data,
          total: data.response.count,
        }));


      });

    } catch (error) {

    }
  }
  useEffect(() => {
    fetchAllStudents()
  }, [pageState.page, pageSize])
  const AllStudentscolumns = [
    {
      field: "itemNumber", headerName: "Student ID", flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student/${params.row.customerId}/${params.value}`)}>
              {params.value}
            </Typography>
          </div>
        );
      }
    },
    {
      field: "studentName", headerName: "Student Name", flex: 1, renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.row.firstName + " " + params.row.lastName}

            </Typography>
          </div>
        );
      }
    },
    { field: "grade", headerName: "Grade", flex: 1 },
    { field: "term", headerName: "Term", flex: 1 },
    { field: "stream", headerName: "Stream", flex: 1 },
    { field: "parentName", headerName: "Parent Name", flex: 1 },
    { field: "parentPhone", headerName: "Parent Phone", flex: 1 },
    {
      field: "pendingInvoices", headerName: "Pending Invoices", flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value == 0 ? <span style={{ color: "#00B87C" }}>{params.value}</span> : <span style={{ color: "#dc3545" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "pendingAmount", headerName: "Pending Amount", flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value == 0 ? <span style={{ color: "#17ae7b" }}>{params.value}</span> : params.value < -1 ? <span style={{ color: "#F69109" }}>{params.value}</span> : <span style={{ color: "#dc3545" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "Action", headerName: "Action", flex: 1, renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ display: "flex", alignItems: "center" }}>

                  <IconButton>
                    <EditOutlined style={{ color: "#032541" }} />
                  </IconButton>
                  <IconButton>
                    <img src={DeactivateUser} alt="" srcset="" />
                  </IconButton>
                </Box>
              </Box>
            </Typography>
          </div>
        );

      }
    }


  ]
  const AllStudentscolumnsCollege = [
    {
      field: "itemNumber", headerName: "Student ID", flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student/${params.row.customerId}/${params.value}`)}>
              {params.value}
            </Typography>
          </div>
        );
      }
    },
    {
      field: "studentName", headerName: "Student Name", flex: 1, renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.row.firstName + " " + params.row.lastName}

            </Typography>
          </div>
        );
      }
    },
    { field: "grade", headerName: "Course", flex: 1 },
    { field: "term", headerName: "Term", flex: 1 },
    { field: "stream", headerName: "Stream", flex: 1 },
    { field: "parentPhone", headerName: "Phone Number", flex: 1 },
    {
      field: "pendingInvoices", headerName: "Pending Invoices", flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value == 0 ? <span style={{ color: "#00B87C" }}>{params.value}</span> : <span style={{ color: "#dc3545" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "pendingAmount", headerName: "Pending Amount", flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value == 0 ? <span style={{ color: "#17ae7b" }}>{params.value}</span> : params.value < -1 ? <span style={{ color: "#F69109" }}>{params.value}</span> : <span style={{ color: "#dc3545" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "Action", headerName: "Action", flex: 1, renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ display: "flex", alignItems: "center" }}>

                  <IconButton>
                    <EditOutlined style={{ color: "#032541" }} />
                  </IconButton>
                  <IconButton>
                    <img src={DeactivateUser} alt="" srcset="" />
                  </IconButton>
                </Box>
              </Box>
            </Typography>
          </div>
        );

      }
    }


  ]
  const AllStudentsrows = allStudentData?.filter((item) => {
    return searchValue === '' ? item :
      item?.firstName.includes(searchValue?.toLocaleLowerCase()) ||
      item?.itemNumber.includes(searchValue) ||
      item?.lastName.toLowerCase()?.includes(searchValue) ||
      item?.grade.toLowerCase()?.includes(searchValue) ||
      item?.stream?.includes(searchValue)
  }).filter((item) => {
    return courseName === '' ? item : courseName === 'all' ? item : item?.grade?.includes(courseName)

  }).filter((item) => {
    return grade?.toLocaleLowerCase() === '' ? item : grade?.toLocaleLowerCase() === 'all' ? item : item?.grade?.toLocaleLowerCase()?.includes(grade?.toLocaleLowerCase())
  }).filter((item) => {
    return stream === '' ? item : stream === 'all' ? item : item?.stream.includes(stream)
  }).map((item) => {
    return {
      id: item.itemNumber,
      customerId: item?.customerId,
      itemNumber: item.itemNumber,
      firstName: item.firstName,
      lastName: item.lastName,
      parentName: item.parentName,
      parentPhone: item.parentPhone,
      grade: item.grade,
      term: item.term,
      stream: item.stream,
      pendingInvoices: item.pendingInvoices,
      pendingAmount: item.pendingAmount
    }
  })

  const navigateStudentPayments = (id) => {
    navigate(`/school/student/${id}`);
  }
  // get grades


  // form state to search data

  const [formSearch, setFormSearch] = useState({ search: "" })
  console.log(formSearch, 'tryuio')

  // get grades
  const [gradeOptions, setGradeOptions] = useState([])
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Grades");

    if (response.status === 201) {
      setGradeOptions(data.data.map((itemGrade) => {
        return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
      }
      ))
    }
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatusOptions(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])
  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses");
    if (response.status === 200) {
      // setCoursesOptions(data.data);
      setCoursesOptions(data.data.map((courses) => {
        return { value: courses.courseName, label: courses.courseName }
      }
      ))
    }
  };

  useEffect(() => {
    getCourses();
  }, []);
  const csvStudentColumns = [
    { label: "Student Admission", key: "Student Admission" },
    { label: "Student Name", key: "Student Name" },
    { label: "Grade", key: "Grade" },
    { label: "Stream", key: "Stream" },
    { label: "Parent Name", key: "Parent Name" },
    { label: "Parent Phone", key: "Parent Phone" },
    { label: "Pending Invoices", key: "Pending Invoices" },
    { label: "Pending Amount", key: "Pending Amount" },
  ]
  const csvStudentColumnsCollege = [
    { label: "Student Admission", key: "Student Admission" },
    { label: "Student Name", key: "Student Name" },
    { label: "Course", key: "Grade" },
    { label: "Phone Number", key: "Parent Phone" },
    { label: "Pending Invoices", key: "Pending Invoices" },
    { label: "Pending Amount", key: "Pending Amount" },
  ]

  const newstudentFileData = allStudentData.map((item) => {
    return {
      "Student Admission": item.itemNumber,
      "Student Name": item.firstName + " " + item.lastName,
      "Grade": item.grade,
      "Stream": item.stream,
      "Parent Name": item.parentName,
      "Parent Phone": item.parentPhone,
      "Pending Invoices": item.pendingInvoices,
      "Pending Amount": item.pendingAmount

    }
  })
  const newstudentFileDataCollege = allStudentData.map((item) => {
    return {
      "Student Admission": item.itemNumber,
      "Student Name": item.firstName + " " + item.lastName,
      "Course": item.grade,
      "Phone Number": item.parentPhone,
      "Pending Invoices": item.pendingInvoices,
      "Pending Amount": item.pendingAmount
    }
  })




  const studentFileData = allStudentData.map((item) => {
    return {
      "Student Admission": item.itemNumber,
      "Student Name": item.firstName + " " + item.lastName,
      "Grade": item.grade,
      "Stream": item.stream,
      "Parent Name": item.parentName,
      "Parent Phone": item.parentPhone,
      "Pending Invoices": item.pendingInvoices,
      "Pending Amount": item.pendingAmount

    }
  })
  const studentFileDataCollege = allStudentData.map((item) => {
    return {
      "Student Admission": item.itemNumber,
      "Student Name": item.firstName + " " + item.lastName,
      "Course": item.grade,
      "Phone Number": item.parentPhone,
      "Pending Invoices": item.pendingInvoices,
      "Pending Amount": item.pendingAmount
    }
  })

  const fileDataStudentHeaders = [["Student Admission", "Student Name", "Grade", "Stream", "Parent Name", "Parent Phone", "Pending Invoices", "Pending Amount "]];
  const fileDataStudentHeadersCollege = [["Student Admission", "Student Name", "Course", "Phone Number", "Pending Invoices", "Pending Amount "]];
  const fileDataStudentPDF = allStudentData.map((item) => [item.itemNumber, item.firstName + " " + item.lastName, item.grade, item.stream, item.parentName, item.parentPhone, item.pendingInvoices, item.pendingAmount])
  const fileDataStudentPDFCollege = allStudentData.map((item) => [item.itemNumber, item.firstName + " " + item.lastName, item.grade, item.parentPhone, item.pendingInvoices, item.pendingAmount])
  const businessName = localStorage.getItem("businessName")




  console.log(searchValue, 'tdfgyhjkl;')

  return (
    <div>
      <div>
        <div>
          <Box style={{ display: "flex", }}>
            <Box style={{ display: "flex" }}>
              <ArrowBackIos className='mr-2' />
              <h1 style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Students</h1>
            </Box>
            <Box style={{}} className='ml-auto'>
              <h4 style={{ color: "#111927", fontWeight: "bold", fontSize: "30px" }}>{pageState?.total}</h4>
              <h4 style={{ color: " #111927", fontSize: "14px", fontWeight: "normal" }}>Total Students</h4>
            </Box>
          </Box>
          <div className=''>
            <Box component={'nav'} aria-label="breadcrumb">
              <Breadcrumbs
                separator={
                  <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>
          </div>
          <Box>
            <TabContext value={tabvalue} index={0}>
              <AntTabs variant="centered" onChange={setNewTabValue} aria-label="ant example">
                <AntTab label="Active Students" value="active" />
                <AntTab label="Suspended Students" value="suspended" />
              </AntTabs>
              {/* Search Tab */}
              <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
                <div>
                  <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search by Admission...'} sx={{ marginRight: "20px" }} />
                  {schoolTypeName?.includes("University") ?
                    <CustomSelectField value={courseName} onChange={(e) => setCourseName(e.target.value)} name={"courseName"} placeholder={"select CourseName"} options={coursesOptions} sx={{ marginRight: "20px" }} />
                    : <CustomSelectField value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"select Grade"} options={gradeOptions} sx={{ marginRight: "20px" }} />}

                  {schoolTypeName?.includes("University") ? <CustomSelectField value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"select Year"} options={gradeOptions} sx={{ marginRight: "20px" }} />
                    : <CustomSelectField value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"select Stream"} options={streamsOptions} sx={{ marginRight: "20px" }} />}
                </div>

                <div className='export ml-auto' >
                  <ExportMenu
                    csvColumns={schoolTypeName?.includes("University") ? csvStudentColumnsCollege : csvStudentColumns}
                    fileData={schoolTypeName?.includes("University") ? newstudentFileDataCollege : newstudentFileDataCollege}
                    fileHeaders={schoolTypeName?.includes("University") ? fileDataStudentHeadersCollege : fileDataStudentHeaders}
                    fileDataPDF={schoolTypeName?.includes("University") ? fileDataStudentPDFCollege : fileDataStudentPDF}
                    title={`${businessName} | Students All Students | ${new Date()}`}
                    fileName={'Student All Report  Students'}

                  />
                </div>

              </Box>


              <TabPanel value="active">
                <DataGrid style={{ height: "500px", width: "auto", overflow: "visible", }}
                  rows={AllStudentsrows}
                  components={{ NoRowsOverlay: NoRowsOverlay }}


                  // onRowClick={(row) => {
                  //   // handle row click event here
                  //   console.log("Clicked row:", row.id);
                  //   navigateStudentPayments(row.id);
                  // }}
                  rowCount={pageState?.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  columns={schoolTypeName?.includes("University") ? AllStudentscolumnsCollege : AllStudentscolumns}
                  onPageChange={(newPage) => {
                    setPageState((old) => ({
                      ...old,
                      page: newPage + 1,
                      pageSize: pageSize,
                    }));
                  }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

                />


              </TabPanel>
              <TabPanel value="suspended">
                <div className='row'>
                  <div style={{ fontSize: "50rem", color: "grey" }}>
                    <h1>Coming Soon</h1>

                  </div>

                </div>
              </TabPanel>

            </TabContext>
          </Box>







        </div>
      </div>
    </div>
  )
}

export default AllStudents