import React from 'react'
import AssignCategory from '../../../payit/components/outlets/addCategory'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'

function AssignCategoriesToOutlet() {
    let storeId = useSelector(state => state.outletDetails.storeId)
    let branchId = useSelector(state => state.outletDetails.storeId)
  return (
    <Stack height={'100vh'} bgcolor={'white'} padding={4}>
        <AssignCategory outLetId={storeId} branchId={branchId}/>
    </Stack>
  )
}

export default AssignCategoriesToOutlet
