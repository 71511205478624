import React, { useState, useEffect } from "react";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import DateFormatter from "../../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "@mui/icons-material";
import { Button, Checkbox } from "@mui/material";
import { stockStyles } from "../stockStyling";
const AddstockReport = ({ setExportData, setCsvExport, inputValue }) => {
  const navigate = useNavigate();
  const stockfunctions = new StockFunctions();
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {
    if(inputValue === "")
    stockfunctions.getAddStockReports(pageState?.page, dataGridPageSize).then((data) => {
      if (data?.status === 200) {
        setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
      }
    });
  }, [pageState?.page, dataGridPageSize, inputValue]);

  // search stock By Number;
  // searchStockReportByType = (searchValue,stockType,page,limit)
  useEffect(() => {
    if(inputValue)
    stockfunctions.searchStockReportByType(inputValue,'addStock',pageState?.page,dataGridPageSize).then((data) => {
      if(data?.status === 200){
        setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
      }
    })
  },[pageState?.page, dataGridPageSize, inputValue])

  const [stockIds, setStockIds] = useState([]);

  const getStockIds = (id) => {
    const newStockIds = [...stockIds];

    const currentIndex = newStockIds.indexOf(id);

    if (currentIndex === -1) {
      newStockIds.push(id);
    } else {
      newStockIds.splice(currentIndex, 1);
    }

    setStockIds(newStockIds);
  };

  const stockColumn = [
    {
      field: "batchNumber",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Batch No</span>;
      },
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Checkbox onChange={() => getStockIds(params?.row?.id)} color="error" />
            <span style={{ color: "red", cursor: "pointer" }} onClick={() => navigate(`/reports/viewMore?${params?.row?.id}?NOTARRAY?ADDSTOCK`)}>
              {params?.row?.batchNumber}
            </span>
          </div>
        );
      },
    },
    {
      field: "createdByName",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Requested By</span>;
      },
      flex: 1,
    },

    {
      field: "approvedBy",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Approved By</span>;
      },
      flex: 1,
    },
    {
      field: "dateCreated",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Date Requested</span>;
      },
      flex: 1,
    },

    {
      field: "dateApproved",
      renderHeader: (params) => {
        return <span style={{ color: "#032541", fontSize: "14px", fontWeight: 600 }}>Approved Date</span>;
      },
      flex: 1,
    },
  ];


  const stockData = pageState?.data?.map((stock) => ({
    id: stock._id,
    batchNumber: stock?.batchNumber,
    approvedBy: stock?.approvedBy,
    createdByName: stock?.createdByName,
    dateCreated: DateFormatter(stock?.dateCreated) ,
    dateApproved: DateFormatter(stock?.dateApproved),
  }));

  // set exports Data
  // exported data;

  useEffect(() => {
    const pdfData = pageState?.data?.map((stock) => [stock?.batchNumber, stock?.approvedBy, DateFormatter(stock?.dateApproved)])
    const pdfHeaders = [["Batch Number", "Approved By", "Date Approved"]]
    setExportData({ headers: pdfHeaders, data: pdfData });

    const csvData = pageState?.data?.map((stock) => {
      return {
        "Batch Number": stock?.batchNumber,
        "Approved By":stock?.approvedBy,
        "Date Approved": DateFormatter(stock?.dateApproved) 
      }
    })

    const csvHeaders = [
      {label:'Batch Number', key:'Batch Number'},
      {label:'Approved By', key:'Approved By'},
      {label:'Date Approved', key:'Date Approved'},
    ]

    setCsvExport({ headers: csvHeaders, data: csvData });
  }, [ pageState?.data, pageState?.page, dataGridPageSize]);

  return (
    <div>
      <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={stockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={stockData} />

      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        {stockIds.length > 1 ? (
          <Button style={{ color: "white", backgroundColor: "black" }} onClick={() => navigate(`/reports/viewMore?${JSON.stringify(stockIds)}?ISARRAY?ADDSTOCK`)}>
            View Report
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default AddstockReport;
