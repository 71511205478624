import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    code: "",
    name: "",
    description: "",
    value: ""
};

const configToUpdateSlice = createSlice({
  name: "configDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
        state.code= action.payload.code;
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.value = action.payload.value;
    },
  },
});

export const { setDetails } = configToUpdateSlice.actions;

export default configToUpdateSlice.reducer;
