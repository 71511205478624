import { Grid, Typography, InputLabel, TextField, Button } from '@mui/material'
import {useNavigate} from 'react-router-dom'
import { useEffect, useState } from 'react'
import {useSelector ,useDispatch} from 'react-redux'
import HttpComponent from '../../../../School/MakeRequest'
import { SuccessAlert } from '../../../../snackBar Alerts/successAlert'
import { ErrorAlert } from '../../../../snackBar Alerts/errorAlert'
import { handleResetDetail } from '../../../../../features/businessAddPartner'
export default function AddAccountPartnerStepper(){
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [accountNumber, setAccountNumber] = useState('')
    const {businessNumber  ,name  , branchId } = useSelector((store)=>store.BusinessPartnerDetails.businessDetails)
    const dispatch = useDispatch()

  
    const navigate = useNavigate()


    //function set up pocket money card account

    function handleAdminSetPocketMoneyCardAccount(){
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/setUpPocketMoneyAccount`,
                body:{
                    accountNumber:accountNumber,
                    accountType: "KCB",
                    businessNo:businessNumber,
                    accountPurpose:"pocketMoney"
                },
                token: localStorage.getItem('X-Authorization'),
            }).then((data)=>{
                if (data?.status === 200 || data?.status === 201  ) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    dispatch(handleResetDetail())
                    setTimeout(() => {
                        localStorage.removeItem('activeStep')
                        navigate(-1)
                    }, 1500)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
              
            })
        }catch(error){

        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2}>
                <Typography>ZPM Card Collection Account Details</Typography>
            </Grid>
            <Grid item  mt={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom:'10px' }}>Business Name</InputLabel>
                <TextField  style={{ width: "100%" }} disabled value={name}  id="outlined"  multiline required />
            </Grid>
            <Grid item  mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom:'10px' }}>Bank Name</InputLabel>
                <TextField  style={{ width: "100%" }} disabled value={'KCB'} placeholder='KCB'  id="outlined"  multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom:'10px' }}>Bank Account</InputLabel>
                <TextField  style={{ width: "100%" }} value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} id="outlined" placeholder="1245378" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={handleAdminSetPocketMoneyCardAccount} disabled={!accountNumber} style={ !accountNumber  ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}