import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { makeStyles } from "@mui/styles";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Expenditure() {

	const [active, setActive] = React.useState(false);
	const handleClick = () => { setActive(!active) };
	const classes = useStyles();
	const location = useLocation();
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"


	return (
		<div>
			<Link style={{ textDecoration: 'none', color: isActiveLink("/expenditure") ? activeColor : '#fff' }} to="/expenditure">
				<ListItem button className={classes.nested}>
					<div style={{ marginRight: '5%' }}>
						<CurrencyExchangeIcon />
					</div>
					<ListItemText primary="Expenditure" />
				</ListItem>
			</Link>
		</div>
	)
}
