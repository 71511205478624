import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {List, ListItemIcon} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import HttpComponent from "../../components/School/MakeRequest";


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

function SendIcon() {
	return null;
}

export default function Dashboard () {
	const classes = useStyles();

	const businessCat = localStorage.getItem("businessCategory")
	const [defaultDashboardSchool, setDefaultDashboardSchool] = useState()
	
	function getDefaultSchoolDashboard() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/getDefaultSchooldashboardstatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setDefaultDashboardSchool(data?.response?.data?.hasDefaultSchoolDashbaord)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getDefaultSchoolDashboard()
    }, [])
	//console.log("businessCat here", businessCat);


	return (
		<div>
			<Link style={{textDecoration: 'none', color : '#fff'}} to={((businessCat === "School" && defaultDashboardSchool === false ) || businessCat === "Rental") ? "/dashboard":(businessCat === "School" && defaultDashboardSchool === true ) ? '/dashboard?dash' : "/userDashboard"}>
				<ListItem button className={classes.nested} >
					<div style={{marginRight : '5%'}}>
						<DashboardIcon />
					</div>
					<div>
						<ListItemText primary="Dashboard" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}
