import { Delete, Download } from "@mui/icons-material";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, Checkbox, FormControl, InputLabel, Select, MenuItem, Box, Typography, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sum } from "lodash";
import startOrderFunction from "./posmethods";
import { currencyconverter } from "../../../common/currencyconverter";
import { ErrorAlertRightAligned, PrinterPopUp, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
// import { CustomerOnAccountsModal, ErrorAlertRightAligned, PrinterPopUp, SuccessAlertRightAligned } from "../..";

const StartOrderOverview = ({ switchCustomer, setSwitchCustomer }) => {
  const navigate = useNavigate();
  const startOrderFunctions = new startOrderFunction();

  // start order items!
  const [startOrderItems, setStartOrderItems] = useState([]);

  useEffect(() => {
    // setSwitchCustomer(JSON.parse(localStorage.getItem("customerOnAccountState")) )
  }, [startOrderItems]);
  const [parentId, setParentId] = useState("");

  const getStartOrderItems = () => {
    return JSON.parse(JSON.stringify(localStorage.getItem("startOrderItems")));
  };

  const { tabValue } = useParams();
  const queryData = decodeURIComponent(window.location.search);

  useEffect(() => {
    if (tabValue === "startorder" && (switchCustomer === false || !switchCustomer)) {
      const queryData = decodeURIComponent(window.location.search).slice(1);
      const [Id] = queryData.split("?");
      setParentId(Id);
    } else if (tabValue === "startorder" && switchCustomer === true) {
      const queryData = decodeURIComponent(window.location.search).slice(1);

      const [customerAccountName, customerType, paymentType, availableBalance, phoneNumber, customerId] = queryData.split("?");
      // console.log("startOrderData", customerAccountName, customerType, paymentType, availableBalance, phoneNumber, customerId);
      setCustomerOnAccountsName(customerAccountName);
      setCustomerOnAccountsPaymentType(paymentType);
      setCustomerAccountsId(customerId);
      setCustomerAccountsType(customerType);
      setAccountCustomerBalance(availableBalance);
      setCustomerPhone(phoneNumber);
      setParentId("");
    }
  }, [queryData, tabValue, switchCustomer, startOrderItems]);

  // discunts

  const [productDiscountAmount, setProductDiscountAmount] = useState({})
  const [calculatedDiscount, setCalculatedDiscount] = useState({})
  const [newSelectedDiscountType, setNewSelectedDiscountType] = useState({})

  const handleCalculateProductDiscount = (selectedDiscountType, product, discountAmount) => {
    let newProductArray = [...startOrderItems];
    let initialDiscount = 0;
    let discountPercent = 0;
    if (selectedDiscountType === 'Percentage') {
      initialDiscount = (product?.itemAmount * product?.itemCount) * (discountAmount / 100);
      discountPercent = discountAmount;
    } else if (selectedDiscountType === 'Amount') {
      discountPercent = 0
      initialDiscount = discountAmount;
    }
    console.log('product ====== <><><>', product);
    product.discount = (initialDiscount * 1) || 0
    setCalculatedDiscount(prevState => ({ ...prevState, [product._id]: initialDiscount }));
    const updatedProduct = {
      ...product,
      discount: initialDiscount,
      discountPercent: discountPercent,
      discountType: selectedDiscountType,
      
    };
  
    // Update the items array by replacing the updated product
    const updatedItems = newProductArray?.map(item => 
      item.checkedProductId === product._id ? updatedProduct : item
    );
  
    setStartOrderItems(updatedItems);

  };
  const handleDiscountChange = (e, product) => {
    console.log('product ====== <><><>', product, e.target.value);
    const discountAmount = Number(e.target.value)
    setProductDiscountAmount(prevState => ({ ...prevState, [product._id]: discountAmount }));
    handleCalculateProductDiscount(newSelectedDiscountType[product._id], product, discountAmount);
  };

  const handleDiscountTypeChange = (e, product) => {
    const selectedDiscountType = e.target.value;
    setNewSelectedDiscountType(prevState => ({ ...prevState, [product._id]: selectedDiscountType }));
    handleCalculateProductDiscount(selectedDiscountType, product, productDiscountAmount[product._id]);
  };



  // product totals
  const productTotals = sum(startOrderItems?.map((items) => items.totalAmount));

  //  discount!
  const [toggleDiscount, setToggleDiscount] = useState(false);
  const [discountTypes, setDiscountTypes] = useState(["Amount", "Percentage"]);
  const [discountType, setDiscountType] = useState("");
  const [productDiscount, setProductDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  useEffect(() => {
    if (discountType === "Amount") {
      let newDiscount = Number(discountAmount);
      if (newDiscount > productTotals) {
        setIsError({ ...isError, state: true, message: `Discount cant be more than totals!` });
      } else {
        setProductDiscount(newDiscount);
      }
    }

    if (discountType === "Percentage") {
      const newDiscount = (Number(discountAmount) / 100) * productTotals;
      if (newDiscount > productTotals) {
        setIsError({ ...isError, state: true, message: `Discount cant be more than totals!` });
      } else {
        setProductDiscount(newDiscount);
      }
    }

    if (toggleDiscount === false) {
      setProductDiscount(0);
      setDiscountType("");
    }
  }, [discountType, toggleDiscount, discountAmount, productTotals, productDiscount]);

  // console.log("productDiscount", productDiscount);

  // add event listener to get itmes from local storage!
  window.addEventListener("startOrderItemsUpdated", () => {
    let existingItems = getStartOrderItems();
    if (existingItems) {
      setStartOrderItems(JSON.parse(existingItems));
    }
    // Do something with updatedItems
  });

  useEffect(() => {
    let existingItems = getStartOrderItems();
    if (existingItems) {
      setStartOrderItems(JSON.parse(existingItems));
    }
  }, []);

  const updateVariableItems = (e) => {
    let varibaleItemsTotal = [...startOrderItems];

    varibaleItemsTotal?.map((item, index) => {
      if (e.target.name) {
        if (parseInt(e.target.name?.slice(1)) === index) {
          if (e.target.name[0] === "q") {
            item.totalAmount = Math.ceil(item.itemAmount * Number(e.target.value));
            item.itemCount = Number(e.target.value);
          }

          if (e.target.name[0] === "t") {
            item.itemCount = Number(e.target.value) / item.itemAmount;
            item.totalAmount = Math.ceil(Number(e.target.value));
          }
        }
      }
    });
    setStartOrderItems(varibaleItemsTotal);
    localStorage.setItem("startOrderItems", JSON.stringify(varibaleItemsTotal));
    const event = new Event("startOrderItemsUpdated");
    window.dispatchEvent(event);
  };

  const updateInputTotals = (e) => {
    // console.log("e.target.value", e.target.value)
    let updateTotalsArray = [...startOrderItems];
    updateTotalsArray?.map((prod, index) => {
      // console.log("product ===<>", prod)
      if (parseInt(e.target.name) === index) {
        prod.itemCount = e.target.value * 1;
        prod.totalAmount = e.target.value * prod.itemAmount;
      }
    });

    updateTotalsArray.filter((prod, index) => {
      if (parseInt(e.target.name) === index) {
        // if (prod.itemCount > prod.quantityInStock) {
        //   //   setNoItems(true);
        //   prod.itemCount = prod.quantityInStock - 1;
        // }
      }
    });

    setStartOrderItems(updateTotalsArray);
    localStorage.setItem("startOrderItems", JSON.stringify(updateTotalsArray));
    const event = new Event("startOrderItemsUpdated");
    window.dispatchEvent(event);
    // updateTotalsArray.map((prod, index) => ({...prod, ...(parseInt(e.target.name) === index ? {itemCount: value, totalAmount: prod.itemAmount * value} :{} )}))
  };

  const updateVariablePriceTotals = (e) => {
    let updateVariableTotals = [...startOrderItems];
    updateVariableTotals?.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
        prod.itemAmount = e.target.value;
        prod.totalAmount = e.target.value * prod.itemCount;
      }
    });

    setStartOrderItems(updateVariableTotals);
    localStorage.setItem("startOrderItems", JSON.stringify(updateVariableTotals));
    const event = new Event("startOrderItemsUpdated");
    window.dispatchEvent(event);
  };

  // remove product from pos array;
  const removeproductFromPosArray = (newProduct) => {
    const newPosRemove = [...startOrderItems];
    const currentIndex = newPosRemove?.findIndex((product) => product.checkedProductId === newProduct.checkedProductId);
    if (currentIndex >= 0) {
      newPosRemove.splice(currentIndex, 1);
    }
    setStartOrderItems(newPosRemove);
    localStorage.setItem("startOrderItems", JSON.stringify(newPosRemove));
    const event = new Event("startOrderItemsUpdated");
    window.dispatchEvent(event);

    const removeEvent = new Event("removeItems");
    window.dispatchEvent(removeEvent);
  };

  // handle customer on accounts!
  const [customerOnAccountsName, setCustomerOnAccountsName] = useState("");
  const [customerOnAccountsPaymentType, setCustomerOnAccountsPaymentType] = useState("");
  const [customerAccountsId, setCustomerAccountsId] = useState("");
  const [customerAccountsType, setCustomerAccountsType] = useState("");
  const [customerAcccountBalance, setAccountCustomerBalance] = useState(0);
  const [phoneNumber, setCustomerPhone] = useState("");

  // set data to save!;
  const [customerBalance, setCustomerBalance] = useState(0);
  const [customerId, setCustomerId] = useState("N/A");
  const [customerName, setCustomerName] = useState("");
  const [customerPaymentType, setCustomerPaymentType] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [items, setItems] = useState([]);
  const [orderTable, setOrderTable] = useState("N/A");
  const [orderType, setOrderType] = useState("N/A");
  const [parentOrderId, setParentOrderId] = useState("");
  const [discAmount, setDisc] = useState(0);
  const [transamount, setTransAmount] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState("N/A");

  useEffect(() => {
    setCustomerBalance(0);
    setCustomerId(customerAccountsId ? customerAccountsId : "N/A");
    setCustomerName(customerOnAccountsName ? customerOnAccountsName : "N/A");
    setCustomerPaymentType(customerOnAccountsPaymentType ? customerOnAccountsPaymentType : "N/A");
    setCustomerType(customerAccountsType ? customerAccountsType : "N/A");
    setItems(startOrderItems);
    setParentOrderId("");
    setTransAmount(Number(productTotals));
    setDisc(Number(productDiscount));
  }, [startOrderItems, customerOnAccountsName, customerOnAccountsPaymentType, customerAccountsType, parentId, discountAmount, productDiscount, productTotals, customerAccountsId]);
  // SAVE ORDER TO PAY LATER;
  const saveBillToPayLater = () => {
    if (startOrderItems?.length < 1) {
      setIsError({ state: true, message: `Add items to proceed!` });
      setStartOrderItems([]);
      localStorage.removeItem("startOrderItems");
      return;
    }

    const data = { customerBalance, customerId, customerName, customerPaymentType, customerType, items, orderTable, orderType, parentOrderId, discountAmount: productDiscount, transamount, paymentMethod };

    // if ((businessCategory === "Eatery, Bar and Restaurant" || businessCategory === "Hotel") && !switchCustomer) {
    //   navigate(`/orders/startordersavebill?${productTotals * 1 - productDiscount * 1}?${JSON.stringify(startOrderItems)}?${false}?${productDiscount}`);
    //   setStartOrderItems([]);
    //   const removeEvent = new Event("removeItems");
    //   window.dispatchEvent(removeEvent);
    // }
    //  else {
      startOrderFunctions.saveOrder(data).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setSuccess({ state: true, message: "Order saved successfully!" });
          const removeEvent = new Event("removeItems");
          window.dispatchEvent(removeEvent);
          setStartOrderItems([])
          navigate(`/orders/startorder`);
          // window.location.reload()
        }else{
          setIsError({state : true , message: data?.response?.message})
        }
      });
    // }
  };

  //  SAVE ORDER AND PAY
  const [saveOrderId, setSaveOrderId] = useState("");
  const businessCategory = localStorage.getItem("businessCategory");
  const processPayment = () => {
    if(startOrderItems?.length > 0) {
      const data = { customerBalance, customerId, customerName, customerPaymentType, customerType, items, orderTable, orderType, parentOrderId, discountAmount: productDiscount, transamount, paymentMethod };
        //"discountName": "",
        //"discountPercent": 0,
       // "customerId": "666c3d7f3e9601c4efc2108e",
        //"discountAmount": 0,
       // "discountType": "",

      // if ((businessCategory === "Eatery, Bar and Restaurant" || businessCategory === "Hotel") && !switchCustomer) {
      //   navigate(`/orders/startordersavebill?${productTotals * 1 - productDiscount * 1}?${JSON.stringify(startOrderItems)}?${false}?${productDiscount}`);
      //   setStartOrderItems([]);
      // }
      // if (switchCustomer) {
      //   startOrderFunctions.saveOrder(data).then((data) => {
      //     if (data?.status === 200 || data?.status === 201) {
      //       let saveOrderId = data?.response?.data?._id;
      //       completeTransaction(saveOrderId);
      //     } else if (data?.status === 400) {
      //       setIsError({ state: true, message: data.response.message });
      //     }
      //   });
      // } 
      // else {
        startOrderFunctions.saveOrder(data).then((data) => {
          if (data?.status === 200 || data?.status === 201) {
            let saveOrderId = data?.response?.data?._id;
            setSaveOrderId(saveOrderId);
            navigate(`/pos/requestPayment?startOrderTag?${saveOrderId}?${productTotals * 1 - productDiscount * 1}?${JSON.stringify(startOrderItems)}?${false}?${productDiscount}`);
            localStorage.removeItem("startOrderItems");
            setStartOrderItems([]);
          } else if (data?.status === 400) {
            setIsError({ state: true, message: data.response.message });
          }
        });
      // }
    }

  };

  const completeTransaction = (saveOrderId) => {
    const customerName = customerOnAccountsName ? customerOnAccountsName : "N/A";
    const newTotals = Number(productTotals - productDiscount);
    const payData = { items: startOrderItems, customerFirstName: customerName, discountAmount: productDiscount, startOrderIds: [saveOrderId], transamount: newTotals - productDiscount, transactionType: "Customer On Account" };
    startOrderFunctions.processStartOrderPayment(payData).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccess({ state: true, message: "Payment processed successfully!" });
        setSwitchCustomer(!switchCustomer);

        localStorage.removeItem("startOrderItems");
        setStartOrderItems([]);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (data?.status === 400) {
        setIsError({ state: true, message: data.response.message });
      }
    });
  };

  // handle error
  const [isError, setIsError] = useState({ state: false, message: "" });
  useEffect(() => {
    if (isError.state) {
      setTimeout(() => {
        setIsError({ state: false, message: "" });
      }, 2000);
    }
  }, [isError.state]);

  // handle success!;
  const [isSucess, setSuccess] = useState({ state: false, message: "" });

  useEffect(() => {
    if (isSucess.state) {
      setTimeout(() => {
        setSuccess({ state: false, message: "" });
      }, 2000);
    }
  }, [isSucess.state]);

  // handle Printing!

  const [printerPopUp, setPrinterPopUp] = useState(false);
  const [showPrinter, setShowPrinter] = useState(false);
  const [pos, setPos] = useState([]);
  const [posSerialNo, setPosSerialNo] = useState("");
  // setErrorShow
  // doRedirect ="noredirect"

  const getBusinessPos = () => {
    startOrderFunctions.getPos().then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPos(data.response.data);
      }
    });
  };
  useEffect(() => {
    getBusinessPos();
  }, [startOrderItems]);

  const handlePrinting = () => {
    if (pos?.length === 0) {
      setIsError({ state: true, message: "No printer detected!" });
    } else if (pos?.length === 1) {
      // save bill And Print;
      const data = { customerBalance, customerId, customerName, customerPaymentType, customerType, items, orderTable, orderType, parentOrderId, discountAmount: productDiscount, transamount, paymentMethod };
      const serialNo = pos[0]?.terminalSerialNumber;
      setPosSerialNo(serialNo);

      new Promise((resolve, reject) => {
        startOrderFunctions.saveOrder(data).then((data) => {
          if (data?.status === 200 || data?.status === 201) {
            setStartOrderItems([]);
            localStorage.removeItem("startOrderItems");
            const removeEvent = new Event("removeItems");
            window.dispatchEvent(removeEvent);
            let saveOrderId = data?.response?.data?._id;
            resolve({ saveOrderId, serialNo });
            reject(data.response.message);
            
          } else if (data?.status === 400) {
            setIsError({ state: true, message: data.response.message });
          }
        });
      })
        .then((data) => {
          startOrderFunctions.sendToPrinter(data.saveOrderId, data.serialNo).then((data) => {
            if (data?.status === 409 || data?.status === 200) {
              setSuccess({ state: true, message: "Bill Printed Sucessfully!" });

              setTimeout(() => {
                navigate(`/orders/startorder`);
              }, 2000);
             
            }
          });
        })
        .catch((e) => {
          setIsError({ state: true, message: e.message });
        });
    } else {
      setPrinterPopUp(true);
      const removeEvent = new Event("removeItems");
      window.dispatchEvent(removeEvent);
    }
  };

  const sendToPrinter = (serialNo) => {
    const data = { customerBalance, customerId, customerName, customerPaymentType, customerType, items, orderTable, orderType, parentOrderId, discountAmount: productDiscount, transamount, paymentMethod };
    new Promise((resolve, reject) => {
      startOrderFunctions.saveOrder(data).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setStartOrderItems([]);
          localStorage.removeItem("startOrderItems");
          let saveOrderId = data?.response?.data?._id;
          resolve({ saveOrderId, serialNo });
          reject(data.response.message);
        } else if (data?.status === 400) {
          setIsError({ state: true, message: data.response.message });
        }
      });
    }).then((data) => {
      startOrderFunctions.sendToPrinter(data.saveOrderId, data.serialNo).then((data) => {
        if (data?.status === 409 || data?.status === 200) {
          setSuccess({ state: true, message: "Bill Printed Sucessfully!" });
          setPrinterPopUp(false);
        } else if (data?.status === 400) {
          setIsError({ state: true, message: data.response.message });
        }
      });
    });
  };

  const [showRequestPayment, setShowRequestPayment] = useState(true);
  console.log(items , 'startOrderItems 🚀')


  useEffect(() => {
    const newData = queryData?.split("?");

    if (newData && newData?.length > 1) {
      setShowRequestPayment(false);
    }
  }, [queryData, startOrderItems]);

  console.log(startOrderItems , 'now hereee')

  // calculate total discounts

  const calculateTotalDiscountStartOrder = (items) => {
    return items.reduce((totalDiscount, item) => {
      let itemDiscount = 0;
  
      if (item.discountType === 'Percentage') {
        itemDiscount = (item.itemAmount* item.itemCount) * (item.discountPercent / 100);
      } else if (item.discountType === 'Amount') {
        itemDiscount = item.discount;
      }
  
      return totalDiscount + itemDiscount;
    }, 0);
  };

  const  totalDiscountFound = calculateTotalDiscountStartOrder(startOrderItems);
  
  



  // PrinterPopUp = ({printerPopUp, setPrinterPopUp, showPrinter, setShowPrinter, pos, printers , sendToPrinter, setPosSerialNo, posSerialNo, doRedirect

  const cancelOrder = () => {
    setStartOrderItems([]);
    navigate(`/orders/startorder`);
    const removeEvent = new Event("removeItems");
    window.dispatchEvent(removeEvent);
  }
  return (
    <Box component={Paper} padding={2} style={{ fontFamily: "Poppins",  }}>
      {isError?.state && <ErrorAlertRightAligned error={isError.state} message={isError.message} />}
      { isSucess.state &&   <SuccessAlertRightAligned    message={isSucess.message} sucess={isSucess.state} /> }  
      {printerPopUp && <PrinterPopUp showPrinter={showPrinter} setPrinterPopUp={setPrinterPopUp} setPosSerialNo={setPosSerialNo} printers={[]} pos={pos ? pos : []} setShowPrinter={setShowPrinter} printerPopUp={printerPopUp} sendToPrinter={sendToPrinter} doRedirect={"fromSaveBill"} posSerialNo={posSerialNo} /> } 
      {/* <CustomerOnAccountsModal  setCustomerOnAccountsName={setCustomerOnAccountsName} switchCustomer={switchCustomer} setCustomerOnAccountsPaymentType={setCustomerOnAccountsPaymentType} setCustomerAccountsId={setCustomerAccountsId} setCustomerAccountsType={setCustomerAccountsType} /> */}

      <Box mb={3}>
        <TableContainer style={startOrderItems?.length > 0 ? { flexDirection: "column-reverse", height: "40vh", overflow: "auto", display: "flex" } : { flexDirection: "column", height: "40vh", overflow: "auto", display: "flex" }}>
          <Table stickyHeader sx={{ width: "100%" }}>
            <TableHead style={{ fontWeight: "bold", fontSize: "10px" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Product Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Item Amount</TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Quantity
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Discount
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {startOrderItems?.map((posItem, index) => {
                const { itemName, checkedProductId, itemAmount, itemCount, totalAmount, priceStatus, isWeightedProduct, discount } = posItem;
                // console.log("posItem", posItem);
                let prodDiscount = discount || 0
                return (
                  <TableRow>
                    <TableCell>{itemName}</TableCell>
                    {priceStatus === "Variable Price Item" && isWeightedProduct === false ? (
                      <TableCell align="center">
                        {" "}
                        <TextField InputProps={{ inputProps: { min: 1 } }} onChange={updateVariablePriceTotals} value={itemAmount} id={checkedProductId} name={index} type="number" size="small" sx={{ width: "100%" }} />{" "}
                      </TableCell>
                    ) : priceStatus === "Variable Price Item" && isWeightedProduct === true ? (
                      <TableCell align="center">
                        <TextField disabled onChange={updateVariableItems} value={itemAmount} id={checkedProductId} name={index} type="number" size="small" sx={{ width: "100%" }} />{" "}
                      </TableCell>
                    ) : (
                      <TableCell>
                        {" "}
                        <TextField size="small" value={itemAmount} disabled />
                      </TableCell>
                    )}
                    {priceStatus === "Variable Price Item" && isWeightedProduct === false ? (
                      <TableCell align="center">
                        <TextField value={itemCount} InputProps={{ inputProps: { min: 1 } }} onChange={updateInputTotals} id={checkedProductId} name={index} size="small" type="number" sx={{ width: "100%" }} />
                      </TableCell>
                    ) : priceStatus === "Variable Price Item" && isWeightedProduct === true ? (
                      <TableCell align="center">
                        <TextField value={itemCount} InputProps={{ inputProps: { min: 1 } }} onChange={updateVariableItems} id={checkedProductId} name={`q${index}`} size="small" type="number" sx={{ width: "100%" }} />
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <TextField type="number" InputProps={{ inputProps: { min: 1 } }} onChange={updateInputTotals} size="small" value={itemCount} name={index} sx={{ width: "100%" }} />
                      </TableCell>
                    )}
                    <TableCell>
                      <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField id="outlined-basic" sx={{ width: 100 }}
                          value={productDiscountAmount[posItem._id] || 0}
                          onChange={(e) => handleDiscountChange(e, posItem)}
                          variant="outlined" />
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                          <Select
                            value={newSelectedDiscountType[posItem._id] || ''}
                            onChange={(e) => handleDiscountTypeChange(e, posItem)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            {discountTypes.map((option) => {
                              return (
                                <MenuItem value={option} >{option}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        <span style={{ color: '#17ae7b', fontWeight: 600 }}> {currencyconverter(calculatedDiscount[posItem._id] || 0)}</span>
                      </Box>
                    </TableCell>

                    {priceStatus === "Variable Price Item" && isWeightedProduct === false ? (
                      <TableCell>
                        {" "}
                        <TextField sx={{ width: "100%" }} size="small" value={itemAmount * itemCount - prodDiscount} />{" "}
                      </TableCell>
                    ) : priceStatus === "Variable Price Item" && isWeightedProduct === true ? (
                      <TableCell>
                        <TextField size="small" value={itemAmount * itemCount - prodDiscount} InputProps={{ inputProps: { min: 1 } }} onChange={updateVariableItems} id={checkedProductId} name={`t${index}`} sx={{ width: "100%" }} type="number" />
                      </TableCell>
                    ) : (
                      <TableCell>
                        <TextField sx={{ width: "100%" }} size="small" value={itemAmount * itemCount - prodDiscount} disabled />
                      </TableCell>
                    )}
                    <TableCell>
                      {" "}
                      <Delete
                        onClick={() => {
                          removeproductFromPosArray(posItem);
                          // dispatch(deleteItemFromArray(posItem));
                        }}
                        style={{ color: "#dc3545", cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {startOrderItems?.length > 0 && (
        <>
          <Box mb={2} item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <span>
              <Checkbox onClick={() => setToggleDiscount(!toggleDiscount)} />
              Discount
            </span>
            {toggleDiscount && (
              <>
                <FormControl sx={{ width: "30%" }} size="small">
                  <InputLabel id="demo-simple-select-label">Discount Types</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={discountType} label="Discount Types" onChange={(e) => setDiscountType(e.target.value)}>
                    {discountTypes?.map((discount, index) => {
                      return (
                        <MenuItem key={index} value={discount}>
                          {discount}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField onChange={(e) => setDiscountAmount(e.target.value)} type="number" size="small" />
              </>
            )}
          </Box>

          <Box mb={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", border: "", padding: "30px", gap: "30px" }}>
            <Box sx={{ backgroundColor: "#fafafa", width: "50%", padding: "20px", color: "#032541", fontSize: "14px" }}>
              <Box sx={{ margin: "0 0 5px 0", display: "flex", justifyContent: "space-between" }}>
                <Typography>Subtotal</Typography>
                <Typography>{currencyconverter(productTotals)}</Typography>
              </Box>

              <Box sx={{ margin: "0 0 5px 0", display: "flex", justifyContent: "space-between" }}>
                <Typography>Items</Typography>
                <Typography>{startOrderItems?.length}</Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Discount</Typography>
                {/* <Typography>{currencyconverter(productDiscount)}</Typography> */}
                <Typography>{currencyconverter(calculateTotalDiscountStartOrder(startOrderItems))}</Typography>
              </Box>
            </Box>

            <Box sx={{ backgroundColor: "#f0fcf7", border: "", width: "50%", fontSize: "40px", padding: "20px", color: "#17ae7b" }}>
              <Typography sx={{ fontSize: "18px" }}>Total KES</Typography>
              <Typography sx={{ fontSize: "40px" }}>{currencyconverter(productTotals - (totalDiscountFound ?? 0)) }</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: "30px", alignItems: "center", justifyContent: "space-between" }}>
            <Button onClick={ () =>  cancelOrder()} style={{ border: "1px solid #dc3545", color: "#dc3545" }}>Cancel Order</Button>
            <Button onClick={() => saveBillToPayLater()} style={{ color: "#032541", border: "1px solid #032541" }}>Print Later</Button>
            <Button style={{ backgroundColor: "#032541", color: "white" }} onClick={() => handlePrinting()} >Print Bill</Button> 
            <Button onClick={() => processPayment()} style={{ backgroundColor: "#17ae7b", color: "white" }}>Pay Now</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

{
  /* <Grid container direction={"column"} backgroundColor={"rgba(178, 180, 187, 0.1)"} padding={"20px"}>
        <Grid item margin={"0 0 1% 0"}>
          <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item>
              <span style={{ color: "#032541" }}>Order Overview</span>
            </Grid>

            <Grid item>
              <div onClick={() => saveBillToPayLater()} style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer", color: "#032541" }}>
                <span>Save</span>
                <Download />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item margin={"0 0 1% 0"}>

        </Grid>

        <Grid item width={"100%"} sx={{ backgroundColor: "#f7f7f7" }}>
          <Grid container width={"100%"}>
            <Grid margin={"0 0 1% 0"} item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <span style={{ color: "#032541" }}>Subtotal</span>
              <span style={{ color: "#17ae7b" }}>{currencyconverter(productTotals)}</span>
            </Grid>

            <Grid margin={"0 0 1% 0"} item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <span style={{ color: "#032541" }}>Number of Items</span>
              <span>{startOrderItems?.length}</span>
            </Grid>

            <Grid margin={"0 0 1% 0"} item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <span>
                <Checkbox onClick={() => setToggleDiscount(!toggleDiscount)} />
                Discount
              </span>
              {toggleDiscount && (
                <>
                  <FormControl sx={{ width: "30%" }} size="small">
                    <InputLabel id="demo-simple-select-label">Discount Types</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={discountType} label="Discount Types" onChange={(e) => setDiscountType(e.target.value)}>
                      {discountTypes?.map((discount, index) => {
                        return (
                          <MenuItem key={index} value={discount}>
                            {discount}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <TextField onChange={(e) => setDiscountAmount(e.target.value)} type="number" size="small" />
                </>
              )}
            </Grid>

            <Grid margin={"0 0 1% 0"} item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <span style={{ color: "#032541" }}> Discount Amount</span>
              <span style={{ color: "#032541" }}>{currencyconverter(productDiscount)}</span>
            </Grid>

            <Grid margin={"0 0 1% 0"} item width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <span style={{ color: "#032541" }}> Total Amount</span>
              <span style={{ color: "#032541", fontWeight: "bold" }}>{currencyconverter(productTotals - productDiscount)}</span>
            </Grid>
          </Grid>
        </Grid>

        <Grid item display={"flex"} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
          <Button size="large" style={startOrderItems?.length > 0 ? { backgroundColor: "black", color: "white" } : { backgroundColor: "black", color: "white", opacity: 0.3 }} onClick={() => (startOrderItems?.length > 0 ? clearStartOrder() : null)}>
            Clear
          </Button>

          {
            switchCustomer  && <Button onClick={() => ((startOrderItems?.length > 0 && switchCustomer) ? navigate(`/orders/previewInvoice?${customerId}`) : null)} size="large" style={startOrderItems?.length > 0 ? { backgroundColor: "black", color: "white" } : { backgroundColor: "black", color: "white", opacity: 0.3 }}>
            Preview Invoice
          </Button>
          }

          {
            showRequestPayment &&
           <Button size="large" style={startOrderItems?.length > 0 ? { backgroundColor: "black", color: "white" } : { backgroundColor: "black", color: "white", opacity: 0.3 }} onClick={() => (startOrderItems?.length > 0 ? processPayment() : null)}>
            Request Payment
          </Button>
          }
   
          <Button onClick={ () => startOrderItems?.length > 0 ? handlePrinting(): null} size="large" style={startOrderItems?.length > 0 ? { backgroundColor: "black", color: "white" } : { backgroundColor: "black", color: "white", opacity: 0.3 }}>
            Print Bill
          </Button>
          <Button onClick={() => saveBillToPayLater()} size="large" style={startOrderItems?.length > 0 ? { backgroundColor: "black", color: "white" } : { backgroundColor: "black", color: "white", opacity: 0.3 }}>
            Print Later
          </Button>
        </Grid>
      </Grid> */
}

export default StartOrderOverview;
