import React from "react";
import { Modal, Button } from "@mui/material";
import { warehousestyles } from "./styles";
import powericon from "../../common/images/warning.svg";
import restore from "../../common/images/restore-icn.svg";

export const Suspendsuppliermodal = (props) => {
  const openSuspendModal = props.openSuspendModal;
  const setSuspendModal = props.setSuspendModal;
  const supplierToSuspendName = props.supplierToSuspendName;
  const suspendSupplier = props.suspendSupplier;

  // suspendwarehouse
  const suspendWareHouseName = props.suspendWareHouseName;
  const suspendWareHouse = props.suspendWareHouse;
  const warehousesToBeSuspended = props.warehousesToBeSuspended;

  // suspend substores
  const storesToBeSuspended = props.storesToBeSuspended;
  const storeNameToSuspend = props.storeNameToSuspend;
  const suspendSubstore = props.suspendSubstore;
  return (
    <Modal open={openSuspendModal} style={warehousestyles.universalFont}>
      <div style={warehousestyles.deactivateSupplierModal}>
        <div style={warehousestyles.supplierIconHolder}>
          <div style={warehousestyles.divwithImg}>
            <img style={warehousestyles.wareHouseImg} src={powericon} alt="warehouse" />
          </div>

          <div>
            <p style={warehousestyles.suppliersText}> {suspendWareHouseName ? "Suspend Warehouse?" : storeNameToSuspend ? "Suspend sub store" : "Suspend supplier?"} </p>
            <p style={warehousestyles.suppliersPara}>Selected {suspendWareHouseName ? "warehouse" : storeNameToSuspend ? "sub store" : "supplier"} will be suspended and no longer listed when managing stores</p>

            {supplierToSuspendName || suspendWareHouseName || storeNameToSuspend ? (
              <p style={warehousestyles.suppliersPara}> {suspendWareHouseName ? suspendWareHouseName : storeNameToSuspend ? storeNameToSuspend : supplierToSuspendName} </p>
            ) : (
              []?.map((suplier) => {
                return <p style={warehousestyles.suppliersPara}>{suplier}</p>;
              })
            )}
            {}

            <p style={warehousestyles.paraWithDisplayFlex}>
              <Button onClick={() => setSuspendModal(false)} style={warehousestyles.cancelButton}>
                Cancel
              </Button>

              <Button style={warehousestyles.deactivateButton} onClick={() => (suspendWareHouseName || warehousesToBeSuspended ? suspendWareHouse() : storesToBeSuspended || storeNameToSuspend ? suspendSubstore() : suspendSupplier())}>
                Suspend
              </Button>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const Reactivatesuppliermodal = (props) => {
  const reactivateSupplier = props.reactivateSupplier;
  const setReactivateSupplier = props.setReactivateSupplier;
  const reactivateName = props.reactivateName;
  const reactivateId = props.reactivateId;
  const reactivateCustomer = props.reactivateCustomer;
  const suppliersToBeActivated = props.suppliersToBeActivated;

  // reactivate warehouse;
  const reactivateWarehouseName = props.reactivateWarehouseName;
  const reactivateWarehouse = props.reactivateWarehouse;
  const reactivateSubstores = props.reactivateSubstores;
  const reactivateSubstoreName = props.reactivateSubstoreName;
  const substoresToBeReactivated = props.substoresToBeReactivated;

  return (
    <Modal open={reactivateSupplier} style={warehousestyles.universalFont}>
      <div style={warehousestyles.deactivateSupplierModal}>
        <div style={warehousestyles.supplierIconHolder}>
          <div style={warehousestyles.divwithImg}>
            <img style={warehousestyles.wareHouseImg} src={restore} alt="warehouse" />
          </div>

          <div>
            <p style={warehousestyles.suppliersText}>Reactivate {reactivateWarehouseName ? "warehouse?" : reactivateSubstoreName ? "Substore" : "supplier?"} </p>
            <p style={warehousestyles.suppliersPara}>Selected {reactivateWarehouseName ? "warehouse?" : reactivateSubstoreName ? "substore" : "supplier?"} will be reactivated and listed when managing stores</p>

            {reactivateName || reactivateWarehouseName || reactivateSubstoreName ? (
              <p style={warehousestyles.suppliersPara}> {reactivateWarehouseName ? reactivateWarehouseName : reactivateSubstoreName ? reactivateSubstoreName : reactivateName}</p>
            ) : (
              []?.map((suplier) => {
                return <p style={warehousestyles.suppliersPara}>{suplier}</p>;
              })
            )}
            {}

            <p style={warehousestyles.paraWithDisplayFlex}>
              <Button onClick={() => setReactivateSupplier(false)} style={warehousestyles.cancelButton}>
                Cancel
              </Button>

              <Button style={warehousestyles.activateButton} onClick={() => (reactivateName ? reactivateCustomer() : suppliersToBeActivated ? reactivateCustomer() : reactivateSubstoreName ? reactivateSubstores() : substoresToBeReactivated ? reactivateSubstores() : reactivateWarehouse())}>
                Activate
              </Button>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
