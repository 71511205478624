import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { currencyconverter } from "../../../../common/currencyconverter";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import ExportMenu from "../../../School/ExportMenu";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import HttpComponent from "../../../School/MakeRequest";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../../../utils/dateFormatter";
import { CustomizedDate } from "../../../customerAccounts/customerComponents/customDate";
import CashIcon from '../../../../common/images/coin.svg'
import CardIcon from '../../../../common/images/cardr.svg'
import MobileIcon from '../../../../common/images/fingerprint-icn.svg'
import SelforderIcon from '../../../../common/images/MobileMoney.svg'


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Zed Payit Report</Typography>,
]

export default function CashierDrillDownPayit() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("")
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, total: 0, noOfOrders: 0 , stats:[] });
    const [orderNumber, setOrderNumber] = useState('');
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("This Month")
    const [transactionType , setTransactionType] = useState('')
    const transactionTypes = ['card' ,'bio' ,'SELFORDER','CASH PAYIT']
    const navigate = useNavigate()

    const queryParams = decodeURIComponent(window.location.search);
    let businessName = localStorage.getItem('businessName')

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, userId, Name] = newqueryParamValue.split("?")

    function fetchPayitOrderBycashierbyId(){
        setPageState({ ...pageState, isLoading: true })
        try{
            HttpComponent({
                method:'GET',
                url:`/api/v1/groupedorderbycashierId?userId=${userId}&page=${pageState.page}&limit=${dataGridPageSize}&startDate=${startDate}&endDate=${endDate}&paymentMethod=${transactionType}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data)=>{
                if(data?.status === 200){
                    console.log(data?.response , 'bitches')
                    setPageState((prev) => ({ ...prev, isLoading: false, data: data?.response?.data?.filter((data)=> data !== null) , count:data?.response?.count , total:data?.response?.cashierTotal , stats:data?.response?.stats }))
                }
            })
        }catch(error){
            console.log(error)
        }finally{
            setPageState((prev) => ({ ...prev, isLoading: false }))
        }
    }
    useEffect(() =>{
        fetchPayitOrderBycashierbyId()
    },[pageState.page, dataGridPageSize , userId , startDate , endDate , transactionType])


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                break;
        }
    }, [handleDaysChange , dateFrom , dateTo]);

    const configData = pageState?.data?.map((config) => ({
        id: config?._id ? config?._id: config?.orderNumber,
        orderNumber: config?.orderNumber,
        Amount: config?.transamount,
        paymentMethod: config?.paymentMethod,
        CreatedAt:DateFormatter(config?.createdAt),
    }))

    const payitOrders = [
        { field: 'orderNumber', headerName: 'Order No', flex: 1 ,renderCell: (params) => <Typography onClick={()=>navigate(`/school/zedpayitreports?OrderDetails?${params?.row?.id}`)} style={{ fontFamily: "Poppins", fontSize: "14px", textDecoration:'underline',cursor:'pointer', color: '#000', fontWeight: 500 }}>{params?.value}</Typography> },
        { field: 'paymentMethod', headerName: 'Payment Method', flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
        { field: 'CreatedAt', headerName: 'Transaction Time', flex: 1 },

    ]

    const filedata = pageState?.data?.map((data) => {
        return {
            "orderNumber": data?.orderNumber,
            "Payment Method": data?.paymentMethod,
            "Amount": data?.transamount,
            "Transaction Time": DateFormatter(data?.createdAt),
        }
    })
    const fileHeaders = [["Order No", "Payment Method", "Amount" , "Transaction Time"]]
    const csvColumns = [
        { label: "Order No", key: "Order No" },
        { label: "Payment Method", key: "Payment Method" },
        { label: "Amount", key: "Amount" },
        { label: "Transaction Time", key: "Transaction Time" },
    ];

    const getBackgroundColor = (transactionType) => {
        switch (transactionType) {
            case 'SELFORDER':
                return `rgb(240,251,247)`;
            case 'card':
                return `rgb(242,248,252)`;
            case 'bio':
                return `rgb(253,250,247)`;
            case 'CASH PAYIT':
                 return `rgb(242,246,248)`;
            default:
                return `rgb(240,251,247)`;
        }
    };

    const getImage = (transactionType) => {
        switch (transactionType) {
            case 'bio':
            return MobileIcon
            case 'card':
                return CardIcon
            case 'SELFORDER':
                return SelforderIcon
            case 'CASH PAYIT':
                return CashIcon
            default:
                return ''
        }
    }
    const getextColor = (transactionType) => {
        switch (transactionType) {
            case 'bio':
                return `#ff8503`;
            case 'card':
                return `#1186c4`;
            case 'SELFORDER':
                return `#009447`;
            case 'Cash Payment':
                return `#032541`;
            default:
                return `#000000`;
        }
    };
            
    const fileDataPDF = pageState?.data?.map((data) => [data?.orderNumber, data?.paymentMethod, data?.transamount ,DateFormatter(data?.createdAt)]);
    return (
        <Grid container spacing={2} direction={'column'}>
             <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={() =>navigate(-1)} style={{ color: "#032541" }} />
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>{`Zed Payit Report - ${Name}`}</Typography>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
                <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                    <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> No of Orders <span style={{ fontWeight: 700 }}>{pageState.count}</span></Typography>
                    </Paper>
                    <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> Total Amount <span style={{ fontWeight: 700 }}>{currencyconverter(pageState.total)}</span></Typography>
                    </Paper>
                </Box>
            </Grid>
            <Grid container spacing={2} mt={2}>
                    {transactionTypes.map((item) => {
                        const resultFound = pageState.stats?.find((type) => type?._id === item);
                        const totalCount = resultFound?.count || 0;
                        const backgroudColor = getBackgroundColor(item);
                        const transIcon  = getImage(item);
                        const textc = getextColor(item);
                        return (
                            <Grid onClick={()=>navigate(`/school/zedpayitreports?pm?${item}?${userId}`)} sx={{cursor:'pointer'}} item xs={12} sm={6} md={3} key={item}>
                                <Paper elevation={0} sx={{ background:backgroudColor, padding: 2, height: '150px' }}>
                                   <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Box component={'div'}>
                                            <Typography sx={{fontWeight:700}}>{item === 'SELFORDER' ? 'Mobile Money':item}</Typography>
                                            <Typography fontWeight={700} py={2} color={textc}>{currencyconverter(resultFound?.total ? resultFound?.total:0 )}</Typography>
                                            <Typography>{totalCount} transactions</Typography>
                                        </Box>
                                        <Box component={'div'} display={'flex'}  alignItems={'center'}>
                                            <img src={transIcon} alt="icon" style={{ background: backgroudColor, width: '40px', height: '40px', borderRadius: '20px' }}  />
                                        </Box>
                                   </Box>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} width={'100%'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Box component={'div'} width={'30%'}>
                        <SearchFilter placeholder={"Search"} setInputValue={setOrderNumber} />
                    </Box>
                    <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                        {handleDaysChange !== "Custom Range" &&
                        <Grid item display={'flex'}>
                            <FormControl sx={{ width: '190px', height: "50px" }}>
                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Date"
                                    value={handleDaysChange}
                                    sx={{ height: '100%' }}
                                    onChange={(e) => setHandleDaysChange(e.target.value)}
                                >
                                    <MenuItem value={'Today'}>Today</MenuItem>
                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                    <MenuItem value={'Custom Range'}>Custom Range</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>}
                        {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                        <Grid item display={'flex'}>
                            <FormControl sx={{ width: '190px', height: "50px" }}>
                                <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Payment Method"
                                    value={transactionType}
                                    sx={{ height: '100%' }}
                                    onChange={(e) => setTransactionType(e.target.value)}
                                >
                                    <MenuItem value={'KCB_MPESA'}>KCB Mpesa</MenuItem>
                                    <MenuItem value={'card'}>Card</MenuItem>
                                    <MenuItem value={'Cash Payment'}>Cash</MenuItem>
                                    <MenuItem value={'MPESA'}>MPESA</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                             <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={`ZPM Cashier Report For ${Name}  `} title={`ZPM Cashier Report For ${Name} in ${businessName}`} />
                        </Grid>
                    </Box>
                </Box> 
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={payitOrders} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}