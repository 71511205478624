import { Grid, InputLabel, Button, TextField, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { SuccessAlert } from '../../../../snackBar Alerts/successAlert'
import { ErrorAlert } from '../../../../snackBar Alerts/errorAlert'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PhoneInput from "react-phone-input-2";
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import HttpComponent from '../../../../School/MakeRequest';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Partners</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Insurance Details</Typography>,
]
export default function EditInsuranceCompany() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ insuranceName: '', description: '', email: '', address: '' })
    const [phone, setPhone] = useState('');
    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, companyid] = newqueryParamValue.split("?")

    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    const navigate = useNavigate();

    // get insurance company to edt

    function getinsuranceToEdit(){
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getinsurancecompanybyid?companyid=${companyid}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setFormdata({
                        ...formData,
                        insuranceName: data?.response?.data?.name,
                        description: data?.response?.data?.description,
                        email: data?.response?.data?.email,
                        address: data?.response?.data?.adress,
                    })
                    setPhone(data?.response?.data?.phoneNo)
                }
            })
        } catch (err) {

        }
    }

    useEffect(()=>{
        getinsuranceToEdit()
    },[companyid])

    function updateinsuranceCompany() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/updateInsuranceCompanyById?id=${companyid}`,
                body: {
                    name: formData.insuranceName,
                    description: formData.description,
                    email: formData.email,
                    phoneNo: phone,
                    address: formData.address
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1500)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }
    }
    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Insurance Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography>Edit Insurance Details</Typography>
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Insurance Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.insuranceName} name='insuranceName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Description</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.description} name='description' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Email</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.email} name='email' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Phone</InputLabel>
                <PhoneInput required country={"ke"} enableSearch={true} label={"Mobile Number"} value={phone} onChange={(phone) => setPhone(phone)} multiline inputStyle={{ height: "55px", width: "100%" }} />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Address</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.address} name='address' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={()=>navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={updateinsuranceCompany} disabled={!formData.address || !formData.email || !phone || !formData.description || !formData.insuranceName} style={!formData.address || !formData.email || !formData.description || !formData.insuranceName || !phone ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Update</Button>
            </Grid>
        </Grid>
    )
}