import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import HttpComponent from "./MakeRequest";
import { useSelector, useDispatch, } from "react-redux";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { handleIsCardSetup, setCardStatus } from '../../features/paymentOptionsSlice';
import EditCard from './EditCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';



const baseUrl = process.env.REACT_APP_BASE_URL;





const CardSetup = ({ setSuccessShow, setErrorShow }) => {
    const { cardPaymentData } = useSelector((store) => store.payment);
    const [merchantId, setMerchantId] = useState('')
    const [merchantKey, setMerchantKey] = useState('')
    const [selectedAccountType, setSelectedAccountType] = useState('')
    const [merchantSecret, setMerchantSecret] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const [selectedCardType, setSelectedCardType] = useState('')
    const [bankOptions, setBankOptions] = useState([])
    const [selectedBank, setSelectedBank] = useState()
    const [bankcode, setBankcode] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountName, setAccountName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('')
    const [isEdit , setIsEdit] = useState(false)
    const [iagree,setiagree] = useState(false)
    const [cardsset , setCardsSet] = useState([])
    const [isCardPresentset, setIsCardPresent] = useState(false)
    const [isCardNotPresent , setIsCardNotPresent] = useState(false)



    //select value

    const handleSelectBank = (e) => {
        let value = e.target.value
        setSelectedBank(value)
        if (value) {
            setBankcode(value.bankCode)
            setBankName(value.bankName)
        } else {
            setBankcode('')
            setBankName('')
        }
    }


    const dispatch = useDispatch();


    const [formState, setFormState] = useState(false)
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };


    // card status check
    const { cardStatus } = useSelector((store) => store.payment);

    function getBankOptions() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transfer_banks`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setBankOptions(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error, 'error getting bank options')
        } finally {
            console.log('bank options', bankOptions)
        }
    }


    useEffect(() => {
        getBankOptions()
    }, [])

    function getcardsSet(){
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/get_general_card_setup`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    let cNotPresentstatus = data?.response?.data?.find(item => item?.name === 'Card Not Present')?.status ?? false
                    let cPresent = data?.response?.data?.find(item => item?.name === 'Card Present')?.status ?? false
                    setIsCardNotPresent(cNotPresentstatus)
                    setIsCardPresent(cPresent)
                }
            })
        } catch (error) {
            console.log(error, 'error getting cards options')
        } finally {
            console.log('cards options')
        }
    }

    useEffect(() =>{
        getcardsSet()
    },[])


    // save configured card details
    const saveCardDetails = async () => {
        console.log('businessName', merchantId, merchantKey, merchantSecret)
        if (merchantId === ' ') {
            // alert('Kindly select the bankname')
            setOpenSnack(true)
            setError(true)
            setmessage('merchantId is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (merchantKey === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('merchantKey is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);

        } else if (selectedAccountType === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('accountType is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (merchantSecret === '') {
            setOpenSnack(true)
            setError(true)

            setmessage('merchantSecret is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else {
            try {
                const response = await fetch(`${baseUrl}/api/v1/business/card-merchant-config`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        merchantId,
                        merchantKey,
                        merchantSecretKey: merchantSecret,
                        accountType: selectedAccountType
                    }),
                })
                await response.json().then((data) => {
                    console.log('response', data)
                    setOpenSnack(true)
                    setError(false)
                    setmessage('Successfully added card details')
                    dispatch(setCardStatus(true))
                    dispatch(handleIsCardSetup(true));
                    // setTimeout(() => {
                    //     setOpenSnack(false);: merch
                    //     window.location.reload();
                    //     // setSuccessfullyPaid(true);
                    // }, 2000);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }

    console.log(iagree , 'iagree')
    console.log(accountName, 'accountName')
       

    const savecardPresent = async () => {
        if (accountName === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('accountName is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (accountNumber === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('accountNumber is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        }else if (iagree !== true){
            setOpenSnack(true)
            setError(true)
            setmessage('Please accept terms and conditions')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (bankName === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('bankname is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (bankcode === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('bank code is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else {
            try {
                const response = await fetch(`${baseUrl}/api/v1/cardpresentsetup`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        creditAccountNumber: accountNumber,
                        bankCode: bankcode,
                        bankName: bankName,
                        localCurrency: localStorage.getItem("localCurrency"),
                        accountName: accountName,
                        isTnChecked: iagree,
                    }),
                })
                if (response?.status === 200 || response?.status === 201) {
                    await fetch(`${baseUrl}/api/activate_cash_payment`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            "X-Authorization": localStorage.getItem("X-Authorization"),
                        },
                        body: JSON.stringify({
                            cardStatus: true,
                            localCurrency: localStorage.getItem("localCurrency"),
                        })
                    }).then((res) => {
                        console.log(res , 'activatedd shiit')
                        setOpenSnack(true)
                        setError(false)
                        setmessage(res?.response?.message)
                        dispatch(setCardStatus(true))
                        dispatch(handleIsCardSetup(true));
                    })
                }
            } catch (error) {
                setmessage(error?.message)
            }

        }
    }


    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }


    const changeFormState = () => {
        if (formState) {
            setFormState(false)

        } if (!formState) {
            setFormState(true)
        }

    }

    return (
        <>
            <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
                {selectedCardType ? null :
                    <Box component="div">
                        <Box component={'div'} sx={{ display: "flex", alignItems: 'center', paddingLeft: 2, paddingTop: 2 }}>
                            <ArrowBackIosIcon sx={{ fontSize: "16px", color: "#b4bcc4", fontWeight: "700" }} />
                            <Typography sx={{ color: "#032541", fontSize: '16px', fontWeight: 700 }} variant='h6'>Card Options</Typography>
                        </Box>
                        <Box component="div" onClick={() => setSelectedCardType('cardnotpresent')} sx={{ paddingX: 2, paddingY: 2, cursor: 'pointer' }}>
                            <Box component="div" sx={{ display: "flex", mb: 2, justifyContent: "space-between", alignItems: 'center' }}>
                                <Typography sx={{ color: "#032541", fontSize: '16px', fontWeight: 500 }} variant='h6'>Card Not Present</Typography>
                                {isCardNotPresent ? <Typography variant='h6' color={'green'}>Active</Typography> :<Typography variant='h6' color={'red'}>Not Set</Typography>}
                                <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#b4bcc4", fontWeight: "600" }} />
                            </Box>
                            <Typography sx={{ mt: 2, color: '#707070' }} variant="p">Cardholder can authorize payments via a link and does not
                                have to be physically present.</Typography>
                        </Box>
                        {/* <Box component="div" onClick={() => setSelectedCardType('cardpresent')} sx={{ paddingX: 2, paddingY: 2, cursor: "pointer" }}>
                            <Box component="div" sx={{ display: "flex", mb: 2, justifyContent: "space-between", alignItems: 'center' }}>
                                <Typography sx={{ color: "#032541", fontSize: '16px', fontWeight: 500 }} variant='h6'>Card  Present</Typography>
                                {isCardPresentset ? <Typography variant='h6' color={'green'}>Active</Typography> :<Typography variant='h6' color={'red'}>Not Set</Typography>}
                                <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#b4bcc4", fontWeight: "600" }} />
                            </Box>
                            <Typography sx={{ mt: 2, color: '#707070' }} variant="p">Cardholder is required to be physically present to authorize
                                Payments by swiping or tapping the card.</Typography>
                        </Box> */}
                    </Box>}
                {selectedCardType &&
                    <>
                        <div className="topHeader" style={{ display: "flex", marginTop: "15px", marginLeft: "16px" }}>
                            <ArrowBack onClick={changeFormState} style={{ fontSize: "30px", cursor: "pointer" }} />
                            {cardStatus === false ? <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Card Setup</h6> :
                                <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Edit Card Details</h6>}
                        </div>
                        <Box component="form" sx={{ '& .MuiTextField-root': { margin: "20px", paddingTop: "10px" }, }} noValidate autoComplete='off' >
                            <form>
                                {selectedCardType === 'cardpresent' ?
                                    <>
                                        <FormControl sx={{ mt: 2, mr: 2, ml: 2 }} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <InputLabel id="demo-simple-select-label">Select Bank</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedBank}
                                                sx={{ width: '100%' }} label="Select Bank"
                                                onChange={handleSelectBank}
                                            >
                                                {bankOptions?.map((item, index) => (
                                                    <MenuItem key={index} value={item}>{item?.bankName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Account Name</InputLabel>
                                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                                id="outlined-multiline-flexible"
                                                placeholder="Account Name"
                                                value={accountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                                multiline
                                                required
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Account Number</InputLabel>
                                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                                id="outlined-multiline-flexible"
                                                placeholder="Account Number"
                                                value={accountNumber}
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                multiline
                                                required
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Confirm Account Number</InputLabel>
                                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                                id="outlined-multiline-flexible"
                                                placeholder="Confirm Account Number"
                                                error={accountNumber !== confirmAccountNumber}
                                                value={confirmAccountNumber}
                                                onChange={(e) => setConfirmAccountNumber(e.target.value)}
                                                multiline
                                                required
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: '100%' }}>
                                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' , px:3 }}>
                                                <Typography variant='body1'>
                                                    By agreeing to our <a  style={{color:'#032541'}} href='https://zed.business/Terms%20&%20Conditions.html'>Terms and Conditions</a> you authorise Zed to process card payments on your behalf, which will subsequently be credit to the account specified above.
                                                </Typography>
                                                <FormControlLabel required control={<Checkbox value={iagree} onChange={()=>setiagree(!iagree)} />} label="I agree" />
                                            </Box>
                                        </FormControl>
                                    </> :
                                    <>
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant ID</InputLabel>
                                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                                id="outlined-multiline-flexible"
                                                placeholder="Merchat ID"
                                                value={merchantId}
                                                onChange={(e) => setMerchantId(e.target.value)}
                                                multiline
                                                required
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: "100%", marginTop: '-15px' }}>
                                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant Key</InputLabel>
                                            <OutlinedInput
                                                style={{ width: "95%", marginTop: "45px", marginLeft: '20px' }}
                                                placeholder='merchantKey'
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={merchantKey}
                                                onChange={(e) => setMerchantKey(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl style={{ width: "100%" }}>
                                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Merchant Secrete Key</InputLabel>
                                            <OutlinedInput
                                                style={{ width: "95%", marginTop: "45px", marginLeft: "20px" }}
                                                id={`outlined-adornment-password - ${merchantSecret}`}
                                                placeholder='Merchant Secrete Key'
                                                type={showPassword1 ? 'text' : 'password'}
                                                value={merchantSecret}
                                                onChange={(e) => setMerchantSecret(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword1}
                                                            onMouseDown={handleMouseDownPassword1}
                                                            edge="end"
                                                        >
                                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{ mt: 2, mr: 2, ml: 2 }} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedAccountType}
                                                sx={{ width: '100%' }} label="Account Type"
                                                onChange={(e) => setSelectedAccountType(e.target.value)}
                                            >
                                                <MenuItem value={'pocketmoney'}>Pocket Money</MenuItem>
                                                <MenuItem value={'general'}>General</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </>
                                }
                                <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '20px', paddingBottom: '20px' }}>
                                    <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end", marginTop: '30px' }}>
                                        <Box>
                                            <Button sx={{
                                                ...buttonStyles,
                                                marginLeft: 1,
                                                backgroundColor: selectedCardType ===  'cardpresent' && iagree !== true ? 'rgba(3, 37, 65, 0.4)': "#032541",
                                                color: "white",
                                                '&:hover': {
                                                    backgroundColor: '#032541',
                                                    color: 'white'
                                                }
                                            }}
                                                onClick={() => selectedCardType === 'cardpresent' ? savecardPresent() : saveCardDetails()}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                            </form>
                            {isEdit && <EditCard cardPaymentData={cardPaymentData} setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} />}
                            <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                                <Alert severity={ifError === true ? "error" : "success"} sx={{ width: "100%" }}>
                                    <span>{message}</span>
                                </Alert>
                            </Snackbar>
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}
export default CardSetup;
