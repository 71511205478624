import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { currencyconverter } from "../../../../common/currencyconverter";
import DateFormatter from "../../../../utils/dateFormatter";
import HttpComponent from "../../../School/MakeRequest";
import { CustomizedDate } from "../../../customerAccounts/customerComponents/customDate";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Zed Payit Report </Typography>,
]

export default function ZPMPaymentMethodDrillDowns() {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, total: 0, noOfOrders: 0 });
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamState, paymentMethod, userId] = newqueryParamValue.split("?")
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [dateFrom, setDateFrom] = useState("");
    const [handleDaysChange, setHandleDaysChange] = useState("This Month")
    const [dateTo, setDateTo] = useState("")

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                break;
        }
    }, [handleDaysChange, dateFrom, dateTo]);

    const configData = pageState?.data?.map((config) => ({
        id: config?._id,
        orderNumber: config?.orderNumber,
        transactionID: config?.transactionID,
        createdAt: DateFormatter(config?.createdAt),
        transamount: currencyconverter(config?.transamount),
    }))

    const payitOrders = [
        { field: 'orderNumber', headerName: 'Order No', flex: 1 },
        { field: 'transamount', headerName: 'Amount', flex: 1 },
        { field: 'transactionID', headerName: 'Transaction ID', flex: 1 },
        { field: 'createdAt', headerName: 'Transaction Time', flex: 1 },
    ]

    function fetchPayitOrderBycashierbypaymentMethod() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/transactionbypm?paymentMethod=${paymentMethod}&page=${pageState.page}&limit=${dataGridPageSize}&userId=${userId ? userId : ''}&startDate=${startDate}&endDate=${endDate}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState((prev) => ({ ...prev, isLoading: false, data: data?.response?.data, count: data?.response?.count, total: data?.response?.cashierTotal }))
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setPageState((prev) => ({ ...prev, isLoading: false }))
        }
    }
    useEffect(() => {
        fetchPayitOrderBycashierbypaymentMethod()
    }, [pageState.page, dataGridPageSize, paymentMethod, userId, startDate, endDate])
    return (
        <Grid container direction={'column'} width={'100%'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={() => navigate(-1)} style={{ color: "#032541" }} />
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>{`Zed Payit Report - ${paymentMethod}`}</Typography>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={1} display={'flex'} justifyContent={'space-between'}>
                <Box component={'div'}></Box>
                <Box component={'div'} display={'flex'} gap={2} mt={3} alignItems={'center'}>
                    {handleDaysChange !== "Custom Range" &&
                        <Grid item display={'flex'}>
                            <FormControl sx={{ width: '190px', height: "50px" }}>
                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Date"
                                    value={handleDaysChange}
                                    sx={{ height: '100%' }}
                                    onChange={(e) => setHandleDaysChange(e.target.value)}
                                >
                                    <MenuItem value={'Today'}>Today</MenuItem>
                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                    <MenuItem value={'Custom Range'}>Custom Range</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>}
                    {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                </Box>
            </Grid>

            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={payitOrders} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}