import { Box, Button, Grid, Tab, Modal, Typography, Breadcrumbs } from "@mui/material"
import AcceptImg from "../../../images/accept-icn.svg"
import DeclineImg from "../../../images/sad-decline-icn.svg"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import CustomSearchInput from "../CustomSearchInput";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddUnitsToSchool from "./addschoolunits";
import { useNavigate } from 'react-router-dom'
import HttpComponent from "../MakeRequest";
import EditSchoolUnits from "./editSchoolUnits";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";

//breadcrumbs // images/accept-icn.svg

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Course & units</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Units</Typography>
]

// modal styles
const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "440px", height: "440px", p: 4, };

// custom tab styling
const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": { backgroundColor: "" },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "18px",
        background: "",
        borderRadius: "5px",
        marginLeft: "-10px",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": { color: "#032541", opacity: 1 },
        "&.Mui-selected": {
            color: "#dc3545",
            backgroundColor: "",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": { backgroundColor: "blue" },
    })
);
export default function SchoolUnitsMainSetUp() {
    // routing
    const navigate = useNavigate()
    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[0]
    const [unitDetails, setUnitDetails] = useState([])

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    // modal states

    const [unitName, setUnitName] = useState('')
    const [unitCode, setUnitCode] = useState('')
    const [courseId, setCourseId] = useState('')
    const [unitId , setUnitId] = useState('')

    console.log(courseId, 'courseId')

    const [openRestoreUnit, setOpenRestoreUnit] = useState(false)
    const [openDeleteUnit, setOpenDeleteUnit] = useState(false)

    const handleOpenRestoreUnitModal = (params) => {
        setUnitId(params?.row?._id)
        setUnitName(params?.row?.unitName)
        setUnitCode(params?.row?.unitCode)
        setCourseId(params?.row?._id)
        setOpenRestoreUnit(true)
    }
    const handleCloseRestoreUnitModal = () => {
        setUnitName('')
        setUnitCode('')
        setCourseId('')
        setUnitId('')
        setOpenRestoreUnit(false)
    }

    const handleOpenDeleteUnitModal = (params) => {
       // console.log(params, 'params')
        setUnitId(params?.row?._id)
        setUnitName(params?.row?.unitName)
        setUnitCode(params?.row?.unitCode)
        setCourseId(params?.row?.courseId)
        setOpenDeleteUnit(true)
    }
    const handleCloseDeleteUnitModal = () => {
        setUnitName('')
        setUnitCode('')
        setCourseId('')
        setUnitId('')
        setOpenDeleteUnit(false)
    }

    //tabs
    const [tabValue, setTabValue] = useState('ACTIVE')
    const [searchValue, setSearchValue] = useState('')

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    //handle tabvalue change
    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)

    // inactive columns
    const InActiveUnits = [
        { headerName: "unitCode", field: 'unitCode', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Unit Code</strong>) }, renderCell: (params) => { return (<span>{params?.row?.unitCode}</span>) } },
        { headerName: "unitName", field: 'unitName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Unit Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.unitName}</span></>) } },
        { headerName: "nocorses", field: 'nocorses', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >No Of Courses</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.courseCount}</span></>) } },
        {
            headerName: "action",
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <RefreshOutlinedIcon onClick={() => handleOpenRestoreUnitModal(params)} />
                    </Grid>
                )
            }
        },
    ]

    // active units

    const Activeunits = [
        { headerName: "unitCode", field: 'unitCode', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Unit Code</strong>) }, renderCell: (params) => { return (<span>{params?.row?.unitCode}</span>) } },
        { headerName: "unitName", field: 'unitName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Unit Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.unitName}</span></>) } },
        { headerName: "nocorses", field: 'nocorses', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >No Of Courses</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.courseCount}</span></>) } },
        {
            headerName: "action",
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <EditIcon onClick={() => { setUnitDetails(params?.row); navigate('/school/units?EditUnit') }} style={{ color: '#032541', cursor: 'pointer' }} />
                        <DeleteIcon onClick={() => handleOpenDeleteUnitModal(params)} style={{ color: '#dc3545', marginLeft: '10px', cursor: 'pointer', }} />
                    </Grid>
                )
            }
        },
    ]

    // get all added units

    function getAllSchoolUnits() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getCourseUnits?page=${pageState.page}&limit=${dataGridPageSize}&status=${tabValue}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                console.log(data, 'fk')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getAllSchoolUnits()
    }, [pageState.page, dataGridPageSize, state, tabValue])

    function handleDeactivateUnit() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/updateUnitStatus`,
                token: localStorage.getItem("X-Authorization"),
                body: {
                    unitId: unitId,
                    status: "INACTIVE"
                }
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        handleCloseDeleteUnitModal()
                    }, [1000])
                    getAllSchoolUnits()
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                    getAllSchoolUnits()
                }
            })
        } catch (error) {

        }
    }


    function handleRestoreUnit() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/updateUnitStatus`,
                token: localStorage.getItem("X-Authorization"),
                body: {
                    unitId:unitId,
                    status: "ACTIVE"
                }
            }).then((data) => {
                console.log(data , 'data noted')
                if (data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        getAllSchoolUnits()
                        handleCloseRestoreUnitModal()
                    }, [1000])

                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                    getAllSchoolUnits()
                }

            })
        } catch (error) {

        }
    }

    return (
        <Grid container direction={'column'}>
            {state === 'AddUnit' ? <AddUnitsToSchool /> : state === 'EditUnit' ? <EditSchoolUnits unitDetails={unitDetails} /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Courses & Units</Typography>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => navigate('/school/units?AddUnit')} style={{ background: "#032541", width: "145px", height: "45px", fontSize: "14px", textTransform: "inherit", color: "#fff" }}>Add Units</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                            <AntTab label="Active" value="ACTIVE" />
                            <AntTab label="InActive" value="INACTIVE" style={{ marginLeft: "20px" }} />
                        </AntTabs>
                        <TabPanel value="ACTIVE" style={{ marginLeft: "-30px" }}>
                            <Grid item>
                                <Grid container direction={'column'}>
                                    <Grid item>
                                        <CustomSearchInput placeholder={'search'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                    </Grid>
                                    <Grid item mt={1}>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.total}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState?.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={Activeunits}
                                            rows={pageState?.data}
                                            getRowId={row => row?._id}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="INACTIVE">
                            <Grid item style={{ marginLeft: "-30px" }}>
                                <Grid container direction={'column'}>
                                    <Grid item>
                                        <CustomSearchInput placeholder={'search'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                    </Grid>
                                    <Grid item mt={1}>
                                        <DataGrid
                                            components={{ NoRowsOverlay: NoRowsOverlay }}
                                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                            rowCount={pageState?.total}
                                            loading={pageState?.isLoading}
                                            pagination
                                            page={pageState?.page - 1}
                                            pageSize={dataGridPageSize}
                                            paginationMode="server"
                                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            columns={InActiveUnits}
                                            rows={pageState?.data}
                                            getRowId={row => row?._id}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>}

            <Modal
                open={openRestoreUnit}
                onClose={handleCloseRestoreUnitModal}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} ustifyContent={'center'} alignItems={'center'}>
                            <Grid item display={'flex'} justifyContent={'center'} direction={'column'} alignItems={'center'}>
                                <Grid item>
                                    <img src={AcceptImg} alt='Warning' />
                                </Grid>
                                <Grid item direction={'column'} textAlign={'center'} alignItems={'center'}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Reactivate Unit ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to reactivate  <span style={{ color: '#000000', fontWeight: 600 }}>{unitName}</span>
                                    </Typography>
                                    <span>This unit  will be restored!</span>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseRestoreUnitModal} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleRestoreUnit} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#17ae7b" }}>Reactivate</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDeleteUnit}
                onClose={handleCloseDeleteUnitModal}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item display={'flex'} justifyContent={'center'} direction={'column'} alignItems={'center'}>
                                <Grid item>
                                    <img src={DeclineImg} alt='Warning' />
                                </Grid>
                                <Grid item direction={'column'} textAlign={'center'} alignItems={'center'}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Delete Unit ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <span style={{ color: '#000000', fontWeight: 600 }}>{unitName}</span>  <span>Will be deleted from active units </span>
                                    </Typography>
                                    <span>Are you sure you want to proceed!</span>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeleteUnitModal} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleDeactivateUnit} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", marginLeft: "10px", textTransform: "inherit", background: "#dc3545" }}>Delete </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}