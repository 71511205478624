import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  banks: []
}


const EditedBanksSlice = createSlice({
  name:"editedBanks",
  initialState,
  reducers:{
    handleBankEdited:(state, action) => {
        state.banks.push(action.payload)
    },
    handleRemove: (state, action) => {
        state.banks = state.banks.filter((item) => item.name !== action.payload)
    }

  }
})

export const { handleBankEdited, handleRemove } = EditedBanksSlice.actions;

export default EditedBanksSlice.reducer