import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, Grid, Slide, Tab, Typography } from "@mui/material"
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import EditIcon from '@mui/icons-material/Edit';
import AddSponsor from "./addSponsor";
import HttpComponent from "../MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { SponsorProfile } from "./sponsorProfile";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ExportMenu from "../ExportMenu";
import { forwardRef } from "react";
import WarningImg from "../../../images/warning.png"
import EditSponsor from "./editSponsor";



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Sponsors</Typography>,
]


// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: 'transparent' } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "17px", background: 'transparent', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#cb444a", backgroundColor: 'transparent', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: 'transparent' },
}));

const StudentSponsors = () => {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const [tabValue, setTabValue] = useState('ACTIVE')
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")
   

    const RenderCell = (params) => {
        const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false)
        const [selectedSponsor , setSelectedSponsor] = useState(null) 
        const handleDeactivate = () => {
            console.log(params.row , 'handleDeactivate')
            setSelectedSponsor(params.row)
            setOpenDeactivateDialog(!openDeactivateDialog)
        }
        return (
            <Box component={'div'} alignItems={'center'}>
                <EditIcon onClick={()=>navigate(`/school/sponsors?EditSponsor?${params?.row?._id}`)} sx={{ color: '#032541', cursor: 'pointer', marginLeft: '10px' }} />
                <PersonOffIcon onClick={handleDeactivate} sx={{ color: '#dc3545', cursor: 'pointer', marginLeft: '10px' }} />
                <Dialog
                open={openDeactivateDialog}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        height: "300px",
                        width: "600px",
                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                    },
                }}
                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "1", backdropFilter: "blur(1px)", } }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <img src={WarningImg} alt='approve' />
                    <Box component={'div'} sx={{ display: "flex", gap: 2, flexDirection: "column", alignContent: "center", textAlign: 'center', alignItems: "center", width: '100%' }}>
                        <Typography sx={{ color: "#032541", fontWeight: '700', fontSize: "14px" }} variant="subtitle1">Deactivate  Sponsor</Typography>
                        <Typography sx={{ color: "#707070" }} variant="subtitle2"><span style={{ color: "#032541" }}>{selectedSponsor?.customerName}</span> will no longer have access to system  , <span style={{ color: "#032541" }}>{selectedSponsor?.NoofstudentsSponsored} students</span> will automatically be deactivated</Typography>
                        <Typography sx={{ color: '#707070', fontSize: "12px" }} variant="subtitle2">Do you wish to continue ? </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: "flex", pb: 3, justifyContent: "center", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <Button onClick={handleDeactivate} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                    <Button style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                </DialogActions>
            </Dialog>
            </Box>
        )
    }

    const sponsorColumns = [
        { field: "customerName", headerName: "Name", flex: 1, renderCell: (params) => <Link to={`/school/sponsors?ViewSponsorDetails?${params?.row?._id}`}>{params?.row?.customerName}</Link> },
        { field: "mobileNumber", headerName: "Mobile No", flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'physicalAddress', headerName: 'Address', flex: 1 },
        { field: 'NoofstudentsSponsored', headerName: 'Student Sponsored', flex: 1 },
        { field: 'sponsorType', headerName: 'Type', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1, renderCell: RenderCell },
    ]
    function fetchSponsorsAdded() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?page=${pageState.page}&limit=${dataGridPageSize}&customerClassification=Sponsor&status=${tabValue}&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    console.log(data, 'data ###')
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchSponsorsAdded()
    }, [pageState.page, dataGridPageSize, tabValue, searchValue, queryParamValue])

    const filedata = pageState?.data?.map((data) => {
        return {
            "Name": data?.customerName,
            "Mobile No": data?.mobileNumber,
            "Email": data?.email,
            "Address": data?.physicalAddress,
            "Student Sponsored": data?.NoofstudentsSponsored,
            "Type": data?.sponsorType,

        }
    })
    const fileHeaders = [["Name", "Mobile No", "Email", "Address", "Student Sponsored", "Type"]]
    const csvColumns = [
        { label: "Name", key: "Name" },
        { label: "Mobile No", key: "Mobile No" },
        { label: "Email", key: "Email" },
        { label: "Address", key: "Address" },
        { label: "Student Sponsored", key: "Student Sponsored" },
        { label: "Type", key: "Type" },
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.customerName, data?.mobileNumber, data?.email, data?.physicalAddress, data?.NoofstudentsSponsored, data?.sponsorType]);
    return (
        <Grid container width={'100%'} display={'flex'} direction={'column'}>
            {queryParamValue === 'AddSponsor' ? <AddSponsor /> : queryParamValue === 'ViewSponsorDetails' ? <SponsorProfile /> : queryParamValue === 'EditSponsor' ? <EditSponsor/>: <>
                <Grid item mt={2} mb={2}>
                    <Grid container gap={2} display={'flex'} direction={'column'}>
                        <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                                <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Sponsors</Typography>
                            </Grid>
                            <Button onClick={() => navigate(`/school/sponsors?AddSponsor`)} style={{ background: "#032541", borderRadius: "5px", textTransform: "inherit", width: "180px", height: "45px", color: "#fff" }}>Add Sponsor</Button>
                        </Grid>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid item mt={2}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                            <AntTab label="Active" value="ACTIVE" />
                            <AntTab label="Deactivated" value="Suspended" style={{ marginLeft: '20px' }} />
                        </AntTabs>
                        <TabPanel value="ACTIVE" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>
                                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Sponsors'} title={'Sponsors'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"} mt={5}>
                                    <DataGrid
                                        components={{ NoRowsOverlay: NoRowsOverlay }}
                                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                        rowCount={pageState?.total}
                                        loading={pageState?.isLoading}
                                        pagination
                                        page={pageState?.page - 1}
                                        pageSize={dataGridPageSize}
                                        paginationMode="server"
                                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        columns={sponsorColumns}
                                        rows={pageState?.data}
                                        getRowId={row => row?._id} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>}
        </Grid>
    )
}

export { StudentSponsors }