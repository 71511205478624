import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useDispatch, useSelector } from "react-redux";
// import { setDetails } from "../../../../features/businessToUpdateSlice";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { setDetails } from "../../../../features/userToUpdate";
import LinearProgress from "@mui/material/LinearProgress";
import UsersDropdown from "./UsersDropdown";
import { setUserGroups, setUsers } from "../../../../features/ExporterSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function NewTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch();

  const itemActivated = useSelector((state) => state.alertState.activated);
  const itemDeleted = useSelector((state) => state.alertState.deleted);

  let Group = localStorage.getItem("group");
  let partnerName = localStorage.getItem("affiliatedPartner");
  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  //fetching the table data
  const fetchNewUsers = async () => {
    if (isAdmin) {
    const response = await axios.get(baseUrl + "/api/fetchNewUsers", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setData(response.data.data);
  }else if(isPartner) {
    const response = await axios.post(baseUrl + "/api/getPartnerUsers",
    {
      partnerName
    },
    {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    let users = response.data.users

    let arr = []

    users.map(obj => {
      if(obj.userState === 'New') {
        arr.push(obj)
      }
    })
    setData(arr);
    console.log('users', data)
  }

  };

  useEffect(() => {
    fetchNewUsers();
  }, [itemActivated, itemDeleted]);

  let searchQuery = useSelector((state) => state.search.searchValue);

  let filteredData;

  useEffect(() => {
    filteredData = data.filter((item) =>
      item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);

  //creating rows from the fetched data
  const createRows = () => {
    let arr = [];
    if (searchQuery.length === 0) {
      data?.map((obj) => {
        let dateString = obj.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString("en-US");

        let val = createData(
          obj.firstName,
          obj.secondName,
          obj.userName,
          obj.userEmail,
          obj.userPhone,
          obj.userGroup,
          obj.userState,
          formattedDate,
          <UsersDropdown />
        );
        arr.push(val);
      });
    } else {
      filteredData?.map((obj) => {
        let dateString = obj.createdAt;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString("en-US");

        let val = createData(
          obj.firstName,
          obj.secondName,
          obj.userName,
          obj.userEmail,
          obj.userPhone,
          obj.userGroup,
          obj.userState,
          formattedDate,
          <UsersDropdown />
        );
        arr.push(val);
      });
    }

    // arr.sort((a, b) => (a.dateCreated < b.dateCreated ? -1 : 1));
    setRows(arr);
  };

  useEffect(() => {
    createRows();
  }, [data, searchQuery]);

  const [loading, setLoading] = useState(true);
  const [hasItems, setHasItems] = useState(true);

  useEffect(() => {
    if (rows.length > 0) {
      setLoading(false);
      const newArray = rows.map(({ action, ...rest }) => rest);
      dispatch(setUsers(newArray));
    }
  }, [rows]);

  useEffect(() => {
    const delayedFunction = () => {
      // This function runs 5 seconds after initial load;
      if (rows.length === 0) {
        setHasItems(false);
        setLoading(false);
      }
    };

    const timer = setTimeout(delayedFunction, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [rows]);

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null}
      <Table
        sx={{ minWidth: 500, fontFamily: "Poppins" }}
        aria-label="custom pagination table"
        size={"small"}
      >
        <TableHead sx={{ bgcolor: "#f0f0f0" }}>
          <TableRow>
            <TableCell>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                First Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Second Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Username
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Phone No
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                User Group
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Date Created
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow
              key={row.groupName}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => {
                let details = {
                  fname: row.firstName,
                  sname: row.secondName,
                  userName: row.userName,
                  email: row.email,
                  phoneNo: row.phoneNo,
                  userGroup: row.userGroup,
                  status: row.status,
                };
                dispatch(setDetails(details));
              }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.firstName}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.secondName}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.userName}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.email}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.phoneNo}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.userGroup}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.status}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.dateCreated}
                </Typography>
              </TableCell>
              <TableCell align="left">{row.action}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={9}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              // showFirstButton={true}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, mr: 10 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(
  firstName,
  secondName,
  userName,
  email,
  phoneNo,
  userGroup,
  status,
  dateCreated,
  action
) {
  return {
    firstName,
    secondName,
    userName,
    email,
    phoneNo,
    userGroup,
    status,
    dateCreated,
    action
  };
}