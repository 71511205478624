import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CustomSelectField from "../../School/CustomSelectField";
import HttpComponent from "../../School/MakeRequest";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { handleTenantTransfer } from "../../../features/tenantsDataSlice";


const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES", }).format(value);

export default function AddMoreUnitsToTenant(props) {
    const {id} = useParams()
    const [houseNo, setHouseNo] = useState('')
    const [houseType, setHouseType] = useState('')
    const [billingPeriod, setBillingPeriod] = useState('')
    const [period, setPeriod] = useState([])
    const [unitHouseType, setUnitHouseType] = useState([])
    const [unitHouseNo, setUnitHouseNo] = useState([])
    const [unit_id, setUnit_id] = useState('')
    const [unitPurpose ,setUnitPurpose] = useState('')
    const [unit_amount, setUnit_amount] = useState('')
    const [rentDueDate, setRentDueDate] = useState('')

    const dispatch = useDispatch()

    //config

    const { userId, X_Authorization } = useSelector((store) => store.user);
    const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
    const configId = businessUnitConfigId ? businessUnitConfigId : "64074a6feae8f43c3b8e2044"


    //Billing Periods
    const billingPeriodOptions = period.map((date) => ({ value: date, label: date }))
    const unitHouseNumberOptions = unitHouseNo.map((house) => ({ value: house.unitName, label: house.unitName }))


    useEffect(() => {
        props?.handleUpdateUnitId(unit_id)
    },[unit_id])

    // Get Billing Periods
    function getBillingPeriods() {
        try {
            HttpComponent({
                method: 'GET',
                url: '/api/get_billing_dates',
                token: X_Authorization
            }).then((data) => {
                if (data.status === 201) {
                    setPeriod(data.response.data)
                } else {
                    console.log(`Error Getting Periods!`)
                }
            }).catch((error) => {
                console.error(error.message);
            })
        } catch (e) {
            console.log(`Error Getting Periods!`)
        }
    }

    // Get House Types
    function getHouseTypes() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_unit_types_by_business_unit_config?businessUnitConfigId=${configId}`,
                token: X_Authorization
            }).then((data) => {
                if (data.status === 201) {
                    setUnitHouseType(data.response.data)
                } else {
                    console.log(`Error Getting Periods!`)
                }
            }).catch((error) => {
                console.error(error.message);
            })
        } catch (e) {
            console.log(`Error getting House types`)
        }
    }

   console.log(props)

    // Get House No
    function getHouseNo() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_units_by_unit_type_id?page=1&limit=1000&businessUnitTypeId=${unit_id}`,
                token: X_Authorization
            }).then((data) => {
                if (data.status === 201) {
                    setUnitHouseNo(data.response.data)
                } else {
                    console.log(`Error Getting Periods!`)
                }
            }).catch((error) => {
                console.error(error.message);
            })
        } catch (e) {
            console.log(`Error Getting House No.`)
        }
    }

    useEffect(() => {
        getBillingPeriods()
        getHouseTypes()
    }, [])

    useEffect(() => {
        getHouseNo()
    }, [unit_id])


    //submit  the house
   

    let formData = {
         tenantId:id, 
         houses:[{
        unitType:houseType,
        itemNumber:houseNo,
        amount:unit_amount ,
        billingDate:billingPeriod,
        rentDueDate:rentDueDate }]
    }

    console.log(formData , 'house details')

    function handleAssignTenantAnotherHouse(){
		try {
			HttpComponent({
				method : 'POST',
				url : '/api/add_tenant',
				body : formData,
				token : X_Authorization
			}).then((data)=>{
				console.log(data ,'added unit to tenants houses')
                props.units(unit_id)
                props.charges()
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error saving tenant`, e.message)
		}

	}

    //handle next
    const handleNext = ()=>{
        props.handleUpdateUnitId(unit_id)
        props.charges()
    }

    const saveHouseInfo = ()=>{
        dispatch(handleTenantTransfer({houseType,billingPeriod,rentDueDate,unitPurpose ,houseNo ,unit_amount}))
    }

    console.log(unitPurpose)


    return (
        <Grid container  direction={'column'} mt={3} spacing={3}>

            {/*Header*/}
            <Grid item>
                <span style={{ fontSize: '16px', fontWeight: '600', color: '#032541' }} >Add House Details</span>
            </Grid>

            {/*House Type*/}
            <Grid item>
                <Select
                    MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" }, getcontentanchorel: null }}
                    value={houseType} onChange={(e) => { setHouseType(e.target.value) }} style={{ color: '#032541FF', width: '400px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1" } }} variant="outlined" margin="dense" displayEmpty>
                    <MenuItem value="">Select House Type</MenuItem>
                    {
                        unitHouseType.map((house, index) => (
                            <MenuItem key={index} onClick={() => { setUnit_id(house._id); setUnitPurpose(house.unitPurpose); setUnit_amount(house.unitCost) }} value={house.unitType}>{house.unitType}</MenuItem>
                        ))
                    }
                </Select>
            </Grid>

            {/*House No*/}
            <Grid item>
                <CustomSelectField style={{ color: '#032541FF', width: '400px', fontFamily: 'Poppins', fontSize: '9px', fontWeight: 'normal' }} value={houseNo} onChange={(e) => { setHouseNo(e.target.value) }} name={"House No"} placeholder={"Select House No"} options={unitHouseNumberOptions} />
            </Grid>

            {/*House No*/}
            <Grid item>
                <TextField disabled required readOnly value={numberFormat(unit_amount)} defaultValue={numberFormat(unit_amount)} type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"400px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '400px' }} id="outlined-basic" label="Amount" variant="outlined" />
            </Grid>

            {/*Billing Period*/}
            <Grid item>
                <CustomSelectField style={{ color: '#032541FF', width: '400px', fontFamily: 'Poppins', fontSize: '9px', fontWeight: 'normal' }} value={billingPeriod} onChange={(e) => { setBillingPeriod(e.target.value) }} name={"Billing Period"} placeholder={"Select Billing Period"} options={billingPeriodOptions} />
            </Grid>
            {/*Rent due */}

            <Grid item>
                <CustomSelectField style={{ color: '#032541FF', width: '400px', fontFamily: 'Poppins', fontSize: '9px', fontWeight: 'normal' }} value={rentDueDate} onChange={(e) => { setRentDueDate(e.target.value) }} name={"Rent Due Date"} placeholder={"Select Rent Due Date"} options={billingPeriodOptions} />
            </Grid>
            <Grid item display={'flex'} style={{width:"400px"}} justifyContent={'flex-end'}>
				<Button onClick={() =>{handleNext(); saveHouseInfo()}} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
					Add
				</Button>
			</Grid>
        </Grid>

    )
}