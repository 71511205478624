import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../../components/customerAccounts/customerComponents/invoice.css";
import PrintIcon from '@mui/icons-material/Print';
import { makeStyles } from "@mui/styles";
import html2pdf from 'html2pdf.js';

const moment = require('moment')

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

const Receipt = (props) => {
    const navigate = useNavigate();

    const path = 'invoices'


    const { receiptNumber, itemNumber, businessNo, invoiceNumber } = useParams();
    console.log(invoiceNumber, 'Invoice  Number');
    console.log(businessNo, 'businessNo  Number');


    const classes = useStyles();
    const [total, setTotal] = useState(0)
    const [businessInfo, setBusinessInfo] = useState({})
    const [receiptData, setReceiptData] = useState()

    const [invoicePayments, setInvoicePayments] = useState();
    const [invoiceBalance, setInvoiceBalance] = useState();

    const downloadPDF = () => {
        const input = document.getElementById('downloadable');
        html2pdf(input, {
            margin: 10,
            filename: 'receipt.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }).from(input).save();
       
    }

    const viewPDF = () => {
        const input = document.getElementById("downloadable");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const inputWidthPx = input.clientWidth;
            const inputHeightPx = input.clientHeight;
            const dpi = window.devicePixelRatio * 96;
            let scaleFactor = 1;
            if (window.innerWidth > 1512) {
                scaleFactor = 1.4;
            }
            const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
            const inputHeightMm = (inputHeightPx / dpi) * 25.4;
            const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
            pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);

            // Create a modal dialog to display the PDF
            const modal = document.createElement("div");
            modal.style.position = "fixed";
            modal.style.top = "0";
            modal.style.left = "0";
            modal.style.width = "100%";
            modal.style.height = "100%";
            modal.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
            modal.style.display = "flex";
            modal.style.alignItems = "center";
            modal.style.justifyContent = "center";
            modal.style.zIndex = "1000";

            // Create an iframe to load the PDF content
            const iframe = document.createElement("iframe");
            iframe.style.width = "80%";
            iframe.style.height = "80%";
            iframe.src = pdf.output("bloburl");
            modal.appendChild(iframe);

            // Add the modal to the document
            document.body.appendChild(modal);

            // Close the modal when the user clicks outside of it
            modal.addEventListener("click", () => {
                document.body.removeChild(modal);
            });
        });
    };

    const getReceiptDetails = async () => {
        try {
            const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_receipt_by_no_auth_invoice_number?businessNo=${businessNo}&invoiceNumber=${invoiceNumber}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );
            if (invoiceDetailsResponse.status === 201) {
                const invoiceDetails = await invoiceDetailsResponse.json();
                console.log("receipt data ", invoiceDetails.data)
                setReceiptData(invoiceDetails.data);
                setBusinessInfo({
                    ...businessInfo,
                    businessName: invoiceDetails.data.fromCompanyName,
                    businessCategory: invoiceDetails.data.businessCategory,
                    businessLogo: invoiceDetails.data.businessLogo,
                    businessOwnerAddress: invoiceDetails.data.businessOwnerAddress,
                    country: invoiceDetails.data.fromBranchLocation,
                    businessOwnerEmail: invoiceDetails.data.fromBranchEmail,
                    businessOwnerPhone: invoiceDetails.data.fromPhoneNo,
                    houseNo: invoiceDetails?.data?.studentId,
                    unitType: invoiceDetails?.data?.unitType,
                    invoiceDatePaid: invoiceDetails?.data?.invoiceDatePaid,
                    paymentReferenceNumber: invoiceDetails?.data?.paymentReferenceNumber,
                    localCurrency: invoiceDetails?.data?.localCurrency
                })
            }
        } catch (error) {
            console.error("error fetching invoice details", error.message);
        }
    };

    let localCurrency = businessInfo?.localCurrency

    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
        localCurrency = 'KES'
      }
      
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);

    const formatDate = (date) => {
        return moment(date).format("dddd, MMMM Do, YYYY");
    }

    const formatPaymentDate = (unFormatted) => {
        const date = new Date(unFormatted);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedDate = date.toLocaleString('en-US', options);
        const dateSuffix = function () {
            const date = new Date();
            const dateNumber = date.getDate();
            switch (dateNumber) {
                case 1:
                case 21:
                case 31:
                    return 'st';
                case 2:
                case 22:
                    return 'nd';
                case 3:
                case 23:
                    return 'rd';
                default:
                    return 'th';
            }
        };
        return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(0, 3)} ${formattedDate.slice(7)}`
    }

    const calculateTotal = () => {
        if (receiptData) {
            let calculatedTotal = 0;
            receiptData?.items?.forEach((product) => {
                calculatedTotal += product.productPrice * product.quantity;
            });
            setTotal(calculatedTotal);
        }
    }

    const back = () => {
        navigate("/receipts")
    }



    const getInvoicePayments = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );
        if (invoicePaymentDetailsResponse.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            console.log("Invoice payment data ", paymentDetailsData.data)
            let { invoicePayments, invoiceBalance } = paymentDetailsData.data
            console.log("ip", invoicePayments)
            setInvoicePayments(invoicePayments);
            setInvoiceBalance(invoiceBalance);
        }
    }


    useEffect(() => {
        getReceiptDetails().then(() => console.log("done receipt invoice data"))
        getInvoicePayments().then(() => console.log("payments fetched"))
    }, [receiptNumber, itemNumber, businessNo]);

    useEffect(() => {
        console.log("fff", businessInfo)
    }, [businessInfo]);


    useEffect(() => {
        calculateTotal();
    }, [receiptData]);


    return (
        <>
            <div style={{ width: "70%", marginLeft: "20%" }}>
                <div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ paddingTop: 15, display: "flex" }}>
                                <span>
                                    <IconButton aria-label="back" onClick={() => path === 'coa' ? navigate("/customerreceipts") : back()}>
                                        <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                                    </IconButton>
                                </span>
                                <span style={{ "fontFamily": "Poppins", "fontSize": "25px", "fontWeight": "600", "textAlign": "left", "color": "#dc3545" }}>Preview Receipt</span>
                            </div>
                            <div style={{ padding: 10, display: "flex", }}>


                                <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                                    <IconButton aria-label="download" onClick={downloadPDF}>
                                        <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                    </IconButton>
                                </span>
                                <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", marginLeft: "20px", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                                    <IconButton aria-label="download" onClick={viewPDF}>
                                        <PrintIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                    </IconButton>

                                </span>

                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
                            <div className={classes.p3} style={{ backgroundColor: `rgba(3,37,65,0.05)`, height: "150px", display: "flex", justifyContent: "space-between", }}>

                                <div className={classes.p1} style={{ display: "flex", flexDirection: "column", }}>
                                    <span style={{ color: "black", fontSize: "30px", fontWeight: "700", }}>PAYMENT RECEIPT</span>
                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "700", }}>Date:</span>
                                    <span style={{ fontSize: "14px" }}>{formatDate(receiptData?.createdAt)}</span>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                        <span style={{ color: "black", fontSize: "14px", fontWeight: "700", textAlign: "left" }}>Receipt No: <span style={{ fontSize: "14px" }}>{businessInfo?.paymentReferenceNumber}</span></span>

                                    </div>
                                </div>


                                <div style={{ display: "flex", flexDirection: "column", }}>
                                    <img src={`${baseUrl}/staticimages/logos/${businessInfo?.businessLogo}`} style={{ width: "150px", height: "150px", objectFit: "contain", }} />

                                </div>

                            </div>
                            <div className={classes.p3} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                                <div style={{ display: "flex", flexDirection: "column", }}>
                                    <span style={{ fontSize: "18px", }}>From:</span>
                                    <span className={classes.address_text_common} style={{ fontWeight: "700" }}>{businessInfo?.businessName}</span>
                                    <span className={classes.address_text_common}>{businessInfo?.businessOwnerAddress}, {businessInfo?.country}</span>
                                    <span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
                                    <span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
                                </div>
                                <div className={classes.mt4} style={{ display: "flex", flexDirection: "column", textAlign: "right", }}>
                                    <span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
                                    <span className={classes.address_text_common}>{receiptData?.toName}</span>
                                    <span className={classes.address_text_common}>{receiptData?.toEmail}</span>
                                    <span className={classes.address_text_common}>{receiptData?.toPhoneNo}</span>
                                </div>
                            </div>
                            <br />
                            {
                                receiptData?.businessCategory === 'Rental' ?

                                    <div style={{ border: "dotted 1px", display: "flex", justifyContent: "flex-start", spacing: '10px', padding: "0.625rem" }}>
                                        <div>
                                            HOUSE NUMBER: <span style={{ fontWeight: "bold" }}>{businessInfo?.houseNo}</span>
                                        </div>
                                        <div style={{ marginLeft: '20px' }}>
                                            HOUSE TYPE: <span style={{ fontWeight: "bold", }}>{businessInfo?.unitType}</span>
                                        </div>
                                        <div style={{ marginLeft: '20px' }}>
                                            PERIOD: <span style={{ fontWeight: "bold" }}>{formatDate(businessInfo?.invoiceDatePaid)}</span>
                                        </div>

                                        <div style={{ marginLeft: '20px' }}>
                                            INVOICE NUMBER: <span style={{ fontWeight: "bold" }}>{itemNumber}</span>
                                        </div>
                                        {console.log('usinessInfo.businessInfo', businessInfo.businessInfo)}
                                    </div> : receiptData?.businessCategory === 'School' ?
                                        <div style={{ border: "dotted 1px", display: "flex", justifyContent: "space-between", padding: "0.625rem" }}>
                                            <div>
                                                STUDENT ID: <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.studentId}</span>
                                            </div>
                                            <div>
                                                STUDENT NAME: <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.studentName}</span>
                                            </div>
                                            <div>
                                                GRADE:  <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.grade?.replace("Grade", "")}</span>
                                            </div>
                                            <div>
                                                TERM:  <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.term?.replace("Term", "")}</span>
                                            </div>
                                        </div> : null
                            }

                            <br />
                            <div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.textLeft}>
                                                Description
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                Price ({localCurrency})
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>Qty</TableCell>
                                            <TableCell className={classes.textRight}>
                                                Total ({localCurrency})
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {receiptData?.items?.map((product, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.textLeft}>
                                                <span className={classes.table_body}>
                                                    {product.productName}
                                                </span>
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                <span className={classes.table_body}>{numberFormat(product.productPrice)}</span>
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                <span className={classes.table_body}>{product.quantity}</span>
                                            </TableCell>
                                            <TableCell className={classes.textRight}>
                                                <span className={classes.table_body}>{numberFormat(product.productPrice * product.quantity)}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </div>
                            <div
                                className={classes.p3}
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                }}
                            >
                                <div
                                    className={classes.p1}
                                    style={{
                                        backgroundColor: "#f7f7f7",
                                        width: "288px",
                                        height: "130px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className={classes.totals}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span>Subtotal</span>
                                        <span>{numberFormat(total)} </span>
                                    </div>
                                    <div
                                        className={classes.totals}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span>Discount</span>
                                        <span>{localCurrency} 0.00</span>
                                    </div>
                                    <div
                                        className={classes.totals}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span style={{ color: `${receiptData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>
                                            Total
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                color: `${receiptData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {numberFormat(total)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div style={{ border: "dotted 1px" }}></div>
                            <br />

                            <div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.textLeft}>
                                                Mode of Payment
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                Date
                                            </TableCell>
                                            <TableCell className={classes.textRight}>
                                                Amount
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {invoicePayments?.map((payment, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.textLeft}>
                                                <span className={classes.table_body}>
                                                    {payment.modeOfPayment}: {payment.ReferenceNumber}
                                                </span>
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                <span className={classes.table_body}>{formatPaymentDate(payment.date)}</span>
                                            </TableCell>
                                            <TableCell className={classes.textRight}>
                                                <span className={classes.table_body}>{numberFormat(payment.amount)}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </div>
                            <br />
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                                    <span>Balance: {numberFormat(invoiceBalance)}</span>
                                </div>
                            </div>
                            <br />
                            <div style={{ border: "dotted 1px" }}></div>
                            <br />

                            <div
                                className={classes.p2}
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div
                                    className={classes.textCenter}
                                    style={{ fontSize: "10px", fontWeight: "300" }}
                                >
                                    This is a system-generated receipt is created without any
                                    alteration whatsoever.
                                    <br />
                                    Thank you for your business.
                                </div>
                            </div>
                            <div
                                className={classes.p2}
                                style={{
                                    backgroundColor: "#f7f7f7",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span className={classes.footer_text}>
                                    Powered by <span style={{
                                        fontWeight: "700"
                                    }}>ZED Payments Limited</span>
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>
                                    info@zed.business
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>v1.0.2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Receipt;
