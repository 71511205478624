import React, { useEffect, useState } from "react";
import { Button, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import cardIcon from '../../../common/images/card-icon.svg'
import creditIcon from '../../../common/images/creditbal-icn.svg'
import mpesaIcon from '../../../common/images/mpesa-icn.svg'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';


// ../../Modals/Invoice/receivedPayment
import { makeStyles } from "@mui/styles";
import DateFormatter from "../../../utils/dateFormatter";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import HttpComponent from "../../School/MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL;
const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));
const PreviewSupplierInvoice = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const date = new Date();
    const [businessName, setBusinessName] = useState(localStorage.getItem("businessName"));
    const [invoiceData, setInvoiceData] = useState(JSON.parse(localStorage.getItem("supplierInvoice")));
    const [businessInfo, setBusinessInfo] = useState()
    const [responseData, setResponseData] = useState()
    const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);
    
    const supplierDetails = invoiceData?.supplierDetails[0]
    let totals = invoiceData?.items.reduce((acc, item) => { return acc + item.itemTotal }, 0)
    const [totalPrice, setTotalPrice] = useState(totals);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });


    

    const downloadPDF = () => {
        const input = document.getElementById('downloadable');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 1.0);
                const pdf = new jsPDF("p", "mm", [210, 297]); // A4 paper size in mm
                pdf.addImage(imgData, 'JPEG', 5, 5, 200, 287);
                pdf.save(`invoice.pdf`);
            })
    }


    const back = () => {
        navigate(-1)
    }

    const getBusinessDetails = async () => {
        try {
            const businessDetailsResponse = await fetch(baseUrl + "/api/get_business_info",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );


            if (businessDetailsResponse.status === 200) {
                const businessDetailsData = await businessDetailsResponse.json();
                console.log("business data ", businessDetailsData.data)
                setBusinessInfo(businessDetailsData.data);
            }
        } catch (error) {
            console.error("error fetching customer details", error.message);
        }
    };

    console.log(invoiceData , 'invoiceData')

    
    let items = invoiceData.items.map((item) => {
        return {
            productId: item.itemId,
            quantity: parseInt(item.quantity),
            price:parseInt(item.itemAmount)
        }
    })
    console.log(items , 'items >>>>>>>.')
    const saveInvoice = async () => {
        let items = invoiceData.items.map((item) => {
            return {
                productId: item.itemId,
                quantity: parseInt(item.quantity),
                price:parseInt(item.itemAmount),
                accountId: item.accountId,
                accountName: item.accountName

            }
        })
        console.log(items , 'items >>>>>>>.')

        let body = {
            supplierId: supplierDetails?._id,
            invoiceDate: invoiceData.invoiceDate,
            items: items
        }
        
        console.log(body, 'body')
        try {

            HttpComponent({
                method: 'POST',
                url: `/api/v1/supplier/generate_invoice`,
                body: body,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')

                if (data?.status === 200 || data?.status === 201) {
                    
                    setSuccessShow({ ...successShow, state: true, message: "Invoice Successfully saved" })
                    // remove otherPayments from local storage
                    localStorage.removeItem('supplierInvoice')
                    navigate('/payables/suppplier/raisepayment')
                   

                }else{
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.message })
                }

            })

        } catch (error) {

        }

    };
    const saveInvoiceAndPay = async () => {
        let items = invoiceData.items.map((item) => {
            return {
                productId: item.itemId,
                quantity: item.quantity,
                price: parseInt(item.itemAmount)
            }
        })

        let body = {
            supplierId: supplierDetails?._id,
            invoiceDate: invoiceData.invoiceDate,
            items: items
        }
        console.log(body, 'body')
        try {

            HttpComponent({
                method: 'POST',
                url: `/api/v1/supplier/generate_invoice`,
                body: body,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')

                if (data.status === 200 || data.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: "Invoice Successfully saved" })
                    // remove otherPayments from local storage
                    let dataresponse = data.response?.data
                    console.log(dataresponse, 'dataresponse')
                    let  dataObj = {
                        selectedSupplierId: supplierDetails?._id,
                        selectedName: supplierDetails?.name,
                        selectedInvoices:[{
                            invoiceId: dataresponse?._id,
                            invoiceNumber: dataresponse?.invoiceNumber,
                            invoiceAmount: dataresponse?.invoiceAmount
                        }],
                        bankCode: supplierDetails?.bankCode,
                        bankName: supplierDetails?.bankName,
                        phoneNumber: supplierDetails?.phone,
                        accountNumber: supplierDetails?.bankAccountNumber,
                        totalInvoicesAmount: data.response?.data?.invoiceAmount,
                    }
                    localStorage.setItem("supplierPayment", JSON.stringify(dataObj))
                    localStorage.removeItem('supplierInvoice')
                    navigate('/payables/suppplier/raisepayment?step=2')

                    




                }else{
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.message })
                }

            })

        } catch (error) {

        }

    };


    let localCurrency = localStorage.getItem('localCurrency')

    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }

    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);


    useEffect(() => {
        getBusinessDetails()
    }, []);

    const deleteItem = (id) => {
        console.log("delete item", id)
        let items = invoiceData.items.filter((item) => item.itemId !== id)
        setInvoiceData({ ...invoiceData, items })
        localStorage.setItem("supplierInvoice", JSON.stringify({ ...invoiceData, items }))
        console.log("invoice data", invoiceData)


    }


    return (
        <>
         <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

            <div style={{ width: "80%", marginLeft: "10%" }}>
                <div>
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <div style={{ paddingTop: 15, display: "flex" }}>
                                <span>
                                    <IconButton aria-label="back" onClick={() => back()}>
                                        <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                                    </IconButton>
                                </span>
                                <span style={{ "fontFamily": "Poppins", "fontSize": "25px", "fontWeight": "600", "textAlign": "left", "color": "#dc3545" }}>Preview Invoice</span>
                            </div>
                            <div style={{
                                padding: 10,
                                display: "flex",
                            }}>
                                
                                    <Button style={{ "width": "145px", "height": "45px", "borderRadius": "5px","border":"1px solid #032541", "backgroundColor": "#fff", color: "#032541", marginRight: 5 }}
                                        onClick={() => saveInvoice()}
                                    >Save Invoice</Button>
                                     <Button style={{ "width": "145px", "height": "45px", "borderRadius": "5px", "backgroundColor": "#032541", color: "white" }}
                                        onClick={() => saveInvoiceAndPay()}
                                    >Pay Now</Button>
                               
                                <span style={{
                                    backgroundColor: "rgba(3, 37, 65, 0.05)",
                                    width: "45px",
                                    height: "45px",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginLeft: 5,
                                    borderRadius: 5
                                }}>
                                    <IconButton aria-label="download" onClick={downloadPDF}>
                                        <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                    </IconButton>
                                </span>

                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "visible",
                                height: "auto"
                            }}
                            id="downloadable"
                        >
                            <div
                                className={classes.p3}
                                style={{
                                    backgroundColor: "rgba(220,53,69,0.05)",
                                    height: "150px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    className={classes.p1}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "black",
                                            fontSize: "40px",
                                            fontWeight: "700",
                                        }}
                                    >
                                        INVOICE
                                    </span>
                                    <span
                                        style={{
                                            color: "black",
                                            fontSize: "14px",
                                            fontWeight: "700",
                                        }}
                                    >
                                        Date:
                                    </span>
                                    <span style={{ fontSize: "14px" }}>
                                        {DateFormatter(invoiceData?.invoiceDate)}
                                    </span>
                                </div>
                                <div style={{     display: "flex",     flexDirection: "column", }}>
                                    <span style={{     color: "#dc3545",     fontSize: "40px",     fontWeight: "700", }}>
                                        Unpaid
                                    </span>
                                    <span style={{}}>
                                        Invoice Number <br />
                                        ----
                                    </span>
                                    
                                </div>
                            </div>

                            <div
                                className={classes.p3}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "18px",
                                        }}
                                    >
                                        From:
                                    </span>
                                    <span
                                        className={classes.address_text_common}
                                        style={{ fontWeight: "700" }}
                                    >
                                        {businessName}
                                    </span>
                                    {/*                  <span className={classes.address_text_common}>*/}
                                    {/*  Branch: Parklands*/}
                                    {/*</span>*/}
                                    <span className={classes.address_text_common}>
                                        {businessInfo?.businessOwnerAddress}, {businessInfo?.country}
                                    </span>
                                    <span className={classes.address_text_common}>
                                        {businessInfo?.businessOwnerEmail}
                                    </span>
                                    <span className={classes.address_text_common}>
                                        {businessInfo?.businessOwnerPhone}
                                    </span>
                                </div>
                                <div
                                    className={classes.mt4}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "right",
                                    }}
                                >
                                    <span
                                        className={classes.address_text_common}
                                        style={{ fontWeight: "700" }}
                                    >
                                        Billed to:
                                    </span>
                                    <span className={classes.address_text_common}>{supplierDetails.name}</span>
                                    <span className={classes.address_text_common}>
                                        {supplierDetails.email}
                                    </span>
                                    <span className={classes.address_text_common}>
                                        {supplierDetails.phone}
                                    </span>
                                </div>
                            </div>

                            <div
                                className={classes.m1}
                                style={{ border: "dotted 1px" }}
                            ></div>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.textLeft}>
                                            Description
                                        </TableCell>
                                        <TableCell className={classes.textCenter}>
                                            Price ({localCurrency})
                                        </TableCell>
                                        <TableCell className={classes.textCenter}>Qty</TableCell>
                                        <TableCell className={classes.textRight}>
                                            Total ({localCurrency})
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {invoiceData.items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.textLeft}>
                                            <span className={classes.table_body}>
                                                {item?.itemName}
                                            </span>
                                        </TableCell>
                                        <TableCell className={classes.textCenter}>
                                            <span className={classes.table_body}>{numberFormat(item?.itemAmount)}</span>
                                        </TableCell>
                                        <TableCell className={classes.textCenter}>
                                            <span className={classes.table_body}>{item?.quantity}</span>
                                        </TableCell>
                                        <TableCell className={classes.textRight}>
                                            <span className={classes.table_body}>{numberFormat(item.itemAmount * item.quantity)}</span>
                                            <span className={classes.table_body} style={{color:"#dc3545",cursor:"pointer" }} onClick={() => deleteItem(item?.itemId)}> <DeleteIcon /> </span>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>

                            <div className={classes.p3} style={{     display: "flex",     justifyContent: "end", }}>
                                <div style={{marginRight:"12rem"}}>
                                    <Button variant="contained" style={{ backgroundColor: "#f7f7f7", border:"#032541", color: "#032541", width: "145px", height: "40px", marginTop: "20px" }}  onClick={() => back()}>Add Products</Button>

                                </div>
                                <div className={classes.p1}style={{    backgroundColor: "#f7f7f7",    width: "288px",    height: "130px",    display: "flex",    flexDirection: "column",    justifyContent: "center",}} >
                                    <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span>Subtotal</span>
                                        <span>{numberFormat(totalPrice)}</span>
                                    </div>
                                    <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <span style={{ color: "#dc3545", fontWeight: "bold" }}>
                                            Total
                                        </span>
                                        <span style={{fontSize: "16px",color: "#dc3545",fontWeight: "bold", }}>
                                            {numberFormat(totalPrice)}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div style={{ border: "dotted 1px" }}></div>

                            <div
                                className={classes.p3}
                                style={{ display: "flex", flexDirection: "column" }}
                            >
                                <div className={classes.p1}>
                                    <span
                                        style={{
                                            color: "#032541",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                        }}
                                    >
                                        Accepted Payment Methods
                                    </span>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        <img
                                            className={classes.p2}
                                            src={mpesaIcon}
                                            alt="M-pesa"
                                        />
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "12px"
                                        }}><span>M-Pesa</span></div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <img
                                            className={classes.p2}
                                            src={cardIcon}
                                            alt="Visa"
                                        />
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "12px"
                                        }}>
                                            <span>Card</span>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <img
                                            className={classes.p2}
                                            src={creditIcon}
                                            alt="Credit"
                                        />
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: "12px"
                                        }}>
                                            <span>Credit Balance</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={{ border: "dotted 1px" }}></div>

                            <div
                                className={classes.p2}
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div
                                    className={classes.textCenter}
                                    style={{ fontSize: "10px", fontWeight: "300" }}
                                >
                                    This is a system-generated invoice is created without any
                                    alteration whatsoever.
                                    <br />
                                    Thank you for your business.
                                </div>
                            </div>
                            <div
                                className={classes.p2}
                                style={{
                                    backgroundColor: "#f7f7f7",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span className={classes.footer_text}>
                                    Powered by <span style={{
                                        fontWeight: "700"
                                    }}>ZED Payments Limited</span>
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>
                                    info@zed.business
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>v1.0.2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Received payment*/}
        </>
    )
}

export default PreviewSupplierInvoice