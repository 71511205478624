import { Typography, Button, Breadcrumbs, Grid, Card, TableHead, TableRow, TableCell } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from "react";
import lodash from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { ButtonBase, Table } from "@mui/material";
import { useNavigate } from "react-router-dom";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DeleteOutline } from "@mui/icons-material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HttpComponent from "../../School/MakeRequest";
import { handleSetCurrentServices, handledeleteCurrentServcie } from "../../../features/tenantsDataSlice";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import SetWorkFlow from "../Utility/updateSetupWorkFlow";
import {makeStyles} from '@mui/styles'

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Create Tenant
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Tenant Details
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        House Details
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Advance Charges
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Preview Invoice
    </Typography>
];

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

export default function PreviewTanantInvoice(props) {

    const allHouseInvoice = props?.houseiN
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [tenantInfo ,setTenantInfo] = useState([])
    const classes = useStyles();
    const date = new Date();
    const {X_Authorization } = useSelector((store) => store.user);
    const formattedDate = format(date, "EEEE, MMMM dd, yyyy");
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [isOpenCard , setIsOpenCard]= useState(false)
    const { companyName, firstName, lastName, email, userPhone, customerType, tenantId } = useSelector((store) => store?.tenantData?.tenantBioData)
    //const { itemNumber, unitType, extraServices } = useSelector((store) => store.tenantData.tenantServices[0])
    //let { businessName, businessOwnerEmail, businessOwnerPhone, businessOwnerAddress } = useSelector((store) => store?.tenantBusinessData?.businessData)
    const dispatch = useDispatch()
    const [businessDetails , setBusinessDetails] = useState({})
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
	const [successShow, setSuccessShow] = useState({state: false, message: ""})

    function fetchTenantInfo(){
		try {
			HttpComponent({
				method: "GET",
                url: `/api/get_customer_by_customer_id?customerId=${tenantId}`,
                token: X_Authorization
			}).then((data)=>{
                if(data.status === 200){
                    setTenantInfo(data.response.data)
                }
			})
		} catch (error) {
			console.log(error)
		}
	}
    function fetchBusinessDetails(){
        try {
            HttpComponent({
                method:'POST',
                body:null,
                url:`/api/get_business_info` ,
                token:localStorage.getItem("X-Authorization"),
            }).then((data)=>{
                console.log(data , 'fetch business info')

                if(data.status === 200){
                    setBusinessDetails(data.response.data)
                }
            })
            
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        fetchBusinessDetails()
    },[])



    //download invoice

    const downloadPDF = () => {
		const input = document.getElementById('downloadable');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png', 1.0);
				const inputWidthPx = input.clientWidth;
				const inputHeightPx = input.clientHeight;
				const dpi = window.devicePixelRatio * 96;
				let scaleFactor = 1;
				if (window.innerWidth > 1512) {
					scaleFactor = 1.4;
				}
				const inputWidthMm = (inputWidthPx / dpi) * 25.4 / scaleFactor;
				const inputHeightMm = (inputHeightPx / dpi) * 25.4;
				const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
				pdf.addImage(imgData, 'JPEG', 5, 5, inputWidthMm - 10, inputHeightMm - 10);
				pdf.save(`tenantInvoice.pdf`);
			})
	}

// data to show;
const [dataToShow, setDataToShow] = useState([]) 
const populateCard = (data) => {

    const newData = [...dataToShow];

    if(newData.length === 0){
        // newData.shift()
        newData.push(data)
        setSelectedInvoice(data)
    }else {
        newData.shift()
        newData.push(data)
        setSelectedInvoice(data)
    }
    setIsOpenCard(true)
    setDataToShow(newData)
}

//persist selected card 
//unitTypeID
const extras = lodash.get(dataToShow ,'[0].extraServices')
const unitTypeID = lodash.get(dataToShow ,'[0].unitTypeID')
const unitID = lodash.get(dataToShow ,'[0].businessUnitTypeId')
const itemNumber = lodash.get(dataToShow,'[0].itemNumber')

//update the current services  itemNumber

useEffect(()=>{
    dispatch(handleSetCurrentServices(extras))
},[extras])

const tenantCurrentServices = useSelector((store)=>store?.tenantData?.tenantCurrentServices)

const services = tenantCurrentServices?.map((servcie)=>{
    return{
        name:servcie?.name,
        productId:servcie?.productId,
        quantity:1,
        amount:servcie?.amount
    }
})

const total = lodash.sumBy(tenantCurrentServices, charge => {
    return parseInt(charge?.amount)

})

    //invoice body
    let invoiceBody = {
        itemNumber : itemNumber,
		customerId: tenantId,
		type: `one-off`,
		frequency: `once`,
		products:services ,
		billableItemId: unitTypeID ? unitTypeID:unitID
    }
console.log(isOpenCard ,'invoicecard is open')
 //send invoice

 function sendInvoice(){
    try {
        
        HttpComponent({
            method: 'POST',
			url: '/api/generateInvoice',
			body: [invoiceBody],
			token: X_Authorization
        }).then((data)=>{
            console.log(data ,'invoice send successfully')
            if(data.status === 200){
                setInvoiceNumber(data.response.data.invoiceNumber)
                setSuccessShow({state:"true" ,message:"Invoice send successfully"})
                props.handleT()
            }else{
                setErrorShow({state:"true" , message:"Error sending invoice"})
            }
           
        })
    } catch (error) {
        
    }

}

useEffect(()=>{
    fetchTenantInfo()
} ,[])

const {businessName ,businessOwnerAddress ,businessOwnerEmail ,businessOwnerName,businessOwnerPhone} = businessDetails

//delete item from invoice

const handleDeleteClick = (productId) => {
    console.log('handling delete of a service',productId)
    dispatch(handledeleteCurrentServcie(productId));
};

useEffect(()=>{


},[])

//add more services

const addMoreservices = ()=>{
    if(selectedInvoice !== null && isOpenCard === true){
        props?.saveunitid(unitTypeID)
        props.updateInvo()
    }
}

//save the invoice

function saveTenantInvoice (){
    try {
        HttpComponent({
            method:'POST',
            url:'/api/save_invoice_in_draft',
            body:invoiceBody,
            token:X_Authorization
        }).then((data)=>{
            //console.log(data ,'invoice saved successfully')

            if(data.status === 200){
                setSuccessShow({state:"true" , message:"Invoice saved as draft"})
                props.handleT()
            }else{
                setErrorShow({state:"true" ,message:"Error saving invoice"})
            }
        })
        
    } catch (error) {
    
    }
}

const navigate = useNavigate()

function handleCompleteInvoice() {
    SetWorkFlow({ flowName: "COMPLETE", token:localStorage.getItem('X-Authorization')})
    navigate("/dashboard")
}


    return (
        <>
            {/*snackBar*/}
			<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
            <ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>
            <Grid container direction={'column'}>
                <Grid item display={'flex'} alignItems={'center'}>
                    <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={props?.prev} />
                    <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Preview Invoices</Typography>
                </Grid>
                <Grid item mb={'10px'} alignItems={'center'} mt={'10px'} direction={'row'} display={'flex'} justifyContent={'space-between'}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Grid item>
                        <Grid container>
                            {selectedInvoice && 
                            <Grid item>
                                 <Button onClick={downloadPDF}>
                                    <FileDownloadOutlinedIcon style={{ width: '55px', height: '35px', color: '#032541', background: 'rgba(3, 37, 65, 0.05)', borderRadius: '5px' }} />
                                </Button>
                                <Button onClick={saveTenantInvoice} style={{ textTransform: 'none', marginRight: "10px", color: '#032541', background: "#fff", border: '1px solid #032541', fontSize: '16px', fontWeight: '600' }}>Save Invoice</Button>
                                <Button onClick={sendInvoice} style={{ textTransform: 'none', color: '#fff', background: '#032541', fontSize: '16px', fontWeight: '600' }}>Send  Invoice</Button>
                            </Grid>
                            }   
                            <Grid item>
                                <Button onClick={handleCompleteInvoice} style={{ textTransform: 'none', width:"145px", marginLeft: "10px", color: '#fff', background: "#17ae7b", fontSize: '16px', fontWeight: '600' }}>Complete</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h6" style={{ color: '#032541', fontWeight: 400, fontSize: "18px" }}>Select House to Preview Invoice</Typography>
                </Grid>

                {/* TODO : List the invoice cards */}
                <Grid container mb={5} mt={3} column={4} spacing={2}>
                    {allHouseInvoice?.map((invoiceCard , index) => {
                          const cardTotal = lodash.sumBy(invoiceCard?.extraServices, charge => {
                            return parseInt(charge?.amount)
                    
                        })
                        return (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                <Card onClick={() => populateCard(invoiceCard)} style={selectedInvoice === invoiceCard ? { border: "1px solid #0d243f", cursor: "pointer", marginRight: "10px", width: '100%', height: '100px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', backGroundColor: '#fff', display: "flex", alignContent: "center" } : { cursor: "pointer", marginRight: "10px", width: '100%', height: '100px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', backGroundColor: '#fff', display: "flex", alignContent: "center" }}>
                                    <Grid container display={'flex'} alignItems={'center'} alignContent={'center'} textAlign={'center'} justifyContent={'space-evenly'} spacing={3}>
                                        <Grid item>
                                            <Typography variant="h6" style={{ color: '#032541', fontWeight: 400, fontSize: "18px", background: 'rgba(3, 37, 65, 0.05)', borderRadius: '5px', padding: '10px 10px' }}>{invoiceCard?.itemNumber}</Typography>
                                        </Grid>
                                        <Grid item direction={'column'} justifyContent={'flex-start'} alignContent={'center'}>
                                            <Typography variant="h6" style={{ color: '#0d243f', fontSize: "12px", fontWeight: 400 }}>Invoice Amount</Typography>
                                            <Typography variant="h6" style={{ color: "#707070", fontSize: "18px" }}>{numberFormat(cardTotal)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            {selectedInvoice &&
                <div style={{ width: "80%", marginLeft: "10%" }}>
                    <div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
                                <div className={classes.p3} style={{ backgroundColor: "rgba(220,53,69,0.05)", height: "177px", display: "flex", justifyContent: "space-between", }}>
                                    <div className={classes.p1} style={{ display: "flex", flexDirection: "column", margin: '2%' }}>
                                        <span style={{ color: "black", fontSize: "40px", fontWeight: "bolder", }}>INVOICE</span>
                                        <span style={{ color: "black", fontSize: "14px", fontWeight: "700", marginTop: '17px' }}>Date:</span>
                                        <span style={{ fontSize: "14px" }}>{formattedDate}</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", margin: '2%' }}>
                                        <span style={{ color: "#dc3545", fontSize: "40px", fontWeight: "700", textAlign: 'right' }}>Unpaid</span>
                                        <span style={{ color: "black", fontSize: "14px", fontWeight: "700", marginTop: '17px', textAlign: 'right' }}>Invoice No:</span>
                                        {invoiceNumber ? (<span style={{ fontSize: "14px", textAlign: 'right' }}>{invoiceNumber}</span>) :
                                            <span style={{ fontSize: "14px", fontWeight: 'bold', textAlign: 'right' }} >--------</span>}
                                    </div>
                                </div>
                                <div className={classes.p3} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: '21px', width: "95%", marginLeft: '2%' }}>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <span style={{ fontSize: "18px", marginBottom: '5.7px' }}>From:</span>
                                        <span className={classes.address_text_common} style={{ fontWeight: "700" }}>{businessName}</span>
                                        <span className={classes.address_text_common}>{businessOwnerAddress}</span>
                                        <span className={classes.address_text_common}>{businessOwnerEmail}</span>
                                        <span className={classes.address_text_common}>{businessOwnerPhone}</span>
                                    </div>
                                    <div className={classes.mt4} style={{ display: "flex", flexDirection: "column", textAlign: "right", }}>
                                        <span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
                                        {customerType?.toLowerCase()?.includes('company') ?
                                            <span className={classes.address_text_common}>{companyName}</span>
                                            :
                                            <span className={classes.address_text_common}>{firstName}{lastName}</span>
                                        }
                                        <span className={classes.address_text_common}>{email}</span>
                                        <span className={classes.address_text_common}>{userPhone}</span>
                                    </div>
                                </div>

                                {dataToShow?.map((house ,index)=>{
                                    return(
                                        <Grid key={index} container justifyContent={'flex-start'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%', border: "dotted 2px #bfbfbf", height: "45px" }}>
                                        <Grid item style={{ marginRight: "15%", padding: '0.5%' }}>
                                            <span style={{ marginLeft: '22px' }}>HOUSE NUMBER: </span>
                                            <span style={{ fontWeight: "bold" }}>{house.itemNumber}</span>
                                        </Grid>
                                        <Grid item style={{ padding: '0.5%' }} >
                                            <span>HOUSE TYPE: </span>
                                            <span style={{ fontWeight: "bold" }} >{house.unitType}</span>
                                        </Grid>
                                    </Grid>
                                    )
                                })}


                                <Table style={{ marginLeft: '22px' }}>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.textLeft}>Description</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.textCenter}>Price (KES)</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.textCenter}>Qty</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'right' }} className={classes.textRight}>Total (KES)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {tenantCurrentServices?.map((service, index) => (
                                        <TableRow key={index} align={'right'}>
                                            <TableCell className={classes.textLeft}><span className={classes.table_body}>{service?.serviceName || service?.name}</span></TableCell>
                                            <TableCell className={classes.textCenter}><span className={classes.table_body}>{numberFormat(service?.amount)}</span></TableCell>
                                            <TableCell className={classes.textCenter}><span className={classes.table_body}>{1}</span></TableCell>
                                            <TableCell style={{ textAlign: 'right' }} className={classes.textRight}><span className={classes.table_body} style={{ textAlign: 'right' }}>{numberFormat(service?.amount)}</span></TableCell>
                                            <TableCell className={classes.textRight}>
                                                <ButtonBase  onClick={() => {handleDeleteClick(service.productId)}}>
                                                    <DeleteOutline style={{ color: '#dc3545' }} />
                                                </ButtonBase>
                                            </TableCell>

                                        </TableRow>

                                    ))}
                                </Table>

                                <Grid container justifyContent={'space-between'} alignContent={'center'} direction={'row'} mt={1} spacing={2}>

                                    {/*<Grid item>*/}
                                    {/*<Grid item>*/}
                                    <Button onClick={addMoreservices} style={{ border: 'solid 1px rgba(3, 37, 65, 0.1)', fontWeight: 'bold', width: '651px', color: '#032541', borderRadius: '2px', alignContent: 'center', background: '#fff', fontSize: '14px' }}>
                                        <AddCircleOutlineIcon style={{ fontSize: '18px', marginRight: '12px' }} /> Add Service</Button>
                                    {/*</Grid>*/}
                                    {/*</Grid>*/}
                                    <Grid item style={{ backgroundColor: 'rgba(3, 37, 65, 0.1)', width: '40%', textAlign: 'right', borderRadius: '2px' }}>
                                        <span style={{ color: '#032541', marginRight: '2%' }}>Total :</span>
                                        <span style={{ color: '#032541', fontWeight: 'bold', marginRight: '10%' }}>{numberFormat(total)}</span>
                                    </Grid>
                                </Grid>

                                <div className={classes.p3} style={{ display: "flex", justifyContent: "end", }}>
                                    <div className={classes.p1} style={{ backgroundColor: 'rgba(220, 53, 69, 0.05)', width: "288px", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                                        <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Subtotal</span>
                                            <span>{numberFormat(total)}</span>
                                        </div>
                                        <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>Discount</span>
                                            <span>KES 0.00</span>
                                        </div>
                                        <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span style={{ color: "#dc3545", fontWeight: "bold" }}>Total</span>
                                            <span style={{ fontSize: "16px", color: "#dc3545", fontWeight: "bold", }}>{numberFormat(total)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ borderTop: "dotted 2px #bfbfbf" }}></div>
                                <div className={classes.p3} style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignContent: 'center' }}>
                                    <div className={classes.p1}>
                                        <span style={{ color: "#032541", fontWeight: "700", fontSize: "16px", }}>Accepted Payment Methods</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <img className={classes.p2} src={mpesaIcon} alt="M-pesa" />
                                            <div style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>
                                                <span>M-Pesa</span>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <img className={classes.p2} src={cardIcon} alt="Visa" />
                                            <div style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>
                                                <span>Card</span>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            <img className={classes.p2} src={creditIcon} alt="Credit" />
                                            <div style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>
                                                <span>Credit Balance</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div style={{ border: "dotted 1px" }}></div>

                                <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                                    <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
                                        This is a system-generated invoice is created without any
                                        alteration whatsoever.
                                        <br />
                                        Thank you for your business.
                                    </div>
                                </div>

                                <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }}>
                                    <span className={classes.footer_text}>Powered by <span style={{ fontWeight: "700" }}>ZED Payments Limited</span></span>
                                    <span className={classes.footer_text}>.</span>
                                    <span className={classes.footer_text}>info@zed.business</span>
                                    <span className={classes.footer_text}>.</span>
                                    <span className={classes.footer_text}>v1.0.2</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                }
            </Grid>
        </>
    )
}