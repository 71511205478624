import * as React from "react";
import { Grid, styled, MenuItem, alpha, Menu, Button } from '@mui/material'
import { MoreVert } from "@mui/icons-material";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Stack } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import DateFormatter from "../../../../utils/dateFormatter";
import CustomSelectField from "../../../School/CustomSelectField";
import { CustomizedDate } from "../../../customerAccounts/customerComponents/customDate";
import CustomSearchInput from "../../../School/CustomSearchInput";
import { Exports } from "../../../customerAccounts/customerComponents/exports";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";

const baseUrl = process.env.REACT_APP_BASE_URL;


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function AllTransactionsTable() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
  const [categories, setCategories] = useState([])
  const [selectedBusinessCatgory, setSelectedcategory] = useState('')
  const fetchBusinessCategories = () => {
    try {
      axios
        .get(baseUrl + "/api/fetchBusinessCategories", {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        })
        .then((response) => {
          if (response) {
            setCategories(response?.data?.data?.map((category) => ({
              value: category?.categoryName,
              label: category?.categoryName
            })));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessCategories();
  }, []);

  let partnerName = localStorage.getItem("affiliatedPartner");
  const [searchValue, setSearchValue] = useState('')

  const [days, setDays] = useState([
    { value: "Default", label: "Default" },
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 7 Days", label: "Last 7 Days" },
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
    { value: "Custom Range", label: "Custom Range" },
  ]);

  const [handleDaysChange, setHandleDaysChange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");


  useEffect(() => {
    switch (handleDaysChange) {
      case "Today":
        const today = new Date();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1));
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]);
          const lastSevenLast = new Date(result[result?.length - 1]);
          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0, 0));
        const lastDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth() + 1, 0, 23, 59, 59, 999));
        setStartDate(firstDay);
        setEndDate(lastDay);
        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth() - 1, 1, 0, 0, 0, 0));
        const lastDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), 0, 23, 59, 59, 999));
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      case "Default":
        setStartDate("");
        setEndDate("");
        break;
      case "Custom Range":
        setStartDate(new Date(dateFrom));
        setEndDate(new Date(dateTo));
        break;
      default:
        return;
    }
  }, [handleDaysChange, dateFrom, dateTo]);

  //fetching the table data
  function fetchAllTransactions() {
    setPageState((old) => ({ ...old, isLoading: true }));
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/fetchAllTransactions?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
        token: localStorage.getItem("X-Authorization"),
        body: {
          category: selectedBusinessCatgory,
          startDate: startDate,
          endDate: endDate,
          // dateFilter: period,
          partnerName
        }
      }).then((data) => {
        if (data?.status === 200) {
          console.log(data, 'yyyyyyyy')
          setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data?.response?.data })
        } else {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      })

    } catch (error) {
      console.log(error?.message)

    }
  };


  useEffect(() => {
    fetchAllTransactions();
  }, [startDate, endDate, pageState?.page, searchValue, selectedBusinessCatgory, dataGridPageSize]);


  const column = [
    {
      field: "transactionID",
      headerName: "TXID",
      flex: 1,
      filter: "text",
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#000",
          }}
        >
          {"TXID"}
        </strong>
      ),
      renderCell: (params) => {
        return (
          <span style={{ color: "#000", cursor: "pointer" }}>
            {" "}
            {params?.row?.transactionID}{" "}
          </span>
        );
      },
    },
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Business Name"}
        </strong>
      ),
    },
    {
      field: "businessType",
      headerName: "Business Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Business No"}
        </strong>
      ),
    },
    {
      field: "transtime",
      headerName: "Date & Time",
      flex: 1,
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Date & Time"}
        </strong>
      ),
      width: 200,
      valueGetter: (params) => {
        const transTime = DateFormatter(params?.row?.transtime);
        return transTime;
      },
    },
    {
      field: "uploadTime",
      headerName: "Upload Time",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Upload Time"}
        </strong>
      ),
      flex: 1,
      width: 200,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return (DateFormatter(params?.row?.uploadTime))
      },
    },
    {
      field: "customerPhone",
      headerName: "Phone",
      flex: 1,
      width: 200,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Phone"}
        </strong>
      ),
      valueGetter: (params) => {
        const phone = params?.row?.customerPhone || "";
        return phone.replace(/(\d{5})\d{3}(\d{4})/, "$1***$2");
      },
    },
    {
      field: "transamount",
      flex: 1,
      headerName: "Amount",
      type: "number",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Amount"}
        </strong>
      ),
      valueGetter: (params) => {
        const amount = params?.row?.transamount || "";
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        }).format(amount);
      },
    },
    {
      field: "terminalSerialNumber",
      headerName: "Serial",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Serial"}
        </strong>
      ),
    },
  ];

  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [csvExport, setcsvExport] = useState({ headers: [], data: [] })

  useEffect(() => {
    // CSV Export Format
    const csvColumns = [
      { label: "TransactionId", key: "transactionID" },
      { label: "Business Name", key: "businessName" },
      { label: "Date", key: "Date" },
      { label: "Upload Time", key: "Upload Time" },
      { label: "PhoneNo", key: "PhoneNo" },
      { label: "Amount", key: "Amount" },
      { label: "Terminal  Serial", key: "serialNo" },
    ];

    const csvData = pageState?.data?.map((data) => {
      return {
        "transactionID": data?.transactionID,
        "businessName": data?.businessName,
        "Date": DateFormatter(data?.transtime),
        "Upload Time": DateFormatter(data?.uploadTime),
        "PhoneNo": data?.customerPhone,
        "Amount": data?.transamount,
        "serialNo": data?.terminalSerialNumber
      }
    })

    const fileDataPDF = pageState?.data?.map((data) => [data?.transactionID, data?.businessName, DateFormatter(data?.transtime), DateFormatter(data?.uploadTime), data?.customerPhone, data?.transamount, data?.terminalSerialNumber]);

    //PDF export Format
    const headers = [
      [
        "TransactionID",
        "Business Name",
        "Date",
        "Upload Time",
        "PhoneNo",
        "Amount",
        "serialNo",
      ],
    ];

    setExportData({ headers: headers, data: fileDataPDF });
    setcsvExport({ headers: csvColumns, data: csvData });

  }, [pageState?.data, searchValue, selectedBusinessCatgory, startDate, endDate, pageState?.total])


  function handleExportCSV() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/payments/export-by-date`,
        body: {
          category: selectedBusinessCatgory,
          startDate: startDate,
          endDate: endDate,
          partnerName: partnerName
        },
        token: localStorage.getItem("X-Authorization"),
      }).then((data)=>{
        if(data?.status === 200){
           setSuccessShow({state:true , message:data?.response?.message})
        }else{
          setErrorShow({state:true , message:data?.response?.message})
        }
      })
    } catch (error) {

    }
  }




  return (
    <Grid container>
      <SuccessAlert vertical="top" horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state} message={errorShow.message} />
      {pageState.isLoading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null}
      <Grid item display={"flex"} alignItems={"center"} sx={{ width: '100%' }} pl={1}>
        <Grid container display={"flex"} gap={2} alignItems={"center"} width={'100%'}>
          <Grid item>
            <CustomSearchInput sm placeholder={'Search'} value={searchValue} name={'searchValue'} onChange={(e) => setSearchValue(e.target.value)} />
          </Grid>
          <Grid item>
            <CustomSelectField onChange={(e) => setSelectedcategory(e.target.value)} value={selectedBusinessCatgory} placeholder={"Business Category"} options={categories} name={"Business Category"} />
          </Grid>
          <Grid item>
            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}

            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
          </Grid>
          <Grid item>
            <Button style={{ fontFamily: "Poppins", fontWeight: "500", margin: "0 12px 0 0", padding: "9px 10px 8px 11px", backgroundColor: "#f5f6f7", color: "#032541" }} endIcon={<MoreVert />} id="demo-customized-button" aria-controls={open ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} variant="contained" disableElevation onClick={handleClick}>
              Export
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem disableRipple style={{}}>
                Export as PDF
              </MenuItem>
              <MenuItem disableRipple>
                Export as Excel
              </MenuItem> */}
              <MenuItem disableRipple onClick={() => {handleExportCSV(); setAnchorEl(null)}}>
                Send  CSV  to Email
              </MenuItem>
            </StyledMenu>
            {/* <Exports exportData={exportData} csvExport={csvExport} activeTabs={'Transaction Report'} /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} item direction={'column'} height={'600px'} width={'100%'}>
        <DataGrid
          components={{ NoRowsOverlay: NoRowsOverlay }}
          sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
          rowsPerPageOptions={[10, 20, 50, 70, 100]}
          rowCount={pageState?.total}
          loading={pageState?.isLoading}
          pagination
          page={pageState?.page - 1}
          pageSize={dataGridPageSize}
          paginationMode="server"
          onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          columns={column}
          rows={pageState?.data}
          getRowId={row => row?._id} />
      </Grid>
    </Grid>
  );
}

