import React, { useState, useEffect } from "react";
import { Grid, Breadcrumbs, Tab, styled, Button, Typography } from "@mui/material";
import { customerstyles } from "./customerComponents/styles";
import { NavigateNext } from "@mui/icons-material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Allcustomers from "./customerComponents/activecustomers";
import SuspendedCustomer from "./customerComponents/suspendedCustomer";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomerAproval from "./customerComponents/customerAproval";
import SearchFilter from "./customerComponents/searchFilter";
import { Exports } from "./customerComponents/exports";
import { CustomDate } from "./customerComponents/customDate";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

//breadcrumbs

const breadcrumbs = [
  <Typography style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Dashboard</Typography>,
  <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Customers</Typography>
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const Customerlist = () => {
  const navigate = useNavigate();
  const [customersTab, setCustomersTab] = useState("Customers");

  // exported data;
  const [exportData, setExportData] = useState({ headers: [], data: [] });

  // csv data exports;

  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
  // set tabChange;
  const customersTabChange = (event, newTabValue) => {
    setCustomersTab(newTabValue);
  };

  // FILTER BY DATE;
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");


  useEffect(() => {
    // if(dateFrom && dateTo)
    // getFilteredData(dateFrom, dateTo);
  }, [dateFrom, dateTo]);


  // filter customer by name;
  const [customerSearchValue, setInputValue] = useState("")

  return (
    <div style={customerstyles.universalStyle}>
      <Grid container direction={"column"}>
        <Grid item>
          <Typography fontFamily={'Poppins'} style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Customer Accounts</Typography>
        </Grid>
        <Grid item mt={1}>
          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
            <Grid item>
              <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Button onClick={() => navigate(`/createcustomer/new`)} startIcon={<Add />} style={customerstyles.addCustomer}>
                Add Customer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={customerstyles.gridMargin}>
        <Grid item width={"100%"}>
          <TabContext value={customersTab}>
            <AntTabs onChange={customersTabChange}>
              <AntTab label="Customers" value="Customers" />
              <AntTab label="Customer Approval" value="Approval" />
              <AntTab label="Suspended Customer" value="Suspended" />
            </AntTabs>
            <TabPanel value={"Customers"}>
              <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                <Grid item style={customerstyles.searchDateFilter}  >
                  <SearchFilter placeholder={'Search customer'} setInputValue={setInputValue} />
                  <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                </Grid>
                <Grid item>
                  <Exports  exportData={exportData} activeTabs={customersTab} csvExport={csvExport} />
                </Grid>
              </Grid>
              <Allcustomers setExportData={setExportData} setCsvExport={setCsvExport} customerSearchValue={customerSearchValue} />
            </TabPanel>

            {/* suspended! */}
            <TabPanel value={"Approval"}>
              <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                <Grid item style={customerstyles.searchDateFilter}  >
                  <SearchFilter placeholder={'Search customer'} setInputValue={setInputValue} />
                  <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                </Grid>
                <Grid item>
                  <Exports exportData={exportData} activeTabs={customersTab} csvExport={csvExport} />
                </Grid>
              </Grid>
              <CustomerAproval setExportData={setExportData} setCsvExport={setCsvExport} customerSearchValue={customerSearchValue} />
            </TabPanel>

            <TabPanel value={"Suspended"}>
              <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                <Grid item style={customerstyles.searchDateFilter}  >
                  <SearchFilter placeholder={'Search customer'} setInputValue={setInputValue} />
                  <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                </Grid>
                <Grid item>
                  <Exports exportData={exportData} activeTabs={customersTab} csvExport={csvExport} />
                </Grid>
              </Grid>
              <SuspendedCustomer setExportData={setExportData} setCsvExport={setCsvExport} customerSearchValue={customerSearchValue} />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default Customerlist;
