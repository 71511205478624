import React , {useEffect , useState} from "react";
import Dash from "../../common/dash";
import {DataGrid} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {Grid , Select , Tab} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import success from '../../common/images/success.png'
import AddIcon from '@mui/icons-material/Add';
import {alpha , styled} from "@mui/material/styles";
import Menu , {MenuProps} from "@mui/material/Menu";
import activateGroup from "../../common/images/activate.svg";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ElIf , If} from "rc-if-else";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import deactivateGroup from "../../common/images/deactivate.svg";
import deactivate from "../../common/images/deactivate.svg"
import Success from "../../common/images/success.png"
import session from '../../common/images/session.svg'
import {SessionModal} from "../Modals/SessionExpiredModal/sessionModal";
import {SuccessModal} from "../Modals/SuccessModal/successModal";
import {QuestionModal} from "../Modals/QuestionModal/questionModal";
import DeactivateRequest from "../Modals/Deactivate/deactivate";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddGroupStyleModal = {
    width : "665px" ,
    height : "630px" ,
    margin : "10% Auto 0 Auto" ,
    padding : "0 0 26.8px" ,
    borderRadius : "15px" ,
    backgroundColor : "#fff" ,
    textAlign : "right" ,
};

const SuccessModalStyle = {
    width : "506px" ,
    height : "506px" ,
    margin : "10% Auto 0 Auto" ,
    padding : "86px 24px 97.7px 61px" ,
    borderRadius : "36px" ,
    boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
    backgroundColor : "#fff"
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu elevation={0} anchorOrigin={{vertical : "bottom" , horizontal : "right"}} transformOrigin={{
        vertical : "top" ,
        horizontal : "right"
    }}{...props}/>))(({theme}) => ({
    "& .MuiPaper-root" : {
        borderRadius : 6 ,
        marginTop : theme.spacing(1) ,
        minWidth : 180 ,
        color : theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300] ,
        boxShadow : "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" ,
        "& .MuiMenu-list" : {padding : "4px 0"} ,
        "& .MuiMenuItem-root" : {
            "& .MuiSvgIcon-root" : {
                fontSize : 18 ,
                color : theme.palette.text.secondary ,
                marginRight : theme.spacing(1.5)
            } ,
            "&:active" : {backgroundColor : alpha(theme.palette.primary.main , theme.palette.action.selectedOpacity)}
        }
    }
}));

// Custom Tab Styling
const AntTabs = styled(TabList)({
    borderBottom : '0px solid #e8e8e8' , '& .MuiTabs-indicator' : {
        backgroundColor : '#ffffff00' ,
    } ,
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
    textTransform : 'none' ,
    minWidth : 0 ,
    [theme.breakpoints.up('sm')] : {
        minWidth : 0 ,
    } ,
    fontWeight : theme.typography.fontWeightRegular ,
    marginRight : theme.spacing(1) ,
    fontSize : "12px" ,
    fontStretch : "normal" ,
    fontStyle : "normal" ,
    lineHeight : "2.75" ,
    letterSpacing : "normal" ,
    textAlign : "left" ,
    color : '#6e7074' ,
    fontFamily : ['Poppins'].join(',') ,
    '&:hover' : {
        color : '#032541' , opacity : 1 ,
    } ,
    '&.Mui-selected' : {
        color : '#dc3545' , fontWeight : 600 ,
    } ,
    '&.Mui-focusVisible' : {
        backgroundColor : 'blue' ,
    } ,
}));

// session Expired
const style = {borderRadius: "36px", boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "660px", height: "570px", p: 4,};

// Conditional Data grid columns
//Active Columns
const activeColumns = [
    {field : 'groupName' , headerName : 'Group Name' , align : 'center' , flex : 1 , headerClassName : 'super-app-theme-header' , headerAlign : 'center' , editable : false} ,
    {field : 'groupType' , headerName : 'Group Type' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'groupDescription' , headerName : 'Description' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'Actions' , headerName : 'Actions' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false ,
        renderCell : (params , event) => {

            //DropDown Handler
            const [anchorEl , setAnchorEl] = React.useState(null);
            const ActionOpen = Boolean(anchorEl);
            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };
            const handleActionClose = () => {
                setAnchorEl(null);
            };

            const {row} = params

            // session Modal Config
            const [sessionOpen, setSessionOpen] = React.useState(false);
            const handleSessionOpen = () => setSessionOpen(true);
            const handleSessionClose = () => setSessionOpen(false);
            const handleLogin = () => {window.location.href = '/logout'}

            // Edit Group Modal Config
            const [open , setOpen] = useState(false);
            const handleClickOpen = () => setOpen(true);
            const handleClose = () => setOpen(false);

            // Deactivate Group Modal Config
            const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
            const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
            const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

            // Success Deactivation Modal Config
            const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
            const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
            const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

            // Success Edit Modal Control
            const [successOpen , setSuccessOpen] = React.useState(false);
            const handleSuccessOpen = () => setSuccessOpen(true);
            const handleSuccessClose = () => setSuccessOpen(false);

            // Data Getters and Setters
            const [groupName , setGroupName] = useState(row.groupName)
            const [createdAt , setCreatedAt] = useState(row.createdAt)
            const [groupType , setGroupType] = useState(row.groupType)
            const [groupDescription , setGroupDescription] = useState(row.groupDescription)

            //Group Update Url
            const updateUrl = baseUrl + '/api/updateGroup/' + row.id
            const deleteUrl = baseUrl + '/api/deleteGroup/' + row.id

            //Update Group Function
            const updateGroup = async (e) => {
                e.preventDefault()
                const editResponse = await fetch(updateUrl , {

                    method : 'PUT' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    } , body : JSON.stringify({
                        groupName : groupName ,
                        groupType : groupType ,
                        groupDescription : groupDescription ,
                        createdAt : createdAt ,
                        requestedBy : localStorage.getItem('username') ,
                        modifiedAt : Date.now()
                    })
                });
                if (editResponse.status === 202) {
                    handleClose()
                    handleSuccessOpen()
                } else if (editResponse === 401 ) {
                    handleSessionOpen()
                }
            }

            // Deactivate Groups Update Function
            const deactivateGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const deactivateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Deactivation" ,
                            requestedBy : localStorage.getItem('username') ,
                            deactivatedAt : Date.now() ,
                            approvedBy : localStorage.getItem('username')
                        })
                    });
                    if (deactivateResponse.status === 202) {
                        DeactivateModalHandleClose()
                        successDeactivationModalHandleOpen()
                    } else if (deactivateResponse === 401 ) {
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            return (
                <div>
                    {/*sessionModal*/}
                    <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                    {/*Edit Group Modal*/}
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description" className="container-fluid">
                        <Box sx={AddGroupStyleModal}>
                            <Box sx={{width : "665px" , height : "90px" , margin : "0 0 8px" , padding : "35px 30px 34px" , borderRadius : "15px" , backgroundColor : "#dc3545" ,}}>
                                <Typography id="modal-modal-title" style={{width : "156px" , height : "21px" , margin : "0 433px 0 0" , fontFamily : "Poppins" , fontSize : "15px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#fff" ,}}>
                                    Add User Groups
                                </Typography>
                            </Box>
                            <Box sx={{width : "605.2px" , height : "495px" , margin : "2.3px 29.8px 0 30px" , padding : "24.7px 54.2px 54.1px 54px" , backgroundColor : "#f5f7ff" ,}}>
                                <form onSubmit={updateGroup}><label style={{width : "153px" , height : "20px" , margin : "0 343px 10px 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "3.14" , letterSpacing : "normal" , textAlign : "left" , color : "#b4bcc4" ,}}>Name
                                </label>
                                    <input value={groupName} onChange={(e) => setGroupName(e.target.value)} maxLength="25" required style={{width : "496px" , height : "39px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff" ,}} type="text"/>
                                    <label style={{width : "153px" , height : "20px" , margin : "0 343px 10px 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "3.14" , letterSpacing : "normal" , textAlign : "left" , color : "#b4bcc4" ,}}>
                                        User Type
                                    </label>
                                    <select value={groupType} onChange={(e) => setGroupType(e.target.value)} style={{width : "496px" , height : "39px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff" ,}}>
                                        <option value="">Select User Type</option>
                                        <option value="Admin">Admin</option>
                                        <option value="User">User</option>
                                        <option value="SuperUser">SuperUser</option>
                                        <option value="Merchant">Merchant</option>
                                    </select>
                                    <br></br>
                                    <label style={{width : "153px" , height : "20px" , margin : "0 343px 10px 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "3.14" , letterSpacing : "normal" , textAlign : "left" , color : "#b4bcc4" ,}}>
                                        Description
                                    </label>
                                    <textarea value={groupDescription} onChange={(e) => setGroupDescription(e.target.value)} maxLength='100' required style={{width : "496px" , height : "136px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff" ,
                                    }} type="text"/>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <Button type="close" onClick={handleClose} style={{width : "90px" , height : "33.1px" , margin : "0 30px 0 0" , padding : "9px 30px 8.1px 29px" , backgroundColor : "#dc3545" , color : "#fff" ,}}>
                                        Close
                                    </Button>
                                    <Button type="submit" style={{width : "90px" , height : "33.1px" , margin : "0 0 0 30px" , padding : "9px 32px 8.1px 31px" , backgroundColor : "#032541" , color : "#fff" ,}}>
                                        Save
                                    </Button>
                                </form>
                            </Box>
                        </Box>
                    </Modal>

                    {/*Deactivate Group Modal*/}
                    <QuestionModal deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} formAction={deactivateGroupUpdate} actionImage={deactivateGroup} action='Deactivate' item={row.groupName} processName={'Deactivate'}/>

                    {/*Success Deactivation Modal*/}
                    <SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Deactivated'} />

                    {/*success Edit*/}
                    <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={row.groupName} successObject={'Group'} successType={'Updated'} />

                    {/*Action Button Menu*/}
                    <Button
                        sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
                        aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
                        aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
                        onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
                    </Button>
                    <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        {/*Conditional Modal Popups*/}
                        {/*Check if is on Active Tab*/}
                        <MenuItem disableRipple onClick={() => {handleClickOpen(); handleActionClose()}}> <EditIcon sx={{color : 'black'}}/>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => {DeactivateModalHandleOpen(); handleActionClose()}} disableRipple> <PersonRemoveIcon/>
                            Deactivate
                        </MenuItem>
                    </StyledMenu>
            </div>
            )
        }
    }]

// New Columns
const newColumns = [    // { field: 'id', headerName: 'ID' },
    {field : 'groupName' , headerName : 'Group Name' , align : 'center' , flex : 1 , headerClassName : 'super-app-theme-header' , headerAlign : 'center' , editable : false} ,
    {field : 'groupType' , headerName : 'Group Type' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'groupDescription' , headerName : 'Description' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'createdAt' , headerName : 'Created At' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'createdBy' , headerName : 'Created By' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'Actions' , headerName : 'Actions' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false ,
        renderCell : (params , event) => {

            //DropDown Handler
            const [anchorEl , setAnchorEl] = React.useState(null);
            const ActionOpen = Boolean(anchorEl);
            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };
            const handleActionClose = () => {
                setAnchorEl(null);
            };

            const {row} = params

            // session Modal Config
            const [sessionOpen, setSessionOpen] = React.useState(false);
            const handleSessionOpen = () => setSessionOpen(true);
            const handleSessionClose = () => setSessionOpen(false);
            const handleLogin = () => {window.location.href = '/logout'}

            // Activate Group Modal Config
            const [ActivateModalOpen , setActivateModalOpen] = useState(false);
            const ActivateModalHandleOpen = () => setActivateModalOpen(true);
            const ActivateModalHandleClose = () => setActivateModalOpen(false);

            // Deactivate Group Modal Config
            const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
            const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
            const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

            // Status Updates Modals
            // Success Activation Modal Config
            const [successActivationModalOpen , setSuccessActivationModalOpen] = useState(false);
            const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
            const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

            // Success Deactivation Modal Config
            const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
            const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
            const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

            //Group Update Url
            const updateUrl = baseUrl + '/api/updateGroup/' + row.id

            // Activate Groups Update Function
            const activateGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const activateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Active" ,
                            modifiedAt : Date.now() ,
                            requestedBy : localStorage.getItem('username')
                        })
                    });
                    if (activateResponse.status === 202) {
                        ActivateModalHandleClose()
                        successActivationModalHandleOpen()
                    } else if (activateResponse.status === 401 ){
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            // Deactivate Groups Update Function
            const deactivateGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const deactivateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Deactivation" ,
                            requestedBy : localStorage.getItem('username') ,
                            deactivatedAt : Date.now() ,
                            approvedBy : localStorage.getItem('username')
                        })
                    });
                    if (deactivateResponse.status === 202) {
                        DeactivateModalHandleClose()
                        successDeactivationModalHandleOpen()
                    }  else if (deactivateResponse.status === 401 ){
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            return (
                <div>
                    {/*sessionModal*/}
                    <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                    {/*Activate Group Modal*/}
                    <QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateGroupUpdate} actionImage={activateGroup} action='Activate' item={row.groupName} processName={'Activate'}/>

                    {/*Deactivate Group Modal*/}
                    <QuestionModal deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} formAction={deactivateGroupUpdate} actionImage={deactivateGroup} action='Deactivate' item={row.groupName} processName={'Deactivate'}/>

                    {/*Status Update Modals*/}
                    {/*Success Deactivation Modal*/}
                    <SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Deactivated'} />

                    {/*Success Activation Modal*/}
                    <SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Activated'} />

                    {/*Action Button Menu*/}
                    <Button
                        sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
                        aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
                        aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
                        onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
                    </Button>
                    <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
                                anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

                        <MenuItem disableRipple onClick={() => {
                            ActivateModalHandleOpen()
                            handleActionClose()
                        }}>
                            <EditIcon sx={{color : 'black'}}/>
                            Approve
                        </MenuItem>
                        <MenuItem onClick={() => {
                            DeactivateModalHandleOpen()
                            handleActionClose()
                        }} disableRipple>
                            <PersonRemoveIcon/>
                            Decline
                        </MenuItem>
                    </StyledMenu>
                </div>
            )
        }
    }]

// Edited Columns
const editedColumns = [    // { field: 'id', headerName: 'ID' },
    {
        field : 'groupName' ,
        headerName : 'Group Name' ,
        align : 'center' ,
        flex : 1 ,
        headerClassName : 'super-app-theme-header' ,
        headerAlign : 'center' ,
        editable : false
    } , {
        field : 'createdAt' ,
        headerName : 'Created At' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false
    } , {
        field : 'groupType' ,
        headerName : 'Group Type' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false
    } , {
        field : 'groupDescription' ,
        headerName : 'Description' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false
    } , {
        field : 'modifiedBy' ,
        headerName : 'Edited By' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false
    } , {
        field : 'modifiedAt' ,
        headerName : 'Date Modified' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false
    } , {
        field : 'businessName' ,
        headerName : 'Business Name' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false
    } ,

    // Action Button.
    {
        field : 'Actions' ,
        headerName : 'Actions' ,
        flex : 1 ,
        headerAlign : 'center' ,
        align : 'center' ,
        editable : false ,
        renderCell : (params , event) => {

            //DropDown Handler
            const [anchorEl , setAnchorEl] = React.useState(null);
            const ActionOpen = Boolean(anchorEl);
            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };
            const handleActionClose = () => {
                setAnchorEl(null);
            };

            const {row} = params

// session Modal Config
const [sessionOpen, setSessionOpen] = React.useState(false);
const handleSessionOpen = () => setSessionOpen(true);
const handleSessionClose = () => setSessionOpen(false);
const handleLogin = () => {window.location.href = '/logout'}

            // Activate Group Modal Config
            const [ActivateModalOpen , setActivateModalOpen] = useState(false);
            const ActivateModalHandleOpen = () => setActivateModalOpen(true);
            const ActivateModalHandleClose = () => setActivateModalOpen(false);

            // Edit Group Modal Config
            const [open , setOpen] = useState(false);
            const handleClickOpen = () => setOpen(true);
            const handleClose = () => setOpen(false);

            // Deactivate Group Modal Config
            const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
            const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
            const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

            // DeactivateRequest Group Modal Config
            const [deactivateReqModalOpen , setDeactivateReqModalOpen] = useState(false);
            const DeactivateReqModalHandleOpen = () => setDeactivateReqModalOpen(true);
            const DeactivateReqModalHandleClose = () => setDeactivateReqModalOpen(false);

            // Delete Group Modal Config
            const [deleteModalOpen , setDeleteModalOpen] = useState(false);
            const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
            const DeleteModalHandleClose = () => setDeleteModalOpen(false);


            // Status Updates Modals
            // Success Activation Modal Config
            const [successActivationModalOpen , setSuccessActivationModalOpen] = useState(false);
            const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
            const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

            // Success Deactivation Modal Config
            const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
            const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
            const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

            // Success Deletion Modal Config
            const [successDeletionModalOpen , setSuccessDeletionModalOpen] = useState(false);
            const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
            const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);

            // Data Getters and Setters
            const [groupName , setGroupName] = useState(row.groupName)
            const [createdAt , setCreatedAt] = useState(row.createdAt)
            const [groupType , setGroupType] = useState(row.groupType)
            const [groupDescription , setGroupDescription] = useState(row.groupDescription)

            //Group Update Url
            const updateUrl = baseUrl + '/api/updateGroup/' + row.id
            const deleteUrl = baseUrl + '/api/deleteGroup/' + row.id

            //Update Group Function
            //Update Group Function
            const updateGroup = async () => {

                const response = await fetch(updateUrl , {

                    method : 'PUT' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    } , body : JSON.stringify({
                        groupName : groupName ,
                        groupType : groupType ,
                        groupDescription : groupDescription ,
                        createdAt : createdAt ,
                        requestedBy : localStorage.getItem('username') ,
                        modifiedAt : Date.now()
                    })
                })
                const data = await response.json();
                if (response.status === 202) {
                    console.log("Group Updated");
                } else if (response.status === 401) {
                    alert("No Token Available");
                } else {
                    alert("Error Updating Group")
                }
            }

            // Activate Groups Update Function
            const activateGroupUpdate = async () => {
                try {
                    const activateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Active" ,
                            modifiedAt : Date.now() ,
                            requestedBy : localStorage.getItem('username')
                        })
                    })
                } catch (e) {
                    console.log(e)
                }
            }

            // Deactivate Groups Update Function
            const deactivateGroupUpdate = async () => {
                try {
                    const deactivateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Deactivation" ,
                            requestedBy : localStorage.getItem('username') ,
                            deactivatedAt : Date.now() ,
                            approvedBy : localStorage.getItem('username')
                        })
                    })
                } catch (e) {
                    console.log(e)
                }
            }

            // Deactivate Request Groups Update Function
            const deactivateReqGroupUpdate = async () => {
                try {
                    const deactivateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Inactive" ,
                            deactivatedAt : Date.now() ,
                            approvedBy : localStorage.getItem('username')
                        })
                    })
                } catch (e) {
                    console.log(e)
                }
            }

            // Delete Groups Update Function
            const deleteGroup = async () => {
                try {
                    const deleteResponse = await fetch(deleteUrl , {
                        method : 'DELETE' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } ,
                    })
                } catch (e) {
                    console.log(e)
                }
            }
            return (<div>

{/*session expired modal*/}
<Modal
    open={sessionOpen} onClose={handleSessionClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={style}>
        <div style={{width: "207px", height: "206px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
            <img src={session}/>
        </div>
        <div style={{width: "506px", height: "89px", margin: "26px 0 3px 47px", padding: "35px 2px 9px 16px", fontFamily: "Poppins", fontSize: "30px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
            <span>
                Your Session has expired and you have been logged out.
            </span>
        </div>
        <div style={{width: "500px", height: "61.7px", margin: "65px 52.9px 0 75px"}}>
            <Button onClick={handleSessionClose} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#032541",width: "146.8px", height: "61.7px", margin: "0 62.2px 0 0", padding: "19.1px 44.6px 18.5px 41.1px", border: "solid 1px #dc3545"}}>
                Cancel
            </Button>
            <Button onClick={handleLogin} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#fff",width: "190.1px", height: "61.7px", margin: "0 0 0 62.2px", padding: "19px 32.1px 18.7px 33px", backgroundColor: "#032541"}} >
                Go to Login
            </Button>
        </div>
    </Box>
</Modal>
                    {/*Edit Group Modal*/}
                    <Modal className='container container-fluid' open={open} onClose={handleClose}
                           aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <div style={{
                            width : "553px" ,
                            height : "618px" ,
                            margin : "9% auto 0px auto" ,
                            borderRadius : "15px" ,
                            backgroundColor : "#fff"
                        }}>
                            <div style={{
                                width : "553x" ,
                                height : "90px" ,
                                margin : "0 0 9px" ,
                                padding : "36px 30px 33px" ,
                                borderRadius : "16px" ,
                                backgroundColor : "#dc3545"
                            }}>
                                <label>
                                    Edit a Group
                                </label>
                            </div>
                            {/*Content Group and Form*/}
                            <div align='center'
                                 style={{width : "404px" , height : "414.1px" , margin : "29px 60px 41.9px 70px"}}>
                                <form onSubmit={updateGroup}>
                                    {/*Serial No.*/}
                                    <label style={{
                                        height : "20px" ,
                                        margin : "0 340px 10px 0" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "14px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "3.14" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#6e7074"
                                    }}>
                                        Serial No.
                                    </label>
                                    <input value={groupName} onChange={(e) => setGroupName(e.target.value)}
                                           placeholder="Serial" style={{
                                        width : "404px" ,
                                        height : "39px" ,
                                        margin : "10px 0 0" ,
                                        objectFit : "contain" ,
                                        borderRadius : "4px" ,
                                        border : "solid 1px #dfdede" ,
                                        backgroundColor : "#fff"
                                    }}/>

                                    {/*POS createdAt*/}
                                    <label style={{
                                        width : "100px" ,
                                        height : "20px" ,
                                        margin : "0 328px 10px 0" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "14px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "3.14" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#6e7074"
                                    }}>
                                        POS createdAt.
                                    </label>
                                    <input value={createdAt} onChange={(e) => setCreatedAt(e.target.value)}
                                           placeholder="createdAt" style={{
                                        width : "404px" ,
                                        height : "39px" ,
                                        margin : "10px 0 0" ,
                                        objectFit : "contain" ,
                                        borderRadius : "4px" ,
                                        border : "solid 1px #dfdede" ,
                                        backgroundColor : "#fff"
                                    }}/>

                                    {/*PayBill*/}
                                    <label style={{
                                        width : "110px" ,
                                        height : "20px" ,
                                        margin : "0 304px 10px 0" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "14px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "3.14" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#6e7074"
                                    }}>
                                        Pay Bill/Till No.
                                    </label>
                                    <input value={groupType} onChange={(e) => setGroupType(e.target.value)}
                                           placeholder="groupType" style={{
                                        width : "404px" ,
                                        height : "39px" ,
                                        margin : "10px 0 0" ,
                                        objectFit : "contain" ,
                                        borderRadius : "4px" ,
                                        border : "solid 1px #dfdede" ,
                                        backgroundColor : "#fff"
                                    }}/>

                                    {/*Description*/}
                                    <label style={{
                                        width : "78px" ,
                                        height : "20px" ,
                                        margin : "0 324px 10px 0" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "14px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "3.14" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#6e7074"
                                    }}>
                                        Description
                                    </label>
                                    <input value={groupDescription}
                                           onChange={(e) => setGroupDescription(e.target.value)}
                                           placeholder="Description" style={{
                                        width : "404px" ,
                                        height : "39px" ,
                                        margin : "10px 0 0" ,
                                        objectFit : "contain" ,
                                        borderRadius : "4px" ,
                                        border : "solid 1px #dfdede" ,
                                        backgroundColor : "#fff"
                                    }}/>

                                    {/*Buttons*/}
                                    <div align='right'>
                                        {/*  Close  */}
                                        <Button onClick={handleClose} variant='outlined' style={{
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            textAlign : "left" ,
                                            color : "#032541" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 30px 0 0" ,
                                            padding : "9px 29px 8.1px 30px" ,
                                            border : "solid 1px #032541"
                                        }}>
                                            Close
                                        </Button>
                                        {/*  save  */}
                                        <Button type='submit' variant='standard' style={{
                                            color : '#fff' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 0 0 30px" ,
                                            padding : "9px 32px 8.1px 31px" ,
                                            backgroundColor : "#032541"
                                        }}>
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>

                    {/*Activate Group Modal*/}
                    <Modal centered align={'center'} className='container container-fluid' open={ActivateModalOpen}
                           onClose={ActivateModalHandleClose} aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description">
                        <div style={{
                            width : "553px" ,
                            height : "306px" ,
                            margin : "10% 23px 8px 39px" ,
                            padding : "0 0 57.9px" ,
                            borderRadius : "15px" ,
                            backgroundColor : "#fff"
                        }}>
                            <div style={{
                                width : "553px" ,
                                height : "90px" ,
                                margin : "0 0 50px" ,
                                padding : "35px 36px" ,
                                borderRadius : "16px" ,
                                backgroundColor : "#dc3545"
                            }}>
                                <label style={{
                                    width : "140px" ,
                                    height : "20px" ,
                                    margin : "0 325px 0 0" ,
                                    fontFamily : "Poppins" ,
                                    fontSize : "14px" ,
                                    fontWeight : "600" ,
                                    fontStretch : "normal" ,
                                    fontStyle : "normal" ,
                                    lineHeight : "normal" ,
                                    letterSpacing : "normal" ,
                                    textAlign : "left" ,
                                    color : "#fff"
                                }}>
                                    Activate Group
                                </label>
                            </div>
                            <div>
                                <form onSubmit={activateGroupUpdate}>
                                    <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}
                                         src={activateGroup} alt={'activate Group'}/>
                                    <label style={{
                                        height : "24px" ,
                                        margin : "0 1.1px 0 14px" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "17px" ,
                                        fontWeight : "500" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "1.47" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#032541"
                                    }}>
                                        Do you want to Activate <b>{row.groupName}?</b>
                                    </label>
                                    <div align='right'>
                                        <Button onClick={ActivateModalHandleClose} variant='outlined' style={{
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            textAlign : "left" ,
                                            color : "#dc3545" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 30px 0 0" ,
                                            padding : "9px 29px 8.1px 30px" ,
                                            border : "solid 1px #dc3545"
                                        }}>
                                            Cancel
                                        </Button>
                                        {/*  save  */}
                                        <Button type='submit' variant='standard' style={{
                                            color : '#fff' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 16px 0 30px" ,
                                            padding : "9px 32px 8.1px 31px" ,
                                            backgroundColor : "#032541"
                                        }}>
                                            Activate
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>

                    {/*Deactivate Group Modal*/}
                    <Modal centered align={'center'} className='container container-fluid' open={deactivateModalOpen}
                           onClose={DeactivateModalHandleClose} aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description">
                        <div style={{
                            width : "553px" ,
                            height : "306px" ,
                            margin : "10% 23px 8px 39px" ,
                            padding : "0 0 57.9px" ,
                            borderRadius : "15px" ,
                            backgroundColor : "#fff"
                        }}>
                            <div style={{
                                width : "553px" ,
                                height : "90px" ,
                                margin : "0 0 50px" ,
                                padding : "35px 36px" ,
                                borderRadius : "16px" ,
                                backgroundColor : "#dc3545"
                            }}>
                                <label style={{
                                    height : "20px" ,
                                    margin : "0 325px 0 0" ,
                                    fontFamily : "Poppins" ,
                                    fontSize : "14px" ,
                                    fontWeight : "600" ,
                                    fontStretch : "normal" ,
                                    fontStyle : "normal" ,
                                    lineHeight : "normal" ,
                                    letterSpacing : "normal" ,
                                    textAlign : "left" ,
                                    color : "#fff"
                                }}>
                                    Deactivate Group
                                </label>
                            </div>
                            <div>
                                <form onSubmit={deactivateGroupUpdate}>
                                    <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}
                                         src={deactivateGroup} alt={'activate Group'}/>
                                    <label style={{
                                        height : "24px" ,
                                        margin : "0 1.1px 0 14px" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "17px" ,
                                        fontWeight : "500" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "1.47" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#032541"
                                    }}>
                                        Do you want to Deactivate <b>{row.groupName}?</b>
                                    </label>
                                    <div align='right'>
                                        <Button onClick={DeactivateModalHandleClose} variant='outlined' style={{
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            textAlign : "left" ,
                                            color : "#dc3545" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 30px 0 0" ,
                                            padding : "9px 29px 8.1px 30px" ,
                                            border : "solid 1px #dc3545"
                                        }}>
                                            Cancel
                                        </Button>
                                        {/*  save  */}
                                        <Button type='submit' variant='standard' style={{
                                            color : '#fff' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 16px 0 30px" ,
                                            padding : "9px 32px 8.1px 31px" ,
                                            backgroundColor : "#032541"
                                        }}>
                                            Deactivate
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>

                    {/*Deactivation Request Group Modal*/}
                    <Modal centered align={'center'} className='container container-fluid' open={deactivateReqModalOpen}
                           onClose={DeactivateReqModalHandleClose} aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description">
                        <div style={{
                            width : "665px" ,
                            height : "604px" ,
                            margin : "10% 47px 5px 96px" ,
                            padding : "0 0 9px" ,
                            borderRadius : "15px" ,
                            backgroundColor : "#fff"
                        }}>
                            <div style={{
                                width : "665px" ,
                                height : "90px" ,
                                margin : "0 0 3px" ,
                                padding : "35px 30px 34px" ,
                                borderRadius : "16px" ,
                                backgroundColor : "#dc3545"
                            }}>
                                <label style={{
                                    height : "20px" ,
                                    margin : "0 325px 0 0" ,
                                    fontFamily : "Poppins" ,
                                    fontSize : "14px" ,
                                    fontWeight : "600" ,
                                    fontStretch : "normal" ,
                                    fontStyle : "normal" ,
                                    lineHeight : "normal" ,
                                    letterSpacing : "normal" ,
                                    textAlign : "left" ,
                                    color : "#fff"
                                }}>
                                    Deactivate Group User Group
                                </label>
                            </div>
                            <form onSubmit={deactivateReqGroupUpdate}>
                                <div>
                                    <div>
                                        <label style={{
                                            eight : "44px" ,
                                            margin : "48px 106px 37px 84px" ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "15px" ,
                                            fontWeight : "normal" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "normal" ,
                                            textAlign : "left" ,
                                            color : "#032541"
                                        }}>
                                            To deactivate Group <b>{row.groupName}</b>, Select the Reason and
                                            describe why you are deactivating.
                                        </label>
                                    </div>
                                    <label style={{
                                        width : "41px" ,
                                        height : "16px" ,
                                        margin : "0px 537px 10px 84px" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "11px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "4" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#6e7074"
                                    }}>
                                        Reason
                                    </label>
                                <Select value={reasonCode} onChange={(e) => setReasonCode(e.target.value)} defaultValue="" id="grouped-select" label="Grouping" sx={{fontFamily:"Poppins", fontSize:'12px', textAlign:'left', width : "496px" , height : "39px" , margin : "10px 85px 20px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    {reasonData.map((reason) => (<MenuItem sx={{fontFamily:"Poppins", fontSize:'12px', textAlign:'left',}} key={reason.code} value={reason.code}>{reason.code} {reason.name}</MenuItem>))}
                                    </Select>
                                    <label style={{
                                        width : "62px" ,
                                        height : "16px" ,
                                        margin : "0px 537px 10px 84px" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "11px" ,
                                        fontWeight : "normal" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "4" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#6e7074"
                                    }}>
                                        Description
                                    </label>
                                    <textarea id="w3review" name="w3review" rows="4" cols="50"
                                              placeholder='Enter Description' style={{
                                        width : "505px" ,
                                        height : "106px" ,
                                        margin : "10px 76px 43px 84px" ,
                                        objectFit : "contain" ,
                                        borderRadius : "4px" ,
                                        border : "solid 1px #dfdede" ,
                                        backgroundColor : "#fff"
                                    }}></textarea>
                                    <div align='right'>
                                        <Button onClick={DeactivateReqModalHandleClose} variant='outlined' style={{
                                            fontSize : '11px' ,
                                            color : "#032541" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "0px 30px 62.9px 13px" ,
                                            padding : "9px 29px 8.1px 30px" ,
                                            border : "solid 1px #032541"
                                        }}>
                                            Cancel
                                        </Button>
                                        {/*  save  */}
                                        <Button type='submit' variant='standard' style={{
                                            fontSize : '11px' ,
                                            color : '#fff' ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "0px 76px 62.9px 30px" ,
                                            padding : "9px 15px 8.1px 15px" ,
                                            backgroundColor : "#dc3545"
                                        }}>
                                            Deactivate
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>

                    {/*Delete Group Modal*/}
                    <Modal centered align={'center'} className='container container-fluid' open={deleteModalOpen}
                           onClose={DeleteModalHandleClose} aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description">
                        <div style={{
                            width : "553px" ,
                            height : "306px" ,
                            margin : "10% 23px 8px 39px" ,
                            padding : "0 0 57.9px" ,
                            borderRadius : "15px" ,
                            backgroundColor : "#fff"
                        }}>
                            <div style={{
                                width : "553px" ,
                                height : "90px" ,
                                margin : "0 0 50px" ,
                                padding : "35px 36px" ,
                                borderRadius : "16px" ,
                                backgroundColor : "#dc3545"
                            }}>
                                <label style={{
                                    height : "20px" ,
                                    margin : "0 325px 0 0" ,
                                    fontFamily : "Poppins" ,
                                    fontSize : "14px" ,
                                    fontWeight : "600" ,
                                    fontStretch : "normal" ,
                                    fontStyle : "normal" ,
                                    lineHeight : "normal" ,
                                    letterSpacing : "normal" ,
                                    textAlign : "left" ,
                                    color : "#fff"
                                }}>
                                    Delete Group
                                </label>
                            </div>
                            <div>
                                <form onSubmit={deleteGroup}>
                                    <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}
                                         src={deactivateGroup} alt={'activate Group'}/>
                                    <label style={{
                                        height : "24px" ,
                                        margin : "0 1.1px 0 14px" ,
                                        fontFamily : "Poppins" ,
                                        fontSize : "17px" ,
                                        fontWeight : "500" ,
                                        fontStretch : "normal" ,
                                        fontStyle : "normal" ,
                                        lineHeight : "1.47" ,
                                        letterSpacing : "normal" ,
                                        textAlign : "left" ,
                                        color : "#032541"
                                    }}>
                                        Do you want to delete <b>{row.groupName}?</b>
                                    </label>
                                    <div align='right'>
                                        <Button onClick={DeleteModalHandleClose} variant='outlined' style={{
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            textAlign : "left" ,
                                            color : "#032541" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 30px 0 0" ,
                                            padding : "9px 29px 8.1px 30px" ,
                                            border : "solid 1px #032541"
                                        }}>
                                            Cancel
                                        </Button>
                                        {/*  save  */}
                                        <Button type='submit' variant='standard' style={{
                                            color : '#fff' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "11px" ,
                                            width : "90px" ,
                                            height : "33.1px" ,
                                            margin : "30px 16px 0 30px" ,
                                            padding : "9px 32px 8.1px 31px" ,
                                            backgroundColor : "#dc3545"
                                        }}>
                                            Delete
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>

                    {/*Status Update Modals*/}
                    {/*Success Deactivation Modal*/}
                    <Modal centered align={'center'} className='container container-fluid'
                           open={successDeactivationModalOpen} onClose={successDeactivationModalHandleClose}
                           aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <div style={{
                            width : "506px" ,
                            height : "506px" ,
                            margin : "10% 16px 23px 45px" ,
                            padding : "87px 75px 92.7px 34px" ,
                            borderRadius : "36px" ,
                            boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
                            backgroundColor : "#fff"
                        }}>
                            <img src={success} alt={'success deactivation'}/>
                            <label style={{
                                width : "356px" ,
                                height : "65px" ,
                                margin : "37.8px 0 0" ,
                                fontFamily : "Poppins" ,
                                fontSize : "25px" ,
                                fontWeight : "normal" ,
                                fontStretch : "normal" ,
                                fontStyle : "normal" ,
                                lineHeight : "1.2" ,
                                letterSpacing : "normal" ,
                                textAlign : "center" ,
                                color : "#6e7074"
                            }}>
                                <b>{row.groupName}</b> Deactivated
                                successfully.
                            </label>
                            <Button onClick={successDeactivationModalHandleClose} style={{
                                fontFamily : "Poppins" ,
                                fontSize : "25px" ,
                                fontWeight : "600" ,
                                color : "#fff" ,
                                width : "124.5px" ,
                                height : "52.3px" ,
                                padding : "8px 44.5px 9.3px 35px" ,
                                backgroundColor : "#032541"
                            }}>
                                OK
                            </Button>
                        </div>
                    </Modal>

                    {/*Success Deletion Modal*/}
                    <Modal centered align={'center'} className='container container-fluid'
                           open={successDeletionModalOpen} onClose={successDeletionModalHandleClose}
                           aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <div style={{
                            width : "506px" ,
                            height : "506px" ,
                            margin : "10% 16px 23px 45px" ,
                            padding : "87px 75px 92.7px 34px" ,
                            borderRadius : "36px" ,
                            boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
                            backgroundColor : "#fff"
                        }}>
                            <img src={success} alt={'success deletion'}/>
                            <label style={{
                                width : "356px" ,
                                height : "65px" ,
                                margin : "37.8px 0 0" ,
                                fontFamily : "Poppins" ,
                                fontSize : "25px" ,
                                fontWeight : "normal" ,
                                fontStretch : "normal" ,
                                fontStyle : "normal" ,
                                lineHeight : "1.2" ,
                                letterSpacing : "normal" ,
                                textAlign : "center" ,
                                color : "#6e7074"
                            }}>
                                <b>{row.groupName}</b> Deleted
                                successfully.
                            </label>
                            <Button onClick={successDeletionModalHandleClose} style={{
                                fontFamily : "Poppins" ,
                                fontSize : "25px" ,
                                fontWeight : "600" ,
                                color : "#fff" ,
                                width : "124.5px" ,
                                height : "52.3px" ,
                                padding : "8px 44.5px 9.3px 35px" ,
                                backgroundColor : "#032541"
                            }}>
                                OK
                            </Button>
                        </div>
                    </Modal>

                    {/*Success Activation Modal*/}
                    <Modal centered align={'center'} className='container container-fluid'
                           open={successActivationModalOpen} onClose={successActivationModalHandleClose}
                           aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <div style={{
                            width : "506px" ,
                            height : "506px" ,
                            margin : "10% 16px 23px 45px" ,
                            padding : "87px 75px 92.7px 34px" ,
                            borderRadius : "36px" ,
                            boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
                            backgroundColor : "#fff"
                        }}>
                            <img src={success} alt={'success deletion'}/>
                            <label style={{
                                width : "356px" ,
                                height : "65px" ,
                                margin : "37.8px 0 0" ,
                                fontFamily : "Poppins" ,
                                fontSize : "25px" ,
                                fontWeight : "normal" ,
                                fontStretch : "normal" ,
                                fontStyle : "normal" ,
                                lineHeight : "1.2" ,
                                letterSpacing : "normal" ,
                                textAlign : "center" ,
                                color : "#6e7074"
                            }}>
                                <b>{row.groupName}</b> Activated
                                successfully.
                            </label>
                            <Button onClick={successActivationModalHandleClose} style={{
                                fontFamily : "Poppins" ,
                                fontSize : "25px" ,
                                fontWeight : "600" ,
                                color : "#fff" ,
                                width : "124.5px" ,
                                height : "52.3px" ,
                                padding : "8px 44.5px 9.3px 35px" ,
                                backgroundColor : "#032541"
                            }}>
                                OK
                            </Button>
                        </div>
                    </Modal>


                    {/*Action Button Menu*/}
                    <Button
                        sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
                        aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
                        aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
                        onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
                    </Button>
                    <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
                                anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

                        {/*Conditional Modal Popups*/}
                        {/*Check if is on Active Tab*/}
                        <If condition={localStorage.getItem("tabValue") === 'Active'}>
                            <MenuItem disableRipple onClick={() => {
                                handleClickOpen()
                                handleActionClose()
                            }}>
                                <EditIcon sx={{color : 'black'}}/>
                                Edit
                            </MenuItem>
                            <MenuItem onClick={() => {
                                DeactivateModalHandleOpen()
                                handleActionClose()
                            }} disableRipple>
                                <PersonRemoveIcon/>
                                Deactivate
                            </MenuItem>

                            {/*Check if is on New Tab*/}
                            <ElIf condition={localStorage.getItem('tabValue') === 'New'}>
                                <MenuItem disableRipple onClick={() => {
                                    ActivateModalHandleOpen()
                                    handleActionClose()
                                }}>
                                    <EditIcon sx={{color : 'black'}}/>
                                    Approve
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    DeactivateModalHandleOpen()
                                    handleActionClose()
                                }} disableRipple>
                                    <PersonRemoveIcon/>
                                    Decline
                                </MenuItem>
                            </ElIf>

                            {/*Check if is on Inactive Tab*/}
                            <ElIf condition={localStorage.getItem('tabValue') === 'Inactive'}>
                                <MenuItem disableRipple onClick={() => {
                                    ActivateModalHandleOpen()
                                    handleActionClose()
                                }}>
                                    <EditIcon sx={{color : 'black'}}/>
                                    Activate
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    DeleteModalHandleOpen()
                                    handleActionClose()
                                }} disableRipple>
                                    <PersonRemoveIcon/>
                                    Delete
                                </MenuItem>
                            </ElIf>

                            {/*Check if is on Pending Tab*/}
                            <ElIf condition={localStorage.getItem('tabValue') === 'Pending'}>
                                <MenuItem disableRipple onClick={() => {
                                    handleClickOpen()
                                    handleActionClose()
                                }}>
                                    <EditIcon sx={{color : 'black'}}/>
                                    Activate
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleActionClose()
                                }} disableRipple>
                                    <PersonRemoveIcon/>
                                    Delete
                                </MenuItem>
                            </ElIf>

                            {/*Check if is on Deactivation Tab*/}
                            <ElIf condition={localStorage.getItem('tabValue') === 'Deactivation'}>
                                <MenuItem disableRipple onClick={() => {
                                    DeactivateReqModalHandleOpen()
                                    handleActionClose()
                                }}>
                                    <EditIcon sx={{color : 'black'}}/>
                                    Approve
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    ActivateModalHandleOpen()
                                    handleActionClose()
                                }} disableRipple>
                                    <PersonRemoveIcon/>
                                    Decline
                                </MenuItem>
                            </ElIf>
                        </If>
                    </StyledMenu>
                </div>)
        }
    }]

// Deactivation request Columns
const deactivationColumns = [
    {field : 'groupName' , headerName : 'Group Name' , align : 'center' , flex : 1 , headerClassName : 'super-app-theme-header' , headerAlign : 'center' , editable : false} ,
    {field : 'createdAt' , headerName : 'Created At' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'groupType' , headerName : 'Group Type' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'groupDescription' , headerName : 'Description' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'requestedBy' , headerName : 'Requested By' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'deactivatedAt' , headerName : 'Date Deactivated' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'businessName' , headerName : 'Business Name' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'Actions' , headerName : 'Actions' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false ,
        renderCell : (params , event) => {

            //DropDown Handler
            const [anchorEl , setAnchorEl] = React.useState(null);
            const ActionOpen = Boolean(anchorEl);
            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };
            const handleActionClose = () => {
                setAnchorEl(null);
            };

            const {row} = params

            // session Modal Config
            const [sessionOpen, setSessionOpen] = React.useState(false);
            const handleSessionOpen = () => setSessionOpen(true);
            const handleSessionClose = () => setSessionOpen(false);
            const handleLogin = () => {window.location.href = '/logout'}

            // Activate Group Modal Config
            const [ActivateModalOpen , setActivateModalOpen] = useState(false);
            const ActivateModalHandleOpen = () => setActivateModalOpen(true);
            const ActivateModalHandleClose = () => setActivateModalOpen(false);

            // Deactivate Group Modal Config
            const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
            const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
            const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

            // DeactivateRequest Group Modal Config
            const [deactivateReqModalOpen , setDeactivateReqModalOpen] = useState(false);
            const DeactivateReqModalHandleOpen = () => setDeactivateReqModalOpen(true);
            const DeactivateReqModalHandleClose = () => setDeactivateReqModalOpen(false);

            // Status Updates Modals
            // Success Activation Modal Config
            const [successActivationModalOpen , setSuccessActivationModalOpen] = useState(false);
            const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
            const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

            // Success Deactivation Modal Config
            const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
            const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
            const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

            //value Setters and Getters
            const [reasonData, setReasonData] = useState([])
            const [reasonCode, setReasonCode] = useState('')
            const [reasonDescription, setReasonDescription] = useState('')

            //Group Update Url
            const reasonUrl = baseUrl + '/api/reasons'
            const updateUrl = baseUrl + '/api/updateGroup/' + row.id

            // Activate Groups Update Function
            const activateGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const activateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Active" ,
                            modifiedAt : Date.now() ,
                            requestedBy : localStorage.getItem('username')
                        })
                    });
                    if (activateResponse.status === 202) {
                        ActivateModalHandleClose()
                        successActivationModalHandleOpen()
                    } else if (activateResponse.status === 401 ){
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            // Deactivate Groups Update Function
            const deactivateGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const deactivateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Deactivation" ,
                            requestedBy : localStorage.getItem('username') ,
                            deactivatedAt : Date.now() ,
                            approvedBy : localStorage.getItem('username')
                        })
                    });
                    if (deactivateResponse.status === 202) {
                        DeactivateModalHandleClose()
                        successDeactivationModalHandleOpen()
                    } else if (deactivateResponse.status === 401 ){
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            // Deactivate Request Groups Update Function
            const deactivateReqGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const deactivateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Inactive" ,
                            deactivationCode : reasonCode,
                            deactivationDescription : reasonDescription,
                            deactivatedAt : Date.now() ,
                            approvedBy : localStorage.getItem('username')
                        })
                    });
                    if (deactivateResponse.status === 202) {
                        DeactivateReqModalHandleClose()
                        successDeactivationModalHandleOpen()
                    } else if (deactivateResponse.status === 401 ){
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            // Getting Reason Codes
            const getReasonCodes = async() => {
                try {
                    const response = await fetch(reasonUrl, {
                        method:"GET",
                        headers : {"Content-Type" : "application/json" , "Accept" : "application/json" , "X-Authorization" : localStorage.getItem('X-Authorization')}});
                    const data = await response.json()
                    if (response.status === 200) {setReasonData(data.reasons)}
                    else if (response.status === 401) {handleSessionOpen()}
                } catch (e) {console.log(e)}
            };useEffect(() => {
                getReasonCodes();
            },[])
            return (<div>
                {/*sessionModal*/}
                <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                {/*Activate Group Modal*/}
                <QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateGroupUpdate} actionImage={activateGroup} action='Activate' item={row.groupName} processName={'Activate'}/>

                {/*Deactivate Group Modal*/}
                <QuestionModal deactivateModalOpen={deactivateModalOpen} onClose={deactivateModalOpen} formAction={deactivateGroup} actionImage={deactivateGroup} action='Deactivate' item={row.groupName} processName={'Deactivate'}/>

                {/*Deactivate Group*/}
                {/*<DeactivateRequest code={reasonCode} description={reasonDescription} formAction={deactivateReqGroupUpdate} modalOpen={deactivateReqModalOpen} onClose={DeactivateReqModalHandleClose} data={reasonData} target={'Group'} object={row.groupName} action={'Deactivate'} />*/}

                {/*Deactivation Request Group Modal*/}
                <Modal centered align={'center'} className='container container-fluid' open={deactivateReqModalOpen}
                       onClose={DeactivateReqModalHandleClose} aria-labelledby="modal-modal-title"
                       aria-describedby="modal-modal-description">
                    <div style={{width : "665px" , height : "604px" , margin : "10% 47px 5px 96px" , padding : "0 0 9px" , borderRadius : "15px" , backgroundColor : "#fff"}}>
                        <div style={{width : "665px" , height : "90px" , margin : "0 0 3px" , padding : "35px 30px 34px" , borderRadius : "16px" , backgroundColor : "#dc3545"}}>
                            <label style={{height : "20px" , margin : "0 325px 0 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "600" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#fff"}}>
                                Deactivate Group
                            </label>
                        </div>
                        <form onSubmit={deactivateReqGroupUpdate}>
                            <div>
                                <div>
                                    <label style={{eight : "44px" , margin : "48px 106px 37px 84px" , fontFamily : "Poppins" , fontSize : "15px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#032541"}}>
                                        To deactivate Group <b>{row.groupName}</b>, Select the Reason and
                                        describe why you are deactivating.
                                    </label>
                                </div>
                                <label style={{width : "41px" , height : "16px" , margin : "0px 537px 10px 84px" , fontFamily : "Poppins" , fontSize : "11px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "4" , letterSpacing : "normal" , textAlign : "left" , color : "#6e7074"}}>
                                    Reason
                                </label>
                                <Select value={reasonCode} onChange={(e) => setReasonCode(e.target.value)} defaultValue="" id="grouped-select" label="Grouping" sx={{fontFamily:"Poppins", fontSize:'12px', textAlign:'left', width : "496px" , height : "39px" , margin : "10px 85px 20px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>
                                    <MenuItem sx={{fontFamily:"Poppins", fontSize:'12px', textAlign:'left',}} value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {reasonData.map((reason) => (<MenuItem sx={{fontFamily:"Poppins", fontSize:'12px', textAlign:'left',}} key={reason.code} value={reason.code}>{reason.code} {reason.name}</MenuItem>))}
                                </Select>
                                <label style={{width : "62px" , height : "16px" , margin : "0px 537px 10px 84px" , fontFamily : "Poppins" , fontSize : "11px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "4" , letterSpacing : "normal" , textAlign : "left" , color : "#6e7074"}}>
                                    Description
                                </label>
                                <textarea value={reasonDescription} onChange={(e) => setReasonDescription(e.target.value)} id="w3review" name="w3review" rows="4" cols="50" placeholder='Enter Description' style={{padding:'10px', fontFamily:"Poppins", fontSize:'12px', textAlign:'left',width : "505px" , height : "106px" , margin : "10px 76px 43px 84px" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}></textarea>
                                <div align='right'>
                                    <Button onClick={DeactivateReqModalHandleClose} variant='outlined' style={{fontSize : '11px' , color : "#032541" , width : "90px" , height : "33.1px" , margin : "0px 30px 62.9px 13px" , padding : "9px 29px 8.1px 30px" , border : "solid 1px #032541"}}>
                                        Cancel
                                    </Button>
                                    {/*  save  */}
                                    <Button type='submit' variant='standard' style={{fontSize : '11px' , color : '#fff' , width : "90px" , height : "33.1px" , margin : "0px 76px 62.9px 30px" , padding : "9px 15px 8.1px 15px" , backgroundColor : "#dc3545"}}>
                                        Deactivate
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>

                {/*Status Update Modals*/}
                {/*Success Deactivation Modal*/}
                <SuccessModal onClose={successDeactivationModalHandleClose} editConfigOpen={successDeactivationModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Deactivated'} />

                {/*Success Activation Modal*/}
                <SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Activated'} />

                {/*Action Button Menu*/}
                <Button
                    sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
                    aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
                    aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
                    onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
                </Button>
                <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
                            anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

                    <MenuItem disableRipple onClick={() => {
                        DeactivateReqModalHandleOpen()
                        handleActionClose()
                    }}>
                        <EditIcon sx={{color : 'black'}}/>
                        Approve
                    </MenuItem>
                    <MenuItem onClick={() => {
                        ActivateModalHandleOpen()
                        handleActionClose()
                    }} disableRipple>
                        <PersonRemoveIcon/>
                        Decline
                    </MenuItem>
                </StyledMenu>
            </div>)
        }
    }]

// Inactive Columns
const inactiveColumns = [
    {field : 'groupName' , headerName : 'Group Name' , align : 'center' , flex : 1 , headerClassName : 'super-app-theme-header' , headerAlign : 'center' , editable : false} ,
    {field : 'createdAt' , headerName : 'Created At' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'groupType' , headerName : 'Group Type' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'groupDescription' , headerName : 'Description' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'requestedBy' , headerName : 'Requested By' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'deactivatedAt' , headerName : 'Date Deactivated' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'approvedBy' , headerName : 'Approved By' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'businessName' , headerName : 'Business Name' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false} ,
    {field : 'Actions' , headerName : 'Actions' , flex : 1 , headerAlign : 'center' , align : 'center' , editable : false ,
        renderCell : (params , event) => {

            //DropDown Handler
            const [anchorEl , setAnchorEl] = React.useState(null);
            const ActionOpen = Boolean(anchorEl);
            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };
            const handleActionClose = () => {
                setAnchorEl(null);
            };

            const {row} = params

            // session Modal Config
            const [sessionOpen, setSessionOpen] = React.useState(false);
            const handleSessionOpen = () => setSessionOpen(true);
            const handleSessionClose = () => setSessionOpen(false);
            const handleLogin = () => {window.location.href = '/logout'}

            // Activate Group Modal Config
            const [ActivateModalOpen , setActivateModalOpen] = useState(false);
            const ActivateModalHandleOpen = () => setActivateModalOpen(true);
            const ActivateModalHandleClose = () => setActivateModalOpen(false);

            // Delete Group Modal Config
            const [deleteModalOpen , setDeleteModalOpen] = useState(false);
            const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
            const DeleteModalHandleClose = () => setDeleteModalOpen(false);


            // Status Updates Modals
            // Success Activation Modal Config
            const [successActivationModalOpen , setSuccessActivationModalOpen] = useState(false);
            const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
            const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

            // Success Deletion Modal Config
            const [successDeletionModalOpen , setSuccessDeletionModalOpen] = useState(false);
            const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
            const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);

            //Group Update Url
            const updateUrl = baseUrl + '/api/updateGroup/' + row.id
            const deleteUrl = baseUrl + '/api/deleteGroup/' + row.id

            // Activate Groups Update Function
            const activateGroupUpdate = async (e) => {
                e.preventDefault()
                try {
                    const activateResponse = await fetch(updateUrl , {
                        method : 'PUT' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } , body : JSON.stringify({
                            groupState : "Active" ,
                            modifiedAt : Date.now() ,
                            requestedBy : localStorage.getItem('username')
                        })
                    });
                    if (activateResponse.status === 202) {
                        ActivateModalHandleClose()
                        successActivationModalHandleOpen()
                    } else if (activateResponse.status === 401){
                        handleSessionOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            // Delete Groups Update Function
            const deleteGroup = async (e) => {
                e.preventDefault()
                try {
                    const deleteResponse = await fetch(deleteUrl , {
                        method : 'DELETE' , headers : {
                            'Content-Type' : 'application/json' ,
                            'Accept' : 'application/json' ,
                            'X-Authorization' : localStorage.getItem('X-Authorization')
                        } ,
                    });
                    if (deleteResponse.status === 202) {
                        DeleteModalHandleClose()
                        successDeletionModalHandleOpen()
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            return (
                <div>

                    {/*sessionModal*/}
                    <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                    {/*Activate Group Modal*/}
                    <QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateGroupUpdate} actionImage={activateGroup} action='Activate' item={row.groupName} processName={'Activate'}/>

                    {/*Delete Group Modal*/}
                    <QuestionModal deactivateModalOpen={deleteModalOpen} onClose={DeleteModalHandleClose} formAction={deleteGroup} actionImage={deactivateGroup} action='Delete' item={row.groupName} processName={'Delete'}/>

                    {/*Success Deletion Modal*/}
                    <SuccessModal onClose={successDeletionModalHandleClose} editConfigOpen={successDeletionModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Deleted'} />

                    {/*Success Activation Modal*/}
                    <SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen} modalRow={row.groupName} successObject={'Group'} successType={'Deleted'} />

                    {/*Action Button Menu*/}
                    <Button
                        sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
                        aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
                        aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
                        onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
                    </Button>
                    <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
                                anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>

                        {/*Check if is on Inactive Tab*/}
                        <MenuItem disableRipple onClick={() => {
                            ActivateModalHandleOpen()
                            handleActionClose()
                        }}>
                            <EditIcon sx={{color : 'black'}}/>
                            Activate
                        </MenuItem>
                        <MenuItem onClick={() => {
                            DeleteModalHandleOpen()
                            handleActionClose()
                        }} disableRipple>
                            <PersonRemoveIcon/>
                            Delete
                        </MenuItem>
                    </StyledMenu>
                </div>
            )
        }
    }]

const Groups = () => {

    // session Modal Config
    const [sessionOpen, setSessionOpen] = React.useState(false);
    const handleSessionOpen = () => setSessionOpen(true);
    const handleSessionClose = () => setSessionOpen(false);
    const handleLogin = () => {window.location.href = '/logout'}

    // Add group Modal Control
    const [open , setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Success Modal Control
    const [successOpen , setSuccessOpen] = React.useState(false);
    const handleSuccessOpen = () => setSuccessOpen(true);
    const handleSuccessClose = () => setSuccessOpen(false);

    // Existing Group Modal config
    const [existingUserModal , setExistingUserModalOpen] = useState(false);
    const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
    const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

    // handle tab Changes
    const [tabValue , setTabValue] = React.useState('Active');
    const handleTabChange = (event , newValue) => {
        setTabValue(newValue);
    };

    // Groups Setters and Getters
    const [activeGroup , setActiveGroups] = useState([])
    const [newGroup , setNewGroups] = useState([])
    const [inactiveGroup , setInactiveGroup] = useState([])
    const [pendingGroup , setPendingGroup] = useState([])
    const [deactivationGroup , setDeactivationGroups] = useState([])

    const fetchUrl = baseUrl + "/api/viewAllGroups"
    const postUrl = baseUrl + "/api/createGroup"

    // Check if Tab is on Active
    if (tabValue === "Active") {

        // Fetch Active Groups
        const ActiveGroups = async () => {
            try {
                const ActiveResponse = await fetch(fetchUrl + '/Active' , {
                    method : 'GET' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    }
                });
                const data = await ActiveResponse.json();
                if (ActiveResponse.status === 200) {
                    setActiveGroups(data.data)
                    localStorage.setItem("tabValue" , "Active")
                } else if ( ActiveResponse.status === 401) {
                    handleSessionOpen()
                }

            } catch (e) {
                console.log(e)
            }
        };
        useEffect(() => {
            ActiveGroups()
        } , [tabValue])

    }

    // Check if tab is on New
    else if (tabValue === "New") {

        // Fetch New Groups yet to be Approved
        const NewGroups = async () => {
            try {
                const newResponse = await fetch(fetchUrl + '/New' , {
                    method : 'GET' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    }
                });
                const data = await newResponse.json();
                if (newResponse.status === 200) {
                    setNewGroups(data.data)
                    localStorage.setItem("tabValue" , "New")
                } else if ( newResponse.status === 401) {
                    handleSessionOpen()
                }

            } catch (e) {
                console.log(e)
            }
        };
        useEffect(() => {
            NewGroups()
        } , [tabValue])

    }

    // Check if tab is on Pending
    else if (tabValue === "Pending") {

        // Fetch Edits Approval Groups
        const PendingGroups = async () => {
            try {
                const inactiveResponse = await fetch(fetchUrl + '/Pending' , {
                    method : 'GET' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    }
                });
                const data = await inactiveResponse.json();
                if (inactiveResponse.status === 200) {
                    setPendingGroup(data.data)
                    localStorage.setItem("tabValue" , "Pending")
                } else if ( inactiveResponse.status === 401) {
                    handleSessionOpen()
                }

            } catch (e) {
                console.log(e)
            }
        };
        useEffect(() => {
            PendingGroups()
        } , [tabValue])


    }

    // Check if tab is on Deactivation
    else if (tabValue === "Deactivation") {

        // Fetch Pending Deactivation Groups
        const DeactivationGroups = async () => {
            try {
                const inactiveResponse = await fetch(fetchUrl + '/Deactivation' , {
                    method : 'GET' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    }
                });
                const data = await inactiveResponse.json();
                if (inactiveResponse.status === 200) {
                    setDeactivationGroups(data.data)
                    localStorage.setItem("tabValue" , "Deactivation")
                } else if ( inactiveResponse.status === 401) {
                    handleSessionOpen()
                }

            } catch (e) {
                console.log(e)
            }
        };
        useEffect(() => {
            DeactivationGroups()
        } , [tabValue])

    }

    // Check if tab is on Inactive
    else if (tabValue === "Inactive") {

        // Fetch Deactivated Groups
        const InactiveGroups = async () => {
            try {
                const inactiveResponse = await fetch(fetchUrl + '/Inactive' , {
                    method : 'GET' , headers : {
                        'Content-Type' : 'application/json' ,
                        'Accept' : 'application/json' ,
                        'X-Authorization' : localStorage.getItem('X-Authorization')
                    }
                });
                const data = await inactiveResponse.json();
                if (inactiveResponse.status === 200) {
                    setInactiveGroup(data.data)
                    localStorage.setItem("tabValue" , "Inactive")
                } else if (inactiveResponse.status === 401) {
                    handleSessionOpen()
                }

            } catch (e) {
                console.log(e)
            }
        };
        useEffect(() => {
            InactiveGroups()
        } , [tabValue])

    }

    // Creating a New Group Setters and Getters
    const [groupName , setGroupName] = useState('');
    const [groupType , setGroupType] = useState('');
    const [groupDescription , setGroupDescription] = useState('');

    // create group
    const addNewGroupHandler = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(postUrl , {
                method : "POST" , headers : {
                    "Content-Type" : "application/json" ,
                    "Accept" : "application/json" ,
                    "X-Authorization" : localStorage.getItem('X-Authorization')
                } , body : JSON.stringify({
                    groupName : groupName ,
                    groupType : groupType ,
                    groupDescription : groupDescription ,
                    createdBy : localStorage.getItem('username')
                })
            })
            const postData = await response.json()
            console.log(postData);
            if (response.status === 201 && postData.Status === 'SUCCESS') {
                setGroupName('');
                setGroupType('');
                setGroupDescription('');
                handleClose();
                handleSuccessOpen()
            } else if ( response.status === 401) {
                handleSessionOpen()
            } else if (postData.message === 'Error Group Already Existing!'){
                existingUserModalHandleOpen()
                handleClose()
            }
            else {
                console.log('Error creating group');
            }
        } catch (error) {
            console.log(error)
        }

    }

    // Active Groups Row Data
    const activeRowData = activeGroup.map(activeGroupMap => {
        return {
            id : activeGroupMap._id ,
            groupName : activeGroupMap.groupName ,
            groupType : activeGroupMap.groupType ,
            groupDescription : activeGroupMap.groupDescription ,
            createdAt : activeGroupMap.createdAt
        };
    });

    // New Groups Row Data
    const newRowData = newGroup.map(newGroupMap => {
        return {
            id : newGroupMap._id ,
            groupName : newGroupMap.groupName ,
            groupType : newGroupMap.groupType ,
            groupDescription : newGroupMap.groupDescription ,
            createdAt : newGroupMap.createdAt ,
            createdBy : newGroupMap.createdBy ,
        };
    });

    // Inactive Groups Row Data
    const inactiveRowData = inactiveGroup.map(inactiveGroupMap => {
        return {
            id : inactiveGroupMap._id ,
            groupName : inactiveGroupMap.groupName ,
            groupType : inactiveGroupMap.groupType ,
            groupDescription : inactiveGroupMap.groupDescription ,
            createdAt : inactiveGroupMap.createdAt ,
            deactivatedAt : inactiveGroupMap.deactivatedAt ,
            requestedBy : inactiveGroupMap.requestedBy ,
            approvedBy : inactiveGroupMap.approvedBy
        };
    });

    // Pending Groups Row Data
    const pendingRowData = pendingGroup.map(pendingGroupMap => {
        return {
            id : pendingGroupMap._id ,
            groupName : pendingGroupMap.groupName ,
            groupType : pendingGroupMap.groupType ,
            groupDescription : pendingGroupMap.groupDescription ,
            createdAt : pendingGroupMap.createdAt ,
            modifiedAt : pendingGroupMap.modifiedAt ,
            modifiedBy : pendingGroupMap.modifiedBy
        };
    });

    // Deactivation Groups Row Data
    const deactivationRowData = deactivationGroup.map(deactivateGroupMap => {
        return {
            id : deactivateGroupMap._id ,
            groupName : deactivateGroupMap.groupName ,
            groupType : deactivateGroupMap.groupType ,
            groupDescription : deactivateGroupMap.groupDescription ,
            createdAt : deactivateGroupMap.createdAt ,
            deactivatedAt : deactivateGroupMap.deactivatedAt ,
            requestedBy : deactivateGroupMap.requestedBy
        };
    });
    // page size setter and getter
    const [pageSize, setPageSize] = React.useState(5);

    return (
        <div style={{fontFamily : 'Poppins'}}>
            <div>
                <div>
                    <div>
                        <Grid container>
                            <Grid item marginTop="22px" marginBottom='22px' >
                                <span style={{width: "78px", height: "35px", margin: "0px 41px 22px 12px", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545"}} >
                                    Groups
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container columns={2} marginBottom='22px'>
                            <Grid item xs={1}>
                                <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >Dashboard</span>
                                <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >\</span>
                                <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Groups</span>
                            </Grid>
                            <Grid item xs={.68} ></Grid>
                            <Grid item>
                                {localStorage.getItem('group') === 'Admin'?
                                    <Button sx={{fontFamily: "Poppins", fontSize: "9px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#f5f5f5", width: "136px", height: "37px", padding: "12px 8.1px 12px 11px", borderRadius: "8px", backgroundColor: "#032541"}} onClick={handleOpen} startIcon={<AddIcon/>}>
                                        Add Group
                                    </Button>:''
                                }
                            </Grid>
                        </Grid>

                        {/*Tab Context*/}
                        <Box>
                            <Box sx={{'& .super-app-theme--header' : {color : '#032541' , fontWeight : '600' , fontSize : '10px' ,}}}>
                                <TabContext value={tabValue}>
                                    <Box>
                                        <AntTabs onChange={handleTabChange} aria-label="Group tabs"
                                                 TabIndicatorProps={{hidden : false}} textColor='primary'>
                                            <AntTab label="Active Group " value="Active"/>
                                            <AntTab label="New Group " value="New"/>
                                            <AntTab label="Edited Group " value="Pending"/>
                                            <AntTab label="Deactivation request" value="Deactivation"/>
                                            <AntTab label="inactive Group " value="Inactive"/>
                                        </AntTabs>
                                    </Box>
                                    <TabPanel value="Active">
                                        <DataGrid style={{height : '500px' , width : 'auto' , overflow : 'visible'}}
                                                  rows={activeRowData}
                                                  columns={activeColumns}
                                                  pageSize={pageSize}
                                                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                  pagination
                                                  sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}>
                                        </DataGrid>
                                    </TabPanel>
                                    <TabPanel value="New">
                                        <DataGrid style={{height : '500px' , width : 'auto'}}
                                                  rows={newRowData}
                                                  columns={newColumns}
                                                  pageSize={pageSize}
                                                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                  pagination
                                                  sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}>
                                        </DataGrid>
                                    </TabPanel>
                                    <TabPanel value="Pending">
                                        <DataGrid style={{height : '500px' , width : 'auto'}}
                                                  rows={pendingRowData}
                                                  columns={editedColumns}
                                                  pageSize={pageSize}
                                                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                  pagination
                                                  sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}>
                                        </DataGrid>
                                    </TabPanel>
                                    <TabPanel value="Deactivation">
                                        <DataGrid style={{height : '500px' , width : 'auto'}}
                                                  rows={deactivationRowData}
                                                  columns={deactivationColumns}
                                                  pageSize={pageSize}
                                                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                  pagination
                                                  sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}>
                                        </DataGrid>
                                    </TabPanel>
                                    <TabPanel value="Inactive">
                                        <DataGrid style={{height : '500px' , width : 'auto'}}
                                                  rows={inactiveRowData}
                                                  columns={inactiveColumns}
                                                  pageSize={pageSize}
                                                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                  pagination
                                                  sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}>
                                        </DataGrid>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Box>
                        {/*sessionModal*/}
                        <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                        {/*group addition success modal*/}
                        <SuccessModal onClose={handleSessionClose} editConfigOpen={successOpen} modalRow={`New!`} successObject={'Group'} successType={'Created'} />

                        {/*Existing user Modal*/}
                        <Modal centered align={'center'} className='container container-fluid'
                               open={existingUserModal} onClose={existingUserModalHandleClose}
                               aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                            <div style={{width : "506px" , height : "506px" , margin : "10% 16px 23px 45px" , padding : "87px 75px 92.7px 34px" , borderRadius : "36px" , boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" , backgroundColor : "#fff"}}>
                                <img src={deactivate} alt={'success deactivation'}/>
                                <label style={{width : "356px" , height : "65px" , margin : "37.8px 0 0" , fontFamily : "Poppins" , fontSize : "25px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "1.2" , letterSpacing : "normal" , textAlign : "center" , color : "#6e7074"}}>Terminal is Existing!.
                                </label>
                                <Button onClick={() => {existingUserModalHandleClose(); window.location.reload(true)}} style={{fontFamily : "Poppins" , fontSize : "25px" , fontWeight : "600" , color : "#fff" , width : "124.5px" , height : "52.3px" , padding : "8px 44.5px 9.3px 35px" , backgroundColor : "#032541"}}>
                                    OK
                                </Button>
                            </div>
                        </Modal>

                        {/*add user group modal*/}
                        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                               aria-describedby="modal-modal-description" className="container-fluid">
                            <Box sx={AddGroupStyleModal}>
                                <Box sx={{width : "665px" , height : "90px" , margin : "0 0 8px" , padding : "35px 30px 34px" , borderRadius : "15px" , backgroundColor : "#dc3545" ,}}>
                                    <Typography id="modal-modal-title" style={{width : "156px" , height : "21px" , margin : "0 433px 0 0" , fontFamily : "Poppins" , fontSize : "15px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "normal" , letterSpacing : "normal" , textAlign : "left" , color : "#fff" ,}}>
                                        Add User Groups
                                    </Typography>
                                </Box>
                                <Box sx={{width : "605.2px" , height : "495px" , margin : "2.3px 29.8px 0 30px" , padding : "24.7px 54.2px 54.1px 54px" , backgroundColor : "#f5f7ff" ,}}>
                                    <form onSubmit={addNewGroupHandler}><label style={{width : "153px" , height : "20px" , margin : "0 343px 10px 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "3.14" , letterSpacing : "normal" , textAlign : "left" , color : "#b4bcc4" ,}}>Name
                                    </label>
                                        <input value={groupName} onChange={(e) => setGroupName(e.target.value)} maxLength="25" required style={{width : "496px" , height : "39px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff" ,}} type="text"/>
                                        <label style={{width : "153px" , height : "20px" , margin : "0 343px 10px 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "3.14" , letterSpacing : "normal" , textAlign : "left" , color : "#b4bcc4" ,}}>
                                            User Type
                                        </label>
                                        <select value={groupType} onChange={(e) => setGroupType(e.target.value)} style={{width : "496px" , height : "39px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff" ,}}>
                                            <option value="">Select User Type</option>
                                            {/*<option value="Admin">Admin</option>*/}
                                            <option value="User">User</option>
                                            <option value="SuperUser">SuperUser</option>
                                            {/*<option value="Merchant">Merchant</option>*/}
                                        </select>
                                        <br></br>
                                        <label style={{width : "153px" , height : "20px" , margin : "0 343px 10px 0" , fontFamily : "Poppins" , fontSize : "14px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "3.14" , letterSpacing : "normal" , textAlign : "left" , color : "#b4bcc4" ,}}>
                                            Description
                                        </label>
                                        <textarea value={groupDescription} onChange={(e) => setGroupDescription(e.target.value)} maxLength='100' required style={{width : "496px" , height : "136px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff" ,
                                        }} type="text"/>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <Button type="close" onClick={handleClose} style={{width : "90px" , height : "33.1px" , margin : "0 30px 0 0" , padding : "9px 30px 8.1px 29px" , backgroundColor : "#dc3545" , color : "#fff" ,}}>
                                            Close
                                        </Button>
                                        <Button type="submit" style={{width : "90px" , height : "33.1px" , margin : "0 0 0 30px" , padding : "9px 32px 8.1px 31px" , backgroundColor : "#032541" , color : "#fff" ,}}>
                                            Save
                                        </Button>
                                    </form>
                                </Box>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Groups