import React, {useEffect, useState} from 'react'
import Dash from '../../common/dash'
import {Grid} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import {SessionModal} from '../Modals/SessionExpiredModal/sessionModal'
import {SuccessModal} from '../Modals/SuccessModal/successModal'
import {QuestionModal} from "../Modals/QuestionModal/questionModal";
import deactivateGroup from "../../common/images/deactivate.svg";

// base url
const baseUrl = process.env.REACT_APP_BASE_URL

// data grid columns
const columns = [
    {field:'reasonCode', align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Reason Code '}</strong>)},
    {field:'reasonName', align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Reason Name'}</strong>)},
    {field:'reasonDescription', align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Reason Description'}</strong>)},
    {field:'createdBy',  align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Created By'}</strong>)},
    {field:'dateCreated',  align:'left', flex:1,valueGetter : (params) => {return (moment(params.row.dateCreated).format('llll'))}, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Date Created '}</strong>)}]

if (localStorage.getItem('group') === 'Admin') {
    columns.push(
        {headerName:'Reason Codes', headerAlign:'center', align:'center', width:5,flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Action'}</strong>), renderCell : (params) => {

                // session Modal Reason
                const [editReasonOpen, setEditReasonOpen] = React.useState(false);
                const handleEditReasonOpen = () => setEditReasonOpen(true);
                const handleEditReasonClose = () => setEditReasonOpen(false);

                // Delete Group Modal Config
                const [deleteModalOpen , setDeleteModalOpen] = useState(false);
                const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
                const DeleteModalHandleClose = () => setDeleteModalOpen(false);

                // Success Deletion Modal Config
                const [successDeletionModalOpen , setSuccessDeletionModalOpen] = useState(false);
                const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
                const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);

                // Values Setters and Getters
                const [editReasonCode, setEditReasonCode] = useState(params.row.reasonCode)
                const [editReasonName, setEditReasonName] = useState(params.row.reasonName)
                const [editDescription, setEditDescription] = useState(params.row.reasonDescription)

                // Success Reason Edit Modal Reason
                // Success Modal Control
                const [successOpen , setSuccessOpen] = React.useState(false);
                const handleSuccessOpen = () => setSuccessOpen(true);
                const handleSuccessClose = () => setSuccessOpen(false);

                //Update url
                const updateUrl = baseUrl + "/api/updateReason/"+`?code=${params.row.reasonCode}`
                const deleteUrl = baseUrl + "/api/deleteReason/"+`?code=${params.row.reasonCode}`

                // update url
                const pushEditUpdate = async(e) => {
                    e.preventDefault()
                    const response = await fetch(updateUrl, {
                        method : "PUT",
                        headers : {
                            "Content-Type" : "application/json" ,
                            "Accept" : "application/json" ,
                            "X-Authorization" : localStorage.getItem('X-Authorization')
                        },
                        body : JSON.stringify({
                            code : editReasonCode,
                            name : editReasonName,
                            description : editDescription
                        })
                    }); const Data = await response.json()
                    console.log(Data)
                    if (response.status === 202) {
                        handleSuccessOpen()
                        setEditReasonCode('')
                        handleEditReasonClose()
                    }
                    else if (response.status === 401){
                        alert('Unauthorised')
                    }
                }

                //delete Reason
                const pushDelete = async(e) => {
                    e.preventDefault()
                    const response = await fetch(deleteUrl, {
                        method : "DELETE",
                        headers : {"Content-Type" : "application/json" , "Accept" : "application/json" , "X-Authorization" : localStorage.getItem('X-Authorization')}});
                    const Data = await response.json()
                    if (response.status === 200) {
                        successDeletionModalHandleOpen()
                        setEditReasonCode('')
                        handleEditReasonClose()
                    }
                    else if (response.status === 401){
                        alert('Unauthorised')
                    }
                }

                return (

                    <div>

                        {/*edit button*/}
                        <Button onClick={handleEditReasonOpen} >
                            <EditIcon sx={{color:'#dc3545'}}>
                                Edit
                            </EditIcon>
                        </Button>

                        {/*Delete button*/}
                        <Button onClick={DeleteModalHandleOpen} >
                            <DeleteIcon sx={{color:'#dc3545'}}>
                                Delete
                            </DeleteIcon>
                        </Button>

                        {/*Success Deletion Modal*/}
                        <SuccessModal onClose={successDeletionModalHandleClose} editConfigOpen={successDeletionModalOpen}  modalRow={params.row.reasonName} successObject={'Reason'} successType={'Deleted'} />

                        {/*Delete Modal Question*/}
                        <QuestionModal deactivateModalOpen={deleteModalOpen} onClose={DeleteModalHandleClose} formAction={pushDelete} actionImage={deactivateGroup} action='Delete' item={params.row.reasonName} processName={'Delete'}/>

                        {/*success on Edit*/}
                        <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen}  modalRow={params.row.reasonName} successObject={'reason'} successType={'Updated'} />

                        {/*open edit reason*/}
                        <Modal open={editReasonOpen} onClose={handleEditReasonClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                            <form onSubmit={pushEditUpdate} >
                                <Box sx={{ transform: 'translate(-50%, -50%)',position: 'absolute', top:'50%', left:'50%', width: "605px", height: "550px", padding: "0 0 73.9px", borderRadius: "15px", backgroundColor: "#fff"}}>
                                    <Grid sx={{width: "605px", height: "90px", margin: "0 0 20px", padding: "35px 30px 3px", borderRadius: "16px", backgroundColor: "#dc3545"}} >
                                        <span style={{width: "189px", height: "21px", margin: "0 340px 0 0", fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#fff"}}>Add Reason</span>
                                    </Grid>
                                    {/*<Grid>*/}
                                    {/*    <span style={{width: "62px", height: "16px", margin: "4px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Code</span>*/}
                                    {/*    <input readOnly type='text' value={editReasonCode} onChange={(e) => setEditReasonCode(e.target.value)} style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />*/}
                                    {/*</Grid>*/}
                                    <Grid>
                                        <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Name</span>
                                        <input type='text' value={editReasonName} onChange={(e) => setEditReasonName(e.target.value)}  style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />
                                    </Grid>
                                    <Grid>
                                        <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Description</span>
                                        <input type='text' value={editDescription} onChange={(e) => setEditDescription(e.target.value)}  style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />
                                    </Grid>
                                    <Grid marginLeft={'350px'}>
                                        <Button onClick={handleEditReasonClose} align={'right'} sx={{color:'#dc3545', fontWeight:600 , margin:"20px 30px 0px 0px", width: "90px", height: "33.1px", padding: "9px 29px 8.1px 30px", border: "solid 1px #dc3545"}} >Close</Button>
                                        <Button type='submit' align={'right'} sx={{":hover": {color:'#032541',fontWeight:600 },fontWeight:600 , color:"#fff", margin:"20px 0px 0px 0px", width: "90px", height: "33.1px", padding: "9px 32px 8.1px 32px", backgroundColor: "#032541"}}>Save</Button>
                                    </Grid>
                                </Box>
                            </form>
                        </Modal>

                    </div>
                )
            } }
    )
}

// session Expired
const style = {borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "350px", p: 4,};

const Reasons = () => {

    // session Modal Reason
    const [sessionOpen, setSessionOpen] = React.useState(false);
    const handleSessionOpen = () => setSessionOpen(true);
    const handleSessionClose = () => setSessionOpen(false);

    // Success Modal Control
    const [successOpen , setSuccessOpen] = React.useState(false);
    const handleSuccessOpen = () => setSuccessOpen(true);
    const handleSuccessClose = () => setSuccessOpen(false);

    // Add Reason Modal
    const [AddReasonOpen, setAddReasonOpen] = React.useState(false);
    const handleAddReasonOpen = () => setAddReasonOpen(true);
    const handleAddReasonClose = () => setAddReasonOpen(false);

    // page size setter and getter
    const [pageSize, setPageSize] = useState(5)

    // restoration rows setter and Getter
    const [reasonData, setReasonData] = useState([])

    // Reason Creation Setters and Getters
    const [code, setCode] = useState()
    const [codeName, setCodeName] = useState()
    const [description, setDescription] = useState()

    const reasonUrl = baseUrl + '/api/reasons'
    const createReasonUrl = baseUrl + '/api/createReason/'

    // Getting Reason Codes
    const getReasonCodes = async() => {
        try {
            const response = await fetch(reasonUrl, {
                method:"GET",
                headers : {"Content-Type" : "application/json" , "Accept" : "application/json" , "X-Authorization" : localStorage.getItem('X-Authorization')}});
            const data = await response.json()
            if (response.status === 200) {setReasonData(data.reasons)}
            else if (response.status === 401) {handleSessionOpen()}
        } catch (e) {console.log(e)}
    };useEffect(() => {
        getReasonCodes();
    },[])

    // creating Reason Codes
    const createReasonCodes = async(event) => {
        event.preventDefault()
        try {
            const response = await fetch(createReasonUrl, {
                method: "POST",
                headers : {"Content-Type" : "application/json" ,"Accept" : "application/json" ,"X-Authorization" : localStorage.getItem('X-Authorization')},
                body : JSON.stringify({name: codeName, description : description})})
            if (response.status === 201) {handleSuccessOpen()}
            else if (response.status === 401) {handleSessionOpen()}
        } catch (e) {console.log(e)}
    }

    const rowData = reasonData.map((reason) => {
        return {
            id : reason._id,
            reasonCode : reason.code,
            reasonName : reason.name,
            reasonDescription : reason.description,
            createdBy : reason.createdBy,
            dateCreated : reason.dateCreated
        }
    })

    return (
        <div>
            <div>
                <div>

                    {/*sessionModal*/}
                    <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                    {/*group addition success modal*/}
                    <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen}  modalRow={`New!`} successObject={'Reason'} successType={'Created'} />

                    {/*Page header*/}
                    <Grid container sx={{flex:1}} >
                        <Grid item>
                            <label style={{height: "35px", margin: "10px 32px 21px 12px", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545"}} >Reason Codes</label>
                        </Grid>
                    </Grid>
                    <Grid container columns={1} sx={{margin:"0px 0px 20px 0px"}}>
                        <Grid item xs={.853}>
                            <span style={{height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >Dashboard</span>
                            <span style={{height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >\</span>
                            <span style={{height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Reason Codes</span>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleAddReasonOpen} style={{padding:'10px', backgroundColor:'#032541', color:"#fff", fontFamily:'Poppins'}} startIcon={<AddIcon />}> Add Reason</Button>
                        </Grid>
                    </Grid>

                    {/*Add Reason Modal*/}
                    <Modal open={AddReasonOpen} onClose={handleAddReasonClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <form onSubmit={createReasonCodes} >
                            <Box sx={{ transform: 'translate(-50%, -50%)',position: 'absolute', top:'50%', left:'50%', width: "605px", height: "550px", padding: "0 0 73.9px", borderRadius: "15px", backgroundColor: "#fff"}}>
                                <Grid sx={{width: "605px", height: "90px", margin: "0 0 20px", padding: "35px 30px 3px", borderRadius: "16px", backgroundColor: "#dc3545"}} >
                                    <span style={{width: "189px", height: "21px", margin: "0 340px 0 0", fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#fff"}}>Add Reason</span>
                                </Grid>
                                {/*<Grid>*/}
                                {/*    <span style={{width: "62px", height: "16px", margin: "4px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Code</span>*/}
                                {/*    <input type='text' value={code} onChange={(e) => setCode(e.target.value)} style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />*/}
                                {/*</Grid>*/}
                                <Grid>
                                    <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Name</span>
                                    <input type='text' value={codeName} onChange={(e) =>setCodeName(e.target.value)}  style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />
                                </Grid>
                                <Grid>
                                    <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Description</span>
                                    <input type='text' value={description} onChange={(e) =>setDescription(e.target.value)}  style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "70px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />
                                </Grid>
                                <Grid marginLeft={'350px'}>
                                    <Button onClick={handleAddReasonClose} align={'right'} sx={{color:'#dc3545', fontWeight:600 , margin:"20px 30px 0px 0px", width: "90px", height: "33.1px", padding: "9px 29px 8.1px 30px", border: "solid 1px #dc3545"}} >Close</Button>
                                    <Button type='submit' align={'right'} sx={{":hover": {color:'#032541',fontWeight:600 },fontWeight:600 , color:"#fff", margin:"20px 0px 0px 0px", width: "90px", height: "33.1px", padding: "9px 32px 8.1px 32px", backgroundColor: "#032541"}}>Save</Button>
                                </Grid>
                            </Box>
                        </form>
                    </Modal>

                    {/*search bar*/}
                    <Grid container spacing={2}>
                        <Grid item xs={10} lg={9} md={10} sm={8}></Grid>
                        <Grid item>
                            <label style={{width: "35px", height: "12px", fontFamily: "Poppins", fontSize: "9px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Search:</label>
                            <input style={{fontSize:'12px',color:'#272d3b', borderRadius:"6px", width:"238px", height: "36px", margin: "0 0 6.4px 20.4px", padding: "7px 107.7px 6.4px 11.1px", objectFit: "contain", border: "solid 1px #032541"}} placeholder='search here' ></input>
                        </Grid>
                    </Grid>

                    {/*Data grid*/}
                    <Grid container sx={{ height: 500, width: '100%', flex:1, margin:'50px 0px 0px 0px',border: "solid 0px transparent", backgroundColor: "#fff"}}>
                        <DataGrid
                            sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}
                            rows={rowData}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize)=>setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            pagination/>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Reasons;