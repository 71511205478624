import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from '@mui/material/List';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ExpandLess } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from "react";
import { Collapse } from '@mui/material';
import { makeStyles } from "@mui/styles";
import GradesAndStreamsIcon from '../../components/School/Images/grade-streams-icn.svg';
let baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));

export default function GradesAndStreams() {
    const classes = useStyles();
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const { schoolTypeDetail } = useSelector((store) => store?.schoolType)

    useEffect(() => {
        const { schoolTypeName, schoolTypeId } = schoolTypeDetail
        setSchoolTypeName(schoolTypeName)
        setSchoolTypeId(schoolTypeId)
    }, [schoolTypeDetail])

    const [schoolTypeName, setSchoolTypeName] = useState('')
    const [schoolTypeId, setSchoolTypeId] = useState('')
    const location = useLocation()
    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc"
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open);
    };

    // console.log(schoolTypeDetail , "sschoolTypeDetail");
    const userGroup = localStorage.getItem('group')
    const businessCat = localStorage.getItem('businessCategory')
    //             
    return (
        <div>

            {/*Users*/}
            {businessCat === 'School' ?
                <>
                    <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }} className={classes.nested}>
                        <div style={{ marginRight: '5%' }}>
                            <img src={GradesAndStreamsIcon} alt="" />
                        </div>
                        <ListItemText primary={schoolTypeName?.includes("University") ? "Courses & Programs" : "Grades & Streams"} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <div style={{ marginLeft: '8%' }}>
                                {schoolTypeName?.includes("University") ?
                                    <Link style={{ textDecoration: 'none', color: isActiveLink("/school/units") ? activeColor : '#fff' }} to="/school/units">
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary={'Units'} />
                                        </ListItem>
                                    </Link> : null
                                }
                                <Link style={{ textDecoration: 'none', color: isActiveLink("/school/grades") ? activeColor : '#fff' }} to="/school/grades">
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary={schoolTypeName?.includes("University") ? "Courses & Programs" : "Grades & Streams"} />
                                    </ListItem>
                                </Link>
                            </div>
                        </List>
                    </Collapse>
                </> : null}
        </div>
    )
}
