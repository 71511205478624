import React from "react";
import { TextField } from "@mui/material";

const CustomSearchComponent = ({setSearchValue}) => {

  const getSearchValue = (e) => {
    let value = e.target.value
    if(!value){
      setSearchValue("")
    }
    setSearchValue(value)
    
  }
  return (
    <TextField
    onChange={getSearchValue}
      placeholder="Search..."
      sx={{width:'100%'}}
      InputProps={{
        sx: {
          border: "1px solid #d8dee2",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #d8dee2",
          },
          
        },
      }}
    />
  );
};

export default CustomSearchComponent;
