import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  branchesArr: [],
};

const businessBranchesSlice = createSlice({
  name: 'businessBranches',
  initialState,
  reducers: {
    handleBusinessBranches: (state, action) => {
      const { branchesArr } = action.payload;
      state.branchesArr = branchesArr;
    },
    deleteBusinessBranches: state => {
      state.branchesArr = [];
    },
  },
});

export const { handleBusinessBranches, deleteBusinessBranches } = businessBranchesSlice.actions;

export default businessBranchesSlice.reducer;
