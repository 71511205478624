import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    valChanged: false,
};

const onChangeSlice = createSlice({
  name: "userChanged",
  initialState,
  reducers: {
    setChange: (state, action) => {
      state.valChanged = action.payload
    },
  },
});

export const { setChange } = onChangeSlice.actions;

export default onChangeSlice.reducer;
