import React, { useEffect, useState } from 'react'
import Dash from '../../common/dash'
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CustomSelectField from '../School/CustomSelectField';
import Box from "@mui/material/Box";
import moment from "moment-timezone";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import * as XLSX from 'xlsx'
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import lodash from "lodash";
import ExportMenu from '../School/ExportMenu';
import CustomSearchInput from '../School/CustomSearchInput';
import DateFormatter from '../../utils/dateFormatter';
import { currencyconverter } from '../../common/currencyconverter';
import { CustomizedDate } from '../customerAccounts/customerComponents/customDate';
import { Link, useNavigate } from 'react-router-dom';
import ProductsInOrder from './productsInaOrder';
import CustomDataGrid from '../products/stockComponents/customDataGrid';


const baseUrl = process.env.REACT_APP_BASE_URL
let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

export default function ReportsUnpaidOrdersByCashier(){

    // page size setter and getter
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = useState(1)
    //add days to current date
    function addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    // session Modal Config
    const [sessionOpen, setSessionOpen] = React.useState(false);
    const handleSessionClose = () => setSessionOpen(false)
    const handleSessionOpen = () => setSessionOpen(true);

    // Transaction typesetters and Getters
    const [transactionType, setTransactionType] = useState([])
    const [cashiers, setCashiers] = useState([])
    const [cashierOrders, setCashierOrders] = useState([])
    const [cashierOrdersTotal, setCashierOrdersTotal] = useState([])
    const [cashierOrdersCount, setCashierOrdersCount] = useState([])

    // payment Type setter and getter
    const [paymentType, setPaymentType] = useState('All');
    const [cashierSelected, setCashierSelected] = useState('');

    //const
    const [searchValue, setSearchValue] = useState('')
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")
    const navigate = useNavigate()


    //"DD-MM-YYYY HH:MM:ss"

    // custom date format;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1));
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 Days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result?.length - 1]);
                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0, 0));
                const lastDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth() + 1, 0, 23, 59, 59, 999));
                setStartDate(firstDay);
                setEndDate(lastDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth() - 1, 1, 0, 0, 0, 0));
                const lastDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), 0, 23, 59, 59, 999));
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Default":
                setStartDate("");
                setEndDate("");
                break;
            case "Custom Range":
                setStartDate(new Date(dateFrom));
                setEndDate(new Date(dateTo));
                break;
            default:
                return;
        }
    }, [handleDaysChange, dateFrom, dateTo]);

    let totalsList = []

    //get_transactions_by_cashier
    useEffect(() => {
        const fetchCashierOrders = async () => {
            try {
                const res = await axios.get(baseUrl + `/api/get_unpaid_orders_by_cashier?cashier=${cashierSelected}&startDate=${startDate}&endDate=${endDate}&searchValue=${searchValue}`, { headers: { "Content-Type": "application/json", "Accept": "application/json", "X-Authorization": localStorage.getItem('X-Authorization') } })
                console.log(`fetchCashierOrders.data.data ${res.data.data}`)
                if (res.status === 200) {
                    setCashierOrders(res.data.data);
                    setCashierOrdersTotal(res.data.total);
                    setCashierOrdersCount(res.data.count);
                    console.log(`cashierOrdersTotalcashierOrdersTotal ${cashierOrders}`)
                    totalsList = []
                    totalsList.push({ type: "total", amount: cashierOrdersTotal, count: cashierOrdersCount })
                    //totalsList.push({type:"count", count: cashierSalesCount})
                }
                else if (res.status === 401) { handleSessionOpen() }
            } catch (e) { console.log(e) }
        }
        fetchCashierOrders();
    }, [cashierSelected, startDate, endDate, searchValue,state])




    const summaryData = totalsList.map(summary => {
        return {
            count: summary.count,
            total: summary.total
        }
    });

    const rowData = cashierOrders.map(order => {
        return {
            id: order._id,
            businessNo: order.businessId,
            orderTable: order.orderTable || "N/A",
            customerName: order.customerName || "N/A",
            status: order.status,
            items: order.items,
            transtime: order.transtime,
            transamount: order.transamount,
            cashier: order.cashier,
            orderNumber: order?.orderNumber || "N/A"
        };
    });

    // console.log(rowData, 'Row Data')

    // Fetch Payment Types from DB
    const fetchTransactionTypes = async () => {
        try {
            const response = await fetch(baseUrl + '/api/transactionType', { method: 'GET', headers: { "Accept": "application/json", "X-Authorization": localStorage.getItem('X-Authorization') } });
            const data = await response.json();
            console.log(`datadatatt ${JSON.stringify(data)}`)
            if (response.status === 200 && data.Status === 'SUCCESS') { setTransactionType(data.data) }
            else if (response.status === 401) { handleSessionOpen() }
        } catch (e) { console.log(e) }
    }

    //get_cashiers_by_business
    const fetchCashiers = async () => {
        try {
            const response = await fetch(baseUrl + '/api/get_cashiers_by_business', { method: 'GET', headers: { "Accept": "application/json", "X-Authorization": localStorage.getItem('X-Authorization') } });
            const data = await response.json();
            console.log(`datadata ${JSON.stringify(data)}`)
            if (response.status === 200 && data.Status === 'SUCCESS') { setCashiers(data.data) }
            else if (response.status === 401) { handleSessionOpen() }
        } catch (e) { console.log(e) }
    }

    useEffect(() => {
        fetchTransactionTypes()
    }, [])


    useEffect(() => {
         fetchCashiers();
    }, [])

    //
    const handleCashierChange = (event) => {
        setCashierSelected(event.target.value);
    }

    const handleSearchBox = (event) => {
        setSearchValue(event.target.value)
    }

    // CSV Export Format
    const csvColumns = [
        { label: "Order Table", key: "orderTable" },
        { label: "Customer Name", key: "customerName" },
        { label: "Status", key: "status" },
        { label: "Cashier", key: "cashier" },
        { label: "Date", key: "transtime" },
        { label: "Amount", key: "transamount" }
    ]


    // columns selection for Excel Export
    const pdfData = rowData.map((order) => {
        return {
            OrderTable: order.orderTable,
            Customer: order.customerName,
            Status: order.status,
            Cashier: order.cashier,
            Date: order.transtime,
            Amount: order.transamount
        }
    })

    const columns = [
        { field: "orderNumber", headerName: 'Order No', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Order No'}</strong>) },
        { field: "items", headerName: 'Units', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Unit Sold Summary'}</strong>), renderCell: (params) =>{
            return(
                <Typography sx={{cursor:'pointer'}} onClick={() => navigate(`/get_unpaid_orders_by_cashier?ViewOrder?${params?.row?.orderNumber}`)}>{params.row.items.length <= 0 ? "N/A" : params.row.items.length}</Typography>
            )
        }},
        { field: "orderTable", headerName: 'Order Table', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Order Table'}</strong>) },
        { field: "customerName", headerName: 'Customer Name', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Customer Name'}</strong>) },
        { field: "status", headerName: 'Status', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Status'}</strong>) },
        { field: "cashier", headerName: 'Terminal User', flex: 1, align: 'left', renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Terminal User'}</strong>) },
        { field: "transamount", headerName: 'Amount', flex: 1, align: 'left', valueGetter: (params) => { return currencyconverter(params.row.transamount) }, renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Amount'}</strong>) },
        { field: "transtime", headerName: 'Date', flex: 1, align: 'left', valueGetter: (params) => { return moment(params.row.transtime).tz('Africa/Banjul').format('llll') }, renderHeader: () => (<strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }} >{'Date'}</strong>) },
    ];


    const pdfTotal = []
    const data = pdfData.map((data) => [data.OrderTable, data.Customer, data.Status, data.Cashier, moment(data.Date).format('llll'), currencyconverter(data.Amount)])
    pdfData.map((data) => { const x = data.Amount; pdfTotal.push(x) })
    const headers = [["Order Table", "Customer", "Status", "Cashier", "Date", "Amount"]]
    const fileDataPdf = rowData?.map((order) => {
        return {
            "OrderTable": order.orderTable,
            "Customer": order.customerName,
            "Status": order.status,
            "Cashier": order.cashier,
            "Date": DateFormatter(order.transtime),
            "Amount": order.transamount
        }
    })

    return (
        <Grid container display={'flex'} flexDirection={'column'} width={'100%'}>
            {queryParamValue === 'ViewOrder' ? <ProductsInOrder /> :
             <>
                <Grid item>
                    <label style={{ width: "100%", height: "35px", margin: "10px 32px 21px 12px", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }} >Unpaid Orders</label>
                </Grid>
                <Grid container sx={{ margin: "0px 0px 20px 0px" }}>
                    <Grid item >
                        <span style={{ width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074" }} >Dashboard</span>
                        <span style={{ width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074" }} >\</span>
                        <span style={{ width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#032541" }} >Unpaid Orders By Cashier</span>
                    </Grid>
                </Grid>
                <Grid container display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item display={'flex'} width={'100%'} alignItems={'center'}>
                        <Grid container width={'100%'} spacing={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item mt={1} >
                                <CustomSearchInput name={'search'} value={searchValue} onChange={handleSearchBox} placeholder={'Search here'} />
                            </Grid>
                            <Grid item style={{ width: "200px" }}>
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel id="">Cashier</InputLabel>
                                    <Select
                                        labelId="This Month"
                                        id="date"
                                        label="Cashier"
                                        value={cashierSelected}
                                        onChange={handleCashierChange}
                                    >
                                        <MenuItem value={"all"} >All Cashiers</MenuItem>
                                        {cashiers?.map((cashier) => (
                                            <MenuItem value={cashier?._id?.cashier}>{cashier?._id?.cashier}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item style={{}}>
                                {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", }} />}
                            </Grid>
                            <Grid item>
                                <ExportMenu csvColumns={csvColumns} fileHeaders={headers} fileDataPDF={data} fileData={fileDataPdf} fileName={'Product Sales Report'} title={'Product sales Report'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ height: "500px", margin: "50px 0 0 0", border: "solid 0px transparent", backgroundColor: "#fff" }}>
                    <DataGrid
                        sx={{ fontFamily: 'Poppins', fontSize: '10px', color: '#272d3b' }}
                        rows={rowData}
                        columns={columns}
                        paginationMode='server'
                        page={page - 1}
                        onPageChange={(newPage) => {
                            setPage(newPage + 1)
                            setPageSize(pageSize)
                        }}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        pagination
                    />
                    <Grid alignItems={'right'} item sx={{ margin: "20px 0px 0px 12px" }}>
                        <label style={{ width: "35px", height: "12px", fontFamily: "Poppins", fontSize: "12px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#032541" }} >Total:</label>
                        <label style={{ width: "35px", height: "12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#032541" }} >{currencyconverter(lodash.sum(pdfTotal))}</label>
                    </Grid>
                </Grid>
            </>
            }
        </Grid>
    )
}
