import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    firstNameR:'',
    lastNameR:'',
    additionalInfoR:'',
    studentIdR:"",
    gradeR:"",
    termR:"",
    admissionDateR:"",
    customerIdR:"",
    studentItemNo:"",
    servicesR: []
    // invoiceDetails: {}
}



const requestableService = createSlice({
    name:"requestableService",
    initialState,
    reducers: {
        saveRequstableServiceDeatails: (state, action) => {
            // state.saverequestableService  =action.payload
            console.log(action.payload, 'action.payload');
            
            const {lastName,additionalInfo,studentId,grade,term,admissionDate,customerId,studentItemNo,servicesData }  = action.payload
            const firstName = action.payload.firstName
            console.log(firstName, 'firstName');

            state.firstNameR = firstName
            state.lastNameR = lastName
            state.additionalInfoR = additionalInfo
            state.studentIdR = studentId
            state.gradeR = grade
            state.termR = term
            state.admissionDateR = admissionDate
            state.customerIdR = customerId
            state.studentItemNo = studentItemNo
            state.servicesR =servicesData
            // state.invoiceDetails = action.payload
        },
        removeRequstableServiceDeatails: (state, action) => {
            // set to initial state
            state.firstNameR = ''
            state.lastNameR = ''
            state.additionalInfoR = ''
            state.studentIdR = ''
            state.gradeR = ''
            state.termR = ''
            state.admissionDateR = ''
            state.customerIdR = ''
            state.studentItemNo = ''
            state.servicesR = []
            // state.invoiceDetails = {}

        },

        
    }

})

export const  { saveRequstableServiceDeatails } = requestableService.actions
export default requestableService.reducer