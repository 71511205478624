import { Collapse, Grid, Tab, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import CustomSearchInput from "../../../School/CustomSearchInput";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HttpComponent from "../../../School/MakeRequest";
import { PendingRows } from './CustomRowTable'
import VerificationPayable from "./VerificationPayable";
import { useNavigate } from "react-router-dom";
import AutocompleteComponent from "../../../../common/autoComplete";
import { makeStyles } from "@mui/styles";

const AntTabs = styled(TabList)({ borderBottom: "none", "& .MuiTabs-indicator": { backgroundColor: "none" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", maxWidth: 200, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), width: '100%',
    fontSize: "18px", background: '#f5f6f7', borderRadius: '5px', fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", backgroundColor: '#fef7f8', borderBottom: '', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "none" },
}));


const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "14px",
        fontWeight: "700",
        color: '#032541'
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));



export default function OtherPaymentsPayableTabs(props) {

    const [tabValue, setTabValue] = useState('Pending')
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const classes = useStyles()
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    //url params

    // const queryparams = decodeURIComponent(window.location.search);
    // const state = queryparams.slice(1)
    // const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // const handleNavigateToVerify = () => {
    //     navigate('/payables/transactions?verifyPayable')
    // }

    // get all business

    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness()
    }, [selectedValue])


    // fetch pending payable transactions

    function fetchtOtherPayableTransactions() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/admin/funds_transfers?type=payee&status=${tabValue}&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&businessNumber=${selectInputValue?.bsNo ? selectInputValue?.bsNo : ''}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchtOtherPayableTransactions()
    }, [tabValue, pageState.page, dataGridPageSize, searchValue, selectInputValue?.bsNo])

    // console.log(state, 'pagestate data')


    return (
        <Grid direction={'column'} container>
            <Grid item>
                <TabContext value={tabValue}>
                    <AntTabs onChange={handleChange} indicatorColor={""} sx={{ display: 'flex' }}>
                        <AntTab label="Pending Approval" value="Pending" />
                        <AntTab label="Disbursed" value="Disbursed" />
                        <AntTab label="Declined" value="Declined" />
                        <AntTab label="Failed" value="Failed" />
                    </AntTabs>
                    <TabPanel value="Pending">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} display={'flex'}>
                                        <Grid item>
                                            <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "40px", width: "400px" }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Payment To</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Disbursed">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} display={'flex'}>
                                        <Grid item>
                                            <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "40px", width: "400px" }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Payment To</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                                {tabValue === 'Disbursed' ? <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Date Disbursed</TableCell> : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} tabValue={tabValue} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Declined">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} display={'flex'}>
                                        <Grid item>
                                            <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "40px", width: "400px" }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Payment To</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                                {tabValue === 'Declined' ? <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Date Declined</TableCell> : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} tabValue={tabValue} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Failed">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} display={'flex'}>
                                        <Grid item>
                                            <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "40px", width: "400px" }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Payment To</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>

                </TabContext>
            </Grid>
        </Grid>
    )

}
