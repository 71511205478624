import { Box, Button, Grid, List, ListItem, Modal,FormControl,RadioGroup,FormControlLabel,Radio,Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
const REACT_APP_ENV = process.env.REACT_APP_ENV

const Evoucher = () => {
    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [errorNotificationOpen, setErrorNotificationOpen] = useState(false);
    const [evoucherStatus, setEvoucherStatus] = useState(false)
    const [openEvoucherModal, setOpenEvoucherModal] = useState(false)
    const [message, setMessage] = useState('')
    const [subDomainName, setBusinessName] = useState('')
    const navigate = useNavigate();


    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };
    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };
    const handleErrorNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorNotificationOpen(false);
    };

    const handleOpenEvoucherModal = () => {
        setOpenEvoucherModal(true)
    }
    const handleCloseEvoucherModal = () => {
        
    }
    function getEvoucherStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/evoucher/getEvoucherConfigStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                   let statusEvoucher = data?.response?.status
                   setEvoucherStatus(JSON.parse(statusEvoucher));
                   console.log(evoucherStatus)
                }else {
                    setMessage('Failed to enable evoucher');
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getEvoucherStatus()
        getAccountingStatus()
    }, [])

    const switchEvoucher = () => {
        if (evoucherStatus) {
            handleCloseEvoucherModal()
        } else if (!evoucherStatus) {
            handleOpenEvoucherModal()
        }
       
    }

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    let businessResp = data?.response?.data?.businessName
                    let frontUrl = 'https://dev.zed.business'
                    if(REACT_APP_ENV === 'development'){
                        frontUrl = 'https://dev.zed.business'
                        
                    }else if(REACT_APP_ENV === 'test'){
                        frontUrl = 'https://zed.swerri.io'

                    }else if(REACT_APP_ENV === 'production'){
                        frontUrl = 'https://web.zed.business'
                    }

                    let newDomain = businessResp.replace(/\s+/g, "")
                    const fullUrl = `https://${newDomain.toLowerCase()}.${frontUrl.replace(/^https?:\/\//, "")}`
                    setBusinessName(fullUrl)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const updateEvoucherStatus =  async() => {
        try {
            const response = await HttpComponent({
                method: 'post',
                url: `/api/v1/evoucher/setEvoucherConfigStatus`,
                body: {
                "status": true,
                    "subDomain": subDomainName,
                    "type": "zedDomain"
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                if (data.status === 200) {
                    setMessage(data?.response?.message)
                    handleSuccessNotificationClick();
                    getEvoucherStatus()
                    setOpenEvoucherModal(false)
                    navigate('/evoucher_booking'); 
                }
            });
        } catch (error) {
            // Handle the error
            console.log(error)
        }
    }

    const disableEvoucherStatus =  async() => {
        try {
            const response = await HttpComponent({
                method: 'post',
                url: `/api/v1/evoucher/setEvoucherConfigStatus`,
                body: {
                "status": false,
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                if (data.status === 200) {
                    console.log(data);
                    setMessage(data?.response?.message)
                    handleSuccessNotificationClick();
                    getEvoucherStatus()
                    setOpenEvoucherModal(false)
                }
            });
        } catch (error) {
            // Handle the error
            console.log(error)
        }
    }

    return (
        <div>
            <Grid container item className={"container-fluid"}>
                {/*Page Title Container*/}
                <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                    <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                </Grid>

                {/*Page Sub title and Search */}
                <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                    <Grid item>
                        <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                            <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                            <span style={{ color: "#032541" }}>Configuration\</span>
                            <span style={{ color: "#032541" }}>Evoucher Config</span>
                        </div>
                    </Grid>
                </Grid>

                {/*Headers*/}
              
                <Grid container style={{ width: "86%", marginTop: "30px" }}>
                    <Grid item style={{ width: "100%" }}>
                        {/* <List>
                            <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Evoucher</Box>
                                    <Box>
                                    <Switch checked={evoucherStatus} onClick={switchEvoucher} />
                                    </Box>
                                </Box>
                            </ListItem>
                        </List> */}
                    </Grid>
                </Grid>
            </Grid>
            <Box style={{ display: "flex", justifyContent: "" }}>
                   <Box style={{ marginLeft: "35px" }}>
                       <Box style={{marginBottom: "25px"}}>
                           <p>
                            Domain Type. <br /><br/>
                           Which domain type would you like to use?
                           </p>
                       </Box>
                       <Box style={{ display: 'flex', alignItems: 'center',marginBottom: "25px" }}>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="" name="radio-buttons-group">
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel value=""  control={<Radio sx={{color: 'rgb(3, 37, 65)','&.Mui-checked': {color: 'rgb(3, 37, 65)', }}} />} label="" />
                                <p style={{ margin: 0, paddingLeft: '8px' }}>
                                Zed Domain <br />
                                <a>{subDomainName}</a>
                                </p>
                                </Box>
                            </RadioGroup>
                        </FormControl>
                        </Box>
                        <Box>
                            <Divider/>
                        </Box>
                       <form>
                           <Box style={{ display: "flex", marginTop: "15px", justifyContent: 'flex-end' }}>
                               {/* <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick="">Cancel</Button> */}
                               <Box marginLeft="20px">
                               {evoucherStatus ? (
                                <Button
                                    style={{
                                        height: "45px",
                                        width: "125px",
                                        padding: "12.5px 37.4px 12.5px",
                                        borderRadius: "4px",
                                        backgroundColor: "rgb(3, 37, 65)",
                                        color: "#fff",
                                        justifyContent: "right",
                                    }}
                                    type="button"
                                    onClick={() => disableEvoucherStatus()}
                                >
                                    Disable
                                </Button>
                            ) : (
                                <Button
                                    style={{
                                        height: "45px",
                                        width: "125px",
                                        padding: "12.5px 37.4px 12.5px",
                                        borderRadius: "4px",
                                        backgroundColor: "rgb(3, 37, 65)",
                                        color: "#fff",
                                        justifyContent: "right",
                                    }}
                                    type="button"
                                    onClick={() => updateEvoucherStatus()}
                                >
                                    Enable
                                </Button>
                            )}

                               </Box>
                           </Box>
                       </form>
                   </Box>
               </Box>
            <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={message} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

        </div>
    );
};

export { Evoucher };
