import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleUserGroupEdited } from "../../../../features/EditedUserGroups";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/userGroupToUpdate";
import { handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function EditUserGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  const reduxName = useSelector((state) => state.userGroupDetails.name);
  const reduxType = useSelector((state) => state.userGroupDetails.type);
  const reduxDescription = useSelector((state) => state.userGroupDetails.description);

  //value to set when user edits form
  const [name, setName] = useState(reduxName);
  const [type, setType] = useState(reduxType);
  const [description, setDescription] = useState(reduxDescription);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleType = (e) => {
    setType(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});

  const handleUpdate = () => {
    try {
      axios
      .post(baseUrl + "/api/editUserGroup", {
        name:reduxName,
        newName: name,
        newType: type,
        newDescription: description
      })
      .then((response) => {
        let data = response.data.data
        if (data) {
          console.log("updated successfully");
          setLocalDetails({
            name: name,
            type: type,
            description: description,
          });
        } else {
          setFailedMessage(response.data.message)
        }
      });
    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(handleUserGroupEdited(localDetails));
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/UserGroups");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [failMessage, setFailedMessage] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailedMessage(null);
        // dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Edit User Group
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2 point1={"User Groups"} link1={"/UserGroups"} point2={"Edit User Group"} />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Edit User Group Details
            </Typography>
            <TextField
              label="User Group Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              autoFocus
              className={classes.focusedTextField}
              required
            />
            <TextField
              label="User Group Type"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxType}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleType}
              autoFocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDescription}
              className={classes.focusedTextField}
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate('/UserGroups')}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EditUserGroup;
