import { useState } from "react";
import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import PaymentsIcon from '@mui/icons-material/Payments';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function PayitMenu() {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc"

    const businessCat = localStorage.getItem("businessCategory")
    const userGroup = localStorage.getItem('group')

    const handleClick = () => { setOpen(!open); };
    

    return (
        <div>
            <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
                <div style={{ marginRight: '5%' }}>
                    <PaymentsIcon />
                </div>
                {businessCat === "School" &&
                    <ListItemText primary="Zed Pocket Money" style={{ fontFamily: 'Poppins' }} />
                }
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <div style={{ marginLeft: '8%' }}>
                        {userGroup === 'Merchant' || userGroup === 'Accountant' || userGroup === 'Director' || userGroup === "ZPM School Admin" ?
                            <Link style={{ textDecoration: 'none', color: isActiveLink("/school/payitshooladmin") ? activeColor : '#fff' }} to="/school/payitshooladmin">
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Dashboard" />
                                </ListItem>
                            </Link> :
                            null
                        }
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/outlets") ? activeColor : '#fff' }} to="/outlets">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Outlets" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/terminal/users") ? activeColor : '#fff' }} to="/terminal/users">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Terminal users" />
                            </ListItem>
                        </Link>
                        {/* <Link style={{ textDecoration: 'none', color: '#fff' }} to="/terminal/devices">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Device model" />
                            </ListItem>
                        </Link> */}
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/terminal/mngt") ? activeColor : '#fff' }} to="/terminal/mngt">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Terminal Mngt" />
                            </ListItem>
                        </Link>
                       
                    </div>
                </List>
            </Collapse>
        </div>
    )
}