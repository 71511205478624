import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import { stockStyles } from "../stockStyling";
import { SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";
import { useNavigate, useParams } from "react-router-dom";

function ViewAddStockBatch({ storeId, supplierId, warehouseName, supplierName }) {
  const navigate = useNavigate();

  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


  useEffect(() => {
    const stockTakeItems = JSON.parse(localStorage.getItem("addStockProducts"));
    const newArray = stockTakeItems.filter((stock, index) => stock?.changed & stock?.productPrice > 0);
    setPageState({...pageState, data: newArray, count: newArray?.length });
  }, [dataGridPageSize , pageState.page]);

  const [inputValue, setInputValue] = useState("");
  const breadcrumb = [<span>Dashboard</span>, <span>Stock</span>, <span style={{ color: "red" }}>Add Stock</span>, <span>Products</span>, <span style={{ color: "red" }}>Batch details</span>];

  const productsColumn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "inStockQuantity", headerName: "In Stock", flex: 1 },
    { field: "newStock", headerName: "New Quantity", flex: 1, editable: true },
    { field: "productPrice", headerName: "Buying Price", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Delete style={{ color: "red", cursor: "pointer" }} onClick={() => removeProductFromBatch(params?.row?.id)} />;
      },
    },
  ];

  const productsRow = pageState?.data
  ?.filter((product) => {
    return inputValue?.toLocaleLowerCase() === "" ? product : product?.productName?.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase())
  })
  ?.map((prod) => ({
    id: prod?.id || prod._id,
    productName: prod?.productName,
    inStockQuantity: prod?.inStockQuantity,
    newStock: Number(prod?.newStock),
    productPrice: prod?.buyingPrice,
    newStock: prod?.newStock,
  }));

  const removeProductFromBatch = (id) => {
    const removeProducts = [...pageState?.data];
    // get index
    const currentIndex = removeProducts.findIndex((prod) => prod._id === id);
    removeProducts.splice(currentIndex, 1);
    localStorage.setItem("addStockProducts", JSON.stringify(removeProducts));
    setPageState({...pageState,  data: removeProducts, count: removeProducts?.length });
  };

  //   submit batch;
  const newArray = pageState?.data
  ?.map((product) => {
    return {
      productId: product?.id || product._id,
      productName: product?.productName,
      quantity: parseInt(product?.newStock),
      buyingPrice: parseInt(product?.buyingPrice),
    };
  });

  const [isSuccess, setSuccess] = useState({ state: false, message: "" });
  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={"Batch added succesfully!"} />;

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 2000);
    }
  }, [isSuccess.state]);

  const submitBatch = () => {
    const stockFunctions = new StockFunctions();
    
    const body = { supplierId: supplierId || "", warehouseId: storeId, products: newArray };
    if (storeId && newArray?.length > 0) {
      stockFunctions.submitStockBatch(body).then((data) => {
        if (data.status === 201) {
          setSuccess({ state: true });
          localStorage.removeItem("addStockProducts")
        }
        setTimeout(() => {
          navigate(`/stock`);
        }, 2000);
      });
    }
  };

  const clearStage = () => {
    localStorage.removeItem("addStockProducts");
    navigate(-1);
  };

  return (
    <div style={{ width: "100%" }}>
      {isSuccess.state && batchAdded}
      <Grid container>
        <Grid item>{/* <Breadcrumbcomponent breadcrumbs={breadcrumb} /> */}</Grid>
      </Grid>

      <Grid container style={stockStyles?.batchDetailsHolder} width={"100%"} alignItems={"center"} justifyContent={"space-between"} direction={"row"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <span style={{ margin: "0% 0% 0% 0%" }}>Batch No:</span>
          <span style={stockStyles?.submitBatchdisplay}>xxxx</span>
        </Grid>

        <Grid item>
          <span style={{ margin: "0% 5% 0% 0%" }}>Supplier:</span>
          <span style={stockStyles?.submitBatchdisplay}>{supplierName}</span>
        </Grid>

        <Grid item>
          <span style={{ margin: "0% 5% 0% 0%" }}>Warehouse:</span>
          <span style={stockStyles?.submitBatchdisplay}>{warehouseName}</span>
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <SearchFilter setInputValue={setInputValue} placeholder={"Search product"} />
        </Grid>
        <Grid item sx={{ display: "flex", gap: "20px", width: "50%", justifyContent: "flex-end" }}>
          <Button style={stockStyles?.cancelButton} onClick={clearStage}>
            Clear
          </Button>
          <Button style={stockStyles?.viewBatchButton} onClick={() => submitBatch()}>
            Submit Batch
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {/* <CustomDataGrid/> */}
        <CustomDataGrid column={productsColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productsRow} />
      </Grid>
    </div>
  );
}

export { ViewAddStockBatch };
