import { Typography, Grid, Breadcrumbs, Button, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, Select, TextField, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import ExportMenu from "../../../School/ExportMenu";
import DateFormatter from "../../../../utils/dateFormatter";
import HttpComponent from "../../../School/MakeRequest";
import { Exports } from "../../../customerAccounts/customerComponents/exports";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";

//Number Format
let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency}).format(value);


const breadcrumbs = [
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Reports
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Failed Transactions
    </Typography>
]

const inputStyle = {
    width: '100%',
    '& input': {
        border: '1px solid #bdbdbd',
        borderRadius: '4px',
    },
};


export default function FailedPayitCardTransactions() {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [searchValue ,setSearchValue] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("Last7days");
    //data grid options

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    //handle date change
    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last7days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                setStartDate('');
                setEndDate('');
                break;
        }

    }, [handleDaysChange])

    //fetch failed transactions

    function fetchFailedTransactions() {
        try {
            setPageState((old) => ({ ...old, isLoading: true }));
            HttpComponent({
                method: "GET",
                url: `/api/v1/payments/card/payit/failedbyadmin?page=${pageState.page}&limit=${dataGridPageSize}?searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.foundTransactions })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchFailedTransactions()
    }, [dataGridPageSize, pageState.page ,searchValue , startDate , endDate])

    //console.log(pageState?.total , 'llllllll')

     // export products data

     function handleExportCSV() {
        try {
          HttpComponent({
            method: 'GET',
            url: `/api/v1/payments/card/payit/failedbyadminexport?exportData=export&startDate=${startDate}&endDate=${endDate}&searchValue=${searchValue}`,
            token: localStorage.getItem("X-Authorization"),
          }).then((data)=>{
            if(data?.status === 200){
               setSuccessShow({state:true , message:data?.response?.message})
            }else{
              setErrorShow({state:true , message:data?.response?.message})
            }
          })
        } catch (error) {
            setErrorShow({state:true , message:error?.message})
        }
      }


    //transactions columns

    const FailedTransactionColumns = [
        { field: 'rrn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >RRN</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.rrn}</span></>) } },
        { field: 'receiptNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Receipt No</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.receiptNo}</span></>) } },
        { field: 'date', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => { return (<span>{DateFormatter(params?.row?.date)}</span>) } },
        { field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Trxn Type</strong>) }, renderCell: (params) => { return (<span>{params?.row?.transactionType}</span>) } },
        { field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => { return (<span>{params?.row?.status}</span>) } },
        { field: 'message', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Description</strong>) }, renderCell: (params) => { return (<span>{params?.row?.message}</span>) } },
        { field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) }, renderCell: (params) => { return (<span>{params?.row?.tellerId}</span>) } },
        { field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) }, renderCell: (params) => { return (<span>{params?.row?.terminalSerialNumber}</span>) } },
        { field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Total ({localCurrency})</strong>) }, renderCell: (params) => { return (<span>{numberFormat(params?.row?.amount)}</span>) } },

    ]
    // if (bsGroup === 'Admin') {
    //     const extraColum = { headerName: "reorderLevel", field: 'reorderLevel', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Re-order Level</strong>) } }
    //     FailedTransactionColumns.splice(4, 0, extraColum);
    // }

    //download the data in different formats

    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
    useEffect(() => {

        const fileHeaders = [["Receipt No", "Date & Time", "Description", "Teller ID", "Transaction Type", "Status", "Terminal Serialid", "total"]]

        const csvColumns = [
            { label: "Receipt No", key: "RRN" },
            { label: "Date & Time", key: "Date & Time" },
            { label: "Transaction Type", key: "Transaction Type" },
            { label: "Status", key: "Status" },
            { label: "Terminal Serialid", key: "Terminal Serialid" },
            { label: "Total", key: "Total" },
            { label: "Description", key: "Description" },
            { label: "Teller ID", key: "Teller ID" },
        ];


        const fileDataPDF = pageState?.data?.map((transaction) => [
            transaction?.rrn,
            transaction?.date,
            transaction?.message,
            transaction?.userId,
            transaction?.transactionType,
            transaction?.status,
            transaction?.terminalSerialNumber,
            transaction?.amount,


        ])

        const fileData = pageState?.data?.map((transaction) => {
            return {
                "ReceiptNo": transaction?.rrn,
                "Date": transaction?.date,
                "Description": transaction?.message,
                "TellerId": transaction?.userId,
                "TransactionType": transaction?.transactionType,
                "Status": transaction?.status,
                "TerminalserialId": transaction?.terminalSerialNumber,
                "Total": transaction?.amount,
            }
        });
        setExportData({ headers: fileHeaders, data: fileDataPDF })
        setCsvExport({ data: fileData, headers: csvColumns })
    }, [pageState.data])

    
    return (
        <Grid display={'flex'} direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
             <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2}>
                <Typography style={{ fontSize: "25px", fontWeight: 600, color: "#032541" }}>
                    Failed Transactions
                </Typography>
            </Grid>
            <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: "Poppins", fontSize: "14px" }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
                <Button>
                    <Exports exportData={exportData} activeTabs={`Failed Transactions`} csvExport={csvExport} sendCSVEmail={handleExportCSV}/>
                    {/* <ExportMenu csvColumns={csvColumns} fileData={fileData} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} title={'Failed Payit Trabnsaction'} fileName={'Failed Payit Transactions'} /> */}
                </Button>
            </Grid>
            <Grid item>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                    <Grid item>
                        <FormControl sx={{ width: '22ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Search Transactions</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={'text'}
                                value={searchValue}
                                onChange={(e)=>setSearchValue(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search Transactions"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} placeholder={'startDate'} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                    placeholder={'endDate'}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'endDate',
                                        style: { inputStyle }
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value=''>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last7days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState?.total}
                    loading={pageState?.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={FailedTransactionColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}