import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../../common/sideBar";
import { NavBar } from "../../common/NavBar";
import { UserNavbar } from "../../common/userNavbar";
import UserSidebar from "../../common/userSidebar";
import SchoolSetup from "../School/SchoolSetup";
import RentalMultiSetup from "../Rental/rentalBusinessSetup";
// import AdminSidebar from "../../common/AdminSidebar";
import AdminRoutes from "./AdminRoutes";
import TransportPartnerSidebar from "../transportpartner/transportpartnersidebar";
import { Grid } from "@mui/material";
import { MTNSidebar } from "../mtn/mtnSidebar";
import { useState } from "react";



const PrivateRoutes = () => {
  const { isLoggedIn } = useSelector((store) => store.user);
  let  businessCat = localStorage.getItem("businessCategory");

  // let auth = {'token':true}

  const group = localStorage.getItem("group");
  const partnerbusinessType = localStorage.getItem('partnerbusinessType')

  const [openMobileSideBar, setOpenMobileSideBar] = useState(false)
	const handleDrawerToggle = () => {
		  setOpenMobileSideBar(!openMobileSideBar);
		};


  return (
    <>
      {isLoggedIn && (
        <>
          {" "}
          {group === "User" ||
          (group !== "User" && businessCat === "School") ||
          (group !== "User" && businessCat === "Rental") ? (
            <>
              {" "}
              <UserSidebar openMobileSideBar={openMobileSideBar} setOpenMobileSideBar={setOpenMobileSideBar}>
                <UserNavbar handleDrawerToggle={handleDrawerToggle} /> <Outlet />{" "}
              </UserSidebar>
            </>
          ) :
            businessCat !== "Rental" && businessCat !== 'Transport' && businessCat !== "School" 
             && group !== "Admin" && group !== "Partner" && group !== 'TransportPartner' && partnerbusinessType !== 'FMCG'? (
              <>
              <Sidebar>
                {" "}
                <NavBar /> <Outlet />{" "}
              </Sidebar>
            </>
          ) : 
          (group === "Admin" || group === "Partner") && partnerbusinessType !== 'FMCG' ? (
            <AdminRoutes />
          ):(group && group === 'Partner' && partnerbusinessType === 'FMCG' ) ? (
            <MTNSidebar>
              <UserNavbar />
              <Outlet />
            </MTNSidebar>
          ):((businessCat === "Transport" && group === 'Merchant' || group === 'Customer') || (group === 'TransportPartner'))? (
           <TransportPartnerSidebar>
            <UserNavbar />
             <Outlet/>
           </TransportPartnerSidebar>

          )
          
          :
          (
            // : isLoggedIn && group !== 'User' && group !== "Cashier" && businessCat === 'School' && (setup && setup != "PARENT") ?
            //       (<> <UserSidebar> <UserNavbar/> <SchoolSetup/> </UserSidebar></>)
            //       : isLoggedIn && group !== 'User' && group !== "Cashier" && businessCat === 'Rental' && (setup && setup != "TENANT") ?
            //           (<> <UserSidebar> <UserNavbar/> <RentalMultiSetup/> </UserSidebar></>)   :
            <>
              {" "}
              <NavBar /> <Outlet />{" "}
            </>
          )}{" "}
        </>
      )}

      {}
      {}
      {!isLoggedIn && <Navigate to="/" />}
    </>
  );
};

export default PrivateRoutes;

// import { Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Sidebar from "../../common/sideBar";
// import { NavBar } from "../../common/NavBar";

// const PrivateRoutes = () => {
//   const { isLoggedIn } = useSelector((store) => store.user);
//   const group = localStorage.getItem("group");
//   // let auth = {'token':true}

//
// };

// export default PrivateRoutes;
