import React from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Title = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(0),
}));

const ReusableModal = ({ open, onClose, title, children, width }) => {
    return (
        <Dialog open={open} onClose={onClose}  sx={{ "& .MuiDialog-paper": { minWidth: width, borderRadius:"20px" } }}>
            {title && (
                <Title>
                    <Typography variant="h6" component="span" sx={{alignSelf:"center"}}>
                        {title}
                    </Typography>
                    <CloseButton onClick={onClose}>
                        <CloseIcon />
                    </CloseButton>
                </Title>
            )}
            {children}
        </Dialog>
    );
};

export default ReusableModal;

