import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import PaymentsIcon from '@mui/icons-material/Payments';


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function PayitAdmin() {
    const classes = useStyles();
    const [open, setOpen] = useState(false)

    const handleClick = () => { setOpen(!open); };

    return (
        <div>
            <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
                <div style={{ marginRight: '5%' }}>
                 <PaymentsIcon/>
                </div>
                <ListItemText primary="Zed Pocket Money" style={{ fontFamily: 'Poppins' }} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <div style={{ marginLeft: '8%' }}>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/payit/payitadmin">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/outlets">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Outlets" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/terminal/users">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Terminal users" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/terminal/devices">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Device model" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/terminal/mngt">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Terminal Mngt" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/cards/cardbins">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Card Bins" />
                            </ListItem>
                        </Link>
                        </div>
                    </List>
            </Collapse>
        </div>
    )
}