import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceName: "",
    deviceType: "",
    version: "",
};

const modelToUpdatedSlice = createSlice({
  name: "DetailsOfModel",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.deviceName = action.payload.deviceName;
      state.deviceType = action.payload.deviceType;
      state.version = action.payload.version;
    },
  },
});

export const { setDetails } = modelToUpdatedSlice.actions;

export default modelToUpdatedSlice.reducer;
