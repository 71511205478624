import { Box, Breadcrumbs, Button, ButtonBase, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Grid } from '@mui/material'
import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from "./ExportMenu";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
//import Form from "react-bootstrap/Form";
//import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import CustomSearchInput from "./CustomSearchInput";
import CustomTable from "./CustomTable";
import CustomSelectField from "./CustomSelectField";
import CustomInputLabel from "./CustomInputLabel";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import DateFormatter from "../../utils/dateFormatter";




let baseUrl = process.env.REACT_APP_BASE_URL;
const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Payment Report
  </Typography>
];

const createButton = {
  // "width": "15.313rem",
  // "height": "2.813rem",
  "width": "125px",
  "height": "45px",
  "borderRadius": "5px",
  "border": "solid 1px #002543",
  "backgroundColor": "#fff",
  "color": "#032541",
  '&:hover': {
    backgroundColor: '#fff',
    color: '#032541'
  }
}

let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
  localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);

const PaymentReport = () => {

  let navigate = useNavigate();
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
  const [coursesOptions, setCoursesOptions] = useState([]);


  const [year, setYear] = useState('');


  const years = Array.from({ length: 5 }, (_, index) => `${new Date().getFullYear() - index}`);

  const handleChange = (event) => {
    setYear(event.target.value);

  };
  const [pageSize, setPageSize] = React.useState(10);
  const [dropDown, setDropDown] = useState("7days");

  const [grade, setGrade] = useState('')
  const [term, setTerm] = useState('')
  const [courseName, setCourseName] = useState('')
  const [invoiceStatus, setinvoiceStatus] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [stream, setStream] = useState('');

  const [filteredData, setFilteredData] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [dataStudent, setDataStudentDetails] = useState([])
  const [selectedYear, setSelectedYear] = useState('')


  //add days to current date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  //date range Filter setters and Getters
  const [dValue, onDValueChange] = useState([moment(new Date()).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD")]);

  // date range filter
  const filterByDateRange = (data) => {
    return data.filter((item) =>
      dropDown === "range"
        ? item.transtime >= moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") && item.transtime <= moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD")
        : item.transtime >= dValue[0] && item.transtime <= dValue[1]
    );
  };

  // handle drop down change
  const dropChange = (event) => {
    setDropDown(event.target.value);
  };

  // handle delay
  function delayChange() {
    setDropDown("Today");
  }

  // handle custom range
  const handleRange = () => {
    const x = document.getElementById("rangeDiv");
    if (x.style.display === "none") {
      x.style.display = "flex";

      // work on open calendar
    } else if (x.style.display === "flex") {
      x.style.display = "none";
      setIsOpen(false);
      setTimeout(delayChange, 5000000);
    } else {
      x.style.display = "none";
    }
  };

  //const dp open state
  const [isOpen, setIsOpen] = useState(false);


  //use Effect
  useEffect(() => {
    if (dropDown === "Today") {
      onDValueChange([moment(new Date()).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "YesterDay") {
      onDValueChange([moment(addDays(new Date(), -1)).format("YYYY-MM-DD"), moment(addDays(new Date(), 0)).format("YYYY-MM-DD")]);
    } else if (dropDown === "7days") {
      onDValueChange([moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"), moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "this Month") {
      onDValueChange([moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"), moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "last Month") {
      onDValueChange([
        moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1)).format("YYYY-MM-DD"),
        moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"),
      ]);
    } else if (dropDown === "range") {
      setIsOpen(true);
      handleRange();
      onDValueChange([
        moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1)).format("YYYY-MM-DD"),
        moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"),
      ]);
    }
  }, [dropDown]);

  useEffect(() => {
    let filteredData = rows;

    filteredData = filterByDateRange(filteredData);
    // filteredData = filterByPaymentType(filteredData);
    // filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [dValue]);



  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    // search : searchValue
  });

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541", }}>Invoice No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {/* /school/invoice/INV_75102 */}

              <Link style={{color:'#032541'}} to={`${`/school/invoice/${params.value}`}`}>
                {params.value}
              </Link>

            </Typography>

          </div>
        );
      }
    },
    {
      field: "studentName",
      headerName: "student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Student Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Grade</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "stream",
      headerName: "Stream",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Stream</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "parentName",
      headerName: "Parent Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Parent Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceAmount",
      headerName: "invoiceAmount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoice Amount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceDiscountAmount",
      headerName: "Discount Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Discount Amount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params?.row?.invoiceDiscountAmount)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "amountPaid",
      headerName: "amountPaid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Amount Paid</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {numberFormat(params.row?.amountPaid)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "datePaid",
      headerName: "Date Paid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Date Paid</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {DateFormatter(params?.row?.datePaid)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "receiptNo",
      headerName: "Receipt No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Receipt No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {params?.row?.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceBalance",
      headerName: "Invoice Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoice Balance</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceStatus",
      headerName: "invoice Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoice Status</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value == 'Paid' ? <span style={{ color: "#00B87C" }}>{params.value}</span> : <span style={{ color: "#F9B75D" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    }


  ]
  const columnsCollege = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541", }}>Invoice No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {/* /school/invoice/INV_75102 */}

              <Link to={`${`/school/invoice/${params.value}`}`}>
                {params.value}
              </Link>

            </Typography>

          </div>
        );
      }
    },
    {
      field: "studentName",
      headerName: "student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Student Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Course</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },



    {
      field: "invoiceAmount",
      headerName: "invoiceAmount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoice Amount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "invoiceDiscountAmount",
      headerName: "Discount Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Discount Amount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {numberFormat(params?.row?.amountPaid)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "datePaid",
      headerName: "Date Paid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Date Paid</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {DateFormatter(params?.row?.datePaid)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "receiptNo",
      headerName: "Receipt No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Receipt No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {params?.row?.receiptNo}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "amountPaid",
      headerName: "amountPaid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Amount Paid</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

            {numberFormat(params.row?.amountPaid)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceBalance",
      headerName: "Invoice Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoice Balance</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceStatus",
      headerName: "invoice Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoice Status</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value == 'Paid' ? <span style={{ color: "#00B87C" }}>{params.value}</span> : <span style={{ color: "#F9B75D" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    }


  ]


  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [selectedSchoolType, setSelectedSchoolType] = useState()
  // get school types
  const [allSchoolCategory, setAllSchoolCategory] = useState([])

  function GetType_school() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/config/school_classification`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 201) {
          setAllSchoolCategory(data?.response?.data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetType_school()
  }, [])

  let paymentURL = `/api/v1/reports/student_payments?status=Paid&page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`
   if(selectedSchoolType){
    paymentURL=`/api/v1/reports/student_payments?status=Paid&page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}&classification=${selectedSchoolType}`
   }

  //payment details
  const getStudentDetails = async () => {
    try {
      HttpComponent({
        method: "GET",
        url:paymentURL,
        token: X_Authorization,
      }).then((data) => {
        console.log(data.response.data ,'data.response.data')
        setDataStudentDetails(data.response.data)
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: data.response.data,
          total: data.response.data.count,
        }));

      })
    } catch (error) {
      console.log(error, 'Error data');

    }
  }

  const getPaymentData = async () => {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/v1/reports/student_payments?status=Paid&page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
        token: X_Authorization,
      }).then((data) => {
        console.log('payment data here', data.response);
        setPaymentData(data.response)
      })
    } catch (error) {
      console.log(error, 'Error data');

    }
  }



  useEffect(() => {
    getStudentDetails()
  }, [dValue, pageState.page, pageState.pageSize, searchValue ,paymentURL , selectedSchoolType])

  useEffect(() => {
    getPaymentData()
  }, [pageState?.page ])


  const navigateInvoiceNumber = (invoiceNumber) => {
    navigate(`/school/invoice/${invoiceNumber}`);
  }

  //console.log(pageState.data ,'pageState')

  const rows = pageState?.data?.filter((item) => {
    return courseName === '' ? item : courseName === 'ALL' ? item : item?.grade.toLocaleLowerCase().includes(courseName.toLocaleLowerCase())

  })
    .filter((item) => {
      return grade === '' ? item : grade === 'ALL' ? item : item?.grade.includes(grade)

    })
    .filter((item) => {
      return term === '' ? item : term === 'ALL' ? item : item?.term.includes(term)
    }).filter((item) => {
      return selectedYear === '' ? item : selectedYear === 'all' ? item : item?.year?.toLocaleLowerCase().includes(selectedYear?.toLocaleLowerCase())
    })
    .filter((item) => {
      return invoiceStatus === '' ? item : invoiceStatus === 'ALL' ? item : item?.invoiceStatus.includes(invoiceStatus)

      // new Date(item.dateOfAdmission).getFullYear() === year
    }).filter((item) => {
      console.log("new Date(item.dateOfAdmission)", new Date(item.dateOfAdmission).getFullYear());
      let filterDate = new Date(item?.dateOfAdmission).getFullYear().toString();
      return year === '' ? item : filterDate === year;
    })
    .filter((item) => {
      return searchValue?.toLowerCase() === "" ? item : item?.invoiceNumber?.toLowerCase().includes(searchValue?.toLowerCase())
    }).
    filter((item) => {
      return stream === "" ? item : item?.stream.includes(stream)
    })
    // .filter((item) =>
    // dropDown === "range"
    //   ? item.transtime >= moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") && item.transtime <= moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD")
    //   : item.transtime >= dValue[0] && item.transtime <= dValue[1])
    .map((item, index) => {
      return {
        id: index,
        parentId: item.parentId,
        studentId: item.studentId,
        studentNumber: item.studentNumber,
        studentName: item.studentName,
        grade: item.grade,
        term: item.term,
        stream: item.stream,
        parentName: item.parentName,
        phone: item.phone,
        invoiceBalance: item.invoiceBalance,
        invoiceAmount: item.invoiceAmount,
        invoiceNumber: item.invoiceNumber,
        amountPaid: item.amountPaid,
        invoiceStatus: item.invoiceStatus,
        invoiceDiscountAmount:item?.invoiceDiscountAmount,
        datePaid:item?.datePaid,
        receiptNo:item?.receiptNo,
      }
    })

    // console.log('pageState?.data rows ========<><>', rows);

  const csvStudentColumns = [
    { label: "Invoice No", key: "Invoice No" },
    { label: "Student Name", key: "Student Name" },
    { label: "Grade", key: "Grade" },
    { label: "Stream", key: "Stream" },
    { label: "Parent Name", key: "Parent Name" },
    { label: "Invoice Amount", key: "Invoice Amount" },
    { label: "Amount Paid", key: "Amount Paid" },
    { label: "Invoice Balance", key: "Balance" },
    { label: "Invoice Status", key: "Invoice Status" },
  ]
  const csvStudentColumnsCollege = [
    { label: "Invoice No", key: "Invoice No" },
    { label: "Student Name", key: "Student Name" },
    { label: "Course", key: "Grade" },
    { label: "Invoice Amount", key: "Invoice Amount" },
    { label: "Amount Paid", key: "Amount Paid" },
    { label: "Invoice Balance", key: "Balance" },
    { label: "Invoice Status", key: "Invoice Status" },
  ]




  const newstudentFileData = dataStudent.map((item) => {
    return {
      "Invoice No": item.invoiceNumber,
      "Student Name": item.studentName,
      "Grade": item.grade,
      "Stream": item.stream,
      "Parent Name": item.parentName,
      "Invoice Amount": item.invoiceAmount,
      "Amount Paid": item.amountPaid,
      "Invoice Balance": item.invoiceBalance,
      "Invoice Status": item.invoiceStatus,
    }
  })
  const newstudentFileDataCollege = dataStudent.map((item) => {
    return {
      "Invoice No": item.invoiceNumber,
      "Student Name": item.studentName,
      "Course": item.grade,
      "Invoice Amount": item.invoiceAmount,
      "Amount Paid": item.amountPaid,
      "Invoice Balance": item.invoiceBalance,
      "Invoice Status": item.invoiceStatus,
    }
  })

  const studentFileData = dataStudent.map((item) => {
    return {
      invoiceNumber: item.invoiceNumber,
      studentName: item.studentName,
      grade: item.grade,
      stream: item.stream,
      parentName: item.parentName,
      invoiceAmount: item.invoiceAmount,
      amountPaid: item.amountPaid,
      invoiceBalance: item.invoiceBalance,
      invoiceStatus: item.invoiceStatus,
    }
  })
  const studentFileDataCollege = dataStudent.map((item) => {
    return {
      invoiceNumber: item.invoiceNumber,
      studentName: item.studentName,
      grade: item.grade,
      invoiceAmount: item.invoiceAmount,
      amountPaid: item.amountPaid,
      invoiceBalance: item.invoiceBalance,
      invoiceStatus: item.invoiceStatus,
    }
  })


  const fileDataStudentHeaders = [["Invoice No", "Student Name", "Grade", "Stream", "Parent Name", "Invoice Amount", "Amount Paid", "Invoice Balance", "Invoice Status"]];
  const fileDataStudentHeadersCollege = [["Invoice No", "Student Name", "Course", "Invoice Amount", "Amount Paid", "Invoice Balance", "Invoice Status"]];
  const fileDataStudentPDF = dataStudent.map((item) => [item?.invoiceNumber, item?.studentName, item?.grade, item?.parentName, item?.stream, item?.invoiceAmount, item?.amountPaid, item?.invoiceBalance, item?.invoiceStatus])
  const fileDataStudentPDFCollege = dataStudent.map((item) => [item?.invoiceNumber, item?.studentName, item?.grade, item?.invoiceAmount, item?.amountPaid, item?.invoiceBalance, item?.invoiceStatus])
  const [gradeOptions, setGradeOptions] = useState([])
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);

  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Grades");

    if (response.status === 201) {
      setGradeOptions(data.data.map((itemGrade) => {
        return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
      }
      ))
    }
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatusOptions(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      // setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])
  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses");
    if (response.status === 200) {
      // setCoursesOptions(data.data);
      setCoursesOptions(data.data.map((courses) => {
        return { value: courses.courseName, label: courses.courseName }
      }
      ))
    }
  };

  useEffect(() => {
    getCourses();
  }, []);
  const businessName = localStorage.getItem('businessName')

  return (
    <>
      <Box component="div">
        <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box component="div">
            <Box component="div" sx={{}}>
              <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                Payment Report
              </Typography>
            </Box>
            <Box component="div" sx={{}}>
              <Breadcrumbs
                separator={
                  <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>
          </Box>
        </Box>
        <Box component='div' sx={{ alignItems: "center", display: "flex" }} className="mt-3">
          <div className="" style={{ display: "flex", alignItems: "center",width:"100%", justifyContent: "space-between", }}>
            <div style={{ alignItems: "center", display: "flex" , width:"100%" }}>
              <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search by Admission...'} sx={{ marginRight: "20px" }} />
              {schoolTypeName === 'Kindergarten/Junior/High School' ?
              <FormControl style={{ width: "200px", marginLeft: "10px", marginRight:"10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
              <InputLabel id="year-select-label">School Type</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={selectedSchoolType}
                label="School Type"
                onChange={(e) => setSelectedSchoolType(e.target.value)}
              >
                <MenuItem value="">
                  Select School
                </MenuItem>
                {allSchoolCategory.map((school) => (
                  <MenuItem key={school} value={school}>
                    {school}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>:null}
              {schoolTypeName?.includes("University") ?
                <CustomSelectField value={courseName} onChange={(e) => setCourseName(e.target.value)} name={"courseName"} placeholder={"select CourseName"} options={coursesOptions} sx={{ marginRight: "20px" }} />
                : <CustomSelectField style={{width:"200px"}} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"select Grade"} options={gradeOptions} sx={{ marginRight: "20px" }} />}
              {schoolTypeName?.includes("University") ? <CustomSelectField style={{width:"200px"}} value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} name={"grade"} placeholder={"select Year"} options={gradeOptions} sx={{ marginRight: "20px" }} />
                : <CustomSelectField value={stream} style={{width:"200px"}} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"select Stream"} options={streamsOptions} sx={{ marginRight: "20px" }} />}


              {/* <Form.Select
                  size="sm"
                  aria-label="Default select example"
                  style={{
                    borderRadius: "6px",
                    fontSize: "12px",
                    color: "#272d3b",
                    width: "220px",
                    height: "36px",
                    padding: "0px 0px 0px 17px",
                    border: "solid 1px #b4bcc4",
                    backgroundColor: "#fff",
                  }}
                  value={dropDown}
                  onChange={dropChange}
                >
                  <option value="Today">Today</option>
                  <option value="YesterDay">Yesterday</option>
                  <option value="7days">Last 7 Days</option>
                  <option value="this Month">This Month</option>
                  <option value="last Month">Last Month</option>
                  <option value="range">Custom range</option>
                </Form.Select>
                <div value={dropDown} onChange={dropChange} style={{ display: "none" }} id="rangeDiv">
                  <DateRangePicker maxDate={new Date()} isOpen={isOpen} onCalendarClose={handleRange} value={dValue} onChange={onDValueChange} />
                </div> */}

            </div>
            <div className="">
              <ExportMenu
                csvColumns={schoolTypeName?.includes("University") ? csvStudentColumnsCollege : csvStudentColumns}
                fileData={schoolTypeName?.includes("University") ? newstudentFileDataCollege : newstudentFileData}
                fileHeaders={schoolTypeName?.includes("University") ? fileDataStudentHeadersCollege : fileDataStudentHeaders}
                fileDataPDF={schoolTypeName?.includes("University") ? newstudentFileDataCollege : newstudentFileData}
                title={"BusinessName :" + businessName + "Report Type: " + "   Payment Report  " + "Generated At : " + new Date().toLocaleDateString()}
                fileName={"Payment Report"}
              />
            </div>


          </div>


        </Box >


        < Box component={'div'} className="mt-5" sx={{ position: 'relative' }
        }>
          <DataGrid style={{ height: "500px", width: "auto", overflow: "visible", }}
            rows={rows}
            components={{ NoRowsOverlay: NoRowsOverlay }}

            onRowClick={(row) => {
              // handle row click event here
              console.log("Clicked row:", row.row.invoiceNumber);
              navigateInvoiceNumber(row.row.invoiceNumber);
            }}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            columns={schoolTypeName?.includes("University") ? columnsCollege : columns}
            onPageChange={(newPage) => {
              setPageState((old) => ({
                ...old,
                page: newPage + 1,
                pageSize: pageSize,
              }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
          <Box sx={{ display: 'flex', width: '95%', marginTop: '20px', justifyContent: 'flex-end' }}>
            <Typography style={{ fontWeight: 900, fontSize: '12px', marginRight: '50px' }}>Total Invoice Amount: {paymentData?.invoiceAmountRequested ? numberFormat(paymentData?.invoiceAmountRequested) : 0}</Typography>
            <Typography style={{ fontWeight: 900, fontSize: '12px' }}>Total Paid: {paymentData?.InvoicetotalAmountPaid ? numberFormat(paymentData?.InvoicetotalAmountPaid) : 0}</Typography>
            <Typography style={{ marginLeft: '35px', fontWeight: 900, fontSize: '12px' }}>Total Balance: {paymentData?.InvoiceTotalBalance ? numberFormat(paymentData?.InvoiceTotalBalance) : 0}</Typography>
          </Box>
        </ Box>
      </Box >
      <></>
    </>
  )
}

export default PaymentReport;