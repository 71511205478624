import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import startOrderFunction from "./posmethods";
import { CustomizedDate } from "../../customerAccounts/customerComponents/customDate";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PosHeaders = ({ showRequestPayButton , setNavigateToPayment, setDateFrom, setDateTo }) => {
  // initailize;
  const startOrderFunctions = new startOrderFunction();
  const navigate = useNavigate();
  // useEffect(() => {
  //   startOrderFunctions.getAllTransactions().then((data) => {
  //     // console.log("response ===>", data.response);
  //   });
  // }, []);



  
  return (

      <Box sx={{ display: "flex", margin: "0 0 10px 0", alignItems: "center", gap: "10px" , width:'100%'}}>
        <Box sx={{display:'flex', gap:'10px', flex:3 }}>
          {/* <TextField
          placeholder="Search..."
            InputProps={{
              sx: {
                border: "1px solid #d8dee2",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #d8dee2",
                },
              },
            }}
          /> */}

          <CustomizedDate styles={{ border: "1px solid #d8dee2", "& .MuiOutlinedInput-notchedOutline": { border: "none" } }} setDateFrom={setDateFrom} setDateTo={setDateTo} />
        </Box>

        
        <Box sx={{ display: "flex", gap: "5px", flex:1 }}>
          {
            showRequestPayButton && <Button onClick={() =>  setNavigateToPayment(true)} size={"large"} style={{ backgroundColor: "black", color: "white" }}>
            Request Payment
          </Button>
          }

          <Button size={"large"} onClick={() => navigate(`/orders/startorder`)} style={{ backgroundColor: "black", color: "white" }}>
            Start Order
          </Button>
        </Box>
      </Box>

  );
};

export default PosHeaders;
