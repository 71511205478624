import Dash from "../../../common/dash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, Table, TableCell, TableHead, TableRow ,Checkbox, Box, FormControl, TextField, Select, MenuItem } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import { format } from "date-fns";
import { ReceivedPayment } from "../../Modals/Invoice/receivedPayment";
import { sumBy } from "lodash";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { Add } from "@mui/icons-material";
import { AddCircle, Delete } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { removeInvoiceItems } from "../../../features/startorderSlice";
import html2pdf from 'html2pdf.js';
import CustomeronAccountFunctions from "./customerOnAccountFunctions";
import { currencyconverter } from "../../../common/currencyconverter";

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  address_text_common: {
    fontSize: "13px",
    fontWeight: "300",
  },
  table_titles: {
    fontSize: "14px",
    fontWeight: "700",
  },
  table_body: {
    fontSize: "13px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  footer_text: {
    fontSize: "10px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  totals: {
    fontSize: "14px",
    padding: theme.spacing(0.25),
  },
  p1: {
    padding: theme.spacing(0.25),
  },
  p2: {
    padding: theme.spacing(0.5),
  },
  p3: {
    padding: theme.spacing(1),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  m1: {
    margin: theme.spacing(1),
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
}));

const InvoicePreview = (props) => {

  const setInvoiceItemsShow = props.setInvoiceItemsShow;
  const setPreviewInvoiceItemsShow = props.setPreviewInvoiceItemsShow
  const invoiceArrayProduct = props?.invoiceArrayProduct;
  const customerInfo = props?.customerInfo;
  let newCustomerId = props?.newCustomerId;
  const setAddItems = props?.setAddItems
  const dispatch = useDispatch();
  const [fromStartOrder , setFromStartOrder] = useState(false);
  const [newInvoiceArrays, setInvoiceItemsArray] = useState(invoiceArrayProduct);
  const [customId, setCustomId] = useState("");
  const queryParams = decodeURIComponent(window.location.search);
  newCustomerId = newCustomerId ? newCustomerId : customId
  const [startOrdersItems, setStartOrderItems ] = useState([])
  useEffect(() => {
    if(!invoiceArrayProduct){
      const startOrderItems = JSON.parse(localStorage.getItem("startOrderItems"))
  
      const InvoiceArray = startOrderItems?.map((prod) => {
        return      {
          productId: prod.checkedProductId,
          amount: prod.totalAmount/prod.itemCount,
          quantity: prod.itemCount
        }
      })
      setInvoiceItemsArray(startOrderItems)
      setStartOrderItems(InvoiceArray)
      setFromStartOrder(true)

      const queryData = decodeURIComponent(window.location.search).slice(1);

      const  [ customerId ] = queryData?.split("?")
      setCustomId(customerId)
    }

  }, [invoiceArrayProduct, queryParams])


  const userGroup = localStorage.getItem('group');
  const customerOnAccountsMethods = new CustomeronAccountFunctions();
  const canPerformDiscounting = userGroup && userGroup !== 'Customer';
  const [salesOrPurchaseInvoice, setSalesOrPurchaseInvoice] = useState("");
  const [showDiscount, doShowDiscount] = useState(false);
  const [discountNames, setDiscountNames] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [showDiscountButton, setShowDiscountButton] = useState(false);
  const [discountName, setDiscountName] = useState("");
  const [otherDiscount, setOtherDiscount] = useState("")
  const [discountValue, setDiscountValue] = useState(0);
  const [discountDetails, setDiscountDetails] = useState({ invoiceNumber: "", discountName: "", discountType: "", discountAmount: 0, discountPercent: 0 });

  const [newInvoiceArray, setNewInvoiceArray] = useState([])
  // save customer;
  useEffect(() => {

    const InvoiceArray = newInvoiceArrays?.map((prod) => {
      return      {
        productId: prod.checkedProductId,
        amount: prod.totalAmount/prod.itemCount,
        quantity: prod.itemCount
      }
    })

    setNewInvoiceArray(InvoiceArray)
 
  }, [])


  useEffect(() => {
    if (discountName === "Other") {
      setDiscountDetails({ ...discountDetails, discountName: otherDiscount })
    }
  }, [otherDiscount , discountName])



  const getDiscount = (discValue) => {
    setDiscountType(discValue);
  };


  const getDiscountName = () => {
    customerOnAccountsMethods.getDiscountName().then((data) => {
      if (data?.status === 200) {
        setDiscountNames(data?.response?.data);
      }
    });
  };

  useEffect(() => {
    getDiscountName();
  }, []);

  // get discount type;

  const getDiscountType = () => {
    customerOnAccountsMethods.getDiscountType().then((data) => {
      if (data?.status === 200) {
        setDiscountTypes(data?.response?.data);
      }
    });
  };

  useEffect(() => {
    getDiscountType();
  }, []);


  const invoiceTotals = sumBy(newInvoiceArrays, (invoiceArray) => {
    return invoiceArray.totalAmount;
  });


  useEffect(() => {
    setDiscountDetails({ ...discountDetails, discountName: discountName, discountType: discountType, discountAmount: 0, discountPercent: 0 });

    if (discountType === "Percentage") {
      setDiscountDetails({ ...discountDetails, discountName: discountName, discountAmount: 0, discountPercent: discountValue, discountType: discountType });
      const discAmount = Number(invoiceTotals * (discountValue / 100));
      console.log(discAmount , 'discountValue')

      if (Number(discountValue) >= 100) {
        setDiscount(0);
      }
      setDiscount(discAmount);
    } else if (discountType === "Amount") {
      setDiscountDetails({ ...discountDetails, discountName: discountName, discountAmount: discountValue, discountPercent: 0, discountType: discountType });
      if (Number(discountValue) >= Number(invoiceTotals)) {
        setDiscount(0);
      } else {
        setDiscount(Number(discountValue));
      }
    }
  }, [discountType, discountValue]);

  useEffect(() => {
    if (discountDetails.discountName && discountDetails.discountType && discountValue && discountType) {
      setShowDiscountButton(true);
    } else if (discountDetails.discountName == "Discount Name" || discountDetails.discountType === "" || discountValue === 0 || discountType === "") {
      setShowDiscountButton(false);
    }
  }, [discountDetails.discountName, discountDetails.discountType, discountValue, discountType]);

  useEffect(() => {
    if (showDiscount === false) {
      setDiscountDetails({ ...discountDetails, discountName: "", discountAmount: "", discountPercent: 0, discountType: "" });
    }
  }, [showDiscount])

  console.log(discountDetails , 'discountDetails')


  const classes = useStyles();
  const navigate = useNavigate();
  const date = new Date();
  const formattedDate = format(date, "EEEE, MMMM dd, yyyy");
  const [businessName, setBusinessName] = useState(localStorage.getItem("businessName"));
  const [invoiceData, setInvoiceData] = useState(JSON.parse(localStorage.getItem("invoice_data")));
  const [businessInfo, setBusinessInfo] = useState();
  const [responseData, setResponseData] = useState();
  const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);

  const downloadPDF = () => {
    const input = document.getElementById("downloadable");
    html2pdf(input, {
      margin: 10,
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }).from(input).save();
  };
  const back = () => {
    navigate(-1);
  };

  const getBusinessDetails = async () => {
    try {
      const businessDetailsResponse = await fetch(baseUrl + "/api/get_business_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (businessDetailsResponse.status === 200) {
        const businessDetailsData = await businessDetailsResponse.json();
        console.log("business data ", businessDetailsData.data);
        setBusinessInfo(businessDetailsData.data);
      }
    } catch (error) {
      console.error("error fetching customer details", error.message);
    }
  };
 
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [error, setError] = useState({ state: false, message: "" });
  console.log("url", props.customerId, newCustomerId, props.invoiceType, props.frequency, newInvoiceArray);

  const saveInvoice = async () => {

    // checkInvoiceProducts()
    let url = baseUrl + "/api/generateInvoice";
    console.log("url", props.customerId, newCustomerId, props.invoiceType, props.frequency, newInvoiceArray);

    console.log("newInvoiceArray chesksssss", newInvoiceArray)
    const response = await fetch(url, {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        customerId: newCustomerId ? newCustomerId : props.customerId,
        type:props.invoiceType ? props.invoiceType : "one-off",
        frequency:props.frequency? props.frequency : 'once',
        products:newInvoiceArray ? newInvoiceArray : startOrdersItems,
        discountAmount: discountDetails.discountAmount,
        discountName: discountDetails.discountName,
        discountPercent: discountDetails.discountPercent,
        discountType: discountDetails.discountType
      }),
    });
    const responseData = await response.json();
    if(response.status === 200){
      if(fromStartOrder){
        setSucess({ state: true, message: "Invoice has been created and sent to customers email!" });
        localStorage.removeItem("customerOnAccountState")
        localStorage.removeItem("startOrderItems")
        setTimeout(() => {
         setSucess({ state: false });
         navigate(`/customerDetails/${newCustomerId ? newCustomerId : props.customerId }`);
         navigate(`/orders/startorder`);
       }, 2000);
      }else{
        setSucess({ state: true, message: "Invoice has been craeted and sent to customers email!" });
        setTimeout(() => {
         setSucess({ state: false });
         navigate(`/customerDetails/${newCustomerId ? newCustomerId : props.customerId }?Invoice`);
       }, 2000);
      }

    }else if(responseData.status  === "FAILED"){
      console.log("error", responseData.message);
      setError({ state: true, message: responseData.message });
      setTimeout(() => {
        setError({ state: false });
      }, 3000);

      
    }
    console.log("save invoice response data", responseData);
    setResponseData(responseData);
    console.log("response test", responseData.data);
   
  };

  let localCurrency = localStorage.getItem('localCurrency')

  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);

 

  useEffect(() => {
    getBusinessDetails();
  }, []);


  // remove items from array;
  const removeItems = (product) => {
    setInvoiceItemsArray(newInvoiceArrays?.filter((prod) => prod.checkedProductId !== product.checkedProductId))  
  }

  console.log(newInvoiceArrays , 'original newInvoiceArrays')

  
  const [productDiscountAmount, setProductDiscountAmount] = useState({})
  const [calculatedDiscount, setCalculatedDiscount] = useState({})
  const [newSelectedDiscountType, setNewSelectedDiscountType] = useState({})
  const [newProductArray, setNewProductArray] = useState([])


  const handleCalculateProductDiscount = (selectedDiscountType, product, discountAmount) => {
    let initialDiscount = 0;
    let newdiscountPercent = 0;
    if (selectedDiscountType === 'Percentage') {
      initialDiscount = (product?.itemAmount * product?.itemCount) * (discountAmount / 100);
      newdiscountPercent = discountAmount;
    } else if (selectedDiscountType === 'Amount') {
      newdiscountPercent = 0
      initialDiscount = discountAmount;
    }
    console.log('product ====== <><><>', product);
    setCalculatedDiscount(prevState => ({ ...prevState, [product.variationKeyId]: initialDiscount })); // tem => item.variationKeyId !== product.variationKeyId)
    const newProductArrayWithDiscount = newProductArray.filter((item) => item.productId !== product.variationKeyId)
    console.log(newProductArrayWithDiscount , 'newProductArrayWithDiscount')
    const newProduct = {
      discount: initialDiscount,
      discountPercent: newdiscountPercent,
      discountType: selectedDiscountType,
      productId: product.variationKeyId,
      amount: product.itemAmount,
      quantity: product.itemCount,
    };
    setNewProductArray([...newProductArrayWithDiscount, newProduct]);

  };
  const handleDiscountChange = (e, product) => {
    console.log('product ====== <><><>', product, e.target.value);
    const discountAmount = Number(e.target.value)
    setProductDiscountAmount(prevState => ({ ...prevState, [product.variationKeyId]: discountAmount }));
    handleCalculateProductDiscount(newSelectedDiscountType[product.variationKeyId], product, discountAmount);
  };

  const handleDiscountTypeChange = (e, product) => {
    const selectedDiscountType = e.target.value;
    setNewSelectedDiscountType(prevState => ({ ...prevState, [product.variationKeyId]: selectedDiscountType }));
    handleCalculateProductDiscount(selectedDiscountType, product, productDiscountAmount[product.variationKeyId]);
  };


  console.log(newProductArray, 'newProductArray  🚀 🚀🚀');

  // final array with all products with discount or not
  const [finalProductsArry, setFinalproductArray] = useState([])

  useEffect(() => {
    const productsWithDisc = newProductArray
    const productsWithoutDisc = newInvoiceArrays
    let finalArr = []
    productsWithoutDisc?.forEach(product => {
      const productWithDisc = productsWithDisc?.find(prod => prod?.productId === product?.variationKeyId)
      if (productWithDisc) {
        finalArr.push(productWithDisc)
      } else {
        let newProduct = {
          discount: 0,
          discountPercent: 0,
          discountType: '',
          productId: product?.variationKeyId,
          amount: product?.itemAmount,
          quantity: product?.itemCount,
        };
        finalArr.push(newProduct)
      }
      setFinalproductArray(finalArr)
    })
  }, [newProductArray, newInvoiceArrays])

  console.log(finalProductsArry, 'finalProductsArry 🚀 🚀🚀');


  const saveInvoiceWithDiscount = async () => {

    // checkInvoiceProducts()
    let url = baseUrl + "/api/generateInvoice";
    console.log("newInvoiceArray chesksssss", newInvoiceArray)
    const response = await fetch(url, {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        customerId: newCustomerId ? newCustomerId : props.customerId,
        type:props.invoiceType ? props.invoiceType : "one-off",
        frequency:props.frequency? props.frequency : 'once',
        products:finalProductsArry,
        discountName: '',
        discountPercent: 0,
        discountAmount: 0,
        discountType: '',
      }),
    });
    const responseData = await response.json();
    if(response.status === 200){
      if(fromStartOrder){
        setSucess({ state: true, message: "Invoice has been created and sent to customers email!" });
        localStorage.removeItem("customerOnAccountState")
        localStorage.removeItem("startOrderItems")
        setTimeout(() => {
         setSucess({ state: false });
         navigate(`/customerDetails/${newCustomerId ? newCustomerId : props.customerId }`);
         navigate(`/orders/startorder`);
       }, 2000);
      }else{
        setSucess({ state: true, message: "Invoice has been craeted and sent to customers email!" });
        setTimeout(() => {
         setSucess({ state: false });
         navigate(`/customerDetails/${newCustomerId ? newCustomerId : props.customerId }`);
       }, 2000);
      }

    }else if(responseData.status  === "FAILED"){
      console.log("error", responseData.message);
      setError({ state: true, message: responseData.message });
      setTimeout(() => {
        setError({ state: false });
      }, 3000);

      
    }
    console.log("save invoice response data", responseData);
    setResponseData(responseData);
    console.log("response test", responseData.data);
   
  };




  return (
    <>
      <div style={{ width: "80%", marginLeft: "10%" }}>
        <div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ paddingTop: 15, display: "flex" }}>
                <span>
                  <IconButton aria-label="back" onClick={() => {
                    if(fromStartOrder){
                        navigate(-1)
                    }else{
                      setAddItems(newInvoiceArrays)
                      setPreviewInvoiceItemsShow(false)
                    }
                
                  } }>
                    <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                  </IconButton>
                </span>
                <span style={{ fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>Preview Invoice</span>
              </div>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                }}
              >
                {responseData && responseData.status === "SUCCESS" ? (
                  <>
                    <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "transparent", color: "#032541", border: "solid 1px #032541", marginRight: 2 }} onClick={() => setReceivePaymentOpen(true)}>
                      Received Payment
                    </Button>
                    <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}>Resend Invoice</Button>
                  </>
                ) : (
                  <Button style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }} onClick={() => {newProductArray.length > 0 ? saveInvoiceWithDiscount():saveInvoice(); dispatch(removeInvoiceItems())} }>
                    Send Invoice
                  </Button>
                )}
                <span
                  style={{
                    backgroundColor: "rgba(3, 37, 65, 0.05)",
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 5,
                    borderRadius: 5,
                  }}
                >
                  <IconButton aria-label="download" onClick={downloadPDF}>
                    <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                  </IconButton>
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "visible",
                height: "auto",
              }}
              id="downloadable"
            >
              <div
                className={classes.p3}
                style={{
                  backgroundColor: "rgba(220,53,69,0.05)",
                  height: "150px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={classes.p1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "black",
                      fontSize: "40px",
                      fontWeight: "700",
                    }}
                  >
                    INVOICE
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Date:
                  </span>
                  <span style={{ fontSize: "14px" }}>{formattedDate}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#dc3545",
                      fontSize: "40px",
                      fontWeight: "700",
                    }}
                  >
                    Unpaid
                  </span>
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Invoice No:
                  </span>
                  {responseData && responseData.status === "SUCCESS" && <span style={{ fontSize: "14px" }}>{responseData.data.invoiceNumber}</span>}
                </div>
              </div>

              <div
                className={classes.p3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    From:
                  </span>
                  <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                    {businessName}
                  </span>
                  {/*                  <span className={classes.address_text_common}>*/}
                  {/*  Branch: Parklands*/}
                  {/*</span>*/}
                  <span className={classes.address_text_common}>
                    {businessInfo?.businessOwnerAddress}, {businessInfo?.country}
                  </span>
                  <span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
                  <span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
                </div>
                <div
                  className={classes.mt4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                  <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                    Billed to:
                  </span>
                  <span className={classes?.address_text_common}>{customerInfo?.customerName}</span>
                  <span className={classes?.address_text_common}>{customerInfo?.customerEmail}</span>
                  <span className={classes?.address_text_common}>{customerInfo?.customerPhone}</span>
                </div>
              </div>

              <div className={classes.m1} style={{ border: "dotted 1px" }}></div>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.textLeft}>Description</TableCell>
                    <TableCell className={classes.textCenter}>Price ({localCurrency})</TableCell>
                    <TableCell className={classes.textCenter}>Qty</TableCell>
                    <TableCell className={classes.textRight}>Discount</TableCell>
                    <TableCell className={classes.textRight}>Total ({localCurrency})</TableCell>
                    <TableCell className={classes.textRight}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {newInvoiceArrays?.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.textLeft}>
                      <span className={classes.table_body}>{product.itemName}</span>
                    </TableCell>
                    <TableCell className={classes.textCenter}>
                      <span className={classes.table_body}>{numberFormat(product.itemAmount)}</span>
                    </TableCell>
                    <TableCell className={classes.textCenter}>
                      <span className={classes.table_body}>{product.itemCount}</span>
                    </TableCell>
                    <TableCell className={classes.textRight}>
                            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                              <TextField id="outlined-basic" sx={{ width: 100 }}
                                value={productDiscountAmount[product.variationKeyId] || 0}
                                onChange={(e) => handleDiscountChange(e, product)}
                                variant="outlined" />
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <Select
                                  value={newSelectedDiscountType[product.variationKeyId] || ''}
                                  onChange={(e) => handleDiscountTypeChange(e, product)}
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                >
                                  {discountTypes.map((option) => {
                                    return (
                                      <MenuItem value={option} >{option}</MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                              <span style={{ color: '#17ae7b', fontWeight: 600 }}> {currencyconverter(calculatedDiscount[product.variationKeyId] || 0)}</span>
                            </Box>
                          </TableCell>
                    <TableCell className={classes.textRight}>
                      <span className={classes.table_body}>{numberFormat(product.totalAmount)}</span>
                    </TableCell>
                    <TableCell className={classes.textRight}>
                      <span className={classes.table_body}><Delete style={{color:"red", cursor:"pointer"}} onClick={() => removeItems(product)} /></span>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>

              <div style={{display:'flex', alignItems:"center", justifyContent:"center", margin:"10px 0px 0px 0px"}} >
                  <span style={{cursor:'pointer'}} onClick={() => {
                    if(fromStartOrder){
                      navigate(-1)
                    }else{
                      setAddItems(newInvoiceArrays)
                      setPreviewInvoiceItemsShow(false)
                    }
                   
                  }} > <AddCircle/> Add products/Service</span>
                </div>
              <div
                className={classes.p3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: '20px'
                }}
              >
                {canPerformDiscounting  ? <div style={{ width: '70%' }} > 
                    <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                          <Checkbox
                            style={{ color: "#000000" }}
                            onChange={(e) => {
                              doShowDiscount(!showDiscount);
                              setDiscount(0);
                            }}
                          />
                          <span style={{ color: "#000000", fontWeight: "bold" }}>Apply Discount</span>
                        </div>
                    
                      {showDiscount && (
                        <>
                          <div style={{ display: "flex", alignItems: "center", gap: "5px", margin: "0px 0px 2% 0px" }}>
                            <div>
                              <select onChange={(e) => setDiscountName(e.target.value)} style={{ color: "#000000", border: "1px solid #bec5d1", outline: "none", padding: "5px", width: "100%", cursor: "pointer", borderRadius: "5px", backgroundColor: "#ffffff", fontFamily: "Poppins", fontSize: "14px" }}>
                                <option>Discount Name</option>
                                {discountNames?.map((disc) => {
                                  return <option value={disc}>{disc}</option>;
                                })}
                              </select>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <input placeholder="Value" onChange={(e) => setDiscountValue(e.target.value)} style={{ width: "50%", border: "1px solid #bec5d1", outline: "none", padding: "5px" }} />
                              <select onChange={(e) => getDiscount(e.target?.value)} style={{ color: "#000000", border: "1px solid #bec5d1", outline: "none", padding: "5px 5px 5px 20px", width: "100%", cursor: "pointer", borderRadius: "5px", backgroundColor: "#ffffff", fontFamily: "Poppins", fontSize: "14px" }}>
                                <option></option>
                                {discountTypes?.map((type) => {
                                  return <option value={type}>{type}</option>;
                                })}
                              </select>
                            </div>
                          </div>

                          <div style={{ margin: '0px 0px 2% 0px' }}>
                            {
                              discountName === "Other" ? <input placeholder="Description" onChange={(e) => setOtherDiscount(e.target.value)} style={{ width: "50%", border: "1px solid #bec5d1", outline: "none", padding: "5px" }} /> : null
                            }
                          </div>
                              {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={() => (showDiscountButton ? doApplyDiscount() : null)} style={showDiscountButton ? { backgroundColor: "#17ae7b", color: "#ffffff", opacity: 1 } : { backgroundColor: "#17ae7b", color: "#ffffff", opacity: 0.5 }}>
                                  Apply Discount
                                </Button>
                              </div> */}
                        </>
                      )}
                    </div> : 
                    <div style={{ width: '70%' }}></div>
                  }
                <div
                  className={classes.p1}
                  style={{
                    backgroundColor: "#f7f7f7",
                    width: "288px",
                    height: "130px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Subtotal</span>
                    <span>{numberFormat(invoiceTotals)}</span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Discount</span>
                    {/* <span>{discountType === "Percentage" ? }</span> */}
                    <span>{discountDetails.discountAmount ? currencyconverter(discountDetails.discountAmount)  : discountType === "Percentage" ? currencyconverter(discount): currencyconverter(0) }</span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ color: "#dc3545", fontWeight: "bold" }}>Total</span>
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#dc3545",
                        fontWeight: "bold",
                      }}
                    >
                      {discountDetails.discountAmount ? currencyconverter(invoiceTotals - discountDetails.discountAmount) : discountType === "Percentage" ?  currencyconverter(invoiceTotals - discount):  currencyconverter(invoiceTotals)}
                    </span>
                  </div>
                </div>

                
              </div>
{/* 
              <div style={{display:'flex', alignItems:"center", justifyContent:"center"}} >
                  <span style={{cursor:'pointer'}} onClick={() => setPreviewInvoiceItemsShow(false)} > <AddCircle/> Add products/Service</span>
                </div> */}

        

              <div style={{ border: "dotted 1px" }}></div>

              <div className={classes.p3} style={{ display: "flex", flexDirection: "column" }}>
                <div className={classes.p1}>
                  <span
                    style={{
                      color: "#032541",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                  >
                    Accepted Payment Methods
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img className={classes.p2} src={mpesaIcon} alt="M-pesa" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>M-Pesa</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img className={classes.p2} src={cardIcon} alt="Visa" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>Card</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img className={classes.p2} src={creditIcon} alt="Credit" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>Credit Balance</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ border: "dotted 1px" }}></div>

              <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
                  This is a system-generated invoice is created without any alteration whatsoever.
                  <br />
                  Thank you for your business.
                </div>
              </div>
              <div
                className={classes.p2}
                style={{
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className={classes.footer_text}>
                  Powered by{" "}
                  <span
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    ZED Payments Limited
                  </span>
                </span>
                <span className={classes.footer_text}>.</span>
                <span className={classes.footer_text}>info@zed.business</span>
                <span className={classes.footer_text}>.</span>
                <span className={classes.footer_text}>v1.0.2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Received payment*/}

      {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
      {error && <ErrorAlertRightAligned message={error.message} error={error.state} />}

      <ReceivedPayment paymentOpen={receivePaymentOpen} onClose={() => setReceivePaymentOpen(false)} />
    </>
  );
};

export default InvoicePreview;
