import { Typography, Breadcrumbs, Grid, ButtonBase, Button  ,Checkbox, Box ,CircularProgress} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomSearchInput from "../../School/CustomSearchInput";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch, useSelector } from "react-redux";
import HttpComponent from "../../School/MakeRequest";
import { useEffect, useState } from "react";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import lodash from 'lodash'
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { handleChargeService, handleChargesData } from "../../../features/tenantsDataSlice";

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Create Tenant
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Tenant Details
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        House Details
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Advance Charges
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Allocated Houses
    </Typography>
];

export default function ListAllocatedHouses(props) {
    console.log('at lesdt we are here')
    const dispatch = useDispatch()

    function handleGoBack(){
        props?.setFlagGoBack(true);
       
    }

    //tenant id to get  customer details

    const { tenantId, customerType, companyName, firstName, lastName } = useSelector((store) => store.tenantData.tenantBioData)
    const { X_Authorization } = useSelector((store) => store.user)
    const [customerInfo, setCustomerInfo] = useState([])
    const [persistPage, setPersistPage] = useState(true)
    const [tenantInfo ,setTenantInfo] = useState([])
    const [dataGridPageSize, setPageSize] = useState(5);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    // fetch customer data

    //check if the _id inside this object is the same as the id inside the this

    //api/get_houses_by_customer_id
    function fetchCustomerData() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/get_houses_by_customer_id`,
                body: {
                    customerId: tenantId
                },
                token: X_Authorization
            }).then((data) => {
                console.log(data , 'customer info')
                if (data.status === 200) {
                    setCustomerInfo(data.response.data)
                }
            })
        } catch (error) {

        }
    }
console.log(tenantInfo, 'tenant info')
    function fetchTenantInfo(){
		try {
            setPageState((old) => ({ ...old, isLoading: true }));
			HttpComponent({
				method: "GET",
                url: `/api/get_customer_by_customer_id?customerId=${tenantId}`,
                token: X_Authorization
			}).then((data)=>{
                console.log(data)
                if(data.status === 200){
                    setTenantInfo(data.response.data)
                    setPageState((old) => ({
                        ...old,
                        isLoading: false,
                        data: data.response.data,
                        total: data.response.count,
                    }));
                }
			})
		} catch (error) {
			console.log(error)
		}
	}

    useEffect(() => {
        fetchCustomerData()
    }, [])

    useEffect(()=>{
        fetchTenantInfo()
    },[])

  
    const billableIte = lodash?.get(tenantInfo ,'billableItems')

    const extraService = lodash?.map(billableIte ,'extraServices')
  
    //const test = lodash.map(customerInfo ,'extraServices')
   
    //const showOutput = lodash.map(customerInfo, ({ itemNumber, unitType, extraServices }) => ({ itemNumber, unitType, extraServices }));

    const houseTotals = lodash?.sumBy(extraService ,charge => {
        return parseInt(charge?.amount) 
       })

    //dispatch invoice data to redux store
    function UpdateInvoiceData(){
        //dispatch(handleChargesData(test))
        //dispatch(handleChargeService(showOutput))
        props?.saveeHouseiNV(selectedHouse)
        props?.goInv()
    }


    //House service columns

    const [selectedHouse, setselectedHouse] = useState([])
    const [isRowSelected, setIsRowSelected] = useState(false)

    const unitColumns = [
        { field: 'unitType', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >Unit Name</strong>) },
        renderCell:(params)=>{
            const getSelected = (productData) => {
                const isSelected = selectedHouse?.some((house) => house === productData);
                if (!isSelected) {
                    setselectedHouse((prevSelectedHouses) => [...prevSelectedHouses, productData]);
                    setIsRowSelected(true)
                  } else {
                    setselectedHouse((prevSelectedHouses) =>
                      prevSelectedHouses?.filter((house) => house !== productData)
                    );
                    setIsRowSelected(false)
                  }
              };

              console.log(selectedHouse , 'selectedhouses')
            return(
                <div>
                     <Checkbox onClick={()=>getSelected(params?.row)}  style={{ color: isRowSelected ? '#dc3545' : null }}></Checkbox>
                     <span>{params?.row?.unitType}</span>
                </div>
            )
        } },
        { field: 'itemNumber', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >House No</strong>) } },
        {
            field: 'amount', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >Amount</strong>) },
            renderCell: (params) => {
                const total =  params?.row?.extraServices

                const houseTotals = lodash?.sumBy(total ,charge => {
                    return parseInt(charge?.amount) 
                   })

                return (
                    <>{numberFormat(houseTotals)}</>
                )
            }
        },
        {
            field: 'action', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >Action</strong>) }, renderCell: (params) => {
                return (
                <div>
                    <Grid container direction={'row'} justifyContent={'space-between'}>

                    {/*Pencil*/}
                    <Grid item style={{ color: '#032541' }}>
                        <ButtonBase>
                            <ModeEditIcon />
                            <span style={{ marginLeft: '5px' }}>Edit</span>
                        </ButtonBase>
                    </Grid>

                    {/*Delete*/}
                    <Grid item ml={3} style={{ color: '#dc3545' }}>
                        <ButtonBase>
                            <DeleteIcon />
                            <span style={{ marginLeft: '5px' }}>Remove</span>
                        </ButtonBase>
                    </Grid>
                </Grid>
            </div>
                )
            }
        },
    ]


    //spinner

    const Spinner = ()=>{
        return (
        <Box sx={{ display: 'flex' , marginTop:"10px", alignItems:"center", justifyContent:'center' , alignContent:"center" }}>
             <CircularProgress />
        </Box>
        )
    }
   
    return (
        <>
            <Grid container direction={'column'}>
                <Grid item display={'flex'} alignItems={'center'}>
                    <ArrowBackIosIcon />
                    <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Allocated Houses</Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <Grid container direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <Typography variant="body1">
                                    <span style={{ fontWeight: 100, fontSize: "12px" }}>Tenant Name</span>
                                </Typography>
                                <Typography variant="body2" marginLeft={3}>
                                    {customerType.includes('Company') ? <span style={{ color: "#032541", fontSize: "14px" }}>{companyName}</span> :
                                        <>
                                            <span style={{ color: "#032541", fontSize: "14px" }}>{firstName} {lastName}</span>
                                        </>}

                                </Typography>
                            </Grid>
                            <Grid item display={'flex'} alignItems={'center'} marginLeft={6}>
                                <Typography variant="body1">
                                    <span style={{ fontWeight: 100, fontSize: "10px" }}>Customer Type</span>
                                </Typography>
                                <Typography variant="body2" marginLeft={3}>
                                    <span style={{ color: "#032541", fontSize: "14px" }}>{customerType}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CustomSearchInput name={'search'} placeholder={'Search Houses'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>Allocate more units or preview invoice</Typography>
                </Grid>
                {/* TODO : Datagrid to show all the data for the tenant*/}

                <Grid item>
                    {!billableIte ? <Spinner/>:
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[3, 5, 10, 20, 50, 100]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState?.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={unitColumns}
                        rows={billableIte}
                        cellRenderer={() => { return (1 + 1 === 2 ? <div>x</div> : '') }}
                        getRowId={(row) => Math.floor(Math.random() * 100000)}
                    />
          }

                </Grid>
                <Grid item display={'flex'} justifyContent={'flex-end'}>
                   <Button disabled={!isRowSelected} onClick={UpdateInvoiceData} style={{ textTransform: 'none', color: '#fff', background: '#032541', height: '45px', fontSize: '16px', fontWeight: '600',opacity:isRowSelected ? '1':'0.5'  }}>Preview Invoices</Button>
                    <Button onClick={handleGoBack} style={{ border: 'solid 1px #032541', textTransform: 'none', color: '#032541', background: '#fff', height: '45px', marginLeft: "10px", fontSize: '16px', width:"145px" ,fontWeight: '600' }}>Add Unit</Button>
                </Grid>
            </Grid>
        </>
    )
}