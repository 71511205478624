import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PaymentSettingUp from '../paymentmodule/paymentsettingup';
import { ArrowBackIos } from "@mui/icons-material";
import BusinessDetailEcommerce from './businessDetailEcommerce';
import SetWorkFlow from '../Rental/Utility/updateSetupWorkFlow';
import { changeCategory } from '../../features/generalBusinessCategorySlice';
const ZedEcommerceSetup = () => {
    const steps = ['Business Details', 'Payment Options'];
	const [activeStep, setActiveStep] = useState(0);
	const { userId, X_Authorization } = useSelector((store) => store.user);
	const [showActiveStepper ,setShowActiveStepper] = useState(true)
	const [showBreadCrumbs ,setShowBreadCrumbs] = useState(true)
	const dispatch = useDispatch();

	const checkFormStatus = () => {
		HttpComponent({
			method: 'GET',
			url: '/api/get_setup_status',
			body: null,
			token: X_Authorization,
		}).then((data) => {
			console.log("here store is data", data);
			if (data.status === 201) {
				console.log("state", data.response.data.workflowState)
				let state = data.response.data.workflowState;
				if (state === "BASIC") {
					setActiveStep(0)
				} else if (state?.includes("PAYMENTOPTIONS")) {
					setActiveStep(3)
				} 
			} else {

			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		checkFormStatus();
	}, []);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleGoTenant=()=>{
		SetWorkFlow({flowName : 'zedEcommerce', token : localStorage.getItem('X-Authorization')})
		localStorage.setItem("workflow", "ZED_ECOMMERCE")
        localStorage.setItem("group", "Merchant")
        dispatch(changeCategory('ZED_ECOMMERCE'));
		navigate("/dashboard")

		// setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}

	const breadcrumbs = [
		<Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
			Dashboard
		</Typography>,
		<Typography key={steps[activeStep]} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
			{steps[activeStep]}
		</Typography>
	];

	const renderFormContent = () => {
		switch (activeStep) {
			case 0:
				//Business Details
				return (
					<Box component="div" >
						<BusinessDetailEcommerce next={handleNext} />
					</Box>
				);
			case 1:

            return (
                <Box component="div">
                    <PaymentSettingUp next={handleGoTenant} />
                </Box>
            )
			
			default:
				return null;
		}
	};
	const navigate = useNavigate();
	return (
		<Box component="div" sx={{ fontFamily: 'Poppins',  margin: 1, display: "flex", flexDirection: "column" }}>

			{/*Header title*/}
			{showBreadCrumbs &&
				<Grid container justifyContent={'flex-start'} style={{ marginBottom: '20px' }} direction={'column'} spacing={2}>
					<Grid item style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>
                    < ArrowBackIos onClick={() => navigate("/createBusiness")} /><span>Create Business</span>
					</Grid>
					<Grid item>
						<Breadcrumbs
							separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
							aria-label="breadcrumb">
							{breadcrumbs}
						</Breadcrumbs>
					</Grid>
				</Grid>
			}

			{showActiveStepper &&
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps?.map((label, index) => {
						return (
							<Step key={label}>
								<StepLabel StepIconProps={{ classes: { completed: 'step-icon-completed', text: index !== activeStep ? "step-icon-text" : null, }, style: { color: index === activeStep ? '#032541' : '#f5f6f7', width: "2.188rem", height: "2.188rem", } }}>
									{label}
								</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			}

			<Box component="div">
				{renderFormContent()}
			</Box>

			
		</Box>
	)
}

export default ZedEcommerceSetup