import React, { useEffect, useState } from "react";
import startOrderFunction from "./posmethods";
import { Box, Button, Paper, Typography } from "@mui/material";
import CustomSearchComponent from "./customSearchComponent";
import CustomProductSkeleton from "./customProductSkeleton";
import { useNavigate } from "react-router-dom";

const CategoryList = ({ setCategoryName, categoryName }) => {
  const queryParams = decodeURIComponent(window.location.search).split("?")[1];
  const [dataGridPageSize, setPageSize] = React.useState(30);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const navigate = useNavigate();
  // search categories;
  const [searchValue, setSearchValue] = useState("");
  const startOrderFunctions = new startOrderFunction();
  useEffect(() => {
    startOrderFunctions.getProductCategories(1, dataGridPageSize, searchValue).then((data) => {
      // category
      setPageState({ ...pageState, isLoading: false, data: data.response.categories, count: data?.response?.count });
    });
  }, [dataGridPageSize, searchValue]);

  //   "#032541"fafafa

  const getScrollPosition = (e) => {
    let scrollableDiv = e.target;
    let scrollPosition = scrollableDiv.scrollTop + scrollableDiv.clientHeight;
    let scrollHeight = scrollableDiv.scrollHeight;
    let totalCategoryCount = pageState?.count;
    let currentCategoryList = pageState?.data?.length;

    setTimeout(() => {
      if (scrollPosition === scrollHeight) {
        if (currentCategoryList < totalCategoryCount) {
          startOrderFunctions.getProductCategories(1, currentCategoryList + dataGridPageSize, searchValue).then((data) => {
            // category
            setPageState({ ...pageState, isLoading: false, data: data.response.categories, count: data?.response?.count });
          });
        }
      }
    }, 200);
  };

  useEffect(() => {

    setCategoryName(queryParams);
  
  }, [queryParams]);
  console.log("pageState.data?.length -->", pageState.data?.length)
  return (
    <Box>
      <Box mb={2}>
        <CustomSearchComponent setSearchValue={setSearchValue} />
      </Box>

      <Box component={Paper} onScroll={getScrollPosition} sx={{ padding: "20px", height: "65vh", overflow: "scroll", display: "flex", cursor: "pointer", gap: "10px", flexWrap: "wrap" }}>
        {pageState.isLoading && <CustomProductSkeleton styles={{ display: "flex" }} arrayLength={dataGridPageSize} heigth={"80px"} width={"100px"} variant={"rectangular"} />}
        {pageState.data?.length === 0 && <Box sx={{display: "flex", alignItems: "center", justifyContent:"center", width:"100%", }}> <Typography sx={{fontSize:"20px"}}>No categories to be listed</Typography> </Box> }
        {!pageState.isLoading &&
          pageState.data?.map((category, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  navigate(`/orders/startorder?${category?.categoryId}`);
                }}
                sx={{
                  cursor: "pointer",
                  height: "10%",
                  flex: pageState.data?.length < 10 ? "1 0 50%" : "1 0 41%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: categoryName === category?.categoryId ? "#032541" : "#fafafa",
                  borderRadius: "5px",
                  color: categoryName === category?.categoryId ? "#ffffff" : "#707070",
                }}
              >
                {category?.category}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default CategoryList;
