import React, {useState} from "react";
import AddService from "./addService";
import EditInvoice from "./editInvoice";

export default function AddServiceFromInvoice() {
	try {
		
		const [flagState, setFlagState] = useState('')
		
		function handleCreateService () {
			setFlagState('createService')
		}
		
		function handleGoBack () {
			setFlagState("")
		}
		
		let renderComponent = <EditInvoice setFlagCreateService={handleCreateService} />
		if (flagState === 'createService') 
		{renderComponent  = <AddService setFlagBacktoUpdateInvoice={handleGoBack} />
	}

		
		return (
			<div>
				{renderComponent}
			</div>
		)
	} catch (e) {
		console.log(`Error doing this!`, e.message)
	}
}