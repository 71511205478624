import { Grid, Breadcrumbs, Typography, TextField, Button, Checkbox } from '@mui/material'
import { SuccessAlert } from '../../snackBar Alerts/successAlert'
import { ErrorAlert } from '../../snackBar Alerts/errorAlert'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { handleDeleteUnit, handleResetStaff } from '../../../features/staffSlice';
import HttpComponent from '../MakeRequest';

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Staff</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Add Teaching Staff</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Preview Teaching Staff Details</Typography>
]

export default function PreviewteachingStaffDetails() {

    //alerts

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const dispatch = useDispatch()

    //details from redux store
    const staffDetails = useSelector((store) => store?.teachingStaff?.staffDetails)

    // console.log(staffDetails, 'lets go ')
    const staffCourseUnits = useSelector((store) => store?.teachingStaff?.staffCourseUnits)
    const { admissionNo, customerAddress, email, firstName, gender, lastName, phone } = staffDetails

    console.log(staffCourseUnits , 'staffCourseUnits')

    //console.log(staffCourseUnits, 'hoess')
    const [opencourse, setOpenCourse] = useState()
    const [openCollapse, setOpenCollapse] = useState(false)
    const [courses, setCourses] = useState([])

    useEffect(() => {
        // check if the course units are more than 1
        const filteredCourses = staffCourseUnits?.filter(course => course?.units?.length > 0);
        setCourses(filteredCourses)
    }, [staffCourseUnits])

    // console.log(courses , 'courses >>>>>')

    const courseDetails = courses?.map((course) => ({
        courseId: course?.courseId,
        unitIds: course?.units?.map((unit) => unit?.unitId),
    }))?.filter((result) => result?.unitIds && result?.unitIds.length > 0);

    //   console.log(courseDetails , 'resultFound')


    // extract uint ids

    // const extractUnitIds = (courses) => {
    //     return courses?.flatMap(course => course?.units.map(unit => unit?.unitId));
    //   };

    // const unitIdsArray = extractUnitIds(courses);


    // collapse and open 

    const handleClick = (courseName) => {
        setOpenCourse(courseName)
        setOpenCollapse(!openCollapse)

    }

    // handle delete details

    const handleDelete = (courseId, unitId) => {
        //  console.log(courseId , unitId)
        dispatch(handleDeleteUnit({ courseId: courseId, unitIds: [unitId] }))
    }

    const navigate = useNavigate();

    //  console.log(staffCourseUnits , 'jjj')

    // add staff details

    function addStaffDetails() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/addCustomer`,
                body: {
                    customerAddress: customerAddress,
                    lastName: lastName,
                    role: "TeachingStaff",
                    gender: gender,
                    customerClassification: "TeachingStaff",
                    paymentType: "Prepaid",
                    amountReceived: 0,
                    firstName: firstName,
                    customerType: "Individual",
                    phone: phone,
                    admissionNo: admissionNo,
                    studentAsParent: true,
                    email: email,
                    courseUnits: courseDetails
                    // courseUnits:unitIdsArray
                },
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                //console.log(data , 'yooooooooooooo')
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        dispatch(handleResetStaff())
                        navigate(`/school/universityStaff`)
                    }, [1000])
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error.message })
        }
    }


    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Preview  Staff Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <TextField style={{ width: '100%' }} placeholder="Staff ID Number" value={admissionNo} name="staffId" />
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="FirstName" value={firstName} name="firstName" />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="LastName" value={lastName} name="lastName" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="Phone" value={phone} name="Phone Number" />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="email" value={email} name="email" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="Address" value={customerAddress} name="location" />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="Gender" value={gender} name="gender" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'49%'} display={'flex'} mb={1}>
                <Grid container width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography>Courses & Units</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => navigate(`/school/universityStaff?AddTeachingUnit`)} style={{ display: 'flex', border: '1px solid #bec5d1', alignItems: 'center', textTransform: 'inherit' }}> <AddIcon style={{ marginRight: '10px', color: "#032541" }} /> <Typography style={{ color: '#bec5d1' }}>Add Courses & Units</Typography> </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <Grid container width={'100%'}>
                    {courses?.map((course) => (
                        <Grid item width={'100%'} py={2} sx={{ borderBottom: '0.1px solid #707070' }}>
                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography style={{ color: '#00000', fontWeight: 'bold' }}>{course?.courseName}</Typography>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    {openCollapse ? <ExpandLess onClick={() => handleClick(course?.courseName, course?._id)} /> : <ExpandMore onClick={() => handleClick(course?.courseName, course?._id)} />}
                                </Grid>
                            </Grid>
                            {opencourse === course?.courseName ?
                                <Grid container width={'100%'}>
                                    {course?.units?.length > 0 && course?.units?.map((unit) => (
                                        <Grid item width={'100%'} my={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography>{unit?.unitName}</Typography>
                                            <DeleteIcon onClick={() => handleDelete(course?.courseId, unit?.unitId,)} style={{ color: '#dc3545', cursor: 'pointer' }} />
                                        </Grid>
                                    ))}
                                </Grid> : null}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid mt={2} item width={'50%'} display={'flex'} justifyContent={'flex-end'}>
                {/* <Button onClick={() => navigate(-1)} style={{ border: '1px solid #dc3545', color: '#dc3545', textTransform: 'inherit', width: '124px' }}> Cancel</Button> */}
                <Button onClick={addStaffDetails} style={{ backgroundColor: '#032541', marginLeft: '10px', color: '#fff', textTransform: 'inherit', width: '124px' }}> Add Staff</Button>
            </Grid>
        </Grid>
    )
}