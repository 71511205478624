import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import DraftsIcon from '@mui/icons-material/Drafts';
import { SuccessAlert } from "../../components/snackBar Alerts/successAlert";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import HttpComponent from "../../components/School/MakeRequest";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));

export default function GeneralLedgerSidebar() {
    const classes = useStyles();
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const location = useLocation();

    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc";

    const [accountStatus, setAccountStatus] = useState({});

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data.response.data);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAccountingStatus();
    }, []);

    return (
        <div>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            {accountStatus.accountingEnabled && (
                <Link to="/ledgersetup" style={{ textDecoration: 'none', color: isActiveLink("/ledgersetup") ? activeColor : '#fff' }}>
                    <ListItem button className={classes.nested}>
                        <div style={{ marginRight: '5%' }}>
                            <SwitchAccountIcon />
                        </div>
                        <div>
                            <ListItemText primary="General Ledger Account" />
                        </div>
                    </ListItem>
                </Link>
            )}
        </div>
    );
}
