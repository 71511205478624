import { Breadcrumbs, MenuItem, Menu, Grid, Tab, Typography, Modal, Button, Box, Stack } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled, alpha } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { SuccessAlert } from "../../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../../snackBar Alerts/errorAlert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomDataGrid from "../../../../products/stockComponents/customDataGrid";
import SearchFilter from "../../../../customerAccounts/customerComponents/searchFilter";
import HttpComponent from "../../../../School/MakeRequest";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom'
import AddVehicleRoutes from "./AddVehicleroutes";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import WarningImg from "../../../../../images/warning.png"
import SuccessImg from "../../../../../images/success.png"
import { DoMaskValue } from "../../../../../utils/dateFormatter";
import EditVehicleRoutes from "./editVehicleRoute";

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };


// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: 'transparent' } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "17px", background: 'transparent', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#cb444a", backgroundColor: 'transparent', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: 'transparent' },
}));


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle Routes</Typography>
]

export default function VehicleRoutes() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const [openRestore , setOpenRestore] = useState(false)
    const [openDeactivate , setOpenDeactivate] = useState(false)
    const [vehiclerouteName , setVehicleRouteName] = useState('')
    const  [routeId ,setRouteId] = useState('')

    // handle Modals

    const handleOpenRestore = () => setOpenRestore(true)
    const handleCloseRestore = () => setOpenRestore(false)

    const handleOpenDeactivate  =() =>setOpenDeactivate(true)
    const handleCloseDeactivate  =() =>setOpenDeactivate(false)
    const [tabValue, setTabValue] = useState('ACTIVE')
    const navigate = useNavigate()

    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue] = newqueryParamValue.split("?")

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const RenderCell = (params) =>{
        const [anchorEl, setAnchorEl] = useState(null);
        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };
        const handleActionClose = () => { setAnchorEl(null); };
        return(
            <Grid item>
                <MoreVertIcon onClick={handleClick} />
                <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                    {tabValue === 'ACTIVE' ? <>
                        <MenuItem disableRipple onClick={() =>navigate(`/partners/vehicleroutes?EditVehicleRoute?${params?.row?.id}`)}>
                            <ModeEditIcon style={{ color: '#cb444a' }} /> <span>Edit</span>
                        </MenuItem>
                        <MenuItem disableRipple onClick={() => {setRouteId(params?.row?.id);setVehicleRouteName(params?.row?.routeName); handleOpenDeactivate() ;setAnchorEl(null)}}>
                            <NotInterestedIcon style={{ color: '#cb444a' }} /> <span>Deactivate</span>
                        </MenuItem>
                    </> : tabValue === 'INACTIVE' ? <MenuItem disableRipple onClick={ () => {setRouteId(params?.row?.id) ;setVehicleRouteName(params?.row?.routeName);handleOpenRestore() ;setAnchorEl(null)}}>
                        <SettingsBackupRestoreIcon style={{ color: '#17ae7b'}} /> <span>Restore</span>
                    </MenuItem> : null}
                </StyledMenu>
             </Grid>

        )
    }

    const column = [
        { field: "routeName", headerName: "Name", flex: 1 },
        { field: 'routeCode', headerName: 'Route Code', flex: 1 },
        { field: "description", headerName: "Description", flex: 1 },
        { field: 'county', headerName: 'County', flex: 1 },
        { field: "action", headerName: "Action", flex: 1, renderCell: RenderCell }
    ]

    // get insurance companies

    function getVehicleRoutes() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/listroutes/${tabValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getVehicleRoutes()
    }, [pageState.page , dataGridPageSize ,tabValue , newqueryParamValue])

    const configDataZPM = pageState?.data?.map((config) => ({
        id: config._id,
        routeName: config?.routeName,
        routeCode: config?.routeCode,
        description: config?.description,
        county: config?.county,
    }))

    function handleUpdateRouteStatus(){
        try {
            HttpComponent({
                method:"post",
                url:`/api/v1/updateroute?routeId=${routeId}`,
                body:{
                    status:tabValue === 'ACTIVE'  ? 'INACTIVE': 'ACTIVE'
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({state:true , message:data?.response?.message})
                    setTimeout(()=>{
                        setRouteId('')
                        getVehicleRoutes();
                    },1000)
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })
        } catch (error) {
            
        }
    }

    console.log(newqueryParamValue , 'newqueryParamValue')

    return (
        <Grid container direction={'column'}>
            {queryParamValue === 'AddVehicleRoute' ? <AddVehicleRoutes /> : queryParamValue === 'EditVehicleRoute' ? <EditVehicleRoutes/>: <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "#707070",
                                "&:hover": { cursor: "pointer" },
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        />
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Vehicle Routes</Typography>
                    </Stack>
                    <Button onClick={() => navigate(`/partners/vehicleroutes?AddVehicleRoute`)} style={{ width: '176px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Add Vehicle Routes</Button>
                </Grid>
                <Grid item mt={2}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mt={2}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                            <AntTab label="Active" value="ACTIVE" />
                            <AntTab label="InActive" value="INACTIVE" style={{ marginLeft: '20px' }} />
                        </AntTabs>
                        <TabPanel value="ACTIVE" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={column} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configDataZPM} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="INACTIVE" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>{/* <Exports /> */}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"}>
                                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={column} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configDataZPM} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>
            }
              <Modal
                open={openDeactivate}
                onClose={handleCloseDeactivate}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={WarningImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Deactivate Route ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to deactivate  <span style={{fontWeight:700}}>{vehiclerouteName}</span>
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeactivate} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={() => {handleUpdateRouteStatus();handleCloseDeactivate() }}  style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openRestore}
                onClose={handleCloseRestore}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={SuccessImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Restore  Route ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to restore   <span style={{fontWeight:700}}>{vehiclerouteName}</span>
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseRestore} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={()=> {handleUpdateRouteStatus();handleCloseRestore()}}  style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Activate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}