import React, {useState} from "react";
import TenantBioData from "../Rental/components/tenantBioData";
import AddHouseDetails from "./tenantComponents/addHouseDetails";
import AddTenantService from "./tenantComponents/addServiceDetails";
import CompleteTenant from "./tenantComponents/completeTenant";
import ListServicesDetails from "./tenantComponents/listServiceDetails";
import ListTenants from "./tenantComponents/listTenants";
import PreviewNewTenantInvoice from "./tenantComponents/previewNewTenantInvoice";
import GiveNotice from "./tenantComponents/GiveNotice";
import ExitNotice from "./tenantComponents/tenantsExit/Notice";
import EditTenantServices from "./tenantComponents/tenantDetails/EditTenantservices";
import DeleteTenantService from "./tenantComponents/tenantDetails/DeleteTenantUnits";
import SendVacateNotice from "./tenantComponents/sendVacateNotice";
import NoticePdfPreview from "./tenantComponents/tenantsExit/NoticePreviewpdf";
export default function Tenants(props) {
	try {
		const [flagState, setFlagState] = useState("")
		const [tenantInfo ,setTenantInfo] = useState({
			firstName:"" ,
			lastName:"" ,
			phone:"" ,
			country:"" ,
			email:""

		})

		const [selectedTenantToExit , setSelectedTenantToExit] = useState([])
		const [tenantId ,setTenantid] = useState()

		function picTenantId(id){
			setTenantid(id)
		}

		function handleTenantToExit(tenantData){
			setSelectedTenantToExit(tenantData)
		}

		function UpdateSelectedTenantInfo(data){
			setTenantInfo(data)
		}
		
		// Handle Open Create tenant
		function handleAddTenantBioData() {
			setFlagState('addTenantBioData')
		}
		
		function handleAddHouse () {
			setFlagState('addHouse')
		}
		
		function handleAddService () {
			setFlagState('addServices')
		}
		
		function handleListServices () {
			setFlagState('listServices')
		}
		
		function handleCompleteTenant () {
			setFlagState('completeTenant')
		}
		
		function handlePreviewTenantInvoice () {
			setFlagState('previewInvoice')
		}

		function handleBackToMenu () {
			setFlagState("")
		}

		function handleEditService(){
			setFlagState('editservicees')
		}
		function handleDeleteService(){
			setFlagState('deleteTenantService')
		}
		function handleGoBack (value) {
			setFlagState(value)
		}
		function handleGiveNotice(){
			setFlagState('giveNotice')
		}
		function handleNotice(){
			setFlagState('sendVacateNotice')
		}
		function showPreviewExitNotice(){
			setFlagState('previewNoticee')
		}
		function handleNoticepdf(){
			setFlagState('noticePage')
		}
	
		let renderComponent = <ListTenants tId={picTenantId} tenantParams={handleTenantToExit}  setFlagCreateTenant={handleAddTenantBioData} setFlagGiveNotice={handleGiveNotice} tenantInfo={tenantInfo} setTenantInfo={UpdateSelectedTenantInfo}  />
		
		if (flagState === "addTenantBioData") {
			renderComponent = <TenantBioData setFlagStateHouse={handleAddHouse} setFlagStateBack={() => {handleGoBack("")}} />
		} else if (flagState === 'addHouse') {
			renderComponent = <AddHouseDetails setFlagStateViewServices={handleCompleteTenant} setFlagGoBack={handleAddTenantBioData} setFlagStateService={handleAddService} />
		} else if (flagState === 'addServices') {
			renderComponent = <AddTenantService setFlagListServices={handleListServices} />
		} else if (flagState === 'listServices') {
			renderComponent = <ListServicesDetails setFlagStateDone={handleCompleteTenant} setFlagStateGoBack={() => {handleGoBack('addServices')}} />
		} else if (flagState === 'completeTenant') {
			renderComponent = <CompleteTenant setFlagStateAddService={handleListServices} setFlagStateBackToServices={handleListServices} setFlagStateBackToMenu={handleBackToMenu} />
		} else if (flagState === 'previewInvoice') {
			renderComponent = <PreviewNewTenantInvoice />
		} else if (flagState === 'giveNotice'){
			renderComponent = <GiveNotice tenaId={tenantId} tenantINfo={selectedTenantToExit} goBack={handleBackToMenu} postData={tenantInfo} goodMorning={props.goodMorning} selectedTenantInfo setFlagNoticepdf={handleNotice}/>
		}else if (flagState === 'sendVacateNotice'){
				renderComponent=<SendVacateNotice goBack={handleGiveNotice} goPreveiewN={showPreviewExitNotice}/>
		}else if(flagState === 'previewNoticee'){
				renderComponent= <NoticePdfPreview goBack={handleBackToMenu}/>
		} else if (flagState === 'noticePage'){
			renderComponent = <ExitNotice />
		}else if (flagState === 'editservicees'){
			renderComponent = <EditTenantServices/>
		}else if(flagState === 'deleteTenantService'){
			 renderComponent = <DeleteTenantService/>
		}
		return (
			<div>
				{renderComponent}
			</div>
		)
	} catch (e) {
		console.log(`Error Doing this`, e.message)
	}
}