import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    businessDetails: {},
    adminBusinessDetails: {},
    outlets:[],
    terminals:[],
    terminalUsers:[],
    accountNumbers:{}
}
const BusinessPartnerDetails = createSlice({
    name: 'BusinessPartnerDetails',
    initialState,
    reducers: {
        handleSetBusinessDetails: (state, action) => {
            state.businessDetails = action.payload
        },
        handleSetadminBusinessDetails: (state, action) => {
            state.adminBusinessDetails = action.payload
        },
        handleUpdateBusinessDetails :(state , action) =>{
            const {businessNumber ,branchId} = action.payload
            state.businessDetails = {...state.businessDetails ,businessNumber , branchId }
        },
        handleSaveOutlets :(state , action) => {
            state.outlets = [...state.outlets , action.payload]
        },
        handleSaveTerminals :(state , action) => {
            state.terminals = [...state.terminals , action.payload]
        },
        handleSaveTerminalUsers :(state , action) => {
            state.terminalUsers = [...state.terminalUsers , action.payload]
        },
        handleSaveAccount: (state, action) => {
            state.accountNumbers = action.payload
        },
        handleResetDetail: (state) => {
            state.adminBusinessDetails = {}
            state.businessDetails = {}
            state.accountNumbers = {}
            state.outlets = []
            state.terminalUsers  = []
            state.terminals = []
        }
    }

})

export const { handleResetDetail,handleSaveOutlets ,handleSaveTerminals ,handleSaveTerminalUsers ,handleSaveAccount , handleSetadminBusinessDetails, handleSetBusinessDetails ,handleUpdateBusinessDetails } = BusinessPartnerDetails.actions
export default BusinessPartnerDetails.reducer