import { Box, Typography ,Grid ,FormControl ,InputLabel ,Select,MenuItem ,Skeleton} from '@mui/material'
import jwt from 'jwt-decode'
import DirectionsCarFilledSharpIcon from '@mui/icons-material/DirectionsCarFilledSharp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useEffect, useMemo, useState } from 'react';
import HttpComponent from '../../School/MakeRequest';
import { currencyconverter } from '../../../common/currencyconverter';
import CustomDataGrid from '../../products/stockComponents/customDataGrid';
import DateFormatter from '../../../utils/dateFormatter';
import {Link} from 'react-router-dom'
import TransactionByVehicleReport from './transbyVehicle';
export default function VehicleOwnerDash(){
    const [cardData , setCardData] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [startDate ,setStartdate] = useState('')
    const [endDate ,setenddate] = useState('')
    const [defaultRecentStartDate ,setDefaultRecentStart] = useState()
    const [defaultRecentendDate ,setDefaultRecentend] = useState()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [todaysPageSize , setTodaysPageSize] = useState(10)
    const [todaypageState, settodayPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize:todaysPageSize });
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")


    // customer classification

    const customerclassificationFound = localStorage.getItem('customerClassification')

     // get recent transactions

     function getdashboardDetails() {
        setIsLoading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/dashboard_details?startDate=${startDate}&endDate=${endDate}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setCardData(data?.response?.data)
                    setIsLoading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        console.log('running >>>>>>>')
        console.log(localStorage.getItem("X-Authorization") ,'running >>>>>>>')
        getdashboardDetails();
    },[startDate ,endDate])


       // get recent transactions

       function transactionbyvehciledrilldown() {
        setPageState({ ...pageState, isLoading: true });
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getFareTransactionsByVehicle?page=${pageState.page}&limit=${dataGridPageSize}&startDate=${new Date()}&endDate=${new Date()}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
                } else {
                    setPageState({ ...pageState, isLoading: false });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        transactionbyvehciledrilldown();
    }, [pageState.page, dataGridPageSize,]);
    
    const { lastSevenFirst, lastSevenLast } = useMemo(() => {
        var result = [];
        for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
        }
        const lastSevenFirst = new Date(result[0]);
        const lastSevenLast = new Date(result[result.length - 1]);
        
        setDefaultRecentStart(lastSevenFirst);
        setDefaultRecentend(lastSevenLast);
    
        return {
            lastSevenFirst,
            lastSevenLast
        };
    }, []);

    useEffect(()=>{
        setDefaultRecentStart(lastSevenFirst);
        setDefaultRecentend(lastSevenLast);
    },[])


    function getTodaysTransactions(){
        settodayPageState({ ...todaypageState, isLoading: true });
            try {
                HttpComponent({
                    method: "GET",
                    url: `/api/v1/getFareTransactions?startDate=${new Date()}&endDate=${new Date()}`,
                    body: null,
                    token: localStorage.getItem("X-Authorization"),
                }).then((data) => {
                    if (data?.status === 200 || data?.status === 201) {
                        settodayPageState({ ...todaypageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
                    }
                })
            } catch (error) {
                console.log(error)
            }
    }
    useEffect(()=>{
        getTodaysTransactions();
    },[todaypageState.page , todaysPageSize])


    const { regNo } = jwt(localStorage.getItem("X-Authorization"));
    const configData = todaypageState?.data?.map((config) => ({
        id: config?._id,
        name:config?.customerFirstName,
        Amount: currencyconverter(config?.transamount),
        totalCount:DateFormatter(config?.createdAt),
        registrationNo: config?.regNo,
        paymentType:config?.transactionType
    }))

    // vehcile owner

    const configDatavehicleowbner = pageState?.data?.map((config) => ({
        id: config?.registrationNo,
        Amount: currencyconverter(config?.totalAmount),
        totalCount:config?.totalCount,
        registrationNo: config?.registrationNo,
    }))

    // recent transactions columns

    const recentTransactionsColumns = [
        { field: "name", headerName: "Customer Name", flex: 1 },
        { field: "registrationNo", headerName: "Reg No", flex: 1 },
        { field: "totalCount", headerName: "Trans Time", flex: 1 },
        { field: "paymentType", headerName: "Payment Type", flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
    ]

    const recentTransactionsColumnsvehicleowner = [
        { field: "registrationNo", headerName: "Reg No", flex: 1, renderCell:(params) => <Link to={`/dashboard?transByVehicle?${params?.row.registrationNo}`}>{params?.row.registrationNo}</Link> },
        { field: "totalCount", headerName: "Transactions", flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
    ]

    return(
        <Grid container direction={'column'} width={'100%'}>
            {queryParamValue === 'transByVehicle'  ? <TransactionByVehicleReport/> :<>
            <Grid item width={'100%'}>
                <Typography style={{fontSize:'22px' , fontWeight:700 , textTransform:'capitalize' , color:'#C4E0F8' , fontStyle:'italic'}}>{customerclassificationFound}</Typography>
            </Grid>
           <Grid item width={'100%'} pt={1}>
                <Grid container width={'100%'} gap={1} height={'100%'}>
                    <Grid item width={'25%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(23, 174, 123, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(23, 174, 123, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography>Collections Today</Typography>
                                    <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-label">Date Filter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Date Filter"
                                        >
                                            <MenuItem value=''>All</MenuItem>
                                            <MenuItem value={'Today'}>Today</MenuItem>
                                            <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                            <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                            <MenuItem value={'This Month'}>This Month</MenuItem>
                                            <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#17ad7a' }}>{currencyconverter(cardData?.dailyTransactions ? cardData?.dailyTransactions : 0)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>{cardData?.dailyTransactionCount} Transactions</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ReceiptLongIcon style={{ color: '#fff', background: '#17ad7a', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'25%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(61, 162, 248, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(61, 162, 248, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Collections This Month</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '25px', fontWeight: 700 , color:'#dc3545' }}>{currencyconverter(cardData?.currentMonthTransaction)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>{cardData?.monthlyTransactionCount}  transactions</Typography>
                                        </Grid>
                                        <Grid item>
                                           <ReceiptLongIcon style={{ color: '#fff', background: '#dc3545', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(220, 53, 69, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(220, 53, 69, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Vehicles</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#3da2f8' }}>{customerclassificationFound === 'driver' || customerclassificationFound === 'conductor' ? regNo: cardData?.vehicleOwnersCount}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}> Vehicles {customerclassificationFound === 'driver' || customerclassificationFound === 'conductor' ? regNo:cardData?.vehicleCount}  </Typography>
                                        </Grid>
                                        <Grid item>
                                        <DirectionsCarFilledSharpIcon style={{ color: '#fff', background: '#3da2f8', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(247, 144, 9, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(247, 144, 9, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Operators</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#f79009' }}>{cardData.operatorsCount}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>Recently added :</Typography>
                                        </Grid>
                                        <Grid item>
                                            <PeopleAltIcon style={{ color: '#fff', background: '#f79009', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'100%'}>
                <Grid container direction={'column'} gap={2}>
                    <Grid item width={'100%'}>
                        <Typography pt={2} variant='body1' style={{ fontWeight: 700, fontSize: '20px' }}>Recent Transactions</Typography>
                    </Grid>
                    <Grid item width={'100%'}>
                        {customerclassificationFound === 'driver' || customerclassificationFound === 'conductor' || customerclassificationFound === 'saccoOperator' ? 
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={recentTransactionsColumns} setPageSize={setTodaysPageSize} setPageState={settodayPageState} pageState={todaypageState} dataGridPageSize={todaysPageSize} row={configData} /> :customerclassificationFound === 'vehicleowner' ?
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={recentTransactionsColumnsvehicleowner} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configDatavehicleowbner} />:null }
                    </Grid>
                </Grid>
            </Grid>
            </>}
        </Grid>
    )
}