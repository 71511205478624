import React, {useState, useEffect} from "react";
import {Modal, Box, Grid, Select,} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";

const CardPayments = (props) => {

	const [mpesaOpen, setMpesaOpen] = useState(props.mpesaOpen);

	const wholeModal = {width: '500px', height: '530px', backgroundColor: '#fff', borderColor:"transparent", borderRadius: "7px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',};
	const headerSection = {backgroundColor: 'red', width: '100%', borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: '109px', flexDirection: "row", alignContent: "center", justifyItems: "center", textAlign: "center",}

	useEffect(() => {
		setMpesaOpen(props.mpesaOpen);
	}, [props.mpesaOpen]);

	return (
		<div>
			<Modal open={mpesaOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={wholeModal}>
					<div style={headerSection}>
						<div style={{marginLeft: "auto", marginRight: "auto", color: "white", fontWeight: "bold", fontSize: 25, paddingTop:"40px"}}>
							Received Payment
						</div>
					</div>

					<Grid container alignItems={'center'} justifyContent={'center'} style={{marginTop : '5%'}} >
						<Grid item>

							{/*input Label*/}
							<InputLabel style={{"color":"#032541","fontFamily":"Poppins","fontSize":"15px"}} >Card Type:</InputLabel>
							<Select value={'ddd'} style={{fontSize : '10px', fontFamily : 'Poppins', "width":"450px","height":"45px","borderRadius":"6px"}} size="small" variant="outlined" placeholder={'Select Card Type'}>
								<option>Visa</option>
							</Select>

							<InputLabel style={{"color":"#032541","fontFamily":"Poppins","fontSize":"15px"}} >Acquirer</InputLabel>
							<TextField style={{fontSize : '10px', fontFamily : 'Poppins', "width":"450px","height":"45px","borderRadius":"6px"}} size="small" variant="outlined" placeholder={'Acquirer'} />

							<InputLabel style={{"color":"#032541","fontFamily":"Poppins","fontSize":"15px"}} >Reference No.</InputLabel>
							<TextField style={{fontSize : '10px', fontFamily : 'Poppins', "width":"450px","height":"45px","borderRadius":"6px"}} size="small" variant="outlined" placeholder={'Reference No.'} />

							<InputLabel style={{"color":"#032541","fontFamily":"Poppins","fontSize":"15px"}} >Customer Name</InputLabel>
							<TextField style={{fontSize : '10px', fontFamily : 'Poppins', "width":"450px","height":"45px","borderRadius":"6px"}} size="small" variant="outlined" placeholder={'Customer Name'} />

							{/*Receive Payment button*/}
							<Grid item style={{marginTop : '5%'}}>
								<Button style={{color: '#fff', fontSize : '14px', fontFamily : 'Poppins', "width":"450px","height":"45px", "borderRadius":"5px","backgroundColor":"rgba(3, 37, 65, 0.4)"}} size="small" variant="outlined" placeholder={'Select Card Type'}>
									Receive payment
								</Button>
							</Grid>

						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
};
export { CardPayments };
