import { Box, Breadcrumbs, Button, Grid, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useEffect, useRef, useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../School/MakeRequest";
import CustomInputLabel from "../School/CustomInputLabel";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
const baseurl = process.env.REACT_APP_BASE_URL
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={1} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Business Info</Typography>,
]


export default function BusinessInfo() {
    const [bsDetails, setBsDetails] = useState({})
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const imageUploader = useRef(null);
    const uploadedImage = useRef(null);
    const [img, setImg] = useState(null);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileInputChange(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const { businessName, country, localCurrency, businessLogo, businessOwnerPhone, businessNumber, businessOwnerName, businessOwnerEmail, businessOwnerAddress, businessCategory } = bsDetails

    function getBsInfo() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/get_business_info`,
                body: { businessId: localStorage.getItem('businessID') },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'hhhhhhhh')
                setBsDetails(data?.response?.data)
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getBsInfo()
    }, [])

    const navigate = useNavigate();

    const handleFileInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            const selectedFile = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            setFile(file);
            setSelectedFile(selectedFile);
        }
    };

    //update logo
    const baseUrl  = process.env.REACT_APP_BASE_URL

    const  uploadFile = (file)  => { 
        const url = `${baseUrl}/api/edit_business_logo`;
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
      
        formData.append('businessLogo', file);
      
        xhr.open('POST', url, true);
        xhr.setRequestHeader("X-Authorization", localStorage.getItem('X-Authorization'));
        xhr.onload = function() {
            if (xhr.status === 200) {
              let data = JSON.parse(xhr.responseText);
              setSuccessShow({state:true ,message:'Logo updated successfully'})
              setSelectedFile(null)
              setFile(null)
            } else {
                setErrorShow({state:true ,message:'Logo updated successfully'})
                setSelectedFile(null)
                setFile(null)
            }
            getBsInfo()
          };
        
          xhr.onerror = function() {
            console.error('An error occurred while uploading the file');
          };
        
          xhr.send(formData);
    }



   
    const businessID = localStorage.getItem('businessID')

    return (
        <Grid container direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Business Info</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={4}>
                <Typography style={{ color: '#000000', fontSize: "18px" }}>Your Logo</Typography>
            </Grid>

            <Grid item mt={2}>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width: "198px", height: "102px", padding: "35px", border: "dashed 2px #c2cdda", position: 'relative', cursor: 'pointer' }} onDrop={handleDrop} onDragOver={handleDragOver}>
                    <input type="file" accept="image/*" multiple="false" onChange={handleFileInputChange} style={{ display: "none" }} ref={imageUploader} />
                    <img style={{ width: "150px", height: "90px", position: "absolute", }}
                        onClick={() => imageUploader.current.click()}
                        alt="Upload" src={`${baseurl}/staticimages/logos/${businessLogo}`} ref={uploadedImage} />
                         <Box sx={{ display:'flex', flexDirection:'column', position:'absolute', top: 30, left: 163, zIndex: 99, width:'30px' }}>
                        <Button style={{ color:'#032541', width:'5%', height:'30px' }} onClick={() => imageUploader.current.click()}><EditIcon /></Button>
                                        </Box>
                </Box>
                    {selectedFile && <Button  style={{ background:'#17ae7b', marginTop:"10px", textTransform:'inherit', color:"#fff", width:'125px', height:'40px' }} onClick={()=>uploadFile(file)}>Update Logo</Button>}
            </Grid>

            <Grid item mt={2} width={'50%'} display={'flex'} alignContent={'center'} justifyContent={'space-between'}>
                <Typography style={{ color: '#000000', fontSize: "18px" }}>Business Details</Typography>
                <Typography onClick={()=>navigate(`/shops/edit/${businessID}`)} style={{ color: '#000000', fontSize: "18px" , cursor:'pointer' }}>Edit Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Business Type'} />
                        <TextField id="standard-basic" value={businessCategory} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Email'} />
                        <TextField id="standard-basic" value={businessOwnerEmail} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Business Name'} />
                        <TextField id="standard-basic" value={businessName} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Location'} />
                        <TextField id="standard-basic" value={businessOwnerAddress} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Business Owner'} />
                        <TextField id="standard-basic" value={businessOwnerName} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Country'} />
                        <TextField id="standard-basic" value={country} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Phone Number'} />
                        <TextField id="standard-basic" value={businessOwnerPhone} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <CustomInputLabel label={'Currency'} />
                        <TextField id="standard-basic" value={localCurrency} style={{ width: "100%" }} variant="standard" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}