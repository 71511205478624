import {Button, Grid, TextField} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import CustomSelectField from "../../School/CustomSelectField";
import HttpComponent from "../../School/MakeRequest";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";
import {navigate} from "react-big-calendar/lib/utils/constants";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function AddTenantService(props){
	const [serviceType, setServiceType] = useState('')
	const [serviceName, setServiceName] = useState('')
	const [amount, setAmount] = useState('')
	const [newAmount, setNewAmount] = useState(0)
	const [unitType ,setUnitType] = useState('')
	const [serviceData, setServiceData] = useState([])
	const [unitServiceName, setUnitServiceNames] = useState([])
	const [unitServiceType, setUnitServiceTypes] = useState([])

	console.log(props , 'page props')
	
	//Select Field Options
	const unitOptions = serviceData.map((unit) => ({value : unit._id, label : unit.unitType}))
	const serviceOptions = unitServiceName.map((service) => ({value : service, label : service}))
	const serviceTypeOption = unitServiceType.map((type) => ({value : type, label : type}))
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"
	
	const formData = {serviceType : serviceType, amount :amount === 0 ? '0' :amount , serviceName : serviceName, businessUnitTypeId : unitType}
	const [successShow, setSuccessShow] = useState({state: false, message: ""})
	const [errorShow, setErrorShow] = useState({state: false, message: ""})

	
	//Get services
	function getUnits() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unit_types_by_business_unit_config?limit=1000&page=1&businessUnitConfigId=${configId}`,
			token: X_Authorization
		}).then((data)=>{
			
			console.log("here store is data", data.response.data);
			if(data.status === 201){
				setServiceData(data.response.data)
			}
			else{
				console.error("Error setting info")
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}
	
	//Get Service names
	function ServiceName() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_rental_service_names',
				token : X_Authorization
			}).then((data)=>{
				console.log("Service names",data);
				if(data.status === 201){
					setUnitServiceNames(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error getting Service names')
		}
	}
	
	//Get Service Types
	function ServiceType() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_service_types',
				token : X_Authorization
			}).then((data)=>{
				console.log("Service Types",data);
				if(data.status === 201){
					setUnitServiceTypes(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error getting Service names')
		}
	}

	function handleFromProducts (message) {
		setSuccessShow({state : true, message : message})
		setTimeout(() => {
			props.doneSaving()
		}, 1500)
	}

	//Create Service
	function submitService() {
		console.log('formdata', formData)
		try {
			HttpComponent({
				method: 'POST',
				url: `/api/add_business_unit_service`,
				token: X_Authorization,
				body : formData,
			}).then((data)=>{
				console.log("here is post data",data);
				if(data.status === 201){
					props.isFromProducts === true ? handleFromProducts(data.response.message) :
						handleAddServiceClick(data.response.message)
				}
				else{
					console.error("Error setting info")
					setErrorShow({state : true, message: data.response.message})
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error Saving Service!')
		}
	}
	
	useEffect(() => {
		getUnits()
		ServiceName()
		ServiceType()
	}, [])
	
	
	const handleAddServiceClick = (message) => {
		setSuccessShow({state : true, message : message})
		setServiceType("")
		setServiceName("")
		setAmount('')
		setUnitType('')
	}
	const handleListingFlagClick = () => {
		props?.setFlagListServices()
	}
	const changeAmountState = (serviceType) => {
		// console.log('serviceType', serviceType)
		if (serviceType === 'Fixed') {

			setAmount(0)
			console.log(amount, serviceType)
			
		}if(serviceType === 'Variable'){
			setAmount(false)

		}
	}

	useEffect(() => {
		if(serviceType === 'Variable'){
			setAmount(0)
			setNewAmount(0)
		}
		if(serviceType === 'Fixed'){
			setAmount( Number(newAmount) )
		}

	},[serviceType, newAmount, amount])

	return (
		<div style={{fontFamily : 'Poppins'}}>
			
			{/*Success Invoice Sent*/}
			<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
			<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

			<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={2} mt={2}>
			
			{/*header*/}
			<Grid item>
				<span style={{fontWeight : '600'}}>Add Service</span>
			</Grid>
			
			<Grid item>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={unitType} onChange={(e) => {setUnitType(e.target.value)}} name={"Unit Type"} placeholder={"Select Unit Type"} options={unitOptions} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={serviceName} onChange={(e) => {setServiceName(e.target.value)}} name={"Service Name"} placeholder={"Select Service Name"} options={serviceOptions} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={serviceType} onChange={(e) => {
							setServiceType(e.target.value)
							changeAmountState(serviceType)
							
							}} name={"Service Type"} placeholder={"Select Service Type"} options={serviceTypeOption} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<TextField disabled={false} value={newAmount} onChange={(e) => {setNewAmount(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Amount" variant="outlined" required />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={1}>
					<Grid item>
						<Button onClick={submitService} style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Save
						</Button>
					</Grid>
					<Grid item>
						<Button onClick={props.isFromProducts ? props.setFlagListServices:handleListingFlagClick} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Preview
						</Button>
					</Grid>
				</Grid>
				
				{/*Main Screen Buttons*/}
				{/*<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={1}>*/}
				{/*	<Grid item>*/}
				{/*		<Button style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>*/}
				{/*			Skip Step*/}
				{/*		</Button>*/}
				{/*	</Grid>*/}
				{/*	<Grid item>*/}
				{/*		<Button style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>*/}
				{/*			Add Service*/}
				{/*		</Button>*/}
				{/*	</Grid>*/}
				{/*</Grid>*/}
			
			</Grid>
		</Grid>
	</div>
	)
}
