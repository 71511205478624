import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Stack, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import TuneIcon from '@mui/icons-material/Tune';
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import ReplayIcon from "@mui/icons-material/Replay";
// import DeleteIcon from "@mui/icons-material/Delete";
// import DangerousIcon from "@mui/icons-material/Dangerous";
// import EditIcon from "@mui/icons-material/Edit";
// import { useDispatch } from "react-redux";
// import { handleItemClick } from "../../../../features/actionSlice";
// import { useSelector } from "react-redux";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

export default function OutletsDropdown() {
  //   const dispatch = useDispatch();
  const navigate = useNavigate();

  const Group = localStorage.getItem("group");
  const isPartner = Group && Group == "Partner";
  const isAdmin = Group && Group == "Admin";

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* <ThemeProvider theme={theme}> */}
          <Button variant="text" {...bindTrigger(popupState)}>
            <MoreVertIcon sx={{ color: "#032541" }} />
          </Button>
          {/* </ThemeProvider> */}
          <Menu {...bindMenu(popupState)}>
            {isPartner && (
              <>
                <MenuItem
                  onClick={() => {
                    // handleClick("");
                    popupState.close();
                    navigate("/AssignCategoriesToOutlet");
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <AddIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Assign Categories
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {/* {isPartner && (
              <>
                <MenuItem
                  onClick={() => {
                    // handleClick("");
                    popupState.close();
                    navigate("/PartnerCreateProduct");
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <AddIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Create Product
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {isPartner && (
              <>
                <MenuItem
                  onClick={() => {
                    // handleClick("");
                    popupState.close();
                    navigate("/PartnerCreateCategory");
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <AddIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Create Category
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )} */}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
