import {Button, Grid, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {handleAllocation} from "../../../../features/tenantsUnitsSlice";
import CustomSelectField from "../../../School/CustomSelectField";
import HttpComponent from "../../../School/MakeRequest";
import {ErrorAlert} from "../../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../../snackBar Alerts/successAlert";

export default function EditUnit(props, { onButtonClick }) {

    const navigate = useNavigate()


    const { userId ,X_Authorization } = useSelector((store) => store.user);

    const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
    const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"

    // Notifications
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const [successShow, setSuccessShow] = useState({state: false, message: ""})

    const dispatch = useDispatch()

    const [unitOptions, setUnitOptions] = useState([])
    const unitTypesOptions = unitOptions.map((unit) => ({value : unit, label : unit}))

    const editData = JSON.parse(localStorage.getItem('unitsEditData'))
    // console.log(`Edit Data`, JSON.parse(localStorage.getItem('unitsEditData')))
    console.log(`This is data from localstorage`, editData, editData?._id)

    const [unitId , setUnitId] = useState(editData?._id)
    const [type, setType] = useState(editData?.unitType)
    const [prefix, setPrefix] = useState(editData?.unitTypePrefix)
    const [noOfUnits, setNoOfUnits] = useState(editData?.unitTypeCount)
    const [startPrefix, setStartPrefix] = useState(editData?.startNumber)
    const [amount, setAmount] = useState(editData?.unitCost)
    const [billingPeriod, setBillingPeriod] = useState(editData?.billingPeriod)

    const formData = {unitTypeId : unitId,unitType : type, startNumber : startPrefix, billingPeriod : billingPeriod, unitTypeCount : noOfUnits,
        unitTypePrefix : prefix, unitCost : amount
    }
    // const data = {
    //     unitTypeId: "640cb808d9a63e3066a4652e",
    //     unitType: "4 Bedroom",
    //     startNumber: "1",
    //     billingPeriod: "Monthly",
    //     unitTypeCount: "10",
    //     unitCost: "25000",
    //     unitTypePrefix: "E"
    // }

    function editUnit () {
        HttpComponent({
            method: 'POST',
            url: '/api/edit_business_unit_type',
            body: formData,
            token: X_Authorization
        })
            .then((data)=>{
                console.log("here store is data",data);
                if(data.status === 201){
                    setSuccessShow({state : true, message: data.response.message})
                    handleAddHouseClick(formData)
                }else{
                    setErrorShow({state : true, message: data.response.message})
                    console.error("Error setting info")
                }
            }).catch((error)=>{
            console.error(error.message);
        })
    }

    function getUnitTypes() {
        HttpComponent({
            method : 'GET',
            url : '/api/get_house_unit_types',
            token : X_Authorization
        }).then((data)=>{
            console.log("here store is units Types Data",data);
            if(data.status === 201){
                setUnitOptions(data.response.data)
            }else{
                console.error("Error setting Unit")
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    function handleAddHouseClick () {
        props.setFlagListUnits()
    }
    const handleGoBackClick = () => {
        props.setFlastStateBack('')
    }
    const billingOptions = [
        {value:"Monthly", label:"Monthly"},
        {value:"Quarterly", label:"Quarterly"},
        {value:"Halfly", label:"Halfly"},
        {value:"Yearly", label:"Yearly"},
    ]

    useEffect(() => {
        getUnitTypes()
    }, [])


    return (
        <div>

            <ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

            <SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>

            <Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={5} mt={3}>

                {/*label*/}
                <Grid item>
                    <span style={{color : '#032541', fontSize : '16px', fontWeight : '600'}} >Edit units</span>
                </Grid>

                {/*form Units Defination*/}
                <Grid item>
                    {/*Type prefix*/}
                    <Grid container direction={'row'} justifyContent={'space-between'} >

                        {/*Type*/}
                        <Grid item>
                            <CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins',fontWeight : 'normal'}} value={type} onChange={(e) => {setType(e.target.value)}} name={"Unit Type"} placeholder={"Select Unit Type"} options={unitTypesOptions} />
                        </Grid>

                        {/*Amount*/}
                        <Grid item mt={1}>
                            <TextField value={amount} onChange={(e) => {setAmount(e.target.value)}} type='number' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541",fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Amount" variant="outlined" required />
                        </Grid>

                    </Grid>

                    {/*Units start*/}
                    <Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={2}>

                        {/*No of units*/}
                        <Grid item>
                            <TextField value={noOfUnits} onChange={(e) => {setNoOfUnits(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541",fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "13px", width : '400px'}} id="outlined-basic" label="No of Units" variant="outlined" required />
                        </Grid>

                        {/*Billing Period*/}
                        <Grid item mt={-1}>
                            <CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '13px',fontWeight : 'normal'}} value={billingPeriod} onChange={(e) => {setBillingPeriod(e.target.value)}} name={"Billing Period"} placeholder={"Select Billing Period"} options={billingOptions} />
                        </Grid>

                    </Grid>

                    {/*label*/}
                    <Grid item mt={3}>
                        <span style={{color : '#032541', fontSize : '16px', fontWeight : '600'}} >House Numbering</span>
                    </Grid>

                    {/*Amount billing period*/}
                    <Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={2}>

                        {/*Prefix*/}
                        <Grid item>
                            <TextField value={prefix} onChange={(e) => {setPrefix(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541",fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Prefix" variant="outlined" required />
                        </Grid>

                        {/*start date*/}
                        <Grid item>
                            <TextField value={startPrefix} onChange={(e) => {setStartPrefix(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541",fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Start From" variant="outlined" required />
                        </Grid>
                    </Grid>

                    {/*cancel add House*/}
                    <Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2}>
                        <Grid item>
                            <Button onClick={() => {props.setFlagListUnits()}} style={{border : '1px solid #dc3545', fontWeight : '600', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px'}} >
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button onClick={editUnit} style={{fontWeight : '600', textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px'}} >
                                Update
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

        </div>
    )
}