import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    payableStatus: "",
    transcations : [],
    
};


const payableSlice = createSlice({
    name: "payable",
    initialState,

    reducers: {
        addPayable: (state, action) => {
            const {payableStatus, transcationIds} = action.payload
            state.payableStatus = payableStatus
            let uniqueData = [...new Set(transcationIds)];
            state.transcations  = uniqueData

        },

        clearPayable: (state, action) => {
            state.payableStatus = ""
            state.transcations = []
        },

    },
});

export const {addPayable,clearPayable  } = payableSlice.actions;
export default payableSlice.reducer;