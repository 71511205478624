import { Grid, Breadcrumbs, Typography, Button, Checkbox } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../../School/MakeRequest";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { handleAddChargeToList, handleAddCurrentServcie, handledeleteCurrentServcie } from "../../../features/tenantsDataSlice";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Create Tenant
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Tenant Details
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        House Details
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Advance Charges
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Preview Invoice
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Update Invoice
    </Typography>
]

export default function UpdateInvoice(props) {
    const { X_Authorization } = useSelector((store) => store.user)
    const [unitCharges ,setUnitCharges]= useState([])

    const dispatch = useDispatch()

    const tenantCurrentServices = useSelector((store)=>store?.tenantData?.tenantCurrentServices)


    //handle box change

    const handleCheckboxChange = (event, product) => {
		if (event.target.checked) {
			let data = {productId : product.productId, name: product.serviceName, amount: product.amount, serviceType : product.serviceType}
			console.log(`this is Data`, data)
			dispatch(handleAddCurrentServcie(data));}
		else {dispatch(handledeleteCurrentServcie(product.productId))}
	};

    //fetch units services

    //Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${props?.unitid}`,
			token: X_Authorization
		}).then((data) => {
			console.log(data, 'hello serrvices per unit')
			if (data.status === 201) {
				setUnitCharges(data.response.data)
				dispatch(handleAddChargeToList(data.response.data))
			} else {
				console.log(`Error getting data`)
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		fetchServicesPerUnit()
	}, [])


    // columns for all services  for the house unit
    const listServiceColumns = [
        { field: 'serviceName', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >Service Name</strong>) },renderCell: (params) => {
            return(
                <div>
                    <Checkbox
                    style={{ color: '#dc3545' }}
                    checked={tenantCurrentServices?.some(s => s.productId === params?.row?.productId)}
                    onChange={(event) => handleCheckboxChange(event, params.row)} />
                <span style={{ color: '#707070', fontSize: '14px' }}>{params.value}</span>
            </div>
            )
        }
     },
        { field: 'serviceType', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >Service Type</strong>) } },
        { field: 'amount', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', fontWeight: '600' }} >Amount</strong>) } },
    ]


    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'}  alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={props?.prev} />
                <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Update Invoice</Typography>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item display={'flex'} justifyContent={'space-between'}  alignItems={'center'}>
                <Typography>List of Services</Typography>
                <Button style={{ backgroundColor: "#032541", color: "#fff", width: "146px", textTransform: "inherit" }}>Create Service</Button>
            </Grid>
            <Grid item>
                {/* TODO : Data grid to show all services */}
                <DataGrid
				components={{ NoRowsOverlay: NoRowsOverlay }}
				sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#707070', boxShadow: 0, border: 0 }}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				columns={listServiceColumns}
				rows={unitCharges}
				getRowId={(row) => row.productId}
				isCellEditable={(params) => params.row.serviceType === 'Variable'}
			/>
            </Grid>
            <Grid item display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} >
                {/* TODO : Update invoice */}
                <Button onClick={props?.goBackToPreviewInvoice} style={{ backgroundColor: "#17ae7b", color: "#fff", width: "146px", textTransform: "inherit" }}>Update Invoice</Button>
            </Grid>
        </Grid>
    )
}