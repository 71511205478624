import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import CustomSelect from "../customSelect";
import StockFunctions from "../stockEndpoints";
import { stockStyles } from "../stockStyling";
import { ArrowBackIos } from "@mui/icons-material";
import Breadcrumbcomponent from "../breadcrumb";
import { useNavigate } from "react-router-dom";

const TransferStockSetUp = (props) => {
  const navigate = useNavigate();

  const [storeFrom, setStoreFrom] = useState("");
  const [storeTo, setStoreTo] = useState("");
  const [storesFromData, setWarehouses] = useState([]);
  const [storesDataTo, setStoreDataTo] = useState([]);
  const [storeFromId, setStoreFromId] = useState("");
  const [storeToId, setStoreToId] = useState("");
  const setStoreFromName = props?.setStoreFromName
  const setStoreNameTo = props?.setStoreNameTo


  const storesFrom = [
    { name: "", _id: "" },
    { name: "Warehouse", _id: "Warehouse" },
    { name: "Main store", _id: "MainStore" },
    { name: "Sub store", _id: "SubStore" },
  ];

  useEffect(() => {
    const stockfunctions = new StockFunctions();
    if (storeFrom)
      stockfunctions.findStoresByType(storeFrom, 1, 1000).then((data) => {
        setWarehouses(data.response.data);
      });
    // findStoresByType = (storeType, page, pageSize) => {
  }, [storeFrom]);

  const storesTo = [
    { name: "", _id: "" },
    { name: "Warehouse", _id: "Warehouse" },
    { name: "Main store", _id: "MainStore" },
    { name: "Sub store", _id: "SubStore" },
  ];

  useEffect(() => {
    const stockfunctions = new StockFunctions();
    if (storeTo)
      stockfunctions.findStoresByType(storeTo, 1, 1000).then((data) => {
        setStoreDataTo(data.response.data);
      });
    // findStoresByType = (storeType, page, pageSize) => {
  }, [storeTo]);

  useEffect(() => {
    props?.setStoreFromId(storeFromId);
    props?.setStoreToId(storeToId);
  }, [storeFromId, storeToId]);

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"}>
        <Grid item sx={{ margin: "0% 0% 1% 0%", width: "30%" }}>
          <p style={{ margin: "0% 0% 2% 0%" }}>Transfer From</p>
          <CustomSelect storesFrom={storesFrom} setItemId={setStoreFrom} selectLabel={"Store From"} />
        </Grid>

        {storeFrom && (
          <Grid item sx={{ margin: "0% 0% 1% 0%", width: "30%" }}>
            <p style={{ margin: "0% 0% 2% 0%" }}>{storeFrom}</p>
            <CustomSelect storesData={storesFromData} setItemId={setStoreFromId} selectLabel={storeFrom} setStoreFromName={setStoreFromName} />
          </Grid>
        )}

        {storeFromId && (
          <Grid item sx={{ margin: "0% 0% 1% 0%", width: "30%" }}>
            <p style={{ margin: "0% 0% 2% 0%" }}>Transfer To</p>
            <CustomSelect storesTo={storesTo} setItemId={setStoreTo} selectLabel={"Store To"} />
          </Grid>
        )}

        {storeTo && (
          <Grid item sx={{ margin: "0% 0% 1% 0%", width: "30%" }}>
            <p style={{ margin: "0% 0% 2% 0%" }}>{storeTo}</p>
            <CustomSelect storesDataTo={storesDataTo} setItemId={setStoreToId} selectLabel={storeTo} setStoreNameTo={setStoreNameTo} />
          </Grid>
        )}

        <Grid item sx={{ margin: "0% 0% 1% 0%", width: "30%" }} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Button onClick={() => (storeFromId && storeToId ? navigate(`/transferstock/categories?${storeFromId}?${storeToId}`) : null)} style={storeToId && storeFromId ? stockStyles.nextButtonActive : stockStyles.nextButtonInactive}>
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TransferStockSetUp;
