import {TextField} from "@mui/material";
import React from 'react';

const CustomTextFieldSmall = ({value, onChange,name, placeholder,...rest }) => {
    return (
        <TextField
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            variant="outlined"
            margin="dense"
            sx={{marginBottom:2}}
            InputProps={{
                sx: {
                    width: '15rem',
                    height: '3.438rem',
                    fontSize:"0.875rem",
                    "& fieldset": {
                        borderColor: "#bec5d1"
                    }
                }
            }}
            {...rest}
        />
    );
};

export default CustomTextFieldSmall;

