import { Stack, LinearProgress,Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { setSalesSeries } from "../../../features/SalesChartSlice";

// import { handleRental, handleSchool, handleNormal } from "../../../features/salesChartSlice";
// import { useDispatch, useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_BASE_URL;

function SalesChart(props) {
  const series = useSelector((state) => state.salesSeries.series);

  let Group = localStorage.getItem("group");
  let partnerName = localStorage.getItem("affiliatedPartner");
  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  // const [series, setSeries] = useState([
  //   {
  //     name: "Schools",
  //     data: [],
  //   },
  //   {
  //     name: "Normal businesses",
  //     data: [],
  //   },
  //   {
  //     name: "Rentals",
  //     data: [],
  //   },
  // ]);

  const [localSeries, setLocalSeries] = useState([
    {
      name: "Schools",
      data: [],
    },
    {
      name: "Other businesses",
      data: [],
    },
    {
      name: "Rentals",
      data: [],
    },
  ]);

  const [isEmpty, setIsEmpty] = useState(true);

  let year = useSelector(state => state.salesDropdown.clickedItem)

  let yearToPass 

  if(year === "2022") {
    yearToPass = 2022
  }
  else if(year === "2023") {
    yearToPass = 2023
  }
  else if(year === "2024") {
    yearToPass = 2024
  }

  const fetchChartValues = async () => {
    console.log('fetcher called')
    setLoading(true)
    try {
      const response = await axios.post(
        baseUrl + "/api/fetchCategoryTotalsByMonth2",
        {
          year: yearToPass
        },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
      );

      if (isEmpty || !isEmpty) {
        setLocalSeries(null);
        setIsEmpty(false);
        if(isAdmin) {
          setLocalSeries([
            {
              name: "Schools",
              data: response.data.schoolResponse,
            },
            {
              name: "Other businesses",
              data: response.data.normalResponse,
            },
            {
              name: "Rentals",
              data: response.data.rentalResponse,
            },
          ]);
        } else if (isPartner) {
          setLocalSeries([
            {
              name: "Schools",
              data: response.data.schoolResponse,
            },
            {
              name: "Other businesses",
              data: response.data.normalResponse,
            },
            // {
            //   name: "Rentals",
            //   data: response.data.rentalResponse,
            // },
          ]);
        }
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchChartValues();
  }, [year]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSalesSeries(localSeries));
    setLoading(false)
  }, [localSeries]);

  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          value?.toFixed(0);
          value /= 1000000;
          // value.toLocaleString();
          return value + "M";
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          value?.toFixed(0);
          return value?.toLocaleString();
        },
      },
    },
    legend: {
      // offsetY: 10,
      offsetY: 5,
      itemMargin: {
        horizontal: 20,
        vertical: 0,
      },
    },
  });

  const [loading, setLoading] = useState(true)

  return (
    <Stack width={'100%'} >
        {loading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null}
          {series[0].data.length === 0 ? (
            // <CircularProgress
            //   sx={{
            //     alignSelf: "center",
            //     justifySelf: "center",
            //     mt: "150px",
            //   }}
            // />
            <Skeleton
          variant="rectangular"
          width={"100%"}
          height={320}
          sx={{ bgcolor: "#f0f0f0" }}
          animation="wave"
        />
          ) : (
            <Chart options={options} series={series} type="line" height={'320'} />
          )}
        </Stack>
  );
}

export default SalesChart;
