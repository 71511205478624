import { Grid, TextField, InputLabel, Typography ,Button } from '@mui/material'
import { ErrorAlert } from '../../../../snackBar Alerts/errorAlert'
import { SuccessAlert } from '../../../../snackBar Alerts/successAlert'
import {useSelector , useDispatch} from 'react-redux'
import { useState } from 'react'
import HttpComponent from '../../../../School/MakeRequest'
import { handleSaveOutlets } from '../../../../../features/businessAddPartner'
export default function AddOutletStepper(props) {
    const {businessNumber ,name  , branchId } = useSelector((store)=>store.BusinessPartnerDetails.businessDetails)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formdata, setFormdata] = useState({ outletName: '', outLetdescription: '' })
    const dispatch = useDispatch()

    // handle form change

    const handleChange = (e) => {
        setFormdata({ ...formdata, [e.target.name]: e.target.value })
    }

    console.log(branchId , 'branchId')


    // add storee


    function handleadddStore() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/addStore`,
                token: localStorage.getItem('X-Authorization'),
                body: {
                    storeName: formdata.outletName,
                    description: formdata.outLetdescription,
                    businessNumber: businessNumber,
                    branchId: branchId,
                    storeType: "MainStore",
                }
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    dispatch(handleSaveOutlets({name:formdata.outletName , description:formdata.outLetdescription , outletNameId:data?.response?.data?._id , status:data?.response?.data?.status}))
                    setTimeout(()=>{
                        props.openAddOutlet();
                    },1000)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }
    }




    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2}>
                <Typography variant='h6' sx={{ color: '#032541' }}>Outlet Details</Typography>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Outlet Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formdata.outletName} name='outletName' onChange={handleChange} id="outlined" required />
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Outlet Description</InputLabel>
                <TextField style={{ width: "100%" }} value={formdata.outLetdescription} name='outLetdescription' onChange={handleChange} id="outlined" required />
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Institution</InputLabel>
                <TextField disabled style={{ width: "100%" }} value={name} name='outLetdescription'  id="outlined" required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => handleadddStore()} disabled={!formdata.outletName || !formdata.outLetdescription } style={!formdata.outletName || !formdata.outLetdescription ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Submit</Button>
            </Grid>
        </Grid>
    )
}