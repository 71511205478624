/* eslint-disable react-hooks/rules-of-hooks */
import CustomTable from "../../../../School/CustomTable";
import { Button, Grid ,Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import CurrencyFormatter from "../../../../../utils/currencyFormatter";
import HttpComponent from "../../../../School/MakeRequest";
import ListTenants from "./listTenants";

export default function ListHouseUnits(props) {
  try {
    const navigate = useNavigate();
    const { X_Authorization } = useSelector((store) => store.user);

    // Handle Radio Button
    const [selectedValue, setSelectedValue] = React.useState();
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const columns = [
      {
        field: "houseNumber",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>House Number</strong>;
        },
        renderCell: (params) => {
          return (
            <div onClick={() => props.handleUnitId(params.row)}>
              <Link onClick={handleNext}>
              <span style={{ marginLeft: "5px" }}>{params.value}</span>
              </Link>
              <Link  value={params.unitId} onChange={handleChange} color={"error"}></Link>
              {/* <Radio value={params.unitId} size={"small"} checked={selectedValue === params.unitId} onChange={handleChange} color={"error"} /> */}
            
            </div>
          );
        },
      },
      {
        field: "numberOfTenants",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>NumberofTenants</strong>;
        },
        renderCell: (params) => {
          return <span>{params.value}</span>;
        },
      },
      {
        field: "tenantsVacated",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Vacated</strong>;
        },
        renderCell: (params) => {
          return <span>{params.value}</span>;
        },
      },
      {
        field: "createdOn",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Created On</strong>;
        },
        renderCell: (params) => {
          return <span>{params.value}</span>;
        },
      },
    ];

    // loader
    const [loader, setLoader] = useState(false);

    // Data
    const [data, setData] = useState([]);


    // Search functionality
    const [dataToShow, setDataToShow] = useState([]);
    const [formData, setFormData] = useState({ search: "" });
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const setSearchData = () => {
      let searchable = [...data];
      if (formData.search !== "") {
        let result = searchable.filter((unit) => unit.unitType.toLowerCase()?.includes(formData.search) || unit.unitTypePrefix?.includes(formData.search));
        setDataToShow(result);
      } else {
        setDataToShow(data);
      }
    };

    // Use Effect
    // Search UseEffect
    useEffect(() => {
      setSearchData();
    }, [formData.search, data]);

    function fetchUnits() {
      try {
        HttpComponent({
          method: "GET",
          url: `/api/v1/unitTypeHistory?unitTypeId=${props.detail.id}&search=limit=1000&page=1`,
          token: X_Authorization,
        })
          .then((data) => {
            if (data.status === 200) {
              setData(data.response.data);
              props.setDataToShow(data.response.data)
            } else {
              console.log(`Error getting Units`);
            }
          })
          .catch((e) => {
            console.log(`Error getting transaction BreakDown`, e.message);
          });
      } catch (e) {
        console.log(`Error getting Units`, e.message);
      }
    }

    useEffect(() => {
      fetchUnits();
    }, []);

  
    //handle next

    //const [showUnits, setShowUnit] = useState(true);

    function handleNext() {
      props.listTenants();
    }

    return (
      <div>
        {/* {showUnits &&  */}
        <CustomTable fontSize={"10px"} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />

        {/* {!showUnits && <ListTenants selectedValue={selectedValue} />} */}
        <Grid container justifyContent={"flex-end"} spacing={3}>
          {/* {!selectedValue ? null : ( */}
          {/* <Grid item>
            <Button onClick={handleNext} style={{ padding: "5px", textTransform: "none", color: "#fff", background: "#032541", height: "45px", fontSize: "16px", fontWeight: "600", width: "146px" }}>
              Next
            </Button>
          </Grid> */}
           {/* )}  */}
        </Grid>
      </div>
    );
  } catch (e) {
    console.log(`Something Went Wring with List House Units`, e.message);
  }
}