import { Box, Breadcrumbs, Button, Grid, Tab, Typography } from "@mui/material"
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import EditIcon from '@mui/icons-material/Edit';
import HttpComponent from "../MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { AddSponsoredStudents } from "./addSponsoredStudent";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ExportMenu from "../ExportMenu";
import { EditSponsoredStudents } from "./editSponsoredStudent";
import { currencyconverter } from "../../../common/currencyconverter";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Sponsored Students</Typography>,
]


// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: 'transparent' } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "17px", background: 'transparent', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#cb444a", backgroundColor: 'transparent', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: 'transparent' },
}));

const ListStudentSponsored = () => {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const [tabValue, setTabValue] = useState('ACTIVE')
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")

    const sponsorColumns = [
        { field: "itemNumber", headerName: "Student ID", flex: 1},
        { field: "parentName", headerName: "Student Name", flex: 1 },
        { field: 'SponsorName', headerName: 'Sponsor Name', flex: 1 },
        { field: 'maxAmountToSpend', headerName: 'Max Amount Per Day', flex: 1 },
        { field: 'sponsorTransactionCount', headerName: 'Transactions', flex: 1 },
        { field: 'totalAmountSpend', headerName: 'Total Amount Spend', flex: 1 , renderCell: (params) => {
            return <Typography>{currencyconverter(params?.row?.sponsorTransactionTotal)}</Typography>
        } },
        {
            field: 'action', headerName: 'Action', flex: 1, renderCell: (params) => {
                return (
                    <Box component={'div'} alignItems={'center'}>
                        <EditIcon  onClick={() => navigate(`/school/sponsors/students?EditSponsoredStudents?${params?.row?.customerId}`)} sx={{ color: '#032541', cursor: 'pointer', marginLeft: '10px' }} />
                        <PersonOffIcon sx={{ color: '#dc3545', cursor: 'pointer', marginLeft: '10px' }} />
                    </Box>
                )
            }
        },
    ]
    function fetchStudentsSponsorsAdded() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_students_in_school?page=${pageState.page}&limit=${dataGridPageSize}&status=${tabValue}&sponsorStatus=Active&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchStudentsSponsorsAdded()
    }, [pageState.page, dataGridPageSize, tabValue, searchValue , queryParamValue])

    const filedata = pageState?.data?.map((data) => {
        return {
            "Student ID": data?.itemNumber,
            "Student Name": data?.parentName,
            "Sponsor Name": data?.SponsorName,
            "Max Amount Per Day": data?.maxAmountToSpend,
            "Transactions": data?.studentCount,
            "Total Amount Spend": data?.totalAmountSpend,

        }
    })
    const fileHeaders = [["Student ID", "Student Name", "Sponsor Name", "Max Amount Per Day" , "Transactions", "Total Amount Spend"]]
    const csvColumns = [
       {label: "Student ID", key: "Student ID"},
       {label: "Student Name", key: "Student Name"},
       {label: "Sponsor Name", key: "Sponsor Name"},
       {label: "Max Amount Per Day", key: "Max Amount Per Day"},
       {label: "Transactions", key: "Transactions"},
       {label: "Total Amount Spend", key: "Total Amount"}

    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.itemNumber, data?.parentName, data?.SponsorName, data?.maxAmountToSpend ,data?.studentCount, data?.totalAmountSpend]);
    return (
        <Grid container width={'100%'} display={'flex'} direction={'column'}>
            {queryParamValue === 'AddSponsoredStudents' ? <AddSponsoredStudents /> :queryParamValue === 'EditSponsoredStudents' ? <EditSponsoredStudents/>: <>
                <Grid item mt={2} mb={2}>
                    <Grid container gap={2} display={'flex'} direction={'column'}>
                        <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                                <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Sponsored Students</Typography>
                            </Grid>
                            <Button onClick={() => navigate(`/school/sponsors/students?AddSponsoredStudents`)} style={{ background: "#032541", borderRadius: "5px", textTransform: "inherit", width: "200px", height: "45px", color: "#fff" }}>Add Sponsored Students</Button>
                        </Grid>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid item mt={2}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                            <AntTab label="Active" value="ACTIVE" />
                            <AntTab label="Deactivated" value="Suspended" style={{ marginLeft: '20px' }} />
                        </AntTabs>
                        <TabPanel value="ACTIVE" style={{ marginLeft: '-30px' }}>
                            <Grid container direction={'column'}>
                                <Grid item>
                                    <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"}>
                                        <Grid item width={"30%"}>
                                            <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                                        </Grid>
                                        <Grid item>
                                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Sponsors'} title={'Sponsors'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={"100%"} mt={5}>
                                    <DataGrid
                                        components={{ NoRowsOverlay: NoRowsOverlay }}
                                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                        rowCount={pageState?.total}
                                        loading={pageState?.isLoading}
                                        pagination
                                        page={pageState?.page - 1}
                                        pageSize={dataGridPageSize}
                                        paginationMode="server"
                                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        columns={sponsorColumns}
                                        rows={pageState?.data}
                                        getRowId={row => row?.customerId} />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>}
        </Grid>
    )
}

export { ListStudentSponsored }