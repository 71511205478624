import { Grid, Breadcrumbs, Typography, Button, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Device Model</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create Type</Typography>
]

export default function CreateNewDevice() {

    const [formData, setFormData] = useState({ deviceName: "", deviceType: "", supportVersion: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const options = [
        {
            label: "POS",
            value: "POS"
        },
        {
            label: "Android POS",
            value: "ANDROIDPOS"
        }
    ]
    //handle navigate back

    const navigate = useNavigate()

    //handle onchange

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const submitData = {
        deviceName:formData.deviceName,
        deviceType:formData.deviceType,
        version:formData.supportVersion
    }

    //handle submit data

    function handleSubmit(){
        try {
            HttpComponent({
                method:"POST",
                url:`/api/v1/addModels`,
                body:submitData,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                console.log(data , 'device created')
                if(data.status === 201){
                    setSuccessShow({state:true , message:"Device created successfully"})
                    setFormData('')
                }else{
                    setErrorShow({state:true , message:"Error creating device"})
                }
                setTimeout(()=>{
                    navigate(-1)
                },[1500])
            })
        } catch (error) {
            
        }
    }

   
    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item style={{display:"flex" , cursor:"pointer" , alignItems:"center" , alignContent:"center"}}>
                <ArrowBackIosIcon onClick={()=>navigate(-1)}/>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Create Type</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Device Model Details </Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name='deviceName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.deviceName} label="Device Name" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Device Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.deviceType}
                        label="Device Type"
                        onChange={handleOnChange}
                        name="deviceType"
                    >
                        {options?.map((item) => (
                            <MenuItem name="deviceType" key={item?.value} value={item?.value}>{item?.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name='supportVersion' onChange={handleOnChange} style={{ width: "100%" }} value={formData.supportVersion} label="Supported Version" variant="outlined" />
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleSubmit} style={!formData.deviceName || !formData.deviceType  ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Submit</Button>
            </Grid>
        </Grid>

    )
}