import { ArrowDropDown } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import ModulesTable from "./ModulesTable";


function Modules() {
  return (
    <Stack
      width={"100%"}
      height={490}
      borderRadius={5}
      backgroundColor="#ffffff"
      shadowColor="rgba(145, 158, 171, 0.12)"
      shadowRadius={20}
      shadowOpacity={1}
      spacing={2}
    >
      <Stack direction={"row"} justifyContent={"space-between"} paddingTop={2} px={2}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Modules
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            // fontWeight="bold"
            fontStyle="normal"
            textAlign="right"
            color="grey"
          >
            View:
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="right"
            color="#000000"
          >
            All Time
          </Typography>
          <ArrowDropDown  />
        </Stack>
      </Stack>
      
      <Stack width={'100%'} height={'100%'}>
        <ModulesTable />
      </Stack>
    </Stack>
  );
}

export default Modules;
