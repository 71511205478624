import { Breadcrumbs, Grid, List, ListItem, Typography, Switch, Box, Button, Modal } from "@mui/material"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import HttpComponent from "../School/MakeRequest";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import EditConfig from "./EditConfig";
import EditPaymentConfig from "./EditPaymentConfig";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import ZedaccImg from '../../images/zedacc.svg'
import axios from "axios";
import jwtDecode from "jwt-decode";



const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Configurations</Typography>
];


const zedAccountingmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 2,
}

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL


export default function TenantConfig(props) {
    const { X_Authorization } = useSelector((store) => store.user)
    const [configMethods, setConfigMethods] = useState([])
    const [switchState, setSwitchState] = useState()


    const manipulateSwitch = (newState) => {

        if (!switchState) {
            setSwitchState(true)
        }
        setSwitchState(newState)
    }

    const [invoicerReminder, setInvoiceReminder] = useState(null);
    const [paymentReminder, setPaymentReminder] = useState(null);



    const [configurationId, setConfigurationId] = useState()
    const [flagState, setFlagState] = useState('listconfig')

    const handleFlagState = (state) => {
        setFlagState(state)
    }

    const handleNext = (id) => {
        setConfigurationId(id)
    }

    const [openZedAccountingModal, setOpenZedAccountingModal] = useState(false);
    const [accountStatus, setAccountStatus] = useState()
    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [message, setMessage] = useState('')

    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };

    const [zedAccountingStatus, setZedAccountingStatus] = useState(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        return savedStatus ? JSON.parse(savedStatus) : false;
    })

    const handleOpenZedAccountingModal = () => {
        setOpenZedAccountingModal(true)
    }
    const handleCloseZedAccountingModal = () => {
        if (zedAccountingStatus === true) {
            return;
        }
        setOpenZedAccountingModal(false);
        setTimeout(() => {
            window.location.reload()
        }, [1000])
    }

    const swicthAccounting = () => {
        if (accountStatus) {
            handleCloseZedAccountingModal()
        } else if (!accountStatus) {
            handleOpenZedAccountingModal()
        }

    }

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data?.response?.data?.accountingEnabled)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAccountingStatus()
    }, [])



    //enable accounting
    function handleEnableAccounting() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enable_accounting?status=true',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async (data) => {

                //console.log(data , 'heeeeee')

                if (data.status === 201) {
                    setMessage('Zed Accounting enabled successfully , click zedAccounting icon to proceed')
                    handleSuccessNotificationClick();
                    setZedAccountingStatus(data.response.data.accountingEnabled)
                    localStorage.setItem('zedAccountingStatus', true);
                }
            }).then(async () => {
                const newT = await HttpComponent({
                    method: "POST",
                    url: `/api/get_token_after_invite`,
                    token: localStorage.getItem('X-Authorization')
                })
                const result = newT?.response?.data?.token
                const decodedT = jwtDecode(result)
                localStorage.setItem('X-Authorization', result);
                localStorage.setItem('zedAccountingId', decodedT?.zedAccountingId);
                setTimeout(() => {
                    handleCloseZedAccountingModal()
                    window.location.reload()
                }, 1000);
            })
        } catch (error) {
            console.log(error)
        }
    }
    //fetch the config parameters

    function fetchTenantsConfig() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/rental_configs?limit=1000&page=1`,
                token: X_Authorization
            }).then((data) => {

                if (data.status === 200) {
                    setConfigMethods(data.response.data)
                    setPaymentReminder(data.response.data[0].status)
                    setInvoiceReminder(data.response.data[1].status)
                }
            })

        } catch (error) {

        }
    }


    useEffect(() => {
        fetchTenantsConfig();
    }, []);


    //Invoice Reminder Config


    return (
        <div>
            {flagState === 'listconfig' ?
                <>
                    <Grid item mb={3}>
                        <Typography style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} variant="h6">  <ArrowBackIosIcon /> Configurations</Typography>
                    </Grid>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid container item justifyContent={"space-between"} mt={3} mb={3}>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Configuration
                        </Grid>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            value
                        </Grid>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Action
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item style={{ width: "100%" }}>
                            <List>
                                {configMethods.map((configM, index) => {
                                    return (
                                        <ListItem key={configM._id} style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                            <Grid container justifyContent={"space-between"} style={{ display: "flex", width: "100%" }}>
                                                <Grid item style={{ paddingTop: "10px", fontFamily: "Poppins", fontSize: "15px", color: "#032541" }}>
                                                    {configM?.description}
                                                </Grid>
                                                <Grid item>
                                                    {configM?.period && configM.status === true ? ` ${configM?.period} days` : "Not Set"}
                                                </Grid>
                                                <Grid item>
                                                    {/* <Switch checked={configM?.description === 'Payment Reminder Config' ? configM.status : configM?.description === 'Invoice Reminder Config' ? configM.status :null } onChange={() =>  configM?.description === 'Payment Reminder Config' && configM.status ? manipulateSwitch(configM?.status)  : manipulateSwitch(configM?.status)} /> */}
                                                    <EditIcon onClick={() => {
                                                        handleNext(configM._id);
                                                        handleFlagState(configM?.description === 'Payment Reminder Config' ? 'editPaymentConfig' : 'editInvoiceConfig')

                                                    }} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    );
                                })}

                                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Accounting</Box>
                                        <Box>
                                            <Switch checked={accountStatus} onClick={swicthAccounting} />
                                        </Box>
                                    </Box>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Modal
                        open={openZedAccountingModal} onClose={handleCloseZedAccountingModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                        <Box sx={zedAccountingmodal}>
                            <form>
                                <Box style={{ display: "flex", justifyContent: "" }}>
                                    <Box>
                                        <img src={ZedaccImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                                    </Box>
                                    <Box style={{ marginLeft: "20px" }}>
                                        <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Accounting </Box>
                                        <Box>
                                            <p style={{ fontSize: "14px", color: "#707070" }}>
                                                Enabling accounting  will allow the business to:
                                            </p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "0px" }}>

                                            <Box>
                                                <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                            </Box>
                                            <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Manage financial transactions</p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "0px" }}>

                                            <Box>
                                                <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                            </Box>
                                            <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "0px" }}>

                                            <Box>
                                                <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                            </Box>
                                            <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare budget forecasts.</p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "0px" }}>
                                            <Box>
                                                <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                            </Box>
                                            <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements..</p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "0px" }}>
                                            <Box>
                                                <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                            </Box>
                                            <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish financial statements in time.</p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "0px" }}>
                                            <Box>
                                                <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                            </Box>
                                            <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile accounts payable and receivable..</p>
                                        </Box>
                                        <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                            <Box>
                                                <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                                    handleCloseZedAccountingModal

                                                }>Cancel</Button>
                                            </Box>
                                            <Box>
                                                <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                                    onClick={handleEnableAccounting}>Enable</Button>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
                    <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
                </>
                : flagState === 'editPaymentConfig' ? <EditPaymentConfig paymentReminder={paymentReminder} configId={configurationId} />
                    : flagState === 'editInvoiceConfig' ? <EditConfig invoicerReminder={invoicerReminder} configId={configurationId} />
                        : null

            }

        </div>
    )
}