import {createSlice} from "@reduxjs/toolkit";

let initialState = {
	units : 0,
	allocated : 0,
	unitToAdd : null,
	businessConfigId : null
}

const tenantsSlice = createSlice({
	name : 'tenants',
	initialState,
	reducers : {
		handleUnit : (state,action) => {
			state.units = action.payload
		},
		handleAllocation : (state, action) => {
			state.allocated = action.payload
		},
		handleBusinessConfigId : (state, action) => {
			state.businessConfigId = action.payload
		},
		handleAddUnit : (state, action) => {
			if(!state.unitToAdd) {
				state.unitToAdd = [...action.payload]
			} else {
				state.unitToAdd.push(action.payload)
			}
		}
	}
})

export const {handleUnit, handleAllocation, handleAddUnit, handleBusinessConfigId} = tenantsSlice.actions
export default tenantsSlice.reducer