import { Breadcrumbs, Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentPinVerify from "./paymentPinVerify";
import PaymentVerifyOtp from "./paymentVerifyOtp";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Payment Setup</Typography>
   
]

export default function AuthecateUser(props) {
    console.log(props, 'props handleIsAuthecated');
    const steps = ['verifyStep1', 'verifyStep2']
    const [activeStep, setActiveStep] = useState(1)
    const [pin, setPin] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const navigate = useNavigate()

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

     const parentSubChildCallBack = (childData) => {
        console.log(childData, 'childData parentSubChildCallBack');
        let pin = childData.pin
        let email = childData.email
        let phone = childData.phone
        setPin(pin)
        setEmail(email)
        setPhone(phone)
        setActiveStep(2)

    

     }
     const parentCallBackAuthChild = (childData) => {
        let data = {
            state: childData.state,
        }
        console.log(data, 'data parentCallBackAuthChild');
        props.handleIsAuthecated(data)
     }

    

    const renderFormContent = () => {
        switch (activeStep) {
            case 1:
                return (
                   <PaymentPinVerify parentSubChildCallBack = {parentSubChildCallBack}  />
                );
            case 2:
                return (
                    <Grid item>
                        <PaymentVerifyOtp pin = {pin} email = {email}  phone = {phone}   parentCallBackAuthChild ={parentCallBackAuthChild}/>
                    </Grid>
                );
            default:
                return null
        }

    }
    console.log(activeStep, 'activeStep');


    return (
        <Grid container direction={'column'}>
            <Grid item mt={1} display={'flex'} alignItems={'center'} style={{cursor:"pointer"}} onClick={() => navigate(-1)}>
                <ArrowBackIosNewIcon style={{ width: '25px', height: '30px', marginRight: "10px" }} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541", cursor:"pointer" }} onClick={() => navigate(-1)}>Verification</Typography>
            </Grid>
            <Grid item mt={1}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item>
                <Typography>step {activeStep} of {steps?.length}</Typography>
            </Grid>
            <Grid item mt={2}>
                {renderFormContent()}
            </Grid>
        </Grid>
    )
}