import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ZedLogo from "../../images/zedLogo.png";
import ZedSmall from "../../common/images/ZedLogo2.png";
import { Link } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Search from "../../components/SuperAdmin/components/Search.js";
import { Stack } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import "../../components/SuperAdmin/styles/NavbarStyles.css";
import SidebarList from "../../components/SuperAdmin/components/SidebarList";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const drawerWidth = 245;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  width: "95.5%",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(99.5% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminRoutes = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((store) => store.user);
  let businessCat = localStorage.getItem("businessCategory");

  // let auth = {'token':true}
  // const businessSetup = useSelector(state => state.isBusinessSetup)
  const [businessSetup, setBusinessSetup] = useState(false);
  const [pad, setPad] = useState(0);
  const location = useLocation();

  // Check the current route path and set isSpecialPage accordingly
  useEffect(() => {
    if (
      location.pathname === "/CreateBusiness" ||
      location.pathname === "/createBusiness"
    ) {
      setBusinessSetup(true);
      setPad(0);
    } else if (location.pathname.startsWith("/setup")) {
      setBusinessSetup(true);
      setPad(8);
    } else {
      setBusinessSetup(false);
      setPad(0);
    }
  }, [location.pathname]);

  const group = localStorage.getItem("group");

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const ZedPayments = {
    width: "147px",
    height: "28px",
    margin: "1px 20px 1px 20px",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#707070",
  };
  const currentPath = window.location.pathname; 
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor:
          currentPath === "/terminal/mngt" ||
          currentPath === "/payables/admin/transactions" ||
          currentPath === "/admin/zpmbiotransactions" ||
          currentPath === "/admin/zpmcardtransactions" ||
          currentPath === "/payit/payitadmin" ||
          currentPath === "/admin/allzpmtransactions" ||
          currentPath === "/payItMode"  ||
          currentPath === "/feeconfigs" ||
          currentPath === "/viewPartnerBusiness" ||
          currentPath === "/admin/adminwalletereports" ||
          currentPath === "/admin/zpmcashtransactionsadmin" ||
          currentPath === "/admin/adminreconciliationreport" || 
          currentPath === '/zpmactivationfeereport' || 
          currentPath === "/failedcardreportadmin" || 
          currentPath === "/admin/auditlogs" ||
          currentPath === "/partners/insurancecompanies" || 
          currentPath === "/partners/vehicletypes" || 
          currentPath === "/partners/vehicleroutes" || 
          currentPath === "/partnerproductscategories" || 
          currentPath === "/partnerproducts" || 
          currentPath === '/zpmbioregistrationreport' ||
          currentPath === '/partner/terminalsummaryreports' ||
          currentPath === '/partner/zpmbankpaybillreport' ||
          currentPath === '/partner/regions' ||
          currentPath === '/partner/salesperson' ||
          currentPath === '/admin/zedmpesadarajasetup' ||
          currentPath === '/zedb2btransactions' ||
          currentPath === '/partner/branches' ||
          currentPath === '/admin/merchantfeeconfig' ||
          currentPath === '/admin/merchantreport' ||
          currentPath === '/admin/summarydetails' ||
          currentPath === "/admin/settlmentreport" ||
          currentPath === "/admin/revenueshare" || 
          currentPath === "/admin/merchantaccountconfig" ||
          currentPath === "/admin/merchantscheduling" ||
          currentPath === "/admin/settlementreview"
            ? "white" 
            : "#f0f0f0",
      }}
    >
      <CssBaseline />
      <AppBar
        sx={{ bgcolor: "white", color: "black", height: "80px" }}
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 2,
              // ...(!open && { marginLeft: 8 }),
            }}
          >
            <MenuOpenIcon />
          </IconButton>
          <Typography variant="h6" style={ZedPayments} noWrap component="div">
            Zed Payments
          </Typography>
          <Stack
            direction={"row"}
            spacing={3}
            alignItems={"center"}
            marginLeft={"auto"}
            justifyContent={"flex-end"}
          >
            <Search />
            <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <NotificationsIcon />
            </motion.div>
            <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <AccountCircleIcon />
            </motion.div>
            <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <PowerSettingsNewIcon onClick={() => navigate("/logout")} />
            </motion.div>
          </Stack>
        </Toolbar>
      </AppBar>

      <Drawer position={"fixed"} variant="permanent" open={open}>
        <DrawerHeader
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
          {open ? (
            <Link to={"/adminDashboard"} style={{ marginLeft: 10, marginTop: 10 }}>
              <img src={ZedLogo} alt="Zed Logo" width="140px" height="70px" />
            </Link>
          ) : (
            <Link
              to={"/adminDashboard"}
              style={{ alignSelf: "center", marginLeft: 5 }}
            >
              <img src={ZedSmall} alt="Zed Logo" />
            </Link>
          )}
        </DrawerHeader>
        {/* <Divider /> */}

        <SidebarList open={open} />
      </Drawer>

      {businessSetup ? (
        <Box
          component="main"
          sx={{ flexGrow: 1, paddingY: pad, px: 1, bgcolor: "white" }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1, paddingY: 3, px: 1 }}>
          <DrawerHeader />
          <Outlet />
        </Box>
      )}
    </Box>
  );
};

export default AdminRoutes;
