import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Stack, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../../../features/actionSlice";



export default function TerminalsDropdown2() {
  const dispatch = useDispatch();

  const handleClick = (clicked) => {
    dispatch(handleModel(clicked)); //setting the clicked item in redux
  };

  const model = useSelector((state) => state.dropdownAction.model);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* <ThemeProvider theme={theme}> */}
          <Button variant="text" {...bindTrigger(popupState)}>
            <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: 12,
              fontWeight: "normal",
              fontStyle: "normal",
              textAlign: "left",
              color: "#707070",
              textTransform: "none"
            }}
            >{model}</Typography>
            <MoreVertIcon sx={{ color: "#032541" }} />
          </Button>
          {/* </ThemeProvider> */}
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                handleClick("urovo");
                popupState.close();
              }}
            >
              <Stack direction={"row"} spacing={1}>
                {/* <EditIcon color={"primary"} /> */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 12,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "left",
                    color: "#707070",
                  }}
                >
                  urovo
                </Typography>
              </Stack>
              <Divider variant={"middle"} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClick("fesian");
                popupState.close();
              }}
            >
              <Stack direction={"row"} spacing={1}>
                {/* <ReplayIcon color={"success"} /> */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 12,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "left",
                    color: "#707070",
                  }}
                >
                  fesian
                </Typography>
              </Stack>
              <Divider variant={"middle"} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClick("all models");
                popupState.close();
              }}
            >
              <Stack direction={"row"} spacing={1}>
                {/* <ReplayIcon color={"success"} /> */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 12,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "left",
                    color: "#707070",
                  }}
                >
                  all models
                </Typography>
              </Stack>
              <Divider variant={"middle"} />
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
