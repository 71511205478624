import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CustomSelect = (props) => {
  const label = props?.selectLabel;
  const suppliers = props?.suppliers;
  const warehouses = props?.warehouses;
  const storesFrom = props?.storesFrom;
  const storesData = props?.storesData;
  const storesTo = props?.storesTo;
  const storesDataTo = props?.storesDataTo;
  const addStockStores = props?.addStockStores;
  const addNewSupplierData = props?.addNewSupplierData;
  const storesToTakeCount = props?.storesToTakeCount;
  const transferWarehouses = props?.transferWarehouses;

  const setSupplierName = props?.setSupplierName;
  const setWarehouseName = props?.setWarehouseName;
  const setStoreFromName = props?.setStoreFromName;
  const setStoreNameTo = props?.setStoreNameTo;

  const dataToMap = suppliers ? suppliers : warehouses ? warehouses : storesFrom ? storesFrom : storesData ? storesData : storesTo ? storesTo : storesDataTo ? storesDataTo : addStockStores ? addStockStores : addNewSupplierData ? addNewSupplierData : storesToTakeCount ? storesToTakeCount : transferWarehouses ? transferWarehouses : props?.data;

  let [selectItem, setSelectItem] = useState("");

  const handleChange = (e) => {
    setSelectItem(e.target.value);
    if (suppliers) {
      setSupplierName(e.target.value);
    }
    if (warehouses) {
      setWarehouseName(e.target.value);
    }
    if (storesData) {
      setStoreFromName(e.target.value);
    }
    if (storesDataTo) {
      setStoreNameTo(e.target.value);
    }
  };

  useEffect(() => {
    props?.setItemId(dataToMap[0]?._id);
  }, [props?.data]);

  useEffect(() => {
    setSelectItem(dataToMap[0]?.name);
  }, [props?.data]);

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
      <Select labelId="demo-multiple-name-label" id="demo-multiple-name" value={selectItem || ""} onChange={handleChange} label={label} style={{ width: "100%" }}>
        {dataToMap?.map((newData, index) => {
          return (
            <MenuItem key={index} value={newData?.name} onClick={() => props?.setItemId(newData?._id)}>
              {newData?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
