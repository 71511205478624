import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ViewAddStockBatch } from "./stockModals/viewAddStockBatch";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import Breadcrumbcomponent from "./breadcrumb";
import { stockStyles } from "./stockStyling";
import Viewsupplierandwarehouse from "./stockModals/viewSupplierAndWarehouse";
import CategoryAndTable from "./stockModals/categoryAndTable";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

// Format currency to Local
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);
const ADDSTOCK = (props) => {

  const navigate = useNavigate();
  const breadcrumb = [<span key={1}>Dashboard</span>, <span key={2} onClick={() => navigate(`/stock`)}>Stock</span>, <span key={3} style={{ color: "red" }}>Add Stock</span>];

  const [componentToShow, setComponentToShow] = useState(0);
  const [storeId, setStoreId] = useState("");
  const [batchProducts, setBatch] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [warehouseName, setWarehouseName] = useState("");

  const { state } = useParams();
  
  const queryParams = decodeURIComponent(window.location.search)
  const paramsData = queryParams.slice(1);
  const wareHouseId = paramsData.split("?")[0]
  const paramSupplierId = paramsData.split("?")[1]


  const newComponentToShow = () => {
    if (state === "addstock") {
      return <Viewsupplierandwarehouse setStoreId={setStoreId} setSupplierId={setSupplierId} setComponentToShow={setComponentToShow} setSupplierName={setSupplierName} setWarehouseName={setWarehouseName} />;
    } else if (state === "categories") {
      return <CategoryAndTable storeId={storeId || wareHouseId} supplierId={supplierId || paramSupplierId} setBatch={setBatch} setComponentToShow={setComponentToShow} />;
    } else if (state === "viewBatch") {
      return <ViewAddStockBatch batchProducts={batchProducts} storeId={storeId || wareHouseId} supplierId={supplierId || paramSupplierId} setComponentToShow={setComponentToShow} supplierName={supplierName} warehouseName={warehouseName} />;
    }
  };

  return (
    <div>
      <Grid container direction={"column"}>
        <Grid item width={"100%"} margin={"0px 0px 1% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{cursor:'pointer'}} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"}  margin={"0px 0px 1% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      <Grid container width={"100%"}>
        {newComponentToShow()}
      </Grid>
    </div>
  );
};

export { ADDSTOCK };
