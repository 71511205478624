import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, TextField, } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import backIcon from "./Images/back-icn.svg"
import CustomInputLabel from "./CustomInputLabel";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import HttpComponent from "./MakeRequest";
import { useDispatch, useSelector } from "react-redux";
import { handleIsMomoSetup, setMomoData } from '../../features/paymentOptionsSlice';

const MTNMomoSetup = ({ setSuccessShow, setErrorShow, setShowMTNMomoSetup, setMomoChecked }) => {
    const [pageStatus, setPageStatus] = useState("initial")
    const ExPortalForm = localStorage.getItem('exPortalForm')
    const [checked, setChecked] = React.useState(true);

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const noStyle = {
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545",
        textTransform: "none",
        textDecoration: "none"
    }

    const buttonWidthHeight = {
        width: "9.063rem",
        height: "3.125rem"
    }

    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        textTransform: "none",
        textDecoration: "none",
        color: "white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        },
        '&:disabled': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const [formData, setFormData] = useState(
        {
            merchantName: "",
            subscriptionName: "",
            consumerKey: "",
            passKey: "",
        }
    )

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const { userId, X_Authorization } = useSelector((store) => store.user);
    const { momoData } = useSelector((store) => store.payment);
    const dispatch = useDispatch();

    // handling momo setup
    const handleMomo = () => {
       // formData.thirdPartyCallback = "https://zed.swerri.io/api/stkCallback"
        HttpComponent({
            method: 'POST',
            url: `/api/v1/business/mobileMoney/config/add`,
            body: {
                merchantName: formData.merchantName,
                subscriptionName: "Collections"
            },
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                setSuccessShow({
                    state: true,
                    message: "Momo Configurations Added Successfully"
                })
                console.log('momo setup data here', data.response);
                dispatch(handleIsMomoSetup(true));
                setShowMTNMomoSetup(false)
                dispatch(setMomoData(formData))
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
            setErrorShow({ state: true, message: error.message })
        })
    }

    return (
        <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
            <Box component="div" sx={{ display: "flex", margin: 2 }}>
                <Box component="div" sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <img
                        onClick={() => {
                            setShowMTNMomoSetup(false)
                            setMomoChecked(false)
                        }
                        }
                        style={{
                            width: "0.856rem",
                            height: "1.25rem",
                            cursor: 'pointer'
                        }} src={backIcon} alt="" />
                </Box>

                <Box component="div" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: 1 }}>
                    <Typography sx={{ color: "#032541", fontSize: "1rem", fontWeight: 700 }}>
                        {pageStatus === "initial" || pageStatus === "none" ?
                            "MTN MoMo Setup"
                            : null
                        }
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: '100%', paddingLeft: '50px' }}>
                    <Box component="div" sx={{ marginX: 0.5 }}>
                        <CustomInputLabel required={true} label={"Merchant Name"} />
                        <TextField
                            style={{ width: "93%", marginTop: "10px", height: '54px' }}
                            name={"merchantName"}
                            value={formData.merchantName}
                            onChange={handleInputChange}
                            placeholder={"Merchant Name"}
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                                sx: {
                                    fontSize: "16px",
                                    fontFamily: 'Poppins'
                                }
                            }}
                        />
                    </Box>
                    <Box component="div" sx={{ marginX: 0.5, marginTop: '15px' }}>
                        <CustomInputLabel required={true} label={"Subscription Name"} />
                        <TextField
                            style={{ width: "93%", marginTop: "10px", height: '54px' }}
                            name={"subscriptionName"}
                            value={'Collections'}
                            onChange={handleInputChange}
                            placeholder={"Subscription Name"}
                            variant="outlined"
                            margin="dense"
                            disabled
                            InputProps={{
                                sx: {
                                    fontSize: "16px",
                                    fontFamily: 'Poppins'
                                }
                            }}
                        />
                    </Box>
                    {/* <Box component="div" sx={{ marginX: 0.5, marginTop: '15px' }}>
                        <CustomInputLabel required={true} label={"Consumer Key"} />
                        <OutlinedInput
                            style={{ width: "93%", marginTop: '12px', height: '54px' }}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.consumerKey}
                            name='consumerKey'
                            onChange={handleInputChange}
                            placeholder={"Consumer Key"}
                            variant="outlined"
                            margin="dense"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Box>
                    <Box component="div" sx={{ marginX: 0.5, marginTop: '15px' }}>
                        <CustomInputLabel required={true} label={"Pass Key"} />
                        <OutlinedInput
                            style={{ width: "93%", marginTop: '12px', height: '54px' }}
                            id="outlined-adornment-password"
                            type={showPassword1 ? 'text' : 'password'}
                            value={formData.passKey}
                            name={"passKey"}
                            onChange={handleInputChange}
                            placeholder={"Pass Key"}
                            variant="outlined"
                            margin="dense"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword1}
                                        edge="end"
                                    >
                                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Box> */}

                    <Box component="div" sx={{}}>
                        <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                        <span>By ticking, you are agreeing to the terms and conditions.</span>
                    </Box>

                    {!ExPortalForm?.includes('true') ?
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: 2 }}>
                            <Box component="div" sx={{ alignSelf: "center" }}>
                                <Button onClick={() => setPageStatus("initial")} sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                    Cancel
                                </Button>

                                <Button disabled={!checked} onClick={handleMomo} sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }} id='preview-category'>
                                    Activate
                                </Button>
                            </Box>
                        </Box>
                        :
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: 2 }}>
                            <Box component="div" sx={{ alignSelf: "center" }}>
                                <Button sx={{ ...noStyle, ...buttonWidthHeight, marginX: 1 }}>
                                    Deactivate
                                </Button>

                                <Button sx={{ ...yesStyle, ...buttonWidthHeight, marginX: 1 }}>
                                    Update
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default MTNMomoSetup;
