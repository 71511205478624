import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    id: "",
    type: "",
    fee: "",
    currency: "",
    accountNo: "",
};

const businessToUpdateSlice = createSlice({
  name: "businessDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.fee = action.payload.fee;
      state.currency = action.payload.currency;
      state.accountNo = action.payload.accountNo;
    },
  },
});

export const { setDetails } = businessToUpdateSlice.actions;

export default businessToUpdateSlice.reducer;
