import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/cardBinToUpdateSlice";
import { handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function EditCardbin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  const reduxName = useSelector((state) => state.cardBinDetails.name);
  const reduxNo = useSelector((state) => state.cardBinDetails.cardNo);
  const reduxType = useSelector((state) => state.cardBinDetails.cardType);
  const reduxSuspense = useSelector((state) => state.cardBinDetails.suspense);

  //value to set when user edits form
  const [leName, setName] = useState(reduxName);
  const [cardNumber, setCardNumber] = useState(reduxNo);
  const [type, setType] = useState(reduxType);
  const [suspense, setSuspense] = useState(reduxSuspense);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleCardNo = (e) => {
    setCardNumber(e.target.value);
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  const handleSuspense = (e) => {
    setSuspense(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleUpdate = () => {
    try {
      axios
        .post(baseUrl + "/api/editCardBin", {
          name: leName,
          cardNo: reduxNo,
          cardNumber, 
          cardType: type,
          suspense,
        })
        .then((response) => {
          let data = response.data.data;
          if (data) {
            console.log("updated successfully");
            setLocalDetails({
              name: leName,
              cardNo: cardNumber,
              cardType: type,
              suspense: suspense,
            });
          } else {
            setFailMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/CardBins");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Edit Card Bin
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Card Bins"}
        link1={"/CardBins"}
        point2={"Edit CardBin"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Edit Card Bin Details
            </Typography>
            <TextField
              label="Card No"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxNo}
              InputLabelProps={{
                shrink: true,
              }}
              // disabled
              autoFocus
              onChange={handleCardNo}
              className={classes.focusedTextField}
            />
            <TextField
              label="Card Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              className={classes.focusedTextField}
            />
            <TextField
              label="Card Type"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxType}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleType}
              className={classes.focusedTextField}
            />
            <TextField
              label="Suspense Account"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxSuspense}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleSuspense}
              className={classes.focusedTextField}
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/CardBins")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EditCardbin;
