import React from "react";
import { ReactSVG } from "react-svg";
import MySVG1 from "./configs-iconRED.svg";
import MySVG2 from "./configs-iconBLUE.svg";

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG =
    currentPath === "/partners" ||
    currentPath === "/GlobalConfigurations" ||
    currentPath === "/ReasonCodes" ||
    currentPath === "/BusinessCategories"
      ? MySVG1
      : MySVG2;

  return <ReactSVG src={MySVG} />;
}

export default MySVGComponent;
