import { Grid,Typography, Button, Box } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux'
import { useState } from 'react';
import AddOutletStepper from './AddOutletstepper';

export default function OutletPartnerList(props) {
    const outlets = useSelector((store) => store.BusinessPartnerDetails.outlets)
    const [openAddOutlet, setOpenAddoutlets] = useState(false)
    const handleOpenAddOutlet = () => setOpenAddoutlets(!openAddOutlet)

    return (
        <Grid container direction={'column'} height={'100%'} pt={4}>
            {openAddOutlet ? <AddOutletStepper openAddOutlet={handleOpenAddOutlet} /> : <>
                <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={() => setOpenAddoutlets(true)} style={{ width: '146px', marginLeft: '10px', textTransform: "inherit", height: '45px', color: '#fff', backgroundColor: '#032541' }}>Create Outlet</Button>
                </Box>
                <Box sx={{height:'400px'}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Description</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {outlets?.map((row) => (
                                <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        {row?.name}
                                    </TableCell>
                                    <TableCell>{row?.description}</TableCell>
                                    <TableCell style={{ color: '#17ae7b', fontWeight: 700 }}>{row?.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                <Box mt={2} component={'div'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Typography variant='h6'  sx={{color:'#dc3545' , fontSize:'14px'}}>Skip</Typography>
                    <Button  style={{ width: '100px', marginLeft: '10px', textTransform: "inherit", height: '45px',color:'#dc3545', border:'1px solid #dc3545', backgroundColor: '#fff' }}>Cancel</Button>
                    <Button disabled={outlets?.length < 1} onClick={() => props?.next()} style={outlets?.length < 1 ? { width: '100px', marginLeft: '10px', textTransform: "inherit", height: '45px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)'}:{ width: '100px', marginLeft: '10px', textTransform: "inherit", height: '45px', color: '#fff', backgroundColor: '#032541' }}>Next</Button>
                </Box>
            </>}
        </Grid>
    )
}



