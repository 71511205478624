import moment from "moment-timezone/moment-timezone-utils";

export default function DateFormatter(value) {
	//console.log(value , 'value >>>>>>>>>>.')
	try {

		const myDate = new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString();
		//console.log(myDate , 'myDate')
		// return moment(value).tz('Africa/Nairobi').format("llll")
		return myDate
	} catch (e) {
		console.log(`Error Formatting Date`, e.message)
	}
}
export function DoMaskValue(value, num = 4, maskCharacter = '*') {
	try {
		// convert value to string
		const newValue = value.toString();
		if (newValue.length < num * 2) {
			return newValue
		}
		// go ahead and mask

		const maskStart = Math.floor((newValue.length - num) / 2);
		const maskEnd = maskStart + num;

		const maskedString = newValue.slice(0, maskStart) + Array(num).fill(maskCharacter).join('') + newValue.slice(maskEnd);
		return maskedString;

	} catch (error) {

	}

}
export function formatDateReadeable(inputDate){
	const yyyy = new Date(inputDate)?.getFullYear();
	let mm = new Date(inputDate)?.getMonth() + 1; 
	let dd = new Date(inputDate)?.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	const formattedToday = yyyy + "-" + mm + "-" + dd;

	return formattedToday;
}
