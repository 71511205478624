import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabValue: "APPROVED",
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.tabValue = action.payload;
      console.log(state.tabValue);
    },
  },
});

export const { changeTab } = tabSlice.actions;
export default tabSlice.reducer;
