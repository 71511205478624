import { Breadcrumbs, Grid, Typography, Box, Modal, Button } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DateFormatter from "../../../../utils/dateFormatter";
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import WarningImg from "../../../../images/warning.png"
import CustomSearchInput from "../../../School/CustomSearchInput";
import ExportMenu from "../../../School/ExportMenu";
import { CustomizedDate } from "../../../customerAccounts/customerComponents/customDate";
import CustomSelectField from '../../../School/CustomSelectField'
import HttpComponent from "../../../School/MakeRequest";
import AutocompleteComponent from "../../../../common/autoComplete";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };

// breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Pocket Money Bio Registration Report</Typography>
]


export default function ZPMBioRegistrationReports() {



    // delete fingerprint pop up

    const [deleteFingerPrintsOpen, setDeleteFingerPrintsOpen] = useState(false)
    const [regNo, setRegNo] = useState('')
    const [studentName, setStudentName] = useState('')
    const [businessNumber, setBusinessNumber] = useState('')
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const bsgroup = localStorage.getItem('group')



    const handleOpenDeleteFingerPrints = (params) => {
        console.log(params, 'paramas')
        setDeleteFingerPrintsOpen(true)
        setStudentName(params?.row?.studentName)
        setRegNo(params?.row?._id)
        setBusinessNumber(params?.row?.businessId)

    }

    const handleCloseDeleteFingerPrints = () => {
        setDeleteFingerPrintsOpen(false)
    }

    // delete fingerprint

    function handleDeleteFingerPrintByAdmin() {
        if (!businessNumber) {
            setErrorShow({ state: true, message: 'Business number is missing' })
        } else if (!regNo) {
            setErrorShow({ state: true, message: 'student number is missing' })
        } else {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/v1/deleteFingerPrints`,
                    body: {
                        businessId: businessNumber,
                        studentAdmissionNumber: regNo
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setSuccessShow({ state: true, message: data?.response?.message })

                        setTimeout(() => {
                            handleCloseDeleteFingerPrints()
                        }, 1000)
                        getAllTransactionsList()
                    } else {
                        setErrorShow({ state: true, message: data?.response?.message })
                        setTimeout(() => {
                            handleCloseDeleteFingerPrints()
                        }, 1000)
                        getAllTransactionsList()
                    }
                })
            } catch (error) {

            }
        }
    }

    // bs name filter

    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness();
    }, [selectedValue])

    // filters

    const [searchValue, setSearchValue] = useState('')

    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
                const currentDay = new Date().toLocaleDateString();
                setStartDate(firstDay);
                setEndDate(currentDay);

                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
                const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Default":
                setStartDate("");
                setEndDate("");
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                return;
        }
    }, [handleDaysChange, dateFrom, dateTo]);


    //pagesize & pagestate state

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    // fetch data => /api/v1/getStudentBioReistartionReportsAdmin?searchValue=Calvin&page=1&limit=1

    function getAllTransactionsList() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getStudentBioReistartionReportsAdmin?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}&businessNumber=${selectInputValue?.bsNo ? selectInputValue?.bsNo : ''}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data , 'data >>>>')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllTransactionsList()
    }, [dataGridPageSize, pageState.page, startDate, searchValue, endDate, selectInputValue?.bsNo])


    // columns


    const BioColumns = [

        { headerName: "Student Name", field: 'studentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) } },

        { headerName: "Reg No", field: 'regNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Reg No</strong>) } },

        { headerName: "Email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Eamil</strong>) } },

        { headerName: "businessName", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Institution</strong>) } },

        { headerName: 'PhoneNumber', field: 'phoneNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Phone No</strong>) } },

        { headerName: "Registered on", field: 'bioAddedOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Registered On</strong>) }, renderCell: (params) => <Typography>{params?.row?.bioAddedOn === 'N/A' ? 'N/A' : DateFormatter(params?.row?.bioAddedOn)}</Typography> },

        { headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} > Status</strong>) }, renderCell: (params) => <Typography style={{ color: '#17ae7b' }}>{params?.row?.status}</Typography> },

    ]

    if (bsgroup === 'Admin') {
        const extraColum = {
            headerName: 'Action', options: { display: bsgroup === 'Admin' }, field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <Grid item>
                        <DeleteIcon onClick={() => handleOpenDeleteFingerPrints(params)} style={{ color: '#dc3545', cursor: 'pointer' }} />
                    </Grid>
                )
            }
        }
        BioColumns.push(extraColum);
    }

    // exports

    const filedata = pageState?.data?.map((data) => {
        return {
            "Student Name": data?.studentName,
            "Reg No": data?.regNo,
            "Email": data?.email,
            "PhoneNumber": data?.phoneNumber,
            "Registered On": data?.bioAddedOn,
            "Status": data?.status,

        }
    })
    const fileHeaders = [["Student Name", "Reg No", "Email", "PhoneNumber", "Registered On", 'Status']]
    const csvColumns = [
        { label: "Student Name", key: "Student Name" },
        { label: "Reg No", key: "Reg" },
        { label: "Email", key: "Email" },
        { label: "PhoneNumber", key: "PhoneNumber", },
        { label: "Registered On", key: "Registered On" },
        { label: "status", key: "status", },
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.studentName, data?.regNo, data?.email, data?.phoneNumber, data?.bioAddedOn, data?.status]);

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'}>
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Pocket Money Bio Registration Report</Typography>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} spacing={1} >
                    <Grid item>
                        <CustomSearchInput placeholder={'search'} value={searchValue} name={'searchValue'} onChange={(e) => setSearchValue(e.target.value)} />
                    </Grid>
                    <Grid item>
                        {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Custom Range"} options={days} name={"Transaction Type"} />}
                    </Grid>
                    <Grid item>
                        {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", width: '600px' }} />}
                    </Grid>
                    <Grid item>
                        <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "10px", width: '300px' }} />
                    </Grid>
                    <Grid item>
                        <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Bio Transactions'} title={'Zed-Pocket Money Bio Transactions'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={BioColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>

            <Modal
                open={deleteFingerPrintsOpen}
                onClose={handleCloseDeleteFingerPrints}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={WarningImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={4}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Reset Student Bio details
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you need to reset <span style={{ color: "#212b36", fontWeight: 600 }}>{studentName}</span> bio details with registration number  <span style={{ color: "#212b36", fontWeight: 600 }}>{regNo}</span>
                                    </Typography>
                                    <Typography mt={1}>
                                        Click reset to proceeed
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleDeleteFingerPrintByAdmin} style={{ width: "200px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}