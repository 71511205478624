import { Grid, Stack, Typography, TextField, Button, Alert ,FormControl ,MenuItem ,Select , InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import Breadcrumbs2 from "../../../SuperAdmin/components/Breadcrumbs/Breadcrumbs2";
import { removeClicked } from "../../../../features/actionSlice";
import { setDetails } from "../../../../features/terminalToUpdateSlice";
import { handleAdded } from "../../../../features/AlertSlice";
import { handleTabChange } from "../../../../features/currentTabSlice";
import DeviceModelSelect from "../../../SuperAdmin/components/TerminalsAndPrinters/DeviceModelSelect";
import AutocompleteComponent from "../../../../common/autoComplete";
import HttpComponent from "../../../School/MakeRequest";
import ConfigurationFunctions from "../../../configuration/configEndpoints";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));


const baseUrl = process.env.REACT_APP_BASE_URL;

function AddTerminalAndAssign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedValue, setSelectValue] = useState("");
  const [businessess, setBusinesses] = useState([]);
  const [selectInputValue, setInputValue] = useState("");
  const [outletsAdmin, setOutletsAdmin] = useState([])
  const configMethods = new ConfigurationFunctions();

  //value to set when user edits form
  const [serialNo, setSerialNo] = useState("");

  const posModel = useSelector(state => state.deviceModels.selectedModel)

  const handleSerialNo = (e) => {
    setSerialNo(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  useEffect(() => {
    configMethods.getAllBusiness(selectedValue).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
            const newArray = data?.response?.data?.map((bus) => {
                if (bus.zedPayItStatus === true) {
                    // console.log(bus);
                    return {
                        name: bus.businessName,
                        bsNo: bus?.businessNumber,
                    };
                } else {
                    return {
                        name: "default",
                        bsNo: 1111,
                    };
                }
            });

            setBusinesses(newArray);
        }
    });
}, [selectedValue]);

//selectInputValue?.bsNo

// get outlets for business

function getOutletsByAdminPartner() {
    try {
        HttpComponent({
            method: "GET",
            url: `/api/listBusinessOutlets?storeType=MainStore&businessNumber=${selectInputValue?.bsNo}`,
            body: null,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            console.log(data, 'admin outlet')
            if (data?.status === 200) {
                setOutletsAdmin(data?.response?.data)
            }
        })
    } catch (error) {
        console.log(error)
    }
}

useEffect(() => {
    getOutletsByAdminPartner()
}, [selectInputValue?.bsNo])


const [partnerOutlet, setPartnerOutlet] = useState('')
const [outlateNameAdmin, setoutlateNameAdmin] = useState('')
const [adminBranchId, setadminBranchId] = useState('')


const handdleOutletSelected = (e) => {
    const valueFound = e.target.value;
    const selectedValue = outletsAdmin?.find(item => item?._id === valueFound);
    if (selectedValue) {
        const { branchId, _id, storeName } = selectedValue
        setPartnerOutlet(_id)
        setadminBranchId(branchId)
        setoutlateNameAdmin(storeName)

    }
}

  const handleAdd = () => {
    try {
      axios
        .post(baseUrl + "/api/addTerminal", {
          serialNo,
          posModel,
        },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.data
          if (data) {
            console.log("added successfully");
            setLocalDetails({
              serialNo: serialNo,
              posModel: posModel,
            });
            dispatch(handleAdded(true));
          } else {
            setFailMessage(response.data.message)
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(setDetails(localDetails));
      navigate("/AssignTerminal");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());
 

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Add Terminal
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Terminal Mgt"}
        link1={"/terminal/mngt"}
        point2={"Add Terminal"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Add Terminal Details
            </Typography>
            <TextField
              label="Serial No"
              variant="outlined"
              fullWidth
              size="medium"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleSerialNo}
              autoFocus
              className={classes.focusedTextField}
            />

            {/* <TextField
              label="POS Model"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handlePosModel}
              className={classes.focusedTextField}
            /> */}
            <DeviceModelSelect />
            <Grid item mt={2} style={{ width: "100%" }}>
              <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search Business.."} data={businessess ? businessess : [{ name: "default", bsNo: 546778 }]} styles={{ width: "100%", margin: "0 0 3% 0" }} />
            </Grid>
            <Grid item mt={2} style={{ width: "100%" }}>
              <FormControl size="medium" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={partnerOutlet}
                  label="Outlet"
                  onChange={handdleOutletSelected}
                  name="outletName"
                >
                  {outletsAdmin?.map((item) => (
                    <MenuItem name="outlet" key={item._id} value={item._id}>{item?.storeName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => {
                  dispatch(handleTabChange("Active"));
                  navigate("/TerminalsAndPrinters");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddTerminalAndAssign;
