import {Button, Checkbox, Grid, TextField ,Typography} from "@mui/material";
import SetImg from '../../../images/newR.svg'
import FormControlLabel from "@mui/material/FormControlLabel";
import React, {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import CustomSelectField from "../../School/CustomSelectField";
import AddCharge from "./Tenant/createTenant";
import AddService from "./Tenant/addService";
import ListServices from "./listServices";
import TenantBioData from "./tenantBioData";
import AddHouse from "./tenantHouseDetails";
import ListAllocatedHouses from "./listAllocatedUnits";
import PreviewTanantInvoice from "./previewTenantINV";
import ListAllTenantsSetUp from "./listAllTenants";
import UpdateInvoice from "./updateINV";


const baseUrl = process.env.REACT_APP_BASE_URL;
const fetchUrl = baseUrl + "/api/listProducts";
const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);
export default function AddTenant(props) {

	const [isFromAllocatedHouse, setIsFromAllocatedHouse] = useState(false)
	const [unitTypeID ,setUnitTypeId] =useState('')
	const [houseInvoice ,setHouseInvoice] = useState([])

	//update the unit typeid
	const handleUnitTypeId = (unitId)=>{
		setUnitTypeId(unitId)
	}

	//update house invoices

	const handleHouseInvoice = (houses)=>{
		setHouseInvoice(houses)
	}

	const [unitId ,setUnitId] = useState('')
	const [flagState, setFlagState] = useState("");

	const handleAddTenantAtSetUp = ()=>{
		setFlagState('addTenantAtSetUp')
	}
	
	const handleAddServiceClick = () => {
		setFlagState("addHouse");
	};
	
	const handleAddHouseClick = () => {
		setFlagState("addHouse");
	}

	const saveUnitId = (unitId)=>{
		setUnitId(unitId)
	}

	const handleIsFromAll = (isFromAllocatedHouse)=>{
		setIsFromAllocatedHouse(isFromAllocatedHouse)
		setFlagState('addHouse')
	}
	
	const handleAddCharges = () => {
		setFlagState('addCharges')
	}
	const handlePreviewIn = ()=>{
		setFlagState('previewInvoice')
	}

	const handleListT = () =>{
		setFlagState('listTenantsset')
	}
	
	const handleListingClick = () => {
		setFlagState("listService");
	};
	const handleGoBackClick = () => {
		setFlagState("");
	};

	const handleupdateinv = ()=>{
		setFlagState('updateinvoiceData')
	}
	const handleListAllocatedUnits = ()=>{
		setFlagState("listAllocatedHouses");
	}

	// Rendering External Components
	let componentToRender = <>
		<Grid container direction={'column'} mt={4} justifyContent={'center'} alignContent={'center'}>
			<Grid item mb={5}>
				<img style={{ width: "100%" }} src={SetImg} alt="tenantsetup" />
			</Grid>
			<Grid item mt={10} mb={3} display={'flex'} justifyContent={'center'}>
				<Typography variant="body2">Add tenants and track them here</Typography>
			</Grid>
			<Grid item display={'flex'} justifyContent={"space-between"}>
				<Button onClick={handleAddTenantAtSetUp} style={{ backgroundColor: "#fff", textTransform: "inherit", marginRight: "10px", border: "2px solid #032541", color: "#032541", width: "125px" }}>Add Existing</Button>
				<Button onClick={handleAddTenantAtSetUp} style={{ backgroundColor: "#032541", textTransform: "inherit", marginLeft: "10px", color: "#fff", width: "125px" }}> Add New</Button>
			</Grid>
		</Grid>
	</>

	if (flagState === 'addTenantAtSetUp'){
		props.setShowBreadCrumbs(true)
		props.setShowActiveStepper(true)
		componentToRender = (
			<TenantBioData setFlagStateHouse={handleAddHouseClick} setFlagGoBack={handleGoBackClick}/>
		)
	}else if (flagState === "addHouse") {
		props.setShowBreadCrumbs(true)
		props.setShowActiveStepper(true)
		componentToRender = (
			<AddHouse  setFlagGoBack={handleGoBackClick} saveUnit={saveUnitId} isFromHouse={isFromAllocatedHouse} setFlagChargesState={handleAddCharges} />
		);
	} else if (flagState === 'listService') {
		componentToRender = (
			<ListServices  setFlagStateListingBack={handleAddServiceClick} setFlagState={handleAddServiceClick} handleGoBackClick={handleAddHouseClick} />
		);
	}  else if (flagState === 'addCharges') {
		props.setShowBreadCrumbs(true)
		props.setShowActiveStepper(true)
		componentToRender = (
			<AddCharge setFlagGoBack={handleIsFromAll} unitId={unitId} moreHouseUnits={handleListAllocatedUnits} isFromCharges={isFromAllocatedHouse}/>
		);
	}else if (flagState === 'listAllocatedHouses'){
		props.setShowBreadCrumbs(false)
		props.setShowActiveStepper(false)
		componentToRender= (
			<ListAllocatedHouses saveUnit={unitId} saveeHouseiNV={handleHouseInvoice}  goInv={handlePreviewIn} isFromAllocatedHouse={true} setFlagGoBack={handleIsFromAll} />
		)
	}else if (flagState ===  'previewInvoice'){
		componentToRender = (
			<PreviewTanantInvoice saveunitid={handleUnitTypeId} handleT={handleListT} houseiN={houseInvoice} updateInvo={handleupdateinv} prev={handleListAllocatedUnits} />
		)
	}else if(flagState === 'updateinvoiceData'){
		componentToRender =(
			<UpdateInvoice prev={handlePreviewIn} unitid={unitTypeID} goBackToPreviewInvoice={handlePreviewIn} />
		)
	}else if (flagState === 'listTenantsset'){
		props.setShowBreadCrumbs(true)
		props.setShowActiveStepper(true)
		componentToRender =(
			<ListAllTenantsSetUp addAnother={handleAddTenantAtSetUp}/>
		)
	}
	
	return (
		<div style={flagState === 'listAllocatedHouses' || 'previewInvoice' ?  {fontFamily : 'Poppins',} :{fontFamily : 'Poppins', marginLeft : '15%', marginRight : '15%'}}>
			{componentToRender}
		</div>
	)
}

