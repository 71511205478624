import React, { useState,useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { customerstyles } from "./styles";


const Customeractivity = (props) => {

      // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const getCustomerActivity = () => {
    HttpComponent({
      method: "GET",
      url: `/api/CustomersActivities?customerId=${props.customerId}&page=${pageState.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        setPageState({...pageState, isLoading: false, data: data.response.data, count: data.response.count});
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getCustomerActivity();
  }, [props.customerId, pageState.page, dataGridPageSize]);

  return (
    <div>
              <DataGrid
      style={customerstyles.dataGridStyling}
        columns={[
            {field:"customerName", headerName:"Customer Name", flex:1, renderCell:(params) => {
                return (
                    <span>{params.row.customerName}</span>
                )
            }},
            {field:"customerType", headerName:"Customer Type", flex:1},
            {field:"paymentType", headerName:"Payment Type", flex:1},
            {field:"createdAt", headerName:"Date Created", flex:1},
            {field:"createdBy", headerName:"Served By", flex:1},
            {field:"amount", headerName:"Amount(KES)", flex:1},
    
            ]}
        rows={pageState?.data?.map((act) => ({
            id:act?._id,
            customerName:act?.customerName,
            customerType:act?.customerType,
            paymentType:act?.customerPaymentType,
            createdAt: DateFormatter(act?.createdAt),
            createdBy:act?.createdBy,
            amount:act?.amount,
        }))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />
    </div>
  )
}

export default Customeractivity