import React , {useState} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import loginbackgroundImage from './images/login_signupBackground.png'
import loginLogo from './images/zedLogo.png';
import {Paper} from '@mui/material';

const baseUrl = process.env.REACT_APP_BASE_URL;

const styles = {
    paperContainer : {
        backgroundImage : `url(${loginbackgroundImage})` ,
        backgroundSize : 'contain' ,
        backgroundColor : '#1A354E' ,
        width : '100%' ,
        height : '100%' ,
        padding : "0px 0px 1px" ,
        ObjectFit : 'contain' ,
        fontFamily : 'Poppins'
    }
};

const OtpVerification = () => {
    const [otp1 , setOtp1] = useState('');
    const [otp2 , setOtp2] = useState('');
    const [otp3 , setOtp3] = useState('');
    const [otp4 , setOtp4] = useState('');
    const [otp5 , setOtp5] = useState('');
    const [otp6 , setOtp6] = useState('');

    const UncleanphoneNumber = localStorage.getItem("phone");
    const phoneNumber = UncleanphoneNumber.replace(/^\d/ , '+254')


    async function submitHandler(event) {
        event.preventDefault();
        const response = await fetch(baseUrl + '/api/verify' , {
            method : 'POST' , headers : {
                'Content-Type' : 'application/json' , 'X-Authorization' : localStorage.getItem("X-Authorization")
            } , body : JSON.stringify({
                phoneNumber : phoneNumber , otpCode : otp1 + otp2 + otp3 + otp4 + otp5 + otp6
            }) ,

        })
        console.log(otp1 + otp2 + otp3 + otp4)
        const data = await response.json();
        if (response.status === 200 && data.Status === "SUCCESS") {
            setOtp1('');
            setOtp2('');
            setOtp3('');
            setOtp4('');
            setOtp5('');
            setOtp6('');
            alert("Business Creation Successful");
            return window.location.href = "/transactions";
        } else if (response.status === 401 && data.status === "FAILED" && data.message === "No token provided.") {
            alert("No Token Available");
        } else if (response.status === 401 && data.status === "FAILED" && data.message === "Invalid token.") {
            alert("Invalid Token");
        } else if (response.status === 200 && data.Status === "FAILED" && data.message === "Only Admin can create Business") {
            alert("Only Admin can create Business");
        }
    }

    return (<Paper style={styles.paperContainer}>
            <Stack width={'100%'} height={'100%'} alignItems={'center'}>
                <Card sx={{
                    border : 'solid 1px #707070' ,
                    width : '600px' ,
                    height : '716px' ,
                    margin : '0 0 30px' ,
                    backgroundColor : '#fff' ,
                    padding : "40px 53px 67px 54px" ,
                    borderRadius : '19px' ,
                    boxShadow : '0 3px 6px 0 rgba(0, 0, 0, 0.16)'
                }}>
                    <CardContent align={'center'}>
                        <div style={{
                            marginTop : '0 158px 39px 196px' ,
                            width : '147px' ,
                            height : '95px' ,
                            padding : '0px 0 0'
                        }}>
                            <img src={loginLogo} alt="logo" style={{
                                width : '147px' ,
                                height : '67px' ,
                                margin : '0 0 17px' ,
                                objectFit : 'contain'
                            }}/>
                            <span style={{
                                fontSize : '8px' ,
                                width : '44px' ,
                                height : '11px' ,
                                margin : '17px 51px 0 52px' ,
                                fontFamily : 'Poppins' ,
                                fontWeight : 'normal' ,
                                fontStyle : 'normal' ,
                                fontStretch : 'normal' ,
                                lineHeight : '3.38' ,
                                letterSpacing : 'normal' ,
                                textAlign : 'left' ,
                                color : '#032541' ,
                            }}>
                                version 1.0.1
                            </span>
                        </div>
                    </CardContent>
                    <CardContent>
                        <CardContent align={'center'}>
                            <span style={{
                                "width" : "187px" ,
                                "height" : "28px" ,
                                "margin" : "0 153px 30px 92px" ,
                                "objectFit" : "contain" ,
                                "fontFamily" : "Poppins" ,
                                "fontSize" : "20px" ,
                                "fontWeight" : "500" ,
                                "fontStretch" : "normal" ,
                                "fontStyle" : "normal" ,
                                "lineHeight" : "normal" ,
                                "letterSpacing" : "normal" ,
                                "textAlign" : "left" ,
                                "color" : "#032541"
                            }}>
                                Mobile Vefitifcation
                            </span>
                        </CardContent>
                        <CardContent align={'center'}>
                            <span style={{
                                width : "349px" ,
                                height : "21px" ,
                                margin : "30px 72px 85px 11px" ,
                                objectFit : "contain" ,
                                fontFamily : "Poppins" ,
                                fontSize : "15px" ,
                                fontWeight : "normal" ,
                                fontStretch : "normal" ,
                                fontStyle : "normal" ,
                                lineHeight : "normal" ,
                                letterSpacing : "0.21px" ,
                                textAlign : "left" ,
                                color : "#032541"
                            }}>
                                We have sent a 6-digit code to {localStorage.getItem("phone").charAt(0)}{localStorage.getItem("phone").charAt(1)}{localStorage.getItem("phone").charAt(2)}{localStorage.getItem("phone").charAt(3)}***{localStorage.getItem("phone").charAt(7)}{localStorage.getItem("phone").charAt(8)}{localStorage.getItem("phone").charAt(9)}
                            </span>
                        </CardContent>
                        <CardContent align={'center'} style={{
                            width : "493px" , height : "35.7px" , margin : "85px 0 95.9px"
                        }}>
                            <form id="Submit" onSubmit={submitHandler}>
                                <div className='container d-flex justify-content-center align-items-center'>
                                    <div className="input-container d-flex flex-row justify-content-center mt-2">
                                        <input type="text" className="m-1 text-center form-control rounded"
                                               maxLength={1} placeholder="1" name='otp1' value={otp1}
                                               onChange={(e) => setOtp1(e.target.value)} style={{
                                            width : '40px' ,
                                            height : '40px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "18px" ,
                                            fontWeight : "600" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "-0.04px" ,
                                            textAlign : "center" ,
                                            color : "#032541" ,
                                            margin : "0 5px 10.7px 10.5px" ,
                                            border : "solid 1px #707070" ,
                                        }}/>-
                                        <input type="text" className="m-1 text-center form-control rounded"
                                               maxLength={1} placeholder="2" name='otp2' value={otp2}
                                               onChange={(e) => setOtp2(e.target.value)} style={{
                                            width : '40px' ,
                                            height : '40px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "18px" ,
                                            fontWeight : "600" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "-0.04px" ,
                                            textAlign : "center" ,
                                            color : "#032541" ,
                                            margin : "0 5px 10.7px 10.5px"
                                        }}/>-
                                        <input type="text" className="m-1 text-center form-control rounded"
                                               maxLength={1} placeholder="3" name='otp3' value={otp3}
                                               onChange={(e) => setOtp3(e.target.value)} style={{
                                            width : '40px' ,
                                            height : '40px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "18px" ,
                                            fontWeight : "600" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "-0.04px" ,
                                            textAlign : "center" ,
                                            color : "#032541" ,
                                            margin : "0 5px 10.7px 10.5px"
                                        }}/>-
                                        <input type="text" className="m-1 text-center form-control rounded"
                                               maxLength={1} placeholder="4" name='otp4' value={otp4}
                                               onChange={(e) => setOtp4(e.target.value)} style={{
                                            width : '40px' ,
                                            height : '40px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "18px" ,
                                            fontWeight : "600" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "-0.04px" ,
                                            textAlign : "center" ,
                                            color : "#032541" ,
                                            margin : "0 5px 10.7px 10.5px"
                                        }}/>-
                                        <input type="text" className="m-1 text-center form-control rounded"
                                               maxLength={1} placeholder="5" name='otp5' value={otp5}
                                               onChange={(e) => setOtp5(e.target.value)} style={{
                                            width : '40px' ,
                                            height : '40px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "18px" ,
                                            fontWeight : "600" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "-0.04px" ,
                                            textAlign : "center" ,
                                            color : "#032541" ,
                                            margin : "0 5px 10.7px 10.5px"
                                        }}/>-
                                        <input type="text" className="m-1 text-center form-control rounded"
                                               maxLength={1} placeholder="6" name='otp6' value={otp6}
                                               onChange={(e) => setOtp6(e.target.value)} style={{
                                            width : '40px' ,
                                            height : '40px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "18px" ,
                                            fontWeight : "600" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "-0.04px" ,
                                            textAlign : "center" ,
                                            color : "#032541" ,
                                            margin : "0 5px 10.7px 10.5px" ,
                                        }}/>
                                        <button type="submit" style={{
                                            alignSelf : 'center' ,
                                            width : '122px' ,
                                            height : '42.6px' ,
                                            padding : '9px 39.5px 8.6px 40.5px' ,
                                            backgroundColor : '#032541' ,
                                            borderRadius : '12px' ,
                                            margin : '9px 39.5px 8.6px 40.5px' ,
                                            fontFamily : "Poppins" ,
                                            fontSize : "15px" ,
                                            fontWeight : "normal" ,
                                            fontStretch : "normal" ,
                                            fontStyle : "normal" ,
                                            lineHeight : "normal" ,
                                            letterSpacing : "0.21px" ,
                                            textAlign : "center" ,
                                            color : "#ffffff" ,
                                            cursor : 'pointer' ,
                                        }}>Verify
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </CardContent>
                        <div>
                            <Stack align={'center'}>
                                <Stack align={'center'}>
                                    <span style={{
                                        color : '#032541' ,
                                        textAlign : 'left' ,
                                        lineHeight : '5.33' ,
                                        fontSize : '15px' ,
                                        margin : '-25px 0px 0px 130px'
                                    }}>
                                        Already have an account? <a href={'login'} style={{
                                        fontFamily : 'Poppins' ,
                                        fontWeight : 'bold'
                                    }}>Login</a>
                                    </span>
                                </Stack>
                            </Stack>
                            {/* </form> */}
                        </div>
                    </CardContent>
                </Card>
                <Stack>
                    <span style={{}}>
                        @2022 Zed. All rights reserved.
                    </span>
                </Stack>
            </Stack>
        </Paper>);
}
export default OtpVerification;