import { Modal, Button } from "@mui/material";
import React from "react";
import { warehousestyles } from "../../../warehouse/styles";
import { stockStyles } from "../stockStyling";
import approveStockImg from "../../../../common/images/approveStock.svg"
import declineStock from "../../../../common/images/declineStock.svg"

const ApproveStockModal = ({ approveStockModal, setApproveStock, approveStock, status, approveStockTransfer }) => {
  return (
    <div>
      <Modal open={approveStockModal} >
        <div style={stockStyles?.approveStockModal}>
          <div style={{textAlign:"center"}}>
            <div>
              <img src={approveStockImg} alt="warehouse" />
            </div>

            <div>
              <p>Approve Batch?</p>
              <p>Selected batch number(s) will be approved and stock updated automatically. </p>

              <p>
                <li></li>
              </p>
              <div style={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
                <Button onClick={() => setApproveStock(false)} style={stockStyles.cancelStockButton}>Cancel</Button>

                <Button style={stockStyles.approveButton} onClick={() => status === "transferPending" ? approveStockTransfer() : approveStock()}>Approve</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};


const DeclineStockModal = ({openDeclineModal, setOpenDeclineModal, declineStockRequest, status, declineTransferStock}) => {
    return (
        <div>
        <Modal open={openDeclineModal} >
          <div style={stockStyles?.approveStockModal}>
            <div style={{textAlign:"center"}}>
              <div>
                <img src={declineStock} alt="warehouse" />
              </div>
  
              <div>
                <p>Decline Batch?</p>
                <p>Selected batch number(s) will be declined and stock updated automatically. </p>
  
                <p>
                  <li></li>
                </p>
                <div style={{display:"flex", alignItems:"center", gap:"10px", justifyContent:"center"}}>
                  <Button  style={stockStyles.cancelStockButton} onClick={() => setOpenDeclineModal(false)} >Cancel</Button>
  
                  <Button style={stockStyles.declineButton} onClick={() => status === "transferPending" ? declineTransferStock() : declineStockRequest()} >Decline</Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
}

export { ApproveStockModal, DeclineStockModal };
