import React, { useEffect, useState } from "react";
import CustomDataGrid from "../../products/stockComponents/customDataGrid";
import ReportFunctions from "../reportsFunctions";
import DateFormatter from "../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Exports } from "../../customerAccounts/customerComponents/exports";
import { CustomDate } from "../../customerAccounts/customerComponents/customDate";
const ApprovedVoids = (props) => {

  const navigate = useNavigate();
  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  const exportData = props?.exportData
  const tabvalue = props?.tabvalue
  const csvExport = props?.csvExport
  
  let localCurrency = localStorage.getItem('localCurrency')

  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
  const [startDate, setStartDate] = useState('')
  const [openRange , setOpenRange] = useState(false)
  const [endDate, setEndDate] = useState('')


  // filters

  const setStartAndEndDates = (e) => {
    switch (e.target.value) {
      case "Today":
        const today = new Date().toLocaleDateString();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]).toLocaleDateString();
          const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
        const currentDay = new Date().toLocaleDateString();
        setStartDate(firstDay);
        setEndDate(currentDay);

        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
        const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      default:
        return e.target.value;
    }
  };


  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((transaction) => [transaction?.transactionID, transaction?.transactionType, CurrencyFormatter(transaction?.transamount, localCurrency), transaction?.voidedBy, DateFormatter(transaction?.dateVoided), transaction?.voidedBy, DateFormatter(transaction?.dateVoided)]);
    const pdfHeaders = [["TXID", "Type", "Amount", "Void By", "Voided On", "Approved By", "Approved On"]];
    setExportData({ headers: pdfHeaders, data: pdfData });

    // csv exports;
    const csvData = pageState?.data?.map((customer) => {
      return {
        "TXID": customer?.transactionID,
        "Type": customer?.transactionType,
        "Amount": CurrencyFormatter(customer?.transamount, localCurrency),
        "Voided By": customer?.voidedByWho,
        "Voided On": DateFormatter(customer?.dateVoided),
        "Approved  By": customer?.voidedBy,
        "Approved On": DateFormatter(customer?.dateVoidRequested),
      };
    });

    // csv headers;
    const csvHeaders = [
      { label: "TXID", key: "TXID" },
      { label: "Type", key: "Type" },
      { label: "Amount", key: "Amount" },
      { label: "Voided By", key: "Voided By" },
      { label: "Voided On", key: "Voided On" },
      { label: "Approved By", key: "Approved By" },
      { label: "Approved On", key: "Approved On" },
    ];

    setCsvExport({ headers: csvHeaders, data: csvData });
  }, [pageState.data, pageState.pageSize, dataGridPageSize ,startDate ,endDate]);

  const reportsMethods = new ReportFunctions();

  const pendingColumns = [
    {
      field: "transactionID",
      headerName: "Transaction ID",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Transaction ID</span>;
      },
      renderCell: (params) => {
        return <span onClick={() => navigate(`/detailedbill/${params?.row?.transactionID}`)} style={{ color: "red", cursor: 'pointer' }}>{params?.row?.transactionID}</span>
      }
    },
    {
      field: "transactionType",
      headerName: "Payment Mode",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Payment Mode</span>;
      },
    },

    {
      field: "transamount",
      headerName: "Amount(KES)",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Amount (kES) </span>;
      },
    },

    {
      field: "voidedByWho",
      headerName: "Void Requested By",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Void  By</span>;
      },
    },

    {
      field: "dateVoided",
      headerName: "Voided On",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Voided On</span>;
      },
    },

    {
      field: "voidedBy",
      headerName: "Approved By",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Approved By</span>;
      },
    },

    {
      field: "dateVoidRequested",
      headerName: "Approved On",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Approved On</span>;
      },
    },
  ];


  useEffect(() => {

    reportsMethods.getApprovedVoidTransactions(pageState?.page, dataGridPageSize , startDate ,endDate).then((data) => {
      setPageState({ ...pageState, data: data.response?.transactions, count: data.response?.transactions?.length, isLoading: false })
    })
  }, [pageState?.page, dataGridPageSize ,startDate ,endDate])

  const approvedVoids = pageState?.data?.map((trans, index) => ({
    id: index,
    transactionID: trans?.transactionID,
    transactionType: trans?.transactionType,
    transamount: trans?.transamount,
    voidedBy: trans?.voidedBy,
    dateVoided: DateFormatter(trans?.dateVoided),
    voidedBy: trans?.voidedBy,
    dateVoidRequested: DateFormatter(trans?.dateVoidRequested),
    voidedByWho: trans?.voidedBy
  }))

  return (
    <div>
      <Grid container direction={'column'} display={'flex'}>
        <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item display={'flex'} alignItems={'center'}>
              <FormControl style={{ width: "200px" }}>
                <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Date Range"
                  onChange={setStartAndEndDates}
                >
                  <MenuItem value={'Today'}>Today</MenuItem>
                  <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                  <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                  <MenuItem value={'This Month'}>This Month</MenuItem>
                  <MenuItem value={'Last Month'}>Last Month</MenuItem>
                  <MenuItem value={'Custom Range'}  onClick={()=>setOpenRange(true)}>Custom Range</MenuItem>
                </Select>
              </FormControl>
              {openRange === true ? <CustomDate setDateFrom={setStartDate} setDateTo={setEndDate} />:null }
            </Grid>
            <Grid item>
              <Exports exportData={exportData} activeTabs={tabvalue} csvExport={csvExport} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item mt={2}>
          <CustomDataGrid customStyles={{ border: "none", height: "500px", width: "100%" }} column={pendingColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={approvedVoids} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ApprovedVoids;
