import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { warehousestyles } from "../styles";
import { useNavigate } from "react-router-dom";

const SupplierTypes = () => {
  const navigate = useNavigate();

  const supplierDetails = [
    { id: 1, supplierType: "New Supplier", description: "Add the supplier name, contact details and locations", url: `/addsupplier/newSupplier` },
    { id: 2, supplierType: "Supplier in ZED", description: "Search for suppliers already registered in ZED and easily add them to your list", url: `/addsupplier/supplierInZed` },
  ];

  return (
    <div style={{ width: "50%", fontFamily: "Poppins" }}>
      <Grid container direction={"column"}>
        <Grid item margin={"0% 0% 2% 0%"}>
          <span style={warehousestyles?.selectSupplierText}>Select Supplier Type</span>
        </Grid>
        {supplierDetails?.map((supplier, index) => {
          return (
            <>
              <Grid item key={supplier?.id} onClick={() => navigate(supplier?.url)} style={{ cursor: "pointer", margin: "0% 0% 1% 0%" }}>
                <p style={warehousestyles?.supplierTypeText}>{supplier?.supplierType}</p>
                <p style={warehousestyles?.supplierTypePara}>{supplier?.description}</p>
              </Grid>

              <hr />
            </>
          );
        })}
      </Grid>
    </div>
  );
};

export default SupplierTypes;
