import { Grid, Typography, Breadcrumbs, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import DateFormatter from '../../../../utils/dateFormatter';
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'
import ReconcileFunds from './reconcileFunds';
import SearchFilter from '../../../customerAccounts/customerComponents/searchFilter';


const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

// breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Reconciliation</Typography>
]


export default function ZpmReconciliationReports() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [searchValue, setInputValue] = useState("");
    const navigate = useNavigate()

    //url params

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)

    const rencolitationRows = [
        { headerName: "BusinessName", field: 'businessName', headerAlign: 'left', minWidth: 170, align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }}>Business Name</strong>) } },
        { headerName: "businessNumber", minWidth: 100, field: 'businessNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Business ID</strong>) }, renderCell: (params) => <Typography>{params?.row?.businessNumber}</Typography> },
        { headerName: "transamount", field: 'transamount', headerAlign: 'left', minWidth: 120, align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Collected</strong>) }, renderCell: (params) => <Typography>{numberFormat(params?.row?.transamount)}</Typography> },
        { headerName: "transamountCredit", field: 'transamountCredit', minWidth: 120, headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Credited</strong>) }, renderCell: (params) => <Typography>{numberFormat(params?.row?.transamount)}</Typography> },
        { headerName: "startDate", field: 'startDate', headerAlign: 'left', minWidth: 160, align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date From</strong>) }, renderCell: (params) => <Typography>{DateFormatter(params?.row?.startDate)}</Typography> },
        { headerName: "endDate", field: 'endDate', headerAlign: 'left', minWidth: 160, align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date To</strong>) }, renderCell: (params) => <Typography>{DateFormatter(params?.row?.endDate)}</Typography> },
        { headerName: "transferReference", field: 'transferReference', minWidth: 150, headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >TXN Ref</strong>) }, renderCell: (params) => <Typography>{params?.row?.referenceNumber}</Typography> },
        { headerName: "updatedAt", field: 'updatedAt', headerAlign: 'left', minWidth: 170, align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <Typography>{DateFormatter(params?.row?.updatedAt)}</Typography> },
        { headerName: "accountNumber", field: 'accountNumber', minWidth: 150, headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Account Number</strong>) }, renderCell: (params) => <Typography>{params?.row?.accountNumber}</Typography> },
        { headerName: "transactionCost", field: 'transactionCost', minWidth: 120, headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Transaction Cost</strong>) }, renderCell: (params) => <Typography>{numberFormat(params?.row?.transactionCost)}</Typography> },
        { headerName: "status", field: 'status', minWidth: 100, headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => <Typography>{params?.row?.status?.toLowerCase()}</Typography> },
    ]



    // fetch already reconciliation
    function handleReconciliation() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/schoolSettlementTransactions`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        handleReconciliation()
    }, [])
    return (
        <Grid direction='column' container>
            {state === 'reconcileFunds' ? <ReconcileFunds /> : <>
                <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>ZPM Reconciliation</Typography>
                </Grid>
                <Grid item mt={2} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Button onClick={() => navigate(`/admin/adminreconciliationreport?reconcileFunds`)} style={{ backgroundColor: '#002543', width: '145px', textTransform: 'inherit', color: "#fff" }}>Reconcile</Button>
                </Grid>
                <Grid item mt={2} width={'100%'}>
                    <Grid container width={'100%'}>
                        <Grid item width={"30%"}>
                            <SearchFilter placeholder={"Search"} setInputValue={setInputValue} />
                        </Grid>
                    </Grid>
                    <Grid item width={'70%'}>

                    </Grid>
                </Grid>
                <Grid item mt={3} width={'100%'}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={rencolitationRows}
                        rows={pageState?.data}
                        getRowId={row => row?._id} />
                </Grid>
            </>}
        </Grid>
    )
}