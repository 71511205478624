import { Grid, Breadcrumbs, Typography, Button, InputAdornment, FormControl, InputLabel, OutlinedInput, IconButton, MenuItem, DialogActions, DialogContent, Dialog, Menu, Slide, Stack } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExportMenu from "../../../School/ExportMenu";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import CreateNewTerminal from "./createterminal";
import HttpComponent from "../../../School/MakeRequest";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { alpha, styled } from "@mui/material/styles";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import WarningImg from "../../../../images/warning.png"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RestoreImg from "../../../../images/restoreImg.svg"
import EditTerminal from "./editTerminal";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import ArticleIcon from '@mui/icons-material/Article';
import AssignNewTerminal from "./assignTerminal";
import Breadcrumb from "../../../SuperAdmin/components/Breadcrumbs/Breadcrumbs";
import Breadcrumb2 from "../../../SuperAdmin/components/Breadcrumbs/Breadcrumbs2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { setDeviceModels } from "../../../../features/DeviceModelsSlice";
import { useDispatch } from "react-redux";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;


//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Terminals</Typography>
]

//transition

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));

export default function TerminalMgnt() {

    

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue, setSearchValue] = useState('')
    const [tabValue, setTabValue] = useState('Active')
    const group = localStorage.getItem('group')

    //terminal id
    const [terminalId, setTerminalId] = useState(null)
    const [branchId, setBranchId] = useState(null)


    //pagination

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    console.log('pageState', pageState?.data);
    //navigate to create a new terminal

    const queryParams = decodeURIComponent(window.location.search)
    const state = queryParams.slice(1)

    const [storedId, setStoreId] = useState(null)
    const [bsNo ,setbsNo] = useState(null)
    const [terminalTerd, setTerminald] = useState(null)
    const [terminalStatus, setTerminalStatus] = useState(null)
    const [outletName, setOutletName] = useState("")
    const businessGroup = localStorage.getItem('group')
    // console.log("outletName", outletName)
    const RenderCell = (params) => {

        const [openRestoreDialog, setOpenRestoreDialog] = useState(false)
        const handleOpenRestoreDialog = () => { setOpenRestoreDialog(true) }
        const handleCloseRestoreDialog = () => { setOpenRestoreDialog(false) }



        const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false)
        const handleOpenDeactivateDialog = () => { setOpenDeactivateDialog(true) }
        const handleCloseDeactivateDialog = () => { setOpenDeactivateDialog(false) }
        const [anchorEl, setAnchorEl] = React.useState(null);


        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };

        const handleActionClose = () => {
            setAnchorEl(null);
        };

        const [terminalSerialNo, setTerminalSerialNo] = useState(null)

        //deactivate terminal

        const deactivateTerminal = () => {
            try {
                HttpComponent({
                    method: "POST",
                    url: `/api/v1/changeTerminalStatus`,
                    body: {
                        terminalState: "InActive",
                        serialNumber: terminalSerialNo
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: "successfully deactivaed terminal" })
                        setTimeout(() => {
                            handleCloseDeactivateDialog()
                        }, [])
                    } else {
                        setErrorShow({ state: true, message: data?.response?.message })
                        setTimeout(() => {
                            handleCloseDeactivateDialog()
                        }, [])
                    }
                    fetchAllTerminals()
                })
            } catch (error) {
                setErrorShow({ state: true, message: error })
            }
        }

        const activateTerminal = () => {
            try {
                HttpComponent({
                    method: "POST",
                    url: `/api/v1/changeTerminalStatus`,
                    body: {
                        terminalState: "Active",
                        serialNumber: terminalSerialNo
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: "successfully Activaed terminal" })
                        setTimeout(() => {
                            handleCloseRestoreDialog()
                        }, [])
                    } else {
                        setErrorShow({ state: true, message: data?.response?.message })
                        setTimeout(() => {
                            handleCloseRestoreDialog()
                        }, [])
                    }
                    fetchAllTerminals()
                })
            } catch (error) {
                setErrorShow({ state: true, message: error })
            }
        }

        return (
            <div>
                <Dialog
                    open={openRestoreDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={RestoreImg} style={{ width: '100px' }} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Restore  Terminal</h6>
                            <span>Do you want to restore this terminal ,  </span>
                            <span>This action cannot be undone</span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestoreDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={activateTerminal} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Restore</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeactivateDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={WarningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Deactivate  Terminal</h6>
                            <span>Do you want to deactivate this terminal  </span> <br></br>
                            {/* <span>This action cannot be undone</span> */}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeactivateDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={deactivateTerminal} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                    </DialogActions>
                </Dialog>
                <MoreVertIcon onClick={handleClick} />
                {params?.row?.terminalStatus === 'Active' ?
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={() => { navigate(`/terminal/mngt?edit`); setTerminalId(params?.row?._id) }}>
                            <EditIcon style={{ color: "#032541" }} /> Edit Terminal
                        </MenuItem>
                        <MenuItem disableRipple onClick={() => { setTerminald(params?.row?._id); setbsNo(params?.row?.businessID); setBranchId(params.row.branchId); setStoreId(params?.row?.storeId); setTerminalStatus(params?.row?.terminalStatus); setOutletName(params?.row?.outlet); navigate(`/terminal/mngt?assignNewOutlet`); }}>
                            <ArticleIcon style={{ color: "#17ae7b" }} /> Assign Another Outlet
                        </MenuItem>
                        {businessGroup === 'Admin' ?
                            <MenuItem disableRipple onClick={() => { handleOpenDeactivateDialog(); setTerminalSerialNo(params?.row?.terminalSerialNumber); setAnchorEl(null) }}>
                                <NotInterestedIcon style={{ color: "#DC3545FF" }} />  Deactivate Terminal
                            </MenuItem> : null}
                    </StyledMenu>
                    : params?.row?.terminalStatus === 'New' || params?.row?.terminalStatus === "Active" ?
                        <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                            <MenuItem disableRipple onClick={() => { setTerminald(params?.row?._id); setTerminalStatus(params?.row?.terminalStatus); setBranchId(params?.row?.branchId); setStoreId(params?.row?.storeId); setOutletName(params?.row?.outlet); navigate(`/terminal/mngt?assignNewOutlet`) }}>
                                <ArticleIcon style={{ color: "#17ae7b" }} /> Assign Another Outlet
                            </MenuItem>

                        </StyledMenu> : <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                            {businessGroup === 'Admin' ? <MenuItem disableRipple onClick={() => { handleOpenRestoreDialog(); setTerminalSerialNo(params?.row?.terminalSerialNumber); setAnchorEl(null) }} >
                                <RestartAltIcon style={{ color: "#17ae7b" }} /> Restore Terminal
                            </MenuItem> : null}

                        </StyledMenu>
                }
            </div>
        )

    }

    // all device models  columns
    const terminalMgnntcolumn = [
        {
            headerName: "SerailNo", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Serial No</strong>) }, renderCell: (params) => {
                return (
                    <div>{params?.row?.terminalSerialNumber ? params?.row?.terminalSerialNumber : 'N/A'}</div>
                )
            }
        },
        {
            headerName: "SimCardNo", field: 'simCardNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Sim Card No</strong>) }, renderCell: (params) => {
                return (
                    <div>{params?.row?.simCardNumber ? params?.row?.simCardNumber : 'N/A'}</div>
                )
            }
        },
        {
            headerName: "Institution", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Institution</strong>) }, renderCell: (params) => {
                return (
                    <div>{params?.row?.businessName ? params?.row?.businessName : 'N/A'}</div>
                )
            }
        },
        {
            headerName: "TodayTransaction", field: 'txn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Today TXN (KES)</strong>) }, renderCell: (params) => {
                return (
                    <div>{params?.row?.txn ? params?.row?.txn : 'N/A'}</div>
                )
            }
        },
        {
            headerName: "Outlet", field: 'outlet', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >OutLet</strong>) }, renderCell: (params) => {
                return (
                    <div>{params?.row?.outlet ? params?.row?.outlet : 'N/A'}</div>
                )
            }
        },
        {
            headerName: "alias", field: 'alias', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Alias</strong>) }, renderCell: (params) => {
                return (
                    <div>{params?.row?.alias !== 'N/A' ? `${params?.row?.terminalSerialNumber} - ${params?.row?.alias}` : 'N/A'}</div>
                )
            }
        },
        {
            headerName: "TMSStatus", field: 'terminalStatus', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >TMS Status</strong>) }, renderCell: (params) => {
                return (
                    <div><span style={params?.row?.terminalStatus === 'InActive' ? { color: "#dc3545" } : params?.row?.terminalStatus === 'Active' ? { color: '#17ae7b' } : {}}>{params?.row?.terminalStatus}</span></div>
                )
            }
        },
        { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: RenderCell }
    ]

    // fetch admin terminals

    function fetchAdminTerminals() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/allTerminals/${tabValue}?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'admin terminals')
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                } else {
                    setPageState((old) => ({ ...old, isLoading: false }));
                }
            })

        } catch (error) {

        }
    }

        //handle search

     const handleSearch = () => {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/searchZedPayItTerminals?searchValue=${searchValue}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    }
                })
            } catch (error) {
    
            }
        }


    //fetch all terminals

    function fetchAllTerminals() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getZedPayItTerminals?page=${pageState.page}&limit=${dataGridPageSize}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'active terminals')
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        if ((businessGroup === 'Partner' || businessGroup === 'Admin') && (searchValue === '' || searchValue)) {
            fetchAdminTerminals()
        } else if (businessGroup !== 'Partner' && businessGroup !== 'Admin' && !searchValue) {
            fetchAllTerminals()
        } else {
            handleSearch()
        }
    }, [state, dataGridPageSize, tabValue, businessGroup, pageState.page, searchValue])




    // useEffect(() => {
    //     if (searchValue)
    //         handleSearch()
    // }, [searchValue, dataGridPageSize, pageState.page,])

console.log(searchValue , 'searchValue')
    //export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "SerailNo": data?.terminalSerialNumber,
            "SimCardNo": data?.simCardNumber,
            "Institution": data?.businessName,
            "TodayTransaction": data?.txn,
            "TMSStatus": data?.terminalStatus,

        }
    })
    const fileHeaders = [["SerailNo", "SimCardNo", "Institution", "TodayTransaction", "TMSStatus"]]
    const csvColumns = [
        { label: "SerailNo", key: "SerailNo" },
        { label: "SimCardNo", key: "SimCardNo" },
        { label: "Institution", key: "Institution" },
        { label: "TodayTransaction ", key: "TodayTransaction" },
        { label: "TMSStatus", key: "TMSStatus", },
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.terminalSerialNumber, data?.simCardNumber, data?.businessName, data?.txn, data?.terminalStatus]);

    const fetchDeviceModels = async () => {
        const response = await axios.get(baseUrl + "/api/fetchDeviceModels", {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        dispatch(setDeviceModels(response.data.data));
      };
    
      useEffect(() => {
        fetchDeviceModels();
      }, []);

    return (
        <Grid container direction={'column'}>
            {state === 'createterminal' ? <CreateNewTerminal /> : state === 'edit' ? <EditTerminal terminalId={terminalId} /> : state === 'assignNewOutlet' ? <AssignNewTerminal terminalId={terminalTerd} branchId={branchId} storedId={storedId} businessNumber={bsNo} terminalStatus={terminalStatus} outletName={outletName} /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Stack alignItems='center' spacing={2} direction={'row'}>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "#707070",
                                "&:hover": { cursor: "pointer" },
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        />
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Terminals</Typography>
                    </Stack>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            {/* <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs> */}
                            <Breadcrumb2 point1={'Zed Pocket Money'} link1={"/payit/payitadmin"} point2={'Terminals'} />

                        </Grid>
                      
                        <Grid item>
                        <Grid item>
                            {businessGroup === 'Admin' || businessGroup === 'Partner' ?
                                <Button onClick={() => navigate('/terminal/mngt?createterminal')} style={{ background: "#032541", width: "140px", textTransform: "inherit", color: "#fff" }}>Create Terminal</Button> : null}
                        </Grid> 
                        {/* <Button
                            sx={{
                                borderRadius: 2,
                                backgroundColor: "#032541",
                                color: "white",
                                textTransform: "none",
                                "&:hover": { color: "white", backgroundColor: "#032541" },
                            }}
                            onClick={() => {
                                navigate("/AddTerminalAndAssign");
                            }}
                        >
                        Create Terminal
                        </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={3}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Search"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                        {/* <Button
                            sx={{
                                borderRadius: 2,
                                backgroundColor: "#032541",
                                color: "white",
                                textTransform: "none",
                                "&:hover": { color: "white", backgroundColor: "#032541" },
                            }}
                            onClick={() => {
                                navigate("/AddTerminalAndAssign");
                            }}
                        >
                        Create Terminal
                        </Button> */}
                        </Grid>
                        <Grid item>
                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Terminal Management'} title={'Terminal Management'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={3}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={terminalMgnntcolumn}
                        rows={pageState?.data}
                        getRowId={row => row._id} />
                </Grid>
            </>
            }
        </Grid>
    )
}