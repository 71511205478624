import { Grid, Typography, Breadcrumbs , Paper  } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from 'react';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import HttpComponent from '../../School/MakeRequest';
import DateFormatter from '../../../utils/dateFormatter';
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles Owner</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle Details</Typography>,
]

export default function ViewaVehicledetails() {
    const navigate = useNavigate();
    const [vehicledetails , setVehicledetails] = useState({})
    const [isloading ,setIsloading]  = useState(true)
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, vehicleId] = newqueryParamValue.split("?")

     // get vehicle details

     function getVehiclesDetails() {
        setIsloading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/vehicle_details_by_Id?vehicleId=${vehicleId}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setVehicledetails(data?.response?.data)
                } else {
                    setIsloading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        getVehiclesDetails()
    },[vehicleId])


    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Vehicle Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'} mt={2}>
               <Paper sx={{width:'70%', background:'#fafafa'}}>
                  <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                      <Grid item>
                          <Typography variant='h4' sx={{fontSize:'16px' , color:'#032541' , fontWeight:500}}>Registration No</Typography>
                          <Typography variant='h6' sx={{fontSize:'12px'}}>{vehicledetails?.regNo}</Typography>
                      </Grid>
                      <DirectionsBusFilledIcon/>
                  </Grid>
               </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
               <Paper sx={{width:'70%', background:'#fafafa'}}>
                  <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                      <Grid item>
                          <Typography variant='h4' sx={{fontSize:'16px' , color:'#032541' , fontWeight:500}}>Type</Typography>
                          <Typography  variant='h6' sx={{fontSize:'12px'}}>{vehicledetails?.vehicleType}</Typography>
                      </Grid>
                  </Grid>
               </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
               <Paper sx={{width:'70%', background:'#fafafa'}}>
                  <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                      <Grid item>
                          <Typography variant='h4' sx={{fontSize:'16px' , color:'#032541' , fontWeight:500}}>Policy Expiry</Typography>
                          <Typography  variant='h6' sx={{fontSize:'12px'}}>{DateFormatter(vehicledetails?.policyExpiry)}</Typography>
                      </Grid>
                  </Grid>
               </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
               <Paper sx={{width:'70%', background:'#fafafa'}}>
                  <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                      <Grid item>
                          <Typography variant='h4' sx={{fontSize:'16px' , color:'#032541' , fontWeight:500}}>Route</Typography>
                          <Typography  variant='h6' sx={{fontSize:'12px'}}>{vehicledetails?.routeCount}</Typography>
                      </Grid>
                  </Grid>
               </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
               <Paper sx={{width:'70%', background:'#fafafa'}}>
                  <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                      <Grid item>
                          <Typography variant='h4' sx={{fontSize:'16px' , color:'#032541' , fontWeight:500}}>Color</Typography>
                          <Typography  variant='h6' sx={{fontSize:'12px'}}>{vehicledetails?.color}</Typography>
                      </Grid>
                  </Grid>
               </Paper>
            </Grid>
        </Grid>
    )
}