import {Button, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
//import CustomSelectField from "../../School/CustomSelectField";
//import AddHouse from "./addHouse";
import AddService from "../Tenant/addService";
import ListServices from "../listServices";
//import AddUnit from "./addUnit";
import ListUnits from "../Units/listUnits";


export default function CreatServiceForm(props){
	const navigate = useNavigate()
	
	const [flagState, setFlagState] = useState("");
	
	const handleAddServiceClick = () => {
		setFlagState("addService");
	};
	const handleListingClick = () => {
		setFlagState("listService");
	};
	const handleGoBackClick = () => {
		setFlagState("");
	};
	
	const handleNextStep = () => {
		props.next()
	}

	const handlePrev = () => {
		setFlagState('listUnits')
	}
	
	// Rendering External Components
	let componentToRender = <div>
		{/*Define*/}
		<Grid container justifyContent={'center'} alignContent={'center'} direction={'row'} mt={20}>
			<Grid item>
				<span style={{fontSize : '12px', color : '#727272'}}> Add At least </span>
				<span style={{fontSize : '12px', color : '#dc3545', fontWeight : 'bold'}}> 1 </span>
				<span style={{fontSize : '12px', color : '#727272'}}> Service </span>
			</Grid>
		</Grid>
		
		{/*Buttons*/}
		<Grid container justifyContent={'center'} alignContent={'center'} direction={'row'} mt={15} spacing={2}>
			{/*<Grid item>*/}
			{/*	<Button onClick={() => {props.next()}} style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>*/}
			{/*		Skip Step*/}
			{/*	</Button>*/}
			{/*</Grid>*/}
			<Grid item>
				<Button onClick={handlePrev} style={{textTransform : 'none', border : 'solid 1px #dc3545', color : '#dc3545', background : '#fff', width : '146px', height : '45px', marginRight:"20px", fontSize : '16px', fontWeight : '600'}}>
					Cancel
				</Button>
				<Button onClick={handleAddServiceClick} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
					Add Service
				</Button>
			</Grid>
		</Grid>
	</div>
	
	if (flagState === "addService") {
		componentToRender = (
			<AddService back={handlePrev} isFromRentalSetup={true} setFlagStateListService={handleListingClick} setFlagListServices={handleListingClick}/>
		);
	} else if (flagState === 'listService') {
		componentToRender = (
			<ListServices next={handleNextStep} setFlagStateListingBack={handleAddServiceClick} setFlagState={handleAddServiceClick} handleGoBackClick={handleGoBackClick} />
		);
	}
	
	return (
		<div>
			{/*Body*/}
			{componentToRender}
		</div>
	)
}