import { Breadcrumbs, Button, ButtonBase, Checkbox, Grid, TextField, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from "../../../School/CustomSearchInput";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import CurrencyFormatter from "../../../../utils/currencyFormatter";
import HttpComponent from "../../../School/MakeRequest";
import { useSelector } from "react-redux";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { sum } from "lodash";

//BreadCrumbs
const breadcrumbs = [
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Tenants</Typography>,
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">House Type</Typography>,
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Unoccupied Units</Typography>,
	<Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Services</Typography>
];

export default function ServiceCharges(props) {


	const navigate = useNavigate()
	//const snewelectedValue = props?.selectedValue;
	const unitType = props?.unitType
	const uniCost = props?.uniCost 
	const unitName = props?.unitName
	const bsId = props?.businessUniId
	
	const [unitCharges, setUnitCharges] = useState([])
	const [unitchargeDb, setUnitChargeToDb] = useState([])

	//Auth
	const { X_Authorization } = useSelector((store) => store.user);
	const {tenantBioData ,tenantHouseData} = useSelector((store)=>store.tenantData)
	const [tenantD ,setTenantsD] = useState({})
	const [errorShow, setErrorShow] = useState({ state: false, message: "" });
	const [successShow, setSuccessShow] = useState({ state: false, message: "" });

	console.log(tenantBioData ,'ten bio data')

	//Redux Store
	//const {unitId, amount, itemNumber, billingDate, unitType} = useSelector((store) => store.tenantData.tenantHouseData)

	//Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${bsId}`,
			token: X_Authorization
		}).then((data) => {
			console.log(data, 'fetch service units')
			if (data.status === 201) {

				setUnitCharges(data.response.data)

				const newUnitCharge = data?.response?.data?.map((charge) => {
					return {
						name:charge.serviceName,
						productId:charge?.productId,
						amount:charge?.amount,
					}
				
				})

				setUnitChargeToDb(newUnitCharge)
				
			} else {
				console.log(`Error getting data`)
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		fetchServicesPerUnit()

	}, [])

	// search
	const [formData, setFormData] = useState({ search: "" })
	const handleInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) }

	//checkBox handler
	let [util, setUtil] = useState([])
	const [selectedItems, setSelectedItems] = useState([]);
	const handleCheckboxChange = (event, item) => {
		if (event.target.checked) {
			setSelectedItems([...selectedItems, item]);
			const object = { "amount": item.amount, "name": item.serviceName, "productId": item.productId, "serviceType": item.serviceType }
			setUtil([...util, object])
		} else {
			setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
			setUtil(util.filter((service) => service.productId !== item.productId))
		}
	};

	// get the total amount

	const getTotal = (selectedItems) => {
		return selectedItems.reduce((sum, i) => {
			return sum + (i.amount)

		}, 0)

	}

	//get the previous unit type id using tenant id

	function fetchTenantInfo(){
		try {
			HttpComponent({
				method: "GET",
                url: `/api/get_customer_by_customer_id?customerId=${tenantBioData.tenantId}`,
                token: X_Authorization
			}).then((data)=>{
				setTenantsD(data.response.data)
				
			})
		} catch (error) {
			console.log(error)
		}
	}

	//useeffect to fetch unit ttype
	useEffect(()=>{
		fetchTenantInfo()
	},[])

	let postData = {
		tenantId:tenantBioData.tenantId,
		houses: {
			unitType: unitType,
			previousUnitName: tenantHouseData.itemNumber,
			itemNumber: unitName,
			amount: uniCost,
			extraServices:unitchargeDb,
			previousUnitTypeId:tenantD?.billableItems?.unitTypeID
		}
	}

	//post transfer notice

	function postTenantTransfer() {
		try {
			HttpComponent({
				method: "POST",
				body:postData,
				url: `/api/v1/customer/transfer_tenant`,
				token: X_Authorization
			}).then((data) => {
				if(data.status ===  200){
					setTimeout(()=>{
						setSuccessShow({state:true ,message:'tenant transfered successfully'}) ;
						window.location.reload()
					} ,2000)
				}else{
					setErrorShow({state:true , message:"Error transfering tenant"})
				}
			})

		} catch (error) {
			console.log(error)

		}

	}

	return (
		<div>
			{/*Header and action buttons, breadCrumbs*/}
			<Grid container justifyContent={'flex-start'} direction={'column'} spacing={2}>
			<ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        	<SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

				{/*Header and Bread crumbs*/}
				<Grid item>
					<ButtonBase onClick={() => { navigate("/dashboard") }}>
						<ArrowBackIosIcon style={{ color: '#707070', fontSize: '25px', marginBottom: '5px' }} />
					</ButtonBase>
					<span style={{ color: '#032541', fontWeight: '600', fontSize: '25px' }}>Services</span>
				</Grid>

				{/*BreadCrumbs and Action Buttons*/}
				<Grid item mt={-2}>
					<Grid container justifyContent={'space-between'} direction={'row'} >

						{/*BreadCrumb container*/}
						<Grid item>
							<Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>

						{/*Create Service*/}
						<Grid item>
							<Button style={{ padding: '5px', textTransform: 'none', color: '#fff', background: '#032541', height: '45px', fontSize: '16px', fontWeight: '600', width: '146px' }}>
								Create Service
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/*Search and Export Container*/}
			<Grid container justifyContent={'space-between'} mt={4}>

				{/*SearchBox*/}
				<Grid item>
					<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:" />
				</Grid>

				{/*Export button*/}
				<Grid item>
					{/*	TODO : Add Export Button*/}
				</Grid>
			</Grid>

			{/*Service Title*/}
			<Grid container justifyContent={'flex-start'} mt={3}>

				{/*Header*/}
				<Grid item mb={3}>
					<span style={{ color: '#032541', fontSize: '16px', fontWeight: '600' }} >Service Charges</span>
				</Grid>
			</Grid>

			{/*Service*/}
			{/*Rent Deposit, service Charge, electricity*/}
			<Grid container direction={'row'} justifyContent={'space-between'} spacing={4}>
				{
					unitCharges?.map((data, index) => (
						<>
							{/*Rent Deposit*/}
							<Grid key={index} item>
								<Grid container direction={'row'} justifyContent={'space-between'}>

									{/*Checkbox and Label*/}
									<Grid item>
										<FormControlLabel label={
											<Grid container direction={'row'} justifyContent={'space-between'}>

												{/*Label*/}
												<Grid item>
													<span style={{ color: '#6e7074', fontSize: '14px', fontFamily: 'Poppins' }} >{data.serviceName}</span>
												</Grid>

											</Grid>
										} control={
											<Checkbox style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} edge="start" checked={selectedItems?.includes(data)} onChange={event => handleCheckboxChange(event, data)} />} />
									</Grid>

									<Grid item>
										<TextField disabled required readOnly size="small" value={CurrencyFormatter(data.amount, 'KES')} defaultValue={CurrencyFormatter(data.amount, 'KES')} type='text' InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '212px' }} id="outlined-basic" label="Amount" variant="outlined" />
									</Grid>
									
								</Grid>
							</Grid>
						</>
					))
				}
			</Grid>

			{/*Amount*/}
			<Grid container direction={'row'} justifyContent={'flex-end'} alignContent={'flex-end'} mt={5}>
				<Grid item>
					<span style={{ color: '#032541', frontWeight: 'bold', fontSize: '14px' }}>Total Amount :</span>
					<label style={{ marginLeft: '5px', color: '#032541', fontFamily: 'Poppins', frontWeight: '600', fontSize: '16px' }}>{CurrencyFormatter(  selectedItems.reduce((a ,i)=>a+i.amount ,0), 'KES')}</label>
				</Grid>
			</Grid>

			{/*Buttons*/}
			<Grid container direction={'row'} justifyContent={'flex-end'} alignContent={'flex-end'} spacing={2} mt={3}>
				<Grid item>
					<Button style={{ border: 'solid 1px #032541', textTransform: 'none', color: '#032541', background: '#fff', width: '146px', height: '45px', fontSize: '16px', fontWeight: '600' }}>
						Save
					</Button>
				</Grid>
				<Grid item>
					<Button disabled={selectedItems.length < 1} onClick={postTenantTransfer} style={{ textTransform: 'none', color: '#fff', background: '#032541', width: '160px', height: '45px', fontSize: '16px', fontWeight: '600' }}>
						Transfer Tenant
					</Button>
					 {/* <Button onClick={postTenantTransfer} style={{ textTransform: 'none', color: '#fff', background: '#032541', width: '160px', height: '45px', fontSize: '16px', fontWeight: '600' }}>
						Transfer Tenant
					</Button>  */}
				</Grid>
			</Grid>
		</div>
	)

}