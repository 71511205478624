import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import React, { useState } from "react";
import {makeStyles} from "@mui/styles";
import BadgeIcon from '@mui/icons-material/Badge';
import UniStaffImg from "../../../images/unistaff.svg"


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function UniversityStaff () {
	const classes = useStyles();
	const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	return (
		<div>
			<Link to="/school/universityStaff" style={{textDecoration: 'none', color: isActiveLink("/school/universityStaff") ? activeColor : '#fff'}}>
				<ListItem button className={classes.nested}>
					<div style={{marginRight : '5%'}}>
						<BadgeIcon/>
					</div>
					<div>
						<ListItemText primary="Staff" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}