import {Box, Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import React, {useState} from 'react';
import CustomInputLabel from "./CustomInputLabel";

const PortalSetUpForm = () => {
    const ExPortalForm = localStorage.getItem('exPortalForm')
    const [formData, setFormData] = useState({
        shortcode:"",
        username:"",
        orgName:""
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const noStyle = {
        "borderRadius":"4px",
        "border":"solid 1px #dc3545",
        "color":"#dc3545",
        textTransform : "none",
        textDecoration : "none"
    }

    const buttonWidthHeight = {
        width:"9.063rem",
        height:"3.125rem"
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        color:"white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }
    return (
        <Box component="div" sx={{display:"flex", justifyContent:"center"}}>
            <Box component="div" sx={{display:"flex", flexDirection:"column"}}>
                <CustomInputLabel required={true} label={"Organisation Shortcode"}/>
                <TextField
                    name={"shortcode"}
                    value={formData.shortcode}
                    onChange={handleInputChange}
                    placeholder={"Short Code"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />

                <CustomInputLabel required={true} label={"Mpesa Username"}/>
                <TextField
                    name={"username"}
                    value={formData.username}
                    onChange={handleInputChange}
                    placeholder={"Username"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />

                <CustomInputLabel required={true} label={"Organisation Name"}/>
                <TextField
                    name={"orgName"}
                    value={formData.orgName}
                    onChange={handleInputChange}
                    placeholder={"Name"}
                    variant="outlined"
                    margin="dense"
                    sx={{marginBottom:2}}
                    InputProps={{
                        sx: {
                            width: '31.063rem',
                            height: '3.438em',
                            fontSize:"0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        }
                    }}
                />

                <Box component="div" sx={{}}>
                    <FormControlLabel sx={{color:"#032541"}} control={<Checkbox />} label="By ticking, you are agreeing to the terms and conditions." />
                </Box>

                {!ExPortalForm.includes('true') ?
                <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                    <Box component="div" sx={{alignSelf:"center"}}>
                        <Button sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                            Cancel
                        </Button>

                        <Button sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                            Activate
                        </Button>
                    </Box>
                </Box>
                :
                <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                    <Box component="div" sx={{alignSelf:"center"}}>
                        <Button sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                            Deactivate
                        </Button>

                        <Button sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                            Update
                        </Button>
                    </Box>
                </Box>
                }
            </Box>
        </Box>
    );
};

export default PortalSetUpForm;

