import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { handleBranchId } from "../../../../features/selectedSlice";


// const options = ["Option 1", "Option 2", "Option 3", "Option 4"];

function SearchableSelect2() {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  let branches = useSelector((state) => state.terminalSelect.branches);

  let stringArr = [];

  const createArray2 = () => {
    if (branches) {
      const uniqueStrings = new Set(); // Use a Set to store unique strings
      branches?.forEach((obj) => {
        let string = `${obj.branchName} - ${obj.businessName} - ${obj.branchId}`;
        uniqueStrings.add(string); // Add each string to the Set
      });
      setOptions(Array.from(uniqueStrings)); // Convert Set back to an array and set options
    }
  };

  useEffect(() => {
    if (branches.length > 0) createArray2();
  }, [branches]);

  useEffect(() => {
    if (selectedOption) {
        const regex = /([a-fA-F0-9]{24})$/;
        const match = selectedOption.match(regex);
        if (match) {
            const extractedString = match[1];
            console.log(`extractedString : ${extractedString}`);
            dispatch(handleBranchId(extractedString))
        }
    }
  }, [selectedOption]);

  return (
    <Autocomplete
      label="Business Id"
      variant="outlined"
      fullWidth
      size="small"
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label="branch id" variant="outlined" />
      )}
    />
  );
}

export default SearchableSelect2;
