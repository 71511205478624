import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {Breadcrumbs, Button, ButtonBase, Grid, TextField, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import CustomSelectField from "../../../School/CustomSelectField";
import back from "../../../School/Images/back-icn.svg";
import HttpComponent from "../../../School/MakeRequest";

export default function AddService(props){
	const [serviceType, setServiceType] = useState('')
	const [serviceName, setServiceName] = useState('')
	const [amount, setAmount] = useState('')
	const [unitType ,setUnitType] = useState('')
	const [serviceData, setServiceData] = useState([])
	const [newAmount, setNewAmount] = useState(0)
	const [unitServiceName, setUnitServiceNames] = useState([])
	const [unitServiceType, setUnitServiceTypes] = useState([])
	const[unitCharges ,setUnitCharges] = useState([])
	
	//Select Field Options
	const unitOptions = serviceData.map((unit) => ({value : unit._id, label : unit.unitType}))
	const serviceOptions = unitServiceName.map((service) => ({value : service, label : service}))
	const serviceTypeOption = unitServiceType.map((type) => ({value : type, label : type}))
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"
	
	const formData = {serviceType : serviceType, amount : amount === 0 ? '0' :amount , serviceName : serviceName, businessUnitTypeId : unitType}
	
	// navigation
	const navigate = useNavigate()
	const { unitId} = useSelector((store) => store.tenantData.tenantHouseData)
	const tenantServices = useSelector((store)=>store.tenantData.tenantServices)
	console.log(serviceOptions ,'srvopt')
	console.log(tenantServices ,'tenent')

	//check if the service options exist in the store

	const checkExists = ({tenantServices ,serviceOptions})=>{
		serviceOptions?.filter(item => !tenantServices?.includes(item));
	}

	//Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method : 'GET',
			url : `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${unitId}`,
			token : X_Authorization
		}).then((data)=>{
				console.log('units in the house')

			if(data.status === 201){
				setUnitCharges(data.response.data)
			}else{
				console.log(`Error getting data`)
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}


	
	//Get services
	function getUnits() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unit_types_by_business_unit_config?limit=1000&page=1&businessUnitConfigId=${configId}`,
			token: X_Authorization
		}).then((data)=>{
			
			console.log("here store is data", data.response.data);
			if(data.status === 201){
				setServiceData(data.response.data)
			}
			else{
				console.error("Error setting info")
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}
	
	//Get Service names
	function ServiceName() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_rental_service_names',
				token : X_Authorization
			}).then((data)=>{
				console.log("Service names",data);
				if(data.status === 201){
					setUnitServiceNames(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error getting Service names')
		}
	}
	
	//Get Service Types
	function ServiceType() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_service_types',
				token : X_Authorization
			}).then((data)=>{
				console.log("Service Types",data);
				if(data.status === 201){
					setUnitServiceTypes(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log('Error getting Service names')
		}
	}
	
	//Create Service
	function submitService() {
		console.log('form data', formData)
		try {
			HttpComponent({
				method: 'POST',
				url: `/api/add_business_unit_service`,
				token: X_Authorization,
				body : formData})
				.then((data)=>{
					console.log("here is post data",data);
					if(data.status === 201){
						props.setFlagBacktoUpdateInvoice()
					}
					else{console.error("Error setting info")}})
				.catch((error)=>{console.error(error.message);})}
		catch (e) {console.log('Error Saving Service!')}}
	
	useEffect(() => {
		getUnits()
		ServiceName()
		ServiceType()
		fetchServicesPerUnit()
	}, [])


	// update service varaible 

	useEffect(() => {
		if(serviceType === 'Variable'){
			setAmount(0)
			setNewAmount(0)
		}
		if(serviceType === 'Fixed'){
			setAmount( Number(newAmount) )
		}

	},[serviceType, newAmount, amount])
	
	const breadcrumbs = [
		<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
		<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Create tenants</Typography>,
		<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Tenant Details</Typography>,
		<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">House Details</Typography>,
		<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Advance Charges</Typography>,
		<Link style={{fontFamily : 'Poppins', fontSize : '14px', textDecoration : "none"}} to={-1} key="3" color="text.inactive">Preview Invoice</Link>,
		<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Update Invoice</Typography>
	];
	
	return (
		<div style={{fontFamily : 'Poppins', marginTop : '-35px'}}>
			
			{/*Top navigation && Header Container*/}
			<Grid container>
				<Grid item mt={2} mb={2} direction={'row'} alignItems={'center'}>
					<ButtonBase onClick={() => {navigate(-1)}} >
						<img alt="" style={{marginRight : '3px', marginBottom : '10px'}} src={back} />
					</ButtonBase>
					<span style={{color : '#032541', fontSize : '25px', fontWeight : '600'}}>Update Invoice</span>
				</Grid>
			</Grid>
			
			{/*BreadCrumb container*/}
			<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
				{breadcrumbs}
			</Breadcrumbs>
			
			{/*Form Container*/}
			<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={2} mt={2}>

			{/*header*/}
			<Grid item>
				<span>Add Service</span>
			</Grid>
			
			<Grid item>
				
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '16px',fontWeight : 'normal'}} value={unitType} onChange={(e) => {setUnitType(e.target.value)}} name={"Unit Type"} placeholder={"Select Unit Type"} options={unitOptions} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '16px',fontWeight : 'normal'}} value={serviceName} onChange={(e) => {setServiceName(e.target.value)}} name={"Service Name"} placeholder={"Select Service Name"} options={serviceOptions} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '16px',fontWeight : 'normal'}} value={serviceType} onChange={(e) => {setServiceType(e.target.value)}} name={"Service Type"} placeholder={"Select Service Type"} options={serviceTypeOption} />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={1}>
					<Grid item>
						<TextField disabled={false} value={newAmount} onChange={(e) => {setNewAmount(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "16px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Amount" variant="outlined" required />
					</Grid>
				</Grid>
				
				<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={1}>
					<Grid item>
						<Button onClick={submitService} style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Save
						</Button>
					</Grid>
					{/*<Grid item>*/}
					{/*	<Button onClick={handleListingFlagClick} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>*/}
					{/*		List Services*/}
					{/*	</Button>*/}
					{/*</Grid>*/}
				</Grid>
				
			</Grid>
		</Grid>
		</div>
	)
}
