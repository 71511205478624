import { Grid, Typography, Breadcrumbs, Button, Autocomplete, TextField, Box, Stack, Modal } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom'
import CustomDataGrid from '../../../products/stockComponents/customDataGrid';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import PartnerCreateCategory from '../Outlets/PartnerCreateCategory'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomSearchInput from '../../../School/CustomSearchInput'
import WarningImg from "../../../../images/warning.png"
import PartnerEditProductcategory from './partnerEditProductcategory';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';


const breadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Partner</Typography>,
  <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Products Category</Typography>,
]

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };


export default function PartnerProductsCategories() {
  const navigate = useNavigate();
  const queryParams = decodeURIComponent(window.location.search);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const newqueryParamValue = queryParams.slice(1);
  const [queryParamValue] = newqueryParamValue.split("?")
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  const [searchValue, setSearchValue] = useState('')
  const [businessFound, setBusinessFound] = useState([])
  const [selectedBusinessNo, setSelectedbnssNumber] = useState('')
  const [options, setOptions] = useState([])
  const partnerName = localStorage.getItem("affiliatedPartner")

  function getActiveBusinesses() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/getActiveBusinesses`,
        token: localStorage.getItem('X-Authorization'),
        body: {
          module: 'all module',
          partnerName: partnerName
        }
      }).then((data) => {
        if (data?.status === 200) {
          setBusinessFound(data?.response?.data)
        }

      })
    } catch (error) {

    }

  };

  useEffect(() => {
    getActiveBusinesses();
  }, []);


  const createArray = () => {
    if (businessFound) {
      const uniqueStrings = new Set();
      businessFound?.forEach((obj) => {
        let string = `${obj.businessNumber} - ${obj.businessName}`;
        uniqueStrings.add(string);
      });
      setOptions(Array.from(uniqueStrings));
    }
  };

  useEffect(() => {
    if (businessFound?.length > 0) createArray();
  }, [businessFound]);


  const [newBusinessNumber, setNewBusinessNumber] = useState('')

  useEffect(() => {
    if (selectedBusinessNo) {
      const regex = /(\d+) -/;
      let match = selectedBusinessNo.match(regex);
      if (match) {
        const extractedNumber = match[1];
        setNewBusinessNumber(extractedNumber)

      }
    }
  }, [selectedBusinessNo]);

  const [openDeactivate, setOpenDeactivate] = useState(false)
  const handleCloseDeactivate = () => setOpenDeactivate(false)
  const [productCategoryName, setProductCategoryName] = useState('')
  const [categoryId , setCategoryId] = useState('')
  const handleOpenDeactivate = (params) => {
    setProductCategoryName(params?.row?.categoryName)
    setCategoryId(params?.row?.id)
    setOpenDeactivate(true)

  }

  function handleDeactivate() {
    try {
      HttpComponent({
        method: 'PUT',
        url: `/api/updateCategory`,
        body: {
          categoryState: "Inactive",
          categoryId: [categoryId]
        },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data?.status === 202) {
          setSuccessShow({ state: true, message: `${productCategoryName} deactivated successfully` })
          setTimeout(() =>{
            getProductsCategory();
            handleCloseDeactivate()
          }, 1000)
        } else {
          setErrorShow({ state: true, message: data.response.message })
        }
      })
    } catch (error) {
      setErrorShow({ state: true, message: error.message })
    }

  }



  const RenderCell = (params) => {
    return (
      <Stack direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }} gap={2}>
        <EditIcon onClick={() => navigate(`/partnerproductscategories?EditCategory?${params?.row?.id}`)} sx={{ color: '#032541' }} />
        <DeleteIcon onClick={() => { handleOpenDeactivate(params) }} sx={{ color: '#dc3545' }} />
      </Stack>
    )
  }

  const productColumns = [
    { field: "categoryName", headerName: "Name", flex: 1 },
    { field: "categoryCode", headerName: "Category Code", flex: 1 },
    { field: "businessName", headerName: "Business Name", flex: 1 },
    { field: "categoryDescription", headerName: "Category Description", flex: 1 },
    { field: 'productService', headerName: 'Product Type', flex: 1 },
    { field: 'Action', headerName: 'Action', flex: 1, renderCell: RenderCell },


  ]

  // get insurance companies

  function getProductsCategory() {
    setPageState({ ...pageState, isLoading: true })
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/listPartnerCategories?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&businessNumber=${newBusinessNumber}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
        } else {
          setPageState({ ...pageState, isLoading: false })
        }
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    getProductsCategory()
  }, [pageState.page, dataGridPageSize, queryParamValue, searchValue, newBusinessNumber])

  const productData = pageState?.data?.map((config) => ({
    id: config._id,
    categoryCode: config?.categoryCode,
    glAccountName: config?.glAccountName,
    glExpenseAccountName: config?.glExpenseAccountName,
    businessName: config?.businessName,
    description: config?.description,
    categoryName: config?.categoryName,
    categoryDescription: config?.categoryDescription,
    productService: config?.productService

  }))

  return (
    <Grid container direction={'column'}>
      {queryParamValue === 'AddProductCategory' ? <PartnerCreateCategory /> : queryParamValue === 'EditCategory' ? <PartnerEditProductcategory /> : <>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
            {breadcrumbs}
          </Breadcrumbs>
          <Button onClick={() => navigate(`/partnerproductscategories?AddProductCategory`)} style={{ width: '210px', marginLeft: '10px', textTransform: "inherit", color: '#fff', backgroundColor: '#032541' }}>Create Product Category</Button>
        </Grid>
        <Grid item mt={2} width={'100%'} alignItems={'center'} display={'flex'}>
          <Grid container gap={3}>
            <Grid item mt={-1}>
              <CustomSearchInput name={'searchValue'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search'} />
            </Grid>
            <Grid item width={'40%'}>
              <Autocomplete
                label="Select Business"
                variant="outlined"
                fullWidth
                size="medium"
                value={selectedBusinessNo}
                onChange={(event, newValue) => {
                  setSelectedbnssNumber(newValue);
                }}
                options={options}
                renderInput={(params) => (
                  <TextField {...params} label="Select Business" variant="outlined" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item mt={2} width={"100%"}>
          <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={productColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productData} />
        </Grid>
      </>}
      <Modal
        open={openDeactivate}
        onClose={handleCloseDeactivate}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid container display={'flex'} spacing={4}>
              <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item ml={2}>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Deactivate Product Category ?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to deactivate -   <span style={{ fontWeight: 700, }}>{productCategoryName}</span>
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={handleCloseDeactivate} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleDeactivate} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  )
}