import { Grid, Typography, Breadcrumbs, Button, Box, Paper } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import {useNavigate} from 'react-router-dom'
import { useEffect, useState } from 'react';
import HttpComponent from '../../School/MakeRequest';
import DateFormatter from '../../../utils/dateFormatter';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Operators</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Operators Details</Typography>
]

export default function ViewOperatorDetails() {

    const navigate = useNavigate();
    const [customerDetails , setCustomerDetails] = useState({})
    const [isloading ,setIsloading]  = useState(true)
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, customerId] = newqueryParamValue.split("?")
    

     // get vehicle details

     function getCustomerdetails() {
        setIsloading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_number?customerId=${customerId}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setCustomerDetails(data?.response?.data ? data?.response?.data[0]:{})
                } else {
                    setIsloading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        getCustomerdetails()
    },[customerId])

    console.log(customerDetails , 'customerDetails')
    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Operator Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Paper sx={{ width: '70%', background: '#fafafa' }}>
                    <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item py={2}>
                            <Typography variant='h4' sx={{ fontSize: '12px', color: '#032541', fontWeight: 500 }}>Operator ID   - {customerDetails?.customerNumber}</Typography>
                            <Typography variant='h6' sx={{ fontSize: '12px' }}>{customerDetails?.firstName} {customerDetails?.lastName}</Typography>
                        </Grid>
                        <PersonIcon/>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Paper sx={{ width: '70%', background: '#fafafa' }}>
                    <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <Typography variant='h4' sx={{ fontSize: '16px', color: '#032541', fontWeight: 500 }}>Assigned Vehicle</Typography>
                            <Typography variant='h6' sx={{ fontSize: '12px' }}>{customerDetails?.regNo}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Paper sx={{ width: '70%', background: '#fafafa' }}>
                    <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <Typography variant='h4' sx={{ fontSize: '16px', color: '#032541', fontWeight: 500 }}>Operator Type</Typography>
                            <Typography variant='h6' sx={{ fontSize: '12px' , textTransform:'capitalize' }}>{customerDetails?.customerClassification}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Paper sx={{ width: '70%', background: '#fafafa' }}>
                    <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <Typography variant='h4' sx={{ fontSize: '16px', color: '#032541', fontWeight: 500 }}>Mobile Number</Typography>
                            <Typography variant='h6' sx={{ fontSize: '12px' }}>{customerDetails?.phone}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <Paper sx={{ width: '70%', background: '#fafafa' }}>
                    <Grid container display={'flex'} py={2} px={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <Typography variant='h4' sx={{ fontSize: '16px', color: '#032541', fontWeight: 500 }}>Email</Typography>
                            <Typography variant='h6' sx={{ fontSize: '12px' }}>{customerDetails?.email}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}