import { Box, Breadcrumbs, Button, Grid, Typography } from '@mui/material'
import React, { useRef, useState , useEffect} from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import icon from "../../../components/School/Images/upload.svg"
import { useNavigate } from 'react-router-dom';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Upload image</Typography>

]
const buttonStyling = {
    "width": "10.625rem",
    "height": "2.813rem",
    "borderRadius": "5px",
    "backgroundColor": "#032541",
    color: "white",
    '&:hover': {
        backgroundColor: '#032541',
        color: 'white'
    }
}
const UploadFileFT = () => {
    const imageUploader = useRef(null);
    const uploadedImage = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const [img, setImg] = useState(null);
    const logoImg = localStorage.getItem('logoImage');
    const navigate = useNavigate();
    useEffect(() => {
        setImg(localStorage.getItem('logoImage'));
    }, []);



    const handleFileInputChange = (e) => {
        const [file] = e.target.files;
        console.log("file", e.target.files)
        console.log("uploadedImage", uploadedImage)
        console.log("imageUploader", imageUploader)
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            const selectedFile = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            }
            reader.readAsDataURL(file);
            setFile(file);
            setSelectedFile(selectedFile);
        }
    };



    const handleDrop = (e) => {
        e.preventDefault();
        handleFileInputChange(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const uploadFile = () => {
        console.log("file", file)
        setImg("");
        // localStorage.setItem('logoImage', uploadedImage.current.src); // clear ths image
        localStorage.setItem('logoImage', "");
        localStorage.setItem('receipt', uploadedImage.current.src);
        console.log(uploadedImage.current.src)
        console.log(img)


        setTimeout(() => {
            navigate(-1)
        }, 1000);
    }
   

    return (
        <Grid>
            <Grid item mt={1}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }} onClick={() => navigate(-1)}> <ArrowBackIos /> Upload Image</Typography>
            </Grid>
            <Grid container mt={3}>
                <Grid item>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid mt={3}>
                <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#000", margin: " 30px 53px 20px 68px" }}>Upload your Receipt</Typography>

            </Grid>
            <Grid >
                <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", margin: " 20px 24px 20px 39px" }}>Have a receipt? Upload it now!</Typography>
            </Grid>

            <Grid sx={{ width: "350px", height: "400px", margin: "20px 792px 20px 40px", padding: "8px 10px 10px", borderRadius: "12px", border: " solid 1px #e0e0e0", backgroundColor: "#fff" }}>
                <Box sx={{ borderRadius: "6px", border: "solid 1px #e1e1e1", backgroundColor: "rgba(3, 37, 65, 0.02)", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    {!logoImg ? (
                        <Box sx={{ display: "flex", alignSelf: "center", flexDirection: "column", justifyContent: "center", width: "15.375rem", height: "24.269rem", padding: "20px 16.5px 46.3px 15.5px" }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <Box sx={{ alignSelf: "center", marginY: 1 }}>
                            <img style={{
                                  width: "15.375rem", height: "24.269rem", objectFit: "contain", display: "flex", flexDirection: "column", justifyContent: "center",

                               
                               
                            }}
                                onClick={() => imageUploader.current.click()}
                                alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                            </Box>
                            {!logoImg ? null : (
                            <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                                    Drag and drop your  receipt here
                                </Typography>
                            </Box>
                            )}

                            <input
                                type="file"
                                accept="image/*"
                                multiple="false"
                                onChange={handleFileInputChange}
                                style={{ display: "none" }}
                                ref={imageUploader}
                            />
                            

                        </Box>
                    ) : (
                        <Box sx={{ display: "flex", alignSelf: "center", flexDirection: "column", justifyContent: "center", width: "15.375rem", height: "24.269rem", padding: "20px 16.5px 46.3px 15.5px" }}
                        > 
                        <img style={{  width: '56.5px',height: '56.5px',objectFit: 'contain' }}onClick={() => imageUploader.current.click()}alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                        </Box>
                           

                    )}

                </Box>

            </Grid>
            <Grid sx={{ margin: "20px 792px 20px 40px", }} >
                <Grid sx={{ display: "flex", flexDirection: "row", }}>
                    <CheckCircleOutlineIcon sx={{ color: "#17AE7B", marginRight: "10px" }} />
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", }}>  Accepted file formats: .png or .jpg</Typography>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    <CheckCircleOutlineIcon sx={{ color: "#17AE7B", marginRight: "10px" }} />
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", }}>  Max. File size: 1MB</Typography>
                </Grid>
            </Grid>
            <Grid sx={{ margin: "20px 792px 20px 40px", }} >

                <Button  disabled={logoImg ? true : false} variant="contained" style={{ backgroundColor: !logoImg ? "#032541" : "#707070", color: "#fff", borderRadius: "12px", width: "350px", height: "50px", textTransform: "none", fontSize: "16px", fontWeight: 600, }} onClick={() => {uploadFile ()}}>Upload File</Button>
            </Grid>

        </Grid>
    )
 }

export default UploadFileFT