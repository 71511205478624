import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import TotalBusinesses from "../components/TotalBusinesses";
import TotalUsers from "../components/TotalUsers";
import TotalTerminals from "../components/TotalTerminals";
import AllTransactions from "../components/AllTransactions";
import TopPerforming from "../components/TopPerforming";
import ZedPayit from "../components/ZedPayit";
import SalesStatistics from "../components/SalesStatistics";
import Modules from "../components/Modules";
import PaymentMode from "../components/PaymentMode";
import Calender from "../components/Calender";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

function AdminDashboard() {
  let navigate = useNavigate();
  let group = localStorage.getItem("group");
  // let email = localStorage.getItem('email')

  let Group = localStorage.getItem("group");
  let partnerName = localStorage.getItem("affiliatedPartner");
  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  // if(group === "Partner") {
  //   try {
  //     axios
  //       .post(baseUrl + "/api/getAffiliatedBusiness",{
  //         email
  //       },
  //        {
  //         headers: {
  //           "X-Authorization": localStorage.getItem("X-Authorization"),
  //         },
  //       })
  //       .then((response) => {
  //         let data = response.data.data
  //         if (response) {
  //           localStorage.setItem("affiliatedPartner", data)
  //         }
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // } else {
  //   localStorage.setItem("affiliatedPartner", null)
  // }

  return (
    // <Container maxWidth={'xl'}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          bgcolor={"white"}
          height="45px"
          // borderRadius={"12px"}
          px={2}
          alignItems={"center"}
          boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
        >
          <Typography
            fontFamily={"Poppins"}
            fontSize={"16px"}
            fontWeight={500}
            fontStretch={"normal"}
            fontStyle={"normal"}
            lineHeight={2.06}
            letterSpacing={"normal"}
            textAlign={"left"}
            color={"#687c8d"}
          >
            {group === "Admin"
              ? `Super Admin Dashboard `
              : `Partner Dashboard : ${partnerName}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"row"} width="inherit" spacing={1}>
          <Stack
            width={"33%"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/Businesses")}
          >
            <motion.div whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.9 }}>
              <TotalBusinesses />
            </motion.div>
          </Stack>
          <Stack
            width={"33%"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/SuperAdminUsers")}
          >
            <motion.div whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.9 }}>
              <TotalUsers />
            </motion.div>
          </Stack>
          <Stack
            width={"33%"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/TerminalsAndPrinters")}
          >
            <motion.div whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.9 }}>
              <TotalTerminals />
            </motion.div>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"row"} width="inherit" spacing={1}>
          <Stack
            width={"33%"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/AllTransactions")}
          >
            <motion.div whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.9 }}>
              <AllTransactions />
            </motion.div>
          </Stack>
          <Stack
            width={"33%"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/OverallPerformance")}
          >
            <motion.div whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.9 }}>
              <TopPerforming />
            </motion.div>
          </Stack>
          <Stack width={"33%"}>
            <ZedPayit />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack width={"100%"}>
          {/* {isAdmin &&  */}
          <SalesStatistics />

          </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack width={"100%"}>{isAdmin && <Modules />}</Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack width={"100%"}>
        {isAdmin &&<PaymentMode /> }
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack width={"100%"}>
          {/* calender */}
          {/* {isAdmin &&  */}
          <Calender />
        </Stack>
      </Grid>
    </Grid>
    // </Container>
  );
}

export default AdminDashboard;
