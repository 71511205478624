import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/terminalToUpdateSlice";
import { handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { handleTerminalEdited } from "../../../../features/EditedTerminalsSlice";


const baseUrl = process.env.REACT_APP_BASE_URL;

function EditTerminal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //pulling values from redux to set initial values of form inputs
  const reduxSerialNo = useSelector((state) => state.terminalDetails.serialNo);
  const reduxPosModel = useSelector((state) => state.terminalDetails.posModel);
  const reduxAlias = useSelector((state) => state.terminalDetails.alias);
  const reduxBusinessId = useSelector(
    (state) => state.terminalDetails.businessId
  );

  //value to set when user edits form
  const [serialNo, setSerialNo] = useState(reduxSerialNo);
  const [posModel, setPosModel] = useState(reduxPosModel);
  const [alias, setAlias] = useState(reduxAlias);
  const [businessId, setBusinessId] = useState(reduxBusinessId);


  const handlePosModel = (e) => {
    setPosModel(e.target.value);
  };
  const handleAlias = (e) => {
    setAlias(e.target.value);
  };
  const handleBusinessId = (e) => {
    setBusinessId(e.target.value);
  };

  const editedBy = localStorage.getItem("username");
  const timestamp = Date.now();
  const date = new Date(timestamp);
  const dateModified = date.toLocaleString();

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleUpdate = () => {
    if(posModel) {
      if(alias) {
        if(businessId) {
          setLocalDetails({
            serialNo,
            posModel,
            alias,
            businessId,
            editedBy,
            dateModified,
          });
        } else {
          setFailMessage("business Id required!")
        }
      } else {
        setFailMessage("alias required!")
      }
    } else {
      setFailMessage("posModel required!")
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(handleTerminalEdited(localDetails));
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/TerminalsAndPrinters");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Edit Terminal
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Terminals & Printers"}
        link1={"/TerminalsAndPrinters"}
        point2={"Edit Terminal"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Edit Terminal Details
            </Typography>
            <TextField
              label="Serial No"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxSerialNo}
              InputLabelProps={{
                shrink: true,
              }}
              // onChange={handleSerialNo}
              autoFocus
              disabled
            />
            <TextField
              label="POS Model"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxPosModel}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handlePosModel}
            />
            <TextField
              label="Alias"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxAlias}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleAlias}
            />
            <TextField
              label="Business ID"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxBusinessId}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleBusinessId}
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/TerminalsAndPrinters")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EditTerminal;
