import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import BusinessesTabs from "../components/Businesses/BusinessesTabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import {
  handleEdited,
  handleDeactivated,
  handleActivated,
  handleDeleted,
  handleAdded,
  handleAssigned,
  handleApproved,
  handleDeclined,
  handleEnabled,
} from "../../../features/AlertSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { handlePartnerId } from "../../../features/partnerSelectSlice";
import AddBusinesssStepper from "../components/Businesses/AddBusinessFiles/AddBusinessStepper";
import { ViewZpmSchoolAdmin } from "../components/Businesses/viewzpmAdmin";

function Businesses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editedAlertState = useSelector((state) => state.alertState.edited);
  const addedAlertState = useSelector((state) => state.alertState.added);
  const deletedAlertState = useSelector((state) => state.alertState.deleted);
  const assignedAlertState = useSelector((state) => state.alertState.assigned);
  const approvedAlertState = useSelector((state) => state.alertState.approved);
  const declinedAlertState = useSelector((state) => state.alertState.declined);
  const enabledAlertState = useSelector((state) => state.alertState.enabled);
  const deactivatedAlertState = useSelector(
    (state) => state.alertState.deactivated
  );
  const activateAlertState = useSelector((state) => state.alertState.activated);

  const [showEditedAlert, setShowEditedAlert] = useState(false);
  const [showDeactivatedAlert, setShowDeactivatedAlert] = useState(false);
  const [showDeletedAlert, setShowDeletedAlert] = useState(false);
  const [showActivatedAlert, setShowActivatedAlert] = useState(false);
  const [showAddedAlert, setShowAddedAlert] = useState(false);
  const [showAssignedAlert, setShowAssignedAlert] = useState(false);
  const [showApprovedAlert, setShowApprovedAlert] = useState(false);
  const [showDeclinedAlert, setShowDeclinedAlert] = useState(false);
  const [showEnabledAlert, setShowEnabledAlert] = useState(false);
  const [showCreateAlert, setShowCreateAlert] = useState(false);
  const group = localStorage.getItem('group')

     //url params

  const queryparams = decodeURIComponent(window.location.search);
  const [state]  = queryparams?.slice(1).split("?")


  console.log(state ,'state')

  const handleShowCreateAlert = () => {
      setShowCreateAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowCreateAlert(false);
      }, 5000);
  };

  const handleShowDeletedAlert = () => {
    if (deletedAlertState === true) {
      setShowDeletedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowDeletedAlert(false);
        dispatch(handleDeleted(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeletedAlert();
  }, [deletedAlertState]);

  const handleShowEnabledAlert = () => {
    if (enabledAlertState === true) {
      setShowEnabledAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowEnabledAlert(false);
        dispatch(handleEnabled(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowEnabledAlert();
  }, [enabledAlertState]);

  const handleShowDeclinedAlert = () => {
    if (declinedAlertState === true) {
      setShowDeclinedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowDeclinedAlert(false);
        dispatch(handleDeclined(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeclinedAlert();
  }, [declinedAlertState]);

  const handleShowApprovedAlert = () => {
    if (approvedAlertState === true) {
      setShowApprovedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowApprovedAlert(false);
        dispatch(handleApproved(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowApprovedAlert();
  }, [approvedAlertState]);
  const handleShowAssignedAlert = () => {
    if (assignedAlertState === true) {
      setShowAssignedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAssignedAlert(false);
        dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAssignedAlert();
  }, [assignedAlertState]);

  const handleShowAddedAlert = () => {
    if (addedAlertState === true) {
      setShowAddedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAddedAlert(false);
        dispatch(handleAdded(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAddedAlert();
  }, [addedAlertState]);

  const handleShowAlert = () => {
    if (editedAlertState === true) {
      setShowEditedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowEditedAlert(false);
        dispatch(handleEdited(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [editedAlertState]);

  const handleShowDeactivatedAlert = () => {
    if (deactivatedAlertState === true) {
      setShowDeactivatedAlert(true);
      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowDeactivatedAlert(false);
        dispatch(handleDeactivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeactivatedAlert();
  }, [deactivatedAlertState]);

  const handleActivateAlert = () => {
    if (activateAlertState === true) {
      setShowActivatedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowActivatedAlert(false);
        dispatch(handleActivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleActivateAlert();
  }, [activateAlertState]);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const nextMove = () => {
    if (clickedItem == "Edit") {
      navigate("/EditBusiness");
    } else if (clickedItem == "View") {
      navigate("/ViewModules");
    } else if (clickedItem == "Branches") {
      navigate("/ViewBranches");
    } else if (clickedItem == "Assign") {
      navigate("/assignPartner");
    }
  };

  useEffect(() => {
    nextMove();
  }, [clickedItem]);

  dispatch(handlePartnerId(""))

  // dispatch(handleTabChange("Active")); //setting the current tab in redux

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      {state === 'PartnerCreatebusiness' ? <AddBusinesssStepper/>: state === 'viewZpmAdmin' ? <ViewZpmSchoolAdmin/>: <>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Businesses
          </Typography>
        </Stack>
        {showEditedAlert && (
          <Alert variant="filled" severity="success">
            Business details Edited successfully...click on Edited to approve
          </Alert>
        )}
        {showEnabledAlert && (
          <Alert variant="filled" severity="success">
            Business Modules Enabled successfully...
          </Alert>
        )}
        {showDeactivatedAlert && (
          <Alert variant="filled" severity="success">
            Business Deactivated successfully...Click on Inactive to view
          </Alert>
        )}

        {showDeletedAlert && (
          <Alert variant="filled" severity="success">
            Business Deleted successfully
          </Alert>
        )}
        {showActivatedAlert && (
          <Alert variant="filled" severity="success">
            Business Activated successfully...Click on Active to view
          </Alert>
        )}
        {showAddedAlert && (
          <Alert variant="filled" severity="success">
            Business Added successfully...click New to view
          </Alert>
        )}
        {showAssignedAlert && (
          <Alert variant="filled" severity="success">
            Partner Assigned successfully
          </Alert>
        )}
        {showApprovedAlert && (
          <Alert variant="filled" severity="success">
            Business Edited successfully...click on Active to view
          </Alert>
        )}
        {showDeclinedAlert && (
          <Alert variant="filled" severity="success">
            Business Edits declined..
          </Alert>
        )}
        {showCreateAlert && (
          <Alert variant="filled" severity="info">
            Feature currently under development..
          </Alert>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Businesses" />
        {group === 'Partner' ?  <Button
          sx={{
            borderRadius: 2,
            backgroundColor: "#032541",
            color: "white",
            textTransform: "none",
            "&:hover": { color: "white", backgroundColor: "#032541" },
          }}
          onClick={() => navigate('/Businesses?PartnerCreatebusiness')}
        >
          Add Business
        </Button>:null }
       
      </Stack>
      <BusinessesTabs />
      </>}
    </Stack>
  );
}

export default Businesses;
