import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    zedPayItStatus: false,
    accountingEnabled: false,
    inventoryEnabled: false,
    warehouseOn: false,
};

const BusinessModuleSlice = createSlice({
  name: "moduleDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.zedPayItStatus = action.payload.zedPayItStatus;
      state.accountingEnabled = action.payload.accountingEnabled;
      state.inventoryEnabled = action.payload.inventoryEnabled;
      state.warehouseOn = action.payload.warehouseOn;
    },
  },
});

export const { setDetails } = BusinessModuleSlice.actions;

export default BusinessModuleSlice.reducer;
