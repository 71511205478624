import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import TransferStockSetUp from "./stockModals/transferStockSetup";
import Category from "./stockModals/category";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbcomponent from "./breadcrumb";
import { stockStyles } from "./stockStyling";
import TransferStockBatch from "./stockModals/transferStockBatch";
import TransferCategoryProducts from "./stockModals/transferCategoryproducts";
import CategoryAndTableTransfer from "./stockModals/categoryAndTableTransfer";

const Transferstock = () => {

  const [storeFromId, setStoreFromId] = useState("");
  const [storeToId, setStoreToId] = useState("");
  const [storeFromName, setStoreFromName] = useState("")
  const [storeNameTo, setStoreNameTo] = useState("")

  const { state } = useParams();

  const queryParams = decodeURIComponent(window.location.search)
  const queryParamsData = queryParams.slice(1);

  const storeFrom = queryParamsData.split("?")[0]
  const storeTo = queryParamsData.split("?")[1]

  const navigate = useNavigate();
  const breadcrumb = [<span key={1}>Dashboard</span>, <span key={2} onClick={() => navigate(-1)}>Stock</span>, <span key={3} style={{ color: "red" }}>Transfer Stock</span>];

  const componentToRender = () => {
    if (state === "transfer") {
      return <TransferStockSetUp setStoreFromId={setStoreFromId} setStoreToId={setStoreToId} setStoreFromName={setStoreFromName} setStoreNameTo={setStoreNameTo} />;
    } else if (state === "categories") {
      return  <CategoryAndTableTransfer storeFromId={storeFromId ? storeFromId : storeFrom} storeToId={storeToId ? storeToId : storeTo}  />
    } else if (state === "viewBatch") {
      return <TransferStockBatch  storeToId={storeToId ? storeToId : storeTo} storeFromId={storeFromId ? storeFromId : storeFrom} storeFromName={storeFromName} storeNameTo={storeNameTo} />;
    }
  };


  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"}>
        <Grid item width={"100%"} margin={"0px 0px 1% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"}  margin={"0px 0px 1% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      <Grid container width={"100%"}>
        {componentToRender()}
      </Grid>
    </div>
  );
};

export default Transferstock;
