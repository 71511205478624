import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { makeStyles } from "@mui/styles";
import vouchers from '../../common/images/whitevoucher.svg'
import { CardGiftcard } from "@mui/icons-material"

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Vouchers() {
	const classes = useStyles();
	const location = useLocation();
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	return (
		<div>
			<Link style={{ textDecoration: 'none', color: isActiveLink("/vouchers") ? activeColor : '#fff'}} to="/vouchers">
				<ListItem button className={classes.nested}>
					<div style={{ marginRight: '5%' }}>
						<CardGiftcard />
					</div>
					<ListItemText primary="Vouchers" />
				</ListItem>
			</Link>
		</div>
	)
}
