import { Breadcrumbs, Grid, Tab, Tabs, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SuccessAlert } from './snackBar Alerts/successAlert';
import { ErrorAlert } from './snackBar Alerts/errorAlert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from './School/CustomSearchInput';

function TimeOff() {
    const [tabIndex, setTabIndex] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(0);
    const [timeOff, setTimeOff] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const token = localStorage.getItem('X-Authorization');
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [totalCount, setTotalCount] = useState(0);
    const customerId = localStorage.getItem('customerId');
    // console.log('customerId', customerId)

    const tabLabels = ["pending", "inprogress", "completed", "deactivated"];

    useEffect(() => {
        getTimeOff(tabLabels[tabIndex]);
    }, [tabIndex, page, rowsPerPage]);

    const handleSearchBox = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
        setPage(0);
    };

    const getTimeOff = async (status) => {
        try {
            const response = await fetch(`${baseUrl}/api/v1/booking/get/list/timeOff?page=${page + 1}&limit=${rowsPerPage}&status=${status}&staffId=${customerId}`, {
                method: 'GET',
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            if (response.ok) {
                setTimeOff(data.data);
                setTotalCount(data.total);
            } else {
                setErrorShow({ state: true, message: data.message });
            }
        } catch (error) {
            setErrorShow({ state: true, message: "Failed to fetch appointments." });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatTime = (timeString) => {
        const date = new Date(timeString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Time Offs</Typography>
            </Grid>
            <Grid item my={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    <Typography style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>
                    <Typography style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Appointment</Typography>
                    <Typography style={{ color: "#dc3545", fontSize: "14px ", fontFamily: "Poppins" }}>{tabLabels[tabIndex]} Appointments</Typography>
                </Breadcrumbs>
                <Grid item style={{ marginTop: '10px' }}>
                    <Tabs value={tabIndex} onChange={handleChangeTab}>
                        {tabLabels.map((label, index) => (
                            <Tab key={index} label={label} />
                        ))}
                    </Tabs>
                </Grid>
                <CustomSearchInput style={{ marginTop: '10px' }} name={"search"} value={searchValue} placeholder={"Search:"} onChange={handleSearchBox} />
            </Grid>
            <Grid item>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: '16px' }}>Title</TableCell>
                                <TableCell style={{ fontSize: '16px' }}>Staff Name</TableCell>
                                <TableCell style={{ fontSize: '16px' }}>Start Date</TableCell>
                                <TableCell style={{ fontSize: '16px' }}>End Date</TableCell>
                                <TableCell style={{ fontSize: '16px' }}>From Time</TableCell>
                                <TableCell style={{ fontSize: '16px' }}>To Time</TableCell>
                                {/* <TableCell style={{ fontSize: '16px' }}>Status</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timeOff.map((record) => (
                                <TableRow key={record._id}>
                                    <TableCell>{record.title}</TableCell>
                                    <TableCell>{record.staffName}</TableCell>
                                    <TableCell>{new Date(record.startDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{new Date(record.endDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{formatTime(record.fromTime)}</TableCell>
                                    <TableCell>{formatTime(record.toTime)}</TableCell>
                                    {/* <TableCell>{record.status}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    );
}

export default TimeOff;