import {Button, Grid, TextField ,Typography} from "@mui/material";
import React, {useState} from "react";
import PhoneInput from "react-phone-input-2";
import {useDispatch} from "react-redux";
import {handleTenantBioData} from "../../../features/tenantsDataSlice";
import CustomSelectField from "../../School/CustomSelectField";
import {useLocation, useNavigate} from "react-router-dom";
import SetWorkFlow from "../Utility/updateSetupWorkFlow";

const breadcrumb = [
	<Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
	Dashboard
	</Typography>,
	<Typography key={"add"} sx={{color:"#707070", fontSize:"0.875rem"}}>
	Create Tenants
	</Typography>,
	<Typography key={"add"} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
	Tenant Details
	</Typography>

]

export default function TenantBioData (props) {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [customPhone, setPhone] = useState('')
	const [customerType, setCustomerType] = useState('')

	//Company
	const [custCompName, setCompanyName] = useState('')
	
	const customerOption = [
		{value:"Company", label:"Company"},
		{value:"Individual", label:"Individual"},
	]
	
	//redux actions
	const dispatch = useDispatch()
	const navigate = useNavigate()
	// userPhone
	//const userPhone = customPhone
	const userPhone = "+" + customPhone
	const companyName = custCompName.split(' ').join('')
	const bioData = {companyName, firstName, lastName, email, userPhone , customerType}
	
	const handleAddHouseClick = () => {
		dispatch(handleTenantBioData(bioData))
		props.setFlagStateHouse('addHouse')
	}

	function handleSkipTenant () {
		SetWorkFlow({flowName : 'complete', token : localStorage.getItem('X-Authorization')})
		navigate("/dashboard")
	}
	
	function handleCancel () {
		props.setFlagStateBack()
	}
	
	const handleGoBackClick = () => {
		try {
			props.setFlagGoBack('addHouse')
		} catch (e) {
			props.setFlagStateBack()
		}
		
	}

	const loc = useLocation()
	const isTenants = loc?.pathname?.includes("/tenants")

	//console.log('companyName ====>', custCompName);

	return (
		<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} mt={3} spacing={3}>
			
			{/*Header*/}
			<Grid item>
				<span style={{fontSize : '16px', fontWeight : '600', color : '#032541'}} >Add Tenant Details</span>
			</Grid>

			{/*Customer Type & Email*/}
			<Grid item>
				<Grid container direction={'row'} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'} spacing={3}>
					{/*Customer Type*/}
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '400px',height:"3.45rem" , fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={customerType} onChange={(e) => {setCustomerType(e.target.value)}} name={"Customer Type"} placeholder={"Select tenant Type"} options={customerOption} />
					</Grid>

					{/*Email*/}
					<Grid item>
						<TextField size={'medium'} value={email} onChange={(e) => {setEmail(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px'}} id="outlined-basic" label="Email" variant="outlined" required />
					</Grid>

				</Grid>
			</Grid>

			{/*First & Last name*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>

					{/*First Name || Company Name*/}
					{
						customerType === 'Company' ?

							// companyName if Company
							<Grid item>
								<TextField value={custCompName} onChange={(e) => {setCompanyName(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px', }} id="outlined-basic" label="Company Name" variant="outlined" required />
							</Grid> :

							// FirstName if Individual
							<Grid item>
								<TextField value={firstName} onChange={(e) => {setFirstName(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px', }} id="outlined-basic" label="First Name" variant="outlined" required />
							</Grid>

					}

					{/*Last Name || Company Name*/}
					{
						customerType === 'Company' ?

							// Phone if Company
							<Grid item>
								<PhoneInput inputStyle={{height : '55px', width : '400px'}} required country={"ke"} enableSearch={true} value={customPhone} onChange={(phone) => setPhone(phone)}/>
							</Grid> :

							// lastName if individual
							<Grid item>
								<TextField value={lastName} onChange={(e) => {setLastName(e.target.value)}} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px', }} id="outlined-basic" label="Last Name" variant="outlined" required />
							</Grid>
					}

				</Grid>
			</Grid>

			{/* New Phone*/}
			{
				customerType === 'Company' ? null :
					<Grid item>
						<Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>
							{/*Phone*/}
							<Grid item>
								<PhoneInput inputStyle={{height : '55px', width : '400px'}} required country={"ke"} enableSearch={true} value={customPhone} onChange={(phone) => setPhone(phone)}/>
							</Grid>
						</Grid>
					</Grid>
			}

			{/*Button*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'flex-end'} spacing={3}>
					
					{/*Cancel*/}
					<Grid item>
						<Button onClick={() => {handleGoBackClick()}} style={{border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}} >
							Cancel
						</Button>
					</Grid>

					{/*Next*/}
					{/*Validation Check*/}
					{
						customerType === 'Company' ?
							<Grid item>
								<Button
									disabled={!custCompName.trim() || !email.trim() || (!customPhone.trim() || customPhone.length < 12) || !customerType.trim()}
									onClick={handleAddHouseClick} style={{
										opacity : !custCompName.trim() || !email.trim() || (!customPhone.trim() || customPhone.length < 12) || !customerType.trim() ? "0.5" : "1",
										textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
									Next
								</Button>
							</Grid> :
							<Grid item>
								<Button
									disabled={!firstName.trim() || !lastName.trim() || !email.trim() || (!customPhone.trim() || customPhone.length < 12)|| !customerType.trim()}
									onClick={handleAddHouseClick} style={{
										opacity : !firstName.trim() || !lastName.trim() || !email.trim() || (!customPhone.trim() || customPhone.length < 12)|| !customerType.trim() ? "0.5" : "1",
										textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
									Next
								</Button>
							</Grid>
					}

					{/*Skip*/}
					{/* {
						isTenants ? null :
							<Grid item>
								<Button onClick={handleSkipTenant} style={{border : "solid 1px #032541", textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
									Skip
								</Button>
							</Grid>
					} */}

				</Grid>
			</Grid>
		</Grid>
	)
}
