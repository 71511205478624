import UnitsDashBoard from "./units/dashboard";
import { Breadcrumbs, Button, ButtonBase, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "../../School/CustomSearchInput";
import CustomTable from "../../School/CustomTable";
import { useSelector } from "react-redux";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import HttpComponent from "../../School/MakeRequest";
import AddUnit from "../../Rental/components/Units/addUnit";

// Bread Crumbs
const breadcrumbs = [
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Number Of Units</Typography>
];

//occupiedUnits
const columns = [
	{ field: 'unitType', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >House Type</strong>) } },
	{ field: 'unitPurpose', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Unit Purpose</strong>) } },
	{ field: 'unitTypeCount', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Total Units</strong>) } },
	{ field: 'unitTypePrefix', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Prefix</strong>) } },
	{
		field: 'occupiedUnits', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Occupied</strong>) }, renderCell: (params) => {
			return (
				<>
					<span>{params.row.occupiedUnits}</span>
				</>
			)
		}
	},
	{
		field: 'unOccupiedUnits', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >UnOccupied</strong>) }, renderCell: (params) => {
			return (
				<>
					<span>{params.row.unOccupiedUnits}</span>
				</>

			)
		}
	},
	{
		field: 'instalmentAmount', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Installment Amount</strong>) }, renderCell: (params) => {
			return (
				<>
					<span>{params.row.unitPurpose === 'Sell' ? params.row.instalmentAmount : 0}</span>
				</>
			)

		}
	},
	{ field: 'billingPeriod', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Billing Period</strong>) }, renderCell: (params) => { return params.row.billingPeriod } },
	{ field: 'unitCost', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Unit Cost</strong>) }, renderCell: (params) => { return (CurrencyFormatter(params.value, 'KES')) } },
]

export default function Units(props) {
	try {
		// Handle Add Units
		const [flagState, setFlagState] = useState("")
		const [isFromAddUnit, setIsFromAddUnit] = useState(false)

		function handleAddUnits() {
			setFlagState('addUnits')
			setIsFromAddUnit(true)
		}

		function handleHome() {
			setFlagState("")
		}

		const navigate = useNavigate()
		const { X_Authorization } = useSelector((store) => store.user)

		// Data
		const [data, setData] = useState('')

		// Loader Setter
		const [loader, setLoader] = useState(false)

		// Search functionality
		const [dataToShow, setDataToShow] = useState([]);
		const [formData, setFormData] = useState({ search: "" })


		const handleInputChange = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value, }); };

		const setSearchData = () => {
			let searchable = [...data]
			if (formData.search !== "") {
				let result = searchable.filter(unit =>
					unit.unitType.toLowerCase()?.includes(formData.search) ||
					unit.unitTypePrefix?.includes(formData.search)
				);
				setDataToShow(result);
			} else {
				setDataToShow(data)
			}
		}

		// Fetch Units
		function getUnits() {
			try {
				HttpComponent({
					method: 'GET',
					url: `/api/get_unit_types_by_business_unit_config?limit=1000&page=1`,
					token: X_Authorization
				}).then((data) => {
					if (data.status === 201) {
						setData(data.response.data)
					} else {
						console.log(`Error getting Units`)
					}

				}).catch((e) => {
					console.log(`Error getting transaction BreakDown`, e.message)
				})
			} catch (e) {
				console.log(`Error getting Units`, e.message)
			}
		}

		// Use Effect
		// Search UseEffect
		useEffect(() => {
			setSearchData()
		}, [formData.search, data]);

		// get units UseEffect
		useEffect(() => {
			getUnits()
		}, [flagState])

		return (
			<div>

				{flagState === "" ?
					<div>

						{/*Header Container*/}
						<Grid container justifycontent={'flex-start'} mt={0}>

							{/*back Image*/}
							<Grid item>
								<ButtonBase onClick={() => { navigate("/dashboard") }}>
									<ArrowBackIosIcon style={{ color: '#707070', fontSize: '35px' }} />
								</ButtonBase>
							</Grid>

							{/*Month Name*/}
							<Grid item>
								<span style={{ color: '#032541', fontWeight: 'bold', fontSize: '25px' }}>Number Of Units</span>
							</Grid>
						</Grid>

						{/*BreadCrumb Container & Add More units*/}
						<Grid container justifyContent={'space-between'} mt={3} mb={3}>
							<Grid item>
								<Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
									{breadcrumbs}
								</Breadcrumbs>
							</Grid>

							{/*Add Unit*/}
							<Grid item>
								<Button onClick={handleAddUnits} style={{ padding: '5px', textTransform: 'none', color: '#fff', background: '#032541', height: '45px', fontSize: '16px', fontWeight: '600' }}>
									Add More units
								</Button>
							</Grid>
						</Grid>

						{/*Display Dashboard*/}
						<UnitsDashBoard />

						{/*Search and Export Container*/}
						<Grid container justifyContent={'space-between'} mt={4}>

							{/*SearchBox*/}
							<Grid item>
								<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:" />
							</Grid>

							{/*Export button*/}
							<Grid item>
								{/*	TODO : Add Export Button*/}
							</Grid>
						</Grid>

						<CustomTable fontSize={'10px'} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />
					</div> : flagState === 'addUnits' ? <AddUnit isFromAddUnit={isFromAddUnit} setFlagStateBack={() => setFlagState("")} /> : null
				}
			</div>
		)
	} catch (e) {
		console.log(`Something Went Wrong With Units`, e.message)
	}
}