import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
// import "../styles/NavbarStyles.css";
import { motion } from "framer-motion";


const SearchBar = () => {
  const [isInputVisible, setInputVisible] = useState(false);

  const toggleInput = () => {
    setInputVisible(!isInputVisible);
  };

  return (
    <div sx={{ alignItems: "center" }}>
      {!isInputVisible && (
        <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
          <SearchIcon
            cursor={"pointer"}
            onClick={toggleInput}
          />
        </motion.div>
      )}
      {isInputVisible && (
        <TextField
          label="Search.."
          //   sx={{height: '50px'}}
          size="small"
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment onClick={toggleInput}>
                <ClearIcon cursor={"pointer"} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};

export default SearchBar;
