import React, { useState, useEffect } from "react";
import StockOrderCategories from "./stockOrderCategories";
import StockOrderProducts from "./stockOrderProducts";
import { Button, Grid } from "@mui/material";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { stockStyles } from "../stockStyling";
import { useNavigate } from "react-router-dom";
const StockOrderProductsAndCategory = ({ storeId, supplierId }) => {

    const navigate = useNavigate()
    const [categoryId, setCategoryId] = useState("")
    const [supplierBsNo, setSupplierBsNo] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [productValue, setProductValue] = useState("")

    const navigateToBatch = () => {
        navigate(`/stockOrder/viewBatch?${storeId}?${supplierId}`)
    }

  return (
    <div style={{width:"100%"}}>
        
        <Grid container width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} margin={"0% 0% 2% 0%"}>
        <Grid item xs={4}>
          <SearchFilter placeholder={'Search Categories'} setInputValue={setInputValue} />
        </Grid>
        <Grid item  sx={{ width: "50%", display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        <SearchFilter placeholder={'Search Products'} setInputValue={setProductValue} />
          <Button style={stockStyles?.cancelButton} onClick={() => navigate(`/stockOrder/newOrder`)}>Cancel</Button>
          <Button style={stockStyles?.viewBatchButton} onClick={() => navigateToBatch()}>
            View Batch
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StockOrderCategories inputValue={inputValue} storeId={storeId} supplierId={supplierId} setCategoryId={setCategoryId} setSupplierBsNo={setSupplierBsNo} />
        </Grid>

        <Grid item xs={8}>
          <StockOrderProducts  productValue={productValue} categoryId={categoryId} supplierId={supplierId} supplierBsNo={supplierBsNo} />
        </Grid>
      </Grid>
    </div>
  );
};

export default StockOrderProductsAndCategory;
