import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  TextField,
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Autocomplete,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomSelectField from "../../School/CustomSelectField";

let baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Students
  </Typography>,
  <Typography key={"student"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Student Profile
  </Typography>,
  <Typography key={"contacts"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Edit Student Profile
  </Typography>,
];

const EditStudentDetailsForm = () => {

  const itemNo = decodeURIComponent(window.location.search).slice(1)
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [year, setYear] = useState("Select Grade");
  const [term, setTerm] = useState("Select Term");
  const [currentStudent, setCurrentStudent] = useState();
  const [courseName, setCourseName] = useState(currentStudent?.courseId);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [parentPhone, setStudentPhone] = useState("")
  const [parentEmail, setParentEmail] = useState("")
  const [id_number, setIdNumber] = useState("")
  const [courseSearch , setCourseSearch] = useState("")
  const [selectedCourse, setSelectedCourse] = useState(() => {
    if (currentStudent?.courseId && currentStudent?.courseName) {
      return { value: currentStudent.courseId, label: currentStudent.courseName };
    }
    return null;
  });
  // const [coursesOptions, setCoursesOptions]=useState([]);
  console.log(courseName, 'current student')

  const { userId, X_Authorization } = useSelector((store) => store.user);

  const { schoolTypeName, schoolTypeId } = useSelector(
    (store) => store.schoolType.schoolTypeDetail
  );
  console.log("School Type Name", schoolTypeName);

  console.log("School Type Id", schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(
      `${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Authorization": X_Authorization,
        },
      }
    );
    const data = await response.json();
    console.log(data, "Grades");

    if (response.status === 201) {
      setGradeOptions(
        data.data.map((itemGrade) => {
          return {
            value: itemGrade.schoolGrades,
            label: itemGrade.schoolGrades,
          };
        })
      );
    }
  };


   // get courses

   const [courses, setCourses] = useState([])

   const getAllCourses = async () => {
       const studentCourseDetails = await fetch(baseUrl + `/api/v1/getUnitsInCourse?searchValue=${courseSearch}`, {
           method: "GET",
           headers: {
               "Content-Type": "application/json",
               Accept: "application/json",
               "X-Authorization": localStorage.getItem("X-Authorization"),
           },
       }
       );

       if (studentCourseDetails?.status === 200) {
           await studentCourseDetails.json().then((data) => {
               // console.log(data , 'hoes')
               setCourses(data?.data?.map((course)=>({
                 value:course?._id,
                 label:course?.courseName
               })))
           })
       }
   }

   useEffect(() => {
    getAllCourses();
   }, [courseSearch])

   console.log(courses , 'courses')
  const getCourses = async () => {
    const studentCourseDetails = await fetch(baseUrl + "/api/v1/getCourses", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    }
    );
    if (studentCourseDetails.status === 200) {
      await studentCourseDetails.json().then((data) => {
        console.log("student courses", data.data[0].schoolCourses)
        const Courses = data.data[0];
        console.log("set courses", Courses.schoolCourses);

        if (Courses && Courses.schoolCourses) {
          // Convert Courses.schoolCourses to an array if it's not already
          const schoolCoursesArray = Array.isArray(Courses.schoolCourses)
            ? Courses.schoolCourses
            : [Courses.schoolCourses];

          const courseOptions = schoolCoursesArray.map((course) => ({
            value: course.courseName,
            label: course.courseName,
          }));

          setCourseOptions(courseOptions);
        } else {
          // Handle the case where Courses.schoolCourses is missing or not defined
          console.error("Courses.schoolCourses is missing or not defined");

        }
      })
    }
    else {
      console.error("there was an error fetching student courses");
    }
  }
  const GetStreamsTerms = async () => {
    const response = await fetch(
      `${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Authorization": X_Authorization,
        },
      }
    );
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(
        data.data.map((terms) => {
          return { value: terms.schoolGrades, label: terms.schoolGrades };
        })
      );
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreamsTerms();
    }
  }, [schoolTypeId]);

  useEffect(() => {
    if (studentCourses) {
      setCourseName(studentCourses.courseName);
    }
    getCourses();
  }, [studentCourses]);


  const fetchStudentData = async () => {
    let url = baseUrl + "/api/getBillableItems";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({ customerId }),
    });
    const data = await response.json();
    if (response.status === 200) {
      console.log("students data =====<><><>", data);
      const theStudent = data.data.find(
        (student) => student.itemNumber === itemNo
      );
      console.log("current student is here", theStudent);
      setCurrentStudent(theStudent);
    }
  };
  useEffect(() => {
    console.log("item number", itemNo);
    fetchStudentData();
  }, [itemNo]);
  useEffect(() => {
    if (currentStudent) {
      setFirstName(currentStudent.firstName);
      setLastName(currentStudent.lastName);
      setCourseName(currentStudent.courseId);
      setTerm(currentStudent.term);
      setYear(currentStudent?.grade);
      setStudentPhone(currentStudent.parentPhone)
      setParentEmail(currentStudent.parentEmail)
      setIdNumber(currentStudent.id_number)
      // parentEmail
    }
  }, [currentStudent]);

  console.log(year , 'j')

  let formData = {
    studentNumber: itemNo,
    courseId:courseName,
    courseName: courses?.find((course)=>course.value === courseName)?.label,
    firstName:firstName,
    year:year,
    lastName:lastName,
    term:term,
    phoneNumber: parentPhone,
    email: parentEmail,
    id_number:id_number
  };

  console.log(formData, "form data edit_student_details");

  const handleSubmit = async () => {
    const endpoint = `${baseUrl}/api/edit_student_details`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify(formData),
    };

    try {
      const response = await fetch(endpoint, requestOptions);
      if (response.ok) {
        //  show a success message
        console.log("Data updated successfully");
        navigate(-1);
      } else {
        //an error message
        console.error("Error updating data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  console.log(courseName, "form data ===>>>><<<>>>");

  return (
    <>
      <Typography variant="h4" align="left" gutterBottom>
        Edit student
      </Typography>
      <br />
      <Box component="div" sx={{}}>
        <Breadcrumbs
          separator={
            <FiberManualRecordIcon
              sx={{
                fontSize: "0.625rem",
                fontFamily: "Poppins",
                color: "#e1e5e8",
              }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
        <br />
        <h5>Edit Student Details</h5>
        <br />
      </Box>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              value={firstName}
              onChange={(e) => { setFirstName(e.target.value) }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              value={lastName}
              onChange={(e) => { setLastName(e.target.value) }}

            />
          </Grid>
          <Grid item xs={5} >
            <CustomInputLabel required={true} label={"Semester"} />
            <CustomSelectField
              style={{ width: "100%" }}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              name={"term"}
              placeholder={"Select Term"}
              options={termOptions}
            />
          </Grid>
          <Grid item xs={5} >
            <CustomInputLabel required={true} label={"Year"} />
            <CustomSelectField
              style={{ width: "100%" }}
              value={year}
              onChange={(e) => setYear(e.target.value)}
              name={"grade"}
              placeholder={"Select Grade"}
              options={gradeOptions}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              label="Email"
              name="Email"
              fullWidth
              value={parentEmail}
              onChange={(e) => { setParentEmail(e.target.value) }}

            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              label="Phone Number"
              name=""
              fullWidth
              value={parentPhone}
              onChange={(e) => { setStudentPhone(e.target.value) }}

            />
          </Grid>

          <Grid item xs={5} >
            <CustomInputLabel required={true} label={"Course Name"} />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={courses?.find(course => course?.value === courseName) || null}
              onChange={(e, newValue) => {
                setSelectedCourse(newValue);
                setCourseName(newValue ? newValue?.value : null) }}
              options={courses}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              inputValue={courseSearch} onInputChange={(e, newInputValue) => {setCourseSearch(newInputValue);}}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField  {...params} label="Course Name" />}
          />
          </Grid>
          <Grid item xs={5} >
            <CustomInputLabel required={true} label={"ID Number"} />
            <TextField
              label="ID Number"
              name=""
              fullWidth
              value={id_number}
              onChange={(e) => { setIdNumber(e.target.value) }}

            />
          </Grid>


        </Grid>
        <br />
        <br />
        <Button
          style={{
            width: "7.813rem",
            height: "2.813rem",
            textTransform: "none",
            borderRadius: "4px",
            marginRight: "20px",
            border: "solid 1px #dc3545",
            color: "#dc3545",
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          style={{
            width: "120px",
            height: "45px",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "500",
            backgroundColor: "#032541",
            borderRadius: "5px",
            color: "white",
          }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </form>
    </>
  );
};

export default EditStudentDetailsForm;
