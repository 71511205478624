import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Stack, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from "@mui/icons-material/Replay";
import DangerousIcon from "@mui/icons-material/Dangerous";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { handleItemClick } from "../../../../features/actionSlice";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PeopleIcon from '@mui/icons-material/People';

export default function PartnersDropdown() {
  const dispatch = useDispatch();

  const handleClick = (clicked) => {
    dispatch(handleItemClick(clicked)); //setting the clicked item in redux
  };

  const currentTab = useSelector((state) => state.currentTab.tab);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* <ThemeProvider theme={theme}> */}
          <Button variant="text" {...bindTrigger(popupState)}>
            <MoreVertIcon sx={{ color: "#032541" }} />
          </Button>
          {/* </ThemeProvider> */}
          <Menu {...bindMenu(popupState)}>
            {currentTab === "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Edit");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <EditIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Edit Partner
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab === "Inactive" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Activate");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <ReplayIcon color={"success"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Activate Partner
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab === "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Deactivate");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <DangerousIcon color={"error"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Deactivate Partner
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab === "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("addUser");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <AddCircleOutlineIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Add User
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab === "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("viewUsers");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <PeopleIcon color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      View Users
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab === "Inactive" && (
              <MenuItem
                onClick={() => {
                  handleClick("Delete");
                  popupState.close();
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <HighlightOffIcon color={"error"} />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                  >
                    Delete Partner
                  </Typography>
                </Stack>
              </MenuItem>
            )}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
