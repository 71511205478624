import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Modal, Select, Stack, styled, Tab, TablePagination} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {CheckCircle, Circle, Close} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
let baseUrl = process.env.REACT_APP_BASE_URL
import deactivateUser from "../../../common/images/deactivate-user.svg"
import ReactPaginate from "react-paginate";
import "./schoolCus.css"

const AntTabs = styled(TabList)({
    borderBottom: "3px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#dc3545",
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "2.75",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
});

const SchoolCustomers = (props) => {
    const [tabValue, setTabValue] = useState("all");
    const classes = useStyles();
    const [customerData, setCustomerData] = useState([]);
    const [parentData, setParentData ] = useState([]);
    const [guardianData, setGuardianData] = useState([]);
    const [allCurrentPage, setAllCurrentPage] = useState(0);
    const [parentCurrentPage, setParentCurrentPage] = useState(0);
    const [guardianCurrentPage, setGuardianCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const allOffset = allCurrentPage * itemsPerPage;
    const parentOffset = parentCurrentPage * itemsPerPage;
    const guardianOffset = guardianCurrentPage * itemsPerPage;
    const allCurrentPageItems = customerData?.slice(allOffset, allOffset + itemsPerPage);
    const parentPageItems =  parentData?.slice(parentOffset, parentOffset + itemsPerPage);
    const guardianPageItems =  guardianData?.slice(guardianOffset, parentOffset + itemsPerPage);
    const setNewValue = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
        setAllCurrentPage(0);
        setParentCurrentPage(0);
        setGuardianCurrentPage(0);
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "KES",
        }).format(value);
    const fetchCustomers = async () => {
        const productResponse = await fetch(
            baseUrl + "/api/customers_list?status=ACTIVE",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );

        if (productResponse.status === 200) {
            const customerData = await productResponse.json();
            console.log("customer data", customerData)
            setCustomerData(customerData.data)
        }
    }

    const suspendCustomer = async (customerId) => {
        const productResponse = await fetch(
            baseUrl+ "/api/suspendCustomer?customerId="+customerId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        ).then(()=>{
            fetchCustomers()
        });
    }

    const fetchParents = async () => {
        const productResponse = await fetch(
            baseUrl + "/api/get_all_parents?status=Active",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );

        if (productResponse.status === 200) {
            const customerData = await productResponse.json();
            console.log("parent data", customerData)
            setParentData(customerData.data)
        }
    }

    const fetchGuardians = async () => {
        const productResponse = await fetch(
            baseUrl + "/api/get_all_guardians?status=Active",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );

        if (productResponse.status === 200) {
            const customerData = await productResponse.json();
            console.log("guardian data", customerData)
            setGuardianData(customerData.data)
        }
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
        });
    }


    useEffect(() => {
        fetchCustomers();
        fetchParents();
        fetchGuardians()
    }, []);
    return (
        <>
            <Grid container marginBottom="10px" >
                <Grid item style={{width:"100%"}}>
                    <TabContext value={tabValue}>
                        <Box>
                            <AntTabs onChange={setNewValue}>
                                <AntTab value="all" label="Parents/Guardians" />
                                <AntTab value="Parents" label="Parents" />
                                <AntTab value="Guardians" label="Guardians" />
                            </AntTabs>
                        </Box>


                        <TabPanel value="all">
                            <div style={{
                                height:"350px",
                                minWidth:"1000px"
                            }}>
                                <div style={{
                                    display:"flex"
                                }}>
                                    <div style={{ marginRight: '10px' }}>Items per page:</div>
                                    <Select
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        style={{ fontSize: '14px', padding: '4px', height:"30px" }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </div>

                                <Table className={classes.table} aria-label="students data grid">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Full Name</TableCell>
                                            {/*<TableCell>Role</TableCell>*/}
                                            <TableCell>Mobile Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Created On</TableCell>
                                            <TableCell>Pending Invoices</TableCell>
                                            <TableCell>Pending Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allCurrentPageItems.map((customer) =>(
                                            <TableRow key={customer._id}>
                                                <TableCell> <Link to={`/parent/${customer._id}`}>{customer.customerName}</Link></TableCell>
                                                {/*<TableCell>{customer.role}</TableCell>*/}
                                                <TableCell>{customer.mobileNumber}</TableCell>
                                                <TableCell>{customer.email}</TableCell>
                                                <TableCell>{formatDate(customer.createdOn)}</TableCell>
                                                <TableCell>{customer.pendingInvoices}</TableCell>
                                                <TableCell>{numberFormat(customer.pendingAmount)}</TableCell>
                                                <TableCell style={{
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    cursor:"pointer"
                                                }}>
                                                    <div>
                                                        <img src={deactivateUser} alt=""/>
                                                    </div>
                                                    <div onClick={()=>suspendCustomer(customer._id)}>
                                                        Remove
                                                    </div>

                                                </TableCell>
                                            </TableRow>

                                        ))}

                                    </TableBody>
                                </Table>

                                <div style={{
                                    fontSize:"14px"
                                }}>Total Records: {customerData.length}</div>

                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={Math.ceil(customerData.length / itemsPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={(data) => {
                                        setAllCurrentPage(data.selected);
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />

                            </div>

                        </TabPanel>

                        <TabPanel value="Parents">
                            <div style={{
                                height:"350px",
                                minWidth:"1000px"
                            }}>
                                <div style={{
                                    display:"flex"
                                }}>
                                    <div style={{ marginRight: '10px' }}>Items per page:</div>
                                    <Select
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        style={{ fontSize: '14px', padding: '4px', height:"30px" }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </div>

                                <Table className={classes.table} aria-label="students data grid">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Mobile Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Created On</TableCell>
                                            <TableCell>Pending Invoices</TableCell>
                                            <TableCell>Pending Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {parentPageItems.map((customer) =>(
                                            <TableRow key={customer._id}>
                                                <TableCell> <Link to={`/parent/${customer._id}`}>{customer.customerName}</Link></TableCell>
                                                <TableCell>{customer.role}</TableCell>
                                                <TableCell>{customer.mobileNumber}</TableCell>
                                                <TableCell>{customer.email}</TableCell>
                                                <TableCell>{formatDate(customer.createdOn)}</TableCell>
                                                <TableCell>{customer.pendingInvoices}</TableCell>
                                                <TableCell>{numberFormat(customer.pendingAmount)}</TableCell>
                                                <TableCell style={{
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    cursor:"pointer"
                                                }}>
                                                    <div>
                                                        <img src={deactivateUser} alt=""/>
                                                    </div>
                                                    <div onClick={()=>suspendCustomer(customer._id)}>
                                                        Remove
                                                    </div>

                                                </TableCell>
                                            </TableRow>

                                        ))}

                                    </TableBody>
                                </Table>

                                <div style={{
                                    fontSize:"14px"
                                }}>Total Records: {parentData.length}</div>

                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={Math.ceil(parentData.length / itemsPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={(data) => {
                                        setParentCurrentPage(data.selected);
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />

                            </div>
                        </TabPanel>

                        <TabPanel value="Guardians">
                            <div style={{
                                height:"350px",
                                minWidth:"1000px"
                            }}>
                                <div style={{
                                    display:"flex"
                                }}>
                                    <div style={{ marginRight: '10px' }}>Items per page:</div>
                                    <Select
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        style={{ fontSize: '14px', padding: '4px', height:"30px" }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </div>
                                <Table className={classes.table} aria-label="students data grid">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Mobile Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Created On</TableCell>
                                            <TableCell>Pending Invoices</TableCell>
                                            <TableCell>Pending Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {guardianPageItems.map((customer) =>(
                                            <TableRow key={customer._id}>
                                                <TableCell> <Link to={`/parent/${customer._id}`}>{customer.customerName}</Link></TableCell>
                                                <TableCell>{customer.role}</TableCell>
                                                <TableCell>{customer.mobileNumber}</TableCell>
                                                <TableCell>{customer.email}</TableCell>
                                                <TableCell>{formatDate(customer.createdOn)}</TableCell>
                                                <TableCell>{customer.pendingInvoices}</TableCell>
                                                <TableCell>{numberFormat(customer.pendingAmount)}</TableCell>
                                                <TableCell style={{
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    cursor:"pointer"
                                                }}>
                                                    <div>
                                                        <img src={deactivateUser} alt=""/>
                                                    </div>
                                                    <div onClick={()=>suspendCustomer(customer._id)}>
                                                        Remove
                                                    </div>

                                                </TableCell>
                                            </TableRow>

                                        ))}

                                    </TableBody>
                                </Table>
                                <div style={{
                                    fontSize:"14px"
                                }}>Total Records: {guardianData.length}</div>
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={Math.ceil(guardianData.length / itemsPerPage)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={(data) => {
                                        setGuardianCurrentPage(data.selected);
                                    }}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </TabPanel>

                    </TabContext>
                </Grid>
            </Grid>
        </>
    )
}

export {SchoolCustomers}
