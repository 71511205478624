import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";

const CustomTableSkeleton = ({ length = 5, numberOfRows = 4 }) => {
  return (
    <TableBody sx={{ width: "100%" }}>
      {Array.from({ length: length }).map((_, index) => {
        return (
          <TableRow>
            {Array.from({ length: numberOfRows }).map((_, index) => {
              return (
                <TableCell>
                  <Skeleton width={"100%"} animation="wave" />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default CustomTableSkeleton;
