import { Box, Breadcrumbs, Button, Grid, Typography, TextField, InputAdornment, Select, MenuItem, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from '../CustomSearchInput';
import CustomSelect from '../CustomSelectField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from '../ExportMenu';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import CustomTable from '../CustomTable';
import HttpComponent from '../MakeRequest';
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../../No Rows/noRowsOverlay';
import RecipientModule from './recipientModule';
import GradeCourseNotifications from './gradeCourse';
import { useSelector, useDispatch } from "react-redux";

// import { Tooltip } from "@material-ui/core";



const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Messaging Service</Typography>
]
const breadcrumbsrecipients = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Messaging Service</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Recipients</Typography>

]
const containerStyle = {
    display: 'flex',
    border: '1px solid #ccc', // Customize border style
    borderRadius: '4px',
    height: "55px",
    // width: "20%",
    marginTop: "10px",
};

const inputStyle = {
    flex: 1,
    border: 'none', // Remove individual input borders
    padding: '18px', // Adjust padding as needed
    outline: 'none', // Remove input outline
};
const MessagingActivity = () => {
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = React.useState([null, null]);
    const {schoolTypeName, schoolTypeId} = useSelector((store) => store.schoolType.schoolTypeDetail)

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const [search, setSearch] = useState("");
    const [selectRecipient, setSelectRecipient] = useState("");
    const [optionsRecipient, setOptionsRecipient] = useState([
        { value: "1", label: "All" },
        { value: "2", label: "Option 2" },
        { value: "3", label: "Option 3" },
    ]);
    const [dataGridPageSize, setPageSize] = useState(10);
    const [notification, setNotification] = useState([]);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })


    const handleCreateMessage = () => {
        navigate("/school/create/message")

    }

    const getNotification = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpComponent({
            method: "GET",
            url: `/api/v1/get_business_sent_notifications?page=${pageState?.page}&limit=${dataGridPageSize}&search=${search}`,
            token: localStorage.getItem("X-Authorization")
        }).then((response) => {

            if (response.status === 200) {
                // setNotification(response.response.data)
                let dataToReturn = response?.response?.data?.map((item) => {
                    return {
                        ...item, gradeOrCourseLength: item?.courses?.length || item?.grades?.length
                    }
                })

                setPageState({ ...pageState, isLoading: false, total: response?.response?.count, data: dataToReturn })

            }
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        getNotification()
    }, [pageState.page, dataGridPageSize,])
    //    "year": 2023,
    // "gradeOrCourseLength": 1,
    // "recipientLength": 1,
    // "subject": "Hello",
    // "message": "\nDear Parent, annual increment of 10% on the school fees will be applicable effective from the beginning of first term 15/01/2024."
    //  
    const [notificationId, setNotificationId] = useState("")

    const handleRecipientView = (id) => {
        console.log(id, "id")
        setNotificationId(id)
        navigate(`/messaging/service?recipient`)
    }

    const handleGradeView = (id) => {
        console.log(id, "id")
        setNotificationId(id)
        if(schoolTypeName === "University/College"){
            navigate(`/messaging/service?course`)

        }else{
            navigate(`/messaging/service?grade`)

        }
    }
    const queryparams = decodeURIComponent(window.location.search);

    const stateUrl = queryparams.slice(1)
    const columns = [
        {
            field: "createdAt",
            headerName: "Date",
            renderHeader: () => {
                return (
                    <span style={{ fontSize: "15px", fontWeight: "700" }}>Date</span>
                );
            },
            flex: 1,
            renderCell: (params) => {
                return (
                    <span style={{ fontSize: "15px" }}>{new Date(params?.row?.createdAt).toLocaleString()}</span>
                );
            }
        },
        {
            field: "gradeOrCourseLength", headerName: "Grade/Course", renderHeader: () => {
                return (
                    <span style={{ fontSize: "15px", fontWeight: "700" }}>Grade/Course</span>
                );
            }, flex: 1, renderCell: (params) => (<span style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => handleGradeView(params?.row?._id)}>{params?.row?.gradeOrCourseLength}</span>)
        },
        {
            field: "recipientLength", headerName: "Recipients", flex: 1,
            renderHeader: () => {
                return (
                    <span style={{ fontSize: "15px", fontWeight: "700" }}>Recipients</span>
                );
            },
            renderCell: (params) => (<span style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => handleRecipientView(params?.row?._id)}>{params?.row?.recipientLength}</span>)
        },
        {
            field: "title", headerName: "Subject", flex: 1, renderHeader: () => {
                return (
                    <span style={{ fontSize: "15px", fontWeight: "700" }}>Subject</span>
                );
            }, renderCell: (params) => (<span style={{ fontSize: "15px" }}>{params?.row?.title}</span>)
        },
        {
            field: "message",
            headerName: "Message",
            renderHeader: () => {
                return (
                    <span style={{ fontSize: "15px", fontWeight: "700" }}>Message</span>
                );
            },
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params?.row?.message} arrow>
                    <Typography>{params?.row?.message}</Typography>
                </Tooltip>
            ),
        },


    ]





    return (
        <Grid>
            {stateUrl === "recipient" ? (
                <RecipientModule notificationId={notificationId} />
            ) : 
            stateUrl === "grade" || stateUrl === "course" ? (
                <GradeCourseNotifications notificationId={notificationId} />
            ) :
            (
                <Grid>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                        <Grid>
                            <Grid item mt={1}>
                                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Messaging Service</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Grid>
                            <Grid item mt={1}>
                                <Typography style={{ fontWeight: 600, fontSize: "20px", color: "#032541" }}>Messaging History</Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "space-between" }}>

                            <Grid sx={{ justifyContent: "flex-end", marginRight: "20px" }}>
                                <Grid item>
                                    <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", fontFamily: "Poppins", fontSize: "12px", fontWeight: 600, borderRadius: "5px", padding: "15px 20px" }} onClick={() => handleCreateMessage()}>Create Message</Button>
                                </Grid>
                            </Grid>


                        </Grid>

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                        <Grid style={{ width: "25%" }}>
                            <TextField
                                disabled={false}
                                name={"search"}
                                value={""}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={"Search"}
                                variant="outlined"
                                margin="dense"
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    sx: {
                                        width: '100% !important',
                                        height: '3.438rem',
                                        fontSize: "0.875rem",
                                        "& fieldset": {
                                            borderColor: "#bec5d1"
                                        }
                                    },
                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                }}

                            />

                        </Grid>
                        <Grid style={{ width: "15%" }}>
                            <Select
                                name={"select"}
                                value={""}
                                onChange={(e) => setSelectRecipient(e.target.value)}
                                placeholder={"Select Recipient"}
                                variant="outlined"
                                margin="dense"
                                displayEmpty
                                style={{ width: '100%' }}
                                sx={{ marginBottom: 2, marginTop: 1 }}
                                inputProps={{ height: '3.438rem', fontSize: "0.875rem", "& ::placeholder": { color: '#bec5d1' }, "& fieldset": { borderColor: "#bec5d1" } }}
                                MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" }, getcontentanchorel: null }}
                                selectprops={{
                                    renderValue: (value) => {
                                        if (value === "") {
                                            return <em>{"select"}</em>;
                                        }
                                        return value;
                                    }
                                }}

                            >
                                {optionsRecipient.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={containerStyle}>
                                <DateRangePicker
                                    startText=""
                                    endText=""
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <input {...startProps} style={inputStyle} placeholder="Start Date" />
                                            <span style={{ margin: '0 10px' }}>to</span>
                                            <input {...endProps} style={inputStyle} placeholder="End Date" />
                                        </>
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                        <Select
                            name={"select"}
                            value={""}
                            onChange={(e) => setSelectRecipient(e.target.value)}
                            placeholder={"Select Recipient"}
                            variant="outlined"
                            margin="dense"
                            displayEmpty
                            style={{ width: '20%' }}
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            inputProps={{ height: '3.438rem', fontSize: "0.875rem", "& ::placeholder": { color: '#bec5d1' }, "& fieldset": { borderColor: "#bec5d1" } }}
                            MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" }, getcontentanchorel: null }}
                            selectprops={{
                                renderValue: (value) => {
                                    if (value === "") {
                                        return <em>{"select"}</em>;
                                    }
                                    return value;
                                }
                            }}

                        >
                            {optionsRecipient.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                        <Grid style={{ marginTop: "10px" }}>
                            <ExportMenu />
                        </Grid>




                    </Box>
                    <Grid>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '600px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={columns}
                            rows={pageState.data}
                            getRowId={row => row?._id} />
                        {/* <CustomTable columns={columns} data={notification} rowsPerPage={20} /> */}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default MessagingActivity