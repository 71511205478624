import { Typography, Breadcrumbs, Grid, TextField, Button ,Switch } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HttpComponent from "../School/MakeRequest";

//breadcrumps
const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Configurations</Typography>
];

export default function EditConfig (props) {

    const paymentReminder = props?.paymentReminder
    const myConfigId = props?.configId
  

    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const { X_Authorization } = useSelector((store) => store.user)
    const [dueDay, setDueDay] = useState('')
    const [newReminder, setNewReminder] = useState(null)
    const [checked ,setChecked] = useState(false)

    useEffect(() => {

        if(paymentReminder){
            setNewReminder(false)
        }
        if(!paymentReminder){
            setNewReminder(true)
        }

    },[paymentReminder])


    // onchange
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    //post a config edit


    function postConfigEdit() {

        let responseBody = {
            period: dueDay,
            status: newReminder
        }

        try {
            HttpComponent({
                method: "POST",
                url: `/api/updateRentalConfiguration?id=${myConfigId}`,
                body: responseBody,
                token: X_Authorization

            }).then((data) => {

                if (data.status === 202) {
                    setSuccessShow({ state: true, message: 'Success Updating payment configuration' })
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                } else {
                    setErrorShow({ state: true, message: 'Error updating payment configuration' })
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                }
            })

        } catch (error) {

        }

    }




    return (
        <div>
             <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <Grid item>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography variant="h6">Set Invoice Reminder Interval </Typography>
            </Grid>

            {/* edit invoice reminder */}
            <Grid item flex mt={3}>
                <Typography variant="h6" style={{display:'flex' , alignItems:"center"}}>
                        Enable Invoice Reminder
                        <Switch 
                            sx={{marginLeft:"80px"}}
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                </Typography>
            </Grid>
            {checked ? 
            <>
                <Grid item mt={3} mb={2}>
                        <TextField  value={dueDay} required onChange={(e) => setDueDay(e.target.value)} style={{width:"300px"}}  label="Invoice Reminder Interval" id="fullWidth" />
                    </Grid>
                    <Grid item mt={5}>
                        <Button style={{border:"1px solid #cb444a" ,width:"141px" ,marginRight:"10px" ,textTransform:"inherit" ,color:"#cb444a"}}>Cancel</Button>
                        <Button onClick={postConfigEdit} style={{width:"141px" ,background:"#032541" ,textTransform:"inherit" ,color:"#fff"}}>Update</Button>
                </Grid>
            </>
           : null}
        </div>
    )
}