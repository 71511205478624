import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VoidTransactionsTable from "../components/Reports/VoidTransactionsTable";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../../features/searchSlice";
import Papa from "papaparse";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

function VoidTransactions() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.transactions);

  const handleExport = () => {
    exportArrayToCSV(exportData, "Void Transactions.csv");
  };
  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Void Transactions
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Void Transactions" />
        <Button
          variant="outlined"
          sx={{
            bgcolor: "#f5f6f7",
            borderColor: "#f5f6f7",
            color: "#032541",
            ":hover": {
              borderColor: "#032541",
            },
            textTransform: "none",
          }}
          onClick={handleExport}
        >
          Export
          <MoreVertIcon
            sx={{
              color: "#032541",
            }}
          />
        </Button>
      </Stack>
      <Box width={"100%"}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <TextField
            id="input-with-icon-textfield"
            label="Search by transaction Id"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearch}
            className={classes.focusedTextField}
          />
        </Stack>
        <Stack>
          <VoidTransactionsTable />
        </Stack>
      </Box>
    </Stack>
  );
}

export default VoidTransactions;
