import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    description: "",
    noofbusiness:'' ,
    partnerBusinessesType:'',
    insuranceCompany:''
};

const partnerToUpdateSlice = createSlice({
  name: "partnerDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.noofbusiness = action.payload?.noofbusiness;
      state.partnerBusinessesType = action.payload.partnerBusinessesType;
      state.insuranceCompany = action.payload.insuranceCompany
    },

  
  },
});

export const { setDetails } = partnerToUpdateSlice.actions;

export default partnerToUpdateSlice.reducer;
