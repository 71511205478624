import { Box, Button, Typography } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import { useEffect, useState } from "react";
import {isValidPhoneNumber,parsePhoneNumber} from 'libphonenumber-js'
import { useDispatch } from "react-redux";
import { addParent } from "../../features/customerSlice";
import PhoneInput from "react-phone-input-2";
import GeoLocation from "../../hooks/useLocation";


export default function NewParentForm(props) {
    const [phoneNumberTwo, setPhoneNumberTwo] = useState('')
    const [isValidPhone, setIsValidPhone] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [countrySelected, setCountrySelected] = useState("KE")
    const [validEmail, setValidEmail] = useState(true);
    const handleInputChangeTwo = (e) => {
        setFormDataTwo({
            ...formDataTwo,
            [e.target.name]: e.target.value,
        });
    };

    let localCurrency = localStorage.getItem("localCurrency")
    const [phoneCurrencyCode, setPhoneCurrency] = useState("ke")

    useEffect(() => {
        if(!localCurrency) {
         setPhoneCurrency("ke")
        }
        if(localCurrency === "UGX") {
         setPhoneCurrency("ug")
        }
        if(localCurrency === "NGN") {
         setPhoneCurrency("ng")
        }
        if(localCurrency === "USD") {
         setPhoneCurrency("us")
        }
        if(localCurrency === "KES") {
         setPhoneCurrency("ke")
        }
     },[localCurrency])


    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" }
    ]

    const dispatch = useDispatch();
    const [formDataTwo, setFormDataTwo] = useState(
        {
            firstName: "",
            lastName: "",
            gender: "",
            email: "",
            mobileNumber: "",
            address: "",
            id_number: ""
        }
    )
    const buttonStyles =
    {
        "minWidth": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const doneButtonStyles = {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        "color": "white",
        "marginLeft": 2,
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    useEffect(() => {
        if (phoneNumberTwo !== '') {
            try {
                const parsedph = parsePhoneNumber(`${phoneNumberTwo}`,countrySelected.toUpperCase());
                const validp = parsedph.number
                const isp = isValidPhoneNumber(validp)
                if(isp){
                    setIsValidPhone(true)
                }else{
                    setIsValidPhone(false)
                }
            } catch (error) {
                if (error.message === 'TOO_SHORT') {
                    setIsValidPhone(false);
                } else {
                    console.error("Parsing error:", error);
                }
            }
        } else {
            setIsValidPhone(false);
        }
    }, [phoneNumberTwo]);

    useEffect(() => {
        if (formDataTwo.email != "") {
            let result = validateEmail(formDataTwo.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formDataTwo.email]);


    const submitTwo = () => {
        let newObject2 = {
            firstName: formDataTwo.firstName,
            lastName: formDataTwo.lastName,
            paymentType: "Prepaid",
            phone: "+" + phoneNumberTwo,
            amountReceived: 0,
            customerAddress: formDataTwo.address,
            businessId: "",
            customerType: "Individual",
            email: formDataTwo.email,
            gender: formDataTwo.gender
        }
        dispatch(addParent(newObject2));
        console.log('Parent2 data', newObject2)
        props.setAddAnother(true)
        props.setView("student")
    }

    const getGeolocation = (e) => {
        setFormDataTwo({...formDataTwo, address: e?.description})
    }
    return (
        <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h4 style={{ fontSize: "16px", fontWeight: "600", color: "#707070", textAlign: "center" }}>Add Secondary Parent/Guardian (Optional)</h4>
        <Box component="div" sx={{ display: "flex" }}>
            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                <CustomInputLabel required={true} label={"First Name"} />
                <CustomTextField value={formDataTwo.firstName} onChange={handleInputChangeTwo} name={"firstName"} placeholder={"First Name"} />

                <CustomInputLabel required={true} label={"Gender"} />
                <CustomSelectField value={formDataTwo.gender} onChange={handleInputChangeTwo} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                {/*<CustomTextField value={formData.gender} onChange={handleInputChangeTwo} name={"gender"} placeholder={"Select Gender"} />*/}

                <CustomInputLabel required={true} label={"Email"} />
                {!validEmail ?
                    <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                        Please enter a valid email
                    </Typography>
                    :
                    null
                }
                <CustomTextField value={formDataTwo.email} onChange={handleInputChangeTwo} name={"email"} placeholder={"Email"} />
            </Box>


            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                <CustomInputLabel required={true} label={"Last Name"} />
                <CustomTextField value={formDataTwo.lastName} onChange={handleInputChangeTwo} name={"lastName"} placeholder={"Last Name"} />

                <CustomInputLabel required={true} label={"Mobile Number"} />
                <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                    {!isValidPhone ?
                        <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                            Phone number is Invalid
                        </Typography>
                        :
                        null
                    }
                    <PhoneInput inputStyle={{ height: '3.438rem' }}  required country={phoneCurrencyCode} enableSearch={true} value={phoneNumberTwo} name={"mobileNumber"}
                     onChange={(phone ,countryData)=>{
                        setPhoneNumberTwo(phone)
                        setCountrySelected(countryData.countryCode);
                        setCountryCode(countryData.dialCode);
                        }}
                      />
                </Box>
                <GeoLocation selectedValue={formDataTwo.address}  onValueChange={getGeolocation} sx={{width:"51rem", mb:4}} />
            </Box>
        </Box>

        <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end" }}>
            <Box component="div">
                <Button sx={{ ...buttonStyles, marginRight: 1, color: "red", '&:hover': { backgroundColor: "transparent", color: 'red' } }} onClick={() => props.setView("student")}>
                    Skip
                </Button>
                <Button sx={cancelButtonStyles} onClick={() => props.setView("initial")}>
                    Cancel
                </Button>

                <Button
                    sx={doneButtonStyles}
                    onClick={submitTwo}
                    disabled={!formDataTwo.firstName.trim()
                        || !formDataTwo.lastName.trim()
                        || !formDataTwo.gender.trim()
                        || !formDataTwo.email.trim() 
                        || !phoneNumberTwo 
                        || isValidPhone === false
                        || !formDataTwo.address.trim()
                    }
                >
                    Next
                </Button>
            </Box>

        </Box>
    </Box>
    )
}