import React, { useState, useEffect } from "react";
import { Grid, Checkbox, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { creditnotestyles } from "../creditNoteStyling/styless";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlertRightAligned, ErrorAlertRightAligned } from "../../bills/startorderModals/modals";

const Pendingcreditnote = () => {
  // get pending creditnotes;
  const [pendingCreditNotes, setPendingcreditNotes] = useState([]);
  const [loading, setLoading] = useState(true)
  const [pageSize, setPageSize] = useState(5)
  const getPendingcreditnotes = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_credit_note_by_status?limit=${pageSize}&page=1&status=pending`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {

        if(data.status === 200){
            const { results } = data.response;
        setPendingcreditNotes(results);
        setLoading(false)
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isTrue = false;

    !isTrue ? getPendingcreditnotes() : null;
    return () => {
      isTrue = true;
    };
  }, []);

  //   approve credit notes;
  const [creditNotes, setCreditNotes] = useState([]);

  const getCreditNoteDetails = (creditDetails) => {
    const newCreditNotes = [...creditNotes];
    const currentIndex = newCreditNotes.indexOf(creditDetails.creditNotes);
    if (currentIndex === -1) {
      newCreditNotes.push(creditDetails.creditNotes);
    } else {
      newCreditNotes.splice(currentIndex, 1);
    }
    setCreditNotes(newCreditNotes);
  };


  // approve credit Note;
  const [sucess, setSucess] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState();

  const approveCreditNote = () => {
    HttpComponent({
      method: "POST",
      url: `/api/action_credit_notes`,
      body: { creditNotes: creditNotes, action: "approved" },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {

        if (data.status === 200) {
          setSucess(true);
          setCreditNotes([]);
          setAutoRefresh(true)
        }

        setTimeout(() => {
          setSucess(false);
          
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isTrue = true;
    !isTrue ? approveCreditNote() : null
    return () => {
      isTrue = false;
    };
  }, []);


//   decline credit Note;
const [error, setError] = useState(false)
const declineCreditNote = () => {
    HttpComponent({
        method:"POST",
        url:`/api/action_credit_notes`,
        body: { creditNotes: creditNotes, action: "declined" },
        token:localStorage.getItem("X-Authorization")
    }).then((data) => {
      
            if (data.status === 200) {
                setError(true)
                setCreditNotes([]);
                setAutoRefresh(true)
              }
      
              setTimeout(() => {
                setError(false);
              }, 2000);
    
    })
}

useEffect(() => {
    let isTrue = true
    !isTrue ? declineCreditNote() : null

    return () => {
         isTrue = false;
    }
}, [])

//   autoRefresh Page;
  useEffect(() => {

    let isTrue = true
    getPendingcreditnotes()
    return () => {
        isTrue = false;
        setAutoRefresh(false)
    }
  },[autoRefresh])
  return (
    <>
      <DataGrid
        style={creditnotestyles.dataGridStyling}
        columns={[
          { field: "creditNoteNo", headerName: "Credit Note No", flex: 1 },
          { field: "createdByName", headerName: "Created By", flex: 1 },
          { field: "dateCreated", headerName: "Created On", flex: 1 },
          { field: "amount", headerName: "Total Amount", flex: 1 },
          {
            field: "Action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
              return (
                <Grid>
                  <Checkbox
                    color="success"
                    onClick={() =>
                      getCreditNoteDetails({
                        id: params.row.id,
                        creditNotes: params.row.creditNoteNo,
                      })
                    }
                  />
                </Grid>
              );
            },
          },
        ]}
        rows={pendingCreditNotes.map((creditNote, index) => ({
          id: creditNote.creditNoteId,
          creditNoteNo: creditNote.creditNoteNo,
          createdByName: creditNote.createdByName,
          dateCreated:
            new Date(creditNote.dateCreated).toDateString() +
            " " +
            new Date(creditNote.dateCreated).toLocaleTimeString(),
          amount: creditNote.amount,
        }))}
        pageSize={5}
        rowsPerPageOptions={[5]}
        pagination
        loading={loading}
      />

      {creditNotes.length > 0 && (
        <Grid container direction={"row"} justifyContent={"flex-end"}>
          <Button
           onClick={() => creditNotes.length > 0 ? declineCreditNote() : null}
            style={creditnotestyles.declineButton}
          >
            Decline {creditNotes.length}{" "}
          </Button>
          <Button style={creditnotestyles.approveButton}  onClick={() =>
              creditNotes.length > 0 ? approveCreditNote() : null
            }>
            Approve {creditNotes.length}
          </Button>
        </Grid>
      )}

      {/* sucesss modals */}
      {sucess && (
        <SuccessAlertRightAligned
          message={"Credit Note approved succcessfully!"}
          sucess={sucess}
        />
      )}

      {/* error modal */}
      {error && <ErrorAlertRightAligned message={'credit note declined'} error={error} /> }
    </>
  );
};

export default Pendingcreditnote;



// components={{NoRowsOverlay : NoRowsOverlay}}
// sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "10px", color : '#272d3d',boxShadow: 0, border: 0}}
// rowsPerPageOptions={[5, 10, 20, 50, 100]}
// rowCount={pageState.total}
// loading={pageState.isLoading}
// pagination
// page={pageState.page - 1}
// pageSize={dataGridPageSize}
// paginationMode="server"
// onPageChange={(newPage) => {setPageState((old) => ({...old, page: newPage + 1, pageSize: dataGridPageSize}));}}
// onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
// columns={addStockColumns}
// rows={products}
// cellRenderer={() => {return (1+1 === 2 ? <div>x</div>: '')}}
// getRowId={(row) => row.id}
