import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AccountingModal} from "../../components/Modals/Accounting/accountingModal";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Accounting (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const [accountingModal, setAccountingModal] = useState(false);
	const [accountingData, setAccountingData] = useState([]);
	const handleAccountingClose = () => setAccountingModal(false);

	const getConfig = async () => {
		const response = await fetch(accountConfigsUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Authorization": localStorage.getItem("X-Authorization"),
			},
		});
		const res = await response.json();
		setAccountingData(res.data);
	};

	useEffect(() => {
		getConfig()
			.then(() => {console.log('Success Getting Account Configs')})
			.catch((e) => {console.log('Error Getting Account Configs', e.message)})
	}, []);

	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')

	return (
		<div>

			{/*Collapsed Users*/}
			{/*<Collapse in={open} timeout="auto" unmountOnExit>*/}
			{/*	<List component="div" disablePadding>*/}

					{userGroup === 'Merchant' ?
						<div>
							{accountingData?.accountingState ? (
								<Link style={{textDecoration: 'none', color : '#fff'}} to="#">
									<ListItem button className={classes.nested}>
										<ListItemText onClick={() => {setAccountingModal(true)}} primary="QuickBooks" />
										<span style={{ color: "#07f51f" }}>Connected</span>
									</ListItem>
								</Link>
								) : (
											<span style={{ color: "#dc3545", marginLeft:'32px' }}>Disconnected</span>
									)
							}

							<AccountingModal accountingOpen={accountingModal} onClose={handleAccountingClose} accountingData={accountingData} />
						</div> : null}
				{/*</List>*/}
			{/*</Collapse>*/}
		</div>
	)
}