import { Stack } from '@mui/material'
import React from 'react'
// import MyResponsiveLine from './testChart'


function TestPage() {
  return (
    <Stack height={'500px'} width={'100%'}>
      {/* <MyResponsiveLine /> */}
    </Stack>
  )
}

export default TestPage