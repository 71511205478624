import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import CustomSelect from "../customSelect";
import StockFunctions from "../stockEndpoints";
import { stockStyles } from "../stockStyling";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Viewsupplierandwarehouse = (props) => {
  const navigate = useNavigate();
  const { wareHouseState } = useSelector((store) => store.sidebar);
  const [warehouses, setwarehouses] = useState([{ name: "warehouse", _id: 1 }]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseStatus, setWarehouseStatus] = useState();
  const [storeToAdd, setStoreToAdd] = useState();
  const setComponentToShow = props?.setComponentToShow;

  const wareHouseOnStoreType = [
    { name: "", _id: "" },
    { name: "Warehouse", _id: "Warehouse" },
    { name: "Mainstore", _id: "MainStore" },
  ];

  const warehouseOffStoreType = [
    { name: "", _id: "" },
    { name: "Mainstore", _id: "MainStore" },
  ];

  const addNewSupplierData = [{ name: <span onClick={() => navigate(`/addsupplier/addsupplier`)}>Add New Supplier</span>, _id: "new supplier" }];

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    stockFunctions.getSuppliers(1, 1000, "ACTIVE").then((data) => {
      setSuppliers(data?.response?.data);
    });
  }, []);

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if (storeToAdd)
      stockFunctions.findStoresByType(storeToAdd, 1, 1000).then((data) => {
        setwarehouses(data?.response?.data);
      });
  }, [storeToAdd]);

  useEffect(() => {
    props?.setStoreId(warehouseId);
  }, [warehouseId]);

  useEffect(() => {
    props?.setSupplierId(supplierId);
  }, [supplierId]);

  

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"}>
        <span style={{ margin: "0px 0px 2% 0px" }}>Add Stock</span>

        {wareHouseState && (
          <Grid item width={"30%"} margin={"0px 0px 3% 0px"}>
            <CustomSelect suppliers={suppliers.length > 0 ? suppliers : addNewSupplierData} setItemId={setSupplierId} selectLabel={"Supplier"} setSupplierName={props?.setSupplierName} />
          </Grid>
        )}

        {wareHouseState === false ? (
          <Grid item width={"30%"} margin={"0px 0px 3% 0px"}>
            <CustomSelect addStockStores={wareHouseState ? wareHouseOnStoreType : warehouseOffStoreType} setItemId={setStoreToAdd} selectLabel={"Add To"} setSupplierName={props?.setSupplierName} />
          </Grid>
        ) : wareHouseState === true && supplierId !== "new supplier" ? (
          <Grid item width={"30%"} margin={"0px 0px 3% 0px"}>
            <CustomSelect addStockStores={wareHouseState ? wareHouseOnStoreType : warehouseOffStoreType} setItemId={setStoreToAdd} selectLabel={"Add To"} setSupplierName={props?.setSupplierName} />
          </Grid>
        ) : null}

        {storeToAdd && (
          <Grid item width={"30%"} margin={"0px 0px 2% 0px"}>
            <CustomSelect warehouses={warehouses} setItemId={setWarehouseId} selectLabel={storeToAdd} setWarehouseName={props?.setWarehouseName} />
          </Grid>
        )}

        {storeToAdd && (
          <Grid item width={"30%"} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button style={warehouseId ? stockStyles.nextButtonActive : stockStyles.nextButtonInactive} onClick={() => (warehouseId ? navigate(`/addstock/categories?${warehouseId}?${supplierId}`) : null)}>
              Next
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Viewsupplierandwarehouse;
