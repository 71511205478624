import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import Business from "./user Dashboard Components/business"
import { Divider } from "@mui/material";
import DashLogo from "./images/dashlogo.png";
import Dashboard from "./user Dashboard Components/dashboard"
import { useSelector, useDispatch } from "react-redux";
import CustomerOnAccount from "./DashBoard Components/customers";
import Inventory from "./DashBoard Components/inventory";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PaymentSetup from "./user Dashboard Components/paymentSetup";
import House from "./user Dashboard Components/house";
import Report from "./DashBoard Components/report";
import SchoolReport from "./DashBoard Components/schoolreport";
import { ArrowBackIos } from "@mui/icons-material";
import { closeSideBar } from "../features/sideBarSlice";
import TenantConfiguration from "./DashBoard Components/tenantConfiguration";
import SchoolConfiguration from "./DashBoard Components/schoolconfiguration";
import Bills from "./DashBoard Components/bills";
import Expenditure from "./DashBoard Components/expenditure";
import Warehouse from "./DashBoard Components/warehouse";
import Users from "./DashBoard Components/users";
import GradesAndStreams from "./DashBoard Components/gradesStreams";
import PayitMenu from "./DashBoard Components/payitMenu";
import HttpComponent from "../components/School/MakeRequest";
import OtherBusinessConvenienceFee from "./DashBoard Components/convenienceSchool";
import Transactions from "./DashBoard Components/transactions";
import BankStatement from "./DashBoard Components/bankstatement";
import GeneralLedgerSidebar from "./DashBoard Components/generaledgerAcc";
import StudentPromotion from "../components/School/studentpromotion/studentPromotionmain";
import PayablesGeneralBusiness from "./DashBoard Components/payablesNavbar";
import { makeStyles } from "@mui/styles";
import UniversityStaff from "../components/School/studentpromotion/universityStaff";
import SessionReportStaff from "../components/School/sessionReport/sessionReportStaff";
import EnrolledUnitsStaff from "../components/School/units/enrolledUnitstaff";
import MessagingService from "./DashBoard Components/messagingService";
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import startOrderFunction from "../components/bills/billscomponents/startOrderEndpoints";
import StudentSponsorship from "./DashBoard Components/studentSponsorship";





const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		fontFamily: "Poppins",
		width:"100%",
		overflowX: "hidden",
	},
	drawer: {
		width: 291,
		color: "#fff",
		flexShrink: 0,
	},
	drawerPaper: {
		width: 291,
		color: "#fff",
		backgroundColor: "rgb(3,37,65)",
	},
	content: {
		flexGrow: 1,
		width:'100%',
		padding: theme.spacing(1),
	},
	nested: {
		color: "#fff",
		paddingLeft: theme.spacing(4),
	},
}));

export default function UserSidebar({ children , openMobileSideBar ,setOpenMobileSideBar}) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const businessCat = localStorage.getItem('businessCategory')
	const userGroup = localStorage.getItem('group')
	const { isSideBarOpen, isMobile } = useSelector((store) => store.sidebar);
	const [status, setStatus] = useState()
	const customerClassification = localStorage.getItem('customerClassification')
	const [isSponsorEnabled, setIsSponsorEnabled] = useState(false);
	const startOrderEndpoints = new startOrderFunction();


	const {schoolTypeName, schoolTypeId} = useSelector((store) => store.schoolType.schoolTypeDetail)
	
	let localCurrency = localStorage.getItem('localCurrency')

	if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
		localCurrency = 'KES'
	  }
	  


	let drawerStyles = {
		color: "#fff",
		width: "291px",
		flexShrink: 0,
	};

	if (isSideBarOpen === false) {
		drawerStyles = {
			color: "#fff",
			width: "0px",
			flexShrink: "0",
		};
	} else if (isSideBarOpen === true) {
		drawerStyles = {
			color: "#fff",
			width: "291px",
			flexShrink: "0",
		};
	} else {
		drawerStyles = {
			drawerColor: "#fff",
			drawerWidth: "291px",
			flexShrink: "0",
		};
	}

	const [payitStatus, setPayitStatus] = useState()

	function getPayitStatus() {
		try {
			HttpComponent({
				method: "GET",
				url: '/api/v1/getZedPayItStatus',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				//console.log(data , 'payit status')
				if (data.status === 200) {
					setPayitStatus(data?.response?.data)
				}
			})
		} catch (error) {
		}
	}

	useEffect(() => {
		getPayitStatus()
	}, [])


	const getWareHouseStatus = async () => {
		try {
			const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),

				},
			})
			const data = response.json()
				.then((response) => {
					// console.log('status getWareHouseStatus ', response.data.enabled);
					setStatus(response?.data?.enabled)
				})

		} catch (error) {


		}


	}
	const [payablesStatus, setPayablesStatus] = useState(false)
	useEffect(() =>{
		startOrderEndpoints.getBusinessInfo().then((data) => {
			if(data?.status === 200){
				setIsSponsorEnabled(data?.response?.data?.enableStudentSponsor)
			}
		})
	},[])

	function getPayablesStatus() {
		try {
			HttpComponent({
				method: "GET",
				url: '/api/v1/business/payables/status',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				// console.log(data, 'payables status')
				if (data.status === 200) {
					console.log(data?.response?.data.payablesStatus, 'payables status')
					let statusPayables = data?.response?.data?.payablesStatus
					setPayablesStatus(JSON.parse(statusPayables));
				}
			})
		} catch (error) {
			console.log(error)

		}
	}

	useEffect(() => {
		getPayablesStatus()
	}, []);

	useEffect(() => {
		getWareHouseStatus()

	}, [])

	const [accountStatus, setAccountStatus] = useState({})

	function getAccountingStatus() {
		try {
			HttpComponent({
				method: "POST",
				url: '/api/v1/check_accounting_status',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				if (data?.status === 200) {
					setAccountStatus(data?.response?.data)
				}
			})
		} catch (error) {
		}
	}

	useEffect(() => {
		getAccountingStatus()
	}, [])

	const { accountingEnabled } = accountStatus
	

	return (
		<div className={classes.root}>
			{/* {isMuiMobile && (
				<MenuIcon className={classes.menuButton} sx={{marginTop:isMuiMobile ? '15px':'' , fontWeight:700 , fontSize:'30px'}} onClick={handleDrawerToggle} />
			)} */}
			<Drawer open={openMobileSideBar} className={classes.drawer} variant={isMuiMobile ? 'temporary': 'permanent'} PaperProps={{
				sx: {
					backgroundColor: "rgb(3,37,65)", width: "291px", color: '#fff !important'
				}
			}}>
				{/*logo*/}
				<div align={"center"} style={{ marginTop: "5%", marginBottom: "5%" }}>
					<Link to={"/dashboard"} style={{ marginRight: "30%" }}>
						<img src={DashLogo} alt="Zed Logo" width="140px" height="70px" />
					</Link>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => {
							dispatch(closeSideBar({ sideBarState: false }));
							setOpenMobileSideBar(false)
							// setSideBarToggler(false)
						}}
					>
						<ArrowBackIos />
					</span>
				</div>
				<Divider />
				<List>
					{/*Dashboard*/}

					{(userGroup === "Cashier" ) ? null : <Dashboard />}

					{businessCat == 'School' && userGroup === "Director" || userGroup === "Merchant" || userGroup === 'ZPM School Admin' && <Users />}

					{/*Business*/}
					<Business userGroup={userGroup} />

					{/*Users*/}
					{(userGroup === "Director" || userGroup === 'Owner' || userGroup === "Merchant"  && (businessCat === "Rental" || businessCat === 'School' ) )   ?   <Users />:null}

					{/*Grades and courses*/}

					{userGroup !== "Customer" && userGroup !== 'User'  && userGroup !== "Cashier" && userGroup !== 'Accountant' && businessCat === "School" && <GradesAndStreams businessCat={businessCat} />}

					{/*Inventory*/}
					{userGroup != "Customer" && userGroup != 'User' && userGroup != "Cashier" && userGroup != "Caretaker" && <Inventory businessCat={businessCat} />}

					{/*Parents*/}

					{userGroup && userGroup != "Customer"  && userGroup != 'User' && userGroup != "Cashier" && <CustomerOnAccount />}

					{/* University Staff */}
					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director") && (businessCat === 'School') ? <UniversityStaff businessCat={businessCat} /> : null}

					{/* Student Promotion */}
					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director" || userGroup === 'ZPM School Admin') && (businessCat === 'School') ? <StudentPromotion businessCat={businessCat} /> : null}

					{/* Bank Statement */}
					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director" || userGroup === 'ZPM School Admin') && (businessCat === 'School') ? <BankStatement businessCat={businessCat} /> : null}

					{/*Houses Menu*/}

					{(userGroup == 'Merchant' && businessCat == 'Rental' || userGroup === "Caretaker") ? <House /> : null}
					{/* Payit */}

					{userGroup !== "Customer" && userGroup !== 'User' && userGroup !== "Cashier" && userGroup !== 'Accountant' && businessCat === "School" && payitStatus === true && <PayitMenu businessCat={businessCat} />}

					{/*Payment Setup*/}
					{(userGroup === "Customer" || userGroup === 'User' || userGroup === "Cashier" || userGroup === "Caretaker" || userGroup === 'Accountant' || userGroup === "Tenant" || userGroup === "ZPM School Admin") && (businessCat === 'Rental' || businessCat === 'School' || businessCat === null) ? null : <PaymentSetup />}


					{/* Convenience Fee */}
					{userGroup !== "Customer" && userGroup !== 'User' && userGroup !== "Cashier" && businessCat === "School" && localCurrency === 'UGX' && <OtherBusinessConvenienceFee businessCat={businessCat} />}


					{/* Units Enrolled  */}

					{(businessCat === 'School') && (customerClassification === 'TeachingStaff') ? <EnrolledUnitsStaff businessCat={businessCat} /> : null}
					{/* Session Report */}

					{(businessCat === 'School') && (customerClassification === 'TeachingStaff') ? <SessionReportStaff businessCat={businessCat} /> : null}

					


					{userGroup && userGroup != "Customer"  && userGroup != 'User' && userGroup != "Cashier" && isSponsorEnabled === true && <StudentSponsorship/>} 


					{userGroup && userGroup != "Customer" && userGroup != 'User' && userGroup != "Cashier" && payablesStatus === true  && <PayablesGeneralBusiness />}


					{/* General Ledger */}

					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director") && (businessCat === 'School') && accountingEnabled ? <GeneralLedgerSidebar businessCat={businessCat} /> : null}

					{userGroup != "Customer" && userGroup != 'User' && businessCat !== "Rental" && businessCat === "School" && status && <Bills businessCat={businessCat} />}
					{/* 
					{userGroup != "Customer" && userGroup !='User' && businessCat !== "Rental" && businessCat === "School" && status &&  <Expenditure businessCat={businessCat} />}
					{userGroup != "Customer" && userGroup !='User' && businessCat !== "Rental" && businessCat === "School" && status &&  <Warehouse businessCat={businessCat} />} */}

					{/*Transactions!*/}
					{(userGroup === "Customer" || userGroup === 'User' || userGroup === "Cashier" || userGroup === "Caretaker" || userGroup === "ZPM School Admin" || userGroup === "Tenant") && (businessCat === 'Rental' || businessCat === 'School' || businessCat === null) ? null : <Transactions />}


					{userGroup !== "Customer" && userGroup !== 'User' && userGroup !== "Cashier" && <SchoolReport businessCat={businessCat} />}
					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director") && (businessCat === 'School') ? <MessagingService businessCat={businessCat} /> : null}
					{userGroup != "Customer" && userGroup != 'User' && userGroup != "Cashier" && <SchoolConfiguration businessCat={businessCat} />}



					{/*Reports*/}
					{(userGroup == 'Merchant' && businessCat == 'Rental') ? <Report /> : null}
					{(userGroup == 'Merchant' && businessCat == 'Rental') ? <TenantConfiguration /> : null}


					{/*Footer*/}
					<div align={"center"} style={{ marginTop: "30%" }}>
						<span>Copyright ©{new Date().getFullYear()} Zed. All rights reserved.</span>
					</div>
				</List>
			</Drawer>
			<main className={classes.content}>{children}</main>
		</div>
	);
}
