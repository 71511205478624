import { Grid, InputLabel, Button, TextField, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PhoneInput from "react-phone-input-2";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState } from 'react'
import { SuccessAlert } from '../../snackBar Alerts/successAlert'
import { ErrorAlert } from '../../snackBar Alerts/errorAlert'
import { useNavigate } from 'react-router-dom'
import GeoLocation from '../../../hooks/useLocation';
import HttpComponent from '../../School/MakeRequest';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles Owner</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Vehicle Owner</Typography>,
]

export default function AddVehicleOwner() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ firstName: '', lastName: '', email: '', })
    const [phone, setPhone] = useState('')
    const [selectedValue, setSelectedValue] = useState(null)

    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    const navigate = useNavigate();

    function addVehicleOwner() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/addCustomer `,
                body: {
                    customerAddress: selectedValue?.description,
                    lastName: formData.lastName,
                    businessid: "",
                    customerClassification: "vehicleowner",
                    limitinstrument: "",
                    paymentType: "credit",
                    amountreceived: "0",
                    firstName: formData.firstName,
                    customerType: "individual",
                    phone: "+" + phone,
                    limit: "0",
                    email: formData.email,
                    status: "Active"
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1500)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }
    }
    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Vehicle Owner Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography>Vehicle Owner Details</Typography>
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>First Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.firstName} name='firstName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Last Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.lastName} name='lastName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Email</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.email} name='email' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Phone</InputLabel>
                <PhoneInput required country={"ke"} enableSearch={true} label={"Mobile Number"} value={phone} onChange={(phone) => setPhone(phone)} multiline inputStyle={{ height: "55px", width: "100%" }} />
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Address</InputLabel>
                <GeoLocation required={true} sx={{ width: "100%" }} selectedValue={selectedValue} onValueChange={(address) => setSelectedValue(address)} />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => addVehicleOwner()} disabled={!formData.firstName || !formData.email || !phone || !formData.lastName || !formData.firstName} style={!formData.firstName || !formData.email || !phone || !formData.lastName || !formData.firstName ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}