import {Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import {Button, ButtonBase, Card, Grid, IconButton, TextField} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import PhoneInput from "react-phone-input-2";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Mpesa from "../../../common/images/mpesa-icon.svg";
import CardImg from '../../../common/images/card-icon.svg'
import {MpesaStk} from "../../Modals/Invoice/mpesaStk";
import {PaymentSent} from "../../Modals/Invoice/paymentSent";
import HttpComponent from "../../School/MakeRequest";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";
import SendIcon from '@mui/icons-material/Send';

const baseUrl = process.env.REACT_APP_BASE_URL;
const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);

export default function TenantPaymentOption(props) {

	const navigate = useNavigate()

	const [successShow, setSuccessShow] = useState({state: false, message: ""})

	const [value, setValue] = useState()
	const [successOpen, setSuccessOpen] = useState(false);
	const [stkPushLoading, setStkPushLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [open, setOpen] = useState(false);

	const [mpesaPaymentOpen, setMpesaPaymentOpen] = useState(false);

	const [activeButton, setActiveButton] = useState('')
	const [userPhone, setUserPhone] = useState('')
	const [amount, setAmount] = useState('')

	const [initialBalance, setInitialBalance] = useState('')

	const [invoicePayments, setInvoicePayments] = useState();
	const [invoiceBalance, setInvoiceBalance] = useState();
	const [invoiceData, setInvoiceData] = useState()
	const [errorFetching, setErrorFetching] = useState(false);
	const { invoiceNumber } = useParams();

	const [bussinessStkId, setBussinessStkId] = useState('')

	// Success Notification
	const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
	const handleSuccessNotificationClick = () => {setSuccessNotificationOpen(true);};
	const handleSuccessNotificationClose = (event, reason) => {if (reason === 'clickaway') {return} setSuccessNotificationOpen(false);};

	// Success Notification
	const [successNotificationReceivedPaymentOpen, setSuccessNotificationReceivedPaymentOpen] = React.useState(false);
	const handleSuccessNotificationPaymentReceivedClick = () => {setSuccessNotificationReceivedPaymentOpen(true);};
	const handleSuccessNotificationReceivedPaymentClose = (event, reason) => {if (reason === 'clickaway') {return} setSuccessNotificationReceivedPaymentOpen(false);};


	// Error Notification
	const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
	const handleErrorNotificationClick = () => {setErrorNotificationOpen(true);};
	const handleErrorNotificationClose = (event, reason) => {if (reason === 'clickaway') {return}setErrorNotificationOpen(false);};

	// Error Notification
	const [errorNotificationTimeoutOpen, setErrorNotificationTimeoutOpen] = React.useState(false);
	const handleErrorNotificationTimeoutClick = () => {setErrorNotificationTimeoutOpen(true);};
	const handleErrorNotificationTimeoutClose = (event, reason) => {if (reason === 'clickaway') {return}setErrorNotificationTimeoutOpen(false);};

	// Error Notification
	const [errorNotificationNoBusinessConfigOpen, setErrorNotificationNoBusinessConfigOpen] = React.useState(false);
	const handleErrorNotificationNoBusinessConfigClick = () => {setErrorNotificationNoBusinessConfigOpen(true);};
	const handleErrorNotificationNoBusinessConfigClose = (event, reason) => {if (reason === 'clickaway') {return}setErrorNotificationNoBusinessConfigOpen(false);};


	const [mpesaLoading, setMpesaLoading] = useState(false)
	const [intervalId, setIntervalId] = useState(null);
	const {X_Authorization} = useSelector((store) => store.user)

	const intervalIdRef = useRef(null);

	const getStkConfigData = () => {
		HttpComponent({
			method : 'GET',
			url : '/api/get_daraja_config_id',
			token : X_Authorization
		}).then((data) => {
			console.log(`This is Response Data`, data)
			if (data.status === 201) {
				setBussinessStkId(data.response.data)
			} else if (data.response.message.includes('Please set Daraja Configuration')){
				handleErrorNotificationNoBusinessConfigClick()
				return console.log('Business Configuration Not Set')
			}
		}).catch((e) => {
			console.log(`Error Getting STK biz Config`)
		})
	}

	const handleClick = async (orderID) => {
		if (!userPhone) {
			console.error("PhoneNumber is not valid");
			setStkPushLoading(false);
		}
		//TODO: Currently hard coded to do stk push for AimSec
		setMpesaLoading(true);
		let businessID = bussinessStkId || "63e2231b620df9bb8076c525"
		let object = { amount, phone: userPhone, orderID: orderID, businessID: businessID };
		try {
			HttpComponent({
				method: "POST",
				url: "/api/pushstk",
				body: object,
			})
				.then((data) => {
					if (data.response.data.status === 200) {
						handleSuccessNotificationClick();
					} else {
						setMpesaLoading(false);
						console.error("Problem Processing StK Payment");
						handleErrorNotificationClick();
					}
				})
				.catch((e) => {
					setMpesaLoading(false);
					console.error(`Error Sending STK`, e.message);
				});
		} catch (error) {
			console.error(error);
			handleErrorNotificationClick();
		}
	};

	const getInvoiceDetails = async () => {
		try {
			const invoiceDetailsResponse = await fetch(baseUrl+`/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);

			if (invoiceDetailsResponse.status === 200) {
				const invoiceDetails = await invoiceDetailsResponse.json();
				setInvoiceData(invoiceDetails.data);
				setInitialBalance(invoiceDetails.data.invoiceBalance)
			}else{
				setErrorFetching(true)
			}
		} catch (error) {
			console.error("error fetching invoice details",error.message);
		}
	};

	const getInvoicePayments = async () =>{
		const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					'X-Authorization' : localStorage.getItem('X-Authorization')
				},
			}
		);
		if (invoicePaymentDetailsResponse.status === 200) {
			const paymentDetailsData = await invoicePaymentDetailsResponse.json();
			let {invoicePayments,invoiceBalance} = paymentDetailsData.data
			setInvoicePayments(invoicePayments);
			setInvoiceBalance(invoiceBalance);
		}
	}

	function handleButtonClick (name) {
		setActiveButton(name)
	}

	useEffect(() => {
		getInvoiceDetails().then(()=>console.log("done fetching invoice data"))
		getInvoicePayments().then(()=>console.log("payments fetched"))
		getStkConfigData()
	}, []);

	useEffect(() => {
		handleButtonClick(activeButton)
	}, [activeButton])

	useEffect(() => {
		if (intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
		}
		if (mpesaLoading) {
			intervalIdRef.current =
				setInterval(() => {
					getInvoicePayments();
					if (parseInt(initialBalance) !== parseInt(invoiceBalance)) {
						setInvoiceBalance(initialBalance)
						setMpesaLoading(false);
						handleSuccessNotificationPaymentReceivedClick()
						navigate(`/receipt/${invoicePayments[invoicePayments.length - 1].receiptNumber}/${invoiceNumber}`)
						clearInterval(intervalIdRef.current);
						intervalIdRef.current = null;
						setUserPhone('')
						setAmount('')
						console.log(initialBalance, 'initiaL BALANCE')
						console.log(invoiceBalance,  'INVOICE BALANCE')
						console.log(invoicePayments[invoicePayments.length - 1].receiptNumber)
						//navigate(`/receipt/${invoicePayments[invoicePayments.length - 1].receiptNumber}/${invoiceNumber}`)
					}
				}, 1000)
			setTimeout(() => {
				clearInterval(intervalIdRef.current);
				intervalIdRef.current = null;
				handleErrorNotificationTimeoutClick()
				setMpesaLoading(false);
			}, 35000);
		}
		return () => {
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		};
	}, [mpesaLoading, initialBalance, invoiceBalance]);

	return (
		<div>

			{/*Alerts*/}
			<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>

			{/*Success Creation*/}
			<SuccessAlert message={'You will receive a prompt on your phone'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />

			{/*Payment Received*/}
			<SuccessAlert message={`Payment received`} open={successNotificationReceivedPaymentOpen} onClose={handleSuccessNotificationReceivedPaymentClose} horizontal={'right'} vertical={'top'} />

			{/*error sending stk*/}
			<ErrorAlert message={`Error With Stk`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

			{/*error TimeOut*/}
			<ErrorAlert message={`Timeout On Awaiting Payment`} open={errorNotificationTimeoutOpen} onClose={handleErrorNotificationTimeoutClose} horizontal={'right'} vertical={'top'} />

			{/*Missing Business Config ID*/}
			<ErrorAlert message={`Missing Mpesa STK Config Id!`} open={errorNotificationNoBusinessConfigOpen} onClose={handleErrorNotificationNoBusinessConfigClose} horizontal={'right'} vertical={'top'} />

			{/*mainContainer*/}
			<Grid container direction={'row'} justifyContent={'space-between'}>

				{/*Labels*/}
				<Grid style={{width : '30%'}} item>

					{/*Label*/}
					<Grid container mb={7}>
						<Grid item>
							<span style={{color : '#032541', fontSize : '20px', fontWeight : '600'}} >Select Payment Method</span>
						</Grid>
					</Grid>

					{/*Cards*/}
					<Grid container direction={'column'} justifyContent={'flex-start'} spacing={7}>

						{/*Mpesa Card*/}
						<Grid item>
							<ButtonBase onClick={() => {handleButtonClick('mpesa')}}>
								<Card style={{background : `${activeButton === 'mpesa' ? 'rgba(23, 174, 123, 0.06)' : '#fff'}`, height : '94px', width : '365px', boxShadow : '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius : '6px'}}>
									<Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

										{/*mpesa image*/}
										<Grid item>
											<img src={Mpesa} alt="Mpesa" style={{height:"32px", width:"100px", marginTop : "30px", marginLeft : '15px'}}/>
										</Grid>

										{/*Mpesa name*/}
										<Grid item style={{marginTop : "30px"}}>
											<span style={{color : '#032541', marginLeft : '15px', fontSize : '18px', fontWeight : 600}}>Mpesa</span>
										</Grid>

									</Grid>
								</Card>
							</ButtonBase>
						</Grid>

						{/*Card*/}
						<Grid item>
							<ButtonBase onClick={() => {setSuccessShow({state : true, message : 'Coming Soon!'})}}>
								<Card style={{background : `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height : '94px', width : '365px', boxShadow : '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius : '6px'}}>
									<Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

										{/*Card image*/}
										<Grid item>
											<img src={CardImg} alt="Mpesa" style={{height:"32px", width:"100px", marginTop : "30px", marginLef : '0px'}}/>
										</Grid>

										{/*Card name*/}
										<Grid item style={{marginTop : "30px"}}>
											<span style={{color : '#032541', marginLeft : '15px', fontSize : '18px', fontWeight : 600}}>Card</span>
										</Grid>

									</Grid>
								</Card>
							</ButtonBase>
						</Grid>

					</Grid>

				</Grid>

				{/*Display Arena*/}
				{activeButton === 'mpesa' ?
					<Grid item style={{background : 'rgba(23, 174, 123, 0.06)', width : '60%', display : 'flex', flexShrink : 1, marginRight : '2.5%'}}>

						<Grid container direction={'column'} ml={1} spacing={3}>

							{/*Header lable*/}
							<Grid item mt={5} >
								<span style={{fontStyle : '16px', fontWeight : '600', color : '#032541'}} >Pay invoice-Mpesa</span>
							</Grid>

							{/*Invoice card Information*/}
							<Grid item>

								<Grid container direction={'row'} spacing={2}>
									<Grid item style={{width : '70%'}}>
										{/*PlyCard*/}
										<Grid container direction={'column'} spacing={0.5} style={{borderRadius : '10px',  background : 'rgba(112, 112, 112, 0.05)'}}>
											<Grid item>
												<span style={{color : '#707070', fontSize : '14px'}}>Invoice No:</span>
											</Grid>
											<Grid item>
												<span style={{color : '#032541', fontWeight : 600, fontSize : '18px'}}>{invoiceNumber}</span>
											</Grid>
										</Grid>
									</Grid>

									<Grid item style={{width : '30%'}}>
										{/*Amount*/}
										<Grid container direction={'column'} spacing={0.5} style={{borderRadius : '10px'}}>
											<Grid item>
												<span style={{color : '#707070', fontSize : '14px'}}>Invoice Balance:</span>
											</Grid>
											<Grid item>
												<span style={{color : '#dc3545', fontWeight : 600, fontSize : '18px'}}>{numberFormat(invoiceBalance)}</span>
											</Grid>
										</Grid>
									</Grid>

								</Grid>

							</Grid>

							{/*Mpesa Instruction*/}
							<Grid item mt={-2}>
								<span style={{fontSize : '14px'}} >Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
							</Grid>

							{/*Mobile input*/}
							<Grid item>
								<div style={{marginBottom : '10px'}}>
									<span style={{fontSize : '12px', color : '#032541'}}>Mobile no.</span>
								</div>

								<PhoneInput required inputStyle={{height : '55px', width : '500px'}} country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)}/>
							</Grid>

							{/*Amount Input*/}
							<Grid item>
								<div style={{marginBottom : '10px'}}>
									<span style={{fontSize : '12px', color : '#032541'}}>Amount.</span>
								</div>
								<TextField value={amount} onChange={(e) => {setAmount(e.target.value)}} type='number' InputProps={{style : {fontFamily : 'Poppins', background : '#fff'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541"}}} style={{width : '500px', border: "solid 0px #e4e4e4", fontSize : "12px"}} id="outlined-basic" label="Amount" variant="outlined" required />
							</Grid>

							{/*Send prompt button*/}
							<Grid item mt={10} mb={9}>
								<Grid container justifyContent={'center'} alignContent={'center'}>
									<Grid item>
										{mpesaLoading ?
											<LoadingButton sx={{width:"407px", backgroundColor:"#34A353", color:"white", '&:hover': {backgroundColor: '#34A353', color: 'white'}}}
												endIcon={<SendIcon />}
												loading={true}
												loadingPosition="end"
												variant="contained">
												<span>Awaiting Payment</span>
											</LoadingButton>:
											<Button onClick={() => {handleClick(invoiceNumber)}} style={{width : '407px', background : "#032541",textTransform : 'none', color : '#fff', fontWeight : 'bold', fontSize : '14px'}} >Send Prompt</Button>
										}
										</Grid>
								</Grid>

							</Grid>

						</Grid>

					</Grid> : activeButton === 'card' ?

					<Grid item style={{background : '#f0f2f3', width : '60%', display : 'flex', flexShrink : 1, marginRight : '2.5%'}}>

						{/*<Grid container direction={'column'} ml={1} spacing={3}>*/}
						{/*	*/}
						{/*	/!*Header lable*!/*/}
						{/*	<Grid item mt={5} >*/}
						{/*		<span style={{fontStyle : '16px', fontWeight : '600', color : '#032541'}} >Pay invoice-Mpesa</span>*/}
						{/*	</Grid>*/}
						{/*	*/}
						{/*	/!*Invoice card Information*!/*/}
						{/*	<Grid item>*/}
						{/*		*/}
						{/*		<Grid container direction={'row'} spacing={2}>*/}
						{/*			<Grid item style={{width : '70%'}}>*/}
						{/*				/!*PlyCard*!/*/}
						{/*				<Grid container direction={'column'} spacing={0.5} style={{borderRadius : '10px',  background : 'rgba(112, 112, 112, 0.05)'}}>*/}
						{/*					<Grid item>*/}
						{/*						<span style={{color : '#707070', fontSize : '14px'}}>Invoice No:</span>*/}
						{/*					</Grid>*/}
						{/*					<Grid item>*/}
						{/*						<span style={{color : '#032541', fontWeight : 600, fontSize : '18px'}}>INV00020001</span>*/}
						{/*					</Grid>*/}
						{/*				</Grid>*/}
						{/*			</Grid>*/}
						{/*			*/}
						{/*			<Grid item style={{width : '30%'}}>*/}
						{/*				/!*Amount*!/*/}
						{/*				<Grid container direction={'column'} spacing={0.5} style={{borderRadius : '10px'}}>*/}
						{/*					<Grid item>*/}
						{/*						<span style={{color : '#707070', fontSize : '14px'}}>Invoice Balance:</span>*/}
						{/*					</Grid>*/}
						{/*					<Grid item>*/}
						{/*						<span style={{color : '#dc3545', fontWeight : 600, fontSize : '18px'}}>KES 65,000.00</span>*/}
						{/*					</Grid>*/}
						{/*				</Grid>*/}
						{/*			</Grid>*/}
						{/*		*/}
						{/*		</Grid>*/}
						{/*	*/}
						{/*	</Grid>*/}
						{/*	*/}
							{/*/!*Mobile input*!/*/}
						{/*	<Grid item>*/}
						{/*		<div style={{marginBottom : '10px'}}>*/}
						{/*			<span style={{fontSize : '12px', color : '#032541'}}>Mobile no.</span>*/}
						{/*		</div>*/}
						{/*		*/}
						{/*		<PhoneInput inputStyle={{height : '55px', width : '500px'}} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)}/>*/}
						{/*	</Grid>*/}
						{/*	*/}
						{/*	/!*Amount Input*!/*/}
						{/*	<Grid item>*/}
						{/*		<div style={{marginBottom : '10px'}}>*/}
						{/*			<span style={{fontSize : '12px', color : '#032541'}}>Amount.</span>*/}
						{/*		</div>*/}
						{/*		<TextField value={amount} onChange={(e) => {setAmount(e.target.value)}} type='number' InputProps={{style : {fontFamily : 'Poppins', background : '#fff'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541"}}} style={{width : '500px', border: "solid 0px #e4e4e4", fontSize : "12px"}} id="outlined-basic" label="Amount" variant="outlined" required />*/}
						{/*	</Grid>*/}
						{/*	*/}
						{/*	/!*Send prompt button*!/*/}
						{/*	<Grid item mt={10} mb={9}>*/}
						{/*		<Grid container justifyContent={'center'} alignContent={'center'}>*/}
						{/*			<Grid item>*/}
						{/*				<Button style={{width : '407px', background : "#032541",textTransform : 'none', color : '#fff', fontWeight : 'bold', fontSize : '14px'}} >Send Prompt</Button>*/}
						{/*			</Grid>*/}
						{/*		</Grid>*/}
						{/*	*/}
						{/*	</Grid>*/}

						{/*</Grid>*/}
					</Grid> : null
				}
				{/*Mpesa payment*/}
				<PaymentSent successOpen={successOpen} onClose={()=>setSuccessOpen(false)}/>
			</Grid>

		</div>
	)
}
