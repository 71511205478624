import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Grid, Modal, Select, Stack, styled, Tab, TablePagination, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { CheckCircle, Circle, Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../../School/MakeRequest";
import { makeStyles } from '@mui/styles';
import CustomSearchInput from "../../School/CustomSearchInput";
import CustomSelect from "../../School/CustomSelectField";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CustomTable from "../../School/CustomTable";
import DateFormatter from "../../../utils/dateFormatter";
import { Link } from "react-router-dom";

let baseUrl = process.env.REACT_APP_BASE_URL
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const AntTabs = styled(TabList)({
    borderBottom: "3px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#dc3545",
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "2.75",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);

const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },
});


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Supplier/Vendor Invoices</Typography>,

]

const columns = [
    {
        field: 'invoiceNumber',
        headerName: 'Invoice Number',
        flex: 1,
        renderCell: (params) => {
            return (
                <Typography style={{ color: '#032541', cursor: 'pointer' }}>
                    <Link to={`/invoice/${params.value}`} style={{ color: '#032541', cursor: 'pointer' }}>{params.value}</Link>
                </Typography>
            )
        }
    },
    {
        field: 'supplierName',
        headerName: 'Supplier / Vendor Name',
        flex: 1,
    },
    {
        field: 'createdAt',
        headerName: 'Created On',
        flex: 1,
        renderCell: (params) => {
            return (
                <Typography>{DateFormatter(params.value)}</Typography>
            )
        }
    },
    {
        field: 'invoiceBalance',
        headerName: 'Amount Due',
        flex: 1,
    },
    {
        field: 'invoiceType',
        headerName: 'Invoice Type',
        flex: 1,

    },
    {
        field: 'invoiceStatus',
        headerName: 'Invoice Status',
        flex: 1,
        renderCell: (params) => {
            return (
                <Typography style={{ color: params.value === 'Paid' ? '#17ae7b' : '#dc3545' }}>{params.value}</Typography>
            )
        }
    },
]



const SupplierVendorInvoices = () => {
    const [tabValue, setTabValue] = useState("Unpaid");
    const [search, setSearch] = useState('')
    const [dataShow, setDataShow] = useState([])
    const [totalInvoicesPaid, setTotalInvoicesPaid] = useState('')
    const [totalInvoicesUnpaid, setTotalInvoicesUnpaid] = useState('')
    const [totalAmountUnpaid, setTotalAmountUnpaid] = useState('')
    const [totalAmountPaid, setTotalAmountPaid] = useState('')
    const [dataGridPageSize, setPageSize] = React.useState(20);

    const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });
    const [status, setStatus] = useState({});


    const classes = useStyles();
    const setNewValue = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 'Paid') {
            setStatus('Paid')
        }else if (newValue === 'Unpaid') {
            setStatus('Unpaid')
        }
        

    };
    console.log(status, 'status ===>>>><<<>>>>>>')
    

    const getInvoicesSupplier = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        let url = `/api/v1/supplier/invoices?status=${status}&search=${search}`
        if (tabValue === 'Paid') {
            url = `/api/v1/supplier/invoices?status=Paid&search=${search}&limit=1000`
        } else if (tabValue === 'Unpaid' ) {
            url = `/api/v1/supplier/invoices?status=${status}&search=${search}&limit=1000`
        } else {
            url = `/api/v1/supplier/invoices?status=${status}&search=${search}&limit=1000`
        }
        try {
            HttpComponent({
                method: 'GET',
                url: url,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200 || data.status === 201) {
                    let dataResponse = data?.response?.data
                    let totalInvoicesPaid = data?.response?.totalInvoicesPaid
                    let totalInvoicesUnpaid = data?.response?.totalInvoicesUnpaid
                    let totalAmountUnpaid = data?.response?.totalAmountUnpaid
                    let totalAmountPaid = data?.response?.totalAmountPaid
                    console.log(dataResponse, 'dataResponse')
                    
                    setDataShow(dataResponse)
                    setTotalInvoicesPaid(totalInvoicesPaid)
                    setTotalInvoicesUnpaid(totalInvoicesUnpaid)
                    setTotalAmountUnpaid(totalAmountUnpaid)
                    setTotalAmountPaid(totalAmountPaid)
                    let count
                    
                    setPageState((old) => ({ ...old, isLoading: false, data: dataResponse, total: count }));





                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getInvoicesSupplier()
    }, [tabValue, search, status])
    const filterOptionsStatusUnpaid = [
        { value: 'Unpaid', label: 'All' },
        { value: 'Processing', label: 'Processing' },
        { value: 'Unpaid_invoices', label: 'Unpaid' },
    ]
    const filterOptionsStatusPaid = [

        { value: 'Paid', label: 'Paid' },
    ]


    return (
        <Grid container direction={'column'}>
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541", cursor: "pointer" }} onClick={() => navigate(-1)}>Supplier/Vendor Invoices</Typography>
            </Grid>
            <Grid item>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item>
                <TabContext value={tabValue}>
                    <Box>
                        <AntTabs onChange={setNewValue}>
                            <AntTab value="Unpaid" label="Unpaid Invoices" />
                            <AntTab value="Paid" label="Paid Invoices" />

                        </AntTabs>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                            <Grid style={{ marginRight: "10px" }}>
                                <TextField
                                    // disabled={disabled}
                                    // name={name}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder={"Search"}
                                    variant="outlined"
                                    margin="dense"
                                    sx={{ marginBottom: 2 }}
                                    InputProps={{
                                        sx: {
                                            width: "266px",
                                            height: '3.438rem',
                                            fontSize: "0.875rem",
                                            "& fieldset": {
                                                borderColor: "#bec5d1"
                                            }
                                        },
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}

                                />
                            </Grid>
                            {tabValue === "Unpaid" ?
                                <CustomSelect options={filterOptionsStatusUnpaid} style={{ width: "266px", height: '3.438rem', marginRight: "10px" }}  onChange={(e) =>setStatus(e.target.value) }/> :
                                <CustomSelect options={filterOptionsStatusPaid} style={{ width: "266px", height: '3.438rem', marginRight: "10px" }} />
                            }
                            <CustomSelect options={[]} style={{ width: "266px", height: '3.438rem', }} />
                        </Box>
                        <Box style={{ width: "35%", marginTop: "-20px", marginLeft: "-12px" }}>
                            <Box style={{ width: "90%", height: "3.438rem", margin: "25px 0 15px 67px", padding: " 11.3px 9.9px 13.8px 18px", borderRadius: " 5px", backgroundColor: tabValue === "Unpaid" ? "rgba(220, 53, 69, 0.05)" : "rgba(23, 174, 123, 0.05)", display: "flex", justifyContent: "space-between" }}>
                                <Typography style={{ fontSize: "14px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.71", letterSpacing: "normal", textAlign: "left", color: tabValue === "Unpaid" ? "#dc3545" : "#17ae7b", fontFamily: "Poppins" }}> {tabValue === "Unpaid" ? totalInvoicesUnpaid : totalInvoicesPaid}  Invoices</Typography>
                                <Typography style={{ fontSize: "14px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.71", letterSpacing: "normal", textAlign: "left", color: tabValue === "Unpaid" ? "#dc3545" : "#17ae7b", fontFamily: "Poppins" }}>Total : KES {tabValue === "Unpaid" ? numberFormat(totalAmountUnpaid) : numberFormat(totalAmountPaid)}</Typography>
                            </Box>

                        </Box>


                    </Box>
                    <TabPanel value="Paid">
                        <CustomTable columns={columns} data={dataShow} />
                    </TabPanel>
                    <TabPanel value="Unpaid">
                        <CustomTable columns={columns} data={dataShow} />
                    </TabPanel>

                </TabContext>

            </Grid>

        </Grid>

    )
}

export default SupplierVendorInvoices