import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Grid, styled, Tab, TextField, Typography } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from "../../School/CustomSearchInput";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CustomTable from "../../School/CustomTable";
import DateFormatter from "../../../utils/dateFormatter";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import HttpComponent from "../../School/MakeRequest";
import TaskIcon from '@mui/icons-material/Task';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "13px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.75",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      color: "#032541",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#dc3545",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "blue",
    },
  })
);
const breadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Supplier/Vendors</Typography>,
  <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Supplier/Vendor Profile</Typography>
]
const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
});
const columns = [
  {
    field: 'invoiceNumber',
    headerName: 'Invoice Number',
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography style={{ color: '#032541', cursor: 'pointer' }}>
          <Link to={`/invoice/${params.value}`} style={{ color: '#032541', cursor: 'pointer' }}>{params.value}</Link>
        </Typography>
      )
    }
  },

  {
    field: 'createdAt',
    headerName: 'Created On',
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography>{DateFormatter(params.value)}</Typography>
      )
    }
  },
  {
    field: 'invoiceBalance',
    headerName: 'Amount Due',
    flex: 1,
  },
  {
    field: 'invoiceType',
    headerName: 'Invoice Type',
    flex: 1,

  },
  {
    field: 'invoiceStatus',
    headerName: 'Invoice Status',
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography style={{ color: params.value === 'Paid' ? '#17ae7b' : '#dc3545' }}>{params.value}</Typography>
      )
    }
  },
]
const SupplierProfile = () => {
  const [tabValue, setTabValue] = useState("Unpaid");
  const [search, setSearch] = useState('')
  const [dataShow, setDataShow] = useState([])
  const [dataGridPageSize, setPageSize] = React.useState(20);
  const { supplierId } = useParams()
  const [supplierDetails, setSupplierDetails] = useState({})

  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });


  const classes = useStyles();
  const setNewValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const getInvoicesSupplier = () => {
    console.log(supplierId, 'supplierId')

    setPageState((old) => ({ ...old, isLoading: true }));
    let url = `/api/v1/supplier/invoices/${supplierId}?status=Unpaid`
    if (tabValue === 'Paid') {
      url = `/api/v1/supplier/invoices/${supplierId}?status=Paid`
    } else if (tabValue === 'Unpaid') {
      url = `/api/v1/supplier/invoices/${supplierId}?status=Unpaid`
    } else {
      url = `/api/v1/supplier/invoices/${supplierId}?status=Unpaid`
    }
    console.log(url, 'url======');
    try {
      HttpComponent({
        method: 'GET',
        url: url,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'dataaaaaaaaaaaa')
        if (data.status === 200 || data.status === 201) {
          let dataResponse = data?.response?.data
          setDataShow(dataResponse)

          // let count
          // if (tabValue === 'Paid') {
          //   count = totalInvoicesPaid
          // } else if (tabValue === 'Unpaid') {
          //   count = totalInvoicesUnpaid
          // }
          // setPageState((old) => ({ ...old, isLoading: false, data: dataResponse, total: count }));





        }
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    getInvoicesSupplier()
  }, [tabValue, search])
  // api/v1/supplier/details/6544bcbf141f5139e733bec6 
  const getDeatails = () => {

    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/supplier/details/${supplierId}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'dataaaaaaaaaaaa')
        if (data.status === 200 || data.status === 201) {
          let dataResponse = data?.response?.data
          setSupplierDetails(dataResponse)

          // let count
          // if (tabValue === 'Paid') {
          //   count = totalInvoicesPaid
          // } else if (tabValue === 'Unpaid') {
          //   count = totalInvoicesUnpaid
          // }
          // setPageState((old) => ({ ...old, isLoading: false, data: dataResponse, total: count }));





        }
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    getDeatails()
  }, [])

  return (
    <Grid container direction={'column'}>
      <Grid>
        <Grid item mt={1}>
          <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Supplier/Vendor Profile</Typography>
        </Grid>
        <Grid item mt={1}>
          <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <Box style={{ display: "flex", justifyContent: "space-between", borderRadius: "10px", width: "600px", boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", padding: "15px 163px 15px 15px", height: "150px" }}>
            <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <Box style={{ width: "120px", height: " 120px", margin: " 0 32px 0 0", padding: "27px 33px 26px", borderRadius: "5px", backgroundColor: "#f5f5f5" }}>
                <Typography style={{ fontSize: "30px", fontWeight: "600", color: "#032541" , textTransform:"uppercase" }}>{supplierDetails?.supplierName?.charAt(0)} {supplierDetails?.supplierName?.charAt(supplierDetails?.supplierName.length - 1)}</Typography>
              </Box>

            </Box>
            <Box style={{ display: "flex", flexDirection: "column", width: "50%" }}>
              <span style={{ marginTop: "20px" }}>{supplierDetails?.supplierName}</span>
              <span style={{ fontSize: "14px", fontFamily: "Poppins", color: "#666f76", margin: "2px 0 0" }}>{supplierDetails?.supplierPhone}</span>
              <span style={{ fontSize: "14px", fontFamily: "Poppins", color: "#666f76", margin: "2px 0 0" }}>{supplierDetails?.supplierEmail}</span>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between", }}>
            <Box style={{ display: "flex", borderRadius: "10px", flexDirection: "column", width: "280px", boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", height: "150px", marginLeft: "20px", padding: "20px 10px" }}>
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}  >
                <span style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", color: "#dc3545", lineHeight: "1.19", }}>Unpaid Invoices</span>
                <span style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", color: "#dc3545", lineHeight: "1.19", marginLeft: "80px" }}><InsertDriveFileIcon /> </span>

              </Box>
              <Box style={{ flexDirection: "column", display: "flex" }}>
                <span style={{ fontSize: "22px", fontFamily: "Poppins", fontWeight: "600", color: "#dc3545", lineHeight: "1.19", marginTop: "20px" }}>{numberFormat(supplierDetails?.unPaidInvoicesTotalAmount)}</span>
                <span style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", color: "#5a5c5e", lineHeight: "1.19", marginTop: "20px" }}>{supplierDetails?.unpaidInvoicesCount} invoices</span>
              </Box>

            </Box>
            <Box style={{ display: "flex", borderRadius: "10px", flexDirection: "column", width: "280px", boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#fff", height: "150px", marginLeft: "20px", padding: "20px 10px" }}>
              <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}  >
                <span style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", color: "#17ae7b", lineHeight: "1.19", }}>Paid Invoices</span>
                <span style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", color: "#17ae7b", lineHeight: "1.19", marginLeft: "80px" }}><TaskIcon /> </span>

              </Box>
              <Box style={{ flexDirection: "column", display: "flex" }}>
                <span style={{ fontSize: "22px", fontFamily: "Poppins", fontWeight: "600", color: "#17ae7b", lineHeight: "1.19", marginTop: "20px" }}>{numberFormat(supplierDetails?.paidInvoicesTotalAmount)}</span>
                <span style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", color: "#5a5c5e", lineHeight: "1.19", marginTop: "20px" }}>{supplierDetails?.paidInvoicesCount} invoices</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <TabContext value={tabValue}>
          <Box>
            <AntTabs onChange={setNewValue}>
              <AntTab value="Unpaid" label="Unpaid Invoices" />
              <AntTab value="Paid" label="Paid Invoices" />
            </AntTabs>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <CustomSearchInput placeholder={"Search Invoice"} />
          </Box>
          <TabPanel value="Paid">

            <CustomTable columns={columns} data={dataShow} rowsPerPage={20} />
          </TabPanel>
          <TabPanel value="Unpaid">
            <CustomTable columns={columns} data={dataShow} rowsPerPage={20} />
          </TabPanel>

        </TabContext>

      </Grid>
    </Grid>
  )
}

export default SupplierProfile