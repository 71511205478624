import React from "react";
import { ReactSVG } from "react-svg";
import MySVG1 from "./about-iconRED.svg";
import MySVG2 from "./about-iconBLUE.svg";

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG =
    currentPath === "/About"
      ? MySVG1
      : MySVG2;

  return <ReactSVG src={MySVG} />;
}

export default MySVGComponent;
