import React, { useState, useEffect } from "react";
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, Grid, Pagination } from "@mui/material";

export const CustomTable = (props) => {
  const tableHeaders = props.tableHeaders;
  const tableData = props.tableData;


  return (
    <TableContainer component={Paper} style={{ margin: "0px 0px 10px 0px" }}>
      {/* <p style={{ textAlign: "right" }}>
          <Input size="sm" variant="plain" value={queryProducts} onChange={(e) => setQueryproducts(e.target.value)} style={{ objectFit: "contain", borderRadius: "6px", outline: "none", border: "solid 1px #032541", fontSize: "12px", paddingLeft: "10px" }} placeholder="Search product" />
        </p> */}
      <Table stickyHeader sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            {tableHeaders?.map((header) => {
              return (
                <>
                  <TableCell style={{ fontWeight: "bold" }}>{header?.header}</TableCell>
                </>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {
            // productsList?.products.length > 0 &&
            tableData?.map((data, index) => {
              return (
                <TableRow style={{ cursor: "pointer" }} key={index}>
                  <TableCell>{data.productName}</TableCell>
                  <TableCell>{data.productPrice}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>{data.totalAmount}</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>

      <Grid item style={{ width: "100%", marginTop: "2%", marginBottom: "2%" }}>
        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          {/* <Pagination count={5} page={tablePage} defaultPage={1} onChange={(e, value) => handleTableChange(value)} variant="outlined" shape="rounded" /> */}
          {/* <Pagination count={0} page={1} defaultPage={1} onChange={handleTableChange} variant="outlined" shape="rounded" /> */}
        </Box>
      </Grid>
    </TableContainer>
  );
};
