import { createSlice } from "@reduxjs/toolkit";

let date = new Date();
const date1 = new Date(date.getFullYear(), date.getMonth(), 1)
let date2 = new Date();

const initialState = {
  startDate: date1,
  endDate: date2,
  startDate2: "",
  endDate2: "",
  period: "this month",
};

const dateSlice = createSlice({
  name: "selectedDate",
  initialState,
  reducers: {
    handleSelectedDate: (state, action) => {
      state.startDate = action.payload.start;
      state.endDate = action.payload.end;
      state.period = "period";
    },
    handleSelectedDate2: (state, action) => {
      state.startDate2 = action.payload.start;
      state.endDate2 = action.payload.end;
    },
    clearSelectedDate: (state) => {
      state.startDate = date1;
      state.endDate = date2;
      state.period = "this month";
    },
    clearSelectedDate2: (state) => {
      state.startDate2 = "";
      state.endDate2 = "";
    },
    handlePeriod: (state, action) => {
      state.startDate = "";
      state.endDate = "";
      state.period = action.payload;
    },
    clearPeriod: (state) => {
      state.period = "this month";
      state.startDate = date1;
      state.endDate = date2;
    },
  },
});

export const {
  handleSelectedDate,
  handleSelectedDate2,
  clearSelectedDate,
  clearSelectedDate2,
  handlePeriod,
  clearPeriod,
} = dateSlice.actions;

export default dateSlice.reducer;
