import { Grid, Typography, Breadcrumbs, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import HttpComponent from '../MakeRequest';
import PreviewSessionReport from './previewSessionReport';
import PreviewSessionDrilldownReport from './sessionDrilldown';

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Session Report</Typography>
]


export default function SchoolSessionReport() {
    const navigate = useNavigate()
    const [academicYear, setAcademicYear] = useState('')

    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[0]
    const [allStaff, setAllStaff] = useState([])
    const [academicYears, setAcademicYears] = useState([])
    const [unitsSchool, setUnitsSchool] = useState([])


    // fecth academic years

    function fetchAcademicYears() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/schoolacademicyear`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data, 'staff')
                if (data?.status === 200) {
                    setAcademicYears(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchAcademicYears()
    }, [])

    // get lecs


    function fetchStaffAdded() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?&customerClassification=TeachingStaff&status=ACTIVE`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'staff')
                if (data?.status === 200) {
                    setAllStaff(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchStaffAdded()
    }, [])


    // units

    function getAllSchoolUnits() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getCourseUnits?status=ACTIVE`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                console.log(data, 'fk')
                if (data?.status === 200) {
                    setUnitsSchool(data?.response?.data)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getAllSchoolUnits()
    }, [])

    // handle select teacher
    const [unitName, setUnitName] = useState('')
    const [unit, setUnit] = useState('')
    const [lecturerId, setlectureId] = useState('')
    const [lectureName, setlecturenName] = useState('')

    const handleSetlectureDetails = (e) => {
        const valueFound = e.target.value;
        const selectedUnit = allStaff?.find(item => item?.students[0]?._id === valueFound);

        if (selectedUnit) {
            const { customerName } = selectedUnit;

            // Assuming setTeacher and setUnitName are state setters
            setlectureId(selectedUnit?.students[0]?._id);
            setlecturenName(customerName);
        }
    }


    const handleSetUnit = (e) => {
        const valueFound = e.target.value;
        const selectedUnit = unitsSchool?.find(item => item?._id === valueFound);

        if (selectedUnit) {
            const { _id: unitId, unitName } = selectedUnit;
            console.log(unitId, unitName, 'valueFound');

            // Assuming setTeacher and setUnitName are state setters
            setUnit(unitId);
            setUnitName(unitName);
        }
    }

    // console.log(unitName , 'unitName')

    // generate report


    return (
        <Grid container direction={'column'}>
            {state === 'PreviewReport' ? <PreviewSessionReport teacher={lecturerId} unit={unit} academicYear={academicYear} teacherName={lectureName} unitName={unitName} /> : state === 'SessionDetails' ? <PreviewSessionDrilldownReport sessionId={''} /> : <>
                <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Session Report</Typography>
                </Grid>
                <Grid item mt={2}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item width={'50%'} mt={2} mb={1}>
                    <Typography style={{ color: '#032541', fontWeight: 500, fontSize: '16px' }}>Staff Details</Typography>
                </Grid>
                <Grid item mt={1} width={'50%'} mb={1}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Select Academic Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={academicYear}
                            label="Select Academic Year"
                            onChange={(e) => setAcademicYear(e.target.value)}
                        >
                            {academicYears?.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item mt={1} width={'50%'} mb={1}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Select Teacher</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={lecturerId}
                            label="Select Teacher"
                            onChange={handleSetlectureDetails}
                        >
                            {allStaff?.map((item) => (
                                <MenuItem value={item?.students[0]?._id}>{item?.customerName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item mt={1} width={'50%'} mb={1}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Select Unit</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unit}
                            label="Select Unit"
                            onChange={handleSetUnit}
                        >
                            {unitsSchool?.map((item) => (
                                <MenuItem value={item?._id}>{item?.unitName}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid mt={2} item width={'50%'} display={'flex'} justifyContent={'flex-end'}>
                    {/* <Button onClick={() => navigate(-1)} style={{ border: '1px solid #dc3545', color: '#dc3545', textTransform: 'inherit', width: '124px' }}> Cancel</Button> */}
                    <Button onClick={() => navigate(`/school/sessionreport?PreviewReport`)} style={{ backgroundColor: '#032541', marginLeft: '10px', color: '#fff', textTransform: 'inherit', width: '142px' }}>Generate Report</Button>
                </Grid>
            </>}
        </Grid>
    )
}