import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  series: [
    {
      name: "Schools",
      data: [],
    },
    {
      name: "Normal businesses",
      data: [],
    },
    {
      name: "Rentals",
      data: [],
    },
  ],
};

const SalesChartSlice = createSlice({
  name: "salesSeries",
  initialState,
  reducers: {
    setSalesSeries: (state, action) => {
      state.series = action.payload;
    },
  },
});

export const { setSalesSeries } = SalesChartSlice.actions;

export default SalesChartSlice.reducer;
