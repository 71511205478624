import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clickedItem: "2024",
  clickedItem2: "2024",
}


const actionSlice = createSlice({
  name:"salesDropdown",
  initialState,
  reducers:{
    handleItemClick:(state, action) => {
      state.clickedItem = action.payload
    },
    handleItemClick2:(state, action) => {
      state.clickedItem2 = action.payload
    },
  }
})

export const { handleItemClick, handleItemClick2 } = actionSlice.actions;

export default actionSlice.reducer