import React from "react";
import { Box, ButtonBase, Paper, Typography } from "@mui/material";
import { currencyconverter } from "../../../common/currencyconverter";
import unpaidbillssvg from "../../../common/images/unpaid bills-icon.svg";
import paidbillssvg from "../../../common/images/paid bills-icon.svg";
import cancelledbillssvg from "../../../common/images/cancelled bills-icon.svg";
import reportsiconssvg from "../../../common/images/Report.svg";
import { useNavigate, useParams } from "react-router-dom";

const PosCards = ({paidTotals, unpaidTotals , partialTotals , cancelledTotals }) => {
  const navigate = useNavigate();

  const { tabValue } = useParams();

  const posCardDetails = [
    { svg: unpaidbillssvg, label: "Unpaid Bills", amount:  unpaidTotals, color: "#dc3545", border: "2px solid #dc3545", linkName: "unpaid" },
    { svg: unpaidbillssvg, label: "Partial Bills", amount: partialTotals, color: "#dc3545", border: "2px solid #dc3545", linkName: "partial" },
    { svg: paidbillssvg, label: "Paid Bills", amount: paidTotals, color: "#17ae7b", linkName: "paid", border: "2px solid #17ae7b" },
    { svg: cancelledbillssvg, label: "Cancelled Bills", amount: cancelledTotals, color: "#dc3545", linkName: "cancelled", border: "2px solid #dc3545" },
    { svg: reportsiconssvg, label: "Reports", amount: 0, linkName: "reports", border: "2px solid #17ae7b" },
    // {svg: '', label: '' , amount:0},
  ];

  const setActiveLink = (linkName) => {
    if (!linkName) {
      navigate(`/pos/unpaid`);
    }
    navigate(`/pos/${linkName}`);
  };
  return (
    <Box sx={{ fontFamily: "Poppins",width:'100%', display: {sm: "flex" , md: "flex", lg:"block" }, gap:"10px"   }} >
      {posCardDetails?.map((carddetail) => {
        return (
          <Box onClick={() => setActiveLink(carddetail.linkName)} sx={{ marginBottom: 5, padding: 3, display:  "flex", alignItems: "center", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.16)", border: tabValue === carddetail.linkName ? carddetail.border : "", borderRadius: "5px", width:'100%' }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <ButtonBase>
                <img src={carddetail.svg} alt={carddetail.label} />
              </ButtonBase>

              <Box>
                <Typography component={"div"}>{carddetail.label}</Typography>
                {carddetail.label !== "Reports" && (
                  <Typography color={carddetail.color} component={"div"}>
                    {currencyconverter(carddetail.amount)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PosCards;
