import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {makeStyles} from "@mui/styles";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function Report (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	let businessCat = localStorage.getItem("businessCategory");

	const handleClick = () => {setOpen(!open);};
	const location = useLocation()

	//set active link

	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	const userGroup = localStorage.getItem('group')

	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
				<div style={{marginRight : '5%'}}>
					<AssessmentIcon />
				</div>
				<ListItemText primary="Reports" style={{fontFamily : 'Poppins'}}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
				<div style={{ marginLeft: '8%' }}>
					{ (userGroup === 'Merchant' && businessCat === 'Rental') ?
						<Link style={{textDecoration: 'none', color: isActiveLink("/tenantsReport") ? activeColor : '#fff'}} to="/tenantsReport">
							<ListItem button className={classes.nested}>
								<ListItemText primary="Tenants Report" />
							</ListItem>
						</Link>
						: (userGroup === 'Admin' || userGroup === 'Merchant' || userGroup === 'Supervisor' || userGroup === "Owner") ?
						<div>
							
							<Link style={{textDecoration: 'none',  color: isActiveLink("/voidtransactionreport") ? activeColor : '#fff'}} to="/voidtransactionreport">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Void Transaction" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color: isActiveLink("/reports-by-cashier") ? activeColor : '#fff'}} to="/reports-by-cashier">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sale Report by Cashier" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color: isActiveLink("/get_unpaid_orders_by_cashier") ? activeColor : '#fff'}} to="/get_unpaid_orders_by_cashier">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Unpaid Orders by Cashier" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color :isActiveLink('/get_sales_total_by_product')?activeColor: '#fff'}} to="/get_sales_total_by_product">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sales Total by Product" />
								</ListItem>
							</Link>

							<Link style={{textDecoration: 'none', color :isActiveLink('/get_total_sales_by_item_by_payments')?activeColor: '#fff'}} to="/get_total_sales_by_item_by_payments">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Sales Report" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color :isActiveLink('/get_all_cashier_to_settle')?activeColor: '#fff'}} to="/get_all_cashier_to_settle">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Cashier Settlement" />
								</ListItem>
							</Link>
							<Link style={{textDecoration: 'none', color :isActiveLink('/get_opening_closing_Report')?activeColor: '#fff'}} to="/get_opening_closing_Report">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Opening & Closing Report" />
								</ListItem>
							</Link>

						</div>: null
					}
				</div>
				</List>
			</Collapse>
		</div>
	)
}