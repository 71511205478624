import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Grid, Tab, Typography } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomTextField from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from "@mui/material/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomTable from './CustomTable';
import CustomSearchInput from './CustomSearchInput';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
const baseUrl = process.env.REACT_APP_BASE_URL;

const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#ffffff00",
    },
});
const columns = [
    {

        field: 'schoolGrades',
        flex: 0.5,
        headerName: 'Default  Grades',
    },
];



const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.75",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
        color: "#032541",
        opacity: 1,
    },
    "&.Mui-selected": {
        color: "#dc3545",
        fontWeight: 600,
    },
    "&.Mui-focusVisible": {
        backgroundColor: "blue",
    },
}));

const SchoolGradesForm = (props) => {

    const url = window.location.href;
    // console.log(url, "url school grades")
    const extractedString = url.substring(url.lastIndexOf("/") + 1);
    console.log(extractedString, 'extractedString');
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);
    const [workflowStatus, setWorkflowStatus] = useState()

    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/school/grades') {
            setWorkflowStatus(true)
        } else {
            setWorkflowStatus(false)
        }
    }, [location.pathname])

    // console.log(workflowStatus, 'url heree')

    const handlePageChange = (params) => {
        setPage(params.page);
    };
    const getRowId = (row) => {
        return row?._id || row?.id || row?.gridId || Math.floor(Math.random() * 2000000)
    }



    const [SuccessNotificationOpen, setSuccessNotificationOpen] = React.useState(false);

    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    const handleSuccessNotificationClose = () => {
        setSuccessNotificationOpen(false);
    };

    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);

    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleErrorNotificationClose = () => {
        setErrorNotificationOpen(false);
    };


    const columnsStream = [
        {

            field: 'streamName',
            flex: 0.5,
            headerName: 'Streams',
        },
        {

            field: 'ACTION',
            flex: 0.5,
            headerName: 'ACTION',

            renderCell: (params) => {
                const deleteStream = async (id) => {
                    const response = await fetch(`${baseUrl}/api/delete_school_streams`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "X-Authorization": X_Authorization,
                        },
                        body: JSON.stringify({
                            "streamId": id,
                        })
                    });
                    const data = await response.json();
                    console.log(data, 'Delete Stream');
                    if (response.status === 201) {
                        setMessage("Stream Deleted Successfully");
                        handleSuccessNotificationClick()

                        GetStreams();
                    } else {
                        handleErrorNotificationClick()
                        return
                    }
                };
                const editStream = (params) => {
                    ///api/edit_school_streams
                    console.log(params, 'Edit Stream');
                    setStreamName(params.streamName);
                    setShowStreamsModal(true);
                    setStreamId(params._id);
                    setUpdateStreamButton(true)



                }



                return (
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <EditIcon style={{ color: "#032440", cursor: "pointer", }} onClick={() => editStream(params.row)} /><span style={{ marginRight: "10px", color: "#032440" }}>Edit</span>
                        <DeleteIcon style={{ color: "#dc3545", cursor: "pointer", }} onClick={() => deleteStream(params.row._id)} /><span style={{ marginRight: "10px", color: "#dc3545" }} >Delete</span>
                    </Grid>
                )
            }



        },

    ];
    const [courses, setCourses] = useState([]);
    let previousCourseName = [];

    // // // Preprocess your data
    // const groupedCourses = groupDataByCourse(courses);

    // Render the table using the grouped data
    const columnsCourse = [
        {
            field: 'courseName',
            flex: 1,
            headerName: 'Courses',
            renderCell: (params) => {
                if (params.row.courseName !== previousCourseName) {
                    previousCourseName = params.row.courseName;
                    return (
                        <div>
                            <span style={{ marginLeft: "20px", color: "#032440", textAlign: "center" }}>{params.row.courseName}</span>
                        </div>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            field: 'duration',
            flex: 1,
            headerName: 'Duration',
            renderCell: (params) => (
                <div>
                    <span style={{ marginLeft: "20px", color: "#032440", textAlign: "center" }}>{params.row.duration}</span>
                </div>
            ),
        },
        {
            field: 'noOfSemestersPerDuration',
            flex: 1,
            headerName: 'Semester',
            renderCell: (params) => (
                <div>
                    <span style={{ marginLeft: "20px", color: "#032440", textAlign: "center" }}>{params.row.noOfSemestersPerDuration || "N/A"}</span>
                </div>
            ),
        },
        {
            field: 'ACTION',
            flex: 1,
            headerName: 'ACTION',
            renderCell: (params) => {
                const deleteCourse = async (id) => {
                    const response = await fetch(`${baseUrl}/api/delete_school_courses`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "X-Authorization": X_Authorization,
                        },
                        body: JSON.stringify({
                            "courseId": id,
                        })
                    });
                    const data = await response.json();
                    console.log(data, 'Delete Course');
                    if (response.status === 201) {
                        setMessage("Course Deleted Successfully");
                        handleSuccessNotificationClick()
                    }
                    else {
                        handleErrorNotificationClick()
                        return
                    }
                };
                const handleEditCourseName = (row) => {
                    console.log(row, 'row ===>>>>>')
                    console.log(row, 'row')
                    setCourseName(row.courseName);
                    setDuration(row.duration);
                    setDurationType(row.durationType);
                    setSemesterEnabled(row.semesterEnabled);
                    setNoOfSemesters((row.noOfSemestersPerDuration).toString());
                    setSemesters(row.semesterName);
                    setShowCourseModal(true);
                    setCourseId(row._id);

                    if (row.semesterEnabled === true && row.noOfSemestersPerDuration === 1) {
                        setSemesterStart1(row.durationOfSemester[0].split(" - ")[0]);
                        setSemesterEnd1(row.durationOfSemester[0].split(" - ")[1]);
                    } else if (row.semesterEnabled === true && row.noOfSemestersPerDuration === 2) {
                        setSemesterStart1(row.durationOfSemester[0].split(" - ")[0]);
                        setSemesterEnd1(row.durationOfSemester[0].split(" - ")[1]);
                        setSemesterStart2(row.durationOfSemester[1].split(" - ")[0]);
                        setSemesterEnd2(row.durationOfSemester[1].split(" - ")[1]);
                    } else if (row.semesterEnabled === true && row.noOfSemestersPerDuration === 3) {
                        setSemesterStart1(row.durationOfSemester[0].split(" - ")[0]);
                        setSemesterEnd1(row.durationOfSemester[0].split(" - ")[1]);
                        setSemesterStart2(row.durationOfSemester[1].split(" - ")[0]);
                        setSemesterEnd2(row.durationOfSemester[1].split(" - ")[1]);
                        setSemesterStart3(row.durationOfSemester[2].split(" - ")[0]);
                        setSemesterEnd3(row.durationOfSemester[2].split(" - ")[1]);
                    } else if (row.semesterEnabled === true && row.noOfSemestersPerDuration === 4) {
                        setSemesterStart1(row.durationOfSemester[0].split(" - ")[0]);
                        setSemesterEnd1(row.durationOfSemester[0].split(" - ")[1]);
                        setSemesterStart2(row.durationOfSemester[1].split(" - ")[0]);
                        setSemesterEnd2(row.durationOfSemester[1].split(" - ")[1]);
                        setSemesterStart3(row.durationOfSemester[2].split(" - ")[0]);
                        setSemesterEnd3(row.durationOfSemester[2].split(" - ")[1]);
                        setSemesterStart4(row.durationOfSemester[3].split(" - ")[0]);
                        setSemesterEnd4(row.durationOfSemester[3].split(" - ")[1]);
                    } else {
                        setSemesterStart1("");
                        setSemesterEnd1("");
                        setSemesterStart2("");
                        setSemesterEnd2("");
                        setSemesterStart3("");
                        setSemesterEnd3("");
                        setSemesterStart4("");
                        setSemesterEnd4("");
                    }
                }
                return (
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <EditIcon style={{ color: "#032440", cursor: "pointer" }} onClick={() => handleEditCourseName(params.row)} /><span style={{ marginRight: "10px", color: "#032440" }} ></span>
                        <DeleteIcon style={{ color: "#dc3545", cursor: "pointer" }} onClick={() => deleteCourse(params.row._id)} /><span style={{ marginRight: "10px", color: "#dc3545" }}></span>
                    </Grid>
                );
            }
        },

    ];

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        });
        const data = await response.json();
        console.log(data, 'Get Courses');
        if (response.status === 200) {
            // Filter and get unique course names
            const uniqueCourses = data.data.reduce((accumulator, currentCourse) => {
                if (!accumulator.some((course) => course.courseName === currentCourse.courseName)) {
                    accumulator.push(currentCourse);
                }
                return accumulator;
            }, []);
            console.log(uniqueCourses, "filtered unique courseNames");
            setCourses(uniqueCourses);
        }
    };

    useEffect(() => {
        getCourses();
    }, []);



    const [tabValue, setTabValue] = useState("Streams");
    const { userId, X_Authorization } = useSelector((store) => store.user);

    console.log(tabValue);
    const handleTabChange = (event, newValue) => {
        console.log("TabValue", newValue);
        setTabValue(newValue);
    };
    const [grades, setGrades] = useState([]);
    const [streams, setStreames] = useState([]);
    const [schoolTypeId, setSchoolTypeId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [message, setMessage] = useState('')
    const [schoolTypeName, setSchoolTypeName] = useState("");
    const [streamId, setStreamId] = useState("");
    const [streamDataObject, setStreamDataObject] = useState({});
    const [courseId, setCourseId] = useState("");




    const GetSchoolTypeIdForBusiness = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            }
        });
        const data = await response.json();
        console.log(data, 'School Type Id');
        if (response.status === 201) {
            setSchoolTypeId(data.data?._id);
            setSchoolTypeName(data.data?.schoolType);

        }
    }
    useEffect(() => {
        GetSchoolTypeIdForBusiness();
    }, [])
    console.log('School Type Id', schoolTypeId);
    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            const foundGrades = data?.data
            //filter out completed
            const filterOutCompleetd = data?.data?.filter((grade) => grade?.schoolGrades !== 'COMPLETED')
            console.log(filterOutCompleetd, 'filterOutCompleetd')
            setGrades(filterOutCompleetd);
        }
    };
    console.log(grades)

    const GetStreams = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setStreames(data.data);
        }
    };
    useEffect(() => {
        if (schoolTypeId !== "") {
            GetGrades();
            GetStreams();
        }

    }, [schoolTypeId]);
    const [showStreamsModal, setShowStreamsModal] = useState(false);
    const [streamNames, setStreamNames] = useState([]);
    const [streamName, setStreamName] = useState('');
    const [updateStreamButton, setUpdateStreamButton] = useState(false);



    const handleStreamsModal = () => {
        setShowStreamsModal(true);
    };
    const handleChangeStreamName = () => {
        console.log('Stream Name', streamName);
        console.log(streamNames, "Stream Names At first");
        const data = [...streamNames]
        let check = streamNames.find((item) => item === streamName);
        console.log(check, "Check");
        if (check) {
            console.log("Stream Name Already Exist");
            return;
        }

        data.push(streamName);
        setStreamNames(data);
        console.log(streamNames, "Stream Names")
        setStreamName("");
    };
    const addStreamName = async () => {
        if (streamName === "") {
            toast.error("Please Enter Stream Name");
            return
        }

        const response = await fetch(`${baseUrl}/api/add_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "POST",
            body: JSON.stringify({
                streams: [streamName],
                workFlow: workflowStatus
            }),
        });
        const data = await response.json();
        console.log(data, "Add Stream Name");
        if (response.status === 201) {
            setShowStreamsModal(false);
            setMessage("Stream Added Successfully");
            handleSuccessNotificationClick()


            setStreamName("");

            GetStreams();
        }
    };

    const updateStreamName = async () => {
        if (streamName === "") {
            toast.error("Please Enter Stream Name");
            return
        }
        const response = await fetch(`${baseUrl}/api/edit_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "POST",
            body: JSON.stringify({
                streamId: streamId,
                streamName: streamName,
            }),
        });
        const data = await response.json();
        console.log(data, "Update Stream Name");
        if (response.status === 201) {
            setShowStreamsModal(false);
            setMessage("Stream Updated Successfully");
            handleSuccessNotificationClick()
            setStreamName("");
            GetStreams();

        }
    }
    const navigate = useNavigate();
    const [courseName, setCourseName] = useState("");
    const [durationType, setDurationType] = useState("");
    const [duration, setDuration] = useState("");
    const [semesterEnabled, setSemesterEnabled] = useState(false);
    const [noOfSemesters, setNoOfSemesters] = useState("");
    const [semesters, setSemesters] = useState([]);
    const [semesterStart1, setSemesterStart1] = useState("");
    const [semesterStart2, setSemesterStart2] = useState("");
    const [semesterStart3, setSemesterStart3] = useState("");
    const [semesterStart4, setSemesterStart4] = useState("");
    const [semesterEnd1, setSemesterEnd1] = useState("");
    const [semesterEnd2, setSemesterEnd2] = useState("");
    const [semesterEnd3, setSemesterEnd3] = useState("");
    const [semesterEnd4, setSemesterEnd4] = useState("");
    const [showCourseModal, setShowCourseModal] = useState(true);
    const [errorMessage, setErrorMessages] = useState("");

    const durationTypesOptions = [
        { value: "Years", label: "Years" },
        { value: "Months", label: "Months" },
        { value: "Weeks", label: "Weeks" },
    ];
    const semestersOptions = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
    ];
    const monthsOptions = [
        { value: "January", label: "January" },
        { value: "February", label: "February" },
        { value: "March", label: "March" },
        { value: "April", label: "April" },
        { value: "May", label: "May" },
        { value: "June", label: "June" },
        { value: "July", label: "July" },
        { value: "August", label: "August" },
        { value: "September", label: "September" },
        { value: "October", label: "October" },
        { value: "November", label: "November" },
        { value: "December", label: "December" },
    ];

    //     // Function to reset all state variables
    const resetForm = () => {
        setCourseName('');
        setDurationType('');
        setDuration('');
        setSemesterEnabled(false);
        setNoOfSemesters('');
        setSemesters([]);
    };

    const addCourse = async () => {

        if (courseName === "") {
            toast.error("Please Enter Course Name");
            setErrorMessages("Please Enter Course Name");
            handleErrorNotificationClick();
            return;
        }
        if (durationType === "") {
            toast.error("Please Select Duration Type");
            setErrorMessages("Please Select Duration Type");
            handleErrorNotificationClick();
            return;
        }
        if (duration === "") {
            toast.error("Please Enter Duration");
            setErrorMessages("Please Enter Duration");
            handleErrorNotificationClick();
            return;
        }
        if (semesterEnabled === true) {
            if (noOfSemesters === "") {
                toast.error("Please Enter No Of Semesters");
                setErrorMessages("Please Enter No Of Semesters");
                handleErrorNotificationClick()
                return

            }


            if (noOfSemesters === "1") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                semesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` });
            }

            if (noOfSemesters === "2") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterStart2 === "") {
                    toast.error("Please Enter Semester Start 2");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd2 === "") {
                    toast.error("Please Enter Semester End 2");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                semesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` }, { "Semester 2": `${semesterStart2} - ${semesterEnd2}` });
            }
            if (noOfSemesters === "3") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return;

                }
                if (semesterStart2 === "") {
                    toast.error("Please Enter Semester Start 2");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return


                }
                if (semesterEnd2 === "") {
                    toast.error("Please Enter Semester End 2");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterStart3 === "") {
                    toast.error("Please Enter Semester Start 3");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd3 === "") {
                    toast.error("Please Enter Semester End 3");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart1) > new Date(semesterStart2)) {
                    toast.error("Please Enter Semester Start 2 Greater Than Semester Start 1");
                    setErrorMessages("Please Enter Semester Start 2 Greater Than Semester Start 1");
                    handleErrorNotificationClick()
                    return


                }
                if (new Date(semesterStart2) > new Date(semesterStart3)) {
                    toast.error("Please Enter Semester Start 3 Greater Than Semester Start 2");
                    setErrorMessages("Please Enter Semester Start 3 Greater Than Semester Start 2");
                    handleErrorNotificationClick()
                    return


                }
                if (new Date(semesterStart3) > new Date(semesterStart4)) {
                    toast.error("Please Enter Semester Start 4 Greater Than Semester Start 3");
                    setErrorMessages("Please Enter Semester Start 4 Greater Than Semester Start 3");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterEnd1) > new Date(semesterEnd2)) {
                    toast.error("Please Enter Semester End 2 Greater Than Semester End 1");
                    setErrorMessages("Please Enter Semester End 2 Greater Than Semester End 1");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterEnd2) > new Date(semesterEnd3)) {
                    toast.error("Please Enter Semester End 3 Greater Than Semester End 2");
                    setErrorMessages("Please Enter Semester End 3 Greater Than Semester End 2");
                    handleErrorNotificationClick()
                    return


                }

                if (new Date(semesterStart1) > new Date(semesterEnd1)) {
                    toast.error("Please Enter Semester Start 1 Less Than Semester End 1");
                    setErrorMessages("Please Enter Semester Start 1 Less Than Semester End 1");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart2) > new Date(semesterEnd2)) {
                    toast.error("Please Enter Semester Start 2 Less Than Semester End 2");

                }
                if (new Date(semesterStart3) > new Date(semesterEnd3)) {
                    toast.error("Please Enter Semester Start 3 Less Than Semester End 3");

                }
                semesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` }, { "Semester 2": `${semesterStart2} - ${semesterEnd2}` }, { "Semester 3": `${semesterStart3} - ${semesterEnd3}` });

            }
            if (noOfSemesters === "4") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterStart2 === "") {
                    toast.error("Please Enter Semester Start 2");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd2 === "") {
                    toast.error("Please Enter Semester End 2");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterStart3 === "") {
                    toast.error("Please Enter Semester Start 3");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd3 === "") {
                    toast.error("Please Enter Semester End 3");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart4) === "") {
                    toast.error("Please Enter Semester Start 4");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd4 === "") {
                    toast.error("Please Enter Semester End 4");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart1) > new Date(semesterStart2)) {
                    toast.error("Please Enter Semester Start 2 Greater Than Semester Start 1");
                    setErrorMessages("Please Enter Semester Start 2 Greater Than Semester Start 1");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart2) > new Date(semesterStart3)) {
                    toast.error("Please Enter Semester Start 3 Greater Than Semester Start 2");
                    setErrorMessages("Please Enter Semester Start 3 Greater Than Semester Start 2");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart3) > new Date(semesterStart4)) {
                    toast.error("Please Enter Semester Start 4 Greater Than Semester Start 3");
                    setErrorMessages("Please Enter Semester Start 4 Greater Than Semester Start 3");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd1 > semesterEnd2) {
                    toast.error("Please Enter Semester End 2 Greater Than Semester End 1");
                    setErrorMessages("Please Enter Semester End 2 Greater Than Semester End 1");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd2 > semesterEnd3) {
                    toast.error("Please Enter Semester End 3 Greater Than Semester End 2");
                    setErrorMessages("Please Enter Semester End 3 Greater Than Semester End 2");
                    handleErrorNotificationClick()
                    return

                }
                if (semesterEnd3 > semesterEnd4) {
                    toast.error("Please Enter Semester End 4 Greater Than Semester End 3");
                    setErrorMessages("Please Enter Semester End 4 Greater Than Semester End 3");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart1) > semesterEnd1) {
                    toast.error("Please Enter Semester Start 1 Less Than Semester End 1");
                    setErrorMessages("Please Enter Semester Start 1 Less Than Semester End 1");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart2) > semesterEnd2) {
                    toast.error("Please Enter Semester Start 2 Less Than Semester End 2");
                    setErrorMessages("Please Enter Semester Start 2 Less Than Semester End 2");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart3) > semesterEnd3) {
                    toast.error("Please Enter Semester Start 3 Less Than Semester End 3");
                    setErrorMessages("Please Enter Semester Start 3 Less Than Semester End 3");
                    handleErrorNotificationClick()
                    return

                }
                if (new Date(semesterStart4) > semesterEnd4) {
                    toast.error("Please Enter Semester Start 4 Less Than Semester End 4");
                    setErrorMessages("Please Enter Semester Start 4 Less Than Semester End 4");
                    handleErrorNotificationClick()
                    return

                }
                semesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` }, { "Semester 2": `${semesterStart2} - ${semesterEnd2}` }, { "Semester 3": `${semesterStart3} - ${semesterEnd3}` }, { "Semester 4": `${semesterStart4} - ${semesterEnd4}` });
            }
            console.log(semesters.length, parseInt(noOfSemesters))
            console.log(JSON.stringify(semesters), 'semesters')


            // if (semesters.length !== parseInt(noOfSemesters)) {
            //     setErrorMessages(`Please Enter All ${noOfSemesters} Semesters`);
            //     handleErrorNotificationClick()
            //     setSemesters([])
            //     return

            // }




        }
        if (durationType === "Year") {
            if (parseInt(duration) > 7) {
                setErrorMessages("Duration Should Be Less Than 7");
                handleErrorNotificationClick()
                return

            }
        }

        // Check if the courseName already exists
        const getCoursesResponse = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            }
        });

        const existingCoursesData = await getCoursesResponse.json();

        if (getCoursesResponse.status === 200) {
            const courseExists = existingCoursesData.data.some(course => course.courseName === courseName);

            if (courseExists) {
                setErrorMessages("Course with the same name already exists");
                handleErrorNotificationClick();
                return;
            }
        } else {
            setErrorMessages("Failed to retrieve existing courses");
            handleErrorNotificationClick();
            return;
        }



        // Proceed to add the course
        let obj = {};

        if (semesterEnabled === true) {
            obj = {
                courseName: courseName,
                durationType: durationType,
                duration: duration,
                semesterEnabled: semesterEnabled,
                noOfSemesters: noOfSemesters,
                semesters: semesters
            }
        } else {
            obj = {
                courseName: courseName,
                durationType: durationType,
                duration: duration,
                semesterEnabled: false,
            };
        }


        const response = await fetch(`${baseUrl}/api/v1/addCourse`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "POST",
            body: JSON.stringify(obj),

        });

        const data = await response.json();
        console.log(data, "Added Courses >>>>>>>>>>>");
        if (response.status === 201) {
            setShowCourseModal(false);
            setMessage("Course Added Successfully");
            setDuration("");
            setCourseName("");
            setDurationType("");
            setSemesterEnabled(false);
            setNoOfSemesters("");
            setSemesterStart1("");
            setSemesterEnd1("");
            setSemesterStart2("");
            setSemesterEnd2("");
            setSemesterStart3("");
            setSemesterEnd3("");
            setSemesterStart4("");
            setSemesterEnd4("");
            setSemesters([]);

            handleSuccessNotificationClick();

            getCourses();
        } else if (response.status === 400) {
            setErrorMessages(data.message);
            handleErrorNotificationClick();
        }
    };

    const updateCourse = async () => {
        let newSemesters = [];
        if (courseName === "") {
            toast.error("Please Enter Course Name");
            setErrorMessages("Please Enter Course Name");
            handleErrorNotificationClick();
            return;
        }
        if (durationType === "") {
            toast.error("Please Select Duration Type");
            setErrorMessages("Please Select Duration Type");
            handleErrorNotificationClick();
            return;
        }
        if (duration === "") {
            toast.error("Please Enter Duration");
            setErrorMessages("Please Enter Duration");
            handleErrorNotificationClick();
            return;
        }
        if (semesterEnabled === true) {
            if (noOfSemesters === "") {
                toast.error("Please Enter No Of Semesters");
                setErrorMessages("Please Enter No Of Semesters");
                handleErrorNotificationClick()
                return
            }
            if (noOfSemesters === "1") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester End Date");
                    handleErrorNotificationClick()
                    return
                }
                newSemesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` });
            }
            if (noOfSemesters === "2") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester 1 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester 1 End Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterStart2 === "") {
                    toast.error("Please Enter Semester Start 2");
                    setErrorMessages("Please Enter Semester 2 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd2 === "") {
                    toast.error("Please Enter Semester End 2");
                    setErrorMessages("Please Enter Semester 2 End Date");
                    handleErrorNotificationClick()  
                    return
                }
                newSemesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` }, { "Semester 2": `${semesterStart2} - ${semesterEnd2}` });
            }
            if (noOfSemesters === "3") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester 1 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester 1 End Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterStart2 === "") {
                    toast.error("Please Enter Semester Start 2");
                    setErrorMessages("Please Enter Semester 2 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd2 === "") {
                    toast.error("Please Enter Semester End 2");
                    setErrorMessages("Please Enter Semester 2 End Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterStart3 === "") {
                    toast.error("Please Enter Semester Start 3");
                    setErrorMessages("Please Enter Semester 3 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd3 === "") {
                    toast.error("Please Enter Semester End 3");
                    setErrorMessages("Please Enter Semester 3 End Date");
                    handleErrorNotificationClick()
                    return
                }
                newSemesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` }, { "Semester 2": `${semesterStart2} - ${semesterEnd2}` }, { "Semester 3": `${semesterStart3} - ${semesterEnd3}` });
            }
            if (noOfSemesters === "4") {
                if (semesterStart1 === "") {
                    toast.error("Please Enter Semester Start 1");
                    setErrorMessages("Please Enter Semester 1 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd1 === "") {
                    toast.error("Please Enter Semester End 1");
                    setErrorMessages("Please Enter Semester 1 End Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterStart2 === "") {
                    toast.error("Please Enter Semester Start 2");
                    setErrorMessages("Please Enter Semester 2 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd2 === "") {
                    toast.error("Please Enter Semester End 2");
                    setErrorMessages("Please Enter Semester 2 End Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterStart3 === "") {
                    toast.error("Please Enter Semester Start 3");
                    setErrorMessages("Please Enter Semester 3 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd3 === "") {
                    toast.error("Please Enter Semester End 3");
                    setErrorMessages("Please Enter Semester 3 End Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterStart4 === "") {
                    toast.error("Please Enter Semester Start 4");
                    setErrorMessages("Please Enter Semester 4 Start Date");
                    handleErrorNotificationClick()
                    return
                }
                if (semesterEnd4 === "") {
                    toast.error("Please Enter Semester End 4");
                    setErrorMessages("Please Enter Semester 4 End Date");
                    handleErrorNotificationClick()
                    return
                }
                newSemesters.push({ "Semester 1": `${semesterStart1} - ${semesterEnd1}` }, { "Semester 2": `${semesterStart2} - ${semesterEnd2}` }, { "Semester 3": `${semesterStart3} - ${semesterEnd3}` }, { "Semester 4": `${semesterStart4} - ${semesterEnd4}` });
            }
        }
        if (durationType === "Year") {
            if (parseInt(duration) > 7) {
                setErrorMessages("Duration Should Be Less Than 7");
                handleErrorNotificationClick()
                return
            }
        }
        let obj = {};
        if (semesterEnabled === true) {
            obj = {
                courseId: courseId,
                courseName: courseName, 
                durationType: durationType,
                duration: duration,
                semesterEnabled: semesterEnabled,
                noOfSemesters: noOfSemesters,
                semesters: newSemesters
            }
        } else {
            obj = {
                courseId: courseId,
                courseName: courseName,
                durationType: durationType,
                duration: duration,
                semesterEnabled: false,
            };
        }
        const response = await fetch(`${baseUrl}/api/v1/update_course?courseId=${courseId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "POST",
            body: JSON.stringify(obj),
        });
        const data = await response.json();
        console.log(data, "Updated Courses >>>>>>>>>>>");
        if (response.status === 201 || response.status === 200) {
            setShowCourseModal(false);
            setMessage("Course Updated Successfully");
            setDuration("");
            setCourseName("");
            setDurationType("");
            setSemesterEnabled(false);
            setNoOfSemesters("");
            setSemesterStart1("");
            setSemesterEnd1("");
            setSemesterStart2("");
            setSemesterEnd2("");
            setSemesterStart3("");
            setSemesterEnd3("");
            setSemesterStart4("");
            setSemesterEnd4("");
            setSemesters([]);
            handleSuccessNotificationClick();
            getCourses();
        } else if (response.status === 400) {
            setErrorMessages(data.message);
            handleErrorNotificationClick();
        }
    };


    const MoveServiceForm = () => {
        props.next();
    };

    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            {schoolTypeName?.includes("University") ? "Courses & Programs" : "Grades & Streams"}
        </Typography>
    ];
    useEffect(() => {
        if (extractedString === "grades") {
            setShowCourseModal(false)
        }
    }, [extractedString])






    return (
        <>

            <>

                {schoolTypeName?.includes("University/") ? <>
                    {extractedString === "grades" ? <>
                        <Box component="div" sx={{ marginY: 2 }}>
                            <Breadcrumbs
                                separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                                aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>

                    </> : null}
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "space-between" }}>
                        <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignContent: "space-between", width: "100%", alignSelf: "center", marginTop: "30px", marginLeft: "3rem" }}>
                            <Box component="div" sx={{ display: "flex", width: "100%", alignSelf: "center" }}>
                                {/* <CustomSearchInput /> */}
                            </Box>
                            <Button style={{ backgroundColor: "#032541", fontSize: "12px", color: "#fff", textTransform: "capitalize", width: "120px", height: "40px", marginRight: "30px" }} onClick={() => setShowCourseModal(true)}>Add Course</Button>
                        </Box>
                    </Box>
                    {/* Add course Modal */}

                    {showCourseModal === true ?
                        <>
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: 2, width: "100%", marginLeft: "100px", marginTop: "30px" }}>
                                <Box component="div" sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%", alignSelf: "center", alignContent: "center" }}>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignSelf: "center" }}>
                                        Add Course / Programs
                                    </Box>
                                </Box>

                                <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%", alignSelf: "center", alignContent: "center" }}>
                                    <CustomInputLabel required={true} label={"course Name"} />
                                    <CustomTextField value={courseName} onChange={(e) => setCourseName(e.target.value)} />

                                    <Box sx={{ display: "flex", justifyContent: "" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column", }}>
                                            <CustomInputLabel required={true} label={"Duration Type"} />
                                            <CustomSelectField value={durationType} options={durationTypesOptions} onChange={(e) => {
                                                setDurationType(e.target.value)
                                                if (e.target.value === "Years") {
                                                    setSemesterEnabled(true)
                                                }
                                                else {
                                                    setSemesterEnabled(false)
                                                }
                                            }
                                            } />
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>

                                            <CustomInputLabel required={true} label={"Duration"} />
                                            <CustomTextField value={duration} onChange={(e) => setDuration(e.target.value)} />
                                        </Box>

                                    </Box>
                                    {durationType === "Years" ? <Box sx={{ display: "flex", flexDirection: "column", }}>
                                        <CustomInputLabel required={true} label={"No Of Semesters"} />
                                        <CustomSelectField value={noOfSemesters} options={semestersOptions} onChange={(e) => setNoOfSemesters(e.target.value)} />

                                        {/* if noOfSemesters=== 1 render 1 input with start date if noOfSemesters === 2 render 2 inputs with start date and end date */}
                                        {
                                            noOfSemesters === "1" ?
                                                <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                    <Box sx={{ display: "flex", }}>
                                                        {/* Start semester one */}
                                                        <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                            <CustomInputLabel label={"semester 1"} />

                                                            <CustomInputLabel required={true} label={" Start "} />

                                                            <CustomSelectField value={semesterStart1} options={monthsOptions} onChange={(e) => setSemesterStart1(e.target.value)} />

                                                        </Box>
                                                        {/* End semester one */}
                                                        <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>

                                                            <CustomInputLabel required={true} label={" End "} />

                                                            <CustomSelectField value={semesterEnd1} options={monthsOptions} onChange={(e) => setSemesterEnd1(e.target.value)} />

                                                        </Box>

                                                    </Box>
                                                </Box>
                                                : noOfSemesters === "2" ?
                                                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                        <Box sx={{ display: "flex", }}>
                                                            {/* Start semester one */}
                                                            <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                <CustomInputLabel label={"semester 1"} />
                                                                <CustomInputLabel required={true} label={" Start "} />

                                                                <CustomSelectField value={semesterStart1} options={monthsOptions} onChange={(e) => setSemesterStart1(e.target.value)} />
                                                            </Box>
                                                            {/* End semester one */}
                                                            <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>

                                                                <CustomInputLabel required={true} label={" End "} />

                                                                <CustomSelectField value={semesterEnd1} options={monthsOptions} onChange={(e) => setSemesterEnd1(e.target.value)} />
                                                            </Box>

                                                        </Box>
                                                        <Box sx={{ display: "flex", }}>
                                                            {/* Start semester two */}
                                                            <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                <CustomInputLabel label={"Semester 2"} />
                                                                <CustomInputLabel required={true} label={" Start "} />

                                                                <CustomSelectField value={semesterStart2} options={monthsOptions} onChange={(e) => setSemesterStart2(e.target.value)} />
                                                            </Box>
                                                            {/* End semester two */}
                                                            <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>
                                                                <CustomInputLabel label={""} />
                                                                <CustomInputLabel required={true} label={" End "} />

                                                                <CustomSelectField value={semesterEnd2} options={monthsOptions} onChange={(e) => setSemesterEnd2(e.target.value)} />
                                                            </Box>
                                                        </Box>



                                                    </Box>
                                                    : noOfSemesters === "3" ?
                                                        <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                            <Box sx={{ display: "flex", }}>
                                                                {/* Start semester one */}
                                                                <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                    <CustomInputLabel label={"Semester 1"} />

                                                                    <CustomInputLabel required={true} label={" Start "} />

                                                                    <CustomSelectField value={semesterStart1} options={monthsOptions} onChange={(e) => setSemesterStart1(e.target.value)} />
                                                                </Box>
                                                                {/* End semester one */}
                                                                <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>

                                                                    <CustomInputLabel required={true} label={" End "} />

                                                                    <CustomSelectField value={semesterEnd1} options={monthsOptions} onChange={(e) => setSemesterEnd1(e.target.value)} />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: "flex", }}>
                                                                {/* Start semester two */}
                                                                <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                    <CustomInputLabel label={"Semester 2"} />
                                                                    <CustomInputLabel required={true} label={" Start "} />

                                                                    <CustomSelectField value={semesterStart2} options={monthsOptions} onChange={(e) => setSemesterStart2(e.target.value)} />
                                                                </Box>
                                                                {/* End semester two */}
                                                                <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>
                                                                    <CustomInputLabel label={""} />
                                                                    <CustomInputLabel required={true} label={" End "} />

                                                                    <CustomSelectField value={semesterEnd2} options={monthsOptions} onChange={(e) => setSemesterEnd2(e.target.value)} />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: "flex", }}>
                                                                {/* Start semester three */}
                                                                <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                    <CustomInputLabel label={"semester 3"} />
                                                                    <CustomInputLabel required={true} label={" Start "} />

                                                                    <CustomSelectField value={semesterStart3} options={monthsOptions} onChange={(e) => setSemesterStart3(e.target.value)} />
                                                                </Box>
                                                                {/* End semester three */}
                                                                <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>
                                                                    <CustomInputLabel label={""} />
                                                                    <CustomInputLabel required={true} label={" End "} />

                                                                    <CustomSelectField value={semesterEnd3} options={monthsOptions} onChange={(e) => setSemesterEnd3(e.target.value)} />
                                                                </Box>
                                                            </Box>

                                                        </Box>
                                                        : noOfSemesters === "4" ?
                                                            <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                <Box sx={{ display: "flex", }}>
                                                                    {/* Start semester one */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                        <CustomInputLabel label={"semester 1"} />



                                                                        <CustomInputLabel required={true} label={" Start "} />

                                                                        <CustomSelectField value={semesterStart1} options={monthsOptions} onChange={(e) => setSemesterStart1(e.target.value)} />
                                                                    </Box>
                                                                    {/* End semester one */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>

                                                                        <CustomInputLabel required={true} label={" End "} />

                                                                        <CustomSelectField value={semesterEnd1} options={monthsOptions} onChange={(e) => setSemesterEnd1(e.target.value)} />
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: "flex", }}>
                                                                    {/* Start semester two */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                        <CustomInputLabel label={"Semester 2"} />
                                                                        <CustomInputLabel required={true} label={" Start "} />

                                                                        <CustomSelectField value={semesterStart2} options={monthsOptions} onChange={(e) => setSemesterStart2(e.target.value)} />
                                                                    </Box>
                                                                    {/* End semester two */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>
                                                                        <CustomInputLabel label={""} />
                                                                        <CustomInputLabel required={true} label={" End "} />

                                                                        <CustomSelectField value={semesterEnd2} options={monthsOptions} onChange={(e) => setSemesterEnd2(e.target.value)} />
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: "flex", }}>
                                                                    {/* Start semester three */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                        <CustomInputLabel label={"Semester 3"} />
                                                                        <CustomInputLabel required={true} label={" Start "} />

                                                                        <CustomSelectField value={semesterStart3} options={monthsOptions} onChange={(e) => setSemesterStart3(e.target.value)} />
                                                                    </Box>
                                                                    {/* End semester three */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>
                                                                        <CustomInputLabel label={""} />
                                                                        <CustomInputLabel required={true} label={" End "} />

                                                                        <CustomSelectField value={semesterEnd3} options={monthsOptions} onChange={(e) => setSemesterEnd3(e.target.value)} />
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: "flex", }}>
                                                                    {/* Start semester four */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", }}>
                                                                        <CustomInputLabel label={"Semester 4"} />
                                                                        <CustomInputLabel required={true} label={" Start "} />

                                                                        <CustomSelectField value={semesterStart4} options={monthsOptions} onChange={(e) => setSemesterStart4(e.target.value)} />
                                                                    </Box>
                                                                    {/* End semester four */}
                                                                    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "18px", marginLeft: "20px" }}>
                                                                        <CustomInputLabel label={""} />
                                                                        <CustomInputLabel required={true} label={" End "} />

                                                                        <CustomSelectField value={semesterEnd4} options={monthsOptions} onChange={(e) => setSemesterEnd4(e.target.value)} />
                                                                    </Box>
                                                                </Box>
                                                            </Box> : null}
                                    </Box> : null}

                                </Box>
                            </Box>

                            <Box component="div" sx={{ display: "flex", flexDirection: "", width: "100%", alignSelf: "center", marginLeft: "6rem" }}>
                                <Button style={{ width: "10rem", height: "2.5rem", backgroundColor: "#fff", color: "#DC3545", border: "#032541 solid 1px", textTransform: "none", fontSize: "0.875rem", fontFamily: 'Poppins', fontWeight: 600, borderRadius: "1rem", marginRight: "1rem" }} onClick={() => { setShowCourseModal(false); resetForm(); navigate(-1) }}>
                                    Cancel
                                </Button>
                                {courseId ?

                                    <Button style={{ width: "10rem", height: "2.5rem", backgroundColor: "#032541", color: "#fff", textTransform: "none", fontSize: "0.875rem", fontFamily: 'Poppins', fontWeight: 600, borderRadius: "1rem" }} onClick={() => { updateCourse(); }}>
                                        Update Course
                                    </Button>
                                    : <Button style={{ width: "10rem", height: "2.5rem", backgroundColor: "#032541", color: "#fff", textTransform: "none", fontSize: "0.875rem", fontFamily: 'Poppins', fontWeight: 600, borderRadius: "1rem" }} onClick={() => { addCourse(); }}>
                                        Save
                                    </Button>}

                            </Box>


                        </> :
                        <>
                            <CustomTable data={courses} columns={columnsCourse} rowsPerPage={20} />
                            {extractedString !== "grades" ? <>
                                {courses.length > 0 ?
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginRight: "140px", marginTop: "20px" }}>
                                        <Button style={{ "width": "9.813rem", "height": "2.813rem", "padding": "12.5px 36.7px 12.5px 38.3px", "borderRadius": "4px", "border": "solid 1px #fff", "color": "#fff", "backgroundColor": "#16AE7A", }} onClick={MoveServiceForm}>
                                            Next
                                        </Button>
                                    </Box> : null}
                            </>
                                : null}



                        </>
                    }

                </> :
                    <>
                        {extractedString === "grades" ? <div>
                            <Box component="div" sx={{ marginY: 2 }}>
                                <Breadcrumbs
                                    separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                                    aria-label="breadcrumb">
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Box>
                        </div> : null}
                        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, width: "100%", marginLeft: "100px", marginTop: "30px" }}>
                            <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%", alignSelf: "center" }}>
                                {showStreamsModal ?
                                    <>
                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: "140px", marginTop: "40px" }}>
                                            <CustomTextField value={streamName} placeholder={"Stream Name"} onChange={(e) => setStreamName(e.target.value)} />
                                            <Box sx={{ display: "flex", justifyContent: "" }}>
                                                {/* <Button sx={{ "width": "9.813rem", "height": "2.813rem", "padding": "12.5px 36.7px 12.5px 38.3px", "borderRadius": "4px", "border": "solid 1px #032541", "color": "#032541", "marginRight": "20px", opacity: !streamName.trim() ? "0.1999" : "1" }} onClick={handleChangeStreamName} disabled={!streamName.trim()}>Add Another</Button> */}
                                                <Button sx={{ "width": "9.813rem", "height": "2.813rem", "padding": "12.5px 36.7px 12.5px 38.3px", "borderRadius": "4px", "border": "solid 1px #032541", "color": "#032541", "marginRight": "20px", }} onClick={() => {
                                                    setShowStreamsModal(false)
                                                }}>Cancel</Button>
                                                {updateStreamButton ?
                                                    <Button style={{ width: "10rem", height: "2.5rem", backgroundColor: "#032541", color: "#fff", textTransform: "none", fontSize: "0.875rem", fontFamily: 'Poppins', fontWeight: 600, borderRadius: "1rem" }} onClick={() => { updateStreamName(); }}>
                                                        Update Stream
                                                    </Button> :

                                                    <Button style={{ "width": "9.813rem", "height": "2.813rem", "padding": "12.5px 36.7px 12.5px 38.3px", "borderRadius": "4px", "border": "solid 1px #032541", color: '#fff', background: "#032541" }} onClick={addStreamName} disabled={!streamName.trim()}>Save </Button>
                                                }
                                            </Box>


                                        </Box>
                                    </> :
                                    <div>
                                        <TabContext value={tabValue}>

                                            <Box sx={{ display: "flex", justifyContent: "space-between", marginRight: "140px", }}>
                                                <AntTabs onChange={handleTabChange} aria-label="grades tabs" TabIndicatorProps={{ hidden: false }} textColor="primary" >
                                                    <AntTab label="Streams" style={{ fontSize: "15px", }} value="Streams" />
                                                    <AntTab label="Grades" style={{ fontSize: "15px", marginRight: "80px" }} value="Grades" />

                                                </AntTabs>
                                                <Box component="div" sx={{ marginTop: "15px" }}>
                                                    <Button sx={{ "width": "11.813rem", "height": "2.813rem", "padding": "12.5px 36.7px 12.5px 38.3px", "borderRadius": "4px", "border": "solid 1px #032541", }} onClick={handleStreamsModal}>
                                                        Add Stream
                                                    </Button>

                                                </Box>
                                            </Box>
                                            <Box sx={{ marginTop: "0px" }}>
                                                <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={`Search .... ${tabValue}`} />
                                                <TabPanel value="Grades">
                                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "90%", alignSelf: "center" }}>
                                                        <CustomTable data={grades} columns={columns} rowsPerPage={20} />
                                                    </Box>
                                                </TabPanel>

                                                <TabPanel value="Streams">
                                                    {streams.length > 0 ?
                                                        <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "90%", alignSelf: "center" }}>
                                                            <CustomTable data={streams} columns={columnsStream} rowsPerPage={20} />
                                                        </Box>
                                                        : <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}>
                                                            <Typography variant="h6" component="div" sx={{ color: "#272d3d", fontWeight: "", fontSize: "14px", color: "#6c6c6c" }}>
                                                                Add Streams and track them here
                                                                <Typography variant="h6" component="div" sx={{ color: "#272d3d", fontSize: "14px", color: "#6c6c6c" }}>
                                                                    <p>Do you have stream name? if no, enter grade <br /> name as your stream</p>
                                                                </Typography>

                                                            </Typography>


                                                        </Box>}

                                                </TabPanel>
                                            </Box>
                                            {extractedString === "grades" ? null :
                                                <>
                                                    {streams.length > 0 ?
                                                        <Box sx={{ display: "flex", justifyContent: "flex-end", marginRight: "140px", marginTop: "20px" }}>
                                                            <Button style={{ "width": "9.813rem", "height": "2.813rem", "padding": "12.5px 36.7px 12.5px 38.3px", "borderRadius": "4px", "border": "solid 1px #fff", "color": "#fff", "backgroundColor": "#16AE7A", }} onClick={MoveServiceForm}>
                                                                Next
                                                            </Button>
                                                        </Box>
                                                        : null}
                                                </>
                                            }


                                        </TabContext>
                                    </div>
                                }


                            </Box>
                        </Box>
                    </>
                }
            </>
            <ErrorAlert message={errorMessage} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />
            <SuccessAlert message={message} open={SuccessNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={"right"} vertical={"top"} />

        </>
    )
}

export default SchoolGradesForm;
