import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react";
import HttpComponent from '../../../School/MakeRequest';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import Autocomplete from "@mui/material/Autocomplete";

//breadcrumbs

const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Products / Services Categories
  </Typography>,
  <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Create Categories
  </Typography>
];


export default function PartnerCreateCategory() {
  const navigate = useNavigate()
  const [categoryName, setCategoryName] = useState('')
  const [bsoptions, setBsOptions] = useState([])
  const [businessFound, setBusinessFound] = useState([])
  const [selectedBusinessNo, setSelectedbnssNumber] = useState('')
  const [selectedLedgerOption, setSelectedLedgerOption] = useState()
  const [categoryDescription, setCategoryDescription] = useState('')
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [generalLedgerOptions, setGeneralLedgerOptions] = useState([])
  const [categoryValue, setCategoryValue] = useState("")
  const [selectedExpenseAcc, setSelectedExpenseId] = useState('')
  const [expenseAccounts, setExpenseAccount] = useState([])
  let partnerName = localStorage.getItem("affiliatedPartner");
  let createdBy = localStorage.getItem('group')

  // get partner business

  function getActiveBusinesses() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/getActiveBusinesses`,
        token: localStorage.getItem('X-Authorization'),
        body: {
          module: 'all module',
          partnerName: partnerName
        }
      }).then((data) => {
        if (data?.status === 200) {
          setBusinessFound(data?.response?.data)
        }

      })
    } catch (error) {

    }

  };

  useEffect(() => {
    getActiveBusinesses();
  }, []);


  const createArray = () => {
    if (businessFound) {
      const uniqueStrings = new Set();
      businessFound?.forEach((obj) => {
        let string = `${obj.businessNumber} - ${obj.businessName}`;
        uniqueStrings.add(string);
      });
      setBsOptions(Array.from(uniqueStrings));
    }
  };

  useEffect(() => {
    if (businessFound?.length > 0) createArray();
  }, [businessFound]);


  const [newBusinessNumber, setNewBusinessNumber] = useState('')

  useEffect(() => {
    if (selectedBusinessNo) {
      const regex = /(\d+) -/;
      let match = selectedBusinessNo.match(regex);
      if (match) {
        const extractedNumber = match[1];
        setNewBusinessNumber(extractedNumber)

      }
    }
  }, [selectedBusinessNo]);


  // get expense account

  function getExpenseAccount() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/business/get_general_ledger_expense_accounts`,
        body: {
          businessID: newBusinessNumber
        },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setExpenseAccount(data?.response?.data)
        }

      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getExpenseAccount()
  }, [])


  const formData = {
    businessID: newBusinessNumber,
    categoryDescription: categoryDescription,
    categoryName: categoryName,
    productService: 'Product',
    createdBy: createdBy,
    categoryState: "Active",
    accountId: selectedLedgerOption?.accountId,
    glAccountName: selectedLedgerOption?.name,
    expenseAccountId: selectedExpenseAcc?.accountId,
    glExpenseAccountName: selectedExpenseAcc?.name
  }



  const handleOnChangeLedger = (e) => {
    setSelectedLedgerOption(e.target.value)
  }

  //get general ledger 

  function getGeneralLedgerCategories() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/business/get_general_ledger_accounts`,
        token: localStorage.getItem('X-Authorization'),
        body: {
          businessID: newBusinessNumber
        }
      }).then((data) => {
        if (data.status === 200) {
          setGeneralLedgerOptions(data?.response?.data)
        }
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    getGeneralLedgerCategories()
  }, [])
  //service type

  const [typeOptions, setTypeOptions] = useState([])


  const mapOptions = typeOptions.map((option) => ({ value: option.value, label: option.label }))




  function submitCreateProductCategory() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/createCategory`,
        body: formData,
        token: localStorage.getItem('X-Authorization'),

      }).then((data) => {
        if (data.status === 201) {
          setSuccessShow({ state: true, message: data.response.message })

          setTimeout(() => {
            window.history.back();
          }, 2000)
        } else {
          setErrorShow({ state: true, message: data.response.message })
          setTimeout(() => {
            window.history.back();
          }, 2000)
        }
      })
    } catch (error) {

    }

  }

  const [accountStatus, setAccountStatus] = useState({})

  function getAccountingStatus() {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/v1/check_accounting_status',
        body: {
          businessID: newBusinessNumber
        },
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        if (data.status === 200) {
          setAccountStatus(data.response.data)
        }
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getAccountingStatus()
  }, [newBusinessNumber])

  const { accountingEnabled } = accountStatus

  console.log(accountingEnabled, 'accountingEnabled')


  return (
    <>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Grid container direction={'column'}>
        <Grid item display={'flex'} alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
          <ArrowBackIosIcon onClick={() => navigate(-1)} />
          <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Product / Service Categories</Typography>
        </Grid>
        <Grid item mb={'10px'} mt={'10px'}>
          <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item mb={'10px'} mt={'10px'}>
          <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Create a Category</Typography>
        </Grid>
        <Grid item mt={3} style={{ width: '400px' }}>
          <Autocomplete
            label="Business Id"
            variant="outlined"
            fullWidth
            size="medium"
            value={selectedBusinessNo}
            onChange={(event, newValue) => {
              setSelectedbnssNumber(newValue);
            }}
            options={bsoptions}
            renderInput={(params) => (
              <TextField {...params} label="business id" variant="outlined" />
            )}
          />
        </Grid>
        {newBusinessNumber ? <>
        <Grid item mt={3} mb={2}>
          <TextField id="outlined-basic" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} label="Category Name" style={{ fontWeight: 200, width: "400px" }} />
        </Grid>
        <Grid item mt={2} mb={2}>
          <TextField id="outlined-basic" disabled value={'Product'} label="Category Type" style={{ fontWeight: 200, width: "400px" }} />
        </Grid>
        {accountingEnabled ?
          <Grid item mt={2} mb={2}>
            <FormControl sx={{ minWidth: 400 }}>
              <InputLabel id="demo-simple-select-helper-label">General Ledger Account (Income)</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedLedgerOption}
                onChange={handleOnChangeLedger}
                label="General Ledger Account (Income)"
              >
                {generalLedgerOptions.map((option) => {
                  return (
                    <MenuItem value={option}>{option.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid> : null}
        {accountingEnabled ?
          <Grid item mt={2} mb={2}>
            <FormControl sx={{ minWidth: 400 }}>
              <InputLabel id="demo-simple-select-helper-label">General Ledger Account (Expense) </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedExpenseAcc}
                onChange={(e) => setSelectedExpenseId(e.target.value)}
                label="General Ledger Account (Expense)"
              >
                {expenseAccounts?.map((option) => {
                  return (
                    <MenuItem value={option}>{option?.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid> : null}
        <Grid container>
          <Grid item lg={6} xl={6} md={12} mt={2} mb={2}>
            <TextField value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} id="outlined-basic" label="Category Description" style={{ fontWeight: 200, width: "400px" }} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item style={{ width: "400px" }} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
            <Button style={{ border: "1px solid #dc3545", marginRight: '10px', color: "#dc3545", textTransform: 'inherit', width: "100px" }}>Cancel</Button>
            <Button onClick={submitCreateProductCategory} style={!categoryDescription  || !categoryName ? { background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", opacity: .2 } : { background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px" }}>Create</Button>
          </Grid>
        </Grid>
        </>:null}
      </Grid>
    </>
  )

}