import { Typography, Grid, Breadcrumbs, FormControl, InputLabel, Select, Button, TextField, MenuItem } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import { useNavigate } from 'react-router-dom'
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>ZPM Account</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Account</Typography>,
]

export default function ZpmMasterAccountSetUp() {
    const [accountSetUp, setAccountSetUp] = useState(["Vooma Till", "KCB Account Number"]);
    const [accountOwner, setAccountOwner] = useState(["ZED", "SCHOOL"]);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [tillNo, setTillNo] = useState("");
    const [verifyTill, setVerifyTill] = useState("");
    const [accountToSetup, setAccountToSetUp] = useState("");
    const [accountOwnerToSetup, setAccountOwnerToSetUp] = useState("");
    const [kcbaccountType, setAccountType] = useState("")
    const [bankNumber, setAccountNumber] = useState("");
    const [verifyAccountNumber, setVerifyAccountNumber] = useState("")
    const [banksCodes, setBankCodes] = useState([])
    const [bankCode, setBankCode] = useState('')
    const [foundBankName, setFoundBankName] = useState('')
    const [newBankNameAccount, setnewBankNameAccount] = useState('')
    const [verifynewBankNameAccount, setverifynewBankNameAccount] = useState('')
    const [beneficiaryDetails, setbeneficiaryDetails] = useState('')
    const navigate = useNavigate()


    //get params from url

    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);
    const [loading, setLoading] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);

    const [queryParamValue, businessNumber, businessName] = newqueryParamValue.split("?")

    function getBankCodes() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transfer_banks`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setBankCodes(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getBankCodes()
    }, [])

    useEffect(() => {
        if (businessNumber) {
            fetchPaymentDetails()
        }
    }, [businessNumber])

    useEffect(() => {
        if (accountOwnerToSetup) {
            fetchAdditionalDetails();
        }
    }, [accountOwnerToSetup]);

    async function fetchPaymentDetails() {
        setLoading(true);
        try {
            const response = await HttpComponent({
                method: 'POST',
                url: `/api/v1/get_account_owner?businessNo=${businessNumber}`,
                token: localStorage.getItem('X-Authorization')
            });
            
            console.log('response...', response);
    
            if (response && response.response) {
                setAccountOwnerToSetUp(response.response.data.accountOwner);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }
    
    async function fetchAdditionalDetails() {
        setLoading(true);
        try {
            const response = await HttpComponent({
                method: 'POST',
                url: `/api/v1/get_payments_details?type=pocketmoney&businessNo=${businessNumber}`,
                token: localStorage.getItem('X-Authorization')
            });
    
            console.log('response------', response);
    
            if (response?.response?.success === true) {
                console.log('response.data------', response.response.data);
    
                const { data } = response.response;
                if (data && data.kcbDarajaConfigId) {
                    const {kcbAccountType,accountNumber,} = data.kcbDarajaConfigId;
                    setPaymentDetails({
                        ...data,
                    });
    
                    if (kcbAccountType === "KCBACCOUNT") {
                        setAccountToSetUp("KCB Account Number");
                        setAccountNumber(accountNumber);
                        setVerifyAccountNumber(accountNumber) 
                    } else if (kcbAccountType === "VOOMATILL") {
                        setAccountToSetUp("Vooma Till");
                        setTillNo(accountNumber);
                        setVerifyTill(accountNumber);
                    } else {
                        console.error('Unexpected account type:', kcbAccountType);
                        setErrorShow({ state: true, message: "Invalid account type format" });
                    }
    
                } else {
                    console.error('Unexpected response structure:', data);
                    setErrorShow({ state: true, message: "Invalid payment details format" });
                }
            } else {
                // setErrorShow({ state: true, message: "Error fetching payment details" });
            }
        } catch (error) {
            console.error('Error fetching payment details:', error);
            setErrorShow({ state: true, message: "Error fetching payment details" });
        } finally {
            setLoading(false);
        }
    } 

    useEffect(() => {
        if (accountToSetup === "Vooma Till") {
            setAccountType("VOOMATILL")
        } else if (accountToSetup === 'KCB Account Number') {
            setAccountType("KCBACCOUNT")
        }
    }, [accountToSetup])

    // handle bank on change

    const handleBankOnChange = (e) => {
        const valueFound = e.target.value
        const selectedValue = banksCodes?.find(item => item?.bankCode === valueFound);
        if (selectedValue) {
            const { bankCode, bankName } = selectedValue
            setFoundBankName(bankName)
            setBankCode(bankCode)
        }
    }

    const formSubmitObject = {
        businessName: businessName,
        accountNumber: bankNumber,
        accountOwner: "ZED",
        beneficiaryBankCode: bankCode,
        beneficiaryBankName: foundBankName,
        beneficiaryDetails: beneficiaryDetails,
        businessNumber: businessNumber,
        beneficiaryAccount: newBankNameAccount,
        accountType: "pocketmoney",
        kcbAccountType: "KCBACCOUNT"
    }

    const formSubmitObjectSchool = {
        businessName: businessName,
        accountNumber: kcbaccountType === "VOOMATILL" ? tillNo : bankNumber,
        accountOwner: "SCHOOL",
        businessNumber: businessNumber,
        accountType: "pocketmoney",
        kcbAccountType: kcbaccountType
    }


    function handleSetZPMAccountByAdmin() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/payments/activate_kcb_mpesa`,
                body: formSubmitObject,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201  ) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
                setTimeout(() => {
                    navigate(-1)
                }, 1500)
            })
        } catch (error) {

        }
    }

    function handleSetZPMAccountSchoolByAdmin() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/payments/activate_kcb_mpesa`,
                body: formSubmitObjectSchool,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
                setTimeout(() => {
                    navigate(-1)
                }, 1500)
            })
        } catch (error) {

        }
    }

    console.log(formSubmitObjectSchool, 'formSubmitObjectSchool')


    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {/* <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Accounts</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid> */}

            <Grid item mt={2}>
                <Typography>ZPM Master Account</Typography>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Account Owner</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={accountOwnerToSetup} label="Account Owner" onChange={(e) => setAccountOwnerToSetUp(e.target.value)}>
                        {accountOwner?.map((acc) => {
                            return <MenuItem value={acc}>{acc}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                {accountOwnerToSetup === 'SCHOOL' ?
                    <Grid container width={'100%'}>
                        <Grid item width={'100%'} mt={2}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Setup Mode</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={accountToSetup} label="Setup Mode" onChange={(e) => setAccountToSetUp(e.target.value)}>
                                    {accountSetUp?.map((acc) => {
                                        return <MenuItem value={acc}>{acc}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {accountToSetup === "Vooma Till" && (
                            <Grid container display={'flex'} width={'100%'} gap={2}>
                                <Grid item width={'48%'}>
                                    <FormControl style={{ width: "100%" }}>
                                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px" }}>Vooma Till No.</InputLabel>
                                        <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" value={tillNo} onChange={(e) => setTillNo(e.target.value)} multiline placeholder="e.g 123456" required />
                                    </FormControl>
                                </Grid>
                                <Grid item width={'49%'}>
                                    <FormControl style={{ width: "100%", }}>
                                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify Vooma Till No.</InputLabel>
                                        <TextField style={{ width: "100%", marginTop: "40px" }} error={tillNo !== verifyTill ? true : false} id="outlined-multiline-flexible" placeholder="e.g 123456" value={verifyTill} onChange={(e) => setVerifyTill(e.target.value)} multiline required />
                                    </FormControl>
                                </Grid>
                            </Grid>

                        )}
                        {accountToSetup === "KCB Account Number" && (
                            <Grid item width={'100%'}>
                                <Grid container display={'flex'} gap={2} width={'100%'}>
                                    <Grid item width={'48%'}>
                                        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>KCB Account Number</InputLabel>
                                            <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-" value={bankNumber} onChange={(e) => setAccountNumber(e.target.value)} placeholder="e.g 123456" multiline required />
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={'49%'}>
                                        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify KCB Account Number</InputLabel>
                                            <TextField style={{ width: "100%", marginTop: "40px" }} value={verifyAccountNumber} onChange={(e) => setVerifyAccountNumber(e.target.value)} error={bankNumber !== verifyAccountNumber ? true : false} id="outlined-" placeholder="e.g 123456" multiline required />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                    </Grid> : accountOwnerToSetup === 'ZED' ?
                        <Grid container width={'100%'} direction={'column'}>
                            <Grid item width={'100%'}>
                                <Grid container display={'flex'} gap={2} width={'100%'}>
                                    <Grid item width={'48%'}>
                                        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Account Number</InputLabel>
                                            <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-" value={bankNumber} onChange={(e) => setAccountNumber(e.target.value)} placeholder="e.g 123456" multiline required />
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={'49%'}>
                                        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify Account Number</InputLabel>
                                            <TextField style={{ width: "100%", marginTop: "40px" }} value={verifyAccountNumber} onChange={(e) => setVerifyAccountNumber(e.target.value)} error={bankNumber !== verifyAccountNumber ? true : false} id="outlined-" placeholder="e.g 123456" multiline required />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item mt={2}>
                                <Typography>School / Shop Account</Typography>
                            </Grid>
                            <Grid item width={'100%'} mt={1}>
                                <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                    <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Account Name</InputLabel>
                                    <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-" value={beneficiaryDetails} onChange={(e) => setbeneficiaryDetails(e.target.value)} placeholder="Account Name" multiline required />
                                </FormControl>
                            </Grid>
                            <Grid item mt={1} width={'100%'} display={'flex'} alignItems={'center'}>
                                <Grid container display={'flex'} gap={2} width={'100%'} alignItems={'center'}>
                                    <Grid item width={'48%'}>
                                        <FormControl style={{ width: '100%', marginTop: '20px' }}>
                                            <InputLabel id="demo-simple-select-label">Select Bank</InputLabel>
                                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={bankCode} label="Select Bank" onChange={handleBankOnChange}>
                                                {banksCodes?.map((acc) => {
                                                    return <MenuItem value={acc?.bankCode}>{acc?.bankName}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={'49%'}>
                                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Bank Name</InputLabel>
                                        <TextField disabled style={{ width: "100%" }} value={foundBankName} id="outlined" placeholder="KCB" multiline required />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item width={'100%'} mt={1}>
                                <Grid container display={'flex'} gap={2} width={'100%'}>
                                    <Grid item width={'48%'}>
                                        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Account Number</InputLabel>
                                            <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-" value={newBankNameAccount} onChange={(e) => setnewBankNameAccount(e.target.value)} placeholder="e.g 123456" multiline required />
                                        </FormControl>
                                    </Grid>
                                    <Grid item width={'49%'}>
                                        <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify Account Number</InputLabel>
                                            <TextField style={{ width: "100%", marginTop: "40px" }} value={verifynewBankNameAccount} onChange={(e) => setverifynewBankNameAccount(e.target.value)} error={newBankNameAccount !== verifynewBankNameAccount ? true : false} id="outlined-" placeholder="e.g 123456" multiline required />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> : null
                }
            </Grid>
            {accountOwnerToSetup === 'ZED' ?
                <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                    <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                    <Button onClick={handleSetZPMAccountByAdmin} disabled={!foundBankName || !businessNumber || !newBankNameAccount || !bankCode || !foundBankName || !bankNumber || !beneficiaryDetails || !businessName} style={!foundBankName || !businessNumber || !newBankNameAccount || !bankCode || !foundBankName || !bankNumber || !beneficiaryDetails || !businessName ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
                </Grid> : accountOwnerToSetup === 'SCHOOL' ?

                <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                        <Button onClick={handleSetZPMAccountSchoolByAdmin} disabled={!businessNumber || !businessName || !kcbaccountType} style={!businessNumber || !bankNumber || !businessName || !kcbaccountType ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
                </Grid> : null
            }
        </Grid>
    )
}