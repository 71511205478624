import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events:[
    {
        title: "Pulling data...",
        start: "2023-11-02",
        customInfo: "",
      },
  ]
}


const CalendarSlice = createSlice({
  name:"calendarEvents",
  initialState,
  reducers:{
    handleEvents:(state, action) => {
      state.events = action.payload
    },
  }
})

export const { handleEvents  } = CalendarSlice.actions;

export default CalendarSlice.reducer