import { Breadcrumbs, Button, Grid, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HttpComponent from "../../../School/MakeRequest";
import CurrencyFormatter from "../../../../utils/currencyFormatter";
import backImg from "../../../School/Images/back-icn.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../../School/CustomTable";
import DateFormatter from "../../../../utils/dateFormatter";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { makeStyles } from "@mui/styles";

// Bread Crumbs
const breadcrumbs = [
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Tenants</Typography>,
	<Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Tenant Profile</Typography>,
	<Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Preview Statement</Typography>
];

const columns = [
	{ field: 'timeAndDate', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Date</strong>) }, renderCell: (params) => { return (DateFormatter(params.value)) } },
	{ field: 'transactionType', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Transaction Type</strong>) } },
	{ field: 'paymentMode', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Payment Mode</strong>) } },
	{ field: 'amountCharged', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Amount Charged</strong>) }, renderCell: (params) => { return (CurrencyFormatter(params.value, 'KES')) } },
	{ field: 'amountPaid', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Amount Paid</strong>) }, renderCell: (params) => { return (CurrencyFormatter(params.value, 'KES')) } },
	{ field: 'balanceAmount', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541' }} >Balance</strong>) }, renderCell: (params) => { return (CurrencyFormatter(params.value, 'KES')) } },
]

const useStyles = makeStyles((theme) => ({
	address_text_common: {
		fontSize: "13px",
		fontWeight: "300",
	},
	table_titles: {
		fontSize: "14px",
		fontWeight: "700",
	},
	table_body: {
		fontSize: "13px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	footer_text: {
		fontSize: "10px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	totals: {
		fontSize: "14px",
		padding: theme.spacing(0.25),
	},
	p1: {
		padding: theme.spacing(0.25),
	},
	p2: {
		padding: theme.spacing(0.5),
	},
	p3: {
		padding: theme.spacing(1),
	},
	mt4: {
		marginTop: theme.spacing(4),
	},
	m1: {
		margin: theme.spacing(1),
	},
	textLeft: {
		textAlign: "left",
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
}));


export default function TenantStatement() {
		const { firstName, lastName, customerType } = useSelector((store) => store.tenantData.tenantBioData)
		const [tenantDetails, setTenantDetails] = useState([])

		const navigate = useNavigate()
		const { X_Authorization } = useSelector((store) => store.user)
		const [businessInfo, setBusinessInfo] = useState({})
		const classes = useStyles()
		const [downloadStatement, setDownloadStatement] = useState(false)

		const handleDownloadStatement = () => {
			setDownloadStatement(true)
		}

		let { businessName, businessOwnerAddress } = businessInfo

		//get details of the business details

		function fetchBusinessDetails() {
			try {
				HttpComponent({
					method: 'POST',
					body: null,
					url: `/api/get_business_info`,
					token: localStorage.getItem("X-Authorization"),
				}).then((data) => {
					console.log(data, 'fetch business info')
					setBusinessInfo(data.response.data)
				})

			} catch (error) {

			}
		}

		useEffect(() => {
			fetchBusinessDetails()
		}, [])

		// Loader Setter
		const [loader, setLoader] = useState(false)
		const { id } = useParams()

		const tenantId = id
		function fetchTenantDetails() {
			try {
				setLoader(true)
				HttpComponent({
					method: 'GET',
					url: `/api/get_customer_statement?page=1&limit=1000&customerId=${tenantId}`,
					token: X_Authorization
				})
					.then((data) => {
						if (data.status === 200) {
							setLoader(false)
							setTenantDetails(data.response)
						}
					})
					.catch((e) => { console.log(`Error Getting Tenant Data`, e.message) })
			} catch (error) {
				console.log(error.message);
			}
		}

		useEffect(() => {
			fetchTenantDetails();
		}, []);

		console.log(tenantDetails, '')


		//download statement
		const downloadPDF = () => {
			const input = document.getElementById('downloadable');
			html2canvas(input)
				.then((canvas) => {
					const imgData = canvas.toDataURL('image/png', 1.0);
					const inputWidthPx = input.clientWidth;
					const inputHeightPx = input.clientHeight;
					const dpi = window.devicePixelRatio * 96;
					let scaleFactor = 1;
					if (window.innerWidth > 1512) {
						//scaleFactor = 1.4;
						scaleFactor = 1.9
					}

					const inputWidthMm = (inputWidthPx / dpi) * 25.4 / scaleFactor;
					const inputHeightMm = (inputHeightPx / dpi) * 25.4;
					const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
					pdf.addImage(imgData, 'JPEG', 5, 5, inputWidthMm - 10, inputHeightMm - 10);
					pdf.save(`tenantStatement.pdf`);
				})
		}


		return (
			<div>

				{/*Header Container*/}
				<Grid container justifyContent={'space-between'} mt={-3} direction={'column'}>
					<Grid item>
						<img alt="" style={{ marginRight: '10px' }} src={backImg} onClick={() => navigate(-1)} />
						<span style={{ color: '#032541', fontWeight: 'bold', fontSize: '25px' }}>Preview Statement</span>
					</Grid>
					{/*Month Name*/}
					<Grid item>
						{/*<span style={{color : '#032541', fontWeight : 'bold', fontSize : '25px'}}>Preview Statement</span>*/}
						<Grid item>
							<Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>
					</Grid>
				</Grid>

				{/*tenant info*/}
				<Grid container direction={'row'} justifyContent={'space-between'} mt={2}>

					{/*name*/}
					<Grid item>
						<span style={{ color: '#707070', fontSize: '16px' }}>Tenant Name: <span style={{ color: '#032541', fontSize: '16px' }}>{tenantDetails?.customerName}</span></span>
					</Grid>

					{/*Download & share Buttons*/}
					<Grid item>
						<Grid container jsutifyContent={'flex-end'} mt={-2}>

							{/*Download*/}
							<Grid item>
								<Button>
									<FileDownloadOutlinedIcon onClick={() => { handleDownloadStatement(); downloadPDF(); }} style={{ width: '45px', height: '45px', color: '#032541', background: 'rgba(3, 37, 65, 0.05)', borderRadius: '5px' }} />
								</Button>
							</Grid>

							{/*Share*/}
							<Grid item>
								<Button style={{ marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '128px' }} >Share</Button>
							</Grid>

						</Grid>
					</Grid>
				</Grid>
				<div style={{ display: "flex", flexDirection: "column", overflow: "visible" ,height: "auto" }} id="downloadable" >				{/*House Information*/}
					<Grid container justifyContent={'space-between'} aligContent={'center'} direction={'row'} mt={3} mb={2} style={{ background: "#f5f5f5", padding: '20px', borderRadius: "3px" }}>
						{/*TenantType*/}
						<Grid item>
							<Grid container direction={'column'}>
								<Grid item>
									<span style={{ color: "#032541", fontStyle: "14px", fontWeight: 'bold' }}>Tenant Type</span>
								</Grid>
								<Grid item>
									<span style={{ color: "#032541", fontSize: '13px', fontWeight: '300' }}>{tenantDetails?.customerType}</span>
								</Grid>
							</Grid>
						</Grid>

						{/*House No*/}
						<Grid item>
							<Grid container direction={'column'}>
								<Grid item>
									<span style={{ color: "#032541", fontStyle: "14px", fontWeight: 'bold' }}>House No</span>
								</Grid>
								<Grid item>
									<span style={{ color: "#032541", fontSize: '13px', fontWeight: '300' }}>{tenantDetails?.houseNo}</span>
								</Grid>
							</Grid>
						</Grid>

						{/*House Type*/}
						<Grid item>
							<Grid container direction={'column'}>
								<Grid item>
									<span style={{ color: "#032541", fontStyle: "14px", fontWeight: 'bold' }}>House Type</span>
								</Grid>
								<Grid item>
									<span style={{ color: "#032541", fontSize: '13px', fontWeight: '300' }}>{tenantDetails?.houseType}</span>
								</Grid>
							</Grid>
						</Grid>

						{/*Pending Invoice*/}
						<Grid item>
							<Grid container direction={'column'}>
								<Grid item>
									<span style={{ color: "#032541", fontStyle: "14px", fontWeight: 'bold' }}>Pending Invoice</span>
								</Grid>
								<Grid item>
									<span style={{ color: "#032541", fontSize: '13px', fontWeight: '300' }}>{tenantDetails?.pendingInvoices}</span>
								</Grid>
							</Grid>
						</Grid>

						{/*Pending balance*/}
						<Grid item>
							<Grid container direction={'column'}>
								<Grid item>
									<span style={{ color: "#032541", fontStyle: "14px", fontWeight: 'bold' }}>Pending Balance</span>
								</Grid>
								<Grid item>
									<span style={{ color: "#032541", fontSize: '13px', fontWeight: '300' }}>{CurrencyFormatter(tenantDetails?.pendingBalance, 'KES')}</span>
								</Grid>
							</Grid>
						</Grid>

					</Grid>

					<CustomTable fontSize={'10px'} data={tenantDetails?.data} columns={columns} rowsPerPage={5} loading={loader} />
				</div>
				{/* <div id="downloadable">
						<Grid container style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }}  direction={'column'}>
							<Grid item>
								<Grid container display={'flex'} alignContent={'center'} justifyContent={'space-between'}>
									<Grid item>
										<Typography style={{color:"#032541" , fontSize:"14px" , fontWeight:"bold"}}>{businessName}</Typography>
										<Typography>{businessOwnerAddress}</Typography>
										<div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
											<Typography style={{color:"#032541" ,marginRight:"10px", fontSize:"14px" , fontWeight:"bold"}}>Tenant Name :</Typography>
											<Typography>{tenantDetails?.customerName}</Typography>
										</div>
										<div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
											<Typography style={{color:"#032541" ,marginRight:"10px", fontSize:"14px" , fontWeight:"bold"}}>Tenant Type :</Typography>
											<Typography>{tenantDetails?.customerType}</Typography>
										</div>
										<div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
											<Typography style={{color:"#032541" ,marginRight:"10px", fontSize:"14px" , fontWeight:"bold"}}>Tenant Type :</Typography>
											<Typography>{tenantDetails?.houseNo}</Typography>
										</div>
									</Grid>
									<Grid item>
										<Typography style={{color:"#032541" ,fontWeight:"bold" ,fontSize: "42px"}}>STATEMENT</Typography>
										<Typography style={{fontWeight:"bold" ,textAlign:"right" ,fontSize:"23px"}}>Tenant Account</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item direction={'row'} display={'flex'} style={{ borderTop: "2px dotted #bfbfbf", padding: "10px 0px", marginTop: "20px" }} justifyContent={'space-between'}>
								<div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
									<Typography style={{color:"#032541" , fontSize:"14px" , fontWeight:"bold"}}>Tenant Type</Typography>
									<Typography>{tenantDetails?.customerType}</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
									<Typography style={{color:"#032541" , fontSize:"14px" , fontWeight:"bold"}}>House No</Typography>
									<Typography>{tenantDetails?.houseNo}</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
									<Typography style={{color:"#032541" , fontSize:"14px" , fontWeight:"bold"}}>House Type</Typography>
									<Typography>{tenantDetails?.houseType}</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
									<Typography style={{color:"#032541" , fontSize:"14px" , fontWeight:"bold"}}>Pending Invoice</Typography>
									<Typography>{tenantDetails?.pendingInvoices}</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
									<Typography style={{color:"#032541" , fontSize:"14px" , fontWeight:"bold"}}>Pending Balance</Typography>
									<Typography>{CurrencyFormatter(tenantDetails?.pendingBalance, 'KES')}</Typography>
								</div>
							</Grid>
							<Grid item style={{ borderTop: "2px dotted #bfbfbf", marginTop: "20px" }}>
								<Table style={{ marginLeft: '22px' }}>
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: "#032541" }} >Time & Date</TableCell>
											<TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: "#032541" }}>Transaction Type</TableCell>
											<TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: "#032541" }} >Payment Mode</TableCell>
											<TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: "#032541", }}>Amount Charged</TableCell>
											<TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: "#032541", }}>Amount Paid</TableCell>
											<TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: "#032541", }}>Balance</TableCell>
										</TableRow>
									</TableHead>
									{tenantDetails?.data?.map((service, index) => (
										<TableRow key={index}>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14px' }} >{DateFormatter(service?.timeAndDate)}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{service?.transactionType}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{service?.paymentMode}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{service?.amountCharged}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{service?.amountPaid}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{service?.balanceAmount}</TableCell>
										</TableRow>
									))}
								</Table>
							</Grid>
							<Grid item>
							<div style={{ border: "dotted 1px" }}></div>
                                <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                                    <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
                                        This is a system-generated statement is created without any
                                        alteration whatsoever.
                                        <br />
                                        Thank you for your business.
                                    </div>
                                </div>
                                <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }}>
                                    <span className={classes.footer_text}>Powered by <span style={{ fontWeight: "700" }}>ZED Payments Limited</span></span>
                                    <span className={classes.footer_text}>.</span>
                                    <span className={classes.footer_text}>info@zed.business</span>
                                    <span className={classes.footer_text}>.</span>
                                    <span className={classes.footer_text}>v1.0.2</span>
                                </div>
							</Grid>
						</Grid>
					</div> */}
			</div>
		)
}