import { Grid, Stack, Typography, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/modelToupdateSlice";
import { handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));


const baseUrl = process.env.REACT_APP_BASE_URL;


function EditDeviceModel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  const reduxName = useSelector((state) => state.DetailsOfModel.deviceName);
  const reduxType = useSelector((state) => state.DetailsOfModel.deviceType);
  const reduxVersion = useSelector((state) => state.DetailsOfModel.version);

  //value to set when user edits form
  const [deviceName, setDeviceName] = useState(reduxName);
  const [deviceType, setDeviceType] = useState(reduxType);
  const [version, setDeviceVersion] = useState(reduxVersion);

  const handleName = (e) => {
    setDeviceName(e.target.value);
  };
  const handleType = (e) => {
    setDeviceType(e.target.value);
  };
  const handleVersion = (e) => {
    setDeviceVersion(e.target.value);
  };

  const editedBy = localStorage.getItem("username");
  const timestamp = Date.now();
  const date = new Date(timestamp);
  const dateModified = date.toLocaleString();

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});

  const handleUpdate = () => {
    try {
      axios
        .post(
          baseUrl + "/api/editDeviceModel",
          {
            name: reduxName,
            deviceName,
            deviceType,
            version,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.data;
          if (data) {
            console.log("updated successfully");
            setLocalDetails({
              deviceName,
              deviceType,
              version
            });
          } else {
            setFailMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
    //   dispatch(handleBusinessEdited(localDetails));
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/DeviceModels");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Typography
        fontFamily="Poppins"
        fontSize={25}
        fontWeight="600"
        fontStyle="normal"
        textAlign="left"
        color="#032541"
      >
        Edit Device Model
      </Typography>
      <Breadcrumbs2
        point1={"Device Models"}
        link1={"/DeviceModels"}
        point2={"Edit Device Model"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Edit Device Model
            </Typography>
            <TextField
              label="Device Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              className={classes.focusedTextField}
            />
            <TextField
              label="Device Type"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxType}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleType}
              className={classes.focusedTextField}
            />
            <TextField
              label="Version"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxVersion}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleVersion}
              className={classes.focusedTextField}
            />

            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/DeviceModels")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EditDeviceModel;
