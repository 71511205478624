import React, { useState, useEffect } from "react";
import StockFunctions from "../stockEndpoints";
import CustomDataGrid from "../customDataGrid";
import DateFormatter from "../../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";

const CancelledAddStockBatch = () => {
  const navigate = useNavigate();

  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });

  useEffect(() => {
    const stockfunctions = new StockFunctions();

    stockfunctions.getCancelledAddStockBatch(pageState?.page, dataGridPageSize).then((data) => {
      if (data?.status === 200) {
        setPageState({ ...pageState, isLoading: false, data: data?.response?.data, count: data?.response?.count });
      }
    });
  }, [pageState?.page, dataGridPageSize]);

  const cancelledAddStockColumn = [
    {
      field: "batchNumber",
      headerName: "Batch No",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ color: "red", cursor: "pointer" }} onClick={() => navigate(`/viewmoreStock/approved/${params?.row?.batchId}`)}>
            {params?.row?.batchNumber}
          </span>
        );
      },
    },
    { field: "dateCreated", headerName: "Date Created", flex: 1 },
  ];

  const cancelledAddStockRows = pageState?.data?.map((stock) => ({
    id: stock?._id,
    batchNumber: stock?.batchNumber,
    dateCreated: DateFormatter(stock?.dateCreated),
    batchId: stock?.batchId,
  }));

  return (
    <div>
      <CustomDataGrid column={cancelledAddStockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={cancelledAddStockRows} />
    </div>
  );
};

const CancelledStockTakeBatch = () => {
  const navigate = useNavigate();

  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });

  useEffect(() => {
    const stockfunctions = new StockFunctions();

    stockfunctions.getCancelledStockTakeBatch(pageState?.page, dataGridPageSize).then((data) => {
      if (data?.status === 200) {
        setPageState({ ...pageState, isLoading: false, data: data?.response?.data, count: data?.response?.count });
      }
    });
  }, [pageState?.page, dataGridPageSize]);

  const cancelledAddStockColumn = [
    {
      field: "batchNumber",
      headerName: "Batch No",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ color: "red", cursor: "pointer" }} onClick={() => navigate(`/viewmoreStock/approved/${params?.row?.batchId}`)}>
            {params?.row?.batchNumber}
          </span>
        );
      },
    },
    { field: "dateCreated", headerName: "Date Created", flex: 1 },
  ];

  const cancelledAddStockRows = pageState?.data?.map((stock) => ({
    id: stock?._id,
    batchNumber: stock?.batchNumber,
    dateCreated: DateFormatter(stock?.dateCreated),
    batchId: stock?.batchId,
  }));

  return (
    <div>
      <CustomDataGrid column={cancelledAddStockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={cancelledAddStockRows} />
    </div>
  );
};

const CancelledStockTransferBatch = () => {
  const navigate = useNavigate();

  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });

  useEffect(() => {
    const stockfunctions = new StockFunctions();

    stockfunctions.getCancelledStockTransferBatch(pageState?.page, dataGridPageSize).then((data) => {
      if (data?.status === 200) {
        setPageState({ ...pageState, isLoading: false, data: data?.response?.data, count: data?.response?.count });
      }
    });
  }, [pageState?.page, dataGridPageSize]);

  const cancelledAddStockColumn = [
    {
      field: "batchNumber",
      headerName: "Batch No",
      flex: 1,
      renderCell: (params) => {
        return (
          <span style={{ color: "red", cursor: "pointer" }} onClick={() => navigate(`/viewmoreStock/approved/${params?.row?.batchId}`)}>
            {params?.row?.batchNumber}
          </span>
        );
      },
    },
    { field: "dateCreated", headerName: "Date Created", flex: 1 },
  ];

  const cancelledAddStockRows = pageState?.data?.map((stock) => ({
    id: stock?._id,
    batchNumber: stock?.batchNumber,
    dateCreated: DateFormatter(stock?.dateCreated),
    batchId: stock?.batchId,
  }));

  return (
    <div>
      <CustomDataGrid column={cancelledAddStockColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={cancelledAddStockRows} />
    </div>
  );
};



export { CancelledAddStockBatch, CancelledStockTakeBatch, CancelledStockTransferBatch };
