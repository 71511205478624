import { Box, Breadcrumbs, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import happy from "./Images/happy-icn.svg"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomDropZone from "./CustomDropZone";
import { clearAdditions } from "../../features/customerSlice";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import csvTemplate from "./Parent-Student-Template.csv";
import csvTemplateCampus from "./Campus_Student_Template_data.csv";
import { useSelector } from "react-redux";


const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"Parents"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Parents/Guardians
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Upload File
    </Typography>
];

const UploadParentsStudents = () => {
    const navigate = useNavigate();
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successToast, setSuccessToast] = useState({ state: false, message: "" })
    const showUploaded = () => {
        navigate("/school/parents")
    }
    const showCampusUploaded = () => {
        navigate("/school/students")
    }
    const { schoolTypeDetail } = useSelector((store) => store?.schoolType)
    useEffect(() => {
        const { schoolTypeName, schoolTypeId } = schoolTypeDetail
        setSchoolTypeName(schoolTypeName)
        setSchoolTypeId(schoolTypeId)
    }, [schoolTypeDetail])

    const [schoolTypeName, setSchoolTypeName] = useState('')
    const [schoolTypeId, setSchoolTypeId] = useState('')


    const downloadCsvTemplate = async () => {
        const response = await fetch("./Parent-Student-Template.csv");
        const csvData = await response.text();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([csvData]));
        link.setAttribute("download", "Parent-Student-Template.csv");
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
            <Box component="div" sx={{ display: "flex" }}>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <ArrowBackIosIcon sx={{ color: "#687c8d" }} onClick={() => navigate("/school/parents")} />
                </Box>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: "1.563rem", color: "#032541", fontWeight: 700 }}>
                        Upload File
                    </Typography>
                </Box>
            </Box>
            <Box component="div" sx={{ marginY: 2 }}>
                <Breadcrumbs
                    separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                    aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>

            <Box component="div" sx={{ alignSelf: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Box sx={{ marginRight: "auto", marginLeft: "auto", marginTop: "5%" }}>
                    <a href={schoolTypeName?.includes("University") ?  csvTemplateCampus : csvTemplate} download>
                        <Button sx={{ color: "#032541", fontSize: "16px" }} startIcon={<FileDownloadOutlinedIcon />}>
                            Download CSV Template
                        </Button>
                    </a>
                </Box>
                <Box component="div" sx={{ marginTop: "5%" }}>
                    <CustomDropZone
                        setShowErrorToast={setErrorShow}
                        setShowSuccessToast={setSuccessToast}
                        successAction={schoolTypeName?.includes("University") ? showCampusUploaded : showUploaded}
                        url={"/api/upload_parents_students"} />
                </Box>

                <Box component="div" sx={{ alignSelf: "center", marginTop: 5 }}>
                    <Button onClick={() => navigate("/school/parents")} sx={{
                        alignSelf: "center",
                        backgroundColor: "transparent",
                        height: "2.813rem",
                        width: "15.313rem",
                        border: "solid 1px #dc3545",
                        color: "#dc3545",
                        marginY: 1
                    }}>
                        Cancel
                    </Button>
                </Box>


            </Box>



        </Box>
    );
};

export default UploadParentsStudents;

