/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextField,
  Stack,
  Grid,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import EditedTable from "./EditedTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleRemove } from "../../../../features/EditedConvenienceSlice";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import {
  handleApproved,
  handleDeclined,
} from "../../../../features/AlertSlice";
import { setSearch } from "../../../../features/searchSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Edited() {
  const dispatch = useDispatch();

  const [declineOpen, setDeclineOpen] = React.useState(false);
  const [activateOpen, setActivateOpen] = React.useState(false);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const fee = useSelector((state) => state.businessDetails.fee);
  const accountNo = useSelector((state) => state.businessDetails.accountNo);
  const id = useSelector((state) => state.businessDetails.id);

  const handleOpenDialog = () => {
    setDeclineOpen(true);
  };

  const handleOpenDialog2 = () => {
    setActivateOpen(true);
  };

  const handleClose = () => {
    setDeclineOpen(false);
  };

  const handleClose2 = () => {
    setActivateOpen(false);
  };

  // /

  // /api/v1/business/convenienceFee/enable

  const handleApprove = () => {
    try {
      axios
        .post(
          baseUrl + "/api/adjustConvenienceFee",
          {
            newFee: fee,
            accountNo,
            id,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          if (response) {
            console.log("enabled successfully");
            dispatch(handleApproved(true));
            dispatch(handleRemove(id));
            // navigate("/ConvenienceFeeMgt")
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDecline = () => {
    dispatch(handleDeclined(true));
    dispatch(handleRemove(id));
  };

  useEffect(() => {
    if (clickedItem === "Decline") {
      handleOpenDialog();
    } else if (clickedItem === "Approve") {
      handleOpenDialog2();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  const businessName = useSelector((state) => state.businessDetails.name);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <TextField
              id="input-with-icon-textfield"
              label="Search by businessName"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
            />
            {/* <TextField
              select
              label="Business Type"
              defaultValue="All"
              size="small"
              value={selected}
              onChange={handleSelect}
              sx={{
                width: '150px'
              }}
            >
              {categories?.map((option) => (
                <MenuItem key={option.categoryName} value={option.categoryName}>
                  {option.categoryName}
                </MenuItem>
              ))}
            </TextField> */}
            {/* <Button
              variant="outlined"
              sx={{
                bgcolor: "#f5f6f7",
                borderColor: "#f5f6f7",
                color: "#032541",
                ":hover": {
                  borderColor: "#032541",
                },
                textTransform:"none"
              }}
            >
              Export
              <MoreVertIcon
                sx={{
                  color: "#032541",
                }}
              />
            </Button> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <EditedTable />
        </Grid>
      </Grid>
      <Dialog
        open={declineOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ErrorIcon
                sx={{
                  fontSize: "100px",
                  color: "#dc3545",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Decline Convenience Fee
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Do You Want To Decline Convenience Fee For:{" "}
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {businessName}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleDecline();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#dc3545",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#dc3545",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={activateOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: "100px",
                  color: "#17ae7b",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Approve Convenience Fee
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Do You Want To Approve Convenience Fee For:{" "}
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {businessName}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose2}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleApprove();
              handleClose2();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#17ae7b",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#17ae7b",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Edited;
