import { Box, Checkbox, Collapse, LinearProgress, Pagination, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import startOrderFunction from "./posmethods";
import { Add, FiberManualRecord, KeyboardArrowDownRounded } from "@mui/icons-material";
import DateFormatter from "../../../utils/dateFormatter";
import { currencyconverter } from "../../../common/currencyconverter";
import greenarrowup from "../../../common/images/greenarrowup.svg"
import CustomTableSkeleton from "./customTableSkeleton";
import { useNavigate } from "react-router-dom";


const PaidBills = ({setPaidTotals, dateFrom , dateTo }) => {
  // initialize methods;
  const startOrderFunctions = new startOrderFunction();
  // get all unpaidBills:
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [noOfPages, setPageNumbers] = useState(0);
  // handle pagination;
  const handlePage = (event, value) => {
    setPage(value);
  };

  const [dataGridPageSize, setPageSize] = React.useState(8);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  useEffect(() => {
    setPageState({ ...pageState, isLoading: true });
    startOrderFunctions.getBillsByStatus("paid", page, dataGridPageSize, dateFrom, dateTo).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data?.response?.transaction, count: data?.response?.count, isLoading: false });

        setPageNumbers(Math.ceil(data?.response?.count / dataGridPageSize));
        console.log("ata?.response", data?.response)
        setPaidTotals( data?.response?.total || 0)
      }
    });
  }, [page, dataGridPageSize,dateFrom, dateTo]);

  //   collapse controls;
  const [collapse, setOpenCollapse] = useState(null);


  // HANDLE PAID BILLS;
  

  return (
    <Box>
      {pageState.isLoading && <LinearProgress  color="success" />}
      <TableContainer sx={{ marginBottom: 2 }}>
        <Table>
          <TableHead sx={{ color: "#032541", fontSize: "14px" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Order No</TableCell>
              <TableCell>No of Items</TableCell>
              <TableCell>Served By</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          {pageState.isLoading && (
            <CustomTableSkeleton length={dataGridPageSize} numberOfRows={6} />
          )}

          <TableBody sx={{ color: "#707070" }}>
            {!pageState.isLoading && pageState.data.map((bill, index) => {
              return (
                <>
                  {bill?.childOrders?.length === 0 ? (
                    <TableRow key={index}
                    onClick={() => navigate(`/detailedbill/${bill._id}`)}
                    // sx={{ border: "1px solid #e1d6d6", color: "#707070" }}
                    sx={{ '& > *': { mb:' !important', cursor:'pointer' } }}
                    >
                      <TableCell sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}>
  
                        <FiberManualRecord sx={{color: '#17ae7b', fontSize:'15px'}} />
                        {/* <Add color="#707070" /> */}
                      </TableCell>
                      <TableCell>{bill.orderNumber}</TableCell>
                      <TableCell>{bill.items.length}</TableCell>
                      <TableCell>{bill.cashier}</TableCell>
                      <TableCell>{DateFormatter(bill.createdAt)}</TableCell>
                      <TableCell>{currencyconverter(bill.transamount)}</TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <TableRow key={index} 
                      //  sx={{ border: "1px solid #e1d6d6", color: "#707070" }}
                       sx={{ '& > *': { mb:' !important' } }}
                       >
                        <TableCell sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer", color: "#707070" }}>
                         <img src={greenarrowup} alt={"greenarrow"} />
                        </TableCell>
                        <TableCell>{bill.orderNumber}</TableCell>
                        <TableCell>{bill.items.length}</TableCell>
                        <TableCell>{bill.cashier}</TableCell>
                        <TableCell>{DateFormatter(bill.createdAt)}</TableCell>
                        <TableCell>{currencyconverter(bill.transamount)}</TableCell>
                      </TableRow>

                      <Collapse in={collapse === bill._id} timeout="auto" unmountOnExit>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>Order No</TableCell>
                              <TableCell>No of Items</TableCell>
                              <TableCell>Served By</TableCell>
                              <TableCell>Date & Time</TableCell>
                              <TableCell>Amount</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {bill?.childOrders?.map((childOrder, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell></TableCell>
                                  <TableCell>{childOrder.orderNumber}</TableCell>
                                  <TableCell>{childOrder.items.length}</TableCell>
                                  <TableCell>{childOrder.cashier}</TableCell>
                                  <TableCell>{DateFormatter(childOrder.createdAt)}</TableCell>
                                  <TableCell>{currencyconverter(childOrder.transamount)}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handlePage} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );
};

export default PaidBills;
