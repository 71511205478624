import { Grid, Typography, Button, FormHelperText, TextField, FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { SuccessAlert } from "../../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../../snackBar Alerts/errorAlert";
import HttpComponent from "../../../../School/MakeRequest";
import {useDispatch ,useSelector} from 'react-redux'
import { handleSaveTerminalUsers } from "../../../../../features/businessAddPartner";
export default function AddTerminalUser(props) {
    const [formData, setFormData] = useState({ firstName: "", outlet: '', userName: "", lastName: "", phoneNumber: "", email: "", idNo: "", userType: "" })
    const [outlets, setOutlets] = useState([])
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const dispatch = useDispatch()
    const [userPhone, setPhone] = useState()
    const {businessNumber ,name  , branchId } = useSelector((store)=>store.BusinessPartnerDetails.businessDetails)
    const outletsFound = useSelector((store) => store.BusinessPartnerDetails.outlets)


    //handle onchange

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const phoneValidate = (phone) => {
        const phoneregex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        return phoneregex.test(phone)
    }



    // handle submit

    function handleSubmit() {
        try {
            HttpComponent({
                method:'POST',
                url: `/api/addNewUser`,
                token: localStorage.getItem('X-Authorization'),
                body: {
                    Equitel: "",
                    Paybill: "",
                    Till: "",
                    branchId:branchId,
                    Vooma: "",
                    assignedBusiness:businessNumber,
                    businessLocation: '',
                    businessName: name,
                    businessNumber: businessNumber,
                    storeId: formData.outlet,
                    businessPhone: formData.phoneNumber,
                    businessShortCode: "",
                    firstName: formData.firstName,
                    loginState: "Active",
                    secondName: formData.lastName,
                    userEmail: formData.email,
                    userGroup: "payItUser",
                    userName: formData.userName,
                    userPhone: `+${userPhone}`,
                    userState: "Active",
                    assignToBusiness: "assignedToZedPayIt",
                    userIdNumber: formData.idNo,
                }
            }).then((data)=>{
                if(data?.status === 200 || data?.status === 201){
                    setSuccessShow({state:true , message:data?.response?.message})
                    dispatch(handleSaveTerminalUsers({userName:data?.response?.data?.userName , email:data?.response?.data?.userEmail, businessName:name, userPhone:data?.response?.data?.userPhone ,tellerId:data?.response?.data?.tellerId}))
                    setTimeout(()=>{
                        props.openAddOutlet()
                    },1000)
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })
            
        } catch (error) {
            
        }

    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Terminal User Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='firstName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.firstName} label="FirstName" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='lastName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.lastName} label="LastName" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='idNo' onChange={handleOnChange} style={{ width: "100%" }} value={formData.idNo} label="ID NO" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <PhoneInput inputStyle={{ height: "55px", width: "100%" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />
                        {userPhone && !phoneValidate(userPhone) && (
                            <FormHelperText error>Enter a valid Phone Number</FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='email' onChange={handleOnChange} style={{ width: "100%" }} value={formData.email} label="Email Address" variant="outlined" />
                        {formData.email && !validateEmail(formData.email) && (
                            <FormHelperText error>Enter a valid email</FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='userType' readonly style={{ width: "100%" }} value="ZPM(user)" label="User Type" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }} spacin>
                <TextField id="outlined-basic" name='userName' onChange={handleOnChange} style={{ width: "98%" }} value={formData.userName} label="userName" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <FormControl style={{ width: "98%" }}>
                    <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.outlet}
                        label="Outlets"
                        onChange={handleOnChange}
                        name="outlet"
                    >
                        {outletsFound?.map((item) => (
                            <MenuItem name="outlet" key={item?.outletNameId} value={item?.outletNameId}>{item?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleSubmit} style={!formData.email || !formData.firstName || !formData.userName || !formData.lastName || !formData.idNo || !formData.outlet || !userPhone ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}