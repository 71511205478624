import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Checkbox, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "../Images/back-icn.svg"
import HttpComponent from "../MakeRequest";
import { useSelector, useDispatch, } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';

const baseUrl = process.env.REACT_APP_BASE_URL;

const EquityMobileMoney = ({showEquityMobileMoneySetup}) => {
    const [businessName, setBusinessName] = useState('')
    const [clientId, setClientId] = useState('')
    const [consumerSecrete, setConsumerSecrete] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);


    const [formState, setFormState] = useState(false)
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCheckBoxChange = (event) => {
      setChecked(event.target.checked);
  };

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };


    // card status check
    // const { paypalStatus } = useSelector((store) => store.payment);

    

    // save configured card details
    const saveEquityMobileMoneyDetails = async () => {
        // console.log('businessName',clientId,accessToken)
        if (clientId === ' ') {
            setOpenSnack(true)
            setError(true)
            setmessage('client Id is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (consumerSecrete === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('access token is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);

        } else if (businessName === "") {
            setOpenSnack(true)
            setError(true)
            setmessage('business name is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        }
        else {
            try {
                const response = await fetch(`${baseUrl}/api/activate_equity_mpesa`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        clientId,
                        clientSecret:consumerSecrete,
                        businessName
                    }),
                })
                await response.json().then((data) => {
                    console.log('paypal details data', data);
                    setOpenSnack(true)
                    setError(false)
                    setmessage('Successfully added equity details')
                    // dispatch(setPaypalData())
                    // dispatch(setPaypalStatus(true))
                    // dispatch(handleIsPaypalSetup(true));
                })
            } catch (error) {
                console.log(error);
            }
        }
    }


    const yesStyle = {
      "borderRadius": "4px",
      "backgroundColor": "#032541",
      textTransform : "none",
      textDecoration : "none",
      color:"white",
      '&:hover': {
          backgroundColor: '#032541',
          color: 'white'
      }
    }

 
    const noStyle = {
      "borderRadius":"4px",
      "border":"solid 1px #dc3545",
      "color":"#dc3545",
      textTransform : "none",
      textDecoration : "none"
    }

    const buttonWidthHeight = {
      width:"9.063rem",
      height:"3.125rem"
    }

    const buttonStyles =
      {"width":"7.813rem",
          "height":"2.813rem",
          "borderRadius":"4px",
      }


    const changeFormState = () => {
        if (formState) {
            setFormState(false)

        } if (!formState) {
            setFormState(true)
        }

    }
  return (
    <Box component="form" sx={{ '& .MuiTextField-root': { margin: "20px", paddingTop: "10px" }, }} noValidate autoComplete='off' >
      <form>
        <FormControl style={{ width:'100%' }}>
        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Business Name</InputLabel>
        <TextField style={{ width: "100%", marginTop: "30px"}}
              id="outlined-multiline-flexible"
              placeholder="Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              multiline
              required
          />
        </FormControl>
        <FormControl style={{ width:'100%' }}>
        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Client ID</InputLabel>
        <TextField style={{ width: "100%", marginTop: "30px"}}
              id="outlined-multiline-flexible"
              placeholder="Client ID"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              multiline
              required
          />
        </FormControl>
          <FormControl style={{ width: "100%", marginTop: '-15px'}}>
              <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Consumer Secrete</InputLabel>
              <OutlinedInput
                  style={{ width: "100%", marginTop: "45px", marginLeft:'20px'}}
                  placeholder='Consumer Secrete'
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={consumerSecrete}
                  onChange={(e) => setConsumerSecrete(e.target.value)}
                  endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                          >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                      </InputAdornment>
                  }
              />
          </FormControl>
          <Box component="div" sx={{}}>
              <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
              <span style={{ fontSize:'14px' }}>By ticking, you are agreeing to the terms and conditions.</span>
          </Box>

          <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
            <Box component="div" sx={{alignSelf:"center"}}>
                <Button onClick={()=>showEquityMobileMoneySetup(false)} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                    Cancel
                </Button>

                <Button disabled={!checked} sx={{...yesStyle,...buttonWidthHeight,marginX:1}} onClick={saveEquityMobileMoneyDetails}>
                    Activate
                </Button>
            </Box>
        </Box>
      </form>
      {/* :  */}
          {/* <EditPaypalSetup paypalData={paypalData} setSuccessShow={setSuccessShow}  setErrorShow={setErrorShow} /> } */}
    <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity={ifError === true ? "error" : "success"} sx={{ width: "100%" }}>
            <span>{message}</span>
        </Alert>
    </Snackbar>

</Box>
  )
}

export default EquityMobileMoney;