/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/partnerToUpdateSlice";
import { handleAdded, handleCreated } from "../../../../features/AlertSlice";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function AddPartnerUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  //   const reduxName = useSelector((state) => state.bankDetails.name);
  //   const reduxDescription = useSelector((state) => state.bankDetails.description);

  //value to set when user edits form
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const businessType = useSelector((store)=>store.partnerDetails.partnerBusinessesType)

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleSecondName = (e) => {
    setSecondName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneNo = (e) => {
    setPhoneNo(e);
  };
  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleAdd = () => {
    setLoading(true);
    try {
      axios
        .post(
          baseUrl + "/api/addNewUser",
          {
            firstName,
            secondName,
            userEmail: email,
            userGroup: businessType === 'Transport' ? 'TransportPartner': "Partner",
            userName: username,
            userPhone: "+" + phoneNo,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data;
          if (data) {
            setLoading(false);
            console.log(data.message);
            setLocalDetails({
              firstName,
              secondName,
              userEmail: email,
              userGroup: businessType === 'Transport' ? 'TransportPartner': "Partner",
              userName: username,
              userPhone: "+" + phoneNo,
            });
            dispatch(handleCreated(true));
          } else {
            setFailMessage(response.error);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  let partnerName = useSelector((state) => state.partnerDetails.name);

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      localDetails.partnerName = partnerName ?? partnerName.trim();
      //   console.log('localDetails', localDetails)
      try {
        axios
          .post(baseUrl + "/api/addPartnerUser", localDetails, {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          })
          .then((response) => {
            let data = response.data.data;
            if (data) {
              console.log("partner user added successfully in partner");
              navigate("/Partners");
            } else {
              setFailMessage(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Add Partner User
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Partners"}
        link1={"/Partners"}
        point2={"Add Partner User"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            {loading && (
              <Stack width="100%" color={"#032541"}>
                <LinearProgress color="inherit" />
              </Stack>
            )}
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Input Partner user Details
            </Typography>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleFirstName}
              autoFocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Second Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleSecondName}
              className={classes.focusedTextField}
            />
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleUsername}
              className={classes.focusedTextField}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleEmail}
              className={classes.focusedTextField}
            />

            <PhoneInput
                  required
                  country={"ke"}
                  enableSearch={true}
                  label={"Mobile Number"}
                  value={phoneNo}
                  onChange={handlePhoneNo}
                  multiline
                  inputStyle={{ height: "45px", width: "100%" }}
                />
            {/* <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handlePhoneNo}
              className={classes.focusedTextField}
            /> */}
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/Partners")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddPartnerUser;
