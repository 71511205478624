import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@mui/styles";
import WarehouseIcon from '@mui/icons-material/Warehouse';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function House(props) {
	const classes = useStyles();
	let { businessCat } = useSelector((store) => store.businessCategory);
	if(!businessCat) businessCat = localStorage.getItem("businessCategory");

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')

	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick}>
				<div style={{marginRight : '5%'}}>
					<WarehouseIcon />
				</div>
					<ListItemText primary="House" style={{fontFamily : 'Poppins'}}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					<Link style={{textDecoration: 'none', color : '#fff'}} to="/units">
						<ListItem button className={classes.nested}>
							<ListItemText primary="Units" />
						</ListItem>
					</Link>

					<Link style={{textDecoration: 'none', color : '#fff'}} to="/housingHistory">
						<ListItem button className={classes.nested}>
							<ListItemText primary="Housing History" />
						</ListItem>
					</Link>

				</List>
			</Collapse>
		</div>
	)
}
