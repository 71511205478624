import React, { useState, useEffect } from "react";
import { Grid, Breadcrump, Tab, styled } from "@mui/material";
import { stockStyles } from "./stockStyling";
import Breadcrumbcomponent from "./breadcrumb";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import ActiveStockWarehouse from "./activeStockwarehouse";
import Activestockmainstore from "./activeStockmainstore";
import Activestocksubstore from "./activeStockSubstore";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StockFunctions from "./stockEndpoints";
import { useSelector,  } from "react-redux";

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const Activestock = () => {
  const navigate = useNavigate();

  const { wareHouseState } = useSelector((store) => store.sidebar)

  const [warehouseTabs, setWarehouseTabs] = useState(wareHouseState ? "Warehouse": "Branches");
  const handleWareHouseChange = (event, newTabValue) => {
    setWarehouseTabs(newTabValue);
  };

  // check warehouse status;
  // useEffect(() => {
  //   const stockfunctions = new StockFunctions();
  //   stockfunctions.getWarehouseStatus().then((data) => {
  //     if (data?.status === 201) {
  //       setWarehouseState(data?.response?.data?.warehouseOn);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (wareHouseState) {
      setWarehouseTabs("Warehouse");
    }

    if (wareHouseState === false) {
      setWarehouseTabs("Branches");
    }
  }, [wareHouseState]);

  const breadcrumb = [<span key={1} style={stockStyles.smallTextIntro}>Dashboard</span>, <span key={2} style={stockStyles.smallTextActive}>Active Stock</span>];
  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"} alignItems={"center"}>
        <span style={{ cursor: "pointer" }}>
          <ArrowBackIos onClick={() => navigate(`/stock`)} />
        </span>
        <span style={stockStyles.mediumIntroText}>Stock Management</span>
      </Grid>

      <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"}>
        <Breadcrumbcomponent breadcrumbs={breadcrumb} />
      </Grid>

      <Grid container width={"100%"}>
        <Grid item width={"100%"}>
          <TabContext value={warehouseTabs}>
            <AntTabs onChange={handleWareHouseChange}>
              {wareHouseState && <AntTab label="Warehouse" value="Warehouse" />}

              {wareHouseState && <AntTab label={"Main Store"} value={"Main Store"} />}
              {!wareHouseState && <AntTab label={"Branches"} value={"Branches"} />}

              {wareHouseState && <AntTab label="Sub Store" value="Substores" />}

              {/* <AntTab label="Expired" value="Expired" /> */}
            </AntTabs>

            {/* active tab panel display */}
            {wareHouseState && (
              <TabPanel value={"Warehouse"}>
                <ActiveStockWarehouse />
              </TabPanel>
            )}

            {wareHouseState && (
              <TabPanel value={wareHouseState ? "Main Store" : "Branches"}>
                <Activestockmainstore />
              </TabPanel>
            )}

            {!wareHouseState && (
              <TabPanel value={wareHouseState ? "Branches" : "Branches"}>
                <Activestockmainstore />
              </TabPanel>
            )}

            {wareHouseState && (
              <TabPanel value={"Substores"}>
                <Activestocksubstore />
              </TabPanel>
            )}
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default Activestock;
