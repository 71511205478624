import React, { useState, useEffect } from "react";
import { Edit, Delete } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Button } from "@mui/material";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import HttpComponent from "../../School/MakeRequest";
import { warehousestyles } from "../styles";
import { Suspendsuppliermodal } from "../modals";
import { useNavigate } from "react-router-dom";

const Activesubstores = (props) => {
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const getActiveSubstores = () => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=SubStore&status=ACTIVE&page=${pageState.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, total: data.response.count });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getActiveSubstores();
  }, [pageState.page, dataGridPageSize]);

  const [sucess, setSucess] = useState({state:false, message:""}) 
  const [openSuspendModal, setSuspendModal] = useState(false);
  const [storeNameToSuspend, setStoreNameToSuspend] = useState("");
  const [storeIdToSuspend, setStoreIdToSuspend] = useState("");
  const [storesToBeSuspended, setStoresToBeSuspended] = useState([]);

  const suspendMultiplySubstore = () => {
    if (storesToBeSuspended.length > 1) {
      setSuspendModal(true);
    }
  };

  const getSubstoredetails = (substoreData) => {
    const {substoreId, substoreName} = substoreData;

    if(substoreName){
        setSuspendModal(true)
        setStoreNameToSuspend(substoreName)
        setStoreIdToSuspend(substoreId)
    }
  };

  const suspendSubstore = () => {
    HttpComponent({
        method: "POST",
        url: `/api/deactivate_or_activate_stores?action=inactive`,
        body: { storeIds: storeIdToSuspend ? [storeIdToSuspend] : storesToBeSuspended },
        token: localStorage.getItem("X-Authorization"),
      })
        .then((data) => {
          if (data.status === 202) {
            setSuspendModal(false);
            setSucess({ state: true, message: "Substore successfully suspended!" });
            getActiveSubstores();
          }
  
          setTimeout(() => {
            setSucess({ state: false });
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
  };



  const searchSubstore = () => {
    if(props?.searchValue)
    HttpComponent({
      method:'GET',
      url:`/api/v1/searchByStoreType?page=${pageState?.page}&limit=${dataGridPageSize}&status=ACTIVE&storeType=Substore&searchValue=${props?.searchValue}`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      if (data.status === 200) {
  
        setPageState({ ...pageState, isLoading: false, data: data.response.data, total: 0 });
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  useEffect(() => {
    if(!props?.searchValue)getActiveSubstores()
    searchSubstore();
  }, [props?.searchValue])

  return (
    <div>
      <DataGrid
        style={warehousestyles.dataGridStyling}
        rows={pageState?.data?.map((supplier) => ({
          id: supplier?._id,
          username: supplier?.name,
          branchName: supplier?.branchName,
          storeType: supplier?.storeType,
          location: supplier?.location,
          storesusersCount:supplier?.storesusersCount
        }))}
        columns={[
          { field: "username", headerName: "Name", flex: 1 },
          { field: "branchName", headerName: "Branch Assigned To", flex: 1 },
          { field: "storeType", headerName: "Store type", flex: 1 },
          { field: "storesusersCount", headerName: "Store Users", flex: 1 },
          {
            field: "Actions",
            headerName: "Actions",
            renderCell: (params) => {
              return (
                <>
                  <div style={warehousestyles.activesupplierActions}>
                    {/* <img onClick={() => getsupplierdetails({ userId: params.row.id, supplierName: params.row.username })} src={suspendcustomer} alt="" /> */}
                    <Delete color="error" onClick={() => getSubstoredetails({ substoreId: params.row.id, substoreName: params.row.username })} />
                    <Edit onClick={() => navigate(`/addsubstore/${params.row.id}`)} />
                  </div>
                </>
              );
            },
            flex: 1,
          },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableRowSelectionOnClick={true}
        onSelectionModelChange={(ids) => {
          setStoresToBeSuspended(ids);
        }}
      />

      <Grid container width={"100%"}>
        <Grid item width={"100%"}>
          <div style={warehousestyles.suspendBtnRightAligned}>
            {storesToBeSuspended?.length > 1 && (
              <Button style={warehousestyles.suspendSupplier} onClick={() => (storesToBeSuspended.length > 1 ? suspendMultiplySubstore() : null)}>
                Suspend {storesToBeSuspended.length}{" "}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      {openSuspendModal && <Suspendsuppliermodal openSuspendModal={openSuspendModal} setSuspendModal={setSuspendModal} storeNameToSuspend={storeNameToSuspend} suspendSubstore={suspendSubstore} storesToBeSuspended={storesToBeSuspended}  />}
      {sucess && <SuccessAlertRightAligned sucess={sucess.state} message={sucess.message} />}
    </div>
  );
};

export default Activesubstores;
