import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import HttpComponent from "../../../components/School/MakeRequest";
import { useNavigate } from "react-router-dom";
import { SuccessAlert } from "../../../components/snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../components/snackBar Alerts/errorAlert";

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} color="text.inactive" key="3">General Ledger Account</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.error">Add Account</Typography>
]

export default function CreatenewLedgerAccount() {
    const [ledgerName, setLedgerName] = useState('')
    const [allLedgers, setAllLedgers] = useState()
    const [selectedLedgerOption, setSelectedLedgerOption] = useState()
    const [ledgerCode ,setLedgerCode] = useState()
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate()


    // get all ledgers

    function getAllClassifdication() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/business/generallledger/classification`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setAllLedgers(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllClassifdication()
    }, [])

    // formdata

    const formData = {
         name: ledgerName,
         classification: selectedLedgerOption,
         code:ledgerCode
    }


    //create ledgers 

    function createLedgerAccount() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/business/generallledger/add`,
                body: formData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setSuccessShow({state:true , message:'General ledger account created successfully'})
                    setTimeout(()=>{
                        navigate(-1)
                    } ,2000)
                }else{
                    setErrorShow({state:true , message:'Error Creating General ledger account'})
                }
            })
        } catch (error) {
            console.log(error)
        }
    }


    const handleOnChangeLedger = (e) => {
        setSelectedLedgerOption(e.target.value)
    }

    //

    return (
        <Grid container direction={'column'} display={'flex'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid mb={3} item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosIcon style={{cursor:'pointer'}} onClick={()=>navigate(-1)}/>
                <Typography style={{ fontSize: "16px", color: "#032541", fontWeight: 500 }}>Account Details</Typography>
            </Grid>
            <Grid item mt={3}>
                <FormControl sx={{ width: '40ch' }} variant="outlined">
                    <InputLabel style={{ display: "flex", alignContent: "center", alignItems: "center" }} htmlFor="outlined-adornment-password">Name <span style={{ color: "#dc3545" }}>*</span></InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type='text'
                        value={ledgerName}
                        onChange={(e) => setLedgerName(e.target.value)}
                        label="Name"
                    />
                </FormControl>
            </Grid>
            <Grid item mt={3}>
                <FormControl sx={{ width: '40ch' }} variant="outlined">
                    <InputLabel style={{ display: "flex", alignContent: "center", alignItems: "center" }} htmlFor="outlined-adornment-password">Code <span style={{ color: "#dc3545" }}>*</span></InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type='number'
                        value={ledgerCode}
                        onChange={(e) => setLedgerCode(e.target.value)}
                        label="Name"
                    />
                </FormControl>
            </Grid>
            <Grid item mt={3}>
                <FormControl sx={{ minWidth: 400 }}>
                    <InputLabel id="demo-simple-select-helper-label">Classification</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedLedgerOption}
                        onChange={handleOnChangeLedger}
                        label="Classification"
                    >
                        {allLedgers?.map((option) => {
                            return (
                                <MenuItem value={option}>{option}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid mt={2} item style={{ width: "40ch" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={createLedgerAccount} style={!ledgerName || !selectedLedgerOption || !ledgerCode ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Add</Button>
            </Grid>
        </Grid>
    )
}