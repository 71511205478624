import { Box, Breadcrumbs, Button, Grid, Tab, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import SupplierVendorTabs from "./suppliervendortabs";
import { useNavigate } from "react-router-dom";
import OtherPaymentsPayableTabs from "./otherPayments";
import VerificationPayable from "../admincomponents/transactions/VerificationPayable";
import { useSelector, useDispatch } from 'react-redux';
import { addPayable, clearPayable } from "../../../features/payableslice";
import OtpVerficationFT from "../admincomponents/transactions/otpCode";
import PendingFailedDisbursedReport from "./pendingFailedDisbursedReport";

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>
]

// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "18px", background: '#f5f6f7', borderRadius: '5px', marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#fff", backgroundColor: '#cb444a', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));



export default function PayablesGeneralBusinessTransactions(props) {
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [tabValue, setTabValue] = useState('SuppliersVendors')

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [lengthOtherPayments, setLengthOtherPayments] = useState('')
    const [lengthSupplierVendors, setLengthSupplierVendors] = useState('')
    const [stateChildOtherPayments, setStateChildOtherPayments] = useState('')
    const [stateChildSupplierVendors, setStateChildSupplierVendors] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [pin, setPin] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')




    const parentCallBackOtherPayments = (data) => {
        console.log(data, 'data parentCallBack');
        let tabValueData = data?.tabValue
        let selectedRowLength = data?.selectedRowLength
        let selectedRows = data?.selectedRow

        // console.log(tabValueData, 'tabValueData')
        // console.log(selectedRowLength, 'selectedRowLength')
        console.log(selectedRows, 'selectedRows ====>>>><<<>>>')

        setStateChildOtherPayments(tabValueData)
        setLengthOtherPayments(selectedRowLength)
        setSelectedRows(selectedRows)

    }
    const parentCallBackSupplierVendors = (data) => {
        // console.log(data, 'data parentCallBack parentCallBackSupplierVendors');
        let tabValueData = data?.tabValue
        let selectedRowLength = data?.selectedRowLength
        let selectedRows = data?.selectedRow

        // console.log(tabValueData, 'tabValueData')
        // console.log(selectedRowLength, 'selectedRowLength')
        // console.log(selectedRows, 'selectedRows parentCallBackSupplierVendors')

        setStateChildSupplierVendors(tabValueData)
        setLengthSupplierVendors(selectedRowLength)
        setSelectedRows(selectedRows)
    }
    const handleApprove = () => {
        // console.log(selectedRows, 'selectedRows <<>>>>')
        // remove what is in redux
        dispatch(clearPayable())
        dispatch(addPayable({ payableStatus: 'Approve', transcationIds: selectedRows }))
        navigate('/payables/transcations?verifyPayable')


    }

    const parentCallBackPinSend = (data) => {
        console.log(data, 'data parentCallBackPinSend');
        let pin = data?.pin
        let email = data?.email
        let phone = data?.phone
        setPin(pin)
        setEmail(email)
        setPhone(phone)
    }



    // const handleApprove = () => {
    //     handleNavigateToVerify()

    // }

    const handleDecline = () => {
        // console.log(selectedRows, 'selectedRows <<>>>>')
        // remove what is in redux
        dispatch(clearPayable())
        dispatch(addPayable({ payableStatus: 'Disapprove', transcationIds: selectedRows }))
        navigate('/payables/transcations?verifyPayable')

    }

    console.log(lengthOtherPayments, 'lengthOtherPayments')

    return (
        <Grid container direction={'column'}>
            {state === 'verifyPayable' ? <VerificationPayable parentCallBackPinSend={parentCallBackPinSend} /> :
                state === 'otp' ? <OtpVerficationFT pin={pin} email={email} phone={phone} /> :
                    <>
                        <Grid item mt={1}>
                            <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Payable Transactions</Typography>
                        </Grid>
                        <Grid item mt={1}>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item mt={2}>
                            <TabContext value={tabValue}>
                                <AntTabs onChange={handleChange} sx={{ display: 'flex' }}>
                                    <AntTab label="Suppliers/Vendors" value="SuppliersVendors" />
                                    <AntTab label="OtherPayments" value="OtherPayments" style={{ marginLeft: '20px' }} />
                                    <AntTab label="All Transcations" value="alltranscations" style={{ marginLeft: "20px" }} />

                                </AntTabs>
                                <TabPanel value="SuppliersVendors" style={{ marginLeft: '-30px' }}>
                                    <Grid item>
                                        <SupplierVendorTabs parentCallBack={parentCallBackSupplierVendors} />
                                            {/* <Button onClick={() => handleApprove()} style={{ backgroundColor: '#17ae7b', color: '#fff', width: '100px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}>Approve </Button> */}
                                            {/* <Button onClick={() => handleDecline()} style={{ backgroundColor: '#dc3545', color: '#fff', width: '100px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}>Decline </Button>  */}
                                        {stateChildSupplierVendors === 'Pending' && lengthSupplierVendors > 0 ? <>
                                            <Box style={{ display: "flex", justifyContent: "end" }}>

                                                <Button onClick={() => handleApprove()} style={{ backgroundColor: '#17ae7b', color: '#fff', width: '100px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}>Approve {lengthSupplierVendors} </Button>
                                                <Button onClick={() => handleDecline()} style={{ backgroundColor: '#dc3545', color: '#fff', width: '100px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}>Decline {lengthSupplierVendors} </Button>
                                            </Box>

                                        </>
                                            : null}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="OtherPayments" style={{ marginLeft: '-30px' }}>
                                    <Grid item>

                                        <OtherPaymentsPayableTabs parentCallBack={parentCallBackOtherPayments} />
                                        {stateChildOtherPayments === 'Pending' && lengthOtherPayments > 0 ? <>
                                            <Box style={{ display: "flex", justifyContent: "end" }}>

                                                <Button onClick={() => handleApprove()} style={{ backgroundColor: '#17ae7b', color: '#fff', width: '100px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}>Approve {lengthOtherPayments} </Button>
                                                <Button onClick={() => handleDecline()} style={{ backgroundColor: '#dc3545', color: '#fff', width: '100px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}>Decline {lengthOtherPayments} </Button>
                                            </Box>
                                        </> : null}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="alltranscations" style={{ marginLeft: "-30px" }}>
                                    <Grid item>
                                        <PendingFailedDisbursedReport />
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </>
            }
        </Grid>
    )
}