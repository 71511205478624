import React from 'react';
import { ReactSVG } from 'react-svg';
import MySVG1 from './Dashboard-iconRED.svg';
import MySVG2 from './Dashboard-iconBLUE.svg';

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG = currentPath === "/adminDashboard" ? MySVG1 : MySVG2
  return (
    <ReactSVG src={MySVG} />
  );
}

export default MySVGComponent;