import { useSelector } from "react-redux"
import HttpComponent from "../../../School/MakeRequest"
import { useEffect, useState } from "react"
import DateFormatter from "../../../../utils/dateFormatter"
import { Link, Typography } from "@mui/material"

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);
	

const NoticePdfPreview = () => {
    const { X_Authorization } = useSelector((store) => store.user)
   
    const {customerId} = useSelector((store)=>store.tenantData.tenantWithExitNotice)
    const [noticeDataDetails ,setnoticeInfo] = useState({})
    const tenantExitNotice = useSelector((store)=>store.tenantData.tenantExitNotice)
    console.log(tenantExitNotice )
 
  
     //fetch by notice id

     function postEvictionNoticeByN() {
        const obj = { noticeNumber: tenantExitNotice ,tenantId:customerId }
        try {
            HttpComponent({
                method: "POST",
                url: `/api/get_eviction_notice_by_noticeId`,
                body: obj,
                token: X_Authorization,
            }).then((data) => {
                console.log(data, 'notice pdf')
                setnoticeInfo(data.response.data)

            });

        } catch (error) {

        }
    }

    useEffect(()=>{
        postEvictionNoticeByN()
    },[])
    const invoiceMessage = noticeDataDetails?.noticeMessage
    console.log('invoiceMessage', invoiceMessage)

    return (
        <div>
            <>
            <div style={{ width: "80%", marginLeft: "10%" }}>
                <div>
                    <div className="notice-header" style={{ background: '#fafafa', height: "177px" }}>
                        <div className="px-2">
                        <Typography style={{ fontSize: "30px", fontWeight: "bolder"  }}  py={3} variant={'h1'}>NOTICE TO VACATE</Typography>
                        </div>
                        <div className="d-flex justify-content-between px-2">
                            <div className="date-notice ">
                                <Typography  style={{ fontWeight: "900"  }}>Date</Typography>
                                <span>{DateFormatter(noticeDataDetails?.createdAt)}</span>
                            </div>
                            <div>
                                <Typography  style={{ fontWeight: "900" ,justifyContent:'flex-end' ,textAlign:"end"  }}>Notice No</Typography>
                                <span style={{ alignItems: 'flex-end', display: "flex", flexDirection: "column" }}>{noticeDataDetails?.noticeNumber}</span>
                            </div>
                        </div>
                    </div>
                    <div className="notice-from mt-4 d-flex justify-content-between">
                        <Typography variant={'body2'} fontSize={20}>From</Typography>
                        <Typography variant={'body2'} fontSize={20}>To</Typography>
                     </div>
                        <div className="d-flex justify-content-between">
                            <div className="notice-from py-3">
                                <Typography  style={{ fontWeight: "900"  }}>{noticeDataDetails?.noticeFrom?.name}</Typography>
                                <Typography>{noticeDataDetails?.noticeFrom?.location}</Typography>
                                <Typography>{noticeDataDetails?.noticeFrom?.email}</Typography>
                                <Typography>{noticeDataDetails?.noticeFrom?.phoneNumber}</Typography>

                            </div>
                            <div className="notice-to py-3" style={{ alignItems: 'flex-end', display: "flex", flexDirection: "column" }}>
                                <Typography  style={{ fontWeight: "900"  }}>{noticeDataDetails?.noticeTo?.name}</Typography>
                                <Typography>{noticeDataDetails?.noticeTo?.location}</Typography>
                                <Typography>{noticeDataDetails?.noticeTo?.email}</Typography>
                                <Typography>{noticeDataDetails?.noticeTo?.phoneNumber}</Typography>
                            </div>
                        </div>
                    
                    <div className="vacation-date py-3">
                        <Typography style={{ fontWeight: "900"  }}>Vacation Date</Typography>
                            <span>{DateFormatter(noticeDataDetails?.dateForEviction) }</span>
                     
                    </div>
                    <div className="vacation-invoice py-3">
                        {noticeDataDetails?.invoices?.map((invoice)=>(
                                 <div className="d-flex justify-content-between">
                                 <div className="notice-from">
                                     <Typography  style={{ fontWeight: "900"  }}>Invoice No:</Typography>
                                     <Link href={`/invoice/${invoice?.invoiceNumber}`}>{invoice?.invoiceNumber}</Link>

                                 </div>
                                 <div className="notice-to" style={{ alignItems: 'flex-end', display: "flex", flexDirection: "column" }}>
                                     <Typography  style={{ fontWeight: "900"  }}>Amount Due (KES)</Typography>
                                     <Typography>{numberFormat(invoice?.invoiceAmount)}</Typography>
                                 </div>
                             </div>
                        ))}
                    </div>
                    <div className="py-3" style={{ background: '#fafafa'}}>
                        <Typography>Total KES: {numberFormat(noticeDataDetails?.invoiceTotal)} </Typography>
                    </div>
                    <div className="py-3" style={{borderBottom:"2px dotted #bfbfbf"}}>
                        <Typography>{noticeDataDetails?.noticeMessage}</Typography>
                        <span>Thank you for your business</span>
                    </div>
                    <div>
                      <Typography>{noticeDataDetails?.noticeSignature}</Typography>  
                    
                    </div>
                </div>
            </div>
        </>

        </div>
    )

}
export default NoticePdfPreview