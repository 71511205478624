import { Grid, Breadcrumbs, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, FormControlLabel, Checkbox } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";



const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Terminal Users</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create User</Typography>
]

export default function CreateTerminalUser() {
    const [formData, setFormData] = useState({ firstName: "", outlet: '', userName: "", lastName: "", phoneNumber: "", email: "", idNo: "", userType: "" ,allowedReceiptReprinting:false})
    const [outlets, setOutlets] = useState([])
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [businessInfo, setBusinessInfo] = useState({})
    const [userPhone ,setPhone] = useState()

    const navigate = useNavigate()

    function fetchBusinessDetails() {
        try {
            HttpComponent({
                method: 'POST',
                body: null,
                url: `/api/get_business_info`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data.status === 200) {
                    setBusinessInfo(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchBusinessDetails()
    }, [])

    const { businessNumber, businessName, businessOwnerAddress, businessOwnerPhone } = businessInfo

    //fetch outlets
    function getOutlets() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/list_all_stores_by_type_status?storeType=MainStore&status=ACTIVE&page&limit=`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setOutlets(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOutlets()
    }, [])

    //handle onchange

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    //handle create user

    const submitData = {
        Equitel: "",
        Paybill: "",
        Till: "",
        Vooma: "",
        assignedBusiness: businessNumber,
        businessLocation: businessOwnerAddress,
        businessName: businessName,
        businessNumber: businessNumber,
        storeId: formData.outlet,
        businessPhone: businessOwnerPhone,
        businessShortCode: "",
        firstName: formData.firstName,
        loginState: "Active",
        secondName: formData.lastName,
        userEmail: formData.email,
        userGroup: "payItUser",
        userName: formData.userName,
        userPhone: `+${userPhone}` ,
        userState: "Active",
        assignToBusiness: "assignedToZedPayIt",
        userIdNumber: formData.idNo,
        allowedReceiptReprinting:formData.allowedReceiptReprinting
    }
    console.log(formData , 'hereee')

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const phoneValidate = (phone)=>{
        const phoneregex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        return phoneregex.test(phone)
    }

    function handleSubmit() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/addNewUser`,
                token: localStorage.getItem('X-Authorization'),
                body: submitData
            }).then((data) => {
                console.log(data, 'creating user issue')
                if (data.status === 201) {
                    setSuccessShow({ state: true, message: "Terminal user created successfully" })
                    setTimeout(() => {
                        navigate(-1)
                    }, [1000])
                } else {
                    setErrorShow({ message: data?.response?.message, state: true })
                    setTimeout(() => {
                        //navigate(-1)
                    }, [2000])
                }

            })
        } catch (error) {
            setErrorShow({ message: error, state: true })
        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Create User</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Terminal User Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='firstName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.firstName} label="FirstName" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='lastName' onChange={handleOnChange} style={{ width: "100%" }} value={formData.lastName} label="LastName" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='idNo' onChange={handleOnChange} style={{ width: "100%" }} value={formData.idNo} label="ID NO" variant="outlined" />
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <PhoneInput inputStyle={{ height: "55px" , width:"100%" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />  
                        {userPhone && !phoneValidate(userPhone) && (
                            <FormHelperText error>Enter a valid Phone Number</FormHelperText>
                        )}
                        {/* <TextField id="outlined-basic" name='phoneNumber' onChange={handleOnChange} style={{ width: "100%" }} value={formData.phoneNumber} label="Phone Number" variant="outlined" /> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='email' onChange={handleOnChange} style={{ width: "100%" }} value={formData.email} label="Email Address" variant="outlined" />
                        {formData.email && !validateEmail(formData.email) && (
                            <FormHelperText error>Enter a valid email</FormHelperText>
                        )}
                    </Grid>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='userType' readonly style={{ width: "100%" }} value="ZPM(user)" label="User Type" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item>
                <GeoLocation onValueChange={(location)=>setSelectedValue(location)} selectedValue={selectedValue}/>
            </Grid> */}
            <Grid item mt={2} style={{ width: "50%" }} spacin>
                <TextField id="outlined-basic" name='userName' onChange={handleOnChange} style={{ width: "98%" }} value={formData.userName} label="userName" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <FormControl style={{ width: "98%" }}>
                    <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.outlet}
                        label="Outlets"
                        onChange={handleOnChange}
                        name="outlet"
                    >
                        {outlets?.map((item) => (
                            <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.allowedReceiptReprinting}
                        onChange={(e) => handleOnChange({ target: { name: 'allowedReceiptReprinting', value: e.target.checked } })}
                    />
                }
                label="Allow Receipt Printing" 
            />
        </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleSubmit} style={!formData.email || !formData.firstName || !formData.userName || !formData.lastName || !formData.idNo || !formData.outlet || !userPhone ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}

