import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import BasicDatePicker from "../../../customerAccounts/customerComponents/customDate";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Fee Configs</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Pocket Money Activation Fee</Typography>,
]


export default function EditPocketMoneyConfig() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [accountSetUp, setAccountSetUp] = useState(["Vooma Till", "KCB Account Number"]);
    const [accountToSetup, setAccountToSetUp] = useState("");
    const [kcbaccountType, setAccountType] = useState("")
    const [bankNumber, setAccountNumber] = useState("");
    const [verifyAccountNumber, setVerifyAccountNumber] = useState("")
    const [feeAmount, setFeeAmount] = useState('')
    const [tillNo, setTillNo] = useState("");
    const [verifyTill, setVerifyTill] = useState("");
    const [elapsePeriod, setElapsePeriod] = useState(0)    
    const [selectedTime, setSelectedTime] = useState(new Date())
    const [selectedEndTime, setSelectedEndTime] = useState(new Date())
    const [loading, setLoading] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);

    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, businessNumber , businessName] = newqueryParamValue.split("?")


    useEffect(() => {
        if (accountToSetup === "Vooma Till") {
            setAccountType("VOOMATILL")
        } else if (accountToSetup === 'KCB Account Number') {
            setAccountType("KCBACCOUNT")
        }
    }, [accountToSetup])

    useEffect(() => {
        if (businessNumber) {
            fetchPaymentDetails()
        }
    }, [businessNumber])

    const navigate = useNavigate();

    //console.log(accountToSetup , 'accountToSetup')


    // update fee convenience
    async function fetchPaymentDetails() {
        setLoading(true);
        try {
            const response = await HttpComponent({
                method: 'POST',
                url: `/api/v1/get_payments_details?type=zpmactivationFee&businessNo=${businessNumber}`,
                token: localStorage.getItem('X-Authorization')
            });
    
            console.log('response------', response);
    
            if (response?.response?.success === true) {
                console.log('response.data------', response.response.data);
    
                const { data } = response.response;
                if (data && data.kcbDarajaConfigId) {
                    const {chargeAmount,kcbAccountType,accountNumber,billingStartDate, billingEndDate} = data.kcbDarajaConfigId;
                    setPaymentDetails({
                        ...data,
                        feeAmount: chargeAmount,
                    });

                    setFeeAmount(chargeAmount);
                    setSelectedTime(billingStartDate)
                    setSelectedEndTime(billingEndDate)
                    if (kcbAccountType === "KCBACCOUNT") {
                        setAccountToSetUp("KCB Account Number");
                        setAccountNumber(accountNumber);
                        setVerifyAccountNumber(accountNumber) 
                    } else if (kcbAccountType === "VOOMATILL") {
                        setAccountToSetUp("Vooma Till");
                        setTillNo(accountNumber);
                        setVerifyTill(accountNumber);
                    } else {
                        console.error('Unexpected account type:', kcbAccountType);
                        setErrorShow({ state: true, message: "Invalid account type format" });
                    }
    
                } else {
                    console.error('Unexpected response structure:', data);
                    setErrorShow({ state: true, message: "Invalid payment details format" });
                }
            } else {
                // setErrorShow({ state: true, message: "Error fetching payment details" });
            }
        } catch (error) {
            console.error('Error fetching payment details:', error);
            setErrorShow({ state: true, message: "Error fetching payment details" });
        } finally {
            setLoading(false);
        }
    }    

    function updatePocketMoneyFee() {

        if (tillNo === "" && accountToSetup === "Vooma Till") {
            setErrorShow({ state: true, message: "Till number is required" });
        } else if (verifyTill !== tillNo && accountToSetup === "Vooma Till") {
            setErrorShow({ state: true, message: "Till numbers do not match" });
        } else if (accountToSetup === "KCB Account Number" && bankNumber === "") {
            setErrorShow({ state: true, message: "Account number is required" });
        } else if (accountToSetup === "KCB Account Number" && bankNumber !== verifyAccountNumber) {
            setErrorShow({ state: true, message: "Account number does not match" });

        }else if (feeAmount === '' ){
            setErrorShow({ state: true, message: "Amount is required" });
        }else if((!selectedTime) || (!selectedEndTime)) {
            setErrorShow({state:true, message:"Billing Period is required"})
        }else if(selectedTime === selectedEndTime){
            setErrorShow({state:true, message:"End Billing Period cant be less than or equal to start date"})
        }else if(selectedEndTime < selectedTime  ) {
            setErrorShow({state:true, message:"End Billing Period cant be less than start date"})
        }
        else if((selectedTime < new Date()) || (selectedTime === new Date())){
            setErrorShow({state:true, message:"Start Billing Period cant be less than or equal to start date"})
        }
             else {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/v1/payments/activate_kcb_mpesa`,
                    body: {
                        businessName: businessName,
                        accountNumber: kcbaccountType === "VOOMATILL" ? tillNo : bankNumber,
                        accountType: "zpmactivationFee",
                        kcbAccountType: kcbaccountType,
                        businessNumber: businessNumber,
                        chargeAmount: feeAmount,
                        billingStartDate: selectedTime,
                        billingEndDate:selectedEndTime
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if(data?.status === 200 || data?.status === 201){
                        setSuccessShow({ state: true, message: "Pocket money Activated successfully" });
                        setTimeout(() => {
                          //window.location.reload();
                          navigate(-1)
                        }, [1000]);
                      }else if(data?.status === 400){
                        setErrorShow({ state: true, message: "Error activating pocket money setup" });
                      }
                    })
            } catch (error) {
                console?.error(error)
            }
        }
    }


    // get date details;
    const getDateData = (e) => {
        const currentstarttime = (new Date(e.$d).getTime() / 1000)
        const timeStamp = Math.floor(new Date().getTime() / 1000)
        setSelectedTime(new Date(currentstarttime * 1000) )
        if ( currentstarttime < timeStamp  ) {
            setErrorShow({ state: true, message: "Billing Period cant be backdated" });
            return
        }
        setElapsePeriod(timeStamp)
    }

    const getEndDate = (e) => {
        const currentendtime = (new Date(e.$d).getTime() / 1000)
        const currentstarttime = (new Date(selectedTime).getTime() / 1000)
        if(currentendtime < currentstarttime){
            setErrorShow({ state: true, message: "End Date cant be less than start date" });
            return
        }
        setSelectedEndTime(new Date(currentendtime * 1000) )
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {/* <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate('/feeconfigs')} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Pocket Money Activation Fee</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid> */}

            <Grid item mt={2}>
                <Typography>Pocket Money Activation Details</Typography>
            </Grid>

            <Grid item mt={2} width={'50%'}>
                <FormControl style={{ width: "100%", }}>
                    <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Business Name</InputLabel>
                    <TextField disabled style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="e.g 123456" value={businessName} required />
                </FormControl>
            </Grid>

            <Grid item mt={2} width={'50%'}>
                <TextField style={{ width: '100%' }} value={feeAmount} onChange={(e) => setFeeAmount(e.target.value)} id="outlined-basic" label="Fees Amount" variant="outlined" />
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Account To SetUp</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={accountToSetup} label="Account To SetUp" onChange={(e) => setAccountToSetUp(e.target.value)}>
                        {accountSetUp?.map((acc) => {
                            return <MenuItem value={acc}>{acc}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>

            {accountToSetup === "Vooma Till" && (
                <Grid container display={'flex'} direction={'column'}>
                    <Grid item width={'50%'}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px" }}>Vooma Till No.</InputLabel>
                            <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" value={tillNo} onChange={(e) => setTillNo(e.target.value)} multiline placeholder="e.g 123456" required />
                        </FormControl>
                    </Grid>
                    <Grid item width={'50%'}>
                        <FormControl style={{ width: "100%", }}>
                            <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify Vooma Till No.</InputLabel>
                            <TextField style={{ width: "100%", marginTop: "40px" }} error={tillNo !== verifyTill ? true : false} id="outlined-multiline-flexible" placeholder="e.g 123456" value={verifyTill} onChange={(e) => setVerifyTill(e.target.value)} multiline required />
                        </FormControl>
                    </Grid>
                </Grid>

            )}

            {accountToSetup === "KCB Account Number" && (
                <Grid item width={'50%'}>
                    <Grid container display={'flex'} direction={'column'}>
                        <Grid item>
                            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>KCB Account Number</InputLabel>
                                <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-" value={bankNumber} onChange={(e) => setAccountNumber(e.target.value)} placeholder="e.g 123456" multiline required />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
                                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify KCB Account Number</InputLabel>
                                <TextField style={{ width: "100%", marginTop: "40px" }} value={verifyAccountNumber} onChange={(e) => setVerifyAccountNumber(e.target.value)} error={bankNumber !== verifyAccountNumber ? true : false} id="outlined-" placeholder="e.g 123456" multiline required />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item sx={{display:"flex", gap:"30px"}}> 
                <BasicDatePicker value={selectedTime}  label={"Stert Period"} onChange={getDateData} styles={{width:"23%", mt:2}} />

                <BasicDatePicker value={selectedEndTime}  label={"End Period"} onChange={getEndDate} styles={{width:"25%", mt:2}} />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={updatePocketMoneyFee} disabled={!kcbaccountType || !feeAmount} style={ !kcbaccountType || !feeAmount  ? {width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' }:{ width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Update</Button>
            </Grid>
        </Grid>
    )
}