import { Box, Skeleton } from "@mui/material";
import React from "react";

const CustomProductSkeleton = ({ variant, height, width, arrayLength , styles}) => {
  return (
    < >
      {Array.from({ length: arrayLength }).map((_, index) => {
        return <Skeleton sx={styles} variant={variant} height={height} width={width} animation={"wave"} />;
      })}
    </>
  );
};

export default CustomProductSkeleton;
