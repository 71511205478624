import { ArrowRight } from '@mui/icons-material'
import { borderRadius } from '@mui/system'
import React from 'react'
import Collection from '../../common/images/collection.svg'
import filterIcon from '../../common/images/redarrowdown.svg'
import StyledComponent  from '@emotion/styled'
import { Link } from 'react-router-dom'

import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import cash from '../../common/images/cash.svg'
import MPESA from '../../common/images/mpesa.svg';
import equitel from '../../common/images/equitel.svg';
import CARD from '../../common/images/cardpay.svg';
import BANK  from '../../common/images/bank.svg'
import VOOMA from '../../common/images/vooma.svg';
import voucher from '../../common/images/voucher-icn.svg';
import customerAcc from '../../common/images/customeracc.png';







const paymentsStyle =  {
  // width:"1028px",
  height: "70px",
  padding: "12px 15px 11.5px 22px",
  // opacity: "0.12",
  // backgroundColor:" #707070",
  boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16",
  display:"flex",
  justifyContent:"space-between",
  borderRadius:"11px",
  margin: "0px 20px 20px",
 


}
const paymentsStyleCash =  {
  // width:"1028px",
  height: "70px",
  padding: "12px 15px 11.5px 22px",
  // opacity: "0.12",
  // backgroundColor:" #707070",
  boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16",
  display:"flex",
  justifyContent:"space-between",
  borderRadius:"11px",
  margin: "0px 20px 20px",
  backgroundColor:"#d1faec"
 


}

const payment_name = {
  // width: "29px",
  // height: "16px",
  // margin:" 0 77px 0 0",
  fontfamily: "Poppins",
  fontSize: "10px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle:" normal",
  lineHeight: "2.27",
  letterSpacing: "normal",
  textAlign: "left",
  color:" #032541",

}
const payment_value = {
  // width: "106px",
  // height:" 20px",
  // margin:" 5px 0 0",
  fontfamily: "Poppins",
  // margin:"5px 0 5px",
  fontSize:" 10px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.14",
  letterSpacing:" normal",
  textAlign: "left",
  color: "#17ae7b",

}
const arrowicon = {
  
  // margin: "-13px 0 0px 855px",
  fontSize:"3rem",
  // padding: "7.5px 10.6px 7.5px 10.6px",
  color:" #032541",
  
}

const style_contain = {
  width:"350px",
  margin: '0px 15px 50px 1px',
  padding: '47px 59px 47.6px 20.6px',
  boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16",
  border: 'solid 1px #dc3545',
  backgroundColor:' #fff',
}

const style_contain_notCash = {
  width:"350px",
  margin: '0px 15px 50px 1px',
  padding: '47px 59px 47.6px 20.6px',
  boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16",
  backgroundColor:' #fff',
}

const baseUrl = process.env.REACT_APP_BASE_URL;
const branchId = localStorage.getItem('BranchId')

const Cashier = (props) => {
  const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);
  // branchesArr[0]._id
  // const { branchesArr } = useSelector((store) => store.businessBranches);
  // const branchId = '638d8d155491c7c401f85979';
  const businessIdString = localStorage.getItem('businessID')
  const url = window.location.href
  const parts = url.split('/');
  const id = parts[parts.length - 1];
  const cashierId = id

const [totalSales, setTotalSales] = useState([])
const [sales,setSales] = useState([])
const [mpesa, setMpesaTotal] = useState([])
const [card, setCardTotal] = useState([])
const [Equitel, setEquitelTotal] = useState([])
const [vooma, setVoomaTotal] = useState([])
const [bank, setBankTotal] = useState([])
const [customer, setCustomerTotal] = useState([])
const [VoucherSale, setVoucher] = useState([])




useEffect(() => {
  getSalesOfCashier();
  getAllSales();
  getAllSalesMpesa();
  getAllSalesCard()
  getAllSalesBank()
  getAllSalesEquitel()
  getAllSalesVooma();
  getAllSalesCustomer();
  getAllSalesVoucher() 
  cashierDetails()
  

}, [])
  // get total sales of cashier 
const getSalesOfCashier = async () => {
  
  
  

  const transacations_type = 'Cash Payment'

  console.log('Business String', businessIdString)
  console.log('Cashier Id', cashierId)
  console.log('Branch Id', branchId)
  
  try {
    const res = await fetch (
  
      `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=${transacations_type}`, {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('----------------->', data.data)
          setSales(data.data)
          
        }
        

    
  } catch (error) {
    console.log(error)
    
  }
  
}

const getAllSales = async()=> {
  const transacations_type = 'Cash Payment'
  console.log('Business String', businessIdString)
  console.log('Cashier Id', cashierId)
  console.log('Branch Id', branchId)

  try {
    const res = await fetch (
      `${baseUrl}/api/get_all_transactions_notsettled?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}`, {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--Not settled for cashier -->', data.data)
          setTotalSales(data.data)
          
        }
        

    
  } catch (error) {
    console.log(error)
    
  }

}
const getAllSalesMpesa = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Till Payment`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--MPESA NOT SETTLED -->', data.data)
          setMpesaTotal(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}

// Card Payment
const getAllSalesCard = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Card Payment`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--CARD NOT SETTLED -->', data.data)
          setCardTotal(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}

// Equitel Payment
const getAllSalesEquitel = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Equitel Payment`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--Equitel NOT SETTLED -->', data.data)
          setEquitelTotal(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}
// Vooma Payment
const getAllSalesVooma = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Vooma Payment`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--VOOMA NOT SETTLED -->', data.data)
          setVoomaTotal(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}
// Bank
const getAllSalesBank = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Bank Payment`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--MPESA NOT SETTLED -->', data.data)
          setBankTotal(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}

const getAllSalesCustomer = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Bank Payment`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--CUSTOMER ACC NOT SETTLED -->', data.data)
          setCustomerTotal(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}


const getAllSalesVoucher = async () => {
  try {
    const res = await fetch(
    
        `${baseUrl}/api/get_transactions_by_type?businessIdString=${businessIdString}&cashierId=${cashierId}&branchId=${branchId}&transactionType=Voucher`,
      {
        method:"GET",
        headers:{
          "Content_Type":"application/json",
          Accept:"application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        }, });
        const data = await res.json()
        if (res.status === 200) {
          console.log('-------------Total Sales--CUSTOMER ACC NOT SETTLED -->', data.data)
          setVoucher(data.data)
          
        }
    
  } catch (error) {
    console.log(error)
    
  }

}


let totalCashierCash = 0;

for (let i = 0; i < sales.length; i++) {
  totalCashierCash += sales[i].transamount;
}
console.log(totalCashierCash)

let AlltotalSales = 0;

for (let i = 0; i < totalSales.length; i++) {
  AlltotalSales += totalSales[i].transamount;
}


let AllMpesaSale = 0;

for (let i = 0; i < mpesa.length; i++) {
  AllMpesaSale += mpesa[i].transamount;
}


let AllCardaSale = 0;

for (let i = 0; i < card.length; i++) {
  AllCardaSale += card[i].transamount;
}

let AllVoomaaSale = 0;

for (let i = 0; i < vooma.length; i++) {
  AllCardaSale += vooma[i].transamount;
}

let AllEquitySale = 0;

for (let i = 0; i < Equitel.length; i++) {
  AllEquitySale += Equitel[i].transamount;
}
let AllBankSale = 0;

for (let i = 0; i < bank.length; i++) {
  AllBankSale += bank[i].transamount;
}

let AllCustomerSale = 0;
for (let i = 0; i < customer.length; i++) {
  AllCustomerSale += customer[i].transamount;
}

let Voucher = 0
for(let i = 0; i< VoucherSale.length; i++){
  Voucher+= VoucherSale[i].transamount
}
const [cashierdetails, setSetCashierDetails] = useState({
  firstName: "",
  secondName: "",
});

const cashierDetails = async () => {
  try {
    const res = await fetch(`${baseUrl}/api/get_cashier_by_id?cashierId=${cashierId}`, {
      method: "GET",
      headers: {
        Content_Type: "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    const data = await res.json();
    if (res.status === 200) {
      console.log("-------------CASHIER DETAILS -->", data.data);
      // setSetCashierDetails(data.data)
      setSetCashierDetails({ ...cashierdetails, firstName: data.data.firstName, secondName: data.data.secondName });
    }
  } catch (error) {
    console.log(error);
  }
};
  return (
    <div>
      <div>
        <div className="cashier-name">
          <h3 style={{
            width: "100%",
            height: "35px",
            margin: ' 11px 0 11px 14px',
            fontFamily: "Poppins",
            fontSize: "25px",
            fontWeight: "600",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.32",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#dc3545",

          }}>
            { <div>
                {cashierdetails.firstName } {cashierdetails.firstName}
                </div>}
          </h3>
        </div>
        <div className="cashier-collection " style={{
          width: " 580px",
          display: "flex",
          justifyContent: "space-between",
          height: " 155px",
          margin: " 18px 464px 28px 0",
          padding: " 20px 57px 19px 25px",
          borderRadius: "18px",
          boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16",
          backgroundColor: "#fff",
        }}>
          <div className="cashier-collection-icon">
            <img src={Collection} alt="" />
          </div>
          <div className="cashier-collection-sale">
            <h4 style={{
            
              margin: " 0 185px 7px 0",
              fontfamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
              fontStretch: " normal",
              fontStyle: "normal",
              lineHeight: " 1.4",
              letterSpacing: " normal",
              textAlign: "left",
              color: " #032541",
            }}>Total Cash sales</h4>
            <h4
              style={{
                width: "322px",
                height: "53px",
                margin: "7px 0 0",
                fontFamily: "Poppins",
                fontSize: "38px",
                fontWeight: " bold",
                fontStretch: "normal",
                fontStyle: " normal",
                lineHeight: " 1.05",
                letterSpacing: " normal",
                textAlign: "left",
                color: "#17ae7b",
              }}

            >{numberFormat(totalCashierCash)}</h4>
          </div>

        </div>

        <div className="payments-methods">
          <div className="top-payments" style={{
            display: "flex",

          }}>
            <div className="payments-title" style={{

              margin: "28px 124px 31px 14px",
              fontfamily: "Poppins",
              fontSize: "20px",
              fontWeight: "600",
              fontStretch: "normal",
              fontStyle: " normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAlign: "left",
              color: "#032541",
            }}>
              <h4>Payments Method</h4>
            </div>
            <div className="payments-filter" style={{
              width: "44px",
              height: "21px",
              margin: "2px 16px 20px 0",
              fontfamily: "Poppins",
              fontSize: "15px",
              fontweight: "500",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "2.2",
              letterSpacing: "normal",
              textAlign: "left",
              color: "#032541",
            }}>
              <div className="payments-title" style={{

                margin: "28px 124px 31px 14px",
                fontfamily: "Poppins",
                fontSize: "20px",
                fontWeight: "600",
                fontStretch: "normal",
                fontStyle: " normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#032541",
              }}>
                <span style={{ display: "flex" }}>Filter   <img src={filterIcon} style={{
                  color: "red",
                  width: "24px",
                  height: "24px",
                  margin: "0 0 0 16px",
                  objectFit: "contain",
                }} alt="filter" /></span>
              </div>
            </div>
          </div>
          
          <div className="">
            <div className="row">
              <div className="col-sm-4 col-md-4 col-xl-4 " style={style_contain}>
              <Link to={`/cashier/cash/${cashierId}`} style={{
              textDecoration:"None"
            }}>
                <div className="d-flex">
                  <img src={cash} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">Cash</div>
                    <div className="title-cash mt-2">
                    {numberFormat(totalCashierCash)}
                    </div>
                  </div>
                </div>
                </Link>

              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={MPESA} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">MPESA</div>
                    <div className="title-cash mt-2">{numberFormat(AllMpesaSale)}</div>
                  </div>
                </div>

              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={equitel} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">Equitel</div>
                    <div className="title-cash mt-2">{numberFormat(AllEquitySale)}</div>
                  </div>
                </div>

              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={CARD} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">CARD</div>
                    <div className="title-cash mt-2">{numberFormat(AllCardaSale)}</div>
                  </div>
                </div>

              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={BANK} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">Cash</div>
                    <div className="title-cash mt-2"> {numberFormat(AllBankSale)}</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={VOOMA} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">VOOMA</div>
                    <div className="title-cash mt-2">{numberFormat(AllVoomaaSale)}</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={voucher} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">Voucher</div>
                    <div className="title-cash mt-2">{numberFormat(Voucher)}</div>
                  </div>
                </div>

              </div>
              <div className="col-sm-4 col-md-4 col-xl-4" style={style_contain_notCash}>
                <div className="d-flex">
                  <img src={customerAcc} style={{height:"70px"}} alt="" />
                  <div className="d-block ml-3">
                    <div className="title">Customer Account</div>
                    <div className="title-cash mt-2">{numberFormat(AllCustomerSale)}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Cashier