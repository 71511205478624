import { Grid, Typography, Breadcrumbs, Button ,TextField ,Autocomplete ,Stack ,Modal , Box } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom'
import CustomDataGrid from '../../../products/stockComponents/customDataGrid';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import PartnerCreateProduct from '../Outlets/PartnerCreateProduct';
import CustomSearchInput from '../../../School/CustomSearchInput';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PartnerEditProduct from './partnerEditProduct';
import WarningImg from "../../../../images/warning.png" 
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Partner</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Products</Typography>,
]

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };



export default function PartnerProducts() {
    const navigate = useNavigate();
    const [dataGridPageSize, setPageSize] = useState(10);
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue] = newqueryParamValue.split("?")
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue , setSearchValue] = useState('')
    const [businessFound, setBusinessFound] = useState([])
    const [selectedBusinessNo, setSelectedbnssNumber] = useState('')
    const [options ,setOptions] = useState([])
    const partnerName = localStorage.getItem("affiliatedPartner")

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })


    function getActiveBusinesses() {
        try {
          HttpComponent({
            method: 'POST',
            url: `/api/getActiveBusinesses`,
            token: localStorage.getItem('X-Authorization'),
            body: {
              module: 'all module',
              partnerName: partnerName
            }
          }).then((data) => {
            if (data?.status === 200) {
              setBusinessFound(data?.response?.data)
            }
    
          })
        } catch (error) {
    
        }
    
      };
    
      useEffect(() => {
        getActiveBusinesses();
      }, []);
    
    
      const createArray = () => {
        if (businessFound) {
          const uniqueStrings = new Set();
          businessFound?.forEach((obj) => {
            let string = `${obj.businessNumber} - ${obj.businessName}`;
            uniqueStrings.add(string);
          });
          setOptions(Array.from(uniqueStrings));
        }
      };
    
      useEffect(() => {
        if (businessFound?.length > 0) createArray();
      }, [businessFound]);
    
    
      const [newBusinessNumber, setNewBusinessNumber] = useState('')
    
      useEffect(() => {
        if (selectedBusinessNo) {
          const regex = /(\d+) -/;
          let match = selectedBusinessNo.match(regex);
          if (match) {
            const extractedNumber = match[1];
            setNewBusinessNumber(extractedNumber)
    
          }
        }
      }, [selectedBusinessNo]);

  
    const [openDeactivate , setOpenDeactivate] = useState(false)
    const handleCloseDeactivate  = () =>setOpenDeactivate(false)
    const [productName ,setProductName] = useState('')
    const [productId ,setProductId] = useState('')

    const handleOpenDeactivate  = (params) =>{
      setProductName(params?.row?.name)
      setProductId(params?.row?.id)
      setOpenDeactivate(true)

    }

     // update product

     function handleDeactivate() {
      try {
          HttpComponent({
              method: 'PUT',
              url: `/api/updateProduct`,
              body: {
                  productState: "Deactivation",
                  productId: [productId]
              },
              token: localStorage.getItem('X-Authorization')
          }).then((data) => {
              if (data.status === 202) {
                  setSuccessShow({ state: true, message: `${productName}  has been deactivated`})
                  setTimeout(()=>{
                    getProducts();
                    handleCloseDeactivate()
                  },1000)
                 
              } else {
                  setErrorShow({ state: true, message: data.response.message })
              }
          })
      } catch (error) {

      }
  }


    
  const RenderCell = (params) => {
    return (
      <Stack direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }} gap={2}>
        <EditIcon onClick={() => navigate(`/partnerproducts?EditProduct?${params?.row?.id}?${params?.row?.businessId}`)} sx={{ color: '#032541' }} />
        <DeleteIcon onClick={() => { handleOpenDeactivate(params) }} sx={{ color: '#dc3545' }} />
      </Stack>
    )
  }

    const productColumns = [
        { field: "name", headerName: "Name", flex: 1 },
        { field: 'businessName', headerName: 'Business Name', flex: 1 },
        { field: "category", headerName: "Category", flex: 1 },
        { field: 'productCode', headerName: 'ProductCode', flex: 1 },
        { field: 'amountType', headerName: 'AmountType', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1 , renderCell:RenderCell },
    ]

    // get insurance companies

    function getProducts() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listByProductsByPartner?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&businessNumber=${newBusinessNumber}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getProducts()
    }, [pageState.page, dataGridPageSize ,queryParamValue , searchValue ,newBusinessNumber ])

    const productData = pageState?.data?.map((config) => ({
        id: config._id,
        name: config?.productName,
        category: config?.productCategory,
        productCode:config?.productCode,
        amountType: config?.priceStatus,
        amount: config?.productPrice,
        businessName: config?.businessName,
        businessId:config?.businessID
    }))




    return (
        <Grid container direction={'column'}>
            {queryParamValue === 'AddProduct' ? <PartnerCreateProduct /> : queryParamValue === 'EditProduct' ? <PartnerEditProduct/>: <>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Button onClick={() => navigate(`/partnerproducts?AddProduct`)} style={{ width: '146px', marginLeft: '10px', textTransform: "inherit", color: '#fff', backgroundColor: '#032541' }}>Create Products</Button>
                </Grid>
                <Grid item mt={2} width={'100%'} alignItems={'center'} display={'flex'}>
                    <Grid container gap={3}>
                        <Grid item mt={-1}>
                            <CustomSearchInput name={'searchValue'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search'} />
                        </Grid>
                        <Grid item width={'40%'}>
                            <Autocomplete
                                label="Select Business"
                                variant="outlined"
                                fullWidth
                                size="medium"
                                value={selectedBusinessNo}
                                onChange={(event, newValue) => {
                                    setSelectedbnssNumber(newValue);
                                }}
                                options={options}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Business" variant="outlined" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item width={"100%"}>
                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={productColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productData} />
                </Grid>
            </>}
            <Modal
                open={openDeactivate}
                onClose={handleCloseDeactivate}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} spacing={4}>
                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Grid item>
                                    <img src={WarningImg} alt='Warning' />
                                </Grid>
                                <Grid item ml={2}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Deactivate Product  ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to deactivate -   <span style={{fontWeight:700 ,}}>{productName}</span>
                                    </Typography>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeactivate} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleDeactivate}  style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}