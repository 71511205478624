import { Grid, Stack, Typography, TextField, Button, MenuItem, InputLabel, Select, FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/businessUpdatedSlice";
import { handleEdited } from "../../../../features/AlertSlice";
import { handleBusinessEdited } from "../../../../features/EditedBusinessesSlice";
import { makeStyles } from "@mui/styles";
import HttpComponent from "../../../School/MakeRequest";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function EditBusiness() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  const reduxBusinessId = useSelector(
    (state) => state.DetailsOfbusiness.businessId
  );
  const reduxBusinessName = useSelector(
    (state) => state.DetailsOfbusiness.businessName
  );
  const reduxOwner = useSelector((state) => state.DetailsOfbusiness.owner);
  const reduxEmail = useSelector((state) => state.DetailsOfbusiness.email);
  const reduxPhoneNumber = useSelector((state) => state.DetailsOfbusiness.phoneNumber);
  const partnerBranch = useSelector((state) => state.DetailsOfbusiness.partnerBranch);
  const partnerRegion = useSelector((state) => state.DetailsOfbusiness.partnerRegion);
  const [regionUpdate, setRegionUpdate] = useState(partnerRegion);
  const [branchUpdate, setBranchUpdate] = useState(partnerBranch);

  const [allRegions, setAllRegions] = useState([]);
  const [allBranches, setAllBranches] = useState([]);


  function getAllRegionsList() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/v1/partnerregions`,
        body: null,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data?.status === 200) {
          const myfoundregion = data?.response?.data?.filter(item =>item?.status === 'Active')?.map((item) => ({
            value: item?._id,
            label: item?.regionName
          }))
          setAllRegions(myfoundregion)
        }
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getAllRegionsList()
  }, [])


  function getAllBranchesList() {
    try {
      HttpComponent({
        method: "GET",
        url:`/api/v1/allregionpartnerbranchesbyid?regionId=${regionUpdate}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data?.status === 200) {
          console.log(data , 'filetrdd')
          const myfoundbranch = data?.response?.data?.filter(item =>item?.status === 'Active')?.map((item) => ({
            value: item?.branchCode,
            label: item?.branchName
          }))
          setAllBranches(myfoundbranch)
        }
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getAllBranchesList()
  }, [partnerRegion ,regionUpdate])

  //value to set when user edits form
  const [businessId, setBusinessId] = useState(reduxBusinessId);
  const [businessName, setBusinessName] = useState(reduxBusinessName);
  const [owner, setOwner] = useState(reduxOwner);
  const [email, setEmail] = useState(reduxEmail);
  const [phoneNumber, setPhoneNumber] = useState(reduxPhoneNumber);
  const [salesPName, setSalesPName] = useState('')
  const [salesPCode, setSalesPCode] = useState('')
  
  const handleBusinessName = (e) => {
    setBusinessName(e.target.value);
  };
  const handleOwner = (e) => {
    setOwner(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const editedBy = localStorage.getItem("username");
  const timestamp = Date.now();
  const date = new Date(timestamp);
  const dateModified = date.toLocaleString();

  const [allSalesPersons, setAllSalespersons] = useState([])
  const [salesPerson , setSelectedSalesPerson] = useState(null)


  function getSalespersons() {
      try {
          HttpComponent({
              method: "GET",
              url: `/api/v1/getpartneraddsalesperson`,
              body: null,
              token: localStorage.getItem('X-Authorization')
          }).then((data) => {
              if (data?.status === 200) {
                  setAllSalespersons(data?.response?.data)
              }
          })
      } catch (error) {

      }
  }

  useEffect(() => {
      getSalespersons();
  }, [])


  const handleSalesPersonChange = (event) => {
      const selectedValue = event.target.value;
      const foundSalesP = allSalesPersons?.find((p) => p.code === selectedValue)
      setSelectedSalesPerson(selectedValue)
      if(foundSalesP){
          setSalesPName(foundSalesP?.salesName)
          setSalesPCode(foundSalesP?.code)
      }
  }

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});

  const handleUpdate = () => {
    setLocalDetails({
      businessId,
      businessName,
      owner,
      email,
      phoneNumber,
      editedBy,
      dateModified,
      partnerRegion:regionUpdate,
      partnerBranch:branchUpdate,
      salesPName:salesPName,
      salesPCode:salesPCode
    });
  };

  console.log(localDetails , 'localDetails')

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(handleBusinessEdited(localDetails));
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/Businesses");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Typography
        fontFamily="Poppins"
        fontSize={25}
        fontWeight="600"
        fontStyle="normal"
        textAlign="left"
        color="#032541"
      >
        Edit Business
      </Typography>
      <Breadcrumbs2
        point1={"Businesses"}
        link1={"/Businesses"}
        point2={"Edit Business"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Edit Business Details
            </Typography>
            <TextField
              label="Business Number"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxBusinessId}
              InputLabelProps={{
                shrink: true,
              }}
              //
              // onChange={handleSerialNo}
              disabled
            />
            <TextField
              label="Business Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxBusinessName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleBusinessName}
              autofocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Owner"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxOwner}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleOwner}
              className={classes.focusedTextField}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxEmail}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleEmail}
              className={classes.focusedTextField}
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxPhoneNumber}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handlePhoneNumber}
              className={classes.focusedTextField}
            />
            <Grid item py={2} display={'flex'} alignItems={'center'} gap={1} direction={'column'} style={{ width: '100%' }}>
              <FormControl style={{ width: '100%', }}>
                <InputLabel id="demo-simple-select-label">Select Region</InputLabel>
                <Select size="small" labelId="demo-simple-select-label" id="demo-simple-select" name='regionId' value={regionUpdate} label="Select Region" onChange={(e) => setRegionUpdate(e.target.value)}>
                  {allRegions?.map((region) => {
                    return <MenuItem id={region?.value} value={region?.value}>{region?.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item  display={'flex'} alignItems={'center'} gap={1} direction={'column'} style={{ width: '100%' }}>
              <FormControl style={{ width: '100%', }}>
                <InputLabel id="demo-simple-select-label">Select Branch</InputLabel>
                <Select size="small" labelId="demo-simple-select-label" id="demo-simple-select" name='branchcode' value={branchUpdate} label="Select Region" onChange={(e) => setBranchUpdate(e.target.value)}>
                  {allBranches?.map((branch) => {
                    return <MenuItem id={branch?.value} value={branch?.value}>{branch?.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item width={'100%'} mt={2}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Select Sales Person</InputLabel>
                    <Select size="small" labelId="demo-simple-select-label" id="demo-simple-select" value={salesPerson} label="Select Sales Person" onChange={handleSalesPersonChange}>
                        {allSalesPersons?.map((acc) => {
                            return <MenuItem value={acc?.code}>{acc?.salesName}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Sales Person Name </InputLabel>
                        <TextField  size="small" style={{ width: "100%" }} value={salesPName} disabled  id="outlined" required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Sales Person Code</InputLabel>
                        <TextField  size="small" style={{ width: "100%" }} value={salesPCode} disabled  id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/Businesses")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EditBusiness;
