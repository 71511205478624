import { Grid, Breadcrumbs, Typography, InputLabel, Select, Button, MenuItem, TextField, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, FormGroup } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HttpComponent from "../../../School/MakeRequest";
import { handleAddTenantHouseArray, handleChargeService, handleChargesData, handleInvoiceCharge, handleTenantBioData, handleTenantHouseData, handleTenantHouseServices } from "../../../../features/tenantsDataSlice";
import { useNavigate } from "react-router-dom";
import lodash from 'lodash'

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Invoices</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Create Invoice</Typography>
];

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES", }).format(value);

const CreateTInvoice = (props) => {

    const [invoiceType, setInvoiceType] = useState("");
    const [repeatType, setRepeatType] = useState("Select Schedule")
    const monthDays = Array.from({ length: 31 }, (_, i) => i + 1);
    const [dueDate, setDueDate] = useState('')
    const { X_Authorization } = useSelector((store) => store.user);
    const [selectedItems, setSelectedItems] = useState([]);
    const [tenant, setTenant] = useState([])
    const [selectedTenant, setSelectedTenants] = useState('')
    const [selectedHouses, setSelectedHouses] = useState([])
    const [itemse, setItmes] = useState(false)
    const [myTenant, setMyTenant] = useState({})
    const [installment ,setInstallment] = useState(0)
    const [period ,setPeriod] = useState(0)
    const[checked ,setChecked] = useState(false)
    const [toggleInputs,setToggleInputs] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //toggleName input

    const handleToggleInput = ()=>{
        setToggleInputs(!toggleInputs)
    }

    const handleChange = (event) =>{
        setChecked(event.target.checked)
    }
    console.log(myTenant, 'selected tennat')

    //fetch all customers

    function fetchAllTenant() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?limit=1000&page=1&customerType=&status=ACTIVE`,
                token: X_Authorization
            }).then((data) => {
                console.log(data, 'All tenants')
                setTenant(data.response.data)
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchAllTenant()
    }, [])

    //fetch houses by the selected  tenants

    function fetchHousesByT(){
        try {
            HttpComponent({
                method:"POST",
                body:{customerId:selectedTenant} ,
                url:'/api/get_houses_by_customer_id',
                token:X_Authorization

            }).then((data)=>{
                console.log(data , 'houses by tenant')
            })
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        let unSubsribed = false;

        if(!unSubsribed){
            fetchHousesByT()
        }
        
        return () => {
            unSubsribed = true
        }
    },[selectedTenant])

    //fetch details of selected tenant 

    function fetchSTenant() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_customer_id?customerId=${selectedTenant}`,
                token: X_Authorization
            }).then((data) => {
                if (data.status === 200) {
                    setMyTenant(data.response.data)
                }
            })

        } catch (error) {
            console.log(error)
        }
    }

    // useEffect to fetch details of the selected tenant

    useEffect(() => {
        fetchSTenant()
    }, [selectedTenant])

    useEffect(() => {

    }, [selectedTenant])

    //pick some values from the array

    const tenantDetails = {
        firstName:lodash.get(myTenant ,'firstName'),
        lastName:lodash.get(myTenant , 'lastName'),
        phone:lodash.get(myTenant , 'phone'),
        email:lodash.get(myTenant ,'email',),
        customerType:lodash.get(myTenant ,'customerType'),
        tenantId:lodash.get(myTenant ,'_id')
    } 

    const billableItems = lodash?.get(myTenant, 'billableItems')

   
  

    //select all invoices

    const handleSendSelectAll = (house) => {
        if (selectedHouses.some((selectedHouse) => selectedHouse?.itemNumber === house.itemNumber)) {
            setSelectedHouses(selectedHouses?.filter((selectedHouse) => selectedHouse?.itemNumber !== house?.itemNumber));
        } else {
            setSelectedHouses([...selectedHouses, house]);
        }

    }

     //handle preview Invoice

     const handlePreviewInvoice = () => {
        const updatedSelectedHouses = selectedHouses.map((house) => ({
            ...house,
            installment: installment,
            period: period,
            invoiceType: invoiceType,
            repeatType: repeatType,
          }));
        dispatch(handleTenantBioData(tenantDetails))
        dispatch(handleAddTenantHouseArray(updatedSelectedHouses))
        props?.preview()
     }

     useEffect(()=>{
        
     },[selectedHouses])

     console.log(selectedHouses , 'selected house')

    //select all invoices

    const handleSelectAll = () => {
        if (selectedHouses.length === billableItems?.length) {
          setSelectedHouses([]);
        } else {
          setSelectedHouses(billableItems?.map((house) => ({
            ...house,
            installment: installment,
            period: period,
            invoiceType: invoiceType,
            repeatType: repeatType
          })));
        }
      }

   

    // show only at dev environment

    const devOptions = [
        {
            label: 'Hourly',
            value: 'hourly'
        },
        {
            label: 'minutely',
            value: 'Minutely'
        },
    ]

    const devUrl = process.env.REACT_APP_BASE_URL || 'https://dev.zed.business'


    return (
        <div>
            <Grid container flexDirection={'column'}>
                <Grid item display={'flex'} alignItems={'center'}>
                    <ArrowBackIosNewIcon onClick={props?.goBack} />
                    <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Invoice</Typography>
                </Grid>
                <Grid item>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mt={2} xl={6} sm={12} lg={6} md={6}>
                    <InputLabel mb={1} id="invoice-type-label">Tenant Name<span style={{ color: "#dc3545" }}>*</span></InputLabel>
                    <div>
                        <Select
                            labelId="invoice-type-label"
                            id="invoice-type-select"
                            value={selectedTenant}
                            onChange={(e) => {
                                setSelectedTenants(e.target.value)
                                setItmes(true)
                            }} style={{ width: "415px" }}>
                            {tenant?.map((tenantN) => (
                                <MenuItem key={tenantN?._id} value={tenantN?._id}>{tenantN?.customerType === 'Company' ? tenantN?.name : tenantN?.customerType === 'Individual' ? tenantN?.name : null}</MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>
                <Grid item xl={6} sm={12} lg={6} md={6}>
                    <Grid container>
                        {selectedTenant &&
                            <Grid item style={{width:"415px"}}>
                                <Grid container display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'} mt={3}>
                                    <Typography>Select House (s) <span style={{ color: "red" }}>*</span></Typography>
                                    <Typography onClick={handleSelectAll} sx={{cursor:"pointer" ,color:"#17ae7b"}}>Select All </Typography>
                                </Grid>
                                <Grid container mt={3}>
                                    {billableItems?.map((tenantH) => {
                                        return (
                                            <Grid style={{ display: "flex", marginRight: "10px" , marginBottom:"3px" }} gap={3}>
                                                <ToggleButtonGroup key={tenantH.itemNumber} value={tenantH} onClick={() => { handleSendSelectAll(tenantH) }} style={selectedHouses?.some((selectedHouse) => selectedHouse?.itemNumber === tenantH?.itemNumber) ? { background: "#fff", color: "#17ae7b", width: "100px", border:"1px solid #17ae7b" ,height: "40px" } : { background: "#f0f0f0", color: "#707070", width: "100px", height: "40px" }}>
                                                    <ToggleButton value={tenantH} style={selectedHouses?.some((selectedHouse) => selectedHouse?.itemNumber === tenantH?.itemNumber) ? { background: "#fff", color: "#17ae7b", border:"1px solid #17ae7b", outlineWidth:"medium" , outlineStyle:"solid", width: "100px" ,height: "40px" } : { background: "#f0f0f0", color: "#707070", width: "100px", height: "40px" }}>
                                                        {tenantH?.itemNumber}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Grid container>
                            <Grid item mt={2} mb={2}>
                                <InputLabel id="invoice-type-label">Invoice Type<span style={{ color: "#dc3545" }}>*</span></InputLabel>
                                <Select
                                    labelId="invoice-type-label"
                                    id="invoice-type-select"
                                    value={invoiceType}
                                    onChange={e => { if (e.target.value !== "Invoice Type") { setInvoiceType(e.target.value); } }} style={{ width: "415px" }}>
                                    <MenuItem value="Invoice Type" disabled>Invoice Type</MenuItem>
                                    <MenuItem value="one-off">One-Off</MenuItem>
                                    <MenuItem value="recurring">Recurring</MenuItem>
                                </Select>
                                {invoiceType === 'recurring' && (
                                    <>
                                        <InputLabel style={{ marginBottom: "5px", marginTop: '5px' }} id="invoice-type-label">Repeat<span style={{ color: "#dc3545" }}>*</span></InputLabel>
                                        <Select labelId="invoice-type-label" id="invoice-type-select" value={repeatType} onChange={e => { if (e.target.value !== "Select Schedule") { setRepeatType(e.target.value); } }} style={{ width: "415px" }}>
                                            <MenuItem value="Select Schedule" disabled>Select Schedule</MenuItem>
                                            <MenuItem value="daily">Daily</MenuItem>
                                            <MenuItem value="weekly">Weekly</MenuItem>
                                            <MenuItem value="monthly">Monthly</MenuItem>
                                            <MenuItem value="quarterly">Quarterly</MenuItem>
                                            <MenuItem value="yearly">Yearly</MenuItem>

                                            {devUrl &&
                                                devOptions.map((optio) => {
                                                    return (
                                                        <MenuItem value={optio.value}>
                                                            {optio.label}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormGroup style={{ width: "400px" }}>
                            <FormControlLabel required control={<Checkbox onChange={handleChange} checked={checked} />} label="Invoice in installments" />
                            {checked && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography variant="h6" fontWeight={700}>Total: </Typography>
                                        <span onClick={handleToggleInput} style={{ cursor: "pointer" , color:"#17ae7b" }}>{toggleInputs ? 'set Up with periods':'set up with amount' }</span>
                                    </div> */}
                                    <div>
                                        {selectedHouses?.map((installmentAmount)=>{
                                                return(
                                                    <Grid  spacing={2} item mt={2} mb={2} display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                                                         <TextField disabled readOnly value={installmentAmount?.amount} defaultValue={installmentAmount?.amount}  type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"140px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", marginRight:"4px", fontSize: "12px", width: '140px' }} id="outlined-basic" label="Total" variant="outlined" />
                                                        <TextField disabled readOnly value={installmentAmount?.itemNumber} defaultValue={installmentAmount?.itemNumber}  type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"130px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", marginRight:"4px", fontSize: "12px", width: '130px' }} id="outlined-basic" label="House No" variant="outlined" />
                                                        <TextField disabled readOnly value={installmentAmount?.instalmentAmount} defaultValue={installmentAmount?.instalmentAmount}  type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"130px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '130px' }} id="outlined-basic" label={installmentAmount.unitPurpose === 'Rent' ? 'Rent':'Installment Amount' } variant="outlined" />
                                                    </Grid>
                                                )
                                        })}
                                      
                                    </div>
                                    {/* <Grid item mt={3}>
                                        
                                        {toggleInputs ?
                                            <Grid item>
                                                <TextField value={installment} onChange={(e)=>setInstallment(e.target.value)} style={{width:"400px"}} label="Installment Amount" />
                                                <Typography mt={2} variant="body2">period :</Typography>
                                            </Grid> :
                                            <Grid item>
                                                <TextField value={period} onChange={(e)=>setPeriod(e.target.value)} style={{width:"400px"}} label="Period" />
                                                <Typography mt={2} variant="body2">Installment Amount : </Typography>
                                            </Grid>
                                        }
                                    </Grid> */}
                                </div>
                            )}
                        </FormGroup>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xl={6} sm={12} lg={6} md={6} mt={4}>
                        <Grid container direction={'col'} spacing={1}>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{width:"415px"}} display={'flex'}  justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <div style={{ display: "flex", marginTop: "10%", marginBottom: '10%', justifyContent: "center" }}>
                        <Button style={{ width: "128px", border: "1px solid #dc3545", color: "#dc3545", textTransform: "inherit", marginRight: "20px" }} >Close</Button>
                        <Button onClick={!invoiceType.trim() || !selectedTenant || !selectedHouses.length > 0 ? null : () => handlePreviewInvoice()}  style={{ background: "#032541", textTransform: "inherit", color: "#fff" , opacity:!invoiceType.trim() || !selectedTenant || !selectedHouses.length >0 ? "0.5" : "1" }}>Preview Invoice</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default CreateTInvoice;
