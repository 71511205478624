export const businessConfigstyles = {
    universalStyle:{
        fontFamily:"Poppins"
    },
    wareHouseModal:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        backgroundColor: "#fff",
        width: "25%",
        height: "fit-content",
        fontFamily:"Poppins",
        padding:"30px"
    },
    wareHouseIconHolder:{
        display:"flex",
        alignItems:"center"
    },
    wareHouseImg:{
        width:"70px",
        height:"70px",
        ObjectFit:"cover",
    },
    wareHouseText:{
        fontSize:"16px",
        color:"#032541",
        fontWeight:"bold"
    },
    gotItButton:{
        backgroundColor:"#032541",
        color:"white",
        width:"30%"
    },
    divwithImg:{
        margin:"0px 50px 0px 0px"
    },
    wareHousePara:{
        color:"#707070",
    
        fontSize:"12px"
    },
    cancelButton:{
        border: "solid 1px #002543",
        color:"#002543"
    },
    deactivateButton:{
        backgroundColor:"#dc3545",
        color:"white"
    },
    paraWithDisplayFlex:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    }
}