import { Autocomplete, CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
const SearchComponentFreeSolo = ({ options = [], onChange, label, value, loading, freeSolo = true, disableClearable = false, inputType = 'text', sx = { width: '100%' }, }) => {
    const getOptionLabel = (option) => {
        if (option === null) return '';
        if (typeof option === 'string') return option;
        return `${option?.parentName || option?.itemNumber || ''} - ${option?.itemNumber || ''}`;
    };

    const handleChange = (event, newValue, reason) => {
        if (reason === 'selectOption' && typeof newValue === 'object') {
            onChange?.(event, newValue, reason);  
        } else {
            onChange?.(event, typeof newValue === 'string' ? newValue : getOptionLabel(newValue));
        }
    };
    const renderOption = (props, option) => (
        <li {...props}>
            {getOptionLabel(option)}
        </li>
    );
    return (
        <Stack spacing={2} sx={sx}>
            <Autocomplete
                freeSolo={freeSolo}
                disableClearable={disableClearable}
                options={options}
                onChange={handleChange}
                getOptionLabel={getOptionLabel}
                renderOption={renderOption}
                noOptionsText="No options"
                filterOptions={(x) => x} 
                isOptionEqualToValue={(option, value) => 
                    option.studentId === (typeof value === 'object' ? value : value)
                }
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'input') {
                        onChange(event, newInputValue);
                    }
                }}
                value={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            type: inputType,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="success" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
        </Stack>
    );
};

export default SearchComponentFreeSolo;