import { Box, Checkbox, Collapse, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import startOrderFunction from "./posmethods";
import { Add, KeyboardArrowDownRounded } from "@mui/icons-material";
import DateFormatter from "../../../utils/dateFormatter";
import { currencyconverter } from "../../../common/currencyconverter";
import CustomTableSkeleton from "./customTableSkeleton";
import { useNavigate } from "react-router-dom";
import { sum } from "lodash";
const Unpaidbills = ({ setShowRequestPayment, navigateToPayment, setUnPaidTotals, dateFrom , dateTo }) => {
  // initialize methods;
  const startOrderFunctions = new startOrderFunction();
  // get all unpaidBills:
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [noOfPages, setPageNumbers] = useState(0);
  const [dataGridPageSize, setPageSize] = React.useState(8);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
  useEffect(() => {
    setPageState({ ...pageState, isLoading: true });
    startOrderFunctions.getBillsByStatus("unpaid", page, dataGridPageSize, dateFrom , dateTo).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data?.response?.transaction, count: data?.response?.count, isLoading: false });

        setPageNumbers(Math.ceil(data?.response?.count / dataGridPageSize));
        setUnPaidTotals( data?.response?.total || 0)
      
      }
    });
  }, [page, dataGridPageSize, dateFrom , dateTo ]);

  //   collapse controls;
  const [collapse, setOpenCollapse] = useState(null);

  // handle pagination;
  const handlePage = (event, value) => {
    setPageState({ ...pageState, isLoading: true });
    setPage(value);
  };

  // handle payments;
  const [deficitTotals, setBillTotals] = useState([]);
  const [paymentIds, setPaymentIds] = useState([]);
  const [arrayItems, setArrayItems] = useState([]);
  console.log("arrayItems ==+== -->", arrayItems)
  const allTotals = sum(deficitTotals)
  const handlePayment = (bill) => {
    let deficit = bill?.deficit;
    let orderId = bill?._id;
    let orderItems = bill?.items;

    const newArrayItems = [...arrayItems];
    const incomingItems = [...orderItems];
    // reciptNumber
    incomingItems?.map((newItem) => {
      // const newItemsIndex = newArrayItems.findIndex((item) => item._id === newItem._id);
      const newItemsIndex = newArrayItems.findIndex((item) => item.reciptNumber === newItem.reciptNumber);
      if (newItemsIndex === -1) {
        newArrayItems.push(newItem);
      } else {
        newArrayItems.splice(newItemsIndex, 1);
      }

      setArrayItems(newArrayItems);
    });

    const newpaymentIds = [...paymentIds];
    const newdeficitTotals = [...deficitTotals];
    const payIdIndex = newpaymentIds.findIndex((id) => id === orderId);
    if (payIdIndex === -1) {
      newpaymentIds.push(orderId);
      newdeficitTotals.push(deficit);
    } else {
      newpaymentIds.splice(payIdIndex, 1);
      newdeficitTotals.splice(payIdIndex, 1);
    }
    setPaymentIds(newpaymentIds);
    setBillTotals(newdeficitTotals);
  };

  // show payment button

  useEffect(() => {
    if (paymentIds.length > 0) {
      return setShowRequestPayment(true);
    }
    return setShowRequestPayment(false);
  }, [paymentIds, setShowRequestPayment]);


  useEffect(() => {
    if(navigateToPayment){
          navigate(`/pos/requestPayment?unPaidstartOrderTag?${JSON.stringify(paymentIds)}?${allTotals}?${JSON.stringify(arrayItems)}`);
    } 
  },[navigateToPayment ])

  return (
    <Box sx={{display:{sm:"block"}}} component={Paper} padding={2}>
      {pageState.isLoading && <LinearProgress color="inherit" />}
      <TableContainer sx={{ marginBottom: 2 }}  >
        <Table>
          <TableHead sx={{ color: "#032541", fontSize: "14px" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Order No</TableCell>
              <TableCell>No of Items</TableCell>
              <TableCell>Served By</TableCell>
              <TableCell>Date & Time</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>

          {pageState.isLoading && <CustomTableSkeleton length={dataGridPageSize} numberOfRows={6} />}
          <TableBody sx={{ color: "#707070" }}>
            {!pageState.isLoading &&
              pageState.data.map((bill, index) => {
                return (
                  <>
                    {bill?.childOrders?.length === 0 ? (
                      <TableRow
                      
                        key={index}
                        // sx={{border: '1px solid #e1d6d6',color:'#707070', }}
                        sx={{ "& > *": { mb: " !important", cursor:'pointer' } }}
                      >
                        <TableCell sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}>
                          <Checkbox color="error"  sx={{fontSize:'10px', color:'#dc3545'}} onClick={() => handlePayment(bill)} /> 
                          {/* <Add color="#707070" /> */}
                        </TableCell>
                        <TableCell onClick={() => navigate(`/detailedbill/${bill._id}`)}>{bill.orderNumber}</TableCell>
                        <TableCell onClick={() => navigate(`/detailedbill/${bill._id}`)}>{bill.items.length}</TableCell>
                        <TableCell onClick={() => navigate(`/detailedbill/${bill._id}`)}>{bill.cashier}</TableCell>
                        <TableCell onClick={() => navigate(`/detailedbill/${bill._id}`)}>{DateFormatter(bill.createdAt)}</TableCell>
                        <TableCell onClick={() => navigate(`/detailedbill/${bill._id}`)}>{currencyconverter(bill.transamount)}</TableCell>
                      </TableRow>
                    ) : (null
                      // <>
                      //   <TableRow
                      //     key={index}
                      //     //  sx={{border: '1px solid #e1d6d6', color:'#707070'}}
                      //     sx={{ "& > *": { mb: " !important" } }}
                      //   >
                      //     <TableCell sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer", color: "#707070" }}>
                      //       <Checkbox color="error" /> {collapse && <Add color="#707070" />} <KeyboardArrowDownRounded color="#707070" onClick={() => (collapse ? setOpenCollapse(null) : setOpenCollapse(bill._id))} />{" "}
                      //     </TableCell>
                      //     <TableCell>{bill.orderNumber}</TableCell>
                      //     <TableCell>{bill.items.length}</TableCell>
                      //     <TableCell>{bill.cashier}</TableCell>
                      //     <TableCell>{DateFormatter(bill.createdAt)}</TableCell>
                      //     <TableCell>{currencyconverter(bill.transamount)}</TableCell>
                      //   </TableRow>

                      //   <Collapse in={collapse === bill._id} timeout="auto" unmountOnExit>
                      //     <Table>
                      //       <TableHead>
                      //         <TableRow>
                      //           <TableCell></TableCell>
                      //           <TableCell>Order No</TableCell>
                      //           <TableCell>No of Items</TableCell>
                      //           <TableCell>Served By</TableCell>
                      //           <TableCell>Date & Time</TableCell>
                      //           <TableCell>Amount</TableCell>
                      //         </TableRow>
                      //       </TableHead>

                      //       <TableBody>
                      //         {bill?.childOrders?.map((childOrder, index) => {
                      //           return (
                      //             <TableRow key={index}>
                      //               <TableCell></TableCell>
                      //               <TableCell>{childOrder.orderNumber}</TableCell>
                      //               <TableCell>{childOrder.items.length}</TableCell>
                      //               <TableCell>{childOrder.cashier}</TableCell>
                      //               <TableCell>{DateFormatter(childOrder.createdAt)}</TableCell>
                      //               <TableCell>{currencyconverter(childOrder.transamount)}</TableCell>
                      //             </TableRow>
                      //           );
                      //         })}
                      //       </TableBody>
                      //     </Table>
                      //   </Collapse>
                      // </>
                    )}
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handlePage} variant="outlined" shape="rounded" />
      </Box>
    </Box>
  );
};

export default Unpaidbills;
