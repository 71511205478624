import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isSideBarOpen: true,
  isMobile: false,
  wareHouseState:false
};

const sideBarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    closeSideBar: (state, action) => {
      const { sideBarState } = action.payload;
      state.isSideBarOpen = sideBarState;
    },
    openSideBar: (state, action) => {
      state.isSideBarOpen = true;
    },
    resetSideBarDefault: (state, action) => {
      state.isSideBarOpen = true;
    },
    closeMobileMenu: (state, action) => {
      const { sideBarState } = action.payload;
      state.isMobile = sideBarState;
    },
    openMobileMenu: (state, action) => {
      state.isMobile = true;
      state.isSideBarOpen = false;
    },
    switchWarehouseState:(state, action) => {
      state.wareHouseState = action.payload
    }
  },
});

export const { closeSideBar, openSideBar, resetSideBarDefault, openMobileMenu, switchWarehouseState } = sideBarSlice.actions;

export default sideBarSlice.reducer;
