import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import loginLogo from "../../images/zedLogo.png";
import LoginImg from "../../images/logisvg.svg"
import { Button, Grid, TextField , FormControl , RadioGroup , FormLabel ,Typography,  FormControlLabel , Radio } from "@mui/material";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ForgotPass = () => {
  const navigate = useNavigate();
  const [email, setUserEmail] = useState("");
  const [userPhone, setPhone] = useState("");
  const [resetOption , setResetOption] = useState('')

  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });

  //console.log(resetOption , 'resetOption >>>')

console.log(userPhone , 'userPhone')

  // Submit Registered Email Address
  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(baseUrl + "/posForgotPinVersion2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: resetOption === 'phone' ?  JSON.stringify({ phoneNumber: "+" + userPhone }): JSON.stringify({ userEmail: email }),
      });

      const data = await response.json();
     // console.log(data);
      if (response.status === 209) {
        setSuccessShow({ state: true, message: data.message });
        localStorage.clear();
        localStorage.setItem("email", email);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setErrorShow({ state: true, message: data.message });
      }
    } catch (e) {
      console.log(e);
    }
  };

  //console.log(email , 'email.trim()')

  const handleNavigate = ()=> navigate('/')

  return (
    <Grid container style={{ fontFamily: "Poppins" }}>
      {/*snackBar*/}
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

      {/*Header logo Container*/}
      <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        {/*Logo image*/}
        <Grid item>
          <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
        </Grid>
      </Grid>

      {/*login image and Form container*/}
      <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} column={1} alignContent={"flex-start"} style={{ marginTop: "5%", paddingLeft: "5%" }}>
        {/*login image*/}
       
        <Grid item lg={6} sm={12} xs={12} xl={6} md={6}>
          <img src={LoginImg}  className={`img-fluid`} alt={"loginimage"} />
        </Grid>

        {/*Login Form*/}
        <Grid item lg={6} sm={12} xs={12} xl={6} md={6}>
          {/*Login form*/}
          <Grid item display={'flex'} alignItems={'center'} mb={2}>
              <span onClick={handleNavigate} style={{ fontSize: "20px", cursor:"pointer", display:'flex' , alignItems:'center' ,  color: "#707070", marginLeft:'-10px', fontWeight: "600" }}>  <ArrowBackIosNewIcon style={{marginRight:'5px'}} /> Back</span>
            </Grid>
          <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>
            {/*login label*/}
            <Grid item>
              <span style={{ fontSize: "20px", color: "#032541", fontWeight: "bolder" }}>Forgot Pin?</span>
            </Grid>
          </Grid>
          {/*Phone*/}
          <Grid container direction={'column'}>
            {/*login input label*/}
            <Grid item pt={3}>
              <Typography>Select Option to Reset your Pin</Typography>
            </Grid>
            <Grid item pt={3}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="phone" control={<Radio value={'phone'} onChange={(e)=>setResetOption(e.target.value)} />} label="Phone" />
                  <FormControlLabel value="email" control={<Radio value={'email'} onChange={(e)=>setResetOption(e.target.value)}/>} label="Email" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {resetOption === 'phone' ?
              <Grid item  style={{ marginTop: "5%" }} pr={3} lg={12} sm={12} xs={12} xl={12} md={12}>
                 <PhoneInput inputStyle={{ height: "55px" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />
              </Grid> :
              resetOption === 'email' ?
                <Grid item style={{ marginTop: "5%" }} pr={3} lg={12} sm={12} xs={12} xl={12} md={12}>
                   <TextField style={{width:'100%'}} id="outlined-basic" label="Email Address" variant="outlined"  required type="email" value={email} onChange={(e) => setUserEmail(e.target.value)} />
                </Grid> :
                null
            }

           

            {/*phone number input*/}
          
          </Grid>

          {/*Login button*/}
          {(resetOption === 'phone' )|| (resetOption === 'email') ?
            <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "10%" }}>
              <Button onClick={submitHandler} style={{ color: "white", backgroundColor: "#032541", height: "45px", width: "171px" }}>
                Send New Pin
              </Button>
            </Grid> : null
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ForgotPass;
