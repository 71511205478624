import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPageLoaded:false
}


const pageReloadSlice = createSlice({
  name:"pageload",
  initialState,
  reducers:{
    handlePageReload:(state, action) => {
      state.isPageLoaded = true
    },
    reversePageLoadState:(state, action) => {
      state.isPageLoaded = false
    }
  }
})

export const {handlePageReload , reversePageLoadState } = pageReloadSlice.actions;

export default pageReloadSlice.reducer