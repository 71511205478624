import { Box, Breadcrumbs, Button, Divider, Grid, Menu, MenuItem, Tab, TextField, Typography, TableBody, TableRow, TableCell, Table } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomInputLabel from "../../School/CustomInputLabel";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../School/CustomTextField";
import { useState, useEffect } from "react";
import CustomSelectField from "../../School/CustomSelectField";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";


const baseUrl = process.env.REACT_APP_BASE_URL

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transaction  Details</Typography>,

]
const numberFormat = (value) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(value) }
const PreviewRaisePaymentOtherPayments = (props) => {
    const navigate = useNavigate()
    const transcationDetails = JSON.parse(localStorage.getItem('otherPayments'))
    const [transcationTypeOptions, setTranscationTypeOptions] = useState([])
    const [debitAccountOptions, setDebitAccountOptions] = useState([])
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    let creditAccountNumber = transcationDetails?.payeeAccount
    // creditAccountNumber: "MPESA 25479797979797" i want to get 25479797979797
    creditAccountNumber = creditAccountNumber?.split(" ")[1]
    const [formData, setFormData] = useState({
        transactionType: transcationDetails?.payeeAccount?.includes("MPESA") ? "MO" : "",
        debitBankName: "",
        debitBankCode: "",
        debitAccountNumber: "",
        payeeId: transcationDetails?.payeeId,
        description: "",
        amount: transcationDetails?.totalPrice,
        creditAccountNumber: creditAccountNumber,
        items: transcationDetails?.items,
    })

    const getTranscationDetails = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transcation_type`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data, 'data getTranscationDetails');

                if (data?.status === 200) {
                    let transcationType = data?.response?.data
                    transcationType = transcationType?.map((item) => {
                        return { label: item?.transactionName, value: item?.transactionType }
                    })
                    setTranscationTypeOptions(transcationType)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getTranscationDetails()
    }, [])
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const toSetBankNameAndCode = () => {
        const bankDetails = debitAccountOptions.find((item) => item.value === formData.debitAccountNumber)
        console.log(bankDetails, 'bankNamebankName');
        setFormData({ ...formData, debitBankCode: bankDetails?.bankCode, debitBankName: bankDetails?.bankName })
    }
    useEffect(() => {
        toSetBankNameAndCode()
    }, [formData.debitAccountNumber])
    const getDebitAccountDetails = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getfunds_transfer_config`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data, 'data getTranscationDetails');

                if (data.status === 200) {
                    let debitAccountDetails = data.response?.data
                    console.log(debitAccountDetails, 'debitAccountDetails');
                    debitAccountDetails = debitAccountDetails.map((item) => {
                        return { label: item.bankName + "-" + item.accountNumber, value: item.accountNumber, bankCode: item.bankCode, bankName: item.bankName }
                    })

                    setDebitAccountOptions(debitAccountDetails)

                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getDebitAccountDetails()
    }, [])

    const raisePayment =async () => {
        console.log(formData, 'formDataformData');
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/process/funds_transfer`,
                body: formData,
                token: localStorage.getItem('X-Authorization')
            }).then(async(data) => {
                console.log(data, 'data getTranscationDetails');

                if (data.status === 200 || data.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: "Payment Successfully raised" })
                    // remove otherPayments from local storage
                    // localStorage.removeItem('otherPayments')
                    let selectedFile = props.file
                    await uploadFile(selectedFile, data.response?.data?._id, localStorage.getItem('X-Authorization'))

                    navigate('/payables/transcations')

                }
                else {
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.message })
                }
            })
        }
        catch (error) {
            setErrorShow({ ...errorShow, state: true, message: error?.response?.error })

        }


    }


    const uploadFile = async (file, transcationId, token) => {
        console.log("file", 'transcationId', transcationId, 'token', )

        const url = `${baseUrl}/api/v1/funds_transfer/attachFundsTransferInvoice`; // Replace with your upload endpoint URL

        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        formData.append('invoice', file);
        formData.append('transactionId', transcationId);

        xhr.open('POST', url, true);
        xhr.setRequestHeader("X-Authorization", token);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    let data = JSON.parse(xhr.responseText);
                    console.log('File uploaded successfully');
                } else {
                    console.error('Failed to upload file');
                }
            }
        };

        xhr.send(formData);


    }


console.log(props,'props')
console.log(props.file,'props.file')
    return (
        <Grid container>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <Grid item mt={1}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Raise Payment</Typography>
            </Grid>
            <Grid container mt={3}>
                <Grid item>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item >
                {/* {console.log(transcationDetails, 'transcationDetails <<<<>>>>')} */}

                <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>Payee Details</p>
            </Grid>
            <Grid container >
                <Table style={{ width: "600px" }}>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Payment To : </TableCell>
                            <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.payeeName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Amount : </TableCell>
                            <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}> {numberFormat(transcationDetails?.totalPrice)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>Payment Mode </TableCell>
                            <TableCell style={{ fontSize: "14px", fontWeight: "900", color: "#707070" }}>{transcationDetails?.payeeAccount} </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </Grid>
            <Grid container direction={'column'} style={{ width: "600px" }}>
                <p style={{ fontSize: "14px", fontWeight: "900", color: "#022441", }}>Transaction Details</p>
                <CustomInputLabel required={true} label={"Transcation Type"} />
                {transcationDetails?.payeeAccount?.includes("MPESA")  ?  <TextField
                    // name={"transactionType"}
                    value={"MOBILE MONEY"}
                    // onChange={handleInputChange}
                    // placeholder={"Description"}
                    disabled={true}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 1 }}
                    multiline
                    minRows={1}
                    InputProps={{
                        sx: {
                            width: "400px",
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1",
                            },
                        },
                    }}
                /> : 
                <CustomSelectField medium value={formData.transactionType} onChange={handleInputChange} name={"transactionType"} placeholder={"Select Transaction Type"} options={transcationTypeOptions} />
                }
                {/* <CustomSelectField medium value={formData.transactionType} onChange={handleInputChange} name={"transactionType"} placeholder={"Select Transaction Type"} options={transcationTypeOptions} /> */}

                <CustomInputLabel required={true} label={"Account To Debit"} />
                <CustomSelectField medium value={formData.debitAccountNumber} onChange={handleInputChange} name={"debitAccountNumber"} placeholder={"Select Account To Debit"} options={debitAccountOptions} />
                <CustomInputLabel required={true} label={"Description"} />

                <TextField
                    name={"description"}
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder={"Description"}
                    variant="outlined"
                    margin="dense"
                    sx={{ marginBottom: 1 }}
                    multiline
                    minRows={1}
                    InputProps={{
                        sx: {
                            width: "400px",
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1",
                            },
                        },
                    }}
                />
                <Grid container direction={'column'} style={{ width: "600px" }}>
                    <Button variant="contained" style={{ backgroundColor: "#022541", color: "#fff", width: "200px", height: "40px", marginTop: "20px" }} onClick={raisePayment}>Raise Payment</Button>
                </Grid >

            </Grid>

        </Grid >

    )
}

export default PreviewRaisePaymentOtherPayments