export const warehousestyles = {
  mainDivStyling: {
    padding: "0px 20px 0px 20px",
  },
  universalFont: {
    fontFamily: "Poppins",
  },
  addsupplierGridItems: {
    margin: "0px 0px 2% 0px",
    width: "70%",
  },
  addsupplierInputs: {
    width: "50%",
  },
  rightAlignedButtonHolder: {
    display: "flex",
    justifyContent: "right",
    width: "50%",
    margin: "10px 0px 0px 0px",
  },
  createcustomerInactiveBtn: {
    backgroundColor: "rgba(3, 37, 65, 0.4)",
    color: "white",
  },
  createCustomerActiveBtn: {
    backgroundColor: "rgba(3, 37, 65, 1)",
    color: "white",
  },
  dataGridStyling: {
    height: "400px",
    width: "100%",
    margin: "0px 0px 10px 0px",
  },
  supplierText: {
    fontSize: "25px",
    color: "#032541",
    fontWeight: "600",
    fontFamily: "Poppins",
  },
  addSupplierBtn: {
    backgroundColor: "#032541",
    color: "white",
  },
  gridContainerMargin: {
    margin: "0px 0px 2% 0px",
  },
  gridContainerMarginEmployee: {
    margin: "0px 0px 0px 0px",
  },
  activesupplierActions: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    cursor: "pointer",
  },
  deactivateSupplierModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "25%",
    height: "fit-content",
    fontFamily: "Poppins",
    padding: "30px",
  },
  supplierIconHolder: {
    display: "flex",
    alignItems: "center",
  },
  divwithImg: {
    margin: "0px 50px 0px 0px",
  },
  wareHouseImg: {
    width: "70px",
    height: "70px",
    ObjectFit: "cover",
  },
  suppliersText: {
    fontSize: "16px",
    color: "#032541",
    fontWeight: "bold",
  },
  suppliersPara: {
    color: "#707070",
    fontSize: "12px",
  },
  paraWithDisplayFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cancelButton: {
    border: "solid 1px #002543",
    color: "#002543",
  },
  deactivateButton: {
    backgroundColor: "#dc3545",
    color: "white",
  },
  activateButton: {
    backgroundColor: "#17ae7b",
    color: "white",
  },
  suspendSupplier:{
    backgroundColor:"#dc3545",
    color:"white"
  },
  suspendBtnRightAligned:{
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-end",
    width:"100%"
  },
  reactivateBtn:{
    backgroundColor:"#17ae7b",
    color:"white"
  },
  mainstoresText:{
    backgroundColor:"#eff6fb",
    padding:"10px",
    color:"#032541",
    fontWeight:"bold"
  },
  mainstoreSubText:{
    color:"#707070"
  },
  supplierTypeText:{
    color:"#032541",
    fontSize:"14px",
    fontWeight:600
  },
  supplierTypePara:{
    color:"#a2a2a2",
    fontSize:"14px",
  },
  selectSupplierText:{
    color:"#707070",
    fontSize:"16px",
    fontWeight:600,
  }
};
