import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { creditnotestyles } from "../creditNoteStyling/styless";
import HttpComponent from "../../School/MakeRequest";

const Declinedcreditnote = () => {

    const [declinedcreditnotes, setDeclinedcreditnotes ] = useState([]);
  const [loading, setLoading] = useState(true)
    const getDeclinedcreditnotes = () => {
      HttpComponent({
        method: "GET",
        url: `/api/get_credit_note_by_status?limit=1000&page=1&status=declined`,
        body: null,
        token: localStorage.getItem("X-Authorization"),
      })
        .then((data) => {
          if (data.status === 200) {
            const { results } = data.response;
            setDeclinedcreditnotes(results);
            setLoading(false)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    useEffect(() => {
      let isTrue = false;
  
      !isTrue ? getDeclinedcreditnotes() : null;
      return () => {
        isTrue = true;
      };
    }, []);
  
    return (
        <DataGrid
        style={creditnotestyles.dataGridStyling}
        columns={[
          { field: "creditNoteNo", headerName: "Credit Note No", flex: 1 },
          { field: "createdByName", headerName: "Created By", flex: 1 },
          { field: "dateCreated", headerName: "Date Created", flex: 1 },
          { field: "declinedByName", headerName: "Declined By", flex: 1 },
          { field: "dateDeclined", headerName: "Date declined", flex: 1 },
          { field: "amount", headerName: "Total Amount", flex: 1 },
          // { field: "Action", headerName: "Action" },
        ]}
        rows={declinedcreditnotes.map((creditNote, index) => ({
          id:index,
          creditNoteNo:creditNote.creditNoteNo,
          createdByName:creditNote.createdByName,
          dateCreated:new Date(creditNote.dateCreated).toDateString() +
          " " +
          new Date(creditNote.dateCreated).toLocaleTimeString(),
          declinedByName:creditNote.declinedByName,
          dateDeclined:new Date(creditNote.dateDeclined).toDateString() +
          " " +
          new Date(creditNote.dateDeclined).toLocaleTimeString(),
          amount:creditNote.amount
        }))}

        loading={loading}
      />
    )
}

export default Declinedcreditnote