import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { handleItemClick } from "../../../features/salesDropdownSlice";

export default function SalesDropdown() {
  const dispatch = useDispatch();

  const handleClick = (clicked) => {
    dispatch(handleItemClick(clicked)); //setting the clicked item in redux
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* <ThemeProvider theme={theme}> */}
          <Button variant="text" {...bindTrigger(popupState)}>
            <ArrowDropDownIcon sx={{ color: "#032541" }} />
          </Button>
          {/* </ThemeProvider> */}
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                handleClick("2022");
                popupState.close();
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color: "#707070",
                }}
              >
                2022
              </Typography>
            </MenuItem>
            <Divider variant={"middle"} />
            <MenuItem
              onClick={() => {
                handleClick("2023");
                popupState.close();
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color: "#707070",
                }}
              >
                2023
              </Typography>
            </MenuItem>
            <Divider variant={"middle"} />
            <MenuItem
              onClick={() => {
                handleClick("2024");
                popupState.close();
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  textAlign: "left",
                  color: "#707070",
                }}
              >
                2024
              </Typography>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
