import { Grid, Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import SalesChart from "./SalesChart";
import axios from "axios";
import SalesDropdown from "./SalesDropdown";
import { useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_BASE_URL;

function SalesStatistics() {
  const [rentalTotal, setRentalTotal] = useState(null);
  const [schoolTotal, setSchoolTotal] = useState(null);
  const [normalBizTotal, setNormalBizTotal] = useState(null);

  let year = useSelector((state) => state.salesDropdown.clickedItem);

  let yearToPass 

  if(year === "2022") {
    yearToPass = 2022
  }
  else if(year === "2023") {
    yearToPass = 2023
  }
  else if(year === "2024") {
    yearToPass = 2024
  }

  const fetchRentalTotals = async () => {
    const response = await axios.post(
      baseUrl + "/api/fetchRentalTransactionTotals",
      {
        year: yearToPass
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    setRentalTotal(response.data.RentalTransTotal);
    // console.log(businessTotals)
  };

  const fetchSchoolTotals = async () => {
    const response = await axios.post(
      baseUrl + "/api/fetchSchoolTransactionTotals",
      {
        year: yearToPass
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    setSchoolTotal(response.data.SchoolTransTotal);
    // console.log(businessTotals)
  };

  const fetchNormalBizTotals = async () => {
    const response = await axios.post(
      baseUrl + "/api/fetchNormalBizTransactionTotals",
      {
        year: yearToPass
      },
      {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );
    setNormalBizTotal(response.data.normalTransTotal);
    // console.log(businessTotals)
  };

  useEffect(() => {
    fetchRentalTotals();
    fetchSchoolTotals();
    fetchNormalBizTotals();
  }, [year]);


  let Group = localStorage.getItem("group");
  // let partnerName = localStorage.getItem("affiliatedPartner");
  const isAdmin = Group && Group === "Admin";
  // const isPartner = Group && Group === "Partner";

  return (
    <Stack
      width={"100%"}
      height={490}
      bgcolor="aqua"
      borderRadius={5}
      backgroundColor="#ffffff"
      shadowColor="rgba(145, 158, 171, 0.12)"
      shadowRadius={20}
      shadowOpacity={1}
      p={2}
      spacing={2}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="center"
          color="#032541"
        >
          Sales Statistics
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            // fontWeight="bold"
            fontStyle="normal"
            textAlign="right"
            color="grey"
          >
            View:
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="right"
            color="#000000"
          >
            {/* Yearly */}
            {year}
          </Typography>
          <SalesDropdown />
        </Stack>
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#032541"
            >
              {normalBizTotal === 0 || normalBizTotal > 0 ? (
                `KES ` + normalBizTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
              {/* {!normalBizTotal ? (
                // <CircularProgress />
                <Skeleton
                variant="text"
                fontSize={18}
                // width={"30%"}
                sx={{ bgcolor: "#fff", mx:2 }}
                animation="wave"
              />
              ) : (
                `KES ` + normalBizTotal?.toLocaleString()
              )} */}
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              Other Businesses
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#032541"
            >
              {schoolTotal === 0 || schoolTotal > 0 ? (
                `KES ` + schoolTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
              {/* {!schoolTotal ? (
                // <CircularProgress />
                <Skeleton
                variant="text"
                fontSize={18}
                sx={{ bgcolor: "#fff", mx:2}}
                animation="wave"
              />
              ) : (
                `KES ` + schoolTotal?.toLocaleString()
              )} */}
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              Schools
            </Typography>
          </Stack>
        </Grid>
        {isAdmin && (
          <Grid item xs={4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#032541"
            >
              {rentalTotal === 0 || rentalTotal > 0 ? (
                `KES ` + rentalTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
              {/* {!rentalTotal  ? (
                // <CircularProgress />
                <Skeleton
                variant="text"
                fontSize={18}
                // width={'80%'}
                sx={{ bgcolor: "#fff", mx:2 }}
                animation="wave"
              />
              ) : (
                `KES ` + rentalTotal?.toLocaleString()
              )} */}
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              Rentals
            </Typography>
          </Stack>
        </Grid>
        )}
        
      </Grid>
      <Stack width="100%" overflow={"scroll"} alignItems={"center"}>
        <SalesChart />
      </Stack>
    </Stack>
  );
}

export default SalesStatistics;
