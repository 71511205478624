import { Grid,Typography, Button, Box } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux'
import { useState } from 'react';
import AddTerminalUser from './AddTerminalUserStepper';

export default function AdmTermUser(props) {
    const terminalUsers = useSelector((store) => store.BusinessPartnerDetails.terminalUsers)
    const [openAddOutlet, setOpenAddoutlets] = useState(false)
    const handleOpenAddOutlet = () => setOpenAddoutlets(!openAddOutlet)

    return (
        <Grid container direction={'column'} height={'100%'} pt={4}>
            {openAddOutlet ? <AddTerminalUser openAddOutlet={handleOpenAddOutlet} /> : <>
                <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={() => setOpenAddoutlets(true)} style={{ width: '146px', marginLeft: '10px', textTransform: "inherit", height: '45px', color: '#fff', backgroundColor: '#032541' }}>Add Terminal User</Button>
                </Box>
                <Box sx={{height:'400px'}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>UserName</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Email</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Business Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Phone</TableCell>
                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }}>Teller Id</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {terminalUsers?.map((row) => (
                                <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        {row?.userName}
                                    </TableCell>
                                    <TableCell>{row?.email}</TableCell>
                                    <TableCell>{row?.businessName}</TableCell>
                                    <TableCell>{row?.userPhone}</TableCell>
                                    <TableCell>{row?.tellerId}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                <Box mt={2} component={'div'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Typography variant='h6'  sx={{color:'#dc3545' , fontSize:'14px'}}>Skip</Typography>
                    <Button onClick={() => props?.next()} style={{ width: '100px', marginLeft: '10px', textTransform: "inherit", height: '45px',color:'#dc3545', border:'1px solid #dc3545', backgroundColor: '#fff' }}>Cancel</Button>
                    <Button onClick={() => props?.next()} style={{ width: '100px', marginLeft: '10px', textTransform: "inherit", height: '45px', color: '#fff', backgroundColor: '#032541' }}>Next</Button>
                </Box>
            </>}
        </Grid>
    )
}



