import { Breadcrumbs, Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import VerifyStagePin from "./VerifyStage";
import OtpVerficationFT from "./otpCode";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Preview Transaction</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Verification</Typography>
]

export default function VerificationPayable(props) {
    console.log(props, 'props VerificationPayable');
    const steps = ['verifyStep1', 'verifyStep2']
    const [activeStep, setActiveStep] = useState(1)
    const [pin, setPin] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const navigate = useNavigate()

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

     const parentSubChildCallBack = (childData) => {
        console.log(childData, 'childData parentSubChildCallBack');
        let pin = childData.pin
        let email = childData.email
        let phone = childData.phone
        setPin(pin)
        setEmail(email)
        setPhone(phone)
        props.parentCallBackPinSend(childData)

     }

    

    const renderFormContent = () => {
        switch (activeStep) {
            case 1:
                return (
                   <VerifyStagePin parentSubChildCallBack = {parentSubChildCallBack} />
                );
            case 2:
                return (
                    <Grid item>
                        <OtpVerficationFT pin = {pin} email = {email}  phone = {phone} />
                    </Grid>
                );
            default:
                return null
        }

    }


    return (
        <Grid container direction={'column'}>
            <Grid item mt={1} display={'flex'} alignItems={'center'} style={{cursor:"pointer"}} onClick={() => navigate(-1)}>
                <ArrowBackIosNewIcon style={{ width: '25px', height: '30px', marginRight: "10px" }} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541", cursor:"pointer" }} onClick={() => navigate(-1)}>Verification</Typography>
            </Grid>
            <Grid item mt={1}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item>
                <Typography>step {activeStep} of {steps?.length}</Typography>
            </Grid>
            <Grid item mt={2}>
                {renderFormContent()}
            </Grid>
        </Grid>
    )
}