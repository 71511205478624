import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import { ArrowBack, Send } from "@mui/icons-material";
import mpesaicon from "../../../common/images/mpesa-icon.svg";
import bankPaybil from "../../../common/images/bank-paybill.svg";
import { universalStyles } from "../startorderuniversalstyles/styles";
import { SuccessAlertRightAligned } from "../startorderModals/modals";
import { ErrorAlertRightAligned } from "../startorderModals/modals";
import HttpComponent from "../../School/MakeRequest";
import PhoneInput from "react-phone-input-2";
import { UseFetch } from "../../creditnote/customHook/http";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Requestpayment = (props) => {
  const billTotal = props.billTotal;
  const toggleStartOrderComponents = props.toggleStartOrderComponents;
  const requestPayId = props.requestPayId;
  const setSuccessfullyPaid = props.setSuccessfullyPaid;
  const newIds = props.newIds;
  const backToUnsettledBills = props.backToUnsettledBills;
  const setpaySuccess = props.setpaySuccess;

  // mpesa payments
  const [mpesaPay, setMpesaPayment] = useState(false);
  const [bankDetailsPay, setBankDetails] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [error, setError] = useState({ errorState: false, message: "" });
  const [sendingStk, setSendingStk] = useState(false);
  const [counter, setCounter] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [clearinterval, setClearInterval] = useState(false);
  // customer details;
  const [customerPhone, setCustomerPhone] = useState("");
  const [billAmount, setBillAmount] = useState(billTotal);
  const [bankName, setBankName] = useState("");
  const [bankPaybill, setBankPaybill] = useState("");
  const[mpesaStatus, setMpesaStatus] = useState(false)
  const[cardStatus, setCardStatus] = useState(false)
  const[bankPaybillStatus, setBankPaybillStatus] = useState(false)
  const [errorShow, setErrorShow] = useState({state: false, message: ""})
  //Auth
  const { userId ,X_Authorization } = useSelector((store) => store.user);
  const navigate = useNavigate();

  // clear Interval

  useEffect(() => {
    checkOrderStatus()
    if (counter === 0) {
      if (intervalId) {
        clearInterval(intervalId);
        setError({ errorState: true, message: "request has timeout out" });
        setSendingStk(false);
        setTimeout(() => {
          
          setError({errorState:false})
        }, 3000);
        setCounter((counter) => (counter = 60));
      }
    }

    if (clearinterval) {
      clearInterval(intervalId);
      setCounter((counter) => (counter = 60));
    }
  }, [counter]);

  //  update error and success states
  useEffect(() => {
    if (error.errorState) {
      setTimeout(() => {
        setError({ errorState: false });
      }, 3000);
    }
  }, [error.errorState]);

  useEffect(() => {
    if (sucess) {
      setTimeout(() => {
        setSucess(false);
      }, 3000);
    }
  }, [sucess]);

  // check status;

  useEffect(() => {
    checkOrderStatus();
  }, [requestPayId, newIds]);


  // togglepayment forms
  const toggleMpesaPayForm = () => {
    if (!mpesaPay) {
      setMpesaPayment(true);
      if (bankDetailsPay) {
        setBankDetails(false);
      }
    }
  };

  const toggleBankPayForm = () => {
    if (!bankDetailsPay) {
      setBankDetails(true);
      if (mpesaPay) {
        setMpesaPayment(false);
      }
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const requestPaymentFunction = () => {
    setSendingStk(true);

    if (intervalId) {
      clearInterval(intervalId);
      setCounter((counter) => (counter = 60));
    }

    let id = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);
    setIntervalId(id);

    HttpComponent({
      method: "POST",
      url: "/api/pushstk",
      body: {
        amount: billAmount,
        phone: customerPhone[3]?.startsWith(0) ? "254" + customerPhone?.substring(4) : !customerPhone ? setError({ errorState: true, message: "check the phonenumnber" }) : customerPhone,
        businessId: localStorage.getItem("businessID"),
        orderIds: newIds ? newIds : [requestPayId],
        type: "order",
      },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setSucess(true);
          setTimeout(() => {
            checkOrderStatus();
          }, 30000);
        }
      })
      .catch((error) => {
        console.log(error);
        setSendingStk(false);
      });
  };

  // check order status
  const [deficit, setDeficit] = useState({ deficitBefore: 0, deficitAfter: 0 });

  useEffect(() => {
    if (deficit.deficitBefore > deficit.deficitAfter) {
      if (setSuccessfullyPaid) {
        setSendingStk(false);
        setSuccessfullyPaid(true);
        toggleStartOrderComponents();
        setClearInterval(true);
      }

      if (setpaySuccess) {
        setSendingStk(false);
        setpaySuccess(true);
        backToUnsettledBills();
        setClearInterval(true);
      }
    } else if (deficit.deficitBefore === deficit.deficitAfter) {
      setSendingStk(false);
    }
  }, [deficit.deficitBefore, deficit.deficitAfter]);
  const checkOrderStatus = () => {
    HttpComponent({
      method: "POST",
      url: "/api/order_payment_status",
      body: {
        pushyTransactionId: requestPayId ? requestPayId : newIds[0],
        businessNo: localStorage.getItem("businessId"),
      },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        // console.log("deficit", data.response.data.deficit);
        // console.log("transamount", data.response.order.transamount);
        // console.log("order status", data.response.order.status);
        // console.log("checking the order status ===========>" , data.response.data.paid);
        setDeficit((prevValue) => ({ ...deficit, deficitBefore: prevValue?.deficitAfter, deficitAfter: data?.response.data?.deficit }));

        // if(deficit.deficitBefore > deficit.deficitAfter ){
        //   if (setSuccessfullyPaid) {
        //     setSendingStk(false);
        //     setSuccessfullyPaid(true);
        //     toggleStartOrderComponents();
        //     setClearInterval(true);
        //   }

        //   if (setpaySuccess) {
        //     setSendingStk(false);
        //     setpaySuccess(true);
        //     backToUnsettledBills();
        //     setClearInterval(true);
        //   }
        // }else{
        //   setSendingStk(false);
        // }
      })
      .catch((error) => {
        console.log(error);
        setSendingStk(false);
      });
  };

  // const requestPaymentFunctionKcb = () => {
  //   setSendingStk(true);
  //   HttpComponent({
  //     method: "POST",
  //     url: "/api/v1/payments/initiate_kcb_stk_push",
  //     body: {
  //       amount: 1,
  //       phone: customerPhone[3]?.startsWith(0) ? "254" + customerPhone?.substring(4) : !customerPhone ? setError({ errorState: true, message: "check phonenumber" }) : customerPhone,
  //       businessId: localStorage.getItem("businessID"),
  //       orderIds: newIds ? newIds : [requestPayId],
  //       type: "order",
  //     },
  //     token: localStorage.getItem("X-Authorization"),
  //   })
  //     .then((data) => {
  //       if (data.status === 200) {
  //         // setSendingStk(false);
  //         setSucess(true);
  //         setCustomerPhone("");

  //         setTimeout(() => {
  //         let rerurnValue =  checkOrderStatus()
  //         }, 60000);
  //       } else {
  //         // console.log("timedOut  ========<> ", timedOut );
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setSendingStk(false);
  //     });
  // };

  const checkStatus = () =>{
    HttpComponent({
        method: 'GET',
        url: `/api/get_payment_methods_with_status`,
        body: null,
        token: X_Authorization
    }).then((data)=>{
        console.log("here store is data",data);
        if(data.status === 201){
            const mpesaStatus = data.response.data.find(method => method.name === "Mpesa").status;
            const cardStatus = data.response.data.find(method => method.name === "Card").status;
            const paybillStatus = data.response.data.find(method => method.name === "Bank Paybill").status;
            const bankStatus = data.response.data.find(method => method.name === "Banks").status;
            // setFromBackend(mpesaStatus)
            // setMpesaChecked(mpesaStatus)
            setMpesaStatus(mpesaStatus)
            setCardStatus(cardStatus)
            setBankPaybillStatus(paybillStatus)
            console.log('mpesa status', mpesaStatus);
            console.log('card status', cardStatus);
            console.log('paybill status', paybillStatus);
            console.log('mpesa status', bankStatus);

        }else{
            console.error("Error setting info")
            setErrorShow({state:true, message:data.response.message})
        }
    }).catch((error)=>{
        console.error(error.message);
    })
}

useEffect(() => {
    checkStatus()
}, []);

const userGroup = localStorage.getItem('group');
  return (
    <div style={universalStyles.payrequestuniversalStying}>
     { (cardStatus === true || mpesaStatus === true || bankPaybillStatus === true) ?
     <>
     <Grid container alignItems={"center"} direction={"row"} margin={"0px 0px 10px 0px"}>
        <Grid item alignItems={"center"}>
          <span style={universalStyles.requestmpesatext}>
            <ArrowBack onClick={() => (backToUnsettledBills ? backToUnsettledBills() : toggleStartOrderComponents())} /> Request Payment{" "}
          </span>
        </Grid>
      </Grid>

      <Grid container alignItems={"center"} direction={"row"} margin={"0px 0px 10px 0px"}>
        <Grid item>
          <span style={universalStyles.requestPayselectmethodstext}>Select a payment method</span>
        </Grid>
      </Grid>

      {/* payment option and modal holders */}
      <Grid container direction={"row"} margin={"0px 0px 10px 0px"} sx={{ width:'100%' }}>
        {/* payment option holder */}
        <Grid item xs={4} style={{ display:'flex', flexDirection:'column', width:'30%' }}>
          {mpesaStatus === true && <Grid direction={"row"} alignItems={"center"} 
            style={{ 
              margin: "20px 0px 20px 0px", 
              cursor: "pointer", 
              width:'90%', 
              display:'flex', 
              justifyContent:'space-between', 
              border:"solid 1px rgba(112, 112, 112, 0.15)",
              borderRadius:"6px",
              height:'70px',
              padding:'15px' 
            }} onClick={() => toggleMpesaPayForm()}>
            <img style={universalStyles.payIconStyling} src={mpesaicon} alt="mpesaicon" />
            <span style={universalStyles.payTextInactive}>Mpesa</span>
          </Grid>}
         {bankPaybillStatus === true && <Grid direction={"row"} alignItems={"center"} 
           style={{ 
            margin: "20px 0px 20px 0px", 
            cursor: "pointer", 
            width:'90%', 
            display:'flex', 
            justifyContent:'space-between', 
            border:"solid 1px rgba(112, 112, 112, 0.15)",
            borderRadius:"6px",
            height:'70px',
            padding:'15px' }}
          onClick={() => toggleBankPayForm()}>
            <img style={{ margin: "0px 55px 0px 0px" }} src={bankPaybil} alt="bankpaybill" />
            <span style={universalStyles.payTextInactive}> Bank Paybill</span>
          </Grid>}
        </Grid>

        {/* payment option modal */}
        <Grid item xs={8} style={{ backgroundColor: "#fafafa", padding: "40px" }}>
          {/* mpesa pay */}

          {mpesaPay && (
            <>
              <Grid container direction={"column"}>
                <Grid item style={{ width: "100%" }}>
                  <div style={{ margin: "0px 0px 10px 0px" }}>
                    <span style={universalStyles.payTextActive}>Request via MPESA</span>
                  </div>

                  <div>
                    <div style={universalStyles.payTextdiv}>
                      <p style={universalStyles.payTextInactivesmallpara}>Order No:</p>
                      <p style={universalStyles.payTextInactivesmallpara}>8759HGWIFI6796875</p>
                    </div>

                    <div style={universalStyles.payTextdiv}>
                      <p style={universalStyles.payTextactivesmallpara}>Order Amount</p>
                      <p style={universalStyles.payTextactivesmallpara}> {numberFormat(billTotal)} </p>
                    </div>
                  </div>

                  <div style={{ margin: "10px 0px 10px 0px" }}>
                    <p style={universalStyles.payTextactivesmallparanormal}>Kindly enter your mobile number to recieve the payment prompt and enter your Mpesa-Pin</p>

                    <div style={{ margin: "20px 0px 0px 0px" }}>
                      <div style={{ margin: "0px 0px 10px 0px" }}>
                        <PhoneInput required country={"ke"} enableSearch={true} label={"Mobile Number"} value={customerPhone} onChange={(phone) => setCustomerPhone(phone)} multiline inputStyle={{ height: "55px", width: "70%" }} />
                      </div>

                      <div style={{ margin: "0px 0px 10px 0px" }}>
                        <TextField label={"Amount"} required value={billAmount} onChange={(e) => setBillAmount(e.target.value)} multiline style={universalStyles.textFieldStyles} />
                      </div>
                    </div>

                    <p style={universalStyles.paraGraphButton}>
                      {!sendingStk && (
                        <Button style={universalStyles.awaitPaymentActivebtn} onClick={() => (!mpesaPay ? setError({ errorState: true, message: "Error in sending prompt" }) : !billAmount ? setError({ errorState: true, message: "amount is required" }) : customerPhone.length !== 13 && customerPhone.length !== 12 ? setError({ errorState: true, message: "correct phonenumber formart is required!" }) : requestPaymentFunction())}>
                          {" "}
                          {!sendingStk ? "Send Prompt" : "Resend Prompt"}{" "}
                        </Button>
                      )}

                      {sendingStk && (
                        <LoadingButton style={universalStyles.awaitPaymentActivebtn} endIcon={<Send />} loading={true} loadingPosition="end" variant="contained">
                          <span>Awaiting Payment</span>
                        </LoadingButton>
                      )}
                      {sendingStk && <p style={universalStyles.payTextactivesmallpara}>Resend Prompt: {counter} </p>}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </>
          )}

          {/* bankpaybill details */}
          {bankDetailsPay && (
            <>
              <Grid container direction={"column"}>
                <Grid item style={{ width: "100%" }}>
                  <div style={{ margin: "0px 0px 10px 0px" }}>
                    <span style={universalStyles.payTextActive}>Request via Bank Paybill</span>
                  </div>

                  <div>
                    <div style={universalStyles.payTextdiv}>
                      <p style={universalStyles.payTextInactivesmallpara}>Invoice No:</p>
                      <p style={universalStyles.payTextInactivesmallpara}>8759HGWIFI6796875</p>
                    </div>

                    <div style={universalStyles.payTextdiv}>
                      <p style={universalStyles.payTextactivesmallpara}>Invoice Balance</p>
                      <p style={universalStyles.payTextactivesmallpara}> {numberFormat(billTotal)} </p>
                    </div>
                  </div>

                  <div style={{ margin: "10px 0px 10px 0px" }}>
                    <p style={universalStyles.payTextactivesmallparanormal}>Kindly enter your mobile number to recieve the payment prompt and enter your Mpesa-Pin</p>

                    <div style={{ margin: "20px 0px 0px 0px" }}>
                      <div style={{ margin: "0px 0px 10px 0px" }}>
                        <TextField id="standard-select-currency" select label="Bank" multiline variant="standard" style={universalStyles.textFieldStyles}>
                          {[].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>

                      <div style={{ margin: "0px 0px 10px 0px" }}>
                        <TextField label={"Mobile Number"} required value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)} multiline style={universalStyles.textFieldStyles} />
                      </div>

                      <div style={{ margin: "0px 0px 10px 0px" }}>
                        <TextField label={"Amount"} required value={billTotal} onChange={(e) => setBillAmount(e.target.value)} multiline style={universalStyles.textFieldStyles} />
                      </div>
                    </div>

                    <p style={universalStyles.paraGraphButton}>
                      <Button style={universalStyles.awaitPaymentInactiveBtn}>Send Prompt</Button>
                    </p>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid></> :
      ((cardStatus === false && mpesaStatus === false && bankPaybillStatus === false) && userGroup === 'Merchant') ?
      <>
        <Grid container style={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', paddingTop:'50px' }}>
          <Grid item sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
            <p style={{ fontSize:"1rem" }}>No Payment Option Configured For This Business.</p>
            <Button style={{ color: "#ffffff", backgroundColor: "#032541", padding:'15px', width:'10%', marginTop:'30px' }} onClick={() => navigate('/paymentsettingup')}>Configure</Button>
          </Grid>
        </Grid>
      </> : 
      ((cardStatus === false && mpesaStatus === false && bankPaybillStatus === false) && userGroup === 'Cashier') ?
      <>
      <Grid container style={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', paddingTop:'50px' }}>
        <Grid item sx={{ width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
          <p style={{ fontSize:"1rem" }}>No Payment Option Configured.</p>
          <Button style={{ color: "#ffffff", backgroundColor: "#032541", padding:'15px', width:'10%', marginTop:'30px' }} onClick={() => navigate('/orders/unpaidbills')}>Back</Button>
        </Grid>
      </Grid>
    </> : <></>}
      {/* modals */}
      {sucess && <SuccessAlertRightAligned sucess={sucess} message={"Payment prompt sent successfully!"} />}
      {error.errorState && <ErrorAlertRightAligned error={error.errorState} message={error.message} />}
    </div>
  );
};
