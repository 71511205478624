import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    serialNo: "",
    posModel: "",
    alias: "",
    businessId: "",
    terminalId: ""
};

const terminalToUpdateSlice = createSlice({
  name: "terminalDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.serialNo = action.payload.serialNo;
      state.posModel = action.payload.posModel;
      state.alias = action.payload.alias;
      state.businessId = action.payload.businessId;
      state.terminalId = action.payload.terminalId;
    },
  },
});

export const { setDetails } = terminalToUpdateSlice.actions;

export default terminalToUpdateSlice.reducer;
