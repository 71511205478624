import React, { useState, useEffect } from "react";
import AddTenantService from "./addServiceDetails";
import CompleteTenant from "./completeTenant";
import ListServicesDetails from "./listServiceDetails";
import TenantBioDetails from "./tenantDetails/tenantBioDetail";
import CreateTInvoice from "./tenantDetails/CreateTinvoice";
import TenantProfileInvoice from "./tenantProfile/tenantProfileInvoice";
import PreviewInvoiceFromTenantProfile from "./tenantProfile/tenantPreviewTenantProfile";
import AddMoreServicesFromTenantProfile from "./tenantProfile/addservicetenantProfile";
import AddMoreUnitsToTenant from "./AddMoreUnitsToTenant";
import AddMoreChargesToTenant from "./addMoreCharges";


const baseUrl = process.env.REACT_APP_BASE_URL;

export default function TenantDetails(props) {
	const [tenantId, setTenantId] = useState(null)

	const updateTennantId = (id) => {
		setTenantId(id)
	}

	const [unitId, setUnitId] = useState('')


	const handleUpdateUnitId = (id) => {
		setUnitId(id)
	}

	function handleGoHome(){
		setFlagState('')
	}

	const [flatState, setFlagState] = useState('')

	function handleAddService() {
		setFlagState('addService')
	}
	function handleTenantProfileInvoice() {
		setFlagState('tenantProfileInvoice')
	}

	function handleListService() {
		setFlagState('listService')
	}

	function handleCompleteTenant() {
		setFlagState('completeTenant')
	}
	function handlepreviewInvoiceFromTenantProfile() {
		setFlagState('previewInvoiceFromTenantProfile')
	}

	function handleAddMoreservices() {
		setFlagState('Addmoreservices')
	}
	function AddMoreUnitsToTenantsProfile() {
		setFlagState('AddMoreUnitsToTenantsProfile')
	}

	function handleAddCharges(){
		setFlagState('Addmorecharges')
	}
	function handleCreateInvoice() {
		setFlagState('createTenantInvoice')

	}

	let renderComponent = <TenantBioDetails moreunitt={AddMoreUnitsToTenantsProfile} tenantId={updateTennantId} setFlagStatetoCreatInvoice={handleCompleteTenant} setFlagStateInvoice={handleTenantProfileInvoice} />


	if (flatState === 'addService') {
		renderComponent = <AddTenantService setFlagListServices={handleListService} />
	} else if (flatState === "listService") {
		renderComponent = <ListServicesDetails setFlagStateDone={handleCompleteTenant} />
	} else if (flatState === "completeTenant") {
		renderComponent = <CompleteTenant isExisting={true} setFlagStateBackToServices={handleListService} />
	} else if (flatState === 'createTenantInvoice') {
		renderComponent = <CreateTInvoice />
	} else if (flatState === 'tenantProfileInvoice') {
		renderComponent = <TenantProfileInvoice goToPreview={handlepreviewInvoiceFromTenantProfile} />
	} else if (flatState === 'previewInvoiceFromTenantProfile') {
		renderComponent = <PreviewInvoiceFromTenantProfile goBack={handleTenantProfileInvoice} unitTyId={handleUpdateUnitId} goAddMoreServices={handleAddMoreservices} />
	} else if (flatState === 'Addmoreservices') {
		renderComponent = <AddMoreServicesFromTenantProfile goBack={handlepreviewInvoiceFromTenantProfile} unitId={unitId} />
	} else if (flatState === 'AddMoreUnitsToTenantsProfile') {
		renderComponent = <AddMoreUnitsToTenant handleUpdateUnitId={handleUpdateUnitId} charges={handleAddCharges} units={unitId} back={handleGoHome} tenantId={tenantId} />
	} else if (flatState === 'Addmorecharges'){
		renderComponent =  <AddMoreChargesToTenant units={unitId}/>
	}

	return (
		<div style={{ fontFamily: 'Poppins' }}>
			{renderComponent}
		</div>
	)
}