import React, {useState, useEffect, useRef} from "react";
import {Modal, Grid, Box, Button, Select} from "@mui/material";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {useNavigate, useParams} from 'react-router-dom';
import {SelectedTenant} from "./selectedTenant";

const CreateTenantInvoice = (props) => {
	
	const [invoiceOpen, setInvoiceOpen] = useState(props.paymentOpen);
	const [invoiceType, setInvoiceType] = useState("Invoice Type");
	const [repeatType, setRepeatType] = useState("Select Schedule")
	const [dueDate, setDueDate] = useState('')
	const baseUrl = process.env.REACT_APP_BASE_URL;
	const navigate = useNavigate();
	const { id } = useParams();
	const [tenant, setTenant] = useState(props.tenantName)
	
	const [selectUnitModal, setSelectUnitModal] = useState(false)
	
	const handleClick = () =>{
		let invoiceObject = {};
		invoiceObject.customerId = id;
		if(invoiceType === "one-off"){
			invoiceObject.invoiceType = invoiceType;
			invoiceObject.invoiceFrequency = "once";
			invoiceObject.billableItems = props.billableItems
			saveAndRoute(invoiceObject);
		}else if(invoiceType === "recurring"){
			if(repeatType !== 'Select Schedule'){
				invoiceObject.invoiceType = invoiceType;
				invoiceObject.invoiceFrequency = repeatType;
				invoiceObject.billableItems = props.billableItems
				saveAndRoute(invoiceObject);
			}else{
				console.error("Select a repeat schedule to proceed")
			}
		}else{
			console.error("Select an Invoice Type to proceed")
		}
	}
	
	const saveAndRoute = (object) => {
		localStorage.setItem("invoice_data", JSON.stringify(object));
		navigate("/tenant_invoice_preview");
		props.onClose()
	}
	
	const wholeModal = {
		width: '500px',
		height: 'auto',
		backgroundColor: '#fff',
		borderColor:"transparent",
		borderRadius: "7px",
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	};
	const headerSection = {
		backgroundColor: '#dc3545',
		width: '100%',
		borderTopLeftRadius: "7px",
		borderTopRightRadius: "7px",
		height: '109px',
		flexDirection: "row",
		alignContent: "center",
		justifyItems: "center",
		textAlign: "center",
	}
	const secondaryInvoice = {
		border: "solid 1px #dc3545",
		backgroundColor:"transparent",
		color:"#dc3545",
		width: "75px",
		height: "45px",
		margin:"5px"
	}
	const primaryInvoice = {
		backgroundColor:"#032541",
		color:"white",
		width: "225px",
		height: "45px",
		margin:"5px"
	}
	
	useEffect(() => {
		setInvoiceOpen(props.paymentOpen);
	}, [props.paymentOpen]);
	
	const monthDays = Array.from({length: 31}, (_, i) => i + 1);
	
	return (
		<div style={{fontFamily : 'Poppins'}} >
			<Modal open={invoiceOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={wholeModal}>
					<div style={headerSection}>
						<div style={{marginLeft: "auto", marginRight: "auto", color: "white", fontWeight: "bold", fontSize: 25, paddingTop:"40px"}}>
							Create Invoice
						</div>
					</div>
					
					{/*Tenants House*/}
					<div style={{display:"flex", justifyContent:"center"}}>
						<div style={{marginTop:"20px"}}>
							<InputLabel id="invoice-type-label">Tenant Name:<span style={{color:"#dc3545"}}>*</span></InputLabel>
							<div>
								<Select
									labelId="invoice-type-label"
									id="invoice-type-select"
									value={tenant}
									onChange={(e) => {setTenant(e.target.value)}} style={{width:"415px"}}>
									<MenuItem onClick={() => {setSelectUnitModal(true)}} value={props.tenantName}>{props.tenantName}</MenuItem>
								</Select>
							</div>
						</div>

					</div>
					
					<div style={{display:"flex", justifyContent:"center"}}>
						<div style={{marginTop:"20px"}}>
							<InputLabel id="invoice-type-label">Invoice Type<span style={{color:"#dc3545"}}>*</span></InputLabel>
							<Select
								labelId="invoice-type-label"
								id="invoice-type-select"
								value={invoiceType}
								onChange={e => {if (e.target.value !== "Invoice Type") {setInvoiceType(e.target.value);}}} style={{width:"415px"}}>
								<MenuItem value="Invoice Type" disabled>Invoice Type</MenuItem>
								<MenuItem value="one-off">One-Off</MenuItem>
								<MenuItem value="recurring">Recurring</MenuItem>
							</Select>
							{invoiceType === 'recurring' &&(
								<>
									<InputLabel id="invoice-type-label">Repeat<span style={{color:"#dc3545"}}>*</span></InputLabel>
									<Select labelId="invoice-type-label" id="invoice-type-select" value={repeatType} onChange={e => {if (e.target.value !== "Select Schedule") {setRepeatType(e.target.value);}}} style={{width:"415px"}}>
										<MenuItem value="Select Schedule" disabled>Select Schedule</MenuItem>
										<MenuItem value="daily">Daily</MenuItem>
										<MenuItem value="weekly">Weekly</MenuItem>
										<MenuItem value="monthly">Monthly</MenuItem>
										<MenuItem value="quarterly">Quarterly</MenuItem>
										<MenuItem value="yearly">Yearly</MenuItem>
									</Select>
									
									<InputLabel id="invoice-type-label">Due Date<span style={{color:"#dc3545"}}>*</span></InputLabel>
									<Select labelId="invoice-type-label" id="invoice-type-select" value={dueDate} onChange={e => {if (e.target.value !== "Select Due Date") {setDueDate(e.target.value);}}} style={{width:"415px"}}>
										<MenuItem value="Select Due Date" disabled>Select Due Date</MenuItem>
										{
											monthDays.map((day) => (
												<MenuItem value={day}>{day}</MenuItem>
											))
										}
									</Select>
								</>
								
							)}
							
							{/*Buttons*/}
							<div style={{display:"flex", marginTop:"10%", marginBottom : '10%',justifyContent:"center"}}>
								<Button style={secondaryInvoice} onClick={props.onClose}>Close</Button>
								<Button style={primaryInvoice} onClick={()=>handleClick()}>Preview Invoice</Button>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
			
			{/*Selected Tenant Modal*/}
			<SelectedTenant billableItems={props.billableItems} extraService={props.extraService} customerId={props.customerId} tenantName={tenant} selectedOpen={selectUnitModal} onClose={() => setSelectUnitModal(false)} />
			
		</div>
	);
};
export { CreateTenantInvoice };
