import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));

function SendIcon() {
    return null;
}

export default function AdminBankSetUp () {
    const classes = useStyles();

    let {businessCat} = useSelector((store)=>store.businessCategory)
    if(!businessCat) businessCat = localStorage.getItem("businessCategory")


    return (
        <div>
            <Link style={{textDecoration: 'none', color : '#fff'}} to={"/adminbanksetup"}>
                <ListItem button className={classes.nested} disablePadding>
                    <div style={{marginRight : '5%'}}>
                        <AccountBalanceIcon />
                    </div>
                    <div>
                        <ListItemText primary="Banks" />
                    </div>
                </ListItem>
            </Link>
        </div>
    )
}
