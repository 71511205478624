import {Breadcrumbs, Grid, Typography} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, {useEffect, useState} from "react";
import CustomSelectField from "../../School/CustomSelectField";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import HttpComponent from "../../School/MakeRequest";
import {useSelector} from "react-redux";
import CustomTable from "../../School/CustomTable";
import CustomSearchInput from "../../School/CustomSearchInput";


// Bread Crumbs
const breadcrumbs = [
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Reports</Typography>
];

const columns = [
	{field : 'name', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Tenant Name</strong>)}},
	{field : 'houseType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >House Type</strong>)}},
	{field : 'houseNo', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >House No.</strong>)}},
	{field : 'pendingAmount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Balance</strong>)}, renderCell : (params) => {return (CurrencyFormatter(params.value, 'KES'))}},
]

export default function TenantReport() {
	try {

		const {X_Authorization} = useSelector((store) => store.user)

		const [year, setYear] = useState(new Date().getFullYear())
		const [month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }))
		const [years, setYears] = useState([])

		const [data, setData] = useState([])

		const yearsOptions = years.map(year => ({ value: year, label: year }));
		const monthOptions = [
			{ value: 'January', label: 'January' }, { value: 'February', label: 'February' },
			{ value: 'March', label: 'March' }, { value: 'April', label: 'April' },
			{ value: 'May', label: 'May' }, { value: 'June', label: 'June' },
			{ value: 'July', label: 'July' }, { value: 'August', label: 'August' },
			{ value: 'September', label: 'September' }, { value: 'October', label: 'October' },
			{ value: 'November', label: 'November' }, { value: 'December', label: 'December' },
		];

		const [loader, setLoader] = useState(false)

		// Search functionality
		const [dataToShow, setDataToShow] = useState([]);
		const [formData, setFormData] = useState({search:""})

		const handleInputChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value,});};

		const setSearchData = () => {
			let searchable = [...data]
			if(formData.search !== ""){
				let result = searchable.filter(tenant =>
					tenant.name?.toLowerCase()?.includes(formData.search) || tenant.name?.includes(formData.search) ||
					tenant.houseType?.toLowerCase().includes(formData.search) || tenant.houseType.includes(formData.search) ||
					tenant.houseNo?.toLowerCase().includes(formData.search) || tenant.houseNo.includes(formData.search)
				);
				setDataToShow(result);
			}else{
				setDataToShow(data)
			}
		}

		function monthNameToNumber (month) {return new Date(`${month} 1, ${year}`).getMonth() + 1}

		// Fetch years
		function fetchYears() {
			try {
				try {
					HttpComponent ({
						method : 'GET',
						url : `/api/get_date_range`,
						token : X_Authorization
					}).then((data) => {
						if (data.status === 200) {
							setYears(data.response.data )
						} else {
							console.log(`Error getting Years`)
						}
					}).catch((e) => {
						console.log(`Error getting Years`, e.message)
					})
				} catch (e) {
					console.log(`Error getting Years`, e.message)
				}
			} catch (e) {
				console.log(`Error Getting Years`, e.message)
			}
		}

		// Fetch Report
		function fetchTenantsReport () {
			try {
				setLoader(true)
				HttpComponent ({
					method : 'GET',
					url : `/api/tenants_report?page=1&limit=100000&year=${year}&monthValue=${monthNameToNumber(month)}&monthName=${month}`,
					token : X_Authorization
				}).then((data) => {
					if (data.status === 200) {
						setLoader(false)
						setData(data.response.data)
					} else {
						console.log(`Error getting Tenant Report`)
					}
				}).catch((e) => {
					console.log(`Error getting Tenant Report`, e.message)
				})
			} catch (e) {
				console.log(`Error getting Tenant Report`, e.message)
			}
		}

		// Search UseEffect
		useEffect(() => {
			setSearchData()
		}, [formData.search, data]);

		// Years useEffect
		useEffect(() => {
			fetchYears()
		}, [])

		// Tenants UseEffect
		useEffect(() => {
			fetchTenantsReport()
		}, [month, year])


		return (
			<div>

				{/*Header Container with BreadCrumbs*/}
				<Grid container justifyContent={'space-between'} mt={-3}>
					{/*Month Name*/}
					<Grid item>
						<span style={{color : '#032541', fontWeight : 'bold', fontSize : '25px'}}>Report</span>
						<Grid item>
							<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>
					</Grid>
				</Grid>

				{/*filters and Search*/}
				<Grid container direction={'row'} justifyContent={'flex-start'} mt={2} spacing={2}>

					{/*Year*/}
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '180px', fontFamily : 'Poppins',fontWeight : 'normal'}} value={year} onChange={(e) => {setYear(e.target.value)}} name={"Year"} placeholder={"Select Year"} options={yearsOptions} />
					</Grid>

					{/*Month*/}
					<Grid item>
						<CustomSelectField style={{color : '#032541FF', width : '180px', fontFamily : 'Poppins',fontWeight : 'normal'}} value={month} onChange={(e) => {setMonth(e.target.value)}} name={"Month"} placeholder={"Select Month"} options={monthOptions} />
					</Grid>

					{/*search*/}
					<Grid item>
						<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:"/>
					</Grid>
				</Grid>

				{/*Data grid*/}
				<CustomTable fontSize={'10px'} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />
			</div>
		)
	} catch (e) {
		console.log(`Error Tenant Report`, e.message)
	}
}