import { Grid, Typography, Breadcrumbs, Button, Tab } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import HttpComponent from '../MakeRequest';
import { useEffect, useState } from 'react';
import { NoRowsOverlay } from '../../No Rows/noRowsOverlay';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DataGrid } from '@mui/x-data-grid';
import DateFormatter from '../../../utils/dateFormatter';
import BgReportImg from '../../../images/bgreport.svg'
import { useSelector } from 'react-redux'
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { styled } from "@mui/material/styles";
import ExportMenu from '../ExportMenu';


// custom tab styling
const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": { backgroundColor: "" },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "18px",
        background: "",
        borderRadius: "5px",
        marginLeft: "-10px",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": { color: "#032541", opacity: 1 },
        "&.Mui-selected": {
            color: "#dc3545",
            backgroundColor: "#fef7f8",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": { backgroundColor: "blue" },
    })
);

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Session Report</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Preview Report</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Preview Session</Typography>
]

export default function PreviewSessionDrilldownReport(props) {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    // const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const sessionId = useSelector((store) => store?.teachingStaff?.sessionId)
    const [tabValue, setTabValue] = useState('Present')
    //handle tabvalue change
    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)
    const [sessionDetailinfo, setSessionDetails] = useState([])
    const [studentPresent, setStudentPresent] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [studentAbsent, setStudentAbsent] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })


    function generateReport() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/get_lec_current_session?sessionId=${sessionId}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                console.log(data)
                if (data?.status === 200) {
                    setSessionDetails(data?.response?.data[0])
                    setStudentAbsent({ ...studentAbsent, isLoading: false, total: data?.response?.count, data: data.response.data[0]?.absentiees })
                    setStudentPresent({ ...studentPresent, isLoading: false, total: data?.response?.count, data: data.response.data[0]?.attendies })
                }
            })
        } catch (error) {

        }

    }

    useEffect(() => {
        generateReport()
    }, [sessionId])

    const { courseName, endTime, sessionCode, lecName, startTime } = sessionDetailinfo

    const sessionDetails = [
        { headerName: "StudentName", field: 'sessionCode', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) }, renderCell: (params) => { return (<span>{params?.row?.firstName + ' ' + params?.row?.lastName}</span>) } },
        { headerName: "admissionNumber", field: 'admissionNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Id</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.admissionNumber}</span></>) } },
        { headerName: "status", field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.lastName ? 'Present' : ''}</span></>) } },
        {
            headerName: "Date", field: 'Date', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => {
                return (<><span style={{ marginLeft: "10px" }}>{DateFormatter(params?.row?.joinedAt)} - {DateFormatter(params?.row?.updatedAt)
                }</span></>)
            }
        },
    ]

    const AbsentStudent = [
        { headerName: "StudentName", field: 'StudentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) }, renderCell: (params) => { return (<span>{params?.row?.firstName + ' ' + params?.row?.lastName}</span>) } },
        { headerName: "admissionNumber", field: 'admissionNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.admissionNumber}</span></>) } },
        { headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.lastName ? 'Absent' : ''}</span></>) } },
        {
            headerName: "Date", field: 'Date', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => {
                return (<><span style={{ marginLeft: "10px" }}>'-'</span></>)
            }
        },
    ]

     //export data

     const filedata = tabValue === 'Present' ? studentPresent?.data?.map((data) => {
        return {
            "StudentName":data?.firstName + ' ' + data?.lastName,
            "Student ID": data?.admissionNumber,
            "Status": 'Present',
            "Date & Time": DateFormatter(data?.joinedAt) - '+' - DateFormatter(data?.updatedAt),
        }
    }):studentAbsent?.data?.map((data) => {
        return {
            "StudentName": data?.firstName + ' ' + data?.lastName,
            "Student ID": data?.admissionNumber,
            "Status": 'Absent',
            "Date & Time": '-',
        }
    })
    const fileHeaders = tabValue === 'Present' ?   [["StudentName", "Student ID", "Status", "Date & Time"]]: [["StudentName", "Student ID", "Status", "Date & Time"]]
    const csvColumns = tabValue === 'Present' ?  [
        { label: "StudentName", key: "StudentName" },
        { label: "Student ID", key: "Student ID" },
        { label: "Status", key: "Status", },
        { label: "Date & Time", key: "Date & Time" },
    ]:[
        { label: "StudentName", key: "StudentName" },
        { label: "Student ID", key: "Student ID" },
        { label: "Status", key: "Status", },
        { label: "Date & Time", key: "Date & Time" },
    ]

    const fileDataPDF = tabValue === 'Present' ?  studentPresent?.data?.map((data) => [data?.firstName + ' ' + data?.lastName, data?.admissionNumber, 'Present', DateFormatter(data?.joinedAt)]) : studentAbsent?.data?.map((data) => [data?.firstName + data?.lastName, data?.admissionNumber, 'Absent', '-']);


    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Preview Report</Typography>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
                <Grid item>
                    <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={tabValue === 'Present' ? 'Student Present' :'Student Absent'} title={tabValue === 'Present' ? 'Student Present' :'Student Absent'} />
                </Grid>
            </Grid>
            <Grid item my={2} direction={'column'} style={{ backgroundImage: `url(${BgReportImg})`, height: '142px', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <Grid px={2} item display={'flex'} alignItems={'center'} py={2}>
                    <Grid item display={'flex'} alignItems={'center'} gap={2}>
                        <Typography style={{ fontWeight: 600, color: '#000000', fontSize: '18px' }}>{sessionCode}</Typography>
                        <Typography fontSize={'18px'}> <span style={{ paddingRight: '10px' }}> By </span> {lecName}</Typography>
                    </Grid>
                    <Grid item display={'flex'} alignItems={'center'} gap={2}>
                        <Typography style={{ fontWeight: 600, paddingLeft: '10px', color: '#000000', fontSize: '18px' }}>Date</Typography>
                        <Typography fontSize={'18px'}>{DateFormatter(startTime)} - {DateFormatter(endTime)}</Typography>
                    </Grid>
                </Grid>
                <Grid px={2} item display={'flex'} direction={'column'}>
                    <Typography style={{ fontSize: '22px', fontWeight: 600, color: '#032541' }}> {courseName}</Typography>
                    {/* <Typography style={{ fontSize: '15px', fontWeight: 500, color: '#707070' }}>{props?.teacherName}</Typography> */}
                </Grid>
                <Grid item mt={2} px={2} display={'flex'} gap={4}>
                    <Grid item display={'flex'} alignItems={'center'} gap={1}>
                        <Typography style={{ fontWeight: 600, color: '#17ae7b', fontSize: '18px' }}>Students</Typography>
                        <Typography color={'#17ae7b'} display={'flex'} alignItems={'center'}>Present  <span style={{ paddingLeft: '20px', color: "" }}>{studentPresent?.data?.length}</span></Typography>
                    </Grid>
                    <Grid item display={'flex'} alignItems={'center'} gap={1}>
                        <Typography style={{ fontWeight: 600, color: '#dc3545', fontSize: '18px' }}>Students</Typography>
                        <Typography color={'#dc3545'} display={'flex'} alignItems={'center'}>Absent  <span style={{ paddingLeft: '20px', color: "" }}>{studentAbsent?.data?.length}</span></Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <TabContext value={tabValue}>
                    <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                        <AntTab label="Present" value="Present" />
                        <AntTab label="Absent" value="Absent" style={{ marginLeft: "20px" }} />
                    </AntTabs>
                    <TabPanel value="Present">
                        <Grid item mt={2} width={'100%'}>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={studentPresent?.total}
                                loading={studentPresent?.isLoading}
                                pagination
                                page={studentPresent?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { studentPresent((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={sessionDetails}
                                rows={studentPresent?.data}
                                getRowId={row => row?._id} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Absent">
                        <Grid item mt={2} width={'100%'}>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={studentAbsent?.total}
                                loading={studentAbsent?.isLoading}
                                pagination
                                page={studentAbsent?.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setStudentAbsent((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={AbsentStudent}
                                rows={studentAbsent?.data}
                                getRowId={row => row?._id} />
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}