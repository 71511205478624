import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import success from "../../common/images/success.svg";
import powericon from "../../common/images/powericon.svg";

// src/common/images/powericon.svg

function ValidationAlertMessage(props) {
    const setSuccessUpdate = props.validateModalClose
   

    // session Expired
    const style = {
        borderColor: "transparent",
        borderRadius: "36px",
        backgroundColor: "#fff",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        height: "300px",
        
    };
    return (
        <div>
            <Modal
                open={props.deactivateModalOpen}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "100px",
                            padding: "12px 30px 80px",
                            objectFit: "contain",
                        }}
                    >
                        <img style={{ width: "82px", height: "82px", margin: "4px 0px 0px -15px" }} src={ powericon} alt="img" />
                    </div>
                    <h4
                    style={{
                        fontSize: "18px",
                        fontWeight: " bold",
                        fontStretch: "normal",
                        fontStyle: " normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: " #032541",
                    }}
                    ></h4>
                   
                    <h4
                    style={{
                        fontSize: "18px",
                        fontWeight: " bold",
                        fontStretch: "normal",
                        fontStyle: " normal",
                        lineHeight: "normal",  
                        letterSpacing: "normal",
                        textAlign: "center",
                        color:'green',
                    }}
                    >{props.titleMessage}</h4>
                    <h4 style={{
                        marginTop:"16px",
                        fontSize: "16px",
                        textAlign:"center"
                    }}>{props.message}</h4>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                    
                      
                    <Button
                            type="submit"
                            variant="standard"
                            style={{
                                color: "#fff",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                width: "240px",
                                height: "40px",
                                margin: "20px 10px 0 30px",
                                backgroundColor: "#032541",
                            }}
                            onClick={props.onClose}
                        >
                            ok
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export { ValidationAlertMessage };
