import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { Edit } from "@mui/icons-material";
import AddMerchantAccount from "./addmerchantaccount";
import HttpComponent from "../../../School/MakeRequest";

const MerchantAccountConfig = () => {
  const navigate = useNavigate();
  // query params
  const queryParams = decodeURIComponent(window.location.search).split("?")[1];

  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize });
  const [queryValue, setQueryValue] = useState("");

  useEffect(() => {
    setQueryValue(queryParams);
  }, [queryParams]);
  const feeConfigColum = [
    { field: "accountName", headerName: "Account Name", flex: 1 },
    { field: "accountNumber", headerName: "Account Number", flex: 1 },
    {
      field: "Actions",
      headerName: "Action",
      renderCell: (params) => {
        return <Edit style={{ cursor: "pointer" }} onClick={() => navigate(`/admin/merchantaccountconfig?addconfig?${params?.row?.id}?${params?.row?.accountName}?${params?.row?.accountNumber}`)} />;
      },
    },
  ];
  const accountData = pageState?.data?.map((config, index) => ({
    id: config?._id,
    accountName: config?.accountName,
    accountNumber: config?.accountNumber,
  }));

  const getMerchantAccountConfig = useCallback(() => {
    HttpComponent({
      method:"GET",
      token:localStorage.getItem("X-Authorization"),
      url:`/api/v1/listmasteraccount`
    }).then((data) => {

      const returnData = data?.response?.data ?? []
      setPageState({...pageState, data: returnData })
    })
  },[queryValue])
  useEffect(() => {
    getMerchantAccountConfig()
  }, [queryParams, getMerchantAccountConfig])

  return (
    <div>
      <Grid container width={"100%"} mt={3}>
        <Grid item width={"100%"} style={{ margin: "0px 0px 0px 20px" }}>
          <Box style={{ margin: "0px 0px 10px 0px" }}>
            <Typography sx={{ fontWeight: "600", color: "#032541", fontSize: "25px" }} variant="h5">
              Merchant Account Configuration
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Breadcrumb name={"Merchant Account Configuration"} />
            {queryValue !== "addconfig" && (
              <Button onClick={() => navigate(`/admin/merchantaccountconfig?${"addconfig"}`)} style={{ backgroundColor: "#032541", color: "white", padding: "10px 50px 10px 50px" }}>
                ADD
              </Button>
            )}
          </Box>
        </Grid>
        {queryValue === "addconfig" ? (
          <>
            {" "}
            <Grid item width={"100%"}>
              <AddMerchantAccount />
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid item width={"100%"}>
              <CustomDataGrid customStyles={{ border: "none", height: "400px", width: "100%" }} column={feeConfigColum} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={accountData} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default MerchantAccountConfig;
