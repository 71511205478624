import React from "react";
import Sidebar from "./sideBar";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const Dash = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <main>{children}</main>
    </div>
  );
};

export default Dash;
