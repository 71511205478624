import { Grid, Typography, Breadcrumbs, Button, Box, Paper } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import CustomSearchInput from '../../School/CustomSearchInput';
import ExportMenu from '../../School/ExportMenu';
import CustomDataGrid from '../../products/stockComponents/customDataGrid';
import HttpComponent from '../../School/MakeRequest';
import DateFormatter from '../../../utils/dateFormatter';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>
]

export default function ViewAllSaccoVehicles() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')

    function getVehicles() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/listvehicles?page=${pageState?.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicles();
    }, [searchValue, pageState.page, dataGridPageSize])

    //get vehicle owners

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        regNo: config?.regNo        ,
        insuranceCompanyName: config?.insuranceCompanyName        ,
        policyNo: config?.policyNo        ,
        vehicleType: config?.vehicleType,
        policyExpiry:config?.policyExpiry

    }))

    // recent transactions columns

    const vehcileowners = [
        { field: "regNo", headerName: "Reg No", flex: 1 },
        { field: "insuranceCompanyName", headerName: "Insurance Company", flex: 1 },
        { field: 'policyNo', headerName: 'Policy No', flex: 1 },
        { field: 'vehicleType', headerName: 'Vehicle Type', flex: 1 },
        { field: 'policyExpiry', headerName: 'Policy Expiry', flex: 1 , renderCell:(params) =><Typography>{DateFormatter(params?.row?.policyExpiry)}</Typography> },
    ]
    return (
        <Grid container direction={'column'} width={'100%'}>
            <Grid item mt={2} mb={2}>
                <Grid container gap={2} display={'flex'} direction={'column'}>
                    <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                            <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Vehicles</Typography>
                        </Grid>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item display={'flex'} alignItems={'center'} >
                        <CustomSearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder={'Search'} name={'searchValue'} />
                    </Grid>
                    <Grid item>
                        <ExportMenu />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehcileowners} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}