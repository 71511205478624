import React from "react";
import { ReactSVG } from "react-svg";
import MySVG1 from "./convenience-iconRED.svg";
import MySVG2 from "./convenience-iconBLUE.svg";

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG = currentPath === "/ConvenienceFeeMgt" ? MySVG1 : MySVG2;

  return <ReactSVG src={MySVG} />;
}

export default MySVGComponent;
