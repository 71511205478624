import React from "react";
import { ReactSVG } from "react-svg";
import MySVG1 from "./reports-iconRED.svg";
import MySVG2 from "./reports-iconBLUE.svg";

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG =
    currentPath === "/AllTransactions" ||
    currentPath === "/VoidTransactions" ||
    currentPath === "/admin/allzpmtransactions" ||
    currentPath === "/admin/zpmbiotransactions" ||
    currentPath === "/zpmactivationfeereport" ||
    currentPath === "/admin/zpmcardtransactions" ||
    currentPath === "/OverallPerformance"
      ? MySVG1
      : MySVG2;

  return <ReactSVG src={MySVG} />;
}

export default MySVGComponent;
