import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Typography, Grid, Breadcrumbs,Button, RadioGroup, Radio, Box } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import CustomSelectField from "../../School/CustomSelectField";
// import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import React from "react";
import './datepicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { handleTenantExitNotice } from "../../../features/tenantsDataSlice";


// custom styles


//breadcrumps notice
const breadcrumbs = [
  <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
    Dashboard
  </Typography>,
  <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
    Tenants
  </Typography>,
  <Typography style={{ color: "#dc3545", fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.error">
    Vacate Notice
  </Typography>,
];

const GiveNotice = (props) => {
  const { tenantEvictionData } = useSelector((store) => store.tenantData);

  console.log(tenantEvictionData)
  
  const dispatch = useDispatch()

  //extract some values from the  tenantEvictionData

  console.log(tenantEvictionData, "tenants with pending exit request");

  const { X_Authorization } = useSelector((store) => store.user);
  const [evictionReason, setEvictionReason] = useState([]);
  const [evReason, setEvReason] = useState("");
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [existRequest, setExitRequest] = useState([])
  const [selectedHouse ,setSelectedHouse] =useState('')
  const evictR = evictionReason.map((reason) => ({ value: reason, label: reason }));

  //get current date
  const currentDate = new Date();
  const [myMonth, setMyMonth] = useState(currentDate);
  const [myYear, setMyYear] = useState(currentDate);
  const [myDay, setMyDay] = useState(currentDate);

  const minDate = new Date(myYear.getFullYear(), myMonth.getMonth(), 1);
  const maxDate = new Date(myYear.getFullYear(), myMonth.getMonth() + 1, 0);

  useEffect(() => {
    setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), 1));
  }, [myMonth, myYear, setMyDay]);

  const renderDayContents = (day, date) => {
    if (date < minDate || date > maxDate) {
      return <span></span>;
    }
    return <span>{date.getDate()}</span>;
  };

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}


  //fetch  eveiction reasons
  //1. GET https://dev.zed.business/api/get_tenant_eviction_reasons (reason codes)

  function fetchEvictionReasons() {
    try {
      try {
        HttpComponent({
          method: "GET",
          url: `/api/get_tenant_eviction_reasons`,
          token: X_Authorization,
        })
          .then((data) => {
            console.log(data, "eviction reasons");
            if (data.status === 200) {
              setEvictionReason(data.response.data);
            } else {
              console.log(`Error getting Eviction reasons`);
            }
          })
          .catch((e) => {
            console.log(`Error getting Eviction reasons`, e.message);
          });
      } catch (e) {
        console.log(`Error getting Eviction reasons`, e.message);
      }
    } catch (e) {
      console.log(`Error getting Eviction reasons`, e.message);
    }
  }
  //fetch eviction reasons

  useEffect(() => {
    fetchEvictionReasons();
  }, []);

  // https://dev.zed.business/api/generate_tenant_eviction_notice
  const [unitType, setUnitType] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  useEffect(() => {
    tenantEvictionData[0]?.billableItems?.map((billItem) => setUnitType(billItem.unitTypeID));
    tenantEvictionData[0]?.billableItems?.map((billItem) => setHouseNumber(billItem.itemNumber));
    // const unitTypeName = tenantEvictionData?.billableItems?.map((billItem)=>({value:billItem.unitTypeID}))
  }, [tenantEvictionData]);

  const PostData = {
    unitType: unitType,
    reason: evReason,
    unitTypeName: tenantEvictionData[0]?.businessName,
    tenantId: tenantEvictionData[0]?._id,
    houseNumber: houseNumber,
    evictionDate: formatDate(myDay ,myMonth ,myYear),
    source: "LANDLOARD",
  };


  function fetchExitRequest() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/get_business_eviction_notices?noticeStatus=PENDING&search=&limit=1000&page=1`,
        token: X_Authorization
      })
        .then((data) => {
          console.log(data, 'exit request');
          setExitRequest(data.response.data)
          //dispatch(handleTenantVacationData(data.response.data))

        })
        .catch((e) => { console.log(`Error Getting Exit Request Data`, e.message) })
    } catch (error) {
      console.log(error.message);
    }

  }

  function handleNoticepdf() {
    dispatch(handleTenantExitNotice(selectedHouseInfo))
    props.setFlagNoticepdf();
  }

  //fetch all tenantHouses

  const [tenantHouses , setTenantHouses] = useState([])
  const [selectedHouseInfo ,setSelectedHouseInfo] = useState(null)

  function getHousesForSelectedTenant(){
    try {
      HttpComponent({
        method:'POST',
        url:`/api/get_houses_by_customer_id` ,
        token:X_Authorization ,
        body:{customerId:props?.tenaId}
      }).then((data)=>{
        if(data.status === 200){
          setTenantHouses(data.response.data)
        }
      })
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    getHousesForSelectedTenant()
  },[])

  console.log(tenantHouses , 'all houses')

  return (
      <Grid container direction={'column'} mt={-3}>
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        {/*Month Name*/}
        <Grid mt={3} item display={'flex'} direction={'row'} alignItems={'center'}  alignContent={'center'}>
          <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={props?.goBack}/>
          <Typography variant="h5" style={{color:"#032541" , fontWeight:700}}>Vacate Notice</Typography>
        </Grid>
        <Grid item mt={3}>
            <Breadcrumbs style={{ fontFamily: "Poppins", fontSize: "14px" }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
        </Grid>
        <Grid item mt={3} py={2} style={{backgroundColor:"#fafafa" ,width:"400px"}}>
          <Typography color={'#707070'} variant="body1">Tenant Name</Typography>
          <Typography variant="body1"></Typography>
        </Grid>
        <Grid item mt={3}>
          <Typography>Select  a House</Typography>
        </Grid>
      <Grid container mt={3}>
        {tenantHouses?.map((tenantH) => {
          return (
            <Grid item xl={2} lg={2} sm={4} md={2} display={'flex'}>
              <Box sx={{ width: "150px", padding: "10px 0px", cursor: "pointer", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", border: "2px solid #b2b4bb" }}>
                <input onClick={()=>setSelectedHouseInfo(tenantH)} value={tenantH?._id} checked={selectedHouse === tenantH?._id} onChange={(e) => setSelectedHouse(e.target.value)} style={{ cursor: "pointer" }} type="radio" />
                <div style={{ paddingLeft: "20px" }}>
                  <Typography>{tenantH?.houseNo}</Typography>
                  <Typography variant="body2">{tenantH?.houseType}</Typography>
                </div>
              </Box>
            </Grid>
          )
        })}
      </Grid>
        <Grid item mt={3} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'} alignItems={'flex-end'}>
            <Button onClick={handleNoticepdf} style={!selectedHouse ? {background:"#032541" ,color:"#fff" ,textTransform:'inherit' , cursor:'pointer', width:"150px" , opacity:.2}:{background:"#032541" ,color:"#fff",textTransform:'inherit' , cursor:'pointer', width:"150px" }}>Next</Button>
        </Grid>
    </Grid>
  );
};
export default GiveNotice;
