import React, { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "./styles";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../../utils/dateFormatter";
import { currencyconverter } from "../../../common/currencyconverter";
// import 
const InvoicingInvoices = () => {
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [customerId, setCustomerId] = useState("");
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });



  const getIncomingInvoice = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_user_invoices?limit=${dataGridPageSize}&page=${pageState.page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
        }
 
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getIncomingInvoice();
  }, [pageState.pageSize, pageState.page, dataGridPageSize]);

  // navigate;
  // /invoice/INV_426
  useEffect(() => {
    if (customerId) navigate(`/createInvoice/${customerId}`);
  }, [customerId]);

  return (
    <div>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data?.map((invoice) => ({
          id: invoice?._id,
          invoiceNumber: invoice?.invoiceNumber,
          sentTo: invoice?.sentTo,
          createdAt: DateFormatter(invoice?.createdAt),
          invoiceAmount: invoice?.invoiceAmount,
          discountAmount: currencyconverter(invoice?.invoiceDiscountAmount),
          invoiceType: invoice?.invoiceType,
          customerId: invoice?.customerId,
          invoiceStatus: invoice?.invoiceStatus,
          paidAmount: invoice?.invoiceAmount - invoice?.invoiceBalance,
          invoiceBalance: invoice?.invoiceBalance,
        }))}
        columns={[
          {
            field: "invoiceNumber",
            headerName: "Invoice No",
            flex: 1,
            renderCell: (params) => {
              return (
                <span style={{ color: "red", cursor: "pointer" }} onClick={() =>  navigate(`/invoice/${params.row.invoiceNumber}`)}>
                  {params.row.invoiceNumber}
                </span>
              );
            },
          },
          { field: "sentTo", headerName: "To", flex: 1 },
          { field: "createdAt", headerName: "Date & Time", flex: 1 },
          { field: "invoiceAmount", headerName: "Invoice Amount", flex: 1 },
          { field: "discountAmount", headerName: "Discount Amount", flex: 1 },
          { field: "paidAmount", headerName: "Paid Amount", flex: 1 },
          { field: "invoiceBalance", headerName: "Balance", flex: 1 },
          { field: "invoiceType", headerName: "Invoice Type", flex: 1 },
          { field: "invoiceStatus", headerName: "Status", flex: 1, renderCell:(params) => {
            return <span style={params?.row?.invoiceStatus === "Paid" ? {color:"green"} : params?.row?.invoiceStatus  === "Unpaid" ? {color:"red"} : {color:"orange"}} >{params?.row.invoiceStatus}</span>
        } },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState?.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />
    </div>
  );
};

export default InvoicingInvoices;
