import React from 'react';
const base_url = process.env.REACT_APP_BASE_URL

const HttpComponent = ({ method, url, body, token }) => {
    let finalUrl = `${base_url}${url}`
    // console.log("d", finalUrl)
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    if (token) {
        headers['X-Authorization'] = token;
    }

    return fetch(finalUrl, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined
    })
        .then((res) => {
            const status = res.status;
            const json = res.json();
            return Promise.all([status, json]);
        })
        .then(([status, data]) => {
            return { status, response: data };
        })
        .catch((error) => {
            console.error('Error making request:', error?.message);
            // throw error;
        });
};

export default HttpComponent;
