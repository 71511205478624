import { Typography, Grid, ToggleButton, ToggleButtonGroup,FormControlLabel ,FormGroup , Checkbox , Breadcrumbs, TextField, Button, InputLabel, Select, MenuItem } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { useDispatch, useSelector } from "react-redux";
import lodash from 'lodash'
import { handleSaveSelectedHousesFromTenantProfile } from "../../../../features/tenantsDataSlice";
import Customerdetails from "../../customerComponents/customerdetails";

const breadcrumbs = [
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Invoices
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Create Invoice
    </Typography>
]

export default function TenantProfileInvoice(props) {
    const { X_Authorization } = useSelector((store) => store.user);
    const [tenantHouses, setTenantHouses] = useState([])
    const [loading, setIsloading] = useState(false)
    const [customerDetails, setCustomerDetails] = useState({})
    const[checked ,setChecked] = useState(false)
    const [toggleInputs,setToggleInputs] = useState(true)
    const [selectedHouses ,setSelectedHouses] = useState([])
    const [installment ,setInstallment] = useState(0)
    const [period ,setPeriod] = useState(0)
    const [invoiceType, setInvoiceType] = useState("");
    const [repeatType, setRepeatType] = useState("Select Schedule")

    const devUrl = process.env.REACT_APP_BASE_URL || 'https://dev.zed.business'

    

    console.log(customerDetails , 'customer')

    const { id } = useParams()

    const {billableItems} = customerDetails

    //const billableItems = lodash?.get(customerDetails[0], 'billableItems')

   // const amount = lodash?.get(billableItems[0], 'amount')

   // console.log(amount , 'amount')

    const dispatch = useDispatch()

    //toggle input

      const handleToggleInput = ()=>{
        setToggleInputs(!toggleInputs)
    }

    const handleChange = (event) =>{
        setChecked(event.target.checked)
    }
    //handle the data selected
    const handleSelectHouse = (house) => {
        const updatedHouse = {
            ...house ,
            installment:installment ,
            period:period ,
            invoiceType:invoiceType,
            repeatType:repeatType,
        }
        if (selectedHouses.some((selectedHouse) => selectedHouse?.itemNumber === house.itemNumber)) {
          setSelectedHouses(selectedHouses?.filter((selectedHouse) => selectedHouse?.itemNumber !== house?.itemNumber));
        } else {
          setSelectedHouses([...selectedHouses, updatedHouse]);
        }
      };

    //dispatch that selected house data to store

    const handleDispatchSelectedHouses = ()=>{
        dispatch(handleSaveSelectedHousesFromTenantProfile(selectedHouses))
        props.goToPreview()
    }

    console.log(selectedHouses , 'selected houses')

    //dev options for generating recurring invoices

    const devOptions = [
        {
            label: 'Hourly',
            value: 'hourly'
        },
        {
            label: 'minutely',
            value: 'Minutely'
        },
    ]

    //api/get_customer_by_customer_id?customerId

    //fetch customer by id
    function tenantProfile() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_customer_by_customer_id?customerId=${id}`,
                body: null,
                token: X_Authorization
            }).then((data) => { 
                setIsloading(true)
                if (data.status === 200) {
                    setCustomerDetails(data.response.data)
                    setIsloading(false)
                }
            })

        } catch (error) {

        }

    }

    //fetch houses for that tenant

    function fetchHousesByTheTenant() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/get_houses_by_customer_id`,
                token: X_Authorization,
                body: { customerId: id }
            }).then((data) => {
                if (data.status === 200) {
                    setTenantHouses(data.response.data)
                }
            })
        } catch (error) {

        }
    }

    // fetch all houses of the tenant
    useEffect(() => {
        fetchHousesByTheTenant()
    }, [])

    useEffect(() => {
        tenantProfile()
    }, [])

    //find show total of selected items

    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={props?.prev} />
                <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Invoice</Typography>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={3}>
                        <TextField style={{ width: "400px" }} value={customerDetails?.firstName + ' ' + customerDetails?.lastName} label="Tenant" />
            </Grid> 
            <Grid item style={{ width: "500px" }}>
                <Grid container mt={3}>
                    <Typography>Select House (s) <span style={{ color: "red" }}>*</span></Typography>
                </Grid>
                <Grid container mt={3}>
                    {billableItems?.map((tenantH) => {
                        return (
                            <Grid style={{ display: "flex", marginRight: "10px" }} mb={4} gap={3}>
                                <ToggleButtonGroup key={tenantH.itemNumber} value={tenantH}  onClick={()=>{handleSelectHouse(tenantH)}}  sx={selectedHouses?.some((selectedHouse) => selectedHouse?.itemNumber === tenantH?.itemNumber) ?{background: "#fff",  color:"#17ae7b", width: "100px", height: "40px" }:{background: "rgba(3, 37, 65, 0.4)",  color:"#0000", width: "100px", height: "40px" }}>
                                    <ToggleButton value={tenantH} sx={{background: "#fff", color:"#17ae7b" , width: "100px", height: "40px" }}>
                                        {tenantH?.itemNumber}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item mt={2} mb={2}>
                <InputLabel id="invoice-type-label">Invoice Type<span style={{ color: "#dc3545" }}>*</span></InputLabel>
                <Select
                    labelId="invoice-type-label"
                    id="invoice-type-select"
                    value={invoiceType}
                    onChange={e => { if (e.target.value !== "Invoice Type") { setInvoiceType(e.target.value); } }} style={{ width: "415px" }}>
                    <MenuItem value="Invoice Type" disabled>Invoice Type</MenuItem>
                    <MenuItem value="one-off">One-Off</MenuItem>
                    <MenuItem value="recurring">Recurring</MenuItem>
                </Select>
                {invoiceType === 'recurring' && (
                    <>
                        <InputLabel style={{ marginBottom: "5px", marginTop: '5px' }} id="invoice-type-label">Repeat<span style={{ color: "#dc3545" }}>*</span></InputLabel>
                        <Select labelId="invoice-type-label" id="invoice-type-select" value={repeatType} onChange={e => { if (e.target.value !== "Select Schedule") { setRepeatType(e.target.value); } }} style={{ width: "415px" }}>
                            <MenuItem value="Select Schedule" disabled>Select Schedule</MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="quarterly">Quarterly</MenuItem>
                            <MenuItem value="yearly">Yearly</MenuItem>

                            {devUrl &&
                                devOptions.map((optio) => {
                                    return (
                                        <MenuItem value={optio.value}>
                                            {optio.label}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <FormGroup style={{ width: "400px" }}>
                            <FormControlLabel required control={<Checkbox onChange={handleChange} checked={checked} />} label="Invoice in installments" />
                            {checked && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography variant="h6" fontWeight={700}>Total:  {} </Typography>
                                        <span disabled onClick={handleToggleInput} style={{ cursor: "pointer" , color:"#17ae7b" }}>{toggleInputs ? 'set Up with periods':'set up with amount' }</span>
                                    </div> */}
                                    <div>
                                        {selectedHouses?.map((installmentAmount)=>{
                                                return(
                                                    <Grid  spacing={2} item mt={2} mb={2} display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                                                         <TextField disabled readOnly value={installmentAmount?.amount} defaultValue={installmentAmount?.amount}  type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"140px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", marginRight:"4px", fontSize: "12px", width: '140px' }} id="outlined-basic" label="Total" variant="outlined" />
                                                        <TextField disabled readOnly value={installmentAmount?.itemNumber} defaultValue={installmentAmount?.itemNumber}  type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"130px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", marginRight:"4px", fontSize: "12px", width: '130px' }} id="outlined-basic" label="House No" variant="outlined" />
                                                        <TextField disabled readOnly value={installmentAmount?.instalmentAmount} defaultValue={installmentAmount?.instalmentAmount}  type='text' InputProps={{ style: { fontFamily: 'Poppins' ,width:"130px" } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '130px' }} id="outlined-basic" label={installmentAmount.unitPurpose === 'Rent' ? 'Rent':'Installment Amount' } variant="outlined" />
                                                    </Grid>
                                                )
                                        })}
                                      
                                    </div>
                                    {/* <Grid item mt={3}>
                                        {toggleInputs ?
                                            <Grid item>
                                                <TextField disabled readOnly value={installment} onChange={(e)=>setInstallment(e.target.value)} style={{width:"400px"}} label="Installment Amount" />
                                                <Typography mt={2} variant="body2">period :</Typography>
                                            </Grid> :
                                            <Grid item>
                                                <TextField value={period} onChange={(e)=>setPeriod(e.target.value)} style={{width:"400px"}} label="Period" />
                                                <Typography mt={2} variant="body2">Installment Amount : </Typography>
                                            </Grid>
                                        }
                                    </Grid> */}
                                </div>
                            )}
                        </FormGroup>
                    </>
                )}
            </Grid>
            <Grid item mt={3} display={'flex'} style={{ width: "400px" }} direction={'row'} justifyContent={'flex-end'} alignItems={'flex-end'} alignContent={'flex-end'}>
                <Button style={{ textTransform: "inherit", border: "1px solid #dc3545", color: "#dc3545", marginRight: "10px", width: "130px", height: "40px" }}>Cancel</Button>
                <Button onClick={ !invoiceType || !selectedHouses.length>0 ? null :()=>handleDispatchSelectedHouses()} style={{ textTransform: "inherit", color: "#fff", background: "#032541", width: "130px", height: "40px",opacity:!invoiceType || !selectedHouses.length>0 ? '0.4':'1' }}>Preview Invoice</Button>
            </Grid>
        </Grid>
    )
}