

/**
 * Formats a given number into a currency format based on the local currency stored in localStorage.
 * @example
 * formatCurrency(1000, 'USD') // $1,000.00
 * @param {number} value - The number to be formatted into currency.
 * @returns {string} The formatted currency string.
 * @description
 * - Uses the local currency stored in localStorage, or defaults to 'KES' if not available.
 * - Uses the 'en-Us' locale for formatting.
 * - Only supports currencies with a symbol and decimal separator.
 */

export const currencyconverter = (value, currency) => {

    let countryCurrency = localStorage.getItem('localCurrency');
    if((!countryCurrency) || (countryCurrency === undefined) || (countryCurrency === null) || (countryCurrency === "undefined")){
        countryCurrency = currency ? currency : 'KES'
    }
    return new Intl.NumberFormat('en-Us', {
         style:'currency',
         currency:countryCurrency
     }).format(value)
    
}

export const GetDefaultCurrency = () => {
    let currency = `KES`
    let countryCurrency  = localStorage.getItem("localCurrency")

    if(!countryCurrency){
        currency = `KES`
    }else {
        currency = countryCurrency
    }

    return currency
}

export const ValidateEmailAdress = (email) => {
    if (!email){
        return false
    }

    let emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
   return emailRegex.test(email)

}