import { ListItemButton, ListItemIcon, ListItem, ListItemText, List, Collapse } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { styled } from "@mui/material/styles";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ExpandLess from '@mui/icons-material/ExpandLess';
import MuiDrawer from "@mui/material/Drawer";
import ExpandMore from '@mui/icons-material/ExpandMore';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AnimationIcon from '@mui/icons-material/Animation';
import { useState } from 'react';
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from 'react-router-dom'
import ZedLogo from "../../images/zedLogo.png";
import DashLogo from "../../common/images/dashlogo.png";
import ZedSmall from "../../common/images/ZedLogo2.png";
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DescriptionIcon from '@mui/icons-material/Description';
import jwt from 'jwt-decode'
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        fontFamily: "Poppins",
    },
    drawer: {
        width: 291,
        color: "#fff",
        flexShrink: 0,
    },
    drawerPaper: {
        width: 291,
        color: "#fff",
        backgroundColor: "rgb(3,37,65)",
        
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        color: "#fff",
        paddingLeft: theme.spacing(4),
    },
}));

const drawerWidth = 245;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const ZedPayments = {
    width: "147px",
    height: "28px",
    margin: "1px 20px 1px 20px",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#707070",
};
export default function TransportPartnerSidebar({ children }) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [openBusiness, setOpenBusienss] = useState(false)
    const handleOpenBusiness = () => { setOpenBusienss(!openBusiness); }
    const [openVehicles, setOpenVehicles] = useState(false)
    const handleOpenVehicles = () => { setOpenVehicles(!openVehicles); }
    const [openReports , setOpenReports] = useState(false)
    const handleOpenReports = () => { setOpenReports(!openReports); }

    const { customerClassification } = jwt(localStorage.getItem("X-Authorization"));



    const location = useLocation()
    const isActiveLink = (path) => location.pathname === path;
    const group = localStorage.getItem('group')
    let zedBlue = "#032541";

    const sidebarBg =  group  === 'TransportPartner' ? '#fff': "rgb(3,37,65)"
    const partnerLogo = group === 'TransportPartner' ? ZedLogo:DashLogo
    const activeColor = group === 'TransportPartner' ? '#dc3545': "rgb(4, 242, 252)"
    const normalColor = group === 'TransportPartner' ? '#032541': "#fff"

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.root}>
            <Drawer position={"fixed"} variant="permanent" open={open} PaperProps={{sx: {backgroundColor:sidebarBg , width: "291px", color: normalColor}}}>
                <DrawerHeader style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                    {open ? (
                        <Link to={"/partnerstransport"} style={{ marginLeft: 10, marginTop: 10 }}>
                            <img src={partnerLogo} alt="Zed Logo" width="140px" height="70px" />
                        </Link>
                    ) : (
                        <Link
                            to={"/partnerstransport"}
                            style={{ alignSelf: "center", marginLeft: 5 }}
                        >
                            <img src={DashLogo} alt="Zed Logo" />
                        </Link>
                    )}
                </DrawerHeader>
                <List sx={{ marginTop: 2 }}>
                    <Link to="/partnerstransport" style={{ textDecoration: 'none', color: isActiveLink("/partnerstransport") || isActiveLink("/dashboard") ? activeColor : normalColor }}>
                        <ListItemButton  style={{ textDecoration: 'none', color: isActiveLink("/partnerstransport") || isActiveLink("/dashboard")  ? activeColor : normalColor }}>
                            <ListItemIcon sx={{color: isActiveLink("/partnerstransport") || isActiveLink("/dashboard")  ? activeColor : normalColor }}>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </Link>
                    <ListItemButton onClick={handleOpenBusiness}>
                        <ListItemIcon sx={{color:normalColor}}>
                            <CorporateFareIcon />
                        </ListItemIcon>
                        <ListItemText primary="Business" />
                        {openBusiness ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openBusiness} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <div style={{ marginLeft: '24%' }}>
                                <Link style={{ textDecoration: 'none', color: isActiveLink("/shops") ? activeColor : normalColor }} to={"/shops"}>
                                    <ListItem button>
                                        <ListItemText primary="My Business" />
                                    </ListItem>
                                </Link>
                            </div>
                        </List>
                    </Collapse>
                    {customerClassification === 'driver' || customerClassification === 'conductor' || customerClassification === 'saccoOperator' || group === 'TransportPartner' ? null :
                    <ListItemButton onClick={handleOpenVehicles}>
                        <ListItemIcon sx={{color:normalColor}}>
                            <DirectionsCarIcon />
                        </ListItemIcon>
                        <ListItemText primary="Vehicles" />
                        {openVehicles ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>}
                    <Collapse in={openVehicles} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <div style={{ marginLeft: '24%' }}>
                                <Link style={{ textDecoration: 'none', color: isActiveLink("/sacco/vehicleowners") ? activeColor : normalColor }} to={"/sacco/vehicleowners"}>
                                    <ListItem button>
                                        <ListItemText primary="Vehicle Owners" />
                                    </ListItem>
                                </Link>
                                <Link style={{ textDecoration: 'none', color: isActiveLink("/sacco/vehicles") ? activeColor : normalColor }} to={"/sacco/vehicles"}>
                                    <ListItem button>
                                        <ListItemText primary="Vehicles" />
                                    </ListItem>
                                </Link>
                                <Link style={{ textDecoration: 'none', color: isActiveLink("/sacco/vehiclesoperators") ? activeColor : normalColor }} to={"/sacco/vehiclesoperators"}>
                                    <ListItem button>
                                        <ListItemText primary="Operators" />
                                    </ListItem>
                                </Link>
                            </div>
                        </List>
                    </Collapse>
                    {customerClassification === 'driver' || customerClassification === 'conductor' || customerClassification === 'saccoOperator' || group === 'TransportPartner' ? null :
                    <Link to="/transport/paymentsetup" style={{ textDecoration: 'none', color: isActiveLink("/transport/paymentsetup") ? activeColor : normalColor }}>
                        <ListItemButton>
                            <ListItemIcon sx={{color:normalColor}}>
                                <PaymentsIcon />
                            </ListItemIcon>
                            <ListItemText primary=" Payment Setup" />
                        </ListItemButton>
                    </Link>}
                    {group === 'TransportPartner' ? <>
                        <Link to="/transport/insuranceVehicles" style={{ textDecoration: 'none', color: isActiveLink("/transport/insuranceVehicles") ? activeColor : normalColor }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <DirectionsCarIcon />
                                </ListItemIcon>
                                <ListItemText primary="Vehicles" />
                            </ListItemButton>
                        </Link>
                        <Link to="/transport/insuranceOpeators" style={{ textDecoration: 'none', color: isActiveLink("/transport/insuranceOpeators") ? activeColor : normalColor }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <PersonOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Operators" />
                            </ListItemButton>
                        </Link>
                        <Link to="/transport/insuranceTransactions" style={{ textDecoration: 'none', color: isActiveLink("/transport/insuranceTransactions") ? activeColor : normalColor }}>
                        <ListItemButton>
                            <ListItemIcon sx={{color:normalColor}}>
                                <ReceiptLongIcon />
                            </ListItemIcon>
                            <ListItemText primary="Transactions" />
                        </ListItemButton>
                    </Link>
                    </> : null}
                    <Link to="/sacco/allsaccotransactions" style={{ textDecoration: 'none', color: isActiveLink("/sacco/allsaccotransactions") ? activeColor : normalColor }}>
                        <ListItemButton>
                            <ListItemIcon sx={{color:normalColor}}>
                                <ReceiptLongIcon />
                            </ListItemIcon>
                            <ListItemText primary="Transactions" />
                        </ListItemButton>
                    </Link>
                    {customerClassification === 'saccoOperator' ?
                        <Link to="/sacco/operatorcollect" style={{ textDecoration: 'none', color: isActiveLink("/sacco/operatorcollect") ? activeColor : normalColor }}>
                            <ListItemButton>
                                <ListItemIcon sx={{ color: normalColor }}>
                                    <AnimationIcon />
                                </ListItemIcon>
                                <ListItemText primary="Collect" />
                            </ListItemButton>
                        </Link> : null}
                  
                    <ListItemButton onClick={handleOpenReports} style={{ textDecoration: 'none', color: isActiveLink("/sacco/transactionreports") ? activeColor : normalColor }}>
                        <ListItemIcon>
                            <DescriptionIcon sx={{color:normalColor}} />
                        </ListItemIcon>
                        <ListItemText primary="Reports" />
                        {openReports ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openReports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <div style={{ marginLeft: '24%' }}>
                                <Link style={{ textDecoration: 'none', color: isActiveLink("/sacco/transactionreports") ? activeColor : normalColor }} to={"/sacco/transactionreports"}>
                                    <ListItem button>
                                        <ListItemText primary="Transaction Report" />
                                    </ListItem>
                                </Link>
                            </div>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    )
}