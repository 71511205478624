/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutletsTable from "../components/Outlets/OutletsTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { setSearch } from "../../../features/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { handleData2 } from "../../../features/partnerSelectSlice";
import { handleData } from "../../../features/selectedSlice";
import axios from "axios";
import { handleAdded } from "../../../features/AlertSlice";
import OutletsDropdown2 from "../components/Outlets/OutletsDropdown2";

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

function Outlets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const addedAlertState = useSelector((state) => state.alertState.added);
  const [showAddedAlert, setShowAddedAlert] = useState(false);

  const handleShowAlert = () => {
    if (addedAlertState === true) {
      setShowAddedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAddedAlert(false);
        dispatch(handleAdded(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [addedAlertState]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy3);
  const [searchBy, setSearchBy] = useState(reduxSearchBy);

  useEffect(() => {
    setSearchBy(reduxSearchBy);
  }, [reduxSearchBy]);

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.outlets);

  const handleExport = () => {
    exportArrayToCSV(exportData, "Outlets.csv");
  };

  const fetchBusinessData = () => {
    try {
      axios
        .get(baseUrl + "/api/fetchBusinessNoAndName", {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        })
        .then((response) => {
          let data = JSON.stringify(response.data.data);
          let data2 = JSON.parse(data);
          dispatch(handleData(data2));
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessData();
  }, []);

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Outlets
          </Typography>
          {showAddedAlert && (
            <Alert variant="filled" severity="success">
              Outlet Added successfully
            </Alert>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Outlets" />
        <Button
          sx={{
            borderRadius: 2,
            backgroundColor: "#032541",
            color: "white",
            textTransform: "none",
            "&:hover": { color: "white", backgroundColor: "#032541" },
          }}
          onClick={() => {
            navigate("/CreateOutlet");
          }}
        >
          Create Outlet
        </Button>
      </Stack>
      <Box width={"100%"}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <Stack direction="row" spacing={2}>
            <OutletsDropdown2 />
            <TextField
              id="input-with-icon-textfield"
              // label="Search by institution"
              label={`Search By ${searchBy}`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
              className={classes.focusedTextField}
            />
          </Stack>

          <Button
            variant="outlined"
            sx={{
              bgcolor: "#f5f6f7",
              borderColor: "#f5f6f7",
              color: "#032541",
              ":hover": {
                borderColor: "#032541",
              },
              textTransform: "none",
            }}
            onClick={handleExport}
          >
            Export
            <MoreVertIcon
              sx={{
                color: "#032541",
              }}
            />
          </Button>
        </Stack>

        <Stack>
          <OutletsTable />
        </Stack>
      </Box>
    </Stack>
  );
}

export default Outlets;
