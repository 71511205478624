import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  date: null,
};

const transactionDateSlice = createSlice({
  name: 'transactionDate',
  initialState,
  reducers: {
    handleTransactionDate: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const { handleTransactionDate } = transactionDateSlice.actions;

export default transactionDateSlice.reducer;
