import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import EventIcon from '@mui/icons-material/Event'; // Import the EventIcon
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function Appointment(props) {
    const classes = useStyles();
    const [openAppointments, setOpenAppointments] = useState(false);

    const customerClassification = localStorage.getItem('customerClassification');

    const handleAppointmentsClick = () => { setOpenAppointments(!openAppointments); };

    const location = useLocation();
    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc";

    return (
        <div>
            {customerClassification === "Staff" && (
                <>
                    <ListItem button onClick={handleAppointmentsClick} style={{ color: openAppointments ? '#04f2fc' : '#fff' }}>
                        <EventIcon style={{ marginRight: '8px' }} />
                        <ListItemText primary="Bookings" style={{ fontFamily: 'Poppins', color: openAppointments ? '#04f2fc' : '#fff' }} />
                        {openAppointments ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openAppointments} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
							<Link style={{color: isActiveLink("/appointments") ? activeColor : '#fff', textDecoration: 'none'}} to="/appointments">
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Appointments" />
                                </ListItem>
                            </Link>
                            <Link style={{color: isActiveLink("/timeoff") ? activeColor : '#fff', textDecoration: 'none'}} to="/timeoff">
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Time Off" />
                                </ListItem>
                            </Link>
							{/* <Link style={{color: isActiveLink("/inprogress/appointment") ? activeColor : '#fff', textDecoration: 'none'}} to="/inprogress/appointment">
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="In Progress" />
                                </ListItem>
                            </Link>
							<Link style={{color: isActiveLink("/completed/appointment") ? activeColor : '#fff', textDecoration: 'none'}} to="/completed/appointment">
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Completed" />
                                </ListItem>
                            </Link>
							<Link style={{color: isActiveLink("/cancelled/appointment") ? activeColor : '#fff', textDecoration: 'none'}} to="/cancelled/appointment">
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Cancelled" />
                                </ListItem>
                            </Link>
                            <Link style={{color: isActiveLink("/expired/appointment") ? activeColor : '#fff', textDecoration: 'none'}} to="/expired/appointment">
                                <ListItem className={classes.nested}>
                                    <ListItemText primary="Expired" />
                                </ListItem>
                            </Link> */}
                        </List>
                    </Collapse>
                </>
            )}
        </div>
    );
}