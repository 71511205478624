import { Grid, Typography, Breadcrumbs, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import HttpComponent from '../MakeRequest';
import { useEffect, useState } from 'react';
import { NoRowsOverlay } from '../../No Rows/noRowsOverlay';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DataGrid } from '@mui/x-data-grid';
import DateFormatter from '../../../utils/dateFormatter';
import BgReportImg from '../../../images/bgreport.svg'
import { useDispatch } from 'react-redux'
import { handleSessionId } from '../../../features/staffSlice';

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Session Report</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Preview Report</Typography>
]

export default function PreviewSessionReport(props) {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const dispatch = useDispatch()


    const handlenext = (params) => {
        dispatch(handleSessionId(params?.row?._id))
        navigate(`/school/sessionreport?SessionDetails`)
    }


    function generateReport() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/getLecturerSession`,
                body: {
                    lecturerId: props?.teacher,
                    unitId: props?.unit,
                    academicYear: props?.academicYear
                },
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                console.log(data, 'data >>>')
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }

    }

    useEffect(() => {
        generateReport()
    }, [props?.teacher, props?.unit, props?.academicYear])



    const sessionDetails = [
        { headerName: "StaffName", field: 'sessionCode', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Session ID</strong>) }, renderCell: (params) => { return (<span style={{fontSize:"14px" ,}}>{params?.row?.sessionCode}</span>) } },
        { headerName: "StaffId", field: 'admissionNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Students Present</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" , fontSize:"14px" , }}>{params?.row?.presentCount}</span></>) } },
        { headerName: "phoneNo", field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student    Absent</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" , fontSize:"14px" }}>{params?.row?.absentCount}</span></>) } },
        {
            headerName: "email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => {
                return (<><span style={{ marginLeft: "10px" , fontSize:"11px" , }}>{DateFormatter(params?.row?.startTime)} - {DateFormatter(params?.row?.endTime)
                }</span></>)
            }
        },
        {
            headerName: "", headerAlign: 'left', align: 'right', flex: 1, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <ArrowForwardIosIcon style={{ cursor: 'pointer' }} onClick={() => handlenext(params)} />
                    </Grid>
                )
            }
        },
    ]


    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Preview Report</Typography>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
                <Button onClick={() => navigate(-1)} style={{ backgroundColor: '#032541', marginLeft: '10px', color: '#fff', textTransform: 'inherit', width: '142px' }}>Generate Another</Button>
            </Grid>
            <Grid item my={2} direction={'column'} style={{ backgroundImage: `url(${BgReportImg})`, height: '142px', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <Grid item display={'flex'} direction={'column'}>
                    <Typography style={{ fontSize: '22px', fontWeight: 600, marginTop: "15px", color: '#032541' }}> {props?.unitName}</Typography>
                    <Typography style={{ fontSize: '15px', fontWeight: 500, color: '#707070' }}>{props?.teacherName}</Typography>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState?.total}
                    loading={pageState?.isLoading}
                    pagination
                    page={pageState?.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={sessionDetails}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}