import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Active from "./Active";
import Inactive from "./Inactive";
import { handleTabChange } from "../../../../features/currentTabSlice";
import { useDispatch, useSelector } from "react-redux";
import New from "./New";
import Edited from "./Edited";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const dispatch = useDispatch();

   let reduxActiveTab = useSelector(state => state.currentTab.tab)

  let tabDecider 

  if(reduxActiveTab === "Active") {
    tabDecider = 0
  } 
  if(reduxActiveTab === "New") {
    tabDecider = 1
  }
  if(reduxActiveTab === "Edited") {
    tabDecider = 2
  }
  if(reduxActiveTab === "Inactive") {
    tabDecider = 3
  }

  const [value, setValue] = React.useState(tabDecider);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleActive = () => {
    dispatch(handleTabChange("Active")); //setting the clicked item in redux
  };

  const handleInactive = () => {
    dispatch(handleTabChange("Inactive")); //setting the clicked item in redux
  };
  
  const handleNew = () => {
    dispatch(handleTabChange("New")); //setting the clicked item in redux
  };
  const handleEdited = () => {
    dispatch(handleTabChange("Edited")); //setting the clicked item in redux
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '& .MuiTabs-indicator': {
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#dc3545',
            },
          }}
        >
          <Tab
            label="Active"
            onClick={handleActive}
            {...a11yProps(0)}
            sx={{
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "500",
              fontStyle: "normal",
              // textAlign: "left",
              color: "#707070",
              textTransform: 'capitalize',
              "&.Mui-selected": {
                color: "#dc3545",
              },
            }}
          />
         
          <Tab
            label="New"
            onClick={handleNew}
            {...a11yProps(1)}
            sx={{
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "500",
              fontStyle: "normal",
              textAlign: "center",
              color: "#707070",
              textTransform: 'capitalize',
              "&.Mui-selected": {
                color: "#dc3545",
              },
            }}
          />
          <Tab
            label="Edited"
            onClick={handleEdited}
            {...a11yProps(2)}
            sx={{
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "500",
              fontStyle: "normal",
              textAlign: "center",
              color: "#707070",
              textTransform: 'capitalize',
              "&.Mui-selected": {
                color: "#dc3545",
              },
            }}
          />
          <Tab
            label="Inactive"
            onClick={handleInactive}
            {...a11yProps(3)}
            sx={{
              fontFamily: "Poppins",
              fontSize: 16,
              fontWeight: "500",
              fontStyle: "normal",
              textAlign: "center",
              color: "#707070",
              textTransform: 'capitalize',
              "&.Mui-selected": {
                color: "#dc3545",
              },
            }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel  value={value} index={0}>
        <Active />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <New />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Edited />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Inactive />
      </CustomTabPanel>
    </Box>
  );
}
