import React, { useState, useEffect } from "react";
import CustomDataGrid from "../../products/stockComponents/customDataGrid";
import ReportFunctions from "../reportsFunctions";
import DateFormatter from "../../../utils/dateFormatter";
import { Cancel, CheckCircle, DisabledByDefault } from "@mui/icons-material";
import { ApproveVoidTransactionModal, CancelVoidTransactionModal, ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import startOrderFunction from "../../bills/billscomponents/startOrderEndpoints";
import { useNavigate } from "react-router-dom";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import { Grid, InputLabel, MenuItem, Select ,FormControl } from "@mui/material";
import { Exports } from "../../customerAccounts/customerComponents/exports";
import { CustomDate } from "../../customerAccounts/customerComponents/customDate";

const PendingVoids = (props) => {

  const navigate = useNavigate();
  const setExportData = props.setExportData;
  const setCsvExport = props.setCsvExport;
  const exportData = props?.exportData
  const tabvalue = props?.tabvalue
  const csvExport = props?.csvExport
  let localCurrency = localStorage.getItem('localCurrency')

  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
  const [openRange , setOpenRange] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency}).format(value);

  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {
    // pdf exports;
    const pdfData = pageState?.data?.map?.((transaction) => [transaction?.transactionID, transaction?.transactionType, CurrencyFormatter(transaction?.transamount, localCurrency), transaction?.voidRequestedBy, DateFormatter(transaction?.dateVoidRequested)]);
    const pdfHeaders = [["TXID", "Type", "Amount", "Requested By", "Voided On"]];
    setExportData({ headers: pdfHeaders, data: pdfData });

    // csv exports;
    const csvData = pageState?.data?.map((customer) => {
      return {
        "TXID": customer?.transactionID,
        "Type": customer?.transactionType,
        "Amount": CurrencyFormatter(customer?.transamount, localCurrency),
        "Requested By": customer?.voidRequestedBy,
        "Voided On": DateFormatter(customer?.dateVoidRequested),
      };
    });

    // csv headers;
    const csvHeaders = [
      { label: "TXID", key: "TXID" },
      { label: "Type", key: "Type" },
      { label: "Amount", key: "Amount" },
      { label: "Requested By", key: "RequestedBy" },
      { label: "Voided On", key: "Voided On" },
    ];

    setCsvExport({ headers: csvHeaders, data: csvData });
  }, [pageState.data, pageState.pageSize, dataGridPageSize ,startDate ,endDate]);

  const reportMethods = new ReportFunctions();
  const startOrderMethods = new startOrderFunction();
  const pendingColumns = [
    {
      field: "transactionID",
      headerName: "Transaction ID",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Transaction ID</span>;
      },
      renderCell: (params) => {
        return (
          <span onClick={() => navigate(`/detailedbill/${params?.row?.transactionID}`)} style={{ color: 'red', cursor: 'pointer' }} >{params?.row?.transactionID}</span>
        )
      }
    },
    {
      field: "transactionType",
      headerName: "Payment Mode",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Payment Mode</span>;
      },
    },

    {
      field: "transamount",
      headerName: "Amount(KES)",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Amount ({localCurrency}) </span>;
      },
    },

    {
      field: "voidRequestedBy",
      headerName: "Void Requested By",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Void Requested By</span>;
      },
    },

    {
      field: "dateVoidRequested",
      headerName: "Voided On",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Voided On</span>;
      },
    },

    {
      field: "action",
      headerName: "action",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontSize: "14px" }}>Action</span>;
      },
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "20px", cursor: "pointer" }}>
            <CheckCircle onClick={() => approveVoidPay(params.row)} style={{ color: "#17ae7b" }} />
            <Cancel onClick={() => approveCancel(params.row)} style={{ color: "#dc3545" }} />
          </div>
        );
      },
    },
  ];

  const doGetPendingVoidTransactions = () => {
    reportMethods.getPendingVoidTransactions(pageState?.page, dataGridPageSize ,startDate ,endDate).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data?.response?.transactions, count: data?.response?.transactions?.length, isLoading: false });
      }
    });
  };

  useEffect(() => {
    doGetPendingVoidTransactions();
  }, [pageState?.page, dataGridPageSize ,startDate ,endDate]);

  const pendingVoids = pageState?.data?.map((trans, index) => ({
    id: index,
    transactionID: trans?.transactionID,
    transactionType: trans?.transactionType,
    transamount: trans?.transamount,
    voidRequestedBy: trans?.voidRequestedBy,
    dateVoidRequested: DateFormatter(trans?.dateVoidRequested),
  }));

  // console.log('pendingVoids ===<<>', pageState?.data);

  // approve void modal;
  const [approveVoidModal, setApproveVoidModal] = useState(false);
  const [voidDetails, setVoidDetails] = useState({ amount: 0, transType: "", voidId: "" });
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [error, setShowError] = useState({ state: false, message: '' })
  const approveVoidPay = (trans) => {
    setVoidDetails({ ...voidDetails, amount: trans?.transamount, transType: trans?.transactionType, voidId: trans?.transactionID });

    setApproveVoidModal(true);
  };


  const setStartAndEndDates = (e) => {
    switch (e.target.value) {
      case "Today":
        const today = new Date().toLocaleDateString();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]).toLocaleDateString();
          const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
        const currentDay = new Date().toLocaleDateString();
        setStartDate(firstDay);
        setEndDate(currentDay);

        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
        const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      default:
        return e.target.value;
    }
  };


  const doApproveVoidPayments = () => {
    if (voidDetails?.voidId)
      startOrderMethods.voidTransactions(voidDetails?.voidId).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setApproveVoidModal(false);
          setVoidDetails({ ...voidDetails, amount: 0, transType: "", voidId: "" });

          setSucess({ ...sucess, state: true, message: "Void transaction request approved successfully!" });

          setTimeout(() => {
            setSucess({ ...sucess, state: false, message: "" });
          }, 3000);

          doGetPendingVoidTransactions();
        } else if (data?.status === 400) {

          setShowError({ state: true, message: data?.response?.message });
          setApproveVoidModal(false);
          setTimeout(() => {
            setShowError({ state: false })
          }, 3000);
        }
      });
  };

  // approve decline modal;
  const [cancelVoidModal, setCancelPayModal] = useState(false);
  const [cancelVoidDetails, setCancelVoidDetails] = useState({ amount: 0, transType: '', voidId: '' });

  const approveCancel = (trans) => {
    setCancelVoidDetails({ ...cancelVoidDetails, amount: trans?.transamount, transType: trans?.transactionType, voidId: trans?.transactionID });
    setCancelPayModal(true)

  }

  const doDeclineVoidPayment = () => {
    if (cancelVoidDetails?.voidId)
      startOrderMethods.cancelVoidTransactions(cancelVoidDetails?.voidId).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setCancelVoidDetails({ ...cancelVoidDetails, amount: 0, transType: '', voidId: '' });
          setCancelPayModal(false)

          setSucess({ ...sucess, state: true, message: "Void transaction request approved successfully!" });

          setTimeout(() => {
            setSucess({ ...sucess, state: false, message: "" });
          }, 3000);

          doGetPendingVoidTransactions();
        } else if (data?.status === 400) {

          setShowError({ state: true, message: data?.response?.message });
          setCancelPayModal(false)
          setTimeout(() => {
            setShowError({ state: false })
          }, 3000);
        }
      })
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={'column'} display={'flex'}>
        <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item display={'flex'} alignItems={'center'}>
              <FormControl style={{ width: "200px" }}>
                <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Date Range"
                  onChange={setStartAndEndDates}
                >
                  <MenuItem value={'Today'}>Today</MenuItem>
                  <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                  <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                  <MenuItem value={'This Month'}>This Month</MenuItem>
                  <MenuItem value={'Last Month'}>Last Month</MenuItem>
                  <MenuItem value={'Custom Range'} onClick={()=>setOpenRange(true)}>Custom Range</MenuItem>
                </Select>
              </FormControl>
              {openRange === true ? <CustomDate setDateFrom={setStartDate} setDateTo={setEndDate} />:null }
            </Grid>
            <Grid item>
              <Exports exportData={exportData} activeTabs={tabvalue} csvExport={csvExport} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item mt={2}>
          <CustomDataGrid customStyles={{ border: "none", height: "500px", width: "100%" }} column={pendingColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={pendingVoids} />
        </Grid>
      </Grid>
      <ApproveVoidTransactionModal setApproveVoidModal={setApproveVoidModal} approveVoidModal={approveVoidModal} billAmount={voidDetails?.amount} paymentMethod={voidDetails?.transType} doApproveVoidPayments={doApproveVoidPayments} />
      <CancelVoidTransactionModal cancelVoidModal={cancelVoidModal} setCancelPayModal={setCancelPayModal} billAmount={cancelVoidDetails?.amount} paymentMethod={cancelVoidDetails?.transType} doDeclineVoidPayment={doDeclineVoidPayment} />
      <SuccessAlertRightAligned sucess={sucess.state} message={sucess?.message} />
      <ErrorAlertRightAligned error={error.state} message={error.message} />
    </div>
  );
};

export default PendingVoids;
