import { Button, Grid, Typography, Breadcrumbs } from "@mui/material";
import { useState } from "react";
import PinInput from 'react-pin-input';
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { addPayable, clearPayable } from "../../../../features/payableslice";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';



export default function OtpVerficationFT(props) {
    const breadcrumbs = [
        <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
        <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>,
        <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Preview Transaction</Typography>,
        <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Verification</Typography>
    ]
    console.log(props, 'props OtpVerficationFT');
    const previoursPin = props.pin
    const email = props.email
    const phone = props.phone
    console.log(previoursPin, 'previoursPin OtpVerficationFT');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [pin, setPin] = useState('')
    const [showPin, setShowPin] = useState(false)
    const [callFt, setCallFt] = useState(true)


    const dispatch = useDispatch()


    const navigate = useNavigate()

    const ResendOtp = () => {
        if (!previoursPin) {
            setErrorShow({ state: true, message: 'Your will have to provide a pin first' });
            return navigate('/payables/transcations?verifyPayable')


        }

        HttpComponent({
            method: 'POST',
            url: `/api/v1/zed/funds_transfer/otp`,
            token: localStorage.getItem("X-Authorization"),
            body: {
                "pin": previoursPin

            }

        }).then((data) => {
            if (data.status === 200) {
                console.log(data?.response?.message, 'data')
                let message = data?.response?.message
                // console.log(message, 'message')
                setSuccessShow({ state: true, message: message });


            }

            else {
                console.log(data?.response?.message, 'data')

                let message = data?.response?.message
                // console.log(message, 'message')
                setErrorShow({ state: true, message: message });
            }
        }).catch((error) => {
            console.error(error);
        })


    }
    const { transcations, payableStatus } = useSelector((store) => store.payables);

    const approveDisapproveFtTransaction = () => {
        setCallFt(false)
        console.log('previoursPin approveDisapproveFtTransaction');
        //    const  {payables} = useSelector((store) => store.payables);
        console.log(transcations, 'transactionIds approveDisapproveFtTransaction');
        console.log(payableStatus, 'status approveDisapproveFtTransaction');
        let transactionIds = transcations
        let status = payableStatus
        let url = '/api/v1/funds_transfer/approve_or_disapprove'

        let bodyObj = {
            transactionIds: [...transactionIds],
            status: payableStatus,
            otp: pin
        }
        if (status === 'Retry') {
            url = '/api/v1/funds_transfer/retry_ft';
            let transactionId = transactionIds[0];
            bodyObj = {
                transactionId: transactionId,
                otp: pin
            };
        } else if (status === "Cancel") {
            url = '/api/v1/funds_transfer/cancel_transaction';
            let transactionId = transactionIds[0];
            bodyObj = {
                status: 'Cancel',
                transactionIds: [transactionId], 
                otp: pin
            };
        } else {
            url = '/api/v1/funds_transfer/approve_or_disapprove';
        } if (!transactionIds) {
            setErrorShow({ state: true, message: 'Your will have to select transcations' });
            navigate('/payables/transcations')
            return
        }
        console.log('pin', pin)
        HttpComponent({
            method: 'POST',
            url: url,
            token: localStorage.getItem("X-Authorization"),
            body: bodyObj
        }).then((data) => {
            if (data.status === 200 || data.status === 201) {
                console.log(data?.response?.message, 'data')
                dispatch(clearPayable())
                setSuccessShow({ state: true, message: data?.response?.message });
                setTimeout(() => {
                    //   window.location.reload()
                    navigate('/payables/transcations')
                }, 2000);

            }

            else {
                console.log(data?.response?.message, 'data')

                let message = data?.response?.message
                // console.log(message, 'message')
                setErrorShow({ state: true, message: message });
                setPin('')
            }
        }).catch((error) => {
            console.error(error);
        })

    }
    if (pin.length === 6 && callFt === true) {
        if (transcations.length > 0) {
            approveDisapproveFtTransaction()
            setPin('')

        }
    }

    return (
        <><Grid container direction={'column'}>
            <Grid item mt={1} display={'flex'} alignItems={'center'} style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                <ArrowBackIosNewIcon style={{ width: '25px', height: '30px', marginRight: "10px" }} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541", cursor: "pointer" }} onClick={() => navigate(-1)}>Verification</Typography>
            </Grid>
            <Grid item mt={1}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>

        </Grid>
            <Grid container direction={'column'} mt={10}>
                <Grid item display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                    <Typography style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>We've sent a 6-digit One-Time Pin to</Typography>
                    <Typography style={{ fontSize: '16px', marginTop: "10px", color: '#707070' }}>Your email  <span style={{ color: "black", fontWeight: "900" }}> {email}</span> & WhatsApp <span style={{ color: "black", fontWeight: "900" }}>{phone}</span>  </Typography>
                </Grid>
                <Grid item mt={1} display={'flex'} justifyContent={'center'}>
                    <PinInput
                        length={6}
                        initialValue=""

                        secret={true}
                        secretDelay={100}
                        onChange={(pin) => setPin(pin)}
                        // type="numeric"
                        type={showPin ? 'text' : 'password'}
                        inputMode="numeric"
                        style={{ padding: '10px' }}
                        inputStyle={{ borderColor: '#bec5d1', width: "92px", height: "108px", fontSize: "50px" }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        //onComplete={(value, index) => { }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                </Grid>
                <Grid item mt={2} display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                    {/* <Typography style={{ fontSize: '16px', cursor: 'pointer', fontWeight: 600, color: '#032541' }}>Show Pin</Typography> */}
                    <span style={{ fontSize: '16px', cursor: 'pointer', fontWeight: 600, color: '#9AA9B2' }}>Didn't get the code ?  <span style={{ cursor: "pointer", color: "#032541" }} onClick={() => ResendOtp()} >Resend</span> </span>
                </Grid>
                <Grid item mt={4} display={'flex'} justifyContent={'center'}>
                    {/* <Button style={{ color: '#dc3545', width: '125px', height: '45px', border: '1px solid #dc3545', textTransform: 'inherit' }}>Cancel</Button>
                    <Button style={{ color: '#fff', width: '125px', height: '45px', marginLeft: '15px', textTransform: 'inherit', background: 'rgba(3, 37, 65, 0.4)' }} onClick={() => approveDisapproveFtTransaction()}>Verfiy</Button> */}
                    {/* <Button style={{ color: '#fff', width: '125px', height: '45px', marginLeft: '15px', textTransform: 'inherit', background: 'rgba(3, 37, 65, 0.4)' }} >Resend</Button> */}


                </Grid>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
        </>

    )
}