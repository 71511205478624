import { CircularProgress, Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessIcon2 from "../../../common/images/business-icon.png";
import axios from "axios";
import cardBG from '../../../common/images/cardBG.png'



const baseUrl = process.env.REACT_APP_BASE_URL;

function TotalBusinesses() {
  const [businessTotals, setBusinessTotals] = useState(null)

  const partnerName = localStorage.getItem("affiliatedPartner")

  const fetchBusinesses = async () => {
    const response = await axios.post(
      baseUrl + "/api/businessCount",
      {
        partnerName
      },
      {
        headers: {
					"X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    )
    setBusinessTotals(response.data.data)
    // console.log(businessTotals)
  }

  useEffect(() => {
    fetchBusinesses()
  }, [])

  return (
    <Stack
      width={"100%"}
      height="166px"
      justifyContent={"space-between"}
      p={2}
      borderRadius={"12px"}
      boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
      bgcolor={"white"}
      style={{
        backgroundImage: `url(${cardBG})`,
        backgroundSize: "70% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={"16px"}
          fontWeight={500}
          fontStretch={"normal"}
          fontStyle={"normal"}
          lineHeight={1.31}
          letterSpacing={"normal"}
          textAlign={"left"}
          color={"#17ae7b"}
        >
          Total Businesses
        </Typography>
        <BusinessIcon sx={{color: '#17ae7b'}} /> 
      </Stack>
      <Stack width="100%">
        <Typography
          fontFamily="Poppins"
          fontSize={18}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {/* 15050 */}
          {
          (businessTotals?.total >= 0 ) ?   businessTotals?.total :
          //  <CircularProgress />
        <Skeleton variant="text" fontSize={18} width={'30%'} sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
          }

        </Typography>
      </Stack>
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
          {
          (businessTotals?.Active >= 0) ? businessTotals?.Active :  
        <Skeleton variant="text" fontSize={14}sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
          
          // <CircularProgress />   
          }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Active
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
          {
          (businessTotals?.Inactive >= 0) ? businessTotals?.Inactive : 
        <Skeleton variant="text" fontSize={14} sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
          
          // <CircularProgress /> 
          }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Inactive
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
          {
          (businessTotals?.New >= 0) ? businessTotals?.New : 
        <Skeleton variant="text" fontSize={14} sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
          
          // <CircularProgress /> 
          }
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            New
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TotalBusinesses;
