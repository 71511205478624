import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import { styled } from '@mui/system';

const ResponsiveContainer = styled(Grid)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  backgroundColor: '#000',
}));

const VideoBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

const CancelButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '10px 20px',
  borderRadius: '5px',
  backgroundColor: 'rgba(3, 37, 65, 0.75)',
  color: '#fff',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'rgba(3, 37, 65, 0.9)',
  },
}));

const QRcodeScanner = ({isOpenQR , closeQr}) => {
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const navigate = useNavigate();
  const [qrOn, setQrOn] = useState(true);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const onScanSuccess = (result) => {
    if (result?.data) {
      let scannedResult = result?.data;
      scannedResult = scannedResult.replace('https://dev.zed.business/paymatatufare/', '');
      let splitResult = scannedResult.split('/');
      const [regNo, customerId, payBill, paymentMethod] = splitResult;
      navigate(`/paymatatufare/${regNo}/${customerId}/${payBill}/${paymentMethod}`);
    }
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  const handleCloseCamera = () => {
    scanner?.current?.stop();
    closeQr()
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <ResponsiveContainer container direction="column">
      <SuccessAlert
        vertical="top"
        horizontal="right"
        onClose={() => setSuccessShow({ ...successShow, state: false })}
        open={successShow.state}
        message={successShow.message}
      />
      <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={() => setErrorShow({ ...errorShow, state: false })}
        open={errorShow.state}
        message={errorShow.message}
      />
      <VideoBox component="video" ref={videoEl} />
      {qrOn && (
        <CancelButton onClick={handleCloseCamera}>
          Cancel
        </CancelButton>
      )}
    </ResponsiveContainer>
  );
};

export { QRcodeScanner };