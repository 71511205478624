import { Grid, Typography, Breadcrumbs, TextField, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import AutocompleteComponent from '../../../../common/autoComplete';
import DateFormatter from '../../../../utils/dateFormatter';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
import { If } from 'rc-if-else';

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);


// breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>ZPM Reconciliation</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Reconcile</Typography>
]
export default function ReconcileFunds() {
    const navigate = useNavigate();
    const [formData, setformdata] = useState({ dateFrom: '', dateTo: '', amount: '' })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })


    // get alll business

    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness();
    }, [selectedValue])

    // get amount to settle

    const [reconcileDetails, setReconcileDetails] = useState({})
    console.log(reconcileDetails, 'reconcileDetails')

    function getAmountToReconcile() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getAmountToSettle?businessNumber=${selectInputValue?.bsNo ? selectInputValue?.bsNo : ''}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setReconcileDetails(data?.response?.data)
                    setformdata({
                        ...formData,
                        dateFrom: data?.response?.data?.startSettlingDate,
                        dateTo: data?.response?.data?.endSettlingDate,
                        amount: data?.response?.data?.amountToSettle
                    })
                }

            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getAmountToReconcile()
    }, [selectInputValue?.bsNo])


    // reconcile funds

    function handleFundsReconcile() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/settleSchoolPayments`,
                body:{
                    businessNumber:selectInputValue?.bsNo
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setSuccessShow({state:true , message:data?.response?.message})
                    setTimeout(()=>{
                        navigate(-1)
                    },[1500])
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }

            })
        } catch (error) {

        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>ZPM Reconciliation</Typography>
            </Grid>
            <Grid item mt={2} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ width: "100%" }} />
            </Grid>
            {!selectInputValue?.bsNo ? <Grid width={'50%'} item mt={10} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                <Typography>Kindly select business</Typography>
            </Grid> : formData.amount !== 0 && selectInputValue?.bsNo ? <>
                <Grid item mt={2} display={'flex'} width={'50%'}>
                    <Grid container display={'flex'} width={'100%'} gap={2}>
                        <Grid item width={'48%'}>
                            <TextField value={DateFormatter(formData.dateFrom)} InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '100%', border: "solid 0px #e4e4e4", fontSize: "12px" }} disabled id="outlined-basic" label="From" variant="outlined" required />
                        </Grid>
                        <Grid item width={'49%'}>
                            <TextField value={DateFormatter(formData.dateTo)} InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '100%', border: "solid 0px #e4e4e4", fontSize: "12px" }} disabled id="outlined-basic" label="To" variant="outlined" required />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={2} width={'50%'}>
                    <TextField value={numberFormat(formData.amount)} InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '100%', border: "solid 0px #e4e4e4", fontSize: "12px" }} disabled id="outlined-basic" label="Amount" variant="outlined" required />
                </Grid>
                <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={() => navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleFundsReconcile} style={!selectInputValue?.bsNo ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Reconcile</Button>
                </Grid>
            </> :
                <Grid width={'50%'} item mt={10} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                    <Typography>No Funds to reconcile</Typography>
                </Grid>}

        </Grid>
    )
}