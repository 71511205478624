import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  terminals: []
}


const EditedTerminalsSlice = createSlice({
  name:"editedTerminals",
  initialState,
  reducers:{
    handleTerminalEdited:(state, action) => {
        state.terminals.push(action.payload)
    },
    handleRemove: (state, action) => {
        state.terminals = state.terminals.filter((item) => item.serialNo !== action.payload)
    }

  }
})

export const { handleTerminalEdited, handleRemove } = EditedTerminalsSlice.actions;

export default EditedTerminalsSlice.reducer