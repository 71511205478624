import { Grid, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { currencyconverter } from '../../common/currencyconverter';
import CustomDataGrid from '../products/stockComponents/customDataGrid';
import HttpComponent from '../School/MakeRequest';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Unpaid Orders</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Order Items</Typography>
]


export default function ProductsInOrder() {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({isLoading: false,total: 0,data: [],page: 1,pageSize: dataGridPageSize})
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue , orderNumber] = state.split("?")

    // get items by order number

    function getItemsByOrderNumber() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/orders-by-order-number?orderNumber=${orderNumber}&page=${pageState.page}&limit=${dataGridPageSize} `,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getItemsByOrderNumber()
    }, [pageState.page, dataGridPageSize , orderNumber ,queryParamValue ,state])



    const configData = pageState?.data?.map((config) => ({
        id: config?.itemId,
        itemName: config?.itemName,
        itemCategory: config?.itemCategory,
        itemAmount: config?.itemAmount,
        itemCount: config?.itemCount,
        totalAmount:config?.totalAmount
    }))

    const itemColumns = [
        { field: "itemName", headerName: "Item Name", flex: 1,},
        { field: "itemCategory", headerName: "Category", flex: 1 },
        { field: 'itemAmount', headerName: 'Amount', flex: 1 ,renderCell: (params) => <Typography>{currencyconverter(params?.row?.itemAmount)}</Typography> },
        { field: 'itemCount', headerName: 'Item Count', flex: 1 },
        { field: 'totalAmount', headerName: 'Total Amount', flex: 1,renderCell: (params) => <Typography>{currencyconverter(params?.row?.totalAmount)}</Typography> },

    ]



    return (
        <Grid container direction={'column'} width={'100%'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Order Items</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={"100%"}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={itemColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )

}