import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, MenuItem, Breadcrumbs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { warehousestyles } from "../styles";
import AddUser from "../warehousecomponents/addUser";
import { NavigateNext } from "@mui/icons-material";
import { ArrowBackIos } from "@mui/icons-material";
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { useSelector } from "react-redux";


const MainStoreEdit = () => {
  const navigate = useNavigate();

  const { wareHouseState } = useSelector((store) => store.sidebar)
  const { id } = useParams();
  const queryParams = decodeURIComponent(window.location.search);
  const queryData = queryParams.split("?");

  const mainStoreId = queryData[1];
  const branchName = queryData[2];

  const [mainStoreDetails, setMainStoreDetails] = useState({ name: "" });
  const [mainsStoreUserId, setMainStoreUserId] = useState("");

  const [isError, setError] = useState({ state: false, message: "" });
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });


  const getUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMainStoreDetails({ ...mainStoreDetails, [name]: value });
  };
  const [allInputsFilled, setAllInputsFilled] = useState(false);

  useEffect(() => {
    if (mainStoreDetails.name === "") {
      setAllInputsFilled(false);
    } else if (mainStoreDetails.name) {
      setAllInputsFilled(true);
    }
  }, [mainStoreDetails.name]);

  const changeUserInput = (e) => {
    setMainStoreUserId(e.target.value);
    console.log(e.target.value);
  };

  const getMainStore = () => {
    HttpComponent({
      method: "GET",
      url: `/api/get_store_details?storeType=stores&storeId=${mainStoreId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
         
            const storeDetails = data.response.data.filter((store) => store._id === mainStoreId)
            
          const { name, storeType } = storeDetails[0];

          setMainStoreDetails({ name: name, description: "" });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getMainStore();
  }, [mainStoreId]);

  const [operators, setOperators] = useState([]);

  const getActiveUser = () => {
    HttpComponent({
      method: "GET",
      url: `/api/listUsers/Active?page=${1}&limit=${1000}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        //  get only storeoperators;
        const warehouseOperators = data?.response?.data?.filter((store) => {
          if (store.userRole === "STOREOPERATOR") return store;
        });

        setOperators(warehouseOperators);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  const editMainStore = () => {
    HttpComponent({
      method: "POST",
      url: `/api/update_stores_by_id?storeId=${mainStoreId}`,
      body: { storeName: mainStoreDetails?.name, assignedUserId: mainsStoreUserId },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 202) {
            setSuccess({ state: true, message: "Mainstore has been edited sucessfully!" });
          setTimeout(() => {
            setSuccess({ state: false });
            navigate(-1);
          }, 2000);
        } else if (data?.status === 400) {
          setError({ state: true, message: data?.response?.error });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const errorMessage = <ErrorAlertRightAligned error={isError.state} message={isError.message} />;
  const successMessage = <SuccessAlertRightAligned sucess={isSuccess.state} message={isSuccess.message} />;

  return (
    <div>
      {isError.state && errorMessage}

      {isSuccess.state && successMessage}

      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <span onClick={() => navigate(-1)} style={warehousestyles.supplierText}>
            {" "}
            <ArrowBackIos style={{ cursor: "pointer" }} /> {wareHouseState ? "Stores" : "Branches"} 
          </span>
        </Grid>
      </Grid>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            <span onClick={() => navigate(-1)}> {wareHouseState ? "Mainstores" : "Branches"} </span>
            {id === "editUser" ? <span style={{ color: "red" }}> Stores</span> : id === "addOperator"  ? <span style={{ color: "red" }}> Branches</span> : <span style={{ color: "red" }}>Edit Mainstore</span>}
          </Breadcrumbs>
        </Grid>
      </Grid>

      {id === "addOperator" ? (
        <AddUser storeTypeToAdd={"MainStore"} mainsStoreUserId={mainsStoreUserId} />
      ) : (
        <>
          <Grid container direction={"column"} width={"100%"}>
            <Grid item style={warehousestyles.addsupplierGridItems}>
              <TextField name="name" value={mainStoreDetails.name} onChange={getUserInput} style={warehousestyles.addsupplierInputs} label="Mainstore Name" variant="outlined" />
            </Grid>

            <Grid item style={warehousestyles.addsupplierGridItems}>
              <TextField disabled name="" value={branchName} style={warehousestyles.addsupplierInputs} label="branchName" variant="outlined" />
            </Grid>

            <Grid item style={warehousestyles.addsupplierGridItems}>
              <TextField id="outlined-select-currency" style={{ width: "50%" }} select label="Assign User(Optional)" onChange={changeUserInput}>
                <MenuItem id="outlined-select-currency" onClick={() => navigate(`/mainstores/${"addOperator"}`)}>
                  Create User
                </MenuItem>

                {operators?.map((option) => (
                  <MenuItem key={option._id} value={option?.userId}>
                    {option?.userName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item style={warehousestyles.addsupplierGridItems}>
              <div style={warehousestyles.rightAlignedButtonHolder}>
                <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? editMainStore() : null)}>
                  Update
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default MainStoreEdit;
