import React, { useState, useEffect, useRef } from "react";
import { Modal, Grid, Box, Button, Select, Breadcrumbs, TextField, MenuItem, Typography, FormControl, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos, NavigateNext } from "@mui/icons-material";
import { customerstyles } from "../../customerAccounts/customerComponents/styles";
import InvoiceItems from "../../customerAccounts/customerComponents/invoiceItems";
import { CustomeronAccountsSearch } from "../../customerAccounts/customerComponents/customeronAccountsSearch";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import DateFormatter from "../../../utils/dateFormatter";
import Checkbox from '@mui/material/Checkbox';
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import SearchIcon from '@mui/icons-material/Search';
import PreviewCreditInvoice from "../../customerAccounts/customerComponents/previewCreditInvoice";

const breadcrumbs = [
  <Typography style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Dashboard</Typography>,
  <Typography style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Customers</Typography>,
  <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Credit Invoice</Typography>
]

const btnStyles = {
  border: "2px solid #bec5d1",
  width: "100%",
  background: "#fff",
  color: "#bec5d1",
  textTransform: "inherit",
  height: "55px"

}

const selectedBtn = {
  border: "2px solid #17ae7b",
  width: "100%",
  background: "#fff",
  textTransform: "inherit",
  height: "55px",
  color: "#17ae7b",
}

const CreateInvoice = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [customerDetails, setCustomerDetails] = useState({})
  const [creditCustomerPreviewInvoice, setCreditCustomerPreviewInvoice] = useState(false)

  const [customerId, setCustomersId] = useState("");
  const [invoiceType, setInvoiceType] = useState(["one-off", "recurring"]);
  const [frequency, setFrequency] = useState(["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]);
  const [showCustomercomponent, setShowCustomerComponent] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [invoiceTypeAndFrequency, setTypeAndFrequency] = useState({ type: "", freq: "" });

  const [allInputsField, setInputsField] = useState(false);
  const [selectBtn, setSelectBtn] = useState(false)
  const [showTransaction, setShowTransaction] = useState(false)
  const [checked, setChecked] = useState([])
  const [isRowSelected, seIsRowSelected] = useState(false)



  const handleShow = () => {
    setSelectBtn(true)
    setShowTransaction(false)
  }



  function fetchCustomerDetails() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/get_customer_by_customer_id?customerId=${id}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'customer info')
        if (data?.status === 200) {
          setCustomerDetails(data?.response?.data)
        }
      })
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchCustomerDetails()
  }, [id])


  const { firstName, paymentType, lastName } = customerDetails

  const [customerTransacs, setCustomerTransacs] = useState([])
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const getCustomerActivity = () => {
    HttpComponent({
      method: "GET",
      url: `/api/CustomersActivities?customerId=${id}&page=${pageState.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data, 'customer activity');
        setPageState({ ...pageState, isLoading: false, data: data?.response?.data, count: data?.response?.count });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };


  const getTransactions = () => {
    HttpComponent({
      method: 'GET',
      url: `/api/get_customer_transactions?customerId=${id}`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200) {
        setCustomerTransacs(data?.response?.data)
      }
    })
  }

  console.log(customerTransacs)



  useEffect(() => {
    getTransactions()
  }, [])

  const transactionsColumns = [
    {
      field: "pushTransactionId", headerName: "Transaction No", renderHeader: () => { return (<strong style={{ color: "#032541", fontWeight: 600 }}>Transaction No</strong>) }, flex: 1, renderCell: (params) => {
        const getSelected = (transactionId) => {

          const newChecked = [...checked];

          const currentIndex = newChecked.indexOf(transactionId)
          if (currentIndex === -1) {
            newChecked.push(transactionId)
            seIsRowSelected(true)
          } else {
            newChecked.splice(currentIndex, 1)
            seIsRowSelected(false)
          }

          setChecked(newChecked)
          seIsRowSelected(true)
        }
        return (
          <>
            <Checkbox style={isRowSelected ? { color: "#032541" } : {}} onClick={() => getSelected(params.row)}></Checkbox>
            <span>{params?.row?.pushTransactionId}</span>
          </>
        )
      }
    },
    {
      field: "createdAt", headerName: "Date", renderHeader: () => { return (<strong style={{ color: "#032541", fontWeight: 600 }}>Date</strong>) }, flex: 1, renderCell: (params) => {
        return (
          <>
            <span>{DateFormatter(params?.row?.createdAt)}</span>
          </>
        )
      }
    },
    {
      field: "items", headerAlign: 'center', renderHeader: () => { return (<strong style={{ color: "#032541", fontWeight: 600 }}>Number of Items</strong>) }, align: 'center', headerName: "Number of Items", flex: 1, renderCell: (params) => {
        return (
          <>
            <span>{params?.row?.totalItemCount}</span>
          </>
        )
      }
    },
    {
      field: "amount", headerAlign: 'center', renderHeader: () => { return (<strong style={{ color: "#032541", fontWeight: 600 }}>Amount(KES)</strong>) }, align: 'center', headerName: "Amount(KES)", flex: 1, renderCell: (params) => {
        return (
          <>
            <span>{params?.row?.totalAmountCount}</span>
          </>
        )
      }
    },

  ]

  useEffect(() => {
    getCustomerActivity();
  }, [props.customerId, pageState.page, dataGridPageSize]);


  useEffect(() => {
    if (invoiceTypeAndFrequency.type === "one-off") {
      setTypeAndFrequency({ ...invoiceTypeAndFrequency, freq: "once" });
    }
  }, [invoiceTypeAndFrequency.type]);

  useEffect(() => {
    if (!invoiceTypeAndFrequency.type && !invoiceTypeAndFrequency.freq) {
      setInputsField(false);
    } else if (invoiceTypeAndFrequency.type && invoiceTypeAndFrequency.freq) {
      setInputsField(true);
    } else if (!invoiceTypeAndFrequency.type && !invoiceTypeAndFrequency.freq) {
      if (id === "new" && !customerId) {
        setInputsField(false);
      }
    }
  }, [invoiceTypeAndFrequency.type, invoiceTypeAndFrequency.freq, id]);
  const getInputsData = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setTypeAndFrequency({ ...invoiceTypeAndFrequency, [name]: value });
  };

  //   invoice itemns toggle
  const [invoiceItemsShow, setInvoiceItemsShow] = useState(false);
  const [createInvoiceShow, setCreateInvoiceShow] = useState(true);

  useEffect(() => {
    if (!createInvoiceShow && creditCustomerPreviewInvoice) {
      setInvoiceItemsShow(false);

    }
    if (!createInvoiceShow && !creditCustomerPreviewInvoice) {
      setInvoiceItemsShow(true);
    }
    if (createInvoiceShow) {
      setInvoiceItemsShow(false);
    }
  }, [invoiceItemsShow, createInvoiceShow]);


  const handleShowPreviewInvoice = () => {
    setCreditCustomerPreviewInvoice(true)
    setCreateInvoiceShow(false)

  }

  return (
    <div style={customerstyles.universalStyle}>
      {createInvoiceShow && (
        <Grid container direction={"column"} width={"100%"}>
          <Grid item width={"100%"}>
            <p style={{ fontSize: "25px", fontWeight: 600, color: "#032541" }}>
              {" "}
              <ArrowBackIos onClick={() => navigate(`/customers`)} />
              Create Invoice
            </p>
          </Grid>
          <Grid item width={"100%"} style={{ margin: "0px 0px 10px 0px" }}>
            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>

          {id === "new" && (
            <Grid item style={{ margin: "0px 0px 10px 0px", width: "70%" }}>
              <Grid item width={"68%"} style={{ margin: "0px 0px 10px 0px" }}>
                <SearchFilter placeholder={"Search Customer"} setInputValue={setInputValue} />
              </Grid>
              <Grid item mt={3} width={"68%"}>
                <TextField value={customerName} disabled readOnly style={{ width: "100%", margin: "0px 0px 10px 0px" }} label='Customer Name' onChange={(e) => setCustomerName(e.target.value)} />
              </Grid>

              {inputValue && <CustomeronAccountsSearch setCustomerName={setCustomerName} inputValue={inputValue} setCustomersId={setCustomersId} setInputValue={setInputValue} />}
            </Grid>
          )}

          <Grid item style={{ margin: "10px 0px 20px 0px", width: "70%" }}>
            {id === "new" ? "" : <p style={{ color: "#707070", marginBottom: "20px" }}> Create Invoice</p>}
            <Grid item mt={2}>
              {/* <span>Invoice From <small style={{ color: "#dc3545" }}>*</small></span> */}
            </Grid>
            <Grid item width={'68%'} display={'flex'} justifyContent={'space-between'} mt={2} mb={2}>
              {/* <Button onClick={handleShow} style={selectBtn ? selectedBtn : btnStyles}>Products/Services</Button> */}
              {/* <Button disabled={paymentType === "Credit" ? false : paymentType === "Prepaid" ? false : true} onClick={()=>{setShowTransaction(true) ; setSelectBtn(false)}}  style={showTransaction? selectedBtn:btnStyles}>Existing Transactions</Button> */}
            </Grid>
            {showTransaction &&
              <Grid container flex direction={'column'}>
                <Grid item width={'70%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography style={{ fontSize: "14px" }}>Select Transactions <small style={{ color: "#dc3545" }}>*</small></Typography>
                  <Typography style={{ color: "#17ae7b", fontSize: "14px" }}>Select All</Typography>
                </Grid>
                <Grid item width={'70%'}>
                  <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">search</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={'text'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="start"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="search"
                    />
                  </FormControl>
                </Grid>
                <Grid item width={'70%'}>
                  <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '300px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    columns={transactionsColumns}
                    rows={customerTransacs}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={row => row._id}
                  />
                </Grid>
              </Grid>
            }
            <TextField style={{ width: "68%", marginTop: "10px" }} onChange={getInputsData} name="type" value={invoiceTypeAndFrequency.type} id="outlined-select-meals" select label="Invoice Type" helperText="">
              {invoiceType?.map((customer, index) => {
                return (
                  <MenuItem key={index} value={customer}>
                    {customer}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {invoiceTypeAndFrequency.type === "recurring" && (
            <Grid item style={{ margin: "0px 0px 10px 0px", width: "68%" }}>
              <TextField onChange={getInputsData} value={invoiceTypeAndFrequency.freq} name="freq" style={{ width: "70%" }} id="outlined-select-meals" select label="Frequency" helperText="">
                {frequency?.map((customer, index) => {
                  return (
                    <MenuItem key={index} value={customer}>
                      {customer}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}
          <>
            <Grid item width={'48%'} display={'flex'} justifyContent={'flex-end'}>
              <Button onClick={() => navigate(`/customerDetails/${id}`)} style={customerstyles.cancelBtn}>
                Cancel
              </Button>
              <Button style={!allInputsField ? customerstyles.approvalButtonLowOpacity : customerstyles.approvalButton} onClick={() => (allInputsField ? setCreateInvoiceShow(false) : null)}>
                Add Products/services
              </Button>
            </Grid>
          </>
        </Grid>
      )
      }

      {invoiceItemsShow && <InvoiceItems setCreateInvoiceShow={setCreateInvoiceShow} customerId={id} invoiceType={invoiceTypeAndFrequency.type} frequency={invoiceTypeAndFrequency.freq} newCustomerId={customerId} />}
      {creditCustomerPreviewInvoice && <PreviewCreditInvoice customerId={id} transactions={checked} />}
    </div>
  );
};
export { CreateInvoice };
