import { Breadcrumbs, Button, Grid, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../../../components/snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../components/snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../../../components/School/MakeRequest";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Convenience Fee</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Adjust Fee</Typography>
]

export default function AdjustBusinessConvenienceFee(props){
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData ,setFormData] = useState({localCurrency:'' , businessName:"" ,businessNumber:"" ,businessCategory:"" ,convenientFeeAmount:""})

    const navigate = useNavigate()

    console.log(props)

    const handleOnChange = (e) =>{
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const updateFormData = {
        convenientFeeAmount:formData.convenientFeeAmount
    }

    //update adjust fee

    function updateAdjustFee(){
        const obj = {businessNumber:props?.businessNumber}
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/business/convenienceFee`,
                body: obj,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                console.log(data , 'dataaaaaaaaa is hereeee')
                if(data.status === 201){
                    setFormData({
                        ...formData,
                        businessNumber:data?.response?.data?.businessNumber,
                        businessName: data?.response?.data?.businessName,
                        businessCategory:data?.response?.data?.businessCategory, 
                        convenientFeeAmount: data?.response?.data?.convenientFeeAmount, 
                        localCurrency: data?.response?.data?.localCurrency, 
                    })
                }
            })

        }catch(error){

        }
    }

    useEffect(()=>{
        updateAdjustFee()
    },[])

    function updateFee(){
        try {
            HttpComponent({
                method:'POST',
                url:`/api/v1/business/convenienceFee/enable_disable`,
                body: {
                    status:true,
                    businessNumber:formData.businessNumber,
                    convenienceFee:formData.convenientFeeAmount
                },
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setSuccessShow({state:true , message:"Successfully updated convenience fee"})
                    setTimeout(()=>{
                        navigate(-1)
                    }, 1500)
                   
                }else{
                    setErrorShow({state:true , message:'Error updating convenience fee'})
                }
            })
            
        } catch (error) {
            setErrorShow({state:true , message:error?.message})
        }

    }
  

    return(
        <Grid direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={()=>navigate(-1)}/>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Adjust Fee</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Adjust Fee</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="businessName" onChange={handleOnChange} style={{ width: "100%" }} value={formData.businessName} label="Business Name" InputProps={{readOnly:true}} variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}> 
                <TextField id="outlined-basic" name="businessNumber"  onChange={handleOnChange} style={{ width: "100%" }} value={formData.businessNumber} label="Business ID" InputProps={{readOnly:true}} variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="businessCategory"  onChange={handleOnChange} style={{ width: "100%" }} value={formData.businessCategory} label="Business Type" InputProps={{readOnly:true}} variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="localCurrency"  onChange={handleOnChange} style={{ width: "100%" }} value={formData.localCurrency} label="Currency" InputProps={{readOnly:true}} variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="convenientFeeAmount"  onChange={handleOnChange} style={{ width: "100%" }} value={formData.convenientFeeAmount} label="Convenience Fee" variant="outlined" />
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={updateFee} style={!formData.convenientFeeAmount ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Update</Button>
            </Grid>
        </Grid>
    )
}