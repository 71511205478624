import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Stack, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { handleItemClick } from "../../../../features/actionSlice";
import { useSelector } from "react-redux";
import BlockIcon from "@mui/icons-material/Block";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import MergeRoundedIcon from '@mui/icons-material/MergeRounded';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate } from "react-router-dom";


export default function BusinessesDropdown() {
  const dispatch = useDispatch();

  const handleClick = (clicked) => {
    dispatch(handleItemClick(clicked)); //setting the clicked item in redux
  };

  const currentTab = useSelector((state) => state.currentTab.tab);
  const group = localStorage.getItem("group");
  const navigate = useNavigate();
  const reduxBusinessName = useSelector(
    (state) => state.DetailsOfbusiness.businessId
  );
  console.log(reduxBusinessName , 'reduxBusinessName')
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* <ThemeProvider theme={theme}> */}
          <Button variant="text" {...bindTrigger(popupState)}>
            <MoreVertIcon sx={{color:"#032541"}} />
          </Button>
          {/* </ThemeProvider> */}
          <Menu {...bindMenu(popupState)}>
            {currentTab == "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Edit");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1} alignItems={'center'}>
                    <EditIcon  sx={{width: '20px'}} color={"primary"} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textAlign: "left",
                        color: "#707070",
                      }}
                    >
                      Edit Business
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Deactivate");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1} alignItems="center">
                    <BlockIcon sx={{width: '20px'}} color={"error"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Deactivate Business</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("View");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1} alignItems={'center'}>
                    <ViewQuiltIcon sx={{width: '20px'}} color={"#032541"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >View Modules</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Branches");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1} alignItems='center'>
                    <AltRouteIcon sx={{width:'20px'}} color={"#032541"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >View Branches</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Active" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Assign");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1} alignItems='center'>
                    <MergeRoundedIcon sx={{width:'20px'}} color={"#032541"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Assign Partner</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
             {(currentTab === "Active" && group === 'Partner')  && (
              <>
                <MenuItem onClick={() => {navigate(`/Businesses?viewZpmAdmin?${reduxBusinessName}`); popupState.close();}}>
                  <Stack direction={"row"} spacing={1} alignItems='center'>
                    <SupervisorAccountIcon sx={{width:'20px'}} color={"#032541"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >ZPM School Admin</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Inactive" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Activate");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <CheckCircleIcon color={"success"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Activate Business</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Inactive" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Delete");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <DangerousIcon color={"error"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Delete Business</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "New" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Activate");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <CheckCircleIcon color={"success"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Activate Business</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "New" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Delete");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <DangerousIcon color={"error"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Delete Business</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Edited" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Approve");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <CheckCircleIcon color={"success"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Approve Changes</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
            {currentTab == "Edited" && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClick("Decline");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <DangerousIcon color={"error"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Decline Changes</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
              </>
            )}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
