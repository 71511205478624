import React, { useState, useEffect } from "react";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import DateFormatter from "../../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";

const OutgoingStockOrder = () => {
  const navigate = useNavigate();
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

console.log(pageState?.data);


  const stockOrderColumn = [
    {
      field: "purchaseOrderNumber",
      headerName: "Order No",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Order No"}</span>;
      },
      renderCell: (params) => {
        return (
          <span onClick={() => navigate(`/receiveStock/newStock?${params?.row?.id}?${params?.row?.purchaseOrderNumber}`)} style={{ color: "red", cursor: "pointer" }}>
            {params?.row?.purchaseOrderNumber}
          </span>
        );
      },
    },
    {
      field: "storeName",
      headerName: "Store Name",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Store Name"}</span>;
      },
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Supplier Name"}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Date Created"}</span>;
      },
    },

    {
      field: "recieverStatus",
      headerName: "Status",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Status"}</span>;
      },
      renderCell: (params) => {
        return <span style={params?.row?.recieverStatus === "PENDINGDISPATCH" ? { backgroundColor: "#f5f5f5", color: "#707070", padding: "5px", borderRadius: "10px", fontSize: "10px", fontFamily: "Poppins", cursor: "pointer" } : params?.row?.recieverStatus === "RECEIVED" ? { backgroundColor: "rgba(16, 185, 129, 0.12)", color: "#10b981", padding: "5px", borderRadius: "10px", fontSize: "10px", fontFamily: "Poppins", cursor: "pointer" } : { backgroundColor: "#f5f5f5", color: "#f79009", padding: "5px", borderRadius: "10px", fontSize: "10px", fontFamily: "Poppins", cursor: "pointer" }}>{params?.row?.recieverStatus === "PENDINGDISPATCH" ? "Sent to Supplier" : params?.row?.recieverStatus === "RECEIVED" ? "Recieved" : "Partial Delivery"}</span>;
      },
    },
  ];

  const stockProducts = pageState?.data?.map((stock) => ({
    id: stock._id,
    purchaseOrderNumber: stock?.purchaseOrderNumber,
    supplierName: stock?.supplierName,
    createdAt: DateFormatter(stock?.createdAt),
    recieverStatus: stock?.recieverStatus,
    storeName:stock?.storeName
  }));

  useEffect(() => {
    const stockfunctions = new StockFunctions();
    stockfunctions.getAllPurchaseOrders(pageState.page, dataGridPageSize, "outgoing").then((data) => {
      if (data?.status === 200) {
        setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count });
      }
    });
  }, []);

  // receiveStock

  return (
    <div>
      <CustomDataGrid customStyles={{ border: "none", height: "400px", width: "100%", margin: "0% 0% 1% 0%" }} column={stockOrderColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={stockProducts} />
    </div>
  );
};

export default OutgoingStockOrder;
