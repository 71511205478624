import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FeesInvoiceForms from "./FeesInvoiceForms";
import ServicesInvoiceForm from "./ServicesInvoiceForm";
import CustomSearchInput from "./CustomSearchInput";
import CustomSelectField from "./CustomSelectField";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Link, useNavigate } from "react-router-dom";
import deactivateUser from "../../common/images/deactivate-user.svg";
import Table from "@mui/material/Table";
import Checkbox from "@mui/material/Checkbox";
import HttpComponent from "./MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useSelector, useDispatch } from "react-redux";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { changeTab } from "../../features/tabSlice";
// import InputLabel from "@mui/material/InputLabel";

let baseUrl = process.env.REACT_APP_BASE_URL;



const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Invoices
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Generate Invoice
  </Typography>,
];

const GenerateSchoolInvoices = () => {
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({ renderInvoiceType: "Fees", grade: "", search: "", courseName: ""});
  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [viewStudents, setViewStudents] = useState(true);
  const [selected, setSelected] = useState([]);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [services, setServices] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(students.map((student) => student.studentId)); // select all rows
    } else {
      setSelected([]); // unselect all rows
    }
  };

  const handleSelectRowClick = (event, id) => {
    if (event.target.checked) {
      setSelected([...selected, id]); // add row to selection
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== id)); // remove row from selection
    }
  };

  useEffect(() => {
    console.log("students", [...selected]);
  }, [selected]);

  const [students, setStudents] = useState([]);
  const [studentsToShow, setStudentsToShow] = useState([]);

  //console.log(selected , 'selected')


  const fetchStudents = () => {
    let url 
    if(schoolTypeName?.includes('University')){
      url = `/api/get_students_by_grade?grade=${formData.courseName}&page=1&limit=10000`
    }else{
      url = `/api/get_students_by_grade?grade=${formData.grade}&page=1&limit=10000`
    }
    HttpComponent({
      method: "GET",
      url: url,
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          setStudents(data?.response?.data);
        } else {
          console?.error("Error setting info");
          // setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console?.error(error?.message);
      });
  };
  
  useEffect(() => {
    fetchStudents();
  }, [formData.grade || formData.courseName]);

  const setData = () => {
    let searchable = [...students];
    if (formData.search !== "") {
      let result = searchable.filter(
        (student) =>
          student.firstName.toLowerCase().includes(formData.search) ||
          student.lastName.toLowerCase().includes(formData.search) ||
          student.itemNumber.toLowerCase().includes(formData.search)
      );
      console.log("search", result);
      result = result.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      setStudentsToShow(result);
    } else {
      setStudentsToShow(
        students.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      );
    }
  };

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setData();
    console.log("search", formData.search);
  }, [formData.search, students, currentPage]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();

  const nextHandler = () => {
    setViewStudents(false);
  };

  const generateInvoices = async () => {
    let body = {
      type: "one-off",
      frequency: "once",
      services: [...services],
      students: [...selected],
    };
    HttpComponent({
      method: "POST",
      url: "/api/generate_invoice_batch",
      body: body,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          console.log("success confirmed");
          navigate("/school/invoices/pending", { state: { data: "created" } });
          dispatch(changeTab("PENDING"));
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const [gradeOptions, setGradeOptions] = useState([])

  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const {schoolTypeName, schoolTypeId} = useSelector((store) => store.schoolType.schoolTypeDetail)



  
  // const GetSchoolTypeIdForBusiness = async () => {
  //   const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "X-Authorization": X_Authorization,
  //     }
  //   });
  //   const data = await response.json();
  //   console.log(data, 'School Type Id');
  //   if (response.status === 201) {
  //     setSchoolTypeId(data.data?._id);
  //     setSchoolTypeName(data.data?.schoolType);

  //   }
  // }
  // useEffect(() => {
  //   GetSchoolTypeIdForBusiness();
  // }, [])
  console.log('School Type Id', schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();
    

    setGradeOptions(data?.data?.filter((item)=>item?.schoolGrades !== 'COMPLETED').map((itemGrade) => {
      return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
    }
    ))
  };

  console.log(gradeOptions, "Grades");
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatus, setBoardingStatus] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatus(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])


  const itemsPerPage = 6;
  const [coursesOptions, setCoursesOptions] = useState([]);

  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses");
    if (response.status === 200) {
      // setCoursesOptions(data.data);
      setCoursesOptions(data?.data?.map((courses) => {
        return { value: courses.courseName, label: courses.courseName }
      }
      ))
    }
  };

  useEffect(() => {
    getCourses();
  }, []);
  return (
    <Box component="div" sx={{ width: "100%" }}>
      <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={() => setErrorShow({ ...errorShow, state: false })}
        open={errorShow.state}
        message={errorShow.message}
      />
      <Box sx={{ display: "flex", margin: 1, justifyContent: "space-between" }}>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}
          >
            Generate Invoices
          </Typography>
        </Box>
      </Box>
      <Box component="div" sx={{ margin: 1 }}>
        <Breadcrumbs
          separator={
            <FiberManualRecordIcon
              sx={{ fontSize: "0.625rem", color: "#e1e5e8" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginX: 1,
          marginY: 2,
        }}
      >
        <Typography sx={{ color: "#707070", fontSize: "1rem" }}>
          Generate Invoices:
        </Typography>
        <Box sx={{ marginTop: 2, marginX: 1 }}>
          <RadioGroup
            name="renderInvoiceType"
            value={formData.renderInvoiceType}
            onChange={handleInputChange}
            row
          >
            <FormControlLabel
              value="Fees"
              control={
                <Radio
                  sx={{
                    color: "#dc3545",
                    "&.Mui-checked": {
                      color: "#dc3545",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#707070",
                    "&.Mui-checked": {
                      color: "#707070",
                    },
                  }}
                >
                  {schoolTypeName?.includes("University") ? "Course" :  "Grade"}
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ marginLeft: "5%" }}
              value="Services"
              control={
                <Radio
                  sx={{
                    color: "#dc3545",
                    "&.Mui-checked": {
                      color: "#dc3545",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#707070",
                    "&.Mui-checked": {
                      color: "##707070",
                    },
                  }}
                >
                  Student
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      </Box>

      <Box component="div" sx={{ display: "flex", width: "100%" }}>
        {/*    render */}
        <Box
          component="div"
          sx={{
            display: "flex",
            width: formData.renderInvoiceType === "Fees" ? "40%" : 0,
          }}
        >
          {formData.renderInvoiceType === "Fees" ? <FeesInvoiceForms /> : null}
        </Box>

        {/*:formData.renderInvoiceType === "Services" ?*/}
        {formData.renderInvoiceType === "Services" && !viewStudents && (
          <ServicesInvoiceForm
            generateInvoices={generateInvoices}
            services={services}
            setServices={setServices}
            grade={formData.grade}
          />
        )}

        <Box
          component="div"
          sx={{
            display: "flex",
            width:
              formData.renderInvoiceType === "Services" && viewStudents
                ? "100%"
                : 0,
          }}
        >
          {formData.renderInvoiceType === "Services" && viewStudents && (
            <>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <CustomSearchInput
                      sm
                      name={"search"}
                      value={formData.search}
                      placeholder={"Search:"}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      marginLeft: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {schoolTypeName?.includes("University") ? <CustomSelectField
                      sm
                      value={formData.courseName}
                      placeholder={"Select Course"}
                      onChange={handleInputChange}
                      name={"courseName"}
                      options={coursesOptions}
                    /> :
                      <CustomSelectField
                        sm
                        value={formData.grade}
                        placeholder={"Select Grade"}
                        onChange={handleInputChange}
                        name={"grade"}
                        options={gradeOptions}
                      />
                    }
                    {/* <FormControl style={{}}>
                      <Select labelId="Invoice Status" id="year-select" value={formData.grade === '' ? "Select Grade" : formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} style={{ width: "400px" }} label="Year" >
                        <MenuItem value={"Select Grade"} disabled>
                          Select Grade
                        </MenuItem>
                        {gradeOptions.map((item) => {
                          return (
                            <MenuItem value={item}>{item}</MenuItem>
                          )
                        })}

                      </Select>
                    </FormControl> */}
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      marginLeft: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {/*<Button sx={{*/}
                    {/*    backgroundColor: "#032541",*/}
                    {/*    width: "141px",*/}
                    {/*    height: "3.438rem",*/}
                    {/*    marginBottom: 1,*/}
                    {/*    color: "white",*/}
                    {/*    '&:hover': {*/}
                    {/*        backgroundColor: '#032541',*/}
                    {/*        color: "white"*/}
                    {/*    }*/}
                    {/*}} onClick={generateInvoices}>*/}
                    {/*    GENERATE*/}
                    {/*</Button>*/}
                    <Button
                      disabled={selected?.length < 1}
                      sx={{
                        backgroundColor: "#032541",
                        width: "141px",
                        height: "3.438rem",
                        marginBottom: 1,
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#032541",
                          color: "white",
                        },
                      }}
                      onClick={nextHandler}
                    >
                      NEXT
                    </Button>
                  </Box>
                </Box>

                {/*    table starts*/}
                <div style={{ minHeight: "400px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selected.length === students.length}
                            onChange={handleSelectAllClick}
                          />
                        </TableCell>
                        <TableCell>Admission No</TableCell>
                        <TableCell>Student Name</TableCell>
                        <TableCell> {schoolTypeName?.includes('University') ? "Course" :"Grade"} </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentsToShow.length > 0 ?
                        studentsToShow?.map((student) => (
                          <TableRow
                            hover
                            key={student?.studentId}
                            selected={selected?.includes(student?.studentId)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selected?.includes(student?.studentId)}
                                onChange={(event) =>
                                  handleSelectRowClick(
                                    event,
                                    student?.studentId
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>{student?.itemNumber}</TableCell>
                            <TableCell>
                              {student?.firstName} {student?.lastName}
                            </TableCell>
                            <TableCell>{student?.grade}</TableCell>
                          </TableRow>
                        )) : <TableRow>
                          <TableCell colSpan={4} align="center">
                            <span style={{ color: "#03243F", textAlign: "center" }}>Select Grade to View Student</span>
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    disabled={currentPage === 1}
                    onClick={handlePrevClick}
                    startIcon={<NavigateBefore />}
                    sx={{
                      marginTop: "10px",
                      height: "40px",
                      // padding: "5px",
                    }}
                  >
                    Previous
                  </Button>
                  {currentPage > 2 && (
                    <Button
                      variant="outlined"
                      onClick={() => setCurrentPage(1)}
                      sx={{
                        marginTop: "10px",
                        height: "40px",
                        // padding: "5px",
                      }}
                    >
                      1
                    </Button>
                  )}
                  {currentPage > 3 && <p>...</p>}
                  {currentPage > 1 && (
                    <Button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      sx={{
                        marginTop: "10px",
                        height: "40px",
                      }}
                    >
                      {currentPage - 1}
                    </Button>
                  )}
                  <Button
                    sx={{
                      marginTop: "10px",
                      height: "40px",
                      backgroundColor: "#e1e5e8",
                      border: "1px solid #e1e5e8",
                      color: "#032541",
                      "&:hover": {
                        backgroundColor: "#e1e5e8",
                        color: "#032541",
                      },
                    }}
                  >
                    {currentPage}
                  </Button>
                  {currentPage < Math.ceil(students.length / itemsPerPage) && (
                    <Button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      sx={{
                        border: "1px solid #e1e5e8",
                        marginTop: "10px",
                        height: "40px",
                      }}
                    >
                      {currentPage + 1}
                    </Button>
                  )}
                  {currentPage <
                    Math.ceil(students.length / itemsPerPage) - 2 && <p>...</p>}
                  {currentPage <
                    Math.ceil(students.length / itemsPerPage) - 1 && (
                      <Button
                        onClick={() =>
                          setCurrentPage(
                            Math.ceil(students.length / itemsPerPage)
                          )
                        }
                        sx={{
                          border: "1px solid #e1e5e8",
                          marginTop: "10px",
                          height: "40px",
                        }}
                      >
                        {Math.ceil(students.length / itemsPerPage)}
                      </Button>
                    )}
                  <Button
                    disabled={
                      currentPage === Math.ceil(students.length / itemsPerPage)
                    }
                    onClick={handleNextClick}
                    endIcon={<NavigateNext />}
                    sx={{
                      marginTop: "10px",
                      height: "40px",
                    }}
                  >
                    Next
                  </Button>
                </div>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GenerateSchoolInvoices;
