import { Grid, Breadcrumbs, Typography, Button, FormControl, MenuItem, InputLabel, Select } from '@mui/material'
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useEffect, useState } from 'react';
import CustomInputLabel from '../../../School/CustomInputLabel';
import CustomTextField from '../../../School/CustomTextField';
import HttpComponent from '../../../School/MakeRequest';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Products
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Edit Product
    </Typography>
];
export default function PartnerEditProduct() {
    const [formData, setFormData] = useState({ productName: "", productCategory: "", productPrice: '' })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate()
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, id, businessId] = newqueryParamValue.split("?")
    const [productCategory, setProductCategory] = useState([])


    // handle input change

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }
    // get product categories

    function fetchProductCategories() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listCategories/Active?productService=Product&businessNumber=${businessId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setProductCategory(data?.response?.data?.filter((item) => item?.categoryName !== 'Zpm Activation Fee'))
                }
                console.log(data, 'product categoriess')
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProductCategories()
    }, [businessId])

    const productCa = productCategory?.map((option) => (option.categoryName))

    // get product id to edit

    function fetchProductToEdit() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/findProduct/${id}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        productName: data?.response?.data?.productName,
                        productCategory: data?.response?.data?.productCategory,
                        productPrice: data?.response?.data?.productPrice,
                    })
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProductToEdit();
    }, [id])

    function updateProduct() {
        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/updateProduct/${id}`,
                body: {
                    productName: formData.productName,
                    productCategory: formData.productCategory,
                    productPrice: formData.productPrice,
                    productState: 'Active',
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 202) {
                    setSuccessShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000)
                } else {
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000)
                }
            })
        } catch (error) {

        }
    }





    return (
        <Grid container display={'flex'} direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'} marginBottom={'2px'} gap={1}>
                <ArrowBackIosNewIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}> Edit Product</Typography>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Edit Product Details</Typography>
            </Grid>
            <Grid item width={'31rem'}>
                <CustomInputLabel required={true} label={"Product Name"} />
                <CustomTextField value={formData.productName} large={'100%'} onChange={handleInputChange} name={"productName"} placeholder={"Product Name"} />
            </Grid>
            <Grid item width={'31rem'}>
                <CustomInputLabel required={true} label={"Amount"} />
                <CustomTextField value={formData.productPrice} large={'100%'} onChange={handleInputChange} name={"productPrice"} placeholder={"Amount"} />
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <FormControl sx={{ width: '31rem' }}>
                    <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                    <Select
                        label="Select Category"
                        value={formData.productCategory}
                        name={"productCategory"}
                        onChange={handleInputChange}
                    >
                        {productCa?.map((option) => {
                            return (
                                <MenuItem value={option}>{option}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item width={'31rem'} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={updateProduct} disabled={!formData.productCategory || !formData.productName || !formData.productPrice} style={!formData.productCategory || !formData.productName || !formData.productPrice ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}