/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Select, MenuItem, InputLabel, Typography, FormControl, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/partnerToUpdateSlice";
import { handleAdded } from "../../../../features/AlertSlice";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import HttpComponent from "../../../School/MakeRequest";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

const baseUrl = process.env.REACT_APP_BASE_URL;

function AddPartner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  //pulling values from redux to set initial values of form inputs
  //   const reduxName = useSelector((state) => state.bankDetails.name);
  //   const reduxDescription = useSelector((state) => state.bankDetails.description);

  //value to set when user edits form
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [partnerBusinessesType, setPartnerBusinessesType] = useState('')
  const [insuranceCompany ,setInsuranceComapny] = useState('') 
  const [insuranceList ,setInsuranceList] = useState([])

  const [insurancecompanies ,setInsuranceComapines] = useState([])
  const [isLoading , setIsLoading] = useState(true)

  function getInsuranceCompanies() {
    setIsLoading(true)
    try {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/getinsurance-company/ACTIVE`,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            if (data?.status === 200 || data?.status === 201) {
              setInsuranceComapines(data?.response?.data)
              setIsLoading(false)
            }else{
              setIsLoading(false)
            }
        })
    } catch (error) {
     
    }
}

useEffect(()=>{
  getInsuranceCompanies()
},[])


  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});
  const [failMessage, setFailMessage] = useState(null);

  const handleAdd = () => {
    try {
      axios
        .post(baseUrl + "/api/AddPartner", {
          name,
          description,
          partnerBusinessesType,
          insuranceCompany
        },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.data
          if (data) {
            console.log("added successfully");
            setLocalDetails({
              name: name,
              description: description,
              partnerBusinessesType: partnerBusinessesType,
              insuranceCompany:insuranceCompany
            });
            dispatch(handleAdded(true))
          } else {
            setFailMessage(response.data.message)
          }
        });
    } catch (e) {
      console.log(e)
    }
  };

  const [businessTypes, setBussinessType] = useState([])
  const getBusinessTypes = () => {
    HttpComponent({
      method: 'GET',
      url: '/api/listBusinessCategory',
      body: null,
      token: localStorage.getItem('X-Authorization'),
    }).then((data) => {
      console.log(data, 'businessTypes')
      setBussinessType(data?.response?.category)
    }).catch((error) => {
      console.error(error.message);
    })
  }

  useEffect(() => {
    getBusinessTypes()
  }, [])

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(setDetails(localDetails));
      navigate("/Partners");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  console.log(localDetails , 'localDetails')

  const [showFailedAlert, setShowFailedAlert] = useState(false);


  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Add Partner
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2 point1={"Partners"} link1={"/Partners"} point2={"Add Partner"} />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Add Partner Details
            </Typography>
            <TextField
              label="Partner Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
              autoFocus
              className={classes.focusedTextField}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDescription}
              className={classes.focusedTextField}
            />
            <Grid item width={'100%'}>
              <FormControl size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={partnerBusinessesType} label="Business Type" onChange={(e) => setPartnerBusinessesType(e.target.value)}>
                  {businessTypes?.filter((bs) => bs?.categoryName === 'School/University/College' || bs?.categoryName === 'Transport' || bs?.categoryName === 'FMCG')?.map((acc) => {
                    return <MenuItem value={acc?.categoryName}>{acc?.categoryName}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            {partnerBusinessesType === 'Transport' ?
              <Grid item width={'100%'}>
                <FormControl size="small" style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-label">Select Insurance Company</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={insuranceCompany} label="Select Insurance Company" onChange={(e) => setInsuranceComapny(e.target.value)}>
                    {insurancecompanies?.map((acc) => {
                      return <MenuItem value={acc?.name}>{acc?.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid> : null
            }
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate('/Partners')}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddPartner;
