import { Breadcrumbs, Button, FormControl, Grid, InputLabel, OutlinedInput, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import HttpComponent from "../../../components/School/MakeRequest";
import { SuccessAlert } from "../../../components/snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../components/snackBar Alerts/errorAlert";

 const breadcrumbs = [
     <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
     <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} color="text.inactive" key="3">Banks</Typography>,
     <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.error">Edit Bank</Typography>
 ]


export default function EditBanks(props){

   const [formData ,setFormData] = useState({bankName:"" , description:""})
   const [successShow, setSuccessShow] = useState({state: false, message: ""})
   const [errorShow, setErrorShow] = useState({state: false, message: ""})

   //handle onChange

   const handleOnChange =(e)=>{
    setFormData({
        ...formData , 
        [e.target.name]:e.target.value
    })

   }

   //bank to update

    function fetchBankToUpdate() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_bank_by_id?bankId=${props?.id}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        description: data?.response?.data?.description,
                        bankName: data?.response?.data?.name
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    //Effect to run when  when page loads

    useEffect(()=>{
        fetchBankToUpdate()
    },[])

   //update on edit

   const handleUpdate =()=>{
       try {
          HttpComponent({
            method:"PUT",
            url:`/api/updatebankDetail/${props?.id}`,
            body:{
                description:formData.description,
                name:formData.bankName
            },
            token:localStorage.getItem('X-Authorization')
          }).then((data)=>{
            console.log(data , 'updated bank')
            if(data.status === 200){
                setSuccessShow({state:true , message:"Bank details successfully updated"})
                setTimeout(()=>{
                    window.location.reload()
                },[1000])
            }else{
                setErrorShow({state:true , message:"Error updating bank details"})
            }
          })
       } catch (error) {

       }

   }

    return(
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mb={3} display={'flex'} alignItems={'center'} alignContent={'center'}>
                  <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={props?.back}/>
                  <Typography style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Edit Banks</Typography>
            </Grid>
          
            <Grid mb={3} item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item> 
                <Typography style={{fontSize:"16px" , color:"#032541", fontWeight:500}}>Add Bank Details</Typography>
            </Grid>
            <Grid item mt={3}>
                <FormControl sx={{ width: '40ch' }} variant="outlined">
                    <InputLabel style={{ display: "flex", alignContent: "center", alignItems: "center" }} htmlFor="outlined-adornment-password">Bank Name <span style={{ color: "#dc3545" }}>*</span></InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type='text'
                        value={formData.bankName}
                        onChange={handleOnChange}
                        name="bankName"
                        label="Bank Name"
                    />
                </FormControl>
            </Grid>
            <Grid item mt={3}>
                <FormControl sx={{ width: '40ch' }} variant="outlined">
                    <InputLabel style={{ display: "flex", alignContent: "center", alignItems: "center" }} htmlFor="outlined-adornment-password">Description</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type='text'
                        value={formData.description}
                        name="description"
                        onChange={handleOnChange}
                        label="Description"
                    />
                </FormControl>
            </Grid>
            <Grid item mt={2} sx={{ width: '40ch' }} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                <Button onClick={props?.back} style={{width: "141px",marginRight:"10px" ,color:"#cb444a",textTransform:"inherit", border:"2px solid #cb444a", height:"45px"}}>Cancel</Button>
                <Button onClick={handleUpdate} disabled={!formData?.bankName || !formData?.description} style={{width: "141px",textTransform:"inherit" ,background:"#032541",color:"#fff", height:"45px", opacity:!formData.bankName || !formData.description ? '0.2':'1'}}>Update</Button>
            </Grid>
        </Grid>
    )
}