import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import HttpComponent from "../../../School/MakeRequest";

const AddFeeConfig = () => {
  const navigate = useNavigate();
  const [feeConfigData, setFeeConfig] = useState({ zedshare: "", partnershare: "", feeshare: "" });
  const [activateButton, setActivateButton] = useState(false);

  const [delimiter, queryParams, queryId, feeshare, zedshare, partnershare] = decodeURIComponent(window.location.search).split("?");

  const getInputData = (e) => {
    const value = e?.target?.value;
    const name = e?.target?.name;
    setFeeConfig({ ...feeConfigData, [name]: value * 1 });
  };

  useEffect(() => {
    if (feeConfigData.zedshare > 0 && feeConfigData.partnershare > 0 && feeConfigData.feeshare > 0) {
      setActivateButton(true);
    } else {
      setActivateButton(false);
    }
  }, [feeConfigData.feeshare, feeConfigData.partnershare, feeConfigData.zedshare]);

  //   handle modal;
  const [opensucess, setOpenSuccess] = useState({ message: "", state: false });
  const [openerror, setOpenError] = useState({ message: "", state: false });
  const [editId, setEditId] = useState("");

  useEffect(() => {
    setEditId(queryId);
    setFeeConfig({ ...feeConfigData, feeshare: feeshare, zedshare: zedshare, partnershare: partnershare });
  }, [queryId]);
  const closeSuccess = () => {
    setOpenSuccess(false);
  };

  const closeError = () => {
    setOpenError(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (opensucess.state) {
        closeSuccess();
      }
      if (openerror.state) {
        closeError();
      }
    }, 3000);
  }, [opensucess.state, openerror.state]);
  const doAddConfig = () => {
    if (feeConfigData.partnershare * 1 + feeConfigData.zedshare * 1 !== 100) {
      setOpenError({ ...openerror, message: "Zed Share and Partner Share should add to 100", state: true });
      return;
    }
    if (feeConfigData.feeshare < 1 || feeConfigData.feeshare > 99) {
      setOpenError({ ...openerror, message: "Fee share should be between ( 1 - 99 )", state: true });
      return;
    }

    HttpComponent({
      method: `POST`,
      url: `/api/v1/merchantfeesetup`,
      body: queryParams === "addconfig" ? { ...feeConfigData, type: "create" } : { ...feeConfigData, type: "edit", configId: editId },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setOpenSuccess({ ...opensucess, state: true, message: editId ? "Config Edited Successfully" : "Config Added Successfully" });

        setTimeout(() => {
          navigate(`/admin/merchantfeeconfig`);
        }, 3000);
      } else {
        setOpenError({ ...openerror, state: true, message: data?.response?.message });
      }
    });
  };
  return (
    <div>
      {/* snackbars */}
      <ErrorAlert vertical={"top"} horizontal={"right"} onClose={closeError} open={openerror.state} message={openerror.message} />
      <SuccessAlert vertical={"top"} horizontal={"right"} onClose={closeSuccess} open={opensucess.state} message={opensucess.message} />
      <Grid container direction={"column"} width={"100%"}>
        <Grid item mb={6} mt={3}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}>
            <ArrowBackIos onClick={() => navigate(-1)} />
            <Typography variant="h5" sx={{ color: "#032541", fontWeight: "600" }}>
              {" "}
              Add Transaction Fee
            </Typography>
          </Box>
        </Grid>

        <Grid item mb={2} width={"100%"}>
          <Box sx={{ margin: "0px 0px 35px 0px", width: "100%" }}>
            <TextField value={feeConfigData.feeshare} onChange={getInputData} sx={{ width: "35%" }} id="outlined-basic" name={"feeshare"} label="Enter Fees(%)" variant="outlined" />
          </Box>

          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <TextField value={feeConfigData.zedshare} onChange={getInputData} sx={{ width: "35%" }} id="outlined-basic" name={"zedshare"} label="Enter Zed Share(%)" variant="outlined" />
          </Box>

          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <TextField value={feeConfigData.partnershare} onChange={getInputData} sx={{ width: "35%" }} id="outlined-basic" name={"partnershare"} label="Enter Partner Share (%)" variant="outlined" />
          </Box>

          <Box sx={{ width: "35%", display: "flex", gap: "30px", alignItems: "center", justifyContent: "flex-end" }}>
            <Button style={{ border: "1px solid #dc3545", color: "#dc3545", padding: "10px 50px 10px 50px", opacity: activateButton ? 1 : 0.3 }}>Cancel </Button>
            <Button onClick={() => doAddConfig()} style={{ backgroundColor: "#032541", color: "white", padding: "10px 50px 10px 50px", opacity: activateButton ? 1 : 0.3 }}>
              {editId ? "Edit" : "Add"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddFeeConfig;
