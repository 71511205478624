import { Grid, Breadcrumbs, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";


//breadcrumbs
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed Pocket Money</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Terminals</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Terminal</Typography>
]


export default function EditTerminal(props) {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormData] = useState({ serialNo: "", deviceModel: "", simCardNo: "" , alias:'' })
    const [outlets, setOutlets] = useState([])
    const [deviceModels, setDeviceModels] = useState([])
    const businessGroup = localStorage.getItem('group')

    const navigate = useNavigate();

    //handle onchange

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
console.log(formData , 'formdata')
    //fetch outlets
    function getOutlets() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/list_all_stores_by_type_status?storeType=outlet&status=ACTIVE&page&limit=`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setOutlets(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOutlets()
    }, [])

    //fetch device models
    function getDeviceModel() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getBusinessModels`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setDeviceModels(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDeviceModel()
    }, [])

    //fetch terminal to update

    function fetchTerminalToUpdate() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getTerminalsById?terminalID=${props?.terminalId}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'tttt')
                if (data?.status === 200) {
                    setFormData({
                        ...formData,
                        serialNo: data?.response?.data?.terminalSerialNumber,
                        deviceModel: data?.response?.data?.deviceModel,
                        simCardNo:data?.response?.data?.simCardNumber,
                        alias:data?.response?.data?.alias,
                    })
                }
            })

        } catch (error) {

        }

    }

    useEffect(()=>{
        fetchTerminalToUpdate()
    },[])
    console.log(formData.alias , 'alais')

    //update terminal

    function handleUpdateTerminal() {
        try {
            HttpComponent({
                method:"PUT",
                url:`/api/v1/editTerminalById?terminalID=${props?.terminalId}`,
                body:{
                    terminalSerialNumber:formData.serialNo,
                    deviceModel: formData.deviceModel,
                    simCardNumber:formData.simCardNo,
                    alias:formData.alias
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({state:true , message:'updated terminal details successfully'})
                }else{
                    setErrorShow({state:true , message:'Error updating terminal'})
                }
                setTimeout(()=>{
                    navigate(-1)
                },[1000])
            })
        } catch (error) {
            setErrorShow({state:true , message:error})
        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Terminal</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Edit Terminal Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='serialNo' onChange={handleOnChange} style={{ width: "100%" }} disabled={businessGroup !== 'Admin'} value={formData.serialNo} label="Serial No" variant="outlined" />
                    </Grid>
                    <Grid item spacing={2} style={{ width: "50%" }}>
                        <TextField id="outlined-basic" name='simCardNo' onChange={handleOnChange} style={{ width: "100%" }} value={formData.simCardNo} label="Sim Card No" variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item  mt={3} spacing={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name='alias' onChange={handleOnChange} style={{ width: "98%" }} value={formData.alias} label="Alias" variant="outlined" />
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                    <Grid item style={{ width: "100%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Device Model</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData?.deviceModel}
                                label="Device Model"
                                onChange={handleOnChange}
                                name="deviceModel"
                            >
                                {deviceModels?.map((item) => (
                                    <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.deviceName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item style={{ width: "50%" }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData?.outletName}
                                label="Outlet"
                                onChange={handleOnChange}
                                name="outletName"
                            >
                                {outlets?.map((item) => (
                                    <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid mt={3} spacing={2} item style={{ width: "49%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleUpdateTerminal} style={!formData.serialNo || !formData.deviceModel ||!formData.simCardNo? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Update</Button>
            </Grid>
        </Grid>
    )
}