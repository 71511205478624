import { Box, Breadcrumbs, Button, Grid, List, ListItem, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Switch from "@mui/material/Switch";
import HttpComponent from "../School/MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ArrowBackIos, EditOutlined } from '@mui/icons-material';
import CustomInputLabel from "../School/CustomInputLabel";
import CustomTextField from "../School/CustomTextField";
import EcommerceIcon from "../../common/images/ecommerce.svg";

const zedAccountingmodal = {
  borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 2,
}
const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"students"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Shopify Config
  </Typography>
];
const breadcrumbsConfig = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Configuration
  </Typography>,
  <Typography key={"students"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Enable Shopify
  </Typography>
]; const ShopifyConfig = () => {
  const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
  const [errorNotificationOpen, setErrorNotificationOpen] = useState(false);
  const [shopifyStatus, setShopifyStatus] = useState(false)
  const [openShopifyModal, setOpenShopifyModal] = useState(false)
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    shopify_api_key: "",
    shopify_api_secret: "",
    store_name: "",
    store_url: ""
  })
  const handleOpenShopifyModal = () => { setOpenShopifyModal(true) }
  const handleCloseShopifyModal = () => {
    setOpenShopifyModal(false)
    setFormData({
      shopify_api_key: "",
      shopify_api_secret: "",
      store_name: "",
      store_url: ""
    })
    setShowConfigurations(false)
  }
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [showConfigurations, setShowConfigurations] = useState(false);

  const handleShopifyStatus = () => {
    try {
      HttpComponent({
        method: "GET",
        url: '/api/v1/check_shopify_status',
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        // console.log(data, 'payables status')
        if (data.status === 200) {
          console.log(data?.response?.data, 'shopify status')
          let shopifyStatus = data?.response?.data
          // setShopifyStatus(JSON.parse(shopifyStatus));
          if (shopifyStatus === 'true' || shopifyStatus === true) {
            setShopifyStatus(true)
          } else {
            setShopifyStatus(false)
          }
        }
      })
    } catch (error) {
      console.log(error)

    }
  }


  useEffect(() => {
    handleShopifyStatus()
  }, [])

  const switchShopifyStatus = () => {
    if (shopifyStatus) {

    } else {
      // handleOpenShopifyModal()
      setShowConfigurations(true)
    }

  }

  const handleFormDataChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }





  const handleSaveConfigurations = () => {
    console.log(formData, 'save shopify config')
    if (formData.shopify_api_key === "") {
      return setErrorShow({ state: true, message: 'Shopify Api Key is required' })
    }
    if (formData.shopify_api_secret === "") {
      return setErrorShow({ state: true, message: 'Shopify Api Secret is required' })

    }
    if (formData.store_name === "") {
      return setErrorShow({ state: true, message: 'Store Name is required' })
    }
    if (formData.store_url === "") {
      return setErrorShow({ state: true, message: 'Store Url is required' })
    }

    // confirm store url is a valid url
    const url = formData.store_url
    const urlRegex = new RegExp(/^(http|https):\/\/[^ "]+$/);
    if (!urlRegex.test(url)) {
      return setErrorShow({ state: true, message: 'Store Url is not a valid url' })
    }

    handleOpenShopifyModal()




  }


  const enableShopify = () => {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/v1/activate_shopify',
        token: localStorage.getItem('X-Authorization'),
        body: formData
      }).then((data) => {
        console.log(data, 'save shopify config')
        if (data.status === 200) {
          console.log(data?.response?.message, 'data')
          let message = data?.response?.message
          setSuccessShow({ state: true, message: message });
          setShowConfigurations(false)
          setShopifyStatus(true)
          handleCloseShopifyModal()
          window.location.reload()
        }
        else {
          console.log(data?.response?.message, 'data')
          let message = data?.response?.message
          setErrorShow({ state: true, message: message });

        }
      })
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div>
      {showConfigurations ?
        <>
          <Grid>
            <Box style={{ display: "flex", }}>
              <Box style={{ display: "flex" }}>
                <ArrowBackIos className='' />
                <h1 style={{ color: "#032541", fontSize: "25px", fontWeight: "600", marginTop: "-10px" }}>E-commerce Configuration</h1>
              </Box>

            </Box>
            <div className=''>
              <Box component={'nav'} aria-label="breadcrumb">
                <Breadcrumbs
                  separator={
                    <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                  }
                  aria-label="breadcrumb"
                >
                  {breadcrumbsConfig}
                </Breadcrumbs>
              </Box>
            </div>
          </Grid>
          <Box>
            <CustomInputLabel required={true} label={"Shopify Api Key"} />
            <CustomTextField value={formData.shopify_api_key} onChange={handleFormDataChange} name={"shopify_api_key"} placeholder={"Shopify Api Key"} />
            <CustomInputLabel required={true} label={"Shopify Api Secret "} />
            <CustomTextField value={formData.shopify_api_secret} name={"shopify_api_secret"} onChange={handleFormDataChange} placeholder={"Shopify Api Secret"} />
            <CustomInputLabel required={true} label={"Store Name"} />
            <CustomTextField value={formData.store_name} name={"store_name"} onChange={handleFormDataChange} placeholder={"Store Name"} />
            <CustomInputLabel required={true} label={"Store Url"} />
            <CustomTextField value={formData.store_url} name={"store_url"} onChange={handleFormDataChange} placeholder={"Store Url"} />
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", width: "400px" }}>
            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={() => setShowConfigurations(false)}>Cancel</Button>
            <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#022541", color: "#fff", marginLeft: "40px" }} onClick={() => handleSaveConfigurations()}>Save</Button>
          </Box>



        </>
        :
        <Grid container item className={"container-fluid"}>
          {/*Page Title Container*/}
          <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
            <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Zed E-commerce Configuration</label>
          </Grid>

          {/*Page Sub title and Search */}
          <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
            <div className=''>
              <Box component={'nav'} aria-label="breadcrumb">
                <Breadcrumbs
                  separator={
                    <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                  }
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Box>
            </div>

            {/*Search input*/}
            <Grid item>
              <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
              <TextField
                size="small"
                placeholder={"search"}
                style={{ height: "1px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ width: "15px", height: "15px" }} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          </Grid>

          {/*Headers*/}
          <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
            <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
              Configuration
            </Grid>
            <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
              Action
            </Grid>
          </Grid>
          <Grid container style={{ width: "86%", marginTop: "30px" }}>
            <Grid item style={{ width: "100%" }}>
              <List>
                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                  <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                    <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable E-commerce</Box>
                    <Box>
                      <Switch checked={shopifyStatus} onClick={switchShopifyStatus} />
                    </Box>
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      }

      <Modal
        open={openShopifyModal} onClose={handleCloseShopifyModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
        <Box sx={zedAccountingmodal}>
          <form>
            <Box style={{ display: "flex", justifyContent: "" }}>
              <Box>
                <img src={EcommerceIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Zed E-commerce: </Box>
                <Box>
                  <p style={{ fontSize: "14px", color: "#707070", marginTop: "10px" }}>
                    Enabling Zed E-commerce will allow the business to :
                  </p>
                </Box>
                <Box style={{ display: "flex", marginTop: "10px" }}>
                  <Box style={{ display: "flex" }}>
                    <div className="image-container">
                      <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                    </div>
                    <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                      <p>Integration with other marketing channels</p>
                    </div>
                  </Box>
                </Box>
                <Box style={{ display: "flex", marginTop: "" }}>
                  <Box style={{ display: "flex" }}>
                    <div className="image-container">
                      <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                    </div>
                    <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                      <p>Variety of plug-and-play payment options at checkout.</p>
                    </div>
                  </Box>
                </Box>
                <Box style={{ display: "flex", marginTop: "" }}>
                  <Box style={{ display: "flex" }}>
                    <div className="image-container">
                      <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                    </div>
                    <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                      <p> Intuitive Interface</p>
                    </div>
                  </Box>
                </Box>
                <Box style={{ display: "flex", marginTop: "" }}>
                  <Box style={{ display: "flex" }}>
                    <div className="image-container">
                      <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                    </div>
                    <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                      <p>  Focus on solely on e-commerce</p>
                    </div>
                  </Box>
                </Box>
                <Box style={{ display: "flex", marginTop: "" }}>
                  <Box style={{ display: "flex" }}>
                    <div className="image-container">
                      <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                    </div>
                    <div className="text-container" style={{ marginLeft: "10px", color: "#707070" }}>
                      <p>  Integration with other marketing channels</p>
                    </div>
                  </Box>
                </Box>
                <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                  <Box>
                    <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                      handleCloseShopifyModal

                    }>Cancel</Button>
                  </Box>
                  <Box>


                    <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                      onClick={enableShopify}
                    >Enable</Button>

                  </Box>
                </Box>
              </Box>

            </Box>
          </form>
        </Box>
      </Modal>
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
    </div>
  )
}

export default ShopifyConfig