import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import HttpComponent from "../../../School/MakeRequest";
import { useNavigate } from "react-router-dom";
import AddFeeConfig from "./addfeeconfig";
import DateFormatter from "../../../../utils/dateFormatter";
import { DoDisturb, Edit, MoreVert, Replay } from "@mui/icons-material";
import { FeeActivationModal, FeeInActivationModal } from "./modals";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import AuthecateUser from "../../../paymentmodule/autheticatedComponent";

const Merchantfeeconfig = () => {
  const navigate = useNavigate();

  // query params
  const queryParams = decodeURIComponent(window.location.search).split("?")[1];

  // get fee configs;
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });
  const [queryValue, setQueryValue] = useState("");

  // handle auth
  const [isAuthecated, setIsAuthecated] = useState(false);
  const handleIsAuthecated = (childData) => {
    let state = childData.state;
    setIsAuthecated(state);
  };

  useEffect(() => {
    setQueryValue(queryParams);
  }, [queryParams]);
  //   arrays
  const [feeConfigs, setFeeConfigs] = useState([]);

  const getMerchantConfig = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/getmerchantfeeconfigs`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      const returnArray = [];
      returnArray.push(data.response?.data);
      setFeeConfigs(returnArray);
      setPageState({ ...pageState, isLoading: false });
    });
  };
  useEffect(() => {
    getMerchantConfig();
  }, [queryParams]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // handle modals:
  const [opendeactivate, setOpenDeactivate] = useState(false);

  const handleCloseDeactivate = () => {
    setOpenDeactivate(false);
  };

  const [openactivate, setOpenActivate] = useState(false);

  const handleOpenActivate = () => {
    setOpenActivate(false);
  };
  const renderCell = (params) => {
    const merchantId = params.row.id;
    const feeshare = params.row.feeshare;
    const partnershare = params.row.partnershare;
    const zedshare = params.row.zedshare;
    const status = params.row.status;

    return (
      <>
        <MoreVert
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            console.log(params.row);
            handleClick(e);
          }}
        />

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            style={{ display: "flex", gap: "20px", marginBottom: "5px" }}
            onClick={() => {
              handleClose();
              navigate(`/admin/merchantfeeconfig?addconfig?${merchantId}?${feeshare}?${zedshare}?${partnershare}`);
            }}
          >
            <Edit style={{ color: "#032541" }} /> <Typography> Edit </Typography>
          </MenuItem>
          <>
            {" "}
            {status === "Active" ? (
              <MenuItem
                style={{ display: "flex", gap: "20px", width: "50%" }}
                onClick={() => {
                  setConfigData({ ...editData, feeshare, partnershare, zedshare, editId: merchantId, configId: merchantId, status: "Inactive" });
                  setOpenDeactivate(true);
                }}
              >
                {" "}
                <DoDisturb style={{ color: "#dc3545" }} /> <Typography> Deactivate </Typography>{" "}
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setConfigData({ ...editData, feeshare, partnershare, zedshare, editId: merchantId, configId: merchantId, status: "Active" });
                  setOpenActivate(true);
                }}
              >
                {" "}
                <Replay style={{ color: "#17ae7b" }} /> <Typography> Activate </Typography>{" "}
              </MenuItem>
            )}{" "}
          </>
        </Menu>
      </>
    );
  };

  const feeConfigColum = [
    { field: "feeshare", headerName: "Fees (%) ", flex: 1 },
    { field: "zedshare", headerName: "Zed Share (%) ", flex: 1 },
    { field: "partnershare", headerName: "Partner Share (%) ", flex: 1 },
    { field: "createdAt", headerName: "Date Created ", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <span style={params?.row?.status === "Active" ? { color: "green" } : { color: "red" }}> {params?.row?.status}</span>;
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => {
        return renderCell(params);
      },
    },
  ];

  const feeData =
    feeConfigs?.length > 0 &&
    feeConfigs?.map((config) => ({
      id: config._id,
      feeshare: config?.feeshare,
      zedshare: config?.zedshare,
      partnershare: config?.partnershare,
      createdAt: DateFormatter(config?.createdAt),
      status: config?.status,
    }));

  //   handle modal;
  const [opensucess, setOpenSuccess] = useState({ message: "", state: false });
  const [openerror, setOpenError] = useState({ message: "", state: false });
  const [editData, setConfigData] = useState({ zedshare: "", feeshare: "", partnershare: "", editId: "" });

  const closeSuccess = () => {
    setOpenSuccess({ ...opensucess, state: false });
  };

  const closeError = () => {
    setOpenError({ ...openerror, state: false });
  };

  const deactivateConfig = () => {
    if (!editData.editId) {
      setOpenError({ ...openerror, state: true, message: "Config Id is required" });
      return;
    }

    HttpComponent({
      method: `POST`,
      url: `/api/v1/merchantfeesetup`,
      body: { ...editData, type: "edit" },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setOpenSuccess({ ...opensucess, state: true, message: editData.editId ? "Config Edited Successfully" : "Config Added Successfully" });
        setOpenDeactivate(false);
        setOpenActivate(false);
        getMerchantConfig();
      } else {
        setOpenError({ ...openerror, state: true, message: data?.response?.message });
        setOpenDeactivate(false);
        setOpenActivate(false);
        getMerchantConfig();
      }
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <ErrorAlert vertical={"top"} horizontal={"right"} onClose={closeError} open={openerror.state} message={openerror.message} />
      <SuccessAlert vertical={"top"} horizontal={"right"} onClose={closeSuccess} open={opensucess.state} message={opensucess.message} />
      {opendeactivate && <FeeInActivationModal open={opendeactivate} handleClose={handleCloseDeactivate} closeMoreVert={handleClose} deactivateConfig={deactivateConfig} />}
      {openactivate && <FeeActivationModal open={true} handleClose={handleOpenActivate} closeMoreVert={handleClose} deactivateConfig={deactivateConfig} />}
      {!isAuthecated && <AuthecateUser handleIsAuthecated={handleIsAuthecated} />}

      {isAuthecated && (
        <Grid container width={"100%"}>
          <Grid item width={"100%"}>
            <Box style={{ margin: "0px 0px 10px 0px" }}>
              <Typography sx={{ fontWeight: "600", color: "#032541", fontSize: "25px" }} variant="h5">
                Merchant Transaction Fees Configuration
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Breadcrumb name={"Merchant Transaction Fee Configuration"} />
              {queryValue !== "addconfig" && (
                <Button onClick={() => navigate(`/admin/merchantfeeconfig?${"addconfig"}`)} style={{ backgroundColor: "#032541", color: "white", padding: "10px 50px 10px 50px" }}>
                  ADD
                </Button>
              )}
            </Box>
          </Grid>
          {queryValue === "addconfig" ? (
            <>
              {" "}
              <Grid item width={"100%"}>
                <AddFeeConfig />
              </Grid>
            </>
          ) : (
            <>
              {" "}
              <Grid item width={"100%"}>
                <CustomDataGrid customStyles={{ border: "none", height: "400px", width: "100%" }} column={feeConfigColum} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={feeData} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Merchantfeeconfig;
