import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomSelectField from "./CustomSelectField";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";

let baseUrl = process.env.REACT_APP_BASE_URL;
const FeesInvoiceForms = () => {

  // const gradeOptions = [
  //     {value:"PP 1", label:"PP 1"},
  //     {value:"PP 2", label:"PP 2"},
  //     {value:"Grade 1", label:"Grade 1"},
  //     {value:"Grade 2", label:"Grade 2"},
  //     {value:"Grade 3", label:"Grade 3"},
  //     {value:"Grade 4", label:"Grade 4"},
  //     {value:"Grade 5", label:"Grade 5"},
  //     {value:"Grade 6", label:"Grade 6"},
  //     {value:"Grade 7", label:"Grade 7"},
  //     {value:"Grade 8", label:"Grade 8"},
  //     {value:"Grade 10", label:"Grade 10"},
  //     {value:"Grade 11", label:"Grade 11"},
  //     {value:"Grade 12", label:"Grade 12"},
  //     {value:"Grade 13", label:"Grade 13"},
  // ]
  // get this grade options from this url /api/get_grades
  const [gradeOptions, setGradeOptions] = useState([])
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

  //console.log(gradeOptions , 'gradeOptions')

  console.log('School Type Id', schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log(data, "Grades");

    setGradeOptions(data?.data?.filter((item) => item?.schoolGrades !== 'COMPLETED').map((itemGrade) => {
      return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
    }
    ))
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    //console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatus, setBoardingStatus] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatus(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])

  const [formData, setFormData] = useState({
    grade: "",
    term: "",
    courseName: "",
  })

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [studentCount, setStudentCount] = useState(0)

  const fetchStudents = () => {
    let url;
    if (formData.grade !== "" && formData.term === "" || formData.courseName !== "" && formData.term === "") {
      if (schoolTypeName?.includes("University")) {
        url = `/api/get_students_by_grade?grade=${formData.courseName}&page=1&limit=10000`
      } else {
        url = `/api/get_students_by_grade?grade=${formData.grade}&page=1&limit=10000`
      }
    } else if (formData.grade !== "" && formData.term !== "" || formData.courseName !== "" && formData.term !== "") {
      if (schoolTypeName?.includes("University")) {
        url = `/api/get_students_by_grade_and_term?grade=${formData.courseName}&term=${formData.term}&page=1&limit=10000`
      } else {
        url = `/api/get_students_by_grade_and_term?grade=${formData.grade}&term=${formData.term}&page=1&limit=10000`


      }
    }
    console.log("here url here store is url", url);
    HttpComponent({
      method: 'GET',
      url: url,
      body: null,
      token: X_Authorization
    }).then((data) => {
      console.log("here store is data", data);
      if (data.status === 200) {
        setStudentCount(data?.response?.data?.length)
      } else {
        console.error("Error setting info")
        setErrorShow({ state: true, message: data.response.message })
      }
    }).catch((error) => {
      console.error(error.message);
    })
  }

  useEffect(() => {
    if (formData.grade != "" || formData.courseName != "") {
      fetchStudents()
    }
  }, [formData.grade || formData.courseName, formData.term]);


  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [universityCourses, setUniversityCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedDuration, setSelectedDuration] = useState('');

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
    // clear form when course changes
    setSelectedSemester('');
    setSelectedDuration('');
  };

  const handleSemesterChange = (e) => {
    setSelectedSemester(e.target.value);
  };

  const handleDurationChange = (e) => {
    setSelectedDuration(e.target.value);
  };

  const durationFound = universityCourses?.find(course => course?.courseName === selectedCourse)?.duration
  const [courseDuration, setCourseDuration] = useState([])
  const [newCountStudent, setNewCountStudent] = useState(0)


  // generate a array of duration  depending on the duration time found

  useEffect(() => {
    const newDurationArray = Array.from({ length: durationFound }, (_, index) => {
      return index + 1;
    });
    setCourseDuration(newDurationArray);
  }, [selectedCourse, durationFound]);

  //console.log(courseDuration , 'courseDuration')

  useEffect(() => {
    function getStudentCountUnivers() {
      try {
        HttpComponent({
          method: 'GET',
          url: `/api/get_students_by_grade_and_term?grade=${selectedCourse}&term=${selectedSemester}&duration=${selectedDuration}&courseId=${selectedCourseId}`,
          token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
          if (data?.status === 200) {
            setNewCountStudent(data?.response?.count);
          }
        });
      } catch (error) {}
    }
    getStudentCountUnivers();
  }, [selectedCourse, selectedDuration, selectedSemester]);
  
  useEffect(() => {
    const selectedCourseData = universityCourses.find((course) => course.courseName === selectedCourse);
    if (selectedCourseData) {
      setSelectedCourseId(selectedCourseData._id);
    }
  }, [selectedCourse, universityCourses]);

  //console.log(newCountStudent , 'newCountStudent')


  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses found");
    if (response.status === 200) {
      setUniversityCourses(data.data);
      setCoursesOptions(
        data.data.map((course) => {
          return { value: course.courseName, label: course.courseName, id: course._id };
        })
      );
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleCourseSelection = (selectedOption) => {
    setSelectedCourse(selectedOption.value);
    setSelectedCourseId(selectedOption.id); 
  };

  let univerSityBody = {
    year: selectedDuration,
    grade: selectedCourse,
    term: selectedSemester,
    type: "one-off",
    frequency: "once"
  }

  const handleSubmit = () => {
    let body = {
      type: "one-off",
      grade: formData.grade || formData.courseName,
      term: formData.term,
      frequency: "once"
    }
    HttpComponent({
      method: 'POST',
      url: "/api/generate_invoice_batch",
      body: schoolTypeName?.includes("University") ? univerSityBody : body,
      token: localStorage.getItem('X-Authorization')
    }).then((data) => {
      console.log("here store is data", data);
      if (data.status === 200) {
        navigate('/school/invoices/pending',
          {
            state: { data: "created" }
          });
      } else {
        // console.error("Error setting info")
        setErrorShow({ state: true, message: data.response.message })
      }
    }).catch((error) => {
      console.error(error.message);
    })
  }

  return (
    <Box component="div" sx={{ margin: 1, display: "flex", width: '100%', flexDirection: "column" }}>
      <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={() => setErrorShow({ ...errorShow, state: false })}
        open={errorShow.state}
        message={errorShow.message} />
      {schoolTypeName?.includes("University") ? <>

        <Grid item>
          <Grid container>
            <Grid item width={'100%'}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Course Name</InputLabel>
                <Select

                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCourse}
                  label="Course Name"
                  onChange={handleCourseChange}
                >
                  {universityCourses?.map((course) => (
                    <MenuItem key={course?.courseName} value={course?.courseName}>{course?.courseName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {selectedCourse && (
              <>
                <Grid item mt={2} mb={2} width={'100%'}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedDuration}
                      label="Year"
                      onChange={handleDurationChange}
                    >
                      {courseDuration?.map((duration) => (
                        <MenuItem value={duration}>{duration}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item mt={2} width={'100%'}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label semester">Semester</InputLabel>
                    <Select
                      labelId="demo-simple-select-label semester"
                      id="demo-simple-select semester"
                      value={selectedSemester}
                      label="Semester"
                      onChange={handleSemesterChange}
                    >
                      {universityCourses?.find(course => course?.courseName === selectedCourse)?.semesterName?.map((course) => (
                        <MenuItem key={course?.courseName} value={course}>{course}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </> : <>

        {schoolTypeName?.includes("University") ? <>
          <Box component="div" sx={{ marginX: 1 }} >
            <CustomInputLabel required={true} label={"Course & Programs"} />
            <CustomSelectField value={formData.courseName} onChange={handleInputChange} name={"courseName"} placeholder={"Select course /Program"} options={coursesOptions} />
          </Box>
        </> : <>
          <CustomInputLabel required={true} label={"Grade"} />
          <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} />
        </>
        }

        <CustomInputLabel required={true} label={schoolTypeName?.includes("University") ? "Semester" : "Term"} />
        <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Select Term"} options={termOptions} />
      </>}
      <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        {schoolTypeName?.includes("University") ?
          <Button sx={{
            backgroundColor: "#032541",
            color: "white",
            width: "141px",
            height: "45px",
            marginTop: "10px",
            '&:hover': {
              backgroundColor: '#032541',
              color: 'white'
            },
          }}
            disabled={(!selectedCourse || !selectedDuration) || !selectedSemester.trim()}
            onClick={handleSubmit}
          >
            Generate ({newCountStudent})
          </Button> :

          <Button sx={{
            backgroundColor: "#032541",
            color: "white",
            width: "141px",
            height: "45px",
            marginTop: "10px",
            '&:hover': {
              backgroundColor: '#032541',
              color: 'white'
            },
          }}
            disabled={(!formData.grade.trim() && !formData.courseName.trim()) || !formData.term.trim()}
            onClick={handleSubmit}
          >
            Generate ({studentCount})
          </Button>
        }
      </Box>

    </Box>
  )
}

export default FeesInvoiceForms;
