import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PinInput from 'react-pin-input';

import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
export default function VerifyStagePin(props) {
    console.log(props, 'props VerifyStagePin');
    const [pin, setPin] = useState('')
    const [showPin, setShowPin] = useState(false)
    const [buttonState, setButtonState] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const navigate = useNavigate()




    // handle show pin

    const handleShowPin = () => {
        if (showPin) {
            setShowPin(false)
        }
        else {
            setShowPin(true)
        }
    }

    const phone = '254728270626'

    // function maskPhoneNumber(phoneNumber) {
    //     if (phoneNumber.length < 10) {
    //       return phoneNumber; 
    //     }

    //     const prefix = phoneNumber.slice(0, 4); 
    //     const maskedPart = phoneNumber.slice(4, 7).replace(/\d/g, '*'); 
    //     const suffix = phoneNumber.slice(7,10); 

    //     return `${prefix}${maskedPart}${suffix}`;
    //   }

    // const checkAllValueHas4Char = () => {
    //     let pin = value
    //     if (pin.length === 4) {
    //         setButtonState(true)
    //     }
    //     else {
    //         setButtonState(false)
    //     }


    // }

    // useState(() => {
    //     checkAllValueHas4Char()
    // }, [value])
    const giveParentPinForResend = (dataGive) => {
        props.parentSubChildCallBack(dataGive)
    }

    const checkIfPinIsValid = () => {
        HttpComponent({
            method: 'POST',
            url: `/api/v1/zed/funds_transfer/otp`,
            token: localStorage.getItem("X-Authorization"),
            body: {
                "pin": pin

            }

        }).then((data) => {
            if (data.status === 200) {
                console.log(data?.response?.message, 'data')
                let message = data?.response?.message
                // console.log(message, 'message')
                setSuccessShow({ state: true, message: message });
                let dataGive = {
                    pin: pin,
                    email: data?.response?.email,
                    phone: data?.response?.phone

                }
                giveParentPinForResend(dataGive)
                navigate('/payables/transcations?otp')

            }

            else {
                console.log(data?.response?.message, 'data')

                let message = data?.response?.message
                // console.log(message, 'message')
                setErrorShow({ state: true, message: message });
                setPin('')
            }
        }).catch((error) => {
            console.error(error);
        })


    }

    if (pin.length === 4) {
        // useEffect(() => {
            checkIfPinIsValid()
        // }, [pin])
    }

    return (

        <Grid container direction={'column'} mt={10}>
            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                <Typography style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>We need to confirm that it’s you</Typography>
                <Typography style={{ fontSize: '16px', color: '#707070' }}>Enter your login PIN below.</Typography>
            </Grid>
            <Grid item mt={1} display={'flex'} justifyContent={'center'}>
                {/* {
                    showPin ? <PinInput
                        length={4}
                        initialValue=""

                        secret={!showPin}
                        secretDelay={100}
                        onChange={(value) => setValue(value)}
                        // type="numeric"
                        type={showPin ? 'text' : 'password'}
                        inputMode="numeric"
                        style={{ padding: '10px' }}
                        inputStyle={{ borderColor: '#bec5d1' }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        //onComplete={(value, index) => { }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    /> : <Typography style={{ fontSize: '16px', color: '#707070' }}>{value}</Typography>

                } */}
                <PinInput
                    length={4}
                    initialValue=""

                    secret={true}
                    secretDelay={100}
                    onChange={(pin) => setPin(pin)}
                    // type="numeric"
                    type={showPin ? 'text' : 'password'}
                    inputMode="numeric"
                    style={{ padding: '10px' }}
                    inputStyle={{ borderColor: '#bec5d1', width:"92px", height:"108px", fontSize:"50px" }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    //onComplete={(value, index) => { }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
            </Grid>
            <Grid item mt={2} display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                <Typography style={{ fontSize: '16px', cursor: 'pointer', fontWeight: 600, color: '#032541' }} onClick={handleShowPin}>{showPin ? 'Show Pin' : 'Hide Pin'}</Typography>

            </Grid>
            {/* <Typography>{maskPhoneNumber(phone)}</Typography> */}
            <Grid item mt={2} display={'flex'} justifyContent={'center'}>
                {/* <Button style={{ color: buttonState ? "#032541" : "#dc3545", width: '125px', height: '45px', border: '1px solid #dc3545', textTransform: 'inherit' }}>Cancel</Button> */}
                {/* <Button style={{ color: '#fff', width: '125px', height: '45px', marginLeft: '15px', textTransform: 'inherit', background: 'rgba(3, 37, 65, 0.4)' }} onClick={() => checkIfPinIsValid()}>Verfiy</Button> */}
            </Grid>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />


        </Grid>
    )
}