import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleActivated } from "../../../../features/AlertSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

function EnableFee() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //pulling values from redux to set initial values of form inputs
  const reduxName = useSelector((state) => state.businessDetails.name);
  const reduxId = useSelector((state) => state.businessDetails.id);
  const reduxType = useSelector((state) => state.businessDetails.type);
//   const reduxFee = useSelector((state) => state.businessDetails.fee);
  const reduxCurrency = useSelector((state) => state.businessDetails.currency);

  //value to set when user edits form
  const [fee, setFee] = useState("")

  const handleFee = (e) => {
    setFee(e.target.value)
  }

  //updating the details object
  const [failMessage, setFailMessage] = useState(null)

  const handleUpdate = () => {
    console.log("enable clicked")
    try {
        axios
          .post(
            baseUrl + "/api/v1/business/convenienceFee/enable",
          {
            businessNumber: reduxId,
            convenientFeeAmount: fee,
          },
           {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }).then((response) => {
            if (response) {
              console.log("enabled successfully");
              dispatch(handleActivated(true))
              navigate("/ConvenienceFeeMgt")
            }
            // dispatch(handleRemov(id));
          });
      } catch (e) {
        console.log(e);
      }
  }

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailMessage(null);
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Enable Fee
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs point1={"Convenience Fee Mgt"} link1={"/ConvenienceFeeMgt"} point2={"Enable Fee"} />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Enable Fee
            </Typography>
            <TextField
              label="Business Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxName}
              InputLabelProps={{
                shrink: true, 
              }}
              // onChange={handleName}
              disabled
            />
            <TextField
              label="Business Id"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxId}
              InputLabelProps={{
                shrink: true, 
              }}
              // onChange={handleId}
              disabled
            />
            <TextField
              label="Business Type"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxType}
              InputLabelProps={{
                shrink: true, 
              }}
              // onChange={handleType}
              disabled
            />
            <TextField
              label="Currency"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxCurrency}
              InputLabelProps={{
                shrink: true, 
              }}
              // onChange={handleCurrency}
              disabled
            />
            <TextField
              label="Convenience Fee"
              variant="outlined"
              fullWidth
              size="small"
              placeholder="300"
            //   defaultValue={reduxFee}
              InputLabelProps={{
                shrink: true, 
              }}
              onChange={handleFee}
              autoFocus
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => 
                  navigate("/feeconfigs")
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleUpdate}
              >
                Enable
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default EnableFee;
