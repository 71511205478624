import React, { useEffect, useState } from "react";
import StockFunctions from "../stockEndpoints";
import CustomDataGrid from "../customDataGrid";
import { Done } from "@mui/icons-material";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../../bills/startorderModals/modals";

const Categoryproducts = (props) => {
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const categoryId = props?.categoryId;
  const storeId = props?.storeId;
  const productValue = props?.productValue;

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if (categoryId && storeId && productValue === "")
      stockFunctions.getProductsPerCategory(pageState?.page, dataGridPageSize, categoryId, storeId).then((data) => {
        if (data?.status === 200) {
          setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
        }
      });
  }, [categoryId, storeId, dataGridPageSize, pageState.page, productValue]);

  // serach products;

  console.log('addStockProductsPerCategory ========<><><>', pageState?.data);

  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if (productValue) stockFunctions.searchProductsPerCategory(pageState.page, dataGridPageSize, categoryId, productValue).then((data) => {
      setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
    });
  }, [categoryId, storeId, dataGridPageSize, pageState.page, productValue]);

  const [isError, setError] = useState({ state: false, message: "" });
  const [isMissingQuan, setMissingQuan] = useState({ state: false, message: "" });
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });

  const batchExists = <ErrorAlertRightAligned error={isError.state} message={"Batch already exists!"} />;
  const batchAdded = <SuccessAlertRightAligned sucess={isSuccess.state} message={"Batch added succesfully!"} />;
  const missingQuantity = <ErrorAlertRightAligned error={isMissingQuan.state} message={"Quantity missing!"} />;

  useEffect(() => {
    if (isMissingQuan.state) {
      setTimeout(() => {
        setMissingQuan({ state: false });
      }, 2000);
    }
  }, [isMissingQuan.state]);

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
      }, 2000);
    }
  }, [isSuccess.state]);

  useEffect(() => {
    if (isError.state) {
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
    }
  }, [isError.state]);

  const productsColumn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "inStockQuantity", headerName: "In Stock", flex: 1 },
    {
      field: "newStock",
      headerName: "New Quantity",
      flex: 1,
      renderCell: (params) => {
        return <input id="" name="" type="number" style={{ width: "100%", outline: "none" }} onChange={(e) => updateStock(params?.row, e)} />;
      },
    },
    { field: "productPrice", headerName: "Buying Price", flex: 1, },
    { field: "newBuyingPrice", headerName: "New Buying Price", flex: 1, renderCell:(params) => {
      return  <input id="" name={params?.row?.id} type="number" style={{ width: "100%", outline: "none" }}  onChange={(e) => updateBuyingPrice(params?.row, e)} />;
    } },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return <Done style={params.row?.newStock > 0 ? { color: "green", cursor: "pointer" } : { color: "black", cursor: "pointer" }} />;
      },
    },
  ];

  const productsRow = pageState?.data?.map((prod) => ({
    id: prod?._id,
    productName: prod?.productName,
    inStockQuantity: prod?.inStockQuantity,
    newStock: prod?.newStock,
    productPrice: prod?.buyingPrice,
    changed: false,
  }));

  //   update the actual stock;
  const updateStock = (stock, e) => {
    setTimeout(() => {
      const value = Number(e.target.value);
      const stockId = stock?.id;

      if(Math.sign(value) === -1){
        return
      }
      const stockItems = [...pageState?.data];
      // get index;
      const currentIndex = stockItems.findIndex((stock) => stock._id === stockId);
      stockItems[currentIndex].newStock = value * 1;
      stockItems[currentIndex].changed = true;

      setPageState({ ...pageState, data: stockItems });
      // check and update local storage;

      const localItems = JSON.parse(localStorage.getItem("addStockProducts"));
      if (localItems) {
        const newLocalItems = [...localItems];

        // get Index of Changing item;
        const newIndex = newLocalItems.findIndex((stock) => stock._id === stockId);

        if (newIndex === -1) {
          newLocalItems.push(stockItems[currentIndex]);
        } else {
          newLocalItems[newIndex].newStock = stockItems[currentIndex].newStock;
          newLocalItems[newIndex].changed = stockItems[currentIndex].changed;
        }
        localStorage.setItem("addStockProducts", JSON.stringify(newLocalItems));
      } else {
        localStorage.setItem("addStockProducts", JSON.stringify(stockItems));
      }
    }, 1000);
  };

  const updateBuyingPrice = (stock, e) => {
    setTimeout(() => {
      const value = Number(e.target.value);
      const name = e.target.name

    
      const stockId = stock?.id;

      const stockItems = [...pageState?.data];
      // get index;
      const currentIndex = stockItems.findIndex((stock) => stock._id === stockId);
      // stockItems[currentIndex].newStock = value * 1;
      // stockItems[currentIndex].changed = true;
    
      stockItems[currentIndex].productPrice = value * 1;

      setPageState({ ...pageState, data: stockItems });
      // check and update local storage;

      const localItems = JSON.parse(localStorage.getItem("addStockProducts"));
      if (localItems) {
        const newLocalItems = [...localItems];

        // get Index of Changing item;
        const newIndex = newLocalItems.findIndex((stock) => stock._id === stockId);

        if (newIndex === -1) {
          newLocalItems.push(stockItems[currentIndex]);
        } else {
          newLocalItems[newIndex].productPrice = stockItems[currentIndex].productPrice;
          // newLocalItems[newIndex].changed = stockItems[currentIndex].changed;
        }
        localStorage.setItem("addStockProducts", JSON.stringify(newLocalItems));
      } else {
        localStorage.setItem("addStockProducts", JSON.stringify(stockItems));
      }
    }, 1000);
  }

  return (
    <div>
      {isError.state && batchExists}
      {isMissingQuan.state && missingQuantity}
      {isSuccess.state && batchAdded}
      <CustomDataGrid customStyles={{height:"480px"}} column={productsColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productsRow} />
    </div>
  );
};

export default Categoryproducts;
