import React, {useState, useEffect} from "react";
import {Modal, Box, Grid, ButtonBase,} from "@mui/material";
import Mpesa from '../../../images/mpesa.png'
import Card from '../../../images/card.png'
import {MpesaPayments} from "./mpesaMessages";
import {CardPayments} from "./receiveCardpayment";

const ReceivedPayment = (props) => {

	const [paymentOpen, setPaymentOpen] = useState(props.paymentOpen);
	const [mpesaPaymentOpen, setMpesaPaymentOpen] = useState(false);
	const [cardPaymentOpen, setCardPaymentOpen] = useState(false);

	const wholeModal = {width: '500px', height: '477px', backgroundColor: '#fff', borderColor:"transparent", borderRadius: "7px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',};
	const headerSection = {backgroundColor: 'red', width: '100%', borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: '109px', flexDirection: "row", alignContent: "center", justifyItems: "center", textAlign: "center",}

	useEffect(() => {
		setPaymentOpen(props.paymentOpen);
	}, [props.paymentOpen]);

	return (
		<div>
			<Modal open={paymentOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={wholeModal}>
					<div style={headerSection}>
						<div style={{marginLeft: "auto", marginRight: "auto", color: "white", fontWeight: "bold", fontSize: 25, paddingTop:"40px"}}>
							Received Payment
						</div>
					</div>
					<Grid container alignItems={'center'} justifyContent={'space-around'} style={{marginTop : '10%'}} >
						<Grid item>
							<ButtonBase onClick={()=> {
								setMpesaPaymentOpen(true);
								props.onClose();
							}}>
								<img src={Mpesa} alt={'M-pesa image'} />
							</ButtonBase>
						</Grid>
						<Grid item>
							<ButtonBase onClick={()=> {
								setCardPaymentOpen(true);
								props.onClose()
							}}>
								<img src={Card} alt={'Card image'} />
							</ButtonBase>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<>
				{/*Mpesa messages*/}
				<MpesaPayments mpesaOpen={mpesaPaymentOpen} onClose={() => setMpesaPaymentOpen(false)}/>
				{/*Card Payments*/}
				<CardPayments mpesaOpen={cardPaymentOpen}  onClose={() => setCardPaymentOpen(false)}/>
			</>
		</div>
	);
};
export { ReceivedPayment };
