import {Button, Grid, MenuItem, Select, TextField} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {handleTenantBioData, handleTenantHouseData, handleUpdatedTenantHouseData} from "../../../features/tenantsDataSlice";
import CustomSelectField from "../../School/CustomSelectField";
import HttpComponent from "../../School/MakeRequest";

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);

export default function AddHouse(props) {
	console.log(props , 'from house')
	const [houseNo, setHouseNo] = useState('')
	const [houseType, setHouseType] = useState('')
	const [billingPeriod, setBillingPeriod] = useState('')
	const [rentDueDate ,setRentDueDate] = useState('')
	const [period, setPeriod] = useState([])

	const [unitHouseType, setUnitHouseType] = useState([])
	const [unitHouseNo, setUnitHouseNo] = useState([])
	const [unit_id, setUnit_id] = useState('')
	const [unit_amount, setUnit_amount] = useState('')
	const [unitPurpose ,setUnitPurpose] = useState('')
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"
	
	//Billing Periods
	const billingPeriodOptions = period.map((date) => ({value : date, label : date}))
	const unitHouseNumberOptions = unitHouseNo.map((house) => ({value : house.unitName, label : house.unitName}))
	
	const dispatch = useDispatch()
	const houseData = {unitType : houseType,unitPurpose:unitPurpose,amount:unit_amount , itemNumber : houseNo, billingDate : billingPeriod, rentDueDate:rentDueDate, unitId : unit_id}
	
	const handleGoBackClick = () => {
		props.setFlagGoBack('')
	}

	console.log(houseData , 'house data')
	const isFromHouse = props.isFromHouse

	const handleAddCharges = () => {
		if(isFromHouse === true){
			dispatch(handleTenantHouseData(houseData))
			props.saveUnit(unit_id)
			
		}
		dispatch(handleTenantHouseData(houseData))
		props.setFlagChargesState('addCharges')
		props.saveUnit(unit_id)
	}

	console.log(props ,'check unit id')
	
	// Get Billing Periods
	function getBillingPeriods() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_billing_dates',
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201){
					setPeriod(data.response.data)
				}else{
					console.log(`Error Getting Periods!`)
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error Getting Periods!`)
		}
	}
	
	// Get House Types
	function getHouseTypes() {
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_unit_types_by_business_unit_config?businessUnitConfigId=${configId}`,
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201){
					setUnitHouseType(data.response.data)
				}else{
					console.log(`Error Getting Periods!`)
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error getting House types`)
		}
	}
	
	console.log('unit id ', unit_id)
	
	// Get House No
	function getHouseNo() {
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_units_by_unit_type_id?page=1&limit=1000&businessUnitTypeId=${unit_id}`,
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201){
					setUnitHouseNo(data.response.data)
				}else{
					console.log(`Error Getting Periods!`)
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error Getting House No.`)
		}
	}
	
	useEffect(() => {
		getBillingPeriods()
		getHouseTypes()
	}, [])
	
	useEffect(() => {
		getHouseNo()
	}, [unit_id])
	
	return (
		<Grid container justifyContent={'center'} alignContent={'center'}  direction={'column'} mt={3} spacing={3}>
			
			{/*Header*/}
			<Grid item>
				<span style={{fontSize : '16px', fontWeight : '600', color : '#032541'}} >Add House Details</span>
			</Grid>
			
			{/*House Type*/}
			<Grid item >
				<Select
					MenuProps={{anchorOrigin: {vertical: "bottom", horizontal: "left"}, transformOrigin: {vertical: "top", horizontal: "left"}, getcontentanchorel: null}}
					value={houseType} onChange={(e) => {setHouseType(e.target.value)}} style={{color : '#032541FF', width : '100%', fontSize:"0.875rem", "& fieldset": {borderColor: "#bec5d1"}}} variant="outlined" margin="dense" displayEmpty>
					<MenuItem value="">Select House Type</MenuItem>
					{
						unitHouseType.map((house, index) => (
							<MenuItem key={index} onClick={() => {setUnit_id(house._id);setUnitPurpose(house.unitPurpose); setUnit_amount(house.unitCost)}} value={house.unitType}>{house.unitType}</MenuItem>
						))
					}
				</Select>
				{/*<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={houseType} onChange={(e) => {setHouseType(e.target.value)}} name={"House Type"} placeholder={"Select House Type"} options={unitHouseTypeOptions} />*/}
			</Grid>
			
			{/*House No*/}
			<Grid item>
				<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={houseNo} onChange={(e) => {setHouseNo(e.target.value)}} name={"House No"} placeholder={"Select House No"} options={unitHouseNumberOptions} />
			</Grid>
			
			{/*House No*/}
			<Grid item>
				<TextField disabled required readOnly value={numberFormat(unit_amount)} defaultValue={numberFormat(unit_amount)} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '100%'}} id="outlined-basic" label="Amount" variant="outlined" />
			</Grid>
			
			{/*Billing Period*/}
			<Grid item>
				<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={billingPeriod} onChange={(e) => {setBillingPeriod(e.target.value)}} name={"Billing Period"} placeholder={"Billing (Invoicing) Date"} options={billingPeriodOptions} />
			</Grid>

			{/*Rent Due Date*/}
			<Grid item>
				<CustomSelectField  style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={rentDueDate} onChange={(e) => {setRentDueDate(e.target.value)}} name={"Due Date"} placeholder={"Rent Due Date"} options={billingPeriodOptions} />
			</Grid>
			
			{/*Button*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'flex-end'} spacing={3}>
					
					{/*Cancel*/}
					<Grid item>
						<Button onClick={handleGoBackClick} style={{border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}} >
							Cancel
						</Button>
					</Grid>

					{/*Next*/}
					<Grid item>
						<Button
							disabled={!houseNo.trim() || !houseType.trim() || !billingPeriod.trim()}
							onClick={handleAddCharges} style={{
								opacity : !houseNo.trim() || !houseType.trim() || !billingPeriod.trim()? "0.5" : "1",
								textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Next
						</Button>
					</Grid>
				</Grid>
			</Grid>
		
		</Grid>
	)
}
