import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { warehousestyles } from "../styles";
import PhoneInput from "react-phone-input-2";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import HttpComponent from "../../School/MakeRequest";
import { useNavigate } from "react-router-dom";

const AddUser = ({ storeTypeToAdd, storeOperatoruserId, mainsStoreUserId }) => {

  const navigate = useNavigate();

  const [customerPhone, setCustomerPhone] = useState("");
  const [allInputFilled, setAllInputsFilled] = useState(false);
  const [userDetails, setUserDetails] = useState({ firstName: "", secondName: "", username: "", email: "" });

  useEffect(() => {
    if (userDetails.firstName === "" && userDetails.secondName === "" && userDetails.username === "" && userDetails.email === "" && customerPhone === "") {
      setAllInputsFilled(false);
    } else if (userDetails.firstName && userDetails.secondName && userDetails.username && userDetails.email && customerPhone) {
      setAllInputsFilled(true);
    }
  }, [userDetails.firstName, userDetails.secondName, userDetails.username, userDetails.email, customerPhone]);

  const getUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserDetails({ ...userDetails, [name]: value });
  };

  const [isError, setError] = useState({ state: false, message: "" });
  const [isSuccess, setSuccess] = useState({ state: false, message: "" });

  const errorMessage = <ErrorAlertRightAligned error={isError.state} message={isError.message} />;
  const successMessage = <SuccessAlertRightAligned sucess={isSuccess.state} message={isSuccess.message} />;

  useEffect(() => {
    if (isSuccess.state) {
      setTimeout(() => {
        setSuccess({ state: false });
        if(storeTypeToAdd === "SubStore"){
          navigate(-1);
        }else if(storeTypeToAdd === "Warehouse"){
          navigate(-1);
        }else if(storeTypeToAdd === "MainStore"){
          navigate(-1)
        }
       
      }, 2000);
    }

    if (isError.state) {
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
    }
  }, [isSuccess.state, isError.state]);

  //   let {firstName, secondName, userName, userEmail, userPhone, userGroup, userPassword} = req.body

  const [operatorType, setOperatorType] = useState("");
  useEffect(() => {
    if (storeTypeToAdd === "SubStore") {
      setOperatorType("STOREOPERATOR");
    } else if (storeTypeToAdd === "MainStore") {
      setOperatorType("STOREOPERATOR");
    } else if (storeTypeToAdd === "Warehouse") {
      setOperatorType("STOREMANAGER");
    }
  }, [storeTypeToAdd]);

  const addOperator = () => {
    HttpComponent({
      method: "POST",
      url: `/api/addNewUser`,
      body: {
        Equitel: "",
        Paybill: "",
        Till: "",
        Vooma: "",
        assignToBusiness: "assignToBusiness",
        assignedBusiness: localStorage.getItem("businessId"),
        businessLocation: "",
        businessName: localStorage.getItem("businessName"),
        businessnumber: localStorage.getItem("businessId"),
        businessphone: localStorage.getItem("phone"),
        businessshortcode: "",
        firstName: userDetails.firstName,
        loginstate: "active",
        secondName: userDetails.secondName,
        userEmail: userDetails.email,
        userGroup: storeOperatoruserId ? storeOperatoruserId: mainsStoreUserId ? mainsStoreUserId : operatorType,
        userName: userDetails.username,
        userPhone: customerPhone[3]?.startsWith(0) ? "+254" + customerPhone?.substring(4) : `+${customerPhone}`,
        userState: "Active",
      },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 201) {
          setSuccess({ state: true, message: "Operator added successfully!" });
        } else if (data.status === 400) {
          setError({ state: true, message: "Error in creating operator!" });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <div>
      {isError.state && errorMessage}

      {isSuccess.state && successMessage}

      <Grid container direction={"row"} width={"70%"} spacing={2} margin={"0px 0px 1% 0px"}>
        <Grid item style={{ width: "50%" }}>
          <TextField style={{ width: "100%" }} name="firstName" value={userDetails.firstName} onChange={getUserInput} label="First Name" variant="outlined" />
        </Grid>
        <Grid item style={{ width: "50%" }}>
          <TextField style={{ width: "100%" }} name="secondName" value={userDetails.secondName} onChange={getUserInput} label="Last Name" variant="outlined" />
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"70%"} spacing={2} margin={"0px 0px 1% 0px"}>
        <Grid item style={{ width: "50%" }}>
          <TextField style={{ width: "100%" }} name="username" value={userDetails.username} onChange={getUserInput} label="Username" variant="outlined" />
        </Grid>
        <Grid item style={{ width: "50%" }}>
          <PhoneInput inputStyle={{ height: "55px", width: "200%", margin: "0px 0px 10px 0px" }} name="phone" value={customerPhone} onChange={(phone) => setCustomerPhone(phone)} style={warehousestyles.addsupplierInputs} required country={"ke"} enableSearch={true} label={"Mobile Number"} multiline />
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"70%"} spacing={2} margin={"0px 0px 1% 0px"}>
        <Grid item style={{ width: "50%" }}>
          <TextField style={{ width: "100%" }} name="email" value={userDetails.email} onChange={getUserInput} label="Email" variant="outlined" />
        </Grid>
        <Grid item style={{ width: "50%" }}>
          <TextField style={{ width: "100%" }} disabled name="role" value={storeTypeToAdd === "Warehouse" ?  "Store Manager" : "Store Operator"} label="Role" variant="outlined" />
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"70%"} justifyContent={"flex-end"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <Button style={allInputFilled ? warehousestyles.createCustomerActiveBtn : warehousestyles.createcustomerInactiveBtn} onClick={() => (allInputFilled ? addOperator() : null)}>
            CREATE USER
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddUser;
