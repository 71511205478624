import React, { useState, useEffect } from "react";
import { Button, Modal } from "@mui/material";
import { universalStyles } from "../../../bills/startorderuniversalstyles/styles";
import warning from "../../../../common/images/deactivate.svg";
import { useNavigate } from "react-router-dom";
const Nostock = ({ setOpenStockModal, openCheckStockModal }) => {
  const businessCategory = localStorage.getItem("businessCategory");
  const navigate = useNavigate();
  let navigatelink = `/stockConfig/`;
  if (businessCategory?.includes("School")) {
    navigatelink = `/school/configuration`;
  }

  return (
    <Modal open={openCheckStockModal}>
      <div style={universalStyles.noStockModal}>
        <div style={{ textAlign: "center", padding: "40px" }}>
          <div style={{ margin: "0 0 8% 0" }}>
            <img src={warning} alt="warningimage" style={{ height: "100px", width: "100px", objectFit: "cover" }} />
            <h3>Enable Stock In Zed</h3>
            <span style={{ color: "#707070" }}>Please enable stock in zed under configs to proceed</span>
          </div>

          <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <Button
              style={{ color: "#dc3545", border: "2px solid #dc3545", width: "30%" }}
              onClick={() => {
                setOpenStockModal(false) 
                navigate("/");
              }}
            >
              {" "}
              Cancel
            </Button>
            <Button style={{ backgroundColor: "#032541", color: "white", width: "30%" }} onClick={() => navigate(`${navigatelink}`)}>
              Enable
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Nostock;
