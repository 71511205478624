import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import CustomSelectStockOrder from "./customSelectStockorder";
import { useSelector } from "react-redux";
import StockFunctions from "../stockEndpoints";
import { stockStyles } from "../stockStyling";
import { useNavigate } from "react-router-dom";
const Stockordersetup = () => {
  const navigate = useNavigate();
  const [storesToFetch, setStoresToFetch] = useState("");
  const [wareHouses, setwarehouses] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [supplierBsNo, setSupplierBusinessNumber] = useState("");
  const [supplierName, setSupplierName] = useState("")

  const currentMainStore = [
    { _id: "", name: "" },
    { _id: "MainStore", name: "MainStore" },
  ];
  const currentStore = [
    { _id: "", name: "" },
    { _id: "MainStore", name: "MainStore" },
    { _id: "Warehouse", name: "Warehouse" },
  ];

  const { wareHouseState } = useSelector((store) => store.sidebar);

  // get stores byType
  useEffect(() => {
    const stockFunctions = new StockFunctions();
    if (storesToFetch)
      stockFunctions.findStoresByType(storesToFetch, 1, 1000).then((data) => {
        setwarehouses(data?.response?.data);
      });
  }, [storesToFetch]);

  // get suppliers
  useEffect(() => {
    const stockFunctions = new StockFunctions();
    stockFunctions.getSuppliers(1, 1000, "ACTIVE").then((data) => {
         
      const suppliersNotInZed = [...data?.response?.data].filter((supplier) => supplier.supplierType === "new")
      setSuppliers(suppliersNotInZed);
    });
  }, []);

  
  
  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"}>
        <span style={{ margin: "0px 0px 3% 0px" }}>Current Store</span>
        <Grid item margin={"0px 0px 3% 0px"} width={"30%"}>
          <CustomSelectStockOrder setInputValue={setStoresToFetch} inputMarker={"Current store"} dataToMap={wareHouseState ? currentStore : currentMainStore} label={"Current store"} />
        </Grid>

        {storesToFetch && (
          <Grid item margin={"0px 0px 2% 0px"} width={"30%"}>
            <CustomSelectStockOrder setInputValue={setStoreId} inputMarker={storesToFetch} dataToMap={wareHouses} label={storesToFetch} />
          </Grid>
        )}

        {storeId && (
          <Grid item margin={"0px 0px 2% 0px"} width={"30%"}>
            <CustomSelectStockOrder  setInputValue={setSupplierId} inputMarker={"Supplier"} dataToMap={suppliers} label={storesToFetch} />
          </Grid>
        )}

        {supplierId && (
          <Grid item style={{ display: "flex", justifyContent: "flex-end" }} width={"30%"}>
            <Button onClick={() => (supplierId && storeId ? navigate(`/stockOrder/categories?${storeId}?${supplierId}`) : null)} style={supplierId ? stockStyles.nextButtonActive : stockStyles.nextButtonInactive}>
              Next
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Stockordersetup;
