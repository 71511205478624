import React, { useEffect, useState } from "react";
import profilePicture from "../../common/images/profile_picture.svg";
import { Box, Modal, Grid, styled, Tab, Typography, Paper, FormControl, InputLabel } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomTable from "../School/CustomTable";
import HttpComponent from "../School/MakeRequest";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { clearCategoriesToAdd } from "../../features/businessCategorySlice";
import { useSelector } from "react-redux";
import ListItemText from "@mui/material/ListItemText";
import CustomerInvoice from "./customerComponents/customerinvoice";
import Customertransaction from "./customerComponents/customertransaction";
import Customeractivity from "./customerComponents/customeractivity";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadStudentWallete from "../School/loadStudentWallete";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import DateFormatter from "../../utils/dateFormatter";
import WarningImg from "../../images/activFee.svg";
import ServiceActivationFeeZpm from "../School/serviceActivation";
import TotalAmountIcon from "../../images/pocket-money-icn.svg";
import CustomSearchInput from "../School/CustomSearchInput";
import ExportMenu from "../School/ExportMenu";
import BackImgA from "../../images/bgA.svg";
import BackImgB from "../../images/bgB.svg";
import BackImgC from "../../images/bgC.svg";
import VehicleOwnerDash from "../transportBusiness/vehcleowner/vehicleOwnerDash";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectWalletPaymentMethod from "../School/SelectWalletPaymentMethod";
import LoadViaCard from "../School/LoadViaCard";
const baseUrl = process.env.REACT_APP_BASE_URL;

let localCurrency = localStorage.getItem("localCurrency");

if (!localCurrency || localCurrency === "undefined" || localCurrency === undefined) {
  localCurrency = "KES";
}

const style = { borderRadius: "10px", bgcolor: "background.paper", display: "flex", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "630px", height: "298px", p: 4 };

const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#032541",
    fontWeight: "bold",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const CustomerAccountDash = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMuiMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { businessCat } = useSelector((store) => store.businessCategory);
  const [notSchoolAndRent, setNotSchoolAndRent] = useState(false);
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store?.schoolType?.schoolTypeDetail);
  const bsCategory = localStorage.getItem("businessCategory");
  const [admissionNumber, setAdmissionNumber] = useState(null);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [searchValue, setSearchValue] = useState("");
  const userGroup = localStorage.getItem("group");

  const [openModalPayActivationFee, setOpenModalPayActivationFee] = useState(false);
  const customerClassification = localStorage.getItem("customerClassification");

  const handleOpenModalActivationFee = () => {
    setOpenModalPayActivationFee(true);
  };

  // const handleNavigateToActivateFee = () => {
  //     setOpenModalPayActivationFee(false, () => navigate('/dashboard?payActivationFee'));
  // }

  const handleCloseModalActivationFee = () => {
    setOpenModalPayActivationFee(false);
  };

  const queryParams = decodeURIComponent(window.location.search);
  const state = queryParams.slice(1);

  useEffect(() => {
    if (businessCat === "Eatery, Bar and Restaurant") {
      setNotSchoolAndRent(true);
    }
  }, [businessCat]);

  const theColumns = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };
  const commonTextSize = {
    fontSize: isMuiMobile ? "12px" : "15px",
  };

  const [value, setValue] = useState("Invoices");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [receivedRows, setReceivedRows] = useState([]);
  const [receivedPageSize, setReceivedPageSize] = useState(5);
  const [userData, setUserData] = useState();
  const [customerActivity, setCustomerActivity] = useState();
  const [customerTransRows, setCustomerTransRows] = useState([]);
  const [customerTransPageSize, setCustomerTransPageSize] = useState(5);
  const [selectedBusinessName, setSelectedBusinessName] = useState("");
  const [customerBusinessData, setCustomerBusinessData] = useState();
  const [token, setToken] = useState(localStorage.getItem("X-Authorization"));
  const [studentWallete, setStudentWallet] = useState(0);

  // console.log(userData, "userData >>>>>");

  function convertDate(dateString) {
    let date = new Date(dateString);
    let options = { month: "short" };
    let day = date.getDate();
    let suffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    return day + suffix + " " + date.toLocaleString("default", options) + " " + date.getFullYear();
  }

  const fetchReceived = async () => {
    try {
      const headers = { method: "GET", "X-Authorization": localStorage.getItem("X-Authorization") };
      const response = await fetch(baseUrl + "/api/get_user_invoices", { headers });
      if (response.status === 200) {
        await response.json().then((data) => {
          // console.log("received invoices", data)
          const rowsWithIds = data.data.map((row) => {
            const createdAt = new Date(row.createdAt).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const amountPaid = row.invoiceAmount - row.invoiceBalance;
            return { ...row, id: row._id, createdAt, amountPaid };
          });
          setReceivedRows(rowsWithIds);
        });
      }
    } catch (e) {
      console.error("error fetching recived invoices", e.message);
    }
  };

  const fetchActivity = async () => {
    const customerDetailsResponse = await fetch(baseUrl + `/api/CustomersActivities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse.status === 201) {
      const customerDetailsData = await customerDetailsResponse.json();
      //console.log("init", customerDetailsData.data)
      setCustomerActivity(customerDetailsData.data);
    }
  };

  const getTransactions = async () => {
    console.log("test working");
    const customerDetailsResponse = await fetch(baseUrl + `/api/customerTransactions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse.status === 201) {
      await customerDetailsResponse.json().then((data) => {
        //console.log("transa", data.transaction)
        let counter = 1;
        const rowsWithIds = data.transaction.map((row) => {
          const transactionTime = new Date(row.transactionTime).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          return { ...row, id: counter++, transactionTime };
        });
        // console.log("new rows", rowsWithIds)
        setCustomerTransRows(rowsWithIds);
      });
    }
  };

  let customerId = localStorage.getItem("customerId");

  const getCustomerDetails = async () => {
    try {
      const customerDetailsResponse = await fetch(baseUrl + `/api/get_customer_by_number?customerId=${customerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (customerDetailsResponse.status === 200) {
        const customerDetailsData = await customerDetailsResponse.json();
        // console.log("det", customerDetailsData);
        //console.log("sd", customerDetailsData.data[0])
        setUserData(customerDetailsData.data[0]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleBusinessChange = async (event) => {
    const selectedBusiness = customerBusinessData.businesses.find((business) => business.businessName === event.target.value);
    setSelectedBusinessName(event.target.value);
    console.log("new id", selectedBusiness.businessId);
    await fetch(baseUrl + `/api/change_customer_business`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        oldToken: localStorage.getItem("X-Authorization"),
        businessId: selectedBusiness.businessId,
      }),
    }).then(async (data) => {
      let response = await data.json();
      console.log("changing business done");
      localStorage.setItem("X-Authorization", response.data.newToken);
      setToken(localStorage.getItem("X-Authorization"));
    });
  };

  const getUserBusinesses = async () => {
    // get_customer_businesses
    const customerDetailsResponse = await fetch(baseUrl + `/api/get_customer_businesses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (customerDetailsResponse?.status === 200) {
      const customerDetailsData = await customerDetailsResponse.json();
      console.log("yghj", customerDetailsData);
      setCustomerBusinessData(customerDetailsData?.data);
      setSelectedBusinessName(customerDetailsData?.data?.businessName);
    }
  };

  const { userId, X_Authorization } = useSelector((store) => store.user);

  const [students, setStudents] = useState([]);

  const fetchStudents = () => {
    HttpComponent({
      method: "POST",
      url: `/api/getBillableItems?page=1&limit=1000`,
      body: { customerId: customerId },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          if (schoolTypeName === "University/College") {
            setStudentWallet(data?.response?.data?.[0]?.walletBalance);
          }
          setStudents(data?.response?.data);
        } else {
          console.error("Error setting info");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const [receivedReceipts, setReceivedReceipts] = useState([]);

  // student wallet data

  function getStudentWallet() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/get_student_wallet_transactions`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        if (data?.status === 200) {
          // setStudentWallet(data?.response?.data)
        }
      });
    } catch (error) {}
  }

  const fetchReceivedReceipts = async () => {
    try {
      const headers = { method: "GET", "X-Authorization": localStorage.getItem("X-Authorization") };
      const response = await fetch(baseUrl + "/api/get_user_receipts_invoice_only", { headers });
      if (response.status === 201) {
        await response.json().then((data) => {
          console.log("received receipts", data);
          const rowsWithIds = data.data.map((row) => {
            const issuedDate = new Date(row.issuedDate).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const amount = numberFormat(row.amount);
            return { ...row, id: row.transactionId, issuedDate, amount };
          });
          setReceivedReceipts(rowsWithIds);
        });
      }
    } catch (e) {
      console.error("error fetching recived invoices", e.message);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, [schoolTypeName, state]);

  const [activeStudent, setStudentDetails] = useState({itemNumber:"", studentName:""})
  const handleStudentSelection = (e) => {
    let student = e?.target?.value
    setStudentDetails({...activeStudent, itemNumber:student?.itemNumber, studentName : student?.firstName + " " + student?.lastName})
  }

  console.log("activeStudent", activeStudent)

  // bio set

  const [studentBioSet, setStudentBioSet] = useState([]);

  function getFingerPrintStatus() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/get_customer_by_number?customerId=${userData?._id}`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        console.log(data, "fffffff");
        if (data?.status === 200) {
          setStudentBioSet(data?.response?.data[0]?.billableItems[0]?.studentsBio);
        }
        // console.log(data , 'fingerprints')
      });
    } catch (error) {}
  }

  useEffect(() => {
    getFingerPrintStatus();
  }, [userData?._id]);

  useEffect(() => {
    // fetchStudents()
    fetchActivity();
    getCustomerDetails();
    fetchReceived();
    getTransactions();
    getUserBusinesses();
    fetchReceivedReceipts();
    getStudentWallet();
  }, [state]);

  //get wallet transactions
  const [walleteTransaction, setWalletTransaction] = useState([]);
  const [recentSessions, setRecentSessions] = useState([]);

  //get wallet reports

  function getAllwalletTransactions() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/getZedPayItWalletReports?studentNumber=${userData?.admissionNo ?? activeStudent?.itemNumber}`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        console.log(data, "he;;;");
        if (data?.status === 200) {
          setWalletTransaction(data?.response?.data);
        }
      });
    } catch (error) {}
  }

  useEffect(() => {
    getAllwalletTransactions();
  }, [userData?.admissionNo, state, activeStudent?.studentName]);

  // console.log(walleteTransaction , 'walleteTransaction')

  const [showWallet, setShowWallet] = useState(false);
  const [termlyAmount, setTermlyAmount] = useState(0);
  const [isPayItYEnabledBySchool, setIsPayitEnabledbySchool] = useState(false);
  const [isTermlyFeePaidByStudent, setisTermlyFeePaidByStudent] = useState(false);

  const getBusinessDetails = () => {
    try {
      HttpComponent({
        method: "POST",
        url: `/api/get_business_info`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        console.log(data, "data >>>>");
        if (data?.status === 200 || data?.status === 201) {
          let payItStatus = data?.response?.data?.isPayItYEnabled;
          let transactionMode = data?.response?.data?.transactionMode;
          setTermlyAmount(data?.response?.data?.termlyAmount);
          setIsPayitEnabledbySchool(data?.response?.data?.isPayItYEnabled);
          // setisTermlyFeePaidByStudent(data?.response?.data?.isTermlyFeePaid);
          // console.log(payItStatus, 'transactionMode >>>>>>>>>>>')
          if ((payItStatus === true && transactionMode === "bio") || transactionMode === "card_bio") {
            setShowWallet(true);
          }
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getBusinessDetails();
  }, []);

  // genereate invoice

  const [termlyInvoiceNumber, setTermlyInvoiceNumber] = useState();
  const [termlyInvoiceAmount, setTermlyInvoiceAmount] = useState();

  // get lecs sessions

  function getLectureSEssions() {
    try {
      HttpComponent({
        method: "POST",
        url: `/api/v1/getLecturerSession`,
        body: {
          lecturerId: userData?.billableItems[0]?._id,
        },
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        //console.log(data, 'helo')
        if (data?.status === 200) {
          setRecentSessions(data?.response?.data);
        }
      });
    } catch (error) {}
  }

  useEffect(() => {
    getLectureSEssions();
  }, [userData?.billableItems[0]?._id]);

  function getActivationFeeStatus() {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/v1/getActivationFeeStatus?studentId=${userData?.billableItems[0]?._id}`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        console.log(data, "termly fee paid ???");
        if (data?.status === 200 || data?.status === 201) {
          setisTermlyFeePaidByStudent(data?.response?.data);
        }
      });
    } catch (error) {}
  }
  useEffect(() => {
    getActivationFeeStatus();
  }, [userData?.billableItems[0]?._id, state]);

  function generateTermlyInvoice() {
    try {
      HttpComponent({
        method: "POST",
        url: `/api/v1/business/generateTermlyPaidInvoice`,
        body: {
          studentId: userData?.billableItems[0]?._id,
        },
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        console.log(data, "data invoice ???");
        if (data?.status === 200) {
          setTermlyInvoiceNumber(data?.response?.data?.invoiceNumber);
          setTermlyInvoiceAmount(data?.response?.data?.invoiceAmount);
        }
        setTimeout(() => {
          setOpenModalPayActivationFee(false);
          navigate(`/dashboard?payActivationFee`);
        });
      });
    } catch (error) {}
  }

  // EXports

  const filedata = walleteTransaction?.data?.map((data) => {
    return {
      "Order No": data?.receiptNo,
      Items: data?.items,
      "Date & Time": data?.createdAt,
      Amount: data?.transAmount,
    };
  });
  const fileHeaders = [["Name", "Institution", "Categories", "Terminals", "TerminalUsers", "Status"]];
  const csvColumns = [
    { label: "Order No", key: "Order No" },
    { label: "Items", key: "Items" },
    { label: "Date & Time", key: "Date & Time" },
    { label: "Amount", key: "Amount" },
  ];
  const fileDataPDF = walleteTransaction?.data?.map((data) => [data?.receiptNo, data?.items?.length, DateFormatter(data?.createdAt), numberFormat(data?.transAmount)]);


  return (
    <>
      {state === "loadViaCard" ? (
        <LoadViaCard />
      ) : state === "selectPaymentMethod" ? (
        <SelectWalletPaymentMethod />
      ) : state === "loadStudentWallet" ? (
        <LoadStudentWallete />
      ) : state === "payActivationFee" ? (
        <ServiceActivationFeeZpm invoiceNumber={termlyInvoiceNumber} amount={termlyInvoiceAmount} />
      ) : (
        <>
          <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
          <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
          <div style={{ width: "100%" }}>
            <span
              style={{
                marginLeft: isMuiMobile ? 0 : 20,
                color: "#dc3545",
                fontWeight: "600",
              }}
            >
              Businesses:{" "}
            </span>
            <span
              style={{
                color: "#032541",
              }}
            >
              <Select labelId="business-select-label" id="business-select" value={selectedBusinessName && selectedBusinessName} onChange={handleBusinessChange}>
                {customerBusinessData?.businesses.map((business) => (
                  <MenuItem key={business.businessId} value={business.businessName}>
                    {business.businessName}
                  </MenuItem>
                ))}
              </Select>
            </span>
          </div>
          {businessCat === "School" && userGroup === "Customer" && schoolTypeName !== "University/College" && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid item>
                <Button
                  onClick={() => (isTermlyFeePaidByStudent === true ? navigate("/dashboard?selectPaymentMethod") : handleOpenModalActivationFee())}
                  style={{
                    background: "#032541",
                    width: "160px",
                    marginRight: "10px",
                    height: "45px",
                    textTransform: "inherit",
                    color: "#ffff",
                  }}
                >
                  Load Wallet
                </Button>
              </Grid>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ minWidth: 120 }}>
              {businessCat === "School" && (customerClassification === "Staff" || schoolTypeName === "University/College") ? (
                <Grid container>
                  {schoolTypeName === "University/College" ? (
                    <Grid item>
                      <Button
                        style={{
                          color: "#fff",
                          textTransform: "inherit",
                          marginRight: "10px",
                          width: "245px",
                          height: "45px",
                          background: "#17ae7b",
                        }}
                      >
                        Wallet Balance :{numberFormat} {studentWallete}
                      </Button>
                    </Grid>
                  ) : null}
                  {showWallet && (
                    <Grid item>
                      <Button onClick={() => (isTermlyFeePaidByStudent === true ? navigate("/dashboard?selectPaymentMethod") : handleOpenModalActivationFee())} style={{ background: "#032541", width: "145px", marginRight: "10px", height: "45px", textTransform: "inherit", color: "#ffff" }}>
                        Pocket Money
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate("/school/request/service");
                      }}
                      style={{ border: "1px solid #032541", height: "45px", width: "145px", textTransform: "inherit", color: "#032541" }}
                    >
                      {" "}
                      Request Service
                    </Button>
                  </Grid>
                </Grid>
              ) : businessCat === "School" && customerClassification === "TeachingStaff" && schoolTypeName === "University/College" ? (
                <Grid container>
                  {showWallet && (
                    <Grid item>
                      <Button onClick={() => (isTermlyFeePaidByStudent === true ? navigate("/dashboard?loadStudentWallet") : handleOpenModalActivationFee())} style={{ background: "#032541", width: "195px", marginRight: "10px", height: "45px", textTransform: "inherit", color: "#ffff" }}>
                        Load Pocket Money
                      </Button>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate("/school/request/service");
                      }}
                      style={{ border: "1px solid #032541", height: "45px", width: "145px", textTransform: "inherit", color: "#032541" }}
                    >
                      {" "}
                      Request Service
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Box>
          </div>
          {businessCat === "School" && customerClassification === "Staff" ? (
            <Grid container mt={3} mb={3} spacing={3}>
              <Grid item style={{ width: "60%" }}>
                <Paper sx={{ height: "138px", margin: "auto", px: 1 }}>
                  <Grid width={"100%"} height={"100%"} justifyContent={"space-between"} container alignItems={"center"} spacing={1}>
                    <Grid item>
                      <img src={profilePicture} alt="profilepicture" />
                    </Grid>
                    <Grid item>
                      <Typography style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ color: "#000000", fontWeight: 600, fontSize: "20px" }}>
                          {userData?.firstName} {userData?.lastName}
                        </span>{" "}
                        <span style={{ color: "#17ae7b", fontSize: "14px", marginLeft: "10px" }}>{userData?.status}</span>{" "}
                      </Typography>
                      <Typography py={1} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ color: "#666f76", fontSize: "14px" }}>Staff No :</span> <span style={{ color: "#000", fontWeight: "bold", fontSize: "14px" }}>{userData?.admissionNo}</span>
                      </Typography>
                      <Typography style={{ display: "flex", alignItems: "center" }}>
                        FingerPrints : <span style={studentBioSet?.length > 0 ? { color: "#17ae7b", fontWeight: 600, marginLeft: "10px", fontSize: "14px" } : { color: "#dc3545", marginLeft: "10px", fontWeight: 600, fontSize: "14px" }}>{studentBioSet?.length > 0 ? "Set" : "Not Set"}</span>{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography py={1} style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ color: "#000", fontWeight: 600, fontSize: "14px" }}>Email </span> <span style={{ fontSize: "14px" }}>{userData?.email}</span>
                      </Typography>
                      <Typography py={1} style={{ display: "flex", flexDirection: "column" }}>
                        {" "}
                        <span style={{ color: "#000", fontWeight: 600, fontSize: "14px" }}>Mobile Number </span>
                        <span style={{}}>{userData?.phone}</span>{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item width={"40%"}>
                <Paper sx={{ height: "138px", px: 1, background: "#eaf7f2" }}>
                  <Grid container width={"100%"} height={"100%"} spacing={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                      <Grid container direction={"column"}>
                        <Grid item width={"90%"}>
                          <Typography style={{ color: "#032541", fontSize: "16px", fontWeight: "bold" }}>Pocket Money Balance</Typography>
                        </Grid>
                        <Grid item py={1}>
                          <Typography style={{ color: "#17ae7b", fontWeight: 700, fontSize: "16px" }}>{numberFormat(studentWallete)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid item style={{ width: "70px", alignItems: "center", display: "flex", justifyContent: "center", borderRadius: "rounded", background: "#17ae7b", height: "70px" }}>
                        <img src={TotalAmountIcon} alt="profilepicture" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : businessCat === "School" && customerClassification === "TeachingStaff" ? (
            <Grid container mt={3} mb={3} spacing={3}>
              <Grid item style={{ width: "25%" }}>
                <Paper sx={{ height: "138px", px: 1, width: "100%" }}>
                  <Grid width={"100%"} height={"100%"} justifyContent={"space-between"} container alignItems={"center"}>
                    <Grid item>
                      <img src={profilePicture} style={{ width: "70px", height: "70px" }} alt="profilepicture" />
                    </Grid>
                    <Grid item>
                      <Typography style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ color: "#000000", fontWeight: 600, fontSize: "20px" }}>
                          {userData?.firstName} {userData?.lastName}
                        </span>{" "}
                        <span style={{ color: "#17ae7b", fontSize: "14px", marginLeft: "10px" }}>{userData?.status}</span>{" "}
                      </Typography>
                      <Typography py={1} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ color: "#666f76", fontSize: "14px" }}>Staff No :</span> <span style={{ color: "#000", fontWeight: "bold", fontSize: "14px" }}>{userData?.admissionNo}</span>
                      </Typography>
                      <Typography style={{ display: "flex", alignItems: "center" }}>
                        FingerPrints : <span style={studentBioSet?.length > 0 ? { color: "#17ae7b", fontWeight: 600, marginLeft: "10px", fontSize: "14px" } : { color: "#dc3545", marginLeft: "10px", fontWeight: 600, fontSize: "14px" }}>{studentBioSet?.length > 0 ? "Set" : "Not Set"}</span>{" "}
                      </Typography>
                    </Grid>
                    {/* <Grid item>
                                            <Typography py={1} style={{ display: 'flex', flexDirection: 'column' }}><span style={{ color: '#000', fontWeight: 600, fontSize: '14px' }}>Email </span> <span style={{ fontSize: '14px' }}>{userData?.email}</span></Typography>
                                            <Typography py={1} style={{ display: 'flex', flexDirection: 'column' }}> <span style={{ color: '#000', fontWeight: 600, fontSize: '14px' }}>Mobile Number </span><span style={{}}>{userData?.phone}</span> </Typography>
                                        </Grid> */}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item style={{ width: "25%" }}>
                <Paper sx={{ height: "138px", width: "100%" }}>
                  <Grid item style={{ background: `url(${BackImgC})`, height: "100%", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} width={"100%"} height={"100%"} justifyContent={"space-between"} container alignItems={"center"}>
                    <Grid container direction={"column"}>
                      <Typography style={{ color: "#032541", fontWeight: "bold", marginLeft: "10px" }}>{userData?.unitsCount ? userData?.unitsCount : 0}</Typography>
                      <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "10px", marginLeft: "10px", color: "#000" }}>Units Assigned</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item style={{ width: "25%" }}>
                <Paper sx={{ height: "138px" }}>
                  <Grid item style={{ background: `url(${BackImgA})`, height: "100%", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} width={"100%"} height={"100%"} justifyContent={"space-between"} container alignItems={"center"}>
                    <Grid container direction={"column"}>
                      <Typography style={{ color: "#17ae7b", fontWeight: "bold", marginLeft: "10px" }}>{userData?.completedSessions ? userData?.completedSessions : 0}</Typography>
                      <Typography style={{ fontWeight: 500, fontSize: "16px", marginTop: "10px", marginLeft: "10px", color: "#000" }}>Completed Sessions</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item width={"25%"}>
                <Paper sx={{ height: "138px", px: 1, background: "#eaf7f2" }}>
                  <Grid container width={"100%"} height={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      <Grid container direction={"column"}>
                        <Grid item width={"90%"}>
                          <Typography style={{ color: "#032541", fontSize: "16px", fontWeight: "bold" }}>Pocket Money Balance</Typography>
                        </Grid>
                        <Grid item py={1}>
                          <Typography style={{ color: "#17ae7b", fontWeight: 700, fontSize: "16px" }}>{numberFormat(studentWallete)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <img src={TotalAmountIcon} style={{ background: "#17ae7b", borderRadius: "10px", padding: "5px 5px" }} alt="profilepicture" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item width={"100%"}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: isMuiMobile ? "" : "space-between",
                      flexDirection: isMuiMobile ? "column" : "row",
                      height: isMuiMobile ? "200px" : "150px",
                      width: "100%",
                      boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)",
                      borderRadius: "15px",
                      marginTop: "3%",
                      padding: "20px",
                    }}
                  >
                    {isMuiMobile ? null : (
                      <div style={theColumns}>
                        <img
                          src={profilePicture}
                          alt="Profile Pictur"
                          style={{
                            height: isMuiMobile ? "50px" : "110px",
                            width: isMuiMobile ? "50px" : "110px",
                          }}
                        />
                      </div>
                    )}
                    <div style={theColumns}>
                      <div style={{ color: "#032541", fontSize: isMuiMobile ? "14px" : "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                        {userData?.firstName} {userData?.lastName}
                      </div>
                      {isMuiMobile ? null : <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Added {convertDate(userData?.createdAt)}</div>}
                      {isMuiMobile ? null : (
                        <div style={{ color: "#032541", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>
                          <span style={{ fontWeight: "bold" }}>Customer type: </span>
                          <span style={{ color: "#666f76" }}>{userData?.customerType}</span>
                        </div>
                      )}
                    </div>
                    <div style={theColumns}>
                      <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                        <span style={{ fontWeight: "bold", color: "#032541", fontSize: isMuiMobile ? "12px" : "" }}>Address: </span>
                        <span style={{ color: "#666f76" }}>{userData?.country}</span>
                      </div>
                      {notSchoolAndRent ? null : (
                        <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                          {" "}
                          {schoolTypeName?.includes("University") ? (
                            <span style={{ fontWeight: "bold" }}>
                              Course:<span style={{ color: "#666f76", textTransform: "uppercase" }}>{userData?.courseName} </span>{" "}
                            </span>
                          ) : (
                            <span style={{ fontWeight: "bold" }}>
                              No Of Students:<span style={{ color: "#666f76" }}>{students?.length} </span>{" "}
                            </span>
                          )}
                        </div>
                      )}
                      {/*<div style={{commonTextSize,color:"#17ae7b", fontWeight:"bold",paddingTop:"0.25rem", paddingBottom:"0.25rem"}}>Current Balance: <span>{numberFormat(userData?.totalBalance)}</span></div>*/}
                    </div>
                    <div style={theColumns}>
                      <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                        <span style={{ fontWeight: "bold", color: "#032541", fontSize: isMuiMobile ? "12px" : "" }}>Email: </span>
                        <span style={{ color: "#666f76" }}>{userData?.email}</span>
                      </div>
                      <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                        <span style={{ fontWeight: "bold", color: "#032541", fontSize: isMuiMobile ? "12px" : "" }}>Mobile Number: </span>
                        <span style={{ color: "#666f76" }}>{userData?.phone}</span>
                      </div>
                      {/*<div style={{commonTextSize ,paddingTop:"0.188rem", paddingBottom:"0.438rem"}}><span style={{fontWeight:"bold",color:"#032541"}}>Total Credited: </span><span style={{color:"#666f76"}}>{numberFormat(userData?.totalCredits)}</span></div>*/}
                      {/*<div style={{commonTextSize,color:"#dc3545", paddingBottom:"2.125rem"}}><span style={{fontWeight:"bold"}}>Total Expenses: </span> <span>{numberFormat(userData?.totalExpense)}</span></div>*/}
                    </div>
                    {isMuiMobile ? null : (
                      <div style={theColumns}>
                        <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                          <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Invoices: </span>
                          <span style={{ color: "#666f76" }}>{userData?.pendingInvoiceCount}</span>
                        </div>
                        <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
                          <span style={{ fontWeight: "bold", color: "#032541" }}>Pending Balance: </span>
                          <span style={{ color: "#666f76" }}>KES {userData?.pendingAmount}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </>
          )}
          <Grid container marginBottom="0.625rem" marginTop="0.625rem">
            {businessCat === "School" && customerClassification === "Staff" ? (
              <Grid item style={{ width: "100%" }}>
                <Grid container>
                  <Grid item>
                    <Typography style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>Recent Purchases</Typography>
                  </Grid>
                  <Grid item mt={3} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <CustomSearchInput placeholder={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                    <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={"Wallet Transactions"} title={"Wallet Transactions"} />
                  </Grid>
                  <Grid item mt={3} width={"100%"} height={"400px"}>
                    <CustomTable
                      data={walleteTransaction}
                      columns={[
                        {
                          field: "receiptNo",
                          headerName: "Order No",
                          flex: 1,
                          renderCell: (params) => {
                            return <Link to={`/receipt/`}>{params.value}</Link>;
                          },
                        },
                        { field: "transactionType", headerName: "Items", flex: 1 },
                        {
                          field: "createdAt",
                          headerName: "Date & Time",
                          flex: 1,
                          renderCell: (params) => {
                            return <>{DateFormatter(params?.row?.createdAt)}</>;
                          },
                        },
                        {
                          field: "transAmount",
                          headerName: "Amount",
                          flex: 1,
                          renderCell: (params) => {
                            return <spa>{numberFormat(params?.row?.transAmount)}</spa>;
                          },
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : businessCat === "School" && customerClassification === "TeachingStaff" ? (
              <Grid item style={{ width: "100%" }}>
                <TabContext value={value}>
                  <Box>
                    <AntTabs onChange={handleChange}>
                      <AntTab label="Recent Purchases" value="recentpurchase" />
                      <AntTab label="Recent Sessions" value="recentsessions" />
                    </AntTabs>
                  </Box>
                  <TabPanel value={"recentpurchase"}>
                    <Grid item style={{ width: "100%" }}>
                      <Grid container>
                        <Grid item mt={1} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                          <CustomSearchInput placeholder={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                          <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={"Wallet Transactions"} title={"Wallet Transactions"} />
                        </Grid>
                        <Grid item mt={1} width={"100%"} height={"400px"}>
                          <CustomTable
                            data={walleteTransaction}
                            columns={[
                              {
                                field: "receiptNo",
                                headerName: "Order No",
                                flex: 1,
                                renderCell: (params) => {
                                  return <Link to={`/receipt/`}>{params.value}</Link>;
                                },
                              },
                              { field: "transactionType", headerName: "Items", flex: 1 },
                              {
                                field: "createdAt",
                                headerName: "Date & Time",
                                flex: 1,
                                renderCell: (params) => {
                                  return <>{DateFormatter(params?.row?.createdAt)}</>;
                                },
                              },
                              {
                                field: "transAmount",
                                headerName: "Amount",
                                flex: 1,
                                renderCell: (params) => {
                                  return <spa>{numberFormat(params?.row?.transAmount)}</spa>;
                                },
                              },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={"recentsessions"}>
                    <Grid item style={{ width: "100%" }}>
                      <Grid container>
                        <Grid item mt={1} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                          <CustomSearchInput placeholder={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                          <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={"Wallet Transactions"} title={"Wallet Transactions"} />
                        </Grid>
                        <Grid item mt={1} width={"100%"} height={"400px"}>
                          <CustomTable
                            data={recentSessions}
                            columns={[
                              {
                                field: "sessionCode",
                                headerName: "Sessions",
                                flex: 1,
                                renderCell: (params) => {
                                  return <Link to={`/receipt/`}>{params.value}</Link>;
                                },
                              },
                              { field: "unitName", headerName: "Units", flex: 1 },
                              {
                                field: "createdAt",
                                headerName: "Date & Time",
                                flex: 1,
                                renderCell: (params) => {
                                  return (
                                    <Grid item>
                                      <Typography style={{ fontSize: "12px" }}>
                                        {DateFormatter(params?.row?.startTime)} - {DateFormatter(params?.row?.endTime)}
                                      </Typography>
                                    </Grid>
                                  );
                                },
                              },
                              {
                                field: "transAmount",
                                headerName: "Minutes/Hours",
                                flex: 1,
                                renderCell: (params) => {
                                  const startTime = new Date(params?.row?.startTime);
                                  const endTime = new Date(params?.row?.endTime);

                                  const differenceInMillis = endTime - startTime;

                                  const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
                                  const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));
                                  return <span>{`${hours} hours, ${minutes} minutes`}</span>;
                                },
                              },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            ) : (
              <Grid item style={{ width: "100%" }}>
                <TabContext value={value}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <AntTabs onChange={handleChange} variant={isMuiMobile ? "scrollable" : "standard"}>
                      <AntTab label="Invoices" value="Invoices" />
                      <AntTab label="Transactions" value="Transactions" />
                      <AntTab label="Receipts" value="Receipts" />
                      {bsCategory === "School" && schoolTypeName === "University/College" ? <AntTab label="Pocket Money" onClick={() => (isTermlyFeePaidByStudent === false ? handleOpenModalActivationFee() : null)} value="pocketmoney" /> : null}
                      {notSchoolAndRent || bsCategory !== "School" || schoolTypeName === "University/College" ? null : <AntTab label="Students" value="Students" />}
                      {bsCategory === "School" ? <AntTab label="Student Wallet" value="studentWallet" /> : null}
                    </AntTabs>
                    {userGroup === "Customer" ? (
                      <Box>
                        <Button onClick={() => navigate(`/viewstatement/${customerId}`)} style={{ textTransform: "inherit", background: "#17ae7b", color: "#fff" }}>
                          View Statement
                        </Button>
                      </Box>
                    ) : null}
                  </Box>

                  <TabPanel value={"Students"}>
                    <Grid item style={{ width: "100%", height: "350px" }}>
                      <CustomTable
                        data={students}
                        columns={[
                          {
                            field: "itemNumber",
                            headerName: "Admission Number No",
                            flex: 1,
                            renderCell: (params) => {
                              return <Link to={`/school/student/${customerId}/${params.value}`}>{params.value}</Link>;
                            },
                          },
                          {
                            field: "studentName",
                            headerName: "Student Name",
                            flex: 1,
                            renderCell: (params) => {
                              return (
                                <Typography>
                                  {params.row.firstName} {params.row.lastName}
                                </Typography>
                              );
                            },
                          },
                          {
                            field: "grade",
                            headerName: "Grade",
                            flex: 1,
                            renderHeader: () => {
                              return <Typography>Grade</Typography>;
                            },
                          },
                          { field: "term", headerName: "Term", flex: 1 },
                          {
                            field: "additionalInfo",
                            headerName: "Additional Info",
                            flex: 1,
                            renderCell: (params) => {
                              return <Typography>{params.value !== "" ? params.value : "N/A"}</Typography>;
                            },
                          },
                          { field: "pendingInvoices", headerName: "Pending Invoices", flex: 1 },
                          {
                            field: "pendingBalance",
                            headerName: "Amount Due",
                            flex: 1,
                            renderCell: (params) => {
                              return <Typography>KES {params.value}</Typography>;
                            },
                          },

                          {
                            field: "action",
                            headerName: "Actions",
                            flex: 1,
                            renderCell: (params) => {
                              return <MoreVertIcon />;
                            },
                          },
                        ]}
                      />
                    </Grid>
                  </TabPanel>

                  <TabPanel value="Transactions">
                    {notSchoolAndRent && <Customertransaction customerId={localStorage.getItem("customerId")} />}

                    {!notSchoolAndRent && (
                      <div
                        style={{
                          height: "350px",
                          minWidth: "100%",
                        }}
                      >
                        <CustomTable
                          data={customerTransRows}
                          columns={[
                            { field: "transactionNo", headerName: "Transaction No", flex: 1 },
                            { field: "transactionTime", headerName: "Date", flex: 1 },
                            { field: "noOfItems", headerName: "No. Of Items", flex: 1 },
                            { field: "business", headerName: "Business", flex: 1 },
                            { field: "branchName", headerName: "Branch", flex: 1 },
                            { field: "amount", headerName: "Amount", flex: 1 },
                            { field: "approvedBy", headerName: "Approved By", flex: 1 },
                          ]}
                          rowsPerPage={5}
                        />
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel value="Receipts">
                    {notSchoolAndRent && <Customeractivity customerId={localStorage.getItem("customerId")} />}

                    {!notSchoolAndRent && (
                      <div
                        style={{
                          height: "350px",
                          minWidth: "100%",
                        }}
                      >
                        <CustomTable
                          data={receivedReceipts}
                          columns={[
                            {
                              field: "receiptNo",
                              headerName: "Receipt Number",
                              flex: 1,
                              renderCell: (params) => {
                                return <Link to={`/receipt/${params.value}/${params.row.invoiceNumber}`}>{params.value}</Link>;
                              },
                            },
                            { field: "invoiceNumber", headerName: "Invoice Number", flex: 1 },
                            { field: "from", headerName: "From", flex: 1 },
                            { field: "amount", headerName: "Amount Paid", flex: 1 },
                            { field: "issuedDate", headerName: "Date Issued", flex: 1 },
                          ]}
                          rowsPerPage={5}
                        />
                      </div>
                    )}
                  </TabPanel>

                  <TabPanel value="Invoices">
                    {notSchoolAndRent && <CustomerInvoice customerId={localStorage.getItem("customerId")} />}

                    {!notSchoolAndRent && (
                      <div
                        style={{
                          height: "380px",
                          minWidth: "100%",
                        }}
                      >
                        <CustomTable
                          data={receivedRows}
                          columns={[
                            {
                              field: "invoiceNumber",
                              headerName: "Invoice No",
                              flex: 1,
                              renderCell: (params) => {
                                return <Link to={`/invoice/${params.value}`}>{params.value}</Link>;
                              },
                            },
                            { field: "createdAt", headerName: "Date Issued", flex: 1 },
                            { field: "invoiceAmount", headerName: "Amount", flex: 1 },
                            { field: "invoiceDiscountAmount", headerName: "Discount Amount", flex: 1 },
                            { field: "amountPaid", headerName: "Amount Paid", flex: 1 },
                            { field: "invoiceType", headerName: "Type", flex: 1 },
                            {
                              field: "invoiceStatus",
                              headerName: "Status",
                              flex: 1,
                              renderCell: (params) => {
                                return (
                                  <Typography
                                    sx={{
                                      color: params.value === "Paid" ? "#17ae7b" : params.value === "Unpaid" ? "#dc3545" : params.value === "Partially Paid" ? "#ff8503" : "#032541",
                                    }}
                                  >
                                    {params.value}
                                  </Typography>
                                );
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel value="pocketmoney">
                    {(businessCat === "School" || schoolTypeName === "University/College") && isTermlyFeePaidByStudent === true ? (
                      <div
                        style={{
                          height: "380px",
                          minWidth: "100%",
                        }}
                      >
                        <CustomTable
                          data={walleteTransaction}
                          columns={[
                            {
                              field: "receiptNo",
                              headerName: "Receipt No",
                              flex: 1,
                              renderCell: (params) => {
                                return <Link to={`/receipt/`}>{params.value}</Link>;
                              },
                            },
                            {
                              field: "transAmount",
                              headerName: "Amount",
                              flex: 1,
                              renderCell: (params) => {
                                return (
                                  <spa>
                                    {localCurrency} {params?.row?.transAmount}
                                  </spa>
                                );
                              },
                            },
                            { field: "transactionType", headerName: "Transaction Type", flex: 1 },
                            {
                              field: "createdAt",
                              headerName: "Date & Time",
                              flex: 1,
                              renderCell: (params) => {
                                return <>{DateFormatter(params?.row?.createdAt)}</>;
                              },
                            },
                            { field: "source", headerName: "Source", flex: 1 },
                            // { field: 'studentName', headerName: 'Student Name', flex: 1 },
                          ]}
                        />
                      </div>
                    ) : null}
                  </TabPanel>
                  <TabPanel value="studentWallet">
                    {businessCat === "School" || schoolTypeName === "University/College" ? (
                      <div
                        style={{
                          height: "380px",
                          minWidth: "100%",
                        }}
                      >
                        {schoolTypeName !== "University/College" ? (
                          <Grid item mt={3} width={"50%"}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Select Student</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={""}
                                label="Select Student"
                                onChange={handleStudentSelection}
                              >
                                {students?.map((item) => (
                                  <MenuItem value={item}>{item?.firstName + " " + item?.lastName}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        <CustomTable
                          data={walleteTransaction}
                          columns={[
                            {
                              field: "receiptNo",
                              headerName: "Receipt No",
                              flex: 1,
                              renderCell: (params) => {
                                return <Link to={`/receipt/`}>{params.value}</Link>;
                              },
                            },
                            {
                              field: "transAmount",
                              headerName: "Amount",
                              flex: 1,
                              renderCell: (params) => {
                                return (
                                  <spa>
                                    {localCurrency} {params?.row?.transAmount}
                                  </spa>
                                );
                              },
                            },
                            { field: "transactionType", headerName: "Transaction Type", flex: 1 },
                            {
                              field: "createdAt",
                              headerName: "Date & Time",
                              flex: 1,
                              renderCell: (params) => {
                                return <>{DateFormatter(params?.row?.createdAt)}</>;
                              },
                            },
                            { field: "source", headerName: "Source", flex: 1 },
                            // { field: 'studentName', headerName: 'Student Name', flex: 1 },
                          ]}
                        />
                      </div>
                    ) : null}
                  </TabPanel>
                </TabContext>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Modal open={openModalPayActivationFee} onClose={handleCloseModalActivationFee} slotProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.1)" } } }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid item display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Grid container display={"flex"} spacing={4}>
              <Grid item display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Grid item>
                  <img src={WarningImg} alt="Warning" />
                </Grid>
                <Grid item ml={2}>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Service Activation
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Pay a termly Activation fee of <span style={{ color: "#032541", fontWeight: 600 }}> {numberFormat(termlyAmount)}</span> to activate this service
                  </Typography>
                  <Typography>Click Activate to proceeed</Typography>
                  <Grid mt={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Button onClick={generateTermlyInvoice} style={{ width: "200px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#17ae7b" }}>
                      Activate
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerAccountDash;
