import React, { useState, useEffect } from 'react'
import  { Grid } from "@mui/material"
import SearchFilter from '../../customerAccounts/customerComponents/searchFilter'
import CustomSelect from './customSelect'
import CustomDataGrid from './customDataGrid'
import StockFunctions from './stockEndpoints'

const Activestockmainstore = () => {

    
    const [warehouseId, setItemId] = useState("");
    const [searchValue, setInputValue] = useState("")
    const [mainStoresData, setMainStores] = useState([])
    
    const [dataGridPageSize, setPageSize] = React.useState(5);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, mainstores: [], warehouseLoading: true, mainstorestock: [] });
  
    
    useEffect(() => {

        const stockFnc = new StockFunctions();
        stockFnc.findStoresByType("MainStore", pageState.page, dataGridPageSize).then((data) => {
          if (data.status === 200) {
          
            setMainStores(data?.response?.data)
          }
        });
    }, [])


    useEffect(() => {
        // storeType, storeId,  page, pagesize
        if (warehouseId) {
          const stockFnc = new StockFunctions();
          stockFnc.getStockPerStore(warehouseId, searchValue , pageState.page, dataGridPageSize).then((data) => {
            if (data.status === 200) {
              setPageState({ ...pageState, isLoading: false, mainstorestock: data?.response?.data, count:data?.response?.count });
            }
          });
        }
      }, [dataGridPageSize, pageState.page , searchValue, warehouseId]);


        // main storehouse columns;

  const mainStoreCoulmn = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "productCategory", headerName: "Category", flex: 1 },
    { field: "inStockQuantity", headerName: "Instock", flex: 1 },
    { field: "sellingPrice", headerName: "selling Price (KES)", flex: 1 },
  ];

  const mainstoreData = pageState?.mainstorestock.map((warehouse) => ({
    id: warehouse._id,
    productName: warehouse?.productName,
    productCategory: warehouse?.productCategory,
    inStockQuantity: warehouse?.inStockQuantity,
    sellingPrice: warehouse?.sellingPrice,
  }));


  // useEffect(() => {
  //   // storeType, storeId,  page, pagesize
  //   if (searchValue) {
  //     const stockFnc = new StockFunctions();
  //     stockFnc.searchProductsPerStore(warehouseId, pageState.page, dataGridPageSize, searchValue).then((data) => {
        
  //       if (data.status === 200) {
  //         setPageState({ ...pageState, isLoading: false, mainstorestock: data?.response?.data, count:data?.response?.count });
  //       }
  //     });
  //   }
  // }, [dataGridPageSize, pageState.page, warehouseId, searchValue]);


    
  return (
    <div style={{ width: "100%" }}>
    <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"} >

    <Grid item style={{ width: "30%", margin:'0px 2% 0px 0px'}}>
        <CustomSelect data={mainStoresData} setItemId={setItemId} />
      </Grid>

      <Grid item style={{width:"30%"}}>
        <SearchFilter placeholder={'Search product'} setInputValue={setInputValue} />
      </Grid>

    </Grid>

    <Grid container direction={"row"} width={"100%"}>
    <CustomDataGrid column={mainStoreCoulmn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={mainstoreData} />
    </Grid>
  </div>
  )
}

export default Activestockmainstore