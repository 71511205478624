import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

const AutocompleteComponent = ({ label, styles, setInputValue, setSelectValue, data }) => {

  const getOptionLabel = (option) => option.name

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        getOptionLabel={getOptionLabel}
        onChange={(event, newValue) => {
          setInputValue(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setSelectValue(newInputValue)
        }}
        options={data}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            sx={styles}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </div>
  )
}

export default AutocompleteComponent