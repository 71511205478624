import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    autoIncrementSet: null,
    prefixForAllStudent: null,
    differentPrefixForStudent: []
}

const studentAutogenerateAdmissionSlice = createSlice({
    name: 'studentadmissiongenerate',
    initialState,
    reducers: {
        handleAutoIncrement: (state, action) => {
            state.autoIncrementSet = action.payload
        },
        handlePrefixForAllStudent: (state, action) => {
            state.prefixForAllStudent = action.payload
        },
        handleDifferentPrefixForStudent: (state, action) => {
            const studentGradePrefix = { ...action.payload }
            state.differentPrefixForStudent = [...state.differentPrefixForStudent, studentGradePrefix]

        }
    }

})

export const { handleAutoIncrement, handlePrefixForAllStudent, handleDifferentPrefixForStudent } = studentAutogenerateAdmissionSlice.actions
export default studentAutogenerateAdmissionSlice.reducer;