import {FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from "../../../School/ExportMenu";
import creditTotalIcon from "../../../../images/creditTotal.svg"
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import DateFormatter from "../../../../utils/dateFormatter";
import HttpComponent from "../../../School/MakeRequest";
import ExportDataFromApi from "../../../SuperAdmin/components/Reports/ExportFromApi";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";

let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? "KES" : localCurrency, }).format(value);


export default function AllPayitCashTransactionsAdmin() {

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [allTotals, setTotals] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("Last7days");
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const group = localStorage.getItem('group')


    useEffect(() => {

        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1));
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last7days":
                function setDefaultDates() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);
                    console.log(lastSevenLast , 'lets goo')
                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                setDefaultDates()
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1);
                const currentDay = new Date();
                console.log(currentDay , 'fu')
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1);
                const lastDayLastMonth = new Date(date2.setDate(1));
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                setDefaultDates();
                break;
        }
    }, [handleDaysChange]);

    const [allRegions, setAllRegions] = useState([])
    const [selectedRegion, setSelectedRegion] = useState('')
    function getAllRegionsList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/partnerregions`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllRegions(data?.response?.data?.filter(item => item?.status === 'Active'))
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllRegionsList()
    }, [])

    const [allBranches, setAllBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState('')

    function getAllBranchesList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/allregionpartnerbranches`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const myfoundbranch = data?.response?.data?.filter(item => item?.status === 'Active')?.map((item) => ({
                        value: item?.branchCode,
                        label: item?.branchName
                    }))
                    setAllBranches(myfoundbranch)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllBranchesList()
    }, [])

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    //all  transactions columns

    const CardTransactionColumns = [
        { headerName: "RRN", field: 'rrn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >RRN</strong>) } },
        { headerName: "Receipt No", field: 'receiptNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Receipt No</strong>) } },
        { headerName: "Created At", field: 'transTime', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <span>{DateFormatter(params?.row?.transactionTime)}</span> },
        { headerName: "Source", field: 'source', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Source</strong>) }, renderCell: (params) => <span>{params?.row?.source}</span> },
        { headerName: "Transaction Type", field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >TxN Type</strong>) }, renderCell: (params) => <Typography sx={{ color: params?.row?.transactionType === 'DEBIT' ? '#dc3545' : params?.row?.transactionType === 'CREDIT' ? '#17ae7b' : '#707070' }}>{params?.row?.transactionType}</Typography> },
        { headerName: "Instution", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Institution</strong>) } },
        { headerName: "Teller Id", field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) } },
        { headerName: "Terminal Serial", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) } },
        { headerName: "amount", field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Amount ({localCurrency})</strong>) }, renderCell: (params) => <span>{numberFormat(params?.row?.amount)}</span> },
    ]

    // fetch data

    function getCardTransactions() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/reports/payitcashreportsByAdmin?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}&regionId=${selectedRegion}&branchCode=${selectedBranch}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    setTotals(data?.response?.total)

                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getCardTransactions();
    }, [dataGridPageSize, pageState.page, searchValue, startDate, endDate ,handleDaysChange ,selectedBranch ,selectedRegion])

    //export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "Receipt No": data?.receiptNo,
            "Date & Time": DateFormatter(data?.transTime),
            "Source": data?.source,
            "TXN Type": data?.transactionType,
            "Institution": data?.businessName,
            "Teller Id": data?.tellerId,
            "Terminal Serial": data?.terminalSerialNumber,
            "Total Amount": numberFormat(data?.amount)
        }
    })
    const fileHeaders = [["Receipt No", "Date & Time", "Source", "TXN Type", "Institution", 'Teller Id', 'Terminal Serial', 'Total Amount']]
    const csvColumns = [
        { label: "Receipt No", key: "Receipt No" },
        { label: "Date & Time", key: "Date & Time" },
        { label: "Source", key: "Source" },
        { label: "TXN Type", key: "TXN Type", },
        { label: "Institution", key: "Institution" },
        { label: "Teller Id", key: "Teller Id", },
        { label: "Terminal Serial", key: "Terminal Serial", },
        { label: "Total Amount", key: "Total Amount", }
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.receiptNo, DateFormatter(data?.transTime), data?.source, data?.transactionType, data?.businessName, data?.tellerId, data?.terminalSerialNumber, numberFormat(data?.amount)]);


    // export csv

    function handleExportCSV() {
        try {
          HttpComponent({
            method: 'POST',
            url: `/api/v1/reports/payitcashreportsByAdminExports`,
            body: {
              startDate: startDate,
              endDate: endDate,
              searchValue:searchValue
            },
            token: localStorage.getItem("X-Authorization"),
          }).then((data)=>{
            if(data?.status === 200){
               setSuccessShow({state:true , message:data?.response?.message})
            }else{
              setErrorShow({state:true , message:data?.response?.message})
            }
          })
        } catch (error) {
            setErrorShow({state:true , message:error?.message})
        }
      }
    


    return (
        <Grid container display={'flex'} direction={'column'}>
              <SuccessAlert vertical="top" horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Typography style={{ color: "#032541", fontWeight: 'bold', margin: '10px 0px', fontSize: "25px", fontFamily: "Poppins" }}>Cash Transactions</Typography>
            </Grid>
            <Grid item mt={2} display={'flex'}>
                <Paper elevation={2} sx={{ width: '100%', borderRadius: '5px', background: '#f4fdfa', height: '78px' }}>
                    <Grid item width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid container display={'flex'} px={1} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item display={'flex'} direction={'column'}>
                                <Typography my={1} sx={{ color: '#9c9ea4', lineHeight: 1.25, fontWeight: 'normal', fontSize: '14px' }}>Transaction Type</Typography>
                                <Typography my={1} sx={{ color: '#17ae7b', lineHeight: 1.25, fontWeight: 'normal', fontSize: '14px' }}>Credit</Typography>
                            </Grid>
                            <Grid item display={'flex'} direction={'column'}>
                                <Typography my={1} sx={{ color: '#9c9ea4', lineHeight: 1.25, fontWeight: 'normal', fontSize: '14px' }}>Total amount</Typography>
                                <Typography my={1} sx={{ color: '#17ae7b', lineHeight: 1.25, fontWeight: 'normal', fontSize: '14px' }}>{numberFormat(allTotals)}</Typography>
                            </Grid>
                            <Grid item display={'flex'} direction={'column'}>
                                <img alt="" src={creditTotalIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item display={'flex'} mt={4} justifyContent={'space-between'} alignItems={'center'}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <TextField value={searchValue} onChange={(e) => setSearchValue(e.target.value)} name={'search'} placeholder={'search'} variant="outlined" margin="dense"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                //defaultValue={'Last7days'}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value={'All'}>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last7days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {group === 'Partner' && (
                        <Grid item>
                            <FormControl sx={{ width: '190px' }}>
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Region"
                                    value={selectedRegion}
                                    //defaultValue={'Last7days'}
                                    onChange={(e) => setSelectedRegion(e.target.value)}
                                >
                                    {allRegions?.map((region) => {
                                        return (
                                            <MenuItem value={region?._id}>{region?.regionName}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {group === 'Partner' && (
                    <Grid item>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Branch"
                                value={selectedBranch}
                                onChange={(e) => setSelectedBranch(e.target.value)}
                            >
                                {allBranches?.map((branch) => {
                                    return (
                                        <MenuItem value={branch?.value}>{branch?.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                      )}
                    <Grid item>
                        <ExportDataFromApi handleDownloadData={handleExportCSV} />
                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Card Transactions'} title={'Zed-Pocket Money Card Transactions'} /> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={CardTransactionColumns}
                    rows={pageState?.data}
                    getRowId={row => row._id} />
            </Grid>
        </Grid>
    )
}