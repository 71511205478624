import { Typography, Grid, Breadcrumbs, TextField, FormControl, InputLabel, Select, MenuItem, Button, Checkbox } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import CustomInputLabel from "../../School/CustomInputLabel";


//breadcrumbs

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Products / Services
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Create Product / Service
    </Typography>

];

//POST https://dev.zed.business/api/createProduct

export default function CreateNewServiceProduct(props) {
    const [productName, setProductName] = useState('')
    const [productType, setProductType] = useState([])
    const [prodCat, setProdCat] = useState()
    const [productCategory, setProductCategory] = useState([])
    const [reorderLevel, setReorderLevel] = useState(0);
    const [buyingPrice, setBuyingPrice] = useState()
    const [amount, setAmount] = useState()
    const [productDescription, setProductDescription] = useState()
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [dataGridPageSize, setPageSize] = useState(5);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [unitOfMeasure, setUnitOfMeasure] = useState([])
    const [unitofMeasureValue, setUnitOfMeasureValue] = useState("")
    const [consumable, setConsumables] = useState(false);
    const [isWeightedProduct, setIsWeightProduct] = useState(false);
    const [ectizenAccount_Id, setEctizenAccount_Id] = useState("");
    const [ectizenitem_ref, setEctizenitem_ref] = useState("");
    // authorization & constants

    let businessId = localStorage.getItem('businessId')
    let businessNumber = useSelector(state => state.outletDetails.businessNumber)

    const Group = localStorage.getItem("group");
    const isPartner = Group && Group == "Partner";

    if (isPartner) {
        businessId = businessNumber
    }

    const { X_Authorization } = useSelector((store) => store.user)
    const [productTypes, setProductTypes] = useState([])

    // fetch service type from db

    function getTypeOPtions() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/getVariablePriceStatus`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setProductTypes(data?.response?.data)
                }

            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getTypeOPtions()
    }, [])

    //service type
    const typeOptions = [
        {
            value: "Fixed",
            label: "Fixed",
        },
        {
            value: "Variable Price Item",
            label: "Variable Price Item",
        }
    ]

    const mapOptions = productTypes?.map((option) => ({ value: option?.priceStatusName, label: option?.priceStatusName }))
    const productCa = productCategory?.map((option) => (option.categoryName))




    const formData = { businessID: businessId, priceStatus: productType, productCategory: prodCat, reorderLevel: reorderLevel, buyingPrice: buyingPrice, productName: productName, productPrice: amount, productDescription: productDescription, unitOfMeasure: unitofMeasureValue, consumable: consumable, isWeightedProduct: isWeightedProduct, ectizenAccount_Id: ectizenAccount_Id, ectizenitem_ref: ectizenitem_ref }
    // console.log(formData, 'wertyu')

    //fetch product categories

    function fetchProductCategories() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listCategories/Active?productService=Product&businessNumber=${businessNumber}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setProductCategory(data.response.data)
                }
                console.log(data, 'product categoriess')
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProductCategories()
    }, [])

    const getUnitOfMeasure = () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/products/unitofmeasure/get`,
            token: localStorage.getItem("X-Authorization")
        }).then((data) => {
            if (data?.status == 200 || data?.status == 201) {
                setUnitOfMeasure(data.response.data)
            }
        })
    }

    useEffect(() => {
        getUnitOfMeasure()
    }, [])

    //fetch pending products
    const [newprod, setNewProd] = useState([])

    function fetchnewprod() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/listProducts/New?${pageState.page}&limit=${pageState.pageSize}&search=`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                setNewProd(data.response.data)
                console.log(data, 'New products')
            })

        } catch (error) {

        }
    }

    //effect to re run

    useEffect(() => {
        fetchnewprod()
    }, [])

    // submit product data
    const navigate = useNavigate()

    function submitCreateProductorService() {
        if (!formData) {
            return setErrorShow({ state: true, message: `${formData} is missing` })
        }
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/createProduct`,
                body: formData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'product created')

                if (data.status === 201) {
                    setSuccessShow({ state: true, message: data.response.message })

                    setTimeout(() => {
                        props.goBack();
                    }, [1000])

                } else {
                    setErrorShow({ state: true, message: data.response.message })
                }
                console.log(data, 'new product created')
            })
        } catch (error) {

        }
    }

    const [ectizenStatus, setEctizenStatus] = useState(false)




    const checkEcitizenStatusMethod = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/ecitizen/get_status`,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data.status === 200) {
                let status = data.response?.ecitizenStatus
                console.log(status, 'status---')
                setEctizenStatus(status)

            } else {
            }
        }).catch((error) => {
            console.error(error, 'error');
        })


    }

    useEffect(() => {
        checkEcitizenStatusMethod()
    }, [])



    return (
        <>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid container direction={'column'}>
                <Grid item display={'flex'} alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
                    <ArrowBackIosIcon onClick={props?.goBack} />
                    <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Product / Service </Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Create a Product /Service</Typography>
                </Grid>
                <Grid item mb={'10px'} mt={'10px'}>
                    <FormControl sx={{ minWidth: 400 }}>
                        <InputLabel id="demo-simple-select-label">Category :</InputLabel>
                        <Select
                            label="Category :"
                            value={prodCat}
                            onChange={(e) => setProdCat(e.target.value)}
                            displayEmpty
                        >
                            {productCa?.map((option) => {
                                return (
                                    <MenuItem value={option}>{option}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField id="outlined-basic" value={productName} onChange={(e) => setProductName(e.target.value)} label="Product Name" style={{ fontWeight: 200, width: "400px" }} />
                </Grid>
                <Grid item mt={2} mb={2}>
                    <TextField id="outlined-basic" value={productDescription} onChange={(e) => setProductDescription(e.target.value)} label="Description" style={{ fontWeight: 200, width: "400px" }} />
                </Grid>
                <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-label">Amount Type </InputLabel>
                        <Select
                            label="Category :"
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            displayEmpty
                        >
                            {mapOptions.map((option) => {
                                return (
                                    <MenuItem value={option.value}>{option.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" value={amount} onChange={(e) => setAmount(e.target.value)} label="Selling Price" style={{ fontWeight: 200, marginLeft: '5px', width: "200px" }} />
                </Grid>

                <Grid item mt={2} mb={2}>
                    <CustomInputLabel required={true} label={"Buying Price"} />
                    <TextField id="outlined-basic" type="number" value={buyingPrice} onChange={(e) => setBuyingPrice(e.target.value)} style={{ fontWeight: 200, width: "400px" }} />
                </Grid>


                <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
                    <FormControl sx={{ minWidth: 400 }}>
                        <InputLabel id="demo-simple-select-label">Unit of Measurement </InputLabel>
                        <Select
                            label="Unit of Measure"
                            value={unitofMeasureValue}
                            onChange={(e) => setUnitOfMeasureValue(e.target.value)}
                            displayEmpty
                        >
                            {unitOfMeasure.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>


                </Grid>

                {productType === "Variable Price Item" && <Grid item width={400} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Checkbox onChange={() => setIsWeightProduct(!isWeightedProduct)} /> <span style={{ fontFamily: 'Poppins', }}>Weighed Price Item</span>
                </Grid>}

                <Grid item width={400} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Checkbox onChange={() => setConsumables(!consumable)} /> <span style={{ fontFamily: 'Poppins', margin: '0 50px 0 0' }}>Consumable</span>
                </Grid>

                <Grid item mt={2} mb={2}>
                    <TextField id="outlined-basic" type="number" value={reorderLevel} onChange={(e) => setReorderLevel(e.target.value)} label="Reorder Level" style={{ fontWeight: 200, width: "400px" }} />
                </Grid>
                {ectizenStatus ? <>
                    <Grid item mt={2} mb={2}>
                        <CustomInputLabel required={true} label={"Ecitizen Service Id"} />

                        <TextField id="outlined-basic" type="text" value={ectizenAccount_Id} onChange={(e) => setEctizenAccount_Id(e.target.value)} style={{ fontWeight: 200, width: "400px" }} />
                    </Grid>
                    <Grid item mt={2} mb={2}>
                        <CustomInputLabel required={true} label={"Ecitizen Item Reference"} />
                        <TextField id="outlined-basic" type="text" value={ectizenitem_ref} onChange={(e) => setEctizenitem_ref(e.target.value)} style={{ fontWeight: 200, width: "400px" }} />
                    </Grid>
                </> : null}
                <Grid container>
                    <Grid item style={{ width: "400px" }} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                        <Button onClick={props?.goBack} style={{ border: "1px solid #dc3545", marginRight: '10px', color: "#dc3545", textTransform: 'inherit', width: "100px" }}>Cancel</Button>
                        <Button disabled={!prodCat || !buyingPrice || !productName || !productType || !amount || !productDescription || !unitofMeasureValue} onClick={submitCreateProductorService} style={!prodCat || !productName || !productType || !productDescription || !amount || !buyingPrice || !unitofMeasureValue ? { background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", opacity: .2 } : { background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", }}>Create</Button>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}