import {Button, Grid, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomSelectField from "../../../School/CustomSelectField";
import HttpComponent from "../../../School/MakeRequest";

export default function AddHouse(props) {
	
	const navigate = useNavigate()
	const [type, setType] = useState("")
	
	const [strings, setStrings] = useState([]);
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const {prefix, noOfUnits, startPrefix} = JSON.parse(localStorage.getItem('pendingUnit'))
	
	const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"
	
	function generateStrings() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unit_types_by_business_unit_config?limit=1000&page=1&businessUnitConfigId=${configId}`,
			token: X_Authorization
		}).then((data)=>{
			console.log("here store is data",data);
			if(data.status === 201){

			}
			else{
				console.error("Error setting info")
			}
			}).catch((error)=>{
			console.error(error.message);
		})
		//const newStrings = [];
		//for (let i = startPrefix; i <= noOfUnits; i++) {
		//	newStrings.push(`${prefix}${i}`);
		//}
		//setStrings(newStrings);
	}
	
	useEffect(() => {
		generateStrings()
	}, [])
	
	console.log(`Strings`, strings)
	const handleGoBackClick = () => {
		props.setFlagState('addUnit')
	};
	
	const handleListingClick = () => {
		
		props.setFlagStateListing('listUnits')
	}

	return (
		<div>
			{/*Header*/}
			<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={5} mt={3}>
				
				{/*label*/}
				<Grid item>
					<span style={{color : '#032541', fontSize : '16px', fontWeight : '500'}} >Add House No.</span>
				</Grid>
				
			</Grid>
			
			{/*Form map*/}
			<Grid container justifyContent={'center'} alignContent={'center'} direction={'row'} mt={3} >
				<Grid container direction={'row'} justifyContent={'space-between'} width={1000} spacing={2}>
					{strings.map((houseNumber, index) =>
						(
						<Grid key={index} item style={{display : 'flex', flexGrow : 1, flexShrink : 1}}>
							<TextField value={houseNumber} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '400px', }} id="outlined-basic" label="House" variant="outlined" required />
						</Grid>
						)
					)}
				</Grid>
			</Grid>
			
			<Grid container justifyContent={'center'} direction={'row'} spacing={2} mt={5}>
				<Grid item>
					<Button onClick={handleListingClick} style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px'}} >
						Save
					</Button>
				</Grid>
				<Grid item>
					{/*<Button onClick={handleGoBackClick} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px'}} >*/}
					{/*	Add More*/}
					{/*</Button>*/}
				</Grid>
			</Grid>

		</div>
	)
}