import {Button, ButtonBase, Grid, Checkbox, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {NoRowsOverlay} from "../../../No Rows/noRowsOverlay";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import HttpComponent from "../../../School/MakeRequest";
import SetWorkFlow from "../../Utility/updateSetupWorkFlow";
import {ErrorAlert} from "../../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../../snackBar Alerts/successAlert";
import NoOfUnits from "../../../../common/images/apartment.svg"

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);

export default function ListUnits(props) {
	const [data ,setData] = useState([])
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const { X_Authorization } = useSelector((store) => store.user);
	const [unitRows, setUnitRows] = useState([])
	const configId = businessUnitConfigId ? businessUnitConfigId : "63fe2f1a6fdd99c2416a4445"

	// Notifications
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
	const [successShow, setSuccessShow] = useState({state: false, message: ""})
	console.log(data , 'units')

	console.log(unitRows , 'unit rows')

	//fetch all units

	const getUnitsData = () => {
		HttpComponent({
			method: 'GET',
			url: '/api/get_business_unit_config_list',
			token: X_Authorization
		}).then((data) => {
			console.log("here store is data listststst", data.response.data);
			if (data.status === 201) {
				setData(data.response.data)
			} else {
				console.error("Error setting info")
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		getUnitsData()
	}, [])


	function handleRemoveUnit (unitId) {
		HttpComponent({
			method : 'POST',
			url : '/api/delete_business_unit_type',
			body : {unitTypeId : unitId},
			token : X_Authorization
		}).then((data) => {
			if (data.status === 201) {
				setSuccessShow({state:true, message:data.response.message})
				generateStrings()
			} else {
				setErrorShow({state:true, message:data.response.message})
			}
		}).catch((e) => {
			console.log('Error Removing Unit', e.message)
		})
	}

	function handleRowClick (data) {
		localStorage.setItem('unitsEditData', JSON.stringify(data))
	}
	const unitColumns = [
		{field : 'unitType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >House Type</strong>)},renderCell:(params)=>{
			return(
				<>
					<Checkbox></Checkbox>
					<Link to={'/setup/rental/rentalSetup'}>{params?.row?.unitType}</Link>
				</>
			)
		}},
		{field : 'unitTypeCount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Total Units</strong>)}},
		{field : 'unitCost', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Price (KES)</strong>)}, renderCell : (params) => {return numberFormat(params.value)}},
		{field : 'action', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Action</strong>)}, renderCell : (params) => {return (<div>
			<Grid container direction={'row'} justifyContent={'space-between'}>
				
				{/*Pencil*/}
				<Grid item style={{color : '#032541'}}>
					<ButtonBase onClick={() => {props.setFlagEditUnit(); handleRowClick(params.row)}}>
						<ModeEditIcon />
						<span style={{marginLeft : '5px'}}>Edit</span>
					</ButtonBase>
				</Grid>
				
				{/*Delete*/}
				<Grid item ml={3} style={{color : '#dc3545'}}>
					<ButtonBase onClick={() => {handleRemoveUnit(params.row._id)}} >
						<DeleteIcon />
						<span style={{marginLeft : '5px'}}>Remove</span>
					</ButtonBase>
				</Grid>
			</Grid>
		</div>)}},
	]

	const [dataGridPageSize, setPageSize] = React.useState(3);
	const [pageState, setPageState] = useState({isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize,})
	
	function generateStrings() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unit_types_by_business_unit_config?limit=${dataGridPageSize}&page=${pageState.page}&businessUnitConfigId=${configId}`,
			token: X_Authorization
		}).then((data)=>{
			console.log("here store is data",data);
			if(data.status === 201){
				setPageState((old) => ({
					...old,
					isLoading: false,
					data: data.response.data,
					total: data.response.count,
				}));
				setUnitRows(data.response.data)
			}
			else{
				console.error("Error setting info")
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}
	
	useEffect(() => {
		generateStrings()
	}, [pageState.page, pageState.pageSize, dataGridPageSize])
	
	const navigate = useNavigate()
	
	const handleGoBackClick = () => {
		props.setFlagStateListingBack('addUnit')
	}
	
	const handleNextStep = () => {
		SetWorkFlow({flowName : 'SERVICE', token : X_Authorization})
		//navigate('/setup/rental/rentalSetup')
		props.goToServices()
	}

	return (
		<div>
			<Grid container justifyContent={'flex-start'} direction={'column'}>

				<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

				<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>
				
				<Grid item mt={2} mb={2} display={'flex'} direction={'row'}>
					<img src={NoOfUnits} alt="'Number of Units" />
					<Grid ml={2} display={'flex'} direction={'column'} item style={{ marginTop: '10px' }}>
						<Typography style={{ color: "#6e7074" }}>Total No. of units</Typography>
						<span style={{ fontSize: '18px', fontWeight: 'bolder', color: '#212b36' }} >{data[0]?.unitCount}</span>
					</Grid>
				</Grid>
				{/*label*/}
				<Grid item mt={1}  display={'flex'} alignContent={'center'} justifyContent={'center'}>
					<span style={{color : '#032541', fontSize : '16px', fontWeight : '600'}} >Allocate more units or complete this step.</span>
				</Grid>
				
				{/*DataGrid*/}
				<Grid item>
					<DataGrid
						components={{NoRowsOverlay : NoRowsOverlay}}
						sx={{height : '350px', width : 'auto', fontFamily : 'Poppins', fontSize: "12px", color : '#272d3d',boxShadow: 0, border: 0}}
						rowsPerPageOptions={[3, 5, 10, 20, 50, 100]}
						rowCount={pageState.total}
						loading={pageState.isLoading}
						pagination
						page={pageState.page - 1}
						pageSize={dataGridPageSize}
						paginationMode="server"
						onPageChange={(newPage) => {setPageState((old) => ({...old, page: newPage + 1, pageSize: dataGridPageSize}));}}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						columns={unitColumns}
						rows={unitRows}
						cellRenderer={() => {return (1+1 === 2 ? <div>x</div>: '')}}
						getRowId={(row) => row.unitType}
					/>
				</Grid>
			</Grid>
			
			{/*Buttons*/}
			<Grid container justifyContent={'flex-end'} spacing={2} mt={2}>
				
				{/*Done*/}
				<Grid item>
					<Button onClick={handleNextStep} style={{textTransform : 'none', color : '#fff', background : '#17ae7b', width : '146px', height : '45px', fontSize : '14px', fontWeight : '600'}} >
						Save
					</Button>
				</Grid>
				
				{/*Add Units*/}
				<Grid item>
					<Button onClick={handleGoBackClick} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '14px', fontWeight : '600'}} >
						Add Another
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}