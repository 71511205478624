import { Grid, Typography, FormControl, InputLabel, Button, Breadcrumbs, Select, MenuItem, TextField } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PhoneInput from "react-phone-input-2";
import GeoLocation from '../../hooks/useLocation';
import HttpComponent from '../School/MakeRequest';
import { useDispatch } from 'react-redux'
import { changeToken } from '../../features/userSlice';
import { handleSetBusinessData } from '../../features/tenantBusinessSlice';
import { handleBusinessBranches } from '../../features/businessBranchesSlice';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
const baseUrl = process.env.REACT_APP_BASE_URL;


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Business Details</Typography>
]
export default function AddBusinessSaccoDetails() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedValue, setSelectedValue] = useState('');
    const [country, setCountry] = useState(null)
    const [formData, setformData] = useState({ businessName: "", businessOwnerName: '', businessOwnerEmail: '', })
    const [businessOwnerPhone, setbusinessOwnerPhone] = useState('')

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const handleFormChange = (e) => {
        setformData({ ...formData, [e.target.name]: e.target.value })
    }

    // currency & location

    const allCountry = [{
        "name": {
            "common": "Kenya",
        },
        "currencies": {
            "KES": {
                "name": "Kenyan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Uganda",
        },
        "currencies": {
            "UGX": {
                "name": "Ugandan shilling",
                "symbol": "Sh"
            }
        }
    },
    {
        "name": {
            "common": "Nigeria",
        },
        "currencies": {
            "NGN": {
                "name": "Nigerian naira",
                "symbol": "₦"
            }
        }
    }
    ]
    const filteredCountries = allCountry?.filter(country => {
        const commonName = country.name.common;
        return commonName === "Kenya" || commonName === "Uganda" || commonName === "Nigeria";
    });

    const currencies = filteredCountries.map(country => {
        const commonName = country.name.common;
        const currencyData = country.currencies[Object.keys(country.currencies)[0]];
        return {
            country: commonName,
            currency: currencyData.name,
            currencyCode: Object.keys(country.currencies)[0],
            currencySymbol: currencyData.symbol
        };
    });

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const returnCurreny = currencies?.find((currency) => currency.country === selectedValue)
        setCountry(selectedValue)
        setSelectedCountry(returnCurreny?.country);
        setSelectedCurrency(returnCurreny.currencyCode);

    };


    const [validEmail, setValidEmail] = useState(true);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    useEffect(() => {
        if (formData.email != "") {
            let result = validateEmail(formData.businessOwnerEmail);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.businessOwnerEmail]);


    const fetchBranches = async (token) => {
        try {
            const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
                method: "GET",
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            if (response?.status === 200) {
                dispatch(handleBusinessBranches({ branchesArr: data.data }));
                dispatch(handleSetBusinessData(data.data))
            }
        } catch (e) {
            console.log(e);
        }
    };


    function handlePostBusiness() {
        try {
            HttpComponent({
                method: 'POST',
                url: '/postBusiness',
                token: localStorage.getItem('X-Authorization'),
                body: {
                    businessCategory: "Transport",
                    businessCategoryType: "sacco",
                    businessName: formData.businessName,
                    businessOwnerAddress: selectedValue?.description,
                    businessOwnerName: formData.businessOwnerName,
                    businessOwnerPhone: businessOwnerPhone,
                    businessOwnerUserName: formData.businessOwnerName.split(" ").join(""),
                    businessType: '',
                    businessOwnerEmail: formData.businessOwnerEmail,
                    country: selectedCountry,
                    currency: selectedCurrency,
                    deviceCreatedOn: "Web",
                    Equitel: '',
                    Paybill: '',
                    schooltypeId: '',
                    Till: '',
                    userId: '',
                    Vooma: '',
                    workflowState: 'VEHICLES'
                }
            }).then(async (data) => {
                console.log(data , 'heyyyy data fireddddd')
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({state:true , message:data?.response?.message})
                    dispatch(changeToken({ token: data.response.data.token }))
                    localStorage.setItem("X-Authorization", data.response.data.token);
                    localStorage.setItem("businessCategory", "Transport")
                    localStorage.setItem("businessId", data?.response?.data?.businessNumber)
                    localStorage.setItem("businessID", data?.response?.data?.businessId)
                    localStorage.setItem("businessName", data?.response?.data?.businessName)
                    localStorage.setItem("BranchID", data.response.data.branchId)
                    localStorage.setItem("group", data?.response?.data?.group);
                    localStorage.setItem("zedAccountingId", data?.response?.data?.zedAccountingId);
                    localStorage.setItem("email", data.response?.data?.email);
                    localStorage.setItem("state", data.response?.data?.state);
                    localStorage.setItem("username", data.response?.data?.username);
                    localStorage.setItem("userId", data.response?.data.userId);
                    localStorage.setItem("customerId", data.response?.data?.customerId);
                    localStorage.setItem("phone", data.response?.data?.phone)
                    localStorage.setItem("businessCategory", data?.response?.data?.businessCategory);
                    localStorage.setItem('localCurrency' , data?.response?.data?.localCurrency)
                    await fetchBranches(data?.response?.data?.token)
                    setTimeout(() => {
                        navigate('/partnerstransport')
                    }, 1000)
                }else {
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })

        } catch (error) {
            setErrorShow({state:true , message:error?.message})
        }
    }


    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2} mb={2}>
                <Grid container gap={4} display={'flex'} direction={'column'}>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                        <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Transport</Typography>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <Typography variant='h6' sx={{ color: '#032541' }}>Enter Your Business Details</Typography>
            </Grid>
            <Grid item mt={2} width={'70%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Business Name</InputLabel>
                        <TextField style={{ width: "100%" }} value={formData.businessName} name='businessName' onChange={handleFormChange} id="outlined" required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Phone Number</InputLabel>
                        <PhoneInput inputStyle={{ height: "55px", width: '100%' }} required country={"ke"} enableSearch={true} value={businessOwnerPhone} onChange={(phone) => setbusinessOwnerPhone(phone)} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'70%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Email</InputLabel>
                        <TextField  style={{ width: "100%" }} value={formData.businessOwnerEmail} name='businessOwnerEmail' onChange={handleFormChange} id="outlined" required />
                    </Grid>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Business Owner Name</InputLabel>
                        <TextField style={{ width: "100%" }} value={formData.businessOwnerName} name='businessOwnerName' onChange={handleFormChange} id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'70%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={country} label="Select Country" onChange={handleCountryChange}>
                                {currencies?.map((acc) => {
                                    return <MenuItem value={acc?.country}>{acc?.country}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item width={'49%'}>
                        {/* <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Currency</InputLabel> */}
                        <TextField style={{ width: "100%" }} disabled value={selectedCurrency} name='currency' id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'69%'} mt={2}>
                <GeoLocation required={true} sx={{ width: "100%" }} selectedValue={selectedValue} onValueChange={(address) => setSelectedValue(address)} />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'69%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={handlePostBusiness} disabled={!formData.businessName || !formData.businessOwnerEmail || !formData.businessOwnerName || !validEmail} style={!formData.businessName || !formData.businessOwnerEmail || !formData.businessOwnerName ||!validEmail ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Next</Button>
            </Grid>
        </Grid>
    )
}