import {Breadcrumbs, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { useEffect, useState } from "react";
import { currencyconverter } from "../../../../common/currencyconverter";
import DateFormatter from "../../../../utils/dateFormatter";
import HttpComponent from "../../../School/MakeRequest";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Zed Payit Report </Typography>,
]

export default function CashierOrderDetails() {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, total: 0, noOfOrders: 0 });
    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, id] = newqueryParamValue.split("?") 


    function fetchPayitOrderBycashierbyId() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/order_payment_status`,
                body: {
                    pushyTransactionId: id,
                    businessNo: localStorage.getItem("businessId"),
                },
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState((prev) => ({ ...prev, isLoading: false, data: data?.response?.order?.items}))
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setPageState((prev) => ({ ...prev, isLoading: false }))
        }
    }
    useEffect(() =>{
        fetchPayitOrderBycashierbyId()
    },[id])


    const configData = pageState?.data?.map((config) => ({
        id: Math.random(),
        itemName: config?.itemName,
        Amount: currencyconverter(config?.itemAmount),
        itemCategory: config?.itemCategory,
        quantity: config?.itemCount,
        paymentMethod: config?.paymentMethod,
        totalAmount:currencyconverter(config?.totalAmount),
    }))

    const payitOrders = [
        { field: 'itemName', headerName: 'Product Name', flex: 1},
        { field: 'itemCategory', headerName: 'Product Category', flex: 1},
        { field: 'quantity', headerName: 'Item Count', flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
        { field: 'totalAmount', headerName: 'Total Amount', flex: 1 },
    ]
    return (
        <Grid container direction={'column'} width={'100%'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon onClick={() => navigate(-1)} style={{ color: "#032541" }} />
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>{`Zed Payit Report - Products`}</Typography>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={payitOrders} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )

}