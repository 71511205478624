import React from "react";
import { Breadcrumbs, Typography, Grid } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Breadcrumbcomponent = (props) => {
  const breadcrumbs = props?.breadcrumbs;
  return (
    <div>
      <Grid container width={'100%'}>
        <Breadcrumbs separator=">" key={1} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
    </div>
  );
};

export default Breadcrumbcomponent;
