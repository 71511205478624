import React, { useEffect, useState } from "react";
import { Breadcrumbs, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Tabs, Tab } from "@mui/material";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomSearchInput from "../School/CustomSearchInput";

function Appointments() {
    const customerId = localStorage.getItem('customerId');
    const token = localStorage.getItem('X-Authorization');
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [appointments, setAppointments] = useState([]);
    const [totalAppointments, setTotalAppointments] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [searchValue, setSearchValue] = useState("");
    const [tabIndex, setTabIndex] = useState(0);

    const handleSearchBox = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
        setPage(0);
    };

    const getAppointments = async (status) => {
        try {
            const response = await fetch(`${baseUrl}/api/v1/booking/booked/appointments?page=${page + 1}&staffId=${customerId}&limit=${rowsPerPage}&status=${status}`, {
                method: 'GET',
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            if (response.ok) {
                setAppointments(data.appointments);
                setTotalAppointments(data.totalAppointments);
            } else {
                setErrorShow({ state: true, message: data.message });
            }
        } catch (error) {
            setErrorShow({ state: true, message: "Failed to fetch appointments." });
        }
    };

    useEffect(() => {
        const statuses = ["Upcoming", "Inprogress", "Expired", "Cancelled", "Completed"];
        getAppointments(statuses[tabIndex]);
    }, [page, rowsPerPage, tabIndex]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tabLabels = ["Upcoming", "In Progress", "Expired", "Cancelled", "Completed"];

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Appointments</Typography>
            </Grid>
            <Grid item my={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    <Typography style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>
                    <Typography style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Appointment</Typography>
                    <Typography style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>{tabLabels[tabIndex]} Appointments</Typography>
                </Breadcrumbs>
                <Grid item style={{marginTop: '10px'}}>
                    <Tabs value={tabIndex} onChange={handleChangeTab}>
                        {tabLabels.map((label, index) => (
                            <Tab key={index} label={label} />
                        ))}
                    </Tabs>
                </Grid>
                <CustomSearchInput style={{marginTop: '10px'}} name={"search"} value={searchValue} placeholder={"Search:"} onChange={handleSearchBox} />
                <Grid item style={{marginTop: '10px'}}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: '16px' }}>Client Name</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>Start Time</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>End Time</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>Physical Address</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>Number of Services</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>Invoice Status</TableCell>
                                    <TableCell style={{ fontSize: '16px' }}>Total Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appointments.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>No Data Available</TableCell>
                                    </TableRow>
                                ) : (
                                    appointments.map((appointment) => (
                                        <TableRow key={appointment._id}>
                                            <TableCell>{appointment.clientName}</TableCell>
                                            <TableCell>{new Date(appointment.startTime).toLocaleString()}</TableCell>
                                            <TableCell>{new Date(appointment.endTime).toLocaleString()}</TableCell>
                                            <TableCell>{appointment.physicalAddress}</TableCell>
                                            <TableCell>{appointment.services.length}</TableCell>
                                            <TableCell>{appointment.invoiceStatus}</TableCell>
                                            <TableCell>{appointment.totalServicesAmount}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={totalAppointments}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Appointments;