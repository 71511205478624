import React, { useState, useEffect } from "react";
import { Grid, Button, Breadcrumbs, Tab, styled, List, ListItem, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { ArrowBack, ArrowBackIos, NavigateNext } from "@mui/icons-material";
import { ErrorOutline } from "@mui/icons-material"
import { warehousestyles } from "./styles";
import Activemainstore from "./mainstorecomponents/activemainstores";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import CreateBranch from "./addbranch";

const Mainstores = () => {

  const navigate = useNavigate()

  const queryParams = decodeURIComponent(window.location.search)
  const state = queryParams.slice(1)

  const { wareHouseState } = useSelector((store) => store.sidebar)
  const [searchValue, setSearchValue] = useState('')

  return (
    <>
    {state === 'createBranch' ? <CreateBranch/> :
    <>
    <div style={warehousestyles.mainDivStyling}>
    {" "}
    <Grid container width={"100%"} justifyContent={"space-between"} direction={"row"} alignItems={"center"} style={warehousestyles.gridContainerMargin}>
      <Grid item style={{ alignItems: "center", display: "flex" }}>
        <span style={warehousestyles.supplierText}>{wareHouseState ? "Main Stores" : "Branches"}</span>
      </Grid>
    </Grid>
    <Grid container style={warehousestyles.gridContainerMargin}>
      <Grid item>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
          <span>Dashboard</span>
          <span style={{ color: "red" }}> {wareHouseState ? "Main Stores" : "Branches"} </span>
        </Breadcrumbs>
      </Grid>
    </Grid>
    <Grid container style={warehousestyles.gridContainerMargin} width={"100%"} direction={"column"} >
      {/* <Grid item width={"50%"} >
              <p style={warehousestyles.mainstoresText} > <ErrorOutline/> {wareHouseState ? "Main stores" : "Branches"}  are created automatically {!wareHouseState ? null : "when creating branches"} </p>
          </Grid> */}
      <Grid item>
        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
          <Grid item>
            <FormControl sx={{  width: '30ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={'text'}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button onClick={()=>navigate(`/mainstores?createBranch`)} style={{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" }}>Add Branch</Button>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item mt={3} width={"50%"} >
        <p style={warehousestyles.mainstoreSubText} >Active {wareHouseState ? "Main Stores" : "Branches"} :</p>
      </Grid> */}
      <Grid item mt={4}>
        <Activemainstore />
      </Grid>
    </Grid>
  </div>
    </>
  }
   
 </>
  );
};

export default Mainstores;
