import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";

const CustomSelectStockOrder = ({ label, dataToMap, inputMarker, setInputValue }) => {
    const [inputValue, setUpInputValue] = useState("")
  const handleChange = (e) => {
    console.log(e.target);
    setUpInputValue(e.target.value);
  };


  useEffect(() => {
    setInputValue(inputValue)
  },[inputValue])



  return (
    <div style={{ width: "100%" }}>
      <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">{inputMarker}</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select"  label={label} onChange={handleChange}>
          {dataToMap?.map((data, index) => {
            return (
              <MenuItem key={index} name={data?.name} value={data?._id}>
                {data?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelectStockOrder;
