import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import HttpComponent from "../../../School/MakeRequest";
import ExportSearchBar from "../../../../common/exportsearchbar";

const Settlementreport = () => {
  // get fee configs;
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const [csvExport, setcsvExport] = useState({ headers: [], data: [] });
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [startTransactionTime, setStartTransactionTime] = useState("");
  const [endTransactionTime, setEndTransactionTime] = useState("");
  const [activeTab, setActiveTab] = useState(`Merchant Settlement Report for period ${startTransactionTime} and ${endTransactionTime} printed on ${new Date().toDateString()}`);

  const [searchValue, setSearchValue] = useState("");
  const [startDate, newStartDate] = useState("");
  const [endDate, newEndDate] = useState("");

  const settlementReport = [
    {
      field: "transactionReference",
      renderHeader: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>TXN Ref</Typography>
          </Box>
        );
      },
      flex: 1,
    },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "creditAccountNumber", headerName: "Credit Account", flex: 1 },
    { field: "debitAccountNumber", headerName: "Debit Account", flex: 1 },
    {
      field: "settledBusinessNumber",
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>BusinessID</Typography>
          </Box>
        );
      },
      flex: 1,
    },
    { field: "businessName", headerName: "Business Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  const settlementReportData = pageState?.data?.map((trans, index) => ({
    id: trans?._id,
    transactionReference: trans?.transactionReference,
    debitAccountNumber: trans?.debitAccountNumber,
    creditAccountNumber: trans?.creditAccountNumber,
    status: trans?.status,
    settledBusinessNumber: trans?.settledBusinessNumber,
    amount: trans?.amount,
    businessName: trans?.businessName,
  }));

  const getSettlementReports = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/listsettledtransactions?startDate=${startDate}&endDate=${endDate}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&status=Disbursed`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      const reports = data?.response?.data ?? [];
      const count = data?.response?.data;
      setPageState({ ...pageState, isLoading: false, data: reports, total: count });
    });
  };

  useEffect(() => {
    getSettlementReports();
  }, [pageState.page, startDate, endDate, searchValue]);

  useEffect(() => {
    const csvColumns = [
      { label: "Txn Ref", key: "transactionReference" },
      { label: "Debit AccountNumber", key: "debitAccountNumber" },
      { label: "Credit AccountNumber", key: "creditAccountNumber" },
      { label: "Status", key: "status" },
      { label: "Business ID", key: "settledBusinessNumber" },
      { label: "Transamount", key: "amount" },
      { label: "BusinessName", key: "businessName" },
    ];

    const pdfData = pageState?.data?.map((transaction) => {
      return {
        transactionReference: transaction.transactionReference,
        debitAccountNumber: transaction.debitAccountNumber,
        creditAccountNumber: transaction.creditAccountNumber,
        status: transaction.status,
        settledBusinessNumber: transaction.settledBusinessNumber,
        amount: transaction.amount,
        businessName: transaction.businessName,
      };
    });

    const headers = [["Txn Ref", "Debit AccountNumber", "Credit AccountNumber", "Status", "Business ID",  "Transamount", "BusinessName" ]];

    const data = pdfData?.map((data) => [data.transactionReference, data.debitAccountNumber, data.creditAccountNumber, data.status, data.settledBusinessNumber, data.amount, data.businessName]);
    console.log("pdfData ----->", pdfData)
    setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
    setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
    setExportData({ headers: headers, data: data });
    setcsvExport({ headers: csvColumns, data: pdfData });
  }, [pageState.page, dataGridPageSize, searchValue,startDate, endDate]);

  return (
    <div>
      <Grid container direction={"column"} sx={{ padding: "30px 0px 0px 20px" }}>
        <Grid item  mb={2}>
          <Box style={{ margin: "0px 0px 10px 0px" }}>
            <Typography sx={{ fontWeight: "600", color: "#032541", fontSize: "25px" }} variant="h5">
              Settlement Report
            </Typography>
          </Box>

          <Box>
            <Breadcrumb name={"Settlement Report"} />
          </Box>
        </Grid>

        <Grid item>
          <ExportSearchBar activeTabs={activeTab} csvExport={csvExport} exportData={exportData} textfiledplaceholder={"Search..."} setSearchValue={setSearchValue} newStartDate={newStartDate} newEndDate={newEndDate} />
        </Grid>

        <Grid item >
          <CustomDataGrid customStyles={{ border: "none", height: "600px", width: "100%" }} column={settlementReport} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={settlementReportData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Settlementreport;
