import { Typography, Breadcrumbs, Grid, Button, Checkbox } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { handleAddCharge, handleAddCurrentServcie, handleDeleteCharge, handledeleteCurrentServcie } from "../../../../features/tenantsDataSlice";

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Edit Invoice</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Advance Charges</Typography>,
    <Link style={{ fontFamily: 'Poppins', fontSize: '14px', textDecoration: "none" }} to={-1} key="3" color="text.inactive">Preview Invoice</Link>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Update Invoice</Typography>
];

export default function AddMoreServicesFromTenantProfile(props) {
    const navigate = useNavigate()
    const { X_Authorization } = useSelector((store) => store.user);
    const tenantCurrentServices = useSelector((store) => store?.tenantData?.tenantCurrentServices)
    const [unitCharges, setUnitCharges] = useState([])
    const dispatch = useDispatch()
    const [util ,setUtil] = useState([])
    const [selectedItems ,setSelectedItems] = useState([])

    //
    console.log(props, 'unit id')
    //handle checkbox change

    const handleCheckboxChange = (event, product) => {
		if (event.target.checked) {
			let data = {productId : product.productId, name: product.serviceName, amount: product.amount, serviceType : product.serviceType}
			console.log(`this is Data`, data)
			dispatch(handleAddCurrentServcie(data));}
		else {dispatch(handledeleteCurrentServcie(product.productId))}
	};

    //props to create a new service

    const handleCreateService = () => {

    }

    //fetch service data
    //Fetch services per unit
    function fetchServicesPerUnit() {
        HttpComponent({
            method: 'GET',
            url: `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${props?.unitId}`,
            token: X_Authorization
        }).then((data) => {
            console.log(data, 'hello serrvices per unit')
            if (data.status === 201) {
                setUnitCharges(data.response.data)
                //dispatch(handleChargeService(data.response.data))
            } else {
                console.log(`Error getting data`)
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchServicesPerUnit()
    }, [])


    //service columns
    const servicesColumns = [
        {
            field: 'serviceName', flex: 1, align: 'left', headerAlign: 'left', renderCell: (params) => (
                <div>
                    <Checkbox
                    style={{ color: '#dc3545' }}
                    checked={tenantCurrentServices?.some(s => s.productId === params?.row?.productId)}
                    onChange={(event) => handleCheckboxChange(event, params.row)} />
                    <span style={{ color: '#707070', fontSize: '14px' }}>{params.value}</span>
                </div>
            ), renderHeader: () => { return (<strong style={{ color: '#032541' }} >Service Name</strong>) }
        },
        { field: 'serviceType', flex: 1, align: 'left', headerAlign: 'left', renderHeader: () => { return (<strong style={{ color: '#032541' }}>Service Type</strong>) } },
        {
            field: 'amount', flex: 1, align: 'left', headerAlign: 'left', editable: (row) => {
                return row.serviceType.toString() === 'Variable'
            },
            renderHeader: () => { return (<strong style={{ color: '#032541' }}>Service Amount</strong>) }, renderCell: (params) => {
                return (numberFormat(params.value))
            }
        },
    ]

    return (
        <div style={{ fontFamily: 'Poppins' }}>

            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{cursor:"pointer"}} onClick={props?.goBack} />
                <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}>Update   Invoice</Typography>
            </Grid>
            {/*BreadCrumb container*/}
            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>

            {/*Top Container*/}
            <Grid container justifyContent={'space-between'} mt={3}>

                {/*Header label*/}
                <Grid item>
                    <span style={{ color: '#707070', fontFamily: 'Poppins', fontSize: '16px' }}>List of Service</span>
                </Grid>

                {/*Button Create Service*/}
                <Grid item>
                    <Button onClick={handleCreateService} style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '35px' }} >Create Service</Button>
                </Grid>
            </Grid>

            {/*Table Container*/}
            <DataGrid
                components={{ NoRowsOverlay: NoRowsOverlay }}
                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#707070', boxShadow: 0, border: 0 }}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                columns={servicesColumns}
                rows={unitCharges} 
                getRowId={(row) => row.productId}
                isCellEditable={(params) => params.row.serviceType === 'Variable'}
            />

            {/*Update Button*/}
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button onClick={() => { navigate(-1) }} style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#17ae7b', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '35px' }} >Update Invoice</Button>
                </Grid>
            </Grid>
        </div>
    )
}