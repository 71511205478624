import { Breadcrumbs, Grid, Stack, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";
import { useState } from "react";
import CustomDataGrid from "../products/stockComponents/customDataGrid";
import HttpComponent from "../School/MakeRequest";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>,
]


const MTNTransactions  = () => {
    const [searchValue , setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    function handleFetchTransactions() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/mtntransactions?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((res) => {
                if (res?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, data: res?.data?.data, count: res?.data?.count })
                }
            }).catch((err) => {
                console.log(err)
            })

        } catch (err) {
            console.log(err)
        }
    }

    const TransactionsColumns = [
        { field: "transactionID", headerName: "Transaction ID", flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: "mtnID", headerName: "MTN ID", flex: 1 },
        { field: "zedID", headerName: "ZED ID", flex: 1 },
        { field: "phone", headerName: "Phone Number", flex: 1 },
        { field: "date", headerName: "Date", flex: 1 },

    ]

    const TransactionsData = pageState?.data?.map((config) => ({
        id: config._id,
        transactionID: config?.transactionID,
        amount: config?.phone,
        mtnID: config?.mtnID,
        zedID: config?.zedID,
        phone: config?.phone,
        date: config?.createdAt,
    }))
    return (
        <Grid container direction={'column'} gap={2}>
             <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Transactions</Typography>
                </Stack>
            </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'}>
                <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
            </Grid>
            <Grid item width={'100%'} mt={2}>
                 <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={TransactionsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={TransactionsData} />
            </Grid>
        </Grid>
    )
}

export { MTNTransactions }