import {Button, Checkbox, Grid, TextField} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import lodash from 'lodash'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {handleChargesData, handleTenantBioData, handleTenantExistsBioData, handleTenantHouseData} from "../../../features/tenantsDataSlice";
import HttpComponent from "../../School/MakeRequest";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);

export default function CompleteTenant(props){
	//Navigation
	const navigate = useNavigate()
	const dispatch = useDispatch()

	// Notification
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
	const [successShow, setSuccessShow] = useState({state: false, message: ""})

	const [unitCharges, setUnitCharges] = useState([])
	
	//checkBox handler
	let [util, setUtil] = useState([])
	const [selectedItems, setSelectedItems] = useState([]);
	
	//Auth
	const {X_Authorization } = useSelector((store) => store.user);
	
	//Redux Store
	const {unitId, amount, itemNumber, unitType} = useSelector((store) => store.tenantData.tenantHouseData)
	const {companyName,tenantId, firstName, lastName, email, phone, userPhone , customerType} = useSelector((store) => store.tenantData.tenantBioData)
	
	//tenantId

	// Select tenant
	const {tenantBioData, tenantHouseData} = useSelector((store) => store.tenantData)

	console.log(tenantBioData ,'bio')
	console.log(tenantHouseData ,'house data')

	
	//Tenant creation Data
	let tenantData

	customerType?.toLowerCase()?.includes('company') ?
		tenantData = {companyName,  firstName : companyName ,lastName : '.', customerType, phone: `${userPhone}`, houses: [{unitType, itemNumber, amount, extraServices: util}], email}
		:
		tenantData = {firstName, lastName ,customerType, phone: `${userPhone}`, houses: [{unitType, itemNumber, amount, extraServices: util}], email}

	const handleGoBackClick = () => {
		console.log(props, `this are props`)
		props.setFlagStateBackToServices()
	}

	let existingTenant = {
		companyName:companyName ,
		firstName:firstName ,
		lastName:lastName,
		phone:phone ,
		email:email ,
		tenantId:tenantId,
		customerType:customerType
	}

	const handlePreviewInvoice = () => {
		dispatch(handleChargesData(util))
		dispatch(handleTenantExistsBioData(existingTenant))
		navigate('/tenantPreviewInvoice')
	}
	
	function handleAddTenantId (id) {
		const newObj = {...tenantBioData, tenantId : id ,} 
		dispatch(handleTenantBioData(newObj))
	}
	function handleUpdateTenantUnitId (id) {
		dispatch(handleTenantHouseData({...tenantHouseData, ssss : id}))
	}
	
	//Fetch services per unit
	function fetchServicesPerUnit() {
		HttpComponent({
			method : 'GET',
			url : `/api/get_unity_type_services?limit=1000&page=1&businessUnitTypeId=${unitId}`,
			token : X_Authorization
		}).then((data)=>{
			if(data.status === 201){
				setUnitCharges(data.response.data)
			}else{
				console.log(`Error getting data`)
			}
		}).catch((error)=>{
			console.error(error.message);
		})
	}
	
	const handleCheckboxChange = (event, item) => {
		if (event.target.checked) {
			setSelectedItems([...selectedItems, item]);
			const object = {"amount" : item.amount, "name" : item.serviceName, "productId" : item.productId, "serviceType" : item.serviceType}
			setUtil([...util, object])
		} else {
			setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== item));
			setUtil(util.filter((service) => service.productId !== item.productId))
		}
	};

	// Save tenant
	 function saveTenant() {
			try {
				{ props.isExisting ? handlePreviewInvoice() :
				HttpComponent({
					method: 'POST', url: '/api/add_tenant', body: tenantData, token: X_Authorization
				}).then((data) => {
					console.log(data ,'new tenant info')
					if (data.status === 201) {
						console.log('data.response.data._id0', data.response.data._id);
						handleAddTenantId(data.response.data._id)
						const billableItems = data?.response?.data?.billableItems

						if (billableItems) {
							for (let i = 0; i < billableItems.length; i++) {
							  const item = billableItems[i];
							  const updatedId = handleUpdateTenantUnitId(item._id);
							  item._id = updatedId;
							}
						  }
						//handleUpdateTenantUnitId(data.response.data.billableItems[0]?._id)
						setSuccessShow({state : "true", message: data.response.message})
						handlePreviewInvoice()
					} else {
						setErrorShow({state : "true", message: data.response.message})
					}
				}).catch((error) => {
					console.error(error.message);
				})}
			} catch (e) {
				console.log(`Error saving tenant`, e.message)
			}
		}

	function saveTenantNoInvoice () {
		try {
			{ props.isExisting ? handlePreviewInvoice() :
				HttpComponent({
					method: 'POST', url: '/api/add_tenant', body: tenantData, token: X_Authorization
				}).then((data) => {
					console.log(data ,'tennat new')
					if (data.status === 201) {
						handleAddTenantId(data.response.data._id)
						const billableItems = data?.response?.data?.billableItems

						if (billableItems) {
							for (let i = 0; i < billableItems.length; i++) {
							  const item = billableItems[i];
							  const updatedId = handleUpdateTenantUnitId(item._id);
							  item._id = updatedId;
							}
						  }
						//handleUpdateTenantUnitId(data.response.data.billableItems[0]?._id)
						setSuccessShow({state : "true", message: data.response.message})
						setTimeout(() => {props.setFlagStateBackToMenu()}, 1000)

					} else {
						setErrorShow({state : "true", message: data.response.message})
					}
				}).catch((error) => {
					console.error(error.message);
				})}
		} catch (e) {
			console.log(`Error saving tenant`, e.message)
		}
	}

	
	useEffect(() => {
		fetchServicesPerUnit()
	}, [])
	
	const selectedTotal = lodash.sumBy(selectedItems, item => {return item.amount})
	const loc = useLocation()
	const isTenants = loc?.pathname?.includes("/tenants")

	function doSaveTenant() {
		try {
			HttpComponent({
				method: "POST",
				url: '/api/add_tenant',
				body: tenantData,
				token: X_Authorization
			}).then((data) => {
				if (data.status === 201) {
					handleAddTenantId(data.response.data._id)
					const billableItems = data?.response?.data?.billableItems

					if (billableItems) {
						for (let i = 0; i < billableItems.length; i++) {
							const item = billableItems[i];
							const updatedId = handleUpdateTenantUnitId(item._id);
							item._id = updatedId;
						}
					}
					setSuccessShow({ state: "true", message: data.response.message })
					setTimeout(() => { props.setFlagStateBackToMenu() }, 1000)
				} else {
					setErrorShow({ state: "true", message: data.response.message })
				}
			})

		} catch (error) {

		}
	}

	return (
		<div style={{fontFamily : 'Poppins', marginLeft : '15%', marginRight : '15%'}}>
			{/*{componentToRender}*/}
			<Grid container direction={'row'} justifyContent={'space-between'} mt={3} spacing={3}>

				{/*snackBar*/}
				<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>

				<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

				<Grid container justifyContent={'flex-start'}>
					{/*Header name*/}
					<Grid item>
						<span style={{fontSize : '16px', fontWeight : '600', color : '#032541'}} >Add Charges</span>
					</Grid>
				</Grid>
				
				{/*Rent Deposit, service Charge, electricity*/}
				<Grid item mt={0}>
					<Grid container direction={'row'} justifyContent={'space-between'} spacing={4}>
						{
							unitCharges.map((data, index) => (
								<>
									{/*Rent Deposit*/}
									<Grid key={index} item>
										<Grid container direction={'row'} spacing={4} justifyContent={'space-between'}>
											{/*Checkbox and Label*/}
											<Grid item>
												<FormControlLabel label={
													<Grid container direction={'row'} justifyContent={'space-between'}>
														
														{/*Label*/}
														<Grid item>
															<span style={{color : '#6e7074', fontSize : '14px', fontFamily : 'Poppins'}} >{data.serviceName}</span>
														</Grid>
													
													</Grid>
												} control={
													<Checkbox style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} edge="start" checked={selectedItems.includes(data)} onChange={event => handleCheckboxChange(event, data)} />}/>
											</Grid>
											
											{/*Input Box*/}
											<Grid item>
												<TextField disabled required readOnly size="small" value={numberFormat(data.amount)} defaultValue={numberFormat(data.amount)} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '212px'}} id="outlined-basic" label="Amount" variant="outlined" />
											</Grid>
										</Grid>
									</Grid>
								</>
							))
						}
					</Grid>
				</Grid>
			
			</Grid>
			
			{/*Amount*/}
			<Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'flex-end'} mt={5}>
				<Grid item>
					<span style={{color : '#032541', frontWeight : 'bold', fontSize : '14px'}}>Total Amount :</span>
					<label style={{marginLeft : '5px', color : '#032541', fontFamily : 'Poppins', frontWeight : '600', fontSize : '16px'}}>{numberFormat(selectedTotal)}</label>
				</Grid>
			</Grid>
			
			{/*Buttons*/}
			<Grid container justifyContent={'flex-start'} alignContent={'center'} direction={'row'} mt={3} spacing={2}>
				<Button onClick={saveTenantNoInvoice}  style={{textTransform : 'none', color : '#fff', background : '#17ae7b', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
								Save Tenant
				</Button>
				{/* <Grid item>
					<Button onClick={handleGoBackClick} style={{border : 'solid 1px #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
						Back
					</Button>
				</Grid>
				{
					isTenants ?
						<Grid item>
							<Button onClick={() => {props.setFlagStateAddService()}} style={{border : "solid 1px #032541", textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
								Add Service
							</Button>
						</Grid> : null
				}
				<Grid item>
					<Button disabled={selectedItems.length < 1 } onClick={saveTenant} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
						Preview Invoice
					</Button>
				</Grid>

				
				{
					props.isExisting ? null :
						<Grid item>
							<Button onClick={saveTenantNoInvoice}  style={{textTransform : 'none', color : '#fff', background : '#17ae7b', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
								Save Tenant
							</Button>
						</Grid>
				} */}

			</Grid>
		</div>
	)
}
