import React, {useState, useEffect} from "react";
import {Modal, Box, Grid, ButtonBase, Input, List, ListItem, ListItemIcon, IconButton,} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {CheckCircle} from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const baseUrl = process.env.REACT_APP_BASE_URL;
const MpesaPayments = (props) => {

	const [mpesaOpen, setMpesaOpen] = useState(props.mpesaOpen);
	const [mpesaData, setMpesaData] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 3;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = mpesaData?.slice(indexOfFirstItem, indexOfLastItem);

	const wholeModal = {width: '500px', height: '520px', backgroundColor: '#fff', borderColor:"transparent", borderRadius: "7px", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',};
	const headerSection = {backgroundColor: 'red', width: '100%', borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: '109px', flexDirection: "row", alignContent: "center", justifyItems: "center", textAlign: "center",}

	useEffect(() => {
		setMpesaOpen(props.mpesaOpen);
	}, [props.mpesaOpen]);

	const handleClick = (transactionID) => {
		console.log(transactionID);
	}

	const getMpesaMessages = async () => {
		try {
			const mpesaMessagesResponse = await fetch(baseUrl+`/api/getAllSms?type=MPESA&status=Unused`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			console.log(mpesaMessagesResponse)
			if (mpesaMessagesResponse.status === 200) {
				const mpesaMessages = await mpesaMessagesResponse.json();
				console.log("mpesa data ", mpesaMessages.sms)
				setMpesaData(mpesaMessages.sms);
			}
		} catch (error) {
			console.error("error fetching invoice details",error.message);
		}
	};

	useEffect(() => {
		getMpesaMessages().then(()=>console.log("done fetching mpesa data"))
	}, []);

	return (
		<div>
			<Modal open={mpesaOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={wholeModal}>
					<div style={headerSection}>
						<div style={{marginLeft: "auto", marginRight: "auto", color: "white", fontWeight: "bold", fontSize: 25, paddingTop:"40px"}}>
							Received Payment
						</div>
					</div>

					<Grid container alignItems={'center'} justifyContent={'center'} style={{marginTop : '5%'}} >
						<Grid item>
							<TextField style={{fontSize : '10px', fontFamily : 'Poppins', "width":"450px","height":"45px","borderRadius":"6px"}}
								size="small"
								variant="outlined"
								placeholder={'Search:'}
								InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon style={{width :'15px', height : '15px'}} /></InputAdornment>),}}
							/>
						<Grid item>
							<List>
								{currentItems?.map(data => (
									<ListItem key={data.transactionID}  style={{marginBottom : '10px', fontFamily: "Poppins", fontSize : '14px', "borderRadius":"6px","border":"solid 1px rgba(193,193,193, .3)"}}
											  onClick={() => handleClick(data.transactionID)}
									>
										<CheckCircle style={{color : '#17ae7b', width : '17px', height : '17px', paddingRight : '5px'}}/>
										{data.sms}
									</ListItem>
								))}
							</List>
							<div style={{
								display:"flex",
								justifyContent:"center"
							}}>
								<IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
									<ArrowBackIosNewIcon/>
								</IconButton>
								<div style={{
									display:"flex",
									flexDirection:"column",
									justifyContent:"center"
								}}>
									<span className="pagination-page-number">{currentPage}</span>
								</div>
								<IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(mpesaData?.length / itemsPerPage)}>
									<ArrowForwardIosIcon/>
								</IconButton>
							</div>

						</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
};
export { MpesaPayments };
