import { Box, AccordionDetails, AccordionSummary, Accordion, Breadcrumbs, Button, Divider, Grid, Menu, MenuItem, Tab, TextField, Typography, TableBody, TableRow, TableCell, Table } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomInputLabel from "../../School/CustomInputLabel";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../School/CustomTextField";
import { useState, useEffect } from "react";
import CustomSelectField from "../../School/CustomSelectField";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import CustomSearchField from '../../School/CustomSearchInput'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowBackIos, ArrowForwardIos, TextFields } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomSearchInput from "../../School/CustomSearchInput";
import CustomTable from "../../School/CustomTable";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomSelect from "../../School/CustomSelectField";


const numberFormat = (value) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(value) };


const breadcrumbsCategories = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Supplier Payments</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Add Invoice</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Items</Typography>


]
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Supplier Payments</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Invoice</Typography>


]

const AddInvoiceSupplier = (props) => {
    console.log(props, 'props ===><<<<<<<LLLL')
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [productCategories, setProductCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [searchCategorie, setSearchCategories] = useState("");
    const [products, setProducts] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [totalPrice, setTotalPrice] = useState(0)
    const [itemsCount, setItemsCount] = useState(0)
    const supplierInvoice = localStorage.getItem('supplierInvoice')
    const itemsInvoice = JSON.parse(supplierInvoice)?.items || []
    console.log(itemsInvoice, 'itemsInvoice ==>>>>');
    const [items, setItems] = useState([...itemsInvoice])
    const navigate = useNavigate()
    const [addItemesModal, setAddItemesModal] = useState(false)
    const [invoiceDate, setInvoiceDate] = useState(new Date());


    console.log(items, 'items >>>>>>>>>>>>')

    const [buttonState, setButtonState] = useState(false)





    const setCategoryMethod = (category) => {
        setCategory(category?.category)
        console.log(category, 'category')
        setCategoryId(category?.categoryId)
    }


    const handleInvoiceDate = (newValue) => {
        setInvoiceDate(newValue);

    };
    const handleSelectChange = (e, row) => {
        console.log(e, 'e', row, 'row')
        const productId = row?._id
        let newProducts = products.map((product) => {
            if (product?._id === productId) {
                return { ...product, accountName: e?.target?.value, accountId: e?.target?.value }
            }
            return product
        }
        )
        setProducts(newProducts)
    }
    const [expenseAccount, setExpenseAccount] = useState([])

    function getExpenseAccount() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/business/get_general_ledger_expense_accounts`,
                body: {
                    businessID: localStorage.getItem('businessID')
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    let expenseAccount = data?.response?.data
                    console.log(expenseAccount, 'expenseAccount????')
                    expenseAccount = expenseAccount.map((account) => {
                        return { label: account?.name, value: account?.accountId }
                    })
                    setExpenseAccount(expenseAccount)
                }

            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getExpenseAccount()
    }, [])
    const updateStock = (row, e) => {
        console.log(row, 'row')
        const productId = row?._id
        let newProducts = products.map((product) => {
            if (product?._id === productId) {
                let newSetItem = {
                    itemId: product?._id,
                    itemName: product?.productName,
                    quantity: e.target.value,
                    itemAmount: product?.buyingPrice,
                    itemTotal: product?.buyingPrice * e.target.value,
                    accountName: expenseAccount?.find((account) => account?.value === product?.accountId)?.label || "",
                    accountId: product?.accountId || ""
                }
                let Newitems = [...items, newSetItem]
                console.log(Newitems, 'Newitems')

                const currentIndex = items.findIndex((item) => item.itemId === productId)
                console.log(currentIndex, 'currentIndex')
                if (currentIndex !== -1) {
                    //  replace what in items with newSetItem
                    let newItems = [...items]
                    newItems[currentIndex] = newSetItem
                    setItems(newItems)



                } else if (currentIndex === -1) {
                    Newitems = [...items, newSetItem]
                    setItems(Newitems)

                }

                let getTotalPrice = 0
                getTotalPrice = items.reduce((acc, item) => {
                    return acc + parseInt(item.itemTotal)
                }, 0)

                setTotalPrice(getTotalPrice)

                // setItems([...items, newSetItem])
                return { ...product, quantity: e.target.value }
            }
            return product
        }
        )
        setProducts(newProducts)

    }

    console.log(items, 'items')
    const [accountStatus, setAccountStatus] = useState({})

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                body: {
                    businessID: localStorage.getItem('businessID')
                },
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data.response?.data?.accountingEnabled)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAccountingStatus()
    }, [])





    const productColumns = [
        { field: 'productName', headerName: 'Product Name', flex: 1, renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Product Name</p> },
        { field: 'buyingPrice', headerName: 'Price', flex: 1, renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Buying Price</p> },
        { field: 'accountName', headerName: 'Gl Account', flex: 1, renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Gl Account</p> },
        // accountStatus ? {
        //     field: 'GL Account', headerName: 'accountName', flex: 1.5,
        //     renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>GL Account</p>,
        //     renderCell: (params) => {
        //         return <CustomSelect
        //             label={"GL Account"}
        //             value={params?.row?.accountName}
        //             onChange={(e) => handleSelectChange(e, params?.row)}
        //             options={expenseAccount}

        //         />
        //     }

        // }
            // : null,



        {
            field: "quantity",
            headerName: " Quantity",
            flex: 1,
            renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Quantity</p>,
            renderCell: (params) => {
                return <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input id="" name="" min={1} type="number" style={{ width: "50%", outline: "none" }} onChange={(e) => updateStock(params?.row, e)} />
                </Box>
            },
        }, {
            field: 'totalPrice', headerName: 'Total Price', flex: 1,
            renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>{params?.field}</p>,
            renderCell: (params) => {
                // console.log(params?.row?.productPrice, 'arams?.row?.productPrice', params?.row?.quantityInStock, 'params?.row?.quantityInStock')
                return <p>{parseInt(params?.row?.buyingPrice) * parseInt(params?.row?.quantity) || '-'}
                </p>
            }
        },


    ]

    const deleteItem = (id) => {
        console.log(id, 'id')
        let newItems = items.filter((item) => item.itemId !== id)
        console.log(newItems, 'newItems')
        setItems(newItems)
    }




    //  setItems(newItems)
    const getCategories = () => {
        try {

            HttpComponent({
                method: 'GET',
                url: `/api/listByCategories?limit=100&page=1&status=pending&consumable=true`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')

                if (data.status === 200 || data.status === 201) {
                    let categories = data?.response?.categories
                    setProductCategories(categories)
                }

            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getCategories()
    }, [])
    console.log(category, 'category ===>>>>>>')
    const getProducts = () => {

        try {

            HttpComponent({
                method: 'GET',
                url: `/api/listByProducts?limit=100&page=1&status=pending&consumable=true&categoryId=${categoryId}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')

                if (data.status === 200 || data.status === 201) {
                    let products = data?.response?.products
                    console.log(products, 'products')
                    setProducts(products)
                }

            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getProducts()
    }, [categoryId])
    // console.log(products , 'hry')

    const calculateTotalPrice = () => {
        let getTotalPrice = 0
        getTotalPrice = items.reduce((acc, item) => {
            return acc + parseInt(item.itemTotal)
        }, 0)
        let totalItemsCount = items.length
        setItemsCount(totalItemsCount)

        setTotalPrice(getTotalPrice)
    }
    useEffect(() => {
        calculateTotalPrice()
    }, [items])
    const buttonCheckState = () => {
        if (props?.supplierId !== '' && invoiceDate !== '' && items.length > 0) {
            setButtonState(true)
        }
        else {
            setButtonState(false)
        }
    }
    console.log(buttonState, 'buttonState')
    useEffect(() => {
        buttonCheckState()
    }, [props?.supplierId, invoiceDate, items])

    const handleGenerateInvoice = () => {
        let data = {
            supplierId: props?.supplierId,
            invoiceDate: invoiceDate,
            items: items,
            supplierDetails: props?.supplierDetails,
        }
        console.log(data, 'dataaaaaaaaaaaa')
        localStorage.setItem('supplierInvoice', JSON.stringify(data))
        navigate('/payables/suppplier/raisepayment?preview_invoice')


    }

    const [activeCategoryId, setActiveCategoryId] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const handleCategoryClick = (category) => {
        setActiveCategoryId(category.categoryId);
        setCategoryMethod(category);
    };
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            {addItemesModal ?

                <>
                    <Grid item mt={1}>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }} onClick={() => navigate(-1)}> <ArrowBackIos /> Add Item</Typography>
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbsCategories}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>



                    <Grid item mt={3} >
                        <Grid item mt={3}>
                            <Grid item mt={3}>
                                <Box>
                                    <CustomSearchInput value={searchCategorie} onChange={(e) => setSearchCategories(e.target.value)} name={"searchCategorie"} placeholder={"Search Category"} />
                                </Box>
                                <Box>
                                    <h4 style={{ fontSize: '18px', fontWeight: '600', marginTop: '20px', marginLeft: '20px' }}>Product Categories</h4>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1, marginY: 4, justifyContent: "space-between", width: "100%" }}>
                            <Box component="div" sx={{ marginTop: "10px", minWidth: "600px" }}>
                                {productCategories?.map((category) => {
                                    const isActive = category.categoryId === activeCategoryId;
                                    const isExpanded = expanded === category?.categoryId;
                                    const arrowColor = isActive ? 'white' : 'black';
                                    const backgroundColor = isActive ? '#032541' : 'transparent';
                                    const textColor = isActive ? 'white' : 'black';

                                    return (
                                        <Accordion key={category.categoryId} style={{ backgroundColor, color: textColor }} expanded={isExpanded} onChange={handleAccordionChange(category?.categoryId)}>
                                            <AccordionSummary expandIcon={<ArrowForwardIos style={{ color: arrowColor }} />} onClick={() => handleCategoryClick(category)}>
                                                <Typography>{category.category}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* Content for each category */}
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}

                                {/* {productCategories?.map((category) => {
                                    const isActive = category.categoryId === activeCategoryId;
                                    return (
                                        <Accordion key={category.categoryId} style={{ backgroundColor: isActive ? '#032541' : 'transparent', color: isActive ? 'white' : 'black', }} expanded={expanded === category?.categoryId} onChange={handleAccordionChange(category?.categoryId)} >
                                            <AccordionSummary expandIcon={ <ArrowForwardIos style={{ color: expanded ? 'white': 'black' }} />} onClick={() => handleCategoryClick(category)}>
                                                <Typography>{category.category}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Content for each category
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })} */}
                                {/* {productCategories?.map((category) => {
                                    return (
                                        <Accordion key={category?.categoryId}>
                                            <AccordionSummary expandIcon={<ArrowForwardIos />} onClick={() => setCategoryMethod(category)}>
                                                <Typography>{category?.category}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails></AccordionDetails>
                                        </Accordion>
                                    );
                                })} */}
                            </Box>

                            <Box component="div" sx={{ minWidth: "800px", marginLeft: "10px" }}>
                                {category !== "" ?
                                    <>
                                        <Box style={{ border: "1px solid #e6e6e6" }}>
                                            <Box component="div" sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginX: 1, width: "800px", height: " 50px", margin: "0 0 11px 16px", padding: " 9px 15px 10px 10px", borderRadius: "10px", backgroundColor: " rgba(112, 112, 112, 0.05)" }}>
                                                <Box><span style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Products :{itemsCount} </span></Box>
                                                <Box><span style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Total {numberFormat(totalPrice)}</span></Box>
                                            </Box>
                                            <CustomTable data={products} columns={productColumns} rowsPerPage={10} />
                                            <Box component="div" sx={{ minWidth: "800px", }}>
                                                <Box sx={{ display: "flex", justifyContent: "end", }}>
                                                    {/* <Box></Box> */}
                                                    <Box>
                                                        <Button variant="contained" style={{ backgroundColor: "#ffff", border: "1px solid #dc3545", width: "146px", height: "45px", color: "#dc3545", textTransform: "none", marginRight: "20px" }} onClick={() => setAddItemesModal(false)}>Cancel</Button>
                                                        <Button variant="contained" style={{ backgroundColor: "#032541", width: "146px", height: "45px", color: "#ffffff", textTransform: "none" }} onClick={() => setAddItemesModal(false)}>Add</Button>
                                                    </Box>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </> : ""}

                            </Box>
                        </Box>

                    </Grid>




                </>
                : <>
                    <Grid item mt={1}>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Invoice to Supplier/Vendor</Typography>
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Grid container direction={'column'} mt={3}>
                        <Grid item mt={1}>
                            <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#032541" }}>Invoice Details</Typography>
                        </Grid>
                        <Grid item mt={3}>
                            <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row", width: "810px" }}>
                                <Box>

                                    <CustomInputLabel required={true} label={"Supplier Name"} />

                                    <CustomTextField placeholder={props.supplierName} disabled={true} />

                                </Box>

                                <CustomInputLabel title={"Invoice Date"} />
                                <Box component="div" sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Invoice Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={invoiceDate}
                                            required
                                            onChange={handleInvoiceDate}
                                            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}

                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                            <Grid mt={3} style={{ width: "800px" }}>
                                <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between" }}>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Invoice Item</Typography>
                                    </Box>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", cursor: "pointer" }} onClick={() => setAddItemesModal(true)} > <AddCircleIcon style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }} /> Add Items</Typography>
                                    </Box>
                                </Box>
                                <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between", backgroundColor: "rgba(112, 112, 112, 0.05)", padding: "10px" }}>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Items : {itemsCount}</Typography>
                                    </Box>
                                    <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                                        <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }} >Total: KES  : {numberFormat(totalPrice)}</Typography>
                                    </Box>
                                </Box>
                                <Box mt={3}>
                                    {items?.length <= 0 ?
                                        <Box component="div" style={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-center", backgroundColor: "rgba(112, 112, 112, 0.05)" }}>
                                            <p style={{ display: "flex", justifyContent: "center", fontFamily: "Poppins", fontSize: "14px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", linHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#707070", padding: "40px", alignItems: "center" }}>Add items to invoice and preview them here!</p>
                                        </Box>
                                        :


                                        <Table style={{ width: "100%" }}>
                                            <TableBody style={{ width: "100%" }}>

                                                {items?.map((item) => (
                                                    <TableRow
                                                        hover
                                                        key={item?.itemId}

                                                    >

                                                        <TableCell>{item?.itemName}</TableCell>
                                                        <TableCell>{item?.itemAmount}</TableCell>
                                                        <TableCell>
                                                            {/* <EditIcon style={{ color: "#032541", cursor: "pointer", marginRight: "10px" }} /> */}
                                                            <DeleteIcon style={{ color: "#DC3545", cursor: "pointer" }} onClick={() => { deleteItem(item?.itemId) }} />
                                                            -          </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    }
                                    <Divider style={{ width: "100%", color: "#707070" }} />
                                </Box>
                                <Box sx={{ justifyContent: "space-between", display: "flex", width: "100%", marginTop: "10px" }}>
                                    <Box></Box>
                                    <Box style={{ marginLeft: "auto" }}>
                                        <Button variant="contained" style={{ backgroundColor: "#dc3545", color: "#ffffff", width: "140px", textTransform: "none", marginRight: "30px" }} onClick={() => navigate('/payables/suppplier/raisepayment')}>Cancel</Button>
                                        {/* {buttonState ? */}
                                        <Button variant="contained" disabled={!buttonState} style={{ backgroundColor: buttonState ? "#032541" : "rgba(3, 37, 65, 0.4)", color: "#ffffff", minWidth: "140px", textTransform: "none", }} onClick={() => handleGenerateInvoice()}>Preview Invoice</Button>
                                        {/* : null} */}
                                    </Box>

                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>


                </>}





        </Grid >
    )
}

export default AddInvoiceSupplier