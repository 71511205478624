import { Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import axios from "axios";
import cardBG from '../../../common/images/TotalTerminalsBG.png'


const baseUrl = process.env.REACT_APP_BASE_URL;

function TotalTerminals() {

  const [terminalTotals, setTerminalTotals] = useState([]);

  let partnerName = localStorage.getItem("affiliatedPartner")

  const fetchTerminals = async () => {
    const response = await axios.post(baseUrl + "/api/terminalCount",
    {
      partnerName
    },
    {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setTerminalTotals(response.data);
    // console.log('terminal totals below')
    // console.log(terminalTotals)
  };

  useEffect(() => {
    fetchTerminals();
  }, []);

  return (
    <Stack
      width={"100%"}
      height="166px"
      justifyContent={"space-between"}
      p={2}
      borderRadius={"12px"}
      boxShadow={"3px 3px 20px 0 rgba(145, 158, 171, 0.16)"}
      bgcolor={"white"}
      style={{
        backgroundImage: `url(${cardBG})`,
        backgroundSize: "70% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily={"Poppins"}
          fontSize={"16px"}
          fontWeight={500}
          fontStretch={"normal"}
          fontStyle={"normal"}
          lineHeight={1.31}
          letterSpacing={"normal"}
          textAlign={"left"}
          color={"#f79009"}
        >
          Total Terminals
        </Typography>
        <PointOfSaleIcon sx={{color: '#f79009'}} />
      </Stack>
      <Stack width="100%">
        <Typography
          fontFamily="Poppins"
          fontSize={18}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {terminalTotals?.total >= 0 ? terminalTotals?.total : 
        <Skeleton variant="text" fontSize={18} width={'30%'} sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
        // <CircularProgress />
        }            
        </Typography>
      </Stack>
      <Stack width={"100%"} direction={"row"} spacing={15}>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
          {terminalTotals?.normal >= 0 ? terminalTotals?.normal : 
        <Skeleton variant="text" fontSize={14}  sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
        // <CircularProgress />
        }            
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Other Businesses
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
          {terminalTotals?.payit >= 0 ? terminalTotals?.payit : 
        <Skeleton variant="text" fontSize={14}  sx={{ bgcolor: '#f0f0f0' }} animation="wave"/>
          // <CircularProgress />
          }            
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="500"
            fontStyle="normal"
            textAlign="left"
            color="#707070"
          >
            Zed Payit
          </Typography>
        </Stack>
        
      </Stack>
    </Stack>
  );
}

export default TotalTerminals;
