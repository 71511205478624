import { Box, Breadcrumbs, Button, Grid, Tab, TextField, Typography } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomInputLabel from "../../School/CustomInputLabel";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../School/CustomTextField";
import { useState, useEffect } from "react";
import CustomSelectField from "../../School/CustomSelectField";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import PhoneInput from "react-phone-input-2";
import { ArrowBackIos } from "@mui/icons-material";



const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Payee</Typography>

]
const AddPayeeForm = () => {
    const navigate = useNavigate()
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [VerifyaccountNumber, setVerifyaccountNumber] = useState('')
    const [bankCode, setBankCode] = useState('')



    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        bankName: '',
        accountNumber: '',
        description: '',
        bankCode: bankCode

    })
    const submitData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: '+' + formData.phoneNumber,
        email: formData.email,
        bankName: formData.bankName,
        accountNumber: formData.accountNumber,
        description: formData.description,
        bankCode: bankCode
    }
    const [banksOptions, setBanksOptions] = useState([])
    const getBanks = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/get_transfer_banks`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200) {
                    let banks = data.response.data
                    console.log(banks, 'banks')
                    banks.map((bank) => {
                        setBanksOptions((old) => [...old, { label: bank.bankName, value: bank.bankName, bankCode: bank.bankCode }])
                    }
                    );

                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getBanks()
    }, [])
    const changeBankCode = () => {
        console.log(banksOptions, 'formData.bankName')
        // console.log(formData.bankName, 'formData.bankName')
        let bankCodeValue = banksOptions.findIndex((bank) => bank.value === formData.bankName)
        bankCodeValue = banksOptions[bankCodeValue]?.bankCode
        setBankCode(bankCodeValue)
        console.log(bankCodeValue, 'bankCode')
    }
    useEffect(() => {
        changeBankCode()
    }, [formData.bankName])
   
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [validEmail, setValidEmail] = useState(true);

    useEffect(() => {
        if (formData.email != "") {
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.email]);
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = () => {
        console.log(submitData, '-')
        if (formData.firstName === '' || formData.lastName === '' || formData.email === '') {
            let emptyFields = []
            if (formData.firstName === '') {
                emptyFields.push('First Name')
            }
            if (formData.lastName === '') {
                emptyFields.push('Last Name')
            }
            if (formData.email === '') {
                emptyFields.push('Email')
            }
            // if (formData.phoneNumber === '') {
            //     emptyFields.push('Phone Number')
            // }
            // if (formData.bankName !== '' || formData.bankCode === undefined) {
            //     emptyFields.push('Bank Name')
            // }
            // if (formData.accountNumber !== '' &&  formData.bankName === '') {
            //     console.log(formData.bankName, 'formData.bankName hapa ---')
            //     emptyFields.push('Bank Name')
            // }
            // if (formData.accountNumber === '' && formData.bankName !== '') {
            //     console.log(formData.bankName, 'formData.bankName here')
            //     emptyFields.push('Account Number')
            // }
            // if (formData.bankName === '') {
            //     emptyFields.push('Bank Name')
            // }
            // if (formData.accountNumber === '') {
            //     emptyFields.push('Account Number')
            // }
            // if (formData.description === '') {
            //     emptyFields.push('Description')
            // }
            let emptyFieldsString = emptyFields.join(', ')
            console.log(emptyFieldsString, 'emptyFieldsString')

            return setErrorShow({ ...errorShow, state: true, message: `Kindly fill the following fields ${emptyFieldsString}` })
        }
        if(formData.accountNumber === '' && formData.phoneNumber === ''){
            return setErrorShow({...errorShow, state: true, message: `Kindly add accountNumber or Phone Number fields`})
        }
        if(formData.bankName !== '' && formData.accountNumber === ''){
            return setErrorShow({...errorShow, state: true, message: `Kindly add accountNumber  fields`})
        }
        if (VerifyaccountNumber !== formData.accountNumber) {
            return setErrorShow({ ...errorShow, state: true, message: `Account number does not match` })
        }
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/payee/new`,
                body: submitData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200 || data.status === 201) {
                    setSuccessShow({ ...successShow, state: true, message: "Payee added successfully" })
                    setTimeout(() => {
                        // setSuccessShow({ ...successShow, state: false, message: "" })
                        navigate('/payables/otherpayments')

                    }, 1000)
                } else {
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.error })
                }
            })
        } catch (error) {

        }
    }

    return (
        <Grid container>
            <Grid item>
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <Grid item mt={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}><ArrowBackIos/>  Add Payee</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px', marginTop:"12px" }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mt={1}>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070",  marginTop:"40px"  }}>  Add Payee Details</Typography>
                </Grid>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1, marginTop:"10px" }}>

                    <Box component="div" sx={{ display: "flex" }}>
                        <Box>
                            <CustomInputLabel required={true} label={"First Name"} />
                            <CustomTextField value={formData.firstName} onChange={handleInputChange} name={"firstName"} placeholder={"First Name"} />

                            <CustomInputLabel  label={"Phone Number"} />
                            {/* <CustomTextField value={
                                formData.phoneNumber} onChange={handleInputChange} name={"phoneNumber"} placeholder={"Phone Number"} /> */}
                            <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"} onChange={(phone) => formData.phoneNumber = phone} />
                            </Box>

                            <CustomInputLabel required={true} label={"Bank"} />
                            <CustomSelectField medium value={formData.bankName} onChange={handleInputChange} name={"bankName"} placeholder={"Select Bank Name"} options={banksOptions} />
                            <CustomInputLabel  label={"Confirm  Account Number"} />
                            <CustomTextField error={VerifyaccountNumber !== formData.accountNumber ? true : false} value={VerifyaccountNumber} onChange={(e) => setVerifyaccountNumber(e.target.value)} name={"accountNumber"} placeholder={"Account Number"} />



                        </Box>

                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomInputLabel required={true} label={"Last Name"} />
                            <CustomTextField value={formData.lastName} onChange={handleInputChange} name={"lastName"} placeholder={"Last Name"} />

                            <CustomInputLabel required={true} label={"Email"} />
                            {!validEmail ?
                                <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                    Please enter a valid email
                                </Typography>
                                :
                                null
                            }
                            <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                            <CustomInputLabel  label={"Account Number"} />
                            <CustomTextField value={formData.accountNumber} onChange={handleInputChange} name={"accountNumber"} placeholder={"Account Number"} />
                            <CustomInputLabel required={false} label={"Description"} />
                            <CustomTextField value={formData.description} onChange={handleInputChange} name={"description"} placeholder={"Description"} />
                            {/* <TextField
                        name={"description"}
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder={"Description"}
                        variant="outlined"
                        margin="dense"
                        sx={{ marginBottom: 2 }}
                        multiline
                        minRows={2}
                        InputProps={{
                            sx: {
                                width: "",
                                fontSize: "0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1",
                                },
                            },
                        }}
                    /> */}


                        </Box>
                    </Box>
                </Box>

                <Box sx={{ marginX: 1 }}>
                    {/* <CustomInputLabel required={false} label={"Description"} />
                    <TextField
                        name={"description"}
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder={"Description"}
                        variant="outlined"
                        margin="dense"
                        sx={{ marginBottom: 2 }}
                        multiline
                        minRows={2}
                        InputProps={{
                            sx: {
                                width: "51rem",
                                fontSize: "0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1",
                                },
                            },
                        }}
                    /> */}
                </Box>
                {/* </Grid> */}
                <Box component="div" sx={{ display: "flex", justifyContent: "end" }}>
                    <Button onClick={() => navigate('/payables/otherpayments')} style={{ backgroundColor: "#fff", border:"1px solid #dc3545",color: "#dc3545", textTransform: "none", fontSize: "14px", fontWeight: 600, marginRight: "10px", width: "10rem", height: "2.5rem" }}>Cancel</Button>
                    <Button disabled={!formData.firstName || !formData.lastName || !formData.email || (formData.bankName && !formData.accountNumber) } style={!formData.firstName || !formData.lastName || !formData.email || (formData.bankName && !formData.accountNumber) ? {opacity: 0.4, width: "125px", background: "#032541", color:'#fff', textTransform: "inherit"}:{ backgroundColor: "#032541", color: "#fff", textTransform: "none", fontSize: "14px", fontWeight: 600, width: "10rem", height: "2.5rem", opacity:1 }} onClick={() => handleSubmit()} >Add </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddPayeeForm