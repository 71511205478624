import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchValue: "",
    selected: "",
    selected2: ""
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelect: (state, action) => {
      state.selected = action.payload;
    },
    setSelect2: (state, action) => {
      state.selected2 = action.payload;
    },
  },
});

export const { setSearch, setSelect, setSelect2 } = searchSlice.actions;

export default searchSlice.reducer;
