import { Breadcrumbs, Grid, Tab, Button, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react"
import AddUniversityStaff from "./AddUniStaff";
import EditStaffProfile from "./updateSatffProfile";
import NonTeachingStaff from "./nonteachingStaff";
import { useLocation, useNavigate } from 'react-router-dom'
import AddTeachingUniversityStaff from "./AddTeachingStuff";
import AddUnitsStaff from "./AddUnitsToStaff";
import PreviewteachingStaffDetails from "./previewStaffDetails";
import TeachingStaff from "./teachingStaff";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Staff</Typography>
]

// custom tab styling
const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": { backgroundColor: "#fef7f8" },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "18px",
        background: "#fafafa",
        borderRadius: "5px",
        marginLeft: "-10px",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": { color: "#032541", opacity: 1 },
        "&.Mui-selected": {
            color: "#dc3545",
            backgroundColor: "#fef7f8",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": { backgroundColor: "blue" },
    })
);


export default function StaffUniversityMain() {

    const [tabValue, setTabValue] = useState('teachingstaff')

    //handle tabvalue change
    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)

    const [paramValue, setParamvalue] = useState('')
    const location = useLocation()
    const navigate = useNavigate()

    // console.log(location , 'location')

    // const location = useLocation()
    const queryParams = decodeURIComponent(window.location.search)
    const handleUrlParamsChange = () => {

        const stateDate = queryParams.slice(1)
        const state = stateDate.split("?")[0]
        setParamvalue(state)

    }

    useEffect(() => {
        handleUrlParamsChange()
    }, [queryParams])

    // 

    return (
        <Grid container direction={'column'}>
            {paramValue === 'Addstuff' ? <AddUniversityStaff /> : paramValue === 'updateStaffProfile' ? <EditStaffProfile /> : paramValue === 'AddTeachingstuff' ? <AddTeachingUniversityStaff /> : paramValue === 'AddTeachingUnit' ? <AddUnitsStaff /> : paramValue === 'PreviewStaff' ? <PreviewteachingStaffDetails /> : <>
                <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Staff</Typography>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Button onClick={() => tabValue === 'teachingstaff' ? navigate(`/school/universityStaff?AddTeachingstuff`) : navigate(`/school/universityStaff?Addstuff`)} style={{ background: "#032541", width: "141px", height: "45px", fontSize: "14px", textTransform: "inherit", color: "#fff" }}>Add Staff</Button>
                    </Grid>
                </Grid>
                <Grid item mt={2}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleTabChange} sx={{ display: "flex" }}>
                            <AntTab label="Teaching Staff" value="teachingstaff" />
                            <AntTab
                                label="Non-Teaching Staff"
                                value="nonteachingstaff"
                                style={{ marginLeft: "20px" }}
                            />
                        </AntTabs>
                        <TabPanel value="teachingstaff" style={{ marginLeft: "-30px" }}>
                            <Grid item>
                                <TeachingStaff/>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="nonteachingstaff" style={{ marginLeft: "-30px" }}>
                            <Grid item>
                                <NonTeachingStaff />
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>
            }
        </Grid>
    )

}