import React, { useState, useEffect } from "react";
import { Modal, Box, Grid, ButtonBase } from "@mui/material";
import Mpesa from "../../../common/images/mpesa-icon.svg";
import Card from "../../../common/images/card-icon.svg";
import Credit from "../../../common/images/creditbal-icon.svg";
import { MpesaStk } from "./mpesaStk";

const PaymentMethods = (props) => {
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(
    props.paymentMethodsOpen
  );
  const [mpesaPaymentOpen, setMpesaPaymentOpen] = useState(false);
  const [cardPaymentOpen, setCardPaymentOpen] = useState(false);

  const wholeModal = {
    width: "520px",
    height: "477px",
    backgroundColor: "#fff",
    borderColor: "transparent",
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const headerSection = {
    backgroundColor: "#dc3545",
    width: "100%",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    height: "109px",
    flexDirection: "row",
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
  };
  const buttons = {
    display: "flex",
    height: "60px",
    width: "420px",
    border: "solid 0.5px #707070",
    marginTop: "10px",
    marginLeft: "9%",
    borderRadius: "6px",
    cursor: "Pointer",
  };

  useEffect(() => {
    setPaymentMethodsOpen(props.paymentMethodsOpen);
  }, [props.paymentMethodsOpen]);

  const stK = () => {
    props.onClose();
    setMpesaPaymentOpen(true);
  };

  return (
    <div>
      <Modal
        open={paymentMethodsOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="payment-modal"
      >
        <Box sx={wholeModal}>
          <div style={headerSection}>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                fontWeight: "bold",
                fontSize: 25,
                paddingTop: "40px",
              }}
            >
              Pay Invoice
            </div>
          </div>

          <Grid
            style={{
              marginTop: "20px",
            }}
            id="payment-methods"
          >
            <Grid item>
              <div
                style={{
                  paddingLeft: "10%",
                  color: "#032541",
                }}
              >
                <div>Select a payment method</div>
              </div>
            </Grid>
            <Grid item sx={buttons} onClick={() => stK()}>
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "5%",
                }}
              >
                <img
                  src={Mpesa}
                  alt="Mpesa"
                  style={{
                    height: "32px",
                    width: "100px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "2%",
                  color: "#032541",
                  fontFamily: "Poppins",
                }}
              >
                <div>Mpesa</div>
              </div>
            </Grid>

            <Grid item sx={buttons}>
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "12%",
                }}
              >
                <img
                  src={Card}
                  alt="Card"
                  style={{
                    height: "35px",
                    width: "32px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "2%",
                  color: "#032541",
                  fontFamily: "Poppins",
                }}
              >
                <div>Card</div>
              </div>
            </Grid>
            <Grid item sx={buttons}>
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "12%",
                }}
              >
                <img
                  src={Credit}
                  alt="Credit Balance"
                  style={{
                    height: "30px",
                    width: "32px",
                  }}
                />
              </div>
              <div
                style={{
                  paddingLeft: "2%",
                  color: "#032541",
                  fontFamily: "Poppins",
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>Credit Balance</span>
                  <span>Available Bal. KES 20,000</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "40px",
                  }}
                >
                  <span>New Balance</span>
                  <span>KES 2,000</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/*<>*/}
      {/*    /!*Mpesa payments*!/*/}
      <MpesaStk
        mpesaStkOpen={mpesaPaymentOpen}
        onClose={() => setMpesaPaymentOpen(false)}
        invoiceBalance={props.invoiceBalance}
        invoiceNumber={props.invoiceNumber}
      />
      {/*    /!*Card Payments*!/*/}
      {/*    <CardPayments mpesaOpen={cardPaymentOpen}  onClose={() => setCardPaymentOpen(false)}/>*/}
      {/*</>*/}
    </div>
  );
};
export { PaymentMethods };
