import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessId: "",
  branchId: "",
  data: [],
  branches: []
}


const selectedSlice = createSlice({
  name:"terminalSelect",
  initialState,
  reducers:{
    handleBusinessId:(state, action) => {
      state.businessId = action.payload
    },
    handleBranchId:(state, action) => {
      state.branchId = action.payload
    },
    handleData:(state, action) => {
      state.data = action.payload
    },
    handleBranches:(state, action) => {
      state.branches = action.payload
    },
    removeClicked: (state, action) => {
        state.id = ""
      },
  }
})

export const { handleBusinessId,handleBranchId, removeClicked, handleData, handleBranches } = selectedSlice.actions;

export default selectedSlice.reducer