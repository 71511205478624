/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Grid, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import CurrencyFormatter from "../../../../../utils/currencyFormatter";
import CustomTable from "../../../../School/CustomTable";

// Bread Crumbs

export default function ListHouseTypes(props) {
  try {
    const [selectedValue, setSelectedValue] = useState();
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const columns = [
      {
        field: "unitType",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Unit Type</strong>;
        },
        renderCell: (params) => {
          return (
            <div onClick={() => props.handleDetails(params)}>
              <Link onClick={handleNext}>
              <span style={{ marginLeft: "5px" }}>{params.value}</span>
              </Link>
              <Link  value={params.id} onChange={handleChange} color={"error"} />
              {/* <Radio value={params.id} size={"small"} checked={selectedValue === params.id} onChange={handleChange} color={"error"} /> */}
            
            </div>
          );
        },
      },
      {
        field: "unitTypeCount",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>units</strong>;
        },
      },
      {
        field: "unitCost",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Amount</strong>;
        },
        renderCell: (params) => {
          return <span>{CurrencyFormatter(params.value, "KES")}</span>;
        },
      },
    ];

    // loader
    const [loader, setLoader] = useState(false);

    // Search functionality
    const [dataToShow, setDataToShow] = useState([]);

    const [formData, setFormData] = useState({ search: "" });
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //search data

    const setSearchData = () => {
      let searchable = [...props.data];
      if (formData.search !== "") {
        let result = searchable.filter((unit) => unit.unitType.toLowerCase()?.includes(formData.search) || unit.unitTypePrefix?.includes(formData.search));
        setDataToShow(result);
      } else {
        setDataToShow(props.data);
      }
    };

    // Use Effect
    // Search UseEffect
    useEffect(() => {
      setSearchData();
    }, [formData.search, props.data]);

    function handleNext(e) {
      props.listHouseUnits();
    }

    return (
      <div>
        <CustomTable fontSize={"10px"} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />
        <Grid container justifyContent={"flex-end"} spacing={3}>
          {/*Add Unit*/}
          {/* {!selectedValue ? null : ( */}
            {/* // <Grid item>
            //   <Button onClick={handleNext} style={{ padding: "5px", textTransform: "none", color: "#fff", background: "#032541", height: "45px", fontSize: "16px", fontWeight: "600", width: "146px" }}>
            //     Next
            //   </Button>
            // </Grid> */}
          {/* )} */}
        </Grid>
      </div>
    );
  } catch (e) {
    console.log(`Error on House History`, e.message);
  }
}
