import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  businessCat: null,
  categoriesToAdd: null,
  checkedItems:[],
  isClicked: false,
};

const businessCategorySlice = createSlice({
  name: "businessCategory",
  initialState,
  reducers: {
    addCategory: (state, action) =>{
      state.businessCat = action.payload
    },
    handleBusinessCategory: (state, action) => {
      state.businessCat = action.payload;
    },
    deleteBusinessCategoryDetails: (state) => {
      state.businessCat = null;
    },
    addCategoriesToAdd : (state,action) =>{
      if(!state.categoriesToAdd){
        state.categoriesToAdd = [action.payload]
      }else{
        state.categoriesToAdd.push(action.payload)
      }
    },
    removeFromCategoriesToAdd:(state, action) =>{
      let index = action.payload.index;
      if (index > -1) {
        state.categoriesToAdd.splice(index, 1);
      }
    },
    clearCategoriesToAdd: (state, action) =>{
      state.categoriesToAdd = null;
    },
    updateCheckedItems: (state, action) =>{
      state.checkedItems = [...action.payload];
    },

    setRowClicked: (state, action) =>{
      state.isClicked = action.payload;
    },
    updateItem: (state, action) =>{
      state.categoriesToAdd = action.payload;
    }
  },
});

export const { addCategory, handleBusinessCategory, deleteBusinessCategoryDetails ,addCategoriesToAdd,removeFromCategoriesToAdd,clearCategoriesToAdd, updateCheckedItems,setRowClicked} = businessCategorySlice.actions;

export default businessCategorySlice.reducer;
