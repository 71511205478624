import { Box, Breadcrumbs, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HttpComponent from '../School/MakeRequest';
import { DataGrid } from '@mui/x-data-grid';
import CustomTable from '../School/CustomTable';
import CustomSearchInput from '../School/CustomSearchInput';

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Product Sales Report
    </Typography>
];

const ProductSalesReport = () => {
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const getRowId = (row) => {
        return row?._id || row?.id || row?.gridId || Math.floor(Math.random() * 2000000)
    }


    const salesReportSummary = async () => {
        setPageState({ ...pageState, isLoading: true })
        HttpComponent({
            method: 'GET',
            url: `/products/sale_report_per_product?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
            body: null,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            if (data.status === 200) {
                setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
            }

        })
    }

    useEffect(() => {
        salesReportSummary()
    }, [pageState.page, dataGridPageSize, searchValue])

    const columns = [
        { field: "productName", headerName: "Product Name", flex: 1, align: "center", headerAlign: "center", },
        { field: "itemCount", headerName: "Unit sold", flex: 1, align: "center", headerAlign: "center", },
        { field: "totalAmount", headerName: "Cum Amount", flex: 1, align: "center", headerAlign: "center", },

    ]


    return (
        <Grid>
            <Grid container>
                <Grid container sx={{ flex: 1 }}>
                    <Grid item>
                        <label style={{ width: "100%", height: "35px", margin: "10px 32px 21px 12px", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545", }} >
                            Product Sales Report
                        </label>
                    </Grid>
                </Grid>
                
                <Grid container sx={{ margin: "0px 0px 20px 0px" }}>
                    <Box component="div" sx={{}}>
                        <Breadcrumbs
                            separator={
                                <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                            }
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                </Grid>
                <Grid container>
                    <Grid item>
                        <CustomSearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder={"Search Product Name"} />
                    </Grid>
                </Grid>
                <Grid item width={'100%'}>
                    <DataGrid
                            sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[10, 20, 50, 70, 100 ]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) =>  setPageSize(newPageSize)}
                            columns={columns}
                            rows={pageState?.data}
                            getRowId={getRowId} 
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProductSalesReport