import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HttpComponent from "../../../School/MakeRequest";
import CardTransactionIcon from "../../../../images/card-icon.svg"
import TotalTransactionIcon from "../../../../images/total-amount.svg"
import TransactIcon from "../../../../images/perfomance-g.svg"
import ToatAmountctIcon from "../../../../images/graph-icon.svg"
import BioTransactIcon from "../../../../images/bio.svg"
import OutletIcon from "../../../../images/outletIcon.svg"
import { useEffect, useState } from "react";
import DateFormatter from "../../../../utils/dateFormatter";
import CustomSearchInput from "../../../School/CustomSearchInput";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";


const baseUrl = process.env.REACT_APP_BASE_URL;


const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

// configure chart

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);


export default function PayitSuperAdminDashboard() {
    //get todays perfomance

    const [todaysStartdate, setTodaysStartDate] = useState(new Date().toLocaleDateString())
    const [todaysEnddate, setTodaysEndDate] = useState(new Date().toLocaleDateString())
    const thisMonth = new Date();
    const firstDayMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), 1).toLocaleDateString();
    const currentDayMonth = new Date().toLocaleDateString();
    const [overallPerformanceStartDate, setOverallPerfmanceStartDate] = useState(firstDayMonth)
    const [overallPerformanceendDate, setOverallPerfmanceendDate] = useState(currentDayMonth)
    const [todayPerfomanceTransactions, setTodayPerfomanceTransactions] = useState([])
    const [todaysTotal, setTodaysTotal] = useState(0)
    const transactionIds = ['bio', 'card']


    const gridFormatDate = (inputDate) => {
        // const today = new Date();
        const yyyy = new Date(inputDate)?.getFullYear();
        let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
        let dd = new Date(inputDate)?.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;

        return formattedToday;
    };

    useEffect(() => {
        const newStartDate = gridFormatDate(todaysStartdate);
        setTodaysStartDate(newStartDate)
        const newEndDate = gridFormatDate(todaysEnddate);
        setTodaysEndDate(newEndDate)
    }, [todaysStartdate, todaysEnddate]);


    //get dates for last month

    const date2 = new Date();
    const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
    const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();

    const date1 = new Date();
    const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
    const currentDay = new Date().toLocaleDateString();

    const [startDateThisMonth, setStartDateThisMonth] = useState(firstDay)
    const [endDateThisMonth, setendDateThisMonth] = useState(currentDay)

    const [lastMonthStartDate, setLastMonthStartDate] = useState(firstDayLastMonth)
    const [lastMonthEndDate, setLastMonthendDate] = useState(lastDayLastMonth)

    useEffect(() => {
        //last Month

        setLastMonthStartDate(firstDayLastMonth);
        setLastMonthendDate(lastDayLastMonth);


    }, [firstDayLastMonth, lastDayLastMonth])

    useEffect(() => {

        //currentMonth
        setStartDateThisMonth(firstDay);
        setendDateThisMonth(currentDay);

    }, [firstDay, currentDay])

    // This Month perfomance
    const [showCurrentMonthTotals, setShowCurrentMonthTotals] = useState(0)

    useEffect(() => {
        function showCurrentMonthPerfomance() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTotalPayitTransactionsByAdmin?startDate=${startDateThisMonth}&endDate=${endDateThisMonth}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setShowCurrentMonthTotals(data?.response?.total)
                    }
                })

            } catch (error) {
                console.log(error?.message)
            }
        }

        showCurrentMonthPerfomance()
    }, [endDateThisMonth, startDateThisMonth])



    // Last Month perfomance
    const [lastMonthTotals, setLastMonthTotals] = useState(0)


    useEffect(() => {
        function showLastMonthPerfomance() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTotalPayitTransactionsByAdmin?startDate=${lastMonthStartDate}&endDate=${lastMonthEndDate}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setLastMonthTotals(data?.response?.total)
                    }
                })

            } catch (error) {
                console.log(error?.message)
            }
        }

        showLastMonthPerfomance()
    }, [lastMonthStartDate, lastMonthEndDate])




    //TODAYS PERFOMANCE


    useEffect(() => {
        function getTodayPerformance() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTotalPayitTransactionsByAdmin?startDate=${todaysStartdate}&endDate=${todaysEnddate}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setTodayPerfomanceTransactions(data?.response?.data)
                        setTodaysTotal(data?.response?.total)
                    }

                })
            } catch (error) {

            }

        }
        getTodayPerformance()
    }, [todaysStartdate, todaysEnddate])

    // setOverallPerfomanceDates

    const setOverallPerfomanceDates = (e) => {
        switch (e.target.value) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setOverallPerfmanceStartDate(today)
                setOverallPerfmanceendDate(today)
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString()
                setOverallPerfmanceStartDate(yeseterday)
                setOverallPerfmanceendDate(yeseterday)
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();
                    setOverallPerfmanceStartDate(lastSevenFirst)
                    setOverallPerfmanceendDate(lastSevenLast)
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
                const currentDay = new Date().toLocaleDateString();
                setOverallPerfmanceStartDate(firstDay)
                setOverallPerfmanceendDate(currentDay)
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
                const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
                setOverallPerfmanceStartDate(firstDayLastMonth)
                setOverallPerfmanceendDate(lastDayLastMonth)
                break;
            default:
                setOverallPerfmanceStartDate('')
                setOverallPerfmanceendDate('')
                break;
        }

    }


    // get total outlets
    const [allOutlets, setAllOutlets] = useState(0)


    useEffect(() => {
        function getTotalOutlets() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getAllOutletsByAdmin`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setAllOutlets(data?.response?.data?.[0]?.total_outlets)
                    }
                })
            } catch (error) {
            }
        }

        getTotalOutlets()

    }, [])


    //over perfomance : default ==> monthly
    const [overallPerfomanceTransactions, setOverallPerfmanceTransactions] = useState(0)
    const [overallPerfomanceTotals, setOverallPerfmanceTotals] = useState(0)

    useEffect(() => {
        function getTodayPerformance() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTotalPayitTransactionsByAdmin?startDate=${overallPerformanceStartDate}&endDate=${overallPerformanceendDate}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setOverallPerfmanceTransactions(data?.response?.count)
                        setOverallPerfmanceTotals(data?.response?.total)
                    }

                })
            } catch (error) {

            }

        }

        getTodayPerformance();

    }, [overallPerformanceStartDate, overallPerformanceendDate])

    // top instutions

    const [topInstutionsPiechart, setTopInstutionsPiechart] = useState([])

    useEffect(() => {
        function getTopInstitutionsPieChart() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTopInstitutionByAdmin`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    console.log(data, 'data >>')
                    if (data?.status === 200) {
                        setTopInstutionsPiechart(data?.response?.data)
                    }

                })

            } catch (error) {

            }
        }
        getTopInstitutionsPieChart();

    }, [])


    const pieCahrtlabels = topInstutionsPiechart?.map((item) => item?.businessName);
    const pieChartdatas = topInstutionsPiechart?.map((item) => item?.total);

    const pieChartOptions = {

    }

    const piechartData = {
        labels: pieCahrtlabels,
        datasets: [
            {
                data: pieChartdatas,
                backgroundColor: ['#64ca5b', '#9020e0', '#22409a', '#f22431', '#d30088'],
            },
        ],
    };

    // filter top institutions

    const setStartAndEndDates = () => {

    }

    // all transactions columns

    const [searchAllTransactions, setSearchAllTransactions] = useState('')
    const [allTransactionsStartDate, setAllTransactionsStartDate] = useState(new Date().toLocaleDateString())
    const [allTransactionsendDate, setAllTransactionsendDate] = useState(new Date().toLocaleDateString())
    const [selectedAllTransactionTransType, setSelectedAllTransactionTransType] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    const AllTransactionColumns = [

        { headerName: "Receipt No", field: 'receiptNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Receipt No</strong>) } },
        { headerName: "Created At", field: 'transTime', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <span>{DateFormatter(params?.row?.transactionTime)}</span> },
        { headerName: "Source", field: 'source', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Source</strong>) }, renderCell: (params) => <span>{params?.row?.source}</span> },
        { headerName: "Transaction Type", field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >TxN Type</strong>) }, renderCell: (params) => <Typography sx={{ color: params?.row?.transactionType === 'DEBIT' ? '#dc3545' : params?.row?.transactionType === 'CREDIT' ? '#17ae7b' : '#707070' }}>{params?.row?.transactionType}</Typography> },
        { headerName: "businessId", field: 'businessId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Institutions</strong>) } },
        { headerName: "Teller Id", field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) } },
        { headerName: "Terminal Serial", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) } },
        { headerName: "amount", field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Amount (KES)</strong>) }, renderCell: (params) => <Typography>{numberFormat(params?.row?.amount)}</Typography> },
    ]

    useEffect(() => {
        function getAllTransactionsLists() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getAllTransactionsByAdmin?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchAllTransactions}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    // console.log(data , 'instutunnnnnnnnnn')
                    if (data.status === 200) {
                        setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    }
                })
            } catch (error) {

            }
        }
        getAllTransactionsLists();

    }, [dataGridPageSize, pageState.page, searchAllTransactions])

    //fetch top instutions 

    const [topInstitutionDataGridPageSize, setTopInstitutiondataGridPageSize] = useState(10);
    const [topInstutionPageState, setTopInstutionPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: topInstitutionDataGridPageSize, })

    useEffect(() => {
        function fetchTopInstitutionsTotals() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTopInstitutionByAdmin?page=${topInstutionPageState.page}&limit=${topInstitutionDataGridPageSize}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setTopInstutionPageState({ ...topInstutionPageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    }
                })
            } catch (error) {

            }

        }

        fetchTopInstitutionsTotals();

    }, [topInstitutionDataGridPageSize, topInstutionPageState.page,])


    // overall perfomance chart

    const [overallPerfomanceMonthly, setOverallPerfomanceMonthly] = useState([])

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [handleDaysChange, setHandleDaysChange] = useState("Last7days");

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last7days":
                function setDefaultDates() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();
                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                setDefaultDates()
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
                const currentDay = new Date().toLocaleDateString();
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
                const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                setStartDate('')
                setEndDate('')
                break;
        }
    }, [handleDaysChange, startDate, endDate]);

    useEffect(() => {

        function handleOverallPerfomanceMonthly() {
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/v1/getTotalPayitTransactionsByAdmin?startDate=${startDate}&endDate=${endDate}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data?.status === 200) {
                        setOverallPerfomanceMonthly(data?.response?.data)
                    }

                })
            } catch (error) {
                console.log(error?.message)
            }

        }

        handleOverallPerfomanceMonthly();

    }, [startDate, endDate])


    //Bar chart 

    const [selectedTransactionType, setSelectedTransactionType] = useState('')

    const [selectedOptionBar, setSelectedOptionBar] = useState('weeks')

    const labelsMonthly = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


    // Bar chart data
    const [barChartData, setBarChartData] = useState({
        labels: labelsMonthly,
        datasets: [
            {
                label: 'Card Transactions',
                data: [],
                backgroundColor: '#22409a',
            },
            {
                label: 'Bio Transactions',
                data: [],
                backgroundColor: '#ab55ff',
            },
            {
                label: 'Total',
                data: [],
                backgroundColor: '#009447',
            },
        ],
    });



    //console.log(barChartData , 'barChartData')

    // add all months

    async function generateMonthLabels(months) {
        const currentDate = new Date();
        const labels = [];

        for (let i = 0; i < months; i++) {
            const monthDate = new Date(currentDate);
            monthDate.setMonth(currentDate.getMonth() - i);

            const month = labelsMonthly[monthDate.getMonth()];
            const year = monthDate.getFullYear();

            labels.unshift(`${month} ${year}`);
        }

        return labels;
    }

    // options



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title:
                <Grid item>
                    <Typography>Transactions</Typography>
                </Grid>
        },
        scales: {
            y: {
                min: 0,
                max: 5000,
                stepSize: 100,
                position: 'top',
                drawOnChartArea: false,
                title: {
                    display: true,
                    text: 'Amount in 000',
                    padding: { top: 20, bottom: 0 },
                }
            },
            x: {
                beginAtZero: true,
                position: 'bottom',
                title: {
                    display: true,
                },
            }
        },
    };

    useEffect(() => {
        async function fetchDataFromAPI() {
            try {
                const response = await fetch(`${baseUrl}/api/v1/getZPMAdminBarchartTotals`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        'X-Authorization': localStorage.getItem('X-Authorization'),
                    },
                });

                if (response.status === 200) {
                    const apiData = await response.json();

                    const numMonthsToShow = 12;

                    // Generate labels for the specified number of months
                    const labels = await generateMonthLabels(numMonthsToShow);

                    // console.log(apiData?.data, 'labels >>>>>>>>>>>>>')

                    const cardData = labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        //console.log(foundData, 'youuuuuuuuuuuuuu')
                        return foundData ? foundData?.CardTotal : 0;
                    });

                    const bioData = labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        return foundData ? foundData?.BioTotal : 0;
                    });

                    const totalData = labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        return foundData ? foundData?.MonthlyTotal : 0;
                    });



                    const updatedBarchartData = {
                        ...barChartData,
                        labels: labels,
                        datasets: [
                            {
                                ...barChartData.datasets[0],
                                data: cardData,
                            },
                            {
                                ...barChartData.datasets[1],
                                data: bioData,
                            },
                            {
                                ...barChartData.datasets[2],
                                data: totalData,
                            },
                        ],
                    };

                    setBarChartData(updatedBarchartData)

                    // update options

                    const totalDataFound = updatedBarchartData?.datasets?.find(dataset => dataset?.label === 'Total');
                    const maxTotalValue = Math.max(...totalDataFound?.data);


                    //updated options will be

                    const updatedOptions = {
                        ...options,
                        scales: {
                            y: {
                                ...options.scales.y,
                                max: maxTotalValue ? maxTotalValue : options?.scales.y.max,
                            },
                            x: {
                                ...options.scales.x,
                                grid: {
                                    display: false
                                }
                            }
                        },
                    };

                    setBarChartData({
                        ...updatedBarchartData,
                        options: updatedOptions,
                    });


                }
            } catch (error) {
                console.error(error);
            }
        }

        // Call the function to fetch data from the API
        fetchDataFromAPI();
    }, []);




    return (
        <Grid direction={'column'} container>
            <Grid item display={'flex'} mt={3}>
                <Grid container display={'flex'} direction={'row'} spacing={2}>
                    <Grid item width={'50%'}>
                        <Paper elevation={2} style={{ width: "100%", height: "250px", padding: "10px 10px  10px 10px" }} >
                            <Grid item>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item pt={2}>
                                        <Typography fontSize={'14px'} fontWeight={'bold'} style={{ color: '#032541' }}>Today's Performance</Typography>
                                    </Grid>
                                    <Grid item pt={5} display={'flex'} width={'100%'}>
                                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                                            {transactionIds?.map((transaction) => {
                                                const resultFound = todayPerfomanceTransactions?.find((item) => item?._id === transaction);
                                                const total = resultFound?.total || 0;


                                                return (
                                                    <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                        <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                            <img height={'40px'} width={'40px'} src={transaction === 'card' ? CardTransactionIcon : transaction === 'bio' ? BioTransactIcon : null} alt="" />
                                                        </Box>
                                                        <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>{transaction === 'card' ? 'Card Transactions' : 'Bio Transactions'}</Typography>
                                                        <Typography sx={transaction === 'card' ? { color: '#22409a', fontWeight: 'bold', fontSize: '20px' } : transaction === 'bio' ? { color: '#ab55ff', fontWeight: 'bold', fontSize: '20px' } : {}}>{numberFormat(total)}</Typography>
                                                    </Grid>
                                                )
                                            })}
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={TotalTransactionIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Total Amount</Typography>
                                                <Typography sx={{ color: '#009447', fontWeight: 'bold', fontSize: '20px' }}>{numberFormat(todaysTotal)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item width={'50%'}>
                        <Paper elevation={2} style={{ width: "100%", height: '250px', padding: "10px 10px  10px 10px" }}>
                            <Grid item width={'100%'}>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography fontSize={'14px'} fontWeight={'bold'} style={{ color: '#032541' }}>Overall Performance</Typography>
                                        <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-label">Date Filter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Date Filter"
                                                onChange={setOverallPerfomanceDates}
                                            >
                                                <MenuItem value=''>All</MenuItem>
                                                <MenuItem value={'Today'}>Today</MenuItem>
                                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item pt={3} display={'flex'} width={'100%'}>
                                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={TransactIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Transactions</Typography>
                                                <Typography sx={{ color: '#22409a', fontWeight: 'bold', fontSize: '20px' }}>{overallPerfomanceTransactions}</Typography>
                                            </Grid>
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={ToatAmountctIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Total Amount</Typography>
                                                <Typography sx={{ color: '#009447', fontWeight: 'bold', fontSize: '20px' }}>{numberFormat(overallPerfomanceTotals)}</Typography>
                                            </Grid>
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={OutletIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Outlets</Typography>
                                                <Typography sx={{ color: '#f87f05', fontWeight: 'bold', fontSize: '20px' }}>{allOutlets}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={2} item display={'flex'}>
                <Grid container display={'flex'} direction={'row'} spacing={2}>
                    <Grid item width={'50%'} display={'flex'} direction={'column'}>
                        <Paper sx={{ width: '100%', height: '490px' }}>
                            <Grid container display={'flex'} direction={'column'} mt={2}>
                                <Grid width={'100%'} item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid pl={1} item width={'50%'} display={'flex'}>
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#032541' }}>Transactions</Typography>
                                    </Grid>
                                    <Grid item width={'50% '} pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                        <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', }}>
                                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                            <Select
                                                sx={{ height: "40px" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedTransactionType}
                                                label="Type"
                                                onChange={(e) => setSelectedTransactionType(e.target.value)}
                                            >
                                                <MenuItem value='all'>All</MenuItem>
                                                <MenuItem value='card'>Card</MenuItem>
                                                <MenuItem value="Mpesa">Bio</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', marginLeft: "10px" }}>
                                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                            <Select
                                                sx={{ height: "40px" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedOptionBar}
                                                label="Date"
                                                onChange={(e) => setSelectedOptionBar(e.target.value)}
                                            >
                                                <MenuItem value="monthly">Monthly</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item display={'flex'} width={'100%'} height={'100%'} mt={2} px={3}>
                                    <Bar height={'160px'} options={barChartData?.options} data={barChartData} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item width={'50%'} display={'flex'} direction={'column'}>
                        <Paper sx={{ width: '100%', height: '490px' }}>
                            <Grid container display={'flex'} direction={'column'} mt={2}>
                                <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item pl={1} width={'50%'} display={'flex'}>
                                            <Typography variant="" sx={{ fontSize: '18px', fontWeight: 'bold', color: '#032541' }}>Top Institutions</Typography>
                                        </Grid>
                                        <Grid item width={'50% '} pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                            <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', marginLeft: "10px" }}>
                                                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={setStartAndEndDates} label="Filter">
                                                    <MenuItem value=''>All</MenuItem>
                                                    <MenuItem value={'Today'}>Today</MenuItem>
                                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={'100%'} justifyContent={'center'} display={'flex'}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '400px', width: '300px' }}>
                                        <Pie options={pieChartOptions} data={piechartData} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} display={'flex'} direction={'column'}>
                <Grid container>
                    <Grid item width={'100%'}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Grid item>
                                <CustomSearchInput value={searchAllTransactions} onChange={(e) => setSearchAllTransactions(e.target.value)} placeholder={'search:'} />
                            </Grid>
                            <Grid item>
                                <Grid container display={'flex'} alignItems={'center'}>
                                    <Grid item mt={-1}>
                                        <FormControl style={{ width: "190px" }}>
                                            <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                                            <Select
                                                // sx={{ height: "40px" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedAllTransactionTransType}
                                                label="Transaction Type"
                                                onChange={(e) => setSelectedAllTransactionTransType(e.target.value)}
                                            >
                                                <MenuItem value=''>All</MenuItem>
                                                <MenuItem value='card'>Card</MenuItem>
                                                <MenuItem value="Mpesa">Bio</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid mt={-1} ml={1} item display={'flex'} alignItems={'center'}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="startDate"
                                                value={allTransactionsStartDate}
                                                onChange={(newValue) => setAllTransactionsStartDate(newValue)}
                                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid mt={-1} item display={'flex'} ml={2} alignItems={'center'}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="startDate"
                                                value={allTransactionsendDate}
                                                onChange={(newValue) => setAllTransactionsendDate(newValue)}
                                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mt={2} display={'flex'} width={'100%'}>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={pageState.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={AllTransactionColumns}
                            rows={pageState?.data}
                            getRowId={row => row?._id} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={3}>
                <Grid container display={'flex'} direction={'row'} spacing={2}>
                    <Grid item width={'60%'}>
                        <Paper elevation={2} sx={{ height: '470px', width: '100%' }}>
                            <Grid display={'flex'} direction={'column'}>
                                <Grid pt={3} width={'100%'} item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid pl={1} item display={'flex'}>
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#032541' }}>Transactions</Typography>
                                    </Grid>
                                    <Grid item pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                        <FormControl style={{ width: '140px', backgroundColor: '#f7f7f7' }}>
                                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Date"
                                                value={handleDaysChange}
                                                onChange={(e) => setHandleDaysChange(e.target.value)}
                                            >
                                                <MenuItem value={'Today'}>Today</MenuItem>
                                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                <MenuItem value={'Last7days'}>Weekly</MenuItem>
                                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid pl={1} item pt={2} direction={'column'} display={'flex'}>
                                    <Grid item>
                                        <Grid container display={'flex'} direction={'column'}>
                                            <Grid item>
                                                <Typography fontWeight={'bold'} fontSize={'20px'} sx={{ color: "#707070" }}>Total Amount</Typography>
                                            </Grid>
                                            <Grid item py={2}>
                                                <Typography fontWeight={'bold'} sx={{ color: '#009447', fontSize: '30px' }}>{numberFormat(showCurrentMonthTotals ? showCurrentMonthTotals : 0)}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{ color: '#707070' }}>Compared to <span style={{ fontWeight: 'bold' }}>{numberFormat(lastMonthTotals ? lastMonthTotals : 0)}</span> <span>Last Month</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid display={'flex'} item width={'100%'} pl={1} pr={2}>
                                        <Grid container width={'100%'}>
                                            {transactionIds?.map((monthT) => {
                                                const resultFound = overallPerfomanceMonthly?.find((item) => item?._id === monthT);
                                                const total = resultFound?.total || 0;
                                                const totalTransact = resultFound?.count || 0
                                                const totalPercent = resultFound?.percentageTotal || 0

                                                return (
                                                    <Grid key={monthT?._id} item width={'100%'} pt={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Grid item display={'flex'} alignItems={'center'}>
                                                            <Box mb={2}>
                                                                {monthT === 'card' ? <img height={'40px'} width={'40px'} src={CardTransactionIcon} alt="" /> : monthT === 'bio' ? <img height={'40px'} width={'40px'} src={BioTransactIcon} alt="" /> : null}

                                                            </Box>
                                                            <Box mb={2} sx={{ display: 'flex', marginLeft: '10px', flexDirection: 'column' }}>
                                                                <Typography sx={{ color: '#707070', fontSize: '16px' }}>{monthT === 'card' ? 'Card Transactions' : monthT === 'bio' ? 'Bio Transactions' : null}</Typography>
                                                                <Typography sx={{ color: monthT === 'card' ? '#22409a' : monthT === 'bio' ? '#f87f05' : '', fontWeight: 'bold', fontSize: '20px' }}>{monthT === 'card' ? numberFormat(total) : monthT === 'bio' ? numberFormat(total) : numberFormat(0)}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid container spacing={2} flex={1} display={'flex'} ml={2} direction={'column'}>
                                                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                                <Typography sx={{ color: '#707070', fontSize: '12px' }}>{monthT === 'card' ? totalTransact : monthT === 'bio' ? totalTransact : 0} Transactions</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Progress
                                                                    status={monthT === 'card' ? 'card' :
                                                                        monthT === 'bio' ? 'bio' : ''}
                                                                    theme={{ card: { color: '#22409a' }, bio: { color: '#f87f05' } }}
                                                                    percent={monthT === 'card' ? totalPercent : monthT === 'bio' ? totalPercent : 0}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item width={'40%'}>
                        <Paper elevation={2} sx={{ height: '470px', width: '100%' }}>
                            <Grid display={'flex'} container direction={'column'}>
                                <Grid item width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid container pt={2} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item pl={1}>
                                            <Typography style={{ color: '#032541', fontSize: '20px', fontWeight: 'bold' }}>Institutions</Typography>
                                        </Grid>
                                        <Grid item pr={2}>
                                            <FormControl style={{ marginLeft: "10px", width: "180px" }}>
                                                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                                <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={setStartAndEndDates} label="Filter">
                                                    <MenuItem value={'Today'}>Today</MenuItem>
                                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: "#000000", fontSize: '14px', fontWeight: 'bold' }}>Name</TableCell>
                                            <TableCell style={{ color: "#000000", fontSize: '14px', fontWeight: 'bold' }} align={'right'}>Overall</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {topInstutionPageState?.data?.map((row) => (
                                        <TableRow key={row?._id} align={'right'}>
                                            <TableCell style={{ color: "#707070", fontSize: '12px', fontWeight: 'normal' }}>
                                                {row?.businessName}
                                            </TableCell>
                                            <TableCell style={{ color: "#707070", fontSize: '12px', fontWeight: 'normal' }} align={'right'}>{numberFormat(row?.total)}</TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}