import { Box, Typography, Paper, Breadcrumbs, Grid, Radio, FormControlLabel, FormControl, RadioGroup, Button, TextField, Checkbox } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextFieldSmall from "../../School/CustomTextFieldSmall";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import CollectFareAsZed from "./farecollectzed";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Payment Setup</Typography>
]

export default function TransportPaymentSetup(props) {
    const [selectedPayOption, setSelectedpayOption] = useState('');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();
    /// const [isPending , setTransistion] = useTransition()
    const [formData, setFormData] = useState({
        businessName: "",
        businessShortCode: "",
        consumerKey: "",
        consumerSecret: "",
        accountReference: "",
        passKey: "",
        thirdPartyCallback: "https://zed.swerri.io/api/stkCallback",
        accountType: "faresetup"
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const [checked, setChecked] = useState('');
    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleOnChange = (e) => {
        setSelectedpayOption(e.target.value)
    }
    const setdaraja = () => {
        setSelectedpayOption('daraja')
    }
    const declinedaraja = () => {
        setSelectedpayOption('declinedaraja')
    }

    //check if daraja is configured



    // get payment config
    const [paymentConfig, setPaymentConfig] = useState({})

    console.log(paymentConfig , 'paymentConfig')

    function getPaymentConfig() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getfareConfig`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                console.log(data, 'data >>>')
                if (data?.status === 200 || data?.status === 201) {
                    setPaymentConfig(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getPaymentConfig()
    }, [])



    // handle activate daraja
    const handleMpesa = () => {
        HttpComponent({
            method: 'POST',
            url: `/api/activate_daraja_for_business`,
            body: formData,
            token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
            if (data?.status === 201 || data?.status === 200) {
                setSuccessShow({ state: true, message: data?.response?.message })
                setTimeout(()=>{
                        setSelectedpayOption('')
                },[500])
            } else {
                setErrorShow({ state: true, message: data?.response?.message })
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: error.message })
        })
    }
    function updatesetfareConfig() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/setfarecollection`,
                body: {
                    paymentType: selectedPayOption,
                },
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 201 || data?.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        setSelectedpayOption(selectedPayOption === 'sacco' ? 'acceptDaraja' : selectedPayOption === 'owner' ? 'accptDaraja' : selectedPayOption === 'sacco_owner' ? 'zedsetup' :'')
                    }, [500])
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error?.message })
        }
    }

    return (
        <Box component="div" sx={{ display: "flex", width: '100%', flexDirection: 'column' }}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item mt={2} mb={2}>
                <Grid container gap={2} display={'flex'} direction={'column'}>
                    <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ArrowBackIosNewIcon onClick={() => navigate(-1)} style={{ color: "#707070", cursor: 'pointer' }} />
                            <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Payment Setup</Typography>
                        </Grid>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={1} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={{ fontWeight: 600 }}>Payment Configuration Setup</Typography>
            </Grid>
            <Grid item width={'100%'} mt={1} mb={1}>
                <Grid container display={'flex'} width={'100%'} gap={2}>
                    <Grid item width={'48%'} mt={1}>
                        <Grid container display={'flex'} alignItems={'center'} width={'100%'} gap={2}>
                            <FormControl sx={{ width: '100%' }}>
                                <RadioGroup sx={{ width: '100%', gap: 1 }} onChange={handleOnChange} value={selectedPayOption} row aria-labelledby="demo-row-radio-buttons-group-label" name="controlled-radio-buttons-group">
                                    <Grid item width={'100%'} py={2}>
                                        <Paper elevation={1} sx={{
                                            width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center',
                                        }}>
                                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Payment to Sacco</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '12px', }}>Payment details are setup managed by sacco and used by all vehicles</Typography>
                                            </Box>
                                            <FormControlLabel value="sacco" control={<Radio  style={{color:paymentConfig=== 'sacco' ? '#17ae7b':''}} />} />
                                        </Paper>
                                    </Grid>
                                    <Grid item width={'100%'} py={2}>
                                        <Paper elevation={1} sx={{ width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center', backgroundColor: selectedPayOption === 'cash' ? 'rgba(23, 174, 123, 0.05)' : '#fff', border: selectedPayOption === 'cash' ? '1px solid #17ae7b' : '' }}>
                                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Payment to Owner</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '12px' }}>Payment details will be set up by each vehicle owners and apply to their vehicles only</Typography>
                                            </Box>
                                            <FormControlLabel value="owner" control={<Radio />} />
                                        </Paper>
                                    </Grid>
                                    <Grid item width={'100%'} py={2}>
                                        <Paper elevation={1} sx={{ width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center', backgroundColor: selectedPayOption === 'cash' ? 'rgba(23, 174, 123, 0.05)' : '#fff', border: selectedPayOption === 'cash' ? '1px solid #17ae7b' : '' }}>
                                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Payment Collection By Zed</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '12px' }}>Payments are collected by Zed and later disbursed to the sacco or vehicle owners.</Typography>
                                            </Box>
                                            <FormControlLabel value="sacco_owner" control={<Radio />} />
                                        </Paper>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item width={'48%'} mt={1}>
                        <Grid container display={'flex'} direction={'column'} alignItems={'center'} width={'100%'} gap={2}>
                            {selectedPayOption === 'acceptDaraja' ?
                                <Grid item width={'100%'} py={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="p">Do you have Mpesa daraja setup </Typography>
                                    <Typography variant="p">for your business ?</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 2 }}>
                                        <Button onClick={() => declinedaraja()} style={{ width: '143px', color: '#032541', textTransform: 'inherit', border: '1px solid #032541', background: '#fff', height: '50px' }}>No</Button>
                                        <Button onClick={() => setdaraja()} style={{ width: '143px', color: '#fff', textTransform: 'inherit', background: '#032541', height: '50px' }}>Yes</Button>
                                    </Box>
                                </Grid> : selectedPayOption === 'daraja' ?
                                    <Grid item width={'100%'} display={'flex'} flexDirection={'column'}>
                                        <Box component="div" sx={{ display: "flex" }}>
                                            <Box component="div" sx={{ marginX: 0.5 }}>
                                                <CustomInputLabel required={true} label={"Business Name"} />
                                                <CustomTextFieldSmall value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
                                            </Box>

                                            <Box component="div" sx={{ marginLeft: 2 }}>
                                                <CustomInputLabel required={true} label={"Business Shortcode"} />
                                                <CustomTextFieldSmall value={formData.businessShortCode} onChange={handleInputChange} name={"businessShortCode"} placeholder={"Short Code"} />
                                            </Box>
                                        </Box>
                                        <Box component="div" sx={{ marginX: 1 }}>
                                            <CustomInputLabel required={true} label={"Consumer Key"} />
                                            <TextField type="password" name={"consumerKey"} value={formData.consumerKey} onChange={handleInputChange} placeholder={"Consumer Key"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} InputProps={{ sx: { width: '31.063rem', height: '3.438em', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1" } } }}
                                            />
                                        </Box>
                                        <Box component="div" sx={{ display: "flex" }}>
                                            <Box component="div" sx={{ marginX: 0.5 }}>
                                                <CustomInputLabel required={true} label={"Consumer Secret"} />
                                                <CustomTextFieldSmall value={formData.consumerSecret} onChange={handleInputChange} name={"consumerSecret"} placeholder={"Consumer Secret"} />
                                            </Box>
                                            <Box component="div" sx={{ marginLeft: 2 }}>
                                                <CustomInputLabel required={true} label={"Account Reference"} />
                                                <CustomTextFieldSmall value={formData.accountReference} onChange={handleInputChange} name={"accountReference"} placeholder={"Account Reference"} />
                                            </Box>
                                        </Box>
                                        <Box component="div" sx={{ marginX: 1 }}>
                                            <CustomInputLabel required={true} label={"Pass Key"} />
                                            <TextField name={"passKey"} value={formData.passKey} onChange={handleInputChange} placeholder={"Pass Key"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }}
                                                InputProps={{ sx: { width: '31.063rem', height: '3.438em', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1" } } }}
                                            />
                                        </Box>
                                        <Box component="div" sx={{}}>
                                            <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                                            <span>By ticking, you are agreeing to the terms and conditions.</span>
                                        </Box>
                                        <Box component="div" sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1, width: '86%' }}>
                                            <Button onClick={() => declinedaraja()} style={{ width: '143px', color: '#032541', textTransform: 'inherit', border: '1px solid #032541', background: '#fff', height: '50px' }}>Cancel</Button>
                                            <Button disabled={!checked} onClick={() => handleMpesa()} style={{ width: '143px', color: '#fff', textTransform: 'inherit', background: '#032541', height: '50px' }}>Set</Button>
                                        </Box>
                                    </Grid> : selectedPayOption === 'declinedaraja' ?
                                        <Grid item width={'100%'} py={2} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                            <Typography variant="p">For assistance Contact Our </Typography>
                                            <Typography variant="p">support team </Typography>
                                            <Typography variant="p">Email : info@zed.business</Typography>
                                            <Typography variant="p">Phone : 079836100</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 2 }}>
                                                <Button onClick={() => setSelectedpayOption('acceptDaraja')} style={{ width: '143px', color: '#032541', textTransform: 'inherit', border: '1px solid #032541', background: '#fff', height: '50px' }}>No</Button>
                                                <Button onClick={() => setSelectedpayOption('')} style={{ width: '143px', color: '#fff', textTransform: 'inherit', background: '#032541', height: '50px' }}>Yes</Button>
                                            </Box>
                                        </Grid>
                                        : selectedPayOption === 'sacco' ?
                                            <Grid item width={'100%'} mt={10} py={2} display={'flex'} direction={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                                                <Typography variant="p"> Would you like to authorize the SACCO to set up and process payments, which will be managed by the SACCO and utilized by all vehicles? </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 4 }}>
                                                    <Button onClick={() => setSelectedpayOption('')} style={{ width: '143px', color: '#032541', textTransform: 'inherit', border: '1px solid #032541', background: '#fff', height: '50px' }}>No</Button>
                                                    <Button onClick={() => updatesetfareConfig()} style={{ width: '143px', color: '#fff', textTransform: 'inherit', background: '#032541', height: '50px' }}>Yes</Button>
                                                </Box>
                                            </Grid>
                                            :selectedPayOption === 'owner' ?
                                            <Grid item width={'100%'} mt={10} py={2} display={'flex'} direction={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                                            <Typography variant="p"> Would you like to authorize payment details to be set up by each vehicle owner and apply only to their vehicles?</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 4 }}>
                                                <Button onClick={() => setSelectedpayOption('')} style={{ width: '143px', color: '#032541', textTransform: 'inherit', border: '1px solid #032541', background: '#fff', height: '50px' }}>No</Button>
                                                <Button onClick={() => updatesetfareConfig()} style={{ width: '143px', color: '#fff', textTransform: 'inherit', background: '#032541', height: '50px' }}>Yes</Button>
                                            </Box>
                                        </Grid>
                                            : selectedPayOption === 'sacco_owner' ?
                                            <Grid item width={'100%'} mt={10} py={2} display={'flex'} direction={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                                                <Typography variant="p"> Would you like to authorize  Zed to collect payments and later disburse to the sacco or vehicle owners </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 4 }}>
                                                    <Button onClick={() => setSelectedpayOption('')} style={{ width: '143px', color: '#032541', textTransform: 'inherit', border: '1px solid #032541', background: '#fff', height: '50px' }}>No</Button>
                                                    <Button onClick={() => updatesetfareConfig()} style={{ width: '143px', color: '#fff', textTransform: 'inherit', background: '#032541', height: '50px' }}>Yes</Button>
                                                </Box>
                                            </Grid>:selectedPayOption === 'zedsetup' ? <CollectFareAsZed/>:
                                            null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}