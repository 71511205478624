import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import gift_voucher from "../../common/images/gift-voucher.svg";
import download from '../../common/images/download.svg';


import { useNavigate } from "react-router-dom";


import { format } from 'date-fns'
import moment from "moment-timezone";
const baseUrl = process.env.REACT_APP_BASE_URL;
let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency}).format(value);


const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

const VoucherGiftPreview = () => {
    const [voucherData, setVoucherData] = useState({
        voucherNumber: "",
        amount: "",
        expiryDate: "",
        voucherId: "",
        startDate: "",
        qrCode: ""
    })
    const classes = useStyles();
    const navigate = useNavigate();
    const date = new Date();
    const formattedDate = format(date, "EEEE, MMMM dd, yyyy");

    const [businessInfo, setBusinessInfo] = useState()





    const downloadPDF = () => {
        const input = document.getElementById('downloadable');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 1.0);
                const pdf = new jsPDF("p", "mm", [210, 297]); // A4 paper size in mm
                pdf.addImage(imgData, 'JPEG', 5, 5, 200, 287);
                pdf.save(`voucher.pdf`);
            })
    }

    const back = () => {
        navigate(-1)
    }

  
    const getVoucherDetails = async () => {
        // get the voucher number from url 
        const url = window.location.href;
        const parts = url.split("/");
        const id = parts[parts.length - 1];
        const voucherNumber = id




        try {
            const res = await fetch(
                `${baseUrl}/api/get_qrcode?voucherNumber=${voucherNumber}`, {
                method: "GET",
                headers: {
                    Content_Type: "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },

            }

            )
            const data = await res.json();
            if (res.status === 200) {
                console.log('-------------------', data.data);
                setVoucherData({ ...voucherData, voucherNumber: data.data.voucherNumber, amount: data.data.amount, expiryDate: data.data.expiryDate, voucherId: data.data.voucherId, startDate: data.data.startDate, qrCode: data.data.qrCode })

            }

        } catch (error) {


        }
    }

    

    const getBusinessDetails = async () => {
        try {
            const businessDetailsResponse = await fetch(baseUrl+"/api/get_business_info",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );


            if (businessDetailsResponse.status === 200) {
                const businessDetailsData = await businessDetailsResponse.json();
                console.log("business data ", businessDetailsData.data)
                setBusinessInfo(businessDetailsData.data);
            }else{
                setErrorFetching(true)
            }
        } catch (error) {
            console.error("error fetching customer details",error.message);
        }
    };
    useState(() => {
        getVoucherDetails()
        getBusinessDetails()

    }, [])


    // function numberToWords(num) {
    //     const ones = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    //     const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    //     const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    //     const scales = ['', 'thousand', 'million', 'billion', 'trillion'];

    //     if (num === 0) {
    //       return ones[num];
    //     }

    //     let numStr = num.toString();
    //     let numArr = numStr.padStart(Math.ceil(numStr.length / 3) * 3, '0').match(/.{1,3}/g).map(str => parseInt(str));
    //     let wordsArr = [];

    //     for (let i = numArr.length - 1; i >= 0; i--) {
    //       let scale = scales[i];

    //       if (numArr[i] !== 0) {
    //         let hundred = ones[Math.floor(numArr[i] / 100)];

    //         if (hundred) {
    //           wordsArr.push(`${hundred} hundred`);
    //         }

    //         let ten = numArr[i] % 100;

    //         if (ten < 10) {
    //           wordsArr.push(ones[ten]);
    //         } else if (ten >= 10 && ten < 20) {
    //           wordsArr.push(teens[ten - 10]);
    //         } else {
    //           wordsArr.push(`${tens[Math.floor(ten / 10)]} ${ones[ten % 10]}`);
    //         }

    //         if (scale) {
    //           wordsArr.push(scale);
    //         }
    //       }
    //     }

    //     return wordsArr.reverse().join(' ');
    //   }

    //   console.log(numberToWords(5000)); // "five thousand"
    //    // "five thousand"
    //    function numberToWords(num) {
    //     const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    //     const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    //     const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    //     const scales = ['', 'thousand', 'million', 'billion', 'trillion'];

    //     if (num === 0) {
    //       return 'zero';
    //     }

    //     let numStr = num.toString();
    //     let numArr = numStr.padStart(Math.ceil(numStr.length / 3) * 3, '0').match(/.{1,3}/g).map(str => parseInt(str));
    //     let wordsArr = [];

    //     for (let i = numArr.length - 1; i >= 0; i--) {
    //       let scale = scales[i];

    //       if (numArr[i] !== 0) {
    //         let hundred = ones[Math.floor(numArr[i] / 100)];

    //         if (hundred) {
    //           wordsArr.push(`${hundred} hundred`);
    //         }

    //         let ten = numArr[i] % 100;

    //         if (ten < 10) {
    //           wordsArr.push(ones[ten]);
    //         } else if (ten >= 10 && ten < 20) {
    //           wordsArr.push(teens[ten - 10]);
    //         } else {
    //           wordsArr.push(`${tens[Math.floor(ten / 10)]} ${ones[ten % 10]}`);
    //         }

    //         if (scale) {
    //           wordsArr.push(scale);
    //         }
    //       }
    //     }

    //     return wordsArr.reverse().join(' ');
    //   }

    //   console.log(numberToWords(111)); // "five thousand"


    console.log("voucherNumber", voucherData.voucherNumber);

    const image_url = voucherData.qrCode.split(',')[1];
    console.log("image_url", image_url);

    const amt = voucherData.amount


    const username = localStorage.getItem('username')
    const branch = localStorage.getItem('branch')











    return (
        <>
            <div style={{ width: "80%", marginLeft: "10%" }}>
                <div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", margin:"0px" }}>
                            <h3>Gift Vouchers</h3>
                            <h4><img src={download} alt="" style={{height:"30px", width:"30px", cursor:"pointer"}} onClick={()=> {
                                downloadPDF()
                            }}/></h4>
                        </div>
                      <div className="d-flex justify-content-center">
                      <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                justifyContent: "center",
                                overflow: "visible",
                                height: "auto",
                                width: '660px',
                                height: '866px',
                                border: "2px solid #BDB4B9",
                                padding: ' 28px 30.3px 63px 34.3px',
                                backgroundColor: ' #fff',

                            }}
                            id="downloadable"
                        >
                            <div className="business-details">
                                <div className="header-text" style={{
                                    margin: '0 179.7px 1px 175.7px',
                                    fontFamily: ' Poppins',
                                    fontSize: ' 20px',
                                    fontWeight: 'bold',
                                    fontStretch: ' normal',
                                    fontStyle: 'normal',
                                    lineHeight: ' 0.72',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#040505',
                                }}>
                                    {businessInfo?.businessName} </div>
                                <h4 style={{


                                    fontSize: ' 16px',
                                    fontWeight: ' normal',
                                    fontStretch: 'normal',
                                    fontStyle: ' normal',
                                    lineHeight: '1.38',
                                    letterSpacing: ' normal',
                                    textAlign: 'center',
                                    color: '#040505',
                                }}>{businessInfo?.businessCategory} </h4>
                                <h4 style={{

                                    fontSize: ' 16px',
                                    fontWeight: ' normal',
                                    fontStretch: 'normal',
                                    fontStyle: ' normal',
                                    lineHeight: '1.38',
                                    letterSpacing: ' normal',
                                    textAlign: 'center',
                                    color: '#040505',
                                }}> Tel:{businessInfo?.businessOwnerPhone}</h4>
                                <h4 style={{

                                    fontSize: ' 16px',

                                    fontStyle: ' normal',
                                    lineHeight: '1.38',

                                    textAlign: 'center',
                                    color: '#040505',
                                }}> {businessInfo?.businessOwnerEmail}</h4>
                            </div>
                            <div className="topbar-voucher" style={{
                               
                                height: '140.4px',
                                margin: '20px 0.2px 18.6px 1.2px',
                                // padding: '31.5px 185.7px 32.9px 186.5px',
                                opacity: '0.9',
                                border: 'dotted 2px #cb444a',
                                display:"flex",
                                justifyContent:"center",
                                flexDirection:"column"

                            }} >
                                <img src={gift_voucher} alt=""  style={{height:"40px"}}/>
                                <h4 className="mt-2 text-center" style={{ width: '594.1px'}}>
                                    <strong>NO.</strong>
                                    {voucherData.voucherNumber}
                                </h4>

                            </div>

                            <div className="to-data" style={{
                                width: '594.1px',
                                height: '60.4px',

                                opacity: '0.9',
                                border: 'dotted 2px #cb444a',
                            }}>
                                <h6 className="mt-2 ml-3">TO</h6>
                            </div>
                            <div className="from-data mt-4" style={{
                                width: '594.1px',
                                height: '60.4px',

                                opacity: '0.9',
                                border: 'dotted 2px #cb444a',
                            }}>
                                <h6 className="mt-2 ml-3">From</h6>
                            </div>

                            <div className="money-part mt-4" style={{
                                width: '594.1px',
                                height: '108.4px',

                                opacity: '0.9',
                                border: 'dotted 2px #cb444a',
                            }}>
                                <div className="mt-2 ml-3">
                                    <span> <strong>KSH</strong> </span>

                                    {/* const formattedNumber = number.toFixed(2);  */}
                                    <h1>{amt}*****</h1>
                                </div>
                            </div>
                            <div className="mt-2">
                                <span style={{ fontSize: "12px", color: " #040505" }}>*Valid from <strong>  {moment(voucherData.startDate).format("DD/MM/YYYY")}</strong> to  <strong>  {moment(voucherData.expiryDate).format("DD/MM/YYYY")}</strong></span>
                            </div>

                            <div className="mt-3" style={{ borderBottom: "2px dotted  #bfbfbf" }}></div>
                            <div className="d-flex mt-3" style={{ display: "flex", justifyContent: "space-between" }} >
                                <div className="container-left" style={{

                                    fontSize: "9px",
                                    fontWeight: ' normal',
                                    fontStretch: ' normal',
                                    fontStyle: 'normal',
                                    lineHeight: '1.07',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#040505',
                                }}>
                                    {/* <h6>{businessInfo?.businessOwnerName}</h6> */}

                                    <h6>AUTHORISING SIGNATURE</h6>
                                    <h6>Branch: </h6>
                                    <h6>{branch == '' ? 'NO branch selected' : businessInfo?.branchName}</h6>
                                </div>
                                <div className="container-right ">
                                    <img src={`data:image/png;base64,${image_url}`} alt="base64 image" />
                                </div>
                            </div>
                            <div className="mt-3" style={{ borderBottom: "2px dotted  #bfbfbf" }}></div>
                            <p style={{ color: "#dc3545", fontSize: "15px" }}>Redeemable at all {businessInfo?.businessName} Branches Countrywide</p>

                            {/* 

 */}


                            <div
                                className={classes.p2}
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div
                                    className={classes.textCenter}
                                    style={{ fontSize: "10px", fontWeight: "300" }}
                                >
                                    This is a system-generated voucher is created without any
                                    alteration whatsoever.
                                    <br />
                                    Thank you for your business.
                                </div>
                            </div>
                            <div
                                className={classes.p2}
                                style={{
                                    backgroundColor: "#f7f7f7",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span className={classes.footer_text}>
                                    Powered by <span style={{
                                        fontWeight: "700"
                                    }}>ZED Payments Limited</span>
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>
                                    info@zed.business
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>v1.0.2</span>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            {/*Received payment*/}
        </>
    );
};

export default VoucherGiftPreview;