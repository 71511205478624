import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import {
  handleDeleted,
  handleEdited,
  handleAdded,
} from "../../../features/AlertSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import { removeClicked } from "../../../features/actionSlice";
import axios from "axios";
import BusinessCategoriesTable from "../components/BusinessCategories/BusinessCategoriesTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setSearch } from "../../../features/searchSlice";
import Papa from "papaparse";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const baseUrl = process.env.REACT_APP_BASE_URL;

function BusinessCategories() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const editedAlertState = useSelector((state) => state.alertState.edited);
  const deletedAlertState = useSelector((state) => state.alertState.deleted);
  const addedAlertState = useSelector((state) => state.alertState.added);
  const [showEditedAlert, setShowEditedAlert] = useState(false);
  const [showDeletedAlert, setShowDeletedAlert] = useState(false);
  const [showAddedAlert, setShowAddedAlert] = useState(false);

  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleOpenDialog2 = () => {
    setDeleteOpen(true);
  };

  const handleClose2 = () => {
    setDeleteOpen(false);
  };

  const handleShowAlert = () => {
    if (editedAlertState === true) {
      setShowEditedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowEditedAlert(false);
        dispatch(handleEdited(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [editedAlertState]);

  const handleShowAddedAlert = () => {
    if (addedAlertState === true) {
      setShowAddedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAddedAlert(false);
        dispatch(handleAdded(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAddedAlert();
  }, [addedAlertState]);

  const handleShowDeletedAlert = () => {
    if (deletedAlertState === true) {
      setShowDeletedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowDeletedAlert(false);
        dispatch(handleDeleted(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeletedAlert();
  }, [deletedAlertState]);

  useEffect(() => {
    if (clickedItem === "Delete") {
      handleOpenDialog2();
    } else if (clickedItem === "Edit") {
      navigate("/EditBusinessCategory");
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  const code = useSelector((state) => state.categoryDetails.code);
  const name = useSelector((state) => state.categoryDetails.name);

  const handleDelete = () => {
    try {
      axios
        .post(
          baseUrl + `/api/deleteCategory`,
          {
            code,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          if (response) {
            console.log("deleted successfully");
            dispatch(handleDeleted(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.bizCategories);

  const handleExport = () => {
    exportArrayToCSV(exportData, "Business Categories.csv");
  };

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Business Categories
          </Typography>
        </Stack>
        {showEditedAlert && (
          <Alert variant="filled" severity="success">
            Category details Edited successfully...
          </Alert>
        )}
        {showDeletedAlert && (
          <Alert variant="filled" severity="success">
            Category Deleted successfully
          </Alert>
        )}
        {showAddedAlert && (
          <Alert variant="filled" severity="success">
            Category Added successfully...
          </Alert>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Business Categories" />
        <Button
          sx={{
            borderRadius: 2,
            backgroundColor: "#032541",
            color: "white",
            textTransform: "none",
            "&:hover": { color: "white", backgroundColor: "#032541" },
          }}
          onClick={() => navigate("/AddBusinessCategory")}
        >
          Add Category
        </Button>
      </Stack>
      <Box width={"100%"}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <TextField
            id="input-with-icon-textfield"
            label="Search by category name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearch}
            className={classes.focusedTextField}
          />
          <Button
            variant="outlined"
            sx={{
              bgcolor: "#f5f6f7",
              borderColor: "#f5f6f7",
              color: "#032541",
              ":hover": {
                borderColor: "#032541",
              },
              textTransform: "none",
            }}
            onClick={handleExport}
          >
            Export
            <MoreVertIcon
              sx={{
                color: "#032541",
              }}
            />
          </Button>
        </Stack>
        <Stack>
          <BusinessCategoriesTable />
        </Stack>
        <Dialog
          open={deleteOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose2}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
          <DialogContent>
            <Stack direction="row">
              <Stack
                width={"30%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <ErrorIcon
                  sx={{
                    fontSize: "100px",
                    color: "#dc3545",
                  }}
                />
              </Stack>
              <Stack width={"70%"}>
                <Typography
                  fontFamily="Poppins"
                  fontSize={16}
                  fontWeight="600"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  Delete Category
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  Selected Category will be deleted permanently...this action
                  cannot be undone
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#032541"
                >
                  <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                  {`${code} - ${name}`}
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose2}
              variant="outlined"
              sx={{
                borderRadius: 4,
                backgroundColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#032541",
                color: "#032541",
                ":hover": {
                  backgroundColor: "#032541",
                  color: "white",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                console.log("delete clicked");
                handleClose2();
                handleDelete();
              }}
              variant="outlined"
              sx={{
                borderRadius: 4,
                backgroundColor: "#dc3545",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#dc3545",
                color: "white",
                ":hover": {
                  backgroundColor: "#032541",
                  color: "white",
                },
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Stack>
  );
}

export default BusinessCategories;
