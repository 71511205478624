import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import startOrderFunction from "./startOrderEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { universalStyles } from "../startorderuniversalstyles/styles";
import {
  RequestToVoidTransactions,
  SuccessAlertRightAligned,
} from "../startorderModals/modals";

const VoidPayment = () => {
  // initialize start order functions!
  const startOrderEndpoints = new startOrderFunction();

  const navigate = useNavigate();
  //   get Id from query Parameter;
  const { id } = useParams();

  // SET ORDERED ITEMS;
  const [order, setOrder] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    amount: 0,
    transdate: "",
    cashier: "",
    subtotal: 0,
    vat: 0,
    discAmount: 0,
    deficit: 0,
    status: "",
    customerType: "",
    transactionId: "",
    paymentMethod: "",
  });

  useEffect(() => {
    if(id?.length === 24)
    startOrderEndpoints.getDetailedBill(id).then((data) => {
      setOrder(data.response.order.items);
      const {
        cashier,
        transamount,
        subTotal,
        vat,
        createdAt,
        discountAmount,
        status,
        customerType,
        paymentMethod,
        transactionId,
      } = data.response.order;

      const { deficit, billAmount
      } = data.response.data;
      const newDate = new Date(createdAt).toLocaleString();

      setOrderDetails({
        ...orderDetails,
        amount: transamount,
        transdate: newDate,
        cashier: cashier,
        subtotal: subTotal.toFixed(2),
        vat: vat.toFixed(2),
        discAmount: discountAmount,
        deficit: deficit,
        status: status,
        customerType: customerType,
        transactionId: transactionId,
        paymentMethod: paymentMethod,
        billAmount:billAmount
      });
    });
  }, [id]);


  useEffect(() => {
    if(id?.length !== 24);
    startOrderEndpoints.getBillByTransactionID(id).then((data) => {
      if(data?.status === 200){
        console.log('data?.response?.data', data?.response?.data);
        const { items, transamount, discountAmount, voidStatus , subTotal,  vat, cashier,  transactionType,  transactionCreated, transactionID } = data?.response?.data

        const newDate = new Date(transactionCreated).toLocaleString();

        setOrder(items);
        setOrderDetails({
          ...orderDetails,
          amount: transamount,
          transdate: newDate ,
          cashier: cashier,
          subtotal: subTotal.toFixed(2),
          vat: vat.toFixed(2),
          discAmount: discountAmount,
          // deficit: deficit,
          status: voidStatus,
          customerType: transactionType,
          // transactionStatus: transactionStatus,
          paymentMethod: transactionType,
          transactionId: transactionID,
          billAmount: transamount
        });
      }
    })
  },[id])

  const [reasonToVoid, setReasonToVoid] = useState("");

  // open void modal;
  const [openVoidModal, setOpenVoidModal] = useState(false);

  // open sucess modal ;
  const [success, setSuccess] = useState({ state: false, message: "" });

  // void payment Request!
  const sendVoidPaymentRequest = () => {
    startOrderEndpoints.requestToVoidBill(orderDetails?.transactionId, reasonToVoid).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccess({ state: true, message:'Void transaction request sent successfully!' });
        setOpenVoidModal(false)
        setTimeout(() => {
          setSuccess({ state: false });
          navigate(`/detailedbill/${id}`);
        }, 3000);
      }
    });
  };

  return (
    <div style={{ width: "50%", fontFamily: "Poppins" }}>
      <Grid
        container
        direction={"row"}
        margin={"0px 0px 5% 0px"}
        alignItems={"center"}
      >
        <Grid item>
          <ArrowBackIos
            style={universalStyles?.voidRequestText}
            onClick={() => navigate(-1)}
          />
        </Grid>

        <Grid item>
          <span style={universalStyles?.voidRequestText}>
            Void Transaction Request
          </span>
        </Grid>
      </Grid>

      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0px 0px 5% 0px"}
      >
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}>Transaction Date</p>
          <p style={{ color:"#707070",}}>{orderDetails?.transdate}</p>
        </Grid>
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}>Payment Mode</p>
          <p style={{ color:"#707070",}}>{orderDetails?.paymentMethod}</p>
        </Grid>
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}>Transaction ID</p>
          <p style={{ color:"#707070",}}>{orderDetails?.transactionId}</p>
        </Grid>
      </Grid>

      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0px 0px 5% 0px"}
      >
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}>Items</p>
        </Grid>
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}> {order?.length}</p>
        </Grid>

      </Grid>

      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        margin={"0px 0px 5% 0px"}
      >
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}>Total</p>
        </Grid>
        <Grid item>
          <p style={{ margin: "0px", fontWeight: "bold", color:'#032541' }}> {orderDetails?.billAmount} </p>
        </Grid>

      </Grid>

      <Grid container margin={"0px 0px 5% 0px"}>
        <TableContainer>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Product Name
                </TableCell>
                {/* <TableCell>Category</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>
                  Amount(KES)
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total(KES)</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {order?.map((item, index) => {
                const {
                  itemName,
                  itemCategory,
                  itemAmount,
                  itemCount,
                  productId,
                  reciptNumber,
                  status,
                  totalAmount,
                } = item;

                return (
                  <TableRow key={index}>
                    <TableCell>{itemName}</TableCell>
                    {/* <TableCell>{product.productCategory}</TableCell> */}
                    <TableCell> {itemAmount} </TableCell>
                    <TableCell>{itemCount}</TableCell>
                    <TableCell>{totalAmount}</TableCell>
                    <TableCell>
                      {/* {" "}
                                {paymentStatus === "unpaid" && (
                                  <span onClick={() => toBeRemovedItem(item)}>
                                    <img style={{ cursor: "pointer" }} src={removeitemdetailedbill} />
                                  </span>
                                )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container margin={"0px 0px 5% 0px"} width={"100%"}>
        <Grid item width={"100%"}>
          <textarea
            onChange={(e) => setReasonToVoid(e.target.value)}
            style={universalStyles?.voidPaymentTextArea}
            rows={4}
            cols={50}
            placeholder="Reason to void"
          />
        </Grid>
      </Grid>

     <Grid container alignItems={"center"}  width={'100%'}>
 
        <Grid item  width={'100%'} style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'20px'}} >

        <Button
            style={
              reasonToVoid
                ? universalStyles?.cancelVoidPaymentActiveButton
                : universalStyles?.cancelVoidPaymentInactiveBtn
            }
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            style={
              reasonToVoid
                ? universalStyles?.requestToVoidActiveButton
                : universalStyles?.requestToVoidInActiveButton
            }
            onClick={() => (reasonToVoid ? setOpenVoidModal(true) : null)}
          >
            Request To Void
          </Button>
        </Grid>
      </Grid>

      <RequestToVoidTransactions
        openVoidModal={openVoidModal}
        setOpenVoidModal={setOpenVoidModal}
        detailedBillId={id}
        sendVoidPaymentRequest={sendVoidPaymentRequest}
        billAmount={orderDetails?.billAmount}
        paymentMethod={orderDetails?.paymentMethod}
      />
      <SuccessAlertRightAligned
        message={success?.message}
        sucess={success?.state}
      />
    </div>
  );
};

export default VoidPayment;
