import React, { useEffect, useState } from "react";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { Box, Grid, Typography } from "@mui/material";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import HttpComponent from "../../../School/MakeRequest";
import { currencyconverter } from "../../../../common/currencyconverter";
import DateFormatter from "../../../../utils/dateFormatter";
import ExportSearchBar from "../../../../common/exportsearchbar";

const Revenuesharereport = () => {
  // get fee configs;
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const [csvExport, setcsvExport] = useState({ headers: [], data: [] });
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [startTransactionTime, setStartTransactionTime] = useState("");
  const [endTransactionTime, setEndTransactionTime] = useState("");
  const [activeTab, setActiveTab] = useState(`Merchant Settlement Report for period ${startTransactionTime} and ${endTransactionTime} printed on ${new Date().toDateString()}`);

  const [searchValue, setSearchValue] = useState("");
  const [startDate, newStartDate] = useState("");
  const [endDate, newEndDate] = useState("");



  const revenuesharecolumn = [
    { field: "transactionReference", headerName: "TXN Ref", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "merchantfee", headerName: "TXN Fees", flex: 1 },
    { field: "totalAmount", headerName: "Total Amount", flex: 1 },
    { field: "transferfee", headerName: "Transfer Fee", flex: 1 },
    { field: "zedshare", headerName: "Zed Share", flex: 1 },
    { field: "partnershare", headerName: "Partner Share", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
  ];

  const revenueshareReport = pageState?.data?.map((trans, index) => ({
    id: index,
    transactionReference: trans?.transactionReference,
    amount: currencyconverter(trans?.amount) ,
    merchantfee:currencyconverter(trans?.merchantfee)  ,
    totalAmount: currencyconverter(trans?.totalAmount) ,
    transferfee: currencyconverter(0) ,
    zedshare: currencyconverter(trans?.zedshare) ,
    partnershare: currencyconverter(trans?.partnershare) ,
    createdAt:DateFormatter(trans?.createdAt) 
  }));

  const getTransactions = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/listsettledtransactions?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      let transactions = data?.response?.data ?? [];
      let count = data?.response?.count;
      setPageState({ ...pageState, total: count, data: transactions, isLoading: false });
    });
  };

  useEffect(() => {getTransactions()}, [pageState.page, dataGridPageSize, searchValue, startDate, endDate]);

  useEffect(() => {
    const csvColumns = [
      { label: "Txn Ref", key: "transactionReference" },
      { label: "Amount", key: "amount" },
      { label: "Merchant Fee", key: "merchantfee" },
      { label: "Total Amount", key: "totalAmount" },
      { label: "Transfer Fee", key: "transferfee" },
      { label: "Zed Share", key: "zedshare" },
      { label: "Partner Share", key: "partnershare" },
      { label: "Date", key: "createdAt" },
    ];

    const pdfData = pageState?.data?.map((transaction) => {
      return {
        transactionReference: transaction.transactionReference,
        amount: transaction.amount,
        merchantfee: transaction.merchantfee,
        totalAmount: transaction.totalAmount,
        transferfee: transaction.transferfee,
        zedshare: transaction.zedshare,
        partnershare: transaction.partnershare,
        createdAt: transaction.createdAt,
      };
    });

    const headers = [["Txn Ref", "Amount", "Merchant Fee", "Total Amount", "Transfer Fee", "Zed Share",  "Partner Share", "Date" ]];

    const data = pdfData?.map((data) => [data.transactionReference, data.amount, data.merchantfee, data.totalAmount, data.transferfee, data.zedshare, data.partnershare, data.createdAt]);

    setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
    setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
    setExportData({ headers: headers, data: data });
    setcsvExport({ headers: csvColumns, data: pdfData });
  }, [pageState.page, dataGridPageSize, searchValue,startDate, endDate]);

  return (
    <div>
      <Grid container direction={"column"} style={{ padding: "30px 0px 0px 20px" }}>
        <Grid item mb={2}>
          <Box style={{ margin: "0px 0px 20px 0px" }}>
            <Typography sx={{ fontWeight: "600", color: "#032541", fontSize: "25px" }} variant="h5">
              Revenue Share Report
            </Typography>
          </Box>

          <Box>
            <Breadcrumb name={"Revenue Share Report"} />
          </Box>
        </Grid>

        <Grid item mb={2}>
          <ExportSearchBar activeTabs={activeTab} csvExport={csvExport} exportData={exportData} textfiledplaceholder={"Search..."} setSearchValue={setSearchValue} newStartDate={newStartDate} newEndDate={newEndDate} />
        </Grid>

        <Grid item >
        <CustomDataGrid customStyles={{ border: "none", height: "700px", width: "100%" }} column={revenuesharecolumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={revenueshareReport} />
        </Grid>
      </Grid>
      
    </div>
  );
};

export default Revenuesharereport;
