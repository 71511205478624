import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import zedImg from "../../../common/images/zedPayitModule.svg";
import warehouseImg from "../../../common/images/warehouseModule.svg";
import inventoryImg from "../../../common/images/inventoryModule.svg";
import accountingImg from "../../../common/images/accountingModule.svg";
import { Stack, Typography, Skeleton } from "@mui/material";

const baseUrl = process.env.REACT_APP_BASE_URL;

function createData(moduleName, noOfBusinesses) {
  return { moduleName, noOfBusinesses };
}

export default function BasicTable() {
  const [zedPocket, setZedPocket] = useState(0);
  const [accounting, setAccounting] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [warehouses, setWarehouses] = useState(0);

  const fetchZedPocket = async () => {
    const response = await axios.get(baseUrl + "/api/countZedPayit", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setZedPocket(response.data.zedPayitCount);
  };

  useEffect(() => {
    fetchZedPocket();
  }, []);

  const fetchAccounting = async () => {
    const response = await axios.get(baseUrl + "/api/countAccounting", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setAccounting(response.data.accountingTotal);
  };

  const fetchInventory = async () => {
    const response = await axios.get(baseUrl + "/api/countInventory", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setInventory(response.data.inventoryTotal);
  };

  const fetchWarehouses = async () => {
    const response = await axios.get(baseUrl + "/api/countWarehouses", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    setWarehouses(response.data.warehouseTotal);
  };

  

  useEffect(() => {
    fetchZedPocket();
    fetchAccounting();
    fetchInventory();
    fetchWarehouses();
  }, []);

  const rows = [
    createData("Zed-Pocket money", zedPocket),
    createData("Accounting", accounting),
    createData("Inventory", inventory),
    createData("Warehouse and Stores", warehouses),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#f0f0f0" }}>
          <TableRow>
            <TableCell>
              <Typography
               fontFamily="Poppins"
               fontSize={14}
               fontWeight="bold"
               fontStyle="normal"
               textAlign="left"
               color="#687c8d"
              >
                Module Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="bold"
                fontStyle="normal"
                textAlign="center"
                color="#687c8d"
              >
                Number of Businesses
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.moduleName}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Stack direction="row" spacing={2}>
                  {row.moduleName === "Zed-Pocket money" ? (
                    <img alt="Remy Sharp" src={zedImg} />
                  ) : null}
                  {row.moduleName === "Warehouse and Stores" ? (
                    <img alt="Remy Sharp" src={warehouseImg} />
                  ) : null}
                  {row.moduleName === "Inventory" ? (
                    <img alt="Remy Sharp" src={inventoryImg} />
                  ) : null}
                  {row.moduleName === "Accounting" ? (
                    <img alt="Remy Sharp" src={accountingImg} />
                  ) : null}
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "right",
                      color: "#707070",
                    }}
                  >
                    {row.moduleName}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  {!row.noOfBusinesses ? (
                    <Skeleton
                    variant="text"
                    fontSize={18}
                    width={"100px"}
                    sx={{ bgcolor: "#f0f0f0" }}
                    animation="wave"
                  />
                  ) : (
                    row.noOfBusinesses
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
