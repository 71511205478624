import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, useMediaQuery} from "@mui/material";
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';

const CustomTable = ({ data, columns, rowsPerPage,loading, count, fontSize, checkboxSelection, disableSelectionOnClick,  onSelectionModelChange}) => {
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(rowsPerPage);
    
    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const getRowId = (row) => {
        return row?._id || row?.id || row?.gridId || Math.floor(Math.random() * 2000000)
    }

    // Define custom CSS classes for the header and data cells
    const headerClass = 'custom-header';
    const cellClass = 'custom-cell';
    // const isSmallScreen = useMediaQuery('(max-width:599px)');
    // const cellStyle = {
    //
    // };

    return (
        <Box component={"div"} style={{display: 'flex', minHeight: "24rem", width:"100%"}}>
            <Box sx={{flexGrow: 1}}>
                <DataGrid
                    // components={{NoRowsOverlay : NoRowsOverlay}}

                    sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: fontSize || '0.875rem', color : '#272d3d',boxShadow: 0, border: 0}}
                    pagination
                    page={page}
                    rowCount={count}
                    onPageChange={handlePageChange}
                    // rows={data || []}
                    // have a condition if data is empty then show no rows overlay else show the data
                    rows={data?.length > 0 ? data ||data : NoRowsOverlay }

                    columns={columns.map((col) => ({
                        ...col,
                        headerClassName: headerClass,
                        cellClassName: cellClass,
                    }))}
                    pageSize={pageSize}
                    getRowId={getRowId}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    autoWidth
                    autoHeight
                    disableColumnMenu
                    loading={loading}
                    checkboxSelection={checkboxSelection}
                    disableSelectionOnClick={disableSelectionOnClick}
                     onSelectionModelChange={onSelectionModelChange}
                />

            </Box>
            <style>

                {`.${headerClass} .MuiDataGrid-colCellTitle {
                    font-size: 1rem;
                    font-weight: 700;
                    text-align: center;
                }
                .${headerClass} {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
                .${cellClass} {
                    font-size: ${fontSize} || 0.875rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .${cellClass} .MuiDataGrid-cell {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: '16px';
                }
                `}
            </style>
        </Box>
    );
};

export default CustomTable;
