import React, { useState, useEffect } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Box,
  TableContainer,
  Input,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { ExpandMore, Delete, KeyboardBackspace } from "@mui/icons-material";
import { universalStyles } from "../../bills/startorderuniversalstyles/styles";
import { creditnotestyles } from "../creditNoteStyling/styless";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import { useNavigate } from "react-router-dom";
import { escape, parseInt, set } from "lodash";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Productscategory = () => {
    
    const navigate = useNavigate();
  // AACORDION DISPLAY;
  const [expanded, setExpanded] = React.useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //   numberFormat
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const [productsList, setProductsList] = useState({
    products: [],
    isLoading: true,
  });
  const [categoriesList, setCategories] = useState([]);
  const [newCategoryName, setItemCategory] = useState("");
  const [categoryName, setQueryCategory] = useState("");

  console.log(newCategoryName , 'newCategoryName')
  console.log(categoryName , 'categoryName')

  const [customerName, setUserName] = useState("")
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCategoryCount, setCategoryCount] = useState(0);
  const noOfPages = Math.ceil(totalCategoryCount / pageSize);
  const handleTableChange = (value) => {
    //setTablePage(value);
    // getItemsPerCategory(newCategoryName);
  };

  //   credit note array;
  const [creditNoteArray, setCreditNoteArray] = useState([]);
 
  //   total credit balance states;
  const [overallTotal, setOverallTotal] = useState(0);
  // get overrall total;
  useEffect(() => {
    setOverallTotal(
      creditNoteArray.length > 0 &&
        creditNoteArray.reduce((total, curr) => {
          return (total += curr.totalAmount);
        }, 0)
    );
  });

  // add products to credit note;
  const addProductsToCreditpage = (product) => {


    const posProduct = {
      itemName: product.productName,
      itemAmount: product.productPrice,
      productId: product.id,
      quantity: 1,
      totalAmount: product.productPrice * 1,
      priceStatus:product.priceStatus
    };
    // console.log(newProdObj);
    const newCreditArray = [...creditNoteArray];
    // check index and add item;
    const currentIndex = newCreditArray.findIndex(
      (posProduct) => posProduct.productId === product.id
    );
    if (currentIndex === -1) {
      newCreditArray.push(posProduct);
    } else {
      newCreditArray.map((item, index) => {
        if (index === currentIndex) {
          // console.log(item);
          item.quantity = parseInt(item.quantity) + 1;
          item.totalAmount = item.quantity * item.itemAmount;
        }
        return item;
      });
    }

    setCreditNoteArray(newCreditArray);
  };

  // remove product from pos Array
  const removeproductFromPosArray = (product) => {
    const newPosRemove = [...creditNoteArray];

    const currentIndex = newPosRemove.indexOf(product);

    if (currentIndex >= 0) {
      newPosRemove.map((item, index) => {
        if (currentIndex === index) {
          newPosRemove.splice(currentIndex, 1);
        }
        return item;
      });
      // newPosRemove.map((item, index) => {
      //   if(currentIndex === index){
      //     if(item.itemCount < 2){
      //       newPosRemove.splice(currentIndex, 1);
      //     }else {
      //       item.itemCount = parseInt(item.itemCount)  - 1
      //      item.totalAmount = item.itemCount * item.itemAmount
      //     }
      //   }
      //   return item
      // })
    }
    setCreditNoteArray(newPosRemove);
  };

  const updateInputTotals = (e) => {

    let updateTotalsArray = [...creditNoteArray];
    updateTotalsArray.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
        prod.quantity = e.target.value;
        prod.totalAmount = e.target.value * prod.itemAmount;
      }
    });

    setCreditNoteArray(updateTotalsArray);
    // updateTotalsArray.map((prod, index) => ({...prod, ...(parseInt(e.target.name) === index ? {itemCount: value, totalAmount: prod.itemAmount * value} :{} )}))
  };

  const updateVariablePriceTotals = (e) => {
    let updateVariableTotals = [...creditNoteArray];

    updateVariableTotals?.map((prod, index) => {
      if (parseInt(e.target.name) === index) {
        prod.itemAmount = e.target.value;
        prod.totalAmount = e.target.value * prod.quantity;
      }
    });

    setCreditNoteArray(updateVariableTotals);
  };

  // get all categories;
  const getAllCategories = async () => {
    try {
      const allCategoriesResp = await fetch(baseUrl + `/api/listByCategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      await allCategoriesResp.json().then((data) => {
        setCategoryCount(data.count);
        const categoryData = data.categories.map((cat) => {
          // getItemsPerCategory(cat.category);
          console.log(cat);

          return {
            category: cat?.categoryId,
            categoryName:cat?.category
          };
        });

        setCategories(categoryData);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let checkReturned = false;
    if (!checkReturned) {
      getAllCategories();
    }

    return () => {
      checkReturned = true;
    };
  }, []);

  // get items per category
  // GET PRODUCTS PER CATEGORIES

  const getItemsPerCategory = async (categoryName) => {
    // console.log(categoryName);
    // if(categoryName)
    const itemPerCategoryResp = await fetch(
      // baseUrl + `/api/searchByCategories?search=Soft Drinks`,

      // baseUrl + `/api/listByProducts?search=${categoryName}&page=1&limit=10`,
      // baseUrl + `/api/listByProducts?search=${escape(categoryName)}`,
      // baseUrl + `/api/listByProducts?search=${escape(categoryName)}&page=${tablePage}&limit=${tablePageSize}`,
      baseUrl +
        `/api/listByProducts?categoryId=${escape(categoryName)}&page=${1}&limit=${5}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      }
    );

     console.log(itemPerCategoryResp , 'runnnnn');
    await itemPerCategoryResp.json().then((data) => {
   
      const productData = data.products?.map((product) => {
        // console.log(product);
        return {
          id: product._id,
          productCategory: product.productCategory,
          productPrice: product.productPrice,
          productName: product.productName,
          outOfStock: product.outOfStock,
          productCode: product.productCode,
          unitOfMeasure: product.unitOfMeasure,
          quantityInStock: product.quantityInStock,
          priceStatus: product.priceStatus,
        };
      });

      setProductsList({
        ...productsList,
        products: productData,
        isLoading: false,
      });
    });
  };

    // query categories;
    const [queryProducts, setQueryproducts] = useState('')
    const queryItemsPerCategory = async () => {

      if (queryProducts.length === 0 ) {
        getItemsPerCategory(escape(newCategoryName));
      }
      if (newCategoryName && queryProducts) 
  
      try {
        const searchedItemsResp = await fetch(baseUrl + `/api/searchProducts?c_search=&p_search=&page=1&limit=10&searchValue=${escape(queryProducts)}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
  
        // console.log(searchedItemsResp);
        const searchedItemData = await searchedItemsResp.json();


        if (queryProducts.length === 0 ) {
          getItemsPerCategory(escape(newCategoryName));
        }else {
          setProductsList({ ...productsList, products: searchedItemData.products });
        }
      
      } catch (error) {
        console.log(error.message);
      }
    };
  
    useEffect(() => {
      let isItemsTrue = false;
  
      if (!isItemsTrue) {
        queryItemsPerCategory();
      }
      isItemsTrue = true;
    }, [ queryProducts]);
  

//   add creditNote;
const [sucess, setSucess] = useState(false);
const [errorShow, setErrorShow] = useState({state: false, message: ""})

const handleNoCustomername = () =>{
  setErrorShow({state: true, message: "Please enter customer name"})
}

const addcreditNote = () => {
    HttpComponent({
        method:"POST",
        url:"/api/create_credit_note",
        body:{productsList:creditNoteArray, customerName:customerName},
        token:localStorage.getItem("X-Authorization")
    }).then((data) => {
        if(data.status === 200){
            setCreditNoteArray([]) 
            setUserName("")
             setSucess(true) 

             
        }
        
        setTimeout(() => {
            setSucess(false)
            navigate("/creditnote?Pending Approval")
        },2000)
    }).catch((error) => {
        console.log(error);
    })
}

  return (
    <div>
        <Grid container style={{padding:"0px 0px 0px 20px"}} alignItems="center">
          <span onClick={() => navigate("/creditnote")} style={creditnotestyles.creditNoteText}><KeyboardBackspace style={{marginRight:"10px", cursor:"pointer"}}/></span>  <span style={creditnotestyles.creditNoteText}>Create Credit Note</span>

        </Grid>
      <Grid container xs={12} sm={12} style={{padding:"0px 0px 0px 20px"}}>
        <Grid style={{ backgroundColor: "#fff" }} item xs={5} sm={5} md={5}>
          {/*Products Accordion*/}

          <Grid container style={{ marginTop: "2%" }} direction="column">
            {/* <ACTIVESTOCK page={page} pageSize={pageSize} /> */}

          <Grid item style={{margin:"0px 0px 10px 0px"}}>
            <input type="text"  placeholder="Search category" style={creditnotestyles.searchCategoryInput} onChange={(e) => setQueryCategory(e.target.value)} />
          </Grid>
            {
              // categoriesList.length > 0 &&

              categoriesList
                ?.filter((category) => {
                  return categoryName.toLocaleLowerCase() === ""
                    ? category
                    : category.category
                        .toLocaleLowerCase()
                        .includes(categoryName.toLocaleLowerCase());
                })
                .slice((page - 1) * pageSize, page * pageSize)
                // .splice((page - 1), page * pageSize)

                .map((category, index) => {
                  return (
                    // <div>
                    <Accordion
                      key={index}
                      onChange={handleChange(category.category)}
                      expanded={expanded === category.category}
                    >
                      <AccordionSummary
                        onClick={() => {
                          getItemsPerCategory(category.category);
                          setItemCategory(category.category);
                        }}
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {/* <span>{category.category.toLocaleUpperCase()}</span> */}
                        <span>{category.categoryName}</span>
                      </AccordionSummary>

                      <AccordionDetails>
                        {productsList.isLoading === true ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </Box>
                          </>
                        ) : (
                          <TableContainer component={Paper}>
                            <p style={{ textAlign: "right" }}>
                              <Input
                                size="sm"
                                variant="plain"
                                //   value={queryProducts}
                                  onChange={(e) => setQueryproducts(e.target.value)}
                                style={{
                                  objectFit: "contain",
                                  borderRadius: "6px",
                                  outline: "none",
                                  border: "solid 1px #032541",
                                  fontSize: "12px",
                                  paddingLeft: "10px",
                                }}
                                placeholder="Search product"
                              />
                            </p>
                            <Table stickyHeader sx={{ width: "100%" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Product Name
                                  </TableCell>
                                  {/* <TableCell>Category</TableCell> */}
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    In Stock
                                  </TableCell>
                                  <TableCell style={{ fontWeight: "bold" }}>
                                    Cost
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {
                                  // productsList?.products.length > 0 &&
                                  productsList.products
                                    // .filter((product) => {
                                    //   return queryProducts === "" ? product : product.productName.toLocaleLowerCase().includes(queryProducts.toLocaleLowerCase());
                                    // })
                                    ?.map((product, index) => {
                                      var outOf = "";
                                      var stockamount = "";
                                      if (product.outOfStock === true) {
                                        var outOf = "out of stock";
                                      } else {
                                        var outOf = "stock available";
                                        var stockamount =
                                          product.quantityInStock;
                                      }

                                      return (
                                        <TableRow
                                          style={{ cursor: "pointer" }}
                                          key={index}
                                          onClick={() =>
                                            addProductsToCreditpage(product)
                                          }
                                        >
                                          <TableCell>
                                            {product.productName}
                                          </TableCell>
                                          {/* <TableCell>{product.productCategory}</TableCell> */}
                                          <TableCell>
                                            {" "}
                                            {outOf === "out of stock"
                                              ? "out of stock"
                                              : stockamount}{" "}
                                          </TableCell>
                                          <TableCell>
                                            {product.productPrice}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })
                                }
                              </TableBody>
                            </Table>

                            <Grid
                              item
                              style={{
                                width: "100%",
                                marginTop: "2%",
                                marginBottom: "2%",
                              }}
                            >
                              <Box
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {/* <Pagination count={5} page={tablePage} defaultPage={1} onChange={(e, value) => handleTableChange(value)} variant="outlined" shape="rounded" /> */}
                                <Pagination
                                  count={0}
                                  page={1}
                                  defaultPage={1}
                                  onChange={handleTableChange}
                                  variant="outlined"
                                  shape="rounded"
                                />
                              </Box>
                            </Grid>
                          </TableContainer>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    // </div>
                  );
                })
            }

            <Grid item style={{ width: "100%", marginTop: "2%" }}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  count={noOfPages}
                  page={page}
                  defaultPage={1}
                  onChange={(e, value) => setPage(value)}
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ backgroundColor: "#f9fafb" }} item sm={5} xs={7} md={7}>
          {/* POS ITEMS */}
          <div
            style={{
              width: "100%",
              padding: "10px",
              // border: "3px solid green",
              height: "70vh",
              // flexDirection: "column-reverse",
            }}
          >
            {creditNoteArray.length > 0 && (
              <TableContainer
                component={Paper}
                style={{
                  flexDirection: "column-reverse",
                  height: "40vh",
                  overflow: "auto",
                  display: "flex",
                }}
              >
                <Table stickyHeader sx={{ width: "100%" }}>
                  <TableHead style={{ fontWeight: "bold", fontSize: "10px" }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Product Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Item Amount(KES)
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }} align="center">
                        Quantity
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {creditNoteArray?.map((item, index) => {
                      const {
                        itemName,
                        checkedProductId,
                        itemAmount,
                        quantity,
                        totalAmount,
                        priceStatus,
                      } = item;

                      if (priceStatus === "Variable Price Item") {
                       
                        return (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            key={index}
                            // onClick={() => addProductsToCreditpage(item)}
                          >
                            <TableCell>{itemName}</TableCell>
                            <TableCell>
                              <input
                                required
                                onChange={updateVariablePriceTotals}
                                value={itemAmount}
                                style={{
                                  width: "20%",
                                  outline: "none",
                                  margin: "0px 10px 0px 0px",
                                }}
                                id={checkedProductId}
                                name={index}
                                type="number"
                              />
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              <input
                                value={quantity}
                                onChange={updateInputTotals}
                                required
                                id={checkedProductId}
                                name={index}
                                style={{
                                  width: "20%",

                                  margin: "0px 10px 0px 0px",
                                }}
                                type="number"
                              />{" "}
                            </TableCell>
                            <TableCell> - {itemAmount * quantity}</TableCell>
                            <TableCell>
                              {" "}
                              <Delete
                                onClick={() => {
                                  removeproductFromPosArray(item);
                                }}
                                style={{ color: "#dc3545", cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            key={index}
                            // onClick={() => addProductsToCreditpage(item)}
                          >
                            <TableCell>{itemName}</TableCell>
                            <TableCell>{itemAmount}</TableCell>
                            <TableCell align="center">
                              {" "}
                              <input
                                type="number"
                                required
                                value={quantity}
                                onChange={updateInputTotals}
                                id={checkedProductId}
                                name={index}
                                style={{
                                  // padding: "7px",
                                  width: "20%",
                                  outline: "none",
                                  margin: "0px 10px 0px 0px",
                                }}
                              />{" "}
                            </TableCell>
                            <TableCell>- {itemAmount * quantity}</TableCell>
                            <TableCell>
                              {" "}
                              <Delete
                                onClick={() => removeproductFromPosArray(item)}
                                style={{ color: "#dc3545", cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {creditNoteArray.length === 0 && (
              <div
                style={{
                  height: "45vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  {/* <img src={emptyState} /> */}
                  <p style={universalStyles.xlParaBold}>No product added!</p>
                </p>
              </div>
            )}

            {/* CONTROL BUTTONS */}
            {/* {posArray?.length > 0 && ( */}
            <Grid
              container
              style={{
                width: "100%",
                margin: "10px 0px 0px 0px",
                paddingLeft: "25px",
                paddingRight: "25px",
                backgroundColor: "#fff",
                fontFamily: "Poppins",
              }}
            >
              <Grid
                item
                style={{
                  width: "100%",
                  margin: "10px 0px 10px 0px",
                  display:"flex",
                  justifyContent:"flex-end"
                }}
              >
                <TextField required label={!customerName ? "Customer Name is required!" : "Customer Name" } sx={ customerName ?  creditnotestyles.addCustomerNameInput : creditnotestyles.addCustomerNameInputError } value={customerName} onChange={(e) => setUserName(e.target.value)} />
              </Grid>

              <Grid
                item
                style={{
                  margin: "0px 0px 10px 0px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span style={{ color: "#144166", fontWeight: "bold" }}>
                  Total {numberFormat(-overallTotal)}
                </span>
              </Grid>

              <Grid
                item
                style={{
                  margin: "0px 0px 10px 0px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span style={{ color: "#144166", fontWeight: "bold" }}>
                  Number of Items {creditNoteArray.length}
                </span>
              </Grid>

              <Grid
                item
                style={{
                  margin: "0px 0px 10px 0px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button style={creditNoteArray.length === 0 ? creditnotestyles.cancelButtonLowOpacity: creditnotestyles.cancelButton} onClick={() =>creditNoteArray.length > 0 ? setCreditNoteArray([]): null } >Remove</Button>
                <Button  style={creditNoteArray.length === 0 ? creditnotestyles.approvalButtonLowOpacity:creditnotestyles.approvalButton} onClick={() => creditNoteArray.length > 0 && customerName  ? addcreditNote() : !customerName  ? handleNoCustomername(): null}>
                  Submit For Approval
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* sucesss modals */}
      {sucess && <SuccessAlertRightAligned message={"Credit Note Successfully added!"} sucess={sucess} /> }
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

    </div>
  );
};

export default Productscategory;
