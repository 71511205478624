import React, { useState, useEffect } from "react";
import { Modal, Button } from "@mui/material";
import { businessConfigstyles } from "./styles";
import warehouseicon from "../../../common/images/warehouse-icn.svg";
import powericon from "../../../common/images/warning.svg";

export const Warehousesetup = (props) => {
  const openWareHouseModal = props.openWareHouseModal;
  const setOpenWareHouseModal = props.setOpenWareHouseModal;
  const activateWarehouse = props.activateWarehouse;
  const getWarehouseStatus = props.getWarehouseStatus;

  const enablewareHouse = () => {

    activateWarehouse()

    setTimeout(() => {
      setOpenWareHouseModal(false);
    }, 3000);
  };
  return (
    <Modal open={openWareHouseModal} style={businessConfigstyles.universalStyle}>
      <div style={businessConfigstyles.wareHouseModal}>
        <div style={businessConfigstyles.wareHouseIconHolder}>
          <div style={businessConfigstyles.divwithImg}>
            <img style={businessConfigstyles.wareHouseImg} src={warehouseicon} alt="warehouse" />
          </div>

          <div>
            <p style={businessConfigstyles.wareHouseText}>Warehouses & Stores</p>
            <p style={businessConfigstyles.wareHousePara}>Set up multiple warehouses, main stores, sub stores and suplliers for easier stock management</p>

            <p>
              <Button style={businessConfigstyles.gotItButton} onClick={enablewareHouse}>
                Got it
              </Button>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const Deactivatewarehouseandstoresmodal = (props) => {
  const opendeactivatemodal = props.opendeactivatemodal;
  const setOpenDeactivateModal = props.setOpenDeactivateModal;
  const deactivateWareHouse = props.deactivateWareHouse;
  return (
    <Modal open={opendeactivatemodal} style={businessConfigstyles.universalStyle}>
      <div style={businessConfigstyles.wareHouseModal}>
        <div style={businessConfigstyles.wareHouseIconHolder}>
          <div style={businessConfigstyles.divwithImg}>
            <img style={businessConfigstyles.wareHouseImg} src={powericon} alt="warehouse" />
          </div>

          <div>
            <p style={businessConfigstyles.wareHouseText}>Deactivate Warehouses & Stores?</p>
            <p style={businessConfigstyles.wareHousePara}>Your warehouse and stores will be deactivated and no longer listed when managing stores</p>
            <p style={businessConfigstyles.wareHousePara}>Your setup will be saved and will be reactivated when turned on</p>

            <p style={businessConfigstyles.paraWithDisplayFlex}>
              <Button onClick={() => setOpenDeactivateModal(false)} style={businessConfigstyles.cancelButton}>
                Cancel
              </Button>

              <Button
                style={businessConfigstyles.deactivateButton}
                onClick={() => {
                  deactivateWareHouse()
                  setOpenDeactivateModal(false);
                }}
              >
                Deactivate
              </Button>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
