import { Grid, Stack,FormControl  , MenuItem, Typography, InputLabel, TextField ,Button ,Select } from "@mui/material"
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";

const EditPartnerBranch = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ branchName: '', branchCode: '' , _id:'' })
    const navigate = useNavigate()
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue , branchID] = state.split("?")

    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }

    // update branch

    console.log(formData , 'formData')

    // get details of the branch to update

    function getBranchDetailsToUpdate(){
        try{
            HttpComponent({
                method: "GET",
                url: `/api/v1/branchtoupdate?regionBranchId=${branchID}`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data)=>{
                if(data?.status === 200){
                    console.log(data, 'done >>>')
                    setFormdata({
                        ...formData,
                        branchName: data?.response?.branchName,
                        branchCode: data?.response?.branchCode,
                        _id: data?.response?._id
                    })
                }else{
                    setErrorShow({ state: true, message: data?.response?.message})
                }
            })

        }catch(error){

        }
    }

    useEffect(() =>{
        getBranchDetailsToUpdate()
    },[branchID])

    function updateBranchDetails() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/v1/partnerUpdatebranch?branchId=${branchID}`,
                body: formData,
                token: localStorage.getItem('X-Authorization'),
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({ state: true, message: "Branch Updated Successfully" })
                    setTimeout(() => {
                        navigate(-1)
                    },1500)
                }else{
                    setErrorShow({ state: true, message: data?.response?.message})
                }
            })
        }catch(error){
            setErrorShow({ state: true, message: error?.message})
        }
    }
    const [allRegions ,setAllRegions] = useState([])

    function getAllRegionsList() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/partnerregions`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setAllRegions( data?.response?.data?.filter(item =>item?.status === 'Active'))
                }
            })
        } catch (error) {
        }
    }

    useEffect(() =>{
        getAllRegionsList()
    },[])
    return (
        <Grid container flex flexDirection={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'} >
                <Stack alignItems='center' direction='row' spacing={1}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Edit Branch</Typography>
                </Stack>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumb name={'Edit Branch'} />
            </Grid>
            <Grid item width={'49%'} mb={1}>
                <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <InputLabel id="demo-simple-select-label">Select Region</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" name='_id' value={formData._id} label="Select Region" onChange={handleFormdata}>
                        {allRegions?.map((region) => {
                            return <MenuItem id={region?._id} value={region?._id}>{region?.regionName}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item width={'50%'} gap={1} mt={2}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Branch Code</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.branchCode} name='branchCode' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item width={'50%'} mt={1} gap={1}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Branch Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.branchName} name='branchName' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => updateBranchDetails()} disabled={!formData.branchName || !formData.branchCode || !formData._id} style={!formData.branchCode || !formData.branchName ||!formData._id ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}

export { EditPartnerBranch }
