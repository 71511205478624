import {Breadcrumbs, Button, ButtonBase, Grid, Typography} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, {useEffect, useState} from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useNavigate} from "react-router-dom";
import CustomSearchInput from "../../../School/CustomSearchInput";
import CustomTable from "../../../School/CustomTable";
import CurrencyFormatter from "../../../../utils/currencyFormatter";
import Radio from "@mui/material/Radio";
import UnoccupiedUnits from "./unoccupiedUnits";
import HttpComponent from "../../../School/MakeRequest";
import { useSelector } from "react-redux";

//BreadCrumbs
const breadcrumbs = [
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Tenants</Typography>,
	<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">House Type</Typography>
];


export default function UnoccupiedHouses(props) {

		//TOKEN
		const { X_Authorization } = useSelector((store) => store.user)
		const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
		const [unitHouseType,setUnitHouseType] = useState([])
		const [selectedUnit ,setSelectedUnit] = useState({})

		// handle selected unit
		

		// Template Render
		const [flagState, setFlagState] = useState("listHouses")
		function handleFlagState (state) {setFlagState(state)}

		const [selectedValue, setSelectedValue] = React.useState();
		const [unitType, setUniType] = useState('')
		// const handleChange = (event) => {
		// 	setSelectedValue(event.target.value)
		// 	console.log(event.target.value);
		// 	console.log(event.target);
		// };


		const handleChange = (params) => {
			setSelectedValue(params.row._id)
			setUniType(params.row.unitType);
		};

		const columns = [
			{field : 'unitType', headerAlign : 'left', align : 'left', flex : 1, renderHeader : () => { return (<strong style={{fontSize : '12px', color : '#032541', fontWeight : 600}} >Unit Type</strong>)}, renderCell : (params) => {return (
					<div>
						<Radio value={params.row._id} size={'small'} checked={selectedValue === params.row._id} onChange={ () => handleChange(params)} color={'error'}/>
						<span style={{marginLeft : '5px'}}>{params.value}</span>
					</div>
				)}},
			{field : 'unOccupiedUnits', headerAlign : 'left', align : 'left', flex : 1, renderHeader : () => { return (<strong style={{fontSize : '12px', color : '#032541', fontWeight : 600}} >Available units</strong>)}},
			{field : 'unitCost', headerAlign : 'left', align : 'left', flex : 1, renderHeader : () => { return (<strong style={{fontSize : '12px', color : '#032541', fontWeight : 600}} >Amount</strong>)}, renderCell : (params) => {return (<span>{CurrencyFormatter(params.value, 'KES')}</span>)}},
		]


		const navigate = useNavigate()

		// loader
		const [loader, setLoader] = useState(false)

		// search
		const [formData, setFormData] = useState({search:""})
		const handleInputChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value})}


		//fetch unit types

		function getHouseTypes() {
			try {
				HttpComponent({
					method : 'GET',
					url : `/api/get_unit_types_by_business_unit_config?businessUnitConfigId=${businessUnitConfigId}`,
					token : X_Authorization
				}).then((data)=>{
					console.log(data ,'units >>>>>>>>>>>>>>')
					if(data.status === 201){
						setUnitHouseType(data.response.data)
					}else{
						console.log(`Error Getting Periods!`)
					}
				}).catch((error)=>{
					console.error(error.message);
				})
			} catch (e) {
				console.log(`Error getting House types`)
			}
		}

		//effect to fetch  unit types

		useEffect(()=>{
			getHouseTypes()
		},[])

		
		//show house units
		console.log(unitHouseType ,'here we go>>>>>>>>>>>>>>>')



		return (
			<div>
				{flagState === 'listHouses' ?
					<div>

						{/*Header and action buttons, breadCrumbs*/}
						<Grid container justifyContent={'flex-start'} direction={'column'} spacing={2}>

							{/*Header and Bread crumbs*/}
							<Grid item>
								<ButtonBase onClick={() =>{navigate("/dashboard")}}>
									<ArrowBackIosIcon style={{color : '#707070', fontSize : '25px', marginBottom : '5px'}} />
								</ButtonBase>
								<span style={{color : '#032541', fontWeight : '600', fontSize : '25px'}}>Unoccupied House Type</span>
							</Grid>

							{/*BreadCrumbs and Action Buttons*/}
							<Grid item>
								<Grid container justifyContent={'space-between'} direction={'row'}>

									{/*BreadCrumb container*/}
									<Grid item>
										<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
											{breadcrumbs}
										</Breadcrumbs>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						{/*Search and Export Container*/}
						<Grid container justifyContent={'space-between'} mt={4}>

							{/*SearchBox*/}
							<Grid item>
								<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:"/>
							</Grid>

							{/*Export button*/}
							<Grid item>
								{/*	TODO : Add Export Button*/}
							</Grid>
						</Grid>

						{/*Table*/}
						<CustomTable fontSize={'10px'} data={unitHouseType} columns={columns} rowsPerPage={5} loading={loader}/>

						{/*Cancel and Next Buttons*/}
						<Grid container justifyContent={'flex-end'} spacing={3}>

							{/*Cancel*/}
							<Grid item>
								<Button onClick={() => {props.listTenants()}} style={{borderRadius : "6px", border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}} >
									Cancel
								</Button>
							</Grid>

							{/*Add Unit*/}
							{!selectedValue ? null :
							<Grid item>
								<Button onClick={() => {handleFlagState('unoccupiedunits')}} style={{padding : '5px', textTransform : 'none', color : '#fff', background : '#032541', height : '45px', fontSize : '16px', fontWeight : '600', width : '146px'}}>
									Next
								</Button>
							</Grid>
							}

						</Grid>
					</div> : flagState === "unoccupiedunits" ? <UnoccupiedUnits selectedValue={selectedValue} unitType={unitType} listUnoccupiedHouses={() => {handleFlagState('listHouses')}} /> : null }
			</div>
		)
	} 