import React, { useEffect, useState } from "react";
import { Exports } from "../components/customerAccounts/customerComponents/exports";
import { Grid, TextField } from "@mui/material";
import CustomSelectField from "../components/School/CustomSelectField";
import { CustomizedDate } from "../components/customerAccounts/customerComponents/customDate";
const ExportSearchBar = ({ newEndDate, newStartDate, activeTabs = "None", textfiledplaceholder = "search", setSearchValue, csvExport = { headers: [], data: [] }, exportData = { headers: [], data: [] } }) => {
  // HANDLE EXPORTS {  };

  const [days, setDays] = useState([
    { value: "Default", label: "Default" },
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 7 Days", label: "Last 7 Days" },
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
    { value: "Custom Range", label: "Custom Range" },
  ]);

  const [handleDaysChange, setHandleDaysChange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const [textfieldValue, setTextFieldValue] = useState("");

  useEffect(() => {
    setSearchValue(textfieldValue);
    newStartDate(startDate);
    newEndDate(endDate);
  }, [textfieldValue, startDate, endDate]);

  useEffect(() => {
    switch (handleDaysChange) {
      case "Today":
        const today = new Date().toLocaleDateString();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]).toLocaleDateString();
          const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
        const currentDay = new Date().toLocaleDateString();
        setStartDate(firstDay);
        setEndDate(currentDay);

        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
        const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      case "Default":
        setStartDate("");
        setEndDate("");
        break;
      case "Custom Range":
        setStartDate(dateFrom);
        setEndDate(dateTo);
        break;
      default:
        return;
    }
  }, [handleDaysChange, dateFrom, dateTo]);

  return (
    <div>
      <Grid container direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <TextField onChange={(e) => setTextFieldValue(e?.target?.value)} placeholder={textfiledplaceholder} />
        </Grid>

        <Grid item sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {handleDaysChange && handleDaysChange === "Custom Range" ? <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} /> : <CustomSelectField xs={true} onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
          <Exports exportData={exportData} csvExport={csvExport} activeTabs={activeTabs} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExportSearchBar;
