import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, ButtonBase, Grid } from "@mui/material";
import Breadcrumbcomponent from "./breadcrumb";
import { ArrowBackIos } from "@mui/icons-material";
import { stockStyles } from "./stockStyling";
import { useNavigate } from "react-router-dom";
import StockTakeSetUp from "./stockModals/stockTakeSetUp";
import CategoryAndProductsStockTake from "./stockModals/categoryAndProductsStockTake";
import ViewBatchStockTake from "./stockModals/viewBatchStockTake";

const STOCKTAKE = (props) => {

	const navigate = useNavigate();

	const queryParams = decodeURIComponent(window.location.search)
	const state = queryParams.slice(1).split('?')[0]
	const wareHouseId = queryParams.slice(1).split('?')[1]

  const breadcrumb = [<span>Dashboard</span>, <span onClick={() => navigate(-1)}>Stock</span>, <span style={{ color: "red" }}>Stock Take</span>];

  const componentToShow = () => {
	if(state === "category"){
		return <CategoryAndProductsStockTake wareHouseId={wareHouseId} />
	}else if(state === "viewBatch"){
		return <ViewBatchStockTake wareHouseId={wareHouseId} />
	}
	else {
		return <StockTakeSetUp/>
	}
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"} margin={"0px 0px 2% 0px"} width={"100%"}>
        <Grid item width={"100%"} margin={"0px 0px 2% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"} direction={"row"} margin={"0px 0px 1% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

	  <Grid container>
		{componentToShow()}
	  </Grid>
    </div>
  );
};

export { STOCKTAKE };
