import { Grid, Stack, Typography, TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import {
    handleBranches,
  handleData,
  handleBranchId,
} from "../../../../features/selectedSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/bankToUpdateSlice";
import {handleAssigned, handleEdited} from "../../../../features/AlertSlice";
import axios from "axios";
import SearchableSelect from "./SearchableSelect";
import SearchableSelect2 from "./SearchableSelect2";

const baseUrl = process.env.REACT_APP_BASE_URL;

function AssignTerminal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //pulling values from redux to set initial values of form inputs
  const reduxSerialNo = useSelector((state) => state.terminalDetails.serialNo);
  const reduxPosModel = useSelector((state) => state.terminalDetails.posModel);
  //   const reduxAlias = useSelector((state) => state.terminalDetails.alias);
  //   const reduxBusinessId = useSelector((state) => state.terminalDetails.businessId);

  //value to set when user edits form
  const [businessId, setBusinessId] = useState();
  const [branch, setBranch] = useState();

  const handleBranch = (e) => {
    setBranch(e.target.value);
  };
  const handleBusinessId = (e) => {
    setBusinessId(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});

  const reduxbranch = useSelector(state => state.terminalSelect.branchId)
  const reduxbusiness = useSelector(state => state.terminalSelect.businessId)
  const reduxSerial = useSelector(state => state.terminalDetails.serialNo)
  const handleAssign = () => {
    try {
      axios
      .post(baseUrl + "/api/assignTerminalToBusiness", {
        branchId: reduxbranch,
        businessId: reduxbusiness,
        serialNo: reduxSerial
      })
      .then((response) => {
        if (response) {
          console.log("Assigned successfully");
          dispatch(handleAssigned(true));
          navigate('/terminalsAndPrinters')
        }
      });
    } catch (e) {
      console.log(e)
    }
  };

  const id = useSelector(state => state.terminalSelect.businessId)

  const fetchBranches = () => {
    try {
      axios
        .post(baseUrl + "/api/fetchBusinessBranches", {
          id: id,
        })
        .then((response) => {
          let data = JSON.stringify(response.data.data);
          let data2 = JSON.parse(data);
          setData(data2);
          dispatch(handleBranches(data2))
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) fetchBranches();
  }, [id]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(handleBankEdited(localDetails));
      dispatch(setDetails(localDetails));
      dispatch(handleEdited(true));
      navigate("/TerminalsAndPrinters");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [data, setData] = useState([]);

  const fetchBusinessData = () => {
    try {
      axios
        .get(baseUrl + "/api/fetchBusinessNoAndName", {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        })
        .then((response) => {
          let data = JSON.stringify(response.data.data);
            let data2 = JSON.parse(data);
          setData(data2);
          dispatch(handleData(data2))
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessData();
  }, []);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Typography
        fontFamily="Poppins"
        fontSize={25}
        fontWeight="600"
        fontStyle="normal"
        textAlign="left"
        color="#032541"
      >
        Assign To Business
      </Typography>
      <Breadcrumbs2
        point1={"Terminals & Printers"}
        link1={"/TerminalsAndPrinters"}
        point2={"Assign To Business"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Terminal Details
            </Typography>
            <TextField
              label="Serial No"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxSerialNo}
              InputLabelProps={{
                shrink: true,
              }}
              //   onChange={handleSerialNo}
              autoFocus
              disabled
            />
            <TextField
              label="POS Model"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxPosModel}
              InputLabelProps={{
                shrink: true,
              }}
              //   onChange={handlePosModel}
              disabled
            />
            <SearchableSelect  />{" "}
            <SearchableSelect2  />{" "}
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/TerminalsAndPrinters")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAssign}
              >
                Assign
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AssignTerminal;
