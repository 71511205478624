import { Grid, Breadcrumbs, Typography,Button } from '@mui/material'
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useEffect, useState } from 'react';
import CustomTextField from '../../../School/CustomTextField';
import CustomInputLabel from '../../../School/CustomInputLabel';
import HttpComponent from '../../../School/MakeRequest';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Products Category
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Edit Categories
    </Typography>
];
export default function PartnerEditProductcategory() {
    const [formData, setFormData] = useState({ categoryDescription: "", productService: "", categoryName: '' })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, id, businessId] = newqueryParamValue.split("?")
    const navigate = useNavigate()

      // handle input change

      function updateProductCategory() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/getCategoryById?categoryId=${id}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        categoryDescription: data?.response?.data?.categoryDescription,
                        productService: data?.response?.data?.productService,
                        categoryName: data?.response?.data?.categoryName
                    })
                }

            })

        } catch (error) {

        }
    }

    useEffect(() =>{
        updateProductCategory()
    },[])

    //handle input change

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setFormData({ ...formData, [name]: value })
    }

    // edit product category

    function updateProductCat() {
        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/updateCategory/${id}`,
                body: {
                    categoryDescription: formData.categoryDescription,
                    categoryName: formData.categoryName,
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 202) {
                    setSuccessShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000)
                } else {
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000)
                }
            })
        } catch (error) {

        }
    }

    return (
        <Grid container display={'flex'} direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'} marginBottom={'2px'} gap={1}>
                <ArrowBackIosNewIcon style={{cursor:'pointer'}} onClick={() => navigate(-1)}/>
                <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}> Edit Product Category</Typography>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mb={'10px'} mt={'10px'}>
                <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Edit Category Details</Typography>
            </Grid>
            <Grid item width={'31rem'}>
                <CustomInputLabel required={true} label={"Category Name"} />
                <CustomTextField value={formData.categoryName} large={'100%'} onChange={handleInputChange} name={"categoryName"} placeholder={"Category Name"} />
            </Grid>
            <Grid item width={'31rem'}>
                <CustomInputLabel required={true} label={"Category Description"} />
                <CustomTextField value={formData.categoryDescription} onChange={handleInputChange} large={'100%'} name={"categoryDescription"}  placeholder={"Category Description"} />
            </Grid>
            <Grid item width={'31rem'} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={updateProductCat}  disabled={!formData.categoryName || !formData.categoryDescription} style={!formData.categoryName || !formData.categoryDescription  ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}