import { Breadcrumbs, Button, Grid, Typography } from "@mui/material"
import { SuccessAlert } from "../../snackBar Alerts/successAlert"
import { ErrorAlert } from "../../snackBar Alerts/errorAlert"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomSearchInput from "../CustomSearchInput";
import ExportMenu from "../ExportMenu";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import HttpComponent from "../MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import BankSlipDrillDown from "./banksslipdrilldown";

let  localCurrency = localStorage.getItem('localCurrency')
if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
    localCurrency = 'KES'
  }
  
const numberFormat = (value) => new Intl.NumberFormat("en-US", {     style: "currency",     currency: localCurrency, }).format(value); 

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Bank Statements</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Upload File</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Preveiw Bank Statement</Typography>
]

export default function PreviewBankStatements() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    // console.log('pageState =======<><>', pageState?.data);
    const navigate = useNavigate()

    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[1]
    const previewState = stateDate.split('?')[2]
   // console.log(previewState , 'next >>>>>>>>>>>>')

    // fetch data /api/v1/get_business_bankStatements?

    function getBankStatements(){
        setPageState((old) => ({ ...old, isLoading: true }));
        try{
            HttpComponent({
                method:'GET',
                url:`/api/v1/getBankstatementtransactions?statementId=${state}&searchValue=${searchValue}&page=${pageState.page}&limit=${dataGridPageSize}`,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                console.log(data , 'pagestatttttttttttt')
                if(data.status === 200){
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })

        }catch(error){
            console.log(error)
        }

    }

    useEffect(()=>{
        getBankStatements()
    },[searchValue,state ,dataGridPageSize])



    const preveiwColumns = [
        { field: 'narrative', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Narrative</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.narrative}</span></>) } },
        { field: 'transactionRef', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >TXN Reference</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}><Link to={`/school/bankstatements?previewStatement?${params?.row?._id}?statementHistory?${params?.row?.balance === undefined ? params?.row?.creditAmount : params?.row?.balance}?${params?.row?.transactionRef}?${params?.row?.accountNumber}?${params?.row?.bankName}`}>{params?.row?.transactionRef}</Link></span></>) } },
        { field: 'transactionDate', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >TXN Date</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{DateFormatter(params?.row?.transactionDate)}</span></>) } },
        { field: 'creditAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Credit Amount</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{numberFormat(params?.row?.creditAmount)}</span></>) } },
        { field: 'balance', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Balance</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{numberFormat(params?.row?.balance === undefined ? params?.row?.creditAmount : params?.row?.balance)}</span></>) } },
    ]

    const filedata = pageState?.data?.map((data) => {
        return {
            "Narrative": data?.narrative,
            "TXN Reference": data?.transactionRef,
            "TXN Date":DateFormatter(data?.transactionDate),
            "Credit Amount":numberFormat(data?.creditAmount),
            "Balance":numberFormat(data?.balance),
        }
    })
    const fileHeaders = [["Narrative", "TXN Reference", "TXN Date", "Credit Amount", "Balance"]]
    const csvColumns = [
        { label: "Narrative", key: "Narrative" },
        { label: "TXN Reference", key: "TXN Reference" },
        { label: "TXN Date", key: "TXN Date" },
        { label: "Credit Amount", key: "Credit Amount", },
        { label: "Balance", key: "Balance", },
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.narrative, data?.transactionRef, DateFormatter(data?.transactionDate), numberFormat(data?.creditAmount), numberFormat(data?.balance)]);

   
    return (
        <Grid container direction={'column'}>
            {previewState === 'statementHistory' ? <BankSlipDrillDown/> : <>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Preview Bank Statements</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    {/* <Grid item>
                        <Button onClick={() => navigate('/school/bankstatements?sendApproval')} style={{ background: "#032541", width: "215px", height: "45px", fontSize: "14px", textTransform: "inherit", color: "#fff" }}>Send For Approval</Button>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'column'}>
                    <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                        <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Bank Statements'} title={'Bank Statements'} />
                    </Grid>
                    <Grid item>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState?.total}
                            loading={pageState?.isLoading}
                            pagination
                            page={pageState?.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={preveiwColumns}
                            rows={pageState?.data}
                            getRowId={row => row?._id} />
                    </Grid>
                </Grid>
            </Grid>
        </>}
        </Grid>
    )
}