import { ArrowDropDown } from "@mui/icons-material";
import {
  Grid,
  LinearProgress,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect } from "react";
import PaymentModeChart from "./PaymentModeChart";
import { useState } from "react";
import axios from "axios";
import PaymentModeDropdown from "./PaymentModeDropdown";
import { useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_BASE_URL;

function PaymentMode() {
  const [cardTotal, setCardTotal] = useState(null);
  const [MPESATotal, setMPESATotal] = useState(null);
  const [walletTotal, setWalletTotal] = useState(null);
  const [cashTotal, setCashTotal] = useState(null);
  const [paybillTotal, setPaybillTotal] = useState(null);

  const fetchPaymentModeTotals = async () => {
    const response = await axios.get(baseUrl + "/api/fetchPaymentModeTotals", {
      headers: {
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if(response.data.paymentModeTotals["Card Payment"]) {
      setCardTotal(response.data.paymentModeTotals["Card Payment"]);
    }else {
      setCardTotal(0);
    }
    if(response.data.paymentModeTotals.MPESA) {
      setMPESATotal(response.data.paymentModeTotals.MPESA);
    }else {
      setMPESATotal(0);
    }
    if(response.data.paymentModeTotals["PAYITCARD"]) {
      setWalletTotal(response.data.paymentModeTotals["PAYITCARD"]);
    }else {
      setWalletTotal(0);
    }
    if(response.data.paymentModeTotals["Cash Payment"]) {
      setCashTotal(response.data.paymentModeTotals["Cash Payment"])
    }else {
      setCashTotal(0);
    }
    if(response.data.paymentModeTotals["Paybill Payment"]) {
      setPaybillTotal(response.data.paymentModeTotals["Paybill Payment"]);
    }else {
      setPaybillTotal(0);
    }
    ;
  };

  useEffect(() => {
    fetchPaymentModeTotals();
  }, []);

  let year = useSelector((state) => state.salesDropdown.clickedItem2);

  return (
    <Stack
      width={"100%"}
      // height={"660px"}
      borderRadius={5}
      backgroundColor="#ffffff"
      shadowColor="rgba(145, 158, 171, 0.12)"
      shadowRadius={20}
      shadowOpacity={1}
      p={2}
      spacing={2}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={14}
          fontWeight="bold"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Payment Mode Performance
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            // fontWeight="bold"
            fontStyle="normal"
            textAlign="right"
            color="grey"
          >
            View:
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize={14}
            fontWeight="bold"
            fontStyle="normal"
            textAlign="right"
            color="#000000"
          >
            {year}
          </Typography>
          <PaymentModeDropdown />
        </Stack>
      </Stack>
      <Grid container spacing={0.5}>
        <Grid item xs={2.4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="600"
              fontStyle="normal"
              textAlign="center"
              color="#032541"
            >
              Card
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              {cardTotal || cardTotal === 0  ? (
                `KES ` + cardTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2.4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="600"
              fontStyle="normal"
              textAlign="center"
              // color="#0381ed"
              color="#f79008"
            >
              Mpesa
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              {MPESATotal || MPESATotal === 0   ? (
                `KES ` + MPESATotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2.4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="600"
              fontStyle="normal"
              textAlign="center"
              color="#17ae7b"
            >
              Zed-pocket Money
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              {walletTotal || walletTotal === 0 ? (
                `KES ` + walletTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2.4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="600"
              fontStyle="normal"
              textAlign="center"
              // color="#f79008"
              color="#0381ed"
            >
              Cash
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              {cashTotal || cashTotal === 0 ? (
                `KES ` + cashTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2.4}>
          <Stack textAlign={"center"} bgcolor={"#f0f0f0"} borderRadius={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="600"
              fontStyle="normal"
              textAlign="center"
              color="#ff462e"
            >
              Bank Paybill
            </Typography>
            <Typography
              fontFamily="Poppins"
              fontSize={12}
              fontWeight="bold"
              fontStyle="normal"
              textAlign="center"
              color="#687c8d"
            >
              {paybillTotal || paybillTotal === 0   ? (
                `KES ` + paybillTotal?.toLocaleString()
              ) : (
                <Skeleton
                  variant="text"
                  fontSize={18}
                  // width={'80%'}
                  sx={{ bgcolor: "#fff", mx: 2 }}
                  animation="wave"
                />
              )}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Stack width={"100%"} height="400px">
        <PaymentModeChart />
      </Stack>
    </Stack>
  );
}

export default PaymentMode;
