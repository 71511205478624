import { useState, useEffect } from "react";
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { Collapse  , ListItem , ListItemText} from "@mui/material";
import List from "@mui/material/List";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HttpComponent from "../../components/School/MakeRequest";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function PayablesGeneralBusiness(props) {
    const classes = useStyles();
    let businessCat = localStorage.getItem("businessCategory");
    const [open, setOpen] = React.useState(false);
    const location = useLocation()

    //set active link

    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc"

    const handleClick = () => { setOpen(!open); };
 
    




    return (
        <div>

            {/*Users*/}
            <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
                <div style={{ marginRight: '5%' }}>
                    <AccountCircleIcon />
                </div>

                <ListItemText primary="Payables" style={{ fontFamily: 'Poppins' }} />

                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/*Collapsed Users*/}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>


                    <div>
                        {/*  */}
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/payables/suppplier/raisepayment") ? activeColor : '#fff' }} to="/payables/suppplier/raisepayment">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Raise Payment" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/suppliers") ? activeColor : '#fff' }} to="/suppliers">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Suppliers/Vendors" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/employees") ? activeColor : '#fff' }} to="/employees">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Employees" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/payables/otherpayments") ? activeColor : '#fff' }} to="/payables/otherpayments">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Other Payments" />
                            </ListItem>
                        </Link>

                        <Link style={{ textDecoration: 'none', color: isActiveLink("/payables/approvers") ? activeColor : '#fff' }} to="/payables/approvers">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Approvers" />
                            </ListItem>
                        </Link>

                        <Link style={{ textDecoration: 'none', color: isActiveLink("/payables/transcations") ? activeColor : '#fff' }} to="/payables/transcations">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Transcations" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: isActiveLink("/supplier/vendor/invoices") ? activeColor : '#fff' }} to="/supplier/vendor/invoices">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Supplier / Vendor Invoices" />
                            </ListItem>
                        </Link>
                        
                    </div>



                </List>
            </Collapse>
        </div>
    )
}
