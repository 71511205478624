import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Dash from "../../common/dash";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Select, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ElIf, If } from "rc-if-else";
import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import deactivateTerminal from "../../common/images/deactivate.svg";
import success from "../../common/images/success.png";
import deactivate from "../../common/images/deactivate.svg";
import activateGroup from "../../common/images/activate.svg";
import deactivateGroup from "../../common/images/deactivate.svg";
import session from "../../common/images/session.svg";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";
import { handleBranchDetails } from "../../features/branchSlice";

// session Expired
const style = {
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "660px",
  height: "570px",
  p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;
//const baseUrl = "https://zed.swerri.io"

const AddTerminalModalStyle = {
  width: "553px",
  height: "448px",
  margin: "9% auto 0px auto",
  padding: "0 0 30.8px",
  borderRadius: "15px",
  backgroundColor: "#fff",
  align: "center",
};

const SuccessModalStyle = {
  width: "506px",
  height: "506px",
  margin: "10% Auto 0 Auto",
  padding: "86px 24px 97.7px 61px",
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": { padding: "4px 0" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "0px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffff00",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "12px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

// Conditional Data grid columns
//Active Columns
const activeColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "terminalSerialNumber",
    headerName: "Terminal Serial Number",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "till_paybill",
    headerName: "Short Code",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessID",
    headerName: "Business ID",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params) => {
      return <div>{!params.row.businessID ? "N/A" : params.row.businessID}</div>;
    },
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params) => {
      console.log(params.row.businessName);
      return <div>{!params.row.businessName ? "N/A" : params.row.businessName}</div>;
    },
  },
  {
    field: "deviceModel",
    headerName: "Device model",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "branch",
    headerName: "Branch",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params) => {
      return <div>{!params.row.branch ? "N/A" : params.row.branch}</div>;
    },
  },
  {
    field: "alias",
    headerName: "Alias",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
];

if (localStorage.getItem("group") === "Merchant") {
  activeColumns.push({
    field: "Actions",
    hide: false,
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      const dispatch = useDispatch();
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;
      console.log("<><><><>", row);

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Edit Terminal Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      // Assign Branch Terminal Modal Config
      const [assignBranchOpen, setAssignBranchOpen] = useState(false);
      const handleAssignBranchOpen = () => setAssignBranchOpen(true);
      const handleAssignBranchClose = () => setAssignBranchOpen(false);

      // Release Branch Terminal Modal Config
      const [releaseTerminalOpen, setReleaseTerminalOpen] = useState(false);
      const ReleaseTerminalModalOpen = () => setReleaseTerminalOpen(true);
      const ReleaseTerminalModalClose = () => setReleaseTerminalOpen(false);

      // Deactivate Terminal Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // Status Updates Modals
      // Success Edit Modal Control
      const [successOpen, setSuccessOpen] = React.useState(false);
      const handleSuccessOpen = () => setSuccessOpen(true);
      const handleSuccessClose = () => setSuccessOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
      const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

      // Data Getters and Setters
      const [serialNo, setSerialNo] = useState(row.terminalSerialNumber);
      const [model, setModel] = useState(row.deviceModel);
      const [shortCode, setShortCode] = useState(row.alias);
      const [businessId, setBusinessId] = useState(row.businessID);
      const [terminalId, setTerminalId] = useState(row.id);

      //Terminal Update Url
      const updateUrl = baseUrl + "/api/updateTerminal/?_id=" + row.id;
      const assignUrl = baseUrl + "/api/transferTerminalToBranch";
      const releaseUrl = baseUrl + "/api/deAssignTerminalFromBranch";
      const shopsUrl = baseUrl + "/api/listBusiness";
      const branchesUrl = baseUrl + "/api/getBusinessBranchesByBid";

      // Fetch all Active shops Created
      const [businessData, setBusinessData] = useState([]);
      const [shopName, setShopName] = useState([]);

      const [branchName, setBranchName] = useState([]);
      const [branchId, setBranchId] = useState([]);
      const [branches, setBranches] = useState([]);

      // console.log(`This is branch`, branches)
      // console.log(`This is branch ID`, branchId)

      // Fetch all Active shops Created
      const fetchActiveShops = async () => {
        try {
          const response = await fetch(shopsUrl + "/Active", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          const data = await response.json();

          if (response.status === 200) {
            setBusinessData(data.data);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      //Fetch branches
      const fetchBranches = async () => {
        try {
          const response = await fetch(branchesUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            // body: JSON.stringify({
            //   businessId: "6384c4748fae6a0f079b13cf"
            // })
          });
          const data = await response.json();
          if (response.status === 200) {
            setBranches(data.data);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      //Update terminal Function
      const updateTerminal = async (e) => {
        e.preventDefault();
        console.log(shopName);
        const editResponse = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalSerialNumber: serialNo,
            alias: shortCode,
            businessID: businessId,
            deviceModel: model,
            requestedBy: localStorage.getItem("username"),
            dateModified: Date.now(),
          }),
        });
        const data = await editResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      // console.log(terminalId, `i am terminal ID`)
      //Assign terminal Function
      const assignTerminal = async (e) => {
        e.preventDefault();
        const assignResponse = await fetch(assignUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalId: row.id,
            // branchId : "6384c4748fae6a0f079b13d0" || "6389d8894aea6e58c368e562" || branchId
            branchId: localStorage.getItem("branchId"),
          }),
        });
        const data = await assignResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      //Release terminal Function
      const releaseTerminal = async (e) => {
        e.preventDefault();
        const releaseResponse = await fetch(releaseUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalId: row.id,
          }),
        });
        const data = await releaseResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      // Deactivate terminals Update Function
      const deactivateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              dateDeactivated: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      useEffect(() => {
        fetchActiveShops();
        fetchBranches();
      }, []);

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

          {/*Edit terminal Modal*/}
          <Modal className="container container-fluid" open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Edit a Terminal</label>
              </div>

              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form onSubmit={updateTerminal}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>
                  <input
                    readOnly
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    readOnly
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*PayBill*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Alias
                  </label>

                  <input
                    value={shortCode}
                    onChange={(e) => setShortCode(e.target.value)}
                    placeholder="ShortCode"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Business ID*/}
                  <label
                    style={{
                      width: "78px",
                      height: "20px",
                      margin: "0 324px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Business ID
                  </label>
                  {/*<select value={shopName} onChange={(e) => setShopName(e.target.value)} style={{width : "404px" , height : "39px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>*/}
                  {/*    <option value="">Select Business</option>*/}
                  {/*    {businessData.map((shops) => {*/}
                  {/*        const shopName = shops.businessNumber*/}
                  {/*        const shopID = shops.businessName*/}
                  {/*        return <option value={shopName}>{shopName} {shopID}</option>*/}
                  {/*    })}*/}
                  {/*</select>*/}

                  <input
                    readOnly
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                    placeholder="Business ID"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Branch*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Branch
                  </label>

                  <input
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    // placeholder="Branch"
                    disabled={true}
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Close  */}
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Close
                    </Button>

                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Assign terminal Modal*/}
          <Modal className="container container-fluid" open={assignBranchOpen} onClose={handleAssignBranchClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Assign Branch</label>
              </div>

              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form id="formID" onSubmit={assignTerminal}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>

                  <input
                    readOnly
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    readOnly
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Branch*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Branch
                  </label>

                  <select
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branche) => {
                      const brancName = branche.branchName;
                      const branchID = branche._id;
                      dispatch(handleBranchDetails(branche));
                      localStorage.removeItem("branchId");
                      localStorage.setItem("branchId", branchID);
                      // setBranchId(branchID);
                      return (
                        <option id={branchID} value={branchID} onChange={(e) => setBranchId(e.target.value) || branchID}>
                          {brancName}
                        </option>
                      );
                    })}
                  </select>

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Cancel  */}
                    <Button
                      onClick={handleAssignBranchClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>

                    {/*  Assign  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Assign
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Release terminal Modal*/}
          <Modal className="container container-fluid" open={releaseTerminalOpen} onClose={ReleaseTerminalModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Release Terminal</label>
              </div>

              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form id="formID1" onSubmit={releaseTerminal}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>

                  <input
                    readOnly
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    readOnly
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Branch*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Branch
                  </label>

                  <select
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* <option value="">Select Branch</option> */}
                    {branches.map((branche) => {
                      const brancName = branche.branchName;
                      const branchID = branche._id;
                      return (
                        <option id={branchID} value={branchID}>
                          {brancName}
                        </option>
                      );
                    })}
                  </select>

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Cancel  */}
                    <Button
                      onClick={ReleaseTerminalModalClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>

                    {/*  Release  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Release
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Deactivate Terminal Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateTerminalUpdate}
            actionImage={deactivateTerminal}
            action="Deactivate"
            item={row.terminalSerialNumber}
            processName={"Deactivate"}
          />

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Deactivated"}
          />

          {/*Success Release Modal*/}
          {/* <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Deactivated"}
          /> */}

          {/*Success Edit Terminals*/}
          <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={row.terminalSerialNumber} successObject={"Terminal"} successType={"Updated"} />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>

          <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            {/*Conditional Modal Popups*/}
            {/*Check if is on Active Tab*/}

            {/* <MenuItem
              disableRipple
              onClick={() => {
                handleClickOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Edit
            </MenuItem> */}

            {row.branch === "" ? (
              <>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    handleAssignBranchOpen();
                    handleActionClose();
                  }}
                >
                  <AddOutlinedIcon sx={{ color: "green" }} />
                  Assign Branch
                </MenuItem>
              </>
            ) : (
              <></>
            )}

            {row.branch !== "" ? (
              <>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    ReleaseTerminalModalOpen();
                    handleActionClose();
                  }}
                >
                  <RemoveIcon sx={{ color: "red" }} />
                  Release Terminal
                </MenuItem>
              </>
            ) : (
              <></>
            )}

            <MenuItem
              onClick={() => {
                DeactivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Deactivate
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  });
} else if (localStorage.getItem("group") === "Admin") {
  activeColumns.push({
    field: "Actions",
    hide: false,
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;
      console.log("<><<>>>", row);

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Edit Terminal Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      const [branchName, setBranchName] = useState([]);
      const [branchId, setBranchId] = useState([]);
      const [branches, setBranches] = useState([""]);

      // Assign Branch Terminal Modal Config
      const [assignBranchOpen, setAssignBranchOpen] = useState(false);
      const handleAssignBranchOpen = () => setAssignBranchOpen(true);
      const handleAssignBranchClose = () => setAssignBranchOpen(false);

      // Release Business Terminal Modal Config
      const [releaseTerminalOpen, setReleaseTerminalOpen] = useState(false);
      const ReleaseTerminalModalOpen = () => setReleaseTerminalOpen(true);
      const ReleaseTerminalModalClose = () => setReleaseTerminalOpen(false);

      // Deactivate Terminal Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // Status Updates Modals
      // Success Edit Modal Control
      const [successOpen, setSuccessOpen] = React.useState(false);
      const handleSuccessOpen = () => setSuccessOpen(true);
      const handleSuccessClose = () => setSuccessOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
      const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

      // Data Getters and Setters
      const [serialNo, setSerialNo] = useState(row.terminalSerialNumber);
      const [model, setModel] = useState(row.deviceModel);
      const [shortCode, setShortCode] = useState(row.alias);
      const [businessId, setBusinessId] = useState(row.businessID);
      const [terminalId, setTerminalId] = useState(row.id);
      const [businessName, setBusinessName] = useState(row.businessName);

      //Terminal Update Url
      const updateUrl = baseUrl + "/api/updateTerminal/?_id=" + row.id;
      const shopsUrl = baseUrl + "/api/listBusiness";
      const assignToBusiness = baseUrl + "/api/AssignTerminalToBusiness";
      const releaseFromBusiness = baseUrl + "/api/deAssignTerminalFromBusiness";
      const getBranches = baseUrl + "/api/getBusinessBranches";

      // Fetch all Active shops Created
      const [businessData, setBusinessData] = useState([]);
      const [defaultBranch, setDefaultBranch] = useState([]);
      const [shopName, setShopName] = useState("");

      const handleChange = () => {
        fetchBranches();
      };

      // Fetch all Active shops Created
      const fetchActiveShops = async () => {
        try {
          const response = await fetch(shopsUrl + "/Active", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          const data = await response.json();
          // console.log("data active shops", data)
          if (response.status === 200) {
            setBusinessData(data.data);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      //Fetch branches
      const fetchBranches = async () => {
        try {
          const response = await fetch(getBranches, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              // businessId: '63a486669cd16a267a1d8997'
              // businessId: localStorage.getItem("localShopID")
              businessId: localStorage.getItem("shopid"),
            }),
          });
          const data = await response.json();
          console.log(">>>>>>>>>>>>>>> fetch branches as admin", data.data);
          setBranches(data.data);

          if (response.status === 200) {
            setBranches(data.data);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      //Update terminal Function
      const updateTerminal = async (e) => {
        e.preventDefault();
        const editResponse = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalSerialNumber: serialNo,
            alias: shortCode,
            businessID: businessId,
            deviceModel: model,
            requestedBy: localStorage.getItem("username"),
            dateModified: Date.now(),
          }),
        });
        const data = await editResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      //Assign terminal To Business
      const assignTerminalBusiness = async (e) => {
        e.preventDefault();
        const assignResponse = await fetch(assignToBusiness, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalId: row.id,
            branchId: localStorage.getItem("branchid"),
            // branchId : '63a486669cd16a267a1d8999'
            // businessId : localStorage.getItem('businessID')
          }),
        });
        const data = await assignResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      //Release terminal from business
      const releaseTerminalBusiness = async (e) => {
        e.preventDefault();
        const releaseResponse = await fetch(releaseFromBusiness, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalId: row.id,
          }),
        });
        const data = await releaseResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      // Deactivate terminals Update Function
      const deactivateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              dateDeactivated: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      useEffect(() => {
        fetchActiveShops();
      }, []);

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

          {/*Edit terminal Modal*/}
          <Modal className="container container-fluid" open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Edit a Terminal</label>
              </div>

              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form onSubmit={updateTerminal}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>

                  <input
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Alias*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Alias
                  </label>

                  <input
                    value={shortCode}
                    onChange={(e) => setShortCode(e.target.value)}
                    placeholder="ShortCode"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Business ID*/}
                  <label
                    style={{
                      width: "78px",
                      height: "20px",
                      margin: "0 324px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Business ID
                  </label>
                  {/*<select value={shopName} onChange={(e) => setShopName(e.target.value)} style={{width : "404px" , height : "39px" , margin : "10px 0 0" , objectFit : "contain" , borderRadius : "4px" , border : "solid 1px #dfdede" , backgroundColor : "#fff"}}>*/}
                  {/*    <option value="">Select Business</option>*/}
                  {/*    {businessData.map((shops) => {*/}
                  {/*        const shopName = shops.businessNumber*/}
                  {/*        const shopID = shops.businessName*/}
                  {/*        return <option value={shopName}>{shopName} {shopID}</option>*/}
                  {/*    })}*/}
                  {/*</select>*/}

                  <input
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                    placeholder="Business ID"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Close  */}
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Close
                    </Button>

                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Assign terminal Modal*/}
          <Modal className="container container-fluid" open={assignBranchOpen} onClose={handleAssignBranchClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Assign To Business</label>
              </div>

              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form id="formID" onSubmit={assignTerminalBusiness}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>

                  <input
                    readOnly
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    readOnly
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Business ID*/}
                  <label
                    style={{
                      width: "78px",
                      height: "20px",
                      margin: "0 324px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Business ID
                  </label>

                  <select
                    value={shopName}
                    // onChange={(e) => setShopName(e.target.value)}
                    onChange={(e) => {
                      setShopName(e.target.value);
                      localStorage.setItem("shopid", e.target.value);
                      handleChange();
                    }}
                    // onChange={(e) => handleChange(e)}
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="">Select Business</option>
                    {businessData.map((shops) => {
                      const shopNumber = shops.businessNumber;
                      const shopName = shops.businessName;
                      const shopID = shops._id;
                      return (
                        <option value={shopID}>
                          {/* <option value={[shopID, shopName]} > */}
                          {shopNumber} {shopName}
                        </option>
                      );
                    })}
                  </select>

                  {/*Branch*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Branch
                  </label>

                  <select
                    value={branchName}
                    onChange={(e) => {
                      setBranchName(e.target.value);
                      localStorage.setItem("branchid", e.target.value);
                    }}
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branche) => {
                      const brancName = branche.branchName;
                      const branchID = branche._id;
                      // localStorage.removeItem('branchId')
                      // localStorage.setItem("branchId", branchID)
                      // setBranchId(branchID);
                      return (
                        <option
                          id={branchID}
                          value={branchID}
                          // onChange={(e) => localStorage.setItem("branchid", e.target.value)}
                        >
                          {brancName}
                        </option>
                      );
                    })}
                  </select>

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Cancel  */}
                    <Button
                      onClick={handleAssignBranchClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>

                    {/*  Assign  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Assign
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Release terminal Modal*/}
          <Modal className="container container-fluid" open={releaseTerminalOpen} onClose={ReleaseTerminalModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Release From Business</label>
              </div>

              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form id="formID1" onSubmit={releaseTerminalBusiness}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>

                  <input
                    readOnly
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    readOnly
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Business*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Business Name
                  </label>

                  <input
                    readOnly
                    value={businessName}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Cancel  */}
                    <Button
                      onClick={ReleaseTerminalModalClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>

                    {/*  Release  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Release
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Deactivate Terminal Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateTerminalUpdate}
            actionImage={deactivateTerminal}
            action="Deactivate"
            item={row.terminalSerialNumber}
            processName={"Deactivate"}
          />

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Deactivated"}
          />

          {/*Success Edit Terminals*/}
          <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={row.terminalSerialNumber} successObject={"Terminal"} successType={"Updated"} />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>

          <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            {/*Conditional Modal Popups*/}
            {/*Check if is on Active Tab*/}
            <MenuItem
              disableRipple
              onClick={() => {
                handleClickOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Edit
            </MenuItem>

            {row.businessID === "" ? (
              <>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    handleAssignBranchOpen();
                    handleActionClose();
                  }}
                >
                  <AddOutlinedIcon sx={{ color: "green" }} />
                  Assign To Business
                </MenuItem>
              </>
            ) : (
              <></>
            )}

            {row.businessID !== "" ? (
              <>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    ReleaseTerminalModalOpen();
                    handleActionClose();
                    // console.log("Awaiting Release Endpoint")
                  }}
                >
                  <RemoveIcon sx={{ color: "red" }} />
                  Release from Business
                </MenuItem>
              </>
            ) : (
              <></>
            )}

            <MenuItem
              onClick={() => {
                DeactivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Deactivate
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  });
}

// New Columns
const newColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "terminalSerialNumber",
    headerName: "Terminal Serial Number",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "deviceModel",
    headerName: "Device model",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "till_paybill",
    headerName: "Short Code",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessID",
    headerName: "Business ID",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "dateCreated",
    headerName: "Date Created",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
];

if (localStorage.getItem("group") === "Merchant") {
  // newColumns.push({
  //     field : 'Actions' ,
  //     hide : true ,
  //     headerName : 'Actions' ,
  //     flex : 1 ,
  //     headerAlign : 'center' ,
  //     align : 'center' ,
  //     editable : false ,
  //     renderCell : (params , event) => {
  //
  //         //DropDown Handler
  //         const [anchorEl , setAnchorEl] = React.useState(null);
  //         const ActionOpen = Boolean(anchorEl);
  //         const handleClick = (event) => {
  //             setAnchorEl(event.currentTarget);
  //         };
  //         const handleActionClose = () => {
  //             setAnchorEl(null);
  //         };
  //
  //         const {row} = params
  //
  // // session Modal Config
  // const [sessionOpen, setSessionOpen] = React.useState(false);
  // const handleSessionOpen = () => setSessionOpen(true);
  // const handleSessionClose = () => setSessionOpen(false);
  // const handleLogin = () => {window.location.href='/logout'}
  //
  //         // Edit Terminal Modal Config
  //         const [open , setOpen] = useState(false);
  //         const handleClickOpen = () => setOpen(true);
  //         const handleClose = () => setOpen(false);
  //
  //         // Deactivate Terminal Modal Config
  //         const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
  //         const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
  //         const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);
  //
  //         // Status Updates Modals
  //         // Success Edit Modal Control
  //         const [successOpen , setSuccessOpen] = React.useState(false);
  //         const handleSuccessOpen = () => setSuccessOpen(true);
  //         const handleSuccessClose = () => setSuccessOpen(false);
  //
  //         // Success Deactivation Modal Config
  //         const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
  //         const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
  //         const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);
  //
  //         // Data Getters and Setters
  //         const [serialNo , setSerialNo] = useState(row.terminalSerialNumber)
  //         const [model , setModel] = useState(row.deviceModel)
  //         const [shortCode , setShortCode] = useState(row.till_paybill)
  //         const [businessId , setBusinessId] = useState(row.businessID)
  //
  //         //Terminal Update Url
  //         const updateUrl = baseUrl + '/api/updateTerminal/?_id=' + row.id
  //         const deleteUrl = baseUrl + '/api/deleteTerminal/' + row.id
  //
  //         //Update terminal Function
  //         const updateTerminal = async (e) => {
  //             e.preventDefault()
  //             const editResponse = await fetch(updateUrl , {
  //
  //                 method : 'PUT' , headers : {
  //                     'Content-Type' : 'application/json' ,
  //                     'Accept' : 'application/json' ,
  //                     'X-Authorization' : localStorage.getItem('X-Authorization')
  //                 } , body : JSON.stringify({
  //                     terminalSerialNumber : serialNo ,
  //                     till_paybill : shortCode ,
  //                     businessID : businessId ,
  //                     deviceModel : model ,
  //                     requestedBy : localStorage.getItem('username') ,
  //                     dateModified : Date.now()
  //                 })
  //             });
  //             const data = await editResponse.json()
  //             if (data.Status === "SUCCESS") {
  //                 handleClose()
  //                 handleSuccessOpen()
  //             } else if (editResponse.status === 401) {
  //                handleSessionOpen()
  //
  //             }
  //         }
  //
  //         // Deactivate terminals Update Function
  //         const deactivateTerminalUpdate = async (e) => {
  //             e.preventDefault()
  //             try {
  //                 const deactivateResponse = await fetch(updateUrl , {
  //                     method : 'PUT' , headers : {
  //                         'Content-Type' : 'application/json' ,
  //                         'Accept' : 'application/json' ,
  //                         'X-Authorization' : localStorage.getItem('X-Authorization')
  //                     } , body : JSON.stringify({
  //                         terminalStatus : "Deactivation" ,
  //                         requestedBy : localStorage.getItem('username') ,
  //                         dateDeactivated : Date.now() ,
  //                         approvedBy : localStorage.getItem('username')
  //                     })
  //                 })
  //                 if (deactivateResponse.status === 202) {
  //                     DeactivateModalHandleClose()
  //                     successDeactivationModalHandleOpen()
  //                 } else if (deactivateResponse.status === 401) {
  //                    handleSessionOpen()
  //                 }
  //             } catch (e) {
  //                 console.log(e)
  //             }
  //         }
  //
  //     //         return (<div>
  //
  // {/*session expired modal*/}
  // <Modal
  //     open={sessionOpen} onClose={handleSessionClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //     <Box sx={style}>
  //         <div style={{width: "207px", height: "206px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
  //             <img src={session}/>
  //         </div>
  //         <div style={{width: "506px", height: "89px", margin: "26px 0 3px 47px", padding: "35px 2px 9px 16px", fontFamily: "Poppins", fontSize: "30px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
  //             <span>
  //                 Your Session has expired and you have been logged out.
  //             </span>
  //         </div>
  //         <div style={{width: "500px", height: "61.7px", margin: "65px 52.9px 0 75px"}}>
  //             <Button onClick={handleSessionClose} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#032541",width: "146.8px", height: "61.7px", margin: "0 62.2px 0 0", padding: "19.1px 44.6px 18.5px 41.1px", border: "solid 1px #dc3545"}}>
  //                 Cancel
  //             </Button>
  //             <Button onClick={handleLogin} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#fff",width: "190.1px", height: "61.7px", margin: "0 0 0 62.2px", padding: "19px 32.1px 18.7px 33px", backgroundColor: "#032541"}} >
  //                 Go to Login
  //             </Button>
  //         </div>
  //     </Box>
  // </Modal>
  //             {/*Edit terminal Modal*/}
  //             <Modal className='container container-fluid' open={open} onClose={handleClose}
  //                    aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "553px" ,
  //                     height : "618px" ,
  //                     margin : "9% auto 0px auto" ,
  //                     borderRadius : "15px" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <div style={{
  //                         width : "553x" ,
  //                         height : "90px" ,
  //                         margin : "0 0 9px" ,
  //                         padding : "36px 30px 33px" ,
  //                         borderRadius : "16px" ,
  //                         backgroundColor : "#dc3545"
  //                     }}>
  //                         <label>
  //                             Edit a Terminal
  //                         </label>
  //                     </div>
  //                     {/*Content Group and Form*/}
  //                     <div align='center'
  //                          style={{width : "404px" , height : "414.1px" , margin : "29px 60px 41.9px 70px"}}>
  //                         <form onSubmit={updateTerminal}>
  //                             {/*Serial No.*/}
  //                             <label style={{
  //                                 height : "20px" ,
  //                                 margin : "0 340px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Serial No.
  //                             </label>
  //                             <input value={serialNo} onChange={(e) => setSerialNo(e.target.value)}
  //                                    placeholder="Serial" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*POS Model*/}
  //                             <label style={{
  //                                 width : "100px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 328px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 POS Model.
  //                             </label>
  //                             <input value={model} onChange={(e) => setModel(e.target.value)} placeholder="Model"
  //                                    style={{
  //                                        width : "404px" ,
  //                                        height : "39px" ,
  //                                        margin : "10px 0 0" ,
  //                                        objectFit : "contain" ,
  //                                        borderRadius : "4px" ,
  //                                        border : "solid 1px #dfdede" ,
  //                                        backgroundColor : "#fff"
  //                                    }}/>
  //
  //                             {/*PayBill*/}
  //                             <label style={{
  //                                 width : "110px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 304px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Pay Bill/Till No.
  //                             </label>
  //                             <input value={shortCode} onChange={(e) => setShortCode(e.target.value)}
  //                                    placeholder="ShortCode" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*Business ID*/}
  //                             <label style={{
  //                                 width : "78px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 324px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Business ID
  //                             </label>
  //                             <input value={businessId} onChange={(e) => setBusinessId(e.target.value)}
  //                                    placeholder="Business ID" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*Buttons*/}
  //                             <div align='right'>
  //                                 {/*  Close  */}
  //                                 <Button onClick={handleClose} variant='outlined' style={{
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     textAlign : "left" ,
  //                                     color : "#032541" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 30px 0 0" ,
  //                                     padding : "9px 29px 8.1px 30px" ,
  //                                     border : "solid 1px #032541"
  //                                 }}>
  //                                     Close
  //                                 </Button>
  //                                 {/*  save  */}
  //                                 <Button type='submit' variant='standard' style={{
  //                                     color : '#fff' ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 0 0 30px" ,
  //                                     padding : "9px 32px 8.1px 31px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     Save
  //                                 </Button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                 </div>
  //             </Modal>
  //
  //             {/*Deactivate Terminal Modal*/}
  //             <Modal centered align={'center'} className='container container-fluid' open={deactivateModalOpen}
  //                    onClose={DeactivateModalHandleClose} aria-labelledby="modal-modal-title"
  //                    aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "553px" ,
  //                     height : "306px" ,
  //                     margin : "10% 23px 8px 39px" ,
  //                     padding : "0 0 57.9px" ,
  //                     borderRadius : "15px" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <div style={{
  //                         width : "553px" ,
  //                         height : "90px" ,
  //                         margin : "0 0 50px" ,
  //                         padding : "35px 36px" ,
  //                         borderRadius : "16px" ,
  //                         backgroundColor : "#dc3545"
  //                     }}>
  //                         <label style={{
  //                             height : "20px" ,
  //                             margin : "0 325px 0 0" ,
  //                             fontFamily : "Poppins" ,
  //                             fontSize : "14px" ,
  //                             fontWeight : "600" ,
  //                             fontStretch : "normal" ,
  //                             fontStyle : "normal" ,
  //                             lineHeight : "normal" ,
  //                             letterSpacing : "normal" ,
  //                             textAlign : "left" ,
  //                             color : "#fff"
  //                         }}>
  //                             Deactivate Terminal
  //                         </label>
  //                     </div>
  //                     <div>
  //                         <form onSubmit={deactivateTerminalUpdate}>
  //                             <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}
  //                                  src={deactivateTerminal} alt={'activate terminal'}/>
  //                             <label style={{
  //                                 height : "24px" ,
  //                                 margin : "0 1.1px 0 14px" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "17px" ,
  //                                 fontWeight : "500" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "1.47" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#032541"
  //                             }}>
  //                                 Do you want to Deactivate <b>{row.terminalSerialNumber}?</b>
  //                             </label>
  //                             <div align='right'>
  //                                 <Button onClick={DeactivateModalHandleClose} variant='outlined' style={{
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     textAlign : "left" ,
  //                                     color : "#dc3545" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 30px 0 0" ,
  //                                     padding : "9px 29px 8.1px 30px" ,
  //                                     border : "solid 1px #dc3545"
  //                                 }}>
  //                                     Cancel
  //                                 </Button>
  //                                 {/*  save  */}
  //                                 <Button type='submit' variant='standard' style={{
  //                                     color : '#fff' ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 16px 0 30px" ,
  //                                     padding : "9px 32px 8.1px 31px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     Deactivate
  //                                 </Button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                 </div>
  //             </Modal>
  //
  //             {/*Status Update Modals*/}
  //             {/*Success Deactivation Modal*/}
  //             <Modal centered align={'center'} className='container container-fluid'
  //                    open={successDeactivationModalOpen} onClose={successDeactivationModalHandleClose}
  //                    aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "506px" ,
  //                     height : "506px" ,
  //                     margin : "10% 16px 23px 45px" ,
  //                     padding : "87px 75px 92.7px 34px" ,
  //                     borderRadius : "36px" ,
  //                     boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <img src={success} alt={'success deactivation'}/>
  //                     <label style={{
  //                         width : "356px" ,
  //                         height : "65px" ,
  //                         margin : "37.8px 0 0" ,
  //                         fontFamily : "Poppins" ,
  //                         fontSize : "25px" ,
  //                         fontWeight : "normal" ,
  //                         fontStretch : "normal" ,
  //                         fontStyle : "normal" ,
  //                         lineHeight : "1.2" ,
  //                         letterSpacing : "normal" ,
  //                         textAlign : "center" ,
  //                         color : "#6e7074"
  //                     }}>
  //                         <b>{row.terminalSerialNumber}</b> Deactivated
  //                         successfully.
  //                     </label>
  //                     <Button onClick={() => {
  //                         successDeactivationModalHandleClose()
  //                         window.location.reload(true)
  //                     }} style={{
  //                         fontFamily : "Poppins" ,
  //                         fontSize : "25px" ,
  //                         fontWeight : "600" ,
  //                         color : "#fff" ,
  //                         width : "124.5px" ,
  //                         height : "52.3px" ,
  //                         padding : "8px 44.5px 9.3px 35px" ,
  //                         backgroundColor : "#032541"
  //                     }}>
  //                         OK
  //                     </Button>
  //                 </div>
  //             </Modal>
  //
  //             {/*Success Edit Terminals*/}
  //             <Modal open={successOpen} onClose={handleSuccessClose} aria-labelledby="modal-modal-title"
  //                    aria-describedby="modal-modal-description" className="container-fluid">
  //                 <Box sx={SuccessModalStyle}>
  //                     <img src={success} alt="Success" style={{
  //                         width : "135.2px" ,
  //                         height : "135.2px" ,
  //                         margin : "0 124.8px 37.8px 124px" ,
  //                         objectFit : "contain"
  //                     }}/>
  //                     <Box>
  //                         <label style={{
  //                             margin : "37.8px 0 0" ,
  //                             fontFamily : "Poppins" ,
  //                             fontSize : "25px" ,
  //                             fontWeight : "normal" ,
  //                             fontStretch : "normal" ,
  //                             fontStyle : "normal" ,
  //                             lineHeight : "1.2" ,
  //                             letterSpacing : "normal" ,
  //                             textAlign : "center" ,
  //                             color : "#6e7074"
  //                         }}>
  //                             Terminal Edit Success!
  //                         </label>
  //                     </Box>
  //                     <Box>
  //                         <Button onClick={() => {
  //                             handleSuccessClose()
  //                             window.location.reload(true)
  //                         }} sx={{
  //                             fontSize : "25px" ,
  //                             fontWeight : "600" ,
  //                             color : '#fff' ,
  //                             width : "124.5px" ,
  //                             height : "52.3px" ,
  //                             margin : "21px 129.5px 0 130px" ,
  //                             padding : "8px 44.5px 9.3px 44px" ,
  //                             backgroundColor : "#032541"
  //                         }}>
  //                             OK
  //                         </Button>
  //                     </Box>
  //                 </Box>
  //             </Modal>
  //
  //             {/*Action Button Menu*/}
  //             <Button
  //                 sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
  //                 aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
  //                 aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
  //                 onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
  //             </Button>
  //             <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
  //                         anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
  //
  //                 {/*Conditional Modal Popups*/}
  //                 {/*Check if is on Active Tab*/}
  //                 <MenuItem disableRipple onClick={() => {
  //                     handleClickOpen()
  //                     handleActionClose()
  //                 }}>
  //                     <EditIcon sx={{color : 'black'}}/>
  //                     Edit
  //                 </MenuItem>
  //                 <MenuItem onClick={() => {
  //                     DeactivateModalHandleOpen()
  //                     handleActionClose()
  //                 }} disableRipple>
  //                     <PersonRemoveIcon/>
  //                     Deactivate
  //                 </MenuItem>
  //             </StyledMenu>
  //         </div>)
  //     }
  // })
} else if (localStorage.getItem("group") === "Admin") {
  newColumns.push({
    field: "Actions",
    hide: false,
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Edit Terminal Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Deactivate Terminal Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
      const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

      // Status Updates Modals
      // Success Edit Modal Control
      const [successOpen, setSuccessOpen] = React.useState(false);
      const handleSuccessOpen = () => setSuccessOpen(true);
      const handleSuccessClose = () => setSuccessOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
      const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

      // Data Getters and Setters
      const [serialNo, setSerialNo] = useState(row.terminalSerialNumber);
      const [model, setModel] = useState(row.deviceModel);
      const [shortCode, setShortCode] = useState(row.till_paybill);
      const [businessId, setBusinessId] = useState(row.businessID);

      //Terminal Update Url
      const updateUrl = baseUrl + "/api/updateTerminal/?_id=" + row.id;
      const deleteUrl = baseUrl + "/api/deleteTerminal/?_id=" + row.id;

      // Activate Groups Update Function
      const activateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate terminals Update Function
      const deactivateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              dateDeactivated: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

          {/*Activate Terminal Modal*/}
          <QuestionModal
            deactivateModalOpen={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            formAction={activateTerminalUpdate}
            actionImage={activateGroup}
            action="Activate"
            item={row.terminalSerialNumber}
            processName={"Activate"}
          />

          {/*Success Activation Modal*/}
          <SuccessModal
            onClose={successActivationModalHandleClose}
            editConfigOpen={successActivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Activated"}
          />

          {/*Deactivate Terminal Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateTerminalUpdate}
            actionImage={deactivateTerminal}
            action="Deactivate"
            item={row.terminalSerialNumber}
            processName={"Deactivate"}
          />

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Deactivated"}
          />

          {/*Success Edit Terminals*/}
          <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={row.terminalSerialNumber} successObject={"Terminal"} successType={"Activated"} />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>

          <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            {/*Conditional Modal Popups*/}
            {/*Check if is on Active Tab*/}
            <MenuItem
              disableRipple
              onClick={() => {
                ActivateModalHandleOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Approve
            </MenuItem>
            <MenuItem
              onClick={() => {
                DeactivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Decline
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  });
}

// Edited Columns
const editedColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "terminalSerialNumber",
    headerName: "Terminal Serial Number",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "deviceModel",
    headerName: "Device model",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "till_paybill",
    headerName: "Short Code",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessID",
    headerName: "Business ID",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "modifiedBy",
    headerName: "Edited By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "dateModified",
    headerName: "Date Modified",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
];

if (localStorage.getItem("group") === "Merchant") {
  // editedColumns.push({
  //     field : 'Actions' ,
  //     hide : true ,
  //     headerName : 'Actions' ,
  //     flex : 1 ,
  //     headerAlign : 'center' ,
  //     align : 'center' ,
  //     editable : false ,
  //     renderCell : (params , event) => {
  //
  //         //DropDown Handler
  //         const [anchorEl , setAnchorEl] = React.useState(null);
  //         const ActionOpen = Boolean(anchorEl);
  //         const handleClick = (event) => {
  //             setAnchorEl(event.currentTarget);
  //         };
  //         const handleActionClose = () => {
  //             setAnchorEl(null);
  //         };
  //
  //         const {row} = params
  // // session Modal Config
  // const [sessionOpen, setSessionOpen] = React.useState(false);
  // const handleSessionOpen = () => setSessionOpen(true);
  // const handleSessionClose = () => setSessionOpen(false);
  // const handleLogin = () => {window.location.href='/logout'}
  //
  //         // Edit Terminal Modal Config
  //         const [open , setOpen] = useState(false);
  //         const handleClickOpen = () => setOpen(true);
  //         const handleClose = () => setOpen(false);
  //
  //         // Deactivate Terminal Modal Config
  //         const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
  //         const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
  //         const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);
  //
  //         // Status Updates Modals
  //         // Success Edit Modal Control
  //         const [successOpen , setSuccessOpen] = React.useState(false);
  //         const handleSuccessOpen = () => setSuccessOpen(true);
  //         const handleSuccessClose = () => setSuccessOpen(false);
  //
  //         // Success Deactivation Modal Config
  //         const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
  //         const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
  //         const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);
  //
  //         // Data Getters and Setters
  //         const [serialNo , setSerialNo] = useState(row.terminalSerialNumber)
  //         const [model , setModel] = useState(row.deviceModel)
  //         const [shortCode , setShortCode] = useState(row.till_paybill)
  //         const [businessId , setBusinessId] = useState(row.businessID)
  //
  //         //Terminal Update Url
  //         const updateUrl = baseUrl + '/api/updateTerminal/?_id=' + row.id
  //         const deleteUrl = baseUrl + '/api/deleteTerminal/' + row.id
  //
  //         //Update terminal Function
  //         const updateTerminal = async (e) => {
  //             e.preventDefault()
  //             const editResponse = await fetch(updateUrl , {
  //
  //                 method : 'PUT' , headers : {
  //                     'Content-Type' : 'application/json' ,
  //                     'Accept' : 'application/json' ,
  //                     'X-Authorization' : localStorage.getItem('X-Authorization')
  //                 } , body : JSON.stringify({
  //                     terminalSerialNumber : serialNo ,
  //                     till_paybill : shortCode ,
  //                     businessID : businessId ,
  //                     deviceModel : model ,
  //                     requestedBy : localStorage.getItem('username') ,
  //                     dateModified : Date.now()
  //                 })
  //             });
  //             const data = await editResponse.json()
  //             if (data.Status === "SUCCESS") {
  //                 handleClose()
  //                 handleSuccessOpen()
  //             } else if (editResponse.status === 401) {
  //                handleSessionOpen()
  //
  //             }
  //         }
  //
  //         // Deactivate terminals Update Function
  //         const deactivateTerminalUpdate = async (e) => {
  //             e.preventDefault()
  //             try {
  //                 const deactivateResponse = await fetch(updateUrl , {
  //                     method : 'PUT' , headers : {
  //                         'Content-Type' : 'application/json' ,
  //                         'Accept' : 'application/json' ,
  //                         'X-Authorization' : localStorage.getItem('X-Authorization')
  //                     } , body : JSON.stringify({
  //                         terminalStatus : "Deactivation" ,
  //                         requestedBy : localStorage.getItem('username') ,
  //                         dateDeactivated : Date.now() ,
  //                         approvedBy : localStorage.getItem('username')
  //                     })
  //                 })
  //                 if (deactivateResponse.status === 202) {
  //                     DeactivateModalHandleClose()
  //                     successDeactivationModalHandleOpen()
  //                 } else if (deactivateResponse.status === 401) {
  //                    handleSessionOpen()
  //                 }
  //             } catch (e) {
  //                 console.log(e)
  //             }
  //         }
  //
  //         return (<div>
  //
  // {/*session expired modal*/}
  // <Modal
  //     open={sessionOpen} onClose={handleSessionClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //     <Box sx={style}>
  //         <div style={{width: "207px", height: "206px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
  //             <img src={session}/>
  //         </div>
  //         <div style={{width: "506px", height: "89px", margin: "26px 0 3px 47px", padding: "35px 2px 9px 16px", fontFamily: "Poppins", fontSize: "30px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
  //             <span>
  //                 Your Session has expired and you have been logged out.
  //             </span>
  //         </div>
  //         <div style={{width: "500px", height: "61.7px", margin: "65px 52.9px 0 75px"}}>
  //             <Button onClick={handleSessionClose} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#032541",width: "146.8px", height: "61.7px", margin: "0 62.2px 0 0", padding: "19.1px 44.6px 18.5px 41.1px", border: "solid 1px #dc3545"}}>
  //                 Cancel
  //             </Button>
  //             <Button onClick={handleLogin} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#fff",width: "190.1px", height: "61.7px", margin: "0 0 0 62.2px", padding: "19px 32.1px 18.7px 33px", backgroundColor: "#032541"}} >
  //                 Go to Login
  //             </Button>
  //         </div>
  //     </Box>
  // </Modal>
  //             {/*Edit terminal Modal*/}
  //             <Modal className='container container-fluid' open={open} onClose={handleClose}
  //                    aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "553px" ,
  //                     height : "618px" ,
  //                     margin : "9% auto 0px auto" ,
  //                     borderRadius : "15px" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <div style={{
  //                         width : "553x" ,
  //                         height : "90px" ,
  //                         margin : "0 0 9px" ,
  //                         padding : "36px 30px 33px" ,
  //                         borderRadius : "16px" ,
  //                         backgroundColor : "#dc3545"
  //                     }}>
  //                         <label>
  //                             Edit a Terminal
  //                         </label>
  //                     </div>
  //                     {/*Content Group and Form*/}
  //                     <div align='center'
  //                          style={{width : "404px" , height : "414.1px" , margin : "29px 60px 41.9px 70px"}}>
  //                         <form onSubmit={updateTerminal}>
  //                             {/*Serial No.*/}
  //                             <label style={{
  //                                 height : "20px" ,
  //                                 margin : "0 340px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Serial No.
  //                             </label>
  //                             <input value={serialNo} onChange={(e) => setSerialNo(e.target.value)}
  //                                    placeholder="Serial" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*POS Model*/}
  //                             <label style={{
  //                                 width : "100px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 328px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 POS Model.
  //                             </label>
  //                             <input value={model} onChange={(e) => setModel(e.target.value)} placeholder="Model"
  //                                    style={{
  //                                        width : "404px" ,
  //                                        height : "39px" ,
  //                                        margin : "10px 0 0" ,
  //                                        objectFit : "contain" ,
  //                                        borderRadius : "4px" ,
  //                                        border : "solid 1px #dfdede" ,
  //                                        backgroundColor : "#fff"
  //                                    }}/>
  //
  //                             {/*PayBill*/}
  //                             <label style={{
  //                                 width : "110px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 304px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Pay Bill/Till No.
  //                             </label>
  //                             <input value={shortCode} onChange={(e) => setShortCode(e.target.value)}
  //                                    placeholder="ShortCode" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*Business ID*/}
  //                             <label style={{
  //                                 width : "78px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 324px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Business ID
  //                             </label>
  //                             <input value={businessId} onChange={(e) => setBusinessId(e.target.value)}
  //                                    placeholder="Business ID" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*Buttons*/}
  //                             <div align='right'>
  //                                 {/*  Close  */}
  //                                 <Button onClick={handleClose} variant='outlined' style={{
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     textAlign : "left" ,
  //                                     color : "#032541" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 30px 0 0" ,
  //                                     padding : "9px 29px 8.1px 30px" ,
  //                                     border : "solid 1px #032541"
  //                                 }}>
  //                                     Close
  //                                 </Button>
  //                                 {/*  save  */}
  //                                 <Button type='submit' variant='standard' style={{
  //                                     color : '#fff' ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 0 0 30px" ,
  //                                     padding : "9px 32px 8.1px 31px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     Save
  //                                 </Button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                 </div>
  //             </Modal>
  //
  //             {/*Deactivate Terminal Modal*/}
  //             <Modal centered align={'center'} className='container container-fluid' open={deactivateModalOpen}
  //                    onClose={DeactivateModalHandleClose} aria-labelledby="modal-modal-title"
  //                    aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "553px" ,
  //                     height : "306px" ,
  //                     margin : "10% 23px 8px 39px" ,
  //                     padding : "0 0 57.9px" ,
  //                     borderRadius : "15px" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <div style={{
  //                         width : "553px" ,
  //                         height : "90px" ,
  //                         margin : "0 0 50px" ,
  //                         padding : "35px 36px" ,
  //                         borderRadius : "16px" ,
  //                         backgroundColor : "#dc3545"
  //                     }}>
  //                         <label style={{
  //                             height : "20px" ,
  //                             margin : "0 325px 0 0" ,
  //                             fontFamily : "Poppins" ,
  //                             fontSize : "14px" ,
  //                             fontWeight : "600" ,
  //                             fontStretch : "normal" ,
  //                             fontStyle : "normal" ,
  //                             lineHeight : "normal" ,
  //                             letterSpacing : "normal" ,
  //                             textAlign : "left" ,
  //                             color : "#fff"
  //                         }}>
  //                             Deactivate Terminal
  //                         </label>
  //                     </div>
  //                     <div>
  //                         <form onSubmit={deactivateTerminalUpdate}>
  //                             <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}
  //                                  src={deactivateTerminal} alt={'activate terminal'}/>
  //                             <label style={{
  //                                 height : "24px" ,
  //                                 margin : "0 1.1px 0 14px" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "17px" ,
  //                                 fontWeight : "500" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "1.47" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#032541"
  //                             }}>
  //                                 Do you want to Deactivate <b>{row.terminalSerialNumber}?</b>
  //                             </label>
  //                             <div align='right'>
  //                                 <Button onClick={DeactivateModalHandleClose} variant='outlined' style={{
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     textAlign : "left" ,
  //                                     color : "#dc3545" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 30px 0 0" ,
  //                                     padding : "9px 29px 8.1px 30px" ,
  //                                     border : "solid 1px #dc3545"
  //                                 }}>
  //                                     Cancel
  //                                 </Button>
  //                                 {/*  save  */}
  //                                 <Button type='submit' variant='standard' style={{
  //                                     color : '#fff' ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 16px 0 30px" ,
  //                                     padding : "9px 32px 8.1px 31px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     Deactivate
  //                                 </Button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                 </div>
  //             </Modal>
  //
  //             {/*Status Update Modals*/}
  //             {/*Success Deactivation Modal*/}
  //             <Modal centered align={'center'} className='container container-fluid'
  //                    open={successDeactivationModalOpen} onClose={successDeactivationModalHandleClose}
  //                    aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "506px" ,
  //                     height : "506px" ,
  //                     margin : "10% 16px 23px 45px" ,
  //                     padding : "87px 75px 92.7px 34px" ,
  //                     borderRadius : "36px" ,
  //                     boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <img src={success} alt={'success deactivation'}/>
  //                     <label style={{
  //                         width : "356px" ,
  //                         height : "65px" ,
  //                         margin : "37.8px 0 0" ,
  //                         fontFamily : "Poppins" ,
  //                         fontSize : "25px" ,
  //                         fontWeight : "normal" ,
  //                         fontStretch : "normal" ,
  //                         fontStyle : "normal" ,
  //                         lineHeight : "1.2" ,
  //                         letterSpacing : "normal" ,
  //                         textAlign : "center" ,
  //                         color : "#6e7074"
  //                     }}>
  //                         <b>{row.terminalSerialNumber}</b> Deactivated
  //                         successfully.
  //                     </label>
  //                     <Button onClick={() => {
  //                         successDeactivationModalHandleClose()
  //                         window.location.reload(true)
  //                     }} style={{
  //                         fontFamily : "Poppins" ,
  //                         fontSize : "25px" ,
  //                         fontWeight : "600" ,
  //                         color : "#fff" ,
  //                         width : "124.5px" ,
  //                         height : "52.3px" ,
  //                         padding : "8px 44.5px 9.3px 35px" ,
  //                         backgroundColor : "#032541"
  //                     }}>
  //                         OK
  //                     </Button>
  //                 </div>
  //             </Modal>
  //
  //             {/*Success Edit Terminals*/}
  //             <Modal open={successOpen} onClose={handleSuccessClose} aria-labelledby="modal-modal-title"
  //                    aria-describedby="modal-modal-description" className="container-fluid">
  //                 <Box sx={SuccessModalStyle}>
  //                     <img src={success} alt="Success" style={{
  //                         width : "135.2px" ,
  //                         height : "135.2px" ,
  //                         margin : "0 124.8px 37.8px 124px" ,
  //                         objectFit : "contain"
  //                     }}/>
  //                     <Box>
  //                         <label style={{
  //                             margin : "37.8px 0 0" ,
  //                             fontFamily : "Poppins" ,
  //                             fontSize : "25px" ,
  //                             fontWeight : "normal" ,
  //                             fontStretch : "normal" ,
  //                             fontStyle : "normal" ,
  //                             lineHeight : "1.2" ,
  //                             letterSpacing : "normal" ,
  //                             textAlign : "center" ,
  //                             color : "#6e7074"
  //                         }}>
  //                             Terminal Edit Success!
  //                         </label>
  //                     </Box>
  //                     <Box>
  //                         <Button onClick={() => {
  //                             handleSuccessClose()
  //                             window.location.reload(true)
  //                         }} sx={{
  //                             fontSize : "25px" ,
  //                             fontWeight : "600" ,
  //                             color : '#fff' ,
  //                             width : "124.5px" ,
  //                             height : "52.3px" ,
  //                             margin : "21px 129.5px 0 130px" ,
  //                             padding : "8px 44.5px 9.3px 44px" ,
  //                             backgroundColor : "#032541"
  //                         }}>
  //                             OK
  //                         </Button>
  //                     </Box>
  //                 </Box>
  //             </Modal>
  //
  //             {/*Action Button Menu*/}
  //             <Button
  //                 sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
  //                 aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
  //                 aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
  //                 onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
  //             </Button>
  //             <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
  //                         anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
  //
  //                 {/*Conditional Modal Popups*/}
  //                 {/*Check if is on Active Tab*/}
  //                 <MenuItem disableRipple onClick={() => {
  //                     handleClickOpen()
  //                     handleActionClose()
  //                 }}>
  //                     <EditIcon sx={{color : 'black'}}/>
  //                     Edit
  //                 </MenuItem>
  //                 <MenuItem onClick={() => {
  //                     DeactivateModalHandleOpen()
  //                     handleActionClose()
  //                 }} disableRipple>
  //                     <PersonRemoveIcon/>
  //                     Deactivate
  //                 </MenuItem>
  //             </StyledMenu>
  //         </div>)
  //     }
  // })
} else if (localStorage.getItem("group") === "Admin") {
  editedColumns.push({
    field: "Actions",
    hide: false,
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Edit Terminal Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      // Deactivate Terminal Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // Status Updates Modals
      // Success Edit Modal Control
      const [successOpen, setSuccessOpen] = React.useState(false);
      const handleSuccessOpen = () => setSuccessOpen(true);
      const handleSuccessClose = () => setSuccessOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
      const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

      // Data Getters and Setters
      const [serialNo, setSerialNo] = useState(row.terminalSerialNumber);
      const [model, setModel] = useState(row.deviceModel);
      const [shortCode, setShortCode] = useState(row.till_paybill);
      const [businessId, setBusinessId] = useState(row.businessID);

      //Terminal Update Url
      const updateUrl = baseUrl + "/api/updateTerminal/?_id=" + row.id;
      const deleteUrl = baseUrl + "/api/deleteTerminal/" + row.id;

      //Update terminal Function
      const updateTerminal = async (e) => {
        e.preventDefault();
        const editResponse = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            terminalSerialNumber: serialNo,
            till_paybill: shortCode,
            businessID: businessId,
            deviceModel: model,
            requestedBy: localStorage.getItem("username"),
            dateModified: Date.now(),
          }),
        });
        const data = await editResponse.json();
        if (data.Status === "SUCCESS") {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      // Deactivate terminals Update Function
      const deactivateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              dateDeactivated: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

          {/*Edit terminal Modal*/}
          <Modal className="container container-fluid" open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Edit a Terminal</label>
              </div>
              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form onSubmit={updateTerminal}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>

                  <input
                    value={serialNo}
                    onChange={(e) => setSerialNo(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS Model*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS Model.
                  </label>

                  <input
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    placeholder="Model"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*PayBill*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Pay Bill/Till No.
                  </label>

                  <input
                    value={shortCode}
                    onChange={(e) => setShortCode(e.target.value)}
                    placeholder="ShortCode"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Business ID*/}
                  <label
                    style={{
                      width: "78px",
                      height: "20px",
                      margin: "0 324px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Business ID
                  </label>

                  <input
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                    placeholder="Business ID"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Close  */}
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Close
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Deactivate Terminal Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "553px",
                height: "306px",
                margin: "10% 23px 8px 39px",
                padding: "0 0 57.9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553px",
                  height: "90px",
                  margin: "0 0 50px",
                  padding: "35px 36px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Deactivate Terminal
                </label>
              </div>
              <div>
                <form onSubmit={deactivateTerminalUpdate}>
                  <img
                    style={{
                      width: "70.4px",
                      height: "70.4px",
                      objectFit: "contain",
                    }}
                    src={deactivateTerminal}
                    alt={"activate terminal"}
                  />
                  <label
                    style={{
                      height: "24px",
                      margin: "0 1.1px 0 14px",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.47",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Do you want to Deactivate <b>{row.terminalSerialNumber}?</b>
                  </label>
                  <div align="right">
                    <Button
                      onClick={DeactivateModalHandleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#dc3545",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #dc3545",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 16px 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Deactivate
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={successDeactivationModalOpen}
            onClose={successDeactivationModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "506px",
                height: "506px",
                margin: "10% 16px 23px 45px",
                padding: "87px 75px 92.7px 34px",
                borderRadius: "36px",
                boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                backgroundColor: "#fff",
              }}
            >
              <img src={success} alt={"success deactivation"} />
              <label
                style={{
                  width: "356px",
                  height: "65px",
                  margin: "37.8px 0 0",
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.2",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#6e7074",
                }}
              >
                <b>{row.terminalSerialNumber}</b> Deactivated successfully.
              </label>
              <Button
                onClick={() => {
                  successDeactivationModalHandleClose();
                  window.location.reload(true);
                }}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "#fff",
                  width: "124.5px",
                  height: "52.3px",
                  padding: "8px 44.5px 9.3px 35px",
                  backgroundColor: "#032541",
                }}
              >
                OK
              </Button>
            </div>
          </Modal>

          {/*Success Edit Terminals*/}
          <Modal open={successOpen} onClose={handleSuccessClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="container-fluid">
            <Box sx={SuccessModalStyle}>
              <img
                src={success}
                alt="Success"
                style={{
                  width: "135.2px",
                  height: "135.2px",
                  margin: "0 124.8px 37.8px 124px",
                  objectFit: "contain",
                }}
              />
              <Box>
                <label
                  style={{
                    margin: "37.8px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.2",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#6e7074",
                  }}
                >
                  Terminal Edit Success!
                </label>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    handleSuccessClose();
                    window.location.reload(true);
                  }}
                  sx={{
                    fontSize: "25px",
                    fontWeight: "600",
                    color: "#fff",
                    width: "124.5px",
                    height: "52.3px",
                    margin: "21px 129.5px 0 130px",
                    padding: "8px 44.5px 9.3px 44px",
                    backgroundColor: "#032541",
                  }}
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Modal>

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            {/*Conditional Modal Popups*/}
            {/*Check if is on Active Tab*/}
            <MenuItem
              disableRipple
              onClick={() => {
                handleClickOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                DeactivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Deactivate
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  });
}

// Deactivation request Columns
const deactivationColumns = [
  {
    field: "terminalSerialNumber",
    headerName: "Terminal Serial Number",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "deviceModel",
    headerName: "Device model",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "till_paybill",
    headerName: "Short Code",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessID",
    headerName: "Business ID",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "requestedBy",
    headerName: "Requested By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "dateDeactivated",
    headerName: "Date Deactivated",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
];

if (localStorage.getItem("group") === "Merchant") {
  // deactivationColumns.push({
  //     field : 'Actions' ,
  //     hide : true ,
  //     headerName : 'Actions' ,
  //     flex : 1 ,
  //     headerAlign : 'center' ,
  //     align : 'center' ,
  //     editable : false ,
  //     renderCell : (params , event) => {
  //
  //         //DropDown Handler
  //         const [anchorEl , setAnchorEl] = React.useState(null);
  //         const ActionOpen = Boolean(anchorEl);
  //         const handleClick = (event) => {
  //             setAnchorEl(event.currentTarget);
  //         };
  //         const handleActionClose = () => {
  //             setAnchorEl(null);
  //         };
  //
  //         const {row} = params
  // // session Modal Config
  // const [sessionOpen, setSessionOpen] = React.useState(false);
  // const handleSessionOpen = () => setSessionOpen(true);
  // const handleSessionClose = () => setSessionOpen(false);
  // const handleLogin = () => {window.location.href='/logout'}
  //     //
  //         // Edit Terminal Modal Config
  //         const [open , setOpen] = useState(false);
  //         const handleClickOpen = () => setOpen(true);
  //         const handleClose = () => setOpen(false);
  //
  //         // Deactivate Terminal Modal Config
  //         const [deactivateModalOpen , setDeactivateModalOpen] = useState(false);
  //         const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
  //         const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);
  //
  //         // Status Updates Modals
  //         // Success Edit Modal Control
  //         const [successOpen , setSuccessOpen] = React.useState(false);
  //         const handleSuccessOpen = () => setSuccessOpen(true);
  //         const handleSuccessClose = () => setSuccessOpen(false);
  //
  //         // Success Deactivation Modal Config
  //         const [successDeactivationModalOpen , setSuccessDeactivationModalOpen] = useState(false);
  //         const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
  //         const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);
  //
  //         // Data Getters and Setters
  //         const [serialNo , setSerialNo] = useState(row.terminalSerialNumber)
  //         const [model , setModel] = useState(row.deviceModel)
  //         const [shortCode , setShortCode] = useState(row.till_paybill)
  //         const [businessId , setBusinessId] = useState(row.businessID)
  //
  //         //Terminal Update Url
  //         const updateUrl = baseUrl + '/api/updateTerminal/?_id=' + row.id
  //         const deleteUrl = baseUrl + '/api/deleteTerminal/' + row.id
  //
  //         //Update terminal Function
  //         const updateTerminal = async (e) => {
  //             e.preventDefault()
  //             const editResponse = await fetch(updateUrl , {
  //
  //                 method : 'PUT' , headers : {
  //                     'Content-Type' : 'application/json' ,
  //                     'Accept' : 'application/json' ,
  //                     'X-Authorization' : localStorage.getItem('X-Authorization')
  //                 } , body : JSON.stringify({
  //                     terminalSerialNumber : serialNo ,
  //                     till_paybill : shortCode ,
  //                     businessID : businessId ,
  //                     deviceModel : model ,
  //                     requestedBy : localStorage.getItem('username') ,
  //                     dateModified : Date.now()
  //                 })
  //             });
  //             const data = await editResponse.json()
  //             if (data.Status === "SUCCESS") {
  //                 handleClose()
  //                 handleSuccessOpen()
  //             } else if (editResponse.status === 401) {
  //                handleSessionOpen()
  //
  //             }
  //         }
  //
  //         // Deactivate terminals Update Function
  //         const deactivateTerminalUpdate = async (e) => {
  //             e.preventDefault()
  //             try {
  //                 const deactivateResponse = await fetch(updateUrl , {
  //                     method : 'PUT' , headers : {
  //                         'Content-Type' : 'application/json' ,
  //                         'Accept' : 'application/json' ,
  //                         'X-Authorization' : localStorage.getItem('X-Authorization')
  //                     } , body : JSON.stringify({
  //                         terminalStatus : "Deactivation" ,
  //                         requestedBy : localStorage.getItem('username') ,
  //                         dateDeactivated : Date.now() ,
  //                         approvedBy : localStorage.getItem('username')
  //                     })
  //                 })
  //                 if (deactivateResponse.status === 202) {
  //                     DeactivateModalHandleClose()
  //                     successDeactivationModalHandleOpen()
  //                 } else if (deactivateResponse.status === 401) {
  //                    handleSessionOpen()
  //                 }
  //             } catch (e) {
  //                 console.log(e)
  //             }
  //         }
  //
  //         return (<div>
  // {/*session expired modal*/}
  // <Modal
  //     open={sessionOpen} onClose={handleSessionClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //     <Box sx={style}>
  //         <div style={{width: "207px", height: "206px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
  //             <img src={session}/>
  //         </div>
  //         <div style={{width: "506px", height: "89px", margin: "26px 0 3px 47px", padding: "35px 2px 9px 16px", fontFamily: "Poppins", fontSize: "30px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
  //             <span>
  //                 Your Session has expired and you have been logged out.
  //             </span>
  //         </div>
  //         <div style={{width: "500px", height: "61.7px", margin: "65px 52.9px 0 75px"}}>
  //             <Button onClick={handleSessionClose} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#032541",width: "146.8px", height: "61.7px", margin: "0 62.2px 0 0", padding: "19.1px 44.6px 18.5px 41.1px", border: "solid 1px #dc3545"}}>
  //                 Cancel
  //             </Button>
  //             <Button onClick={handleLogin} sx={{fontFamily: "Poppins", fontSize: "17px", fontWeight: "600", color: "#fff",width: "190.1px", height: "61.7px", margin: "0 0 0 62.2px", padding: "19px 32.1px 18.7px 33px", backgroundColor: "#032541"}} >
  //                 Go to Login
  //             </Button>
  //         </div>
  //     </Box>
  // </Modal>
  //             {/*Edit terminal Modal*/}
  //             <Modal className='container container-fluid' open={open} onClose={handleClose}
  //                    aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "553px" ,
  //                     height : "618px" ,
  //                     margin : "9% auto 0px auto" ,
  //                     borderRadius : "15px" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <div style={{
  //                         width : "553x" ,
  //                         height : "90px" ,
  //                         margin : "0 0 9px" ,
  //                         padding : "36px 30px 33px" ,
  //                         borderRadius : "16px" ,
  //                         backgroundColor : "#dc3545"
  //                     }}>
  //                         <label>
  //                             Edit a Terminal
  //                         </label>
  //                     </div>
  //                     {/*Success Edit Terminals*/}
  //                     <Modal open={successOpen} onClose={handleSuccessClose} aria-labelledby="modal-modal-title"
  //                            aria-describedby="modal-modal-description" className="container-fluid">
  //                         <Box sx={SuccessModalStyle}>
  //                             <img src={success} alt="Success" style={{
  //                                 width : "135.2px" ,
  //                                 height : "135.2px" ,
  //                                 margin : "0 124.8px 37.8px 124px" ,
  //                                 objectFit : "contain"
  //                             }}/>
  //                             <Box>
  //                                 <label style={{
  //                                     margin : "37.8px 0 0" ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "25px" ,
  //                                     fontWeight : "normal" ,
  //                                     fontStretch : "normal" ,
  //                                     fontStyle : "normal" ,
  //                                     lineHeight : "1.2" ,
  //                                     letterSpacing : "normal" ,
  //                                     textAlign : "center" ,
  //                                     color : "#6e7074"
  //                                 }}>
  //                                     Terminal Addition Success!
  //                                 </label>
  //                             </Box>
  //                             <Box>
  //                                 <Button onClick={() => {
  //                                     handleSuccessClose()
  //                                     window.location.reload(true)
  //                                 }} sx={{
  //                                     fontSize : "25px" ,
  //                                     fontWeight : "600" ,
  //                                     color : '#fff' ,
  //                                     width : "124.5px" ,
  //                                     height : "52.3px" ,
  //                                     margin : "21px 129.5px 0 130px" ,
  //                                     padding : "8px 44.5px 9.3px 44px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     OK
  //                                 </Button>
  //                             </Box>
  //                         </Box>
  //                     </Modal>
  //                     {/*Content Group and Form*/}
  //                     <div align='center'
  //                          style={{width : "404px" , height : "414.1px" , margin : "29px 60px 41.9px 70px"}}>
  //                         <form onSubmit={updateTerminal}>
  //                             {/*Serial No.*/}
  //                             <label style={{
  //                                 height : "20px" ,
  //                                 margin : "0 340px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Serial No.
  //                             </label>
  //                             <input value={serialNo} onChange={(e) => setSerialNo(e.target.value)}
  //                                    placeholder="Serial" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*POS Model*/}
  //                             <label style={{
  //                                 width : "100px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 328px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 POS Model.
  //                             </label>
  //                             <input value={model} onChange={(e) => setModel(e.target.value)} placeholder="Model"
  //                                    style={{
  //                                        width : "404px" ,
  //                                        height : "39px" ,
  //                                        margin : "10px 0 0" ,
  //                                        objectFit : "contain" ,
  //                                        borderRadius : "4px" ,
  //                                        border : "solid 1px #dfdede" ,
  //                                        backgroundColor : "#fff"
  //                                    }}/>
  //
  //                             {/*PayBill*/}
  //                             <label style={{
  //                                 width : "110px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 304px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Pay Bill/Till No.
  //                             </label>
  //                             <input value={shortCode} onChange={(e) => setShortCode(e.target.value)}
  //                                    placeholder="ShortCode" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*Business ID*/}
  //                             <label style={{
  //                                 width : "78px" ,
  //                                 height : "20px" ,
  //                                 margin : "0 324px 10px 0" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "14px" ,
  //                                 fontWeight : "normal" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "3.14" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#6e7074"
  //                             }}>
  //                                 Business ID
  //                             </label>
  //                             <input value={businessId} onChange={(e) => setBusinessId(e.target.value)}
  //                                    placeholder="Business ID" style={{
  //                                 width : "404px" ,
  //                                 height : "39px" ,
  //                                 margin : "10px 0 0" ,
  //                                 objectFit : "contain" ,
  //                                 borderRadius : "4px" ,
  //                                 border : "solid 1px #dfdede" ,
  //                                 backgroundColor : "#fff"
  //                             }}/>
  //
  //                             {/*Buttons*/}
  //                             <div align='right'>
  //                                 {/*  Close  */}
  //                                 <Button onClick={handleClose} variant='outlined' style={{
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     textAlign : "left" ,
  //                                     color : "#032541" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 30px 0 0" ,
  //                                     padding : "9px 29px 8.1px 30px" ,
  //                                     border : "solid 1px #032541"
  //                                 }}>
  //                                     Close
  //                                 </Button>
  //                                 {/*  save  */}
  //                                 <Button type='submit' variant='standard' style={{
  //                                     color : '#fff' ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 0 0 30px" ,
  //                                     padding : "9px 32px 8.1px 31px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     Save
  //                                 </Button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                 </div>
  //             </Modal>
  //
  //             {/*Deactivate Terminal Modal*/}
  //             <Modal centered align={'center'} className='container container-fluid' open={deactivateModalOpen}
  //                    onClose={DeactivateModalHandleClose} aria-labelledby="modal-modal-title"
  //                    aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "553px" ,
  //                     height : "306px" ,
  //                     margin : "10% 23px 8px 39px" ,
  //                     padding : "0 0 57.9px" ,
  //                     borderRadius : "15px" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <div style={{
  //                         width : "553px" ,
  //                         height : "90px" ,
  //                         margin : "0 0 50px" ,
  //                         padding : "35px 36px" ,
  //                         borderRadius : "16px" ,
  //                         backgroundColor : "#dc3545"
  //                     }}>
  //                         <label style={{
  //                             height : "20px" ,
  //                             margin : "0 325px 0 0" ,
  //                             fontFamily : "Poppins" ,
  //                             fontSize : "14px" ,
  //                             fontWeight : "600" ,
  //                             fontStretch : "normal" ,
  //                             fontStyle : "normal" ,
  //                             lineHeight : "normal" ,
  //                             letterSpacing : "normal" ,
  //                             textAlign : "left" ,
  //                             color : "#fff"
  //                         }}>
  //                             Deactivate Terminal
  //                         </label>
  //                     </div>
  //                     <div>
  //                         <form onSubmit={deactivateTerminalUpdate}>
  //                             <img style={{width : "70.4px" , height : "70.4px" , objectFit : "contain"}}
  //                                  src={deactivateTerminal} alt={'activate terminal'}/>
  //                             <label style={{
  //                                 height : "24px" ,
  //                                 margin : "0 1.1px 0 14px" ,
  //                                 fontFamily : "Poppins" ,
  //                                 fontSize : "17px" ,
  //                                 fontWeight : "500" ,
  //                                 fontStretch : "normal" ,
  //                                 fontStyle : "normal" ,
  //                                 lineHeight : "1.47" ,
  //                                 letterSpacing : "normal" ,
  //                                 textAlign : "left" ,
  //                                 color : "#032541"
  //                             }}>
  //                                 Do you want to Deactivate <b>{row.terminalSerialNumber}?</b>
  //                             </label>
  //                             <div align='right'>
  //                                 <Button onClick={DeactivateModalHandleClose} variant='outlined' style={{
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     textAlign : "left" ,
  //                                     color : "#dc3545" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 30px 0 0" ,
  //                                     padding : "9px 29px 8.1px 30px" ,
  //                                     border : "solid 1px #dc3545"
  //                                 }}>
  //                                     Cancel
  //                                 </Button>
  //                                 {/*  save  */}
  //                                 <Button type='submit' variant='standard' style={{
  //                                     color : '#fff' ,
  //                                     fontFamily : "Poppins" ,
  //                                     fontSize : "11px" ,
  //                                     width : "90px" ,
  //                                     height : "33.1px" ,
  //                                     margin : "30px 16px 0 30px" ,
  //                                     padding : "9px 32px 8.1px 31px" ,
  //                                     backgroundColor : "#032541"
  //                                 }}>
  //                                     Deactivate
  //                                 </Button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                 </div>
  //             </Modal>
  //
  //             {/*Status Update Modals*/}
  //             {/*Success Deactivation Modal*/}
  //             <Modal centered align={'center'} className='container container-fluid'
  //                    open={successDeactivationModalOpen} onClose={successDeactivationModalHandleClose}
  //                    aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  //                 <div style={{
  //                     width : "506px" ,
  //                     height : "506px" ,
  //                     margin : "10% 16px 23px 45px" ,
  //                     padding : "87px 75px 92.7px 34px" ,
  //                     borderRadius : "36px" ,
  //                     boxShadow : "0 0 12px 0 rgba(120, 90, 236, 0.2)" ,
  //                     backgroundColor : "#fff"
  //                 }}>
  //                     <img src={success} alt={'success deactivation'}/>
  //                     <label style={{
  //                         width : "356px" ,
  //                         height : "65px" ,
  //                         margin : "37.8px 0 0" ,
  //                         fontFamily : "Poppins" ,
  //                         fontSize : "25px" ,
  //                         fontWeight : "normal" ,
  //                         fontStretch : "normal" ,
  //                         fontStyle : "normal" ,
  //                         lineHeight : "1.2" ,
  //                         letterSpacing : "normal" ,
  //                         textAlign : "center" ,
  //                         color : "#6e7074"
  //                     }}>
  //                         <b>{row.terminalSerialNumber}</b> Deactivated
  //                         successfully.
  //                     </label>
  //                     <Button onClick={() => {
  //                         successDeactivationModalHandleClose()
  //                         window.location.reload(true)
  //                     }} style={{
  //                         fontFamily : "Poppins" ,
  //                         fontSize : "25px" ,
  //                         fontWeight : "600" ,
  //                         color : "#fff" ,
  //                         width : "124.5px" ,
  //                         height : "52.3px" ,
  //                         padding : "8px 44.5px 9.3px 35px" ,
  //                         backgroundColor : "#032541"
  //                     }}>
  //                         OK
  //                     </Button>
  //                 </div>
  //             </Modal>
  //
  //             {/*Success Edit Terminals*/}
  //             <Modal open={successOpen} onClose={handleSuccessClose} aria-labelledby="modal-modal-title"
  //                    aria-describedby="modal-modal-description" className="container-fluid">
  //                 <Box sx={SuccessModalStyle}>
  //                     <img src={success} alt="Success" style={{
  //                         width : "135.2px" ,
  //                         height : "135.2px" ,
  //                         margin : "0 124.8px 37.8px 124px" ,
  //                         objectFit : "contain"
  //                     }}/>
  //                     <Box>
  //                         <label style={{
  //                             margin : "37.8px 0 0" ,
  //                             fontFamily : "Poppins" ,
  //                             fontSize : "25px" ,
  //                             fontWeight : "normal" ,
  //                             fontStretch : "normal" ,
  //                             fontStyle : "normal" ,
  //                             lineHeight : "1.2" ,
  //                             letterSpacing : "normal" ,
  //                             textAlign : "center" ,
  //                             color : "#6e7074"
  //                         }}>
  //                             Terminal Edit Success!
  //                         </label>
  //                     </Box>
  //                     <Box>
  //                         <Button onClick={() => {
  //                             handleSuccessClose()
  //                             window.location.reload(true)
  //                         }} sx={{
  //                             fontSize : "25px" ,
  //                             fontWeight : "600" ,
  //                             color : '#fff' ,
  //                             width : "124.5px" ,
  //                             height : "52.3px" ,
  //                             margin : "21px 129.5px 0 130px" ,
  //                             padding : "8px 44.5px 9.3px 44px" ,
  //                             backgroundColor : "#032541"
  //                         }}>
  //                             OK
  //                         </Button>
  //                     </Box>
  //                 </Box>
  //             </Modal>
  //
  //             {/*Action Button Menu*/}
  //             <Button
  //                 sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
  //                 aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
  //                 aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
  //                 onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
  //             </Button>
  //             <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
  //                         anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
  //
  //                 {/*Conditional Modal Popups*/}
  //                 {/*Check if is on Active Tab*/}
  //                 <MenuItem disableRipple onClick={() => {
  //                     handleClickOpen()
  //                     handleActionClose()
  //                 }}>
  //                     <EditIcon sx={{color : 'black'}}/>
  //                     Edit
  //                 </MenuItem>
  //                 <MenuItem onClick={() => {
  //                     DeactivateModalHandleOpen()
  //                     handleActionClose()
  //                 }} disableRipple>
  //                     <PersonRemoveIcon/>
  //                     Deactivate
  //                 </MenuItem>
  //             </StyledMenu>
  //         </div>)
  //     }
  // })
} else if (localStorage.getItem("group") === "Admin") {
  deactivationColumns.push({
    field: "Actions",
    hide: false,
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Edit Terminal Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      // Success Modal Control
      const [successOpen, setSuccessOpen] = React.useState(false);
      const handleSuccessOpen = () => setSuccessOpen(true);
      const handleSuccessClose = () => setSuccessOpen(false);

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Deactivate Group Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // DeactivateRequest Group Modal Config
      const [deactivateReqModalOpen, setDeactivateReqModalOpen] = useState(false);
      const DeactivateReqModalHandleOpen = () => setDeactivateReqModalOpen(true);
      const DeactivateReqModalHandleClose = () => setDeactivateReqModalOpen(false);

      // Status Updates Modals
      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
      const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] = useState(false);
      const successDeactivationModalHandleOpen = () => setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () => setSuccessDeactivationModalOpen(false);

      // Data Getters and Setters
      const [serialNo, setSerialNo] = useState(row.terminalSerialNumber);
      const [model, setModel] = useState(row.deviceModel);
      const [shortCode, setShortCode] = useState(row.till_paybill);
      const [businessId, setBusinessId] = useState(row.businessID);

      //Terminal Update Url
      const updateUrl = baseUrl + "/api/updateTerminal/?_id=" + row.id;
      const deleteUrl = baseUrl + "/api/deleteTerminal/" + row.id;

      //value Setters and Getters
      const [reasonData, setReasonData] = useState([]);
      const [reasonCode, setReasonCode] = useState("");
      const [reasonDescription, setReasonDescription] = useState("");

      //Reason Update Url
      const reasonUrl = baseUrl + "/api/reasons";

      // Getting Reason Codes
      const getReasonCodes = async () => {
        try {
          const response = await fetch(reasonUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          const data = await response.json();
          if (response.status === 200) {
            setReasonData(data.reasons);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };
      useEffect(() => {
        getReasonCodes();
      }, []);

      // Activate Groups Update Function
      const activateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate terminals Update Function
      const deactivateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              dateDeactivated: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };
      // Deactivate Request Groups Update Function
      const deactivateReqTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Inactive",
              deactivationCode: reasonCode,
              deactivationDescription: reasonDescription,
              deactivatedAt: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateReqModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

          {/*Activate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            formAction={activateTerminalUpdate}
            actionImage={activateGroup}
            action="Activate"
            item={row.terminalSerialNumber}
            processName={"Activate"}
          />

          {/*Deactivate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateTerminalUpdate}
            actionImage={deactivateGroup}
            action="Deactivate"
            item={row.terminalSerialNumber}
            processName={"Deactivate"}
          />

          {/*Deactivation Request Group Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={deactivateReqModalOpen}
            onClose={DeactivateReqModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "665px",
                height: "604px",
                margin: "10% 47px 5px 96px",
                padding: "0 0 9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "665px",
                  height: "90px",
                  margin: "0 0 3px",
                  padding: "35px 30px 34px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Deactivate Group User Group
                </label>
              </div>
              <form onSubmit={deactivateReqTerminalUpdate}>
                <div>
                  <div>
                    <label
                      style={{
                        eight: "44px",
                        margin: "48px 106px 37px 84px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      To deactivate Terminal <b>{row.terminalSerialNumber}</b>, Select the Reason and describe why you are deactivating.
                    </label>
                  </div>
                  <label
                    style={{
                      width: "41px",
                      height: "16px",
                      margin: "0px 537px 10px 84px",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "4",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Reason
                  </label>
                  <Select
                    value={reasonCode}
                    onChange={(e) => setReasonCode(e.target.value)}
                    defaultValue=""
                    id="grouped-select"
                    label="Grouping"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      textAlign: "left",
                      width: "496px",
                      height: "39px",
                      margin: "10px 85px 20px 84px",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                      value=""
                    >
                      <em>None</em>
                    </MenuItem>
                    {reasonData.map((reason) => (
                      <MenuItem
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                        key={reason.code}
                        value={reason.code}
                      >
                        {reason.code} {reason.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <label
                    style={{
                      width: "62px",
                      height: "16px",
                      margin: "0px 537px 10px 84px",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "4",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Description
                  </label>
                  <textarea
                    value={reasonDescription}
                    onChange={(e) => setReasonDescription(e.target.value)}
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    placeholder="Enter Description"
                    style={{
                      padding: "10px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      textAlign: "left",
                      width: "505px",
                      height: "106px",
                      margin: "10px 76px 43px 84px",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  ></textarea>
                  <div align="right">
                    <Button
                      onClick={DeactivateReqModalHandleClose}
                      variant="outlined"
                      style={{
                        fontSize: "11px",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "0px 30px 62.9px 13px",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        fontSize: "11px",
                        color: "#fff",
                        width: "90px",
                        height: "33.1px",
                        margin: "0px 76px 62.9px 30px",
                        padding: "9px 15px 8.1px 15px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      Deactivate
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Deactivated"}
          />

          {/*Success Activation Modal*/}
          <SuccessModal
            onClose={successActivationModalHandleClose}
            editConfigOpen={successActivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Activated"}
          />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            <MenuItem
              disableRipple
              onClick={() => {
                DeactivateReqModalHandleOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Approve
            </MenuItem>
            <MenuItem
              onClick={() => {
                ActivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Decline
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  });
}

// Inactive Columns
const inactiveColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "terminalSerialNumber",
    headerName: "Terminal Serial Number",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "deviceModel",
    headerName: "Device model",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "till_paybill",
    headerName: "Short Code",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessID",
    headerName: "Business ID",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "requestedBy",
    headerName: "Requested By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "dateDeactivated",
    headerName: "Date Deactivated",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "approvedBy",
    headerName: "Approved By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
];

if (localStorage.getItem("group") === "Merchant") {
  // inactiveColumns.push({
  //     field : 'Actions' ,
  //     hide : true ,
  //     headerName : 'Actions' ,
  //     flex : 1 ,
  //     headerAlign : 'center' ,
  //     align : 'center' ,
  //     editable : false ,
  //     renderCell : (params , event) => {
  //
  //         //DropDown Handler
  //         const [anchorEl , setAnchorEl] = React.useState(null);
  //         const ActionOpen = Boolean(anchorEl);
  //         const handleClick = (event) => {
  //             setAnchorEl(event.currentTarget);
  //         };
  //         const handleActionClose = () => {
  //             setAnchorEl(null);
  //         };
  //
  //         const {row} = params
  //
  //         // session Modal Config
  //         const [sessionOpen, setSessionOpen] = React.useState(false);
  //         const handleSessionOpen = () => setSessionOpen(true);
  //         const handleSessionClose = () => setSessionOpen(false);
  //         const handleLogin = () => {window.location.href='/logout'}
  //
  //         // Activate Group Modal Config
  //         const [ActivateModalOpen , setActivateModalOpen] = useState(false);
  //         const ActivateModalHandleOpen = () => setActivateModalOpen(true);
  //         const ActivateModalHandleClose = () => setActivateModalOpen(false);
  //
  //         // Delete Group Modal Config
  //         const [deleteModalOpen , setDeleteModalOpen] = useState(false);
  //         const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
  //         const DeleteModalHandleClose = () => setDeleteModalOpen(false);
  //
  //         // Status Updates Modals
  //         // Success Activation Modal Config
  //         const [successActivationModalOpen , setSuccessActivationModalOpen] = useState(false);
  //         const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
  //         const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);
  //
  //         // Success Deletion Modal Config
  //         const [successDeletionModalOpen , setSuccessDeletionModalOpen] = useState(false);
  //         const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
  //         const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);
  //
  //         //Terminal Update Url
  //         const updateUrl = baseUrl + '/api/updateTerminal/?_id=' + row.id
  //         const deleteUrl = baseUrl + '/api/deleteTerminal/' + row.id
  //
  //
  //         // Activate Groups Update Function
  //             const activateTerminalUpdate = async (e) => {
  //                 e.preventDefault()
  //                 try {
  //                     const activateResponse = await fetch(updateUrl , {
  //                         method : 'PUT' , headers : {
  //                             'Content-Type' : 'application/json' ,
  //                             'Accept' : 'application/json' ,
  //                             'X-Authorization' : localStorage.getItem('X-Authorization')
  //                         } , body : JSON.stringify({
  //                             terminalStatus : "Active" ,
  //                             modifiedAt : Date.now() ,
  //                             requestedBy : localStorage.getItem('username')
  //                         })
  //                     });
  //                     if (activateResponse.status === 202) {
  //                         ActivateModalHandleClose()
  //                         successActivationModalHandleOpen()
  //                     } else if (activateResponse.status === 401 ){
  //                        handleSessionOpen()
  //                     }
  //                 } catch (e) {
  //                     console.log(e)
  //                 }
  //             }
  //
  //         // Delete Groups Update Function
  //         const deleteTerminal = async (e) => {
  //             e.preventDefault()
  //             try {
  //                 const activateResponse = await fetch(updateUrl , {
  //                     method : 'PUT' , headers : {
  //                         'Content-Type' : 'application/json' ,
  //                         'Accept' : 'application/json' ,
  //                         'X-Authorization' : localStorage.getItem('X-Authorization')
  //                     } , body : JSON.stringify({
  //                         terminalStatus : "Active" ,
  //                         modifiedAt : Date.now() ,
  //                         requestedBy : localStorage.getItem('username')
  //                     })
  //                 });
  //                 if (activateResponse.status === 202) {
  //                     ActivateModalHandleClose()
  //                     successActivationModalHandleOpen()
  //                 } else if (activateResponse.status === 401 ){
  //                    handleSessionOpen()
  //                 }
  //             } catch (e) {
  //                 console.log(e)
  //             }
  //         }
  //
  //         return (<div>
  //
  //             {/*sessionModal*/}
  //             <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />
  //
  //             {/*Activate Group Modal*/}
  //             <QuestionModal deactivateModalOpen={ActivateModalOpen} onClose={ActivateModalHandleClose} formAction={activateTerminalUpdate} actionImage={activateGroup} action='Activate' item={row.terminalSerialNumber} processName={'Activate'}/>
  //
  //             {/*Delete Group Modal*/}
  //             <QuestionModal deactivateModalOpen={deleteModalOpen} onClose={DeleteModalHandleClose} formAction={deleteTerminal} actionImage={deactivateGroup} action='Delete' item={row.terminalSerialNumber} processName={'Delete'}/>
  //
  //             {/*Success Deletion Modal*/}
  //             <SuccessModal onClose={successDeletionModalHandleClose} editConfigOpen={successDeletionModalOpen}  modalRow={row.terminalSerialNumber} successObject={'Terminal'} successType={'Deleted'} />
  //
  //             {/*Success Activation Modal*/}
  //             <SuccessModal onClose={successActivationModalHandleClose} editConfigOpen={successActivationModalOpen}  modalRow={row.terminalSerialNumber} successObject={'Terminal'} successType={'Activated'} />
  //
  //             {/*Action Button Menu*/}
  //             <Button
  //                 sx={{backgroundColor : "#dc3545"}} id="demo-customized-button"
  //                 aria-controls={ActionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true"
  //                 aria-expanded={ActionOpen ? "true" : undefined} variant="contained" disableElevation
  //                 onClick={handleClick} endIcon={<KeyboardArrowDownIcon/>}>Action
  //             </Button>
  //             <StyledMenu id="demo-customized-menu" MenuListProps={{"aria-labelledby" : "demo-customized-button"}}
  //                         anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
  //
  //                 {/*Check if is on Inactive Tab*/}
  //                 <MenuItem disableRipple onClick={() => {
  //                     ActivateModalHandleOpen()
  //                     handleActionClose()
  //                 }}>
  //                     <EditIcon sx={{color : 'black'}}/>
  //                     Activate
  //                 </MenuItem>
  //                 <MenuItem onClick={() => {
  //                     DeleteModalHandleOpen()
  //                     handleActionClose()
  //                 }} disableRipple>
  //                     <PersonRemoveIcon/>
  //                     Delete
  //                 </MenuItem>
  //             </StyledMenu>
  //         </div>)
  //     }
  // })
} else if (localStorage.getItem("group") === "Admin") {
  inactiveColumns.push({
    field: "Actions",
    hide: false,
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Delete Group Modal Config
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
      const DeleteModalHandleClose = () => setDeleteModalOpen(false);

      // Status Updates Modals
      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] = useState(false);
      const successActivationModalHandleOpen = () => setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () => setSuccessActivationModalOpen(false);

      // Success Deletion Modal Config
      const [successDeletionModalOpen, setSuccessDeletionModalOpen] = useState(false);
      const successDeletionModalHandleOpen = () => setSuccessDeletionModalOpen(true);
      const successDeletionModalHandleClose = () => setSuccessDeletionModalOpen(false);

      //Terminal Update Url
      const updateUrl = baseUrl + "/api/updateTerminal/?_id=" + row.id;
      const deleteUrl = baseUrl + "/api/deleteTerminal/" + row.id;

      // Activate Groups Update Function
      const activateTerminalUpdate = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Delete Groups Update Function
      const deleteTerminal = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              terminalStatus: "Delete",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

          {/*Activate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            formAction={activateTerminalUpdate}
            actionImage={activateGroup}
            action="Activate"
            item={row.terminalSerialNumber}
            processName={"Activate"}
          />

          {/*Delete Group Modal*/}
          <QuestionModal
            deactivateModalOpen={deleteModalOpen}
            onClose={DeleteModalHandleClose}
            formAction={deleteTerminal}
            actionImage={deactivateGroup}
            action="Delete"
            item={row.terminalSerialNumber}
            processName={"Delete"}
          />

          {/*Success Deletion Modal*/}
          <SuccessModal onClose={successDeletionModalHandleClose} editConfigOpen={successDeletionModalOpen} modalRow={row.terminalSerialNumber} successObject={"Terminal"} successType={"Deleted"} />

          {/*Success Activation Modal*/}
          <SuccessModal
            onClose={successActivationModalHandleClose}
            editConfigOpen={successActivationModalOpen}
            modalRow={row.terminalSerialNumber}
            successObject={"Terminal"}
            successType={"Activated"}
          />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>

          <StyledMenu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            {/*Check if is on Inactive Tab*/}
            <MenuItem
              disableRipple
              onClick={() => {
                ActivateModalHandleOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Activate
            </MenuItem>
            <MenuItem
              onClick={() => {
                DeleteModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Delete
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  });
}

const Terminals = () => {
  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionOpen = () => setSessionOpen(true);
  const handleSessionClose = () => setSessionOpen(false);
  const handleLogin = () => {
    window.location.href = "/logout";
  };

  //Add terminal Modal config
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Success Modal Open
  // Success Edit Modal Control
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSuccessOpen = () => setSuccessOpen(true);
  const handleSuccessClose = () => setSuccessOpen(false);

  // Post Data to API
  const [serialNumber, setTerminalNumber] = useState("");
  const [deviceModel, setDeviceModel] = useState("");

  const fetchUrl = baseUrl + "/api/allTerminals";
  const postUrl = baseUrl + "/api/createTerminal";
  const shopsUrl = baseUrl + "/api/listBusiness";

  // handle tab Changes
  const [tabValue, setTabValue] = React.useState("Active");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Existing terminal Modal config
  const [existingUserModal, setExistingUserModalOpen] = useState(false);
  const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
  const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

  // Terminals Setters and Getters
  const [activeTerminal, setActiveTerminals] = useState([]);
  const [newTerminal, setNewTerminals] = useState([]);
  const [inactiveTerminal, setInactiveTerminal] = useState([]);
  const [pendingTerminal, setPendingTerminal] = useState([]);
  const [deactivationTerminal, setDeactivationTerminals] = useState([]);

  // Check if Tab is on Active
  if (tabValue === "Active") {
    // Fetch Active Terminals
    const ActiveTerminals = async () => {
      try {
        const ActiveResponse = await fetch(fetchUrl + "/Active", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });

        const data = await ActiveResponse.json();
        if (ActiveResponse.status === 200) {
          setActiveTerminals(data.data);
          localStorage.setItem("tabValue", "Active");
        } else if (data.error === "jwt expired") {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      ActiveTerminals();
    }, [tabValue]);
  }

  // Check if tab is on New
  else if (tabValue === "New") {
    // Fetch New Terminals yet to be Approved
    const NewTerminals = async () => {
      try {
        const newResponse = await fetch(fetchUrl + "/New", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await newResponse.json();
        if (newResponse.status === 200) {
          setNewTerminals(data.data);
          localStorage.setItem("tabValue", "New");
        } else if (data.error === "jwt expired") {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      NewTerminals();
    }, [tabValue]);
  }

  // Check if tab is on Pending
  else if (tabValue === "Pending") {
    // Fetch Edits Approval Terminals
    const PendingTerminals = async () => {
      try {
        const inactiveResponse = await fetch(fetchUrl + "/Pending", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await inactiveResponse.json();
        if (inactiveResponse.status === 200) {
          setPendingTerminal(data.data);
          localStorage.setItem("tabValue", "Pending");
        } else if (data.error === "jwt expired") {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      PendingTerminals();
    }, [tabValue]);
  }

  // Check if tab is on Deactivation
  else if (tabValue === "Deactivation") {
    // Fetch Pending Deactivation Terminals
    const DeactivationTerminals = async () => {
      try {
        const inactiveResponse = await fetch(fetchUrl + "/Deactivation", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await inactiveResponse.json();
        if (inactiveResponse.status === 200) {
          setDeactivationTerminals(data.data);
          localStorage.setItem("tabValue", "Deactivation");
        } else if (data.error === "jwt expired") {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      DeactivationTerminals();
    }, [tabValue]);
  }

  // Check if tab is on Inactive
  else if (tabValue === "Inactive") {
    // Fetch Deactivated Terminals
    const InactiveTerminals = async () => {
      try {
        const inactiveResponse = await fetch(fetchUrl + "/Inactive", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await inactiveResponse.json();
        if (inactiveResponse.status === 200) {
          setInactiveTerminal(data.data);
          localStorage.setItem("tabValue", "Inactive");
        } else if (data.error === "jwt expired") {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      InactiveTerminals();
    }, [tabValue]);
  }

  // Add a New terminal
  async function addNewTerminalHandler(event) {
    event.preventDefault();
    try {
      const response = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          terminalSerialNumber: serialNumber,
          deviceModel: deviceModel,
          createdBy: localStorage.getItem("username"),
        }),
      });
      const data = await response.json();
      if (response.status === 201 && data.Status === "SUCCESS") {
        setTerminalNumber("");
        setDeviceModel("");
        handleClose();
        handleSuccessOpen();
      } else if (response.status === 401) {
        handleSessionOpen();
      } else if (data.message === "Terminal already exists") {
        handleClose();
        existingUserModalHandleOpen();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Fetch all Active shops Created
  const [businessData, setBusinessData] = useState([]);

  const fetchActiveShops = async () => {
    try {
      const response = await fetch(shopsUrl + "/Active", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        setBusinessData(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchActiveShops();
  }, []);

  // businessNames Mapping
  const businessname = businessData.map((bdata) => {
    return bdata.businessName;
  });

  // Active Terminals Row Data
  const activeRowData = activeTerminal.map((activeTerminalMap) => {
    return {
      id: activeTerminalMap._id,
      terminalSerialNumber: activeTerminalMap.terminalSerialNumber,
      till_paybill: activeTerminalMap.till_paybill,
      businessID: activeTerminalMap.businessID,
      businessName: activeTerminalMap.businessName,
      deviceModel: activeTerminalMap.deviceModel,
      branch: activeTerminalMap.branchName,
      alias: activeTerminalMap.alias,
    };
  });

  // New Terminals Row Data
  const newRowData = newTerminal.map((newTerminalMap) => {
    return {
      id: newTerminalMap._id,
      terminalSerialNumber: newTerminalMap.terminalSerialNumber,
      till_paybill: newTerminalMap.till_paybill,
      businessID: newTerminalMap.businessID,
      businessName: newTerminalMap.businessName,
      deviceModel: newTerminalMap.deviceModel,
      createdBy: newTerminalMap.createdBy,
      // branch: newTerminalMap.branch,
      dateCreated: newTerminalMap.dateCreated,
    };
  });

  // Inactive Terminals Row Data
  const inactiveRowData = inactiveTerminal.map((inactiveTerminalMap) => {
    return {
      id: inactiveTerminalMap._id,
      terminalSerialNumber: inactiveTerminalMap.terminalSerialNumber,
      till_paybill: inactiveTerminalMap.till_paybill,
      businessID: inactiveTerminalMap.businessID,
      businessName: inactiveTerminalMap.businessName,
      deviceModel: inactiveTerminalMap.deviceModel,
      dateDeactivated: inactiveTerminalMap.dateDeactivated,
      requestedBy: inactiveTerminalMap.requestedBy,
      // branch: inactiveTerminalMap.branch,
      approvedBy: inactiveTerminalMap.approvedBy,
    };
  });

  // Pending Terminals Row Data
  const pendingRowData = pendingTerminal.map((pendingTerminalMap) => {
    return {
      id: pendingTerminalMap._id,
      terminalSerialNumber: pendingTerminalMap.terminalSerialNumber,
      till_paybill: pendingTerminalMap.till_paybill,
      businessID: pendingTerminalMap.businessID,
      businessName: pendingTerminalMap.businessName,
      deviceModel: pendingTerminalMap.deviceModel,
      dateModified: pendingTerminalMap.dateModified,
      modifiedBy: pendingTerminalMap.modifiedBy,
    };
  });

  // Deactivation Terminals Row Data
  const deactivationRowData = deactivationTerminal.map((deactivateTerminalMap) => {
    return {
      id: deactivateTerminalMap._id,
      terminalSerialNumber: deactivateTerminalMap.terminalSerialNumber,
      till_paybill: deactivateTerminalMap.till_paybill,
      businessID: deactivateTerminalMap.businessID,
      businessName: deactivateTerminalMap.businessName,
      deviceModel: deactivateTerminalMap.deviceModel,
      dateDeactivated: deactivateTerminalMap.dateDeactivated,
      requestedBy: deactivateTerminalMap.requestedBy,
    };
  });

  // page size setter and getter
  const [pageSize, setPageSize] = React.useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(activeRowData);
    // console.log("uE", filteredData)
  }, []);

  useEffect(() => {
    let filteredData = activeRowData;
    filteredData = setFilteredData(filteredData);
  }, [tabValue, searchValue]);

  useEffect(() => {
    let filteredData = activeRowData;
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [activeTerminal, searchValue]);

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  const filterBySearchBox = (data) => {
    return data.filter((item) => {
      if (
        item?.terminalSerialNumber?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.till_paybill?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.businessID?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.businessName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.deviceModel?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.branch?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.alias?.toLowerCase().includes(searchValue?.toLowerCase())
      ) {
        return item;
      }
    });
  };

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div>
        <div>
          <div>
            <Box>
              <Grid container>
                <Grid item marginTop="22px" marginBottom="22px">
                  <span
                    style={{
                      width: "78px",
                      height: "35px",
                      margin: "0px 41px 22px 12px",
                      fontFamily: "Poppins",
                      fontSize: "25px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.32",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                    }}
                  >
                    Terminal
                  </span>
                </Grid>
              </Grid>

              <Grid container columns={2} marginBottom="22px">
                <Grid item xs={1}>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Dashboard
                  </span>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    \
                  </span>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Terminal
                  </span>
                </Grid>

                <Grid item xs={0.68}></Grid>

                <Grid item>
                  {localStorage.getItem("group") === "Admin" ? (
                    <Button
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#f5f5f5",
                        width: "136px",
                        height: "37px",
                        padding: "12px 8.1px 12px 11px",
                        borderRadius: "8px",
                        backgroundColor: "#032541",
                      }}
                      onClick={handleOpen}
                      startIcon={<AddIcon />}
                    >
                      Add Terminal
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Grid container columns={1} sx={{ marginTop: "22px" }}>
                <Grid item xs={0.9} md={0.755}></Grid>

                <Grid item>
                  <label
                    style={{
                      width: "35px",
                      height: "12px",
                      fontFamily: "Poppins",
                      fontSize: "9px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "2.78",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Search:
                  </label>
                  <input
                    value={searchValue}
                    onChange={handleSearchBox}
                    style={{
                      fontSize: "12px",
                      color: "#272d3b",
                      borderRadius: "6px",
                      width: "238px",
                      height: "36px",
                      margin: "0 0 0 20.4px",
                      padding: "7px 107.7px 6.4px 11.1px",
                      objectFit: "contain",
                      border: "solid 1px #032541",
                    }}
                    placeholder="search here"
                  ></input>
                </Grid>
              </Grid>

              {/* <Grid
                sx={{
                  height: "500px",
                  margin: "50px 0 0 0",
                  border: "solid 0px transparent",
                  backgroundColor: "#fff",
                }}
              >
                <DataGrid
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                  rows={filteredData}
                  columns={activeColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                ></DataGrid>
              </Grid> */}

              <Box
                sx={{
                  "& .super-app-theme--header": {
                    color: "#032541",
                    fontWeight: "600",
                    fontSize: "10px",
                  },
                }}
              >
                <TabContext value={tabValue}>
                  <Box>
                    <AntTabs onChange={handleTabChange} aria-label="Group tabs" TabIndicatorProps={{ hidden: false }} textColor="primary">
                      <AntTab label="Active Terminal " value="Active" />
                      <AntTab label="New Terminal " value="New" />
                      <AntTab label="Edited Terminal " value="Pending" />
                      <AntTab label="Deactivation request" value="Deactivation" />
                      <AntTab label="inactive Terminal " value="Inactive" />
                    </AntTabs>
                  </Box>

                  <TabPanel value="Active">
                    <DataGrid
                      style={{
                        height: "500px",
                        width: "auto",
                        overflow: "visible",
                      }}
                      // rows={activeRowData}
                      rows={filteredData}
                      columns={activeColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>

                  <TabPanel value="New">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={newRowData}
                      columns={newColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>

                  <TabPanel value="Pending">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={pendingRowData}
                      columns={editedColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>

                  <TabPanel value="Deactivation">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={deactivationRowData}
                      columns={deactivationColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>

                  <TabPanel value="Inactive">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={inactiveRowData}
                      columns={inactiveColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>

            {/*sessionModal*/}
            <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

            {/*success terminal creation*/}
            <SuccessModal onClose={handleSuccessClose} editConfigOpen={successOpen} modalRow={serialNumber} successObject={"Terminal"} successType={"Created"} />

            {/*Existing user Modal*/}
            <Modal
              centered
              align={"center"}
              className="container container-fluid"
              open={existingUserModal}
              onClose={existingUserModalHandleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                style={{
                  width: "506px",
                  height: "506px",
                  margin: "10% 16px 23px 45px",
                  padding: "87px 75px 92.7px 34px",
                  borderRadius: "36px",
                  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                  backgroundColor: "#fff",
                }}
              >
                <img src={deactivate} alt={"success deactivation"} />
                <label
                  style={{
                    width: "356px",
                    height: "65px",
                    margin: "37.8px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.2",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#6e7074",
                  }}
                >
                  Terminal is Existing!.
                </label>
                <Button
                  onClick={() => {
                    existingUserModalHandleClose();
                    window.location.reload(true);
                  }}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    color: "#fff",
                    width: "124.5px",
                    height: "52.3px",
                    padding: "8px 44.5px 9.3px 35px",
                    backgroundColor: "#032541",
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>

            {/*Add Terminal Modal*/}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="container-fluid">
              <div style={AddTerminalModalStyle}>
                <div
                  style={{
                    width: "553px",
                    height: "90px",
                    margin: "0 0 9px",
                    padding: "35px 30px 34px",
                    borderRadius: "16px",
                    backgroundColor: "#dc3545",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    style={{
                      width: "156px",
                      height: "21px",
                      margin: "0 433px 0 0",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Add Terminal
                  </Typography>
                </div>
                <div
                  style={{
                    width: "498.2px",
                    height: "309.2px",
                    margin: "18px 27.8px 0 27px",
                    padding: "37px 47.2px 37px 47px",
                    backgroundColor: "#f5f7ff",
                  }}
                >
                  <form onSubmit={addNewTerminalHandler}>
                    {/*Serial No*/}
                    <label
                      style={{
                        width: "80px",
                        height: "20px",
                        margin: "0 335px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#6e7074",
                      }}
                    >
                      Serial No.
                    </label>
                    <input
                      value={serialNumber}
                      onChange={(e) => setTerminalNumber(e.target.value)}
                      maxLength="50"
                      required
                      style={{
                        fontFamily: "Poppins",
                        width: "404px",
                        height: "39px",
                        margin: "10px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                    />

                    {/*Pos Model*/}
                    <label
                      style={{
                        width: "80px",
                        height: "20px",
                        margin: "0 335px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#6e7074",
                      }}
                    >
                      POS Model
                    </label>
                    <input
                      value={deviceModel}
                      onChange={(e) => setDeviceModel(e.target.value)}
                      maxLength="50"
                      required
                      style={{
                        padding: "16px 131px 8px 0",
                        fontFamily: "Poppins",
                        width: "404px",
                        height: "39px",
                        margin: "10px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                    />

                    <div align={"right"} style={{ height: "33.1px", margin: "40px 0 0 0px" }}>
                      <Button
                        type="close"
                        onClick={handleClose}
                        style={{
                          width: "90px",
                          height: "33.1px",
                          margin: "0 30px 0 0",
                          padding: "9px 30px 8.1px 29px",
                          backgroundColor: "#dc3545",
                          color: "#fff",
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        style={{
                          width: "90px",
                          height: "33.1px",
                          margin: "0 0 0 30px",
                          padding: "9px 32px 8.1px 31px",
                          backgroundColor: "#032541",
                          color: "#fff",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminals;
