import { Grid, Typography, Breadcrumbs, Paper } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import profilePicture from "../../../common/images/profile_picture.svg";
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import HttpComponent from '../MakeRequest';
import BackImgA from "../../../images/bgA.svg"
import BackImgB from "../../../images/bgB.svg"
import BackImgC from "../../../images/bgC.svg"
import DateFormatter from '../../../utils/dateFormatter';
import { handleSessionId } from '../../../features/staffSlice';
import { useDispatch } from 'react-redux'
import { NoRowsOverlay } from '../../No Rows/noRowsOverlay';
import { DataGrid } from '@mui/x-data-grid';
import PreviewSessionDrilldownReport from '../reports/sessionDrilldown';

const baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Session Report</Typography>
]

export default function StaffSessionReportProfile() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState();
    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[0]
    const dispatch = useDispatch()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    // get customer details

    let customerId = localStorage.getItem("customerId")

    const getCustomerDetails = async () => {
        try {
            const customerDetailsResponse = await fetch(
                baseUrl + `/api/get_customer_by_number?customerId=${customerId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );

            if (customerDetailsResponse.status === 200) {
                const customerDetailsData = await customerDetailsResponse.json();
                //console.log("det", customerDetailsData)
                //console.log("sd", customerDetailsData.data[0])
                setUserData(customerDetailsData.data[0])
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getCustomerDetails()
    }, [customerId])


    // check bio set

    const [studentBioSet, setStudentBioSet] = useState([])

    function getFingerPrintStatus() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_customer_by_number?customerId=${userData?._id}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'fffffff')
                if (data?.status === 200) {
                    setStudentBioSet(data?.response?.data[0]?.billableItems[0]?.studentsBio)
                }
                // console.log(data , 'fingerprints')
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getFingerPrintStatus()
    }, [userData?._id])


    // recent session

    function getLectureSEssions() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/getLecturerSession`,
                body: {
                    lecturerId: userData?.billableItems[0]?._id
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'helo')
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getLectureSEssions()
    }, [userData?.billableItems[0]?._id])

    const handlenext = (params) => {
        dispatch(handleSessionId(params?.row?._id))
        navigate(`/school/sessionreport?SessionDetails`)
    }



    // session columns ==>courseName

    const sessionDetails = [
        { headerName: "StaffName", field: 'sessionCode', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Session ID</strong>) }, renderCell: (params) => { return (<span style={{ fontSize: "14px", }}>{params?.row?.sessionCode}</span>) } },
        { headerName: "StaffId", field: 'admissionNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Students Present</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px", fontSize: "14px", }}>{params?.row?.presentCount}</span></>) } },
        { headerName: "UnitName", field: 'UnitName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Unit</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px", fontSize: "14px", }}>{params?.row?.unitName}</span></>) } },
        { headerName: "phoneNo", field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student    Absent</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px", fontSize: "14px" }}>{params?.row?.absentCount}</span></>) } },
        {
            headerName: "email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => {
                return (<><span style={{ marginLeft: "10px", fontSize: "11px", }}>{DateFormatter(params?.row?.startTime)} - {DateFormatter(params?.row?.endTime)
                }</span></>)
            }
        },
        {
            headerName: "", headerAlign: 'left', align: 'right', flex: 1, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <ArrowForwardIosIcon style={{ cursor: 'pointer' }} onClick={() => handlenext(params)} />
                    </Grid>
                )
            }
        },
    ]

    return (
        <Grid container direction={'column'}>
            {state === 'SessionDetails' ? <PreviewSessionDrilldownReport /> : <Grid container direction={'column'}>
                <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Session Report</Typography>
                </Grid>
                <Grid item mt={2}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid container mt={3} mb={3} spacing={3}>
                    <Grid item style={{ width: '45%' }}>
                        <Paper sx={{ height: '138px', px: 1, width: "100%" }}>
                            <Grid width={'100%'} height={'100%'} justifyContent={'space-between'} container alignItems={'center'}>
                                <Grid item>
                                    <img src={profilePicture} style={{ width: '70px', height: '70px' }} alt='profilepicture' />
                                </Grid>
                                <Grid item>
                                    <Typography style={{ display: 'flex', alignItems: 'center' }}><span style={{ color: '#000000', fontWeight: 600, fontSize: '20px' }}>{userData?.firstName} {userData?.lastName}</span>  <span style={{ color: '#17ae7b', fontSize: '14px', marginLeft: '10px' }}>{userData?.status}</span> </Typography>
                                    <Typography py={1} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><span style={{ color: '#666f76', fontSize: '14px' }}>Staff No :</span> <span style={{ color: '#000', fontWeight: 'bold', fontSize: '14px' }}>{userData?.admissionNo}</span></Typography>
                                    <Typography style={{ display: 'flex', alignItems: 'center' }}>FingerPrints : <span style={studentBioSet?.length > 0 ? { color: '#17ae7b', fontWeight: 600, marginLeft: '10px', fontSize: '14px' } : { color: '#dc3545', marginLeft: '10px', fontWeight: 600, fontSize: '14px' }}>{studentBioSet?.length > 0 ? 'Set' : 'Not Set'}</span> </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item style={{ width: '55%' }}>
                        <Paper sx={{ height: '138px', width: '100%' }}>
                            <Grid item style={{ background: `url(${BackImgC})`, height: '100%', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} width={'100%'} height={'100%'} justifyContent={'space-between'} container alignItems={'center'}>
                                <Grid container direction={'column'}>
                                    <Typography style={{ color: '#032541', fontWeight: 'bold', marginLeft: '10px' }}>{userData?.unitsCount ? userData?.unitsCount : 0}</Typography>
                                    <Typography style={{ fontWeight: 500, fontSize: '16px', marginTop: "10px", marginLeft: '10px', color: '#000' }}>Units Assigned</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item width={'100%'} mt={2}>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState?.total}
                            loading={pageState?.isLoading}
                            pagination
                            page={pageState?.page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={sessionDetails}
                            rows={pageState?.data}
                            getRowId={row => row?._id} />

                    </Grid>
                </Grid>
            </Grid>}
        </Grid>
    )
}