import {
  TextField,
  Stack,
  Grid,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InactiveTable from "./InactiveTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import ErrorIcon from "@mui/icons-material/Error";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { handleActivated } from "../../../../features/AlertSlice";
import { handleDeleted } from "../../../../features/AlertSlice";
import { setSearch } from "../../../../features/searchSlice";
// import { handleRemove } from "../../../../features/EditedBanksSlice";
import Papa from "papaparse";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function exportArrayToCSV(data, filename) {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Inactive() {
  const dispatch = useDispatch();

  const classes = useStyles()

  const [activateOpen, setActivateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const handleOpenDialog = () => {
    setActivateOpen(true);
  };

  const handleOpenDialog2 = () => {
    setDeleteOpen(true);
  };

  const handleClose = () => {
    setActivateOpen(false);
  };

  const handleClose2 = () => {
    setDeleteOpen(false);
  };

  const name = useSelector((state) => state.userDetails.userName);
  const phoneNo = useSelector((state) => state.userDetails.phoneNo);

  const handleActivate = () => {
    try {
      axios
        .post(baseUrl + "/api/activateUser", {
          phoneNo,
        },
        {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
        )
        .then((response) => {
          if (response) {
            console.log("Activated successfully");
            dispatch(handleActivated(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = () => {
    try {
      axios
        .post(baseUrl + "/api/deleteUser", {
          phoneNo,
        },
        {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
        )
        .then((response) => {
          if (response) {
            console.log("deleted successfully");
            dispatch(handleDeleted(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (clickedItem == "Activate") {
      handleOpenDialog();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  useEffect(() => {
    if (clickedItem == "Delete") {
      handleOpenDialog2();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  const exportData = useSelector((state) => state.exportDetails.users);

  const handleExport = () => {
    exportArrayToCSV(exportData, "InactiveUsers.csv");
  };

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <TextField
              id="input-with-icon-textfield"
              label="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={handleSearch}
              className={classes.focusedTextField}
            />

            <Button
              variant="outlined"
              sx={{
                bgcolor: "#f5f6f7",
                borderColor: "#f5f6f7",
                color: "#032541",
                ":hover": {
                  borderColor: "#032541",
                },
                textTransform: "none",
              }}
              onClick={handleExport}
            >
              Export
              <MoreVertIcon
                sx={{
                  color: "#032541",
                }}
              />
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <InactiveTable />
        </Grid>
      </Grid>
      <Dialog
        open={activateOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CheckCircleIcon
                sx={{
                  fontSize: "100px",
                  color: "#17ae7b",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Activate User 
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Do You Want To Activate User:{" "}
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {name}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleActivate();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#17ae7b",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#17ae7b",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Activate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Stack direction="row">
            <Stack
              width={"30%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ErrorIcon
                sx={{
                  fontSize: "100px",
                  color: "#dc3545",
                }}
              />
            </Stack>
            <Stack width={"70%"}>
              <Typography
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="600"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Delete User
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#707070"
              >
                Selected User will be deleted permanently...this action
                cannot be undone
              </Typography>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="normal"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                <CircleIcon sx={{ fontSize: "10px", mr: 1 }} />
                {name}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose2}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#ffffff",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#032541",
              color: "#032541",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              console.log("delete clicked");
              handleClose2();
              handleDelete();
            }}
            variant="outlined"
            sx={{
              borderRadius: 4,
              backgroundColor: "#dc3545",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#dc3545",
              color: "white",
              ":hover": {
                backgroundColor: "#032541",
                color: "white",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Inactive;
