import { Box, Grid, TextField, FormControl, RadioGroup, Radio, Paper, Typography, FormControlLabel, InputLabel, MenuItem, Select, Button  } from '@mui/material'
import { useEffect, useState } from 'react'
import CustomSearchInput from '../../School/CustomSearchInput';
import HttpComponent from '../../School/MakeRequest';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomTable from '../../School/CustomTable';
export default function CollectFareAsZed() {
    const [selectedFareCollector, setFareCollector] = useState('');
    const [selectedAccountType, setSelectedAccountType] = useState('');
    const [PaybillNumber, setPaybillNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [tillNumber, setTillNumber] = useState('');
    const [searchValue , setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [configurePayment, setConfigurePayment] = useState(false)
    const [customerName , setCustomerName] = useState('')
    const [customerPhoneNumber , setCustomerPhoneNumber] = useState('')

    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    function getVehicleOwners() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/customers_list?status=Active&customerClassification=vehicleowner&page=${pageState?.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicleOwners();
    }, [searchValue, pageState.page, dataGridPageSize ])


    const handleOnChange = (e) => {
        setFareCollector(e.target.value)
    }

    const handleSetDetails = (params) => {
        console.log(params , 'hey params')
        setCustomerName(params?.row?.customerName)
        setCustomerPhoneNumber(params?.row?.mobileNumber)
        setConfigurePayment(true)
    }



    return (
        <Box component="div" sx={{ display: "flex", width: '100%', flexDirection: 'column' }}>
            <Typography>Disburse funds to</Typography>
            <Grid container spacing={2} width={'100%'}>
                <Grid item width={'100%'} mt={1}>
                    <Grid container display={'flex'} alignItems={'center'} width={'100%'} gap={2}>
                        <FormControl sx={{ width: '100%' }}>
                            <RadioGroup sx={{ width: '100%', gap: 1 }} onChange={handleOnChange} value={selectedFareCollector} row aria-labelledby="demo-row-radio-buttons-group-label" name="controlled-radio-buttons-group">
                                <Grid item width={'49%'} py={2}>
                                    <Paper elevation={1} sx={{
                                        width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center',
                                    }}>
                                        <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>To Sacco</Typography>
                                        </Box>
                                        <FormControlLabel value="ToSacco" control={<Radio style={{ color: selectedFareCollector === 'sacco' ? '#17ae7b' : '' }} />} />
                                    </Paper>
                                </Grid>
                                <Grid item width={'48%'} py={2}>
                                    <Paper elevation={1} sx={{ width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center', backgroundColor: selectedFareCollector === 'cash' ? 'rgba(23, 174, 123, 0.05)' : '#fff', border: selectedFareCollector === 'cash' ? '1px solid #17ae7b' : '' }}>
                                        <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>To Vehicle Owner</Typography>
                                        </Box>
                                        <FormControlLabel value="ToVehicleOwner" control={<Radio />} />
                                    </Paper>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container width={'100%'} mt={1}>
                {selectedFareCollector === 'ToSacco' ?
                    <Grid item width={'100%'}>
                        <Box component={'div'} width={'100%'} display={'flex'} flexDirection={'column'}>
                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Account Details</Typography>
                            <Grid item width={'100%'}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedAccountType}
                                        label="Account Type"
                                        onChange={(e) => setSelectedAccountType(e.target.value)}
                                    >
                                        <MenuItem value={'Paybill Number'}>Paybill Number</MenuItem>
                                        <MenuItem value={'Till Number'}>Till Number</MenuItem>
                                        <MenuItem value={'Bank Account'}>Bank Account</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {selectedAccountType === 'Paybill Number' ?
                                <Grid item width={'100%'} my={2}>
                                    <TextField style={{ width: "100%" }} value={PaybillNumber} name='PaybillNumber' onChange={(e) => setPaybillNumber(e.target.value)} id="outlined" placeholder="Paybill Number" multiline required />
                                </Grid> :
                                selectedAccountType === 'Till Number' ?
                                    <Grid item width={'100%'} my={2}>
                                        <TextField style={{ width: "100%" }} value={tillNumber} name='tillNumber' onChange={(e) => setTillNumber(e.target.value)} id="outlined" placeholder="Till Number" multiline required />
                                    </Grid> :
                                    selectedAccountType === 'Bank Account' ?
                                        <Grid item width={'100%'} my={2}>
                                            <TextField style={{ width: "100%" }} value={accountNumber} name='accountNumber' onChange={(e) => setAccountNumber(e.target.value)} id="outlined" placeholder="Account Number" multiline required />
                                        </Grid> : null
                            }
                            {selectedAccountType ?
                                <Grid item my={2} display={'flex'} width={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                                    <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                                    <Button style={{ width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541'}}>Save</Button>
                                </Grid> : null
                            }
                        </Box>
                    </Grid>
                    : selectedFareCollector === 'ToVehicleOwner' ? 
                    <Grid container width={'100%'} direction={'column'}>
                        <Grid item width={'100%'} my={1}>
                            <Typography>Vehicle Owners</Typography>
                        </Grid>
                        <Grid item width={'100%'} my={1}>
                            <CustomSearchInput value={searchValue} style={{width:'100%'}} onChange={(e)=> setSearchValue(e.target.value)} name={'searchValue'} placeholder={'Search Value'}/>
                            <CustomTable 
                              data={pageState?.data}
                               columns={[
                                         {field: 'customerName', headerName: 'Name', flex: 1},
                                         { field: 'mobileNumber', headerName: 'Mobile Number', flex: 1 },
                                         { field: 'action', headerName: '', flex: 1 , 
                                           renderCell:(params) =><Box flexDirection={'row'} onClick={() => handleSetDetails(params)} display={'flex'} alignItems={'center'} sx={{cursor:'pointer'}} component={'div'} gap={2}><Typography variant='h6' sx={{color:'#DC3545'}}>Configure</Typography> <ArrowForwardIosIcon sx={{color:'rgba(3, 37, 65, 0.4)'}}/> </Box> },
                                        ]}
                            />
                        </Grid>
                    </Grid>:configurePayment === true ? <Grid item width={'100%'}>
                        <Box component={'div'} width={'100%'} display={'flex'} flexDirection={'column'}>
                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>Account Details</Typography>
                            <Grid item width={'100%'}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedAccountType}
                                        label="Account Type"
                                        onChange={(e) => setSelectedAccountType(e.target.value)}
                                    >
                                        <MenuItem value={'Paybill Number'}>Paybill Number</MenuItem>
                                        <MenuItem value={'Till Number'}>Till Number</MenuItem>
                                        <MenuItem value={'Bank Account'}>Bank Account</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {selectedAccountType === 'Paybill Number' ?
                                <Grid item width={'100%'} my={2}>
                                    <TextField style={{ width: "100%" }} value={PaybillNumber} name='PaybillNumber' onChange={(e) => setPaybillNumber(e.target.value)} id="outlined" placeholder="Paybill Number" multiline required />
                                </Grid> :
                                selectedAccountType === 'Till Number' ?
                                    <Grid item width={'100%'} my={2}>
                                        <TextField style={{ width: "100%" }} value={tillNumber} name='tillNumber' onChange={(e) => setTillNumber(e.target.value)} id="outlined" placeholder="Till Number" multiline required />
                                    </Grid> :
                                    selectedAccountType === 'Bank Account' ?
                                        <Grid item width={'100%'} my={2}>
                                            <TextField style={{ width: "100%" }} value={accountNumber} name='accountNumber' onChange={(e) => setAccountNumber(e.target.value)} id="outlined" placeholder="Account Number" multiline required />
                                        </Grid> : null
                            }
                            {selectedAccountType ?
                                <Grid item my={2} display={'flex'} width={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                                    <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                                    <Button style={{ width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541'}}>Save</Button>
                                </Grid> : null
                            }
                        </Box>
                    </Grid>:
                    null}
            </Grid>
        </Box>
    )
}