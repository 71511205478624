import React, { useState, useEffect } from "react";
import CustomSelect from "../customSelect";
import { Button, Grid } from "@mui/material";
import StockFunctions from "../stockEndpoints";
import { useParams, useNavigate } from "react-router-dom";
import { stockStyles } from "../stockStyling";
import { useSelector } from "react-redux";

const StockTakeSetUp = () => {
  const navigate = useNavigate();

  const storesToTakeCount = [
    { name: "", _id: "" },
    { name: "Warehouse", _id: "Warehouse" },
    { name: "Main store", _id: "MainStore" },
    { name: "Sub store", _id: "SubStore" },
  ];

  const mainStoreToCount = [
    { name: "", _id: "" },
    { name: "Main store", _id: "MainStore" },
  ]

  const [storeType, setStoreFromId] = useState("");
  const [transferWarehouses, setWarehouses] = useState([]);
  const [wareHouseId, setWareHouseId] = useState("");

  //   get storeBy StoreType;

  useEffect(() => {
    const stockfunctions = new StockFunctions();
    if (storeType)
      stockfunctions.findStoresByType(storeType, 1, 1000).then((data) => {
        if (data.status === 200) {
          setWarehouses(data?.response?.data);
        }
      });
  }, [storeType]);

  const  { wareHouseState } = useSelector((store) => store.sidebar)

  return (
    <div style={{ width: "100%" }}>
      <Grid container width={"100%"} direction={"column"}>
        <Grid item margin={"0px 0px 2% 0px"} width={"30%"}>
          <CustomSelect storesToTakeCount={wareHouseState ? storesToTakeCount :mainStoreToCount } setItemId={setStoreFromId} selectLabel={"Select Store Type"} />
        </Grid>

        {storeType && (
          <Grid item margin={"0px 0px 2% 0px"} width={"30%"}>
            <CustomSelect selectLabel={storeType} transferWarehouses={transferWarehouses} setItemId={setWareHouseId} />
          </Grid>
        )}
        
        {wareHouseId && (
          <Grid item sx={{ margin: "0% 0% 1% 0%", width: "30%" }} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Button onClick={() => wareHouseId && storeType ? navigate(`/stockTake?${"category"}?${wareHouseId}`) : null } style={wareHouseId && storeType ? stockStyles.nextButtonActive : stockStyles.nextButtonInactive}>Next</Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default StockTakeSetUp;
