import React, { useState, useEffect } from "react";
import { Modal, Grid, Box, TextField, MenuItem, Button } from "@mui/material";
import { creditnotestyles } from "../creditNoteStyling/styless";
import { ArrowBack } from "@mui/icons-material";
import possvg from "../../../common/images/possvg.svg";
import printersvg from "../../../common/images/printersvg.svg";
import { Close, Share } from "@mui/icons-material";
import sendpayrequest from "../../../common/images/sendpayrequest.svg";
import { useNavigate } from "react-router";

export const Sharecreditnotemodal = (props) => {
  const share = props.share;
  const activePos = props.activePos;
  const printCreditNote = props.printCreditNote;
  const setSharemodal = props.setSharemodal;

  const navigate = useNavigate();
  const creditNoteId = props.creditNoteId;
  const setPosSerialNo = props.setPosSerialNo;
  const [printersHolder, setPrintersHolder] = useState(true);
  const [selectprinter, setSelectPrinter] = useState(false);

  // set default Printer
  useEffect(() => {
     if (activePos.length === 1) {
      setPosSerialNo(activePos[0].terminalSerialNumber);
        // printCreditNote();
    }
  },[activePos])

  const togglePrinterAndSelect = () => {

    if(activePos.length === 0){

    }

    if (activePos.length === 1) {
        printCreditNote();
    } else {
      setPrintersHolder(false);
      setSelectPrinter(true);
    }
  };

  const backToPrinter = () => {
    setPrintersHolder(true);
    setSelectPrinter(false);
  };

  return (
    <Modal open={share} style={creditnotestyles.universalFont}>
      <div style={creditnotestyles.shareModal}>
        <div style={creditnotestyles.shareModalIntro}>
          <span>
            <ArrowBack style={{ cursor: "pointer" }} onClick={ ()  =>  printersHolder ? setSharemodal(false)  : backToPrinter() } />
          </span>

          {printersHolder && <span style={creditnotestyles.shareCreditNoteText}>Share Credit Note</span>}

          {!printersHolder && <span style={creditnotestyles.shareCreditNoteText}>Select Pos</span>}
        </div>

        {printersHolder && (
          <>
            <Grid container direction={"column"} width="100%" style={{ padding: "40px" }}>
              <Grid item width={"80%"} marginBottom={"30px"} style={creditnotestyles.printerPosModalStlying} onClick={togglePrinterAndSelect}>
                <img style={creditnotestyles.creditNotePosImg} src={possvg} alt="pos" /> <span style={creditnotestyles.creditNoteModalText}>Send to POS</span>
              </Grid>

              <Grid item width={"80%"} style={creditnotestyles.printerPosModalStlying}>
                <img style={creditnotestyles.creditNotePosImg} src={printersvg} alt="printer" /> <span style={creditnotestyles.creditNoteModalText}>Send to Printer</span>
              </Grid>

              <Grid item width={"80%"} style={creditnotestyles.printerPosModalStlying} onClick={() => navigate(`/previewcreditnote/${creditNoteId}`)}>
                <Share style={creditnotestyles.creditNotePosImg} /> <span style={creditnotestyles.creditNoteModalText}>Share</span>
              </Grid>
            </Grid>
          </>
        )}

        {selectprinter && (
          <>
            <Grid container direction={"column"} width="100%" style={{ padding: "40px" }}>
              <Grid
                item
                width={"100%"}
                marginBottom={"30px"}
                // style={creditnotestyles.printerPosModalStlying}
              >
                <TextField style={{ width: "100%" }} id="outlined-select-meals" select label="Pos" helperText="POS" onChange={(e) => setPosSerialNo(e.target.value)}>
                  {activePos?.length > 0 &&
                    activePos?.map((pos) => {
                      return (
                        <MenuItem key={pos._id} value={pos.terminalSerialNumber}>
                          {pos.terminalSerialNumber}
                        </MenuItem>
                      );
                    })}
                </TextField>

                {activePos.length === 0 && <p style={{ textAlign: "right", color: "red" }}> You do not have any POS</p>}
              </Grid>

              <Grid style={creditnotestyles.cancelprintHolder}>
                <Button style={creditnotestyles.cancelButton}>Cancel</Button>
                <Button onClick={() => printCreditNote()} style={creditnotestyles.printButton}>
                  Print
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </Modal>
  );
};

export const ShareToPos = (props) => {
  const sendRequest = props.sendRequest;
  const setSendingrequest = props.setSendingrequest;
  return (
    <Modal open={sendRequest} style={creditnotestyles.universalFont}>
      <div style={creditnotestyles.sendRequestModal}>
        <Box style={{ width: "100%", textAlign: "right", padding: "10px" }}>
          <span>
            <Close onClick={() => setSendingrequest(false)} style={{ cursor: "pointer" }} />
          </span>
        </Box>

        <Box style={{ width: "100%" }}>
          <p style={creditnotestyles.sendingToPosImgText}>
            <img style={creditnotestyles.sendingToPosImg} src={sendpayrequest} />
          </p>

          <p style={creditnotestyles.sendingToPosImgText}>
            Sending the request...
            <br />
            Please Wait
          </p>
        </Box>
      </div>
    </Modal>
  );
};

export const CreditnoteexpiryModal = (props) => {
  const selectExpiry = props.selectExpiry;
  const expiryDate = props.expiryDate;
  const setExpiryDate = props.setExpiryDate;
  const customDate = props.customDate;
  const setCustomDate = props.setCustomDate;
  const setExpiry = props.setExpiry;
  const setCreditExpiry = props.setCreditExpiry;

  const [toggleCustomDate, setToggleCustomDate] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  
  const getCustomDate = (e) => {
    setExpiryDate(e.target.value)
  }

  const selectDates = ["1 Week", "1 Month", "Custom Duration"];

  const setCreditNoteDetails = (e) => {
    if (e.target.value === "Custom Duration") {
      setToggleCustomDate(true);
      setExpiryDate(customDate);
    } else if(e.target.value === "1 Month") {
      setToggleCustomDate(false);
      setExpiryDate("28");
    }else if(e.target.value === "1 Week"){
      setToggleCustomDate(false);
      setExpiryDate("7");
    }

    if (e.target.value) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  };


  return (
    <Modal open={selectExpiry} style={creditnotestyles.universalFont}>
      <div style={creditnotestyles.creditNoteModal}>
        <div style={creditnotestyles.shareModalIntro}>
          <span>
            {" "}
            <ArrowBack style={{ cursor: "pointer" }} onClick={() => setExpiry(false)} />{" "}
          </span>
          <span> Credit Note Expiry </span>
        </div>

        <div style={creditnotestyles.creditNoteConfigHolder}>
          <p>Configure credit note to expire automatically after</p>
          <Box noValidate autoComplete="off">
            <TextField style={{ width: "100%", margin: "0px 0px 10px 0px" }} id="outlined-select-meals" select label="Expires After" onChange={setCreditNoteDetails}>
              {selectDates?.map((dates, index) => {
                return (
                  <MenuItem key={index} value={dates}>
                    {dates}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>

          {toggleCustomDate && (
            <Box >
              <TextField  onChange={getCustomDate} style={{ width: "100%", margin: "0px 0px 10px 0px" }} label="Expires After (days)" />
            </Box>
          )}
        </div>

        <Grid
          style={{
            margin: "0px 0px 10px 0px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button style={activeButton ? creditnotestyles.cancelButton : creditnotestyles.cancelButtonLowOpacity}>Close</Button>
          <Button style={activeButton ? creditnotestyles.approvalButton : creditnotestyles.approvalButtonLowOpacity} onClick={() => setExpiryDate !== "" ? setCreditExpiry() : null} >Save</Button>
        </Grid>
      </div>
    </Modal>
  );
};
