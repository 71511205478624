import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
// import Form from "react-bootstrap/Form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment-timezone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from "@mui/material/Modal";
// import "bootstrap/dist/css/bootstrap.css";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import "./react-bootstrap-table2.min.css";
// import "./bootstrap.css";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import axios from "axios";
import SyncIcon from '@mui/icons-material/Sync';

const baseUrl = process.env.REACT_APP_BASE_URL;

// Number Format
const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
  }).format(value);

// OpenTable Modal Styling
const modalTableStyle = {
  transform: "translate(-50%, -50%)",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "900px",
  height: "350px",
  padding: "0 0 73.9px",
  borderRadius: "15px",
  backgroundColor: "#fff",
};

const columns = [
  // {Product Name}
  {
    field: "productName",
    headerName: "Product Name",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Product Name"}
      </strong>
    ),
  },

  // {Product Quantity}
  {
    field: "productQuantity",
    headerName: "Product Quantity",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Product Quantity"}
      </strong>
    ),
  },

  // {Product ID}
  {
    field: "productId",
    headerName: "Product ID",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Product ID"}
      </strong>
    ),
  },

  // {Business ID}
  {
    field: "businessId",
    headerName: "Business ID",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Business ID"}
      </strong>
    ),
  },

  // {QuicKbooks ID}
  {
    field: "quickbooksId",
    headerName: "QuickBooks ID",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"QuickBooks ID"}
      </strong>
    ),
  },

  // {Sync Attempt}
  {
    field: "lastSyncAttempt",
    headerName: "Last Sync Attempt",
    flex: 1,
    align: "left",
    valueGetter: (params) => {
      return moment(params.row.lastSyncAttempt).tz("Africa/Banjul").format("llll");
    },
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Last Sync Attempt"}
      </strong>
    ),
  },

  // {Amount}
  {
    field: "transamount",
    headerName: "Amount",
    flex: 1,
    align: "left",
    valueGetter: (params) => {
      return numberFormat(params.row.transamount);
    },
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Amount"}
      </strong>
    ),
  },

  {
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Actions"}
      </strong>
    ),
    renderCell: (params) => {

      return (
        <div>
          {/*button*/}
          <Button
            // onClick={syncHandler}
            sx={{ fontFamily: "Poppins", fontWeight: 600, color: "#dc3545" }}
          >
            {/* {params.row.initialSerialNumber.length <= 0 ? "N/A" : params.row.initialSerialNumber.length} */}
            Retry
          </Button>
        </div>
      );
    },
    width: 300,

  }
  // {
  //   field: "transactionType",
  //   headerName: "Payment Type",
  //   flex: 1,
  //   align: "left",
  //   renderHeader: () => (
  //     <strong
  //       style={{
  //         fontFamily: "Poppins",
  //         fontSize: "10px",
  //         fontWeight: "600",
  //         color: "#032541",
  //       }}
  //     >
  //       {"Payment Type "}
  //     </strong>
  //   ),
  // },
  // {
  //   field: "paymentChanel",
  //   headerName: "Payment Device",
  //   flex: 1,
  //   align: "left",
  //   renderHeader: () => (
  //     <strong
  //       style={{
  //         fontFamily: "Poppins",
  //         fontSize: "10px",
  //         fontWeight: "600",
  //         color: "#032541",
  //       }}
  //     >
  //       {"Payment Device "}
  //     </strong>
  //   ),
  // },
  
];

const IntergrationSales = () => {
  // page size setter and getter
  const [pageSize, setPageSize] = React.useState(5);

  //add days to current date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionClose = () => setSessionOpen(false);
  const handleSessionOpen = () => setSessionOpen(true);

  //date range Filter setters and Getters
  const [dValue, onDValueChange] = useState([
    moment(new Date()).format("YYYY-MM-DD"),
    moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
  ]);

  //const dp open state
  const [isOpen, setIsOpen] = useState(false);

  //chart dropDown
  const [dropDown, setDropDown] = useState("Today");

  // Transaction typesetters and Getters
  const [transactionType, setTransactionType] = useState([]);

  // payment Type setter and getter
  const [paymentType, setPaymentType] = useState("All");

  // Filter data grid by transaction type using select input
  const [filteredData, setFilteredData] = useState([]);

  //const
  const [searchValue, setSearchValue] = useState([]);
  const [salesTransactions, setSalesTransactions] = useState([]);

  let b_id = localStorage.getItem("businessId");

  const fetchUrl = `${baseUrl}/api/get_accounting_sales?status=Failed&bId=${b_id}&limit=20&page=1`;
  // let accountConfigsUrl = `${baseUrl}/api/get_accounting_configs?bId=${businessId}`;

  // Transactions Setters and Getters
  const [transactions, setTransactions] = useState([]);
  const [sync, setSync] = React.useState([]);

  const syncHandler = async() => {
    
    let bzn_id = localStorage.getItem("businessId");
    const syncUrl = `${baseUrl}/api/sync_failed_sales?bId=${bzn_id}`;
  
    try {
      const response = await fetch(syncUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
  
      if (data.status == "SUCCESS") {
        setSync(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchTransactions = async () => {
    try {
      const res = await fetch(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      // console.log("RESPONSEEEEEE data", data)
      console.log("RESPONSEEEEEE data 2", data.data)
      console.log("DATADATA", JSON.stringify(data))

      console.log(`data.statusdata.status ${data.status}`)
      if (data.status == "SUCCESS") {
        console.log("<><><> 0000 in success ")
        let resultObject = JSON.parse(JSON.stringify(data))
        console.log(`resultObject.data11  ${JSON.stringify(resultObject.data)} `)
        setTransactions(resultObject.data);
        setSalesTransactions(resultObject.data)

        console.log(`Set Transactions -- ${transactions}`)
        console.log(`resultObject.data2 ${resultObject.data}`)
      } else if (res.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e, e);
    }
  };

  //fetch transactions
  useEffect(() => {
    const doProcess = async() => {
      await fetchTransactions();
      console.log(`TTTtransactions ${JSON.stringify(transactions)}`)
      console.log(`salesTransactions ${JSON.stringify(salesTransactions)}`)
    }
    doProcess();
  }, []);

  // const fetchTransactions = async () => {
  //   try {
  //     const response = await fetch(fetchUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         "X-Authorization": localStorage.getItem("X-Authorization"),
  //       },
  //     });
  //     const data = await response.json();
  //     // console.log("RESPONSEEEEEE data 2", data.data)

  //     if (response.status === 200) {
  //       setTransactions(data.data);
  //       // console.log("<><><> 1")

  //     } else if (response.status === "SUCCESS"){
  //       console.log("<><><> 2")
  //       // setTransactions(res?.data);
  //     } 
  //     else if (res.status === 401) {
  //       handleSessionOpen();
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   fetchTransactions()
  // }, []);


  // Currency Formatter
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  // let txns = Object.keys(failedTransactions);

  const rowData = transactions.map((transaction) => {
    return {
      id: transaction._id,
      businessId: transaction.businessId,
      productName: transaction.productName,
      productId: transaction.productId,
      productQuantity: transaction.productQuantity,
      lastSyncAttempt: transaction.lastSyncAttempt,
      quickbooksId : transaction.quickbooksId,
      transamount: transaction.Amount
    }})

  // Fetch Payment Types from DB
  const fetchTransactionTypes = async () => {
    try {
      const response = await fetch(baseUrl + "/api/transactionType", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      if (response.status === 200 && data.Status === "SUCCESS") {
        setTransactionType(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const doFetchTransactionTypes = async () => {
      await fetchTransactionTypes();
    };
    doFetchTransactionTypes().then(() => console.log(transactionType, "check"));
  }, []);

  const paymnentTypes = transactionType.map((type) => {
    return type._id.transactionType;
  });

  const handleChange = (event) => {
    setPaymentType(event.target.value);
  };

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  // filters
  // date range filter
  const filterByDateRange = (data) => {
    return data.filter((item) =>
      dropDown === "range"
        ? item.transtime >=
            moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") &&
          item.transtime <=
            moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD")
        : item.transtime >= dValue[0] && item.transtime <= dValue[1]
    );
  };

  const filterByPaymentType = (data) => {
    return data.filter((item) => {
      if (paymentType === "All") {
        return item;
      } else if (item.transactionType === paymentType.includes(paymnentTypes)) {
        return item;
      } else if (item.transactionType === paymentType) {
        return item;
      }
    });
  };

  const filterBySearchBox = (data) => {
    return data.filter((item) => {
      if (
        item.businessNo.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.transactionID.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.serialNo.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return item;
      }
    });
  };

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterByDateRange(filteredData);
    filteredData = filterByPaymentType(filteredData);
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [transactionType, searchValue]);

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterByDateRange(filteredData);
    filteredData = filterByPaymentType(filteredData);
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [dValue, searchValue]);

  // useEffect(() => {
  //   if (dropDown === "Today") {
  //     onDValueChange([
  //       moment(new Date()).format("YYYY-MM-DD"),
  //       moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
  //     ]);
  //   } else if (dropDown === "YesterDay") {
  //     onDValueChange([
  //       moment(addDays(new Date(), -1)).format("YYYY-MM-DD"),
  //       moment(addDays(new Date(), 0)).format("YYYY-MM-DD"),
  //     ]);
  //   } else if (dropDown === "7days") {
  //     onDValueChange([
  //       moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"),
  //       moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
  //     ]);
  //   } else if (dropDown === "this Month") {
  //     onDValueChange([
  //       moment(
  //         new Date(new Date().getFullYear(), new Date().getMonth())
  //       ).format("YYYY-MM-DD"),
  //       moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
  //     ]);
  //   } else if (dropDown === "last Month") {
  //     onDValueChange([
  //       moment(
  //         new Date(new Date().getFullYear(), new Date().getMonth() - 1)
  //       ).format("YYYY-MM-DD"),
  //       moment(
  //         new Date(new Date().getFullYear(), new Date().getMonth())
  //       ).format("YYYY-MM-DD"),
  //     ]);
  //   } else if (dropDown === "range") {
  //     setIsOpen(true);
  //     handleRange();
  //     onDValueChange([
  //       moment(
  //         new Date(new Date().getFullYear(), new Date().getMonth() - 1)
  //       ).format("YYYY-MM-DD"),
  //       moment(
  //         new Date(new Date().getFullYear(), new Date().getMonth())
  //       ).format("YYYY-MM-DD"),
  //     ]);
  //   }
  // }, [dValue, searchValue ]);

  // handle delay
  function delayChange() {
    setDropDown("Today");
  }

  // handle drop down change
  const dropChange = (event) => {
    setDropDown(event.target.value);
  };

  // handle custom range
  const handleRange = () => {
    const x = document.getElementById("rangeDiv");
    if (x.style.display === "none") {
      x.style.display = "flex";

      // work on open calendar
    } else if (x.style.display === "flex") {
      x.style.display = "none";
      setIsOpen(false);
      setTimeout(delayChange, 5000000);
    } else {
      x.style.display = "none";
    }
  };

  // CSV Export Format
  const csvColumns = [
    { label: "Receipt No.", key: "transactionID" },
    { label: "Product Category", key: "productCategory" },
    { label: "Product Name", key: "productName" },
    { label: "Payment Mode", key: "paymentChanel" },
    { label: "Terminal User", key: "cashier" },
    { label: "Date", key: "transtime" },
    { label: "Amount", key: "transamount" },
  ];

  // CSV Export
  const csvReport = {
    data: filteredData,
    headers: csvColumns,
    filename:
      paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.csv`,
  };

  // columns selection for Excel Export
  const pdfData = filteredData.map((transaction) => {
    return {
      ReceiptNo: transaction.transactionID,
      productName: transaction.productName,
      productCategory: transaction.productCategory,
      PaymentMode: transaction.paymentChanel,
      TerminalUser: transaction.cashier,
      Date: transaction.transtime,
      Amount: transaction.transamount,
    };
  });

  //Excel Export Format
  const xlsxReport = (e, data) => {
    console.log(data);
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.xlsx`
    );
  };

  //PDF export Format
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title =
      paymentType.toUpperCase() +
      ` SALE REPORT FROM ${dValue[0]} TO ${dValue[1]}`;
    const headers = [
      [
        "Receipt No",
        "Product Category",
        "Product Name",
        "Payment Mode",
        "Terminal User",
        "Date",
        "Amount",
        "Item",
      ],
    ];

    const data = pdfData.map((data) => [
      data.ReceiptNo,
      data.productCategory,
      data.productName,
      data.PaymentMode,
      data.TerminalUser,
      moment(data.Date).format("llll"),
      numberFormat(data.Amount),
    ]);
    // console.log(data);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.pdf`
    );
  };

  return (
    <div>
      <div>
        <div>
          <div>
            {/*sessionModal*/}
            <SessionModal
              onClose={handleSessionClose}
              sessionOpen={sessionOpen}
            />

            <Grid container sx={{ flex: 1 }}>
              <Grid item>
                <label
                  style={{
                    width: "158px",
                    height: "35px",
                    margin: "10px 32px 21px 12px",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.32",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#dc3545",
                  }}
                >
                  Failed Transactions
                </label>
              </Grid>
            </Grid>

            <Grid container sx={{ margin: "0px 0px 20px 0px" }}>
              <Grid item>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  Dashboard
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  \
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  Failed Transactions
                </span>
              </Grid>
            </Grid>
            
            <Grid container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3} columns={8}>

                  {/* <Grid item sx={{ margin: "0px 0px 0px 12px" }}>
                    <Form.Select
                      value={paymentType}
                      onChange={handleChange}
                      aria-label="Default select example"
                      style={{
                        borderRadius: "6px",
                        fontSize: "12px",
                        color: "#272d3b",
                        width: "220px",
                        height: "36px",
                        padding: "0px 0px 0px 17px",
                        border: "solid 1px #b4bcc4",
                        backgroundColor: "#fff",
                      }}
                    >
                      <option value="All">All</option>
                      {transactionType.map((type) => (
                        <option
                          sx={{ fontFamily: "Poppins", fontSize: "12px" }}
                          value={type._id.transactionType}
                        >
                          {type._id.transactionType}
                        </option>
                      ))}
                    </Form.Select>
                  </Grid> */}
                      
                  {/* {Date Range} */}
                  {/* <label
                    style={{
                      width: "61px",
                      height: "15px",
                      margin: "35px 0px 12px 20px",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Date Range
                  </label> */}
                  
                  {/* <Grid item xs={4.65}>
                    <Form.Select
                      size="sm"
                      aria-label="Default select example"
                      style={{
                        borderRadius: "6px",
                        fontSize: "12px",
                        color: "#272d3b",
                        width: "220px",
                        height: "36px",
                        padding: "0px 0px 0px 17px",
                        border: "solid 1px #b4bcc4",
                        backgroundColor: "#fff",
                      }}
                      value={dropDown}
                      onChange={dropChange}
                    >
                      <option value="Today">Today</option>
                      <option value="YesterDay">Yesterday</option>
                      <option value="7days">Last 7 Days</option>
                      <option value="this Month">This Month</option>
                      <option value="last Month">Last Month</option>
                      <option value="range">Custom range</option>
                    </Form.Select>
                    <Grid
                      value={dropDown}
                      onChange={dropChange}
                      style={{ display: "none" }}
                      id="rangeDiv"
                    >
                      <DateRangePicker
                        maxDate={new Date()}
                        isOpen={isOpen}
                        onCalendarClose={handleRange}
                        value={dValue}
                        onChange={onDValueChange}
                      />
                    </Grid>
                  </Grid> */}
                    
                  <Grid
                    item
                    alignContent={"right"}
                    sx={{ margin: "0px 0px 0px 12px" }}
                  >
                    <CSVLink
                      style={{
                        borderRadius: "6px",
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px 0 0",
                        padding: "9px 10px 8px 11px",
                        backgroundColor: "#05d8e1",
                      }}
                      {...csvReport}
                    >
                      {" "}
                      CSV{" "}
                    </CSVLink>

                    <Button
                      onClick={(e) => {
                        xlsxReport(e, pdfData);
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Excel
                    </Button>

                    <Button
                      onClick={() => {
                        exportPDF();
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 0 0 12px",
                        padding: "9px 12px 8px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      PDF
                    </Button>

                  </Grid>

                  {/* <Grid>
                    <Button
                      onClick={() => console.log("SYNCING SALES")}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 0 0 1px",
                        padding: "9px 12px 8px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      <SyncIcon/>
                      Sync
                    </Button>
                  </Grid> */}
                </Grid>
                
                <Grid container spacing={2} columns={8}>
                  <Grid item xs={6}></Grid>

                  <Grid
                    alignItems={"right"}
                    item
                    sx={{ margin: "20px 0px 0px 12px" }}
                  >
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Search:
                    </label>
                    <input
                      value={searchValue}
                      onChange={handleSearchBox}
                      style={{
                        fontSize: "12px",
                        color: "#272d3b",
                        borderRadius: "6px",
                        width: "238px",
                        height: "36px",
                        margin: "0 0 0 20.4px",
                        padding: "7px 107.7px 6.4px 11.1px",
                        objectFit: "contain",
                        border: "solid 1px #032541",
                      }}
                      placeholder="search here"
                    ></input>
                  </Grid>
                </Grid>

                <Grid container spacing={2} columns={8}>
                  <Button
                    onClick={() => {console.log("SYNCING SALES"), syncHandler}}
                    sx={{
                      fontWeight: 600,
                      color: "#fff",
                      width: "110px",
                      height: "37px",
                      margin: "10px 60px 0px 1100px",
                      padding: "9px 12px 8px",
                      backgroundColor: "#dc3545",
                    }}
                  >
                    <SyncIcon/>
                    Sync
                  </Button>
                </Grid>

                <Grid
                  sx={{
                    height: "500px",
                    margin: "50px 0 0 0",
                    border: "solid 0px transparent",
                    backgroundColor: "#fff",
                  }}
                >
                  <DataGrid
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      color: "#272d3b",
                    }}
                    rows={rowData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                  ></DataGrid>
                </Grid>
              
              </Box>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntergrationSales;
