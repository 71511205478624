import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function PayablesAdmin() {
    const classes = useStyles();
    const [open, setOpen] = useState(false)

    const handleClick = () => { setOpen(!open); };

    return (
        <div>
            <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
                <div style={{ marginRight: '5%' }}>
                    <LocalLibraryIcon />
                </div>
                <ListItemText primary="Payables Admin" style={{ fontFamily: 'Poppins' }} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <div style={{ marginLeft: '8%' }}>
                        {/* <Link style={{ textDecoration: 'none', color: '#fff' }} to="">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Suppliers/Vendors" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Other Payments" />
                            </ListItem>
                        </Link>
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Approvers" />
                            </ListItem>
                        </Link> */}
                        <Link style={{ textDecoration: 'none', color: '#fff' }} to="/payables/admin/transactions">
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Transactions" />
                            </ListItem>
                        </Link>
                    </div>
                </List>
            </Collapse>
        </div>
    )
}