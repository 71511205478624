import Dash from "../../../common/dash";
import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import {Button, IconButton, Table, TableCell, TableHead, TableRow} from "@mui/material";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import cardIcon from '../../../common/images/card-icn.svg'
import creditIcon from '../../../common/images/creditbal-icn.svg'
import mpesaIcon from '../../../common/images/mpesa-icn.svg'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import { format } from 'date-fns'
import lodash from "lodash";
import {ReceivedPayment} from "../../Modals/Invoice/receivedPayment";
const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	address_text_common: {
		fontSize: "13px",
		fontWeight: "300",
	},
	table_titles: {
		fontSize: "14px",
		fontWeight: "700",
	},
	table_body: {
		fontSize: "13px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	footer_text: {
		fontSize: "10px",
		fontWeight: "300",
		padding: theme.spacing(0.25),
	},
	totals: {
		fontSize: "14px",
		padding: theme.spacing(0.25),
	},
	p1: {
		padding: theme.spacing(0.25),
	},
	p2: {
		padding: theme.spacing(0.5),
	},
	p3: {
		padding: theme.spacing(1),
	},
	mt4: {
		marginTop: theme.spacing(4),
	},
	m1: {
		margin: theme.spacing(1),
	},
	textLeft: {
		textAlign: "left",
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
}));

const getTenantDetailsUrl = baseUrl + "/api/get_customer_by_number";

const TenantInvoicePreview = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const date = new Date();
	const formattedDate = format(date, "EEEE, MMMM dd, yyyy");
	const [businessName, setBusinessName] = useState(localStorage.getItem("businessName"));
	const [invoiceData, setInvoiceData] = useState(JSON.parse(localStorage.getItem("invoice_data")));
	const [businessInfo, setBusinessInfo] = useState()
	const [responseData, setResponseData] = useState()
	const [receivePaymentOpen, setReceivePaymentOpen] = useState(false)
	const [tenantDetails, setTenantDetails] = useState([])
	const [pushData, setPushData] = useState([])
	const [billable, setBillable] = useState()
	const [utils, setUtils] = useState()
	
	console.log(`Invoice Data`, invoiceData)
	
	const fetchTenantDetails = async () => {
		try {
			const tenantDetailsResponse = await fetch(
				getTenantDetailsUrl + `?customerId=${invoiceData?.customerId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),},
				}
			);
			await tenantDetailsResponse.json()
				.then((data) => {console.log(`Tenant Data`, data); setTenantDetails(data.data)})
				.catch((e) => {console.log(`Error Getting Tenant Data`, e.message)})
		} catch (error) {
			console.log(error.message);
		}
	};
	
	const downloadPDF = () => {
		const input = document.getElementById('downloadable');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png', 1.0);
				const pdf = new jsPDF("p","px", [800, 900]);
				pdf.addImage(imgData, 'JPEG', 0, 0,  800, 900);
				pdf.save(`tenant_invoice.pdf`);
			})
	}
	const back = () =>{navigate(-1)}
	
	const getBusinessDetails = async () => {
		try {
			const businessDetailsResponse = await fetch(baseUrl+"/api/get_business_info",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Authorization": localStorage.getItem("X-Authorization"),
					},
				}
			);
			
			if (businessDetailsResponse.status === 200) {
				const businessDetailsData = await businessDetailsResponse.json();
				console.log("business data ", businessDetailsData.data)
				setBusinessInfo(businessDetailsData.data);
			}
		} catch (error) {
			console.error("error fetching customer details",error.message);
		}
	};
	
	const cleanBillable = () => {
		const e = invoiceData.billableItems
		const moded = e.map((ut, index) => {
			const modifiedUtil = ut
			let newUtil = {}
			
			newUtil.productId = modifiedUtil.productId
			newUtil.productName = modifiedUtil.itemNumber
			newUtil.quantity = 1
			newUtil.sellingPrice = modifiedUtil.amount
			newUtil.total = modifiedUtil.amount
			return newUtil
		})
		setBillable(moded)
	}
	
	useEffect(()=>{
		let newArr = [...pushData]
		console.log(`pushData here changing`, newArr)
	},[pushData])
	
	const cleanUtilities = () => {
		const d = invoiceData.billableItems[0].extraServices
		const moded = d.map((ut, index) => {
			const modifiedUtil = ut
			let newUtil = {}
			
			newUtil.productId = modifiedUtil.productId
			newUtil.productName = modifiedUtil.name
			newUtil.quantity = 1
			newUtil.sellingPrice = modifiedUtil.amount
			newUtil.total = modifiedUtil.amount
			return newUtil
		})
		setUtils(moded)

	}
	
	console.log(`Custoemr Billable id`, invoiceData[0])
	
	const saveInvoice = async () => {
		let data = {}
		data.customerId = invoiceData?.customerId
		data.type = invoiceData?.invoiceType
		data.frequency = invoiceData?.invoiceFrequency
		data.products = pushData
		data.billableItemId = invoiceData?.billableItems[0]?._id
		data.itemNumber = invoiceData?.billableItems[0]?.itemNumber
		let url = baseUrl + '/api/generateInvoice';
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-Authorization': localStorage.getItem("X-Authorization"),
			},
			body: JSON.stringify(data),
		});
		const responseData = await response.json();
		console.log("save invoice response data",responseData )
		setResponseData(responseData);
		console.log("response test",responseData.data)
	};
	
	const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);
	
	useEffect(() => {
		fetchTenantDetails()
			.then(() => {console.log('Getting Business Details')})
			.catch((e) => {console.log('Error getting Business Details', e.message)})
		getBusinessDetails()
			.then(() => {console.log('Getting Business Details')})
			.catch((e) => {console.log('Error getting Business Details', e.message)})
		
	}, [])
	
	useEffect(()=>{
		const runFunctions = async () => {
			await cleanBillable()
			cleanUtilities()
		}
		runFunctions()
	},[])
	useEffect(()=>{
		if(utils && billable){
			setPushData([...billable,...utils])
		}
	},[utils, billable])
	
	const rentTotal = lodash.sumBy(invoiceData.billableItems, rent => {return rent.amount})
	const utilsTotals = lodash.sumBy(invoiceData.billableItems[0].extraServices, util => {return util.amount})
	const total = rentTotal + utilsTotals
	
	console.log('Tenant Details', tenantDetails)
	
	return (
		<div>
			<div className={'container-fluid'} style={{ width: "80%" }}>
				<div>
					<div>
						<div style={{display:"flex", justifyContent:"space-between"}}>
							<div style={{paddingTop: 15, display:"flex"}}>
                                <span>
                                     <IconButton aria-label="back" onClick={()=>back()}>
                                        <KeyboardBackspaceOutlinedIcon sx={{color:"#dc3545", fontSize:"25px"}} />
                                    </IconButton>
                                </span>
								<span style={{"fontFamily":"Poppins","fontSize":"25px","fontWeight":"600","textAlign":"left","color":"#dc3545"}}>Preview Invoice</span>
							</div>
							<div style={{padding:10, display:"flex",}}>
								{responseData && responseData.status === "SUCCESS" ?(
									<div>
										<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"transparent", color:"#032541", border:"solid 1px #032541", marginRight:2}} onClick={()=>setReceivePaymentOpen(true)}>
											Received Payment
										</Button>
										<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"#032541", color:"white"}}>
											Resend Invoice
										</Button>
									</div>
								):(
									<Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"#032541", color:"white"}} onClick={()=>saveInvoice()}>
										Send Invoice
									</Button>
								)}
								<span style={{backgroundColor: "rgba(3, 37, 65, 0.05)", width:"45px", height:"45px", display:"flex", justifyContent:"center", marginLeft:5, borderRadius:5}}>
                                    <IconButton aria-label="download" onClick={downloadPDF}>
                                        <FileDownloadOutlinedIcon sx={{color:"#032541", fontSize:"25px"}} />
                                    </IconButton>
                                </span>
							</div>
						</div>
						
						<div style={{display: "flex", flexDirection: "column", overflow: "visible", height: "auto"}} id="downloadable">
							<div className={classes.p3} style={{backgroundColor: "rgba(220,53,69,0.05)", height: "150px", display: "flex", justifyContent: "space-between",}}>
								<div className={classes.p1} style={{display: "flex", flexDirection: "column",}}>
									<span style={{color: "black", fontSize: "40px", fontWeight: "700",}}>INVOICE</span>
									<span style={{color: "black", fontSize: "14px", fontWeight: "700",}}>Date:</span>
									<span style={{ fontSize: "14px" }}>{formattedDate}</span>
								</div>
								<div style={{display: "flex", flexDirection: "column",}}>
                                    <span style={{color: "#dc3545", fontSize: "40px", fontWeight: "700",}}>Unpaid</span>
									<span style={{color: "black", fontSize: "14px", fontWeight: "700",}}>Invoice No:</span>
									{responseData && responseData.status === "SUCCESS" &&(
										<span style={{ fontSize: "14px" }}>{responseData.data.invoiceNumber}</span>
									)}
								</div>
							</div>
							
							<div className={classes.p3} style={{display: "flex", justifyContent: "space-between", flexDirection: "row",}}>
								<div style={{display: "flex", flexDirection: "column",}}>
									<span style={{fontSize: "18px",}}>From:</span>
									<span className={classes.address_text_common} style={{ fontWeight: "700" }}>{businessName}</span>
									<span className={classes.address_text_common}>{businessInfo?.businessOwnerAddress}, {businessInfo?.country}</span>
									<span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
									<span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
								</div>
								<div className={classes.mt4} style={{display: "flex", flexDirection: "column", textAlign: "right",}}>
									<span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
									<span className={classes.address_text_common}>{tenantDetails[0]?.firstName + " " + tenantDetails[0]?.lastName}</span>
									<span className={classes.address_text_common}>{tenantDetails[0]?.email}</span>
									<span className={classes.address_text_common}>{tenantDetails[0]?.phone}</span>
								</div>
							</div>
							{
								invoiceData.billableItems.map((product, index) => (
									<div>
										<Grid container justifyContent={'flex-start'} direction={'row'} style={{marginTop : '1%', marginBottom : '1%', border: "dotted 4px #bfbfbf", height : "45px"}}>
											<Grid item style={{marginRight : "15%", padding : '0.5%'}}>
												<span>HOUSE NUMBER: </span>
												<span style={{fontWeight : "bold"}}>{product.itemNumber}</span>
											</Grid>
											<Grid item style={{padding : '0.5%'}} >
												<span>HOUSE TYPE: </span>
												<span style={{fontWeight : "bold"}} >{product.unitType}</span>
											</Grid>
										</Grid>
										
										{/*Table*/}
										<Table>
											<TableHead>
												<TableRow>
													<TableCell className={classes.textLeft} style={{fontWeight : "bold"}}>
														Description
													</TableCell>
													<TableCell className={classes.textCenter} style={{fontWeight : "bold"}}>
														Price (KES)
													</TableCell>
													<TableCell className={classes.textCenter} style={{fontWeight : "bold"}}>Qty</TableCell>
													<TableCell className={classes.textRight} style={{fontWeight : "bold"}}>
														Total (KES)
													</TableCell>
												</TableRow>
											</TableHead>
											
											{/*Rent*/}
											<TableRow key={index} style={{textAlign : 'right'}}>
												<TableCell className={classes.textLeft}>
													<span className={classes.table_body}>{product.itemNumber ? "Rent" : product.itemNumber}</span>
												</TableCell>
												<TableCell className={classes.textCenter}>
													<span className={classes.table_body}>{numberFormat(product?.amount)}</span>
												</TableCell>
												<TableCell className={classes.textCenter}>
													<span className={classes.table_body}>{product.quantity || 1}</span>
												</TableCell>
												<TableCell style={{textAlign : 'right'}} className={classes.textRight}>
													<span style={{textAlign : 'right'}} className={classes.table_body}>{numberFormat(product.amount)}</span>
												</TableCell>
											</TableRow>
											
											{/*Utilities*/}
											{
												product.extraServices.map((utility, index) => (
													<TableRow key={index}>
														<TableCell className={classes.textLeft}>
															<span className={classes.table_body}>{utility.name}</span>
														</TableCell>
														<TableCell className={classes.textCenter}>
															<span className={classes.table_body}>{numberFormat(utility?.amount)}</span>
														</TableCell>
														<TableCell className={classes.textCenter}>
															<span className={classes.table_body}>{product.quantity || 1}</span>
														</TableCell>
														<TableCell style={{textAlign : 'right'}} className={classes.textRight}>
															<span style={{textAlign : 'right'}} className={classes.table_body}>{numberFormat(utility.amount)}d</span>
														</TableCell>
													</TableRow>
												))
											}
										</Table>
									</div>
								))
							}
							
							<div className={classes.p3} style={{display: "flex", justifyContent: "end",}}>
								<div className={classes.p1} style={{backgroundColor: "#f7f7f7", width: "288px", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center",}}>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span>Subtotal</span>
										<span>{numberFormat(total)}</span>
									</div>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span>Discount</span>
										<span>KES 0.00</span>
									</div>
									<div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
										<span style={{ color: "#dc3545", fontWeight: "bold" }}>Total</span>
										<span style={{fontSize: "16px", color: "#dc3545", fontWeight: "bold",}}>{numberFormat(total)}</span>
									</div>
								</div>
							</div>
							
							<div style={{ borderTop: "dotted 4px #bfbfbf", borderBottom: "dotted 4px #bfbfbf" }}>
								<div className={classes.p3} style={{ display: "flex", flexDirection: "column" }}>
									<div className={classes.p1} style={{ justifyContent : "center", alignContent: 'center', textAlign : 'center'}} >
										<span style={{color: "#032541", fontWeight: "700", fontSize: "16px",}}>Accepted Payment Methods</span>
									</div>
									<div style={{ display: "flex", justifyContent : "center", alignContent: 'center'}}>
										<div style={{display:"flex", flexDirection:"column"}}>
											<img className={classes.p2} src={mpesaIcon} alt="M-pesa"/>
											<div style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
												<span>M-Pesa</span>
											</div>
										</div>
										<div style={{display:"flex", flexDirection:"column",}}>
											<img className={classes.p2} src={cardIcon} alt="Visa"/>
											<div style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
												<span>Card</span>
											</div>
										</div>
										<div style={{display:"flex", flexDirection:"column",}}>
											<img className={classes.p2} src={creditIcon} alt="Credit"/>
											<div  style={{display:"flex", justifyContent:"center", fontSize: "12px"}}>
												<span>Credit Balance</span>
											</div>
										
										</div>
									</div>
								</div>
							</div>
							
							<div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
								<div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
									This is a system-generated invoice is created without any
									alteration whatsoever.
									<br />
									Thank you for your business.
								</div>
							</div>
							<div className={classes.p2} style={{backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center",}}>
                                <span className={classes.footer_text}>Powered by <span style={{fontWeight:"700"}}>ZED Payments Limited</span></span>
								<span className={classes.footer_text}>.</span>
								<span className={classes.footer_text}>info@zed.business</span>
								<span className={classes.footer_text}>.</span>
								<span className={classes.footer_text}>v1.0.2</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*Received payment*/}
			<ReceivedPayment paymentOpen={receivePaymentOpen} onClose={() => setReceivePaymentOpen(false)} />
		</div>
	);
};

export default TenantInvoicePreview;
