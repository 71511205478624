import {Box} from "@mui/material";
import GeneralBusinessMultiSetupForm from "./components/GeneralBusinessMultiSetupForm";
const GeneralBusinessSetup = ({category}) =>{
  return(
    <Box component="div">
      <GeneralBusinessMultiSetupForm category={category}/>
    </Box>
  )
}

export default GeneralBusinessSetup;