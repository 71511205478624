import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {makeStyles} from "@mui/styles";
import { closeSideBar } from "../../features/sideBarSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Bills (props) {
	const classes = useStyles();
	const [active, setActive] = React.useState(false);
	const handleClick = () => {setActive(!active)};
	const dispatch = useDispatch()
	const moveToPos = () => {
		dispatch(closeSideBar({ sideBarState: false }));
	}
	return (
		<div>
			<Link style={{textDecoration: 'none', color : '#fff'}} onClick={()=> moveToPos()} to={"/pos/unpaid"} >
				<ListItem button className={classes.nested} onClick={handleClick} style={{ color: active ? '#04f2fc' : '#fff'  }}>
					<div style={{marginRight : '5%'}}>
						<ReceiptLongIcon />
					</div>
					<div>
						<ListItemText primary= "POS" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}