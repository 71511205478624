import React, { useState, useEffect} from 'react';
import {Box, Breadcrumbs, Button, Step, StepLabel, Stepper, Typography} from "@mui/material";
import back from "../../School/Images/back-icn.svg"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {useNavigate} from "react-router-dom";
import HttpComponent from "../../School/MakeRequest";
import {useSelector} from "react-redux";

// component imports
import GeneralBusinessDetails from './GeneralBusinessDetails';
import GeneralBusinessServiceCategory from './GeneralBusinessServiceCategory';
import GeneralBusinessServices from './GeneralBusinessServices';
import GeneralBusinessPaymentOptions from './GeneralBusinessPaymentOptions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaymentSettingUp from '../../paymentmodule/paymentsettingup';

const GeneralBusinessMultiSetupForm = () =>{
  const steps = ['Business Details', 'Payment Options'];
    const [activeStep, setActiveStep] = useState(0);
    const { userId ,X_Authorization, businessCategory } = useSelector((store) => store.user);
    const { category} = useSelector((store) => store.category)

    const checkFormStatus =() =>{
      HttpComponent({
          method: 'GET',
          url: '/api/get_setup_status',
          body: null,
          token: X_Authorization,
      }).then((data)=>{
          if(data.status === 201){
              let state = data?.response?.data?.workflowState;
              if(state === "BASIC"){
                  setActiveStep(1)
              }else if(state === "PAYMENT"){
                  setActiveStep(2)
              }
          }else{

          }
      }).catch((error)=>{
          console.error(error.message);
      })
  }

  useEffect(() => {
    checkFormStatus();
}, []);
const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
};
const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
};

const breadcrumbs = [
  <Typography key="X" sx={{color:"#707070",fontSize:"0.875rem"}}>
      Dashboard
  </Typography>,
  <Typography key={steps[activeStep]} sx={{color:"#dc3545", fontSize:"0.875rem"}}>
      {steps[activeStep]}
  </Typography>
];

const renderFormContent = () => {
  switch (activeStep) {
      case 0:
          return (
              <Box component="div" sx={{width:"100%"}}>
                  <GeneralBusinessDetails next={handleNext}/>
              </Box>
          );
      case 1:
          return(
              <Box component="div"
                   sx={{width:"100%"}}
              >
                 <PaymentSettingUp next={handleNext}/>
              </Box>
          )
      default:
          return null;
  }
};
const navigate = useNavigate();
  return (
    <Box component="div" sx={{width:"100%", display:"flex", flexDirection:"column", marginTop:"-2%"}}>
    <Box sx={{display:"flex"}} onClick={()=>navigate("/createBusiness")}>
        <img src={back} alt=""/>
        <Typography sx={{color:"#032541", fontSize:"1.563rem", marginLeft:"2%", fontWeight:700}}>
          Create Business
        </Typography>
       <Box style={{ display:'flex', alignItems:'center', marginLeft:'20px', color:'#707070' }}>
        <ArrowForwardIosIcon style={{ fontSize:'1rem' }}/>
        <Typography sx={{ fontSize:"1.3rem", marginLeft:"2%", fontWeight:500, textTransform:'capitalize'}}>{category}</Typography>
       </Box>
    </Box>
    <Box sx={{marginBottom:4, marginTop:2}}>
        <Breadcrumbs
            separator={<FiberManualRecordIcon sx={{fontSize:"0.625rem", color:"#e1e5e8"}} />}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
    </Box>
    <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
            return (
                <Step key={label}>
                    <StepLabel
                        StepIconProps={{
                            classes: {
                                completed: 'step-icon-completed',
                                text: index !== activeStep ? "step-icon-text" : null,
                            },
                            style: {
                                color: index === activeStep ? '#032541' : '#f5f6f7',
                                width: "2.188rem",
                                height: "2.188rem",
                            }
                        }}
                    >
                        {label}
                    </StepLabel>
                </Step>
            );
        })}
    </Stepper>

    <Box component="div">
        {renderFormContent()}
    </Box>
</Box>
  )
}

export default GeneralBusinessMultiSetupForm;
