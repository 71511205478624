import { Breadcrumbs, Button, TextField, Grid, Typography, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import PhoneInput from "react-phone-input-2";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GeoLocation from "../../../hooks/useLocation";
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux'
import { handleSaveStaffDetails } from "../../../features/staffSlice";


//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Staff</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Teaching Staff</Typography>
]


export default function AddTeachingUniversityStaff() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormData] = useState({ staffId: '', firstName: '', lastName: '', email: '', gender: '' })
    const [userPhone, setPhone] = useState("");
    const [selectedValue, setSelectedValue] = useState('')
    const dispatch = useDispatch()

    // onchange

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }

    const navigate = useNavigate();
   
    // get courses

    const staffDetails = {
        email: formData.email,
        customerAddress: selectedValue?.description,
        lastName: formData.lastName,
        gender: formData.gender,
        firstName: formData.firstName,
        phone: "+" + userPhone,
        admissionNo: formData.staffId,
    }


    const handleNext = () => {
        dispatch(handleSaveStaffDetails(staffDetails))
        navigate(`/school/universityStaff?AddTeachingUnit`)
    }


    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Staff</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={1} width={'49%'} mb={1}>
                <TextField style={{ width: '100%' }} placeholder="Staff ID Number" value={formData.staffId} onChange={handleOnChange} name="staffId" />
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="FirstName" value={formData.firstName} onChange={handleOnChange} name="firstName" />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="LastName" value={formData.lastName} onChange={handleOnChange} name="lastName" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <PhoneInput inputStyle={{ height: "55px", width: '100%' }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="email" value={formData.email} onChange={handleOnChange} name="email" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <GeoLocation sx={{ width: "100%" }} onValueChange={(location) => setSelectedValue(location)} selectedValue={selectedValue} />
                    </Grid>
                    <Grid item width={'50%'}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.gender}
                                label="Gender"
                                name="gender"
                                onChange={handleOnChange}
                            >
                                <MenuItem value={'male'}>Male</MenuItem>
                                <MenuItem value={'female'}>Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={2} item width={'49%'} display={'flex'} justifyContent={'flex-end'}>
                <Button disabled={!formData.email || !formData.firstName || !formData.lastName || !formData.gender || !userPhone || !selectedValue?.description} onClick={() => handleNext()} style={!formData.email || !formData.firstName || !formData.lastName || !formData.gender || !userPhone || !selectedValue?.description ? {backgroundColor:'rgba(3, 37, 65, 0.4)' , alignItems: 'center', textTransform: 'inherit' , display: 'flex',}:{ display: 'flex', backgroundColor: '#032541', border: '1px #032541', alignItems: 'center', textTransform: 'inherit' }}> <AddIcon style={{ marginRight: '10px', color: "#fff" }} /> <Typography style={{ color: '#fff' }}>Add Courses & Units</Typography> </Button>
            </Grid>
        </Grid>
    )
}