import { useEffect, useState } from "react";
import { Box, Button, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import HttpComponent from "../MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL;

const KCBPocketMoney = () => {
  const [paybill, setPaybill] = useState("522533");
  const [tillNo, setTillNo] = useState("");
  const [verifyTill, setVerifyTill] = useState("");
  const [message, setmessage] = useState("");
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [accountSetUp, setAccountSetUp] = useState(["Vooma Till", "KCB Account Number"]);
  const [accountToSetup, setAccountToSetUp] = useState("");
  const [kcbaccountType, setAccountType] = useState("")
  const [bankNumber, setAccountNumber] = useState("");
  const [verifyAccountNumber, setVerifyAccountNumber] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if(accountToSetup === "Vooma Till"){
        setAccountType("VOOMATILL")
    }else{
        setAccountType("KCBACCOUNT")
    }
  },[accountToSetup])
  const buttonStyles = {
    width: "7.813rem",
    height: "2.813rem",
    borderRadius: "4px",
  };


  // // get mobile money details!
  const accountDetails = () => {
    HttpComponent({
      method:"GET",
      url:`/api/v1/payments/get_kcb_mpesa_configs?accountType=pocketmoney`,
      token:localStorage.getItem("X-Authorization")
    }).then((data) => {
      if(data?.status === 200 || data?.status === 201){
        console.log("account setpdata", data?.response)
        let kcbAccountType  = data?.response?.kcbAccountType
        let kcbReference = data?.response?.accountReference
        if(kcbAccountType === "VOOMATILL"){
          setAccountToSetUp("Vooma Till")
          setTillNo(kcbReference)
          setVerifyTill(kcbReference)
        }else if(kcbAccountType === "KCBACCOUNT"){
          setAccountToSetUp("KCB Account Number")
          setAccountNumber(kcbReference)
          setVerifyAccountNumber(kcbReference)
        }

        setIsLoading(false)
      }else if(data?.status === 400){
        setIsLoading(false)
      }else{
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    accountDetails()
  }, [])

  // save mobile money details
  const saveMobileMoneyDetails = async () => {
    console.log("businessName", paybill, tillNo, verifyTill);
    if (paybill === " " && accountToSetup === "Vooma Till" ) {
      setmessage("paybill is required");
      setErrorShow({ state: true, message: "Paybill is required" });
    } else if (tillNo === "" && accountToSetup === "Vooma Till" ) {
      //setError(true)
      setmessage("till number is required");
      setErrorShow({ state: true, message: "Till number is required" });
    } else if (verifyTill !== tillNo && accountToSetup === "Vooma Till") {
      //setError(true)
      setmessage("till numbers do not match");
      setErrorShow({ state: true, message: "Till numbers do not match" });
    }else if(accountToSetup === "KCB Account Number" && bankNumber === "" ){
        setErrorShow({ state: true, message: "Account number is required" });
    }else if(accountToSetup === "KCB Account Number" && bankNumber !== verifyAccountNumber ){
        setErrorShow({ state: true, message: "Account number does not match" });
    }
     else {
      HttpComponent({
        method:"POST",
        url:`/api/v1/payments/activate_kcb_mpesa?status=true`,
        body:{
          businessName: localStorage.getItem("businessName"),
          accountNumber: kcbaccountType  === "VOOMATILL" ? tillNo : bankNumber ,
          accountType: "pocketmoney",
          kcbAccountType:kcbaccountType
        },
        token:localStorage.getItem("X-Authorization")
      }).then((data) => {
        if(data?.status === 200 || data?.status === 201){
          setSuccessShow({ state: true, message: "Pocket money Activated successfully" });
          setTimeout(() => {
            window.location.reload();
          }, [1000]);
        }else if(data?.status === 400){
          setErrorShow({ state: true, message: "Error activating pocket money setup" });
        }
      }).catch((e) => {
        console.log(e);
        setErrorShow({ state: true, message: "Error activating pocket money setup" });
      })

    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%", padding: "26px" }}>
      <form style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        {/* accountSetUp */}

        {isLoading && <span>Loading...</span>}

        {
          !isLoading &&         <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Account To SetUp</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={accountToSetup} label="Account To SetUp" onChange={(e) => setAccountToSetUp(e.target.value)}>
            {accountSetUp?.map((acc) => {
              return <MenuItem value={acc}>{acc}</MenuItem>;
            })}
          </Select>
        </FormControl>
        }


        {accountToSetup === "Vooma Till" && !isLoading  && (
          <div>
            {/* <FormControl style={{ width: "100%", display: "flex" }}>
              <InputLabel style={{ fontWeight: "normal", fontSize: "14px" }}>Bank Paybill No.</InputLabel>
              <TextField
                style={{ width: "100%", marginTop: "40px" }}
                id="outlined-multiline-flexible"
                // multiline
                required
                defaultValue="522533"
                onChange={(e) => setPaybill(e.target.value)}
                value={paybill}
              />
            </FormControl> */}

            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
              <InputLabel style={{ fontWeight: "normal", fontSize: "14px" }}>Vooma Till No.</InputLabel>
              <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" value={tillNo} onChange={(e) => setTillNo(e.target.value)} multiline placeholder="e.g 123456" required />
            </FormControl>

        
            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
              <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify Vooma Till No.</InputLabel>
              <TextField style={{ width: "100%", marginTop: "40px" }}  error={ tillNo !== verifyTill ? true : false} id="outlined-multiline-flexible" placeholder="e.g 123456" value={verifyTill} onChange={(e) => setVerifyTill(e.target.value)} multiline required />
            </FormControl>
{/*      
            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
              <InputLabel style={{ fontWeight: "normal", fontSize: "14px" }}>Verify Vooma Till No.</InputLabel>
              <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-multiline-flexible" placeholder="e.g 123456" value={verifyTill} onChange={(e) => setVerifyTill(e.target.value)} multiline required />
            </FormControl> */}
          
          </div>
        )}

        {accountToSetup === "KCB Account Number" && !isLoading && (
          <div>
            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
              <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>KCB Account Number</InputLabel>
              <TextField style={{ width: "100%", marginTop: "40px" }} id="outlined-" value={bankNumber} onChange={(e) => setAccountNumber(e.target.value) } placeholder="e.g 123456" multiline required />
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: "-5px" }}>
              <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "-5px" }}>Verify KCB Account Number</InputLabel>
              <TextField style={{ width: "100%", marginTop: "40px" }} value={verifyAccountNumber} onChange={(e) => setVerifyAccountNumber(e.target.value) } error={bankNumber !==  verifyAccountNumber ? true : false} id="outlined-" placeholder="e.g 123456" multiline required />
            </FormControl>
          </div>
        )}

       

        

        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: "0px", paddingBottom: "20px" }}>
          <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end", marginTop: "30px" }}>
            <Box sx={{ display: "flex" }}>
              {/* <Button sx={{
                                ...buttonStyles,
                                border: " solid 1px #002543",
                                color: "#002543",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                    color: '#002543'
                                }
                            }}
                                onClick={() => showKCBMobileMoneySetup(false)}
                            >
                                Cancel
                            </Button> */}
            {accountToSetup !== "" && <Button
                sx={{
                  ...buttonStyles,
                  marginLeft: 1,
                  backgroundColor: " #032541",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "white",
                  },
                }}
                onClick={saveMobileMoneyDetails}
              >
                Save
              </Button>}
   
            </Box>
          </Box>
        </div>
      </form>
    </Box>
  );
};

export default KCBPocketMoney;
