import React, { useState, useEffect } from "react";
import Breadcrumbcomponent from "./breadcrumb";
import { styled } from "@mui/material/styles";
import { stockStyles } from "./stockStyling";
import { Button, Grid, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import Allreports from "./allReportsComponents";
import ReportsDrillDown from "./stockModals/viewMoreReports";




const REPORT = () => {
  const navigate = useNavigate();
  const { state } = useParams();
  const breadcrumb = [
    <span key={1}>Dashboard</span>,
    <span key={2}>Stock</span>,
    <span key={3} style={{ color: "red" }}>
     {state === "viewMore" ? "Merged Batches Report" : 'Reports'} 
    </span>,
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"} margin={"0px 0px 2% 0px"}>
        <Grid item width={"100%"} margin={"0px 0px 1% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 0% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      <div>
        {state === "newReports" && <Allreports />}
        {state === "viewMore" && <ReportsDrillDown />}
      </div>
    </div>
  );
};

export { REPORT };
