import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { handleBusinessId } from "../../../../features/selectedSlice";
import { handlePartnerId } from "../../../../features/partnerSelectSlice";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

// const options = ["Option 1", "Option 2", "Option 3", "Option 4"];

function partnerSelect() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  let data = useSelector((state) => state.partnerSelect.data);

  // console.log(`data: ${JSON.stringify(data)}`);

  let stringArr = [];

  const createArray = () => {
    if (data) {
      data?.map((obj) => {
        let string = `${obj.partnerId} - ${obj.name}`;
        stringArr.push(string);
      });
      setOptions(stringArr);
    }
  };

  useEffect(() => {
    if (data.length > 0) createArray();
  }, [data]);


  useEffect(() => {
    if (selectedOption) {
        const trimmedString = selectedOption.match(/^\d+/)
        const trimmedString2 = trimmedString[0].toString()
        dispatch(handlePartnerId(trimmedString2))
      }
  }, [selectedOption]);

  return (
    <Autocomplete
      label="select partner"
      variant="outlined"
      fullWidth
      size="small"
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label="select partner" variant="outlined" className={classes.focusedTextField}/>
      )}
    />
  );
}

export default partnerSelect;
