import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import Category from "./category";
import Categoryproducts from "./categoryproducts";
import { stockStyles } from "../stockStyling";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { useNavigate } from "react-router-dom";


const CategoryAndTable = (props) => {

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");
  const [productValue, setProductValue] = useState("")

  

  const [categoryId, setCategoryId] = useState("");
  const [batchStockProducts, setBatchProducts] = useState([]);
  const storeId = props?.storeId;
  const supplierId = props?.supplierId;
  const setComponentToShow = props?.setComponentToShow;

  const toNext = () => {
    const newItems = JSON.parse(localStorage.getItem("addStockProducts"));

    if(newItems){
      navigate(`/addstock/viewBatch?${storeId}?${supplierId}`)
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid container width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} margin={"0% 0% 1% 0%"}>
        <Grid item sx={{width:"30%"}}>
          <SearchFilter placeholder={'Search Category'} setInputValue={setInputValue} />
        </Grid>
        <Grid item sx={{ width: "50%", display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        <SearchFilter placeholder={'Search products'} setInputValue={setProductValue} />
          <Button onClick={() => {
            setComponentToShow(0)
            setBatchProducts([])
          }} style={stockStyles?.cancelButton}>Cancel</Button>
          <Button style={stockStyles?.viewBatchButton} onClick={() => toNext()}>
            View Batch
          </Button>
        </Grid>
      </Grid>
      <Grid container direction={"row"} width={"100%"} spacing={2}>
        <Grid item xs={4}>
          <Category inputValue={inputValue} setCategoryId={setCategoryId} />
        </Grid>

        <Grid item xs={8}>
          <Categoryproducts categoryId={categoryId} storeId={storeId} productValue={productValue} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryAndTable;
