import {
  TextField,
  Stack,
  Grid,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import axios from "axios";
import {
  handleActivated,
  handleDeleted,
} from "../../../../features/AlertSlice";
import NewTable from "./NewTable";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import BranchesTable from "./BranchesTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));


const baseUrl = process.env.REACT_APP_BASE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewBranches() {
  const classes = useStyles()
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog2 = () => {
    setDeleteOpen(true);
  };

  const handleClose2 = () => {
    setDeleteOpen(false);
  };

  const businessId = useSelector((state) => state.DetailsOfbusiness.businessId);

  const handleActivate = () => {
    try {
      axios
        .post(baseUrl + "/api/activateBusiness", {
          businessId,
        })
        .then((response) => {
          if (response) {
            console.log("activated successfully");
            dispatch(handleActivated(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = () => {
    try {
      axios
        .post(baseUrl + "/api/deleteBusiness", {
          businessId,
        })
        .then((response) => {
          if (response) {
            console.log("deleted successfully");
            dispatch(handleDeleted(true));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (clickedItem === "Activate") {
      handleOpenDialog();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  useEffect(() => {
    if (clickedItem === "Delete") {
      handleOpenDialog2();
    }
    dispatch(removeClicked());
  }, [clickedItem]);

  const businessName = useSelector(
    (state) => state.DetailsOfbusiness.businessName
  );

  return (
    <Stack bgcolor={"white"} spacing={1} p={2}>
      <Stack direction="row"spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          {`${businessName}'s Branches `}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs2
          point1={"Businesses"}
          link1={"/Businesses"}
          point2={"Branches"}
        />
      </Stack>
      <Stack>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <TextField
                id="input-with-icon-textfield"
                label="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                className={classes.focusedTextField}
              />

              <Button
                variant="outlined"
                sx={{
                  bgcolor: "#f5f6f7",
                  borderColor: "#f5f6f7",
                  color: "#032541",
                  ":hover": {
                    borderColor: "#032541",
                  },
                  textTransform: "none",
                }}
              >
                Export
                <MoreVertIcon
                  sx={{
                    color: "#032541",
                  }}
                />
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <BranchesTable />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default ViewBranches;
