import { Breadcrumbs, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, Menu, MenuItem, Select, Slide, Tab, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { SuccessAlert } from "../../../components/snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../components/snackBar Alerts/errorAlert";
import { forwardRef, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../components/No Rows/noRowsOverlay";
import CustomSearchInput from "../../../components/School/CustomSearchInput";
import CustomSelect from "../../../components/School/CustomSelectField";
import ExportMenu from "../../../components/School/ExportMenu";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HttpComponent from "../../../components/School/MakeRequest";
import AdjustBusinessConvenienceFee from "./editConvenienceFee";
import { useNavigate } from "react-router-dom";
import WarningImg from "../../../images/warning.png"
import RestoreImg from "../../../images/restoreImg.svg"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(5),
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.error">Convenience Fee</Typography>
]

export default function ConveninienceFee() {
    const [tabValue, setTabValue] = useState('Active')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [businestype, setBusinessType] = useState([])
    const [selectedbstype, setSelectedbstype] = useState('')
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [searchValue, setSearchValue] = useState('')
    const [businessN, setBusinessN] = useState(null)

    const [restoreIcon, setRestoreIcon] = useState(false)
    const [openDeleteDialog, setopenDeleteDialog] = useState(false)

    const handleCloseRestore = () => { setRestoreIcon(false) }
    const handleOpenRestore = () => { setRestoreIcon(true) }

    const handleOpenDeleteDialog = () => { setopenDeleteDialog(true) }
    const handleCloseDeleteDialog = () => { setopenDeleteDialog(false) }

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    //url params

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)


    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })



    //fetch all business

    function fetchAllBusinessWith() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/business/convenienceFee?status=${true}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }

            })

        } catch (error) {

        }
    }

    function fetchAllBusinessWithAll() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/business/convenienceFee?status=${false}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }

            })

        } catch (error) {

        }
    }


    useEffect(() => {
        if (tabValue === 'Active') {
            fetchAllBusinessWith();
        } else if (tabValue === 'InActive') {
            fetchAllBusinessWithAll();
        }
    }, [state, tabValue])

    // fetch all business types

    function fetchAllbusinessTypes() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/listBusinessCategory?state=Active`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setBusinessType(data.response.categories)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchAllbusinessTypes();
    }, [])

    //Active business

    const RenderCell = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };
        const handleActionClose = () => { setAnchorEl(null); };

        function handleDeActivateConvenienceFee() {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/v1/business/convenienceFee/enable_disable`,
                    body: {
                        status: false,
                        businessNumber: businessN,
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: 'successfully deactivated convenience' })
                        handleCloseDeleteDialog()
                    } else if (data.status === 400) {
                        setErrorShow({ state: true, message: 'Error deactivated convenience' })
                        handleCloseDeleteDialog()
                    }

                })

            } catch (error) {

            }

        }

        function handleActivateConvenienceFee() {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/v1/business/convenienceFee/enable_disable`,
                    body: {
                        status: true,
                        businessNumber: businessN,
                    },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: 'successfully activated convenience' })
                        handleCloseRestore()
                    } else if (data.status === 400) {
                        setErrorShow({ state: true, message: 'Error activated convenience' })
                        handleCloseRestore()
                    }

                })
            } catch (error) {

            }

        }

        return (
            <div>
                <Dialog
                    open={restoreIcon}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img width="120px" src={RestoreImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Activate  Convenience Fee</h6>
                            <span>Do you want to activate convenience  </span> <br></br>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestore} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={handleActivateConvenienceFee} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Restore</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeleteDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={WarningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Deactivate  convenience fee</h6>
                            <span>Do you want to deactivate convenience fee  </span> <br></br>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={handleDeActivateConvenienceFee} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                    </DialogActions>
                </Dialog>
                <MoreVertIcon onClick={handleClick} />
                {params?.row?.hasConvenienceFee === true ?
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={() => { navigate('/conviniencefee?adjustfee'); setBusinessN(params?.row?.businessNumber); }}>
                            <EditIcon style={{ color: "#032541" }} /> Adjust Fee
                        </MenuItem>
                        <MenuItem disableRipple onClick={() => { handleOpenDeleteDialog(); setBusinessN(params?.row?.businessNumber); setAnchorEl(null) }}>
                            <NotInterestedIcon style={{ color: "#DC3545FF" }} />  Deactivate
                        </MenuItem>
                    </StyledMenu> :
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={() => { handleOpenRestore(); setBusinessN(params?.row?.businessNumber); setAnchorEl(null) }}>
                            <NotInterestedIcon style={{ color: "#DC3545FF" }} />  Restore
                        </MenuItem>
                    </StyledMenu>
                }
            </div>
        )
    }

    const rowData = pageState?.data?.filter((business) => {
        return searchValue.toLowerCase() === '' ? business :
            business?.businessName.toLowerCase().includes(searchValue)
    }).filter((item) => {
        return selectedbstype === '' ? item : selectedbstype === 'ALL' ? item : item?.businessCategory?.toLowerCase().includes(selectedbstype.toLowerCase())
    }).map((item) => {
        return {
            id: item?._id,
            businessName: item?.businessName,
            businessNumber: item?.businessNumber,
            businessCategory: item?.businessCategory,
            convenientFeeAmount: item?.convenientFeeAmount,
            hasConvenienceFee: item?.hasConvenienceFee
        }
    })

    const ActiveColumns = [
        { headerName: "businessName", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Business Name</strong>) } },
        { headerName: "businessNumber", field: 'businessNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Business ID</strong>) } },
        { headerName: "businessCategory", field: 'businessCategory', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Business Type</strong>) } },
        { headerName: "convenientFeeAmount", field: 'convenientFeeAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Convenience Fee</strong>) } },
        { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: RenderCell },
    ]

    //export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "businessName": data?.businessName,
            "businessNumber": data?.businessNumber,
            "businessCategory": data?.businessCategory,
            "convenientFeeAmount": data?.convenientFeeAmount,
        }
    })
    const fileHeaders = [["businessName", "businessNumber", "businessCategory", "convenientFeeAmount"]]
    const csvColumns = [
        { label: "businessName", key: "businessName" },
        { label: "businessNumber", key: "businessNumber" },
        { label: "businessCategory", key: "businessCategory", },
        { label: "convenientFeeAmount", key: "convenientFeeAmount" },
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.businessName, data?.businessNumber, data?.businessCategory, data?.convenientFeeAmount]);
    return (
        <Grid direction={'column'}>
            {state === 'adjustfee' ? <AdjustBusinessConvenienceFee businessNumber={businessN} /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item mt={3}>
                    <Typography style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Convenience Fee</Typography>
                </Grid>
                <Grid item mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'} direction={'row'}>
                        <Grid item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'}>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={5}>
                    <TabContext value={tabValue}>
                        <AntTabs onChange={handleChange}>
                            <AntTab label="Active" value="Active" />
                            <AntTab label="InActive" value="InActive" />
                        </AntTabs>
                        <TabPanel value="Active">
                            <Grid item width={'100%'} mb={3}>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item display={'flex'} flex={1} alignItems={'center'}>
                                            <CustomSearchInput name={'searchValue'} placeholder={'Business Name'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                            <Grid item marginLeft={3} mt={-1}>
                                                <FormControl style={{ width: "300px" }}>
                                                    <InputLabel id="">Business Type</InputLabel>
                                                    <Select
                                                        labelId="This Month"
                                                        id="date"
                                                        label="Business Type"
                                                        value={selectedbstype}
                                                        onChange={(e) => setSelectedbstype(e.target.value)}
                                                    >
                                                        <MenuItem value={""} >All</MenuItem>
                                                        {businestype?.filter(item => {
                                                            const businessCategory = item?.categoryName?.toLowerCase();
                                                            return businessCategory === 'school' || businessCategory === 'school/university/college';
                                                        }).map(businestype => (
                                                            <MenuItem key={businestype?.categoryName} value={businestype?.categoryName}>
                                                                {businestype?.categoryName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Convenience Fee'} title={'Convenience Fee'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState.total}
                                loading={pageState.isLoading}
                                pagination
                                page={pageState.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={ActiveColumns}
                                rows={rowData}
                                getRowId={row => row?.id} />
                        </TabPanel>
                        <TabPanel value="InActive">
                            <Grid item width={'100%'} mb={3}>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item display={'flex'} flex={1} alignItems={'center'}>
                                            <CustomSearchInput name={'searchValue'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                            <CustomSelect style={{ marginLeft: "20px", width: "300px" }} options={businestype} name={'businesstype'} value={selectedbstype} onChange={(e) => setSelectedbstype(e.target.value)} />
                                        </Grid>
                                        <Grid item>
                                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Convenience Fee'} title={'Convenience Fee'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState.total}
                                loading={pageState.isLoading}
                                pagination
                                page={pageState.page - 1}
                                pageSize={dataGridPageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={ActiveColumns}
                                rows={rowData}
                                getRowId={row => row?.id} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </>}
        </Grid>
    )
}