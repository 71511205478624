import { Box, Button, Checkbox, Grid, Radio, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { RadioButtonCheckedRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import HttpComponent from "../../../School/MakeRequest";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import AuthecateUser from "../../../paymentmodule/autheticatedComponent";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const MerchantsettlementScheduling = () => {
  const getCurrentRoute = window.location.href;

  const [checkedSchedule, setCheckedValue] = useState([
    { value: "Near RealTime", state: false, details: "within 1 - 3 hours" },
    { value: "Scheduled", state: false, details: "After some days" },
    { value: "Manual", state: false, details: "Settlement is triggered manually!" },
  ]);

  const [checkedDate, setDate] = React.useState("");
  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const [isSelected, setIsSelected] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const handleSelection = (event) => {
    const newData = [...checkedSchedule];
    newData?.map((config) => (config?.details === event?.target?.value ? (config.state = true) : (config.state = false)));
    setCheckedValue(newData);
    setIsSelected(event?.target?.value);
  };

  const getInputValue = (e) => {
    setSelectedTime(e?.target?.value);
  };

  const [settledata, setSettleData] = useState({ duration: "", checkedDuration: "" });

  useEffect(() => {
    setSettleData({ ...settledata, duration: checkedDate, checkedDuration: isSelected });
  }, [selectedTime, checkedDate, isSelected]);
  // console.log("selectedTime", selectedTime, checkedDate);

  const getConfigs = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/getsettlementconfigs`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      let returnData = data?.response?.data;
      let index = checkedSchedule?.findIndex((config) => config?.details === returnData?.placeholder);

      checkedSchedule[index].state = true;
      setCheckedValue(checkedSchedule);
    });
  };

  useEffect(() => {
    getConfigs();
  }, []);

  // AUTHENTICATION ;
  const [checkedSettlement, setCheckedSettlement] = useState(false);
  const [removeSetup, setRemoveSetup] = useState(false);
  const [isAuthecated, setIsAuthecated] = useState(false);
  const handleIsAuthecated = (childData) => {
    let state = childData.state;
    setIsAuthecated(state);
    setRemoveSetup(false);
  };

  //   handle modal;
  const [opensucess, setOpenSuccess] = useState({ message: "", state: false });
  const [openerror, setOpenError] = useState({ message: "", state: false });

  const closeSuccess = () => {
    setOpenSuccess(false);
  };

  const closeError = () => {
    setOpenError(false);
  };
  const saveConfig = () => {
    if (settledata.duration === "") {
      setOpenError({ ...openerror, state: true, message: "Duration is required" });
      return;
    }
    HttpComponent({
      method: `POST`,
      url: `/api/v1/setupmerchsettlement`,
      token: localStorage.getItem("X-Authorization"),
      body: settledata,
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setOpenSuccess({ ...opensucess, state: true, message: "Configurations set succesfully!" });
        setTimeout(() => {
          closeSuccess();
        }, 3000);
      } else {
        setOpenError({ ...openerror, state: true, message: data.response?.message });
        setTimeout(() => {
          closeError();
        }, 3000);
      }
    });
  };

  // useEffect(() => {
  //   if (isAuthecated) {
  //     saveConfig();
  //     return;
  //   }
  // }, [isAuthecated]);

  useEffect(() => {
    if (settledata.checkedDuration === "Settlement is triggered manually!") {
      setSettleData({ ...settledata, duration: "manual" });
    }
    if (settledata.duration) {
      setCheckedSettlement(true);
    } else {
      setCheckedSettlement(false);
    }
  }, [settledata.checkedDuration, settledata.duration, settledata.period]);
  console.log("settledata --->", settledata)
  return (
    <div style={{ fontFamily: "Poppins" }}>
      {/* snackbars */}
      {/* {!isAuthecated && removeSetup && <AuthecateUser handleIsAuthecated={handleIsAuthecated} />} */}
      <ErrorAlert vertical={"top"} horizontal={"right"} onClose={closeError} open={openerror.state} message={openerror.message} />
      <SuccessAlert vertical={"top"} horizontal={"right"} onClose={closeSuccess} open={opensucess.state} message={opensucess.message} />

      {!removeSetup && (
        <Grid container direction={"column"} pl={2}>
          <Grid item mb={3} mt={3}>
            <Box sx={{ margin: "0px 0px 20px 0px" }}>
              <Typography sx={{ color: "#032541", fontSize: "25px", fontWeight: 600 }} variant="h5">
                {" "}
                Settlement Scheduling Configuration{" "}
              </Typography>
            </Box>

            <Box>
              <Breadcrumb name={"Settlement Scheduling Configuration"} />
            </Box>
          </Grid>

          <Grid item mb={3}>
            <Typography sx={{ color: "#000000", fontSize: "18px", fontWeight: 600 }} variant="h5">
              {" "}
              Set how frequent payments should be settled{" "}
            </Typography>
          </Grid>

          <Grid item mb={3} width={"50%"}>
            {checkedSchedule?.map((account, index) => {
              const selectedTime = account?.details === "within 1 - 3 hours" ? ["3hrs"] : ["3days"];

              return (
                <Box key={index} width={"100%"} sx={{ display: "flex", alignItems: "center", gap: "30px", margin: "0px 0px 20px 0px", backgroundColor: "#fff", borderRadius: "4px", padding: "3px 3px 20px 3px", cursor: "pointer", borderBottom: "2px solid", borderBottomColor: "#e0e0e0" }}>
                  <Box sx={{ display: "flex", gap: "30px" }} width={"50%"}>
                    <input checked={account?.state} value={account?.details} type="radio" name={"time"} onChange={(e) => handleSelection(e)} />

                    <Box>
                      <Typography>{account?.value}</Typography>
                      <Typography>{account?.details}</Typography>
                    </Box>
                  </Box>
                  {isSelected === account?.details && account?.details !== "Settlement is triggered manually!" && (
                    <Box width={"50%"} sx={{ display: "flex" }}>
                      {/* <FormControl sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="demo-customized-textbox">Days</InputLabel>
                        <BootstrapInput onChange={getInputValue} id="demo-customized-textbox" />
                      </FormControl> */}
                      <FormControl sx={{ m: 1 }} variant="standard">
                        <InputLabel id="demo-customized-select-label">Days</InputLabel>
                        <Select labelId="demo-customized-select-label" id="demo-customized-select" value={checkedDate} onChange={handleChange} input={<BootstrapInput />}>
                          <MenuItem value="">{/*checkedDate <em>None</em> */}</MenuItem>
                          {selectedTime?.map((time, index) => {
                            return (
                              <MenuItem key={index} value={time}>
                                {" "}
                                {time}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item width={"50%"}>
            <Button
              style={{ color: "white", backgroundColor: "black", opacity: checkedSettlement ? 1 : 0.3 }}
              onClick={() => {
                saveConfig();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MerchantsettlementScheduling;
