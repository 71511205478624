import React, {useState} from 'react'

const ServiceTab = (props) => {
    props = props.state
   const [serviceState, setServiceState] = useState(props)
    console.log(serviceState, "serviceState")
  return (
    <div>ServiceTab</div>
  )
}

export default ServiceTab