import HttpComponent from "../../School/MakeRequest";
import { useCallback } from "react";
export default class StockFunctions {
  constructor() {
    this.warehouseData = [];
  }

  findStoresByType = (storeType, page, pageSize) => {
    if (!storeType && !page && !pageSize) return "data required!";

    return HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_type_status?storeType=${storeType}&status=ACTIVE&page=${page}&limit=${pageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStoreById = (id) => {
    return HttpComponent({
      method: "GET",
      url: `/api/get_store_details?storeType=stores&storeId=${id}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get stockPerWraehouse!
  getStockPerStore = (storeId, searchValue, page, pagesize) => {
    return HttpComponent({
      method: "GET",
      url: `/api/activeStock?limit=${pagesize}&page=${page}&storeId=${storeId}&searchValue=${searchValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   search products;
  searchProductsPerStore = (storeId, page, pagesize, searchValue) => {
    return HttpComponent({
      method: "GET",
      url: `/api/active_stock_by_store_all_categories?limit=${pagesize}&page=${page}&storeId=${storeId}&searchValue=${searchValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get suppliers list;
  getSuppliers = (page, pageSize, status) => {
    return HttpComponent({
      method: "GET",
      url: `/api/v1/supplier/all?page=${page}&limit=${pageSize}&status=${status}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 201) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCategories = (page, pageSize) => {
    return HttpComponent({
      method: "GET",
      url: `/api/stockSubCategories?page=${page}&limit=${pageSize}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchCategories = (page, pagesize, value) => {
    return HttpComponent({
      method: "GET",
      url: `/api/search_product_categories_by_name?limit=${pagesize}&page=${page}&name=${value}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProductsPerCategory = (page, pageSize, categoryId, storeId) => {
    return HttpComponent({
      method: "GET",
      url: `/api/activeStock?limit=${pageSize}&page=${page}&categoryId=${categoryId}&storeId=${storeId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  getStockTakeProducts = (page, pageSize, categoryId, storeId) => {
    return HttpComponent({
      method: "GET",
      url: `/api/listStockTake?limit=${pageSize}&page=${page}&categoryId=${categoryId}&storeId=${storeId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchStockTakeProducts = (page, pageSize, categoryId, productName, wareHouseId) => {
    return HttpComponent({
      method: "GET",
      url: `/api/searchlistStockTake?limit=${pageSize}&page=${page}&name=${productName}&categoryId=${categoryId}&storeId=${wareHouseId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchProductsPerCategory = (page, pageSize, categoryId, productName) => {
    return HttpComponent({
      method: "GET",
      url: `/api/searchActiveStock?limit=${pageSize}&page=${page}&productName=${productName}&categoryId=${categoryId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitStockBatch = (body) => {
    return HttpComponent({
      method: "POST",
      url: `/api/addStockRequest`,
      body: body,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        console.log(data);
        if (data.status == 201) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApprovedAddStock = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_approved_add_stock_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApprovedStockTakeBatch = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_approved_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApprovedStockTransfer = (page, limit) => {
    return HttpComponent({
      method: "GET",
      url: `/api/get_stock_transfer_batches?limit=${limit}&page=${page}&status=approved`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPendingStockAddStock = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_pending_add_stock_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPendingStockStockTake = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_pending_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getPendingStockTransfer = (page, limit) => {
    return HttpComponent({
      method: "GET",
      url: `/api/get_stock_transfer_batches?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  approveAddStockBatch = (listBatchIds) => {
    return HttpComponent({
      method: "POST",
      url: `/api/approve_multiple_add_stock_batches`,
      body: listBatchIds,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  declineAddstockBatch = (stockId) => {
    return HttpComponent({
      method: "POST",
      url: `/api/declineStockRequest`,
      body: stockId,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 201) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getBatchProductsById = (id, page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_add_stock_products_batch?limit=${limit}&page=${page}`,
      body: id,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCancelledAddStockBatch = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_add_stock_cancelled_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCancelledStockTakeBatch = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_stock_take_cancelled_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCancelledStockTransferBatch = (page, limit) => {
    return HttpComponent({
      method: "GET",
      url: `/api/get_stock_transfer_cancelled_batches?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getWarehouseStatus = (token) => {
    return HttpComponent({
      method: "GET",
      url: `/api/v1/stores/warehouse_status`,
      body: null,
      token: token ? token : localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createStockTranferRequest = (body) => {
    return HttpComponent({
      method: "POST",
      url: `/api/create_stock_transfer`,
      body: body,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  approveStockTransfer = (listBatchIds) => {
    console.log(listBatchIds);
    return HttpComponent({
      method: "POST",
      url: `/api/approve_stock_transfer`,
      body: listBatchIds,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  declineStockTransfer = (listBatchIds) => {
    return HttpComponent({
      method: "POST",
      url: `/api/decline_stock_transfer`,
      body: listBatchIds,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStockTransferProductsById = (page, limit, batch) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_stock_transfer_products_by_batch?limit=${limit}&page=${page}&status=pending`,
      body: batch,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 200) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createStockTakeRequest = (products, warehouseId) => {
    return HttpComponent({
      method: "POST",
      url: `/api/updateStockItem?storeId=${warehouseId}`,
      body: products,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status == 201) {
          return data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // stock order supplier Not In Zed;
  getSupplierCategories = (page, limit, businessNumber) => {
    return HttpComponent({
      method: "GET",
      url: `/api/getSupplierProductCategories?${page}=1&limit=${limit}&businessNo=${businessNumber}&Inzed`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  searchSupplierCategories = (page, limit, searchValue) => {
    return HttpComponent({
      method: "GET",
      url: `/api/searchSupplierCategory?page=${page}&limit=${limit}&businessNo=&Inzed&productName=${searchValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getProductsPerCategoryStockOrder = (page, limit, categoryId) => {
    return HttpComponent({
      method: "GET",
      url: `/api/getSupplierProducts?page=${page}&limit=${limit}&categoryId=${categoryId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  searchSupplierProducts = (page, limit, categoryId, productName) => {
    return HttpComponent({
      method: "GET",
      url: `/api/searchSupplierActiveproducts?page=${page}&limit=${limit}&productName=${productName}&categoryId=${categoryId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  sendPurchaseOrder = (supplierId, recieverStoreId, products) => {
    return HttpComponent({
      method: "POST",
      url: `/api/createStockRequestBatch`,
      body: { supplierId, recieverStoreId, products },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getAllPurchaseOrders = (page, limit, requestType) => {
    return HttpComponent({
      method: "GET",
      url: `/api/getPurchaseOrder?requestType=${requestType}&page=${page}&limit=${limit}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getPurchaseOrderProducts = (requestType, po_Id, page, limit) => {
    return HttpComponent({
      method: "GET",
      url: `/api/getPurchaseOrderById?requestType=${requestType}&purchaseOrderId=${po_Id}&page=${page}&limit=${limit}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  sendDeliveryNote = (purchaseOrderNumber, products) => {
    return HttpComponent({
      method: "POST",
      url: `/api/recieveStockFromSupplier`,
      body: { purchaseOrderNumber: purchaseOrderNumber, products: products },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getAddStockReports = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_approved_add_stock_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getStockTakeReports = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_approved_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getAddStockTake = (page, limit) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_approved_add_stock_batches_by_branch?limit=${limit}&page=${page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getStockBatchReports = (page, limit, stockIds) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_products_by_multiple_batches_by_branch?limit=${limit}&page=${page}`,
      body: { listBatchIds: JSON.parse(stockIds) },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  
  getAddStockReport = (page, limit, batchId) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_add_stock_products_batch?limit=${limit}&page=${page}`,
      body: { batchId },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getCostofGoodsReports = (page, limit, batchId)  => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_sold_goods_batch?limit=${limit}&page=${page}`,
      body: { batchId },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  getStockTakeReport = (page, limit, batchId) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_stock_take_report_by_batch?limit=${limit}&page=${page}`,
      body: { batchId },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  };

  
  getStockTransferReport = (page, limit, batchId) => {
    return HttpComponent({
      method: "POST",
      url: `/api/get_stock_transfer_products_by_batch?limit=${limit}&page=${page}&status=approved`,
      body: { batchId },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  }

  
  searchStockReportByType = (searchValue,stockType,page,limit) => {
    return HttpComponent({
      method: "GET",
      url: `/api/searchStockReportsBatchByType?searchValue=${searchValue}&stockType=${stockType}&page=${page}&limit=${limit}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data;
    });
  }

  getInventoryStatus = () => {
    return HttpComponent({
      method:'GET',
      url:`/api/v1/stores/get_inventory_status`,
      body:null,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      return data
    })
  }

  getStockConfigurations = () => {
    return HttpComponent({
      method:'GET',
      url:`/api/get_branch_stock_options_list`,
      body:null,
      token:localStorage.getItem('X-Authorization')
    }).then((data) => {
      return data
    })
  }
}
