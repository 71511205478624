import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useCallback, useEffect, useState } from "react";
import CardTransactionIcon from "../../../../images/card-icon.svg"
import TotalTransactionIcon from "../../../../images/total-amount.svg"
import MpesaIcon from "../../../../images/mpes.svg"
import TransactIcon from "../../../../images/perfomance-g.svg"
import ToatAmountctIcon from "../../../../images/graph-icon.svg"
import HttpComponent from "../../../School/MakeRequest";
import BioTransactIcon from "../../../../images/bio.svg"
import OutletIcon from "../../../../images/outletIcon.svg"
import CustomSearchInput from "../../../School/CustomSearchInput";
import CustomSelect from "../../../School/CustomSelectField";
import DateFormatter from "../../../../utils/dateFormatter";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { Progress } from 'react-sweet-progress';
import CashImg from "../../../School/Images/cash-icon.svg"
import "react-sweet-progress/lib/style.css";
import { currencyconverter } from "../../../../common/currencyconverter";
const baseUrl = process.env.REACT_APP_BASE_URL;

let localCurrency = localStorage.getItem('localCurrency')


if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}



// configure chart

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);


export default function SchoolAdminPayitDashboard() {

    const [overallPerfomance, setOverallPerfmance] = useState('Weekly')
    const [todayPerfomanceTransactions, setTodayPerfomanceTransactions] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [optionsselect, setOptions] = useState([])
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: page, pageSize: dataGridPageSize})
    const [todaysTotal, setTodaysTotal] = useState()
    const [alltotals, setAllTotals] = useState()
    const [alltotalsTransa, setAllTotalsTransa] = useState()
    const [allOutlets, setAllOutlets] = useState([])
    const [selectedTransactionType, setSelectedTransactionType] = useState('all')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [overallPerformanceStartDate, setOverallPerfmanceStartDate] = useState('')
    const [overallPerformanceendDate, setOverallPerfmanceendDate] = useState('')
    const [topOutletStartDate, setTopOutletStartDate] = useState('')
    const [topOutletendDate, setTopOutletendDate] = useState('')
    const [saleOutletStartDate, setSaleOutletStartDate] = useState('')
    const [saleOutletendDate, setSaleOutletendDate] = useState('')
    const transactionIds = ['card', 'bio' , 'SELFORDER' , 'Cash Payment'];
    const [listStartDate, setListStartDate] = useState(new Date())
    const [listendDate, setListEndtDate] = useState(new Date())

    const date2 = new Date();
    const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
    const lastDayLastMonth = new Date(date2.setDate(1))

    const date1 = new Date();
    const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
    const currentDay = new Date().toLocaleDateString();

    const [startDateThisMonth, setStartDateThisMonth] = useState(firstDay)
    const [endDateThisMonth, setendDateThisMonth] = useState(currentDay)

    const [lastMonthStartDate, setLastMonthStartDate] = useState(firstDayLastMonth)
    const [lastMonthEndDate, setLastMonthendDate] = useState(lastDayLastMonth)

// onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}


    useEffect(() => {
        //last Month

        setLastMonthStartDate(firstDayLastMonth);
        setLastMonthendDate(lastDayLastMonth);
        // //currentMonth
        setStartDateThisMonth(firstDay);
        setendDateThisMonth(currentDay);

    }, [startDateThisMonth, endDateThisMonth, lastMonthStartDate, lastMonthEndDate])







    //date filters

    const setOverallPerfomanceDates = (e) => {
        switch (e.target.value) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setOverallPerfmanceStartDate(today)
                setOverallPerfmanceendDate(today)
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                setOverallPerfmanceStartDate(yeseterday)
                setOverallPerfmanceendDate(yeseterday)
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])
                    setOverallPerfmanceStartDate(lastSevenFirst)
                    setOverallPerfmanceendDate(lastSevenLast)
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setOverallPerfmanceStartDate(firstDay)
                setOverallPerfmanceendDate(currentDay)
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setOverallPerfmanceStartDate(firstDayLastMonth)
                setOverallPerfmanceendDate(lastDayLastMonth)
                break;
            default:
                setOverallPerfmanceStartDate('')
                setOverallPerfmanceendDate('')
                break;
        }

    }

    const setStartAndEndDates = (e) => {
        switch (e.target.value) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                setOverallPerfmanceStartDate(today)
                setOverallPerfmanceendDate(today)
                setTopOutletStartDate(today)
                setTopOutletendDate(today)
                setSaleOutletStartDate(today)
                setSaleOutletendDate(today)
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                // console.log(yeseterday);
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                setOverallPerfmanceStartDate(yeseterday)
                setOverallPerfmanceendDate(yeseterday)
                setTopOutletStartDate(yeseterday)
                setTopOutletendDate(yeseterday)
                setSaleOutletStartDate(yeseterday)
                setSaleOutletendDate(yeseterday)
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                    setOverallPerfmanceStartDate(lastSevenFirst)
                    setOverallPerfmanceendDate(lastSevenFirst)
                    setTopOutletStartDate(lastSevenFirst)
                    setTopOutletendDate(lastSevenFirst)
                    setSaleOutletStartDate(lastSevenFirst)
                    setSaleOutletendDate(lastSevenFirst)
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1);
                const currentDay = new Date();
                setStartDate(firstDay);
                setEndDate(currentDay);
                setOverallPerfmanceStartDate(firstDay)
                setOverallPerfmanceendDate(currentDay)
                setTopOutletStartDate(firstDay)
                setTopOutletendDate(currentDay)
                setSaleOutletStartDate(firstDay)
                setSaleOutletendDate(currentDay)
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1);
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                setOverallPerfmanceStartDate(firstDayLastMonth)
                setOverallPerfmanceendDate(lastDayLastMonth)
                setTopOutletStartDate(firstDayLastMonth)
                setTopOutletendDate(lastDayLastMonth)
                setSaleOutletStartDate(firstDayLastMonth)
                setSaleOutletendDate(lastDayLastMonth)
                break;
            default:
                setStartDate('');
                setEndDate('');
                setOverallPerfmanceStartDate('')
                setOverallPerfmanceendDate('')
                setTopOutletStartDate('')
                setTopOutletendDate('')
                setSaleOutletStartDate('')
                setSaleOutletendDate('')
                break;
        }
    };
 

    //bar chart
    const [selectedOptionBar, setSelectedOptionBar] = useState('weeks')
    const labelsMonthly = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


    // Bar chart data
    const [barChartData, setBarChartData] = useState({
        labels: labelsMonthly,
        datasets: [
            {
                label: 'Card Transactions',
                data: [],
                backgroundColor: '#22409a',
            },
            {
                label: 'Bio Transactions',
                data: [],
                backgroundColor: '#ab55ff',
            },
            {
                label: 'Mpesa Transactions',
                data: [],
                backgroundColor: '#032541',
            },
            {
                label: 'Cash Transactions',
                data: [],
                backgroundColor: '#dc3545',
            },
            {
                label: 'Total',
                data: [],
                backgroundColor: '#009447',
            },
        ],
    });



    //console.log(barChartData , 'barChartData')

    // add all months

    async function generateMonthLabels(months) {
        const currentDate = new Date();
        const labels = [];

        for (let i = 0; i < months; i++) {
            const monthDate = new Date(currentDate);
            monthDate.setMonth(currentDate.getMonth() - i);

            const month = labelsMonthly[monthDate.getMonth()];
            const year = monthDate.getFullYear();

            labels.unshift(`${month} ${year}`);
        }

        return labels;
    }

    // options



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title:
                <Grid item>
                    <Typography>Transactions</Typography>
                </Grid>
        },
        scales: {
            y: {
                min: 0,
                max: 5000,
                stepSize: 100,
                position: 'top',
                drawOnChartArea: false,
                title: {
                    display: true,
                    text: 'Amount in 000',
                    padding: { top: 20, bottom: 0 },
                }
            },
            x: {
                beginAtZero: true,
                position: 'bottom',
                title: {
                    display: true,
                },
            }
        },
    };

    const [barChartStartDate , setBarChartStartDate] = useState('')
    const [barChartEndDate , setBarChartEndDate] = useState('')

    const setOverallBarchartDates = (e) => {
        switch (e.target.value) {
            case "Today":
                const today = new Date()
                setBarChartStartDate(today)
                setBarChartEndDate(today)
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                setBarChartStartDate(yeseterday)
                setBarChartEndDate(yeseterday)
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])
                    setBarChartStartDate(lastSevenFirst)
                    setBarChartEndDate(lastSevenLast)
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setBarChartStartDate(firstDay)
                setBarChartEndDate(currentDay)
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setBarChartStartDate(firstDayLastMonth)
                setBarChartEndDate(lastDayLastMonth)
                break;
            default:
                setBarChartStartDate('')
                setBarChartEndDate('')
                break;
        }

    }


    useEffect(() => {
        async function fetchDataFromAPI() {
            try {
                const response = await fetch(`${baseUrl}/api/v1/getPayitSchoolBarChartTotals?startDate=${barChartStartDate}&endDate=${barChartEndDate}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        'X-Authorization': localStorage.getItem('X-Authorization'),
                    },
                });

                if (response.status === 200) {
                    const apiData = await response.json();

                    const numMonthsToShow = 12;

                    // Generate labels for the specified number of months
                    const labels = await generateMonthLabels(numMonthsToShow);

                    // console.log(apiData?.data, 'labels >>>>>>>>>>>>>')

                    const cardData = labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        //console.log(foundData, 'youuuuuuuuuuuuuu')
                        return foundData ? foundData?.CardTotal : 0;
                    });

                    const bioData = labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        return foundData ? foundData?.BioTotal : 0;
                    });

                    const selfOrder =  labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        return foundData ? foundData?.SelfOrder : 0;
                    });

                    const cashTotals =  labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        return foundData ? foundData?.CashTotal : 0;
                    });


                    const totalData = labels?.map(label => {
                        const foundData = apiData?.data?.find(item => `${item?.month} ${item?.year}` === label);
                        return foundData ? foundData?.MonthlyTotal : 0;
                    });



                    const updatedBarchartData = {
                        ...barChartData,
                        labels: labels,
                        datasets: [
                            {
                                ...barChartData.datasets[0],
                                data: cardData,
                            },
                            {
                                ...barChartData.datasets[1],
                                data: bioData,
                            },
                            {
                                ...barChartData.datasets[2],
                                data: selfOrder,
                            },
                            {
                                ...barChartData.datasets[3],
                                data: cashTotals,
                            },
                            {
                                ...barChartData.datasets[4],
                                data: totalData,
                            },
                        ],
                    };

                    setBarChartData(updatedBarchartData)

                    // update options

                    const totalDataFound = updatedBarchartData?.datasets?.find(dataset => dataset?.label === 'Total');
                    const maxTotalValue = Math.max(...totalDataFound?.data);


                    //updated options will be

                    const updatedOptions = {
                        ...options,
                        scales: {
                            y: {
                                ...options.scales.y,
                                max: maxTotalValue ? maxTotalValue:options?.scales.y.max,
                            },
                            x:{
                                ...options.scales.x,
                                grid:{
                                    display:false
                                }
                            }
                        },
                    };

                    setBarChartData({
                        ...updatedBarchartData,
                        options: updatedOptions,
                    });


                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchDataFromAPI();
    }, [barChartStartDate , barChartEndDate]);


    console.log(barChartData , 'barChartData >>>')

    // date

    const [todaysStartdate, setTodaysStartDate] = useState(new Date())
    const [todaysEnddate, setTodaysEndDate] = useState(new Date())
    const [selectedOutlet, setSelectedSelectedOutlet] = useState('')

    const gridFormatDate = (inputDate) => {
        // const today = new Date();
        const yyyy = new Date(inputDate)?.getFullYear();
        let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
        let dd = new Date(inputDate)?.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;

        return formattedToday;
    };

    useEffect(() => {
        const newStartDate = gridFormatDate(todaysStartdate);
        setTodaysStartDate(newStartDate)
        const newEndDate = gridFormatDate(todaysEnddate);
        setTodaysEndDate(newEndDate)
    }, [todaysStartdate, todaysEnddate]);


    //fetch today perfomance

    const fetchTodayPerfomance = useCallback(async () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/gettotalpayittransactions?startDate=${todaysStartdate}&endDate=${todaysEnddate}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data , 'data >>>>')
                if (data.status === 200) {
                    setTodayPerfomanceTransactions(data?.response?.data)
                    setTodaysTotal(data?.response?.total)
                }
            })

        } catch (error) {
            console.log(error)
        }
    },[todaysStartdate, todaysEnddate])

    useEffect(() => {
        fetchTodayPerfomance()
    }, [fetchTodayPerfomance])



    // fetch overall perfomance

    const fetchOverallPerfomance = useCallback(async () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getOutletTransactions?startDate=${overallPerformanceStartDate}&endDate=${overallPerformanceendDate}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setAllTotals(data?.response?.total)
                    setAllTotalsTransa(data?.response?.count)
                }
            })
        } catch (error) {
            console.log(error)
        }
    },[overallPerformanceStartDate, overallPerformanceendDate])

    useEffect(() => {
        fetchOverallPerfomance();
    }, [fetchOverallPerfomance])

    // get all outlets for the business

    function getAllOutletsForBs() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listBusinessOutlets?storeType=MainStore`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setAllOutlets(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllOutletsForBs()
    }, [])


    //all transactions columns

    const AllTransactionColumns = [

        { headerName: "Receipt No", field: 'receiptNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Receipt No</strong>) } },
        { headerName: "Bill Ref No", field: 'refNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Bill Ref No</strong>) } },
        { headerName: "Created At", field: 'transTime', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <span>{DateFormatter(params?.row?.transactionTime)}</span> },
        { headerName: "Source", field: 'source', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Source</strong>) }, renderCell: (params) => <span>{params?.row?.source}</span> },
        { headerName: "Transaction Type", field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >TxN Type</strong>) }, renderCell: (params) => <Typography sx={{ color: params?.row?.transactionType === 'DEBIT' ? '#dc3545' : params?.row?.transactionType === 'CREDIT' ? '#17ae7b' : '#707070' }}>{params?.row?.transactionType}</Typography> },
        { headerName: "Outlet", field: 'outlet', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Outlet</strong>) } },
        { headerName: "Teller Id", field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) } },
        { headerName: "Terminal Serial", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) } },
        { headerName: "amount", field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Amount ({localCurrency})</strong>) } },
    ]

    //fetch all transactions

    const  getAllTransactionsList = useCallback(async()=> {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getOutletTransactionList?page=${page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${listStartDate}&endDate=${listendDate}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }finally{
            setPageState((old) => ({ ...old, isLoading: false }))
        }
    },[page ,dataGridPageSize, searchValue, listStartDate, listendDate])

    useEffect(() => {
        getAllTransactionsList()
    }, [getAllTransactionsList])


    //top outlets

    const [topOutletsale, setTopeSales] = useState([])
    const [topOutletSearchValue, setTopOuletSearchvalue] = useState('')

    function getTopSalesByOut() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getAllPayitTotalsForOutletss?startDate=${topOutletStartDate}&endDate=${topOutletendDate}&searchValue=${topOutletSearchValue}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setTopeSales(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getTopSalesByOut()
    }, [topOutletStartDate, topOutletendDate, topOutletSearchValue])



    // get sales by outlets
    const [outletSales, setOutSales] = useState([])

    function getSalesByOut() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getAllPayitTotalsForOutletss?startDate=${saleOutletStartDate}&endDate=${saleOutletendDate}&searchValue=${selectedOutlet}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setOutSales(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getSalesByOut()
    }, [saleOutletStartDate, saleOutletendDate, selectedOutlet])

    //pie chart

    const pieChartOptions = {
    }

    const pieCahrtlabels = topOutletsale?.map((item) => item?.storeName);
    const pieChartdatas = topOutletsale?.map((item) => item?.totalAmount);

    const piechartData = {
        labels: pieCahrtlabels,
        datasets: [
            {
                data: pieChartdatas,
                backgroundColor: ['#64ca5b', '#9020e0', '#22409a', '#f22431', '#d30088'],
            },
        ],
    };

    // get outlets
    const [outletMonthlySales, setMonTtlyOutSales] = useState([])
    const [monthlyTotal, setMonthlyTotal] = useState()
 

    console.log(outletMonthlySales , 'outsales ')


    function fetchOverallMonthlyPerfomance() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/gettotalpayittransactions`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setMonTtlyOutSales(data?.response?.data)
                    setMonthlyTotal(data?.response?.total)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOverallMonthlyPerfomance()
    }, [])

    //fetch last month transactions

    const [previousMonthTotals, setPreviousMonthTotals] = useState()

    function getAllPreviousMonthTransactions() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/gettotalpayittransactions?startDate=${lastMonthStartDate}&endDate=${lastMonthEndDate}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPreviousMonthTotals(data?.response?.total)
                }
            })

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllPreviousMonthTransactions()

    }, [lastMonthStartDate, lastMonthEndDate])

    //fetch current month transactions
    const [currentMonthTotals, setCurrentMonthTotals] = useState()

    function getAllCurrentMonthTransactions() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/gettotalpayittransactions?startDate=${startDateThisMonth}&endDate=${endDateThisMonth}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setCurrentMonthTotals(data?.response?.total)
                }
            })

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllCurrentMonthTransactions()

    }, [startDateThisMonth, endDateThisMonth])






    return (
        <Grid container display={'flex'} direction={'column'} fontFamily={'Poppins'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                <Typography style={{ color: "#687c8d", marginLeft: "10px", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Zed - Pocket Money - Institution Admin</Typography>
            </Grid>
            <Grid item display={'flex'} mt={3}>
                <Grid container display={'flex'} direction={'row'} spacing={2}>
                    <Grid item width={'60%'}>
                        <Paper elevation={2} style={{ width: "100%", height: "250px", padding: "10px 10px  10px 10px" }} >
                            <Grid item>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item pt={2}>
                                        <Typography fontSize={'14px'} fontWeight={'bold'} style={{ color: '#032541' }}>Today's Performance</Typography>
                                    </Grid>
                                    <Grid item pt={5} display={'flex'} width={'100%'}>
                                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                                            {transactionIds?.map((transaction) => {
                                                const resultFound = todayPerfomanceTransactions?.find((item) => item?._id === transaction);
                                                const total = resultFound?.total || 0;
                                                return (
                                                    <Grid key={transaction?._id} item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                        <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                            <img height={'40px'} width={'40px'} src={transaction === 'card' ? CardTransactionIcon : transaction === 'bio' ? BioTransactIcon : transaction === 'SELFORDER' ? MpesaIcon:transaction === 'Cash Payment' ? CashImg :null} alt="" />
                                                        </Box>
                                                        <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>{transaction === 'card' ? 'Card Transactions' : transaction === 'bio' ? 'Bio Transactions': transaction === 'SELFORDER' ? 'MPESA':transaction === 'Cash Payment' ? 'Cash Transaction':null}</Typography>
                                                        <Typography sx={transaction === 'card' ? { color: '#22409a', fontWeight: 'bold', fontSize: '20px' } : transaction === 'bio' ? { color: '#ab55ff', fontWeight: 'bold', fontSize: '20px' } : {}}>{currencyconverter(total)}</Typography>
                                                    </Grid>
                                                )
                                            })}
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={TotalTransactionIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Total Amount</Typography>
                                                <Typography sx={{ color: '#009447', fontWeight: 'bold', fontSize: '20px' }}>{currencyconverter(todaysTotal)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item width={'40%'}>
                        <Paper elevation={2} style={{ width: "100%", height: '250px', padding: "10px 10px  10px 10px" }}>
                            <Grid item width={'100%'}>
                                <Grid container display={'flex'} direction={'column'}>
                                    <Grid item display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography fontSize={'14px'} fontWeight={'bold'} style={{ color: '#032541' }}>Overall Performance</Typography>
                                        <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-label">Date Filter</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Date Filter"
                                                onChange={setOverallPerfomanceDates}
                                            >
                                                {/* <MenuItem value=''>All</MenuItem> */}
                                                <MenuItem value={'Today'}>Today</MenuItem>
                                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item pt={3} display={'flex'} width={'100%'}>
                                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={TransactIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Transactions</Typography>
                                                <Typography sx={{ color: '#22409a', fontWeight: 'bold', fontSize: '20px' }}>{alltotalsTransa}</Typography>
                                            </Grid>
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={OutletIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Outlets</Typography>
                                                <Typography sx={{ color: '#f87f05', fontWeight: 'bold', fontSize: '20px' }}>{allOutlets?.length}</Typography>
                                            </Grid>
                                            <Grid item display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                                                <Box mb={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                                    <img height={'40px'} width={'40px'} src={ToatAmountctIcon} alt="" />
                                                </Box>
                                                <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>Total Amount</Typography>
                                                <Typography sx={{ color: '#009447', fontWeight: 'bold', fontSize: '20px' }}>{currencyconverter(alltotals)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={2} item display={'flex'}>
                <Grid container display={'flex'} direction={'row'} spacing={2}>
                    <Grid item width={'50%'} display={'flex'} direction={'column'}>
                        <Paper sx={{ width: '100%', height: '520px' }}>
                            <Grid container display={'flex'}  direction={'column'} mt={2}>
                                <Grid width={'100%'} item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid pl={1} item width={'50%'} display={'flex'}>
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#032541' }}>Transactions</Typography>
                                    </Grid>
                                    <Grid item width={'50% '} pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                        <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', }}>
                                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                            <Select
                                                sx={{ height: "40px" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedTransactionType}
                                                label="Type"
                                                onChange={(e) => setSelectedTransactionType(e.target.value)}
                                            >
                                                <MenuItem value='all'>All</MenuItem>
                                                <MenuItem value='card'>Card</MenuItem>
                                                <MenuItem value="Mpesa">Bio</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', marginLeft: "10px" }}>
                                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                            <Select
                                                sx={{ height: "40px" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Date"
                                                onChange={setOverallBarchartDates}
                                            >
                                                 <MenuItem value={'Today'}>Today</MenuItem>
                                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item display={'flex'} width={'100%'} height={'100%'}  mt={2}  px={3}>
                                    <Bar height={'160px'} options={barChartData?.options} data={barChartData} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item width={'50%'} display={'flex'} direction={'column'}>
                        <Paper sx={{ width: '100%', height: '520px' }}>
                            <Grid container display={'flex'} direction={'column'} mt={2}>
                                <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item pl={1} width={'20%'} display={'flex'}>
                                            <Typography variant="" sx={{ fontSize: '18px', fontWeight: 'bold', color: '#032541' }}>Top 5 Outlets</Typography>
                                        </Grid>
                                        <Grid item width={'50% '} pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                            <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', }}>
                                                <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={topOutletSearchValue}
                                                    label="Outlet"
                                                    onChange={(e) => setTopOuletSearchvalue(e.target.value)}
                                                >
                                                    <MenuItem value=''>All</MenuItem>
                                                    {allOutlets?.map((outlet) => {
                                                        return <MenuItem key={outlet?._id} value={outlet?._id}>{outlet?.storeName}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth style={{ backgroundColor: '#f7f7f7', marginLeft: "10px" }}>
                                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={setStartAndEndDates}
                                                    label="Date"

                                                >
                                                    <MenuItem value=''>All</MenuItem>
                                                    <MenuItem value={'Today'}>Today</MenuItem>
                                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={'100%'} justifyContent={'center'} display={'flex'}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '400px', width: '300px' }}>
                                        <Pie options={pieChartOptions} data={piechartData} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={5}>
                <Grid container display={'flex'} direction={'column'}>
                    <Grid item display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid container display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Grid item>
                                <CustomSearchInput value={searchValue} name={'search'} placeholder={'search'} onChange={(e) => setSearchValue(e.target.value)} />
                            </Grid>
                            <Grid item ml={1} display={'flex'} alignItems={'center'}>
                                <CustomSelect options={optionsselect} value={selectedPaymentOption} onChange={(e) => setSelectedPaymentOption(e.target.value)} placeholder={'Transaction Type'} />
                                <Grid mt={-1} ml={1} item display={'flex'} alignItems={'center'}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="startDate"
                                            value={listStartDate}
                                            onChange={(newValue) => setListStartDate(newValue)}
                                            renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid mt={-1} item display={'flex'} ml={2} alignItems={'center'}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="startDate"
                                            value={listendDate}
                                            onChange={(newValue) => setListEndtDate(newValue)}
                                            renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mt={2}>
                        <DataGrid
                            components={{ NoRowsOverlay: NoRowsOverlay }}
                            sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            pagination
                            page={page - 1}
                            pageSize={dataGridPageSize}
                            paginationMode="server"
                             onPageChange={(newPage) => {
                                setPage(newPage + 1);
                                setPageSize(dataGridPageSize);
                             }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            columns={AllTransactionColumns}
                            rows={pageState?.data}
                            getRowId={row => row._id} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={3}>
                <Grid container display={'flex'} direction={'row'} spacing={2}>
                    <Grid item width={'60%'} display={'flex'} direction={'column'}>
                        <Paper elevation={2} sx={{ height: '600px', width: '100%' }}>
                            <Grid display={'flex'} direction={'column'}>
                                <Grid pt={3} width={'100%'} item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid pl={1} item display={'flex'}>
                                        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: '#032541' }}>Transactions</Typography>
                                    </Grid>
                                    <Grid item pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                        <FormControl style={{ width: '140px', backgroundColor: '#f7f7f7' }}>
                                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Date"
                                               // onChange={setStartAndEndDates}
                                            >
                                                <MenuItem value={'Today'}>Today</MenuItem>
                                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid pl={1} item pt={2} direction={'column'} display={'flex'}>
                                    <Grid item>
                                        <Grid container display={'flex'} direction={'column'}>
                                            <Grid item>
                                                <Typography fontWeight={'bold'} fontSize={'20px'} sx={{ color: "#707070" }}>Total Amount</Typography>
                                            </Grid>
                                            <Grid item py={2}>
                                                <Typography fontWeight={'bold'} sx={{ color: '#009447', fontSize: '30px' }}>{currencyconverter(currentMonthTotals ? currentMonthTotals : 0)}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{ color: '#707070' }}>Compared to <span style={{ fontWeight: 'bold' }}>{currencyconverter(previousMonthTotals ? previousMonthTotals : 0)}</span> <span>Last Month</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid display={'flex'} item width={'100%'} pl={1} pr={2}>
                                        <Grid container width={'100%'}>
                                            {transactionIds?.map((monthT) => {
                                                const resultFound = outletMonthlySales?.find((item) => item?._id === monthT);
                                                console.log(resultFound , monthT, 'resultFound')
                                                const total = resultFound?.total || 0;
                                                const totalTransact = resultFound?.count || 0
                                                const totalPercent = resultFound?.percentageTotal || 0

                                                return (
                                                    <Grid key={monthT?._id} item width={'100%'} pt={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Grid item display={'flex'} alignItems={'center'}>
                                                            <Box mb={2}>
                                                                {monthT === 'card' ? <img height={'40px'} width={'40px'} src={CardTransactionIcon} alt="" /> : monthT === 'bio' ? <img height={'40px'} width={'40px'} src={BioTransactIcon} alt="" /> : monthT === 'SELFORDER' ? <img height={'40px'} width={'40px'} src={MpesaIcon} alt="" />   :monthT === 'Cash Payment'? <img height={'40px'} width={'40px'} src={CashImg} alt="" />  :null}

                                                            </Box>
                                                            <Box mb={2} sx={{ display: 'flex', marginLeft: '10px', flexDirection: 'column' }}>
                                                                <Typography sx={{ color: '#707070', fontSize: '16px' }}>{monthT === 'card' ? 'Card Transactions' : monthT === 'bio' ? 'Bio Transactions' : monthT === 'SELFORDER' ? 'MPESA Transactions': monthT === 'Cash Payment' ? 'Cash Transactions':null}</Typography>
                                                                <Typography sx={{ color: monthT === 'card' ? '#22409a' : monthT === 'bio' ? '#f87f05' : monthT === 'Cash Payment' ? '#DC3545':  monthT === 'SELFORDER' ? '': '', fontWeight: 'bold', fontSize: '20px' }}>{monthT === 'card' ? currencyconverter(total) : monthT === 'bio' ? currencyconverter(total) : monthT === 'SELFORDER' ? currencyconverter(total): monthT === 'Cash Payment' ? currencyconverter(total) :  currencyconverter(0)}</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid container spacing={2} flex={1} display={'flex'} ml={2} direction={'column'}>
                                                            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                                <Typography sx={{ color: '#707070', fontSize: '12px' }}>{monthT === 'card' ? totalTransact : monthT === 'bio' ? totalTransact :monthT === 'SELFORDER' ? totalTransact: monthT === 'Cash Payment' ? totalTransact : 0} Transactions</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Progress
                                                                    status={monthT === 'card' ? 'card' :
                                                                        monthT === 'bio' ? 'bio' : monthT === 'Cash Payment' ? 'Cash': monthT === 'SELFORDER' ? 'MPESA':''}
                                                                    theme={{ card: { color: '#22409a' }, bio: { color: '#f87f05' } , MPESA:{color:'#032541'}, Cash:{color:'#dc3545'} }}
                                                                    percent={monthT === 'card' ? totalPercent : monthT === 'bio' ? totalPercent :monthT === 'Cash Payment' ? totalPercent :monthT === 'SELFORDER' ? totalPercent : 0}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item width={'40%'}>
                        <Paper elevation={2} sx={{ height: '600px', width: '100%' }}>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid pt={3} container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item pl={1} display={'flex'} >
                                            <Typography variant="" sx={{ fontSize: '18px', fontWeight: 'bold', color: '#032541' }}>Sales By Oulet</Typography>
                                        </Grid>
                                        <Grid item pr={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                            <FormControl style={{ width: '121px', backgroundColor: '#f7f7f7', }}>
                                                <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectedOutlet}
                                                    label="Outlet"
                                                    onChange={(e) => setSelectedSelectedOutlet(e.target.value)}
                                                >
                                                    <MenuItem value=''>All</MenuItem>
                                                    {allOutlets?.map((outlet) => {
                                                        return <MenuItem key={outlet?._id} value={outlet?._id}>{outlet?.storeName}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                            <FormControl style={{ width: '121px', backgroundColor: '#f7f7f7', marginLeft: "10px" }}>
                                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={setStartAndEndDates}
                                                    label="Date"
                                                >
                                                    {/* <MenuItem value=''>All</MenuItem> */}
                                                    <MenuItem value={'Today'}>Today</MenuItem>
                                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item width={'100%'}>
                                    <Grid container pl={1} pr={2} display={'flex'} direction={'column'}>
                                        <Grid item mt={3} px={1} sx={{ background: '#f7f7f7', height: '40px' }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography sx={{ fontWeight: 'bold', color: '#000', fontSize: '14px' }}>Outlet name</Typography>
                                            <Typography sx={{ fontWeight: 'bold', color: '#000', fontSize: '14px' }}>Amount</Typography>
                                        </Grid>
                                        {outletSales?.map((outletItem) => {
                                            return (
                                                <Grid mt={2} value={outletItem?.storeName} item display={'flex'} justifyContent={'space-between'}>
                                                    <Typography>{outletItem?.storeName}</Typography>
                                                    <Typography>{currencyconverter(outletItem?.totalAmount)}</Typography>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}