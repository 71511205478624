import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material"
import DescriptionIcon from '@mui/icons-material/Description';
import { useEffect, useState } from "react";
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";
import CustomDataGrid from "../products/stockComponents/customDataGrid";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HttpComponent from "../School/MakeRequest";
import DateFormatter, { DoMaskValue } from "../../utils/dateFormatter";


const MTNdashboard = () => {
    const [todaysTransactionscount, setTodaysTransactionscount] = useState(0)
    const [todaysTransactionstotal, setTodaysTransactionstotal] = useState(0)
    const [todaysendDateTransactions, setTodaysEnddateTransactionsCount] = useState(0)
    const [thisMonthTransactions, setThisMonthTransactionsTotal] = useState(0)
    const [lastMonthTransactions , setLastMonthTransactionsTotal] = useState(0)
    const [lastMonthTransaction , setLastMonthTransactionsCount] = useState(0)
    const [last7days, setLast7dayscount] = useState(0)
    const [last7daysTotal, setLast7daysTotal] = useState(0)

    const [searchValue, setSearchValue] = useState('')

    const returnDate = (date) => {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; 
        const day = dateObject.getDate();
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`; 
        return formattedDate;
    };
    
    useEffect(() => {
        const startDate = returnDate(new Date());
        const endDate = returnDate(new Date());
        const todayQuery = `startDate=${startDate}&endDate=${endDate}`;

        function getTodaysEnddateTransactions(){
            try {
                 HttpComponent({
                    method: 'GET',
                    url: `/api/partner/mobilemoneytransactions?${todayQuery}`,
                    token: localStorage.getItem('X-Authorization'),
                }).then((response)=>{
                    if (response?.status === 200) {
                        setTodaysTransactionscount(response?.response?.dashboardCount);
                        setTodaysTransactionstotal(response?.response?.dashboardTotal)
                    }
                })
            } catch (error) {
                console.error(error);
            }
        };
    
        function getLast7DaysTransactions(){
            const result = [];
            for (let i = 0; i < 7; i++) {
                const d = new Date();
                result.push(new Date(d.setDate(d.getDate() - i)));
            }
            const lastSevenFirst = returnDate(result[0]);
            const lastSevenLast = returnDate(result[result.length - 1]);
            const last7DaysQuery = `startDate=${lastSevenLast}&endDate=${lastSevenFirst}`;
    
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/partner/mobilemoneytransactions?${last7DaysQuery}`,
                    token: localStorage.getItem('X-Authorization'),
                }).then((response)=>{
                    if (response?.status === 200) {
                        setTodaysEnddateTransactionsCount(response?.response?.dashboardCount);
                        setThisMonthTransactionsTotal(response?.response?.dashboardTotal)
                    }
                })
            } catch (error) {
                console.error(error);
            }
        };
    
        // Fetch this month's transactions
        function getThisMonthTransactions(){
            const now = new Date();
            const firstDayOfMonth = returnDate(new Date(now.getFullYear(), now.getMonth(), 1));
            const lastDayOfMonth = returnDate(now);
            const thisMonthQuery = `startDate=${firstDayOfMonth}&endDate=${lastDayOfMonth}`;
    
            try {
                 HttpComponent({
                    method: 'GET',
                    url: `/api/partner/mobilemoneytransactions?${thisMonthQuery}`,
                    token: localStorage.getItem('X-Authorization'),
                }).then((response)=>{
                    if (response?.status === 200) {
                        setLastMonthTransactionsTotal(response?.response?.dashboardTotal);
                        setLastMonthTransactionsCount(response?.response?.dashboardCount)
                    }
                })
            } catch (error) {
                console.error(error);
            }
        };
    
        // Fetch last month's transactions
        function getLastMonthTransactions(){
            const now = new Date();
            const firstDayOfLastMonth = returnDate(new Date(now.getFullYear(), now.getMonth() - 1, 1));
            const lastDayOfLastMonth = returnDate(new Date(now.getFullYear(), now.getMonth(), 0));
            const lastMonthQuery = `startDate=${firstDayOfLastMonth}&endDate=${lastDayOfLastMonth}`;
    
            try {
                HttpComponent({
                    method: 'GET',
                    url: `/api/partner/mobilemoneytransactions?${lastMonthQuery}`,
                    token: localStorage.getItem('X-Authorization'),
                }).then((response)=>{
                    if (response?.status === 200) {
                        setLast7dayscount(response?.response?.dashboardCount);
                        setLast7daysTotal(response?.response?.dashboardTotal)
                    }
                })
            } catch (error) {
                console.error(error);
            }
        };
    
        getTodaysEnddateTransactions();
        getLast7DaysTransactions();
        getThisMonthTransactions();
        getLastMonthTransactions();
    }, []);
    


    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    const TransactionsColumns = [
        { field: "transactionID", headerName: "Transaction ID", flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: "mtnID", headerName: "MTN ID", flex: 1 },
        { field: "zedID", headerName: "ZED ID", flex: 1 },
        { field: "phone", headerName: "Phone Number", flex: 1 },
        { field: "date", headerName: "Date", flex: 1 },

    ]
    
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "UGX" }).format(value);



    const TransactionsData = pageState?.data?.map((config) => ({
        id: config?._id,
        transactionID: DoMaskValue(config?.transactionID),
        amount: config?.amount ? new Intl.NumberFormat("en-US", { style: "currency", currency: config?.currency ? config?.currency:'UGX'}).format(config?.amount) : "N/A",
        mtnID: config?.mtnID,
        zedID: config?.zedID,
        phone: config?.phone ? DoMaskValue(config?.phone) : "N/A",
        date: DateFormatter(config?.createdAt),
    }))

    const AgentsColumns = [
        { field: "agentName", headerName: "Agent Name", flex: 1 },
        { field: 'phone', headerName: 'Phone Number', flex: 1 },
        { field: "merchantcode", headerName: "Agent Merchanant Code", flex: 1 },
        { field: "glAccount", headerName: "GL Account", flex: 1 },
    ]

    const agentsData = pageState?.data?.map((config) => ({
        id: config._id,
        agentName: config?.agentName,
        phone: config?.phone,
        merchantcode: config?.merchantcode,
        glAccount: config?.glAccount,
    }))


    const setOverallPerfomanceDates = (e) => {
       
    }
    function handleFetchAgents() {
        
    }
    function handleFetchTransactions() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/partner/mobilemoneytransactions?page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((res) => {
                if (res?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, data: res?.response?.data, count: res?.response?.count })
                }
            })
        } catch (err) {
            console.log(err)
            setPageState({ ...pageState, isLoading: false, data: [], count: 0 })
        }
    }
    useEffect(() => {
        handleFetchTransactions()
        handleFetchAgents()
    }, [pageState.page, dataGridPageSize ,searchValue])
    
    return (
        <Grid direction={'column'} container>
            <Grid item width={'100%'} display={'flex'}>
                <Grid display={'flex'} width={'100%'} spacing={4} container direction={'row'}>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Paper elevation={2} sx={{ width: '100%', height: "150px" }}>
                            <Box component={'div'} sx={{ width: '100%', height: "100%", px: 1, display: "flex", alignItems: "center" }}>
                                <Box component={'div'} sx={{ width: '100%', gap: 1, height: "100%", flexDirection: 'column', display: "flex", flex: 0.6, alignItems: "flex-start", justifyContent: "center" }}>
                                    <Typography variant="h6" fontSize={12} fontWeight={600} sx={{ color: '#009447' }}>Today's Performance</Typography>
                                    <Typography variant="p" fontSize={14} color={'#92959e'}>Total Amount</Typography>
                                    <Typography variant="h6" fontSize={18} sx={{ color: '#009447' }}>{numberFormat(todaysTransactionstotal)}</Typography>
                                    <Typography variant="p" fontSize={14} display={'flex'} gap={1} alignItems={'center'}>
                                        <span style={{ fontSize: "12px" }}>Total Transactions</span>
                                        <span style={{ fontWeight: 700 }}>{todaysTransactionscount} </span>
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ width: '100%', height: "100%", flexDirection: 'column', display: "flex", flex: 0.4, alignItems: "center", justifyContent: "center" }}>
                                    <DescriptionIcon sx={{ fontSize: '50px', color: "#009447", paddingX: '10px', paddingY: '10px', borderRadius: "10px", background: "#e5f4ec" }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Paper elevation={2} sx={{ width: '100%', height: "150px" }}>
                            <Box component={'div'} sx={{ width: '100%', height: "100%", px: 1, display: "flex", alignItems: "center" }}>
                                <Box component={'div'} sx={{ width: '100%', gap: 1, height: "100%", flexDirection: 'column', display: "flex", flex: 0.6, alignItems: "flex-start", justifyContent: "center" }}>
                                    <Typography variant="h6" fontSize={12} fontWeight={600} sx={{ color: '#00b7fe' }}>This Week Performance</Typography>
                                    <Typography variant="p" fontSize={14} color={'#92959e'}>Total Amount</Typography>
                                    <Typography variant="h6" fontSize={18} sx={{ color: '#00b7fe' }}>{numberFormat(thisMonthTransactions)}</Typography>
                                    <Typography variant="p" fontSize={14} display={'flex'} gap={1} alignItems={'center'}>
                                        <span style={{ fontSize: "12px" }}>Total Transactions</span>
                                        <span style={{ fontWeight: 700 }}>{todaysendDateTransactions}</span>
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ width: '100%', height: "100%", flexDirection: 'column', display: "flex", flex: 0.4, alignItems: "center", justifyContent: "center" }}>
                                    <DescriptionIcon sx={{ fontSize: '50px', color: "#00b7fe", paddingX: '10px', paddingY: '10px', borderRadius: "10px", background: "#f6fcff" }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xl={4} lg={4} sm={12} xs={12}>
                        <Paper elevation={2} sx={{ width: '100%', height: "150px" }}>
                            <Box component={'div'} sx={{ width: '100%', height: "100%", px: 1, display: "flex", alignItems: "center" }}>
                                <Box component={'div'} sx={{ width: '100%', gap: 1, height: "100%", flexDirection: 'column', display: "flex", flex: 0.6, alignItems: "flex-start", justifyContent: "center" }}>
                                    <Typography variant="h6" fontSize={12} fontWeight={600} sx={{ color: '#551fff' }}>This Month's Performance</Typography>
                                    <Typography variant="p" fontSize={14} color={'#92959e'}>Total Amount</Typography>
                                    <Typography variant="h6" fontSize={18} sx={{ color: '#551fff' }}>{numberFormat(lastMonthTransactions)}</Typography>
                                    <Typography variant="p" fontSize={14} display={'flex'} gap={1} alignItems={'center'}>
                                        <span style={{ fontSize: "12px" }}>Total Transactions</span>
                                        <span style={{ fontWeight: 700 }}>{lastMonthTransaction}</span>
                                    </Typography>
                                </Box>
                                <Box component={'div'} sx={{ width: '100%', height: "100%", flexDirection: 'column', display: "flex", flex: 0.4, alignItems: "center", justifyContent: "center" }}>
                                    <DescriptionIcon sx={{ fontSize: '50px', color: "#551fff", paddingX: '10px', paddingY: '10px', borderRadius: "10px", background: "#f7f5ff" }} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12} width={'100%'} pt={3}>
                <Grid container spacing={2} direction={'column'}>
                    <Grid item display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography sx={{fontWeight:700}}>Recent Transactions</Typography>
                        <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-label">Today</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Today"
                                onChange={setOverallPerfomanceDates}
                            >
                                <MenuItem value={'Today'}>Today</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item width={'50%'}>
                        <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                    </Grid>
                    <Grid item width={'100%'} mt={2}>
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={TransactionsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={TransactionsData} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12} width={'100%'} pt={3}>
                <Grid container spacing={2} direction={'column'}>
                    <Grid item display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography>Agents</Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>Create Agent <ArrowForwardIosIcon /> </Typography>
                    </Grid>
                    <Grid item width={'100%'} mt={2}>
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={AgentsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={agentsData} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export { MTNdashboard }