import React, {useState, useEffect, useRef} from "react";
import {Modal, Grid, Box, Button, Select, AccordionSummary, AccordionDetails, Checkbox, Accordion} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {useNavigate, useParams} from 'react-router-dom';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

const wholeModal = {
    width: '500px',
    height: '477px',
    backgroundColor: '#fff',
    borderColor:"transparent",
    borderRadius: "7px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};
const headerSection = {
    backgroundColor: 'red',
    width: '100%',
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    height: '109px',
    flexDirection: "row",
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
}


const primaryInvoice = {
    backgroundColor:"#032541",
    color:"white",
    width: "225px",
    height: "45px",
    margin:"5px"
}

const SelectStudentsModal = (props) => {

    const [invoiceOpen, setInvoiceOpen] = useState(props.modalOpen);
    const [students, setStudents] = useState([])
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { customerId } = useParams();

    const [expanded, setExpanded] = useState(null);
    const handleChange = (id) => (event, isExpanded) => {
        setExpanded(isExpanded ? id : false);
    };

    useEffect(()=>{
        console.log("parent", props.parentName)
    },[props.parentName])

    useEffect(()=>{
        let newArr = [...props.selectedStudents]
        console.log("new students", newArr)
    },[props.selectedStudents])


    const fetchStudentsInfo = async () => {
        let url = baseUrl + "/api/getBillableItems";
        await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-Authorization': localStorage.getItem("X-Authorization")
            },
            body: JSON.stringify({
                customerId:customerId
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log("student info",data);
                setStudents(data?.data)
            })
            .catch(error => {
                console.error(error);
            });
    };


    useEffect(() => {
        setInvoiceOpen(props.modalOpen);
    }, [props.modalOpen]);

    useEffect(()=>{
        fetchStudentsInfo()
    },[])

    return (
        <div>
            <Modal
                open={invoiceOpen}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={wholeModal}>
                    <div style={headerSection}>
                        <div
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: 25,
                                paddingTop:"40px"
                            }}
                        >
                            Create Invoice
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div style={{
                            marginTop:"20px"
                        }}>

                            <Accordion expanded={expanded === 'x'} onChange={handleChange('x')}>
                                <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon />}>
                                    <Typography sx={{"fontFamily":"Poppins","fontSize":"15px","fontWeight":"600","fontStretch":"normal","fontStyle":"normal","lineHeight":"2.2","letterSpacing":"normal","textAlign":"left","color":"#53365c"}} >{props.parentName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{
                                        display:"flex",
                                        flexDirection:"column"
                                    }}>
                                        {
                                            students.map((item, index) => (
                                                <FormControlLabel key={index} control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            // Check if the student is already in the `selectedStudents` array
                                                            const studentIndex = props.selectedStudents.findIndex(
                                                                (student) => student._id === item._id
                                                            );

                                                            // If the student is already in the array, remove it
                                                            if (studentIndex > -1) {
                                                                props.setSelectedStudents([
                                                                    ...props.selectedStudents.slice(0, studentIndex),
                                                                    ...props.selectedStudents.slice(studentIndex + 1),
                                                                ]);
                                                            } else {
                                                                // If the student is not in the array, add it
                                                                props.setSelectedStudents([...props.selectedStudents, item]);
                                                            }
                                                        }}
                                                />} label={`${item.firstName} ${item.lastName}`} />
                                            ))
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>


                            <div style={{
                                display:"flex",
                                marginTop:"20%",
                                justifyContent:"center"
                            }}>
                                <Button style={primaryInvoice} onClick={()=>props.onClose()}>Select</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
export { SelectStudentsModal };
