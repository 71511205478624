import { Grid, Typography, Breadcrumbs, Button, Menu, Tab, MenuItem, Dialog, DialogContent, DialogActions, Slide } from "@mui/material"
import { forwardRef, useEffect, useState } from "react"
import { alpha, styled } from "@mui/material/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BankImg from "../../../images/bankNo.svg"
import RestoreImg from '../../images/restore-icn.svg'
import AddBanks from "./addBanks";
import EditBanks from "./editBanks";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HttpComponent from "../../../components/School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../components/No Rows/noRowsOverlay";
import WarningImg from "../../images/warning.svg"
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/Delete';
import { SuccessAlert } from "../../../components/snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../components/snackBar Alerts/errorAlert";


//transition

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


//active banks
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
    fontSize: "18px", marginLeft: "-10px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

//breadcrumbs

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.error">Banks</Typography>
]
export default function MainBankSetUp(props) {
    const [flagState, setFlagState] = useState('Active')
    const [tabValue, setTabValue] = useState('Active')
    const [activeBanks, setActiveBanks] = useState([])
    const [inactiveBanks, setInActiveBanks] = useState([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false)
    const [openDeleteForeverDialog, setOpenDeleteForeverDialog] = useState(false)
    const handleOpenDeleteDialog = () => { setOpenDeleteDialog(true) }
    const handleCloseDeleteDialog = () => { setOpenDeleteDialog(false) }
    const handleOpenRestoreDialog = () => { setOpenRestoreDialog(true) }
    const handleCloseRestoreDialog = () => { setOpenRestoreDialog(false) }
    const handleopenDeleteForeverDialog = () => { setOpenDeleteForeverDialog(true) }
    const handleCloseDeleteForeverDialog = () => { setOpenDeleteForeverDialog(false) }

    const [successShow, setSuccessShow] = useState({state: false, message: ""})
    const [errorShow, setErrorShow] = useState({state: false, message: ""})

    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)

    const [Id ,setId] = useState('')
    const updateId = (id)=>{
        setId(id)
    }

    console.log(Id , 'selected id')
    const [dataGridPageSize, setPageSize] = useState(5);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    const [bankId ,setBankId] = useState()
    const handleAddBank = () => {
        setFlagState('addbanks')
    }

    const handleEditBank = (bankId) => {
        setBankId(bankId)
        setFlagState('editBank')
    }

    const backAactive = () => {
        setFlagState('Active')
    }

    //active banks columns
    const ActiveBanksColumn = [
        { field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.name}</span></>) } },
        { field: 'description', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Description</strong>) }, renderCell: (params) => { return (<span>{params?.row?.description}</span>) } },
        {
        field: 'actions', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {

            //delete bank

                function handleDeleteBank(){
                    try {
                        HttpComponent({
                            method:"DELETE",
                            url:`/api/delete_bank/${Id}`,
                            token:localStorage.getItem('X-Authorization')
                        }).then((data)=>{
                           if(data.status === 200){
                            setSuccessShow({state:true , message:"Bank successfully deleted"})
                            handleCloseDeleteDialog()
                           }
                        })
                        fetchActiveBanks()
                    } catch (error) {
                        
                    }
                }

                

                return (
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <MenuItem disableRipple >
                            <EditIcon onClick={()=>{handleEditBank(params?.row?._id)}} style={{ color: "#032541" }} />
                        </MenuItem>
                        <MenuItem disableRipple>
                            <HighlightOffIcon onClick={() => { handleOpenDeleteDialog() ; updateId(params?.row?._id)}} style={{ color: "#DC3545FF" }} />
                            <Dialog
                                open={openDeleteDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                PaperProps={{
                                    style: {
                                        height: "300px",
                                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                                    },
                                }}
                                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                                    <img src={WarningImg} alt='approve' />
                                    <div style={{ marginLeft: '20px' }}>
                                        <h6>Deactivate Bank</h6>
                                        <span>Selected bank  will be <br></br> deactivated.</span><br></br>
                                        <span>This action cannot be undone</span>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDeleteDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                                    <Button onClick={handleDeleteBank} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                                </DialogActions>
                            </Dialog>
                        </MenuItem>
                    </div>

                )
            },
        }
    ]

    //inactive banks columns

    const BanksInActiveColumn = [
        { field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Bank Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.name}</span></>) } },
        { field: 'description', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Description</strong>) }, renderCell: (params) => { return (<span>{params?.row?.description}</span>) } },
        {
            field: 'currency', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <MenuItem disableRipple>
                            <RestoreIcon onClick={() => { handleOpenRestoreDialog() }} style={{ color: "#DC3545FF" }} />
                            <Dialog
                                open={openRestoreDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                PaperProps={{
                                    style: {
                                        height: "300px",
                                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                                    },
                                }}
                                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                                    <img src={RestoreImg} alt='approve' />
                                    <div style={{ marginLeft: '20px' }}>
                                        <h6>Activate Bank ?</h6>
                                        <span>Selected bank will be <br></br> activated.</span><br></br>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseRestoreDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                                    <Button onClick={() => { handleCloseRestoreDialog() }} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Restore</Button>
                                </DialogActions>
                            </Dialog>
                        </MenuItem>
                        <MenuItem disableRipple>
                            <DeleteIcon onClick={() => { handleopenDeleteForeverDialog() }} style={{ color: "#DC3545FF" }} />
                            <Dialog
                                open={openDeleteForeverDialog}
                                TransitionComponent={Transition}
                                keepMounted
                                PaperProps={{
                                    style: {
                                        height: "300px",
                                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                                    },
                                }}
                                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                                    <img src={WarningImg} alt='approve' />
                                    <div style={{ marginLeft: '20px' }}>
                                        <h6>Delete Bank</h6>
                                        <span>Selected bank  will be <br></br> deleted.</span><br></br>
                                        <span>This action cannot be undone</span>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDeleteForeverDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                                    <Button onClick={() => { handleCloseDeleteForeverDialog() }} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Delete</Button>
                                </DialogActions>
                            </Dialog>
                        </MenuItem>
                    </div>

                )
            },
        }

    ]
    //fetch active banks

    function fetchActiveBanks() {
        try {
            HttpComponent({
                method: "GET",
                url: "/api/get_banks_list?page=1&limit=10",
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'active banks')
                if(data.status === 201){
                    setActiveBanks(data?.response?.data)
                }
            })
        } catch (error) {

        }

    }
        useEffect(()=>{
            fetchActiveBanks()
        },[])


    return (
        <> {flagState === 'Active' ?
            <Grid direction={'column'} bgcolor="white">
                  <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Typography style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }} mt={2} ml={2}>Banks</Typography>
                </Grid>
                <Grid item>
                    <Grid container display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'} direction={'row'} >
                        <Grid item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'} ml={2}>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleAddBank} style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '125px' }} >Add Bank</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {activeBanks.length > 0 ?
                    <Grid item>
                        <TabContext value={tabValue}>
                            <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }} >
                                <AntTab label="Active Banks" value="Active" sx={{ml:0.5, mt:-3}}/>
                                <AntTab label="InActive Banks" value="inActive" sx={{ mt:-3}}/>
                            </AntTabs>
                            <TabPanel value="Active">
                                <DataGrid
                                    components={{ NoRowsOverlay: NoRowsOverlay }}
                                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", mt: -4,color: '#272d3d', boxShadow: 0, border: 0 }}
                                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                    rowCount={pageState?.total}
                                    loading={pageState?.isLoading}
                                    pagination
                                    page={pageState?.page - 1}
                                    pageSize={dataGridPageSize}
                                    paginationMode="server"
                                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    columns={BanksInActiveColumn}
                                    rows={activeBanks}
                                    getRowId={row => row?._id} />
                            </TabPanel>
                            <TabPanel value="inActive">
                                <DataGrid
                                    components={{ NoRowsOverlay: NoRowsOverlay }}
                                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", mt:-4, color: '#272d3d', boxShadow: 0, border: 0 }}
                                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                    rowCount={pageState?.total}
                                    loading={pageState?.isLoading}
                                    pagination
                                    page={pageState?.page - 1}
                                    pageSize={dataGridPageSize}
                                    paginationMode="server"
                                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    columns={ActiveBanksColumn}
                                    rows={inactiveBanks}
                                    getRowId={row => row?._id} />
                            </TabPanel>
                        </TabContext>
                    </Grid>
                    : <Grid container direction={'column'} mt={20} justifyContent={'center'} alignContent={'center'}>
                        <Grid item direction={'column'} display={'flex'} justifyContent={'center'} textAlign={'center'}>
                            <img src={BankImg} alt="No Banks" />
                            <Typography style={{ color: "#969bac", fontSize: "14px" }}>Add banks and track them here.</Typography>
                        </Grid>
                    </Grid>}
            </Grid>
            : flagState === 'addbanks' ? <AddBanks back={backAactive} /> : flagState === 'editBank' ? <EditBanks back={backAactive} id={bankId} /> : ''
        }
        </>
    )

}