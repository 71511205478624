import { Breadcrumbs, Button, Grid, InputLabel, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SuccessAlert } from "../../snackBar Alerts/successAlert"
import { ErrorAlert } from "../../snackBar Alerts/errorAlert"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import AutoCompleteFiltertransport from "../../transportBusiness/reports/autoselectFilter"
import HttpComponent from "../MakeRequest"
import SearchComponentFreeSolo from "./searchFree"
import { debounce } from "lodash"

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Sponsor</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Sponsored Student</Typography>,
]

const EditSponsoredStudents = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue ,setSearchValue] = useState('')
    const [amount, setAmount] = useState()
    const [allSponsors, setAllSponsors] = useState([])
    const [selectedSponsor, setSelectedSponsor] = useState()
    const [selectedStudent, setSelectedStudent] = useState()
    const [allStudents, setAllStudents] = useState([])
    const [isLoading , setIsloading] = useState(false)
    const [searchSponsor , setSearchSponsor] = useState('')
    const navigate = useNavigate()

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue, customerId] = state.split("?")

    const handleSearchChange = (event, newValue, reason) => {    
        if (reason === 'selectOption' && typeof newValue === 'object') {
            setSelectedStudent(newValue)
        }
        setSearchValue(newValue);
    };
    const [customerDetails, setCustomerDetails] = useState({})

    //get students details
    function getCustomerdetails() {
        setIsloading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_number?customerId=${customerId}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setCustomerDetails(data?.response?.data ? data?.response?.data[0] : {})
                } else {
                    setIsloading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCustomerdetails()
    }, [customerId])


    //get sponsors
    function fetchSponsorsAdded() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?customerClassification=Sponsor&status=ACTIVE&searchValue=${searchSponsor}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundSponsor = data?.response?.data?.map((item) => ({
                        value: item?._id,
                        label: item?.customerName
                    }))
                    setAllSponsors(foundSponsor)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchSponsorsAdded()
    }, [searchValue])

    // add sponsored student

    console.log(selectedStudent , 'selectedStudent')

    function addNewSponsoredStudent() { 
        try{
            HttpComponent({
                method:"POST",
                url: `/api/addsponsorstostudent`,
                body: {
                    billableItemId: selectedStudent?.studentId,
                    sponsorId: selectedSponsor?.value,
                    amount: amount
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200){
                    setSuccessShow({state: true,message: data?.response?.message})
                    setTimeout(() => {
                        navigate(-1)
                    }, 2000)
                }else{
                    setErrorShow({state: true,message: data?.response?.message})
                }
            })
        }catch(error){
            console.log(error)
        }finally{

        }
    }

    return (
        <Grid container flexDirection={'column'} display={'flex'} width={'100%'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Sponsored Student Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mb={1} width={'50%'} mt={2}>
                <Grid container width={'100%'} gap={1}>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "500", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Student Name</InputLabel>
                        <TextField style={{ width: "100%" }} disabled value={selectedStudent?.parentName} name='firstName' id="outlined" placeholder="Student Name" multiline required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "500", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Student ID</InputLabel>
                        <TextField style={{ width: "100%" }} disabled value={selectedStudent?.itemNumber} name='studentId' id="outlined" placeholder="Student ID" multiline required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mb={1} width={'50%'} mt={2}>
                <Typography sx={{ width: '100%' }}>Select Sponsor</Typography>
                <AutoCompleteFiltertransport label={'Sponsors'} sx={{ width: "100%" }} options={allSponsors} value={selectedSponsor} onChange={(e, newValue) => { setSelectedSponsor(newValue) }} />
            </Grid>
            <Grid item mt={1} display={'flex'} flexDirection={'column'} width={'50%'}>
                <InputLabel style={{ fontWeight: "500", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Max Amount Per Day</InputLabel>
                <TextField style={{ width: "100%" }} value={amount} name='email' onChange={(e) => setAmount(e.target.value)} id="outlined" placeholder="Max Amount Per Day" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => addNewSponsoredStudent()} disabled={!amount || !selectedSponsor || !selectedStudent} style={!amount || !selectedSponsor || !selectedStudent ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Add</Button>
            </Grid>     
        </Grid>
    )
}

export { EditSponsoredStudents }