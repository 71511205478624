import React, { useState, useEffect } from "react";
import { Grid, Breadcrump } from "@mui/material";
import { stockStyles } from "./stockStyling";
import Breadcrumbcomponent from "./breadcrumb";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import CustomSelect from "./customSelect";
import CustomDataGrid from "./customDataGrid";
import StockFunctions from "./stockEndpoints";

const ActiveStockWarehouse = () => {
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [warehouseId, setItemId] = useState("");
  const [searchValue, setInputValue] = useState("");
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, warehouses: [], warehouseLoading: true, warehouseStock: [] });

  useEffect(() => {
    const stockFnc = new StockFunctions();
    stockFnc.findStoresByType("Warehouse", pageState.page, dataGridPageSize).then((data) => {
      if (data.status === 200) {
        setPageState({ ...pageState, warehouses: data?.response?.data });
        if(data?.response?.data?.length == 0){
          setPageState({ ...pageState, isLoading:false });
        }
      }else {
        setPageState({ ...pageState, isLoading:false });
      }
    });
  }, [pageState.page, dataGridPageSize]);

  useEffect(() => {
    // storeType, storeId,  page, pagesize
    if (warehouseId) {
      const stockFnc = new StockFunctions();
      stockFnc.getStockPerStore(warehouseId, searchValue, pageState.page, dataGridPageSize).then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, warehouseStock: data?.response?.data, count: data?.response?.count });
        }
      });
    }
  }, [dataGridPageSize, pageState.page, warehouseId, searchValue]);

  // useEffect(() => {
  //   // storeType, storeId,  page, pagesize
  //   if (searchValue) {
  //     const stockFnc = new StockFunctions();
  //     stockFnc.searchProductsPerStore(warehouseId, pageState.page, dataGridPageSize, searchValue).then((data) => {
  //       if (data.status === 200) {
  //         setPageState({ ...pageState, isLoading: false, warehouseStock: data?.response?.data, count: data?.response?.count });
  //       }
  //     });
  //   }
  // }, [dataGridPageSize, pageState.page, warehouseId, searchValue]);

  // warehouse columns;

  const warehouseColumns = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "productCategory", headerName: "Category", flex: 1 },
    { field: "inStockQuantity", headerName: "Instock", flex: 1 },
    { field: "sellingPrice", headerName: "selling Price (KES)", flex: 1 },
  ];

  const warehouseData = pageState?.warehouseStock.map((warehouse) => ({
    id: warehouse._id,
    productName: warehouse?.productName,
    productCategory: warehouse?.productCategory,
    inStockQuantity: warehouse?.inStockQuantity,
    sellingPrice: warehouse?.sellingPrice,
  }));

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"}>
        <Grid item style={{ width: "30%", margin: "0px 2% 0px 0px" }}>
          <CustomSelect data={pageState?.warehouses} setItemId={setItemId} />
        </Grid>

        <Grid item style={{ width: "30%"}}>
          <SearchFilter placeholder={"Search product"} setInputValue={setInputValue} />
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"}>
        <CustomDataGrid customStyles={{height:"600px"}} column={warehouseColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={warehouseData} />
      </Grid>
    </div>
  );
};

export default ActiveStockWarehouse;
