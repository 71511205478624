import {Button, Grid, MenuItem, Select, TextField} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {handleTenantBioData, handleTenantHouseData, handleUpdatedTenantHouseData} from "../../../features/tenantsDataSlice";
import CustomSelectField from "../../School/CustomSelectField";
import HttpComponent from "../../School/MakeRequest";

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES",}).format(value);

export default function AddHouseDetails(props) {
	const [houseNo, setHouseNo] = useState('')
	const [houseType, setHouseType] = useState('')
	const [billingPeriod, setBillingPeriod] = useState('')
	const [period, setPeriod] = useState([])
	const [unitHouseType, setUnitHouseType] = useState([])
	const [unitHouseNo, setUnitHouseNo] = useState([])
	const [unit_id, setUnit_id] = useState('')
	const [unit_amount, setUnit_amount] = useState('')
	const [rentDueDate ,setRentDueDate] = useState('')
	const [unitPurpose , setUnitPurpose] = useState('')
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const configId = businessUnitConfigId ? businessUnitConfigId : "64074a6feae8f43c3b8e2044"
	
	//Billing Periods
	const billingPeriodOptions = period.map((date) => ({value : date, label : date}))
	const unitHouseNumberOptions = unitHouseNo.map((house) => ({value : house.unitName, label : house.unitName}))
	
	const dispatch = useDispatch()
	const houseData = {unitType : houseType, unitPurpose:unitPurpose, itemNumber : houseNo, billingDate : billingPeriod, rentDueDate:rentDueDate, amount : unit_amount, unitId : unit_id}
	
	const handleGoBackClick = () => {
		props.setFlagGoBack('')
	}

	// unit house props
	const handleAddCharges = () => {

		if(props?.isFromHouse === true){
			dispatch(handleTenantHouseData(houseData))
			props.setFlagStateViewServices()
		}else{
			dispatch(handleTenantHouseData(houseData))
			props.setFlagStateViewServices()
		}
		
	}
	
	
	// Get Billing Periods
	function getBillingPeriods() {
		try {
			HttpComponent({
				method : 'GET',
				url : '/api/get_billing_dates',
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201){
					setPeriod(data.response.data)
				}else{
					console.log(`Error Getting Periods!`)
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error Getting Periods!`)
		}
	}
	
	// Get House Types
	function getHouseTypes() {
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_unit_types_by_business_unit_config?businessUnitConfigId=${configId}`,
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201){
					setUnitHouseType(data.response.data)
				}else{
					console.log(`Error Getting Periods!`)
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error getting House types`)
		}
	}
	
	console.log('unit id ', unit_id)
	
	// Get House No
	function getHouseNo() {
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_units_by_unit_type_id?page=1&limit=1000&businessUnitTypeId=${unit_id}`,
				token : X_Authorization
			}).then((data)=>{
				if(data.status === 201){
					setUnitHouseNo(data.response.data)
				}else{
					console.log(`Error Getting Periods!`)
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error Getting House No.`)
		}
	}
	
	useEffect(() => {
		getBillingPeriods()
		getHouseTypes()
	}, [])
	
	useEffect(() => {
		getHouseNo()
	}, [unit_id])
	
	console.log(houseData , 'house deatils')
	return (
		<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} mt={3} spacing={3}>
			
			{/*Header*/}
			<Grid item>
				<span style={{fontSize : '16px', fontWeight : '600', color : '#032541'}} >Add House Details</span>
			</Grid>
			
			{/*House Type*/}
			<Grid item >
				<Select
					MenuProps={{anchorOrigin: {vertical: "bottom", horizontal: "left"}, transformOrigin: {vertical: "top", horizontal: "left"}, getcontentanchorel: null}}
					value={houseType} onChange={(e) => {setHouseType(e.target.value)}} style={{color : '#032541FF', width : '100%', fontSize:"0.875rem", "& fieldset": {borderColor: "#bec5d1"}}} variant="outlined" margin="dense" displayEmpty>
					<MenuItem value="">Select House Type</MenuItem>
					{
						unitHouseType.map((house, index) => (
							<MenuItem key={index} onClick={() => {setUnit_id(house._id); setUnitPurpose(house.unitPurpose); setUnit_amount(house.unitCost)}} value={house.unitType}>{house.unitType}</MenuItem>
						))
					}
				</Select>
				{/*<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={houseType} onChange={(e) => {setHouseType(e.target.value)}} name={"House Type"} placeholder={"Select House Type"} options={unitHouseTypeOptions} />*/}
			</Grid>
			
			{/*House No*/}
			<Grid item>
				<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={houseNo} onChange={(e) => {setHouseNo(e.target.value)}} name={"House No"} placeholder={"Select House No"} options={unitHouseNumberOptions} />
			</Grid>
			
			{/*House No*/}
			<Grid item>
				<TextField disabled required readOnly value={numberFormat(unit_amount)} defaultValue={numberFormat(unit_amount)} type='text' InputProps={{style : {fontFamily : 'Poppins'}}} InputLabelProps={{style : {fontFamily : 'Poppins', fontSize : "13px", color : "#032541", fontWeight : 'normal'}}} style={{border: "solid 0px #e4e4e4", fontSize : "12px", width : '100%'}} id="outlined-basic" label="Amount" variant="outlined" />
			</Grid>
			
			{/*Billing Period*/}
			<Grid item>
				<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={billingPeriod} onChange={(e) => {setBillingPeriod(e.target.value)}} name={"Billing Period"} placeholder={"Select Billing Period"} options={billingPeriodOptions} />
			</Grid>
			{/*Rent due */}

			<Grid item>
				<CustomSelectField style={{color : '#032541FF', width : '400px', fontFamily : 'Poppins', fontSize : '9px',fontWeight : 'normal'}} value={rentDueDate} onChange={(e) => {setRentDueDate(e.target.value)}} name={"Rent Due Date"} placeholder={"Select Rent Due Date"} options={billingPeriodOptions} />
			</Grid>
			
			{/*Button*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'flex-end'} spacing={3}>
					
					{/*Cancel*/}
					<Grid item>
						<Button onClick={handleGoBackClick} style={{border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}} >
							Cancel
						</Button>
					</Grid>
					{/*Next*/}
					<Grid item>
						<Button onClick={handleAddCharges} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '16px', fontWeight : '600'}}>
							Next
						</Button>
					</Grid>
				</Grid>
			</Grid>
		
		</Grid>
	)
}
