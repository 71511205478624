import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutletsTable from "../components/Outlets/OutletsTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { setSearch } from "../../../features/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import AnnualMaintenanceTable from "../components/AnnualMaintenance/AnnualMaintenanceTable";

//   import Papa from "papaparse";

//   function exportArrayToCSV(data, filename) {
//     const csv = Papa.unparse(data);
//     const blob = new Blob([csv], { type: "text/csv" });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = filename;
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//     URL.revokeObjectURL(url);
//   }

function AnnualMaintenanceFee() {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //seeting search value in redux when user types in textfield
  useEffect(() => {
    if (searchValue) {
      dispatch(setSearch(searchValue));
    } else {
      dispatch(setSearch(""));
    }
  }, [searchValue]);

  // const exportData = useSelector((state) => state.exportDetails.outlets);

  const handleExport = () => {
    //   exportArrayToCSV(exportData, "Outlets.csv");
  };

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      {/* <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Annual Maintenance Fee
          </Typography>
          
        </Stack>
      </Stack> */}
      {/* <Stack direction="row" justifyContent={"space-between"}> */}
        {/* <Breadcrumbs name="Annual Maintenance Fee" /> */}
        {/* {showCreateAlert && ( */}
        {/* <Alert variant="filled" severity="info">
            Feature currently under development... @Elijah 
          </Alert> */}
        {/* )} */}
      {/* </Stack> */}
      <Box width={"100%"}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={2}>
          <TextField
            id="input-with-icon-textfield"
            label="Search by institution"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearch}
          />
          <Button
            variant="outlined"
            sx={{
              bgcolor: "#f5f6f7",
              borderColor: "#f5f6f7",
              color: "#032541",
              ":hover": {
                borderColor: "#032541",
              },
              textTransform: "none",
            }}
            onClick={handleExport}
          >
            Export
            <MoreVertIcon
              sx={{
                color: "#032541",
              }}
            />
          </Button>
        </Stack>
        <Stack>
          <AnnualMaintenanceTable />
        </Stack>
      </Box>
    </Stack>
  );
}

export default AnnualMaintenanceFee;
