import { Button, Checkbox, Grid, Menu, MenuItem } from "@mui/material";
import CustomSearchInput from "../../School/CustomSearchInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HttpComponent from "../../School/MakeRequest";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import moment from "moment-timezone";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import SetWorkFlow from "../Utility/updateSetupWorkFlow";


//format currency
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

//styled menu icon
const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
			frontFamily: "Poppins",
		}}
		{...props}
	/>
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));

export default function ListAllTenantsSetUp(props){
    const { X_Authorization } = useSelector((store) => store.user)
    const [tenantData ,setTenantData] = useState([])

	console.log(tenantData)
    
    const navigate = useNavigate()

		// DataGrid Pagination
		const [dataGridPageSize, setPageSize] =useState(5);
		const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    // Get Tenants
		function fetchTenants() {
			try {
				setPageState((old) => ({ ...old, isLoading: true }));
				HttpComponent({
					method: 'GET',
					url: `/api/customers_list?limit=${dataGridPageSize}&page=${pageState.page}&customerType=&status=ACTIVE`,
					token: X_Authorization
				})
					.then((data) => {
						console.log(data, 'all tenats')
						if (data.status === 200) {
							setTenantData(data.response.data)
							setPageState((old) => ({
								...old,
								isLoading: false,
								data: data.response.data,
								total: data.response.count,
							}));
						}
					})
					.catch((e) => {
						console.log(`Error Getting Tenants`, e.message)
					})
			} catch (e) {
				console.log(`Error Getting Tenants`, e.message)
			}
		}

		//Use Effect
		useEffect(() => {
			fetchTenants()
		}, [dataGridPageSize, pageState.page])



        //tenants columns

        const tenantsColumns = [
			{ headerName: "name", field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Names</strong>) } ,renderCell:(params)=>{
				return(
					<>
						<Checkbox />
						<span>{params.row.name}</span>
					</>
				)
			} },
			{ headerName: "customerType", field: 'customerType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Type</strong>) } },
			{ headerName: "houseNo", field: 'houseNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >House No.</strong>) },renderCell:(params)=>{
				
				//const total houses
				const totalHouses = params.row.houseNo
				const houselength = totalHouses.length
				return(
					<>
					<span>{houselength}</span>
					</>
				)
			} },
			{ headerName: "mobileNumber", field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Mobile No.</strong>) } },
			{ headerName: "email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) } },
			{ headerName: "createdOn", field: 'createdOn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date Created</strong>) }, renderCell: (params) => { return (<span>{moment(params.value).format('MM/DD/yyyy')}</span>) } },
			{ headerName: "pendingInvoices", field: 'pendingInvoices', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Pending Invoices</strong>) }, renderCell: (params) => { return (<span style={{ color: params.value > 0 ? '#dc3545' : null }}>{params.value}</span>) } },
			{ headerName: "pendingAmount", field: 'pendingAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Pending Amount</strong>) }, renderCell: (params) => { return (<span style={{ color: params.value > 0 ? '#dc3545' : '#17ae7b' }}>{numberFormat(params.value)}</span>) } },
			{
				headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) },
				renderCell: (params) => {
                    //const [selectedRowId ,setSelectedRowID] = useState(null)
					const [anchorEl, setAnchorEl] = useState(null);
					const ActionOpen = Boolean(anchorEl);
					const handleClick = (event) => {
						console.log(params.row._id)
						setAnchorEl(event.currentTarget)
					};
					const handleActionClose = () => { setAnchorEl(null); };

					function handleDeleteCustomer(id){
						try {
							
							HttpComponent({
								method:"GET",
								url:`/api/deleteCustomer?customerId=${id}`,
								token:X_Authorization
							}).then((data)=>{
								console.log(data)
								if(data.status === 202){
									fetchTenants()
								}
							})
						} catch (error) {
							
						}
					}

					return (
						<div>
							<MoreVertIcon onClick={handleClick} />
							<StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
								<MenuItem disableRipple onClick={()=>navigate(`/tenant/edit/${params.row._id}`)}>
									<ModeEditIcon style={{ color: "#DC3545FF" }} /> Edit
								</MenuItem> 
								<MenuItem disableRipple onClick={()=>handleDeleteCustomer(params.row._id)}>
									<DeleteIcon style={{ color: "#DC3545FF" }} /> Delete
								</MenuItem>
							</StyledMenu>
						</div>
					)
				}
			},

        ]


    //handle complete the setup

    function handleCompleteInvoice() {
		SetWorkFlow({ flowName: "COMPLETE", token:localStorage.getItem('X-Authorization')})
		navigate("/dashboard")
	}


    return (
        <Grid container direction={'column'} marginTop={5}>
             {/* top tabs */}
            <Grid item>
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                        <CustomSearchInput name={'search'} placeholder={'Search Tenants'} />
                    </Grid>
                    <Grid item>
                        <Button onClick={props?.addAnother} style={{backgroundColor:"#fff", height:"48px", marginRight:"10px", border:"2px solid #032541", color:"#032541" , width:"146px", textTransform:"inherit"}}>Add Existing</Button>
                        <Button onClick={props?.addAnother} style={{backgroundColor:"#032541", height:"48px", color:"#fff" , width:"146px", textTransform:"inherit"}}>Add New</Button>
                    </Grid>
                </Grid>
            </Grid>
            {/* List All tenants Available' */}

            <Grid item>
                <Grid container direction={'column'}>
                    <Grid item>
                        {/* Data grid to list all tenants */}
                        <DataGrid
							components={{ NoRowsOverlay: NoRowsOverlay }}
							sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
							rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
							rowCount={pageState.total}
							loading={pageState.isLoading}
							pagination
							page={pageState.page - 1}
							pageSize={dataGridPageSize}
							paginationMode="server"
							onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							columns={tenantsColumns}
							rows={tenantData}
							getRowId={row => row._id} />
                    </Grid>
                    <Grid item display={'flex'} direction={'row'} alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'} >
                        {/* TODO : complete SetUp Button */}
                        <Button onClick={handleCompleteInvoice} style={{backgroundColor:"#17ae7b", color:"#fff" , width:"146px", height:"48px", textTransform:"inherit"}}>Done</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}