import { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import SearchFilter from "./searchFilter";
import { customerstyles } from "./styles";

export const CustomeronAccountsSearch = (props) => {
  const [customers, setCustomers] = useState([]);
  const inputValue = props?.inputValue
  const setCustomerName = props?.setCustomerName;
  const setInputValue = props?.setInputValue;

  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);
  const getAllCustomers = () => {
    HttpComponent({
      method: "GET",
      url: `/api/customers_list`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        setCustomers(data.response.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  return (
    <div style={customerstyles.universalFont}>
      <Grid container direction={"column"} width={"100%"}>
      

        <Grid item style={customerstyles.customerAccountsDivHolder}>
          {customers
            ?.filter((customers) => {
              return inputValue?.toLowerCase() === "" ? customers : customers?.customerName?.toLowerCase()?.includes(inputValue?.toLowerCase());
            })
            ?.map((customer) => {
              return (
                <>
                  <div style={customerstyles?.customerAccountsDiv} onClick={() => {
                    props?.setCustomersId(customer?._id);
                    setCustomerName(customer?.customerName)
                    setInputValue("")
                  } }>
                    <div>
                      <p style={customerstyles?.customerAccountsPara}>{customer?.customerName}</p>
                      <p style={customerstyles?.customerAccountsPara}>{customer?.customerType}</p>
                      <p style={customerstyles?.customerAccountsPara}>{customer?.paymentType}</p>
                    </div>

                    <div>
                      <p style={customerstyles?.customerAccountsPara}>Available Balance</p>
                      <p style={customerstyles?.customerAccountsPara}> {customer?.paymentType === "Normal" ? "N/A" : numberFormat(customer?.limit)} </p>
                    </div>
                  </div>
                </>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};
