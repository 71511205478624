import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {makeStyles} from "@mui/styles";
import payment from '../../common/images/paymentsetup.svg'
import { LocalAtm } from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function PaymentSetUP () {
	const classes = useStyles();
	const location = useLocation();
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"


	return (
		<div>
			<ListItem button className={classes.nested} style={{  color: isActiveLink("/paymentsettingup") ? activeColor : '#fff' }}>
				<div style={{marginRight : '5%'}}>
				<LocalAtm />
				</div>
				<Link style={{textDecoration: 'none',  color: isActiveLink("/paymentsettingup") ? activeColor : '#fff'}} to="/paymentsettingup">
					<ListItemText primary="Payment Setup" />
				</Link>
			</ListItem>
		</div>
	)
}
