import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import StockFunctions from "./stockEndpoints";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import CustomSelect from "./customSelect";
import CustomDataGrid from "./customDataGrid";
import { ErrorAlertRightAligned } from "../../bills/startorderModals/modals";

const Activestocksubstore = () => {
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [warehouseId, setItemId] = useState("");
  const [searchValue, setInputValue] = useState("");
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, substores: [], warehouseLoading: true, substorestock: [] });
  const [isError, setError] = useState({ state: false, message: "" });
  const isBranch = localStorage.getItem("BranchID");

  const branchError = <ErrorAlertRightAligned error={isError.state} message={isError.message} />;

  useEffect(() => {
    if(isBranch == "undefined"){
        setError({isError:true, message:'Select branch ID to proceed!'})
    }
   
    // setTimeout(() => {
    //     setError({isError:false})
    // }, 2000);
  },[isBranch])

  useEffect(() => {
    const stockFnc = new StockFunctions();
    if (isBranch !== 'undefined'){
      stockFnc.findStoresByType("SubStore", pageState.page, dataGridPageSize).then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, substores: data?.response?.data , isLoading:false });
        }else if(data.status === 400){
          setPageState({ ...pageState, isLoading:false });
        }else {
          setPageState({ ...pageState, isLoading:false });
        }
      })
    } else{
      setPageState({ ...pageState, isLoading:false });
      setError({...isError, state:true, message:"Select branch to view stock!"})
    }
  }, []);

  useEffect(() => {
    // storeType, storeId,  page, pagesize
    if (warehouseId) {
      const stockFnc = new StockFunctions();
      stockFnc.getStockPerStore(warehouseId, searchValue, pageState.page, dataGridPageSize).then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, substorestock: data?.response?.data, count: data?.response?.count });
        }
      });
    }
  }, [dataGridPageSize, pageState.page, warehouseId, searchValue]);

  // useEffect(() => {
  //   // storeType, storeId,  page, pagesize
  //   if (searchValue) {
  //     const stockFnc = new StockFunctions();
  //     stockFnc.searchProductsPerStore(warehouseId, pageState.page, dataGridPageSize, searchValue).then((data) => {
  //       if (data.status === 200) {
  //         setPageState({ ...pageState, isLoading: false, substorestock: data?.response?.data, count: data?.response?.count });
  //       }else if(data?.status === 400){
  //         setPageState({ ...pageState, isLoading: false, substorestock: [], count: 0 });
  //       }else {
  //         setPageState({ ...pageState, isLoading: false, substorestock: [], count: 0 });
  //       }
  //     });
  //   }
  // }, [dataGridPageSize, pageState.page, warehouseId, searchValue]);

  // Substores columns;

  const substoresColumns = [
    { field: "productName", headerName: "Product Name", flex: 1 },
    { field: "productCategory", headerName: "Category", flex: 1 },
    { field: "inStockQuantity", headerName: "Instock", flex: 1 },
    { field: "sellingPrice", headerName: "selling Price (KES)", flex: 1 },
  ];

  const subStoresData = pageState?.substorestock.map((warehouse) => ({
    id: warehouse._id,
    productName: warehouse?.productName,
    productCategory: warehouse?.productCategory,
    inStockQuantity: warehouse?.inStockQuantity,
    sellingPrice: warehouse?.sellingPrice,
  }));

  return (
    <div style={{ width: "100%" }}>

        {branchError}
        
      <Grid container direction={"row"} width={"100%"} margin={"0px 0px 1% 0px"}>
        <Grid item style={{ width: "30%", margin: "0px 2% 0px 0px" }}>
          <CustomSelect data={pageState?.substores} setItemId={setItemId} />
        </Grid>

        <Grid item style={{ width: "30%"}}>
          <SearchFilter placeholder={"Search product"} setInputValue={setInputValue} />
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"}>
        <CustomDataGrid column={substoresColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={subStoresData} />
      </Grid>
    </div>
  );
};

export default Activestocksubstore;
