import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { useEffect, useState } from "react";
import ExportMenu from "./ExportMenu";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import { DataGrid ,GridToolbar } from "@mui/x-data-grid";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import CustomSearchInput from "./CustomSearchInput";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import CustomSelectField from "./CustomSelectField";
import { CustomDate } from "../customerAccounts/customerComponents/customDate";
import { escape } from "lodash";

let baseUrl = process.env.REACT_APP_BASE_URL;


const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Student Report
  </Typography>
];

const createButton = {
  // "width": "15.313rem",
  // "height": "2.813rem",
  "width": "125px",
  "height": "45px",
  "borderRadius": "5px",
  "border": "solid 1px #002543",
  "backgroundColor": "#fff",
  "color": "#032541",
  '&:hover': {
    backgroundColor: '#fff',
    color: '#032541'
  }
}
let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined ) {
  localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);

export  function CustomFooterDatagrid({totalInvoiceAmount ,totalInvoiceAmountDiscount}) {
    return (
        <Box>heyyy {totalInvoiceAmount} {totalInvoiceAmountDiscount}</Box>
    )
}


const StudentReports = () => {
  const [year, setYear] = useState('');
  const [totalInvoiceAmount , settotalInvoiceAmount] = useState(0)
  console.log(totalInvoiceAmount ,'totalInvoiceAmount')
  const [totalInvoiceAmountDiscount , settotalInvoiceAmountDiscount] = useState(0)

  const years = Array.from({ length: 5 }, (_, index) => `${new Date().getFullYear() - index}`);

  const handleChange = (event) => {
    setYear(event.target.value);

  };

  const businessName = localStorage.getItem('businessName')
  const [selectedSchoolType, setSelectedSchoolType] = useState('')
  const [filteredData , setFilteredData] = useState([])



  const [grade, setGrade] = useState('')
  const [stream, setStream] = useState('')
  const [courseName, setCourseName] = useState('')
  const [selectedYear, setSelectedYear] = useState('')
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedCustomDate, setselectedCustomDate] = useState()
  const [showSelectDate, setShowSelectDate] = useState(true)


  const hideShowSelectedDate = (selectedCustomDate) => {
    if (selectedCustomDate === 'custom_range') {
      setShowSelectDate(false)
    } else {
      setShowSelectDate(true)
    }
    setselectedCustomDate(selectedCustomDate);
  }

  const [term, setTerm] = useState('')
  const [boardingStatus, setBoardingStatus] = useState('')

  const [invoiceStatus, setinvoiceStatus] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [pageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    count: 0,
    page: 1,
    pageSize: pageSize,
  });

  const showFilteredData = ()=>{
    let searchable = [...pageState.data]
    console.log(searchable , 'search data')
  }


  useEffect(()=>{
    showFilteredData()
  },[pageState.data])

  const [dataStudent, setDataStudentDetails] = useState([])

  const columns = [
    {
      field: "admisisonNumber",
      headerName: "Admission No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541", }}>Admission No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              <Link style={{color:"#032541"}} to={`${`/school/studentpayments/${params.value}`}`}>
                {params.value}
              </Link>
            </Typography>
          </div>
        );
      }
    },
    {
      field: "studentName",
      headerName: "student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Student Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Grade</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "stream",
      headerName: "Stream",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Stream</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    // {
    //   field: "boardingStatus",
    //   headerName: "Boarding Status",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Boarding Status</strong>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Typography variant="body2" color="textSecondary">

    //           {params.value}

    //         </Typography>
    //       </div>
    //     );
    //   }
    // },

    {
      field: "term",
      headerName: "Term",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Term</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    // {
    //   field: "parentName",
    //   headerName: "parentName",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: false,
    //   minWidth: 200,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Parent Name</strong>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Typography variant="body2" color="textSecondary">

    //           {params.value}

    //         </Typography>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   field: "phone",
    //   headerName: "Mobile No",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Mobile No</strong>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Typography variant="body2" color="textSecondary">

    //           {params.value}

    //         </Typography>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   field: "dateOfAdmission",
    //   headerName: "Date Of Admission",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Date Of Admission</strong>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Typography variant="body2" color="textSecondary">

    //           {moment(params.value).format('DD-MM-YYYY')}

    //         </Typography>
    //       </div>
    //     );
    //   }
    // },
    {
      field: "invoiceCount",
      headerName: "invoiceCount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoices</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceAmount",
      headerName: "invoiceAmount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Cum INV Amount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params?.row?.balance)}

            </Typography>
          </div>
        );
      }
    },
    // {
    //   field: "balance",
    //   headerName: "Balance",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Balance</strong>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Typography variant="body2" color="textSecondary">

    //           {params.value}

    //         </Typography>
    //       </div>
    //     );
    //   }
    // },
    {
      field: "totalPaid",
      headerName: "amountPaid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Cum Amount Paid</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceDiscountAmount",
      headerName: "Discount Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Cum DiscountAmount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },

  ]
  const columnsCollege = [
    {
      field: "admisisonNumber",
      headerName: "Admission No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541", }}>Admission Number</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              <Link to={`${`/school/studentpayments/${params.value}`}`}>
                {params.value}
              </Link>
            </Typography>
          </div>
        );
      }
    },
    {
      field: "studentName",
      headerName: "student Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Student Name</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "grade",
      headerName: "Course",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Course</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "term",
      headerName: "Semester",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Semester</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },

    {
      field: "phone",
      headerName: "Mobile No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Mobile No</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "dateOfAdmission",
      headerName: "Date Of Admission",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Date Of Admission</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {moment(params.value).format('DD-MM-YYYY')}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceAmount",
      headerName: "invoiceAmount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Cum INV Amount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params?.row?.balance)}

            </Typography>
          </div>
        );
      }
    },
    // {
    //   field: "balance",
    //   headerName: "Balance",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   editable: false,
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Balance</strong>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Typography variant="body2" color="textSecondary">

    //           {numberFormat(params.value)}

    //         </Typography>
    //       </div>
    //     );
    //   }
    // },
    {
      field: "totalPaid",
      headerName: "Amount Paid",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Cum Amount Paid</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceCount",
      headerName: "invoiceCount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Invoices</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {params.value}

            </Typography>
          </div>
        );
      }
    },
    {
      field: "invoiceDiscountAmount",
      headerName: "Discount Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <strong style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "600", color: "#032541" }}>Cum DiscountAmount</strong>
      ),
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {numberFormat(params.value)}

            </Typography>
          </div>
        );
      }
    },
  ]

  // search value

  // let urlStudentSearch = `/api/get_student_report?page=${pageState.page}&limit=${pageSize}&classification=${selectedSchoolType}&gradeSearch=${grade}&streamSearch=${stream}&termSearch=${term}&boardingSearch=${boardingStatus}&yearSearch=${selectedYear}&courseNameSearch=${courseName}`
  // if(selectedSchoolType){
  //   urlStudentSearch = `/api/get_student_report?page=${pageState.page}&limit=${pageSize}&classification=${selectedSchoolType}`
  // }


  const getStudentDetails = async () => {
   setPageState((old) => ({ ...old, isLoading: true }));
    try {
      HttpComponent({
        method: "GET",
        url:`/api/get_student_report?page=${pageState.page}&limit=${pageSize}&classification=${selectedSchoolType}&gradeSearch=${escape(grade)}&streamSearch=${stream}&termSearch=${term}&boardingSearch=${boardingStatus}&yearSearch=${selectedYear}&courseNameSearch=${courseName}`,
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        console.log(data.response ,'data.response.data')
        setDataStudentDetails(data.response.data)
        settotalInvoiceAmountDiscount(data?.response?.totalInvoiceDiscountAmount)
        settotalInvoiceAmount(data?.response?.totalinvoicebalance)
        setPageState({ ...pageState, isLoading: false, count: data?.response?.count, data: data.response.data })
      })
    } catch (error) {
      console.log(error, 'Error data');

    }
  }

  useEffect(() => {
    getStudentDetails()
  }, [selectedSchoolType, pageSize, pageState.page  ,boardingStatus ,term ,grade,selectedYear ,courseName])


  const rows = pageState?.data?.filter((item) => {
    return courseName === '' ? item : courseName === 'ALL' ? item : item?.grade.includes(courseName)

  })
    .filter((item) => {
      return grade === '' ? item : grade === 'ALL' ? item : item?.grade.includes(grade)

    })
    .filter((item) => {
      return selectedYear === '' ? item : selectedYear === 'ALL' ? item : item?.year.toLowerCase().includes(selectedYear.toLowerCase())

    })
    .filter((item) => {
      return term === '' ? item : term === 'ALL' ? item : item?.term.toLowerCase().includes(term.toLowerCase())

    }).filter((item) => {
      return stream === '' ? item : stream === 'ALL' ? item : item?.stream.toLowerCase().includes(stream.toLowerCase())

    }).filter((item) => {
      return invoiceStatus === '' ? item : invoiceStatus === 'ALL' ? item : item?.invoiceStatus.includes(invoiceStatus)

    }).filter((item) => {
      let filterDate = new Date(item?.dateOfAdmission).getFullYear().toString();
      return year === '' ? item : filterDate === year;
    })

    .filter((item) => {
      return searchValue === "" ? item : item?.admisisonNumber.includes(searchValue)
    }).map((item, index) => {
      return {
        id: index,
        parentId: item?.parentId,
        admisisonNumber: item?.admisisonNumber,
        studentName: item?.studentName,
        grade: item.grade,
        term: item?.term?.toLowerCase(),
        stream: item?.stream,
        boardingStatus: item?.boardingStatus,
        parentName: item?.parentName,
        phone: item?.phone,
        balance: item?.balance,
        invoiceStatus: item?.invoiceStatus,
        dateOfAdmission: item?.dateOfAdmission,
        invoiceCount: item?.invoiceCount,
        invoiceDiscountAmount: item?.invoiceDiscountAmount,
        totalPaid:item?.totalPaid,
        totalUnpaid:item?.totalUnpaid,
       }
    })




  const csvStudentColumns = [
    { label: "Admission No", key: "Admission No" },
    { label: "Student Name", key: "Student Name" },
    { label: "Grade", key: "Grade" },
    { label: "Term", key: "Term" },
    { label: "Stream", key: "Stream" },
    { label: "Invoices", key: "Invoices" },
    { label: "Cum Inv Amount", key: "Cum Inv Amount" },
    { label: "Cum Amount", key: "Cum Amount" },
    { label: "Cum Discount Amount", key: "Cum Discount Amount" },

  ]

  const newstudentFileData = pageState?.data?.map((item) => {
    return {
      "Admission No": item.admisisonNumber,
      "Student Name": item.studentName,
      "Grade": item.grade,
      "Term": item.term,
      "Stream": item.stream,
      "Invoices": item.invoiceCount,
      "Cum Inv Amount": numberFormat(item.balance),
      "Cum  Amount": numberFormat(item.totalPaid),
      "Cum Discount Amount": numberFormat(item.invoiceDiscountAmount),
    }
  })

  const studentFileData = dataStudent.map((item) => {
    return {
      admisisonNumber: item.admisisonNumber,
      studentName: item.studentName,
      grade: item.grade,
      term: item.term,
      stream: item.stream,
      boardingStatus: item.boardingStatus,
      parentName: item.parentName,
      phone: item.phone,
      balance: item.balance,
      // invoiceStatus: item.invoiceStatus,
      dateOfAdmission: item.dateOfAdmission,

    }
  })

  const fileDataStudentHeaders = [["Admission No", "Student Name", "Grade", "Term", "Stream",'Invoices','Cum Inv Amount', 'Cum  Amount' ,"Cum Discount Amount" ]]

  const fileDataStudentPDF = pageState?.data?.map((item) => [item?.admisisonNumber, item?.studentName, item?.grade, item?.term, item.stream, item.invoiceCount ,numberFormat(item.balance) ,numberFormat(item.totalPaid),numberFormat(item.invoiceDiscountAmount)])
  const [gradeOptions, setGradeOptions] = useState([])
  const [courseOptions, setCourseOptions] = useState([]);
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
  const [coursesOptions, setCoursesOptions] = useState([])

  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization":localStorage.getItem('X-Authorization'),
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses");
    if (response.status === 200) {
      // setCoursesOptions(data.data);
      setCoursesOptions(data.data.map((courses) => {
        return { value: courses.courseName, label: courses.courseName }
      }
      ))
    }
  };

  useEffect(() => {
    getCourses();
  }, []);


  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();
    console.log(data, "Grades");

    if (response.status === 201) {
      setGradeOptions(data.data.map((itemGrade) => {
        return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
      }
      ))
    }
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization":localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization":localStorage.getItem('X-Authorization'),
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatusOptions(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      // setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])

  // get school types
  const [allSchoolCategory, setAllSchoolCategory] = useState([])

  function GetType_school() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/config/school_classification`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 201) {
          setAllSchoolCategory(data?.response?.data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetType_school()
  }, [])

  return (
    <>
      <Box component="div">
        <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box component="div">
            <Box component="div" sx={{}}>
              <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                Reports/Student Reports
              </Typography>
            </Box>
            <Box component="div" sx={{}}>
              <Breadcrumbs
                separator={
                  <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>
          </Box>
        </Box>
        <Box component='div' className="mt-3">
          <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
            <div style={{ display: "flex", alignItems: 'center' }}>
              <CustomSearchInput name={'search'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search by Admission...'} />
              {schoolTypeName === 'Kindergarten/Junior/High School' ?
                <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                  <InputLabel id="year-select-label">School Type</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={selectedSchoolType}
                    label="School Type"
                    onChange={(e) => setSelectedSchoolType(e.target.value)}
                  >
                    <MenuItem value="">
                      Select School
                    </MenuItem>
                    {allSchoolCategory.map((school) => (
                      <MenuItem key={school} value={school}>
                        {school}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> : null}
              {schoolTypeName?.includes("University") ?
                <CustomSelectField value={courseName} onChange={(e) => setCourseName(e.target.value)} name={"courseName"} placeholder={"select CourseName"} options={coursesOptions} />
                : (
                  <CustomSelectField style={{ marginLeft: "10px" }} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                )}
            </div>
            <div className="">
              <ExportMenu
                csvColumns={csvStudentColumns}
                fileData={newstudentFileData}
                fileHeaders={fileDataStudentHeaders}
                fileDataPDF={fileDataStudentPDF}
                title={"BusinessName :" + businessName + "Report Type: " + "   Students Report  " + "Print Date : " + new Date().toLocaleDateString()}
                fileName={'studentreport'}
              />
            </div>
          </div>
          <Box sx={{ display: "flex" }}>
            <div className="" style={{display:"flex" , alignItems:"center"}}>
              <div className="" style={{ display: "flex", alignItems: "center" }}>
                <div className="" style={{ display: "flex", alignItems: "center" }}>
                  {/* <Box component="div" sm={{ marginX: 1, maxWidth: "200px", }}  >
                    {schoolTypeName?.includes("University") ?
                      <CustomSelectField value={courseName} onChange={(e) => setCourseName(e.target.value)} name={"courseName"} placeholder={"select CourseName"} options={coursesOptions} />
                      : (
                        <CustomSelectField value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                      )}
                  </Box> */}
                </div>
                {schoolTypeName?.includes("University") ? null : (
                  <div className="ml-2">
                    <Box component="div" sm={{ marginX: 1, maxWidth: "200px",marginLeft:"10px" }}>
                      <CustomSelectField value={stream} onChange={(e) => setStream(e.target.value)} name={"Stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                      {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>
                  </div>
                )}

                <div className="ml-2" style={{marginLeft:"10px"}}>
                  <Box component="div" sm={{ marginX: 1, maxWidth: "200px",marginLeft:"10px" }}>
                    <CustomSelectField value={term} onChange={(e) => setTerm(e.target.value)} name={"Term"} placeholder={schoolTypeName?.includes("University") ? "Select Semesters" : "Select Term"} options={termOptions} sm={{ maxWidth: "200px" }} />
                    {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                  </Box>
                </div>
                {schoolTypeName?.includes("University") ? null : (
                  <div className="ml-2" style={{marginLeft:"10px"}} >

                    <Box component="div" sm={{ marginX: 1, marginLeft:"10px" }}>
                      {/* <CustomSelectField value={boardingStatus} onChange={(e) => setBoardingStatus(e.target.value)} name={"boardingStatus"} placeholder={"Select Boarding Status"} options={boardingStatusOptions} sm={{ maxWidth: "200px" }} /> */}
                      {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>
                  </div>
                )}
                {schoolTypeName?.includes("University") ?
                  <div className="ml-2" >
                    <Box component="div" sm={{ marginX: 1, maxWidth: "200px", }}  >
                      <CustomSelectField value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} name={"selectedYear"} placeholder={"Select Year"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                    </Box>
                  </div>
                  : (
                    <div style={{marginTop:'-18px' , marginLeft:"10px"}}>
                      <FormControl style={{ width: "200px", height: "45px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                        <InputLabel id="year-select-label">Select YEAR</InputLabel>
                        <Select
                          labelId="year-select-label"
                          id="year-select"
                          value={year}
                          label="Year"
                          onChange={handleChange}
                        >
                          <MenuItem value={"Select Year"} disabled>
                            Select Year
                          </MenuItem>
                          {years?.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {showSelectDate ? (<div style={{marginTop:'-18px' , marginLeft:"10px"}}>
                  <FormControl style={{ width: "200px", height: "45px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                    <InputLabel id="">Date</InputLabel>
                    <Select
                      labelId="This Month"
                      id="date"
                      label="Date"
                      value={selectedCustomDate}
                      onChange={(e) => { hideShowSelectedDate(e.target.value) }}
                    >
                      <MenuItem value={"today"} >Today</MenuItem>
                      <MenuItem value={"week"} >This Week</MenuItem>
                      <MenuItem value={"month"} >This  Month</MenuItem>
                      <MenuItem value={"last_month"} >Last Month </MenuItem>
                      <MenuItem value={"last_3_month"} >Last Three Months </MenuItem>
                      <MenuItem value={"year"} >This Year </MenuItem>
                      <MenuItem value={"custom_range"} >Custom Range </MenuItem>
                    </Select>
                  </FormControl>
                </div>) : null}

                {selectedCustomDate === 'custom_range' && !showSelectDate ? (
                  <div style={{ display: "flex", alignItems: "center", marginTop: "-5px" }}>
                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                  </div>) : null}
              </div>

            </div>

          </Box>
        </Box>

        <Box component={'div'} className="mt-5">
          <Grid item>
            <DataGrid
              //components={{ NoRowsOverlay: NoRowsOverlay}}
              sx={{ height: '600px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
              rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
              rowCount={pageState.count}
              loading={pageState?.isLoading}
              pagination
              page={pageState.page - 1}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: pageSize })); }}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              columns={schoolTypeName?.includes("University") ? columnsCollege : columns}
              rows={rows}
              getRowId={row => row.id}
              slots={{
                NoRowsOverlay:NoRowsOverlay,
                footer:CustomFooterDatagrid
              }}
              slotProps={{
                footer:{totalInvoiceAmount , totalInvoiceAmountDiscount}
              }}
          
            />
            
          </Grid>

          {/* <CustomTable data={rows} columns={schoolTypeName?.includes("University") ? columnsCollege : columns} rowsPerPage={pageSize} /> */}

          {/* <Box sx={{ display: 'flex', width: '60%', marginTop: '20px', justifyContent: 'space-between' }}>
            <div style={{display:"flex" , justifyContent:"space-evenly" }}>
              <Typography style={{ fontWeight: 900, fontSize: '21px', marginLeft: '10px', }}>Total:</Typography>
              <Typography style={{ fontWeight: 900, fontSize: '21px', marginLeft: '80px'}}>100 Student </Typography>
            </div>
            <div>
              <Typography style={{ marginLeft: '35px', fontWeight: 900, fontSize: '21px' }}>Total Balance: {numberFormat(0.00)}</Typography>
            </div>
          </Box> */}

        </Box>
      </Box>
      <></>
    </>
  );
};

export default StudentReports;
