import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import HttpComponent from "./MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { deleteBankPaybill, handleIsBankPaybillSetup, setBankPaybillStatus, setBankPaybillData } from '../../features/paymentOptionsSlice';

const baseUrl = process.env.REACT_APP_BASE_URL;

const EditBankPaybill = ({setShowBankPaybillSetup, errorShow, setErrorShow, setSuccessShow}) => {
  // const [bankName, setBankName] = useState('')
    // const [paybill, setPayBill] = useState('')
    const [accountNo, setAcountNo] = useState('')
    // const [verifyAcc, setVerifyAcc] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const { userId ,X_Authorization } = useSelector((store) => store.user)
    const { bankPaybillData} = useSelector((store) => store.payment);


    const [formState, setFormState] = useState(false);

    const dispatch = useDispatch();


    // get KCB MPESA CONFIGS
    const getKCBMpesaConfigDetails = () =>{
      HttpComponent({
          method: 'GET',
          url: `/api/v1/business_config/kcb/byBusiness`,
          body: null,
          token: X_Authorization
      }).then((data)=>{
          console.log("here is existing daraja config data",data);
          if(data.status === 201){
              // setEditData(data.response.data);
              setSuccessShow({
                  state:true,
                  message:"KCB MPESA Configurations Fetched Successfully"
              })
              console.log('mpesa payment data', bankPaybillData);
              dispatch(setBankPaybillData(data.response.data))
              dispatch(setBankPaybillStatus(true));
              dispatch(handleIsBankPaybillSetup(true));
          }else{
              console.error("Error setting info")
              setErrorShow({state:true, message:data.response.message})
          }
      }).catch((error)=>{
          console.error(error.message);
          setErrorShow({state:true, message:error.message})
      })
   }

   useEffect(() => {
      getKCBMpesaConfigDetails();
      bankPaybillData.map((item) =>{
        setAcountNo(item.accountReference);
      })
  }, []);

  console.log(bankPaybillData, accountNo);



    // save bank paybill data to DB
    const updatePayBillDetails = async (e) => {
      // prevent default form behavior
      e.preventDefault();
      const businessName = localStorage.getItem('businessName')
      console.log('businessName', businessName, accountNo)
      if (accountNo === ' ') {
          alert('Account Number is required')
          setOpenSnack(true)
          setError(true)
          setmessage('Account Number is required')
          setTimeout(() => {
              setOpenSnack(false);
          }, 2000);
      }else {
          try {
              const response = await fetch(`${baseUrl}/api/v1/payments/activate_kcb_mpesa`, {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      "X-Authorization": localStorage.getItem("X-Authorization"),
                  },
                  body: JSON.stringify({
                      businessName: businessName,
                      accountNumber: accountNo,
                  }),
              })
              await response.json().then((data) => {
                  console.log('response', data)
                  setOpenSnack(true)
                  setError(false)
                  setmessage('Successfully updated paybill details')
                  dispatch(setBankPaybillStatus(true));
                  dispatch(handleIsBankPaybillSetup(true));
                  // setTimeout(() => {
                  //     setOpenSnack(false);
                  //     window.location.reload();
                  //     // setSuccessfullyPaid(true);
                  // }, 2000);
              })
          } catch (error) {

          }
      }
  }

  const buttonStyles =
      {"width":"7.813rem",
          "height":"2.813rem",
          "borderRadius":"4px",
      }

  const changeFormState = () => {
      if (formState) {
          setFormState(false)

      } if (!formState) {
          setFormState(true)
      }
  }

  // useEffect(() =>{
  //   bankPaybillData.map((item) =>{
  //     setAcountNo(item.accountReference);
  //   })
  // },[bankPaybillData])

  // const deleteBankPaybill = (delIndex) =>{
  //     const remainingBankData = bankPaybillData.filter((item, index) => index !== delIndex);
  //     dispatch(deleteBankPaybill(remainingBankData));
  //     // handleWarningClose()
  //     setSuccessToast({state: true, message:"Category removed successfully."})
  // }


  return (
    <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", marginTop: "4.6%" }}>
                <Box component="form" sx={{ '& .MuiTextField-root': { margin: "20px", paddingTop: "10px" }, }} noValidate autoComplete='off' >
                    <form>
                       {/* <FormControl style={{ width:'100%' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Bank</InputLabel>
                        <TextField select style={{ height: "50px", width:'95%', marginTop:'30px', borderRadius: "1px", fontFamily: "Poppins", }}
                            defaultValue={bankName}
                            // onChange={changePayBills}
                            placeholder='Select Bank'
                            required>

                            {bankbills?.map((item, index) => (
                                <MenuItem key={index} value={item}>{item.bankName}</MenuItem>
                            ))}
                        </TextField>
                       </FormControl>
                        <FormControl style={{ width:'100%' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Paybill Number</InputLabel>
                        <TextField style={{ width: "95%", marginTop: "30px", }}
                            id="outlined-multiline-flexible"
                            multiline
                            required
                            placeholder="522533"
                            onChange={(e) => setPayBill(e.target.value)}
                            value={paybill}
                        />
                        </FormControl> */}
                        <FormControl style={{ width:'100%', marginTop:'-15px' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Account Number</InputLabel>
                        <TextField style={{ width: "95%", marginTop: "30px" }}
                            id="outlined-multiline-flexible"
                            value={accountNo}
                            onChange={(e) => setAcountNo(e.target.value)}
                            multiline
                            placeholder="Account Number"
                            required
                        />
                        </FormControl>


                        {/* {accountNo !== verifyAcc ? <>
                            <FormControl style={{ width:'100%', marginTop:'-15px' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Verify Account Number</InputLabel>
                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                error
                                id="outlined-                               "
                                placeholder='Verify Account Number'
                                value={verifyAcc}
                                onChange={(e) => setVerifyAcc(e.target.value)}
                                multiline
                                required
                            />
                            </FormControl>
                        </> : <>

                           <FormControl style={{ width:'100%', marginTop:'-15px' }}>
                           <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Verify Account Number</InputLabel>
                           <TextField style={{ width: "95%", marginTop: "30px" }}
                                id="outlined-multiline-flexible"
                                placeholder='Verify Account Number'
                                value={verifyAcc}
                                onChange={(e) => setVerifyAcc(e.target.value)}
                                multiline
                                required
                            />
                           </FormControl>
                        </>} */}
                        
                        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '20px', paddingBottom: '20px'}}>
                            <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end", marginTop: '30px'}}>
                                <Box>
                                <Button sx={{
                                ...buttonStyles,
                                border:" solid 1px #002543",
                                color:"#002543",
                                '&:hover': {
                                    backgroundColor:"transparent",
                                    color: '#002543'
                                }
                                }}
                                onClick={() => setShowBankPaybillSetup(false)}
                                >
                                Cancel
                                </Button>

                                <Button sx={{
                               ...buttonStyles,
                                marginLeft:1,
                                backgroundColor:" #032541",
                                color:"white",
                                '&:hover': {
                                    backgroundColor: '#032541',
                                    color: 'white'
                                }
                                }}
                                onClick={updatePayBillDetails}
                                >
                                Update
                                </Button>
                                </Box>
                            </Box>
                        </div>
                        
                    </form>
                
                    <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <Alert severity={ifError === true ? "error" : "success"} sx={{ width: "100%" }}>
                            <span>{message}</span>
                        </Alert>
                    </Snackbar>

                </Box>
            {/* </>} */}

        </Box>
  )
}

export default EditBankPaybill