/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import PartnersTabs from "../components/Partners/PartnersTabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import {
  handleEdited,
  handleDeactivated,
  handleActivated,
  handleDeleted,
  handleAdded,
  handleCreated,
} from "../../../features/AlertSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function Partners() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editedAlertState = useSelector((state) => state.alertState.edited);
  const createdAlertState = useSelector((state) => state.alertState.created);
  const addedAlertState = useSelector((state) => state.alertState.added);
  const deletedAlertState = useSelector((state) => state.alertState.deleted);
  const deactivatedAlertState = useSelector(
    (state) => state.alertState.deactivated
  );
  const activateAlertState = useSelector((state) => state.alertState.activated);
  const [showEditedAlert, setShowEditedAlert] = useState(false);
  const [showCreatedAlert, setShowCreatedAlert] = useState(false);
  const [showDeactivatedAlert, setShowDeactivatedAlert] = useState(false);
  const [showDeletedAlert, setShowDeletedAlert] = useState(false);
  const [showActivatedAlert, setShowActivatedAlert] = useState(false);
  const [showAddedAlert, setShowAddedAlert] = useState(false);

  const handleShowDeletedAlert = () => {
    if (deletedAlertState === true) {
      setShowDeletedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowDeletedAlert(false);
        dispatch(handleDeleted(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeletedAlert();
  }, [deletedAlertState]);

  const handleShowCreatedAlert = () => {
    if (createdAlertState === true) {
      setShowCreatedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowCreatedAlert(false);
        dispatch(handleCreated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowCreatedAlert();
  }, [createdAlertState]);

  const handleShowAddedAlert = () => {
    if (addedAlertState === true) {
      setShowAddedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAddedAlert(false);
        dispatch(handleAdded(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAddedAlert();
  }, [addedAlertState]);

  const handleShowAlert = () => {
    if (editedAlertState === true) {
      setShowEditedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowEditedAlert(false);
        dispatch(handleEdited(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [editedAlertState]);

  const handleShowDeactivatedAlert = () => {
    if (deactivatedAlertState === true) {
      setShowDeactivatedAlert(true);
      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowDeactivatedAlert(false);
        dispatch(handleDeactivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeactivatedAlert();
  }, [deactivatedAlertState]);

  const handleActivateAlert = () => {
    if (activateAlertState === true) {
      setShowActivatedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowActivatedAlert(false);
        dispatch(handleActivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleActivateAlert();
  }, [activateAlertState]);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const nextMove = () => {
    if (clickedItem === "Edit") {
      navigate("/EditPartner");
    } else if (clickedItem === "addUser") {
      navigate("/AddPartnerUser");
    } else if (clickedItem === "viewUsers") {
      navigate("/ViewPartnerUsers");
    }
  };

  useEffect(() => {
    nextMove();
  }, [clickedItem]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Partners
          </Typography>
        </Stack>
        {showEditedAlert && (
          <Alert variant="filled" severity="success">
            Partner details Adjusted successfully
          </Alert>
        )}
        {showDeactivatedAlert && (
          <Alert variant="filled" severity="success">
            Partner Deactivated successfully...Click on Inactive to view
          </Alert>
        )}

        {showDeletedAlert && (
          <Alert variant="filled" severity="success">
            Partner Deleted successfully
          </Alert>
        )}
        {showActivatedAlert && (
          <Alert variant="filled" severity="success">
            Partner Activated successfully...Click on Active to view
          </Alert>
        )}
        {showAddedAlert && (
          <Alert variant="filled" severity="success">
            Partner Added successfully
          </Alert>
        )}
        {showCreatedAlert && (
          <Alert variant="filled" severity="success">
            Partner User Created successfully
          </Alert>
        )}
      </Stack>
      <Stack direction="row" justifyContent={"space-between"}>
        <Breadcrumbs name="Partners" />
        <Button
          sx={{
            borderRadius: 2,
            backgroundColor: "#032541",
            color: "white",
            textTransform: "none",
            "&:hover": { color: "white", backgroundColor: "#032541" },
          }}
          onClick={() => {
            navigate("/AddPartner");
          }}
        >
          Add Partner
        </Button>
      </Stack>
      <PartnersTabs />
    </Stack>
  );
}

export default Partners;
