import React, {useState} from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import DarajaSetUpForm from "./DarajaSetUpForm";
import PortalSetUpForm from "./PortalSetUpForm";
import WeCanHelp from "./WeCanHelp";
import HttpComponent from "./MakeRequest";
import {useSelector, useDispatch} from "react-redux";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import {CheckBox} from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import { handleIsMpesaSetup } from '../../features/paymentOptionsSlice';

const DarajaSetUp = ({setShowDarajaSetup,setErrorShow,setSuccessShow, setMpesaStatus}) =>{
    const [pageStatus, setPageStatus] = useState("initial")
    const exSetup = localStorage?.getItem('exSetup')
    const [checked, setChecked] = React.useState(false);

    const dispatch = useDispatch();

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };

    const noStyle = {
        "borderRadius": "4px",
        "border": "solid 1px #032541",
        color:"#032541"
    }

    const buttonWidthHeight = {
        width:"9.063rem",
        height:"3.125rem"
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        color:"white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleYes = () =>{
        setPageStatus("daraja");
    }

    function handleNoDaraja () {
        // if (exSetup?.includes('true')) {
        //     setPageStatus('portal')
        // }
        setPageStatus('portal')
    }

    function handleNoPortal () {
        // if (exSetup?.includes('true')) {
        //     setPageStatus('useOurPaybill')
        // }
        setPageStatus('useOurPaybill')
    }

    function handlePortalSetup () {
        // if (exSetup?.includes('true')) {
        //     setPageStatus('portalForm')
        //     localStorage.setItem('exPortalForm', true)
        // }
        setPageStatus('portalForm')
        localStorage.setItem('exPortalForm', true)
    }

    const { mpesaData } = useSelector((store) => store.payment);

    return(
        <Box component="div" sx={{fontFamily : 'Poppins', width:"95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop:"4.6%"}}>
           <Box component="div" sx={{display:"flex", margin:2}}>
               <Box component="div" sx={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                   <img onClick={() => 
                       pageStatus == "useOurPaybill" ? setPageStatus('initial') :
                           pageStatus == "portalForm" ? setPageStatus('initial'):
                           pageStatus == "portal" ? setPageStatus('initial') :
                               pageStatus == 'daraja' ? setPageStatus('initial') :
                                   pageStatus
                   } style={{"width": "0.856rem", "height": "1.25rem"}} src={backIcon} alt=""/>
               </Box>
               <Box component="div" sx={{display:"flex", justifyContent:"center", flexDirection:"column", marginLeft:1}}>
                  <Typography sx={{color:"#032541", fontSize:"1rem", fontWeight:700, fontFamily :'Poppins'}}>
                      {pageStatus == "initial" || pageStatus == "none"?
                          "Mpesa Setup"
                          : pageStatus == "daraja" ?
                              "Mpesa Daraja Setup"
                              : pageStatus == "portal" ||pageStatus == "portalForm"  ?
                                  "Mpesa Portal Setup"
                                  : pageStatus == "useOurPaybill" ? "Mpesa Setup" : null
                      }

                  </Typography>
               </Box>
           </Box>
            {pageStatus === "initial" ?
                <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"10%"}}>
                    <Box component="div" sx={{alignSelf:"center"}}>
                        <Typography sx={{color:"#032541", fontSize:"0.875rem", textAlign:"center"}}>
                            Do you have Mpesa Daraja set up <br/> for your business?
                        </Typography>
                    </Box>
                    <Box component="div" sx={{alignSelf:"center", marginTop:"5%"}}>
                        <Button onClick={() => {handleNoDaraja()}} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                            No
                        </Button>

                        <Button onClick={handleYes} sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                            Yes
                        </Button>
                    </Box>
                </Box>
                : pageStatus === "daraja" ?
                    <DarajaSetUpForm setErrorShow={setErrorShow} setSuccessShow={setSuccessShow}  setShowDarajaSetup={setShowDarajaSetup}  setMpesaStatus={setMpesaStatus} />
                    : pageStatus == "portal" ?
                        <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginTop:"10%"}}>
                            <Box component="div" sx={{alignSelf:"center"}}>
                                <Typography sx={{color:"#032541", fontSize:"0.875rem", textAlign:"center"}}>
                                    Do you have Mpesa Portal set up <br/> for your business?
                                </Typography>
                            </Box>
                            <Box component="div" sx={{alignSelf:"center", marginTop:"5%"}}>
                                <Button onClick={handleNoPortal} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                                    No
                                </Button>

                                <Button onClick={handlePortalSetup} sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                        : pageStatus == "useOurPaybill" ?
                            <Grid container direction={'column'} justifycontent={'center'} alignContent={'center'} style={{textAlign : 'center'}}>
                                <Grid item mt={3}>
                                    <span style={{color : '#17ae7b', fontSize : '24px', fontWeight : 600., textAlign: 'center', width:'50%'}} >For assistance Contact our support team at</span>
                                </Grid>
                                <Grid item mt={2} style={{color : '#707070'}}>
                                    {/* <span>Do you wish to use the <span style={{fontWeight : 'bold'}}>ZED PAYBILL 900900 </span>to easily accept your payments via MPESA?</span> */}
                                    <Typography variant='p'>
                                    Email: <strong style={{ fontWeight:'normal', color:'#032541' }}>info@zed.business</strong>
                                    </Typography>
                                </Grid>
                                <Grid item mt={3} style={{color : '#707070'}}>
                                    {/* <span><i>You will be required to agree to our terms and conditions.</i></span> */}
                                    <Typography>Phone: <strong style={{ fontWeight:'normal', color:'#032541' }}>0709836100</strong></Typography>
                                </Grid>
                                {/* <Grid item mt={9} style={{color : '#707070'}}>
                                    <Checkbox checked={checked} onChange={handleCheckBoxChange} />
                                    <span>I have read and agreed to the <a href="">Terms and Conditions</a></span>
                                </Grid> */}
                                <Box component="div" sx={{alignSelf:"center", marginTop:"5%", marginBottom : '5%'}}>
                                    <Button onClick={()=> {setPageStatus('initial')}} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                                        Cancel
                                    </Button>

                                    <Button onClick={()=>{
                                        setShowDarajaSetup(false)
                                        setMpesaStatus(true);
                                        dispatch(handleIsMpesaSetup(true))
                                        }} sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                                        Okay
                                    </Button>
                                </Box>
                            </Grid>
                        : pageStatus ==  "portalForm" ?
                            <PortalSetUpForm/>
                            : pageStatus == 'none' ?
                                <WeCanHelp/>
                                :null
            }
        </Box>
    )
}

export default DarajaSetUp;
