import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { stockStyles } from "../stockStyling";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { Button } from "@mui/material";
import CustomDataGrid from "../customDataGrid";
import StockFunctions from "../stockEndpoints";
import { useNavigate } from "react-router-dom";

const ReceiveStockPreview = ({ po_Id, purchaseOrderNo }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  const [orderDetails, setOrderDetails] = useState({ purchaseOrderNumber: "", po_Id: "", supplierName: "", recieverStatus: "", items: [] });
  //DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const stockOrderColumn = [
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Product Name"}</span>;
      },
    },
    {
      field: "requestedQuantity",
      headerName: "Qty Requested",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Qty Requested"}</span>;
      },
    },
    {
      field: "receivedQuantity",
      headerName: "Qty Received",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Qty Received"}</span>;
      },
      renderCell: (params) => {
        if(orderDetails?.recieverStatus === "PENDINGDISPATCH") return <input onChange={(e) => updateStock(params?.row, e)}    style={{ outline: "none" }} />;
        return <input  value={params?.row?.receivedQuantity} disabled={true} style={{ outline: "none" }} />;
      },
    },
    {
      field: "pending",
      headerName: "Variance",
      flex: 1,
      renderHeader: () => {
        return <span style={{ color: "#032541", fontWeight: "600" }}>{"Variance"}</span>;
      },
      renderCell: (params) => {
        return <span style={params?.row?.pending > 0 ? { color: "red" } : { color: "green" }}>{params?.row?.pending}</span>;
      },
    },
  ];

  const productsRow = pageState?.data
  ?.filter((product) => {
    return inputValue.toLocaleLowerCase() === "" ? product : product?.productName?.toLocaleLowerCase()?.includes(inputValue?.toLocaleLowerCase())
  })
  ?.map((product) => ({
    id: product._id,
    productName: product?.productName,
    requestedQuantity: product?.requestedQuantity,
    receivedQuantity: product?.approvedQuantity,
    changed: false,
    pending: product?.requestedQuantity - product?.approvedQuantity,
    poNo: purchaseOrderNo,
  }));

  // requestType, po_Id, page, limit
  // get purchase order products!
  useEffect(() => {
    const stockfunctions = new StockFunctions();
    stockfunctions.getPurchaseOrderProducts("outgoing", po_Id, pageState.page, dataGridPageSize).then((data) => {
      if (data.status === 200) {
        const { supplierName, storeName, purchaseOrderNumber, recieverStatus, items } = data.response.data;
        setOrderDetails({ ...orderDetails, supplierName, storeName, purchaseOrderNumber, recieverStatus, items });
        setPageState({ ...pageState, data: items, isLoading: false, count: items?.length });
      }
    });
  }, [po_Id]);

  const updateStock = (stock, e) => {
    // setTimeout(() => {
    const value = Number(e.target.value);
    const stockId = stock?.id;

    const stockItems = [...pageState?.data];
    // get index;
    const currentIndex = stockItems.findIndex((stock) => stock._id === stockId);
    stockItems[currentIndex].approvedQuantity = value * 1;
    stockItems[currentIndex].changed = true;
    stockItems[currentIndex].poNo = purchaseOrderNo;

    setPageState({ ...pageState, data: stockItems });
    // check and update local storage;

    const localItems = JSON.parse(localStorage.getItem("orderStockItems"));
    if (localItems) {
      const newLocalItems = [...localItems];

      // get Index of Changing item;
      const newIndex = newLocalItems.findIndex((stock) => stock._id === stockId);

      if (newIndex === -1) {
        newLocalItems.push(stockItems[currentIndex]);
      } else {
        newLocalItems[newIndex].approvedQuantity = stockItems[currentIndex].approvedQuantity;
        newLocalItems[newIndex].changed = stockItems[currentIndex].changed;
        newLocalItems[newIndex].poNo = stockItems[currentIndex].poNo;
      }
      localStorage.setItem("orderStockItems", JSON.stringify(newLocalItems));
    } else {
      localStorage.setItem("orderStockItems", JSON.stringify(stockItems));
    }
    // }, 1000);
  };

  const submitBatch = () => {
    navigate(`/receiveStock/preview?${po_Id}?${purchaseOrderNo}`);
  };

  const clearStage = () => {
    localStorage.removeItem("orderStockItems")
    navigate(-1);
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid container style={stockStyles?.batchDetailsHolder} width={"100%"} alignItems={"center"} justifyContent={"space-between"} direction={"row"} margin={"0px 0px 1% 0px"}>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          {/* <span style={{ margin: "0% 3% 0% 0%" }}></span> */}
          <span style={stockStyles?.submitBatchdisplay}> Order No: {orderDetails?.purchaseOrderNumber}</span>
        </Grid>

        <Grid item>
          <span style={stockStyles?.submitBatchdisplay}>Request From: {orderDetails?.supplierName}</span>
        </Grid>

        <Grid item>
          <span style={stockStyles?.submitBatchdisplay}>status: {orderDetails?.recieverStatus === "PENDINGDISPATCH" ? "Sent To Supplier" : orderDetails?.recieverStatus === "RECEIVED" ? "Received" : "Partial Delivery"}</span>
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 1% 0px"}>
        <Grid item>
          <SearchFilter setInputValue={setInputValue} placeholder={"Search products"} />
        </Grid>

        {orderDetails?.recieverStatus === "PENDINGDISPATCH" && (
          <Grid item sx={{ display: "flex", gap: "20px", width: "50%", justifyContent: "flex-end" }}>
            <Button style={stockStyles?.cancelButton} onClick={clearStage}>Clear</Button>
            <Button style={stockStyles?.viewBatchButton} onClick={() => submitBatch()}>
              Recieve Stock
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <CustomDataGrid customStyles={{ height: "450px", width: "100%", margin: "0% 0% 1% 0%", border: "none" }} column={stockOrderColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productsRow} />
      </Grid>
    </div>
  );
};

export default ReceiveStockPreview;
