import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Grid } from '@mui/material';
import DateFormatter from '../../../../utils/dateFormatter';
import CurrencyFormatter from '../../../../utils/currencyFormatter';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useNavigate } from 'react-router-dom';


const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);

export const PendingRows = (props) => {
    const { row, handleNavigateToVerify ,tabValue } = props
   // console.log(props , 'props')
    const [openCollapse, setOpenCollapse] = useState(false)
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        sx={{ marginRight: '10px' }}
                        onClick={() => setOpenCollapse(!openCollapse)}
                    >
                        {openCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {row?.ftpNumber}
                </TableCell>
                <TableCell component="th" scope="row">{DateFormatter(row?.dateCreated)}</TableCell>
                <TableCell component={'th'} scope="row">{row?.supplierName}</TableCell>
                <TableCell component={'th'} scope="row">{numberFormat(row?.amount)}</TableCell>
                <TableCell component={'th'} style={{ color: row?.status === "Pending" ? '#ff8503' : row?.status === "Failed" ? '#000000':row?.status === "Declined" ? '#dc3545':'#17ae7b' , fontWeight:'bold' }} scope="row">{row?.status}</TableCell>
                {tabValue === 'Disbursed' ?  <TableCell component="th" scope="row">{DateFormatter(row?.dateCreated)}</TableCell>:null}
                {tabValue === 'Declined' ?  <TableCell component="th" scope="row">{DateFormatter(row?.dateCreated)}</TableCell>:null}
                {/* <TableCell component={'th'}>
                        <HighlightOffIcon style={{cursor:'pointer' , color:'#17ae7b'}}/>
                        <CheckCircleOutlineIcon onClick={handleNavigateToVerify} style={{cursor:'pointer' , marginLeft:'10px', color:'#dc3545'}}/>
                </TableCell> */}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <Grid item display={'flex'} width={'100%'}>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item mt={1}>
                                    <Typography style={{ color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>
                                        Inputer
                                    </Typography>
                                </Grid>
                                <Grid mt={1} item display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'30%'} mb={2}>
                                    <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{row?.inputer?.name}</Typography>
                                    <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{row?.inputer?.inputerCode}</Typography>
                                    <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{DateFormatter(row?.inputer?.paymentCreatedTime)}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        {row?.expectedApproverEachLevel?.map((historyRow) => (
                            <Grid mt={1}  direction={'column'} display={'flex'} width={'30%'}>
                                <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                    <Typography style={{ color: '#000', fontSize: '14px' }}>{historyRow?.levelDescription}</Typography>
                                    <Typography style={{ color: historyRow?.levelStatus === 'Approved' ? '#17ae7b' : '#ff8503', fontSize: '14px', marginLeft: '20px' }}>{historyRow?.levelStatus}</Typography>
                                </Grid>
                                <Grid item mt={1} display={'flex'} direction={'column'}>
                                    {historyRow?.approves?.map((approver) => (
                                        <Grid item  mb={1}  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{approver?.approverName}</Typography>
                                            <Typography style={{ color: approver?.transactionStatus === 'Pending' ? '#f79009':approver?.transactionStatus === 'Approved' ? '#17ae7b' :'#dc3545',  fontSize: '14px' }}>{approver?.transactionStatus}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}


export const disbursedRows = () => {

}