import React, { useState } from "react";
import { FailedSettlementreport, Merchantsummary, RevenueShareReports, SettlementSummary } from "./merchantsummary";
import { Box, Grid, Tab, TextField, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled, alpha } from "@mui/material/styles";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import AuthecateUser from "../../../paymentmodule/autheticatedComponent";
import ExportSearchBar from "../../../../common/exportsearchbar";
// custom tab styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "transparent" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "17px",
  background: "transparent",
  borderRadius: "5px",
  marginLeft: "-10px",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#cb444a", backgroundColor: "transparent", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "transparent" },
}));
const Cardpresentsummary = () => {
  const [tabValue, setTabValue] = useState("merchantreport");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // authentication;
  const [isAuthecated, setIsAuthecated] = useState(false);
  const handleIsAuthecated = (childData) => {
    let state = childData.state;
    setIsAuthecated(state);
  };

  const [searchValue, setSearchValue] = useState("");
  const [startDate, newStartDate] = useState("");
  const [endDate, newEndDate] = useState("");

  const [csvExport, setcsvExport] = useState({ headers: [], data: [] });
  const [exportData, setExportData] = useState({ headers: [], data: [] });
  const [startTransactionTime, setStartTransactionTime] = useState("");
  const [endTransactionTime, setEndTransactionTime] = useState("");
  const [activeTab, setActiveTab] = useState(`Merchant Report for period ${startTransactionTime} and ${endTransactionTime} printed on ${new Date().toDateString()}`);

  return (
    <div style={{ width: "100%" }}>
      {isAuthecated ? (
        <>
          {" "}
          <AuthecateUser handleIsAuthecated={handleIsAuthecated} />{" "}
        </>
      ) : (
        <>
          {" "}
          <Grid container>
            <Grid item>
              <Box style={{ margin: "0px 0px 10px 0px" }}>
                <Typography sx={{ fontWeight: "600", color: "#032541", fontSize: "25px" }} variant="h5">
                  Summary Report
                </Typography>
              </Box>
              <Box>
                <Breadcrumb name={"Revenue Share Report Summary"} />
              </Box>
            </Grid>
          </Grid>
          <Grid container width={"100%"} mt={3}>
            <Grid item width={"100%"}>
              <TabContext value={tabValue}>
                <AntTabs onChange={handleChange}>
                  <AntTab label="Merchant Report Summary " value={"merchantreport"} />
                  <AntTab label="Failed Settlement Report" value={"failedsettlementreport"} />
                  <AntTab label="Settlment Report Summary " value={"settlmentreport"} />
                  <AntTab label="Revenue Share Reports Summary" value={"revenuereport"} />
                </AntTabs>

                <Grid item>
                  <ExportSearchBar activeTabs={activeTab} csvExport={csvExport} exportData={exportData} textfiledplaceholder={"Search..."} setSearchValue={setSearchValue} newStartDate={newStartDate} newEndDate={newEndDate} />
                </Grid>
                <TabPanel value={"merchantreport"}>
                  <Merchantsummary setcsvExport={setcsvExport} setActiveTab={setActiveTab} setEndTransactionTime={setEndTransactionTime} setExportData={setExportData} setStartTransactionTime={setStartTransactionTime} tabValue={tabValue} searchValue={searchValue} />
                </TabPanel>

                <TabPanel value={"failedsettlementreport"}>
                  <FailedSettlementreport setcsvExport={setcsvExport} setActiveTab={setActiveTab} setEndTransactionTime={setEndTransactionTime} setExportData={setExportData} setStartTransactionTime={setStartTransactionTime} tabValue={tabValue} searchValue={searchValue}  />
                </TabPanel>
                <TabPanel value={"settlmentreport"}>
                  <SettlementSummary setcsvExport={setcsvExport} setActiveTab={setActiveTab} setEndTransactionTime={setEndTransactionTime} setExportData={setExportData} setStartTransactionTime={setStartTransactionTime} tabValue={tabValue} searchValue={searchValue}  />
                </TabPanel>
                <TabPanel value={"revenuereport"}>
                  <RevenueShareReports setcsvExport={setcsvExport} setActiveTab={setActiveTab} setEndTransactionTime={setEndTransactionTime} setExportData={setExportData} setStartTransactionTime={setStartTransactionTime} tabValue={tabValue} searchValue={searchValue}  />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>{" "}
        </>
      )}
    </div>
  );
};
// FailedSettlementreport
export default Cardpresentsummary;
