import { Grid, Typography, InputLabel, TextField, Button } from '@mui/material'
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react';
import HttpComponent from '../../../../School/MakeRequest';
import { ErrorAlert } from '../../../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../../../snackBar Alerts/successAlert';
import { handleResetDetail, handleUpdateBusinessDetails } from '../../../../../features/businessAddPartner';
import {useNavigate} from 'react-router-dom'
export default function AdminDetailsSchool(props) {
    const [formdata, setFormData] = useState({ firstName: "", LastName: '', userName: '', email: '' })
    const [phone, setUserPhone] = useState('')
    const [successShow, setSuccessShow] = useState({state: false, message: ""})
    const [businessNumber , setBusinessnumber] = useState('')
    const [errorShow, setErrorShow] = useState({state: false, message: ""}) 
    const {businessCategory ,schoolType ,currency ,name ,partnerRegion ,partnerBranch ,salesPName ,salesPCode} = useSelector((store)=>store.BusinessPartnerDetails.businessDetails)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // handle onchange

    const handleChange = (e) => {
        setFormData({
            ...formdata, [e.target.name]: e.target.value
        })
    }

    const FormSubmitBody = {
        businessCategory: businessCategory,
        schoolType: schoolType,
        currency: currency,
        name: name,
        partnerBranch: partnerBranch,
        partnerRegion: partnerRegion,
        salesPCode: salesPCode,
        salesPName: salesPName,
        adminDetails:{
            firstName: formdata.firstName,
            lastName: formdata.LastName,
            phone: "+" + phone,
            email: formdata.email,
            userType: "ZPM School Admin"
        }
    }

    function handleCreateBusinessByPartner(){
        try {
            HttpComponent({
                url:'/api/v1/business/addPartnerBusiness',
                method:'POST',
                body:FormSubmitBody,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200 || data?.status === 201){
                    setBusinessnumber(data?.response?.data?.businessNumber)
                    dispatch(handleUpdateBusinessDetails({businessNumber:data?.response?.data?.businessNumber , businessName:data?.response?.data?.businessName , branchId:data?.response?.data?.branches[0]?._id}))
                    setSuccessShow({state:true ,message:data?.response?.message})
                    setTimeout(()=>{
                        props.next()
                    },1000)
                }else{
                    setErrorShow({state:true ,message:data?.response?.message})
                }
            })
        } catch (error) {
            
        }
    }


    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {/* <Grid item mt={'2'}>
                <Breadcrumbs name='Add Business' />
            </Grid> */}
            <Grid item mt={2}>
                <Typography variant='h6' sx={{color:'#032541'}}>Add Admin Details</Typography>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>First Name</InputLabel>
                        <TextField style={{ width: "100%" }} value={formdata.firstName} name='firstName' onChange={handleChange} id="outlined" required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Last Name</InputLabel>
                        <TextField style={{ width: "100%" }} value={formdata.LastName} name='LastName' onChange={handleChange} id="outlined" required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <Grid container width={'100%'} gap={2} alignItems={'center'}>
                    <Grid item width={'48%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>UserName</InputLabel>
                        <TextField style={{ width: "100%" }} value={formdata.userName} name='userName' onChange={handleChange} id="outlined" required />
                    </Grid>
                    <Grid item width={'49%'}>
                    <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Phone</InputLabel>
                        <PhoneInput inputStyle={{ height: "55px", width: '100%' }} required country={"ke"} enableSearch={true} value={phone} onChange={(phone) => setUserPhone(phone)} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Email</InputLabel>
                <TextField style={{ width: "100%" }} value={formdata.email} name='email' onChange={handleChange} id="outlined" required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => handleCreateBusinessByPartner()} disabled={!formdata.firstName || !formdata.LastName || !phone || !formdata.email } style={!formdata.firstName || !formdata.LastName || !phone || !formdata.email  ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Submit</Button>
            </Grid>
        </Grid>
    )
}