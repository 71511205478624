import { Grid, InputLabel, Button, FormControl, MenuItem, Select, TextField, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { SuccessAlert } from '../../../../snackBar Alerts/successAlert'
import { ErrorAlert } from '../../../../snackBar Alerts/errorAlert'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import HttpComponent from '../../../../School/MakeRequest';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle Routes</Typography>,
]


export default function EditVehicleRoutes() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ name: '', description: '', county: '', routeCode: '' })
    const [counties, setCounties] = useState([])
    const queryParams = decodeURIComponent(window.location.search);

    const newqueryParamValue = queryParams.slice(1);

    const [queryParamValue, routeId] = newqueryParamValue.split("?")


    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }

    // GET ROUTE DETAILS TO UPDATE


    function getRouteDetailToUpdate() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getvehicleroutebyid?routeId=${routeId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setFormdata({
                        ...formData,
                        name: data?.response?.data?.routeName,
                        description: data?.response?.data?.description,
                        county: data?.response?.data?.county,
                        routeCode: data?.response?.data?.routeCode,
                    })
                }
            })
        } catch (err) {

        }
    }

    useEffect(() => {
        getRouteDetailToUpdate();
    }, [routeId])

    const navigate = useNavigate()

    // get counties

    function getCounties() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/get_counties_kenya`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setCounties(data?.response?.data)
                }
            })
        } catch (error) {

        }

    }

    useEffect(() => {
        getCounties()
    }, [])


    // add routes

    function updatevehicleRoutes() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/updateroute?routeId=${routeId}`,
                body: {
                    routeName: formData.name,
                    routeCode: formData.routeCode,
                    county: formData.county,
                    description: formData.description
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1);
                    }, 1500)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }
    }
    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Vehicle Routes</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography>Edit Vehicle Routes</Typography>
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Name</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.name} name='name' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Route Code</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.routeCode} name='routeCode' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item width={'50%'} mb={2}>
                <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <InputLabel id="demo-simple-select-label">Select County</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" name='county' value={formData.county} label="Select County" onChange={handleFormdata}>
                        {counties?.map((acc) => {
                            return <MenuItem id={acc?.value} value={acc?.value}>{acc?.label}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Description</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.description} name='description' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={updatevehicleRoutes} disabled={!formData.name || !formData.county || !formData.description || !formData.routeCode} style={!formData.name || !formData.county || !formData.description || !formData.routeCode ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}