import { Breadcrumbs, Grid, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import { useEffect, useState } from "react";
import HttpComponent from "./MakeRequest";

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.error">Convenience Fee</Typography>
]


export default function ConveninienceFeeOtherBiz() {

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    const ActiveColumns = [
        { headerName: "_id", field: 'id', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Id</strong>) } },
        { headerName: "businessName", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Business Name</strong>) } },
        { headerName: "businessNumber", field: 'businessNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Business ID</strong>) } },
        { headerName: "businessCategory", field: 'businessCategory', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Business Type</strong>) } },
        { headerName: "convenientFeeAmount", field: 'convenientFeeAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Convenience Fee</strong>) } },
    ];

    const rowData = [
        { id: pageState?.data?._id, businessName: pageState?.data?.businessName, businessNumber: pageState?.data?.businessNumber, businessCategory: pageState?.data?.businessCategory, convenientFeeAmount: pageState?.data?.convenientFeeAmount },
    ]

    function updateAdjustFee() {
        const obj = { businessNumber: localStorage.getItem('businessId') }
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/business/convenienceFee`,
                body: obj,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        updateAdjustFee()
    }, [])


    return (
        <Grid direction={'column'}>
            <Grid item mt={3}>
                <Typography style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Convenience Fee</Typography>
            </Grid>
            <Grid item mt={2}>
                <Grid container display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'} direction={'row'}>
                    <Grid item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'}>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={4}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell> Business Name</TableCell>
                                <TableCell> Business ID</TableCell>
                                <TableCell> Business Type</TableCell>
                                <TableCell> Convenience Fee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                <TableRow key={pageState?.data?._id}>
                                    <TableCell>{pageState?.data?.businessName}</TableCell>
                                    <TableCell>{pageState?.data?.businessNumber}</TableCell>
                                    <TableCell>{pageState?.data?.businessCategory}</TableCell>
                                    <TableCell><span>{pageState?.data?.localCurrency}</span>{pageState?.data?.convenientFeeAmount}</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}