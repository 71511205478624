import { Box, Typography, Breadcrumbs, Grid, Button, FormControl, Select, MenuItem, TextField, InputLabel } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useNavigate, useParams } from "react-router-dom";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import CustomSelectField from "../../School/CustomSelectField";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Checkbox from '@mui/material/Checkbox';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


let baseUrl = process.env.REACT_APP_BASE_URL;

//breadcrumbs

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Products / Services Categories
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Edit Product
    </Typography>
];


export default function EditSingleProduct() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [productCatego, setProductCategory] = useState([])
    const [serviceExpiry, SetServiceExpiry] = useState("");
    const [isWeightedProduct, setIsWeightedProduct] = useState(false)
    const [consumable, setConsumable] = useState(false);
    const [unitOfMeasure, setUnitOfMeasure] = useState([])
    const [ectizenStatus, setEctizenStatus] = useState(false)



    const checkEcitizenStatusMethod = async () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/ecitizen/get_status`,
            body: null,
            token: X_Authorization
        }).then((data) => {

            if (data.status === 200) {
                let status = data.response?.ecitizenStatus
                console.log(status, 'status---')
                setEctizenStatus(status)

            } else {
            }
        }).catch((error) => {
            console.error(error, 'error');
        })


    }

    useEffect(() => {
        checkEcitizenStatusMethod()
    }, [])


    //edit 

    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)

    //console.log(state , 'statt')
    useEffect(() => {

        setFormData({ ...formData, isWeightedProduct: isWeightedProduct, consumable: consumable });
    }, [isWeightedProduct, consumable])

    const navigate = useNavigate()
    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const { id } = useParams();
    console.log(id, 'service to edit')

    const { X_Authorization } = useSelector((store) => store.user)
    const [checkRequest, setCheckRequest] = useState(false);
    const [options, setOptions] = useState([]);
    // const [variationKeyId , setVariaionKeyId] = useState('')
    // const [allVariations ,setAllVariations] = useState([])


    const checkButtonChange = (e) => {
        setCheckRequest(e.target.checked);
    };

    let obj = {
        productName: "",
        priceStatus: "",
        categoryId: "",
        productCategory: "",
        productPrice: "",
        description: "",
        grade: "",
        term: "",
        unitOfMeasure: "",
        consumable: "",
        isWeightedProduct: "",
        ectizenAccount_Id: "",
        ectizenitem_ref: "",
        productQuantity:'',
        buyingPrice:'',
        reorderLevel:'',
        discountPrice: "",
        productVariationName:''
    }
    const obj2 = {
        productName: "",
        priceStatus: "",
        productCategory: "",
        productPrice: "",
        description: "",
        categoryId: "",
        grade: "",
        term: "",
        serviceType: "Request",
        serviceExpiry: "",
        productDescription: "",
        unitOfMeasure: "",
        consumable: "",
        isWeightedProduct: "",
        buyingPrice:'',
        productQuantity:'',
        reorderLevel:'',
        discountPrice: "",
        productVariationName:''

    }
    const [formData, setFormData] = useState(
        checkRequest ? obj : obj2
    );
    const handleServiceExpiry = (newValue) => {
        SetServiceExpiry(newValue);
        setFormData({
            ...formData,
            serviceExpiry: newValue,
            // productName:newValue
        })

    };

    console.log("formData =======<><>", formData)
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [gradeOptions, setGradeOptions] = useState([])

    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        // console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [termOptions, setTermOptions] = useState([]);


    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        // console.log(data, "Get Courses");
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses.courseName, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
        GetGrades();
    }, []);

    // grades
    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetStreamsTerms();
        }

    }, [schoolTypeId]);




    //fetch detail of the product

    function fetchProductToEdit() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/findProduct/${id}`,
                token: X_Authorization
            }).then((data) => {
                console.log(data?.response?.data, 'checking all data showwwwwww')
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        productName: data?.response?.data?.productName,
                        productCategory: data?.response?.data?.productCategory,
                        priceStatus: data?.response?.data?.priceStatus,
                        productPrice: data?.response?.data?.productPrice,
                        reorderLevel: data?.response?.data?.reorderLevel,
                        productDescription: data?.response?.data?.productDescription,
                        buyingPrice: data?.response?.data?.buyingPrice,
                        grade: data?.response?.data?.grade,
                        term: data?.response?.data?.term,
                        serviceType: data?.response?.data?.serviceType,
                        serviceExpiry: data?.response?.data?.serviceExpiry,
                        description: data?.response?.data?.productDescription,
                        unitOfMeasure: data?.response?.data?.unitOfMeasure,
                        isWeightedProduct: data?.response?.data?.isWeightedProduct,
                        consumable: data?.response?.data?.consumable,
                        ectizenAccount_Id: data?.response?.data?.ectizenAccount_Id,
                        ectizenitem_ref: data?.response?.data?.ectizenitem_ref,
                        productQuantity: data?.response?.data?.productQuantity,
                        discountPrice:data?.response?.data?.discountedPrice,
                        productVariationName:data?.response?.data?.productVariationName,
                    })
                    setConsumable(data?.response?.data?.consumable)
                    setIsWeightedProduct(data?.response?.data?.isWeightedProduct)
                    SetServiceExpiry(data?.response?.data?.serviceExpiry)
                    // setAllVariations(data?.response?.data?.pricing)
                    //console.log(formData?.serviceType, 'formdata after update by fetch')
                    if (data?.response?.data?.serviceType === "Request") {

                        setCheckRequest(true)
                        checkButtonChange({ target: { checked: true } })

                        // handleServiceExpiry(data?.response?.data?.serviceExpiry) 
                        // console.log(formData?.serviceType, 'formdata after update by fetch')                    
                    } else if (data?.response?.data?.serviceExpiry === "Normal" || data?.response?.data?.serviceExpiry !== "Request") {
                        setCheckRequest(false)
                    }

                }
            })

        } catch (error) {

        }
    }

    const getUnitOfMeasure = () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/products/unitofmeasure/get`,
            token: localStorage.getItem("X-Authorization")
        }).then((data) => {
            if (data?.status == 200 || data?.status == 201) {
                setUnitOfMeasure(data.response.data)
            }
        })
    }

    useEffect(() => {
        getUnitOfMeasure()
    }, [])

    //useeffect to get the customer  details

    useEffect(() => {
        fetchProductToEdit()
    }, [])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }
    // console.log(formData , 'formdatttttttttttttttt')
    function fetchProductCategories() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listCategories/Active`,
                token: X_Authorization
            }).then((data) => {
                if (data.status === 200) {
                    setProductCategory(data?.response?.data)
                    const mappedData = data.response.data?.filter((category) => category?.categoryName !== 'Zpm Activation Fee').map(({ categoryName, _id }) => ({ label: categoryName, value: categoryName }));
                    setOptions(mappedData)

                }
                console.log(data, 'product categoriess')
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProductCategories()
    }, [])

    //service type
    const typeOptions = [
        {
            value: "Fixed Price",
            label: "Fixed Price",
        },
        {
            value: "Variable Price Item",
            label: "Variable Price Item",
        }
    ]

    const mapOptions = typeOptions?.map((option) => ({ value: option.value, label: option.label }))
    const productCa = productCatego?.map((option) => (option.categoryName))
    const businessCat = localStorage.getItem('businessCategory')


    //update the product

    function updateProduct() {
        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/updateProduct/${id}`,
                body: {
                    productName: formData.productName,
                    productCategory: formData.productCategory,
                    priceStatus: formData.priceStatus,
                    productPrice: formData.productPrice,
                    reorderLevel: formData.reorderLevel,
                    productDescription: formData.productDescription,
                    buyingPrice: formData.buyingPrice,
                    consumable: formData.consumable,
                    isWeightedProduct: formData.isWeightedProduct,
                    discountPrice:formData.discountPrice,
                    unitOfMeasure: formData.unitOfMeasure,
                    productState: 'Edited',
                    ectizenAccount_Id: formData.ectizenAccount_Id,
                    ectizenitem_ref: formData.ectizenitem_ref
                },
                token: X_Authorization
            }).then((data) => {

                if (data.status === 202) {
                    setSuccessShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                } else {
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                }
            })
        } catch (error) {

        }
    }
    function updateProductService() {
        let dataObj = {}

        if (checkRequest) {
            dataObj = {
                productName: formData.productName,
                productCategory: formData.productCategory,
                priceStatus: formData.priceStatus,
                productPrice: formData.productPrice,
                grade: formData.grade,
                term: formData.term,
                serviceType: formData.serviceType,
                serviceExpiry: serviceExpiry,
                productDescription: formData.description,
                ectizenAccount_Id: formData.ectizenAccount_Id,
                ectizenitem_ref: formData.ectizenitem_ref
            }
        }
        else {
            dataObj = {
                productName: formData.productName,
                productCategory: formData.productCategory,
                priceStatus: formData.priceStatus,
                productPrice: formData.productPrice,
                grade: formData.grade,
                term: formData.term,
                serviceType: "Normal",
                productDescription: formData.description,
                ectizenAccount_Id: formData.ectizenAccount_Id,
                ectizenitem_ref: formData.ectizenitem_ref

            }
        }

        console.log(dataObj, 'dataobj for update')

        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/updateProduct/${id}`,
                body: dataObj,
                token: X_Authorization
            }).then((data) => {

                if (data.status === 202) {
                    setSuccessShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                } else {
                    setErrorShow({ state: true, message: data.response.message })
                    // setTimeout(() => {
                    //     navigate("/products")
                    // }, 3000)
                }
            })
        } catch (error) {

        }
    }

    return (
        <>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {businessCat === "School" && state === 'Service' ?
                <>
                    <Box component="div" sx={{ display: "flex" }}>
                        <Box>
                            <CustomInputLabel required={true} label={"Service Name"} />
                            <CustomTextField value={formData.productName} onChange={handleInputChange} name={"productName"} placeholder={"Service Name"} />

                            <CustomInputLabel required={true} label={"Amount Type"} />
                            {/*maybe be a disabled select or text field we will see*/}
                            <CustomTextField value={formData.priceStatus} name={"priceType"} onChange={handleInputChange} />
                        </Box>


                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomInputLabel required={true} label={"Service Category"} />
                            {/*this now will have to be a select*/}

                            <CustomSelectField value={formData.productCategory} onChange={handleInputChange} name={"productCategory"} placeholder={"Select Service Category"} options={options} medium />
                            {/*<CustomTextField value={formData.serviceCategory} onChange={handleInputChange} name={"serviceCategory"} placeholder={"Service Category"} />*/}

                            <CustomInputLabel required={true} label={"Amount"} />
                            <CustomTextField value={formData.productPrice} onChange={handleInputChange} name={"productPrice"} placeholder={"Amount"} />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                        <Box component="div" sx={{}}>
                            <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Course" : "Grade"} />
                            {schoolTypeName.includes("University") ? (
                                <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Course"} options={coursesOptions} />
                            ) :
                                <CustomSelectField value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} />
                            }

                        </Box>
                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Semester" : "Term"} />
                            <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={schoolTypeName.includes("University") ? "Select Semester" : "Select Term"} options={termOptions} />
                        </Box>
                    </Box>
                    {ectizenStatus ? <Box sx={{ display: "flex" }}>
                        <Box component="div" sx={{}}>
                            <CustomInputLabel required={true} label={"Ecitizen Service Id"} />
                            <CustomTextField value={formData.ectizenAccount_Id} onChange={handleInputChange} name={"ectizenAccount_Id"} placeholder={"Ectizen Service Id"} />

                        </Box>
                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomInputLabel required={true} label={"Ecitizen Item Reference"} />
                            <CustomTextField value={formData.ectizenitem_ref} onChange={handleInputChange} name={"ectizenitem_ref"} placeholder={"Ecitizen Item Reference"} />
                        </Box>
                    </Box> : null}
                    {/* <Box sx={{ display: "flex" }}>
                            <FormControl sx={{ minWidth: '68%' }}>
                                <InputLabel id="demo-simple-select-label">Variant</InputLabel>
                                <Select
                                    label="Variant"
                                    value={variationKeyId}
                                    onChange={(e) =>setVariaionKeyId( e.target.value)}
                                    name="variationKeyId"
                                >
                                    {allVariations?.map((option) => {
                                        return (
                                            <MenuItem value={option?._id}>{option?.variationKey}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                    </Box> */}
                    <Box>
                        <CustomInputLabel required={false} label={"Service Description"} />
                        <TextField
                            name={"description"}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={"Service Description"}
                            variant="outlined"
                            margin="dense"
                            sx={{ marginBottom: 2 }}
                            multiline
                            minRows={2}
                            InputProps={{
                                sx: {
                                    width: '51rem',
                                    fontSize: "0.875rem",
                                    "& fieldset": {
                                        borderColor: "#bec5d1"
                                    }
                                }
                            }}
                        />
                    </Box>
                    <Box component="div" sx={{ display: "flex", }}>
                        <Checkbox checked={checkRequest} onChange={checkButtonChange} name={"Request"} label={"Request"} />
                        <div style={{ marginTop: "10px", fontSize: "16px" }}>
                            <span>This service can be requested.</span>

                        </div>

                    </Box>
                    {checkRequest && <Box component="div" sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                        <InputLabel sx={{ fontSize: "0.875rem", color: "#002543", marginBottom: "10px" }}>Valid Until</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={serviceExpiry}
                                required
                                onChange={(newValue) => SetServiceExpiry(newValue)}
                                InputProps={{
                                    sx: {
                                        width: '51rem',
                                        fontSize: "0.875rem",
                                        "& fieldset": {
                                            borderColor: "#bec5d1"
                                        }
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </Box>}



                    <Box component="div" sx={{ width: "70%", marginLeft: "-9center0px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
                        <Box component="div">

                            <Button sx={{
                                ...buttonStyles,
                                border: " solid 1px #002543",
                                color: "#002543",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                    color: '#002543'
                                }
                            }}
                                onClick={updateProductService}
                            // disabled={!formData.productName.trim() || !formData?.categoryId.trim() || !formData?.productPrice.trim()}
                            >
                                Update Service
                            </Button>

                        </Box>

                    </Box>
                </>
                : <>
                    <Grid container direction={'column'}>
                        <Grid item display={'flex'} alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
                            <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}> Edit Product</Typography>
                        </Grid>
                        <Grid item mb={'10px'} mt={'10px'}>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item mb={'10px'} mt={'10px'}>
                            <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Edit a Product /Service</Typography>
                        </Grid>
                        <Grid item mb={'10px'} mt={'10px'}>
                            <FormControl sx={{ minWidth: 400 }}>
                                <InputLabel id="demo-simple-select-label">Category :</InputLabel>
                                <Select
                                    label="Category :"
                                    value={formData.productCategory}
                                    onChange={handleInputChange}
                                    name="productCategory"
                                    displayEmpty
                                >
                                    {productCa?.map((option) => {
                                        return (
                                            <MenuItem value={option}>{option}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField id="outlined-basic" name="productName" value={formData.productName} onChange={handleInputChange} label="Product Name" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        <Grid item my={2}>
                            <TextField disabled id={`outlined-basic ${formData.productVariationName}`} name="productVariationName" value={formData.productVariationName} onChange={handleInputChange} label="Variation Name" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        {/* <Grid item mb={'10px'} mt={'10px'}>
                            <FormControl sx={{ minWidth: 400 }}>
                                <InputLabel id="demo-simple-select-label">Variant</InputLabel>
                                <Select
                                    label="Variant"
                                    value={variationKeyId}
                                    onChange={(e) =>setVariaionKeyId( e.target.value)}
                                    name="variationKeyId"
                                >
                                    {allVariations?.map((option) => {
                                        return (
                                            <MenuItem value={option?._id}>{option?.variationKey}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-label">Amount Type </InputLabel>
                                <Select
                                    label="Category :"
                                    value={formData.priceStatus}
                                    onChange={handleInputChange}
                                    name="priceStatus"
                                    displayEmpty
                                >
                                    {mapOptions.map((option) => {
                                        return (
                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <TextField id="outlined-basic" name="productPrice" value={formData.productPrice} onChange={handleInputChange} label="Selling Price" style={{ fontWeight: 200, marginLeft: '5px', width: "200px" }} />
                        </Grid>

                        <Grid item mt={2} mb={2}>
                            <TextField id="outlined-basic" disabled variant="outlined" name="productQuantity" type="number" value={formData.productQuantity ? formData.productQuantity:0} onChange={handleInputChange} label="Initial Quantity" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>

                        <Grid item mt={2} mb={2}>
                            <TextField id={`outlined ${formData.buyingPrice}`} variant="outlined" name="buyingPrice" type="number" value={formData.buyingPrice} onChange={handleInputChange} label="Buying Price" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        {businessCat === "ZED_ECOMMERCE" && <Grid width={'50%'} item mt={2} mb={2}>
                            <TextField id={`outlined-basic ${formData.discountPrice}`} variant="outlined" name="discountPrice" type="number" value={formData.discountPrice} onChange={handleInputChange} label="Discount Price" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>}
                        <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
                            <FormControl sx={{ minWidth: 400 }}>
                                <InputLabel id="demo-simple-select-label">Unit of Measure </InputLabel>
                                <Select
                                    label="Unit of Measure"
                                    value={formData.unitOfMeasure}
                                    onChange={(e) => setFormData({ ...formData, unitOfMeasure: e.target.value })}
                                    name="unitOfMeasure"
                                    displayEmpty
                                >
                                    {unitOfMeasure.map((option) => {
                                        return (
                                            <MenuItem value={option}>{option}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                        </Grid>



                        <Grid item width={400} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Checkbox checked={isWeightedProduct} onChange={() => setIsWeightedProduct(!isWeightedProduct)} /> <span style={{ fontFamily: 'Poppins' }}>Weighed Price Item</span>
                        </Grid>

                        <Grid item width={400} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Checkbox checked={consumable} onChange={() => setConsumable(!consumable)} /> <span style={{ fontFamily: 'Poppins', margin: '0 50px 0 0' }}>Consumable</span>
                        </Grid>



                        {businessCat !== 'Rental' && businessCat !== 'School' &&
                            <Grid item mt={2} mb={2}>
                                <TextField id={`outlined-basic ${formData.reorderLevel}`} name="reorderLevel" type="number" value={formData.reorderLevel} onChange={handleInputChange} label="Reorder Level" style={{ fontWeight: 200, width: "400px" }} />
                            </Grid>
                        }
                        <Grid item mt={2} mb={2}>
                            <TextField id="outlined-basic" name="productDescription" value={formData.productDescription} onChange={handleInputChange} label="Description" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        {ectizenStatus ? <>
                            <Grid item mt={2} mb={2}>
                                <TextField id="outlined-basic" name="ectizenAccount_Id" type="text" value={formData.ectizenAccount_Id} onChange={handleInputChange} label="Ecitizen Service Id" style={{ fontWeight: 200, width: "400px" }} />
                            </Grid>
                            <Grid item mt={2} mb={2}>
                                <TextField id="outlined-basic" name="ectizenitem_ref" type="text" value={formData.ectizenitem_ref} onChange={handleInputChange} label="Ecitizen Item Reference" style={{ fontWeight: 200, width: "400px" }} />
                            </Grid>

                        </> : null}
                        <Grid container>
                            <Grid item style={{ width: "400px" }} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                                <Button onClick={updateProduct} style={{ background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px" }}>update</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}

        </>
    )
}