import React from 'react'

const Unauthorised = () => {
    return (<div>
            <h1>
                <i className="fas fa-exclamation-triangle" style={{fontFamily : 'Poppins'}}></i> 401 UNAUTHORISED USER
                <span>Please<a href="/" alt="Login"> LOGIN </a></span><span> here with the right Credentials! </span>
            </h1>
        </div>)
}

export default Unauthorised