import React , {useState, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import success from "../../../common/images/success.png";

function SuccessModal(props){

    // session Expired
    const style = {borderColor:"transparent", borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "300px", height: "300px",};

    // // session Modal Config
    // const [editConfigOpen, setEditConfigOpen] = useState(props.editConfigOpen);
    // const props.onClose = () => setEditConfigOpen(false);
    //
    // // Set the new state when props change
    // useEffect(() => {
    //     setEditConfigOpen(props.editConfigOpen);
    // }, [props.editConfigOpen]);


    return (
        <div>

            {/*successModal*/}
            <Modal
                open={props.editConfigOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div style={{width: "50px", height: "50px", marginLeft:"150px", padding: "12px 30px 127px", objectFit: "contain"}}>
                        <img style={{width:"80px", height:"80px",margin: "12px 0px 0px -66px"}} src={success} alt='img'/>
                    </div>
                    <div style={{height: "50px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "500", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#323d52"}} >
                        <span>
                            <b>{props.modalRow}</b>
                        </span><br/>
                        <span style={{marginTop:"-20px !important", fontSize:"18px"}}>
                            {props.successObject} {props.successType}Expense Created Successfully!
                        </span>
                    </div>
                    <div align='center' style={{height: "41.7px", margin: "41px 0px 0px 0px"}}>
                        <Button onClick={() => {props.onClose();
                            window.location.reload(true)
                        }}
                                style={{fontFamily : "Poppins" , fontSize : "20px" , fontWeight : "600" , color : "#fff" , width : "124.5px" , height : "40.3px" , padding : "8px 44.5px 9.3px 35px" , backgroundColor : "#032541"}}>
                            OK
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export {SuccessModal};