import { Grid, Box, Typography, Divider, Table, TableHead, TableRow, TableCell } from '@mui/material'
import React, { useEffect, useState } from 'react'
import zedLogo from './images/zedlogo.svg';
import { QrcodeComponent } from '../creditnote/creditnotecomponents/qrgenerator';
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from '@mui/material';
import HttpComponent from '../School/MakeRequest';
import { useParams } from 'react-router-dom';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
const moment = require("moment");
const baseUrl = process.env.REACT_APP_BASE_URL;


const styles = makeStyles((theme) => ({
    primaryColor: {
        color: "##143d63"
    },
    p2: {
        padding: "2px",
    },
    textCenter: {
        textAlign: "center",
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0),
        },
    },
    textLeft: {
        textAlign: "left",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0),
        },
    },
    biggerContainer: {
        width: "70%", marginLeft: "auto", marginRight: "auto", padding: "26.4px 45.5px 43px 43px", backgroundColor: " #fff",
        [theme.breakpoints.down("sm")]: {
            width: "100%", marginLeft: "auto", marginRight: "auto", padding: "26.4px 45.5px 43px 43px", backgroundColor: " #fff",
        },
    },
    logoStyle: {
        width: "139.6px",
        height: " 89.4px",
        objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
            width: "139.6px",
            height: " 89.4px",
            objectFit: "contain",
        },
    },




}))







const Ticket = () => {
    const classes = styles();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [ticketDetails, setTicketDetails] = useState([]);
    const { ticketNumber } = useParams();
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });

    const formatDate = (date) => {
        return moment(date).format("dddd, MMMM Do, YYYY");
    };
    let localCurrency = "KES"
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: (localCurrency === 'undefined' || !localCurrency) ? 'KES' : localCurrency }).format(value);


    const getTicketDetails = () => {
        HttpComponent({
            method: "GET",
            url: `/api/v1/event/ticket_details/${ticketNumber}`,
            body: null,

        })
            .then((data) => {
                console.log("here store is data", data);
                if (data.status === 200) {
                    setTicketDetails(data.response?.data);

                } else {
                    setErrorShow({ state: true, message: data.response.message });
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    }

    useEffect(() => {
        getTicketDetails();
    }, []);
    return (
        <Grid className={classes.biggerContainer}>
            {console.log("here is ticket details", ticketDetails)}
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid container style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                {/* Logo */}
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <img src={`${!ticketDetails?.event?.eventPoster ? zedLogo : `${baseUrl}/staticimages/events/${ticketDetails?.event?.eventPoster}`}`} className={classes.logoStyle} />
                </Box>
                <Typography variant="h5" style={{ textAlign: "center", fontWeight: "500", fontSize: "25px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541" }} >Hooray! Order Confirmed</Typography>
            </Grid>
            <Grid style={{ display: "flex", flexDirection: "column", backgroundColor: "rgba(3, 37, 65, 0.05)", marginTop: "20px", padding: "20px" }}>
                {/* text order */}
                <Typography variant="h5" style={{ textAlign: "center", fontWeight: "bold", fontSize: isSmallScreen ? "28px" : "42px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >{ticketDetails?.eventName}</Typography>
                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "left" }}>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >Date : </Typography>
                            <Typography variant="" style={{ textAlign: "left", fontWeight: "normal", fontSize:  isSmallScreen ? "12px" : "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >{formatDate(ticketDetails?.event?.startDate)}</Typography>

                        </Box>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >KickOff : </Typography>

                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "normal", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >2.15pm</Typography>

                        </Box>

                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "left" }}>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >Ticket ID /NO : </Typography>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "normal", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >{ticketNumber}</Typography>

                        </Box>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >Venue : </Typography>

                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "normal", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >{ticketDetails?.event?.eventLocation}</Typography>

                        </Box>

                    </Box>
                </Box>

            </Grid>
            <Grid style={{ display: "flex", flexDirection: "column", marginTop: "20px", padding: "20px" }}>
                {/* text order */}
                <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "left" }}>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >Ticket Type : </Typography>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "normal", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >{ticketDetails?.ticketType}</Typography>

                        </Box>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >Ticket Amount : </Typography>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "normal", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >{numberFormat(ticketDetails?.ticketPrice)}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >Seat /NO : </Typography>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "normal", fontSize: "14px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", }} >---</Typography>

                        </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "left", marginTop: isSmallScreen ? "20px" : "0px" }}>
                        <Box>
                            <QrcodeComponent creditNoteNumber={ticketNumber} size={isSmallScreen ? 110 : 200} />
                        </Box>

                    </Box>
                </Box>

            </Grid>
            <Divider style={{ border: "1px dotted #bfbfbf" }} />
            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "500", fontSize: "13px", fontStretch: "normal", fontFamily: "Poppins", marginTop: "10px", color: "#032541", padding:"10px"}} >A HUGE thank you for securing your ticket! 🎉 <br />
                We’re committed to your valued connections and support for cherished experiences.<br />
            </Typography>

            <Divider style={{ border: "1px dotted #bfbfbf" }} />
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.textLeft}>
                                Mode of Payment
                            </TableCell>
                            {/* <TableCell className={classes.textLeft}>
                                Payment ID
                            </TableCell> */}
                            <TableCell className={classes.textCenter}>
                                Date
                            </TableCell>
                            <TableCell className={classes.textRight}>
                                Amount
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableRow key={1}>
                        <TableCell className={classes.textLeft}>
                            <span className={classes.table_body}>
                                {ticketDetails?.event?.defaultPaymentMethod}
                            </span>
                        </TableCell>
                        {/* <TableCell className={classes.textCenter}>

                            <span className={classes.table_body}>
                                {ticketDetails?.paymentId}
                            </span>
                        </TableCell> */}
                        <TableCell className={classes.textCenter}>
                            <span className={classes.table_body}>
                                {formatDate(ticketDetails?.eventDate)}
                            </span>
                        </TableCell>
                        <TableCell className={classes.textRight}>
                            <span className={classes.table_body}>
                                {numberFormat(ticketDetails?.ticketPrice)}
                            </span>
                        </TableCell>
                    </TableRow>

                </Table>
            </div>
            <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }} id="footer-text" >
                <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }} id="footer-text-content" >
                    This is a system-generated invoice is created without any   alteration whatsoever.     Thank you for your business.
                </div>
            </div>
            <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }} id="footer-info" >
                <div id="footer-info-text">
                    <span className={classes.footer_text}> Powered by{" "} <span style={{ fontWeight: "700", }} >   ZED Ticketing Limited </span> <span className={classes.footer_text}>.</span></span>
                </div>
                <div id="footer-info-email">
                    <span className={classes.footer_text}>info@zed.business</span>
                    <span className={classes.footer_text}>.</span>
                    <span className={classes.footer_text}>v1.0.2</span>
                </div>
            </div>

        </Grid >
    )
}

export default Ticket