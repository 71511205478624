import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AllTransactionsTable from "../components/Reports/AllTransactionsTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function AllTransactions() {
  return (
    <Grid container sx={{background:'white'}} direction={'column'}>
     <Stack direction="row" spacing={1} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            color="#032541"
          >
           All Transactions
          </Typography>
          
        </Stack>
      <AllTransactionsTable/>
    </Grid>
  );
}

export default AllTransactions;
