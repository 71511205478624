import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import startOrderFunction from "./posmethods";
import CustomSearchComponent from "./customSearchComponent";
import { currencyconverter } from "../../../common/currencyconverter";
import CustomProductSkeleton from "./customProductSkeleton";
import { ErrorAlertRightAligned } from "../../bills/startorderModals/modals";

const Productlist = ({ categoryName }) => {
  const [dataGridPageSize, setPageSize] = React.useState(20);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

// search products;
const [searchValue, setSearchValue] = useState("");


  const startOrderFunctions = new startOrderFunction();
  useEffect(() => {
    if (categoryName)
      startOrderFunctions.getProducts(categoryName, 1, dataGridPageSize, searchValue).then((data) => {
        // category
        setPageState({ ...pageState, isLoading: false, data: data?.response?.products, count: data?.response?.count });
      });
  }, [dataGridPageSize, categoryName, searchValue]);

  //   "#032541"fafafa

  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [ticking, setTicking] = useState("");

  document.addEventListener("scroll", (event) => {
    setLastScrollPosition(window.scrollY);
  });

  const getScrollPosition = (e) => {
    let scrollableDiv = e.target;
    let scrollPosition = scrollableDiv.scrollTop + scrollableDiv.clientHeight;
    let scrollHeight = scrollableDiv.scrollHeight;
    let totalCategoryCount = pageState?.count;
    let currentCategoryList = pageState?.data?.length;

    if (scrollPosition === scrollHeight) {
      if (currentCategoryList < totalCategoryCount) {
        if (categoryName)
          startOrderFunctions.getProducts(categoryName, 1, dataGridPageSize + currentCategoryList, searchValue).then((data) => {
            // category
            setPageState({ ...pageState, isLoading: false, data: data?.response?.products, count: data?.response?.count });
          });
      }
    }
  };

    // handle error
    const [isError, setIsError] = useState({ state: false, message: "" });
    useEffect(() => {
      if (isError.state) {
        setTimeout(() => {
          setIsError({ state: false, message: "" });
        }, 2000);
      }
    }, [isError.state]);

  const [posArray, setPosArray] = useState([]);
  // ADD PRODUCT TO POS ARRAY
  const addProductsToPosArray = (product) => {
 
    if (product.outOfStock === true || (product.quantityInStock === 0 && product.outOfStock === true) || (Math.sign(product.quantityInStock) === -1 && product.outOfStock === true)) {
      setIsError({ ...isError, state: true, message: `${product.productName} is out of stock!` });
    } else {
      const newPosArray = [...posArray];
      console.log("newPosArray",newPosArray)
      // create object and add
      const posProduct = {
        _id:product._id,
        itemName: product.productName,
        productId:product._id,
        itemAmount: product.productPrice,
        checkedProductId: product.id || product._id,
        itemCount: 1,
        totalAmount: product.productPrice * 1,
        customerNote: "",
        itemCategory: product.productCategory,
        unitOfMeasure: product.unitOfMeasure,
        reciptNumber: Date.now(),
        quantityInStock: product.quantityInStock,
        priceStatus: product.priceStatus,
        businessId: localStorage.getItem("businessId"),
        isWeightedProduct: product?.isWeightedProduct,
      };

      if (posProduct.itemName) {
        const currentIndex = newPosArray.findIndex((posProduct) => posProduct.checkedProductId === product._id);
        if (currentIndex === -1) {
          // const startOrderItems = localStorage.getItem("startOrderItems");
          // dispatch(addStartOrderArray(posProduct));
          newPosArray.push(posProduct);
        } else {
          newPosArray?.map((item, index) => {
            if (index === currentIndex) {
              item.itemCount = parseInt(item.itemCount) + 1;
              item.totalAmount = item.itemCount * item.itemAmount;
            }
            return item;
          });
        }
        setPosArray(newPosArray);
        localStorage.setItem("startOrderItems", JSON.stringify(newPosArray));
        const event = new Event("startOrderItemsUpdated");
        window.dispatchEvent(event);
      }
    }
  };
  const [clickedProducts, setClickedProducts] = useState([])
  const controlProductColorBox = (productName) => {
    if(productName){

      const newClickedProduct = [...clickedProducts];

      let checkIfProductExists = newClickedProduct.indexOf(productName)
      if(checkIfProductExists === -1){
        newClickedProduct.push(productName)
      }
      setClickedProducts(newClickedProduct)
    }
  }
  // handle removing items from pos array;
  window.addEventListener("removeItems", () => {
    setPosArray([]);
    localStorage.removeItem("startOrderItems")
    setPageState({ ...pageState, isLoading: false, data: [] });
    setClickedProducts([])
  });



  return (
    <Box sx={{fontFamily:"Poppins"}}>
      {isError?.state && <ErrorAlertRightAligned error={isError.state} message={isError.message} />}
      <Box mb={2}>
        <CustomSearchComponent setSearchValue={setSearchValue} />
      </Box>

      <Box component={Paper} onScroll={getScrollPosition} sx={{ padding: "20px", height: "65vh", overflow: "scroll", display: "flex", flexWrap: "wrap", gap: "10px", cursor: "pointer", rowGap:"0px" }}>

      {/* {pageState.isLoading && <CustomProductSkeleton styles={{display:"flex", gap:"20px", flexWrap:"wrap", margin:'10px', maxWidth: "calc(50% - 5px)", flex: pageState.data.length < 10 ? "1 0 41%" : "1 0 calc(50% - 5px)",}} arrayLength={dataGridPageSize} height={"60px"} width={"400px"} variant={"rectangular"} /> } */}
      {pageState.data.length === 0  && <Box sx={{display: "flex", alignItems: "center", justifyContent:"center", width:"100%", }}> <Typography sx={{fontSize:"20px"}}>No Products to be listed</Typography> </Box> }
        {!pageState.isLoading &&
          pageState.data?.map((product, index) => {
            const { outOfStock , quantityInStock , productName } = product
            return (
              <Box key={index} sx={{
                 backgroundColor:  clickedProducts?.includes(productName) ? "#032541" : "#fafafa", 
                 padding: "10px", marginBottom: "3px", width: "fit-content", height: "fit-content", borderRadius:'3px', flex: pageState.data?.length < 10 ? "1 0 50%"  : "1 0 41%" }} onClick={() => {
                controlProductColorBox(product?.productName)
                
                addProductsToPosArray(product)
              }}>
                <Box>
                  <Typography sx={{ color: clickedProducts?.includes(productName)  ? "#ffffff" : "#555555", fontFamily:"Poppins" }}>{product?.productName}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ color: clickedProducts?.includes(productName) ? "#ffffff" : "#17ae7b" }}> {currencyconverter(product?.productPrice)} </Typography>
                  <Typography sx={{ color: clickedProducts?.includes(productName) ? "#ffffff" : "#707070" }}>
                    In Stock <span style={{ color: clickedProducts?.includes(productName) ? "#ffffff" : "#000000" }}>{product?.quantityInStock}</span>{" "}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default Productlist;
