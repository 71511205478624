import React , {useState, useEffect} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import powericon from "../../common/images/powericon.svg";

function ValidationMessage(props){

    // session Expired
    const style = {borderColor:"transparent", borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "350px", height: "300px", p: 4,};


    return (
        <div>
              <div>
            <Modal
                open={props.deactivateModalOpen} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                  
                        <div style={{ width: "50px", height: "50px", marginLeft: "100px", padding: "12px 30px 80px", objectFit: "contain" }}>
                            <img style={{ width: "82px", height: "82px", margin: "-24px 0px 0px -30px"}} src={powericon} alt='img' />
                        </div>
                       
                        <h6 style={{
                          
                           
                            fontSize: "18px",
                            fontWeight:" bold",
                            fontStretch: "normal",
                            
                           
                            
                            color:' #000'
                        }}>{props.message}</h6>
                        
                        <div style={{
                            display:"flex",
                            justifyContent:"center",
                         
                        }}>
                            <Button onClick={props.onClose} variant='outlined' style={{ fontFamily: "Poppins",width:"200px", fontSize: "12px", textAlign: "left", color: "#dc3545", height: "40px", margin: "20px 30px 0 0",  border: "solid 1px #dc3545" }}>
                                Cancel
                            </Button>
                           
                          
                        </div>
                    
                </Box>
            </Modal>

        </div>

         
           
           
        </div>
    )
}
export {ValidationMessage};