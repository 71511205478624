import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import HttpComponent from "../../School/MakeRequest";
import { warehousestyles } from "../styles";
import { useNavigate, useParams } from "react-router";



const AddInZedSuppliers = ({setSupplier, setCustomerPhone, setSupplierBsNo, setInZed}) => {
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState("");

  const [dataGridPageSize, setPageSize] = React.useState(500);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });


  const getBusinesses = () => {
    HttpComponent({
      method: "GET",
      url: `/api/search_businesses?limit=1000&page=1&searchValue=${inputValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, total: 0 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getBusinesses();
  }, [inputValue]);

  const redirectToAddSupplier = (business) => {
    const  {businessOwnerEmail, businessOwnerPhone, businessOwnerAddress, businessName, businessNumber } = business
    setCustomerPhone(businessOwnerPhone)
    setSupplier({username: businessName, email: businessOwnerEmail, location: businessOwnerAddress})
    navigate(`/addsupplier/newSupplier`)
    setInZed('inZed')

    setSupplierBsNo(businessNumber)
  }
  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"column"} style={{ width: "100%" }}>
        <Grid item width={"40%"} margin={"0% 0% 2% 0%"}>
          <SearchFilter placeholder={"Search Business Name"} setInputValue={setInputValue} />
        </Grid>

        <div style={pageState?.data?.length > 1 ? { height: "50vh", overflow: "auto" } : null}>
          {pageState?.data?.map((business) => {
            return (
              <Grid item key={business._id} width={"40%"} style={{ margin:"0px 0px 1% 0px", borderBottom: "1px solid #707070", cursor:"pointer" }} onClick={() => redirectToAddSupplier(business)} >
                <p style={warehousestyles?.supplierTypeText}>{business?.businessName}</p>
                <p style={warehousestyles?.supplierTypePara}>{business?.country}</p>
              </Grid>
            );
          })}
        </div>

        {pageState?.data?.length === 0 && (
          <Grid item width={"40%"} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p style={warehousestyles?.supplierTypeText}>No available business</p>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AddInZedSuppliers;
