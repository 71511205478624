import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@mui/styles";
import SmsIcon from '@mui/icons-material/Sms';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function Sms () {
	const classes = useStyles();

	return (
		<div>
			<Link style={{textDecoration: 'none', color : '#fff'}} to="#">
				<ListItem button className={classes.nested}>
					<div style={{marginRight : '5%'}}>
						<SmsIcon />
					</div>
					<div>
						<ListItemText primary="Sms" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}