import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbcomponent from "./breadcrumb";
import { stockStyles } from "./stockStyling";
import { Button, Grid, Tab } from "@mui/material";
import { Add, ArrowBackIos,  } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import OutgoingStockOrder from "./stockModals/outgoingStockOrder";

// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({ textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 }, fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), fontSize: "14px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75", letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","), "&:hover": { color: "#6e7074", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 }, "&.Mui-focusVisible": { backgroundColor: "blue" } }));

const StockOrders = () => {
  const navigate = useNavigate();
  const breadcrumb = [
    <span key={1}>Dashboard</span>,
    <span key={2}>Stock</span>,
    <span key={3} style={{ color: "red" }}>
      Stock Order
    </span>,
  ];

  const [tabValue, setTabValue] = useState("outgoing")

  const handleChange = (prev, newValue) => {
    setTabValue(newValue)
  }
  return (
    <div style={{ width: "100%" }}>

      <Grid container direction={"column"} margin={"0px 0px 2% 0px"}>
        <Grid item width={"100%"} margin={"0px 0px 1% 0px"} style={{ display: "flex", alignItems: "center" }}>
          <span>
            <ArrowBackIos style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
          </span>
          <span style={stockStyles.mediumIntroText}>Stock Management</span>
        </Grid>

        <Grid item width={"100%"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 0px 0% 0px"}>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>
      </Grid>

      <Grid container width={"100%"} direction={"row"} justifyContent={"flex-end"} alignItems={"center"} >
            <Grid item >
                <Button startIcon={<Add />} style={{backgroundColor:"black", color:"white"}} onClick={() => navigate(`/stockOrder/newOrder`)}>ORDER STOCK</Button>
            </Grid>
        </Grid>

      <Grid container width={"100%"} margin={"0px 0px 2% 0px"}>
        <Grid item width={"100%"}>
            <TabContext value={tabValue}>
                <AntTabs onChange={handleChange}>
                    <AntTab value="outgoing" label="Outgoing Requests" />
                    <AntTab value="incoming" label="Incominging Requests" />
                </AntTabs>

                <TabPanel value="outgoing">
                        <OutgoingStockOrder />
                </TabPanel>

                {/* <TabPanel value="incoming">
                    <h1>Good morning guys?!@</h1>
                </TabPanel> */}
            </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default StockOrders;
