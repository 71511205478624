import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import HttpComponent from "../MakeRequest";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
const PocketMoneyCard = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [success, setSuccessShow] = useState({ state: false, message: "" });
  const [error, setIsError] = useState({ state: false, message: "" });

  const configureAccount = () => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/setUpPocketMoneyAccount`,
      body: {
        accountNumber: accountNumber,
        accountType: "KCB",
      },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccessShow({ ...success, state: true, message: data?.response?.message });
        
        
      } else if (data?.status === 400) {
        setIsError({ ...error, state: true, message: data?.response?.message });
      }
    });
  };

  return (
    <div style={{ width: "100%", fontFamily: "Poppins" }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ state: false })} open={success.state} message={success.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setIsError({ state: false })} open={error.state} message={error.message} />
      <Grid container width={"100%"}>
        <Grid item width={"100%"} margin={"0 0 3% 0"}>
          <FormControl style={{ width: "100%", marginTop: "-5px" }}>
            <TextField style={{ width: "100%" }} id="outlined-multiline-flexible" multiline placeholder="e.g 123456" required label={"Enter Acccount Number"} onChange={(e) => setAccountNumber(e.target.value)} />
          </FormControl>
        </Grid>

        <Grid item width={"100%"}>
          <Button style={accountNumber ? { backgroundColor: "black", color: "white", width: "20%", opacity: 1 } : { backgroundColor: "black", color: "white", width: "20%", opacity: 0.3 }} onClick={() => (accountNumber ? configureAccount() : null)}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PocketMoneyCard;
