import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ArrowBack, Message, WhatsApp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import download from '../../common/images/download.svg';
import { Link } from "react-router-dom";
import { EmailRounded } from "@mui/icons-material";


function ShareGenerateMOdal(props) {
  // session Expired
  const AddProductModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "600px",
    height: "500px",
  };
  

  

  return (
    <div>
      <Modal
        open={props.shareModalOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={AddProductModalstyle}>
          <Box
            sx={{
              width: "600px",
              height: "90px",
              margin: "0 0 9px",
              padding: "35px 30px 34px",
              borderRadius: "16px",
              backgroundColor: "#dc3545",
            }}
          >
            <Typography
              style={{
                width: "100%",
                height: "21px",
                margin: "0 423px 0 0",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#fff",
              }}
            >
              <div className="top-modal-bar" style={{ display: "flex", justifyContent: "space-around" }}>
                <ArrowBack onClick={props.onClose} />
                <span>Share Voucher</span>
                <CloseIcon onClick={props.onClose} />
              </div>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "33.8px 55.6px 0.8px 43.6px"
            }}
          >
            <div className="download-container" style={{
                width: '541.5px',
                height: '100.9px',
                margin: '2px 0 0px 0px',
                padding: '27px 224px 27.2px 30px',
                opacity: '0.8',
                border:' solid 1px #707070',
            }}>
              <Link to={`/giftvoucher/${props.voucherNumber}`}>
              <div className="d-flex">
                
                <img src={download} alt="" />
                <h6 className="ml-4 mt-2">Generate  Voucher</h6>
               

              </div>
              </Link>
            </div>
           
            
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "10px 55.6px 0.8px 43.6px"
            }}
          >
            <div className="download-container" style={{
                width: '541.5px',
                height: '100.9px',
                margin: '2px 0 0px 0px',
                padding: '27px 224px 27.2px 30px',
                opacity: '0.8',
                border:' solid 1px #707070',
            }}>
              <Link to=''>
              <div className="d-flex">
                {/* <Message/> */}
                <WhatsApp style={{fontSize:"50px"}}/>
                
                
                <h6 className="ml-4 mt-2">WhatsApp</h6>
               

              </div>
              </Link>
            </div>
           
            
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "10px 55.6px 0.8px 43.6px"
            }}
          >
            <div className="download-container" style={{
                width: '541.5px',
                height: '100.9px',
                margin: '2px 0 0px 0px',
                padding: '27px 224px 27.2px 30px',
                opacity: '0.8',
                border:' solid 1px #707070',
            }}>
              <Link>
              <div className="d-flex">
                
                <EmailRounded style={{fontSize:"50px"}}/>
                <h6 className="ml-4 mt-2">EMAIL</h6>
               

              </div>
              </Link>
            </div>
           
            
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export { ShareGenerateMOdal };
