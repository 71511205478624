import {Button, ButtonBase, Grid, TextField} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {NoRowsOverlay} from "../../No Rows/noRowsOverlay";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import HttpComponent from "../../School/MakeRequest";
import SetWorkFlow from "../Utility/updateSetupWorkFlow";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";

const numberFormat = (value) => new Intl.NumberFormat("en-US", {style: "currency", currency: "KES"}).format(value);

export default function ListServices(props) {

	// Notifications
	const [errorShow, setErrorShow] = useState({state: false, message: ""})
	const [successShow, setSuccessShow] = useState({state: false, message: ""})

	function handleRemoveService (unitId) {
		HttpComponent({
			method : 'POST',
			url : '/api/delete_business_unit_type',
			body : {unitTypeId : unitId},
			token : X_Authorization
		}).then((data) => {
			if (data.status === 201) {
				setSuccessShow({state:true, message:data.response.message})
				getServices()
			} else {
				setErrorShow({state:true, message:data.response.message})
			}
		}).catch((e) => {
			console.log('Error Removing Unit', e.message)
		})
	}

	const unitColumns = [
		{field : 'unitType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Unit Name</strong>)}},
		{field : 'serviceName', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Service Name</strong>)}},
		{field : 'serviceType', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Service Type</strong>)}},
		{field : 'amount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Amount</strong>)}, renderCell : (params) => {return numberFormat(params.value)}},
		{field : 'action', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}} >Action</strong>)}, renderCell : (params) => {return (<div><Grid container direction={'row'} justifyContent={'space-between'}>
			
			{/*Pencil*/}
			<Grid item style={{color : '#032541'}}>
				<ButtonBase>
					<ModeEditIcon />
						<span style={{marginLeft : '5px'}}>Edit</span>
				</ButtonBase>
			</Grid>
			
			{/*Delete*/}
			<Grid item ml={3} style={{color : '#dc3545'}}>
				<ButtonBase onClick={() => {
					if (params.row.serviceName.toLowerCase().includes('rent') ||
						params.row.serviceType.toLowerCase().includes('rent')) {
						setErrorShow({state : true, message : 'You Cant Remove Rent as it is Default!'})
					}
					else {
						handleRemoveService(params.row._id)}
					}
				}>
					<DeleteIcon />
						<span style={{marginLeft : '5px'}}>Remove</span>
				</ButtonBase>
			</Grid>
		</Grid></div>)}},
	]
	
	const { userId ,X_Authorization } = useSelector((store) => store.user);
	
	//Navigation
	const navigate = useNavigate()
	const handleGoBackClick = () => {props.setFlagStateListingBack('addUnit')}
	
	const [unitRows, setUnitRows] = useState([])
	
	//Pagination Stuff
	const [dataGridPageSize, setPageSize] = React.useState(5);
	const [pageState, setPageState] = useState({isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize,})
	
	// Get Services
	function getServices(){
		try {
			HttpComponent({
				method : 'GET',
				url : `/api/get_unity_type_services?page=${pageState.page}&limit=${dataGridPageSize}`,
				token : X_Authorization
			}).then((data)=>{
				console.log("here store is data",data);
				if(data.status === 201){
					setPageState((old) => ({
						...old,
						isLoading: false,
						data: data.response.data,
						total: data.response.count,
					}));
					setUnitRows(data.response.data)
				}
				else{
					console.error("Error setting info")
				}
			}).catch((error)=>{
				console.error(error.message);
			})
		} catch (e) {
			console.log(`Error Getting Services`, e.message)
		}
	}
	
	useEffect(() => {
		getServices()
	}, [pageState.page, pageState.pageSize, dataGridPageSize])
	
	function handleDone() {
		SetWorkFlow({flowName : 'PAYMENTOPTIONS', token : X_Authorization})
		props.next()
	}
	
	return (
		<div>
			<Grid container justifyContent={'flex-start'} direction={'column'} spacing={5} mt={2}>

				{/*Notification*/}
				<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

				<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>

				{/*label*/}
				<Grid item>
					<span style={{color : '#032541', fontSize : '16px', fontWeight : '600',}} >Allocate more Services or complete this step.</span>
				</Grid>
				
				{/*DataGrid*/}
				<Grid item>
					<DataGrid
						components={{NoRowsOverlay : NoRowsOverlay}}
						sx={{height : '400px', width : 'auto', fontFamily : 'Poppins', fontSize: "12px", color : '#272d3d',boxShadow: 0, border: 0}}
						rowsPerPageOptions={[3, 5, 10, 20, 50, 100]}
						rowCount={pageState.total}
						loading={pageState.isLoading}
						pagination
						page={pageState.page - 1}
						pageSize={dataGridPageSize}
						paginationMode="server"
						onPageChange={(newPage) => {setPageState((old) => ({...old, page: newPage + 1, pageSize: dataGridPageSize}));}}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						columns={unitColumns}
						rows={unitRows}
						cellRenderer={() => {return (1+1 === 2 ? <div>x</div>: '')}}
						getRowId={(row) => Math.floor(Math.random() * 100000)}
					/>
				</Grid>
			</Grid>
			
			{/*Buttons*/}
			<Grid container justifyContent={'flex-end'} spacing={2} mt={2}>
				
				{/*Done*/}
				<Grid item>
					<Button onClick={handleDone} style={{textTransform : 'none', color : '#fff', background : '#17ae7b', width : '146px', height : '45px', fontSize : '14px', fontWeight : '600'}} >
						Done
					</Button>
				</Grid>
				
				{/*Add Units*/}
				<Grid item>
					<Button onClick={handleGoBackClick} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontSize : '14px', fontWeight : '600'}} >
						Add Another
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}