import { FormControl, Grid, InputLabel, Select, Typography ,Breadcrumbs, TextField, MenuItem } from "@mui/material"
import CustomDataGrid from "../../../products/stockComponents/customDataGrid"
import ExportMenu from "../../../School/ExportMenu"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import DateFormatter, { DoMaskValue } from "../../../../utils/dateFormatter";
import { currencyconverter } from "../../../../common/currencyconverter";


// breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>ZED B2B Transactions</Typography>
]

const ZedB2BTransactions = () => {
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("Last 7 days")
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    useEffect(() => {
        const setLastSevenDays = () => {
            const result = [];
            for (let i = 0; i < 7; i++) {
                const d = new Date();
                d.setDate(d.getDate() - i);
                result.push(d.toLocaleDateString());
            }
            const lastSevenFirstStart = result[0];
            const lastSevenLastEnd = result[result.length - 1];
            setStartDate(lastSevenFirstStart);
            setEndDate(lastSevenLastEnd);
        };

        setLastSevenDays();
    }, []);

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1));
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);

                    setStartDate(lastSevenFirst);
                    setEndDate(lastSevenLast);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1);
                const currentDay = new Date();
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1);
                const lastDayLastMonth = new Date(date2.setDate(1));
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                function defaultLast7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                defaultLast7Days();
                break;
        }
    }, [handleDaysChange]);

    function getB2BTransactions() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/zedb2btransactions?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${escape(searchValue)}&startdate=${startDate}&endDate=${endDate}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getB2BTransactions()
    }, [pageState?.page, dataGridPageSize, searchValue,startDate, endDate])

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        fromAccount: config?.fromAccount,
        targetAccount: config?.accountType === 'ZedPhone' ? `${DoMaskValue(config?.targetAccount)} - ${config?.accountType}`:`${config?.targetAccount} - ${config?.accountType}`,
        businessName: config?.businessName,
        targetBusinessNumber: config?.targetBusinessNumber,
        transactionId: config?.transactionId,
        updatedAt:DateFormatter(config?.updatedAt),
        status:config?.status,
        amount:currencyconverter(config?.amount)
    }))

    const studentBalanceCols = [
        { field: "fromAccount", headerName: "Debit Account", flex: 1 },
        { field: 'targetAccount', headerName: 'Credit Account', flex: 1 },
        { field: 'transactionId', headerName: 'TXN Ref', flex: 1 },
        { field: 'businessName', headerName: 'Crd Busines Name', flex: 1 },
        { field: 'targetBusinessNumber', headerName: 'Crd Busines ID', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: 'updatedAt', headerName: 'Date & Time', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 , renderCell: (params) => (
            <Typography sx={{color: params?.row?.value === 'SUCCESS' ? 'green' :'red'}}>{params?.row?.status}</Typography>
        )}
    ]


    // export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "Debit Account": data?.fromAccount,
            "Credit Account": data?.targetAccount,
            "TXN Ref": data?.businessName,
            "Crd Busines Name'": data?.businessNumber,
            "Crd Busines ID":data?.targetBusinessNumber,
            "Amount": data?.amount,
            "Date & Time": data?.updatedAt,
            "Status": data?.status
        }
    })
    const fileHeaders = [["Debit Account", "Credit Account", "TXN Ref", "Crd Busines Name", "Crd Busines ID", "Amount", "Date & Time", "Status"]];
    const csvColumns = [
       { label: "Debit Account", key: "Debit Account" },
       { label: "Credit Account", key: "Credit Account" },
       { label: "TXN Ref", key: "TXN Ref" },
       { label: "Crd Busines Name", key: "Crd Busines Name" },
       { label: "Crd Busines ID", key: "Crd Busines ID" },
       { label: "Amount", key: "Amount" },
       { label: "Date & Time", key: "Date & Time" },
       { label: "Status", key: "Status" }
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.fromAccount ,data?.targetAccount , data?.transactionId ,data?.businessName , data?.targetBusinessNumber ,data?.amount , data?.updatedAt , data?.status]);

    return (
        <Grid container direction={'column'} width={'100%'}>
          <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>ZED B2B Transactions Report</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'100%'}>
                <Grid container width={'100%'} justifyContent={'space-between'} gap={2} display={'flex'} direction={'row'} alignItems={'center'}>
                    <Grid item>
                        <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item display={'flex'}>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value='All'>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Student wallet report'} title={"Student wallet report  "   + new Date().toLocaleDateString()} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={studentBalanceCols} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}

export { ZedB2BTransactions }