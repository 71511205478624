import {Button, Grid, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";

//image imports
import NoOfUnits from "../../../../common/images/apartment.svg"
import AddHouse from "../Tenant/addHouse";
import AddUnit from "./addUnit";
import ListUnits from "./listUnits";
import EditUnit from "./editUnit";
import HttpComponent from "../../../School/MakeRequest";

export default function DefineUnits(props) {
	const [data ,setData] = useState([])
	const { X_Authorization } = useSelector((store) => store.user);

	const [flagState, setFlagState] = useState("");
	const handleAddUnitClick = () => {
		setFlagState("addunit");
	};
	
	const handleAddHouseClick = () => {
		setFlagState("addHouse");
	};
	const handleListingClick = () => {
		setFlagState("listUnits");
	};

	const handleEditClick = () => {
		setFlagState("editUnits");
	};

	const handleGoBackClick = () => {
		setFlagState("");
	};

	const handleNext = ()=>{
		props.next()
	}

	//fetch all units

	const getUnitsData = () => {
		HttpComponent({
			method: 'GET',
			url: '/api/get_business_unit_config_list',
			token: X_Authorization
		}).then((data) => {
			console.log("here store is data listststst", data.response.data);
			if (data.status === 201) {
				setData(data.response.data)
			} else {
				console.error("Error setting info")
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		getUnitsData()
	}, [])

	
	
	// Rendering External Components
	let componentToRender =
		<>
			<Grid container direction={'column'} fontFamily={'Poppins'}>
				<Grid item mt={3}>
					<Grid container direction={'row'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
						<Grid item display={'flex'} direction={'row'}>
							<img src={NoOfUnits} alt="'Number of Units" />
							<Grid ml={2} display={'flex'} direction={'column'} item style={{ marginTop: '10px' }}>
								<Typography style={{ color: "#6e7074" }}>Total No. of units</Typography>
								<span style={{ fontSize: '18px', fontWeight: 'bolder', color: '#212b36' }} >{data?.length}</span>
							</Grid>
						</Grid>
						<Grid item>
							<Button onClick={handleAddUnitClick} style={{ background: "#032541", borderRadius: "5px", textTransform: "inherit", width: "140px", height: "45px", color: "#fff" }}>Define Units</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item mt={20} display={'flex'} alignContent={'center'} justifyContent={'center'}>
					<Typography style={{color:"#6e7074"}}>Define at least <span style={{color:"#dc3545"}}>1</span> unit</Typography>
				</Grid>
			</Grid>
		</>

	if (flagState === "addunit") {
		componentToRender = (
			<AddUnit setFlagState={handleListingClick} isFromSetup={true} setFlastStateBack={handleGoBackClick} handleAddHouseClick={handleAddHouseClick} />
		);
	} else if (flagState === "addHouse") {
		componentToRender = (
			<AddHouse setFlagState={handleAddUnitClick} setFlagStateListing={handleListingClick} handleGoBackClick={handleGoBackClick} />
		);
	} else if (flagState === 'listUnits') {
		componentToRender = (
			<ListUnits goToServices={handleNext} setFlagEditUnit={handleEditClick} setFlagStateListingBack={handleAddUnitClick} setFlagState={handleAddUnitClick} handleGoBackClick={handleGoBackClick} />
		);
	} else if (flagState === 'editUnits') {
		componentToRender = (
			<EditUnit setFlagListUnits={handleListingClick} />
		);
	}

	return (
		<div style={{fontFamily : 'Poppins'}}>
			{componentToRender}
		</div>
	)
}