import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    type: "",
    description: "",
};

const userGroupToUpdate = createSlice({
  name: "userGroupDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.description = action.payload.description;
    },
  },
});

export const { setDetails } = userGroupToUpdate.actions;

export default userGroupToUpdate.reducer;
