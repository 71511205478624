import { Grid, Typography, Box, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from 'react';
import CustomSearchInput from '../School/CustomSearchInput';
import { Exports } from '../customerAccounts/customerComponents/exports';
import CustomDataGrid from '../products/stockComponents/customDataGrid';
import HttpComponent from '../School/MakeRequest';
import DateFormatter from '../../utils/dateFormatter';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle</Typography>
]

export default function AllInsuranceVehicles() {
    const [searchValue, setSearchValue] = useState('');
    const [dataGridPageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize, })
    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setCsvExport] = useState({ headers: [], data: [] });

    function getAllInsuranceVehicles(){
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method:'GET',
                url: `/api/v1/getVehiclesForInsurance?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data)=>{
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        getAllInsuranceVehicles()
    },[pageState.page , dataGridPageSize])

    useEffect(() => {

        const filedata = pageState?.data?.map((data) => {
            return {
                "Registration No": data?.vehicleRegNo,
                "Vehicle Type": data?.transactionTime,
                "Policy No": data?.source,
                "Expiry Date": data?.transactionType,
                "Sacco": data?.businessId,
                "Business Name": data?.tellerId,
            }
        })

        const fileHeaders = [["Registration No", "Vehicle Type", "Policy No", "Expiry Date", 'Sacco', "Business Name"]]
        const csvColumns = [
            { label: "Registration No", key: "Registration No" },
            { label: "Vehicle Type", key: "Vehicle Type" },
            { label: "Policy No", key: "Policy No" },
            { label: "Expiry Date", key: "Expiry Date", },
            { label: "Sacco", key: "Sacco" },
            { label: "Business Name", key: "Business Name", },
           
        ];

        const fileDataPDF = pageState?.data?.map((data) => [data?.receiptNo ,(data?.transactionTime), data?.source, data?.transactionType, data?.businessId, data?.tellerId, data?.terminalSerialNumber]);

        setCsvExport({ headers: csvColumns, data: filedata })
        setExportData({ headers: fileHeaders, data: fileDataPDF })


    }, [pageState.data, startDate, searchValue, endDate])


    // vehicles columns

    const vehiclesColumn = [
        { field: "registrationId", headerName: "Registration No", flex: 1 },
        { field: "vehicleType", headerName: "Vehicle Type", flex: 1 },
        { field: 'policyNo', headerName: 'Policy No', flex: 1 },
        { field: 'expiryDate', headerName: 'Expiry Date', flex: 1 },
        { field: 'sacco', headerName: 'Sacco', flex: 1 },
    ]

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        registrationId: config?.vehicleRegNo,
        vehicleType: config?.vehicleType,
        policyNo: config?.policyNo,
        expiryDate: DateFormatter(config?.expiryDate),
        sacco: config?.saccoName,
    }))

    return (
        <Grid container direction={'column'}>
            <Grid item mt={2}  mb={2}>
                <Grid container gap={4} display={'flex'} direction={'column'}>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                        <Typography variant='h4' sx={{fontWeight:600 , fontSize:'20px'}}>Vehicles</Typography>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item width={'100%'} mt={2}  mb={2}>
                <Grid container width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <CustomSearchInput placeholder={'search'} name={'searchValue'} onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </Grid>
                    <Grid item>
                     <Exports exportData={exportData} activeTabs={'Zed-Pocket Money All Transactions'}  csvExport={csvExport} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'100%'} mt={2} mb={2}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehiclesColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}