import { Grid, Box, StepLabel, Typography, Breadcrumbs, Stepper, Step } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from 'react';
import AdminPartnerAddBusiness from './adminPartnerAdddBusiness';
import AdminDetailsSchool from './AdminDetailsSchool';
import AddAccountPartnerStepper from './AddAccountStepper';
import OutletPartnerList from './OutletListPartner';
import AdmTermList from './AdmTermList';
import AdmTermUser from './AdmTernUser';

export default function AddBusinesssStepper() {
    const steps = ['Business / Institution Details', 'ZPM School Admin', 'Outlets / Shop', 'Terminals', 'Terminal User', 'Account Number']
    const [activeStep, setActiveStep] = useState(() => {
        const storedStep = localStorage.getItem('activeStep');
        return storedStep ? parseInt(storedStep, 10) : 0;
    });


    useEffect(() => {
        const storedStep = localStorage.getItem('activeStep');
        if (storedStep) {
            setActiveStep(parseInt(storedStep, 10));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('activeStep', activeStep.toString());
    }, [activeStep]);


    // steppper

    const breadcrumbs = [
        <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
        <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}> 
         {activeStep === 0 ? 'Business / Institution Details' : activeStep === 1  ? 'ZPM School Admin' :activeStep === 2 ? 'Outlets / Shop' : activeStep === 3 ? 'Terminals' : activeStep === 4 ? 'Terminal User':activeStep === 5 ? 'Account Number' :'Set-Up'}
         </Typography>
    ]

    // next or back

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    


    const RenderStepDetails = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Box>
                       <AdminPartnerAddBusiness prev={handlePrev} next={handleNext}/>
                    </Box>
                );
            case 1:
                return (
                    <Box>
                       <AdminDetailsSchool prev={handlePrev} next={handleNext}/>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                         <OutletPartnerList prev={handlePrev} next={handleNext}/>
                        {/* <AddOutletStepper next={handleNext}/> */}
                    </Box>
                );
            case 3:
                return (
                    <Box>
                        {/* <AddTerminalStepper next={handleNext}/> */}
                        <AdmTermList prev={handlePrev} next={handleNext}/>
                    </Box>
                );
            case 4:
                return (
                    <Box>
                       {/* <AddTerminalUser next={handleNext}/> */}
                       <AdmTermUser prev={handlePrev} next={handleNext} />
                    </Box>
                );
            case 5:
                return (
                    <Box>
                       <AddAccountPartnerStepper/>
                    </Box>
                );

            default:
                return null

        }

    }

   

    return (
        <Grid container width={'100%'} direction={'column'}>
            <Grid item mt={2} mb={4}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps?.map((label) => (
                    <Step key={label} sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                            color: "#17ae7b"
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                            color: "#17ae7b"
                        },
                        "& .MuiStepLabel-root .Mui-active": {
                            color: "#032541"
                        },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                            color: "#000"
                        },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                            fill: "#fff"
                        }
                    }}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box component="div">
                {RenderStepDetails()}
            </Box>
        </Grid>
    )
}