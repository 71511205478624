import { Box, AccordionDetails, AccordionSummary, Accordion, Breadcrumbs, Button, Divider, Grid, Menu, MenuItem, Tab, TextField, Typography, TableBody, TableRow, TableCell, Table, InputAdornment } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomInputLabel from "../../School/CustomInputLabel";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../School/CustomTextField";
import React, { useRef, useState, useEffect } from 'react';
import CustomSelectField from "../../School/CustomSelectField";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import CustomSearchField from '../../School/CustomSearchInput';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import icon from "../../../components/School/Images/upload.svg"
import SearchIcon from "@mui/icons-material/Search";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomSearchInput from "../../School/CustomSearchInput";
import CustomTable from "../../School/CustomTable";
import CustomSelect from "../../School/CustomSelectField";

const numberFormat = (value) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(value) };

const breadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>,
  <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>

]

const breadcrumbsCategories = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>,
  <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>,
  <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Item</Typography>


]
const breadcrumbsUpload = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Other Payments</Typography>,
  <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Upload image</Typography>

]
const RaisePaymentOtherPayments = (props) => {
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [payeeId, setPayeeId] = useState('')
  const [otherDetails, setOtherDetails] = useState({})
  const [payeeAccountsOptions, setPayeeAccounts] = useState([])
  const [payees, setPayees] = useState([])
  const [addItemesModal, setAddItemesModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  console.log(searchText, 'searchText')
  const [selectedPayee, setSelectedPayee] = useState('');
  const [productCategories, setProductCategories] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [category, setCategory] = useState("");
  const [searchCategorie, setSearchCategories] = useState("");
  const [products, setProducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [totalPrice, setTotalPrice] = useState(0)
  const [itemsCount, setItemsCount] = useState(0)
  const [items, setItems] = useState([])
  const queryparams = decodeURIComponent(window.location.search);
  const [buttonState, setButtonState] = useState(false)
  const [accountStatus, setAccountStatus] = useState({})



  const stateUrl = queryparams.slice(1)

  console.log(stateUrl, 'stateUrl')


  const setCategoryMethod = (category) => {
    setCategory(category?.category)
    console.log(category, 'category')
    setCategoryId(category?.categoryId)
  }
  const navigate = useNavigate()








  const [selectedAccount, setSelectedAccount] = useState('');


  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedPayee("");
    setPayeeId("")
    setSelectedAccount("")

  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSearchText("");
  };

  const handlePayeeSelect = (value, label) => {
    console.log(value, 'value handlePayeeSelect')
    console.log(label, 'label handlePayeeSelect')
    setSelectedPayee(label);
    setPayeeId(value)

    closeMenu();
  };

  const searchPayee = (event) => {
    setSearchText(event.target.value);
  };

  const filteredPayees = payees.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );
  // console.log(payeeId, 'payeeId')
  // console.log(selectedPayee, 'selectedPayee')

  const [formData, setFormData] = useState({
    transactionType: "",
    debitBankName: "",
    debitBankCode: "",
    debitAccountNumber: "",
    payeeId: "",
    description: "",
    amount: ""
  })

  const setSelectedAccountMethod = (e) => {
    setSelectedAccount(e.target.value)
    // let accountDetails = value.split(" ")
    console.log(selectedAccount, 'accountDetails ===>>>><<<<>>>>')
  }




  const getPayees = () => {


    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/payee/all?status=Active&search=${searchText}`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, ' data.response.data')
        if (data?.status === 200 || data?.status === 201) {
          let payees = data?.response?.data
          const payeeList = payees.map((payee) => {
            return {
              label: payee?.firstName + ' ' + payee?.lastName + ' ',
              value: payee?._id
            }
          })
          setPayees(payeeList)


        }
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    getPayees()
  }, [searchText])



  const getPayeeDetails = () => {
    if (payeeId !== '') {
      try {

        HttpComponent({
          method: 'GET',
          url: `/api/v1/payee/${payeeId}`,
          body: null,
          token: localStorage.getItem('X-Authorization')
        }).then((data) => {
          console.log(data, 'dataaaaaaaaaaaa')

          if (data.status === 200 || data.status === 201) {
            let payeeDetails = data?.response?.otherDetails[0]
            let accountDetails = data?.response?.data
            setOtherDetails(payeeDetails)
            accountDetails = accountDetails.map((account) => {
              return { label: account?.bankName + " " + account?.paymentAccount, value: account?.bankName + " " + account?.paymentAccount }
            })
            setPayeeAccounts(accountDetails)
          }
        })
      } catch (error) {

      }
    }


  }
  useEffect(() => {
    getPayeeDetails()
  }, [payeeId])
  const updateStock = (row, e) => {
    console.log(row, 'row')
    const productId = row?._id
    let newProducts = products.map((product) => {
      if (product?._id === productId) {
        let newSetItem = {
          itemId: product?._id,
          itemName: product?.productName,
          itemAmount: product?.productPrice * e.target.value,
          itemQuantity: e.target.value,
          accountName: expenseAccount?.find((account) => account?.value === product?.accountId)?.label || "",
          accountId: product?.accountId || ""
        }
        let Newitems = [...items, newSetItem]
        console.log(Newitems, 'Newitems')

        const currentIndex = items.findIndex((item) => item.itemId === productId)
        console.log(currentIndex, 'currentIndex')
        if (currentIndex !== -1) {
          //  replace what in items with newSetItem
          let newItems = [...items]
          newItems[currentIndex] = newSetItem
          setItems(newItems)



        } else if (currentIndex === -1) {
          Newitems = [...items, newSetItem]
          setItems(Newitems)

        }





        let getTotalPrice = 0
        getTotalPrice = items.reduce((acc, item) => {
          return acc + parseInt(item.itemAmount)
        }, 0)

        setTotalPrice(getTotalPrice)

        // setItems([...items, newSetItem])
        return { ...product, quantity: e.target.value }
      }
      return product
    }
    )
    setProducts(newProducts)


  }
  console.log(items, 'items')

  const handleSelectChange = (e, row) => {
    console.log(e, 'e', row, 'row')
    const productId = row?._id
    let newProducts = products.map((product) => {
      if (product?._id === productId) {
        return { ...product, accountName: e?.target?.value, accountId: e?.target?.value }
      }
      return product
    }
    )
    setProducts(newProducts)
  }
  const [expenseAccount, setExpenseAccount] = useState([])

  function getExpenseAccount() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/business/get_general_ledger_expense_accounts`,
        body: {
          businessID: localStorage.getItem('businessID')
        },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          let expenseAccount = data?.response?.data
          console.log(expenseAccount, 'expenseAccount????')
          expenseAccount = expenseAccount.map((account) => {
            return { label: account?.name, value: account?.accountId }
          })
          setExpenseAccount(expenseAccount)
        }

      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getExpenseAccount()
  }, [])


  function getAccountingStatus() {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/v1/check_accounting_status',
        body: {
          businessID
        },
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        if (data.status === 200) {
          setAccountStatus(data.response?.data?.accountingEnabled)
        }
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getAccountingStatus()
  }, [])



  const seaqrchPayee = (e) => {
    console.log(e.target.value, 'e.target.value seaqrchPayee')
    // setSearchText(e.target.value)
    setSearchText(e.target.value.toLowerCase());
  }

  const productColumns = [
    { field: 'productName', headerName: 'Product Name', flex: 1, renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Product Name</p> },
    { field: 'productPrice', headerName: 'Price', flex: 1, renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Selling Price</p> },
    { field: 'accountName', headerName: 'Gl Account', flex: 1, renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Gl Account</p> },
    // accountStatus ? {
    //   field: 'GL Account', headerName: 'accountName', flex: 1.5,
    //   renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>GL Account</p>,
    //   renderCell: (params) => {
    //     return <CustomSelect
    //       label={"GL Account"}
    //       value={params?.row?.accountName}
    //       onChange={(e) => handleSelectChange(e, params?.row)}
    //       options={expenseAccount}

    //     />
    //   }

    // }
    //   : null,



    {
      field: "quantity",
      headerName: " Quantity",
      flex: 1,
      renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>Quantity</p>,
      renderCell: (params) => {
        return <Box component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <input id="" name="" min={1} type="number" style={{ width: "50%", outline: "none" }} onChange={(e) => updateStock(params?.row, e)} />
        </Box>
      },
    }, {
      field: 'totalPrice', headerName: 'Total Price', flex: 1,
      renderHeader: (params) => <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>{params?.field}</p>,
      renderCell: (params) => {
        // console.log(params?.row?.productPrice, 'arams?.row?.productPrice', params?.row?.quantityInStock, 'params?.row?.quantityInStock')
        return <p>{parseInt(params?.row?.productPrice) * parseInt(params?.row?.quantity) || '-'}
        </p>
      }
    },


  ]
  const deleteItem = (id) => {
    console.log(id, 'id')
    let newItems = items.filter((item) => item.itemId !== id)
    console.log(newItems, 'newItems')
    setItems(newItems)
  }




  //  setItems(newItems)
  const getCategories = () => {
    try {

      HttpComponent({
        method: 'GET',
        url: `/api/listByCategories?limit=100&page=1&status=pending&consumable=true`,
        body: null,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'dataaaaaaaaaaaa')

        if (data.status === 200 || data.status === 201) {
          let categories = data?.response?.categories
          setProductCategories(categories)
        }

      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getCategories()
  }, [])
  console.log(category, 'category ===>>>>>>')
  const getProducts = () => {

    try {

      HttpComponent({
        method: 'GET',
        url: `/api/listByProducts?limit=100&page=1&status=pending&consumable=true&categoryId=${categoryId}`,
        body: null,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'dataaaaaaaaaaaa')

        if (data.status === 200 || data.status === 201) {
          let products = data?.response?.products
          console.log(products, 'products')
          setProducts(products)
        }

      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getProducts()
  }, [categoryId])

  const calculateTotalPrice = () => {
    let getTotalPrice = 0
    getTotalPrice = items.reduce((acc, item) => {
      return acc + parseInt(item.itemAmount)
    }, 0)
    let totalItemsCount = items.length
    setItemsCount(totalItemsCount)

    setTotalPrice(getTotalPrice)
  }
  useEffect(() => {
    calculateTotalPrice()
  }, [items])


  const handleNextRaisePayment = () => {
    let newItems = items.map((item) => {
      return {
        productId: item?.itemId,
        productName: item?.itemName,
        productPrice: item?.itemAmount,
        quantity: item?.itemQuantity,
        accountName: item?.accountName,
        accountId: item?.accountId,
        price: parseInt(item?.itemAmount) / parseInt(item?.itemQuantity)
      }
    })
    const data = {
      payeeId: payeeId,
      payeeName: selectedPayee,
      payeeAccount: selectedAccount,
      items: newItems,
      totalPrice: totalPrice,
      itemsCount: itemsCount
    }
    console.log(data, 'data')
    localStorage.setItem('otherPayments', JSON.stringify(data))

    navigate('/payables/otherpayments?preview')
  }

  const handleBack = () => {
    localStorage.removeItem('otherPayments')
    navigate(-1)
  }

  const buttonCheckState = () => {
    if (payeeId !== '' && selectedAccount !== '' && items.length > 0) {
      setButtonState(true)
    }
    else {
      setButtonState(false)
    }
  }
  console.log(buttonState, 'buttonState')
  console.log(payeeId !== '', 'payeeId', selectedAccount !== '', 'selectedAccount', items.length > 0, 'items');
  useEffect(() => {
    buttonCheckState()
  }, [payeeId, selectedAccount, items])
  const [showUpload, setShowUpload] = useState(false)
  const imageUploader = useRef(null);
  const uploadedImage = useRef(null);
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState()
  const [img, setImg] = useState(null);
  const logoImg = localStorage.getItem('logoImage');
  useEffect(() => {
    setImg(localStorage.getItem('logoImage'));
  }, []);



  const handleFileInputChange = (e) => {
    const [file] = e.target.files;
    console.log("file", e.target.files)
    console.log("uploadedImage", uploadedImage)
    console.log("imageUploader", imageUploader)
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      const selectedFile = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      }
      reader.readAsDataURL(file);
      setFile(file);
      console.log('file....', file)
      setSelectedFile(selectedFile);
    }
  };

  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const handleCategoryClick = (category) => {
    setActiveCategoryId(category.categoryId);
    setCategoryMethod(category);
};
const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
};

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileInputChange(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const [receipt, setReceipt] = useState('')

  const uploadFile = () => {
    console.log("file", file)
    setImg("");
    // localStorage.setItem('logoImage', uploadedImage.current.src); // clear ths image
    localStorage.setItem('logoImage', "");
    localStorage.setItem('receipt', selectedFile);

    console.log(uploadedImage.current.src)
    console.log(img)
    setReceipt(selectedFile)


    setTimeout(() => {
      setShowUpload(false)
      let childData = { file: selectedFile }
      props.getFile(childData)

    }, 1000);
  }

  console.log(selectedFile, 'selectedFile')
  console.log(props, 'Props')










  return (
    <Grid container>
      {showUpload ? <>
        <Grid>
          <Grid item mt={1}>
            <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }} onClick={() => navigate(-1)}> <ArrowBackIos /> Upload Image</Typography>
          </Grid>
          <Grid container mt={3}>
            <Grid item>
              <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                {breadcrumbsUpload}
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid mt={3}>
            <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#000", margin: " 30px 53px 20px 68px" }}>Upload your Receipt</Typography>

          </Grid>
          <Grid >
            <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", margin: " 20px 24px 20px 39px" }}>Have a receipt? Upload it now!</Typography>
          </Grid>

          <Grid sx={{ width: "350px", height: "400px", margin: "20px 792px 20px 40px", padding: "8px 10px 10px", borderRadius: "12px", border: " solid 1px #e0e0e0", backgroundColor: "#fff" }}>
            <Box sx={{ borderRadius: "6px", border: "solid 1px #e1e1e1", backgroundColor: "rgba(3, 37, 65, 0.02)", display: "flex", flexDirection: "column", justifyContent: "center", }}>
              {!logoImg ? (
                <Box sx={{ display: "flex", alignSelf: "center", flexDirection: "column", justifyContent: "center", width: "15.375rem", height: "24.269rem", padding: "20px 16.5px 46.3px 15.5px" }}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <Box sx={{ alignSelf: "center", marginY: 1 }}>
                    <img style={{
                      width: "15.375rem", height: "10.269rem", objectFit: "contain", display: "flex", flexDirection: "column", justifyContent: "center",



                    }}
                      onClick={() => imageUploader.current.click()}
                      alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                  </Box>
                  {file === null ? (
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                      <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                        Drag or drop your file here
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                      <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                        {file?.name}
                      </Typography>
                    </Box>
                  )}
                  {/* {!logoImg==='' ? null : (
                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                      <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                        Drag or drop your file here
                      </Typography>
                    </Box>
                  )} */}

                  <input
                    type="file"
                    accept="image/*"
                    multiple="false"
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                    ref={imageUploader}
                  />


                </Box>
              ) : (
                <Box sx={{ display: "flex", alignSelf: "center", flexDirection: "column", justifyContent: "center", width: "15.375rem", height: "24.269rem", padding: "20px 16.5px 46.3px 15.5px" }}
                >
                  <img style={{ width: '56.5px', height: '56.5px', objectFit: 'contain' }} onClick={() => imageUploader.current.click()} alt="Upload" src={img ? logoImg : icon} ref={uploadedImage} />
                </Box>


              )}

            </Box>

          </Grid>
          <Grid sx={{ margin: "20px 792px 20px 40px", }} >
            <Grid sx={{ display: "flex", flexDirection: "row", }}>
              <CheckCircleOutlineIcon sx={{ color: "#17AE7B", marginRight: "10px" }} />
              <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", }}>  Accepted file formats: .png or .jpg</Typography>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
              <CheckCircleOutlineIcon sx={{ color: "#17AE7B", marginRight: "10px" }} />
              <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", }}>  Max. File size: 1MB</Typography>
            </Grid>
          </Grid>
          <Grid sx={{ margin: "20px 792px 20px 40px" }}>
            {file === null ? (
              <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", borderRadius: "12px", width: "350px", height: "50px", textTransform: "none", fontSize: "16px", fontWeight: 600, }} onClick={() => imageUploader.current.click()}>
                Browse Files {logoImg}
              </Button>
            ) : (
              <Button variant="contained" style={{ backgroundColor: "#032541", color: "#fff", borderRadius: "12px", width: "350px", height: "50px", textTransform: "none", fontSize: "16px", fontWeight: 600, }} onClick={() => { uploadFile(); setShowUpload(false); }}>
                Upload File
              </Button>
            )}
          </Grid>
          {/* <Grid sx={{ margin: "20px 792px 20px 40px", }} >

            <Button disabled={logoImg ? true : false} variant="contained" style={{ backgroundColor: !logoImg ? "#032541" : "#707070", color: "#fff", borderRadius: "12px", width: "350px", height: "50px", textTransform: "none", fontSize: "16px", fontWeight: 600, }} onClick={() => {
              uploadFile()
              setShowUpload(false)
            }}>Upload File</Button>
          </Grid> */}

        </Grid>
      </> :
        <>
          {addItemesModal ?
            <>
              <Grid item mt={1}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }} onClick={() => navigate(-1)}> <ArrowBackIos /> Add Item</Typography>
              </Grid>
              <Grid container mt={3}>
                <Grid item>
                  <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbsCategories}
                  </Breadcrumbs>
                </Grid>
              </Grid>




              <Grid item mt={3} >
                <Grid item mt={3}>
                  <Grid item mt={3}>
                    <Box>
                      <CustomSearchInput value={searchCategorie} onChange={(e) => setSearchCategories(e.target.value)} name={"searchCategorie"} placeholder={"Search Category"} />
                    </Box>
                    <Box>
                      <h4 style={{ fontSize: '18px', fontWeight: '600', marginTop: '20px', marginLeft: '20px' }}>Product Categories</h4>
                    </Box>
                  </Grid>
                </Grid>
                <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1, marginY: 4, justifyContent: "space-between", width: "100%" }}>
                  <Box component="div" sx={{ marginTop: "10px", minWidth: "600px" }}>
                    {productCategories?.map((category) => {
                      const isActive = category.categoryId === activeCategoryId;
                      const isExpanded = expanded === category?.categoryId;
                      const arrowColor = isActive ? 'white' : 'black';
                      const backgroundColor = isActive ? '#032541' : 'transparent';
                      const textColor = isActive ? 'white' : 'black';

                      return (
                        <Accordion key={category.categoryId} style={{ backgroundColor, color: textColor }} expanded={isExpanded} onChange={handleAccordionChange(category?.categoryId)}>
                          <AccordionSummary expandIcon={<ArrowForwardIos style={{ color: arrowColor }} />} onClick={() => handleCategoryClick(category)}>
                            <Typography>{category.category}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* Content for each category */}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                    {/* {productCategories?.map((category) => {
                      return (
                        <Accordion key={category?.categoryId}>
                          <AccordionSummary expandIcon={<ArrowForwardIos />} onClick={() => setCategoryMethod(category)}>
                            <Typography>{category?.category}</Typography>
                          </AccordionSummary>
                          <AccordionDetails></AccordionDetails>
                        </Accordion>
                      );
                    })} */}
                  </Box>

                  <Box component="div" sx={{ minWidth: "800px", marginLeft: "20px" }}>
                    {category !== "" ?
                      <>
                        <Box style={{ border: "1px solid #e6e6e6" }}>
                          <Box component="div" sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginX: 1, width: "800px", height: " 50px", margin: "0 0 11px 16px", padding: " 9px 15px 10px 10px", borderRadius: "10px", backgroundColor: " rgba(112, 112, 112, 0.05)" }}>
                            <Box><span style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Products :{itemsCount} </span></Box>
                            <Box><span style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Total {numberFormat(totalPrice)}</span></Box>
                          </Box>
                          <CustomTable data={products} columns={productColumns} rowsPerPage={10} />
                          <Box component="div" sx={{ minWidth: "800px", marginLeft: "20px" }}>
                            <Box sx={{ display: "flex", justifyContent: "end", }}>
                              <Box>
                                <Button variant="contained" style={{ backgroundColor: "#fff", border: "1px solid #dc3545", width: "146px", height: "45px", color: "#000", textTransform: "none", marginRight: "20px" }} onClick={() => setAddItemesModal(false)}>Cancel</Button>
                                <Button variant="contained" style={{ backgroundColor: "#032541", width: "146px", height: "45px", color: "#ffffff", textTransform: "none" }} onClick={() => setAddItemesModal(false)}>Add</Button>
                              </Box>
                            </Box>
                          </Box>

                        </Box>
                      </> : ""}

                  </Box>
                </Box>

              </Grid>




            </> :
            <Grid>

              <Grid container>
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <Grid item mt={1}>
                  <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Raise Payment</Typography>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item>
                  <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                  </Breadcrumbs>
                </Grid>
              </Grid>
              <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, fontSize: "18px", color: "#032541" }}>Payee Details</Typography>
              </Grid>

              <Grid container mt={3}>

                <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1 }}>
                  <Box component="div" sx={{ display: "flex", marginTop: "10px" }}>
                    <div>
                      <TextField
                        id="outlined-select-payee"
                        label="Select Payee"
                        value={selectedPayee}
                        required
                        onClick={openMenu}
                      />
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                      >
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Search Payee..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={searchText}
                          onChange={searchPayee}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />

                        {filteredPayees.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() => handlePayeeSelect(option.value, option.label)}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </Box>
                  {/* <Box component="div" sx={{ display: "flex", marginTop: "10px" }}>
                    <div>
                      <TextField
                        id="outlined-select-payee"
                        label="Select Payee"
                        // defaultValue={selectedPayee}
                        value={selectedPayee}
                        required
                        onClick={openMenu}
                      />
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                      > */}
                  {/* <TextField
                  label="Search Payee"
                  onChange={(e) => setSearchText(e.target.value)}
                /> */}
                  {/* <CustomSearchField value={searchText} onChange={seaqrchPayee} name={"searchText"} placeholder={"search Payee"} /> */}
                  {/* <CustomTextField value={searchText} onChange={seaqrchPayee} name={"searchText"} placeholder={"search Payee"}  /> */}

                  {/* {payees?.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            onClick={() => handlePayeeSelect(option.value, option.label)}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </Box> */}
                  <Box component="div" sx={{ marginLeft: 1 }}>
                    <CustomSelectField value={selectedAccount} name={"selectedAccount"} placeholder={"Select Account"} onChange={setSelectedAccountMethod} options={payeeAccountsOptions} />

                  </Box>
                </Box>
              </Grid>
              <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between" }}>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                  <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Invoice Item</Typography>
                </Box>
                <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                  <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", cursor: "pointer" }} onClick={() => setAddItemesModal(true)} > <AddCircleIcon style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }} /> Add Items</Typography>
                </Box>
              </Box>

              {/* items have something */}
              <Grid mt={3}>
                <Box component="div" sx={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between", backgroundColor: "rgba(112, 112, 112, 0.05)", padding: "10px" }}>
                  <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Items : {itemsCount}</Typography>
                  </Box>
                  <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }} >Total: KES  : {numberFormat(totalPrice)}</Typography>
                  </Box>
                </Box>
                <Box mt={3}>
                  {items?.length <= 0 ?
                    <Box component="div" style={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-center", backgroundColor: "rgba(112, 112, 112, 0.05)" }}>
                      <p style={{ fontFamily: "Poppins", fontSize: "14px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", linHeight: "normal", letterSpacing: "normal", textAlign: "center", color: "#707070", padding: "40px", alignItems: "center" }}>Add items to invoice and preview them here!</p>
                    </Box>
                    :


                    <Table style={{ width: "100%" }}>
                      <TableBody style={{ width: "100%" }}>

                        {items?.map((item) => (
                          <TableRow
                            hover
                            key={item?.itemId}

                          >

                            <TableCell>{item?.itemName}</TableCell>
                            <TableCell>{item?.itemAmount}</TableCell>
                            <TableCell>
                              {/* <EditIcon style={{ color: "#032541", cursor: "pointer", marginRight: "10px" }} /> */}
                              <DeleteIcon style={{ color: "#DC3545", cursor: "pointer" }} onClick={() => { deleteItem(item?.itemId) }} />
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                  <Divider style={{ width: "100%", color: "#707070" }} />
                </Box>
              </Grid>
              {receipt ? <>
                <Box component="div" style={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between", height: "50px", padding: "10px 10px 10px 0px", border: "1.5px dotted #707070", marginTop: "10px" }}>
                  <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", paddingLeft: "10px" }}>Attached Receipt</Typography>
                  </Box>

                  <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", cursor: "pointer" }} >receipt.png</Typography>
                    <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070", cursor: "pointer" }} ><CloseOutlinedIcon onClick={() => {
                      setReceipt("")
                      localStorage.setItem("receipt", "")
                    }} /></Typography>

                  </Box>

                </Box>

              </>
                : <>
                  <Box component="div" style={{ display: "flex", flexDirection: "row", marginX: 1, justifyContent: "space-between", height: "50px", padding: "10px 10px 10px 0px", border: "1.5px dotted #707070", marginTop: "10px" }}>
                    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginRight: 1 }}>
                      <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", paddingLeft: "10px" }}>Attach an Image</Typography>
                    </Box>

                    <Box component="div" sx={{ display: "flex", flexDirection: "row", marginRight: 1 }}>
                      <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541", cursor: "pointer" }} onClick={() => setShowUpload(true)} >Upload File</Typography>

                    </Box>

                  </Box>
                </>
              }

              <Box sx={{ justifyContent: "end", display: "flex", width: "100%", marginTop: "10px" }}>
                <Button variant="contained" style={{ backgroundColor: "#dc3545", color: "#ffffff", width: "140px", textTransform: "none", marginRight: "30px" }} onClick={() => handleBack()}>Cancel</Button>
                {/* {buttonState ? */}
                <Button variant="contained" disabled={!buttonState} style={{ backgroundColor: buttonState ? "#032541" : "rgba(3, 37, 65, 0.4)", color: "#ffffff", width: "140px", textTransform: "none", }} onClick={() => handleNextRaisePayment(false)}>Add Invoice</Button>
                {/* : null} */}
              </Box>







            </Grid>

          }

        </>
      }





    </Grid >
  )
}

export default RaisePaymentOtherPayments