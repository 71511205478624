import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { customerstyles } from "./styles";
import { Breadcrumbs, Button, Grid } from "@mui/material";
import { ArrowBackIos, NavigateNext, SaveAlt, Send } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import { CustomDate, CustomizedDate } from "./customDate";
import StatementPreveiw from "./statementpreview";
const baseUrl = process.env.REACT_APP_BASE_URL;
const getCustomerDetailsUrl = baseUrl + "/api/get_customer_by_number";

const Statements = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  // FILTER BY DATE;
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  // console.log('dateFrom ======<><>', dateFrom, dateTo);
  // useEffect(() => {
  //   // if(dateFrom && dateTo)
  //   // getFilteredData(dateFrom, dateTo);
  // }, [dateFrom, dateTo, id]);

  const getCustomerStatements = () => {
      HttpComponent({
        method: "GET",
        url: `/api/filterCustomerTransactionByDate?customerId=${id}&fromDate=${dateFrom}&toDate=${dateTo}`,
        body: null,
        token: localStorage.getItem("X-Authorization"),
      })
        .then((data) => {
          console.log(data);
          setPageState({ ...pageState, isLoading: false, data: data.response.transaction, count: data.response.count });
        })
        .catch((e) => {
          console.log(e.message);
        });
  };

  useEffect(() => {
    getCustomerStatements();
  }, [id, dateFrom, dateTo]);

  const [customerInfo, setCustomerInfo] = useState({
    customerName: "",
    dayCreated: "",
    customerType: "",
    adress: "",
    paymentType: "",
    currentBalance: "",
    totalCredited: "",
    totalexpenses: "",
    email: "",
    phone: "",
    businessName: "",
    limit: 0,
  });

  const getCustomerDetails = async () => {
    try {
      const customerDetailsResponse = await fetch(getCustomerDetailsUrl + `?customerId=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (customerDetailsResponse.status === 200) {
        const customerDetailsData = await customerDetailsResponse.json();

        const customerData = customerDetailsData?.data[0];

        setCustomerInfo({
          ...customerInfo,
          customerName: customerData?.firstName + " " + customerData?.lastName,
          dayCreated: DateFormatter(customerData?.date),
          customerType: customerData?.customerType,
          adress: customerData?.customerAddress,
          paymentType: customerData?.paymentType,
          currentBalance: !customerData?.totalBalance ? 0 : customerData?.paymentType === "Normal" ? "N/A" : customerData?.totalBalance,
          totalCredited: customerData?.paymentType === "Normal" ? "N/A" : customerData?.totalCredits,
          totalexpenses: customerData?.totalExpense,
          email: customerData?.email,
          phone: customerData?.phone,
          businessName: customerData?.businessName,
          limit: customerData?.limiti,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, [id]);

  // conditionally render components;

  const [showCustomerStatements, setShowCustomerStatements] = useState(true);
  // const [showCustomerStatementPreview, setShowCustomerStatementPreview] = useState(false)


  useEffect(() => {

  }, [showCustomerStatements])

  return (
    <div style={customerstyles.universalStyle}>

      {
        showCustomerStatements && <div>
          <Grid container width={"100%"} style={{ margin: "0px 0px 10px 0px" }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item>
              <span style={customerstyles.previewStatement}>
                {" "}
                <ArrowBackIos onClick={() => navigate(`/customerDetails/${id}`)} style={{ color: "#032541" }} /> Statement{" "}
              </span>
            </Grid>

            <Grid item>


              <Button style={{ margin: "0px 10px 0px 0px", backgroundColor: "#f5f5f5", color: "#032541" }} startIcon={<SaveAlt onClick={() => setShowCustomerStatements(false)} />}>

              </Button>
            </Grid>
          </Grid>

          <Grid container width={"100%"} style={{ margin: "0px 0px 10px 0px" }}>
            <Grid item>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                <span>Dashboard</span>
                <span>Customers</span>
                <span style={{ color: "red" }}>Statements</span>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container width={"100%"} style={{ margin: "0px 0px 10px 0px", backgroundColor: "#f5f5f5", padding: "10px 10px 0px 10px" }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Customer Name</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.customerName}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}> Customer Type</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.customerType}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Payment type</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.paymentType}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsPara}>Amount</p>
              <p style={customerstyles.customerDetailsParaNormal}>{customerInfo?.limit}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsParaExpense}>Total Spent</p>
              <p style={customerstyles.customerDetailsParaNormalExpense}>{customerInfo?.totalexpenses}</p>
            </Grid>

            <Grid item>
              <p style={customerstyles.customerDetailsParaBalance}>Balance</p>
              <p style={customerstyles.customerDetailsParaNormalBalance}>{customerInfo?.currentBalance}</p>
            </Grid>
          </Grid>

          <Grid container style={{ margin: "0px 0px 10px 0px" }}>
            <Grid item>
              <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
            </Grid>
          </Grid>

          <DataGrid
            style={customerstyles.dataGridStyling}
            columns={[
              { field: "transactionTime", headerName: "Date", flex: 1 },
              { field: "customerName", headerName: "Customer Name", flex: 1 },
              { field: "servedBy", headerName: "Served By", flex: 1 },
              { field: "amount", headerName: "Amount(KES)", flex: 1 },
              { field: "customerBalance", headerName: "Balance(KES)", flex: 1 },
            ]}
            rows={pageState?.data?.map((statement) => ({
              id: statement?.transactionId,
              transactionTime: DateFormatter(statement.transactionTime),
              customerName: statement?.customerName,
              servedBy: statement?.servedBy,
              amount: statement?.amount,
              customerBalance: statement?.customerBalance,
            }))}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            rowCount={pageState.count}
            loading={pageState.isLoading}
            pagination
            page={pageState.page - 1}
            pageSize={dataGridPageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // checkboxSelection
            // disableRowSelectionOnClick={true}
            onSelectionModelChange={(name) => {
              //   setSupplierToBeSuspendedObj(name)
            }}
          />
        </div>
      }

      {
        !showCustomerStatements && <StatementPreveiw customerStatements={pageState?.data} customerId={id} setShowCustomerStatements={setShowCustomerStatements} />
      }

    </div>
  );
};

export default Statements;
