import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography } from "@mui/material";
import SchoolForm from "./StudentForm";
import StudentServicesForm from "./StudentServicesForm";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { useSelector } from 'react-redux';
let baseUrl = process.env.REACT_APP_BASE_URL;

const StudentSetup = (props) => {
    //console.log('props here heree', props)
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [disabled, SetDisabled] = useState(true);
    const [parentSetUp, setParentSetUp] = useState(true)
    const [schoolTypeName, setSchoolTypeName] = useState('')

    const GetSchoolTypeIdForBusiness = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            }
        });
        const data = await response.json();
        //console.log(data, 'School Type Id');
        if (response.status === 201) {
            setSchoolTypeName(data.data?.schoolType)


        }
    }
    useEffect(() => {
        GetSchoolTypeIdForBusiness();
    }, []);
   
    let dataObj = {}
    const [formData, setFormData] = useState({})

    useEffect(() => {
        if(schoolTypeName){
            if (schoolTypeName?.includes('University')) {
                dataObj = {
                    admissionNumber: "",
                    admissionDate: Date.now(),
                    firstName: "",
                    lastName: "",
                    gender: "",
                    info: "",
                    term: "",
                    courseName: "",
                    courseId: "",
                    dob: Date.now(),
                    email: "",
                    phone: "",
                    studentAsParent: true,
                    customerType:"individual",
                    paymentType:"Prepaid",
                    year: "",
                    id_number:""
                }
                // console.log('data here for university', dataObj)
    
                setFormData(dataObj)
            } else {
                dataObj = {
                    admissionNumber: "",
                    admissionDate: Date.now(),
                    firstName: "",
                    lastName: "",
                    gender: "",
                    grade: "",
                    info: "",
                    term: "",
                    stream:"",
                    boardingStatus:""
        
                }
                setFormData(dataObj)
            }
        }
    }, [schoolTypeName])

    //console.log("original form Data", formData);
   
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    return (
        <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {parentSetUp ?
                <Box component="div" sx={{ width: "100%", alignSelf: "center" }}>
                    <SuccessAlert
                        vertical="top"
                        horizontal="right"
                        onClose={() => setSuccessShow({ ...successShow, state: false })}
                        open={successShow.state}
                        message={successShow.message} />
                    <SchoolForm setView={props.setView} setParentSetUp={setParentSetUp} formData={formData} setFormData={setFormData} setDisabled={SetDisabled} />
                </Box>
                :
                <Box component="div" sx={{ width: "100%", alignSelf: "center" }}>
                    <StudentServicesForm setStudentSuccess={setSuccessShow} setParentSetUp={setParentSetUp} disabled={disabled} SetDisabled={SetDisabled} setStudentFormData={setFormData} setView={props.setView}  grade={formData?.grade}/>
                </Box>
            }

        </Box>
    )
}

export default StudentSetup;
