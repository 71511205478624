import { Box, Button, Grid, TextField } from '@mui/material'
import { useState, useEffect } from "react";
import HttpComponent from '../MakeRequest'
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
const styles = {

    addsupplierGridItems: {
        margin: "0px 0px 2% 0px",
        width: "100%",
    },
    addsupplierInputs: {
        width: "95%",
    },
}
const KcbDebitAccountSetUp = (props) => {
    const [debitAccounts, setDebitAccounts] = useState([])
    const [buttonAddAccountState, setButtonAddAccountState] = useState(false)
    const  [configId, setConfigId] = useState('')
    const [formData, setFormData] = useState({
        companyCode: "KE0010001",
        bankCode: "01",
        consumerSecret: "",
        consumerKey: "",
        accountName: "",
        accountNumber: "",
        bankName: "KCB",
    })
    const [showAddAccount, setShowAddAccount] = useState(false)
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const clearForm = () => {
        setFormData({
            companyCode: "KE0010001",
            bankCode: "01",
            consumerSecret: "",
            consumerKey: "",
            accountName: "",
            accountNumber: "",
            bankName: "KCB",
        })
        setConfigId('')
    }
    const getDebitAccounts = async () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getfunds_transfer_config?accountType=KCB`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    console.log(data.response.data)
                    setDebitAccounts(data.response.data)
                    if (data?.response?.data.length === 0) {
                        setShowAddAccount(true)
                    }

                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getDebitAccounts()
    }, [])

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const addAccount = async () => {
        console.log(formData)
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/addfunds_transfer_config`,
                body: formData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    console.log(data.response.data)
                    setSuccessShow({ state: true, message: "Account Added Successfully" })
                    setTimeout(() => {
                        setSuccessShow({ state: false, message: "" })
                    }, 1000)
                    setShowAddAccount(false)
                    clearForm()
                    getDebitAccounts()


                }
            })
        } catch (error) {

        }



    }

    const takeBankToBanks = (state) => {
        console.log(state)
        let data = {
            state: state,
            bankName: "KCB"
        }
        props.parentDebitAccountShowState(data)

    }
    const addAccountButtonState = () => {
        if (formData.accountName !== "" || formData.accountNumber !== "" || formData.consumerKey !== "" || formData.consumerSecret !== "") {
            setButtonAddAccountState(true)
        } else {
            setButtonAddAccountState(false)
        }

    }
    useEffect(() => {
        addAccountButtonState()
    }, [formData])

    const editControl = (account) => {
        let accountDetails = account
        console.log(accountDetails, 'accountDetails')
        setFormData({
            companyCode: "KE0010001",
            bankCode: "01",
            consumerSecret: accountDetails?.consumerSecret,
            consumerKey: accountDetails?.consumerKey,
            accountName: accountDetails?.accountName,
            accountNumber: accountDetails?.accountNumber,
            bankName: "KCB",
        })
        setConfigId(accountDetails?._id)
        setShowAddAccount(true)
    }
   

    const updateAccount = async () => {
        let updateData = {
            companyCode: formData.companyCode,
            bankCode: formData.bankCode,
            consumerSecret: formData.consumerSecret,
            consumerKey: formData.consumerKey,
            accountName: formData.accountName,
            accountNumber: formData.accountNumber,
            bankName: formData.bankName,
            configId: configId
        }
        console.log(updateData?.configId, 'updateData')
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/updatefunds_transfer_config`,
                body: updateData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    console.log(data.response.data)
                    setSuccessShow({ state: true, message: "Account Added Successfully" })
                    setTimeout(() => {
                        setSuccessShow({ state: false, message: "" })
                    }, 1000)
                    setShowAddAccount(false)
                    clearForm()
                    getDebitAccounts()


                }else{
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        setErrorShow({ state: false, message: "" })
                    }, 1000)
                }
            })
        } catch (error) {

        }

    }
    const closeAddForm = () => {
        setShowAddAccount(false)
        clearForm()
    }

    const deleteAccount = async (configId) => {
        console.log(configId, 'configId')
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/deletefunds_transfer_config`,
                body: { configId: configId },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    console.log(data.response.data)
                    setSuccessShow({ state: true, message: "Account Deleted Successfully" })
                    setTimeout(() => {
                        setSuccessShow({ state: false, message: "" })
                    }, 1000)
                   
                    getDebitAccounts()
                }else{
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        setErrorShow({ state: false, message: "" })
                    }, 1000)
                }
            })
        }catch (error) {

        }
    }


    return (
        <Box>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

            <Box style={{ padding: "0px 40px 0px 40px" }}>
                <Box style={{}}>
                    <span style={{ color: "#707070", fontSize: "16px" }}>Setup you KCB Bank Debit accounts and pay your suppliers, vendors and <br /> other payments!</span>
                </Box>
                {showAddAccount ? <>
                    <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Grid item style={styles.addsupplierGridItems}>
                                <TextField name="accountName" value={formData.accountName} onChange={handleFormChange} style={styles.addsupplierInputs} label="Account Name" variant="outlined" />
                            </Grid>
                            <Grid item style={styles.addsupplierGridItems}>
                                <TextField disabled name="accountName" value={formData.companyCode} onChange={handleFormChange} style={styles.addsupplierInputs} label="Company Code" variant="outlined" />
                            </Grid>
                        </Box>



                    </Box>
                    <Grid item style={styles.addsupplierGridItems}>
                        <TextField name="accountNumber" value={formData.accountNumber} onChange={handleFormChange} style={styles.addsupplierInputs} label="Account Number" variant="outlined" />
                    </Grid>
                    <Grid item style={styles.addsupplierGridItems}>
                        <TextField name="consumerKey" value={formData.consumerKey} onChange={handleFormChange} style={styles.addsupplierInputs} label="KCB Key" variant="outlined" />
                    </Grid>
                    <Grid item style={styles.addsupplierGridItems}>
                        <TextField name="consumerSecret" value={formData.consumerSecret} onChange={handleFormChange} style={styles.addsupplierInputs} label="Consumer Secret" variant="outlined" />
                    </Grid>
                    <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <Button style={{ width: "100px", height: "45px", backgroundColor: "#fff", color: "#dc3545", fontSize: "12px", textTransform: "none", border: "solid 1px #dc3545", marginRight: "20px", }} onClick={() => closeAddForm()  }>Cancel</Button>
                        {configId !== "" ? <Button disabled={!buttonAddAccountState} style={{ width: "100px", height: "45px", backgroundColor: buttonAddAccountState ? "#032541" : "#9AA9B2", color: "#fff", fontSize: "12px", textTransform: "none", border: "solid 1px #032541" }} onClick={() => updateAccount()}>Update</Button> :
                            <Button disabled={!buttonAddAccountState} style={{ width: "100px", height: "45px", backgroundColor: buttonAddAccountState ? "#032541" : "#9AA9B2", color: "#fff", fontSize: "12px", textTransform: "none", border: "solid 1px #032541" }} onClick={() => addAccount()}>Add Account</Button>
                        }
                    </Box>


                </> :
                    <>
                        {
                            debitAccounts.map((account, index) => (
                                <Box style={{ width: "600px", height: "75px", border: "solid 1px #bec5d1", padding: "15px 17px 15px 25px", borderRadius: "4px", marginTop: "20px" }}>
                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <span style={{ fontSize: "14px", color: "#000", }}>{account?.accountName}</span>
                                            <span style={{ fontSize: "14px", color: "#000", }}>{account?.accountNumber}</span>
                                        </Box>
                                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <Button style={{ width: "100px", height: "30px", backgroundColor: "#fff", color: "#000", fontSize: "12px", textTransform: "none", border: "solid 1px #032541", marginRight: "10px" }} onClick={() => editControl(account)}>Edit</Button>
                                            <Button style={{ width: "100px", height: "30px", backgroundColor: "#fff", color: "#000", fontSize: "12px", textTransform: "none", border: "solid 1px #dc3545" }} onClick={() => deleteAccount(account?._id)  }>Remove</Button>
                                        </Box>
                                    </Box>


                                </Box>
                            ))
                        }
                        <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                            <Button style={{ width: "100px", height: "45px", backgroundColor: "#fff", color: "#032541", fontSize: "12px", textTransform: "none", border: "solid 1px #032541", marginRight: "20px" }} onClick={() => takeBankToBanks(false)}>Done</Button>
                            <Button style={{ width: "100px", height: "45px", backgroundColor: "#032541", color: "#fff", fontSize: "12px", textTransform: "none", border: "solid 1px #032541" }} onClick={() => setShowAddAccount(true)}>Add Account</Button>

                        </Box>
                    </>
                }



            </Box>
        </Box>
    )
}

export default KcbDebitAccountSetUp