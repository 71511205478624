import React, {useState, useEffect} from 'react';
import {Box, Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import CustomTextFieldSmall from "./CustomTextFieldSmall";
import {useDispatch, useSelector} from "react-redux";
import HttpComponent from "./MakeRequest";
import {SuccessAlert} from "../snackBar Alerts/successAlert";
import {ErrorAlert} from "../snackBar Alerts/errorAlert";
import Modal from '@mui/material/Modal';
import modalIcon from './Images/madalIcon.svg';
import warningIcon from './Images/warning-remove-icn.svg'
import { handleIsMpesaSetup, setMpesaPaymentData, setMpesaStatus } from '../../features/paymentOptionsSlice';

const EditMpesa = (props) => {
  let {setShowDarajaSetup,setSuccessShow,setErrorShow} = props
    const [pageStatus, setPageStatus] = useState("initial")
    const ExPortalForm = localStorage.getItem('exPortalForm')
    const [checked, setChecked] = React.useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const[showDeleteModal, setShowDeleteModal] = useState(false);
    const [editData, setEditData] = useState([]);

    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };


    const noStyle = {
        "borderRadius":"4px",
        "border":"solid 1px #dc3545",
        "color":"#dc3545",
        textTransform : "none",
        textDecoration : "none"
    }

    const buttonWidthHeight = {
        width:"9.063rem",
        height:"3.125rem"
    }

    const yesStyle = {
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        textTransform : "none",
        textDecoration : "none",
        color:"white",
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const skipModalStyle = {
        position: 'absolute',
        display:'flex',
        flexDirection:'column',
        textAlign:'center',
        alignItems:'center',
        width: '460px',
        height: '330px',
        padding: '35px',
        borderRadius: '12px',
        border: 'solid 1px #707070',
        backgroundColor: '#fff',
        top: '50%',
        left: '58%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
      };

      const deleteModalStyle = {
        position: 'absolute',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        width: '460px',
        height: '330px',
        padding: '35px',
        borderRadius: '12px',
        border: 'solid 1px #707070',
        backgroundColor: '#fff',
        top: '50%',
        left: '58%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
      };

    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const [formData, setFormData] = useState({
        businessName:"",
        businessShortCode:"",
        consumerKey:"",
        consumerSecret:"",
        accountReference:"",
        passKey:"",
        thirdPartyCallback:""
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };


    const { userId ,X_Authorization } = useSelector((store) => store.user);
    const { mpesaData} = useSelector((store)  => store.payment);
    const dispatch = useDispatch()
    const token = localStorage.getItem('X-Authorization');
    // {
    //     "businessName": "Test Limited",
    //     "businessShortCode": "0000110",
    //     "consumerKey": "sv6zTGpu3TKqpu0k5kXyYM8PtjshI16XBBB",
    //     "consumerSecret": "AL5grB46Bz7goC0aBBB",
    //     "accountReference": "Test On Limited",
    //     "passKey":"f463d18174cb2ad5a2d7870b5ae1376b06cbcc686db80a0e330f9bccde141797BBB",
    //     "thirdPartyCallback":""
    // }

    // getting existing mpesa daraja payment data
    const getDarajaConfigDetails = () =>{
        HttpComponent({
            method: 'GET',
            url: `/api/v1/business_config/daraja/byBusiness?businessId=${businessId}`,
            body: null,
            token: X_Authorization
        }).then((data)=>{
            console.log("here is existing daraja config data",data);
            if(data.status === 201){
                // setEditData(data.response.data);
                setSuccessShow({
                    state:true,
                    message:"Mpesa Configurations Fetched Successfully"
                })
                console.log('mpesa payment data', mpesaData);
                dispatch(handleIsMpesaSetup(true));
                dispatch(setMpesaPaymentData(data.response.data));
                setShowDarajaSetup(false)
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
            setErrorShow({state:true, message:error.message})
        })
     }

     console.log(mpesaData);


    // save daraja config data 
    const handleMpesaUpdate = () =>{
        formData.thirdPartyCallback = "https://zed.swerri.io/api/stkCallback"
        HttpComponent({
            method: 'POST',
            url: `/api/v1/business_config/daraja/edit`,
            body: formData,
            token: X_Authorization
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 201){
                setSuccessShow({
                    state:true,
                    message:"Mpesa Configurations Updated Successfully"
                })
                // setMpesaData(data);
                console.log(mpesaData);
                dispatch(handleIsMpesaSetup(true));
                dispatch(setMpesaStatus(true));
                setShowDarajaSetup(false)
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
            setErrorShow({state:true, message:error.message})
        })
    }

    const businessId = localStorage.getItem('businessID');

     useEffect(() => {
        getDarajaConfigDetails(token);
      if (mpesaData) {
        mpesaData?.map((data) =>{
            const { businessName, businessShortCode, consumerKey, consumerSecret, accountReference, passKey, thirdPartyCallback} = data;
          console.log(data);
          formData.businessName = businessName
          formData.businessShortCode = businessShortCode
          formData.consumerKey = consumerKey
          formData.consumerSecret = consumerSecret
          formData.accountReference = accountReference
          formData.passKey = passKey
          formData.thirdPartyCallback = thirdPartyCallback
        })
      }
    }, [mpesaData, formData, token]);

  return (
    <>
    {/* delete modal */}
    <Box>
    <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={deleteModalStyle}>
            <Box sx={{ display:'flex', alignItems:'flex-start' }}>
                <Box>
                    <img src={warningIcon} alt="modalIcon"  style={{ 
                        width: '70px',
                        height: '70px',
                        objectFit: 'contain',
                        marginTop:'20px'
                    }}/>
                </Box>
                <Box sx={{ marginLeft:'39px' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ 
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 600,
                        fontStretch:' normal',
                        fontStyle:' normal',
                        lineHeight:' normal',
                        letterSpacing:' normal',
                        margin:'15px 0px',
                        color: '#032541'
                    }}>
                    Deactivate Mpesa Daraja ?
                    </Typography>
                    <Typography id="modal-modal-description" variant='p' style={{ 
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 'normal',
                        fontStretch:' normal',
                        fontStyle:' normal',
                        lineHeight:' normal',
                        letterSpacing:' normal',
                        margin:'15px 0px',
                        color: '#707070'
                    }}>
                    Are you sure you want to Deactivate Mpesa Daraja ? Your setup details will be saved and used when re-enabled.
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Button style={{ 
                    width: '125px',
                    height: '45px',
                    margin: '31px 0px 0 0px',
                    padding: '12.5px 42.4px 12.5px 44.6px',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    border:'1px solid #032541',
                    color: '#032541',
                    fontWeight:600
                }} onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                <Button style={{ 
                width: '125px',
                height: '45px',
                margin: '31px 0px 0 20px',
                padding: '12.5px 42.4px 12.5px 44.6px',
                borderRadius: '4px',
                backgroundColor: '#dc3545',
                color: '#fff',
                fontWeight:600
             }} onClick={() => {
                setShowDeleteModal(false)
                setShowDarajaSetup(false) 
                }}>Deactivate</Button>
            </Box>
        </Box>
    </Modal>
    </Box>
    <div>
      <Box component="div" sx={{display:"flex", flexDirection:"column"}}>
                <Box component="div" sx={{display:"flex"}}>
                    <Box component="div" sx={{marginX:0.5}}>
                        <CustomInputLabel required={true} label={"Business Name"}/>
                        <CustomTextFieldSmall value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
                    </Box>
    
                    <Box component="div" sx={{marginLeft:2}}>
                        <CustomInputLabel required={true} label={"Business Shortcode"}/>
                        <CustomTextFieldSmall value={formData.businessShortCode} onChange={handleInputChange} name={"businessShortCode"} placeholder={"Short Code"} />
                    </Box>
                </Box>
                <Box component="div" sx={{marginX:1}}>
                    <CustomInputLabel required={true} label={"Consumer Key"}/>
                    <TextField
                        name={"consumerKey"}
                        value={formData.consumerKey}
                        onChange={handleInputChange}
                        placeholder={"Consumer Key"}
                        variant="outlined"
                        margin="dense"
                        sx={{marginBottom:2}}
                        InputProps={{
                            sx: {
                                width: '31.063rem',
                                height: '3.438em',
                                fontSize:"0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1"
                                }
                            }
                        }}
                    />
                </Box>
                <Box component="div" sx={{display:"flex"}}>
                    <Box component="div" sx={{marginX:0.5}}>
                        <CustomInputLabel required={true} label={"Consumer Secret"}/>
                        <CustomTextFieldSmall value={formData.consumerSecret} onChange={handleInputChange} name={"consumerSecret"} placeholder={"Consumer Secret"} />
                    </Box>
                    <Box component="div" sx={{marginLeft:2}}>
                        <CustomInputLabel required={true} label={"Account Reference"}/>
                        <CustomTextFieldSmall value={formData.accountReference} onChange={handleInputChange} name={"accountReference"} placeholder={"Account Reference"} />
                    </Box>
                </Box>
                <Box component="div" sx={{marginX:1}}>
                    <CustomInputLabel required={true} label={"Pass Key"}/>
                    <TextField
                        name={"passKey"}
                        value={formData.passKey}
                        onChange={handleInputChange}
                        placeholder={"Pass Key"}
                        variant="outlined"
                        margin="dense"
                        sx={{marginBottom:2}}
                        InputProps={{
                            sx: {
                                width: '31.063rem',
                                height: '3.438em',
                                fontSize:"0.875rem",
                                "& fieldset": {
                                    borderColor: "#bec5d1"
                                }
                            }
                        }}
                    />
                </Box>
                <Box component="div" sx={{}}>
                    <Checkbox onChange={handleCheckBoxChange} checked={checked} inputProps={{ 'aria-label': 'controlled' }} />
                    <span>By ticking, you are agreeing to the terms and conditions.</span>
                </Box>
    
                {!ExPortalForm?.includes('true') ?
                <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                    <Box component="div" sx={{alignSelf:"center"}}>
                        <Button onClick={() => setShowDeleteModal(true)} sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                            Deactivate
                        </Button>
    
                        <Button hidden={!checked} onClick={handleMpesaUpdate} sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                            Update
                        </Button>
                    </Box>
                </Box>
                :
                <Box component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center", marginBottom:2}}>
                    <Box component="div" sx={{alignSelf:"center"}}>
                        <Button sx={{...noStyle,...buttonWidthHeight, marginX:1}}>
                            Deactivate
                        </Button>
    
                        <Button sx={{...yesStyle,...buttonWidthHeight,marginX:1}}>
                            Update
                        </Button>
                    </Box>
                </Box> }
            </Box>
    </div>
    </>
  )
}

export default EditMpesa