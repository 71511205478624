import React, { useEffect, useState } from "react";
import StockFunctions from "../stockEndpoints";
import CustomDataGrid from "../customDataGrid";

const StockOrderProducts = ({ categoryId, supplierBsNo, productValue }) => {
  //DataGrid Pagination
  const stockfunctions = new StockFunctions();
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  useEffect(() => {

    if (categoryId && productValue === "")
      stockfunctions.getProductsPerCategoryStockOrder(1, 1000, categoryId).then((data) => {
        if (data?.status === 200) {
          setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count });
        }
      });
  }, [categoryId, pageState?.page, dataGridPageSize, productValue]);


  useEffect(() => {
    if(productValue && categoryId)
    // searchSupplierProducts = (page, limit, categoryId, productName)
    stockfunctions.searchSupplierProducts(pageState?.page,dataGridPageSize, categoryId, productValue ).then((data) => {
      if(data?.status === 200){
        setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.data?.length });
      }
    })
  },[categoryId, pageState?.page, dataGridPageSize, productValue])

  const productColumns = [
    { field: "productName", headerName: "productName", flex: 1 },
    { field: "sellingPrice", headerName: "selling Price", flex: 1 },
    {
      field: "qtyRequested",
      headerName: " Qty requested",
      flex: 1,
      renderCell: (params) => {
        return <input style={{ outline: "none" }} type="number" onChange={(e) => updateQuantityRequested(params?.row, e)} />;
      },
    },
  ];

  const productsData = pageState?.data?.map((product) => ({
    id: product._id || product?.id,
    productName: product?.productName,
    sellingPrice: product?.sellingPrice,
    qtyRequested: 0,
    changed: false,
  }));

  const updateQuantityRequested = (stock, e) => {
    // setTimeout(() => {
      const value = Number(e.target.value);
      const stockId = stock?.id;
      const stockItems = [...pageState?.data];

      // get index;
      const currentIndex = stockItems.findIndex((stock) => stock._id === stockId);
      stockItems[currentIndex].qtyRequested = value * 1;
      stockItems[currentIndex].changed = true;

      setPageState({ data: stockItems });

      // check and update local storage;

      const localItems = JSON.parse(localStorage.getItem("stockOrderItems"));
      if (localItems) {
        const newLocalItems = [...localItems];

        // get Index of Changing item;
        const newIndex = newLocalItems.findIndex((stock) => stock._id === stockId);

        if (newIndex === -1) {
          newLocalItems.push(stockItems[currentIndex]);
        } else {
          newLocalItems[newIndex].qtyRequested = stockItems[currentIndex].qtyRequested;
          newLocalItems[newIndex].changed = stockItems[currentIndex].changed;
        }
        localStorage.setItem("stockOrderItems", JSON.stringify(newLocalItems));
      } else {
        localStorage.setItem("stockOrderItems", JSON.stringify(stockItems));
      }
    // }, 1000);
  };

  return (
    <div>
      <CustomDataGrid column={productColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={productsData} />
    </div>
  );
};

export default StockOrderProducts;
