import { Breadcrumbs, Grid, Typography, MenuItem, FormControl, InputLabel, Select, Stack } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DateFormatter from "../../../../utils/dateFormatter";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CustomSearchInput from "../../../School/CustomSearchInput";
import AutocompleteComponent from "../../../../common/autoComplete";
import HttpComponent from "../../../School/MakeRequest";
import ExportMenu from "../../../School/ExportMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomSelect from "../../../School/CustomSelectField";
import { currencyconverter } from "../../../../common/currencyconverter";
import { CustomDate } from "../../../customerAccounts/customerComponents/customDate";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}> Pocket Money Activation Transaction</Typography>,
]


let localCurrency = localStorage.getItem('localCurrency')

if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
}

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? "KES" : localCurrency, }).format(value);


export default function ZPMActivationFeeReport() {
    const [searchValue, setSearchValue] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [days, setDays] = useState([
        // { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
                setStartDate(yesterday);
                setEndDate(yesterday);
                break;
            case "Last 7 Days":
                const last7Days = new Date();
                setStartDate(new Date(last7Days.setDate(last7Days.getDate() - 7)));
                setEndDate(new Date());
                break;
            case "This Month":
                const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                setStartDate(firstDayOfMonth);
                setEndDate(lastDayOfMonth);
                break;
            case "Last Month":
                const firstDayLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                const lastDayLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Custom Range":
                setStartDate(new Date(dateFrom));
                setEndDate(new Date(dateTo));
                break;
            default:
                setStartDate('');
                setEndDate('');
                break;
        }
    }, [handleDaysChange, dateFrom, dateTo]);

    // useEffect(() => {
    //     const setLastSevenDays = () => {
    //         const result = [];
    //         for (let i = 0; i < 7; i++) {
    //             const d = new Date();
    //             d.setDate(d.getDate() - i);
    //             result.push(d.toLocaleDateString());
    //         }
    //         const lastSevenFirstStart = result[0];
    //         const lastSevenLastEnd = result[result.length - 1];
    //         setStartDate(lastSevenFirstStart);
    //         setEndDate(lastSevenLastEnd);
    //     };

    //     setLastSevenDays();
    // }, []);


    // date filter

    // useEffect(() => {
    //     switch (handleDaysChange) {
    //         case "Today":
    //             const today = new Date().toLocaleDateString();
    //             setStartDate(today);
    //             setEndDate(today);
    //             break;
    //         case "Yesterday":
    //             let date = new Date();
    //             const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
    //             console.log(yeseterday, 'yeseterday');
    //             setStartDate(yeseterday);
    //             setEndDate(yeseterday);
    //             break;
    //         case "Last 7 days":
    //             function Last7Days() {
    //                 var result = [];
    //                 for (var i = 0; i < 7; i++) {
    //                     var d = new Date();
    //                     result.push(d.setDate(d.getDate() - i));
    //                 }
    //                 const lastSevenFirst = new Date(result[0]).toLocaleDateString();
    //                 const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

    //                 setStartDate(lastSevenLast);
    //                 setEndDate(lastSevenFirst);
    //             }
    //             Last7Days();
    //             break;
    //         case "This Month":
    //             const date1 = new Date();
    //             const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
    //             const currentDay = new Date().toLocaleDateString();
    //             setStartDate(firstDay);
    //             setEndDate(currentDay);
    //             break;
    //         case "Last Month":
    //             const date2 = new Date();
    //             const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
    //             const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
    //             setStartDate(firstDayLastMonth);
    //             setEndDate(lastDayLastMonth);
    //             break;
    //         default:
    //             function defaultLast7Days() {
    //                 var result = [];
    //                 for (var i = 0; i < 7; i++) {
    //                     var d = new Date();
    //                     result.push(d.setDate(d.getDate() - i));
    //                 }
    //                 const lastSevenFirst = new Date(result[0]).toLocaleDateString();
    //                 const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

    //                 setStartDate(lastSevenLast);
    //                 setEndDate(lastSevenFirst);
    //             }
    //             defaultLast7Days();
    //             break;
    //     }
    // }, [handleDaysChange]);


    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    // function getAllBusiness() {
    //     try {
    //         HttpComponent({
    //             method: "GET",
    //             url: `/api/get_available_businesses?searchValue=${selectedValue}`,
    //             token: localStorage.getItem('X-Authorization')
    //         }).then((data) => {
    //             if (data?.status === 200) {
    //                 const foundBusiness = data?.response?.data?.map((business) => {
    //                     return {
    //                         name: business.businessName,
    //                         bsNo: business?.businessNumber,
    //                     }
    //                 })
    //                 setAllBusiness(foundBusiness)
    //             }
    //         })

    //     } catch (error) {

    //     }
    // }

    // useEffect(() => {
    //     getAllBusiness();
    // }, [selectedValue])
    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getSchoolName?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness();
    }, [selectedValue])

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    ///api/v1/getPocketMoneyActivationTransactions

    const fetchActivationReports = async () => {
        setPageState((old) => ({ ...old, isLoading: true }));

        const adjustedStartDate = new Date(startDate);
        adjustedStartDate.setUTCHours(0, 0, 0, 0);

        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setUTCHours(23, 59, 59, 999);

        try {
            const response = await HttpComponent({
                method: 'GET',
                url: `/api/v1/getPocketMoneyActivationTransactions?startDate=${adjustedStartDate.toISOString()}&endDate=${adjustedEndDate.toISOString()}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            });
            if (response.status === 200) {
                setPageState({ ...pageState, isLoading: false, total: response?.response?.count, data: response.response.data });
            }
        } catch (error) {
            console.error(error);
            setPageState((old) => ({ ...old, isLoading: false }));
        }
    };
    useEffect(() => {
        setHandleDaysChange("This Month");
    }, []);

    useEffect(() => {
        fetchActivationReports();
    }, [startDate, endDate, pageState?.page, dataGridPageSize, searchValue, selectedValue]);

    // function fetchActivationReports() {
    //     try {
    //         HttpComponent({
    //             method: 'GET',
    //             url: `/api/v1/getPocketMoneyActivationTransactions?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
    //             //body: null,
    //             token: localStorage.getItem('X-Authorization')
    //         }).then((data) => {
    //             console.log(data, 'yyyyy')
    //             if (data?.status === 200) {
    //                 setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data?.response.data })
    //             }
    //         })
    //     } catch (error) {

    //     }
    // }

    // useEffect(() => {
    //     fetchActivationReports()
    // }, [searchValue, dataGridPageSize, pageState?.page])


    const FeeActivationColumns = [
        { headerName: "transactionId", field: 'transactionId', healderAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Transaction ID</strong>) } },
        { headerName: "businessName", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Business Name</strong>) } },
        { headerName: "studentName", field: 'studentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Student/Parent Name</strong>) } },
        // { headerName: "itemNumber", field: 'itemNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Student ID</strong>) } },
        { headerName: "Phone No", field: 'PhoneNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Phone No</strong>) } },
        { headerName: "updatedAt", field: 'updatedAt', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => <Typography>{DateFormatter(params?.row?.updatedAt)}</Typography> },
        { headerName: "amount", field: 'amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} > Amount</strong>) }, renderCell: (params) => <Typography>{numberFormat(params?.row?.amount)}</Typography> },
    ]


    // exports

    const filedata = pageState?.data?.map((data) => {
        return {
            "Transaction ID": data?.transactionId,
            "BusinessName": data?.businessName,
            "Student Name": data?.studentName,
            "Phone No": data?.PhoneNo,
            "Date & Time": DateFormatter(data?.updatedAt),
            "Amount": currencyconverter(data?.amount),
        }
    })
    const fileHeaders = [["Transaction ID", "BusinessName", "Student Name", "Phone No", 'Date & Time', 'Amount']]
    const csvColumns = [
        { label: "Transaction ID", key: "Transaction ID" },
        { label: "BusinessName", key: "BusinessName" },
        { label: "Student Name", key: "Student Name", },
        { label: "Phone No", key: "Phone No" },
        { label: "Date & Time", key: "Date & Time" },
        { label: "Amount", key: "Amount", }
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.transactionId, data?.businessName, data?.studentName, data?.PhoneNo, DateFormatter(data?.updatedAt), currencyconverter(data?.amount)]);

    return (
        <Grid container direction={'column'}>
            <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <Stack alignItems='center' direction='row' spacing={2}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Pocket Money Activation Transactions</Typography>
                </Stack>
            </Grid>
            <Grid item mt={2}>
                {/* <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs> */}
                <Breadcrumb name="Pocket Money Activation Transactions" />
            </Grid>
            <Grid item mt={2} width={'100%'} display={'flex'}>
                <Grid container spacing={2} justifyContent={'space-between'} width={'100%'} display={'flex'} alignItems={'center'}>
                    <Grid item mt={1}>
                        <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search Transaction ID'} onChange={(e) => setSearchValue(e.target.value)} />
                    </Grid>
                    <Grid item display={'flex'}>
                        <Grid container display={'flex'} alignItems={'center'} spacing={2}>
                            <Grid item>
                                <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search School.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "10px", width: '300px' }} />
                            </Grid>
                            <Grid item mt={4}>
                                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    {/* <Grid item>
                                        <CustomSearchInput placeholder={'Search'} name={searchValue} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                    </Grid> */}
                                    <Grid item style={{ marginBottom: '25px' }}>
                                        {handleDaysChange !== "Custom Range" && <CustomSelect onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Date"} options={days} name={"Transaction Type"} />}
                                        {handleDaysChange === "Custom Range" && <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", }} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item>
                                <FormControl sx={{ width: '200px' }}>
                                    <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Date"
                                        value={handleDaysChange}
                                        onChange={(e) => setHandleDaysChange(e.target.value)}
                                    >
                                        <MenuItem value={'Today'}>Today</MenuItem>
                                        <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                        <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                        <MenuItem value={'This Month'}>This Month</MenuItem>
                                        <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item>
                                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Bio Transactions'} title={'Zed-Pocket Money Bio Transactions'} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={FeeActivationColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}