import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Button, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from '@mui/icons-material/Print';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import { html2pdf } from "html2pdf.js";
import HttpComponent from "../../School/MakeRequest";
import { currencyconverter } from "../../../common/currencyconverter";

const moment = require("moment");


const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(0.5),
    },
    mt4: {
        marginTop: theme.spacing(0.5),
    },
    m1: {
        margin: theme.spacing(0.5),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

// modal styles

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 1, };


const BatchView = () => {
    const theme = useTheme();
    const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));
    const [isSpinning, setIsSpinning] = useState(false);
    const [batchData, setBatchData] = useState({});
    const navigate = useNavigate();
    const { batchId, batchNo } = useParams();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [currentEmail, setCurrentEmail] = useState();
    const formatDate = (date) => {
        return moment(date).format("dddd, MMMM Do, YYYY");
    };

    function getBatchData() {
        setIsLoading(true);
        try {
            HttpComponent({
                method: "GET",
                url: `/api/sponsorbatchdetails?batchId=${batchId}`,
                token: localStorage.getItem("X-Authorization")
            }).then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                    setBatchData(response.response.data);
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.log(error);
            })
        } catch (error) {
            console.warn(error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getBatchData();
    }, [batchId]);


    const getCurrentEmail = () => {
        setCurrentEmail(localStorage.getItem("customerId"));
    };
    const refresh = () => { }

    const downloadPDF = () => {
        const input = document.getElementById("downloadable");
        const options = {
            margin: 10,
            filename: 'invoicebatch.pdf',
            image: { type: 'png', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        html2pdf()
            .from(input)
            .set(options)
            .save();
    };
    const viewPDF = () => {
        const input = document.getElementById("downloadable");
        html2pdf(input, {
            margin: 10,
            filename: `invoicebatch.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }).from(input).save();

    };

    return (
        <div style={isMobileUI ? { width: "100%" } : { width: "80%", marginLeft: "10%" }}>
            <div>
                <div>
                    <div style={isMobileUI ? { display: "block" } : { display: "flex", justifyContent: "space-between" }}>
                        <div style={{ paddingTop: 15, display: "flex", alignItems: "center" }}>
                            <span>
                                <IconButton aria-label="back" onClick={() => navigate(-1)}>
                                    <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                                </IconButton>
                            </span>
                            <span style={{ fontFamily: "Poppins", fontSize: isMobileUI ? "20px" : "25px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>Preview Invoice Batch</span>
                        </div>
                        <div style={{ padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            {(batchData?.paymentStatus === "Unpaid" || batchData?.paymentStatus === "Partially Paid") && batchData?.sentTo === currentEmail && (
                                <div>
                                    <IconButton aria-label="refresh icon" style={{ color: "#032541", height: "45px", width: "45px" }} onClick={() => refresh()}>
                                        <SyncIcon className={`${isSpinning ? "sync-icon spinning" : ""}`} />
                                    </IconButton>
                                    <Button
                                        style={{ width: isMobileUI ? "150px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}
                                        onClick={() => navigate("/parent/pay/" + batchId)}
                                    >
                                        Pay
                                    </Button>
                                </div>
                            )}
                            {batchData?.status !== "PENDING" && batchData?.status !== "DECLINED" && batchData?.customerId !== currentEmail && batchData?.status !== "CANCELLED" && batchData?.status !== "DELETED" && (
                                <div>
                                    {batchData?.paymentStatus?.toLowerCase() === "paid" ? null : (
                                        <>
                                            <Button
                                                style={{ width: isMobileUI ? "150px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white", marginRight: '10px' }}
                                                onClick={() => navigate("/parent/pay/" + batchId)}>
                                                Request Payment
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                            <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                                <IconButton aria-label="download" onClick={downloadPDF}>
                                    <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                </IconButton>
                            </span>
                            <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", marginLeft: "20px", width: "45px", height: "45px", display: "flex", justifyContent: "center", borderRadius: 5 }}>
                                <IconButton aria-label="download" onClick={viewPDF}>
                                    <PrintIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                </IconButton>
                            </span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
                        <div className={classes.p3} style={{ backgroundColor: `${batchData?.paymentStatus === "Unpaid" ? "rgba(220,53,69,0.07)" : batchData?.paymentStatus === "Paid" ? "rgba(23,174,123, 0.07)" : "rgba(247,137,41, 0.05)"}`, height: "130px", display: "flex", justifyContent: "space-between" }}>
                            <div className={classes.p1} style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ color: "black", fontSize: "30px", fontWeight: "700" }}>INVOICE BATCH</span>
                                <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Date:</span>
                                <span style={{ fontSize: "14px" }}>{formatDate(batchData?.createdAt)}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {isMobileUI ? null : <img alt="" src={`${baseUrl}/staticimages/logos/${batchData?.businessLogo}`} style={{ width: "100px", height: "100px", objectFit: "contain" }} />}
                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ color: `${batchData?.paymentStatus === "Paid" ? "#17ae7b" : batchData?.paymentStatus === "Unpaid" ? "#dc3545" : "#e07c28"}`, fontSize: "40px", fontWeight: "700" }}>{batchData?.paymentStatus}</span>
                                <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Batch No:</span>
                                <span style={{ fontSize: "14px" }}>{batchData?.batchNumber}</span>
                            </div>
                        </div>
                        <div className={""} style={isMobileUI ? { display: "block" } : { display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ fontSize: "18px" }}>From:</span>
                                <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                                    {batchData?.businessName}
                                </span>
                                <span className={classes.address_text_common}>
                                    {/* {businessInfo?.businessOwnerAddress}, {businessInfo?.country} */}
                                    {batchData?.businessOwnerAddress} -
                                    {batchData?.country}
                                </span>
                                <span className={classes.address_text_common}>
                                    {/* {businessInfo?.businessOwnerEmail} */}
                                    {batchData?.businessOwnerEmail}

                                </span>
                                <span className={classes.address_text_common}>
                                    {/* {businessInfo?.businessOwnerPhone} */}
                                    {batchData?.supplierPhoneNumber}
                                </span>
                            </div>
                            <div className={""} style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                                    Billed to:
                                </span>
                                <span className={classes.address_text_common}>
                                    {/* {batchData?.customerName} */}
                                    {batchData?.sponsorName}
                                </span>
                                <span className={classes.address_text_common}>
                                    {/* {batchData?.sentTo} */}
                                    {batchData?.sentTo}
                                </span>
                                <span className={classes.address_text_common}>
                                    {/* {batchData?.customerPhoneNumber} */}
                                    {batchData?.customerPhone}
                                </span>
                            </div>
                        </div>
                        <br />
                    </div>
                    <div style={{ border: "dotted 1px" }}></div>
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.textLeft}>Item Number</TableCell>
                                    <TableCell className={classes.textCenter}>Student Name</TableCell>
                                    <TableCell className={classes.textCenter}>Course Name</TableCell>
                                    <TableCell className={classes.textCenter}>Grade</TableCell>
                                    <TableCell className={classes.textCenter}>Total Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {batchData?.students?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.textLeft}>
                                        <span className={classes.table_body}>{item.studentNumber}</span>
                                    </TableCell>
                                    <TableCell className={classes.textLeft}>
                                        <span className={classes.table_body}>{item.studentName}</span>
                                    </TableCell>
                                    <TableCell className={classes.textLeft}>
                                        <span className={classes.table_body}>{item.courseName}</span>
                                    </TableCell>
                                    <TableCell className={classes.textLeft}>
                                        <span className={classes.table_body}>{item.term}</span>
                                    </TableCell>
                                    <TableCell className={classes.textCenter}>
                                        <span className={classes.table_body}>{currencyconverter(item.totalInvoiceAmount)}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    </div>
                    <div
                        className={classes.p3}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: '20px',
                            marginTop: "20px",
                        }}
                    >
                        <div className={classes.p1} style={{ width: isMobileUI ? "50%" : "30%", height: "80px", display: "flex", flexDirection: "column", justifyContent: "center", }}></div>
                        <div className={classes.p1} style={{ backgroundColor: "#f7f7f7", width: isMobileUI ? "50%" : "30%", height: "80px", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                            <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Subtotal</span>
                                <span>{currencyconverter(batchData.totalAmount)}</span>
                            </div>
                            <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Discount</span>
                                <span> {currencyconverter(0)} </span>
                            </div>
                            <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ color: `${batchData?.paymentStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>Total</span>
                                <span
                                    style={{
                                        fontSize: "16px",
                                        color: `${batchData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {currencyconverter(batchData?.totalAmount)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{ border: "dotted 1px" }}></div>
                    <br />
                    <br />
                    <div style={{ border: "dotted 1px" }}></div>
                    <br />
                    <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }} id="footer-text">
                        <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }} id="footer-text-content">
                            This is a system-generated invoice is created without anyalteration whatsoever.
                            <br />
                            Thank you for your business.
                        </div>
                    </div>
                    <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }} id="footer-info">
                        <div id="footer-info-text">
                            <span className={classes.footer_text}>Powered by{" "}
                                <span style={{ fontWeight: "700", }}>
                                    ZED Payments Limited
                                </span>
                                <span className={classes.footer_text}>.</span>
                            </span>
                        </div>
                        <div id="footer-info-email">
                            <span className={classes.footer_text}>info@zed.business</span>
                            <span className={classes.footer_text}>.</span>
                            <span className={classes.footer_text}>v1.0.2</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { BatchView }