import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from '@mui/material'
export default function AutoCompleteFiltertransport({options , label ,sx , onChange , value , size}){
    return(
        <Autocomplete
        label={label}
        sx={sx}
        variant="outlined"
        fullWidth
        size={size}
        value={value}
        onChange={onChange}
        options={options}
        renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
        )}
    />
    )
}