import { Grid, Typography, Breadcrumbs, Button, Box, Paper } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import CustomDataGrid from '../../products/stockComponents/customDataGrid';
import HttpComponent from '../../School/MakeRequest';
import CustomSearchInput from '../../School/CustomSearchInput';
import ExportMenu from '../../School/ExportMenu';
import AddVehicleOwner from './addvehicleowner';
import ViewOwnersVehicles from './viewVehiclesOwn';
import AddVehiclesSaccoDetails from './AddVehiclesSacco';
import ViewaVehicledetails from './viewaVehicledetails';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle Owners</Typography>
]

export default function VehicleOwnermain() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1)
    const [queryParamValue] = state.split("?")
    const navigate = useNavigate()

    // get vehicle owners

    function getVehicleOwners() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/customers_list?status=Active&customerClassification=vehicleowner&page=${pageState?.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                } else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicleOwners();
    }, [searchValue, pageState.page, dataGridPageSize ,queryParamValue])

    //get vehicle owners

    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        name: config?.customerName,
        vehicleCount: config?.vehicleOwnersCount,
        phone: config?.mobileNumber,
        email: config?.email

    }))

    // recent transactions columns

    const vehcileowners = [
        { field: "name", headerName: "Name", flex: 1, renderCell:(params) => <Link to={`/sacco/vehicleowners?viewMyvehicles?${params?.row?.id}?${params?.row?.name}?${params?.row?.phone}`}>{params?.row?.name}</Link> },
        { field: "vehicleCount", headerName: "No of Vehicles", flex: 1 },
        { field: 'phone', headerName: 'Phone Number', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1, renderCell: (params) => {
                return (
                    <Box component={'div'} alignItems={'center'}>
                        <EditIcon sx={{ color: '#032541', cursor: 'pointer', marginLeft: '10px' }} />
                        <DeleteIcon sx={{ color: '#dc3545', cursor: 'pointer', marginLeft: '10px' }} />
                    </Box>
                )
            }
        },
    ]

    return (
        <Grid container direction={'column'} width={'100%'}>
            {queryParamValue === 'AddNewVehicle' ? <AddVehicleOwner /> : queryParamValue === 'viewMyvehicles' ? <ViewOwnersVehicles/>: queryParamValue === 'addVehicles' ? <AddVehiclesSaccoDetails/>: queryParamValue === 'vehicledrilldown' ? <ViewaVehicledetails/>: <>
                <Grid item mt={2} mb={2}>
                    <Grid container gap={2} display={'flex'} direction={'column'}>
                        <Grid item width={'100%'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                                <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Vehicles</Typography>
                            </Grid>
                            <Button onClick={()=>navigate(`/sacco/vehicleowners?AddNewVehicle`)} style={{ background: "#032541", borderRadius: "5px", textTransform: "inherit", width: "180px", height: "45px", color: "#fff" }}>Add Vehicle Owner</Button>
                        </Grid>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid item mt={2} width={'100%'}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item display={'flex'} alignItems={'center'} >
                            <Paper sx={{ width: '200px', alignItems: 'center', background: 'rgba(220, 53, 69, 0.12)', fontWeight: 700, display: 'flex', px: 2, mr: 2, mt: -1, height: '52px' }}> <span style={{ color: '#dc3545', fontWeight: 700, marginRight: "20px" }}>{pageState.count}</span> vehicle owners</Paper>
                            <CustomSearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder={'Search'} name={'searchValue'} />
                        </Grid>
                        <Grid item>
                            <ExportMenu /> 
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={2} width={'100%'}>
                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={vehcileowners} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                </Grid>
            </>}
        </Grid>
    )
}