import React, { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { currencyconverter } from "../../../../common/currencyconverter";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import DateFormatter from "../../../../utils/dateFormatter";
import { useNavigate } from "react-router-dom";

export const Merchantsummary = ({ searchValue, tabValue, setcsvExport, setExportData, setEndTransactionTime, setStartTransactionTime }) => {
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const navigate = useNavigate();
  // get merchant summary;
  const getMerchantSummary = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/listmerchantsummary?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      setPageState({ ...pageState, data: data.response.data.transactions, total: data.response.data.count, isLoading: false });
    });
  };
  useEffect(() => {
    getMerchantSummary();
  }, []);

  //   handle modal;
  const [opensucess, setOpenSuccess] = useState({ message: "", state: false });
  const [openerror, setOpenError] = useState({ message: "", state: false });

  const closeSuccess = () => {
    setOpenSuccess(false);
  };

  const closeError = () => {
    setOpenError(false);
  };

  const summaryColumn = [
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "txnfees", headerName: "Txn Fees", flex: 1 },
    { field: "totalAmount", headerName: "Total Amount", flex: 1 },
    { field: "merchant", headerName: "Merchant", flex: 1 },
    { field: "businessNo", headerName: "Business ID", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => {
        return (
          <button style={{ backgroundColor: "#707070", color: "white", padding: "5px 20px 5px 20px", borderRadius: "20px", border: "none", cursor: "pointer" }} onClick={() => navigate(`/admin/settlementreview?${params?.row?.businessNo}?${params?.row?.totalAmount}?${params?.row?.txnfees}?${params?.row?.merchant}`)}>
            Settle
          </button>
        );
      },
      flex: 1,
    },
  ];

  const summaryData = pageState?.data?.map((trans) => ({
    id: trans?._id,
    totalAmount: currencyconverter(trans?.totalAmount),
    amount: currencyconverter(trans?.totalAmount),
    merchant: trans?._id,
    txnfees: currencyconverter(trans?.txnfees || 0),
    businessNo: trans?.businessNo,
    createdAt: DateFormatter(trans?.createdAt),
  }));

  useEffect(() => {
    if (tabValue === "merchantreport") {
      const csvColumns = [
        { label: "Amount", key: "totalAmount" },
        { label: "Transaction Fees", key: "txnfees" },
        { label: "Total Amount", key: "totalAmount" },
        { label: "Merchant", key: "_id" },
        { label: "Business No", key: "businessNo" },
        { label: "Date", key: "createdAt" },
      ];
      const pdfData = pageState?.data?.map((transaction) => {
        return {
          amount: transaction.totalAmount,
          txnfees: transaction.txnfees || 0,
          totalAmount: transaction.totalAmount,
          merchant: transaction._id,
          businessNo: transaction.businessNo,
          createdAt: DateFormatter(transaction.createdAt),
        };
      });

      const headers = [["Amount", "Transaction Fees", "Total Amount", "Merchant", "Business No", "Date"]];
      const data = pdfData?.map((data) => [data.amount, data.txnfees, data.totalAmount, data.merchant, data.createdAt]);

      setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
      setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
      setExportData({ headers: headers, data: data });
      setcsvExport({ headers: csvColumns, data: pdfData });
    }
  }, [tabValue]);
  return (
    <div>
      <ErrorAlert vertical={"top"} horizontal={"right"} onClose={closeError} open={openerror.state} message={openerror.message} />
      <SuccessAlert vertical={"top"} horizontal={"right"} onClose={closeSuccess} open={opensucess.state} message={opensucess.message} />

      <CustomDataGrid customStyles={{ border: "none", height: "700px", width: "100%" }} column={summaryColumn} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={summaryData} />
    </div>
  );
};

export const SettlementSummary = ({ searchValue, tabValue, setcsvExport, setExportData, setEndTransactionTime, setStartTransactionTime }) => {
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const listRevenueshare = () => {
    HttpComponent({
      method: `GET`,
      url: `/api/v1/lumpsumsettledtransactions`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      let returndata = data?.response?.data ?? [];
      let count = data?.response?.count ?? 0;

      setPageState({ ...pageState, data: returndata, total: count, isLoading: false });
    });
  };

  useEffect(() => {
    listRevenueshare();
  }, []);
  const settlementSummary = [
    { field: "totalAmount", headerName: "Total Amount", flex: 1 },
    { field: "merchantfee", headerName: "Total TXN Fees", flex: 1 },
    { field: "businessName", headerName: "Business Name", flex: 1 },
    { field: "businessId", headerName: "BusinessID", flex: 1 },
    // { field: "status", headerName: "Status", flex: 1 },
  ];
  const settlementdata = pageState?.data?.map((data, index) => ({
    id: index,
    totalAmount: currencyconverter(data?.totalAmount),
    merchantfee: currencyconverter(data?.merchantfee),
    businessId: data?._id,
    businessName: data?.businessName,
  }));

  useEffect(() => {
    if (tabValue === "settlmentreport") {
      const csvColumns = [
        { label: "Total Amount", key: "totalAmount" },
        { label: "Transaction Fees", key: "merchantfee" },
        { label: "Business ID", key: "businessId" },
        { label: "Business Name", key: "businessName" },
      ];
      const pdfData = pageState?.data?.map((transaction) => {
        return {
          amount: currencyconverter(transaction?.totalAmount),
          txnfees: currencyconverter(transaction?.merchantfee),
          businessId: transaction?._id,
          businessName: transaction.businessName,
        };
      });

      const headers = [["Total Amount", "Transaction Fees", "Business Name", "Business ID"]];
      const data = pdfData?.map((data) => [data.amount, data.txnfees, data.businessId, data.businessName]);

      setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
      setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
      setExportData({ headers: headers, data: data });
      setcsvExport({ headers: csvColumns, data: pdfData });
    }
  }, [tabValue]);
  return (
    <div>
      <CustomDataGrid customStyles={{ border: "none", height: "700px", width: "100%" }} column={settlementSummary} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={settlementdata} />
    </div>
  );
};

export const RevenueShareReports = ({ searchValue, tabValue, setcsvExport, setExportData, setEndTransactionTime, setStartTransactionTime }) => {
  const revenuesummary = [
    { field: "totalAmount", headerName: "Total Share Amount", flex: 1 },
    { field: "merchantfee", headerName: "Total TXN Fees", flex: 1 },
    { field: "zedshare", headerName: "ZED Share", flex: 1 },
    { field: "partnershare", headerName: "Partner Share", flex: 1 },
  ];

  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const listRevenueshare = () => {
    HttpComponent({
      method: `GET`,
      url: `/api/v1/lumpsumsettledtransactions`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      let returndata = data?.response?.data ?? [];
      let count = data?.response?.count ?? 0;

      setPageState({ ...pageState, data: returndata, total: count, isLoading: false });
    });
  };

  useEffect(() => {
    listRevenueshare();
  }, []);

  const revenuesummaryData = pageState?.data?.map((data, index) => ({
    id: index,
    totalAmount: currencyconverter(data?.totalAmount),
    merchantfee: currencyconverter(data?.merchantfee),
    grandTotal: currencyconverter(data?.grandTotal),
    // transferfee: data?.transferfee,
    zedshare: currencyconverter(data?.zedshare),
    partnershare: currencyconverter(data?.partnershare),
    status: data?.status,
  }));

  useEffect(() => {
    if (tabValue === "revenuereport") {
      const csvColumns = [
        { label: "Total Share Amount", key: "totalAmount" },
        { label: "Total TXN  Fees", key: "merchantfee" },
        { label: "Zed Share", key: "zedshare" },
        { label: "Partner Share", key: "partnershare" },
      ];
      const pdfData = pageState?.data?.map((transaction) => {
        return {
          totalAmount: currencyconverter(transaction?.totalAmount),
          merchantfee: currencyconverter(transaction?.merchantfee),
          grandTotal: transaction?.grandTotal,
          zedshare: currencyconverter(Math.ceil(transaction.zedshare)),
          partnershare: currencyconverter(Math.ceil(transaction.partnershare)),
          status: transaction.status,
        };
      });

      console.log("pdfData ->", pdfData);
      const headers = [["Total Share Amount", "Total TXN  Fees", "Zed Share", "Partner Share"]];
      const data = pdfData?.map((data) => [data.totalAmount, data.merchantfee, data.zedshare, data.partnershare]);

      setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
      setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
      setExportData({ headers: headers, data: data });
      setcsvExport({ headers: csvColumns, data: pdfData });
    }
  }, [tabValue]);

  return (
    <div>
      <CustomDataGrid customStyles={{ border: "none", height: "700px", width: "100%" }} column={revenuesummary} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={revenuesummaryData} />
    </div>
  );
};

export const FailedSettlementreport = ({ tabValue, setcsvExport, setExportData, setEndTransactionTime, setStartTransactionTime }) => {
  // get fee configs;
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize });

  const [searchValue, setSearchValue] = useState("");
  const [startDate, newStartDate] = useState("");
  const [endDate, newEndDate] = useState("");

  const settlementReport = [
    {
      field: "transactionReference",
      renderHeader: (params) => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>TXN Ref</Typography>
          </Box>
        );
      },
      flex: 1,
    },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "creditAccountNumber", headerName: "Credit Account", flex: 1 },
    { field: "debitAccountNumber", headerName: "Debit Account", flex: 1 },
    {
      field: "settledBusinessNumber",
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ color: "#032541" }}>BusinessID</Typography>
          </Box>
        );
      },
      flex: 1,
    },
    { field: "businessName", headerName: "Business Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  const settlementReportData = pageState?.data?.map((trans, index) => ({
    id: trans?._id,
    transactionReference: trans?.transactionReference,
    debitAccountNumber: trans?.debitAccountNumber,
    creditAccountNumber: trans?.creditAccountNumber,
    status: trans?.status,
    settledBusinessNumber: trans?.settledBusinessNumber,
    amount: trans?.amount,
    businessName: trans?.businessName,
  }));

  const getSettlementReports = () => {
    HttpComponent({
      method: "GET",
      url: `/api/v1/listsettledtransactions?startDate=${startDate}&endDate=${endDate}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&status=Failed`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      const reports = data?.response?.data ?? [];
      const count = data?.response?.data;
      setPageState({ ...pageState, isLoading: false, data: reports, total: count });
    });
  };

  useEffect(() => {
    getSettlementReports();
  }, [pageState.page, startDate, endDate, searchValue]);

  useEffect(
    () => {
      if (tabValue === "failedsettlementreport") {
        const csvColumns = [
          { label: "Txn Ref", key: "transactionReference" },
          { label: "Debit AccountNumber", key: "debitAccountNumber" },
          { label: "Credit AccountNumber", key: "creditAccountNumber" },
          { label: "Status", key: "status" },
          { label: "Business ID", key: "settledBusinessNumber" },
          { label: "Transamount", key: "amount" },
          { label: "BusinessName", key: "businessName" },
        ];

        const pdfData = pageState?.data?.map((transaction) => {
          return {
            transactionReference: transaction.transactionReference,
            debitAccountNumber: transaction.debitAccountNumber,
            creditAccountNumber: transaction.creditAccountNumber,
            status: transaction.status,
            settledBusinessNumber: transaction.settledBusinessNumber,
            amount: transaction.amount,
            businessName: transaction.businessName,
          };
        });

        const headers = [["Txn Ref", "Debit AccountNumber", "Credit AccountNumber", "Status", "Business ID", "Transamount", "BusinessName"]];

        const data = pdfData?.map((data) => [data.transactionReference, data.debitAccountNumber, data.creditAccountNumber, data.status, data.settledBusinessNumber, data.amount, data.businessName]);
        console.log("pdfData ----->", pdfData);
        setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString());
        setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString());
        setExportData({ headers: headers, data: data });
        setcsvExport({ headers: csvColumns, data: pdfData });
      } 
    },
    [pageState.page, dataGridPageSize, searchValue, startDate, endDate],
    tabValue
  );

  return (
    <div>
      <CustomDataGrid customStyles={{ border: "none", height: "600px", width: "100%" }} column={settlementReport} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={settlementReportData} />
    </div>
  );
};
