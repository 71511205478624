import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceModels: [],
  selectedModel: ""
}


const DeviceModelsSlice = createSlice({
  name:"deviceModels",
  initialState,
  reducers:{
    setDeviceModels:(state, action) => {
      state.deviceModels = action.payload
    },
    setSelectedModel:(state, action) => {
      state.selectedModel = action.payload
    },
    
  }
})

export const {  setDeviceModels, setSelectedModel } = DeviceModelsSlice.actions;

export default DeviceModelsSlice.reducer