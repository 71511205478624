import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { currencyconverter } from "../../../../common/currencyconverter";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import ExportMenu from "../../../School/ExportMenu";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import DateFormatter from "../../../../utils/dateFormatter";
import { fi } from "date-fns/locale";

const baseUrl = process.env.REACT_APP_BASE_URL;




const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student SelfOrder Report</Typography>,
]

const StudentSelfOrderReports = () => {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, total: 0 });
    const [orderNumber, setOrderNumber] = useState('');
    const [selectedCashier, setSelectedCashier] = useState('');
    const [payitUsers , setPayitUsers] = useState([])
    const [selectedpayitUser, setSelectedpayitUser] = useState('')
    const [cashiers, setCashiers] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("This Month")

    // date filter

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                break;
        }
    }, [handleDaysChange]);

    // fetch data
    const [searchValue , setSearchValue] = useState('')

    const fetchPayitData = useCallback(async () => {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getbankpaybilltransczpm?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, count: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setPageState({ ...pageState, isLoading: false })
        }

    }, [dataGridPageSize , startDate , endDate])

    useEffect(() => {
        fetchPayitData();
    }, [fetchPayitData, pageState.page , dataGridPageSize]);



    const fetchPayitUsers = useCallback(async () => {
        try{
            HttpComponent({
                method: "GET",
                url: "/api/listZedPayItUsers",
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPayitUsers(data?.response?.data)
                }
            })
        }catch(error){
            console.log(error)
        }finally{
        }

    },[])

    useEffect(() =>{
        fetchPayitUsers()
    },[fetchPayitUsers])

    const fetchCashiers = useCallback(async () => {
        try {
          const response = await fetch(baseUrl + "/api/get_cashiers_by_business", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          const data = await response.json();
          console.log(`datadata ${JSON.stringify(data)}`);
          if (response.status === 200 && data.Status === "SUCCESS") {
            setCashiers(data.data);
          } 
        } catch (e) {
          console.log(e);
        }
      },[])

      useEffect(() => {
        fetchCashiers();
      }, [fetchCashiers]);


    


    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        name: config?.name,
        refNo: config?.refNo,
        amount: currencyconverter(config?.amount),
        transactionTime: DateFormatter(config?.transactionTime),
    }))

    const payitOrders = [
        { field: "name", headerName: "Student Name", flex: 1 },
        { field: 'refNo', headerName: 'Ref No', flex: 1 },
        { field: 'amount', headerName: 'Amount', flex: 1 },
        { field: 'transactionTime', headerName: 'Transaction Time', flex: 1 },
    ]

    console.log(selectedCashier , 'selectedCashier')



    const filedata = pageState?.data?.map((data) => {
        return {
            "Student Name": data?.name,
            "Ref No": data?.refNo,
            "Amount": data?.amount,
            "Transaction Time": DateFormatter(data?.transactionTime)
        }
    })
    const fileHeaders = [["Student Name", "Ref No", "Amount", "Transaction Time"]]
    const csvColumns = [
        { label: "Student Name", key: "Student Name" },
        { label: "Ref No", key: "Ref No" },
        { label: "Amount", key: "Amount" },
        { label: "Transaction Time", key: "Transaction Time" },
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.orderNumber, data?.cashier, currencyconverter(data?.amount), DateFormatter(data?.transactionTime)]);


    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: "#032541" }} />
                <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Zed Payit Report</Typography>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
                {/* <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                    <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> No of Orders <span style={{ fontWeight: 700 }}>{pageState.count}</span></Typography>
                    </Paper>
                    <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> Total Amount <span style={{ fontWeight: 700 }}>{currencyconverter(pageState.total)}</span></Typography>
                    </Paper>
                </Box> */}
            </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} width={'100%'}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Box component={'div'} width={'30%'}>
                        <SearchFilter placeholder={"Search"} setInputValue={setOrderNumber} />
                    </Box>
                    <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                        <Grid item display={'flex'}>
                            <FormControl sx={{ width: '190px', height: "50px" }}>
                                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Date"
                                    value={handleDaysChange}
                                    sx={{ height: '100%' }}
                                    onChange={(e) => setHandleDaysChange(e.target.value)}
                                >
                                    <MenuItem value={'Today'}>Today</MenuItem>
                                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                    <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                    <MenuItem value={'This Month'}>This Month</MenuItem>
                                    <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item display={'flex'}>
                            <FormControl sx={{ width: '190px', height: '50px' }}>
                                <InputLabel id="demo-simple-select-label">Cashier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Cashier"
                                    value={selectedCashier}
                                    sx={{ height: '100%' }}
                                    onChange={(e) => setSelectedCashier(e.target.value)}
                                >
                                    {payitUsers?.map((cashier) => (
                                        <MenuItem value={cashier?.userId}>{cashier?.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item>
                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'All Transactions'} title={'Student wallete report'} />
                        </Grid>
                    </Box>
                </Box> 
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={payitOrders} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
        </Grid>
    )
}

export { StudentSelfOrderReports }