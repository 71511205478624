export const customerstyles = {
    universalStyle:{
        fontFamily:"Poppins",
        padding:"0px 0px 0px 20px"
    },
    universalFont: {
        fontFamily: "Poppins",
      },
    gridMargin:{
        margin:"0px 0px 10px -10px",
    },
    individualInputsholder:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        width:"50%"
    },
    marginGridItems:{
        margin:"0px 0px 2% 0px"
    },
    cancelBtn:{
      color:'#cb444a',
      border:'1px solid #cb444a'
    },
    cancelButton:{
        border:"1px solid #dc3545",
        color:"#dc3545",
        // margin:"0px 10px 0px 0px",
        width:"20%",
        padding:"7px",
        borderRadius:"5px"
    },
    cancelButtonLowOpacity:{
        border:"1px solid rgba(220,53,69, .4)",
        color:"rgba(220,53,69, .4)",
        margin:"0px 10px 0px 0px",
        // width:"20%",
        padding:"7px",
        borderRadius:"5px"
    },
    approvalButton:{
        backgroundColor:"#032541",
        color:"#ffffff",
        // width:"20%",
        padding:"7px",
        borderRadius:"5px",
        margin:"0px 0px 0px 10px",
    },
    approvalButtonLowOpacity:{
        backgroundColor:"rgba(3,37,65,.4)",
        color:"#ffffff",
        // width:"20%",
        padding:"7px",
        borderRadius:"5px",
        margin:"0px 0px 0px 10px",
    },
    buttonsHoldingDiv:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
        width:"50%"
    },
    addCustomer:{
        backgroundColor:"#032541",
        color:"white" ,
        height:"45px"
    },
    dataGridStyling:{
        height:"500px",
        width:"100%",
        border:"none",
    },
    moreVertStyling:{
        cursor:"pointer"
    },
    menuItemsHolder:{
      width:"10%",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    customerIcon: {
        display: "flex",
        alignItems: "center",
      },
      divwithImg: {
        margin: "0px 50px 0px 0px",
      },
      customerImg: {
        width: "70px",
        height: "70px",
        ObjectFit: "cover",
      },
      customerText: {
        fontSize: "16px",
        color: "#032541",
        fontWeight: "bold",
      },
      customerPara: {
        color: "#707070",
        fontSize: "12px",
      },
      paraWithDisplayFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      cancelButton: {
        border: "solid 1px #002543",
        color: "#002543",
      },
      deactivateButton: {
        backgroundColor: "#dc3545",
        color: "white",
      },
      deactivateSupplierModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        backgroundColor: "white",
        width: "25%",
        height: "fit-content",
        fontFamily: "Poppins",
        padding: "30px",
      },
      activateButton:{
        backgroundColor:"#032541",
        color:"white"
      },
      searchDateFilter:{
        display:"flex",
        alignItems:"center",
      },
      customerDetailsPara:{
        color:"#032541",
        fontWeight:"bold",
        margin:"8px",
      },
      customerDetailsParaExpense:{
        color:"#dc3545",
        fontWeight:"bold",
        margin:"0px",
      } , customerDetailsParaBalance:{
        color:"#17ae7b",
        fontWeight:"bold",
        margin:"0px",
      },
      customerDetailsParaNormal:{
        color:"#000000",
        margin:"8px",
      },
      customerDetailsParaNormalBalance:{
        color:"#17ae7b",
        margin:"8px",
      },
      customerDetailsParaNormalExpense:{
        color:"#dc3545",
        margin:"8px",
      },
      orderItemTotals:{
        fontWeight:'bold',
        color:"#032541"
      },
      orderItemHolder:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-end',
        padding:'20px',
      },
      spanSpacing:{
        margin:"0px 0px 20px 0px"
      },
      invoicesText:{
        color:'#032541',
        fontSize:"25px",
        fontWeight:"bold"
      },
      createInvoiceButton:{
        backgroundColor:"#032541",
        color:'white'
      },
      customerAccountsDiv:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#fff',
        margin:'0px 0px 5px 0px',
        padding:'5px',
        borderRadius:'7px',
        width:'100%',
        fontFamily:'Poppins',
        cursor:'pointer'
      },
      customerAccountsPara:{
        margin:'0px',
        color:'#032541'
      },
      customerAccountsDivHolder:{
        height:'30vh',
        overflow:'auto',
        padding:'5px',
        margin:'0px 0px 5px 0px',
        width:'70%'
      },
      statementPreview:{
        color:"#032541",
        fontSize:"42px",
        margin:"0px",
        fontWeight:"bold"
      },
      customerPreview:{
        color:"#032541",
        fontSize:"23px",
        margin:"0px",
        fontWeight:"bold"
      },
      statementBsName:{
        color:"#032541",
        fontSize:"13px",
        margin:"0px",
        fontWeight:"bold"
      },
      studentBsNameHolders:{
        color:"#707070",
        fontSize:"13px",
        margin:"0px",
      },
      studentBsNameFooters:{
        color:"#707070",
        fontSize:"13px",
        margin:"0px",
        textAlign:"center"
      },
      viewStatementBtn:{
        color:"#032541",
        backgroundColor:"#f5f5f5",
        padding:"10px",
        fontFamily:'Poppins'
      },
      previewStatement:{
        color:"#032541",
        fontSize:"25px",
        fontWeight:'bold'
      },
      previewShareButton:{
        color:"white",
        backgroundColor:"#032541"
      }
}