import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: "",
  name: "",
  description: "",
};

const categoryToUpdateSlice = createSlice({
  name: "categoryDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.code = action.payload.code;
      state.name = action.payload.name;
      state.description = action.payload.description;
    },
  },
});

export const { setDetails } = categoryToUpdateSlice.actions;

export default categoryToUpdateSlice.reducer;
