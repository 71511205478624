import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    firstNameR:'',
    lastNameR:'',
    additionalInfoR:'',
    studentIdR:"",
    gradeR:"",
    termR:"",
    admissionDateR:"",
    genderR:'',
    customerIdR:"",
    studentItemNo:"",
    servicesR: []
}



const studentDetails = createSlice({
    name:"studentdetails",
    initialState,
    reducers: {
        saveStudentDeatails: (state, action) => {
            // state.saveStudentDetails  =action.payload
            console.log(action.payload, 'action.payload');
            
            const { firstName,lastName,additionalInfo,studentId,grade,term,admissionDate,customerId,studentItemNo,servicesData }  = action.payload
            state.firstNameR = firstName
            state.lastNameR = lastName
            state.additionalInfoR = additionalInfo
            state.studentIdR = studentId
            state.gradeR = grade
            state.termR = term
            state.admissionDateR = admissionDate
            state.customerIdR = customerId
            state.studentItemNo = studentItemNo
            state.servicesR =servicesData
        },

        
    }

})

export const  { saveStudentDeatails } = studentDetails.actions
export default studentDetails.reducer