import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAllocation } from "../../../../features/tenantsUnitsSlice";
import CustomSelectField from "../../../School/CustomSelectField";
import HttpComponent from "../../../School/MakeRequest";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";


export default function AddUnit(props, { onButtonClick }) {
	const [type, setType] = useState("")
	const [prefix, setPrefix] = useState("")
	const [noOfUnits, setNoOfUnits] = useState(0)
	const [startPrefix, setStartPrefix] = useState("")
	const [unitPurpose, setUnitPurpose] = useState('For Rent / Sale')
	const [installment, setInstallments] = useState(0)
	const [amount, setAmount] = useState(0)
	const [billingPeriod, setBillingPeriod] = useState("Select Billing Period" || props.billingPeriod)
	const { X_Authorization } = useSelector((store) => store.user);

	const { businessUnitConfigId } = useSelector((store) => store.tenantBusinessData);
	const dispatch = useDispatch()
	const formData = {
		unitType: type, startNumber: startPrefix, unitPurpose: unitPurpose, instalmentAmount: unitPurpose === 'Rent' ? 0 : installment, billingPeriod: billingPeriod, unitTypeCount: noOfUnits,
		unitTypePrefix: prefix, unitCost: amount
	}
    console.log(props , 'data for units created')
	const [unitOptions, setUnitOptions] = useState([])
	const unitTypesOptions = unitOptions.map((unit) => ({ value: unit, label: unit }))

	const [unitRows, setUnitRows] = useState([])

	// Notifications
	const [errorShow, setErrorShow] = useState({ state: false, message: "" })
	const [successShow, setSuccessShow] = useState({ state: false, message: "" })

	// Get Number of Units
	function generateStrings() {
		HttpComponent({
			method: 'GET',
			url: `/api/get_unit_types_by_business_unit_config?limit=1&page=1`,
			token: X_Authorization
		}).then((data) => {
			console.log("here store is data", data);
			if (data.status === 201) {
				setUnitRows(data.response.data)
			}
			else {
				console.error("Error Getting units info")
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	function handleClickList() {
		HttpComponent({
			method: 'POST',
			url: '/api/define_business_unit',
			body: formData,
			token: X_Authorization
		})
			.then((data) => {
				console.log("new unit created", data);
				if (data.status === 201) {
					setSuccessShow({ state: true, message: data.response.message })
					// handleAddHouseNoListClick(formData)
					handleAddHouseClick(formData)
				} else {
					setErrorShow({ state: true, message: data.response.message })
					console.error("Error setting info")
					console.log('we haveerror')
				}
			}).catch((error) => {
				console.error(error.message);
			})
	}

	//submit units created
	const submitUnit = () => {
		HttpComponent({
			method: 'POST',
			url: '/api/define_business_unit',
			body: formData,
			token: X_Authorization
		})
			.then((data) => {
				console.log("here store is data", data);
				if (data.status === 201) {
					setSuccessShow({ state: true, message: data.response.message })
					handleAddHouseClick(formData)

				} else {
					setErrorShow({ state: true, message: data.response.message })

					console.error("Error setting info")
				}
			}).catch((error) => {
				console.error(error.message);
			})

	}

	function getUnitTypes() {
		HttpComponent({
			method: 'GET',
			url: '/api/get_house_unit_types',
			token: X_Authorization
		}).then((data) => {
			console.log("here store is units Types Data", data);
			if (data.status === 201) {
				setUnitOptions(data.response.data)
			} else {
				setErrorShow({ state: true, message: data.response.message })
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	const handleAddHouseClick = (data) => {
		//hold Data to LocalStorage
		dispatch(handleAllocation(noOfUnits))
		if(props?.isFromAddUnit){
			props?.setFlagStateBack()
		}else{
			props.setFlagState()
		}
	

	}


	const handleAddHouseNoListClick = (data) => {
		//hold Data to LocalStorage
		dispatch(handleAllocation(noOfUnits))
		props.isFromAddUnit ? props.setFlagStateBack() : null
		generateStrings()
		setType('')
		setPrefix('')
		setNoOfUnits('')
		setStartPrefix('')
		setAmount('')
		setInstallments('')
		setBillingPeriod('')
		setUnitPurpose('')

	}

	function handlePreview() {
		return !type.trim() ||
			!prefix.trim() ||
			!noOfUnits.trim() ||
			!startPrefix.trim() ||
			!amount.trim() ||
			!billingPeriod.trim()
	}


	const handleGoBackClick = () => {
		props.setFlastStateBack('')
	}
	const billingOptions = [
		{ value: "Monthly", label: "Monthly" },
		{ value: "Quarterly", label: "Quarterly" },
		{ value: "Halfly", label: "Halfly" },
		{ value: "Yearly", label: "Yearly" },
	]

	useEffect(() => {
		getUnitTypes()
		generateStrings()
	}, [])

	console.log(`inside component add units`, prefix, type, noOfUnits, startPrefix, amount, billingPeriod, props.defineUnit)

	return (
		<div>

			<ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

			<SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

			<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={5} mt={3}>

				{/*label*/}
				<Grid item display={'flex'} justifyContent={'center'} alignContent={'center'}>
					<span style={{ color: '#032541', fontSize: '16px', fontWeight: '600', }} >Define units</span>
				</Grid>

				{/*form Units Defination*/}
				<Grid item>
					{/*Type prefix*/}
					<Grid container direction={'row'} justifyContent={'space-between'} >

						{/*Type*/}
						<Grid item>
							<CustomSelectField style={{ color: '#032541FF', width: '400px', fontFamily: 'Poppins', fontWeight: 'normal' }} value={type} onChange={(e) => { setType(e.target.value) }} name={"Unit Type"} placeholder={"Type"} options={unitTypesOptions} />
						</Grid>

						{/*Amount*/}
						<Grid item mt={1}>
							<TextField value={amount} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '400px' }} id="outlined-basic" label={unitPurpose === 'Rent' ? 'Rent':'Price'} variant="outlined" required />
						</Grid>

					</Grid>
					{/*Units Purpose*/}
					<Grid container direction={'row'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'} spacing={2} mt={2}>
						<Grid item mt={1}>
							<FormControl>
								<InputLabel id="demo-simple-select-label">ForRent/Sale</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={unitPurpose}
									label="ForRent/Sale"
									onChange={(e) => setUnitPurpose(e.target.value)}
									style={{ width: "400px" }}
								>
									<MenuItem value='Rent'>Rent</MenuItem>
									<MenuItem value='Sell'>Sell</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						{!unitPurpose || unitPurpose === 'Rent' ?
							<Grid item>
								<TextField value={noOfUnits} onChange={(e) => { setNoOfUnits(e.target.value) }} InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "13px", width: '400px' }} type='number' id="outlined-basic" label="No of Units" variant="outlined" required />
							</Grid> :
							<Grid item mt={1}>
								<TextField value={installment} onChange={(e) => { setInstallments(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '400px' }} id="outlined-basic" label="Installments" variant="outlined" required />
							</Grid>
						}
					</Grid>
					{/*Units start*/}
					<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={2}>
						{/*Billing Period*/}
						<Grid item direction={'row'} spacing={2}>
							{unitPurpose === 'Sell' ?
								<Grid direction={'row'} display={'flex'} justifyContent={'space-between'} spacing={2} mt={2}>
									<Grid item>
										<TextField value={noOfUnits} onChange={(e) => { setNoOfUnits(e.target.value) }} InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "13px", width: '400px' }} type='number' id="outlined-basic" label="No of Units" variant="outlined" required />
									</Grid>
									<Grid item ml={2} mt={-1}>
										<CustomSelectField style={{ color: '#032541FF', width: '400px', fontFamily: 'Poppins', fontSize: '13px', fontWeight: 'normal' }} value={billingPeriod} onChange={(e) => { setBillingPeriod(e.target.value) }} name={"Billing Period"} placeholder={"Select Billing Period"} options={billingOptions} />
									</Grid>
								</Grid>
								: null
							}
						</Grid>
					</Grid>
					{unitPurpose === 'Rent' && <Grid item mt={-1}>
						<CustomSelectField style={{ color: '#032541FF', width: '800px', fontFamily: 'Poppins', fontSize: '13px', fontWeight: 'normal' }} value={billingPeriod} onChange={(e) => { setBillingPeriod(e.target.value) }} name={"Billing Period"} placeholder={"Select Billing Period"} options={billingOptions} />
					</Grid>}

					{/*label*/}
					<Grid item mt={3}>
						<span style={{ color: '#032541', fontSize: '16px', fontWeight: '600' }} >House Numbering</span>
					</Grid>

					{/*Amount billing period*/}
					<Grid container direction={'row'} justifyContent={'space-between'} spacing={2} mt={2}>

						{/*Prefix*/}
						<Grid item>
							<TextField value={prefix} onChange={(e) => { setPrefix(e.target.value) }} type='text' InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '400px' }} id="outlined-basic" label="Prefix" variant="outlined" required />
						</Grid>

						{/*start date*/}
						<Grid item>
							<TextField value={startPrefix} onChange={(e) => { setStartPrefix(e.target.value) }} type='text' InputProps={{ style: { fontFamily: 'Poppins' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541", fontWeight: 'normal' } }} style={{ border: "solid 0px #e4e4e4", fontSize: "12px", width: '400px' }} id="outlined-basic" label="Start From" variant="outlined" required />
						</Grid>
					</Grid>

					{/*cancel add House*/}
					{props.isFromAddUnit ?
						<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2}>

							<Grid item>
								<Button onClick={() => { props.setFlagStateBack() }} style={{ border: '1px solid #032541', fontWeight: '600', textTransform: 'none', color: '#032541', background: '#fff', width: '146px', height: '45px' }} >
									Cancel
								</Button>
							</Grid>

							<Grid item>
								<Button disabled={!type.trim() || !prefix.trim() || !noOfUnits.trim() || !startPrefix.trim() || !amount.trim() || amount < 0 || !billingPeriod.trim()}
									onClick={handleClickList} style={{
										opacity: !type.trim() || !prefix.trim() || !noOfUnits.trim() || !startPrefix.trim() || !amount.trim() || amount < 0 || !billingPeriod.trim() ? "0.5" : "1.0",
										fontWeight: '600', textTransform: 'none', color: '#fff', background: '#032541', width: '146px', height: '45px'
									}} >
									Add Units
								</Button>
							</Grid>
						</Grid> :
						<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2}>
							{!props.isFromSetup ?
								<Grid item>
									<Button disabled={(!type.trim() || !prefix.trim() || !noOfUnits.trim() || !startPrefix.trim() || !amount.trim() || amount < 0 || !billingPeriod.trim()) && unitRows.length < 1}
										hidden={unitRows.length < 1}
										onClick={() => {
											(!type.trim() || !prefix.trim() || !noOfUnits.trim() ||
												!startPrefix.trim() || !amount.trim() || amount < 0 ||
												!billingPeriod.trim()) ?
												props.setFlagState() :
												!(!type.trim() || !prefix.trim() || !noOfUnits.trim() ||
													!startPrefix.trim() || !amount.trim() || amount < 0 ||
													!billingPeriod.trim()) ? submitUnit() : props.setFlagState()
										}} style={{
											opacity: (!type.trim() || !prefix.trim() || !noOfUnits.trim() || !startPrefix.trim() || !amount.trim() || amount < 0 || !billingPeriod.trim()) && unitRows.length < 1 ? "0.5" : "1.0",
											border: '1px solid #032541', fontWeight: '600', textTransform: 'none', color: '#032541', background: '#fff', width: '146px', height: '45px'
										}} >
										Preview
									</Button>
								</Grid> : null}

							<Grid item>
								<Button style={{ border: '1px solid #dc3545', fontWeight: '600', textTransform: 'none', color: '#dc3545', background: '#fff', width: '146px', height: '45px' }} >
									Cancel
								</Button>
								<Button disabled={!type.trim() || !prefix.trim() || !noOfUnits.trim() || !startPrefix.trim() || !amount.trim() || amount < 0 || !billingPeriod.trim()}
									onClick={handleClickList} style={{
										marginLeft: '20px',
										opacity: !type.trim() || !prefix.trim() || !noOfUnits.trim() || !startPrefix.trim() || !amount.trim() || amount < 0 || !billingPeriod.trim() ? "0.5" : "1.0",
										fontWeight: '600', textTransform: 'none', color: '#fff', background: '#032541', width: '146px', height: '45px'
									}} >
									Add Units
								</Button>
							</Grid>
						</Grid>
					}

					{/*</Grid>*/}
				</Grid>

			</Grid>

		</div>
	)
}