import { Grid, Button, styled, List, ListItem, ListItemIcon, ListItemButton, Checkbox, ListItemText, Tab, Modal, Box, TextField, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TabPanel, TabContext, TabList } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { redirect, useNavigate, useParams } from "react-router-dom";
import emptyState from "../../../common/images/emptystate.svg";
import { ConfirmOverPaymentCreditnote, ConfirmPayCreditNote, CreditnotepayModal, CustomerAccounts } from "../../bills/startorderModals/modals";
import { useDispatch } from "react-redux";
import { removeStartOrderArray } from "../../../features/startorderSlice";
import CloseIcon from '@mui/icons-material/Close';
import redarrow from "../../../common/images/redarrowdown.svg";
import mpesasvg from "../../../common/images/mpesa.svg";
import equitelsvg from "../../../common/images/equitel.svg";
import banksvg from "../../../common/images/bank.svg";
import cardsvg from "../../../common/images/card.svg";
import cashsvg from "../../../common/images/cash.svg";
import voomasvg from "../../../common/images/vooma.svg";
import cashpayment from "../../../common/images/cashpayment.svg";
import approvetick from "../../../common/images/approvetick.svg";
import powericon from "../../../common/images/powericon.svg";
import cardpay from "../../../common/images/cardpay.svg";
import vouchersvg from "../../../common/images/voucher-icn.svg";
import creditnote from "../../../common/images/creditnotepay.svg";
import moment from "moment-timezone";
import lodash, { sumBy } from 'lodash'

const baseUrl = process.env.REACT_APP_BASE_URL;
// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(10),
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "KES",
    }).format(value);


const AddProductModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    backgroundColor: "#fff",
    width: "477px",
    height: "400px",
};
const style = {
    borderColor: "transparent",
    borderRadius: "36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    height: "300px",
};

export default function CustomerOrders(props) {
    const [checkedOrders, setCheckedItems] = useState([])
    const customerId = props?.customerId
    const setOrderIds = props?.setOrderIds
    const [orders, setOrders] = useState([])
    const [partialorders, setPartialOrders] = useState([])
    const [paidorders, setPaidOrders] = useState([])
    const [tabValue, setTabValue] = useState('unpaid')
    const [amountRecieved, setAmountRecieved] = useState(0)
    const [discountAmount, setDiscountAmount] = useState(0)
    const [searchedVoucher, setSearchedVoucher] = useState();
    const [titleMessage, settitleMessage] = useState("Confirmation Message");
    const [chainedBillModal, setChainedBill] = useState(false);

    const [checkedDiscount, setCheckedDiscount] = useState(false);
    const setCheckledStatus = () => {
        setCheckedDiscount(!checkedDiscount);
    };

    const totalBill = sumBy(checkedOrders, item => {
        return item?.billTotal
    })

    console.log(checkedOrders)

    const checkedpushTransactionIds = checkedOrders.map((checkord) => checkord?.orderId)

    const [voucherValidationOpen, setvoucherValidationOpen] = useState(false);
    const ClosevoucherValidationClose = () => setvoucherValidationOpen(false);

    const [confirmationVoucherOPen, setconfirmationVoucherOPen] = useState(false);
    const closeconfirmationVoucher = () => setconfirmationVoucherOPen(false);
    const [cardDiscount, setToggleCardDiscount] = useState(false);
    const [cardDiscountAmount, setCardDiscountAmount] = useState(0);

    const [payDetails, setPayDetails] = useState(false);
    const [paySuccess, setpaySuccess] = useState(false);
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false)
    const [messagesTobeFetched, setMessagesToBeFetched] = useState("");
    const [paymentMessageModal, setPaymentMessageModal] = useState(false);
    const [openCashModal, setOpenCashPayment] = useState(false);
    const [cardModal, setCardModalOpen] = useState(false);
    const [bankPay, setBankPay] = useState(false);
    const [bankDetailsDropDown, setBanDetailsDropDown] = useState(true);
    const [bankDetailsMessages, setBankDetailsMessages] = useState(false);
    const [togglebankPay, setToggleBankPay] = useState(false);
    const [confirmPayDetails, setConfirmPatDetails] = useState(false);
    const [openVoucherPay, setopenVoucherPay] = useState(false);
    const closeVoucherPay = () => setopenVoucherPay(false);
    const [cardTypes, setCardTypes] = useState([]);
    const [getAcquirers, setCardAcquirers] = useState([]);
    const [cardCustomer, setCardCustomerName] = useState("");

    const [getCardType, setCardType] = useState("");
    const [refNo, setRefNo] = useState("");
    const [getAcquirer, setAcquirer] = useState("");
    const [customerConfirm, setCustomerConfirm] = useState(false);
    const [customerPreview, setCustomerPreview] = useState(false);
    const [openCreditNote, setOpenCreditNote] = useState(false);
    const [creditnotesearch, setCreditnotesearch] = useState("");
    const [creditNoteData, setCreditNoteData] = useState("");
    const [creditNoteError, setCreditNoteError] = useState("");
    const [openConfirmCredit, setopenConfirmCredit] = useState(false);
    const [creditNotedetails, setCreditNoteDetails] = useState({ amount: 0, no: "" });
    const [creditNoteId, setCreditNoteId] = useState("");
    const [creditNotePayId, setCreditNotePayId] = useState("");
    const [openCreditpayOverPayment, setOpenCreditPayOverPayment] = useState(false);
    const [customerOnAccountsName, setCustomerOnAccountsName] = useState("");
    const [customerOnAccountsBalance, setCustomerOnAccountsBalance] = useState(0);
    const customerOnAccountsBill = 0
    const [openSnack, setOpenSnack] = useState(false);
    const [isError, setIsError] = useState({ state: false, message: '' })
    const [saveBill, setOpenSaveBill] = useState(false);
    const [cashRecieved, setCashRecieved] = useState(0);
    const [overallTotal, setOverallTotal] = useState(0);
    const [generalDiscountAmount, setGeneralDiscountAmount] = useState(0);
    const newOverallTotal = parseInt(overallTotal - generalDiscountAmount);


    let balance = parseInt(cashRecieved) - parseInt(overallTotal - generalDiscountAmount);

    const [messagesCollection, setMessagesCollection] = useState([]);
    const [mobilePayDiscountAmt, setMobilePayDiscount] = useState(0);
    const [mobilePayDiscountToggle, setMobilePayDiscountToggle] = useState(false);
    const [mpesaAmountModal, setMpesaAmountModal] = useState(false);

    // MPESA / VOOMA/ EQUITEL/  POP UP MESSAGES;
    const [mobilePayQuery, setMobilePayQuery] = useState("");
    const [bankPayQuery, setBankPayQuery] = useState("");

    //  MPESA PAYMENT;
    const [customerName, setCustomerName] = useState("");
    const [payAmount, setPayAmount] = useState(0);
    const [payNum, setPayNum] = useState("");
    const [payDate, setPayDate] = useState("");
    const [payRef, setPayRef] = useState("");
    const [confrimPayModal, setConfirmPayModal] = useState(false);
    const [bankPayDiscount, setBankPayDiscount] = useState(0);
    const [bankPayName, setBankPayName] = useState("");
    const [bankPayNum, setBankPayNum] = useState("");
    const [bankPayDate, setBankPayDate] = useState("");
    const [bankPayRef, setBankPayRef] = useState("");
    const [bankPayAmount, setBankPayAmount] = useState(0);

    const dispatch = useDispatch()
    const clearStartOrderFromRedux = () => {
        dispatch(removeStartOrderArray());
    };

    //details of the customer
    var bankAmount = 0;
    var newBankBalance = 0;
    if (Math.sign(balance) === -1) {
        var newcustomerBalance = balance * -1;
    } else if (Math.sign(balance) === 1) {
        var newcustomerBalance = balance;
    }
    var cashtransamount = 0;

    if (Math.sign(balance) === -1) {
        cashtransamount = parseInt(cashRecieved);
    } else if (Math.sign(balance) === 1) {
        cashtransamount = parseInt(newOverallTotal);
    } else if (Math.sign(balance) === 0) {
        cashtransamount = parseInt(newOverallTotal);
    }


    const payTransAmountTotal = parseInt(totalBill - mobilePayDiscountAmt);
    const payTransBalance = parseInt(payAmount) - parseInt(totalBill - mobilePayDiscountAmt);
    const cardTotal = parseInt(totalBill) - parseInt(cardDiscountAmount);

    var paytransamount = 0;
    var newPayTransBalance = 0;
    if (Math.sign(payTransBalance) === -1) {
        paytransamount = parseInt(payAmount);
        newPayTransBalance = payTransBalance * -1;
    } else if (Math.sign(payTransBalance) === 1) {
        paytransamount = parseInt(payTransAmountTotal);
    } else if (Math.sign(payTransBalance) === 0) {
        paytransamount = parseInt(payTransAmountTotal);
    }

    const { id } = useParams()
    const [customerDetails, setCustomerDetails] = useState({})
    const businessCategory = localStorage.getItem('businessCategory')
    const [customerOnAccountsPrintBill, setCustomerOnAccountsPrintBill] = useState("");

    const { userId, lastName, businessName, phone, firstName, businessId, paymentType, customerType, } = customerDetails

    function fetchCustomerDetaisl() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_customer_id?customerId=${id}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
               // console.log(data, 'user deatisls')
                setCustomerDetails(data?.response?.data)
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchCustomerDetaisl()
    }, [id])



    const productData = checkedOrders?.flatMap((item) => item?.products?.map((product) => product));

    // clear used messages;
    const unUseMessage = async (sms) => {
        // console.log(sms.sms);
        try {
            const unUseMessoResp = await fetch(baseUrl + "/api/updatePosSms", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    sms: sms.sms,
                }),
            });
            const unUseMessoRespData = await unUseMessoResp.json();
            // console.log(unUseMessoRespData);
        } catch (error) {
            console.log(error.message);
        }
    };

    //save order to db
    const saveOrderToBd = async () => {
        try {
            const saveOrderResp = await fetch(baseUrl + "/api/savePushy", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    beneficiary: "N/A",
                    businessId: localStorage.getItem("businessId"),
                    cashier: localStorage.getItem("username"),
                    customerBalance: customerType === "regular" ? newcustomerBalance : customerOnAccountsBalance - overallTotal,
                    customerId: customerType === "customeronaccounts" ? id : "N/A",
                    customerName: customerType === "regular" ? firstName : customerOnAccountsName,
                    customerPaymentType: customerType === "customeronaccounts" ? paymentType : "N/A",
                    customerType: customerType === "regular" ? "regular" : customerType === "customeronaccounts" ? customerType : "N/A",
                    items: productData,
                    mileage: "0",
                    orderTable: 'N/A',
                    orderType: customerType === "customeronaccounts" ? "Customer On Account" : "N/A",
                    parentOrderId: "N/A",
                    paymentMethod: "N/A",
                    pushTransactionId: Date.now(),
                    serialNo: Date.now(),
                    status: "unpaid",
                    transamount: customerType === "customeronaccounts" ? parseInt(customerOnAccountsBill) : overallTotal,
                    transtime: new Date(Date.now()),
                    userId: localStorage.getItem("userId"),
                    discountAmount: parseInt(generalDiscountAmount),
                }),
            });

            const saveOrderData = await saveOrderResp.json();

            if (saveOrderResp.status === 201) {
                clearStartOrderFromRedux();
                setCustomerOnAccountsPrintBill(saveOrderData.data._id);
                setOpenSaveBill(false);
                if (customerType === "customeronaccounts") {
                    setCustomerConfirm(true);
                }
                if (businessCategory !== "Eatery, Bar and Restaurant" || businessCategory !== "Hotel") {
                    setOpenSnack(true);

                    if (customerType === "regular") {
                        setTimeout(() => {
                            setOpenSnack(false);
                            window.location.reload();
                            // setSuccessfullyPaid(true);
                        }, 2000);
                    } else {
                        setTimeout(() => {
                            setOpenSnack(false);
                            // window.location.reload();
                            // setSuccessfullyPaid(true);
                        }, 2000);
                    }
                } else {
                    setOpenSnack(true);
                    // setSuccessfullyPaid(true);
                }

                // setTimeout(() => {
                //   window.location.reload();
                // }, 2000);
            } else if (saveOrderResp.status === 400) {
                setIsError({ state: true, message: saveOrderData.message })
            }
        } catch (error) {
            console.log(error.message);
        }
    };




    const handleChangeTabValue = (e, tabValue) => {
        setTabValue(tabValue)
    }


    const navigate = useNavigate()

    function fetchGetUnPaidOrders() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/fetchByStatus/?status=unpaid&customerId=${customerId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'unpaid orders')
                if (data.status === 200) {
                    setOrders(data.response.transaction)
                }
            })

        } catch (error) {

        }

    }


    function fetchGetPaidOrders() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/fetchByStatus/?status=paid&customerId=${customerId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'paid orders')
                if (data.status === 200) {
                    setPaidOrders(data.response.transaction)
                }
            })

        } catch (error) {

        }

    }


    function fetchGetpartialOrders() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/fetchByStatus/?status=partial&customerId=${customerId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'partial orders')
                if (data.status === 200) {
                    setPartialOrders(data.response.transaction)
                }
            })

        } catch (error) {

        }

    }

    //transaction

    useEffect(() => {
        fetchGetUnPaidOrders();
    }, [])
    useEffect(() => {
        fetchGetpartialOrders();
    }, [])

    useEffect(() => {


        fetchGetPaidOrders();
    }, [])

    const [orderDetails, setOrderDetails] = useState({

        cashier: "", customerName: "", items: [], status: "", transamount: '', vat: '', subTotal: '',
        balance: '', customerBalance: '', discountAmount: '', childUnpaid: '', billTotal: '', createdAt: ''
    })


    const getOrderInfo = () => {
        orders.length > 0 && orders.map((order) => {
            const date = new Date(order?.createdAt).toDateString();
            setOrderDetails({
                ...orderDetails,
                cashier: order?.cashier,
                customerName: order?.customerName,
                items: order?.items,
                status: order?.status,
                transamount: order?.transamount,
                vat: order?.vat,
                subTotal: order?.subTotal,
                balance: order?.balance,
                customerBalance: order?.customerBalance,
                discountAmount: order?.discountAmount,
                childUnpaid: order?.childUnpaid,
                billTotal: order?.billTotal,
                createdAt: date
            })
        })

    }

    useEffect(() => {
        getOrderInfo()
    }, [orders])



    useEffect(() => {

    }, [tabValue])

    const handleChecked = (order) => {
        const newOrderChecked = [...checkedOrders];
        const billTotal = order?.billTotal;
        const orderId = order?._id;
        const pushTransactionId = order?.pushTransactionId;
        const products = order?.items
        const deficit = order?.deficit
        const customerBalance = order?.customerBalance
        const createdAt = order?.createdAt


        const index = newOrderChecked.findIndex((item) => item.orderId === orderId);

        if (index !== -1) {
            newOrderChecked.splice(index, 1);
        } else {
            newOrderChecked.push({ orderId, billTotal, pushTransactionId, products, deficit, customerBalance, createdAt });
        }
        setCheckedItems(newOrderChecked);
        setOrderIds(newOrderChecked)
    };


    //handle checkbox check

    const handleCheckBox = (order) => {
        const orderChecked = [...checkedOrders]
        const orderId = order?._id;
        const currentIndex = orderChecked.findIndex((item) => item.orderId === orderId);
        if (currentIndex === -1) {
            return false;
        } else {
            return true;
        }
    }

    // GET CARD TYPES;
    const getCardTypes = async () => {
        try {
            const getCardsResp = await fetch(baseUrl + "/api/getCards", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });

            const cardData = await getCardsResp.json();
            setCardTypes(cardData.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getCardTypes();
    }, []);

    //handle voucher validation
    const [voucherNumber, setvoucherNumber] = useState("");
    const [resultSearch, setresultSearch] = useState(false);
    const [searchVoucherMessage, setsearchVoucherMessage] = useState("");
    const [VoucherInfomessage, setVoucherInfomessage] = useState("");

    const searchVoucher = async () => {
        try {
            const res = await fetch(`${baseUrl}/api/search_voucher_by_voucher_number?voucherNumber=${voucherNumber}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            const data = await res.json();

            if (data.status == "Success") {
                setresultSearch(true);
                setSearchedVoucher(data.data);
            } else {
                setsearchVoucherMessage(data.message);
                setresultSearch(false);
            }
        } catch { }
    };
    useEffect(() => {
        if (voucherNumber !== "") {
            searchVoucher();
        }
    }, [voucherNumber]);


    // PAY UNPAID BILLS;

    const payBills = async () => {
        try {
            const executePayResp = await fetch(`${baseUrl}/api/transactions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    appBuildTime: "N/A",
                    billRefNo: Date.now(),
                    businessName: localStorage.getItem("businessName"),
                    businessNo: localStorage.getItem("businessId"),
                    businessShortCode: "0000",
                    cashier: localStorage.getItem("username"),
                    customerFirstName: "N/A",
                    customerMiddleName: "N/A",
                    customerPhone: "N/A",
                    customerSecondName: "N/A",
                    items: productData,
                    paybillBalance: 0,
                    discountAmount: discountAmount,
                    paymentChanel: "Web",
                    productCategory: "--",
                    productName: "--",
                    pushyTransactionId: checkedpushTransactionIds,
                    receiptNumber: Date.now(),
                    requestType: "N/A",
                    serialNo: " N/A",
                    transactionID: Date.now(),
                    transactionType: "Cash Payment",
                    transamount: totalBill,
                    transtime: new Date(Date.now()),
                    uploadTime: new Date(Date.now()),
                    userId: localStorage.getItem("userId"),
                    versionCode: "webv1",
                    versionName: "webv1",
                }),
            })

            const response = await executePayResp.json()

            console.log(response, 'response')

            if (response.Status === "SUCCESS") {
                setpaySuccess(true);
                // window.location.reload();
                setCustomerName("");
                setPayAmount(0);
                setPayNum("");
                setPayDate("");
                setPayRef("");
                setMobilePayDiscount(0);
                setMessagesToBeFetched("");
            } else if (response.status === 400) {
                setCustomerName("");
                setPayAmount(0);
                setPayNum("");
                setPayDate("");
                setPayRef("");
                setChainedBill(true);
                setMobilePayDiscount(0);
                setMessagesToBeFetched("");
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const executePayVoucher = async () => {
        // console.log("running ===========>}}}}");
        // const OrderIds = newIds;
        // const voucherIds = creditNoteId ? "" : searchedVoucher._id;

        // const voucherBody = {
        //   transactionType: "Voucher",
        //   appBuildTime: new Date(Date.now()),
        //   orderIds: OrderIds,
        //   versionName: "webv1",
        //   voucherIds: [voucherIds],
        //   versionCode: "webv1",
        //   paymentChanel: "Web",
        //   serialNo: " N/A",
        // };

        // const creditNotebody = {
        //   transactionType: "CreditNote",
        //   appBuildTime: new Date(Date.now()),
        //   orderIds: newIds,
        //   versionName: "webv1",
        //   creditNoteId: creditNoteId,
        //   versionCode: "webv1",
        //   paymentChanel: "Web",
        //   serialNo: " N/A",
        // };

        // try {
        //   const savePayment = await fetch(baseUrl + "/api/v1/payments/pay", {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Accept: "application/json",
        //       "X-Authorization": localStorage.getItem("X-Authorization"),
        //     },
        //     body: JSON.stringify(creditNoteId ? creditNotebody : voucherBody),
        //   });

        //   if (savePayment.status === 200) {
        //     localStorage.removeItem("bankPayId");
        //     localStorage.removeItem("mpesaPayId");
        //     localStorage.removeItem("voomaPayId");
        //     localStorage.removeItem("cardPayId");
        //     localStorage.removeItem("voomaPayId");
        //     setopenVoucherPay(false);
        //     setconfirmationVoucherOPen(false);
        //     setpaySuccess(true);
        //     const paidData = await savePayment.json();
        //   }
        // } catch (error) {
        //   console.log(error.message);
        // }
    };


    const handleNext = () => {
        navigate(`/customerDetails/${customerId}?requestPayment`)
    }

    //handle search

    return (
        <Grid container direction={'column'}>
            <TabContext value={tabValue}>
                <AntTabs onChange={handleChangeTabValue}>
                    <AntTab label="Unpaid" value="unpaid" />
                    <AntTab label="Paid" value="paid" />
                    <AntTab label="Partially" value="partially" />
                </AntTabs>
                <TabPanel value="unpaid">
                    <Grid item>
                        <List>
                            {orders?.length > 0 && orders?.map((order) => (
                                <ListItem style={{ width: "100%", }}>
                                    <ListItemButton style={{ border: "1.5px solid #e1d6d6", borderRadius: "6px", width: "100%", }}>
                                        <ListItemIcon style={{ display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center" }}>
                                            <Checkbox checked={handleCheckBox(order)} onClick={() => handleChecked(order)} />
                                            <AddIcon style={{ marginRight: "10px" }} />
                                        </ListItemIcon>
                                        <ListItemText onClick={() => navigate(`/detailedbill/${order?._id}`)}>KES {order?.transamount}  served by {order?.cashier}</ListItemText>
                                        <ListItemText style={{ display: "flex", justifyContent: "flex-end" }}>{DateFormatter(order?.createdAt)}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </TabPanel>
                <TabPanel value="paid">
                    <Grid item>
                        <List>
                            {paidorders?.length > 0 && paidorders?.map((order) => (
                                <ListItem style={{ width: "100%", }}>
                                    <ListItemButton style={{ border: "1.5px solid #e1d6d6", borderRadius: "6px", width: "100%", }}>
                                        <ListItemIcon style={{ display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center" }}>
                                            {/* <Checkbox checked={handleCheckBox(order)} onClick={() => handleChecked(order)} />
                                            <AddIcon style={{ marginRight: "10px" }} /> */}
                                            <FiberManualRecordIcon style={{ color: "#17ae7b" }} />
                                        </ListItemIcon>
                                        <ListItemText onClick={() => navigate(`/detailedbill/${order?._id}`)}>KES {order?.transamount}  served by {order?.cashier}</ListItemText>
                                        <ListItemText style={{ display: "flex", justifyContent: "flex-end" }}>{DateFormatter(order?.createdAt)}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </TabPanel>
                <TabPanel value="partially">
                    <Grid item>
                        <List>
                            {partialorders?.length > 0 && partialorders?.map((order) => (
                                <ListItem style={{ width: "100%", }}>
                                    <ListItemButton style={{ border: "1.5px solid #e1d6d6", borderRadius: "6px", width: "100%", }}>
                                        <ListItemIcon style={{ display: "flex", alignItems: "center", justifyContent: "center", alignContent: "center" }}>
                                            <Checkbox checked={handleCheckBox(order)} onClick={() => handleChecked(order)} />
                                            <AddIcon style={{ marginRight: "10px" }} />
                                        </ListItemIcon>
                                        <ListItemText onClick={() => navigate(`/detailedbill/${order?._id}`)}>KES {order?.transamount}  served by {order?.cashier}</ListItemText>
                                        <ListItemText style={{ display: "flex", justifyContent: "flex-end" }}>{DateFormatter(order?.createdAt)}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </TabPanel>
            </TabContext>
            {checkedOrders.length > 0 &&
                <Grid mt={4} item display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                    <Button onClick={() => setOpenPaymentMethod(true)} style={{ background: "#fff", color: "#032541", width: "145px", border: "2px solid #032541", textTransform: "inherit", marginRight: "20px" }}>Pay Now</Button>
                    <Button onClick={handleNext} style={{ background: "#032541", color: "#fff", width: "145px", textTransform: "inherit" }}>Request Pay</Button>
                </Grid>
            }

            <Modal open={openPaymentMethod}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "30%",
                        height: "fit-content",
                    }}
                >
                    <Grid container justifyContent="space-around" direction="row" style={{ width: "100%", padding: "20px" }}>
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.5",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#041920",
                                }}
                            >
                                Choose Payment Method
                            </span>
                        </Grid>

                        <Grid item>
                            <span>
                                <CloseIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setOpenPaymentMethod(false);
                                    }}
                                />
                            </span>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" style={{ width: "100%", padding: "20px" }}>
                        {/* <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setMessagesToBeFetched("MPESA");
                                setPaymentMessageModal(true);
                                setOpenPaymentMethod(false);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px" }} src={mpesasvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                }}
                            >
                                Mpesa
                            </span>
                        </Grid> */}
                        <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setOpenCashPayment(true);
                                setOpenPaymentMethod(false);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px" }} src={cashsvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                }}
                            >
                                Cash
                            </span>
                        </Grid>

                        {/* <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setMessagesToBeFetched("VOOMA");
                                setPaymentMessageModal(true);
                                setOpenPaymentMethod(false);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px" }} src={voomasvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                }}
                            >
                                Vooma
                            </span>
                        </Grid> */}

                        {/* <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setMessagesToBeFetched("EQUITEL");
                                setPaymentMessageModal(true);
                                setOpenPaymentMethod(false);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px" }} src={equitelsvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                }}
                            >
                                Equitel
                            </span>
                        </Grid>

                        <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setMessagesToBeFetched("CARD");
                                setOpenPaymentMethod(false);
                                setCardModalOpen(true);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px" }} src={cardsvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                }}
                            >
                                Card
                            </span>
                        </Grid> */}
                        {/* 
                        <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setBankPay(true);
                                setOpenPaymentMethod(false);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px" }} src={banksvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                }}
                            >
                                Banks
                            </span>
                        </Grid> */}
                        <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                // setBankPay(true);
                                setOpenPaymentMethod(false);
                                setopenVoucherPay(true);
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px", height: "49px" }} src={vouchersvg} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                    marginTop: "10px",
                                }}
                            >
                                Vouchers
                            </span>
                        </Grid>

                        <Grid
                            item
                            style={{
                                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                backgroundColor: "#fff",
                                margin: "0px 0px 5px 0px",
                                padding: "5px 0px 5px 5px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setOpenCreditNote(true);
                                setOpenPaymentMethod(false);
                                // setopenVoucherPay(true)
                            }}
                        >
                            <img style={{ padding: "5px 20px 5px 15px", height: "49px" }} src={creditnote} />{" "}
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "0.71",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#032541",
                                    marginTop: "10px",
                                }}
                            >
                                Credit Note
                            </span>
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            {/* CASH PAYMENT MODAL */}
            <Modal open={openCashModal} onClose={() => setOpenCashPayment(false)}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "30%",
                        height: "450px",
                    }}
                >
                    <Grid container style={{ padding: "20px" }}>
                        <Grid item style={{ width: "100%" }}>
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "2.08",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "#032541",
                                }}
                            >
                                {" "}
                                <Checkbox checked={checkedDiscount} onChange={setCheckledStatus} inputProps={{ "aria-label": "controlled" }} />
                                Discount
                            </p>
                        </Grid>

                        <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center">
                            <Grid item>
                                <p style={{ margin: "0", textAlign: "center" }}>
                                    <img src={cashpayment} />
                                </p>
                                <p
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAlign: "center",
                                        color: "#032541",
                                        margin: "0px",
                                    }}
                                >
                                    Cash Payment
                                </p>

                                <p
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAlign: "center",
                                        color: "#032541",
                                        margin: "0px",
                                    }}
                                >
                                    Sub Total = {numberFormat(totalBill - discountAmount)}
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item style={{ width: "100%" }}>
                                <Box
                                    style={{ display: "flex", flexDirection: "column" }}
                                    component="form"
                                    sx={{
                                        "& > :not(style)": { m: 1, width: "90%" },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    {checkedDiscount && <TextField id="outlined-basic" label="Enter Cash Discount" variant="outlined" value={discountAmount} type="number" onChange={(e) => setDiscountAmount(e.target.value)} />}

                                    <TextField id="outlined-basic" label="Enter Cash Recieved" variant="outlined" value={amountRecieved} type="number" onChange={(e) => setAmountRecieved(e.target.value)} />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container style={{ width: "100%" }} justifyContent="center" alignItems="center">
                            <Button
                                style={{
                                    borderRadius: "5px",
                                    border: "solid 1px #dc3545",
                                    color: "#dc3545",
                                    padding: "10px 40px 10px 40px",
                                    margin: "0px 20px 0px 0px",
                                }}
                                onClick={() => setOpenCashPayment(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "#032541",
                                    padding: "10px 40px 10px 40px",
                                    margin: "0px 0px 0px 20px",
                                    color: "#ffffff",
                                }}
                                onClick={() => {
                                    setOpenCashPayment(false);
                                    setPayDetails(true);
                                }}
                            >
                                Pay
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            {/* CONFROM PAY MODAL */}
            <Modal open={payDetails} onClose={() => setPayDetails(false)}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "25%",
                        height: "400px",
                    }}
                >
                    <Grid container style={{ width: "100%", padding: "20px" }} alignItems="center" justifyContent="center">
                        <Grid item>
                            <p style={{ margin: "0", textAlign: "center" }}>
                                <img src={cashpayment} />
                            </p>
                            <p
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "center",
                                    color: "#032541",
                                    margin: "0px",
                                }}
                            >
                                Cash Payment
                            </p>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        style={{
                            width: "100%",
                            padding: "0px 30px 0px 30px",
                            margin: "0px 0px 10px 0px",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#b2b4bb",
                                }}
                            >
                                Bill Amount:
                            </span>
                        </Grid>
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "#032541",
                                }}
                            >
                                {numberFormat(totalBill)}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        style={{
                            width: "100%",
                            padding: "0px 30px 0px 30px",
                            margin: "0px 0px 10px 0px",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#b2b4bb",
                                }}
                            >
                                Amount Recieved:
                            </span>
                        </Grid>
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "#032541",
                                }}
                            >
                                {numberFormat(amountRecieved)}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        style={{
                            width: "100%",
                            padding: "0px 30px 0px 30px",
                            margin: "0px 0px 10px 0px",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#b2b4bb",
                                }}
                            >
                                Discount:
                            </span>
                        </Grid>
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "#032541",
                                }}
                            >
                                {numberFormat(discountAmount)}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        style={{
                            width: "100%",
                            padding: "0px 30px 0px 30px",
                            margin: "0px 0px 10px 0px",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#b2b4bb",
                                }}
                            >
                                Total:
                            </span>
                        </Grid>
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "#032541",
                                }}
                            >
                                {numberFormat(totalBill - discountAmount)}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        style={{
                            width: "100%",
                            padding: "0px 30px 0px 30px",
                            margin: "0px 0px 20px 0px",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#b2b4bb",
                                }}
                            >
                                Balance:
                            </span>
                        </Grid>
                        <Grid item>
                            <span
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "#032541",
                                }}
                            >
                                {Math.sign(balance) === -1 ? numberFormat(balance * -1) : numberFormat(balance)}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Button
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "5px",
                                    border: "solid 1px #dc3545",
                                    color: "#dc3545",
                                    padding: "10px 20px 10px 20px",
                                    margin: "0px 0px 0px 20px",
                                }}
                                onClick={() => {
                                    setPayDetails(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "5px",
                                    backgroundColor: "#032541",
                                    color: "#ffffff",
                                    padding: "10px 20px 10px 20px",
                                    margin: "0px 0px 0px 20px",
                                }}
                                onClick={() => {
                                    setPayDetails(false);
                                    payBills();
                                }}
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            <Modal open={confirmationVoucherOPen} onClose={closeconfirmationVoucher} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        borderColor: "transparent",
                        borderRadius: "36px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "387px",
                        height: "382px",
                    }}
                >
                    <div
                        style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "100px",
                            padding: "12px 30px 80px",
                            objectFit: "contain",
                        }}
                    >
                        <img style={{ width: "82px", height: "82px", display: "flex", marginTop: "20px", justifyContent: "center", alignItems: "center" }} src={powericon} alt="img" />
                    </div>
                    <h4
                        style={{
                            fontSize: "22px",
                            fontWeight: " bold",
                            fontStretch: "normal",
                            fontStyle: " normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "center",
                            color: " #032541",
                            marginTop: "24px",
                            color: "#032541",
                        }}
                    >
                        Confirm Payment
                    </h4>
                    <h4 style={{ padding: "0 60px 10px 60px", fontSize: "18px" }}>
                        Are you sure you want to pay with Voucher No. <strong className="text-success">{searchedVoucher?.voucherNo}</strong>
                    </h4>
                    <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                        Amount <strong className="text-success">{numberFormat(searchedVoucher?.amount)}</strong>
                    </h4>
                    <div className="button-row" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                        <Button
                            type="submit"
                            variant="standard"
                            style={{
                                color: "#dc3445",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                width: "140px",
                                height: "40px",
                                margin: "20px 0px 0 0px",
                                border: "solid 1px  #dc3545",
                            }}
                            onClick={closeconfirmationVoucher}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="standard"
                            style={{
                                color: "#fff",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                width: "140px",
                                height: "40px",
                                margin: "20px 0px 0 0px",
                                backgroundColor: "#032541",
                            }}
                            onClick={() => {
                                executePayVoucher();
                                ClosevoucherValidationClose();
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </Box>
            </Modal>

            {/* SAVE BILL MODAL */}
            <Modal open={paySuccess} onClose={() => setpaySuccess(false)}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "300px",
                        height: "250px",
                    }}
                >
                    <div style={{ width: "100%", padding: "20px" }}>
                        <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                            <img
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "contain",
                                }}
                                src={approvetick}
                            />
                        </p>

                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#032541",
                            }}
                        >
                            Success
                        </p>

                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "17px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#032541",
                                margin: "0px 0px 10px 0px",
                            }}
                        >
                            Payment Recieved successfully!
                        </p>

                        <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                            <Button
                                style={{
                                    textAlign: "center",
                                    margin: "0px 20px 0px 0px",
                                    backgroundColor: "#032541",
                                    color: "#ffffff",
                                }}
                                onClick={() => {
                                    setpaySuccess(false);
                                    window.location.reload();
                                }}
                            >
                                ok
                            </Button>

                            <Button
                                style={{
                                    textAlign: "center",
                                    backgroundColor: "#032541",
                                    color: "#ffffff",
                                    margin: "0px 20px 0px 0px",
                                }}
                            // onClick={() => {
                            //   closeStoreBill();
                            //   navigate("/orders");
                            // }}
                            >
                                share
                            </Button>
                        </p>
                    </div>
                </div>
            </Modal>

            {/* MPESA PAYMENTS */}
            <Modal
                open={paymentMessageModal}
            // onClose={() => setPaymentMessageModal(false)}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "30%",
                        height: "700px",
                    }}
                >
                    <Box
                        style={{
                            width: "100%",
                            backgroundColor: "#dc3545",
                            borderRadius: "15px 15px 0px 0px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={{ color: "#ffffff" }}>
                            <ArrowBackIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPaymentMessageModal(false);
                                    // window.location.reload()
                                    redirect("/orders");
                                }}
                            />
                        </span>
                        <span
                            style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "0.71",
                                letterSpacing: "normal",
                                textAlign: "left",
                            }}
                        >
                            Received Payment
                        </span>
                        <span style={{ color: "#ffffff" }}>
                            <CloseIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPaymentMessageModal(false);
                                    window.location.reload();
                                }}
                            />
                        </span>
                    </Box>

                    <Grid container style={{ width: "100%", padding: "20px" }}>
                        <Grid item style={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
                            <span style={{ fontFamily: "Poppins", fontWeight: "bold" }}>Bill Total = {numberFormat(payTransAmountTotal)}</span>
                            <span>
                                {" "}
                                <Checkbox onClick={() => setMobilePayDiscountToggle(!mobilePayDiscountToggle)} /> Discount{" "}
                            </span>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <TextField label="Search Messages" style={{ width: "100%" }} helperText={"Search"} onChange={(e) => setMobilePayQuery(e.target.value)} />
                        </Grid>

                        {mobilePayDiscountToggle && (
                            <Grid item style={{ width: "100%" }}>
                                <TextField label="Discount" style={{ width: "100%" }} helperText={"Discount"} type="number" onChange={(e) => setMobilePayDiscount(e.target.value)} />
                            </Grid>
                        )}

                        {/* LOOP THROUGH ALL MESSAGES; */}
                        <Grid
                            item
                            style={{
                                width: "100%",
                                height: "40vh",
                                overflow: "auto",
                            }}
                        >
                            {messagesCollection?.length > 0 && (
                                <List>
                                    {messagesCollection
                                        ?.filter((messo) => {
                                            if (mobilePayQuery.toLocaleLowerCase() === "") {
                                                return messo;
                                            } else if (messo.transactionID.toLocaleLowerCase().includes(mobilePayQuery.toLocaleLowerCase()) || messo.phoneNumber.includes(mobilePayQuery)) {
                                                return messo;
                                            }
                                        })
                                        ?.map((messo, index) => {
                                            let { customerName, amount, transactionID, phoneNumber, day, hour, meridian, minute, month, sms, year, smsType } = messo;
                                            // phoneNumber.replace(/(\d{5})\d{3}(\d{4})/, "$1***$2")

                                            function replaceAtMultipleIndexes(str, indexes, newValue) {
                                                let result = str;
                                                for (let i = 0; i < indexes?.length; i++) {
                                                    const index = indexes[i];
                                                    result = result.substr(0, index) + newValue + result.substr(index + newValue?.length);
                                                }
                                                return result;
                                            }
                                            let myString = phoneNumber;
                                            let indexes = [6, 7, 8];
                                            let newValue = "*";
                                            let newPhone = replaceAtMultipleIndexes(myString, indexes, newValue);

                                            return (
                                                <ListItem
                                                    key={index}
                                                    disablePadding
                                                    onClick={() => {
                                                        const date = ` ${day}/${month}/${year}`;

                                                        setCustomerName(customerName);
                                                        setPayAmount(amount);
                                                        setPayNum(phoneNumber);
                                                        setPayDate(date);
                                                        setPayRef(transactionID);

                                                        if (amount > 'itemmsTotal' - mobilePayDiscountAmt || amount > 'itemmsTotal' - 'bankPayDiscount') {
                                                            setPaymentMessageModal(false);
                                                            setMpesaAmountModal(true);
                                                        } else {
                                                            setPaymentMessageModal(false);
                                                            setConfirmPayModal(true);
                                                            unUseMessage(messo);
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton>
                                                        <ListItemText
                                                            style={{
                                                                fontFamily: "Poppins",
                                                                fontSize: "15px",
                                                                fontWeight: "500",
                                                                fontStretch: "normal",
                                                                fontStyle: "normal",
                                                                lineHeight: "2.2",
                                                                letterSpacing: "normal",
                                                                textAlign: "left",
                                                                color: "#6e7074",
                                                            }}
                                                            primary={`${transactionID}  ${customerName} ${"+" + newPhone}  ${numberFormat(amount)} ${day}/${month}/${year}  ${hour}.${minute} ${meridian}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            )}

                            {messagesCollection?.length === 0 && <span>No Messages to show</span>}
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            {/* BANK PAYMENTS */}
            <Modal
                open={bankPay}
            // onClose={() => setBankPay(false)}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "25%",
                        height: "500px",
                    }}
                >
                    <Box
                        style={{
                            width: "100%",
                            backgroundColor: "#dc3545",
                            borderRadius: "15px 15px 0px 0px",
                            padding: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={{ color: "#ffffff" }}>
                            <ArrowBackIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setBankPay(false);
                                    setBanDetailsDropDown(true);
                                    setBankDetailsMessages(false);
                                }}
                            />
                        </span>
                        <span
                            style={{
                                color: "#ffffff",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "0.71",
                                letterSpacing: "normal",
                                textAlign: "left",
                            }}
                        >
                            Received Payment
                        </span>
                        <span style={{ color: "#ffffff" }}>
                            <CloseIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setBankPay(false);
                                    setBanDetailsDropDown(true);
                                    setBankDetailsMessages(false);
                                }}
                            />
                        </span>
                    </Box>

                    <Grid container style={{ width: "100%", padding: "20px" }}>
                        <Grid item style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <span style={{ fontFamily: "Poppins", fontWeight: "bold", width: "50%" }}>Total = {numberFormat('itemmsTotal - bankPayDiscount')}</span>
                            <span>
                                Discount <Checkbox onClick={() => setToggleBankPay(!togglebankPay)} />
                            </span>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <TextField label="Search Messages" value={bankPayQuery} onChange={(e) => setBankPayQuery(e.target.value)} style={{ width: "100%" }} helperText={"Search"} />
                        </Grid>
                        {togglebankPay && (
                            <Grid item style={{ width: "100%" }}>
                                <TextField label="Discount" style={{ width: "100%" }} helperText={"Discount"} type="number" defaultValue={bankPayDiscount} onChange={(e) => setBankPayDiscount(e.target.value)} />
                            </Grid>
                        )}

                        {/* LOOP THROUGH ALL MESSAGES; */}
                        <Grid
                            item
                            style={{
                                width: "100%",
                                height: "40vh",
                                overflow: "auto",
                            }}
                        >
                            {bankDetailsDropDown && (
                                <>
                                    <Grid
                                        item
                                        style={{
                                            backgroundColor: "#deedfa",
                                            width: "100%",
                                            padding: "20px",
                                            margin: "0px 0px 10px 0px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setMessagesToBeFetched("DTB");
                                            setBankDetailsMessages(true);
                                            setBanDetailsDropDown(false);
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontStyle: "normal",

                                                letterSpacing: "normal",
                                                textAlign: "left",
                                                color: "#032541",
                                            }}
                                        >
                                            DTB
                                        </span>
                                    </Grid>

                                    <Grid
                                        item
                                        style={{
                                            backgroundColor: "#deedfa",
                                            width: "100%",
                                            padding: "20px",
                                            margin: "0px 0px 10px 0px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setMessagesToBeFetched("COOP");

                                            setBankDetailsMessages(true);
                                            setBanDetailsDropDown(false);
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontStyle: "normal",

                                                letterSpacing: "normal",
                                                textAlign: "left",
                                                color: "#032541",
                                            }}
                                        >
                                            COOP-BANK
                                        </span>
                                    </Grid>

                                    <Grid
                                        item
                                        style={{
                                            backgroundColor: "#deedfa",
                                            width: "100%",
                                            padding: "20px",
                                            margin: "0px 0px 10px 0px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setMessagesToBeFetched("ABSA");

                                            setBankDetailsMessages(true);
                                            setBanDetailsDropDown(false);
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontStyle: "normal",

                                                letterSpacing: "normal",
                                                textAlign: "left",
                                                color: "#032541",
                                            }}
                                        >
                                            ABSA
                                        </span>
                                    </Grid>
                                </>
                            )}

                            {bankDetailsMessages && (
                                <>
                                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                        {/* LOOP THROUGH MESSAGES TO MAP */}
                                        {messagesCollection
                                            ?.filter((messo) => {
                                                if (bankPayQuery.toLocaleLowerCase() === "") {
                                                    return messo;
                                                } else if (messo.transactionID.toLocaleLowerCase().includes(bankPayQuery.toLocaleLowerCase()) || messo.phoneNumber.includes(bankPayQuery)) {
                                                    return messo;
                                                }
                                            })
                                            ?.map((messo) => {
                                                // console.log(messo);
                                                const { customerName, amount, transactionID, phoneNumber, day, hour, meridian, minute, month, sms, year, smsType } = messo;

                                                function replaceAtMultipleIndexes(str, indexes, newValue) {
                                                    let result = str;
                                                    for (let i = 0; i < indexes?.length; i++) {
                                                        const index = indexes[i];
                                                        result = result.substr(0, index) + newValue + result.substr(index + newValue?.length);
                                                    }
                                                    return result;
                                                }
                                                let myString = phoneNumber;
                                                let indexes = [6, 7, 8];
                                                let newValue = "*";
                                                let newPhone = replaceAtMultipleIndexes(myString, indexes, newValue);

                                                return (
                                                    <ListItem
                                                        // key={bill._id}
                                                        sx={{
                                                            // border: "1px solid",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-start",
                                                            width: "100%",
                                                        }}
                                                        component="div"
                                                        // value={listItemValue}
                                                        onClick={() => {
                                                            const date = ` ${day}/${month}/${year}`;
                                                            setBankPayName(customerName);
                                                            setBankPayAmount(amount);
                                                            setBankPayNum(phoneNumber);
                                                            setBankPayDate(date);
                                                            setBankPayRef(transactionID);

                                                            if (amount > 'itemmsTotal' - bankPayDiscount) {
                                                                setPaymentMessageModal(false);
                                                                setMpesaAmountModal(true);
                                                            } else {
                                                                setBankPay(false);
                                                                setPaymentMessageModal(false);
                                                                setConfirmPayModal(true);
                                                                unUseMessage(messo);
                                                            }
                                                        }}
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                border: "1.5px solid #e1d6d6",
                                                                borderRadius: "6px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <ListItemText
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "15px",
                                                                    fontWeight: "500",
                                                                    fontStretch: "normal",
                                                                    fontStyle: "normal",
                                                                    lineHeight: "2.2",
                                                                    letterSpacing: "normal",
                                                                    textAlign: "left",
                                                                    color: "#6e7074",
                                                                }}
                                                                primary={``}
                                                            />

                                                            <ListItemText
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontSize: "15px",
                                                                    fontWeight: "500",
                                                                    fontStretch: "normal",
                                                                    fontStyle: "normal",
                                                                    lineHeight: "2.2",
                                                                    letterSpacing: "normal",
                                                                    textAlign: "left",
                                                                    color: "#6e7074",
                                                                }}
                                                                primary={`${transactionID}  ${customerName} ${newPhone}  ${numberFormat(amount)} ${day}/${month}/${year}  ${hour}.${minute} ${meridian}`}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                    </List>
                                    {/* empty state transaction */}
                                    {messagesCollection?.length === 0 && (
                                        <Grid item style={{ width: "100%" }}>
                                            <p style={{ textAlign: "center" }}>
                                                <img
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "fit-content",
                                                        margin: "0px",
                                                    }}
                                                    src={emptyState}
                                                />
                                            </p>
                                            <p style={{ textAlign: "center", margin: "0px" }}>No tranasctions added transactions will appear here</p>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            {/* CARD PAY MODAL */}
            {/* CARD PAYMENT MODAL */}
            <Modal open={cardModal} onClose={() => setCardModalOpen(false)}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "30%",
                        height: "fit-content",
                    }}
                >
                    <Box style={{ width: "100%", padding: "10px" }}>
                        <p style={{ textAlign: "right" }}>
                            <Checkbox value={cardDiscount} onChange={(e) => setToggleCardDiscount(!cardDiscount)} /> Discount
                        </p>
                    </Box>

                    <Grid container direction="row" style={{ width: "100%" }}>
                        <Grid item style={{ width: "100%" }}>
                            <p
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    margin: "0",
                                    lineHeight: "1.7",
                                }}
                            >
                                <img
                                    style={{
                                        width: "70px",
                                        height: "70px",
                                        objectFit: "contain",
                                    }}
                                    src={cardpay}
                                />
                            </p>
                            <p
                                style={{
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.7",
                                    letterSpacing: "normal",
                                    textAlign: "center",
                                    color: "#032541",
                                    margin: "0",
                                }}
                            >
                                Card Payment
                            </p>
                            <p
                                style={{
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    // lineHeight: "1.8",
                                    letterSpacing: "normal",
                                    textAlign: "center",
                                    color: "#032541",
                                    // margin:"0"
                                }}
                            >
                                Total = {numberFormat('itemmsTotal - cardDiscountAmount')}
                            </p>
                        </Grid>

                        <Grid container style={{ width: "100%", padding: "20px" }}>
                            <Grid item style={{ width: "100%" }}>
                                {cardDiscount && (
                                    <Grid item style={{ width: "100%" }}>
                                        <TextField id="outlined-select-meals" label="Discount" style={{ width: "100%" }} defaultValue={cardDiscountAmount} helperText={"Enter discount"} onChange={(e) => setCardDiscountAmount(e.target.value)} type="number" />
                                    </Grid>
                                )}

                                <TextField id="outlined-select-meals" select label="Card Type" style={{ width: "100%" }} defaultValue={getCardType} helperText={"Select Card Type"} onChange={(e) => setCardType(e.target.value)}>
                                    {cardTypes?.length > 0 &&
                                        cardTypes?.map((card, index) => {
                                            return (
                                                <MenuItem key={index} value={card.cardType}>
                                                    {card.cardType}
                                                </MenuItem>
                                            );
                                        })}
                                </TextField>
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                                <TextField id="outlined-select-meals" label="" style={{ width: "100%" }} defaultValue={refNo} helperText={"Select Reference Number"} onChange={(e) => setRefNo(e.target.value)} />
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                                <TextField id="outlined-select-meals" select label="acquirer" style={{ width: "100%" }} defaultValue={getAcquirer} helperText={"acquirer"} onChange={(e) => setAcquirer(e.target.value)}>
                                    {getAcquirers?.length > 0 &&
                                        getAcquirers?.map((acquirer, index) => {
                                            return (
                                                <MenuItem key={index} value={acquirer.name}>
                                                    {acquirer.name}
                                                </MenuItem>
                                            );
                                        })}
                                </TextField>
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                                <TextField id="outlined-select-meals" label="Customer's Name" style={{ width: "100%" }} defaultValue={cardCustomer} helperText={"Enter name of the  Customer's Name"} onChange={(e) => setCardCustomerName(e.target.value)} />
                            </Grid>
                        </Grid>

                        <Grid container style={{ width: "100%", padding: "10px" }} direction="row" justifyContent={"center"} alignItems="center">
                            <Grid item>
                                <Button
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAlign: "left",
                                        color: "#dc3545",
                                        border: "1px solid #dc3545",
                                        margin: "0px 20px 0px 0px",
                                    }}
                                    onClick={() => setCardModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    style={{
                                        objectFit: "contain",
                                        borderRadius: "5px",
                                        backgroundColor: "#032541",
                                        color: "#ffffff",
                                        margin: "0px 0px 0px 20px",
                                    }}
                                    onClick={() => {
                                        setConfirmPatDetails(true);
                                        setCardModalOpen(false);
                                    }}
                                >
                                    Pay Now
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            {/* CONFIRM CARD PAY */}
            <Modal open={confirmPayDetails} onClose={() => setConfirmPatDetails(false)}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "15px",
                        backgroundColor: "#fff",
                        width: "20%",
                        height: "fit-content",
                    }}
                >
                    <Box>
                        <p style={{ textAlign: "center" }}>
                            <img style={{ objectFit: "contain" }} src={powericon} />
                        </p>

                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#032541",
                            }}
                        >
                            Confirm Payment
                        </p>

                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#032541",
                            }}
                        >
                            Are you sure you want to pay with
                        </p>

                        <br />

                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#032541",
                            }}
                        >
                            {getCardType}?
                        </p>

                        <p
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#032541",
                            }}
                        >
                            For {cardCustomer} Amount {numberFormat('itemmsTotal - cardDiscountAmount')}
                        </p>

                        <p style={{ textAlign: "center" }}>
                            <Button
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#dc3545",
                                    border: "1px solid #dc3545",
                                    margin: "0px 20px 0px 0px",
                                }}
                                onClick={() => setConfirmPatDetails(false)}
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "5px",
                                    backgroundColor: "#032541",
                                    color: "#ffffff",
                                    margin: "0px 0px 0px 20px",
                                }}
                                onClick={() => {
                                    setConfirmPatDetails(false);
                                    payBills();
                                    //
                                }}
                            >
                                Confirm
                            </Button>
                        </p>
                    </Box>
                </div>
            </Modal>
            {/* voucher payment */}
            <Modal open={openVoucherPay} onClose={closeVoucherPay} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={AddProductModalstyle}>
                    <Box
                        sx={{
                            width: "100%",
                            padding: "33.8px 55.6px 0.8px 43.6px",
                        }}
                    >

                        <div className="topbar-voucher d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
                            <ArrowBackIcon onClick={closeVoucherPay} />
                            <h6>Voucher</h6>
                            <CloseIcon onClick={closeVoucherPay} />
                        </div>
                        <div className="billDate">
                            <div
                                className="d-flex "
                                style={{
                                    width: "407px",
                                    height: " 54px",
                                    margin: " 28px 0 20px 0px",
                                    padding: "17px 10px 16px",
                                    display: "flex",
                                    justifyContent: "space-between",

                                    borderRadius: "6px",
                                    backgroundColor: "#d8eaf5",
                                }}
                            >
                                <h4
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        fontStretch: " normal",
                                        fontStyle: "normal",
                                        lineHeight: "1.67",
                                        letterSpacing: "normal",
                                        textAlign: "left",
                                        color: "#032541",
                                    }}
                                >
                                    Total Bill Amount
                                </h4>
                                <h4
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        fontStretch: " normal",
                                        fontStyle: "normal",
                                        lineHeight: "1.67",
                                        letterSpacing: "normal",
                                        textAlign: "left",
                                        color: "#17ae7b",
                                    }}
                                >
                                    {numberFormat(totalBill - discountAmount)}
                                </h4>
                            </div>
                        </div>
                        <div className="search-holder">
                            <input onChange={(e) => setvoucherNumber(e.target.value)} style={{ width: "400px", padding: "17px 10px 16px", height: " 54px", fontSize: "13px", fontWeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#b4bcc4", outline: "none", border: "solid grey 1px", borderRadius: "5px" }} placeholder="Search voucher number or Scan code" type="text" name="" id="" />
                        </div>
                        {resultSearch === false ? (
                            <span
                                style={{
                                    fontSize: "14px",
                                    marginTop: "20px",
                                    color: "red",
                                }}
                            >
                                {searchVoucherMessage}
                            </span>
                        ) : (
                            <>
                                <div
                                    className="voucher"
                                    style={{
                                        margin: " 25px 14px 85px 15px",
                                        padding: "17px 18px 16px",
                                        borderRadius: "6px",
                                        border: "solid 1px #707070",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    {console.log("resultSearch VOUCHERSSSSS", resultSearch)}

                                    <div
                                        className="voucher-component"
                                        onClick={() => {
                                            if (searchedVoucher.usage_status == "USED") {
                                                setVoucherInfomessage("The voucher have already been used");
                                                settitleMessage("Voucher Already used");
                                                setvoucherValidationOpen(true);
                                            } else if (totalBill - discountAmount < searchedVoucher.amount) {
                                                setVoucherInfomessage("The voucher amount exceeds total bill kindly add some item");
                                                settitleMessage("Voucher Bill");
                                                setvoucherValidationOpen(true);
                                            } else if (searchedVoucher.usage_status == "EXPIRED") {
                                                setVoucherInfomessage("The voucher has already expired");
                                                settitleMessage("Voucher Expired");
                                                setvoucherValidationOpen(true);
                                            } else {
                                                setconfirmationVoucherOPen(true);
                                                setvoucherValidationOpen(false);
                                                setopenVoucherPay(false);
                                            }
                                        }}
                                    >
                                        <div className="d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div className="voucher-details">
                                                <div
                                                    style={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        fontStretch: "normal",
                                                        fontStyle: "normal",
                                                        color: "#032541",
                                                    }}
                                                >
                                                    Voucher No. {searchedVoucher?.voucherNo}
                                                </div>
                                                <div
                                                    style={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "11px",
                                                        fontWeight: "500",
                                                        fontStretch: "normal",
                                                        fontStyle: "normal",
                                                        color: "#032541",
                                                    }}
                                                >
                                                    Voucher Amount: <span className="amount">{numberFormat(searchedVoucher?.amount)}</span>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "11px",
                                                        fontWeight: "500",
                                                        fontStretch: "normal",
                                                        fontStyle: "normal",
                                                        color: "#032541",
                                                    }}
                                                >
                                                    {" "}
                                                    Expiry Date <span> {moment(searchedVoucher?.expiryDate).format("DD/MM/YYYY:HH:MM:SS")}</span>{" "}
                                                </div>
                                            </div>
                                            <div className="voucher-status">
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        marginTop: "10px",
                                                        fontWeight: "500",
                                                        fontStretch: "normal",
                                                        fontStyle: "normal",
                                                        color: "#17ae7b",
                                                    }}
                                                >
                                                    {searchedVoucher?.usage_status}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Box>
                </Box>
            </Modal>
            <Modal open={voucherValidationOpen} onClose={ClosevoucherValidationClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div
                        style={{
                            width: "50px",
                            height: "50px",
                            marginLeft: "100px",
                            padding: "12px 30px 80px",
                            objectFit: "contain",
                        }}
                    >
                        <img style={{ width: "82px", height: "82px", margin: "4px 0px 0px -15px" }} src={powericon} alt="img" />
                    </div>
                    <h4
                        style={{
                            fontSize: "18px",
                            fontWeight: " bold",
                            fontStretch: "normal",
                            fontStyle: " normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "center",
                            color: " #032541",
                        }}
                    ></h4>

                    <h4
                        style={{
                            fontSize: "18px",
                            fontWeight: " bold",
                            fontStretch: "normal",
                            fontStyle: " normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "center",
                            color: "green",
                        }}
                    >
                        {titleMessage}
                    </h4>
                    <h4
                        style={{
                            marginTop: "16px",
                            fontSize: "16px",
                            textAlign: "center",
                        }}
                    >
                        {VoucherInfomessage}
                    </h4>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            type="submit"
                            variant="standard"
                            style={{
                                color: "#fff",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                width: "240px",
                                height: "40px",
                                margin: "20px 10px 0 30px",
                                backgroundColor: "#032541",
                            }}
                            onClick={ClosevoucherValidationClose}
                        >
                            ok
                        </Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={chainedBillModal} onClose={() => setChainedBill(false)}>
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: "15px", backgroundColor: "#fff", width: "20%", height: "fit-content" }}>
                    <Box style={{ width: "100%", padding: "10px", fontFamily: "Poppins", color: "#032541" }}>
                        <p style={{ textAlign: "center" }}>
                            <img src={powericon} />
                        </p>

                        <p style={{ textAlign: "center" }}>You can't combine bills and make a partial payment</p>

                        <p style={{ textAlign: "center" }}>
                            <Button
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    color: "#dc3545",
                                    border: "1px solid #dc3545",
                                    margin: "0px 20px 0px 0px",
                                }}
                                onClick={() => window.location.reload()}
                            >
                                Cancel
                            </Button>

                            <Button
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "5px",
                                    backgroundColor: "#032541",
                                    color: "#ffffff",
                                    margin: "0px 0px 0px 20px",
                                }}
                                onClick={() => window.location.reload()}
                            >
                                Ok
                            </Button>
                        </p>
                    </Box>
                </div>
            </Modal>
            {/* VOUCHER PAYMENTS */}
            {openCreditNote && <CreditnotepayModal billTotal={totalBill} openCreditNote={openCreditNote} setCreditnotesearch={setCreditnotesearch} creditNoteData={creditNoteData} creditnotesearch={creditnotesearch} creditNoteError={creditNoteError} setCreditNotePayId={setCreditNotePayId} setOpenCreditNote={setOpenCreditNote} setCreditNoteId={setCreditNoteId} setopenConfirmCredit={setopenConfirmCredit} creditNotedetails={creditNotedetails} setCreditNoteDetails={setCreditNoteDetails} setOpenCreditPayOverPayment={setOpenCreditPayOverPayment} />}
            {openConfirmCredit && <ConfirmPayCreditNote openConfirmCredit={openConfirmCredit} setopenConfirmCredit={setopenConfirmCredit} creditNoteAmount={creditNotedetails.amount} creditNoteNumber={creditNotedetails.no} executePayVoucher={executePayVoucher} />}
            {openCreditpayOverPayment && <ConfirmOverPaymentCreditnote openCreditpayOverPayment={openCreditpayOverPayment} setOpenCreditPayOverPayment={setOpenCreditPayOverPayment} message={"The Bill Amount is less than the credit note amount add more items to redeem the credit note "} />}
        </Grid>
    )
}