import { Grid, Stack, Typography, TextField, Button, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/userGroupToUpdate";
import { handleAdded, handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { handleTabChange } from "../../../../features/currentTabSlice";


const baseUrl = process.env.REACT_APP_BASE_URL;

function AddUserGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //value to set when user edits form
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleType = (e) => {
    setType(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  //updating the details object
  const [localDetails, setLocalDetails] = useState({});

  const handleAdd = () => {
    try {
      axios
        .post(baseUrl + "/api/AddUserGroup", {
          name,
          type,
          description,
        })
        .then((response) => {
          if (response) {
            let data = response.data.data;
            if (data) {
              console.log("added successfully");
              setLocalDetails({
                name: name,
                description: description,
              });
              dispatch(handleAdded(true));
            } else {
              console.log("unsuccessfull");
              setFailedMessage(response.data.message);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Object.getOwnPropertyNames(localDetails).length > 0) {
      dispatch(setDetails(localDetails));
      navigate("/UserGroups");
    }
  }, [localDetails]);

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [failMessage, setFailedMessage] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailedMessage(null);
        // dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Add User Group
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>

      <Breadcrumbs2
        point1={"Partners"}
        link1={"/Partners"}
        point2={"Add Partner"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Add User Group
            </Typography>
            <TextField
              label="User Group Name"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleName}
            />
            <TextField
              label="User Group Type"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleType}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              size="small"
              //   defaultValue={reduxDescription}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDescription}
            />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => {
                  dispatch(handleTabChange("Active"));
                  navigate("/UserGroups");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddUserGroup;
