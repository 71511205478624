import {Breadcrumbs, Button, ButtonBase, Grid, Tab, Typography} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CurrencyFormatter from "../../../../utils/currencyFormatter";
import CustomTable from "../../../School/CustomTable";
import HttpComponent from "../../../School/MakeRequest";
import {useSelector} from "react-redux";
import CustomSearchInput from "../../../School/CustomSearchInput";
import {makeStyles} from "@mui/styles";

// Bread Crumbs
const breadcrumbs = [
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Receipts</Typography>
];

const useStyles = makeStyles((theme) => ({
	unpaid: {
		background : 'rgba(220, 53, 69, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	paid : {
		background : 'rgba(23, 174, 123, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	overPaid : {
		background : 'rgba(23, 174, 123, 0.1)',
		color : "#272d3b",
		borderRadius : "4px",
		padding : "5px"
	},
	partiallyPaid : {
		color : "#272d3b",
		background : 'rgba(255, 133, 3, 0.1)',
		borderRadius : "4px",
		padding : "5px"

	}
}));


// Custom Tab Styling
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
	fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
	fontSize: "18px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
	letterSpacing: "normal", textAlign: "left", color: "#6e7074", borderRadius : '4px', height : "5px", backgroundColor : "#f5f6f7", fontFamily: ["Poppins"].join(","),
	"&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#fff", backgroundColor : "#cb444a", fontWeight: 600, borderRadius : '4px', height : "5px"},
	"&.Mui-focusVisible": { backgroundColor: "blue" },
}));

//receiptNo

export default function TenantReceipt() {
	try {

		const classes = useStyles()

		const columns = [
			{field : 'receiptNo', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Receipt No.</strong>)}, renderCell : (params) => {
				//console.log(params)
				return (

					<Link to={`/receipt/${params?.row?.receiptNo}/${params?.row?.invoiceNumber}`} style={{textDecoration : "none", textTransform : "none"}} className={classes.paid}>{params.value}</Link>
				)}},
			{field : 'from', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >From</strong>)}},
			{field : 'invoiceNumber', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >For</strong>)}},
			{field : 'issuedDate', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Month Issued</strong>)}},
			{field : 'amount', flex : 1, renderHeader : () => {return (<strong style={{fontSize : '14px', color : '#032541'}} >Invoice Amount</strong>)}, renderCell : (params) => {return (CurrencyFormatter(params.value, 'KES'))}},
		]

		const navigate = useNavigate()
		const {X_Authorization} = useSelector((store) => store.user)

		// Data
		const [data, setData] = useState('')
		

		const [tabValue, setTabValue] = useState("Outgoing");

		// Loader Setter
		const [loader, setLoader] = useState(false)
		const handleTabChange = (event, newValue) => {setTabValue(newValue);};

		// Search functionality
		const [dataToShow, setDataToShow] = useState([]);
		const [formData, setFormData] = useState({search:""})
		//console.log(dataToShow)

		const handleInputChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value,});};

		const setSearchData = () => {
			let searchable = [...data]
			if(formData.search !== ""){
				let result = searchable.filter(invoice =>
					invoice.invoiceNumber?.toLowerCase()?.includes(formData.search) || invoice.invoiceNumber?.includes(formData.search) ||
					invoice.sendToFirstName?.toLowerCase().includes(formData.search) || invoice.sendToFirstName.includes(formData.search)
				);
				setDataToShow(result);
			}else{
				setDataToShow(data)
			}
		}

		// Fetch Receipts
		function getReceipts () {
			try {
				HttpComponent ({
					method : 'GET',
					url : `/api/get_business_receipts?limit=1000&page=1`,
					token : X_Authorization
				}).then((data) => {
					if (data.status === 201) {
						setData(data.response.data)
					} else {
						console.log(`Error getting Receipts`)
					}

				}).catch((e) => {
					console.log(`Error getting Receipts`, e.message)
				})
			} catch (e) {
				console.log(`Error getting Receipts`, e.message)
			}
		}

		// Use Effect
		// Search UseEffect
		useEffect(() => {
			setSearchData()
		}, [formData.search, data]);

		// get Receipts UseEffect
		useEffect(() => {
			getReceipts()
		}, [tabValue])

		return (
			<div>

				{/*Header Container*/}
				<Grid container justifyContent={'space-between'} mb={3}>
					{/*Month Name*/}
					<Grid item>
						<span style={{color : '#dc3545', fontWeight : 'bold', fontSize : '25px'}}>Receipts</span>
						<Grid item>
							<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>
					</Grid>

					<Grid item>
						{/*BreadCrumb Container & Add More units*/}
						<Grid container justifyContent={'flex-end'} spacing={2}>

							{/*Create Statement*/}
							<Grid item>
								<Button style={{padding : '10px', textTransform : 'none', color : '#032541', background : '#fff', height : '45px', fontSize : '16px', fontWeight : '600', border : '1px solid #032541'}}>
									Statement
								</Button>
							</Grid>

						</Grid>
					</Grid>
				</Grid>

				{/*Tabs*/}
				<TabContext value={tabValue}>
					<AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
						<AntTab label="Outgoing" value="Outgoing" />
						{/*<AntTab label="Incoming" value="Incoming" />*/}
						{/*<AntTab label="Saved" value="Saved" />*/}
					</AntTabs>


					{/*Search and Export Container*/}
					<Grid container justifyContent={'space-between'} mt={4}>

						{/*SearchBox*/}
						<Grid item>
							<CustomSearchInput name={"search"} value={formData.search} onChange={handleInputChange} placeholder="Search:"/>
						</Grid>

						{/*Export button*/}
						<Grid item>
							{/*	TODO : Add Export Button*/}
						</Grid>
					</Grid>

					{/*Active tenants*/}
					<TabPanel value="Outgoing">
						<CustomTable fontSize={'10px'} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />
					</TabPanel>
				</TabContext>

			</div>
		)
	} catch (e) {
		console.log(`Error on Tenants`, e.message)
	}
}