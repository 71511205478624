import { createSlice } from '@reduxjs/toolkit';
import {handleReset} from "./tenantsDataSlice"

let initialState = {
  isLoggedIn: false,
  X_Authorization: null,
  userId: null,
  email: null,
  username: null,
  group: null,
  state: null,
  defaultBusinessId: null,
  businessNumber: null,
  businessName: null,
  businessCategory: null,
  branchId: null,
  customerId: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logInUser: (state, action) => {

      console.log('incoming dispatch!', action);
      const { isLoggedIn, token: X_Authorization, userId, email, username, group, state: payloadState, defaultBusinessId, businessNumber, businessName, businessCategory, branchId, customerId } = action.payload;
    
      state.isLoggedIn = isLoggedIn;
      state.X_Authorization = X_Authorization;
      state.userId = userId;
      state.email = email;
      state.username = username;
      state.group = group;
      state.state = payloadState;
      state.defaultBusinessId = defaultBusinessId;
      state.businessNumber = businessNumber;
      state.businessName = businessName;
      state.businessCategory = businessCategory;
      state.branchId = branchId;
      state.customerId = customerId;
    },
    logoutUser: (state) => {
      state.isLoggedIn = false;
      state.X_Authorization = null;
      state.userId = null;
      state.email = null;
      state.username = null;
      state.group = null;
      state.state = null;
      state.defaultBusinessId = null;
      state.businessNumber = null;
      state.businessName = null;
      state.businessCategory = null;
      state.branchId = null;
      state.customerId = null;
       
    },
    changeToken: (state, action) => {
      const { token: X_Authorization } = action.payload;
      console.log("here is the token firingggg ", action.payload);
      state.X_Authorization = X_Authorization;
    },
  },
});

export const { logInUser, logoutUser, changeToken } = userSlice.actions;

export default userSlice.reducer;