import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";


function AdminCreateBusiness() {



  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Typography
        fontFamily="Poppins"
        fontSize={25}
        fontWeight="600"
        fontStyle="normal"
        textAlign="left"
        color="#032541"
      >
        Create Business
      </Typography>
      <Breadcrumbs name="Business Details" />
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={4}>
          
           
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AdminCreateBusiness;
