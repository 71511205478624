import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeToken } from "../../features/userSlice";
import CustomInputLabel from "../School/CustomInputLabel";
import CustomSelectField from "../School/CustomSelectField";
import CustomTextField from "../School/CustomTextField";
import HttpComponent from "../School/MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import GeoLocation from '../../hooks/useLocation';
import { handleSetBusinessData } from '../../features/tenantBusinessSlice';
import { handleBusinessBranches } from '../../features/businessBranchesSlice';
const baseUrl = process.env.REACT_APP_BASE_URL;



const BusinessDetailEcommerce = (props) => {
    const navigate = useNavigate();
	const dispatch = useDispatch();
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [selectedValue, setSelectedValue] = useState('')
    const [formData, setFormData] = useState({
        businessName: "",
        businessOwner: "",
        phoneNumber: "",
        email: "",
        location: "",
        country: "Kenya",
        currency: "KES"
    })
    const countryOptions = [
        { value: "Kenya", label: "Kenya" }
    ]
    const currencyOptions = [
        { value: "KES", label: "KES" }
    ]
    const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

    const cancelButtonStyles = {
		"width": "7.813rem",
		"height": "2.813rem",
		textTransform: "none",
		"borderRadius": "4px",
		marginRight: '20px',
		"border": "solid 1px #dc3545",
		"color": "#dc3545"
	}

	const doneButtonStyles = {
		opacity: !formData.businessName || !formData.businessOwner || !formData.phoneNumber  || !formData.email || !formData.country.trim() || !formData.currency ? "0.5" : "1",
		"width": "7.813rem",
		"height": "2.813rem",
		"borderRadius": "4px",
		"backgroundColor": "#17ae7b",
		"color": "white",
		textTransform: "none",
		"marginLeft": 2,
		'&:hover': {
			backgroundColor: '#17ae7b',
			color: 'white'
		}
	}

    const handlePhoneChange = (phone) => {
        setFormData({
          ...formData,
          phoneNumber: phone,
        });
      };

    // fetch branches

    const fetchBranches = async (token) => {
        try {
            const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
                method: "GET",
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            if (response?.status === 200) {
                dispatch(handleBusinessBranches({ branchesArr: data.data }));
                dispatch(handleSetBusinessData(data.data))
            }
        } catch (e) {
            console.log(e);
        }
    };
    
    


    const submitBusinessDetails = () => {
        let submitObject = {
            businessName: formData.businessName,
            businessCategory: "ZED_ECOMMERCE",
            businessOwnerName: formData.businessOwner,
            businessOwnerUserName: formData.businessOwner.split(" ").join(""),
            businessOwnerPhone: formData.phoneNumber,
            businessOwnerEmail: formData.email,
            businessOwnerAddress: selectedValue?.description,
            country: formData.country,
            localCurrency: formData.currency,
            deviceCreatedOn: "Web",
            userId: userId,
        }

        console.log("data", submitObject)

        HttpComponent({
            method: 'POST',
            url: '/postBusiness',
            body: submitObject,
            token: X_Authorization
        }).then(async(data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                dispatch(changeToken({ token: data.response.data.token }))
                localStorage.setItem("X-Authorization", data.response.data.token);
                localStorage.setItem("businessCategory", "ZED_ECOMMERCE")
                localStorage.setItem("businessId", data.response.businessNumber)
                localStorage.setItem("businessID", data.response.businessId)
                localStorage.setItem("businessName", data.response.data.businessName)
                localStorage.setItem("BranchID", data.response.data.branchId)
                await fetchBranches(data?.response?.data?.token)
                props.next();
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    console.log(formData)

    

    return (
        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, fontFamily: 'Poppins' }}>
            <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>

                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

                <div>
                    <Box component="div" sx={{ alignSelf: "left", marginBottom: '40px', marginTop: '53px' }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#032541" }}>
                            Set-up your Business details.
                        </Typography>
                    </Box>
                    <Box compnent="div" sx={{ display: "flex" }}>
                        {/*column one*/}
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Business Name"} />
                            <CustomTextField value={formData.businessName} onChange={handleInputChange} name={"businessName"} placeholder={"Business Name"} />
                            <CustomInputLabel required={true} label={"Business Owner"} />
                            <CustomTextField value={formData.businessOwner} onChange={handleInputChange} name={"businessOwner"} placeholder={"Business Owner"} />
                            <CustomInputLabel required={true} label={"Phone Number"} />
                            <Box component="div" sx={{ marginTop: 1 }}>
                                <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={formData.phoneNumber} name={"phoneNumber"} onChange={handlePhoneChange} />
                            </Box>
                        </Box>

                        {/*colum two*/}
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Email"} />
                            <CustomTextField required={true} value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                            <CustomInputLabel required={true} label={"Location"} />
                            <GeoLocation sx={{ marginBottom: "10px", marginTop: "10px" }} selectedValue={selectedValue} onValueChange={(location) => setSelectedValue(location)} />
                            <Grid container direction={'row'} spacing={2} >
                                <Grid item >
                                    <CustomInputLabel required={true} label={"Country"} />
                                    <CustomSelectField style={{ width: '12.1rem' }} value={formData.country} onChange={handleInputChange} name={"country"} placeholder={"Select Country"} options={countryOptions} />
                                </Grid>
                                <Grid item>
                                    <CustomInputLabel required={true} label={"Currency"} />
                                    <CustomSelectField style={{ width: '12.1rem' }} value={formData.currency} onChange={handleInputChange} name={"currency"} placeholder={"Select Currency"} options={currencyOptions} />
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>
                    <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                        <Box component="div" >
                            <Button style={cancelButtonStyles} onClick={() => navigate("/createBusiness")}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!formData.businessName.trim() || !formData.businessOwner.trim() || !formData.phoneNumber || !formData.email.trim() || !formData.country.trim() || !formData.currency.trim()}
                                style={doneButtonStyles} onClick={submitBusinessDetails}>
                                Done
                            </Button>
                        </Box>

                    </Box>
                </div>
            </Box>

        </Box>
    )
}

export default BusinessDetailEcommerce