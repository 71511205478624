import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HttpComponent from './MakeRequest';
import { Box, Breadcrumbs, styled, Button, FormControl, Tab, Tabs, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { ArrowBackIos, EditOutlined } from '@mui/icons-material';
import DeactivateUser from '../../common/images/deactivate-user.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from "@mui/icons-material/Search";

import ExportMenu from './ExportMenu';
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';
import CustomSearchInput from './CustomSearchInput';
import CustomSelectField from "./CustomSelectField";
import CustomTable from './CustomTable';
import DateFormatter from "../../utils/dateFormatter";


let baseUrl = process.env.REACT_APP_BASE_URL;





const StudentSummaryReport = () => {
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState([])
    const [gradeData, setGradeData] = useState([])
    const [grade, setGrade] = useState('')
    const [showGradeReport, setShowGradeReport] = useState(false)
    const queryparams = decodeURIComponent(window.location.search);
    const stateUrl = queryparams.slice(1)
    const { status } = useParams()
    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key={"students"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            {status}  Summary Report
        </Typography>
    ];
    console.log(status, 'status======================================>>>>')
    const navigate = useNavigate();
    const getSummaryReport = async () => {
        // /api/v1/invoice/summary_report_per_grade

        console.log(status, 'status')
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/invoice/summary_report_per_grade?status=${status}`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                console.log(data, 'summary_report_per_grade')
                if (data.status === 200) {
                    setData(data?.response?.data)
                }
            })
        } catch (error) {
        }

    }

    useEffect(() => {
        getSummaryReport()
    }, [stateUrl, status])



    const getSummaryGradeReport = async () => {
        // /api/v1/invoice/summary_report_per_grade
        console.log(grade, 'grade')
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/invoice/summary_report_for_grade?grade=${grade}&status=${status}`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                console.log(data, 'summary_report_per_grade')
                if (data.status === 200) {
                    setGradeData(data?.response?.data)
                }
            })
        } catch (error) {
        }

    }


    useEffect(() => {
        if (grade) {
            console.log('grade')
            getSummaryGradeReport()
        } else {
            if (stateUrl) {
                console.log('stateUrl')
            }
        }

    }, [grade])













    return (
        <>
            <div>
                <div>
                    <Box style={{ display: "flex", }}>
                        <Box style={{ display: "flex" }}>
                            <ArrowBackIos onClick={() => navigate(-1)} />
                            <h1 style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>{status} Summary Report</h1>
                        </Box>

                    </Box>
                    <div className=''>
                        <Box component={'nav'} aria-label="breadcrumb">
                            <Breadcrumbs
                                separator={
                                    <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                                }
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                    </div>
                    <Box>
                        <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search...'} sx={{ marginRight: "20px" }} />

                    </Box>
                    {showGradeReport && stateUrl ?

                        <Box>
                            <CustomTable
                                columns={[
                                    {
                                        field: "invoiceNumber",
                                        headerName: "Invoice Number",
                                        flex: 1,
                                        renderCell: (params) => {
                                            return <Link style={{ color: '#032541' }} to={`/school/invoice/${params.value}`}>{params.value}</Link>;
                                        },
                                    },
                                    { field: "studentName", headerName: "Student Name", flex: 1 },
                                    { field: "admissionNumber", headerName: "Admission Number", flex: 1 },
                                    { field: "term", headerName: "Term", flex: 1 },
                                    {
                                        field: "paymentDate", headerName: "paymentDate", flex: 1, renderCell: (params) => {
                                            return <p>{DateFormatter(params.value)}</p>;
                                        }
                                    },
                                    { field: "invoiceAmount", headerName: "Invoice Amount", flex: 1 },
                                    { field: "discountAmount", headerName: "Discount Amount", flex: 1 },


                                ]}
                                data={gradeData}

                            />
                        </Box>
                        :
                        <Box>
                            <CustomTable
                                columns={[
                                    {
                                        field: "grade",
                                        headerName: "GRADE/COURSE",
                                        flex: 1,
                                        renderCell: (params) => {
                                            return <p style={{ color: '#032541',textDecorationLine: 'underline', cursor:"pointer" }} onClick={() => {
                                                setGrade(params.value)
                                                setShowGradeReport(true)
                                                navigate(`/school/summaryReports/${status}?grade=${params.value}`)
                                            }}>{params.value}</p>;
                                        },
                                    },
                                    { field: "invoiceCount", headerName: "Invoice Count", flex: 1 },
                                    { field: "cuminvoiceAmount", headerName: "Cum Invoice Amount", flex: 1 },
                                    { field: "cumDiscountAmount", headerName: "Cum Discount Amount", flex: 1 },


                                    status === "Partially Paid" ? { field: "invoiceBalancePartiallyPaid", headerName: "Cum Inv Paid", flex: 1 } : null,
                                    status === 'Paid' ? {
                                        field: "totalPaid", headerName: "Cum Amount Paid", flex: 1, renderCell: (params) => {
                                            return <p style={{ color: "green" }}>{params.value}</p>;
                                        }
                                    } : status === "Unpaid" ? {
                                        field: "cumAmountUnPaid", headerName: "Cum Inv Bal", flex: 1,
                                        renderCell: (params) => {
                                            return <p style={{ color: "red" }}>{params.value}</p>;
                                        }
                                    } : {
                                        field: "totalPartiallyPaid", headerName: "Cum Inv Bal", flex: 1,
                                        renderCell: (params) => {
                                            return <p style={{ color: "#E07C28" }}>{params.value}</p>;
                                        }
                                    },


                                ]}
                                data={data}

                            />
                        </Box>
                    }







                </div>
            </div>
        </>

    )
}

export default StudentSummaryReport