import React, {useEffect, useState} from 'react'
import Dash from '../../common/dash'
import {Grid} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import success from "../../common/images/success.png";
import {SessionModal} from '../Modals/SessionExpiredModal/sessionModal'
import {SuccessModal} from '../Modals/SuccessModal/successModal'


// base url
const baseUrl = process.env.REACT_APP_BASE_URL

// data grid columns
const columns = [
    {field:'configCode', align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Configuration Code '}</strong>)},
    {field:'configName', align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Configuration Name'}</strong>)},
    {field:'configDescription', align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Configuration Description'}</strong>)},
    {field:'configValue',  align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Value '}</strong>)},
    {field:'createdBy',  align:'left', flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Created By'}</strong>)},
    {field:'dateCreated',  align:'left', flex:1,valueGetter : (params) => {return (moment(params.row.dateCreated).format('llll'))}, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Date Created '}</strong>)},
    {headerName:'Configurations', headerAlign:'center', align:'center', width:5,flex:1, renderHeader: () => (<strong style={{fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541"}} >{'Action'}</strong>), renderCell : (params) => {

        // session Modal Config
        const [editConfigOpen, setEditConfigOpen] = React.useState(false);
        const handleEditConfigOpen = () => setEditConfigOpen(true);
        const handleEditConfigClose = () => setEditConfigOpen(false);

        // Values Setters and Getters
        const [editValue, setEditValue] = useState(params.row.configValue)
        const [editPeriodValue, setEditPeriodValue] = useState(params.row.configPeriod)
        const [editDescriptionValue, setEditDescriptionValue] = useState(params.row.configDescription)

        // Success Configuration Edit Modal Config
        const [successConfigurationModalOpen , setSuccessConfigurationModalOpen] = useState(false);
        const successConfigurationModalHandleOpen = () => setSuccessConfigurationModalOpen(true);
        const successConfigurationModalHandleClose = () => setSuccessConfigurationModalOpen(false);

        //Update url
        const updateUrl = baseUrl + "/api/updateConfig/"

        // update url
        const pushEditUpdate = async(e) => {
            e.preventDefault()
            const response = await fetch(updateUrl, {
                method : "PUT",
                headers : {
                    "Content-Type" : "application/json" ,
                    "Accept" : "application/json" ,
                    "X-Authorization" : localStorage.getItem('X-Authorization')
                },
                body : JSON.stringify({
                    configValue : editValue,
                    configPeriod : editPeriodValue,
                    configCode : params.row.configCode
                })
            }); const Data = await response.json()
            console.log(Data)
            if (response.status === 202) {
                successConfigurationModalHandleOpen()
                setEditValue('')
                handleEditConfigClose()
            }
            else if (response.status === 401){
                alert('Unauthorised')
            }
        }

        const handleEditChange = (e) => {
            setEditValue(e.target.value)
        }
        const handlePeriodEditChange = (e) => {
            setEditPeriodValue(e.target.value)
        }
        const handleDescriptionEditChange = (e) => {
            setEditDescriptionValue(e.target.value)
        }
        return (

            <div>

                {/*edit button*/}
                <Button onClick={handleEditConfigOpen} >
                    <EditIcon sx={{color:'#dc3545'}}>
                        Edit
                    </EditIcon>
                </Button>

                <SuccessModal onClose={successConfigurationModalHandleClose} editConfigOpen={successConfigurationModalOpen} modalRow={params.row.configName} successObject={'config'} successType={'Updated'} />

                {/*open edit config*/}
                <Modal open={editConfigOpen} onClose={handleEditConfigClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <form onSubmit={pushEditUpdate} >
                        <Box sx={{ transform: 'translate(-50%, -50%)',position: 'absolute', top:'50%', left:'50%', width: "605px", height: "550px", padding: "0 0 73.9px", borderRadius: "15px", backgroundColor: "#fff"}}>
                            <Grid sx={{width: "605px", height: "90px", margin: "0 0 20px", padding: "35px 30px 3px", borderRadius: "16px", backgroundColor: "#dc3545"}} >
                                <span style={{width: "189px", height: "21px", margin: "0 340px 0 0", fontFamily: "Poppins", fontSize: "15px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "normal", letterSpacing: "normal", textAlign: "left", color: "#fff"}}>Edit Configuration Details</span>
                            </Grid>
                            <Grid>
                                <span style={{width: "62px", height: "16px", margin: "4px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Description</span>
                                <input value={editDescriptionValue} onChange={handleDescriptionEditChange} style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />
                            </Grid>
                            <Grid>
                                <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Value</span>
                                <input type='Number' value={editValue} onChange={handleEditChange}  style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 10px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}} />
                            </Grid>
                            {
                                params.row.configName === 'loginTrials'?
                                    <Grid>
                                        <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Period</span>
                                        <select value={editPeriodValue} onChange={handlePeriodEditChange}  style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 56px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}}>
                                            <option style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px"}} >No Period for Password Trials</option>
                                        </select>
                                    </Grid>
                                    :params.row.configName === 'passwordExpiry'?
                                        <Grid>
                                            <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Period</span>
                                            <select aria-readonly style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 56px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}}>
                                                <option style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px"}} value={'d'} >Days</option>
                                            </select>
                                        </Grid>
                                    :params.row.configName === 'suspensionTime'?
                                        <Grid>
                                            <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Period</span>
                                            <select aria-readonly style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 56px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}}>
                                                <option style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px"}} value={'m'} >Minutes</option>
                                            </select>
                                        </Grid>
                                    :params.row.configName === 'loginSession'?
                                        <Grid>
                                            <span style={{width: "62px", height: "16px", margin: "30px 108px 10px 54px", fontFamily: "Poppins", fontSize: "11px", fontWeight: "normal", fontStretch: "normal", fontStyle: "normal", lineHeight: "4", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Period</span>
                                            <select aria-readonly style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px",width: "505px", height: "40px", margin: "10px 46px 56px 54px", padding: "5px 2px 3.5px 18px", objectFit: "contain", borderRadius: "4px", border: "solid 1px #dfdede", backgroundColor: "#fff"}}>
                                                <option style={{color:'#032541',fontFamily: "Poppins", fontSize: "11px"}} value={'m'} >Minutes</option>
                                            </select>
                                        </Grid>:''
                            }
                            <Grid marginLeft={'350px'}>
                                <Button onClick={handleEditConfigClose} align={'right'} sx={{color:'#dc3545', fontWeight:600 , margin:"0px 30px 0px 0px", width: "90px", height: "33.1px", padding: "9px 29px 8.1px 30px", border: "solid 1px #dc3545"}} >Close</Button>
                                <Button type='submit' align={'right'} sx={{":hover": {color:'#032541',fontWeight:600 },fontWeight:600 , color:"#fff", margin:"0px 0px 0px 0px", width: "90px", height: "33.1px", padding: "9px 32px 8.1px 32px", backgroundColor: "#032541"}}>Save</Button>
                            </Grid>
                        </Box>
                    </form>
                </Modal>
            </div>
        )
        } }
]

// session Expired
const style = {borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "350px", p: 4,};

const Configuration = () => {

    // session Modal Config
    const [sessionOpen, setSessionOpen] = React.useState(false);
    const handleSessionOpen = () => setSessionOpen(true);
    const handleSessionClose = () => setSessionOpen(false);

    // page size setter and getter
    const [pageSize, setPageSize] = useState(5)

    // configuration rows setter and Getter
    const [configData, setConfigData] = useState([])

    const configUrl = baseUrl + '/api/listConfig'

    // Getting Configurations
    const getConfigurations = async() => {
        try {
            const response = await fetch(configUrl, {
                method:"GET",
                headers : {
                    "Content-Type" : "application/json" ,
                    "Accept" : "application/json" ,
                    "X-Authorization" : localStorage.getItem('X-Authorization')
                }
            }); const data = await response.json()
            if (response.status === 200) {setConfigData(data.data)}
            else if (response.status === 401) {handleSessionOpen()}
        } catch (e) {console.log(e)}
    };useEffect(() => {
        getConfigurations();
    },[])

    const rowData = configData.map((config) => {
        return {
            id : config._id,
            configCode : config.configCode,
            configName : config.configName,
            configDescription : config.configDescription,
            configValue : config.configValue,
            createdBy : config.createdBy,
            dateCreated : config.dateCreated
        }
    })

    return (
        <div>
            <div>
                <div>

                    {/*sessionModal*/}
                    <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

                    {/*Page header*/}
                    <Grid container sx={{flex:1}} >
                        <Grid item>
                            <label style={{width: "158px", height: "35px", margin: "10px 32px 21px 12px", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545"}} >Configurations</label>
                        </Grid>
                    </Grid>
                    <Grid container sx={{margin:"0px 0px 20px 0px"}}>
                        <Grid item >
                            <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >Dashboard</span>
                            <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#6e7074"}} >\</span>
                            <span style={{width: "265px", height: "28px", margin: "21px 0px 39px 12px", fontFamily: "Poppins", fontSize: "20px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.65", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Configurations</span>
                        </Grid>
                    </Grid>

                    {/*search bar*/}
                    <Grid container spacing={2}>
                        <Grid item xs={10} lg={9} md={10} sm={8}></Grid>
                        <Grid item>
                            <label style={{width: "35px", height: "12px", fontFamily: "Poppins", fontSize: "9px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.78", letterSpacing: "normal", textAlign: "left", color: "#032541"}} >Search:</label>
                            <input style={{fontSize:'12px',color:'#272d3b', borderRadius:"6px", width:"238px", height: "36px", margin: "0 0 6.4px 20.4px", padding: "7px 107.7px 6.4px 11.1px", objectFit: "contain", border: "solid 1px #032541"}} placeholder='search here' ></input>
                        </Grid>
                    </Grid>

                    {/*Data grid*/}
                    <Grid container sx={{ height: 500, width: '100%', flex:1, margin:'50px 0px 0px 0px',border: "solid 0px transparent", backgroundColor: "#fff"}}>
                        <DataGrid
                            sx={{fontFamily : 'Poppins' , fontSize : '10px' , color : '#272d3b'}}
                            rows={rowData}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize)=>setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            pagination/>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Configuration;