import React, { useEffect, useState } from 'react'
import CustomTable from '../School/CustomTable'
import { Grid, Stack, Typography } from '@mui/material'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HttpComponent from '../School/MakeRequest';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const DeleteRequestAdmin = () => {
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [data, setData] = useState([])
    const getDeleteAccountsRequests = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/delete_requests`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200 || data.status === 201) {
                    console.log(data, 'dataaaaaaaaaaaa')
                    setData(data.response.data)
                    
                } else {
                    setErrorShow({ ...errorShow, state: true, message: data?.response?.error })
                }
            })
        } catch (error) {
            console.log(error, 'error')
            setErrorShow({ ...errorShow, state: true, message: error })

        }
    }
    useEffect(() => {
        getDeleteAccountsRequests()
    }, [])
    return (
        <Grid>
            <Stack bgcolor={"white"} p={2} spacing={1}>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                        <ArrowBackIosNewIcon
                            sx={{
                                color: "#707070",
                                "&:hover": { cursor: "pointer" },
                            }}
                            onClick={() => {
                                window.history.back();
                            }}
                        />
                        <Typography
                            fontFamily="Poppins"
                            fontSize={25}
                            fontWeight="600"
                            fontStyle="normal"
                            textAlign="left"
                            color="#032541"
                        >
                            Delete Accounts Requests
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Grid>
                <CustomTable data={data} columns={[
                    {
                        field: 'firstName', headerName: 'firstName', flex: 1,

                    },
                    { field: 'lastName', headerName: 'LastName', flex: 1 },
                    {
                        field: 'email', headerName: 'EMAIL', flex: 1,
                    },
                    {
                        field: 'phone', headerName: 'Phone Number', flex: 1,
                    },
                    {
                        field: 'reason', headerName: 'Reason', flex: 1, renderCell: (params) => {

                        }
                    },
                    { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: 
                    (params) => {
                        return (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <DeleteForeverIcon style={{ color: '#dc3545', fontSize: '20px', cursor: 'pointer' }} />
                            </div>
                        )
                    } },



                ]} />
            </Grid>
        </Grid>
    )
}

export default DeleteRequestAdmin