import HttpComponent from "../School/MakeRequest";

export default class ReportFunctions {
  constructor() {}

  getPendingVoidTransactions(page, limit ,startDate, endDate) {
    return HttpComponent({
      method: "GET",
      url: `/api/v1/payments/void-penging-approval?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate} `,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getApprovedVoidTransactions(page, limit ,startDate, endDate){
    return HttpComponent({
      method: "GET",
      url: `/api/v1/payments/void-approved?search=&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate} `,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getDeclinedVoidTransactions(page, limit ,startDate, endDate){
    return HttpComponent({
      method: "GET",
      url: `/api/v1/payments/void-declined?search=&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate} `,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getAllTransaction (page, limit , startDate, endDate ){
    return HttpComponent({
      method: "GET",
      url: `/viewAllTransactions?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate} `,
      token: localStorage.getItem("X-Authorization"),
    });
  }
}
