import { Breadcrumbs, Button, TextField, Grid, Typography, InputAdornment, Checkbox } from "@mui/material"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PhoneInput from "react-phone-input-2";
import { SuccessAlert } from "../../snackBar Alerts/successAlert"
import { ErrorAlert } from "../../snackBar Alerts/errorAlert"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../MakeRequest";
import { useSelector, useDispatch } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleDeleteGetTeachingStaffCourses, handleDeleteUnit, handleResetStaff } from "../../../features/staffSlice";
let baseUrl = process.env.REACT_APP_BASE_URL;

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Staff</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Edit Staff</Typography>
]

export default function EditStaffProfile() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormData] = useState({ staffId: '', firstName: '', lastName: '', phone: '', email: '' })
    const [openCollapse, setOpenCollapse] = useState(false)
    const [opencourse, setOpenCourse] = useState("")
    const [userPhone, setPhone] = useState("");
    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const [queryParamValue, id] = stateDate.split("?")
    const [searchValue, setSearchValue] = useState('')
   
    const [selectedUnits, setSelectedUnits] = useState([])
    const dispatch = useDispatch()

    const staffCourseUnits = useSelector((store) => store?.teachingStaff?.teachingStaffCourses)
    const [courses, setCourses] = useState([])
    const [allCourses, setAllCourses] = useState([])
    const [selectedCourses, setSelectedCourse] = useState([])


    console.log(courses , 'staffCourseUnits')


    useEffect(() => {
        const coursesFound = staffCourseUnits?.filter((course) => allCourses?.some(courseResponse => courseResponse?._id === course?._id))
        if (coursesFound) {
            setCourses(coursesFound)
        }
        setCourses(allCourses)
    }, [staffCourseUnits , allCourses])

    const courseDetails = courses?.map((course) => ({
        courseId: course?.courseId,
        unitIds: course?.units?.map((unit) => unit?.unitId),
    }))?.filter((result) => result?.unitIds && result?.unitIds.length > 0);


    const updatedCouresesUnits = staffCourseUnits?.map(course => {
        return {
            courseId: course?.courseId,
            unitIds: course?.units?.map(unit => unit?.unitId)
        };
    });

    const newUpdateUnits =  selectedCourses?.map(course => {
        return {
            courseId: course?.courseId,
            unitIds: course?.units?.map(unit => unit?.unitId)
        };
    });

    console.log(newUpdateUnits , 'newUpdateUnits >>')



    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleDelete = (courseId, unitId) => {
        //console.log(courseId , unitId)
        dispatch(handleDeleteGetTeachingStaffCourses({ _id: courseId, unitIds: [unitId] }))
    }


    // handle collapse and open courses

    // const handleClick = (courseName) => {
    //     setOpenCourse(courseName)
    //     setOpenCollapse(!openCollapse)

    // }
    console.log(selectedCourses , 'selec')
    const handleClick = (courseName, courseId) => {
        // check if the course has units

        const course = courses?.find((c) => c.courseName === courseName);
        if (course?.units?.length > 1) {
            setOpenCourse(courseName);
            setOpenCollapse(!openCollapse);

            // check whether the course already selected

            const isCourseSelected = selectedCourses?.some((course) => course?.courseId === courseId);

            if (!isCourseSelected) {
                setSelectedCourse((prev) => [
                    ...prev, {
                        courseId: courseId,
                        courseName: courseName,
                        units: []
                    }
                ])
            }
        } else {
            return setErrorShow({ state: true, message: `${courseName} has no units to assign to this staff` })
        }
    }

    // handle course sekection

    const handleSelectCourses = (unitId, courseId, unitName, unitCode) => {
        setSelectedCourse((prev) =>
            prev?.map((course) => {
                if (course?.courseId === courseId) {
                    const isUnitSelected = course?.units?.some((unit) => unit?.unitId === unitId);

                    if (isUnitSelected) {
                        return {
                            ...course,
                            units: course?.units?.filter((unit) => unit?.unitId !== unitId),
                        };
                    } else {
                        return {
                            ...course,
                            units: [
                                ...course.units,
                                {
                                    unitId: unitId,
                                    unitName: unitName,
                                    unitCode: unitCode,
                                },
                            ],
                        };
                    }
                }
                return course;
            })
        );
    };


    const navigate = useNavigate();

    // get customer details

    function getStaffDetails() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_customer_by_number?customerId=${id}`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    const { admissionNo, firstName, lastName, phone, email } = data?.response?.data[0]
                    setFormData({ staffId: admissionNo, firstName: firstName, lastName: lastName, email: email })
                    setPhone(phone)
                }
                //console.log(data , 'staff details')
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getStaffDetails()
    }, [id])

    // all courses


    const getCourses = async () => {
        const studentCourseDetails = await fetch(baseUrl + `/api/v1/getUnitsInCourse?searchValue=${searchValue}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        }
        );

        if (studentCourseDetails?.status === 200) {
            await studentCourseDetails.json().then((data) => {
                // console.log(data , 'hoes')
                setAllCourses(data?.data)
            })
        }
    }

    useEffect(() => {
        getCourses();
    }, [searchValue])

    // update staff details

    function updateStaffDetails() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/edit_student_details`,
                token: localStorage.getItem('X-Authorization'),
                body: {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    studentNumber: formData.staffId,
                    phone: '+' + userPhone ,
                    //courseUnits:updatedCouresesUnits
                    courseUnits:newUpdateUnits
                }
            }).then((data) => {
                if (data?.status === 201 || data?.status === 200 || data?.status === 202) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, [1000])
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {

        }
    }



    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => {dispatch(handleResetStaff);navigate(-1)} } />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Edit Staff</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} width={'49%'}>
                <TextField style={{ width: '100%' }} placeholder="Staff ID Number" value={formData.staffId} onChange={handleOnChange} name="staffId" />
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="FirstName" value={formData.firstName} onChange={handleOnChange} name="firstName" />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="LastName" value={formData.lastName} onChange={handleOnChange} name="lastName" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'50%'} mb={1}>
                <Grid container width={'100%'} spacing={2}>
                    <Grid item width={'50%'}>
                        <PhoneInput inputStyle={{ height: "55px", width: '100%' }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />
                    </Grid>
                    <Grid item width={'50%'}>
                        <TextField style={{ width: '100%' }} placeholder="email" value={formData.email} onChange={handleOnChange} name="email" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <TextField name={'search'} value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={'search'}
                    variant="outlined"
                    margin="dense"
                    sx={{ width: '100%' }}
                    InputProps={{
                        sx: {
                            height: '3.438rem',
                            width: '100%',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        },
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <Grid container width={'100%'}>
                    {courses?.map((course) => (
                        <Grid item width={'100%'} py={2} sx={{ borderBottom: '0.1px solid #707070' }}>
                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography style={{ color: '#00000', fontWeight: 'bold' }}>{course?.courseName}</Typography>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    {openCollapse ? <ExpandLess onClick={() => handleClick(course?.courseName, course?._id)} /> : <ExpandMore onClick={() => handleClick(course?.courseName, course?._id)} />}
                                </Grid>
                            </Grid>
                            {opencourse === course?.courseName ?
                                <Grid container width={'100%'}>
                                    {course?.units?.length > 0 && course?.units?.map((unit) => (
                                        <Grid item width={'100%'} my={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography>{unit?.unitName}</Typography>
                                            {staffCourseUnits?.some(
                                                (staffUnit) => staffUnit?._id === course?._id && staffUnit?.units?.some((staffu)=>staffu?._id === unit?._id)
                                            ) ? (
                                                <DeleteIcon
                                                    onClick={() => handleDelete(course?._id, unit?._id)}
                                                    style={{ color: '#dc3545', cursor: 'pointer' }}
                                                />
                                            ) : (
                                                <Checkbox onChange={() => handleSelectCourses(unit?._id, course?._id, unit?.unitName, unit?.unitCode)} checked={selectedCourses
                                                    ?.find((selectedCourse) => selectedCourse.courseId === course?._id)
                                                    ?.units?.some((selectedUnit) => selectedUnit.unitId === unit?._id)} />
                                            )}
                                        </Grid>
                                    ))}
                                </Grid> : null}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid mt={2} item width={'49%'} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={() => navigate(-1)} style={{ border: '1px solid #dc3545', color: '#dc3545', textTransform: 'inherit', width: '124px' }}> Cancel</Button>
                <Button onClick={updateStaffDetails} style={{ backgroundColor: '#032541', marginLeft: '10px', color: '#fff', textTransform: 'inherit', width: '124px' }}> Update</Button>
            </Grid>
        </Grid>
    )
}