import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import eatery from "../../../../common/images/eatery-icon.svg";
import hotel from "../../../../common/images/hotel-icon.svg";
import service from "../../../../common/images/service-station-icon.svg";
import pharmacy from "../../../../common/images/pharmacy-icon.svg";
import hardware from "../../../../common/images/hardware-icon.svg";
import liquor from "../../../../common/images/liquor-store-icon.svg";
import retail from "../../../../common/images/Retail-Shop-icon.svg";
import wholesale from "../../../../common/images/wholesale-icon.svg";
import manufactures from "../../../../common/images/manufacturers-icon.svg";
import supplier from "../../../../common/images/supplier-icon.svg";
import distributors from "../../../../common/images/distributors-icon.svg";
import school from "../../../../common/images/school-icon.svg";
import housing from "../../../../common/images/housing-icon.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";


function AddBusiness() {
const navigate = useNavigate()


  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Typography
        fontFamily="Poppins"
        fontSize={25}
        fontWeight="600"
        fontStyle="normal"
        textAlign="left"
        color="#032541"
      >
        Add Business
      </Typography>
      <Breadcrumbs2
        point1={"Businesses"}
        link1={"/Businesses"}
        point2={"Add Business"}
      />
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={4}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 25,
                fontWeight: "600",
                fontStyle: "normal",
                textAlign: "center",
                color: "#032541",
              }}
            >
              Create Your Business Today!
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: "normal",
                fontStyle: "normal",
                textAlign: "center",
                color: "#707070",
              }}
            >
              Select Your Business Type
            </Typography>
            <Stack
              direction="row"
              alignItems={"top"}
              justifyContent={"space-around"}
            >
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  maxWidth={"100px"}
                  onClick={() => {
                    navigate("/setup/boom")
                  }}
                >
                  <img src={eatery} alt="eatery" width="70" height="70" />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 14,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "center",
                      color: "#707070",
                    }}
                  >
                    Eatery, Bar and Restaurant
                  </Typography>
                </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
                onClick={() => {
                  navigate("/setup/hotel")
                }}
              >
                <img src={hotel} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Hotel
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
                onClick={() => {
                  navigate("/setup/service")
                }}
              >
                <img src={service} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Service Station (Gas Station)
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={pharmacy} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Pharmacy
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={hardware} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Hardware
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={liquor} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Liquor Shop
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={retail} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Retail Shop
                </Typography>
              </Stack>
              </motion.div>
            </Stack>
            <Stack
              direction="row"
              alignItems={"top"}
              justifyContent={"space-around"}
            >
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={wholesale} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Wholesale Shop
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={manufactures} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Manufacturer
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={supplier} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Suppliers and Vendors
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={distributors} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Distributors
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={school} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  School/College
                </Typography>
              </Stack>
              </motion.div>
              <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 1.2 }}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"100px"}
              >
                <img src={housing} alt="eatery" width="70" height="70" />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textAlign: "center",
                    color: "#707070",
                  }}
                >
                  Rental & Housing
                </Typography>
              </Stack>
              </motion.div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AddBusiness;
