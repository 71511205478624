import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businesses: []
}


const EditedBusinessesSlice = createSlice({
  name:"editedBiz",
  initialState,
  reducers:{
    handleBusinessEdited:(state, action) => {
      console.log(action.payload , 'uuuuu')
        state.businesses.push(action.payload)
    },
    handleRemove: (state, action) => {
        state.businesses = state.businesses.filter((item) => item.businessId !== action.payload)
    }

  }
})

export const { handleBusinessEdited, handleRemove } = EditedBusinessesSlice.actions;

export default EditedBusinessesSlice.reducer