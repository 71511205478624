import { useEffect, useState } from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import HttpComponent from "../MakeRequest";
import { useSelector, useDispatch } from 'react-redux';
import { handleIsKCBMobileSetup, setKCBMobileStatus } from '../../../features/paymentOptionsSlice';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import CustomInputLabel from '../CustomInputLabel';
import CustomSelect from '../CustomSelectField';
const baseUrl = process.env.REACT_APP_BASE_URL;

const KCBMobileMoney = (props) => {
    console.log('props', props.parentCheckStatusKCBMobileMoney)

    const [kcbAccountType, setkcbAccountType] = useState('');
    const [tillNo, setTillNo] = useState('');
    const [verifyTill, setVerifyTill] = useState('');
    const [message, setmessage] = useState('')
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const dispatch = useDispatch();

    const kcbsetModeOptions = [
        { value: "VOOMATILL", label: "Vooma Till" },
        { value: "KCBACCOUNT", label: "KCB Account Number" },
    ]


    const { bankPaybillStatus } = useSelector((store) => store.payment);
    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    // save mobile money details
    const saveMobileMoneyDetails = async () => {
        console.log('businessName', kcbAccountType, tillNo, verifyTill)
        if (kcbAccountType === ' ') {
            let message = `Kindly select setup mode`

            setmessage(message)
            setErrorShow({ state: true, message:message  })

        } else if (tillNo === '') {
            //setError(true)
            let message = `${kcbAccountType} is required`
            setmessage(message)
            setErrorShow({ state: true, message: message })

        } else if (verifyTill !== tillNo) {
            //setError(true)
            let message = `${kcbAccountType} do not match`
           

            setmessage(message)
            setErrorShow({ state: true, message: message })


        }
         else {
            try {
                const response = await fetch(`${baseUrl}/api/v1/payments/activate_kcb_mpesa?status=true`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        businessName: localStorage.getItem('businessName'),
                        accountNumber: tillNo,
                        kcbAccountType: kcbAccountType
                    }),
                })
                await response.json().then((data) => {
                    console.log('response data here', data)
                    setmessage('Successfully added card details')
                    setSuccessShow({ state: true, message: "Vooma Till Activated successfully" })
                   

                    dispatch(setKCBMobileStatus(true));
                    dispatch(handleIsKCBMobileSetup(true));
                    let childData = {
                        state: false
                    }
                    props.parentCheckStatusKCBMobileMoney(childData)
                    // setTimeout(() => {
                    //     setOpenSnack(false);: merch
                    //     window.location.reload();
                    //     // setSuccessfullyPaid(true);
                    // }, 2000);
                })
            } catch (error) {
                console.log(error);
                setErrorShow({ state: true, message: "Error activating Vooma Till" })
            }
        }
    }



    const getMobileDetails = () => {
        HttpComponent({
            method: 'GET',
            url: `/api/v1/payments/get_kcb_mpesa_configs`,
            token: localStorage.getItem("X-Authorization"),
           
        }).then((data) => {
            if (data.status === 200) {
                
                let kcbAccountType = data?.response?.kcbAccountType
                let accountReference = data?.response?.businessShortCode
                setkcbAccountType(kcbAccountType)
                setTillNo(accountReference)
                setVerifyTill(accountReference)
            }
                
            else {
                console.error("Error setting info")
            }
        }).catch((error) => {
            console.error(error.message);
        })

    }
    if(bankPaybillStatus) {
        useEffect(() => {

            getMobileDetails()
           }, [])

    }
  

    return (
        <Box sx={{ display: 'flex', width: '100%', padding: '26px' }}>
            <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

                <FormControl style={{ width: '100%', display: 'flex' }}>
                    {/* <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}>Bank Paybill No.</InputLabel>
                    <TextField style={{ width: "100%", marginTop: '40px' }}
                        id="outlined-multiline-flexible"
                        // multiline
                        required
                        defaultValue="522533"
                        onChange={(e) => setPaybill(e.target.value)}
                        value={paybill}
                    /> */}
                    <Typography style={{ fontWeight: 'normal', fontSize: '14px', marginTop: '40px' }}>
                        KCB MOBILE MONEY
                    </Typography>
                    <CustomInputLabel label="Setup Mode" required={true} />
                    <CustomSelect cw={"custom"} options={kcbsetModeOptions} value={kcbAccountType} onChange={(e) => setkcbAccountType(e.target.value)} />
                </FormControl>


                {kcbAccountType ? <>
                    <FormControl style={{ width: '100%', marginTop: '-5px' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}> {kcbAccountType === 'KCBACCOUNT' ? 'KCB Account Number .' : 'Vooma Till No.'}   </InputLabel>
                        <TextField style={{ width: "100%", marginTop: "40px" }}
                            id="outlined-multiline-flexible"
                            value={tillNo}
                            onChange={(e) => setTillNo(e.target.value)}
                            multiline
                            placeholder="e.g 123456"
                            required
                        />
                    </FormControl>
                    {tillNo !== verifyTill ? <>
                        <FormControl style={{ width: '100%', marginTop: '-5px' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '-5px' }}>{kcbAccountType === 'KCBACCOUNT' ? 'KCB Account Number .' : 'Verify Vooma Till No.'}</InputLabel>
                            <TextField style={{ width: "100%", marginTop: "40px" }}
                                error
                                id="outlined-                               "
                                placeholder='e.g 123456'
                                type='number'
                                value={verifyTill}
                                onChange={(e) => setVerifyTill(e.target.value)}
                                multiline
                                required
                            />
                        </FormControl>
                    </> : <>

                        <FormControl style={{ width: '100%', marginTop: '-5px' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}>{kcbAccountType === 'KCBACCOUNT' ? 'KCB Account Number .' : 'Verify Vooma Till No.'}</InputLabel>
                            <TextField style={{ width: "100%", marginTop: "40px" }}
                                id="outlined-multiline-flexible"
                                placeholder='e.g 123456'
                                type='number'
                                value={verifyTill}
                                onChange={(e) => setVerifyTill(e.target.value)}
                                multiline
                                required
                            />
                        </FormControl>
                    </>}

                    <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '0px', paddingBottom: '20px' }}>
                        <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end", marginTop: '30px' }}>
                            <Box sx={{ display: 'flex', }}>
                                {/* <Button sx={{
                                ...buttonStyles,
                                border: " solid 1px #002543",
                                color: "#002543",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                    color: '#002543'
                                }
                            }}
                                onClick={() => showKCBMobileMoneySetup(false)}
                            >
                                Cancel
                            </Button> */}

                                <Button sx={{
                                    ...buttonStyles,
                                    marginLeft: 1,
                                    backgroundColor: " #032541",
                                    color: "white",
                                    '&:hover': {
                                        backgroundColor: '#032541',
                                        color: 'white'
                                    }
                                }}
                                    onClick={saveMobileMoneyDetails}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </> : null}




            </form>
        </Box>
    )
}

export default KCBMobileMoney;