import { Grid, InputLabel, Button, FormControl, MenuItem, Select, TextField, Typography, Breadcrumbs } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { SuccessAlert } from '../../../../snackBar Alerts/successAlert'
import { ErrorAlert } from '../../../../snackBar Alerts/errorAlert'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import HttpComponent from '../../../../School/MakeRequest';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Vehicle Types</Typography>,
]


export default function AddVehicleTypes() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ vehicletype: '', description: '', numberofseats: '', })

    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    const navigate = useNavigate()

    function AddvehicleTypes(){
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/addvehicle_type`,
                body:{
                    vehicleType:formData.vehicletype,
                     numberOfSeats:formData.numberofseats, 
                     description:formData.description 
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data?.status === 200 || data?.status === 201){
                    setSuccessShow({state:true , message:data?.response?.message})
                    setTimeout(()=>{
                        navigate(-1)
                    },1500)
                }else{
                    setErrorShow({state:true , message:data?.response?.message})
                }
            })
        }catch(error){

        }
    }
    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Vehicle Types</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography>Add Vehicle Types</Typography>
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Vehicle Type</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.vehicletype} name='vehicletype' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Number of Seats</InputLabel>
                <TextField type='number' style={{ width: "100%" }} value={formData.numberofseats} name='numberofseats' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Description</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.description} name='description' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={()=>navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={AddvehicleTypes} disabled={!formData.vehicletype || !formData.numberofseats || !formData.description} style={!formData.vehicletype || !formData.numberofseats || !formData.description ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Save</Button>
            </Grid>
        </Grid>
    )
}