
import { useEffect, useState } from "react"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PhoneInput from "react-phone-input-2";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, InputLabel, Button, Badge, Box, TextField, Paper, Typography, Breadcrumbs, RadioGroup, FormControl, Radio, Select, MenuItem, FormControlLabel } from '@mui/material'
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useNavigate } from 'react-router-dom'
import CashIcon from '../../../images/cash_icn.svg'
import MpesaIcon from '../../../images/mpes 2.svg'
import AutoCompleteFiltertransport from "../reports/autoselectFilter";
import HttpComponent from "../../School/MakeRequest";
import { makeStyles } from "@mui/styles";
import { getInvoicePayments, handleInitiateTransporttkPush, listenWebSocket } from "./utils/socket";



const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Collect</Typography>,
]

const useStyles = makeStyles((theme) => ({
    badgeColorOverride: {
        backgroundColor: "#032541",
        color: '#fff'
    },
}));

const badgeStyles = {
    '&.MuiBadge-colorPrimary': {
        color: '#032541'
    },
    '&.MuiBadge-badge': {
        color: '#032541'
    }
};

export default function CollectFareForSacco() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [isNavigating, setIsNavigating] = useState(false);
    const [selectedPayOption, setSelectedpayOption] = useState('')
    const [phone, setPhone] = useState('')
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [startTransaction ,setStartTransaction] = useState(false)
    const classes = useStyles()
    const handleOnChange = (e) => {
        setSelectedpayOption(e.target.value)
    }

    const navigate = useNavigate();

    // get sacco vehicles
    const [myvehicle, setmyvehicle] = useState([])
    const [selectedVehicleRegNo, setselectedVehicleRegNo] = useState('')
    const [foundvehcileregNo, setvehicleOwnerregNo] = useState('')
    const [isLoading, setIsloading] = useState(false)
    const [vehicleDetails, setVehicledetails] = useState({})
    const [customername, setCustomerName] = useState('')
    const [amount ,setAmount] = useState('')
    const [noofseats , setnumofseats] = useState('')
    const [vehicleOwnerregNo, setNewRegNo] = useState('')
    const [invoiceBalance ,SetInvoiceBalance] = useState('')
    const [socketInterval, setMyInterval] = useState(null)

    useEffect(() => {
        if (selectedVehicleRegNo && selectedVehicleRegNo?.value) {
            const extractedNumber = selectedVehicleRegNo.value;
            setvehicleOwnerregNo(extractedNumber);
            setNewRegNo(selectedVehicleRegNo?.label);
        }
    }, [selectedVehicleRegNo]);


    function getVehicles() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/listvehicles`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    const myfoundvehicl = data?.response?.data?.map((item) => ({
                        value: item?._id,
                        label: item?.regNo
                    }))
                    setmyvehicle(myfoundvehicl)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehicles()
    }, [])

    function getVehiclesDetails() {
        setIsloading(true)
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/vehicle_details_by_Id?vehicleId=${foundvehcileregNo}`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setVehicledetails(data?.response?.data)
                } else {
                    setIsloading(false)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getVehiclesDetails()
    }, [foundvehcileregNo])


    const handleInitiatePayment = async () => {
        try {
            const data = await handleInitiateTransporttkPush({
                vehicleOwnerregNo: vehicleOwnerregNo,
                customername:customername,
                amount:amount,
                noofseats:noofseats,
                paymentMethod:selectedPayOption,
                phone: phone,
                setStartTransaction: setStartTransaction,
                setSuccessShow:setSuccessShow ,
                setErrorShow: setErrorShow,
            });
            if (data?.status === 200) {
                setInvoiceNumber(data?.response?.data?.stkOrderId); 
                setSuccessShow({ state: true, message: data?.response?.message });
                listenWebSocket(data?.response?.data?.stkOrderId);
            } else {
                setErrorShow({ state: true, message: data?.response?.message });
            }
        } catch (error) {
            setErrorShow({ state: true, message: error?.message });
        }
    };



    const handleWebSocketResponse = (response) => {
        if (response?.status === "OK") {
            setSuccessShow({ state: true, message: response.message });
            setStartTransaction(false);
            setIsNavigating(true);
        } else if (response?.status === "FAILED") {
            setErrorShow({ state: true, message: response?.message });
            setStartTransaction(false);
            setIsNavigating(true);
        }
    };


    useEffect(() => {
        if (isNavigating) {
            clearInterval(socketInterval);
            async function checkInvoiceBalance() {
                const data = await getInvoicePayments(invoiceNumber);
                console.log(data , 'lest goooo')
                SetInvoiceBalance(data)
            }
            checkInvoiceBalance();

            if (parseInt(invoiceBalance) !== parseInt(amount)) {
                setSuccessShow({ state: true, message: 'Payment received' });
            }
            setMyInterval(null);
            setTimeout(() => {
                navigate(-1);
            }, 1500);
        }
    }, [isNavigating, amount, invoiceBalance ,invoiceNumber]);

    useEffect(() => {
        if (socketInterval === null) return;

        return () => {
            clearInterval(socketInterval);
            setMyInterval(null);
        };
    }, [socketInterval]);





    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Collect</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography>Payment Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Typography>Select Payment Method</Typography>
            </Grid>
            <Grid item width={'50%'} mt={1}>
                <Grid container display={'flex'} alignItems={'center'} width={'100%'} gap={2}>
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup sx={{ width: '100%', gap: 1 }} onChange={handleOnChange} value={selectedPayOption} row aria-labelledby="demo-row-radio-buttons-group-label" name="controlled-radio-buttons-group">
                            <Grid item width={'49%'}>
                                <Paper elevation={1} sx={{
                                    width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center',
                                    backgroundColor: selectedPayOption === 'mpesa' ? 'rgba(23, 174, 123, 0.05)' : '#fff', border: selectedPayOption === 'mpesa' ? '1px solid #17ae7b' : ''
                                }}>
                                    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box component={'img'} src={MpesaIcon} sx={{ height: "50px", width: '50px' }} />
                                        <Typography variant="h6" sx={{ fontSize: '12px', ml: 2 }}>Mpesa</Typography>
                                    </Box>
                                    <FormControlLabel value="mpesa" control={<Radio />} />
                                </Paper>
                            </Grid>
                            <Grid item width={'49%'}>
                                <Paper elevation={1} sx={{ width: '100%', height: "70px", display: 'flex', px: 1, justifyContent: 'space-between', alignItems: 'center', backgroundColor: selectedPayOption === 'cash' ? 'rgba(23, 174, 123, 0.05)' : '#fff', border: selectedPayOption === 'cash' ? '1px solid #17ae7b' : '' }}>
                                    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box component={'img'} src={CashIcon} sx={{ height: "50px", width: '50px' }} />
                                        <Typography variant="h6" sx={{ fontSize: '12px', ml: 2 }}>Cash</Typography>
                                    </Box>
                                    <FormControlLabel value="cash" control={<Radio />} />
                                </Paper>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item py={2} display={'flex'} alignItems={'center'} gap={1} direction={'column'} style={{ width: '50%' }}>
                <Typography sx={{ width: '100%' }}>Select Vehicle</Typography>
                <AutoCompleteFiltertransport label={'Vehicles'} sx={{ width: "100%" }} options={myvehicle} value={selectedVehicleRegNo} onChange={(e, newValue) => { setselectedVehicleRegNo(newValue) }} />
            </Grid>
            {foundvehcileregNo ? 
            <Grid item mb={2} width={'50%'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ width: '100%' }}>Seats Remaining</Typography>
                <Badge badgeContent={vehicleDetails?.seatsCount ?? 0} classes={{ badge: classes.badgeColorOverride }} sx={badgeStyles} />
            </Grid>:null}
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Phone</InputLabel>
                <PhoneInput required country={"ke"} enableSearch={true} label={"Mobile Number"} value={phone} onChange={(phone) => setPhone(phone)} multiline inputStyle={{ height: "55px", width: "100%" }} />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Name</InputLabel>
                <TextField style={{ width: "100%" }} value={customername} name='lastName' onChange={(e) => setCustomerName(e.target.value)} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <Grid container width={'100%'} display={'flex'} gap={1} alignItems={'center'}>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Number of Seats</InputLabel>
                        <TextField style={{ width: "100%" }} value={noofseats} name='lastName' onChange={(e) => setnumofseats(e.target.value)} id="outlined" placeholder="" multiline required />
                    </Grid>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}> Amount</InputLabel>
                        <TextField inputProps={{ inputMode: 'numeric' }} type="number" style={{ width: "100%" }} value={amount} name='amount' onChange={(e) => setAmount(e.target.value)} id="outlined" placeholder="" multiline required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mb={2} width={'50%'} justifyContent={'flex-end'} display={'flex'} >
               <Button onClick={() => handleInitiatePayment()} style={!customername || !amount || !noofseats || !phone || !selectedPayOption ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Pay</Button>
            </Grid>
        </Grid>
    )
}