import React from "react";
import { ReactSVG } from "react-svg";
import MySVG1 from "./Businesses-iconRED.svg";
import MySVG2 from "./Businesses-iconBLUE.svg";

function MySVGComponent() {
  const currentPath = window.location.pathname;

  let MySVG =
  currentPath === "/Businesses" ||
  currentPath === "/EditBusiness" ||
  currentPath === "/CreateBusiness" ||
  currentPath === "/ViewModules" ||
  currentPath === "/ViewBranches"
      ? MySVG1
      : MySVG2;

  return <ReactSVG src={MySVG} />;
}

export default MySVGComponent;
