import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    businessId: "",
    businessName: "",
    owner: "",
    email: "",
    phoneNumber: "",
    regionName:"",
    regionBranchName:"",
    partnerRegion:"",
    partnerBranch:"",
    salesPName:"",
    salesPCode:""
};

const businessUpdatedSlice = createSlice({
  name: "DetailsOfBusiness",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      console.log(action.payload , 'bbb')
      state.businessId = action.payload.businessId;
      state.businessName = action.payload.businessName;
      state.owner = action.payload.owner;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
      state.partnerBranch = action.payload.partnerBranch;
      state.partnerRegion = action.payload.partnerRegion;
      state.salesPName = action.payload.salesPName;
      state.salesPCode = action.payload.salesPCode;
    },
  },
});

export const { setDetails } = businessUpdatedSlice.actions;

export default businessUpdatedSlice.reducer;
