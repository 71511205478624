import { Box, Breadcrumbs, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import ExportMenu from "../ExportMenu";
import CustomSelect from "../CustomSelectField";
import HttpComponent from "../MakeRequest";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../../utils/dateFormatter";
import { currencyconverter } from "../../../common/currencyconverter";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Sponsor</Typography>,
    <Typography key={1} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}> Invoices</Typography>,
]

const ListSponsorInvoices = () => {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], count: 0, page: 1, pageSize: dataGridPageSize });
    const [searchValue, setSearchValue] = useState('')
    const [businessSponsors, setbusinessSponsors] = useState([])
    const [selectedsponsor, setSelectedSponsor] = useState("")
    const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("")
    const navigate = useNavigate()

    let invoiceStatusOptions = [
        {
            value: "Paid",
            label: "Paid"
        },
        {
            value: "Unpaid",
            label: "Unpaid"
        },
        {
            value: "Partially Paid",
            label: "Partially Paid"
        }
        ]

    function fetchSponsorsAdded() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?customerClassification=Sponsor&status=ACTIVE`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundSponsor = data?.response?.data?.map((item) => ({
                        value: item?._id,
                        label: item?.customerName
                    }))
                    setbusinessSponsors(foundSponsor)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchSponsorsAdded()
    }, [searchValue])


    //invoice columns 
    const sponsorColumns = [
        { field: "batchNo", headerName: "Batch No", flex: 1,renderCell: (params) => (
            <Typography
              onClick={() => navigate(`/school/batch/view/${params?.row?._id}`)}
              sx={{ color: "#032541", textDecoration: "underline", fontSize:'12px', cursor: 'pointer' }}
            >
              {params.row.batchNo}
            </Typography>) },
        { field: "updatedAt", headerName: "Date", flex: 1 ,renderCell:(params)=>{
            return <Typography>{DateFormatter(params?.row?.updatedAt)}</Typography>
        } },
        { field: 'totalAmount', headerName: 'Amount', flex: 1, renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.totalAmount)}</Typography>
        } },
        // { field: 'amountPaid', headerName: 'Amount Paid', flex: 1 },
        { field: 'amountDue', headerName: 'Amount Due', flex: 1 ,renderCell:(params)=>{
            return <Typography>{currencyconverter(params?.row?.amountDue)}</Typography>
        } },
        { field: 'sponsorName', headerName: 'Sponsor Name', flex: 1, },
        { field: 'status', headerName: 'Batch Status', flex: 1 },
        { field: 'paymentStatus', headerName: ' Status', flex: 1 ,renderCell:(params)=>{
            return <Typography sx={{fontWeight:700 , color:params?.row?.paymentStatus === 'Paid' ? '#17ae7b':'#cb444a'}}>{params?.row?.paymentStatus}</Typography>
        } },

    ]
    const csvColumns = []
    const fileHeaders = []
    const fileDataPDF = []
    const filedata = []

    function getSponsorInvoices(){
        try{
            HttpComponent({
                method: 'GET',
                url: `/api/get_invoice_batches?status=APPROVED&classification=&page=${pageState.page}&limit=${dataGridPageSize}&sponsorBatch=YES`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({...pageState, isLoading: false, data: data?.response?.data, count: data?.response?.data?.length, page: 1, pageSize: dataGridPageSize })
                }
            })
        }catch(error){

        }finally{
            setPageState({...pageState, isLoading: false})
        }
    }

    useEffect(() =>{
        getSponsorInvoices()
    }, [dataGridPageSize , pageState.page, selectedsponsor, selectedInvoiceStatus])

    return (
        <Grid container width={'100%'} display={'flex'} direction={'column'}>
            <Grid item mt={2} mb={2}>
                <Grid container gap={2} display={'flex'} direction={'column'}>
                    <Grid item width={'100%'} alignItems={'center'} display={'flex'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                            <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '20px' }}>Sponsorship Invoices</Typography>
                        </Grid>
                    </Grid>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item width={'100%'} display={'flex'} alignItems={'center'}>
                <Box component={"div"} display={"flex"} justifyContent={"flex-start"}>
                    <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                </Box>
                <Box component={"div"} display={"flex"} justifyContent={"flex-end"}  sx={{gap:1}} alignItems={'center'} width={"100%"}>
                    <Box component={'div'} sx={{width:"20%"}}>
                        <CustomSelect style={{width:"100%"}} placeholder={'Sponsors'} options={businessSponsors} name={'businessSponsors'} value={selectedsponsor} onChange={(e) => setSelectedSponsor(e.target.value)} />
                    </Box>
                    <Box component={'div'} sx={{width:"20%"}}>
                        <CustomSelect  style={{width:"100%"}} placeholder={'Invoice Status'} options={invoiceStatusOptions} name={'invoicestatus'} value={selectedInvoiceStatus} onChange={(e) => setSelectedInvoiceStatus(e.target.value)} />
                    </Box>
                    <Box component={'div'}>
                        <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Sponsors Invoices'} title={'Sponsors Invoices'} />
                    </Box>
                </Box>
            </Grid>
            <Grid item width={"100%"} mt={5}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "14px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState?.total}
                    loading={pageState?.isLoading}
                    pagination
                    page={pageState?.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={sponsorColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}

export { ListSponsorInvoices }