import React, { useEffect, useState } from 'react';
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import {CSVLink} from "react-csv";
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Box} from "@mui/material";
import startOrderFunction from '../bills/billscomponents/startOrderEndpoints';
const baseurl = process.env.REACT_APP_BASE_URL


const ExportMenu = ({ fileData, fileHeaders, csvColumns, fileDataPDF, title,fileName ,sendCSVEmail, sendEmail}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openExportMenu = Boolean(anchorEl);
    const startOrderEndpoints = new startOrderFunction();
    const [businessDetails, setBusinessDetails] = useState({businessName:'' , businessEmail:'' , businessPhone:'' , businessAddress:"" , businessLogo:''})

    const handleExportClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseExportMenu = () => {
        setAnchorEl(null);
    };

    //get business details

    useEffect(() =>{
		startOrderEndpoints.getBusinessInfo().then((data) => {
			if(data?.status === 200){
				setBusinessDetails({
                    ...businessDetails,
                    businessName:data?.response?.data?.businessName, 
                    businessEmail:data?.response?.data?.businessOwnerEmail,
                    businessPhone:data?.response?.data?.businessOwnerPhone, 
                    businessAddress:data?.response?.data?.businessOwnerAddress,
                    businessLogo:`${baseurl}/staticimages/logos/${data?.response?.data?.businessLogo}`
                })
			}
		})
	},[])

    const theDate =new Date(Date.now()).toLocaleDateString()

    const xlsxReport = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        /* generate XLSX file and send to client */
        XLSX.writeFile(
            wb,
            `${fileName}-${theDate}.xlsx`
        );
    };

    const exportPDF = (headers, data, docTitle) => {
        const unit = "pt";
        const size = "A4"; 
        const orientation = "landscape"; 
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        const titleX = marginLeft;
        const titleY = 40;
        const dateY = titleY + 15;
        const paddingRight = 80
        const logoX = doc.internal.pageSize.getWidth() / 2 - 40;
        const logoY = 20;
        const businessDetailsX = doc.internal.pageSize.getWidth() - marginLeft - 100 - paddingRight;
        let businessDetailsY = 40;
        const maxTitleWidth = logoX - marginLeft - 10

    
        doc.setFontSize(16);
        doc.text(docTitle, titleX, titleY ,{ maxWidth: maxTitleWidth });
    
        doc.setFontSize(12);
        // doc.text(theDate, titleX, dateY);
    
        if (businessDetails.businessLogo) {
            doc.addImage(businessDetails.businessLogo, "PNG", logoX, logoY, 40, 40); 
        }
    
        doc.setFontSize(10);
        doc.text(`${businessDetails.businessName}`, businessDetailsX, businessDetailsY);
        businessDetailsY += 15;
        doc.text(`${businessDetails.businessEmail}`, businessDetailsX, businessDetailsY);
        businessDetailsY += 15;
        doc.text(`${businessDetails.businessPhone}`, businessDetailsX, businessDetailsY);
        businessDetailsY += 15;
        doc.text(`${businessDetails.businessAddress}`, businessDetailsX, businessDetailsY);
    
        // Table content starting below the header
        let content = {
            startY: 100,
            head: headers,
            body: data,
        };
    
        doc.autoTable(content);
        doc.save(`${fileName}-${theDate}.pdf`);
    };
    

    const itemStyles = {
        color:"#707070",
        fontsize:"14px",
        '&:hover': {
            color: '#032541',
            textDecoration:"underline"
        }
    }

    const csvReport = {
        data: fileData,
        headers: csvColumns,
        filename: `${fileName}-${theDate}.csv`,
    };

    return (
        <Box  component="div" sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <Button
                id="export-button"
                aria-controls={openExportMenu ? 'export-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openExportMenu ? 'true' : undefined}
                onClick={handleExportClick}
                sx={{
                    backgroundColor:"#f5f6f7",
                    color:"#032541",
                    height:"2.813rem",
                    width:"6.25rem",
                    borderRadius:"4px",
                    "&:hover": {
                        backgroundColor:"#f5f6f7",
                        color:"#032541",
                    },
                }}
            >
                Export <MoreVertIcon/>
            </Button>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                open={openExportMenu}
                onClose={handleCloseExportMenu}
                MenuListProps={{
                    'aria-labelledby': 'export-button',
                }}
            >
                <MenuItem>
                    <CSVLink
                        style={{
                            color:"#707070",
                            fontsize:"14px",
                            textDecoration:"none",
                            '&:hover': {
                                color: '#032541',
                                textDecoration:"underline"
                            }
                        }}
                        {...csvReport}
                    >
                        Export as csv
                    </CSVLink>
                </MenuItem>
                <MenuItem sx={itemStyles} onClick={()=>xlsxReport(fileData)}>Export as Excel</MenuItem>
                <MenuItem sx={itemStyles} onClick={()=>exportPDF(fileHeaders,fileDataPDF, title)}>Export as PDF</MenuItem>
                {sendEmail === true ? <MenuItem onClick={sendCSVEmail} disableRipple> Send Report To Email</MenuItem>:null}
            </Menu>
        </Box>
    );
};

export default ExportMenu;
