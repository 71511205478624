import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import transactionDateReducer from "../features/transactionDateSlice";
import userReducer from "../features/userSlice";
import branchReducer from "../features/branchSlice";
import businessCategoryReducer from "../features/businessCategorySlice";
import businessBranchesReducer from "../features/businessBranchesSlice";
import servicesReducer from "../features/servicesSlice";
import customersReducer from "../features/customerSlice";
import tenantReducer from "../features/tenantsUnitsSlice";
import tenantDataReducer from "../features/tenantsDataSlice";
import sideBarReducer from "../features/sideBarSlice";
import tenantBusinessReducer from "../features/tenantBusinessSlice";
import autorefreshReducer from "../features/autorefreshonpageload";
import startorderReducer from "../features/startorderSlice";
import saveStudentReducer from "../features/saveStudentDetails";
import tabReducer from "../features/tabSlice";
import categoryReducer from '../features/generalBusinessCategorySlice';
import paymentOptionsSlice from "../features/paymentOptionsSlice";
import schoolType from "../features/schoolType";
import generateRequestableInvoiceData from "../features/generateRequestableInvoiceData";
import studentAutogenerateAdmissionReducer from "../features/studentAutogenerateadmslice"
import payableslice from "../features/payableslice";
import actionReducer from "../features/actionSlice";
import currentTabReducer from "../features/currentTabSlice";
import businessToUpdateSlice from "../features/businessToUpdateSlice";
import EditedConvenienceSlice from "../features/EditedConvenienceSlice";
import AlertSlice from "../features/AlertSlice"
import bankToUpdateSlice from "../features/bankToUpdateSlice"
import EditedBanksSlice from "../features/EditedBanksSlice"
import EditedTerminalsSlice from "../features/EditedTerminalsSlice"
import terminalToUpdateSlice from "../features/terminalToUpdateSlice"
import selectedSlice from "../features/selectedSlice"
import EditedBusinessesSlice from "../features/EditedBusinessesSlice"
import businessUpdatedSlice from "../features/businessUpdatedSlice"
import BusinessModuleSlice from "../features/BusinessModuleSlice"
import onChangeSlice from "../features/onChangeSlice"
import editedPartners from "../features/EditedPartners"
import partnerToUpdateSlice from "../features/partnerToUpdateSlice"
import userGroupToUpdate from "../features/userGroupToUpdate";
import EditedUserGroups from "../features/EditedUserGroups";
import SalesChartSlice from "../features/SalesChartSlice";
import cardBinToUpdateSlice from "../features/cardBinToUpdateSlice";
import configToUpdateSlice from "../features/configToUpdateSlice";
import reasonToUpdateSlice from "../features/reasonToUpdateSlice";
import categoryToUpdateSlice from "../features/categoryToUpdateSlice";
import partnerSelectSlice from "../features/partnerSelectSlice";
import searchSlice from "../features/searchSlice";
import ExporterSlice from "../features/ExporterSlice";
import modelToupdateSlice from "../features/modelToupdateSlice";
import dateSlice from "../features/dateSlice";
import userToUpdate from "../features/userToUpdate";
import CalendarSlice from "../features/CalendarSlice";
import salesDropdownSlice from "../features/salesDropdownSlice";
import staffSlice from "../features/staffSlice";
import transactionsSlice from "../features/transactionsSlice";
import BusinessPartnerDetailsSlice from '../features/businessAddPartner'
import DeviceModelsSlice from "../features/DeviceModelsSlice";
import OutletsSlice from "../features/OutletsSlice";


const rootReducer = combineReducers({
  transactionDate: transactionDateReducer,
  user: userReducer,
  branch: branchReducer,
  businessCategory: businessCategoryReducer,
  businessBranches: businessBranchesReducer,
  services: servicesReducer,
  customers: customersReducer,
  tenant: tenantReducer,
  tenantData: tenantDataReducer,
  sidebar: sideBarReducer,
  tenantBusinessData: tenantBusinessReducer,
  pageload: autorefreshReducer,
  startorder: startorderReducer,
  studentdetails: saveStudentReducer,
  requstableService: generateRequestableInvoiceData,
  tab: tabReducer,
  category: categoryReducer,
  payment: paymentOptionsSlice,
  schoolType:schoolType,
  schoolAdmNumGenerate:studentAutogenerateAdmissionReducer,
  payables: payableslice,
  dropdownAction: actionReducer,
  currentTab : currentTabReducer,
  businessDetails: businessToUpdateSlice,
  editedBusinesses: EditedConvenienceSlice,
  alertState: AlertSlice,
  bankDetails: bankToUpdateSlice,
  editedBanks: EditedBanksSlice,
  editedTerminals: EditedTerminalsSlice,
  terminalDetails: terminalToUpdateSlice,
  terminalSelect: selectedSlice,
  editedBiz: EditedBusinessesSlice,
  DetailsOfbusiness: businessUpdatedSlice,
  moduleDetails: BusinessModuleSlice,
  userChanged: onChangeSlice,
  editedPartners: editedPartners,
  partnerDetails: partnerToUpdateSlice,
  userGroupDetails: userGroupToUpdate,
  editedUserGroups: EditedUserGroups,
  salesSeries: SalesChartSlice,
  cardBinDetails: cardBinToUpdateSlice,
  configDetails: configToUpdateSlice,
  reasonDetails: reasonToUpdateSlice,
  categoryDetails: categoryToUpdateSlice,
  partnerSelect: partnerSelectSlice,
  search: searchSlice,
  exportDetails: ExporterSlice,
  DetailsOfModel: modelToupdateSlice,
  selectedDate: dateSlice,
  userDetails: userToUpdate,
  calendarEvents: CalendarSlice,
  salesDropdown: salesDropdownSlice,
  teachingStaff:staffSlice,
  BusinessPartnerDetails:BusinessPartnerDetailsSlice,
  transactionAmount: transactionsSlice,
  deviceModels: DeviceModelsSlice,
  outletDetails: OutletsSlice
});


const reduxPersistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: [],
};

const persistedRootReducer = persistReducer(reduxPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: [thunk],
});

export const persistedStore = persistStore(store);
