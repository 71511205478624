import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Breadcrumb from '../../../SuperAdmin/components/Breadcrumbs/Breadcrumbs';
import { Exports } from '../../../customerAccounts/customerComponents/exports';
import { NoRowsOverlay } from '../../../No Rows/noRowsOverlay';
import CustomSearchInput from '../../../School/CustomSearchInput';
import DateFormatter from '../../../../utils/dateFormatter';
import CurrencyFormatter from '../../../../utils/currencyFormatter';
import HttpComponent from '../../../School/MakeRequest';
import { currencyconverter } from '../../../../common/currencyconverter';
import ExportDataFromApi from '../../../SuperAdmin/components/Reports/ExportFromApi';
import { SuccessAlert } from '../../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../../snackBar Alerts/errorAlert';
export default function TerminalSummaryReport() {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [searchValue ,setSearchValue] = useState('')
     // exported data;
    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })


    useEffect(() => {
        const setLastSevenDays = () => {
            const result = [];
            for (let i = 0; i < 7; i++) {
                const d = new Date();
                d.setDate(d.getDate() - i);
                result.push(d.toLocaleDateString());
            }
            const lastSevenFirstStart = result[0];
            const lastSevenLastEnd = result[result.length - 1];
            setStartDate(lastSevenFirstStart);
            setEndDate(lastSevenLastEnd);
        };

        setLastSevenDays();
    }, []);



    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1));
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);
                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0, 0));
                const currentDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth() + 1, 0, 23, 59, 59, 999));
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth() - 1, 1, 0, 0, 0, 0));
                const lastDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), 0, 23, 59, 59, 999));
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                function defaultLast7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1]);

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                defaultLast7Days();
                break;
        }
    }, [handleDaysChange]);

    // fetch data

    function getAllTransactionsList() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/partnerterminalsreports?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllTransactionsList()
    }, [dataGridPageSize, pageState.page, startDate, searchValue, endDate])

    function handleExportCSV() {
        try {
          HttpComponent({
            method: 'GET',
            url: `/api/v1/exportterminalsummaryreport?exportData=exportdata&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`,
            token: localStorage.getItem("X-Authorization"),
          }).then((data)=>{
            if(data?.status === 200){
               setSuccessShow({state:true , message:data?.response?.message})
            }else{
              setErrorShow({state:true , message:data?.response?.message})
            }
          })
        } catch (error) {
            setErrorShow({state:true , message:error?.message})
        }
      }

    // terminal result column

    const AllTransactionColumns = [
        { headerName: "terminalSerialNumber", field: 'terminalSerialNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Serial Number</strong>) } },
        { headerName: "outlet", field: 'outlet', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Outlet </strong>) } },
        { headerName: "businessName", field: 'businessName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >School </strong>) } },
        { headerName: "branchNam", field: 'branchNam', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >branch</strong>) } },
        { headerName: "accountNumber", field: 'accountNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Account </strong>) } },
        { headerName: "transactionCount", field: 'transactionCount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }}>Transaction Count</strong>) } },
        { headerName: "totalAmount", field: 'totalAmount', headerAlign: 'left', align: 'left', flex: 1, renderCell:(params)=><strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }}>{currencyconverter(params?.row?.totalAmount)}</strong>,renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }} >Total Amount </strong>)}},
        { headerName: "createdAt", field: 'createdAt', headerAlign: 'left', align: 'left', flex: 1,renderCell:(params)=><strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }}>{DateFormatter(params?.row?.createdAt)}</strong>, renderHeader: () => { return (<strong style={{ fontSize: '14px', color: '#032541', fontWeight: 600 }}>Created On </strong>) } },
    ]

    return (
        <Grid container direction={'column'}>
              <SuccessAlert vertical="top" horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state}
                message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'} >
                <Stack alignItems='center' direction='row' spacing={2}>
                    <ArrowBackIosNewIcon
                        sx={{
                            color: "#707070",
                            "&:hover": { cursor: "pointer" },
                        }}
                        onClick={() => {
                            window.history.back();
                        }}
                    />
                    <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Terminal Summary Report</Typography>
                </Stack>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Breadcrumb name={'Terminal Summary Report'} />
            </Grid>
            <Grid item display={'flex'} alignItems={'center'} mt={2} width={'100%'}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                    <Grid item mt={1}>
                    <CustomSearchInput sm={{ width: "200px" }} placeholder={'search'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item display={'flex'}>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value='All'>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ExportDataFromApi handleDownloadData={handleExportCSV} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "12px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[10, 20, 50, 70, 100]}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pagination
                    page={pageState.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={AllTransactionColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}