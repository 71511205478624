import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { Grid, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
// import Form from "react-bootstrap/Form";
import moment from "moment-timezone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomSelectField from '../School/CustomSelectField';
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import axios from "axios";
import lodash from "lodash";
import DatePicker from "react-datepicker";
import DateTimeRangePicker from "@wojtekmaj/react-daterange-picker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { currencyconverter } from "../../common/currencyconverter";
import { CustomizedDate } from "../customerAccounts/customerComponents/customDate";

const baseUrl = process.env.REACT_APP_BASE_URL;



// OpenTable Modal Styling
const modalTableStyle = {
  transform: "translate(-50%, -50%)",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "900px",
  height: "350px",
  padding: "0 0 73.9px",
  borderRadius: "15px",
  backgroundColor: "#fff",
};

const columns = [
  {
    field: "productName",
    headerName: "Product Name",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Product Name"}
      </strong>
    ),
  },
  {
    field: "salesCount",
    headerName: "Sales Count",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"SalesCount"}
      </strong>
    ),
  },
  {
    field: "salesTotal",
    headerName: "Sales Total",
    flex: 1,
    align: "left",
    valueGetter: (params) => {
      return currencyconverter(params.row.salesTotal);
    },
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"SalesTotal"}
      </strong>
    ),
  },
];

const ReportSalesTotalByProduct = () => {
  // page size setter and getter
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  //add days to current date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionClose = () => setSessionOpen(false);
  const handleSessionOpen = () => setSessionOpen(true);

  //date range Filter setters and Getters
  const [dValue, onDValueChange] = useState([
    moment(new Date()).format("YYYY-MM-DD HH:MM:ss"),
    moment(addDays(new Date(), 1)).format("YYYY-MM-DD HH:MM:ss"),
  ]);

  //const dp open state
  const [isOpen, setIsOpen] = useState(false);

  //chart dropDown
  const [dropDown, setDropDown] = useState("7days");

  // Transaction typesetters and Getters
  const [transactionType, setTransactionType] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [productSalesList, setProductSalesList] = useState([]);
  const [salesTotal, setSalesTotal] = useState([]);
  const [salesCount, setSalesCount] = useState([]);

  // payment Type setter and getter
  const [paymentType, setPaymentType] = useState("All");
  const [cashierSelected, setCashierSelected] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [handleDaysChange, setHandleDaysChange] = useState("Last 7 Days");

  //const
  const [searchValue, setSearchValue] = useState("");

  //"DD-MM-YYYY HH:MM:ss"
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //Get Transactions by cashier
  //get_transactions_by_cashier
  useEffect(() => {
    const fetchSalesItems = async () => {
      try {
        const res = await axios.get(
          baseUrl +
            `/api/get_total_sales_by_item?cashier=${cashierSelected}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${pageSize}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        );
        if (res.status === 200) {
          setProductSalesList(res.data.data);
          setSalesTotal(res.data.total);
          setCount(res.data.count)
          setSalesCount(res.data.count);
        } else if (res.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchSalesItems();
  }, [cashierSelected, startDate, endDate, page, pageSize ,searchValue]);




  const rowData = productSalesList.map((order, index) => {
    return {
      id:index ,
      productName: order.productName,
      salesCount: order.salesCount,
      salesTotal: order.salesTotal,
    };
  });

  // console.log(rowData, 'Row Data')

  // Fetch Payment Types from DB
  const fetchTransactionTypes = async () => {
    try {
      const response = await fetch(baseUrl + "/api/transactionType", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      console.log(`datadatatt ${JSON.stringify(data)}`);
      if (response.status === 200 && data.Status === "SUCCESS") {
        setTransactionType(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  //get_cashiers_by_business
  const fetchCashiers = async () => {
    try {
      const response = await fetch(baseUrl + "/api/get_cashiers_by_business", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      console.log(`datadata ${JSON.stringify(data)}`);
      if (response.status === 200 && data.Status === "SUCCESS") {
        setCashiers(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const doFetchTransactionTypes = async () => {
      await fetchTransactionTypes();
    };
    doFetchTransactionTypes();
  }, []);

  useEffect(() => {
    const doFetchCashiers = async () => {
      await fetchCashiers();
    };

    doFetchCashiers();
  }, []);

  const handleChange = (event) => {
    setPaymentType(event.target.value);
  };

  //
  const handleCashierChange = (event) => {
    setCashierSelected(event.target.value);
  };

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

       // filter
    
      
      const [days, setDays] = useState([
          { value: "Default", label: "Default" },
          { value: "Today", label: "Today" },
          { value: "Yesterday", label: "Yesterday" },
          { value: "Last 7 Days", label: "Last 7 Days" },
          { value: "This Month", label: "This Month" },
          { value: "Last Month", label: "Last Month" },
          { value: "Custom Range", label: "Custom Range" },
        ]);
  
        useEffect(() => {
          switch (handleDaysChange) {
            case "Today":
              const today = new Date();
              setStartDate(today);
              setEndDate(today);
              break;
            case "Yesterday":
              let date = new Date();
              const yeseterday = new Date(date.setDate(date.getDate() - 1));
              // console.log(yeseterday);
              setStartDate(yeseterday);
              setEndDate(yeseterday);
              break;
            case "Last 7 Days":
              function Last7Days() {
                var result = [];
                for (var i = 0; i < 7; i++) {
                  var d = new Date();
                  result.push(d.setDate(d.getDate() - i));
                }
                const lastSevenFirst = new Date(result[0]);
                const lastSevenLast = new Date(result[result?.length - 1]);
                setStartDate(lastSevenLast); 
                setEndDate(lastSevenFirst);
              }
              Last7Days();
              break;
            case "This Month":
              const date1 = new Date();
              const firstDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0, 0));
              const lastDay = new Date(Date.UTC(date1.getFullYear(), date1.getMonth() + 1, 0, 23, 59, 59, 999));
              setStartDate(firstDay);
              setEndDate(lastDay);
              break;
            case "Last Month":
              const date2 = new Date();
              const firstDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth() - 1, 1, 0, 0, 0, 0));
              const lastDayLastMonth = new Date(Date.UTC(date2.getFullYear(), date2.getMonth(), 0, 23, 59, 59, 999));
              setStartDate(firstDayLastMonth);
              setEndDate(lastDayLastMonth);
              break;
            case "Default":
              setStartDate("");
              setEndDate("");
              break;
            case "Custom Range":
              setStartDate(new Date(dateFrom));
              setEndDate(new Date(dateTo));
              break;
            default:
              return;
          }
        }, [handleDaysChange, dateFrom, dateTo]);
 
  // CSV Export Format
  const csvColumns = [
    { label: "Product", key: "productName" },
    // {label:"Product Category", key:"productCategory"},
    // {label:"Product Name", key:"productName"},
    { label: "Number Sold", key: "salesCount" },
    { label: "Total Sold", key: "salesTotal" },
  ];

  // CSV Export
  const csvReport = {
    data: rowData,
    headers: csvColumns,
    filename:
      cashierSelected.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.csv`,
  };

  // columns selection for Excel Export
  const pdfData = rowData.map((order) => {
    return {
      productName: order.productName,
      // productName : transaction.productName,
      // productCategory : transaction.productCategory,
      salesCount: order.salesCount,
      salesTotal: order.salesTotal,
    };
  });
  //Excel Export Format
  const xlsxReport = (e, data) => {
    console.log(data);
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      cashierSelected.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.xlsx`
    );
  };

  const pdfTotal = [];
  const data = pdfData.map((data) => [
    data.productName,
    data.salesCount,
    currencyconverter(data.salesTotal),
  ]);
  pdfData.map((data) => {
    const x = data.salesTotal;
    pdfTotal.push(x);
  });

  //PDF export Format
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title =
      cashierSelected.toUpperCase() +
      ` Sale Report from ${dValue[0]} to ${
        dValue[1]
      } For Business ${localStorage.getItem(
        "businessName"
      )} TOTAL AMOUNT ${currencyconverter(salesTotal)}`;
    const headers = [
      [
        "productName",
        "salesCount",
        "salesTotal",
      ],
    ];

    console.log(data);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      cashierSelected.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.pdf`
    );
  };

  return (
    <div>
      <div>
        <div>
          <div>
            {/*sessionModal*/}
            <SessionModal
              onClose={handleSessionClose}
              sessionOpen={sessionOpen}
            />

            <Grid container sx={{ flex: 1 }}>
              <Grid item>
                <label
                  style={{
                    width: "100%",
                    height: "35px",
                    margin: "10px 32px 21px 12px",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.32",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#dc3545",
                  }}
                >
                  Product Sales
                </label>
              </Grid>
            </Grid>
            <Grid container sx={{ margin: "0px 0px 20px 0px" }}>
              <Grid item>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  Dashboard
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  \
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  Product Sales By Cashier
                </span>
              </Grid>
            </Grid>
            <Grid container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container display={'flex'} width={'100%'} gap={1} alignItems={'center'}>
                  <Grid item sx={{width:"200px"}}>
                    <Select value={paymentType}  onChange={handleChange} sx={{width:'100%'}} aria-label="Default select example">
                      <MenuItem value="All">All</MenuItem>
                      {transactionType.map((type) => (
                        <MenuItem
                          sx={{ fontFamily: "Poppins", fontSize: "12px" }}
                          value={type._id.transactionType}
                          key={type._id.transactionType}
                        >
                          {type._id.transactionType}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item  sx={{width:"200px"}}>
                    <Select sx={{width:'100%'}}
                      value={cashierSelected}
                      onChange={handleCashierChange}
                      aria-label="Default select example"
                      style={{
                        fontSize: "12px",
                        color: "#272d3b",
                      }}
                    >
                      {cashiers.map((cashierItem) => (
                        <MenuItem
                          sx={{ fontFamily: "Poppins", fontSize: "12px" }}
                          value={cashierItem._id.cashier}
                          key={cashierItem._id.cashier}
                        >
                          {cashierItem._id.cashier}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item style={{}}>
                    {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                    {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", }} />}
                  </Grid>
                  <Grid
                    item
                    alignContent={"right"}
                    sx={{ margin: "0px 0px 0px 12px" }}
                  >
                    <CSVLink
                      style={{
                        borderRadius: "6px",
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px 0 0",
                        padding: "9px 10px 8px 11px",
                        backgroundColor: "#05d8e1",
                      }}
                      {...csvReport}
                    >
                      {" "}
                      CSV{" "}
                    </CSVLink>
                    <Button
                      onClick={(e) => {
                        xlsxReport(e, pdfData);
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Excel
                    </Button>
                    <Button
                      onClick={() => {
                        exportPDF();
                      }}
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 0 0 12px",
                        padding: "9px 12px 8px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      PDF
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} columns={8}>
                  <Grid item xs={6}></Grid>
                  <Grid
                    alignItems={"right"}
                    item
                    sx={{ margin: "20px 0px 0px 12px" }}
                  >
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Search:
                    </label>
                    <input
                      value={searchValue}
                      onChange={handleSearchBox}
                      style={{
                        fontSize: "12px",
                        color: "#272d3b",
                        borderRadius: "6px",
                        width: "238px",
                        height: "36px",
                        margin: "0 0 0 20.4px",
                        padding: "7px 107.7px 6.4px 11.1px",
                        objectFit: "contain",
                        border: "solid 1px #032541",
                      }}
                      placeholder="search here"
                    ></input>
                  </Grid>
                </Grid>

                {/*datagrid grid*/}
                <Grid
                  sx={{
                    height: "400px",
                    margin: "50px 0 0 0",
                    border: "solid 0px transparent",
                    backgroundColor: "#fff",
                  }}
                >
                  <DataGrid
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      color: "#272d3b",
                    }}
                    rowCount={count}
                    rows={rowData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                  />

                  <Grid
                    alignItems={"right"}
                    item
                    sx={{ margin: "20px 0px 0px 12px" }}
                  >
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Total:
                    </label>
                    <label
                      style={{
                        width: "35px",
                        height: "12px",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      {currencyconverter(salesTotal)}
                    </label>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSalesTotalByProduct;
