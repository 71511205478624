import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import { customerstyles } from "./styles";

const Customertransaction = (props) => {
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const getCustomerTransactions = () => {
    HttpComponent({
      method: "GET",
      url: `/api/customerTransactions?customerId=${props.customerId}&limit=${dataGridPageSize}&page=${pageState.page}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
         console.log(data , 'customer transaction');
        setPageState({...pageState, isLoading: false, data: data.response.transaction, count: data.response.count });
      })
      .catch((e) => {
        setPageState({ isLoading: false });
        console.log(e.message);
      });
  };

  useEffect(() => {
    getCustomerTransactions();
  }, [props.customerId, pageState.page, dataGridPageSize]);

    // FILTER TRANSACTIONS ON DATE;
    const getFilteredData = async (DateTo, DateFrom) => {
      try {
        const getFilteredResp = await fetch(filterTranactionUrl + `?customerId=${id}&fromDate=${DateTo}&toDate=${DateFrom}&limit=${dataGridPageSize}&page=${pageState.page}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
  
        if (getFilteredResp.status === 201) {
          const getFilteredData = await getFilteredResp.json();
  
          // setCustomerTransactions(getFilteredData.transaction);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
  


  return (
    <div>
      <DataGrid
        style={customerstyles.dataGridStyling}
        columns={[
            { field: "transactionNo", headerName: "Transaction Code", flex: 1 },
            { field: "date", headerName: "Date", flex: 1 },
            { field: "itemNo", headerName: "No. of Items", flex: 1 },
            { field: "servedBy", headerName: "Served By", flex: 1 },
            { field: "amount", headerName: "Amount(KES)", flex: 1 },
            { field: "balance", headerName: "Balance(KES)", flex: 1 },
        ]}
        rows={pageState?.data?.map((customer) => ({
            id: customer?.transactionId,
            transactionNo: customer?.transactionNo,
            date: DateFormatter(customer?.transactionTime),
            itemNo: customer?.noOfItems,
            servedBy: customer?.servedBy,
            amount: customer?.amount,
            balance: customer?.customerBalance,
          }))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />
    </div>
  );
};

export default Customertransaction;
