import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { handleData } from "../../../../features/partnerSelectSlice";
import { handleBankEdited } from "../../../../features/EditedBanksSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/bankToUpdateSlice";
import { handleAssigned, handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import PartnerSelect from "./partnerSelect";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", 
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

// import SearchableSelect2 from "./SearchableSelect2";

const baseUrl = process.env.REACT_APP_BASE_URL;

function AssignPartner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const reduxBusinessNo = useSelector(
    (state) => state.DetailsOfbusiness.businessId
  );
  const reduxBusinessName = useSelector(
    (state) => state.DetailsOfbusiness.businessName
  );
  const reduxPartnerId = useSelector((state) => state.partnerSelect.partnerId);

  const [failMessage, setFailedMessage] = useState(null);

  const handleAssign = () => {
    if (reduxPartnerId === "") {
      setFailedMessage("please select partner");
    } else {
      try {
        axios
          .post(baseUrl + "/api/assignPartnerToBusiness", {
            bid: reduxBusinessNo,
            partnerId: reduxPartnerId,
          })
          .then((response) => {
            let data = response.data?.data;
            if (data) {
              try {
                axios
                  .post(baseUrl + "/api/setBusinessInPartner", {
                    bid: reduxBusinessNo,
                    partnerId: reduxPartnerId,
                  })
                  .then((response) => {
                    let data = response.data?.data;
                    if (data) {
                      console.log(response.data.message);
                      dispatch(handleAssigned(true));
                      // navigate("/Businesses")
                    } else {
                      console.log("no data in 2nd process");
                      console.log(response.data.message);
                      setFailedMessage(response.data.message);
                      navigate("/Businesses")
                    }
                  });
              } catch (e) {
                console.log(e);
              }
            } else {
              console.log("no data in 1st first process");
              console.log(response.data.message);
              setFailedMessage(response.data.message);
              navigate("/Businesses")
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [data, setData] = useState([]);

  const fetchActivePartners = () => {
    try {
      axios
        .get(baseUrl + "/api/fetchActivePartners", {
          headers: {
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        })
        .then((response) => {
          let data = JSON.stringify(response.data.data);
          let data2 = JSON.parse(data);
          setData(data2);
          dispatch(handleData(data2));
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchActivePartners();
  }, []);

  const assignedAlertState = useSelector((state) => state.alertState.assigned);
  const [showAssignedAlert, setShowAssignedAlert] = useState(false);

  const handleShowAssignedAlert = () => {
    if (assignedAlertState === true) {
      setShowAssignedAlert(true);
      navigate("/Businesses")

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowAssignedAlert(false);
        dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAssignedAlert();
  }, [assignedAlertState]);

  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailedMessage(null);
        // dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Assign Partner to Business
        </Typography>
        {showAssignedAlert && (
          <Alert variant="filled" severity="success">
            Partner Assigned successfully
          </Alert>
        )}
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Businesses"}
        link1={"/Businesses"}
        point2={"Assign Partner"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Details
            </Typography>
            <TextField
              label="Business Id"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxBusinessNo}
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              disabled
              className={classes.focusedTextField}
            />
            <TextField
              label="Business Name"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={reduxBusinessName}
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              disabled
              className={classes.focusedTextField}

            />
            <PartnerSelect />
            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/Businesses")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleAssign}
              >
                Assign
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AssignPartner;
