import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userGroups: [],
    businesses: [],
    banks: [],
    convenience: [],
    outlets: [],
    cardbins: [],
    terminals: [],
    transactions: [],
    partners: [],
    globalConfigs: [],
    reasonCodes: [],
    bizCategories: [],
    deviceModels: [],
    terminalUsers: [],
    users: [],
    customers: []
};

const ExporterSlice = createSlice({
  name: "exportDetails",
  initialState,
  reducers: {
    setUserGroups: (state, action) => {
      state.userGroups = action.payload;
    },
    setBusinesses: (state, action) => {
      state.businesses = action.payload;
    },
    setBanks: (state, action) => {
      state.banks = action.payload;
    },
    setConvenience: (state, action) => {
      state.convenience = action.payload;
    },
    setOutlets: (state, action) => {
      state.outlets = action.payload;
    },
    setCardbins: (state, action) => {
      state.cardbins = action.payload;
    },
    setTerminals: (state, action) => {
      state.terminals = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setPartners: (state, action) => {
      state.partners = action.payload;
    },
    setGlobalConfigs: (state, action) => {
      state.globalConfigs = action.payload;
    },
    setReasonCode: (state, action) => {
      state.reasonCodes = action.payload;
    },
    setBizCategories: (state, action) => {
      state.bizCategories = action.payload;
    },
    setDeviceModels: (state, action) => {
      state.deviceModels = action.payload;
    },
    setTerminalUsers: (state, action) => {
      state.terminalUsers = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
  },
});

export const { setCustomers, setUsers, setTerminalUsers, setUserGroups, setBusinesses, setBanks, setConvenience, setOutlets, setCardbins, setTerminals, setTransactions, setPartners, setGlobalConfigs, setReasonCode, setBizCategories, setDeviceModels } = ExporterSlice.actions;

export default ExporterSlice.reducer;
