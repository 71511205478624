import { Grid, InputLabel, Button, FormControl, MenuItem, TextField, Typography, Breadcrumbs, Select, OutlinedInput, Chip, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import HttpComponent from '../../School/MakeRequest';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Vehicles Owner</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Vehicle Details</Typography>,
]


export default function AddVehiclesSaccoDetails() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const theme = useTheme();

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormdata] = useState({ regNo: '', vehicleType: '', insurancecompany: '', policyNo: '', color: '' })
    const [policyExpiry, setpolicyExpiry] = useState('')
    const [vehicleType, setVehicleType] = useState([])
    const [vehicleRoutes, setVehicleRoutes] = useState([])
    const [routesId, setRoutesId] = useState([])
    const [partners, setPartners] = useState([])

    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, customerId] = newqueryParamValue.split("?")


    const handleFormdata = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setRoutesId(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleDelete = (item) => {
        const result = routesId?.filter(i => i !== item)
        setRoutesId(result)
    };
    const navigate = useNavigate();

    console.log(routesId , 'routesId')

    const routesidfound = routesId?.map((item)=>item?._id)

    function getAllVehicleTypes() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/listvehicletype`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setVehicleType(data?.response?.data)
                }

            })
        } catch (error) {

        }
    }

    function getAllRouts() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/listroutes/ACTIVE`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setVehicleRoutes(data?.response?.data)
                }

            })
        } catch (error) {

        }
    }

    function getAllPartners() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/fetchActivePartners`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPartners(data?.response?.data?.filter((item)=>item?.partnerBusinessesType === 'Transport'))
                }

            })
        } catch (error) {

        }
    }


    useEffect(() => {
        getAllPartners();
        getAllRouts();
        getAllVehicleTypes();
    }, [])

    // update create vehicle

    function addVehicleToBusiness(){
        try{
            HttpComponent({
                method:"POST",
                url:`/api/v1/addbusinessvehicle`,
                body:{
                    regNo: formData.regNo,
                    vehicleOwner: "Owner",
                    color: formData.color,
                    customerId: customerId,
                    insuranceCompanyId: formData.insurancecompany,
                    vehicleRouteId: routesidfound,
                    policyNo: formData.policyNo,
                    policyExpiry:policyExpiry,
                    vehicleType: formData.vehicleType
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if (data?.status === 200 || data?.status === 201) {
                   setSuccessShow({state:true , message:data?.response?.message})
                   setTimeout(()=>{
                     navigate(-1)
                   }, 1000)
                } else {
                     setErrorShow({state:true , message:data?.response?.message})
                }
            })
        }catch(error){
            setErrorShow({state:true , message:error?.message})
        }
    }

    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Vehicle Details</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography>Vehicle  Details</Typography>
            </Grid>
            <Grid item mt={2} mb={1} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Reg No</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.regNo} name='regNo' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item width={'50%'} mb={2}>
                <Grid container width={'100%'} gap={1}>
                    <Grid item width={'49%'}>
                        <FormControl style={{ width: '100%', marginTop: '20px' }}>
                            <InputLabel id="demo-simple-select-label">Vehicle Type</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" name='vehicleType' value={formData.vehicleType} label="Vehicle Type" onChange={handleFormdata}>
                                {vehicleType?.map((acc) => {
                                    return <MenuItem id={acc?._id} value={acc?._id}>{acc?.vehicleType}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item width={'49%'} mb={2}>
                        <FormControl style={{ width: '100%', marginTop: '20px' }}>
                            <InputLabel id="demo-simple-select-label">Insurance Company</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" name='insurancecompany' value={formData.insurancecompany} label="Insurance Company" onChange={handleFormdata}>
                                {partners?.map((acc) => {
                                    return <MenuItem id={acc?._id} value={acc?._id}>{acc?.insuranceCompany}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item mb={2} width={'50%'}>
                <Grid container width={'100%'} alignItems={'center'} gap={1}>
                    <Grid item width={'49%'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Policy No</InputLabel>
                        <TextField style={{ width: "100%" }} value={formData.policyNo} name='policyNo' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
                    </Grid>
                    <Grid item display={'flex'} width={'49%'} direction={'column'}>
                        <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Expiry Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Expiry Date"
                                value={policyExpiry}
                                onChange={(newValue) => setpolicyExpiry(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mb={2} width={'50%'}>
                <InputLabel style={{ fontWeight: "normal", fontSize: "14px", marginLeft: "", marginBottom: '10px' }}>Color</InputLabel>
                <TextField style={{ width: "100%" }} value={formData.color} name='color' onChange={handleFormdata} id="outlined" placeholder="" multiline required />
            </Grid>
            <Grid item mt={3} style={{ width: "50%" }}>
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label">Select Routes</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={routesId}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Select Routes" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value?._id} value={value?._id} label={value?.routeName} onDelete={() => handleDelete(value)} deleteIcon={<ClearIcon />} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {vehicleRoutes?.map((newitem) => (
                            <MenuItem
                                key={newitem?._id}
                                value={newitem}
                                style={getStyles(newitem, routesId, theme)}
                            >
                                {newitem?.routeName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={()=> addVehicleToBusiness()} disabled={!formData.regNo || !formData.color || !formData.insurancecompany || !formData.vehicleType ||!formData.policyNo ||!policyExpiry || routesidfound.length < 1
                } style={!formData.regNo || !policyExpiry || !formData.color || !formData.insurancecompany || !formData.vehicleType ||!formData.policyNo ||  routesidfound.length < 1 ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Next</Button>
            </Grid>
        </Grid>
    )
}
