import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import ConvenienceTabs from "../components/ConvenienceFeeMgt/ConvenienceTabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import {
  handleEdited,
  handleDeactivated,
  handleApproved,
  handleDeclined,
  handleActivated,
} from "../../../features/AlertSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { handleTabChange } from "../../../features/currentTabSlice";

function ConvenienceFeeMgt() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editedAlertState = useSelector((state) => state.alertState.edited);
  const deactivatedAlertState = useSelector(
    (state) => state.alertState.deactivated
  );
  const approveAlertState = useSelector((state) => state.alertState.approved);
  const declineAlertState = useSelector((state) => state.alertState.declined);
  const activateAlertState = useSelector((state) => state.alertState.activated);
  const [showEditedAlert, setShowEditedAlert] = useState(false);
  const [showDeactivatedAlert, setShowDeactivatedAlert] = useState(false);
  const [showApprovedAlert, setShowApprovedAlert] = useState(false);
  const [showDeclinedAlert, setShowDeclinedAlert] = useState(false);
  const [showActivatedAlert, setShowActivatedAlert] = useState(false);

  const handleShowAlert = () => {
    if (editedAlertState == true) {
      setShowEditedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowEditedAlert(false);
        dispatch(handleEdited(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowAlert();
  }, [editedAlertState]);

  const handleShowDeactivatedAlert = () => {
    if (deactivatedAlertState == true) {
      setShowDeactivatedAlert(true);
      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowDeactivatedAlert(false);
        dispatch(handleDeactivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowDeactivatedAlert();
  }, [deactivatedAlertState]);

  const handleApproveAlert = () => {
    if (approveAlertState == true) {
      setShowApprovedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowApprovedAlert(false);
        dispatch(handleApproved(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleApproveAlert();
  }, [approveAlertState]);

  const handleDeclineAlert = () => {
    if (declineAlertState == true) {
      setShowDeclinedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowDeclinedAlert(false);
        dispatch(handleDeclined(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleDeclineAlert();
  }, [declineAlertState]);

  const handleActivateAlert = () => {
    if (activateAlertState == true) {
      setShowActivatedAlert(true);

      // Automatically hide the alert after 5 seconds
      setTimeout(() => {
        setShowActivatedAlert(false);
        dispatch(handleActivated(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleActivateAlert();
  }, [activateAlertState]);

  const clickedItem = useSelector((state) => state.dropdownAction.clickedItem);

  const nextMove = () => {
    if (clickedItem == "Adjust") {
      navigate("/AdjustFee");
    }
  };

  const nextMove2 = () => {
    if (clickedItem == "Enable") {
      navigate("/EnableFee");
    }
  };

  useEffect(() => {
    nextMove();
    nextMove2();
  }, [clickedItem]);

  // dispatch(handleTabChange("Active")); //setting the tab in redux

  return (
    <Stack bgcolor={"white"}  spacing={1}>
      <Stack direction="row" justifyContent={"space-between"}>
        {/* <Stack direction="row" spacing={2} alignItems={"center"}>
          <ArrowBackIosNewIcon
            sx={{
              color: "#707070",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <Typography
            fontFamily="Poppins"
            fontSize={25}
            fontWeight="600"
            fontStyle="normal"
            textAlign="left"
            color="#032541"
          >
            Convenience Fee Mgt
          </Typography>
        </Stack> */}
        {showEditedAlert && (
          <Alert variant="filled" severity="success">
            Convenience Fee Adjusted successfully...Click on edited to approve
          </Alert>
        )}
        {showDeactivatedAlert && (
          <Alert variant="filled" severity="success">
            Convenience Fee Deactivated successfully...Click on Inactive to view
          </Alert>
        )}
        {showApprovedAlert && (
          <Alert variant="filled" severity="success">
            Convenience Fee Approved successfully...Click on Active to view
          </Alert>
        )}
        {showDeclinedAlert && (
          <Alert variant="filled" severity="success">
            Convenience Fee Declined successfully...Click on Active to view
          </Alert>
        )}
        {showActivatedAlert && (
          <Alert variant="filled" severity="success">
            Convenience Fee Enabled successfully...Click on Active to view
          </Alert>
        )}
      </Stack>
      {/* <Breadcrumbs name="Convenience Fee Mgt" /> */}
      <ConvenienceTabs />
    </Stack>
  );
}

export default ConvenienceFeeMgt;
