import { Grid, Typography, Breadcrumbs, IconButton, Button, Table, TableHead,Checkbox, TableRow, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TelegramIcon from '@mui/icons-material/Telegram';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useNavigate, useParams } from "react-router-dom";
import { AddCircle, Delete } from "@mui/icons-material";
import { sumBy } from "lodash";
import DateFormatter from "../../../utils/dateFormatter";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import CustomeronAccountFunctions from "./customerOnAccountFunctions";

const baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography style={{ color: "#707071", fontSize: "14px", fontWeight: 'normal', fontFamily: 'Poppins' }} key={1}>Dashboard</Typography>,
    <Typography style={{ color: "#707071", fontSize: "14px", fontWeight: 'normal', fontFamily: 'Poppins' }} key={2}>Customers</Typography>,
    <Typography style={{ color: "#707071", fontSize: "14px", fontWeight: 'normal', fontFamily: 'Poppins' }} key={3}>Create Invoice</Typography>,
    <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal', fontFamily: 'Poppins' }} key={4}>Preview Invoice</Typography>
]

//styles

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "KES",
    }).format(value);

export default function PreviewCreditInvoice(props) {
    const classes = useStyles()
    const date = new Date();
    const formattedDate = format(date, "EEEE, MMMM dd, yyyy");
    const [businessInfo, setBusinessInfo] = useState();
    const [responseData, setResponseData] = useState();
    const [sucess, setSucess] = useState({ state: false, message: "" });
    const [error, setError] = useState({ state: false, message: "" });
    const { id } = useParams();


    const navigate = useNavigate()

    const invoiceArrayProduct = props?.transactions;
    const [newInvoiceArrays, setInvoiceItemsArray] = useState(invoiceArrayProduct)
   

    const invoiceTotals = sumBy(newInvoiceArrays, (invoiceArray) => {
      return invoiceArray?.totalAmountCount;
    });

    const [newInvoiceArray, setNewInvoiceArray] = useState([])

    useEffect(() => {

        const InvoiceArray = newInvoiceArrays?.map((prod) => {
          return      {
            pushTransactionId: prod?.pushTransactionId,
            createdAt: prod?.createdAt,
            totalItemCount: prod?.totalItemCount,
            totalAmountCount:prod?.totalAmountCount
          }
        })
    
        setNewInvoiceArray(InvoiceArray)
     
      }, [invoiceArrayProduct])
  

    //CUSTOMER DETAILS
    const [customerInfo, setCustomerInfo] = useState({})

    function fetchCustomerDetails(){
        try {
          HttpComponent({
            method:'GET',
            url:`/api/get_customer_by_customer_id?customerId=${id}`,
            token:localStorage.getItem('X-Authorization')
          }).then((data)=>{
            console.log(data , 'customer info')
            if(data.status === 200){
                setCustomerInfo(data?.response?.data)
            }
          })
        } catch (error) {
          
        }
      }
    
      useEffect(()=>{
        fetchCustomerDetails()
      },[id])
    
      const {firstName ,lastName ,email ,phone,customerType ,customerAddress} =customerInfo


    //BUSINESS INFO

    const getBusinessDetails = async () => {
        try {
          const businessDetailsResponse = await fetch(baseUrl + "/api/get_business_info", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
    
          if (businessDetailsResponse.status === 200) {
            const businessDetailsData = await businessDetailsResponse.json();
            console.log("business data ", businessDetailsData.data);
            setBusinessInfo(businessDetailsData.data);
          }
        } catch (error) {
          console.error("error fetching customer details", error.message);
        }
      };

      useEffect(()=>{
        getBusinessDetails()
      },[])

      //SEND INVOICE

      const invoiceBody = {
        customerId: id,
        type:`one-off`,
        frequency:'once',
        products:newInvoiceArray

      }
     function sendInvoice(){
        try {
            HttpComponent({
                method:"POST",
                url:'/api/generateInvoice',
                body:invoiceBody,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{

                console.log(data , 'invoice send')
                if(data.status === 200){
                    setResponseData(data?.response?.data);
                    setSucess({ state: true, message: "Invoice has been craeted and sent to customers email!" });
                    setTimeout(() => {
                     setSucess({ state: false });
                     navigate(`/customerDetails/${id}?Invoice`);
                   }, 2000);
                }
            })
            
        } catch (error) {
            
        }
     }

      // DOWNLOAD INVOICE

    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} justifyItems={'center'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ color: "#687c8d" }} />
                <Typography style={{ color: "#032541", fontSize: "25px", fontWeight: 600, fontFamily: 'Poppins' }}>Preview Invoice</Typography>
            </Grid>
            <Grid item mt={2} mb={2}>
                <Grid container display={'flex'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <span
                            style={{
                                backgroundColor: "rgba(3, 37, 65, 0.05)",
                                width: "45px",
                                height: "45px",
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: 5,
                                borderRadius: 5,
                            }}
                        >
                            <IconButton aria-label="download">
                                <BookmarksIcon sx={{ color: "#032541", fontSize: "25px" }} />
                            </IconButton>
                        </span>
                        <span
                            style={{
                                backgroundColor: "rgba(3, 37, 65, 0.05)",
                                width: "45px",
                                height: "45px",
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: 5,
                                borderRadius: 5,
                            }}
                        >
                            <IconButton aria-label="download">
                                <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                            </IconButton>
                        </span>
                        <Button onClick={sendInvoice} style={{ background: "#032541", alignContent: "center", width: "100px", textTransform: 'inherit', borderRadius: "5px", margin: "0 0 0 15px", display: "flex", alignItems: "center", height: "45px", color: "#fff" }}>
                            <IconButton aria-label="download">
                                <TelegramIcon sx={{ color: "#fff", fontSize: "25px", marginRight: "5px" }} />
                            </IconButton>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ width: "80%", marginLeft: "10%" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "visible",
                        height: "auto",
                    }}
                    id="downloadable"
                >
                    <div
                        className={classes.p3}
                        style={{
                            backgroundColor: "rgba(220,53,69,0.05)",
                            height: "150px",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            className={classes.p1}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{
                                    color: "black",
                                    fontSize: "40px",
                                    fontWeight: "700",
                                }}
                            >
                                INVOICE
                            </span>
                            <span
                                style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                }}
                            >
                                Date:
                            </span>
                            <span style={{ fontSize: "14px" }}>{formattedDate}</span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{
                                    color: "#dc3545",
                                    fontSize: "40px",
                                    fontWeight: "700",
                                }}
                            >
                                Unpaid
                            </span>
                            <span
                                style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                }}
                            >
                                Invoice No:
                            </span>
                            {responseData && responseData.status === "SUCCESS" && <span style={{ fontSize: "14px" }}>{responseData.data.invoiceNumber}</span>}
                        </div>
                    </div>

                    <div
                        className={classes.p3}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "18px",
                                }}
                            >
                                From:
                            </span>
                            <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                                {businessInfo?.businessName}
                            </span>
                            <span className={classes.address_text_common}>
                                {businessInfo?.businessOwnerAddress}, {businessInfo?.country}
                            </span>
                            <span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
                            <span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
                        </div>
                        <div
                            className={classes.mt4}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "right",
                            }}
                        >
                            <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                                Billed to:
                            </span>
                            {customerType === 'Company' ?  <span className={classes?.address_text_common}>{firstName}</span> :
                                    <>
                                     <span className={classes?.address_text_common}>{firstName}</span>
                                     <span className={classes?.address_text_common}>{lastName}</span>
                                    </> 
                            }
                           
                            <span className={classes?.address_text_common}>{email}</span>
                            <span className={classes?.address_text_common}>{phone}</span>
                            <span className={classes?.address_text_common}>{customerAddress}</span>
                        </div>
                    </div>

                    <div className={classes.m1} style={{ border: "dotted 1px" }}></div>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.textLeft}>Transaction No</TableCell>
                                <TableCell className={classes.textCenter}>Transaction Date</TableCell>
                                <TableCell className={classes.textCenter}>No of Items</TableCell>
                                <TableCell className={classes.textRight}>Total (KES)</TableCell>
                                <TableCell className={classes.textRight}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {newInvoiceArrays?.map((product, index) => (
                            <TableRow key={index}>
                                <TableCell className={classes.textLeft}>
                                    <span className={classes.table_body}>{product?.pushTransactionId}</span>
                                </TableCell>
                                <TableCell className={classes.textCenter}>
                                    <span className={classes.table_body}>{DateFormatter(product?.createdAt)}</span>
                                </TableCell>
                                <TableCell className={classes.textCenter}>
                                    <span className={classes.table_body}>{product?.totalItemCount}</span>
                                </TableCell>
                                <TableCell className={classes.textRight}>
                                    <span className={classes.table_body}>{numberFormat(product.totalAmountCount)}</span>
                                </TableCell>
                                <TableCell className={classes.textRight}>
                                    <span className={classes.table_body}><Delete style={{ color: "red", cursor: "pointer" }} /></span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>

                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", margin: "10px 0px 0px 0px" }} >
                        <span style={{ cursor: 'pointer' }} onClick={() => {
        
                        }} > <AddCircle /> Add products/Service</span>
                    </div>
                    <div
                        className={classes.p3}
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <div
                            className={classes.p1}
                            style={{
                                backgroundColor: "#f7f7f7",
                                width: "288px",
                                height: "130px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Subtotal</span>
                                <span>{numberFormat(invoiceTotals)}</span>
                            </div>
                            <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Discount</span>
                                <span>KES 0.00</span>
                            </div>
                            <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ color: "#dc3545", fontWeight: "bold" }}>Total</span>
                                <span
                                    style={{
                                        fontSize: "16px",
                                        color: "#dc3545",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {numberFormat(invoiceTotals)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style={{ border: "dotted 1px" }}></div>
                    <div className={classes.p3} style={{ display: "flex", flexDirection: "column" }}>
                        <div className={classes.p1}>
                            <span
                                style={{
                                    color: "#032541",
                                    fontWeight: "700",
                                    fontSize: "16px",
                                }}
                            >
                                Accepted Payment Methods
                            </span>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <img className={classes.p2} src={mpesaIcon} alt="M-pesa" />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "12px",
                                    }}
                                >
                                    <span>M-Pesa</span>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <img className={classes.p2} src={cardIcon} alt="Visa" />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "12px",
                                    }}
                                >
                                    <span>Card</span>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <img className={classes.p2} src={creditIcon} alt="Credit" />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "12px",
                                    }}
                                >
                                    <span>Credit Balance</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ border: "dotted 1px" }}></div>

                    <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                        <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
                            This is a system-generated invoice is created without any alteration whatsoever.
                            <br />
                            Thank you for your business.
                        </div>
                    </div>
                    <div
                        className={classes.p2}
                        style={{
                            backgroundColor: "#f7f7f7",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <span className={classes.footer_text}>
                            Powered by{" "}
                            <span
                                style={{
                                    fontWeight: "700",
                                }}
                            >
                                ZED Payments Limited
                            </span>
                        </span>
                        <span className={classes.footer_text}>.</span>
                        <span className={classes.footer_text}>info@zed.business</span>
                        <span className={classes.footer_text}>.</span>
                        <span className={classes.footer_text}>v1.0.2</span>
                    </div>
                </div>
            </div>

        {sucess && <SuccessAlertRightAligned message={sucess.message} sucess={sucess.state} />}
      {error && <ErrorAlertRightAligned message={error.message} error={error.state} />}
        </Grid>

    )
}