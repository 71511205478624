import { Typography ,Grid ,Breadcrumbs, Button } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DatePicker from 'react-datepicker'
import './datepicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectField from "../../School/CustomSelectField";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { handleSaveTenantNotice, handleTenantExitNotice } from "../../../features/tenantsDataSlice";

//breadcrumps notice
const breadcrumbs = [
    <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
      Dashboard
    </Typography>,
    <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.inactive">
      Tenants
    </Typography>,
    <Typography style={{ color: "#dc3545", fontFamily: "Poppins", fontSize: "14px" }} key="3" color="text.error">
      Vacate Notice
    </Typography>,
  ];
  

export default function SendVacateNotice(props){
  const {customerId,houseNo ,houseType ,_id} = useSelector((store)=>store.tenantData.tenantWithExitNotice)
  const { X_Authorization } = useSelector((store) => store.user);
  const [evictionReason, setEvictionReason] = useState([]);
  const evictR = evictionReason.map((reason) => ({ value: reason, label: reason }));
  const currentDate = new Date();
  const [myMonth, setMyMonth] = useState(currentDate);
  const [myYear, setMyYear] = useState(currentDate);
  const [myDay, setMyDay] = useState(currentDate);
  const [evReason, setEvReason] = useState("");
  const minDate = new Date(myYear.getFullYear(), myMonth.getMonth(), 1);
  const maxDate = new Date(myYear.getFullYear(), myMonth.getMonth() + 1, 0);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });

  const dispatch = useDispatch()

  const renderDayContents = (day, date) => {
    if (date < minDate || date > maxDate) {
      return <span></span>;
    }
    return <span>{date.getDate()}</span>;
  };

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}

  useEffect(() => {
    setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), 1));
  }, [myMonth, myYear, setMyDay]);


  function fetchEvictionReasons() {
    try {
      try {
        HttpComponent({
          method: "GET",
          url: `/api/get_tenant_eviction_reasons`,
          token: X_Authorization,
        })
          .then((data) => {
            console.log(data, "eviction reasons");
            if (data.status === 200) {
              setEvictionReason(data.response.data);
            } else {
              console.log(`Error getting Eviction reasons`);
            }
          })
          .catch((e) => {
            console.log(`Error getting Eviction reasons`, e.message);
          });
      } catch (e) {
        console.log(`Error getting Eviction reasons`, e.message);
      }
    } catch (e) {
      console.log(`Error getting Eviction reasons`, e.message);
    }
  }
  //fetch eviction reasons

  useEffect(() => {
    fetchEvictionReasons();
  }, []);

   // post eviction notice

  const PostData = {
    unitType: _id,
    reason: evReason,
    unitTypeName: houseType,
    tenantId: customerId,
    houseNumber: houseNo,
    evictionDate: formatDate(myDay, myMonth, myYear),
    source: "LANDLOARD",
  }

   function postEvictionNotice() {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/generate_tenant_eviction_notice',
        body: PostData,
        token: X_Authorization,
      }).then((data) => {
        console.log(data, 'tenant eviction notice created')
        if (data.status === 201) {
          dispatch(handleSaveTenantNotice(data.response.noticeNumber))
          setSuccessShow({ state: true, message: "Tenant Exit Notice Created Successfully" });
          props.goPreveiewN()
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      });
    } catch (error) {
      console.log(error)
    }
  }
  //"AlmostHealed_1"

  console.log(PostData)

  function getNotice(){
    try {
        HttpComponent({
            method: "POST",
            url: `/api/get_eviction_notice_by_noticeId`,
            body: {noticeNumber:"AlmostHealed_1" ,tenantId:"64707b3c2dd787a8af25fd9b"},
            token: X_Authorization,
        }).then((data)=>{
           
        })
    } catch (error) {
        
    }
  
  }

  useEffect(()=>{
    getNotice()
  },[])


    return(
        <>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <Grid container direction={'column'}>
                <Grid mt={2} item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'}>
                    <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={props?.goBack} />
                    <Typography variant="h5" style={{ color: "#032541", fontWeight: 700 }}>Vacate Notice</Typography>
                </Grid>
                <Grid item mt={3}>
                    <Breadcrumbs style={{ fontFamily: "Poppins", fontSize: "14px" }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mt={3}>
                     <Typography style={{ color: "#707070", fontFamily: "Poppins", fontSize: "14px" }} >Tenant Vacate Date</Typography>
                </Grid>
                <Grid item>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            dateFormat="dd"
                            renderCustomHeader={({ date }) => <div></div>}
                            selected={myDay}
                            renderDayContents={renderDayContents}
                            onChange={(date) => setMyDay(date)}
                            className="datepickerInput"
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            showMonthYearPicker
                            dateFormat="MMMM"
                            placeholderText="Month"
                            renderCustomHeader={({ date }) => <div></div>}
                            selected={myMonth}
                            onChange={(date) => setMyMonth(date)}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            selected={myYear}
                            placeholderText="Year"
                            onChange={(date) => setMyYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                        />
                    </div>
                </Grid>
                <Grid item>
                    <CustomSelectField
                        style={{ color: "#032541FF", width: "310px", fontFamily: "Poppins", fontWeight: "normal" }}
                        value={evReason}
                        onChange={(e) => {
                            setEvReason(e.target.value);
                        }}
                        name={"Reason"}
                        placeholder={"Select Eviction Reason"}
                        options={evictR}
                    />
                </Grid>
                <Grid item mt={3} display={'flex'} style={{marginLeft:"160px"}}>
                    <Button onClick={postEvictionNotice} style={!evReason || !myYear || !myMonth || !myDay ? {background:"#032541" ,color:"#fff" ,textTransform:'inherit' , cursor:'pointer', width:"150px" , opacity:.2}:{background:"#032541" ,color:"#fff",textTransform:'inherit' , cursor:'pointer', width:"150px" }}>Preview Notice</Button>
                </Grid>
            </Grid>
        </>
    )
}