import { Box, Button, Grid, Modal, Tab, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";
import CustomSearchInput from "../CustomSearchInput";
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ReplayIcon from '@mui/icons-material/Replay';
import { SuccessAlert } from "../../snackBar Alerts/successAlert"
import { ErrorAlert } from "../../snackBar Alerts/errorAlert"
import { useNavigate, useLocation } from "react-router-dom"
import AcceptImg from "../../../images/accept-icn.svg"
import DeclineImg from "../../../images/sad-decline-icn.svg"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { styled } from "@mui/material/styles";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";
import { useDispatch } from 'react-redux'
import { handleGetTeachingStaffCourses, handleSaveStaffCoursesUnits } from "../../../features/staffSlice";

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "440px", height: "440px", p: 4, };

// custom tab styling
const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": { backgroundColor: "" },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "18px",
        background: "",
        borderRadius: "5px",
        marginLeft: "-10px",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": { color: "#032541", opacity: 1 },
        "&.Mui-selected": {
            color: "#dc3545",
            backgroundColor: "#fef7f8",
            fontWeight: 600,
        },
        "&.Mui-focusVisible": { backgroundColor: "blue" },
    })
);

export default function TeachingStaff(props) {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [openRestoreStaff, setOpenRestoreStaff] = useState(false)
    const [openDeactivateStaff, setOpenDeactivateStaff] = useState(false)
    const [staffName, setStaffName] = useState('')
    const [staffId, setStaffId] = useState('')
    const [customerId, setcustomerId] = useState('')
    const [tabValue, setTabValue] = useState('ACTIVE')

    const dispatch = useDispatch()

    //handle tabvalue change
    const handleTabChange = (event, newTabValue) => setTabValue(newTabValue)

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    const handleOpenRestore = (params) => {
        setcustomerId(params?._id)
        setStaffName(params?.customerName)
        setStaffId(params?.admissionNo)
        setOpenRestoreStaff(true)
    }
    const handleCloseRestore = () => {
        setStaffName('')
        setStaffId('')
        setcustomerId('')
        setOpenRestoreStaff(false)
    }

    const handleOpenDeactivate = (params) => {
        setcustomerId(params?._id)
        setStaffName(params?.customerName)
        setStaffId(params?.admissionNo)
        setOpenDeactivateStaff(true)
    }

    const handleCloseDeactivate = () => {
        setStaffName('')
        setStaffId('')
        setcustomerId('')
        setOpenDeactivateStaff(false)

    }

    const [searchValue, setSearchValue] = useState('')

    const navigate = useNavigate()

    // fetch staff added

    function fetchStaffAdded() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/customers_list?page=${pageState.page}&limit=${dataGridPageSize}&customerClassification=TeachingStaff&status=${tabValue}&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'staff')
                if (data?.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchStaffAdded()
    }, [tabValue, dataGridPageSize, pageState?.page])


    const handleNavigation = (params) => {
        console.log(params , 'params >>>')
        dispatch(handleGetTeachingStaffCourses(params?.row?.courses))
        return navigate(`/school/universityStaff?updateStaffProfile?${params?.row?._id}`)
    }

    // active Staff

    const ActiveStaff = [
        { headerName: "StaffName", field: 'customerName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Staff Name</strong>) }, renderCell: (params) => { return (<span style={{fontSize:'12px'}}>{params?.row?.customerName}</span>) } },
        { headerName: "StaffId", field: 'admissionNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Staff ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" ,fontSize:'12px' }}>{params?.row?.admissionNo}</span></>) } },
        { headerName: "phoneNo", field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Phone Number</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" ,fontSize:'12px' }}>{params?.row?.mobileNumber}</span></>) } },
        { headerName: "email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px"  ,fontSize:'12px'}}>{params?.row?.email}</span></>) } },
        { headerName: "courses", field: 'courses', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Courses Assigned</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px"  ,fontSize:'12px'}}>{params?.row?.courseCount + 'courses'}</span></>) } },
        { headerName: "units", field: 'units', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Units Assigned</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px"  , fontSize:'12px'}}>{params?.row?.unitsCount + 'units'}</span></>) } },
        {
            headerName: "action",
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <EditIcon onClick={() => handleNavigation(params)} style={{ color: '#032541', cursor: 'pointer' }} />
                        <PersonOffIcon onClick={() => handleOpenDeactivate(params?.row)} style={{ color: '#dc3545', marginLeft: '10px', cursor: 'pointer', }} />
                    </Grid>
                )
            }
        },
    ]


    // inactive


    const InActiveStaff = [
        { headerName: "StaffName", field: 'customerName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Staff Name</strong>) }, renderCell: (params) => { return (<span>{params?.row?.customerName}</span>) } },
        { headerName: "StaffId", field: 'admissionNo', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Staff ID</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.admissionNo}</span></>) } },
        { headerName: "phoneNo", field: 'mobileNumber', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Phone Number</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.mobileNumber}</span></>) } },
        { headerName: "email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.email}</span></>) } },
        { headerName: "courses", field: 'courses', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Courses Assigned</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.courseCount + 'courses'}</span></>) } },
        { headerName: "units", field: 'units', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Units Assigned</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.unitsCount + 'units'}</span></>) } },
        {
            headerName: "action",
            field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: (params) => {
                return (
                    <Grid item display={'flex'} alignItems={'center'}>
                        <ReplayIcon onClick={() => handleOpenRestore(params?.row)} style={{ color: '#17ae7b', cursor: 'pointer' }} />
                    </Grid>
                )
            }
        },
    ]





    // handle deactivate customer ==> /api/suspendCustomer?customerId=${suspendId}

    function handleDeactivateStaff() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/suspendCustomer?customerId=${customerId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 202) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    handleCloseDeactivate()
                    fetchStaffAdded()
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                    fetchStaffAdded()
                }
            })
        } catch (error) {

        }
    }
    // handle restore customer

    function handleRestoreStaff() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/activateCustomer?customerId=${customerId}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 202) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    handleCloseRestore()
                    fetchStaffAdded()
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                    fetchStaffAdded()
                }
            })
        } catch (error) {

        }
    }


    return (
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <TabContext value={tabValue}>
                    <AntTabs onChange={handleTabChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                        <AntTab label="Active" value="ACTIVE" />
                        <AntTab label="Deactivated" value="Suspended" style={{ marginLeft: "20px" }} />
                    </AntTabs>
                    <TabPanel value="ACTIVE">
                        <Grid item>
                            <Grid container direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                    {/* <Button onClick={() => navigate(`/school/universityStaff?Addstuff`)} style={{ background: "#032541", width: "141px", height: "45px", fontSize: "14px", textTransform: "inherit", color: "#fff" }}>Add Staff</Button> */}
                                </Grid>
                                <Grid item>
                                    <DataGrid
                                        components={{ NoRowsOverlay: NoRowsOverlay }}
                                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                        rowCount={pageState?.total}
                                        loading={pageState?.isLoading}
                                        pagination
                                        page={pageState?.page - 1}
                                        pageSize={dataGridPageSize}
                                        paginationMode="server"
                                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        columns={ActiveStaff}
                                        rows={pageState?.data}
                                        getRowId={row => row?._id} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Suspended">
                        <Grid item>
                            <Grid container direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <CustomSearchInput name={'search'} placeholder={'search :'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <DataGrid
                                        components={{ NoRowsOverlay: NoRowsOverlay }}
                                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                        rowCount={pageState?.total}
                                        loading={pageState?.isLoading}
                                        pagination
                                        page={pageState?.page - 1}
                                        pageSize={dataGridPageSize}
                                        paginationMode="server"
                                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        columns={InActiveStaff}
                                        rows={pageState?.data}
                                        getRowId={row => row?._id} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Grid>

            <Modal
                open={openRestoreStaff}
                onClose={handleOpenRestore}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} ustifyContent={'center'} alignItems={'center'}>
                            <Grid item display={'flex'} justifyContent={'center'} direction={'column'} alignItems={'center'}>
                                <Grid item>
                                    <img src={AcceptImg} alt='Warning' />
                                </Grid>
                                <Grid item direction={'column'} textAlign={'center'} alignItems={'center'}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Reactivate Staff ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to reactivate  <span style={{ color: '#000000', fontWeight: 600 }}>{staffName}</span> - <span style={{ color: '#000000', fontWeight: 600 }}>{staffId}</span>
                                    </Typography>
                                    <span>All activity in this business will be restored!</span>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseRestore} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleRestoreStaff} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#17ae7b" }}>Reactivate</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDeactivateStaff}
                onClose={handleCloseDeactivate}
                slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item display={'flex'} justifyContent={'center'} direction={'column'} alignItems={'center'}>
                                <Grid item>
                                    <img src={DeclineImg} alt='Warning' />
                                </Grid>
                                <Grid item direction={'column'} textAlign={'center'} alignItems={'center'}>
                                    <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                                        Deactivate staff ?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Are you sure you want to deactivate <span style={{ color: '#000000', fontWeight: 600 }}>{staffName}</span> - <span style={{ color: '#000000', fontWeight: 600 }}>{staffId}</span>
                                    </Typography>
                                    <span>All activity in this business will be limited.You can reactivate staff later!</span>
                                    <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Button onClick={handleCloseDeactivate} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                                        <Button onClick={handleDeactivateStaff} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Deactivate </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    )
}