import React, { useEffect, useState } from "react";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";

const AddMerchantAccount = () => {
  const navigate = useNavigate();
  // query params
  const [nulldata, add, accountId, accountName, accountNumber] = decodeURIComponent(window.location.search).split("?");

  //   handle modal;
  const [opensucess, setOpenSuccess] = useState({ message: "", state: false });
  const [openerror, setOpenError] = useState({ message: "", state: false });
  const [editId, setEditId] = useState("");

  // get config data
  useEffect(() => {
    setEditId(accountId);
    setAccountConfig({ ...accountData, accountName: accountName ?? "", accountNumber: accountNumber ?? "" });
  }, [accountId]);

  const closeSuccess = () => {
    setOpenSuccess(false);
  };

  const closeError = () => {
    setOpenError(false);
  };

  const [bankName, setBankName] = useState("");
  const [accountData, setAccountConfig] = useState({ bankName: "", bankCode: "", accountName: "", accountNumber: "", consumerKey: "", consumerSecret: "", companyCode: "" });
  const [activateButton, setActivateButton] = useState(false);

  useEffect(() => {
    if (accountData.accountName && accountData.accountNumber) {
      setActivateButton(true);
    } else {
      setActivateButton(false);
    }
  }, [accountData.accountName, accountData.accountNumber, accountData.companyCode, accountData.bankCode, accountData.bankName, accountData.consumerKey, accountData.consumerSecret]);

  const getInputData = (e) => {
    const value = e?.target?.value;
    const name = e?.target?.name;
    setAccountConfig({ ...accountData, [name]: value });
  };

  const doAddConfig = () => {
    if (!accountData?.accountName) {
      setOpenError({ ...openerror, state: true, message: "Account Name is Required" });
      return;
    }
    if (!accountData?.accountNumber) {
      setOpenError({ ...openerror, state: true, message: "Account Number is Required" });
      return;
    }

    HttpComponent({
      method: `POST`,
      url: `/api/v1/merchantaccountsetup`,
      body: editId ? { ...accountData, type: "edit", accountId: editId } : { ...accountData, type: "create" },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setOpenSuccess({ ...opensucess, state: true, message: "Account Set up successfully!" });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      } else {
        setOpenError({ ...openerror, state: true, message: data?.response?.message });
      }
    });
  };

  const handleChange = (e) => {
    let value = e?.target?.value
    setAccountConfig({ ...accountData, bankCode: value?.bankCode, bankName: value?.bankName, accountName: value?.bankName });
  };

  // const handleMenuItem = (value) => {
  //   setBankName(value);
  // };
  const [listedBanks, setListedBanks] = useState([]);
  useEffect(() => {
    HttpComponent({
      method: "GET",
      url: "/get_transfer_banks",
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      let banks = data.response.data ?? [];
      setListedBanks(banks);
    });
  }, []);

  console.log("accountData --->", accountData);
  return (
    <div style={{ margin: "0px 0px 0px 20px" }}>
      {/* snackbars */}
      <ErrorAlert vertical={"top"} horizontal={"right"} onClose={closeError} open={openerror.state} message={openerror.message} />
      <SuccessAlert vertical={"top"} horizontal={"right"} onClose={closeSuccess} open={opensucess.state} message={opensucess.message} />
      <Grid container direction={"column"} width={"100%"}>
        <Grid item mb={6} mt={3}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}>
            <ArrowBackIos onClick={() => navigate(-1)} />
            <Typography variant="h5" sx={{ color: "#032541", fontWeight: "600" }}>
              {" "}
              Add Merchant Account Config
            </Typography>
          </Box>
        </Grid>

        <Grid item mb={2} width={"100%"}>
          <FormControl sx={{ width: "35%", margin: "0px 0px 35px 0px" }} variant="outlined">
            <InputLabel id="demo-customized-select-label">Bank Name</InputLabel>
            <Select labelId="demo-customized-select-label" id="demo-customized-select" onChange={(e) => handleChange(e)}>
              {listedBanks.map((bank, index) => {
                return (
                  <MenuItem key={index} value={bank}>
                    {" "}
                    {bank.bankName}{" "}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <Box sx={{ margin: "0px 0px 35px 0px", width: "100%" }}>
            <TextField value={accountData.accountName} disabled onChange={getInputData} sx={{ width: "35%" }} id="outlined-basic" name={"accountName"} label="Account Name" variant="outlined" />
          </Box> */}

          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <TextField value={accountData.accountNumber} onChange={getInputData} sx={{ width: "35%" }} id="outlined-basic" name={"accountNumber"} label="Account Number" variant="outlined" />
          </Box>

          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <TextField value={accountData.consumerKey} onChange={getInputData} name={"consumerKey"} sx={{ width: "35%" }} label={"Consumer Key"} />
          </Box>

          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <TextField value={accountData.consumerSecret} onChange={getInputData} name={"consumerSecret"} sx={{ width: "35%" }} label={"Consumer Secret"} />
          </Box>

          <Box sx={{ margin: "0px 0px 35px 0px" }}>
            <TextField value={accountData.companyCode} onChange={getInputData} name={"companyCode"} sx={{ width: "35%" }} label={"Company Code"} />
          </Box>

          <Box sx={{ width: "35%", display: "flex", gap: "30px", alignItems: "center", justifyContent: "flex-end" }}>
            <Button style={{ border: "1px solid #dc3545", color: "#dc3545", padding: "10px 50px 10px 50px", opacity: activateButton ? 1 : 0.3 }}>Cancel </Button>
            <Button onClick={() => doAddConfig()} style={{ backgroundColor: "#032541", color: "white", padding: "10px 50px 10px 50px", opacity: activateButton ? 1 : 0.3 }}>
              {editId ? "Edit" : "Add"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddMerchantAccount;
