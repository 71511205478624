import { Breadcrumbs, Grid, Typography, TextField, InputAdornment } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

let baseUrl = process.env.REACT_APP_BASE_URL;

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Units Assigned</Typography>,
]

export default function UnitsAssignedToTeachingStaff() {
    const navigate = useNavigate()
    const [courses, setCourses] = useState({})

    let customerId = localStorage.getItem("customerId")

    const [searchValue, setSearchValue] = useState('')
    const [opencourse, setOpenCourse] = useState()
    const [openCollapse, setOpenCollapse] = useState(false)

    const handleClick = (courseName) => {
        setOpenCourse(courseName)
        setOpenCollapse(!openCollapse)

    }

    // units assigned to staff

    const getCustomerDetails = async () => {
        try {
            const customerDetailsResponse = await fetch(
                baseUrl + `/api/get_customer_by_number?customerId=${customerId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );

            if (customerDetailsResponse.status === 200) {
                const customerDetailsData = await customerDetailsResponse.json();
                setCourses(customerDetailsData.data[0])
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getCustomerDetails();
    }, [])
    return (
        <Grid container direction={'column'}>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Units Assigned</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'} mt={1}>
                <Typography>Select Unit</Typography>
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <TextField name={'search'} value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={'search'}
                    variant="outlined"
                    margin="dense"
                    sx={{ width: '100%' }}
                    InputProps={{
                        sx: {
                            height: '3.438rem',
                            width: '100%',
                            fontSize: "0.875rem",
                            "& fieldset": {
                                borderColor: "#bec5d1"
                            }
                        },
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item display={'flex'} mt={1} width={'50%'} mb={1}>
                <Grid container width={'100%'}>
                    {courses?.courses?.map((course) => (
                        <Grid item width={'100%'} py={2} sx={{ borderBottom: '0.1px solid #707070' }}>
                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography style={{ color: '#00000', fontWeight: 'bold' }}>{course?.courseName}</Typography>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    {openCollapse ? <ExpandLess onClick={() => handleClick(course?.courseName, course?._id)} /> : <ExpandMore onClick={() => handleClick(course?.courseName, course?._id)} />}
                                </Grid>
                            </Grid>
                            {opencourse === course?.courseName ?
                                <Grid container width={'100%'}>
                                    {course?.units?.length > 0 && course?.units?.map((unit) => (
                                        <Grid item width={'100%'} my={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography>{unit?.unitName}</Typography>
                                        </Grid>
                                    ))}
                                </Grid> : null}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}