import React from 'react'
import Box from "@mui/material/Box";
import Success from "../../common/images/success.png";
import {Button} from "@mui/material";
import Card from "@mui/material/Card";
import LoginBackgroundImage from "../../images/loginBackground.png";
import Stack from "@mui/material/Stack";

const SuccessModalStyle = {width : "575px" , height : "561px" , margin : "0 0 0 30px" , borderRadius : "19px" , boxShadow : "0 3px 6px 0 rgba(0, 0, 0, 0.16)" , backgroundColor : "#fff"}

const SuccessReset = () => {
    // Success Modal Control

    const handleClick = () => {localStorage.clear(); window.location.href = '/'}

    return (
        <Stack className="container-fluid" width={'auto'} height={'100vh'} align={'center'}
                   sx={{backgroundColor : '#1A354E' , backgroundImage : `url(${LoginBackgroundImage})`}}>
            <Card sx={{margin : "9% auto 0 auto" , width : "575px" , height : "561px" , borderRadius : "19px" , boxShadow : "0 3px 6px 0 rgba(0, 0, 0, 0.16)"}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                  className="container-fluid">
                <Box sx={{marginTop : '35px'}}>
                    <img src={Success} alt="Success" style={{marginTop : "40px" , width : "135.2px" , height : "135.2px" , margin : "0 106.8px 30.4px 106px" , objectFit : "contain"}}/>
                    <label style={{height : "35px" , margin : "30.4px 126px 20px" , fontFamily : "Poppins" , fontSize : "25px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "1.92" , letterSpacing : "normal" , textAlign : "center" , color : "#032541"}}>
                        Successful
                    </label>
                </Box>
                <Box>
                    <label style={{width : "348px" , height : "51px" , margin : "20px 0 34px" , fontFamily : "Poppins" , fontSize : "17px" , fontWeight : "normal" , fontStretch : "normal" , fontStyle : "normal" , lineHeight : "1.59" , letterSpacing : "normal" , textAlign : "center" , color : "#032541"}}>
                        Your Password has been successfully set.
                        Proceed to Login.
                    </label>
                </Box>
                <Box>
                    <Button onClick={handleClick} sx={{color : "white" , fontSize : "18px" , fontWeight : "600" , width : "122px" , height : "42.6px" , padding : "9px 13.4px 8.6px 14px" , backgroundColor : "#032541"}}>
                        OK
                    </Button>
                </Box>
            </Card>
        </Stack>)
}

export default SuccessReset;