import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useDispatch, useSelector } from "react-redux";
// import { setDetails } from "../../../../features/businessToUpdateSlice";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { setOutlets } from "../../../../features/ExporterSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function AnnualMaintenanceTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

//   const dispatch = useDispatch();

//   //fetching the table data
//   const fetchOutlets = async () => {
//     const response = await axios.get(baseUrl + "/api/fetchOutlets",  {
//         headers: {
//           "X-Authorization": localStorage.getItem("X-Authorization"),
//         },
//       });
//     setData(response.data.data);
//   };

//   useEffect(() => {
//     fetchOutlets();
//   }, []);

//    let searchQuery = useSelector((state) => state.search.searchValue);

//   let filteredData;

//   useEffect(() => {
//     filteredData = data.filter((item) =>
//       item.institution.toLowerCase().includes(searchQuery.toLowerCase())
//     );
//   }, [searchQuery]);

//   //creating rows from the fetched data
//   const createRows = () => {
//     let arr = [];
//     if (searchQuery.length === 0) {
//       data?.map((obj) => {
//         let val = createData(
//           obj.storeName,
//           obj.description,
//           obj.institution,
//           obj.categories,
//           obj.terminals,
//           obj.terminalUsers,
//           obj.status
//         );
//         arr.push(val);
//       });
//     } else {
//       filteredData?.map((obj) => {
//         let val = createData(
//           obj.storeName,
//           obj.description,
//           obj.institution,
//           obj.categories,
//           obj.terminals,
//           obj.terminalUsers,
//           obj.status
//         );
//         arr.push(val);
//       });
//     }
//     arr.sort((a, b) => (a.institution < b.institution ? -1 : 1));
//     setRows(arr);
//   };

//   useEffect(() => {
//     createRows();
//   }, [data, searchQuery]);

//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (rows.length > 0) {
//       setLoading(false);
//       dispatch(setOutlets(rows))
//     }
//   }, [rows]);

  return (
    <TableContainer component={Paper}>
      {/* {loading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null} */}
      <Table
        sx={{ minWidth: 500, fontFamily: "Poppins" }}
        aria-label="custom pagination table"
        size={"small"}
      >
        <TableHead sx={{ bgcolor: "#f0f0f0" }}>
          <TableRow>
            <TableCell>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business Id
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business Type
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Phone No
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Amount
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Payments
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Actions
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* table body here */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={8}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              // showFirstButton={true}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, mr: 10 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(
  name,
  description,
  institution,
  categories,
  terminals,
  terminalUsers,
  status
) {
  return {
    name,
    description,
    institution,
    categories,
    terminals,
    terminalUsers,
    status
  };
}
