import { MdEdit } from "react-icons/md";
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Box, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {useState} from "react";

const SuppliersTable = ({ rows,  loading, error }) => {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const handleFilterChange = (newFilterModel) => {
        setFilterModel(newFilterModel);
    };


    const EditButton = ({ id }) => (
        <Button onClick={() => navigate(`/supplier/edit/${id}`)} style={{ border: 'none', backgroundColor:'transparent', cursor: 'pointer' }}>
            <MdEdit size={20} />
        </Button>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    Error: {error.message}
                </Typography>
            </Box>
        );
    }

    return(
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={
                    [
                        // { field: 'id', headerName: 'ID', width: 90 },
                        {
                            field: 'name',
                            headerName: 'Name',
                            width: 200,
                            editable: false,
                        },
                        {
                            field: 'phone',
                            headerName: 'Phone No',
                            width: 200,
                            editable: false,
                        },
                        {
                            field: 'email',
                            headerName: 'Email',
                            width: 250,
                            editable: false,
                        },
                        {
                            field: 'location',
                            headerName: 'Address',
                            editable: false,
                            width: 250,
                        },
                        {
                            field: "Actions",
                            headerName: "Actions",
                            renderHeader:()=>{return (<strong style={{fontWeight:500 , fontSize:"18px" , color:"#032541"}}>Actions</strong>)},
                            renderCell: (params) => <EditButton id={params.row._id} />
                        },
                    ]
                }
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5, 10, 20]}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                filterMode="server"
                onFilterModelChange={handleFilterChange}
                filterModel={filterModel}
                paginationMode="server"
                checkboxSelection
                disableRowSelectionOnClick
                getRowId={(row) => row._id}
            />
        </Box>
    )
}

export default SuppliersTable;