import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useDispatch, useSelector } from "react-redux";
// import { setDetails } from "../../../../features/businessToUpdateSlice";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { setTerminalUsers } from "../../../../features/ExporterSlice";
import TerminalUsersDropdown2 from "./TerminalUsersDropdown2";
import { setDetails } from "../../../../features/userToUpdate";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function TerminalUsersTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch();

  let Group = localStorage.getItem("group")
  let partnerName = localStorage.getItem("affiliatedPartner")
  const isAdmin = Group && Group === "Admin";
  const isPartner = Group && Group === "Partner";

  //fetching the table data
  const fetchTerminalUsers = async () => {
    // if(isAdmin) {
      const response = await axios.get(baseUrl + "/api/fetchTerminalUsers", {
        headers: {
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      setData(response.data.data);
    // } else if(isPartner) {
    //   const response = await axios.post(baseUrl + "/api/getPartnerUsers",
    //   {
    //     partnerName,
    //     typeFilter: 'payItUser'
    //   },
    //   {
    //     headers: {
    //       "X-Authorization": localStorage.getItem("X-Authorization"),
    //     },
    //   });
    //   let users = response.data.users

    //   setData(users);
    //   console.log('payItUsers', users)
    // }
    
  };

  useEffect(() => {
    fetchTerminalUsers();
  }, []);

  let searchQuery = useSelector((state) => state.search.searchValue);

  let filteredData;

  const reduxSearchBy = useSelector((state) => state.dropdownAction.searchBy2);

  useEffect(() => {
    if (reduxSearchBy === "username") {
      filteredData = data.filter((item) =>
        item?.userName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (reduxSearchBy === "email") {
      filteredData = data.filter((item) =>
        item?.userEmail?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (reduxSearchBy === "businessName") {
      filteredData = data.filter((item) =>
        item?.businessName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }else if (reduxSearchBy === "phoneNo") {
      filteredData = data.filter((item) =>
        item?.userPhone?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }else if (reduxSearchBy === "tellerId") {
      filteredData = data.filter((item) =>
        item?.tellerId?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }  
  }, [searchQuery]);


  //creating rows from the fetched data
  const createRows = () => {
    let arr = [];
    if (searchQuery.length === 0) {
      data?.map((obj) => {
        let val = createData(
          obj.userName,
          obj.userEmail,
          obj.businessName,
          obj.storeName,
          obj.userPhone,
          obj.tellerId,
          obj.userGroup,
          obj.userState,
          <TerminalUsersDropdown2 />,
          obj.userId ,
          obj.businessNumber,
        );
        arr.push(val);
      });
    } else {
      filteredData?.map((obj) => {
        let val = createData(
          obj.userName,
          obj.userEmail,
          obj.businessName,
          obj.storeName,
          obj.userPhone,
          obj.tellerId,
          obj.userGroup,
          obj.userState,
          <TerminalUsersDropdown2 />,
          obj.userId ,
          obj.businessNumber,
        
        );
        arr.push(val);
      });
    }

    arr.sort((a, b) => (a.transactionID < b.transactionID ? -1 : 1));
    setRows(arr);
  };

  useEffect(() => {
    createRows();
  }, [data, searchQuery]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (rows.length > 0) {
      setLoading(false);
      dispatch(setTerminalUsers(rows));
    }
  }, [rows]);

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Stack width="100%" color={"#032541"}>
          <LinearProgress color="inherit" />
        </Stack>
      ) : null}
      <Table
        sx={{ minWidth: 500, fontFamily: "Poppins" }}
        aria-label="custom pagination table"
        size={"small"}
      >
        <TableHead sx={{ bgcolor: "#f0f0f0" }}>
          <TableRow>
            <TableCell>
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                User Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Business Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Outlet
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Phone
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Teller Id
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                User Type
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                fontFamily="Poppins"
                fontSize={14}
                fontWeight="500"
                fontStyle="normal"
                textAlign="left"
                color="#032541"
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow
              key={row.transactionId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => {
                let details = {
                  userName: row.name,
                  email: row.email,
                  phoneNo: row.phone,
                  userGroup: row.userType,
                  businessName: row.businessName,
                  userId:row.userId ,
                  businessNumber:row.businessNumber,
                };
                dispatch(setDetails(details));
              }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.email}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.businessName ? row.businessName : 'N/A'}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.storeName ? row.storeName : 'N/A'}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.phone}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.tellerId}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.userType}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.status}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontFamily="Poppins"
                  fontSize={14}
                  fontWeight="normal"
                  fontStyle="normal"
                  textAlign="left"
                  color="#707070"
                >
                  {row.action}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              // showFirstButton={true}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, mr: 10 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, email, businessName, storeName, phone, tellerId, userType, status, action ,userId ,businessNumber) {
  return {
    name,
    email,
    businessName,
    storeName,
    phone,
    tellerId,
    userType,
    status,
    action,
    userId,
    businessNumber,
  };
}
