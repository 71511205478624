import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Select, Button, TextField, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import icon from "../Images/uploadFileIcon.svg"
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SendIcon from "@mui/icons-material/Send";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import csvTemplate from "./Bank-Statement-Template.csv"
import HttpComponent from "../MakeRequest";
import AddBankDetails from "./AddBankDetails";
import DateFormatter from "../../../utils/dateFormatter";
import { CSVLink } from "react-csv";

const baseUrl = process.env.REACT_APP_BASE_URL;

const columnCentered = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
}

const areaStyles = {
    borderRadius: "6px",
    border: "solid 1px #e1e1e1",
    backgroundColor: "rgba(3, 37, 65, 0.02)",
    minHeight: "20rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
}

const buttonStyling = {
    "width": "10.625rem",
    "height": "2.813rem",
    "borderRadius": "5px",
    "backgroundColor": "#032541",
    color: "white",
    '&:hover': {
        backgroundColor: '#032541',
        color: 'white'
    }
}

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Bank Statements</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Upload File</Typography>
]

export default function UploadBankStatement() {

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [bankAccounts, setBankAccounts] = useState([])
    const [banks, setBanks] = useState([])
   // const [addBank, setAddbank] = useState(false)
    const [selectedBankName, setSelectedBankName] = useState('')
    const [selectedBankAccount, setSelectedBankAccount] = useState('')
    // const [dateFrom, setDateFrom] = useState("");
    //const [dateTo, setDateTo] = useState("");
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const navigate = useNavigate()
    //console.log(startDate , ">>>>>>>>>>>>>>>>>>>>> startdate")

    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[1]
   // console.log(selectedBankAccount, 'hello world')

    //fetch banks

    const fetchAllBanks = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_banks_list`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setBanks(data?.response?.data)
                }
            })

        } catch (error) {
            console.log(error)

        }
    }

    useEffect(() => {
        fetchAllBanks()
    }, [])

    // fetch accounts

    const fetchAllBankAccounts = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getBusinessBankStatementAcounts?bankName=${selectedBankName}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data , 'dataaaaaaaaaa')
                if (data.status === 200) {
                    setBankAccounts(data?.response?.data)
                }
            })

        } catch (error) {
            console.log(error)

        }
    }

    useEffect(() => {
        fetchAllBankAccounts()
    }, [selectedBankName , state])

    const gridFormatDate = (inputDate) => {
        // const today = new Date();
        const yyyy = new Date(inputDate)?.getFullYear();
        let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
        let dd = new Date(inputDate)?.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;

        return formattedToday;
    };

    useEffect(() => {
        const newStartDate = gridFormatDate(startDate);
        setStartDate(newStartDate)
        const newEndDate = gridFormatDate(endDate);
        setEndDate(newEndDate)
    }, [startDate, endDate]);

    // show the input to add bank account and name if addbank is triggered
    const showAddbank = () => navigate('/school/bankstatements?uploadstatement?addbank')



    //dropzone upload csv files

    const [Loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileUpload = (e) => {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        const selectedFile = e.dataTransfer ? e.dataTransfer.files[0].name : e.target.files[0].name;
        setFile(file);
        setSelectedFile(selectedFile);
    };

    const handleClearFile = () => {
        setFile(null);
        setSelectedFile(null);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append("bankstatement", file);
        formData.append('bankName', selectedBankName);
        formData.append('bankAccount', selectedBankAccount);
        formData.append('startDateRange', startDate);
        formData.append('endDateRange', endDate)



        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${baseUrl}/api/v1/uploadBankStatements`);
        xhr.setRequestHeader("X-Authorization", localStorage.getItem('X-Authorization'));

        // track upload progress
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = event.loaded / event.total * 100;
                setUploadProgress(percentComplete);
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              const responseData =   JSON.parse(xhr.response);
              if(responseData?.data?.length > 0){
                downloadDuplicateCsv(responseData?.data)
              }      
                if (xhr.status === 200) {
                    setSuccessShow({ state: true, message: 'File Uploaded successfully' })
                    setLoading(false)
                    setTimeout(() => {
                        navigate(-1)
                    }, 3000)
                    // const response = JSON.parse(xhr.response);
                } else if (xhr.status >= 400) {
                    console.error("Request failed with status: " + xhr.status);
                    const response = JSON.parse(xhr.response);
                    setErrorShow({ state: true, message: response.message })
                    setLoading(false)
                }
            }
        };
        xhr.send(formData);

    }

    // download duplicate csvs;
    const downloadDuplicateCsv = (data = []) => {

        const csvHeaders = [
            {label: "Narration", key: "Narration" },
            {label: "TransactionRef", key: "TransactionRef" },
            {label: "TransactionDate", key: "TransactionDate" },
            {label: "Amount", key: "Amount" },
        ]

        const csvData = data?.map((trans) => {
            return {
                "Narration":trans?.narrative,
                "TransactionRef":trans?.transactionRef,
                "TransactionDate": DateFormatter(trans?.transactionDate) ,
                "Amount":trans?.creditAmount,
            }
        })

        const csvExports = {
            data: csvData,
            headers: csvHeaders,
            filename: `Duplicate bankstatement transactions - ${new Date().toDateString()}.csv`,
          };

              // Create a Blob object to represent the CSV data
    const blob = new Blob([CSVData(csvExports)], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the URL of the Blob
    link.href = window.URL.createObjectURL(blob);

    // Set the download attribute to suggest a filename for the downloaded file
    link.download = csvExports.filename;

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically trigger a click event on the link
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    }

const CSVData = (exports) => {
    const rows = exports.data.map((row) => exports.headers.map((header) => row[header.key]));
    const header = exports.headers.map((header) => header.label);
    const csv = [header, ...rows].join("\r\n");
    console.log('csv', csv);
    return csv;
};

    return (
        <Grid container direction={'column'}>
            {state === 'addbank' ? <AddBankDetails /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                    <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}> Bank Statements</Typography>
                </Grid>
                <Grid item mt={2}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item mt={2}>
                    <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Upload Bank Statement</Typography>
                </Grid>
                <Grid item mt={2} width={'51%'}>
                    <Grid container spacing={2} style={{ width: "100%" }} display={'flex'} justifyContent={'space-between'}>
                        <Grid item style={{ width: "50%" }}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedBankName}
                                    label="Bank"
                                    onChange={(e) => setSelectedBankName(e.target.value)}
                                    name="bankName"
                                >
                                    {banks?.map((item) => (
                                        <MenuItem name="outlet" key={item?._id} value={item?.name}>{item?.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item style={{ width: "50%" }}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel id="demo-simple-select-label">Account Number</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedBankAccount}
                                    label="Account Number"
                                    onChange={(e) => setSelectedBankAccount(e.target.value)}
                                    name="Account Number"
                                >
                                    <MenuItem onClick={showAddbank} sx={{ display: 'flex', alignItems: 'center' }}><AddIcon /> Add account number</MenuItem>
                                    {bankAccounts?.map((item) => (
                                        <MenuItem name="outlet" key={item?._id} value={item?.bankAccount}>{item?.bankAccount}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {addBank ?
                <Grid item width={'50%'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                        <Grid item width={'50%'}>
                            <TextField id="outlined-basic" style={{ width: "100%" }} onChange={(e) => setSelectedBankName(e.target.value)} value={selectedBankName} label="Bank Name" variant="outlined" />
                        </Grid>
                        <Grid item width={'50%'}>
                            <TextField id="outlined-basic" style={{ width: "100%" }} onChange={(e) => setSelectedBankAccount(e.target.value)} value={selectedBankAccount} label="Account Number" variant="outlined" />
                        </Grid>
                    </Grid>
                </Grid> : null} */}
                {/* <Grid item width={'50%'}>
                    
                    <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{margin:'none'}} />
                </Grid> */}

                <Grid item width={'50%'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                        <Grid item width={'50%'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item width={'50%'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="End Date" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />} />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid mt={2} item width={'50%'} direction={'column'} display={'flex'}>
                    {Loading ?
                        <><LoadingButton
                            sx={{ width: "407px", backgroundColor: "#34A353", color: "white", "&:hover": { backgroundColor: "#34A353", color: "white", }, }}
                            endIcon={<SendIcon />}
                            loading={true}
                            loadingPosition="end"
                            variant="contained"
                        >
                            <span>Awaiting </span>
                        </LoadingButton>
                        </> :
                        <>
                            <Box sx={areaStyles}>
                                <Box sx={{
                                    display: "flex",
                                    alignSelf: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "42.375rem",
                                    height: "18.269rem",
                                    padding: "20px 170.5px 46.3px 159.5px",
                                    border: "dashed 2px #c2cdda"
                                }}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <img src={icon} alt="Upload" />
                                    </Box>
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                                            Drag and drop your CSV file here
                                        </Typography>
                                    </Box>
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <Typography sx={{ fontSize: "0.875rem", color: "#666666" }}>
                                            or
                                        </Typography>
                                    </Box>
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={handleFileUpload}
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                    />
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <Button sx={buttonStyling} onClick={() => fileInputRef.current.click()}>
                                            Browse Files
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{display:'flex' , my:2, justifyContent:'center'}}>
                                <a href={csvTemplate} download>
                                    <Button sx={{ color: "#032541", fontSize: "16px" }} startIcon={<FileDownloadOutlinedIcon />}>
                                        Download CSV Template
                                    </Button>
                                </a>
                            </Box>
                            {
                                selectedFile ?
                                    <Box component="div" id="file-identifier"
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            border: "solid 1px #e1e1e1",
                                            borderRadius: "0.25rem",
                                            marginTop: "10px"
                                        }}>
                                        <Box component="div" sx={{ display: "flex", marginX: 1, padding: 1, }}>
                                            <Box component="div" sx={{ ...columnCentered }}>
                                                <CloseOutlinedIcon onClick={handleClearFile} sx={{ color: "#dc3545", cursor: "pointer" }} />
                                            </Box>
                                            <Box component="div" sx={{ ...columnCentered }}>
                                                <InsertDriveFileOutlinedIcon sx={{ color: "#032541" }} />
                                            </Box>
                                            <Box component="div" sx={{ ...columnCentered, marginLeft: 1 }}>
                                                <Typography sx={{ color: "#666666", fontSize: "0.875rem" }}>
                                                    {selectedFile}
                                                </Typography>
                                            </Box>
                                            <Box component="div" sx={{ marginLeft: 3.5, ...columnCentered }}>
                                                <progress value={uploadProgress} max="100" />
                                            </Box>
                                            <Box component="div" sx={{ ...columnCentered, marginLeft: 'auto', }}>
                                                <Button disabled={!selectedBankName || !selectedBankAccount} onClick={handleSubmit} style={!selectedBankAccount || !selectedBankName ? { color: "#fff", width: '124px', backgroundColor: '#032541', opacity: 0.1, fontSize: "0.875rem", fontWeight: 700 } : { color: "#fff", width: '124px', backgroundColor: '#032541', opacity: 1, fontSize: "0.875rem", fontWeight: 700 }}>
                                                    Upload
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    : null
                            }
                        </>
                    }
                </Grid>
            </>}
        </Grid>
    )
}