import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    staffDetails: {},
    staffCourseUnits: [],
    teachingStaffCourses: [],
    sessionId:''
}

const SatffCourseUnitsSlice = createSlice({
    name: 'teachingStaff',
    initialState,
    reducers: {
        handleSaveStaffDetails: (state, action) => {
            state.staffDetails = action.payload
        },
        handleSessionId:(state ,action) =>{
            state.sessionId = action.payload
        },
        handleGetTeachingStaffCourses: (state, action) => {
            state.teachingStaffCourses = action.payload
            // const { payload } = action;
            // const coursesToAdd = payload.filter((course) => {
            //   const courseId = course._id;
            //   return  !state.teachingStaffCourses.includes(courseId);
            // });
          
            // if (coursesToAdd.length > 0) {
             
            //   const newCourses = coursesToAdd.map((course) => ({
            //     _id: course._id,
            //     courseName: course.courseName,
            //     units: course.units,
            //   }));
          
            //   return {
            //     ...state,
            //     teachingStaffCourses: [
            //       ...state.teachingStaffCourses,
            //       ...newCourses,
            //     ],
            //   };
            // }
          
            // return state;
        },
        handleSaveStaffCoursesUnits: (state, action) => {
            return {
                ...state,
                staffCourseUnits: [...state.staffCourseUnits, ...action.payload],
            };
        },
        handleDeleteGetTeachingStaffCourses: (state, action) => {
            const { _id: courseId, unitIds } = action.payload;
            const courseIndex = state.teachingStaffCourses?.findIndex(course => course?._id === courseId);
        
            if (courseIndex !== -1) {
                const updatedCourse = {
                    ...state.teachingStaffCourses[courseIndex],
                    units: state.teachingStaffCourses[courseIndex]?.units?.filter(unit => !unitIds?.includes(unit?._id)),
                };
                const updatedStaffCourseUnits = [...state.teachingStaffCourses];
                updatedStaffCourseUnits[courseIndex] = updatedCourse;
        
                console.log(updatedStaffCourseUnits, 'updatedStaffCourseUnits');
        
                return {
                    ...state,
                    teachingStaffCourses: updatedStaffCourseUnits,
                };
            }

            return state
 
        },
   
        handleDeleteUnit: (state, action) => {
            const { courseId, unitIds } = action.payload;
            const courseIndex = state.staffCourseUnits?.findIndex(course => course?.courseId === courseId);
            console.log(courseIndex, 'courseIndex')
            if (courseIndex !== -1) {
                const updatedCourse = {
                    ...state.staffCourseUnits[courseIndex],
                    units: state.staffCourseUnits[courseIndex]?.units?.filter(unit => !unitIds?.includes(unit?.unitId)),
                };
                const updatedStaffCourseUnits = [...state.staffCourseUnits];
                updatedStaffCourseUnits[courseIndex] = updatedCourse;
                return {
                    ...state,
                    staffCourseUnits: updatedStaffCourseUnits,
                };
            }
        },
        handleResetStaff: (state) => {
            state.staffCourseUnits = []
            state.staffDetails = {} 
            state.teachingStaffCourses = []
        }
    }
})
export const { handleSaveStaffDetails, handleSaveStaffCoursesUnits, handleSessionId , handleDeleteGetTeachingStaffCourses , handleGetTeachingStaffCourses, handleResetStaff, handleDeleteUnit } = SatffCourseUnitsSlice.actions
export default SatffCourseUnitsSlice.reducer