import { Box, Button, Grid, IconButton, Modal, TextField, List, ListItem } from "@mui/material";
import { ArrowBackIos, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";4
import SearchIcon from "@mui/icons-material/Search";
import HttpComponent from "../School/MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

const QuickBooks = () => {
    const [quickBookId, setQuickBookId] = useState('');
    const [quickBookSecret, setQuickBookSecret] = useState('');
    const [quickBookHook, setQuickBookHook] = useState('');
    const [showQuickBookId, setShowQuickBookId] = useState(false);
    const [showQuickBookSecret, setShowQuickBookSecret] = useState(false);
    const [showQuickBookWebHook, setShowQuickBookWebHook] = useState(false);
    const [originalQuickBookSecret, setOriginalQuickBookSecret] = useState('');
    const [originalQuickBookId, setOriginalQuickBookId] = useState('');
    const [originalQuickBookWebHook, setOriginalQuickBookWebHook] = useState('');
    const [message, setMessage] = useState('');
    const [errorNotificationOpen, setErrorNotificationOpen] = useState(false);
    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const [openQuickBooks, setOpenQuickBooks] = useState(false);
    const [quickBooksStatus, setQuickBooksStatus] = useState(false)
    const [showClientId, setShowClientId] = useState(false);
    const [showClientSecret, setShowClientSecret] = useState(false);
    const [showWebHook, setShowWebHook] = useState(false);

    const toggleQuickBookIdVisibility = () => {
        setShowQuickBookId(!showQuickBookId);
        if (!showQuickBookId) {
            setQuickBookId(originalQuickBookId);
        } else {
            setQuickBookId(maskQuickBookId({ target: { value: originalQuickBookId } }));
        }
    };
    
    const toggleQuickBookClientSecretVisibility = () => {
        setShowQuickBookSecret(!showQuickBookSecret);
        if (!showQuickBookSecret) {
            setQuickBookSecret(originalQuickBookSecret);
        } else {
            setQuickBookSecret(maskQuickBookSecret({ target: { value: originalQuickBookSecret } }));
        }
    };
    
    const toggleQuickBookWebhookVisibility = () => {
        setShowQuickBookWebHook(!showQuickBookWebHook);
        if (!showQuickBookWebHook) {
            setQuickBookHook(originalQuickBookWebHook);
        } else {
            setQuickBookHook(maskQuickBookWebhook({ target: { value: originalQuickBookWebHook } }));
        }
    };

    const handleCancelQuickBooksClick = () => {
        setOpenQuickBooks(false);
    };

    const maskQuickBookId = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
    
        setQuickBookId(maskedValue);
        setOriginalQuickBookId(value);  
        return maskedValue;
    }; 

    const maskQuickBookSecret = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
    
        setQuickBookSecret(maskedValue);
        setOriginalQuickBookSecret(value);  
        return maskedValue;
    }; 

    const maskQuickBookWebhook = (e) => {
        const value = e.target.value;
        let maskedValue;
    
        if (value && value.length > 4) {
            maskedValue = value.substring(0, 2) + "*".repeat(value.length - 4) + value.substring(value.length - 2);
        } else {
            maskedValue = value;
        }
    
        setQuickBookHook(maskedValue);
        setOriginalQuickBookWebHook(value);  
        return maskedValue;
    };

    // const maskQuickBookId = (e) => {
    //     const value = e.target.value;
    //     const maskedValue = value?.substring(0, 2) + "*".repeat(value?.length - 4) + value?.substring(value?.length - 2);
    //     setQuickBookId(maskedValue);
    //     setOriginalQuickBookId(value); 
    //     return maskedValue;
    // };

    // const maskQuickBookSecret = (e) => {
    //     const value = e.target.value;
    //     const maskedValue = value?.substring(0, 2) + "*".repeat(value?.length - 4) + value?.substring(value?.length - 2);
    //     setQuickBookSecret(maskedValue);
    //     setOriginalQuickBookSecret(value); 
    //     return maskedValue;
    // };

    // const maskQuickBookWebhook = (e) => {
    //     const value = e.target.value;
    //     const maskedValue = value?.substring(0, 2) + "*".repeat(value?.length - 4) + value?.substring(value?.length - 2);
    //     setQuickBookHook(maskedValue);
    //     setOriginalQuickBookWebHook(value); 
    //     return maskedValue;
    // };

    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    // setAccountStatus(data?.response?.data?.accountingEnabled)
                    // setIpsasStatus(data?.response?.data?.ipsasEnabled)
                    // setXeroStatus(data?.response?.data?.xeroAccountingEnabled)
                    // setWebHookStatus(data?.response?.data?.xeroWebHookIntentStatus)
                    setQuickBooksStatus(data?.response?.data?.quickbooksEnabled)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAccountingStatus()
    }, [])

    const handleQuickBooksSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await HttpComponent({
                method: 'post',
                url: '/api/v1/quickbooks/get_quikbooks_token',
                body: {
                    clientId: originalQuickBookId,
                    clientSecret: originalQuickBookSecret,
                    webHookKey: originalQuickBookWebHook,
                },
                token: localStorage.getItem('X-Authorization'),
            }).then(async (data) => {
                // console.log(data);
                if (data.response.Status === "SUCCESS") {
                    setMessage('QuickBooks Enabled Successfully!');
                    handleSuccessNotificationClick();
                    window.location.assign(data.response.redirect_url);
                } else {
                    console.error('Error enabling QuickBooks Account', error);
                    setMessage('Failed to Enable QuickBooks Accounting');
                    handleErrorNotificationClick();
                }
            });
        } catch (error) {
            setMessage('Failed to Enable QuickBooks Accounting');
            handleErrorNotificationClick();
        }
    };

    useEffect(() => {
        const storedClientQuickBookId = localStorage.getItem('clientIdQuick');
        const storedClientSecretQuickBook = localStorage.getItem('clientSecretQuick');
        const storedWebHookQuickBook = localStorage.getItem('webHookKeyQuick');

        const maskedClientIdQuickBook = storedClientQuickBookId === 'undefined' || storedClientQuickBookId === '' || storedClientQuickBookId === undefined ? '': maskQuickBookId({ target: { value: storedClientQuickBookId } });
        const maskedClientSecretQuickBook = storedClientSecretQuickBook === 'undefined' || storedClientSecretQuickBook === '' || storedClientSecretQuickBook === undefined ? '': maskQuickBookSecret({ target: { value: storedClientSecretQuickBook } });
        const maskedWebhookQuickBook = storedWebHookQuickBook === 'undefined' || storedWebHookQuickBook === '' || storedWebHookQuickBook === undefined ? '': maskQuickBookWebhook({ target: { value: storedWebHookQuickBook || '' } });

        setQuickBookId(maskedClientIdQuickBook);
        setOriginalQuickBookId(storedClientQuickBookId || '');
        setQuickBookSecret(maskedClientSecretQuickBook);
        setOriginalQuickBookSecret(storedClientSecretQuickBook || '');
        setQuickBookHook(maskedWebhookQuickBook);
        setOriginalQuickBookWebHook(storedWebHookQuickBook || '');
    }, []);

    const handleErrorNotificationClick = () => {
        setErrorNotificationOpen(true);
    };

    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    return (
        <div>
            {openQuickBooks ? '' :
                <Grid container item className={"container-fluid"}>
                    {/*Page Title Container*/}
                    <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                        <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                    </Grid>

                    {/*Page Sub title and Search */}
                    <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                        <Grid item>
                            <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                                <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                                <span style={{ color: "#032541" }}>QuickBooks Config</span>
                            </div>
                        </Grid>

                        {/*Search input*/}
                        <Grid item>
                            <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
                            <TextField
                                size="small"
                                placeholder={"search"}
                                style={{ height: "1px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ width: "15px", height: "15px" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </Grid>
                    </Grid>

                    {/*Headers*/}
                    <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Configuration
                        </Grid>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Action
                        </Grid>
                    </Grid>
                    <Grid container style={{ width: "86%", marginTop: "30px" }}>
                        <Grid item style={{ width: "100%" }}>
                            <List>
                                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable QuickBooks</Box>
                                        <Box>
                                        <Switch checked={quickBooksStatus} onClick= {() => setOpenQuickBooks(true)} />
                                        </Box>
                                    </Box>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
             }
             {openQuickBooks &&
                <>
                    <IconButton style={{ top: '40px', right: '10px' }} onClick={handleCancelQuickBooksClick}>
                        <ArrowBackIos />
                    </IconButton>
                    <Box style={{ display: "flex", justifyContent: "" }}>
                        <Box style={{ marginLeft: "35px" }}>
                            <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>QuickBooks</Box>
                            <Box>
                                <p style={{ fontSize: '14px', color: "#707070", width: '662px', height: '41px', margin: '23px 599px 20px 3px' }}>
                                    Enabling QuickBooks It allows you to run and view reports easily like the number of outstanding invoices, total amount due from clients and details of each invoice (paid or due).
                                </p>
                            </Box>
                            <ul style={{ listStyleType: "none", padding: 0, marginLeft: 0 }}>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Keep track of financial functions like income and expenses.</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Bills and Expenses Tracking.</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", width: '7px', fontSize: '24px', display: "inline-block", width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Integrates well with other systems and flexible with 3rd party applications.</p>
                                </li>
                                <li style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <span style={{ marginLeft: "10px", color: "#032541", display: "inline-block", fontSize: '24px', width: "10px" }}>•</span>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile Accounts Payable and receivables</p>
                                </li>
                            </ul>
                            <form onSubmit={handleQuickBooksSubmit}>
                                <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                    <label htmlFor="clientId" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client ID:<span style={{ color: 'red' }}>*</span></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" id="quickBookId" name="clientId" placeholder="Client ID" value={quickBookId} onChange={maskQuickBookId} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                        <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleQuickBookIdVisibility}>
                                            {showClientId ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                </Box>

                                <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                    <label htmlFor="clientSecret" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Client Secret Key<span style={{ color: 'red' }}>*</span></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" id="quickBookSecret" name="clientSecret" placeholder="Client Secret" value={quickBookSecret} onChange={maskQuickBookSecret} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px", paddingRight: "40px" }} />
                                        <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleQuickBookClientSecretVisibility}>
                                            {showClientSecret ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                </Box>

                                <Box style={{ marginBottom: "20px", width: '407px', height: '54px', position: 'relative' }}>
                                    <label htmlFor="webhook" style={{ fontSize: "14px", color: "#032541", marginBottom: "5px" }}>Webhook Key<span style={{ color: 'red' }}>*</span></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" id="quickBookHook" name="webhook" placeholder="WebHook Key" value={quickBookHook} onChange={maskQuickBookWebhook} style={{ width: "100%", height: '40px', borderRadius: "4px", border: "1px solid #ccc", paddingLeft: "10px" }} />
                                        <IconButton style={{ position: 'absolute', right: 0, top: '50%', }} onClick={toggleQuickBookWebhookVisibility}>
                                            {showWebHook ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </div>
                                </Box>

                                <Box style={{ display: "flex", marginTop: "15px" }}>
                                    <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={handleCancelQuickBooksClick}>Cancel</Button>
                                    <Box marginLeft="20px">
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff" }} type="submit">Enable</Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </>
            }
            <SuccessAlert message={message} open={successNotificationOpen} onClose={() => setSuccessNotificationOpen(false)} horizontal={'right'} vertical={'top'} />
            <ErrorAlert message={message} open={errorNotificationOpen} onClose={() => setErrorNotificationOpen(false)} horizontal={'right'} vertical={'top'} />
        </div>
    );
};

export { QuickBooks };
