import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Typography, Stack, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import TuneIcon from '@mui/icons-material/Tune';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import DangerousIcon from "@mui/icons-material/Dangerous";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { handleItemClick } from "../../../../features/actionSlice";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import BlockIcon from '@mui/icons-material/Block';

export default function ReasonCodesDropdown() {
  const dispatch = useDispatch();

  const handleClick = (clicked) => {
    dispatch(handleItemClick(clicked)); //setting the clicked item in redux
  };

//   const currentTab = useSelector((state) => state.currentTab.tab);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* <ThemeProvider theme={theme}> */}
          <Button variant="text" {...bindTrigger(popupState)}>
            <MoreVertIcon sx={{color:"#032541"}} />
          </Button>
          {/* </ThemeProvider> */}
          <Menu {...bindMenu(popupState)}>
            {/* {currentTab == "Active" && ( */}
              {/* <> */}
                <MenuItem
                  onClick={() => {
                    handleClick("Edit");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <EditIcon color={"primary"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Edit Reason</Typography>
                  </Stack>
                </MenuItem>
                <Divider variant={"middle"} />
                <MenuItem
                  onClick={() => {
                    handleClick("Delete");
                    popupState.close();
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <DeleteIcon color={"error"} />
                    <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#707070",
                    }}
                    >Delete Reason</Typography>
                  </Stack>
                </MenuItem>
              {/* </> */}
            {/* )} */}
          
            
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
