import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Grid, Input, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import backIcon from "./Images/back-icn.svg"
import HttpComponent from "./MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { ArrowBack, CheckBox } from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { deleteBankPaybill, handleIsBankPaybillSetup, setBankPaybillData, setBankPaybillStatus } from '../../features/paymentOptionsSlice';
import EditBankPaybill from './EditBankPaybill';


const baseUrl = process.env.REACT_APP_BASE_URL;


const BankPayBill = ({showForm, pageStatus, bankbills, setPageStatus, setShowBankPaybillSetup, errorShow, setErrorShow, setSuccessShow}) => {
    const [bankName, setBankName] = useState('')
    const [paybill, setPayBill] = useState('')
    const [accountNo, setAcountNo] = useState('')
    const [verifyAcc, setVerifyAcc] = useState('')
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const {bankPaybillData, bankPaybillStatus} = useSelector((store) => store.payment);
    const [showEdit, setShowEdit] = useState(false);


    const [formState, setFormState] = useState(false);

    const dispatch = useDispatch();

    const changePayBills = (e) => {
        const bankDetails = e.target.value
        setBankName(bankDetails.bankName)
        setPayBill(bankDetails.paybill)
        console.log('bankDetails.bankName', bankName)
    }

    console.log('paybillSetup data', bankPaybillData);

    // save bank paybill data to DB
    const savePayBillDetails = async (e) => {
        // prevent default form behavior
        e.preventDefault();
        const businessName = localStorage.getItem('businessName')
        console.log('businessName', businessName, accountNo)
        if (bankName === ' ') {
            alert('Kindly select the bankname')
            setOpenSnack(true)
            setError(true)
            setmessage('Kindly select the bankname')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (accountNo === '' || verifyAcc === '') {
            // alert('Account number is required')
            setOpenSnack(true)
            setError(true)
            setmessage('Account number is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (accountNo !== verifyAcc) {
            // alert('Account mismatch is required')
            setOpenSnack(true)
            setError(true)
            setmessage('Account mismatch is detected')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else {
            try {
                const response = await fetch(`${baseUrl}/api/v1/payments/activate_kcb_mpesa`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        businessName: businessName,
                        accountNumber: accountNo,
                    }),
                })
                await response.json().then((data) => {
                    console.log('response', data)
                    setOpenSnack(true)
                    setError(false)
                    setmessage('Successfully configured paybill details')
                    dispatch(setBankPaybillStatus(true));
                    dispatch(handleIsBankPaybillSetup(true));
                    // setTimeout(() => {
                    //     setOpenSnack(false);
                    //     window.location.reload();
                    //     // setSuccessfullyPaid(true);
                    // }, 2000);
                })
            } catch (error) {

            }
        }
    }

    console.log(bankPaybillData);

    const buttonStyles =
        {"width":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const changeFormState = () => {
        if (formState) {
            setFormState(false)

        } if (!formState) {
            setFormState(true)
        }
    }

    // const deleteBankPaybill = (delIndex) =>{
    //     const remainingBankData = bankPaybillData.filter((item, index) => index !== delIndex);
    //     dispatch(deleteBankPaybill(remainingBankData));
    //     // handleWarningClose()
    //     setSuccessToast({state: true, message:"Category removed successfully."})
    // }



    return (
        <Box component="div" sx={{ fontFamily: 'Poppins', width: "95%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
            <SuccessAlert  vertical="top"
                               horizontal="right"
                               onClose={()=>setSuccessToast({...successToast, state:false})}
                               open={successToast.state}
                               message={successToast.message} />
                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={()=>setErrorShow({...errorShow, state:false})}
                    open={errorShow?.state}
                    message={errorShow?.message}/>
            <div className="topHeader" style={{ display: "flex", marginTop: "15px", marginLeft: "16px" }}>
                <ArrowBack onClick={() => setPageStatus('initial')} style={{ fontSize: "30px", cursor: "pointer" }} />
                {bankPaybillStatus === false ? <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Bank Paybill Setup</h6> :
                <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Edit Bank Paybill Details</h6>}
            </div>
                <Box component="form" sx={{ '& .MuiTextField-root': { margin: "20px", paddingTop: "10px" }, }} noValidate autoComplete='off' >
                    {bankPaybillStatus  === false && showEdit === false ? <form>
                       <FormControl style={{ width:'100%' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Bank</InputLabel>
                        <TextField select style={{ height: "50px", width:'95%', marginTop:'30px', borderRadius: "1px", fontFamily: "Poppins", }}
                            defaultValue={bankName}
                            onChange={changePayBills}
                            placeholder='Select Bank'
                            required>

                            {bankbills?.map((item, index) => (
                                <MenuItem key={index} value={item}>{item.bankName}</MenuItem>
                            ))}
                        </TextField>
                       </FormControl>
                        <FormControl style={{ width:'100%' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Paybill Number</InputLabel>
                        <TextField style={{ width: "95%", marginTop: "30px", }}
                            id="outlined-multiline-flexible"
                            multiline
                            required
                            placeholder="522533"
                            onChange={(e) => setPayBill(e.target.value)}
                            value={paybill}
                        />
                        </FormControl>
                        <FormControl style={{ width:'100%', marginTop:'-15px' }}>
                        <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Account Number</InputLabel>
                        <TextField style={{ width: "95%", marginTop: "30px" }}
                            id="outlined-multiline-flexible"
                            value={accountNo}
                            onChange={(e) => setAcountNo(e.target.value)}
                            multiline
                            placeholder="e.g 1234567890"
                            required
                        />
                        </FormControl>


                        {accountNo !== verifyAcc ? <>
                            <FormControl style={{ width:'100%', marginTop:'-15px' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Verify Account Number</InputLabel>
                            <TextField style={{ width: "95%", marginTop: "30px" }}
                                error
                                id="outlined-                               "
                                placeholder='Verify e.g 1234567890'
                                value={verifyAcc}
                                onChange={(e) => setVerifyAcc(e.target.value)}
                                multiline
                                required
                            />
                            </FormControl>
                        </> : <>

                           <FormControl style={{ width:'100%', marginTop:'-15px' }}>
                           <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Verify Account Number</InputLabel>
                           <TextField style={{ width: "95%", marginTop: "30px" }}
                                id="outlined-multiline-flexible"
                                placeholder='Verify Account Number'
                                value={verifyAcc}
                                onChange={(e) => setVerifyAcc(e.target.value)}
                                multiline
                                required
                            />
                           </FormControl>
                        </>}
                        
                        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '20px', paddingBottom: '20px'}}>
                            <Box component="div" sx={{width:"95%", display:"flex", justifyContent:"end", marginTop: '30px'}}>
                                <Box>
                                <Button sx={{
                                ...buttonStyles,
                                border:" solid 1px #002543",
                                color:"#002543",
                                '&:hover': {
                                    backgroundColor:"transparent",
                                    color: '#002543'
                                }
                                }}
                                onClick={() => setShowBankPaybillSetup(false)}
                                >
                                Cancel
                                </Button>

                                <Button sx={{
                               ...buttonStyles,
                                marginLeft:1,
                                backgroundColor:" #032541",
                                color:"white",
                                '&:hover': {
                                    backgroundColor: '#032541',
                                    color: 'white'
                                }
                                }}
                                onClick={savePayBillDetails}
                                >
                                Save
                                </Button>
                                </Box>
                            </Box>
                        </div>
                        
                    </form>
                    : showEdit === false ?
                    <Box component="div" sx={{margin:2, display:"flex", flexDirection:"column", width:'100'}}>
                    <Box component="div" style={{ width: '100%', display:'flex', flexDirection:'column' }}>
                        {
                            bankPaybillData?.map((bank, index) =>{
                                return(
                                    <Box key={index} component="div" style={{ display: 'flex', width: '100%', border: 'solid 1px #bec5d1',  borderRadius: '4px', marginTop: '15px', justifyContent: 'space-between', alignItems:'center', padding:'15px'}}>
                                        <Box component="div" style={{ display:'flex' }}>
                                            <Box component="div">
                                                <Checkbox />
                                            </Box>
                                            <Box component="div" style={{ marginLeft: '15px' }}>
                                                <span style={{ display:'flex' }}>
                                                    <Typography style={{fontWeight: 600, fontSize: '14px'}}>KCB</Typography>
                                                    <Typography style={{ marginLeft:'8px', color: '#9c9ea4' }}>522533</Typography>
                                                </span>
                                                <span style={{ display:'flex', marginTop: '5px'}}>
                                                    <Typography style={{fontWeight: 'normal', fontSize: '14px', color:'#032541'}}>{bank.accountReference}</Typography>
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Button variant="text" style={{ color:'#032541', fontSize:'14px', textTransform:'capitalize' }} onClick={() =>setShowEdit(true)}>Edit</Button>
                                            <Button variant="text" sx={{ color:'#dc3545', fontSize:'14px', textTransform:'capitalize'}} onClick={() => dispatch(deleteBankPaybill(index))}>Remove</Button>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    <Box component="div" sx={{width:"98.5%", display:"flex", justifyContent:"end", marginTop: '30px'}}>
                        <Box>
                        <Button sx={{
                                ...buttonStyles,
                                border:" solid 1px #002543",
                                color:"#002543",
                                '&:hover': {
                                    backgroundColor:"transparent",
                                    color: '#002543'
                                }
                            }}
                                    onClick={() => setShowBankPaybillSetup(false)}
                            >
                                Done
                            </Button>

                            <Button sx={{
                               ...buttonStyles,
                                marginLeft:1,
                                backgroundColor:" #032541",
                                color:"white",
                                '&:hover': {
                                    backgroundColor: '#032541',
                                    color: 'white'
                                }
                            }}
                                    onClick={() => setPageStatus('initial')}
                            >
                                Add Another
                            </Button>
                        </Box>
                        </Box>
                    </Box>: <EditBankPaybill setSuccessShow={setSuccessShow}  setErrorShow={setErrorShow} errorShow={errorShow} setShowBankPaybillSetup={setShowBankPaybillSetup} />
                }
                    <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <Alert severity={ifError === true ? "error" : "success"} sx={{ width: "100%" }}>
                            <span>{message}</span>
                        </Alert>
                    </Snackbar>

                </Box>
            {/* </>} */}
            {/* {showEdit === true && <EditBankPaybill />} */}

        </Box>
    )
}

export default BankPayBill;
