import SuppliersTable from "./SuppliersTable";
import {useEffect, useState} from "react";
import HttpComponent from "../../School/MakeRequest";

const NewSuppliers = () => {
    const [newSuppliers, setNewSuppliers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // FETCH ALL SUPPLIERS
    const getNewSuppliers = async () =>{
        setLoading(true)
        HttpComponent({
            method: "GET",
            url: `/api/v1/suppliers?page=1&limit=5000&status=NEW`,
            body: null,
            token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
            if (data.status === 201) {
                setNewSuppliers(data.response.data);
                setLoading(false);
            }
        })
            .catch((e) => {
                console.log(e.message);
                setLoading(false)
            });
        setLoading(false);
    }

    // get suppliers based on tab value
    useEffect(() =>{
        getNewSuppliers();
    },[])
    return(
        <SuppliersTable rows={newSuppliers} />

    )
}

export default NewSuppliers;