import { Grid, Typography, Skeleton, InputLabel, FormControl, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import jsondecode from "jwt-decode";
import HttpComponent from '../School/MakeRequest'
import DirectionsCarFilledSharpIcon from '@mui/icons-material/DirectionsCarFilledSharp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CustomDataGrid from '../products/stockComponents/customDataGrid';
import {useNavigate} from 'react-router-dom'
import DateFormatter from '../../utils/dateFormatter';
import { currencyconverter } from '../../common/currencyconverter';
export default function TransportInsuranceDashboard() {
    const navigate = useNavigate()
    const businessCat = localStorage.getItem("businessCategory");
    const group = localStorage.getItem("group");
    const isTransportPartner = (group && group === "Merchant" && businessCat && businessCat === 'Transport') || (group && group === 'TransportPartner');
    useEffect(() => {
        if (group && group === "Cashier") {
          return navigate('/orders/startorder')
        } 
        if (group && group?.toLocaleLowerCase()?.includes("storemanager")) {
          return navigate("/stock");
        } 
        if (group && group === "Supervisor") {
          return navigate("/orders/startorder")
        }
        if (group && group?.toLocaleLowerCase()?.includes("storeoperator")) {
          return navigate("/orders/startorder")
        }
    
        if(isTransportPartner){
          return navigate("/partnerstransport")
        }
        if (group && group === "Admin") {
          return navigate("/adminDashboard")
        }
        navigate('/dashboard')
      }, [group , isTransportPartner])
      
    let localCurrency = localStorage.getItem('localCurrency')
    const localToken = localStorage.getItem("X-Authorization");
    const [isLoading, setIsLoading] = useState(true)
    const decoded = jsondecode(localToken)
    if (!localCurrency) {
        localCurrency = decoded?.localCurrency
    }
    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

    const [vehicleCounts, setvehicleCounts] = useState([])   // get card details
    const [transactCounts, settransactCount] = useState([]) 
    const [allCards , setAllCards] = useState({})  // get card details
    const [operantdetails ,setoperantsDetails] = useState([])

    function getDashboardCardInfo() {
        setIsLoading(true)
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getInsuranceDashboardStats`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    console.log(data , 'data')
                    setIsLoading(false)
                    setvehicleCounts(data?.response?.vehicleCount ?? 0)
                    settransactCount(data?.response?.transactCount ?? 0)
                    setoperantsDetails(data?.response?.operatorCount ?? 0)

                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getDashboardCardInfo()
    }, [])

    console.log(settransactCount , 'settransactCount')

    // get recent transactions

    function getRecentTransactions() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getinsurancetransactionsummary?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=`,
                body: null,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }else {
                    setPageState({ ...pageState, isLoading: false })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getRecentTransactions()
    },[])


    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        transactionId: config?.transactionID,
        businessNumber: config?.businessName,
        Amount: currencyconverter(config?.transamount),
        createdAt:DateFormatter(config?.transtime),
        registrationNo: config?.regNo,
    }))

    // recent transactions columns

    const recentTransactionsColumns = [
        { field: "transactionId", headerName: "transactionId", flex: 1 },
        { field: "businessNumber", headerName: "Sacco", flex: 1 },
        { field: 'Amount', headerName: 'Amount', flex: 1 },
        { field: 'createdAt', headerName: 'Date', flex: 1 },
        { field: 'registrationNo', headerName: 'registrationNo', flex: 1 },
    ]


    return (
        <Grid container height={'100%'} direction={'column'}>
            <Grid item width={'100%'}>
                <Grid container width={'100%'} gap={1} height={'100%'}>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(23, 174, 123, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(23, 174, 123, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography>Collections</Typography>
                                    <FormControl size="small" style={{ background: 'rgba(0, 0, 0, 0.03)' }} sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-label">Date Filter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Date Filter"
                                        >
                                            <MenuItem value=''>All</MenuItem>
                                            <MenuItem value={'Today'}>Today</MenuItem>
                                            <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                            <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                            <MenuItem value={'This Month'}>This Month</MenuItem>
                                            <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#17ad7a' }}>{currencyconverter(transactCounts[0]?.totalTransactions ?? 0)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>{transactCounts[0]?.transactionCount} Transactions</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ReceiptLongIcon style={{ color: '#fff', background: '#17ad7a', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(61, 162, 248, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(61, 162, 248, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Vehicles</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#3da2f8' }}>{vehicleCounts[0]?.totalVehicles ? vehicleCounts[0]?.totalVehicles:0 }</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>Recent Reg : {vehicleCounts[0]?.recentRegNo ? vehicleCounts[0]?.recentRegNo: 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <DirectionsCarFilledSharpIcon style={{ color: '#fff', background: '#3da2f8', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(220, 53, 69, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(220, 53, 69, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Expering This Month</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#dc3545' }}>{allCards?.expiredPoliciesCount ? allCards?.expiredPoliciesCount:0 }</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>Vehicle policies :</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ReceiptLongIcon style={{ color: '#fff', background: '#dc3545', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item width={'23%'} px={isLoading ? 0 : 2} borderRadius={'10px'} style={{ background: 'rgba(247, 144, 9, 0.12)', height: "170px" }}>
                        {isLoading ? (
                            <Skeleton style={{ background: 'rgba(247, 144, 9, 0.12)', borderRadius: '10px' }} animation="wave" variant="rectangular" width="100%" height="100%" />
                        ) :
                            <Grid container height={'100%'} width={'100%'} display={'flex'} justifyContent={'space-between'} direction={'column'}>
                                <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography pt={2} variant='body1'>Operators</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2' style={{ fontSize: '30px', fontWeight: 700, color: '#f79009' }}>{operantdetails[0]?.totalOperators ? operantdetails[0]?.totalOperators:0}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <Typography variant='body2' style={{ fontWeight: 500 }}>Recently added :</Typography>
                                        </Grid>
                                        <Grid item>
                                            <PeopleAltIcon style={{ color: '#fff', background: '#f79009', width: '40px', height: '40px', padding: '10px', borderRadius: '20px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'100%'}>
                <Grid container direction={'column'} gap={2}>
                    <Grid item width={'100%'}>
                        <Typography pt={2} variant='body1' style={{fontWeight:700 , fontSize:'20px'}}>Recent Transactions</Typography>
                    </Grid>
                    <Grid item width={'100%'}>
                        <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={recentTransactionsColumns} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}