import HttpComponent from "../../../School/MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL

export const handleInitiateTransporttkPush = async ({ vehicleOwnerregNo, customername, amount, noofseats, paymentMethod, phone, setStartTransaction, setErrorShow, listenWebSocket }) => {
    setStartTransaction(true);
    try {
        const data = await HttpComponent({
            method: 'POST',
            url: "/api/v1/processMatatuPayment",
            body: {
                regNo: vehicleOwnerregNo,
                customerName: customername,
                amount: amount,
                customerId: localStorage.getItem("userId"),
                noofseats: noofseats,
                paymentMethod: paymentMethod,
                paymentChanel: 'WEB',
                phone: phone
            },
            token: localStorage.getItem('X-Authorization')
        });
        if (data?.status === 200) {
            listenWebSocket(data?.response?.data?.stkOrderId);
            return data?.response?.data;
        } else {
            setErrorShow({ state: true, message: data?.response?.message });
        }
    } catch (error) {
        // Handle error
    }
};

// Function to get invoice payments
export const getInvoicePayments = async (invoiceNumber) => {
    const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'X-Authorization': localStorage.getItem('X-Authorization')
        },
    });
    if (invoicePaymentDetailsResponse?.status === 200) {
        const paymentDetailsData = await invoicePaymentDetailsResponse.json();
        let { invoiceBalance } = paymentDetailsData?.data;
        return invoiceBalance
    }
};

// Function to listen to WebSocket events
export const listenWebSocket = async (invoiceNumber) => {
    return new Promise((resolve, reject) => {
        let ws = "";

        if (process.env.NODE_ENV === 'development') {
            ws = new WebSocket(`wss://api.dev.zed.business/notification/${invoiceNumber}`);
        } else if (process.env.NODE_ENV === 'production') {
            ws = new WebSocket(`wss://api.portal.zed.business/notification/${invoiceNumber}`);
        } else if (process.env.NODE_ENV === 'test') {
            ws = new WebSocket(`wss://zed.api.swerri.io/notification/${invoiceNumber}`);
        }

        ws.onopen = () => { 
            console.log('WebSocket connection opened');
        };

        let data = {}

        ws.onmessage = function (event) {
            data = JSON.parse(event.data);
            if (data.status === "OK") {
            } else if (data.status === 'FAILED') {
                resolve(data)
                ws.close()
            } else if (data?.status === 'SUCCESS') {
                resolve(data)
            }
        };
        ws.onclose = function (event) {
            console.log(`websocket connection closed ${event.code}`);
        };
        ws.onerror = function (error) {
            reject(error);
        };
    })

};