import HttpComponent from "../../School/MakeRequest";

export default function SetWorkFlow({flowName, token}) {
	try {
		HttpComponent({
			method : 'POST',
			url : '/api/update_business_setup_status',
			body : {workflowState: flowName.toUpperCase()},
			token : token
		}).then((data) => {
			console.log(flowName ,'flowname')
			if (201) {
				return console.log(`WorkFLow Updated to ${flowName}`)
			}
		}).catch((e) => {
			return console.log(`Unable to Update WorkFlow`, e.message)
		})
	} catch (e) {
	
	}
}