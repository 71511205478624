import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";
import CustomTextField from "./CustomTextField";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import CustomSelectField from "./CustomSelectField";
import { addParent } from "../../features/customerSlice";
import {isValidPhoneNumber,parsePhoneNumber} from 'libphonenumber-js'
import NewParentForm from './newparentform';
import GeoLocation from '../../hooks/useLocation';

const ParentGuardianForm = (props) => {
    const [addAnother, setAddAnother] = useState(false)
    let localCurrency = localStorage.getItem("localCurrency")
    const [countryCode, setCountryCode] = useState("")
    const [phoneCurrencyCode, setPhoneCurrency] = useState("ke")
    const [currencyCode, setCurrencyCode] = useState(null)
    const [countrySelected, setCountrySelected] = useState("KE")

    useEffect(() => {
        if(!localCurrency) {
         setPhoneCurrency("ke")
        }
        if(localCurrency === "UGX") {
         setPhoneCurrency("ug")
        }
        if(localCurrency === "NGN") {
         setPhoneCurrency("ng")
        }
        if(localCurrency === "USD") {
         setPhoneCurrency("us")
        }
        if(localCurrency === "KES") {
         setPhoneCurrency("ke")
        }
     },[localCurrency])

    const [formData, setFormData] = useState(
        {
            firstName: "",
            lastName: "",
            gender: "",
            email: "",
            mobileNumber: "",
            address: "",
            id_number: ""

        }
    )
    const [formDataTwo, setFormDataTwo] = useState(
        {
            firstName: "",
            lastName: "",
            gender: "",
            email: "",
            mobileNumber: "",
            address: "",
            id_number: ""
        }
    )
    const buttonStyles =
    {
        "minWidth": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const cancelButtonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "border": "solid 1px #dc3545",
        "color": "#dc3545"
    }

    const doneButtonStyles = {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
        "backgroundColor": "#032541",
        "color": "white",
        "marginLeft": 2,
        '&:hover': {
            backgroundColor: '#032541',
            color: 'white'
        }
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    // Handle Change Two
    const handleInputChangeTwo = (e) => {
        setFormDataTwo({
            ...formDataTwo,
            [e.target.name]: e.target.value,
        });
    };


    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" }
    ]

    const dispatch = useDispatch();

    const [validEmail, setValidEmail] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('')

    const submit = () => {
        let newObject = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            paymentType: "Prepaid",
            phone: "+" + phoneNumber,
            amountReceived: 0,
            customerAddress: formData.address,
            businessId: "",
            customerType: "Individual",
            email: formData.email,
            gender: formData.gender,
            id_number: formData.id_number
        }
        dispatch(addParent(newObject));
        setAddAnother(true)
        // props.setView("student")
    }


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    useEffect(() => {
        if (formData.email !== "") {
            let result = validateEmail(formData.email);
            setValidEmail(result);
            // console.log("email valid", result)
        } else {
            setValidEmail(true);
        }
    }, [formData.email]);

    const [validPhone , setValidPhone] = useState(false)


    useEffect(() => {
        if (phoneNumber !== '') {
            try {
                const parsedph = parsePhoneNumber(phoneNumber, countrySelected.toUpperCase());
                if (parsedph && parsedph.isValid()) {
                    setValidPhone(true);
                } else {
                    setValidPhone(false);
                }
            } catch (error) {
                if (error.message === 'TOO_SHORT') {
                    setValidPhone(false);
                } else {
                    console.error("Parsing error:", error);
                }
            }
        } else {
            setValidPhone(false);
        }
    }, [phoneNumber, countrySelected]);
    
    const getGeolocation = (e) => {
        setFormData({...formData, address: e?.description})
    }
  



    return (
        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, width: "100%" }}>
            {addAnother ? <NewParentForm setView={props.setView} addAnother={addAnother} setAddAnother={setAddAnother} /> : <>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <h4 style={{ fontSize: "16px", fontWeight: "600", color: "#707070", textAlign: "center" }}>Add Primary Parent/Guardian <strong style={{ color: "#DC3545" }}> (Complusory)</strong> </h4>
                    <Box component="div" sx={{ display: "flex" }}>
                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"First Name"} />
                            <CustomTextField value={formData.firstName} onChange={handleInputChange} name={"firstName"} placeholder={"First Name"} />

                            <CustomInputLabel required={true} label={"Gender"} />
                            <CustomSelectField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} options={genderOptions} />
                            {/*<CustomTextField value={formData.gender} onChange={handleInputChange} name={"gender"} placeholder={"Select Gender"} />*/}

                            <CustomInputLabel required={true} label={"Email"} />
                            {!validEmail ?
                                <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                    Please enter a valid email
                                </Typography>
                                :
                                null
                            }
                            <CustomTextField value={formData.email} onChange={handleInputChange} name={"email"} placeholder={"Email"} />
                        </Box>


                        <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                            <CustomInputLabel required={true} label={"Last Name"} />
                            <CustomTextField value={formData.lastName} onChange={handleInputChange} name={"lastName"} placeholder={"Last Name"} />

                            <CustomInputLabel required={true} label={"Mobile Number"} />
                            <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                {/* <PhoneInput inputStyle={{ height: '3.438rem' }} required country={"ke"} enableSearch={true} value={phoneNumber} name={"mobileNumber"} onChange={handlePhoneChange} />
                                {!isValidPhone && <p style={{ color: 'red' }}>Phone number must be 10 digits</p>} */}
                                {!validPhone ?
                                    <Typography sx={{ color: "#dc3545", fontSize: "0.75rem" }}>
                                        Phone number is Invalid
                                    </Typography>
                                    :
                                    null
                                }
                                <PhoneInput  inputStyle={{ height: '3.438rem' }} required country={phoneCurrencyCode} enableSearch={true} value={phoneNumber} name={"mobileNumber"}
                                 onChange={(phone, countryData) => {
                                    setPhoneNumber(phone);
                                    setCountrySelected(countryData.countryCode);
                                    setCountryCode(countryData.dialCode);
                                  }} />
                            </Box>
                            {/*<CustomTextField value={formData.mobileNumber} onChange={handleInputChange} name={"mobileNumber"} placeholder={"Mobile Number"} />*/}


                            <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                                <CustomInputLabel required={true} label={"ID Number"} />
                                <CustomTextField value={formData.id_number} onChange={handleInputChange} name={"id_number"} placeholder={"ID Number"} />
                            </Box>
                        </Box>


                    </Box>

                    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
                        {/* <CustomInputLabel required={true} label={"Physical Address"} /> */}
                        {/* <CustomTextField value={formData.address} onChange={handleInputChange} name={"address"} placeholder={"Physical Address"} /> */}
                        {/* <TextField
                            name={"address"}
                            value={formData.address}
                            onChange={handleInputChange}
                            placeholder={"Physical Address"}
                            variant="outlined"
                            margin="dense"
                            sx={{ marginBottom: 2 }}
                            multiline
                            minRows={1}
                            InputProps={{
                                sx: {
                                    width: '51rem',
                                    fontSize: "0.875rem",
                                    "& fieldset": {
                                        borderColor: "#bec5d1"
                                    }
                                }
                            }}
                        /> */}
                        <GeoLocation selectedValue={formData.address}  onValueChange={getGeolocation} sx={{width:"51rem", mb:4}} />
                    </Box>


                    <Box component="div" sx={{ width: "77%", display: "flex", justifyContent: "end" }}>
                        <Box component="div">
                            <Button sx={cancelButtonStyles} onClick={() => props.setView("initial")}>
                                Cancel
                            </Button>

                            <Button
                                sx={doneButtonStyles}
                                onClick={submit}
                                disabled={
                                    !formData.firstName.trim() ||
                                    !formData.lastName.trim() ||
                                    !formData.gender.trim() ||
                                    !formData.email.trim() ||
                                    !validPhone ||
                                    !formData.address.trim()
                                }
                            >
                                Next
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </>}


        </Box>
    )
}

export default ParentGuardianForm;
