import { Breadcrumbs, Divider, Grid, Tab, Typography, Button } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import CustomSelect from "../../School/CustomSelectField";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTable from "../../School/CustomTable";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Button from "@mui/material/Button";

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Approvers</Typography>
]

const AssignUsersbreadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Approvers</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Assign Inputers</Typography>

]
const AssignUsersApproversbreadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Approvers</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Assign approvers</Typography>

]


export default function PayablesApprovers() {
    // GET AMOUNTS RANGE
    const [formData, setFormData] = useState({
        amountRangeId: '',
        inputers: [],
        levelNumber: '',
        levelsData: [],

    })

    const [amoungRangeOptions, setAmountRangeOptions] = useState([])
    const [levelsRangeOptions, setLevelsRangeOptions] = useState([])
    const [showInputersAdd, setShowInputersAdd] = useState(false)
    const [showApproversAdd, setShowApproversAdd] = useState(false)
    const [users, setUsers] = useState([])
    const [rolesOptions, setRolesOptions] = useState([])
    const [selectedInputer, setSelectedInputer] = useState([]);
    const [showAssignSubmit, setShowAssignSubmit] = useState(false)
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [selectedApprove, setSelectedApprove] = useState([])
    const [LevelAssignLevel, setLevelAssignLevel] = useState()
    const [amountRangeApproverInputers, setAmountRangeApproverInputers] = useState([])
    const [checkAmountRangeExists, setCheckAmountRangeExists] = useState(false)
    const [loading, setLoading] = useState(true)

    // const history = useHistory();
    const navigate = useNavigate();






    const getAmountRangeApproverInputers = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/funds_transfer/get_approver_inputer',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                // console.log(data, 'payables status')
                if (data.status === 200) {
                    let amountRange = data.response.data
                    setLoading(false)
                    // console.log(amountRange, 'amount range')
                    setAmountRangeApproverInputers(amountRange)
                    if (amountRange.length > 0) {
                        setCheckAmountRangeExists(true)
                    }
                }
            }
            )
        } catch (error) {
            console.log(error)
        }

    }
    const handleClose = () => {
        // clear all inputers, approvers, levels
        setFormData({
            amountRangeId: '',
            inputers: [],
            levelNumber: '',
            levelsData: [],
        })
        setSelectedInputer([])
        setSelectedApprove([])
        setCheckAmountRangeExists(true)
        getAmountRangeApproverInputers()

    };
    const columns = [
        {
            field: 'amountRangeDescription',
            headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} >Range</span>,
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>{params.row.amountRangeDescription}</span>
                </strong>
            )


        },
        {
            field: 'levelNumber',
            headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>Levels</span>,
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>{params.row.levelNumber}</span>
                </strong>
            )

        },
        {
            field: 'totalInputers',
            headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>Inputers</span>,
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>{params.row.totalInputers}</span>
                </strong>
            )


        },
        {
            field: 'totalApprovers',
            headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>Approvers</span>,
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }} onClick={() => handleEdit(params.row)}>{params.row.totalApprovers}</span>
                </strong>
            )

        },
        {
            field: 'action',
            headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Action</span>,
            flex: 1,
            renderCell: (params) => (
                <strong>
                    <EditIcon style={{ color: "#032541", cursor: "pointer" }} onClick={() => handleEdit(params.row)} />
                    <DeleteIcon style={{ color: "#DC3545", cursor: "pointer" }} onClick={() => handleDelete(params.row)} />

                </strong>
            )
        }




    ]

    const handleEdit = (row) => {
        let amountRangeId = row._id
        navigate(`/payables/view/approvers/${amountRangeId}`)
    }
    const handleDelete = (row) => {
        // console.log(row, 'row')
        let id = row._id

        HttpComponent({
            method: "POST",
            url: '/api/v1/funds_transfer/remove_amount_range',
            body: { amountRangeId: id },
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            if (data.status === 200 || data.status === 201) {
                setSuccessShow({ state: true, message: "Successfully deleted amount Range" });
                getAmountRangeApproverInputers()
                getAmountRange()


                // refreshPage()

            } else if (data.status === 400) {
                setErrorShow({ state: true, message: data.response.message });
            }
        })


    }

    const getAmountRange = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/funds_transfer/amount_range',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                // console.log(data, 'payables status')
                if (data.status === 200) {
                    let amountRange = data.response.data
                    // console.log(amountRange, 'amount range')


                    amountRange = amountRange.filter((item) => {

                        return item.status === 'Active'
                    })

                    amountRange = amountRange.map((item) => {
                        return { value: item._id, label: item.amountRangeDescription }
                    })

                    // console.log(amountRange, 'amount range')



                    return setAmountRangeOptions(amountRange)
                }
            })
        } catch (error) {
            console.log(error)

        }

    }
    const getLevelsRange = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/funds_transfer/levels',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                // console.log(data, 'payables status')
                if (data.status === 200) {
                    let levelsRange = data.response.data
                    // console.log(levelsRange, 'levels range')

                    let levelsRangeOptions = levelsRange.map((item) => {
                        return { value: item.approveLevelNumber, label: item.approveLevelName, levelId: item._id }
                    }
                    )
                    // console.log(levelsRangeOptions, 'levels range options')
                    return setLevelsRangeOptions(levelsRangeOptions)
                }
            })
        }
        catch (error) {
            console.log(error)

        }
    }

    const getFundTransferUsers = async () => {
        HttpComponent({
            method: "GET",
            url: '/api/v1/funds_transfer/users',
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            if (data.status === 200) {
                let users = data.response.data
                // console.log(users, 'users')
                return setUsers(users)
            }
        })


    }

    const getFundsTranferRoles = async () => {
        HttpComponent({
            method: "GET",
            url: '/api/v1/funds_transfer/approval_roles',
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            if (data.status === 200) {
                let roles = data.response.data
                // console.log(roles, 'roles')
                roles = roles.map((item) => {
                    return { value: item.type, label: item.name }
                })
                // console.log(roles, 'roles')
                return setRolesOptions(roles)
            }
        })
    }
    useEffect(() => {
        getAmountRange()
        getAmountRangeApproverInputers()
    }, [])

    useEffect(() => {
        getLevelsRange()
        getFundsTranferRoles()
    }, [])

    useEffect(() => {
        getFundTransferUsers()
    }, [])

    const handleAddInputer = () => {
        setShowInputersAdd(true)

    }

    // SELECT 
    const handleSelectAllClickInputer = (event) => {
        if (event.target.checked) {
            setSelectedInputer(users.map((user) => user)); // select all rows
        } else {
            setSelectedInputer([]); // unselect all rows
        }
    };

    const handleSelectRowClick = (event, userSelect) => {
        let id = userSelect._id
        if (event.target.checked) {
            setSelectedInputer([...selectedInputer, userSelect]); // add row to selection
            setSuccessShow({ state: true, message: "Successfully added inputer" });
        } else {
            setSelectedInputer(selectedInputer.filter((selectedInputer) => selectedInputer !== userSelect)); // remove row from selection
            setSuccessShow({ state: true, message: "Successfully removed inputer" });
        }
    };

    useEffect(() => {
        // console.log("selectedInputer", [...selectedInputer]);
    }, [selectedInputer]);

    const deleteInputerData = (id) => {
        let inputers = selectedInputer.filter((inputer) => inputer._id !== id)
        setSelectedInputer(inputers)
        setSuccessShow({ state: true, message: "Successfully deleted inputer" });
    }

    const handleAddApproverOnEachLevel = (index) => {
        setShowApproversAdd(true)
        // console.log(index, 'index')
        setLevelAssignLevel(index)


    }
    const handleSelectApproverRow = (event, user, LevelAssignLevel) => {
        let newSelectedApprove = [...selectedApprove]

        let currentIndex = newSelectedApprove.findIndex((item) => item._id === user._id && item.levelNumber === LevelAssignLevel)
        // console.log(currentIndex, 'current index')
        if (currentIndex === -1) {
            let newuser = { ...user, levelNumber: LevelAssignLevel }
            if (newuser.approvalStatus === undefined) {
                // return alert('Please select approval status')
                return setErrorShow({ state: true, message: "Please select approval status" })
            }
            // console.log(newuser, 'new user')
            if (newSelectedApprove.findIndex((item) => item._id === user._id) !== -1) {
                let userInSelectedApprove = newSelectedApprove.find((item) => item._id === user._id)
                // console.log(userInSelectedApprove, 'user in selected approve')
                let message = `${user.userName} already exist exist in level ${parseInt(userInSelectedApprove.levelNumber) + 1}`
                return setErrorShow({ state: true, message: message })
            }
            newSelectedApprove.push(newuser)

            setSuccessShow({ state: true, message: "Successfully added approver" });
        } else {
            newSelectedApprove.splice(currentIndex, 1)
            setSuccessShow({ state: true, message: "Successfully removed approver" });

        }

        setSelectedApprove(newSelectedApprove)

        changeButtonStatus()





    }

    // console.log(selectedApprove, '<<<<<======selectedApprove')
    // console.log("rolesOptions", rolesOptions)
    // update approver status
    const updateApproverStatus = (e, index, id) => {
        let newUsers = [...users]

        let user = newUsers.find((item) => item._id === id)
        // console.log(user, 'user ===????')
        user.approvalStatus = e
        newUsers[index] = user
        setUsers(newUsers)
    }

    const deleteUserApprover = (user) => {
        let approvers = selectedApprove.filter((approver) => approver !== user)
        setSelectedApprove(approvers)
        setSuccessShow({ state: true, message: "Successfully deleted approver" });

    }

    const checkedStatus = (user, LevelAssignLevel) => {
        let status = selectedApprove?.findIndex((item) => item._id === user._id && item.levelNumber === LevelAssignLevel)
        // console.log(status, 'status')
        if (status === -1) {
            return false
        }
        else {
            return true
        }
    }

    function changeButtonStatus() {
        if (formData.amountRangeId === '' || formData.levelNumber === '') {
            return setShowAssignSubmit(false)
        }
        if (selectedApprove.length === 0) {
            return setShowAssignSubmit(false)
        }
        if (selectedInputer.length === 0) {
            return setShowAssignSubmit(false)
        }
        // take levelNumber check selectedApprove array ensure there is atleast one user in each level
        let levelNumber = formData.levelNumber
        // console.log(levelNumber, 'levelNumber')
        let selectedApproveArrays = []
        for (let i = 0; i < levelNumber; i++) {
            selectedApproveArrays.push(selectedApprove.filter((user) => user.levelNumber === i))
        }
        console.log(selectedApproveArrays, 'selectedApproveArrays')
        console.log(selectedApproveArrays.length, 'selectedApproveArrays.length', levelNumber)
        console.log(selectedApproveArrays.length === levelNumber, 'selectedApproveArrays.length  === levelNumber')

        if (selectedApproveArrays.length !== levelNumber) {
            return setShowAssignSubmit(false)
        }
        // check if selectedApproveArrays has atleast one user
        for (let i = 0; i < selectedApproveArrays.length; i++) {
            if (selectedApproveArrays[i].length === 0) {
                return setShowAssignSubmit(false)
            }
        }

        if (formData.amountRangeId !== '' && formData.levelNumber !== '' && selectedApprove.length > 0 && selectedInputer.length > 0 && selectedApproveArrays.length === levelNumber) {
            return setShowAssignSubmit(true)
        }

    }

    useEffect(() => {
        changeButtonStatus()
    }, [formData, selectedApprove, selectedInputer])

    const changeFormData = () => {
        // if formData.level changes, let say from 2 to 1 , remove all selectedApprove that has levelNumber === 2
        let levelNumber = formData.levelNumber
        let newSelectedApprove = selectedApprove.filter((user) => user.levelNumber < levelNumber)
        setSelectedApprove(newSelectedApprove)
    }

    useEffect(() => {
        changeFormData()
    }, [formData])
    const refreshPage = () => {
        window.location.reload();
    }
    const handleSubmit = () => {
        let levelsData = []
        let inputers = []
        let amountRangeId = formData.amountRangeId
        let levelNumber = formData.levelNumber
        inputers = selectedInputer.map((inputer) => {
            return { userId: inputer._id }
        });

        for (let i = 0; i < levelNumber; i++) {
            let approves = selectedApprove.filter((user) => user.levelNumber === i)
            approves = approves.map((user) => {
                return { approvalStatus: user.approvalStatus, userId: user._id }
            })
            let levelId = levelsRangeOptions[i].levelId
            let levelData = { approves, levelId }
            levelsData.push(levelData)
        }
        let obj = { amountRangeId, inputers, levelNumber, levelsData }
        console.log(obj, 'data to submit')

        HttpComponent({
            method: "POST",
            url: '/api/v1/funds_transfer/add_approver_inputer',
            body: obj,
            token: localStorage.getItem('X-Authorization'),
        }).then((data) => {
            if (data.status === 200 || data.status === 201) {
                setSuccessShow({ state: true, message: "Successfully assigned approvers" });
                setFormData({
                    amountRangeId: '',
                    inputers: [],
                    levelNumber: '',
                    levelsData: [],
                })
                setSelectedInputer([])
                setSelectedApprove([])
                setShowAssignSubmit(false)
                refreshPage()

            } else if (data.status === 400) {
                setErrorShow({ state: true, message: data.response.message });
            }
        })





    }

    console.log(checkAmountRangeExists, 'checkAmountRangeExists')

    console.log(formData, 'form data ===>')
    function renderGrids() {
        const numGrids = formData?.levelNumber; // Get the number of grids based on form.levelNumber
        return Array.from({ length: numGrids }, (_, index) => (
            <Grid item key={index} style={{ marginTop: "10px" }}>
                {/* The content of each duplicated grid goes here */}
                <Grid item xs={5} style={{ display: "flex", justifyContent: 'space-between' }}>
                    <h5 style={{ color: "#032541", marginTop: "0" }}>Level {index + 1} Approvers</h5>
                    <div style={{ display: "flex", cursor: "pointer" }} onClick={() => handleAddApproverOnEachLevel(index)}>
                        <AddCircleIcon style={{ color: "#032541", marginTop: "0" }} />
                        <p style={{ color: "#032541", marginTop: "0" }}>Assign Approver</p>
                    </div>
                </Grid>
                {selectedApprove?.filter((user) => user.levelNumber === index).map((user) => (
                    <Grid item xs={5} style={{ display: "flex", justifyContent: 'space-between' }}>
                        <p>{user.userName}</p>
                        <p>{user.userPhone}</p>
                        <p>{user.approvalStatus}</p>
                        <p><DeleteIcon style={{ color: "#DC3545", cursor: "pointer" }} onClick={() => deleteUserApprover(user)} /></p>
                    </Grid>
                ))}
                <Divider style={{ width: "42%", color: "#707070", }} />
            </Grid>
        ));
    }



    return (
        <Grid container direction={'column'}>
            <Grid item mt={1}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Payable Approvers</Typography>
            </Grid>

            <Grid item mt={1}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {showInputersAdd ? AssignUsersbreadcrumbs : showApproversAdd ? AssignUsersApproversbreadcrumbs : breadcrumbs}
                </Breadcrumbs>
            </Grid>
            {checkAmountRangeExists ?
                <Grid item mt={2}>
                    <Grid container direction={'column'}>
                        <Grid item style={{ marginLeft: "auto" }} >
                            <Button sx={{ backgroundColor: "#032541", color: "white", height: "3.25rem", marginBottom: 1, '&:hover': { backgroundColor: '#032541', color: 'white' } }} onClick={() => setCheckAmountRangeExists(false)}>Add Approvers</Button>
                        </Grid>
                        <Grid item>
                            <CustomTable data={amountRangeApproverInputers} columns={columns} rowsPerPage={20} loading={loading} />
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item >
                    {showInputersAdd ?
                        <Grid item width={"43%"}>


                            {/* Users inputers here */}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedInputer.length === users.length}
                                                onChange={handleSelectAllClickInputer}
                                            />
                                        </TableCell>
                                        <TableCell>User Name</TableCell>
                                        <TableCell>Phone NUmber</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.length > 0 ?
                                        users?.map((user) => (
                                            <TableRow
                                                hover
                                                key={user?._id}
                                                selectedInputer={selectedInputer?.includes(user)}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedInputer?.includes(user)}
                                                        onChange={(event) =>
                                                            handleSelectRowClick(
                                                                event,
                                                                user

                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>{user?.userName}</TableCell>
                                                <TableCell>
                                                    {user?.userPhone}
                                                </TableCell>

                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <span style={{ color: "#03243F", textAlign: "center" }}>No users </span>
                                            </TableCell>
                                        </TableRow>}
                                </TableBody>
                            </Table>
                            {/* Buttons */}
                            <Grid item xs={5} style={{ display: "flex", justifyContent: 'space-between' }} >
                                <Button variant="contained" style={{ backgroundColor: "#DD3F4B", color: "white", width: "8.813rem", height: "3.25rem", marginBottom: 1, '&:hover': { backgroundColor: '#DD3F4B', color: 'white' } }} onClick={() => setShowInputersAdd(false)}>Cancel</Button>
                                <Button variant="contained" style={{ backgroundColor: "#032541", color: "white", width: "8.813rem", height: "3.25rem", marginLeft: "auto", marginBottom: 1, '&:hover': { backgroundColor: '#032541', color: 'white' } }} onClick={() => setShowInputersAdd(false)}>Assign</Button>
                            </Grid>

                        </Grid> : showApproversAdd ?
                            <Grid item width={"43%"}>

                                {/* Users inputers here */}
                                <Table>
                                    <TableBody>
                                        {users?.length > 0
                                            // filter if there is a user in selectedApprove that match with user._id or ensure
                                            ? users?.map((user, index) => (
                                                <TableRow
                                                    hover
                                                    key={user?._id}
                                                >
                                                    <TableCell padding="checkbox">

                                                        <Checkbox
                                                            checked={checkedStatus(user, LevelAssignLevel)}
                                                            onChange={(event) =>
                                                                handleSelectApproverRow(event, user, LevelAssignLevel)
                                                            } />
                                                    </TableCell>
                                                    <TableCell>{user?.userName}</TableCell>
                                                    <TableCell>{user?.userPhone}</TableCell>
                                                    <TableCell>
                                                        <CustomSelect
                                                            style={{ width: "100%" }}
                                                            name={"selectRole"}
                                                            value={user?.approvalStatus}
                                                            onChange={(e) => {

                                                                updateApproverStatus(e.target.value, index, user._id);
                                                            }}
                                                            options={rolesOptions}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : (
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center">
                                                        <span style={{ color: "#03243F", textAlign: "center" }}>No users </span>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </TableBody>
                                </Table>

                                {/* Buttons */}
                                <Grid item xs={5} style={{ display: "flex", justifyContent: 'space-between', marginTop: "20px", }} >
                                    <Button variant="contained" style={{ backgroundColor: "#DD3F4B", color: "white", width: "8.813rem", height: "3.25rem", marginBottom: 1, '&:hover': { backgroundColor: '#DD3F4B', color: 'white' } }} onClick={() => setShowApproversAdd(false)}>Cancel</Button>
                                    <Button variant="contained" style={{ backgroundColor: "#032541", color: "white", width: "8.813rem", marginLeft: "40px", height: "3.25rem", marginBottom: 1, '&:hover': { backgroundColor: '#032541', color: 'white' } }} onClick={() => setShowApproversAdd(false)}>Assign</Button>
                                </Grid>

                            </Grid> :
                            <Grid item>
                                <Grid item mt={2}>
                                    <Grid container direction={'column'}>
                                        <Grid item>
                                            <Grid container style={{ width: "442px", color: "#707070" }}>
                                                <p> <KeyboardBackspaceIcon style={{ cursor: "pointer" }} onClick={() => handleClose()} /> Assign approvers who will allow payments made from your business to suppliers, vendors or petty cash.</p>
                                            </Grid>

                                            {/* APPROVERS SELECT */}
                                            <Grid item xs={5} >
                                                <CustomInputLabel required={true} label={"Select Approval Amount Range"} />
                                                <CustomSelect
                                                    style={{ width: "100%" }}
                                                    name={"amoungrange"}
                                                    value={formData.amountRangeId}
                                                    onChange={(e) => { setFormData({ ...formData, amountRangeId: e.target.value }) }}
                                                    options={amoungRangeOptions}
                                                />
                                            </Grid>

                                            <Grid item xs={5} >
                                                <CustomInputLabel required={true} label={"Select Approval Levels"} />
                                                <CustomSelect
                                                    style={{ width: "100%" }}
                                                    name={"levels"}
                                                    value={formData.levelNumber}
                                                    onChange={(e) => { setFormData({ ...formData, levelNumber: e.target.value }) }}
                                                    options={levelsRangeOptions}
                                                />
                                            </Grid>
                                            <Grid item xs={5} style={{ display: "flex", justifyContent: 'space-between' }} >
                                                <h5 style={{ color: "#032541", marginTop: "10px" }}>Inputers</h5>
                                                <div style={{ display: "flex", cursor: "pointer" }} onClick={handleAddInputer}>
                                                    <AddCircleIcon style={{ color: "#032541", marginTop: "10px" }} />
                                                    <p style={{ color: "#032541", marginTop: "10px" }}>Add Inputer</p>
                                                </div>
                                            </Grid>
                                            {selectedInputer?.map((inputer) => (
                                                <Grid item xs={5} style={{ display: "flex", justifyContent: 'space-between' }} >
                                                    <p>{inputer.userName}</p>
                                                    <p>{inputer.userPhone}</p>
                                                    <p> <DeleteIcon style={{ color: "#DC3545", cursor: "pointer" }} onClick={() => deleteInputerData(inputer._id)} /> </p>
                                                </Grid>
                                            ))}
                                            <Divider style={{ width: "42%", color: "#707070" }} />
                                            {/* <hr style={{  color: "#707070", width: "42%" }} /> */}
                                        </Grid>

                                        {/* if form.levelNumber === 2   duplicates this grid 2 , form.levelNumber === 3 duplicates this grid 3 times  etc*/}

                                        <Grid item>
                                            {renderGrids()}
                                        </Grid>


                                        <Grid item style={{ display: "flex", justifyContent: 'end', }} width={"43%"} >
                                            <Button variant="contained" style={{ backgroundColor: "#DC3545", marginRight: "20px", color: "white", width: "7.813rem", height: "2.25rem", marginBottom: 1, '&:hover': { backgroundColor: '#DD3F4B', color: 'white' } }} onClick={() => handleClose()}>Cancel</Button>
                                            <Button disabled={!showAssignSubmit} variant="contained" style={{ backgroundColor: showAssignSubmit ? '#032541' : "#9BA7B2", color: "white", width: "7.813rem", height: "2.25rem", marginBottom: 1, '&:hover': { backgroundColor: showAssignSubmit ? '#032541' : "#9BA7B2", color: 'white' } }} onClick={() => handleSubmit()}>Add</Button>
                                        </Grid>


                                    </Grid>

                                </Grid>
                            </Grid>
                    }
                </Grid>
            }

            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

        </Grid>



    )
}