import {
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Alert,
  FormControl ,MenuItem ,Select ,InputLabel
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumbs2 from "../Breadcrumbs/Breadcrumbs2";
import { useDispatch, useSelector } from "react-redux";
import { removeClicked } from "../../../../features/actionSlice";
import { useNavigate } from "react-router-dom";
import { setDetails } from "../../../../features/userToUpdate";
import { handleAdded, handleEdited } from "../../../../features/AlertSlice";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import TermUsersSelect from "./TermUsersSelect";
import PhoneInput from "react-phone-input-2";
import { clearBusinessNo } from "../../../../features/partnerSelectSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  focusedTextField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#032541", // Change this to the desired color
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#032541",
    },
  },
}));

function CreateTerminalUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  // the clicked variable allows us to navigate to current page...removing it allows us to go back
  dispatch(removeClicked());

  const [failMessage, setFailedMessage] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const handleShowFailedAlert = () => {
    if (failMessage) {
      setShowFailedAlert(true);

      // Automatically hide the alert after 3 seconds
      setTimeout(() => {
        setShowFailedAlert(false);
        setFailedMessage(null);
        // dispatch(handleAssigned(false));
      }, 5000);
    }
  };

  useEffect(() => {
    handleShowFailedAlert();
  }, [failMessage]);

  const selectedBusinessNo = useSelector(
    (state) => state.partnerSelect.businessNo
  );

  const [storeId, setStoreId] = useState([]);
  const [businessDetails, setBusinessDetails] = useState([]);

  console.log(storeId , 'storeId >>>>>')
  console.log(businessDetails , 'businessDetails')

  const fetchOutlets = () => {
    try {
      axios
        .get(
          baseUrl +
            `/api/listBusinessOutlets?storeType=MainStore&businessNumber=${selectedBusinessNo}`,
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.data;
          if (data) {
            setStoreId(data);
          } else {
            setFailedMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchingDetailsOfBusiness = () => {
    try {
      axios
        .post(
          baseUrl + "/api/fetchingDetailsOfBusiness",
          {
            businessNo: selectedBusinessNo,
          },
          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          let data = response.data.data[0];
          if (data) {
            console.log("business details", data);
            setBusinessDetails(data);
          } else {
            setFailedMessage(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedBusinessNo) {
      fetchOutlets();
      fetchingDetailsOfBusiness();
    }
  }, [selectedBusinessNo]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [idNo, setIdNo] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [storeidFound ,setStoreIdFound] = useState('')

  const handleFirstname = (e) => {
    setFirstName(e.target.value);
    console.log("firstName", firstName);
  };
  const handleLastname = (e) => {
    setLastName(e.target.value);
  };
  const HandleIdNo = (e) => {
    setIdNo(e.target.value);
  };
  const handleUserName = (e) => {
    setUserName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneNo = (e) => {
    setCustomerPhone(e);
  };

  const handleCreate = async() => {
    try {
     await axios
        .post(
          baseUrl + "/api/addNewUser",
          {
            Equitel: "",
            Paybill: "",
            Till: "",
            Vooma: "",
            assignedBusiness: selectedBusinessNo,
            businessLocation: businessDetails.businessOwnerAddress,
            businessName: businessDetails.businessName,
            businessNumber: selectedBusinessNo,
            storeId: storeidFound,
            businessPhone: businessDetails.businessOwnerPhone,
            businessShortCode: "",
            firstName: firstName,
            loginState: "Active",
            secondName: lastName,
            userEmail: email,
            userGroup: "payItUser",
            userName: userName,
            userPhone: `+${customerPhone}`,
            userState: "Active",
            assignToBusiness: "assignedToZedPayIt",
            userIdNumber: idNo,
          },

          {
            headers: {
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        )
        .then((response) => {
          console.log(response?.data?.message , 'response >>>>')
          let data = response.data.message;
          if (data.includes("Created Successfully")) {
            // console.log("response after creating....", data);
            dispatch(handleAdded(true))
            dispatch(clearBusinessNo())
            navigate("/TermUsers")
          } else {
            setFailedMessage(data);
          }
        });
    } catch (e) {
      if (e?.response) {
        const responseData = e?.response.data;
        setFailedMessage(responseData.message);
      }
      console.log(e);
    }
  };

  return (
    <Stack bgcolor={"white"} p={2} spacing={2} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          fontFamily="Poppins"
          fontSize={25}
          fontWeight="600"
          fontStyle="normal"
          textAlign="left"
          color="#032541"
        >
          Create Terminal User
        </Typography>
        {showFailedAlert && (
          <Alert variant="filled" severity="error">
            {failMessage}
          </Alert>
        )}
      </Stack>
      <Breadcrumbs2
        point1={"Terminal Users"}
        link1={"/TermUsers"}
        point2={"Create Terminal User"}
      />
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography
              fontFamily="Poppins"
              fontSize={16}
              fontWeight="500"
              fontStyle="normal"
              textAlign="left"
              color="#032541"
            >
              Create Terminal User
            </Typography>

            <TermUsersSelect />

            {selectedBusinessNo && (
              <>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Outlet</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={storeidFound}
                    label="Outlet"
                    onChange={(e)=>setStoreIdFound(e.target.value)}
                  >
                    {storeId?.map((store)=> <MenuItem value={store?._id}>{store?.storeName}</MenuItem>)}
                   
                  </Select>
                </FormControl>
                <TextField
                  label="Firstname"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFirstname}
                  // className={classes.focusedTextField}
                />

                <TextField
                  label="Lastname"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleLastname}
                  // className={classes.focusedTextField}
                />
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleUserName}
                  // className={classes.focusedTextField}
                />

                <TextField
                  label="IdNo"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={HandleIdNo}
                  // className={classes.focusedTextField}
                />

                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleEmail}
                  // className={classes.focusedTextField}
                />

                <PhoneInput
                  required
                  country={"ke"}
                  enableSearch={true}
                  label={"Mobile Number"}
                  value={customerPhone}
                  onChange={handlePhoneNo}
                  multiline
                  inputStyle={{ height: "55px", width: "100%" }}
                />
              </>
            )}

            <Stack
              direction={"row"}
              width={"100%"}
              spacing={2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  borderRadius: 4,
                  // backgroundColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#dc3545",
                  // color: "#dc3545"
                }}
                onClick={() => navigate("/TermUsers")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: 4,
                  backgroundColor: "#032541",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#032541",
                }}
                onClick={handleCreate}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default CreateTerminalUser;
