import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from "react-router-dom";

export default function Breadcrumb2(props) {
  const navigate = useNavigate();
  let point1 = props.point1
  let point2 = props.point2
  let link1 = props.link1
  const breadcrumbsArr = [
    <Link
      underline="hover"
      color="inherit"
      onClick={() => {
        navigate("/adminDashboard")
      }}
      sx={{
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "left",
        color: "#707070",
        "&:hover": { cursor: "pointer" },
      }}
    >
      Dashboard
    </Link>,
    <Link
    underline="hover"
    color="inherit"
    onClick={() => {
      navigate(link1)
    }}
    sx={{
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: "normal",
      fontStyle: "normal",
      textAlign: "left",
      color: "#707070",
      "&:hover": { cursor: "pointer" },
    }}
  >
    {point1}
  </Link>,
    <Typography sx={{
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "left",
        color: "#dc3545",
      }}>
        {point2}
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<CircleIcon sx={{ width: "3px", color: "#e1e5e8" }} />}
        aria-label="breadcrumb"
      >
        {breadcrumbsArr}
      </Breadcrumbs>
    </Stack>
  );
}
