import { Alert, Button, Grid, Typography } from "@mui/material";
import FallbacErrImg from "../images/fallbac.svg"

export const fallBackError = ({error , resetErrorBoundary}) => {
    console.log("fall back error component", error)
    return (
       <Grid container display={'flex'} sx={{height:'800px'}} textAlign={'center'} alignItems={'center'} justifyContent={'center'} direction={'column'}>
            <Grid item>
                <img src={FallbacErrImg} alt='Error'/>
            </Grid>
            <Grid item>
                <Typography style={{color:'#272d3b' , fontWeight:'bold', fontSize:'30px' ,fontFamily:'Poppins' }}>Oops !</Typography>
                {process.env.REACT_APP_BASE_URL === "https://api.dev.zed.business" ? <Alert severity="error">{error?.message}</Alert>:null}
                <Typography style={{color:'#272d3b' , fontWeight:'bold', fontSize:'30px' ,fontFamily:'Poppins' }}>Something Went Wrong :(</Typography>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{color:'#707070' , fontSize:'14px' ,fontFamily:'Poppins' , fontWeight:'normal' }}>Looks like something went wrong. Please try refreshing the page or going back to home.</Typography>
            </Grid>
            <Grid item mt={2}>
                <Button  style={{color:'#fff' ,width:'148px' , height:'50px', textTransform:'inherit', background:'#032541'}}>Try again</Button>
            </Grid>
       </Grid>
    )
}

