import { Grid, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";
import CurrencyFormatter from "../../../utils/currencyFormatter";
import { customerstyles } from "./styles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const baseUrl = process.env.REACT_APP_BASE_URL;
const getCustomerDetailsUrl = baseUrl + "/api/get_customer_by_number";
import { useSelector, useDispatch } from "react-redux";
import { resetPrintSettings } from "../../../features/startorderSlice";
import { ArrowBackIos, SaveAlt, Send } from "@mui/icons-material";

const StatementPreveiw = (props) => {
  // const { customerId, startDate, endDate, printPdf } = useSelector((store) => store.startorder);
  // console.log(customerId, startDate, endDate, printPdf);
  const dispatch = useDispatch();
  const customerStatements = props?.customerStatements;
  const customerId = props?.customerId;
  const setShowCustomerStatements = props?.setShowCustomerStatements;
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);
  // const customerId = props?.customerId;
  // const printPDF = props?.printPDF;

  // const getCustomerStatement = () => {
  //   HttpComponent({
  //     method: "GET",
  //     url: `/api/filterCustomerTransactionByDate?customerId=${customerId}&fromDate=${startDate}&toDate=${endDate}`,
  //     body: null,
  //     token: localStorage.getItem("X-Authorization"),
  //   })
  //     .then((data) => {
  //       if (data.status === 201) {
  //         setCustomerStatements(data?.response?.transaction);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.message);
  //     });
  // };

  // useEffect(() => {
  //   getCustomerStatement();
  // }, [customerId]);

  const [customerInfo, setCustomerInfo] = useState({
    customerName: "",
    dayCreated: "",
    customerType: "",
    adress: "",
    paymentType: "",
    currentBalance: "",
    totalCredited: "",
    totalexpenses: "",
    email: "",
    phone: "",
    businessName: "",
    limit: 0,
  });

  const getCustomerDetails = async () => {
    try {
      const customerDetailsResponse = await fetch(getCustomerDetailsUrl + `?customerId=${customerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (customerDetailsResponse.status === 200) {
        const customerDetailsData = await customerDetailsResponse.json();

        const customerData = customerDetailsData?.data[0];

        setCustomerInfo({
          ...customerInfo,
          customerName: customerData?.firstName + " " + customerData?.lastName,
          dayCreated: DateFormatter(customerData?.date),
          customerType: customerData?.customerType,
          adress: customerData?.customerAddress,
          paymentType: customerData?.paymentType,
          currentBalance: !customerData?.totalBalance ? 0 : customerData?.paymentType === "Normal" ? "N/A" : customerData?.totalBalance,
          totalCredited: customerData?.paymentType === "Normal" ? "N/A" : customerData?.totalCredits,
          totalexpenses: customerData?.totalExpense,
          email: customerData?.email,
          phone: customerData?.phone,
          businessName: customerData?.businessName,
          limit: customerData?.limiti,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, [customerId]);

  // download statements;
  const downloadPDF = () => {
    const input = document.getElementById("downloadable");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 1.0);
        const inputWidthPx = input.clientWidth;
        const inputHeightPx = input.clientHeight;
        const dpi = window.devicePixelRatio * 96;
        let scaleFactor = 1;
        if( window.innerWidth > 1512){
            scaleFactor = 1.9;
        }
        const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
        const inputHeightMm = (inputHeightPx / dpi) * 25.4;
        const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
        pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
        pdf.save(`receipt.pdf`);
      })
      .then(() => {
        console.log("prinitng done!");
      });
  };
  useEffect(() => {
    // if(printPdf && customerStatements?.length > 0 )downloadPDF().then(() => dispatch(resetPrintSettings())).catch((e) => console.log(e.message))
  }, []);

  return (
    <div>
      <Grid container width={"100%"} style={{ margin: "0px 0px 10px 0px" }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <span style={customerstyles.previewStatement}>
            {" "}
            <ArrowBackIos onClick={() => setShowCustomerStatements(true)} style={{ color: "#032541" }} /> Preview Statement{" "}
          </span>
        </Grid>

        <Grid item>
         <Button style={{margin:"0px 10px 0px 0px", backgroundColor:"#f5f5f5", color:"#032541"}}  startIcon={<SaveAlt onClick={() => downloadPDF()} />}>
         
          </Button> 

          <Button style={customerstyles.previewShareButton} startIcon={<Send />}>
            Share
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: "0% 0% 0% 10%", height:"auto" }} id="downloadable">
        <Grid container width={"70%"} direction={"row"} justifyContent={"space-between"} style={{ margin: "0px 0px 10px 0px" }}>
          <Grid item>
            <div>
              <p style={customerstyles?.statementBsName}>{customerInfo?.businessName}</p>
              <p>{customerInfo?.adress}</p>
              <span style={customerstyles?.statementBsName}>Customer Name</span> <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.customerName}</p>
              <span style={customerstyles?.statementBsName}>Customer Type</span> <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.customerType}</p>
              <span style={customerstyles?.statementBsName}>Address</span> <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.adress} </p>
            </div>
          </Grid>

          <Grid item>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <p style={customerstyles?.statementPreview}>STATEMENT</p>
              <p style={customerstyles?.customerPreview}>Customer Account</p>
            </div>
          </Grid>
        </Grid>

        <div style={{ borderBottom: "1px solid black", width: "70%", height: "1px", borderStyle: "dotted" }}></div>

        <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"70%"} style={{ margin: "0px 0px 10px 0px" }}>
          <Grid item>
            <p style={customerstyles?.statementBsName}>Payment Type</p>
            <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.paymentType}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles?.statementBsName}>Amount</p>
            <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.limit}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles?.statementBsName}>Expenses</p>
            <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.totalexpenses}</p>
          </Grid>

          <Grid item>
            <p style={customerstyles?.statementBsName}>Balance</p>
            <p style={customerstyles?.studentBsNameHolders}>{customerInfo?.currentBalance}</p>
          </Grid>
        </Grid>

        <div style={{ borderBottom: "1px solid black", width: "70%", height: "1px", borderStyle: "dotted", margin: "0px 0px 10px 0px" }}></div>

        <Grid container width={"70%"} style={{ margin: "0px 0px 10px 0px" }}>
          <Grid item width={"100%"}>
            <TableContainer width={"70%"} component={Paper}>
              <Table stickyHeader sx={{ width: "100%" }}>
                <TableHead style={{ fontWeight: "bold", fontSize: "10px" }}>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Reg No.</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Served By</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Amount(KES)</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Balance(KES)</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerStatements?.map((statement, index) => {
                    const { transactionTime, servedBy, customerName, amount } = statement;
                    return (
                      <TableRow style={{ cursor: "pointer" }} key={index}>
                        <TableCell>{DateFormatter(transactionTime)}</TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>{servedBy}</TableCell>
                        <TableCell>{numberFormat(amount)}</TableCell>
                        <TableCell>{numberFormat(0)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid container width={"70%"}>
          <Grid item width={"70%"}>
            <p style={customerstyles?.studentBsNameFooters}>Thanks for visiting {customerInfo?.businessName} please come again! </p>
            <p style={customerstyles?.studentBsNameFooters}>Powered by ZED Payments Limited info@zed.business</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StatementPreveiw;
